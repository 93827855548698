import React from "react";
import { useNavigate } from "react-router-dom";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import image01 from "assets/images/red-demands/01.svg";
import image02 from "assets/images/red-demands/02.svg";
import image04 from "assets/images/red-demands/04.svg";
import image05 from "assets/images/red-demands/05.svg";
import image07 from "assets/images/red-demands/07.svg";
import image09 from "assets/images/red-demands/09.svg";
import image11 from "assets/images/red-demands/011.svg";

import logoGuiaEdutec from "assets/images/red-demands/logo-guia-edutec-branco.png";

export default function REDDemands() {
  const navigate = useNavigate();
  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Jornada Escola Conectada | Levantamento de Demanda de RED" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
          <section>
            <p>
              Com este questionário, você irá descobrir quais tipos de
              <br /> Recursos Educacionais Digitais são mais prioritários para
              sua rede
            </p>
          </section>
        </S.BannerWrapper>

        <section>
          <div>
            <div>
              <h1>O que é um RED?</h1>
              <p>
                Os RED são conteúdos, ferramentas e/ou plataformas em formato
                digital para fins que facilitam, potencializam e apoiam as
                atividades educacionais (pedagógicos e/ou administrativos), de
                docentes, estudantes e gestores(as).
              </p>
            </div>
            <div>
              <img src={image01} alt="" />
            </div>
          </div>
        </section>

        <section>
          <div>
            <div>
              <img src={image02} alt="" />
            </div>
            <div>
              <h1>Como saber qual o melhor RED para a minha rede de ensino?</h1>
              <p>
                O universos de REDs é enorme, indo desde um texto em formato
                digital até uma plataforma de ensino personalizado, por isso o
                CIEB desenvolveu um questionário que irá ajudar você a delimitar
                esse universo focando no que é mais importante para a sua rede
                no momento.
              </p>
            </div>
          </div>
        </section>

        <section>
          <div>
            <h2>Mas antes...</h2>
            <p>
              Recomendamos que você responda ao{" "}
              <strong>Diagnóstico do Guia Edutec</strong> e também mobilize os
              docentes da sua rede para que respondam à{" "}
              <strong>Autoavaliação</strong>. Assim você terá dados importantes
              que contribuirão para a sua análise dos resultados desta
              ferramenta de Identificação de Demanda de Recursos Educativos
              Digitais.
            </p>

            <div>
              <div>
                <div>
                  <img src={logoGuiaEdutec} alt="" />
                  <p>
                    Conceitos, metodologias e ferramentas para promover o uso de
                    tecnologia para aparendizagem.
                  </p>
                </div>
                <button>Saiba mais</button>
              </div>
              <div>
                <div></div>
                <button>Saiba mais</button>
              </div>
            </div>
          </div>
        </section>

        <section>
          <h1>ETAPAS DA FERRAMENTA</h1>
          <img src={image04} alt="" />

          <div>
            <div>
              <header>
                <span>1</span>
                <img src={image05} alt="" />
              </header>
              <main>
                <p>
                  Responda ao questionário centralizado. Para isso, considere os
                  resultados do Diagnóstico e Autoavaliação
                </p>
              </main>
              <footer>
                <span>Em breve</span>
              </footer>
            </div>
            <div>
              <header>
                <span>2</span>
                <img src={image07} alt="" />
              </header>
              <main>
                <p>
                  Mobilize as escolas da sua rede para que respondam ao
                  questionário
                </p>
              </main>
            </div>
            <div>
              <header>
                <span>3</span>
                <img src={image09} alt="" />
              </header>
              <main>
                <p>
                  Acesse as devolutivas e descubra qual as principais demandas
                  da sua rede por Recursos Educativos Digitais
                </p>
              </main>
            </div>
            <div>
              <header>
                <span>4</span>
                <img src={image11} alt="" />
              </header>
              <main>
                <p>
                  Considere o nível de adoção de tecnologia da sua rede e as
                  competências dos seus professores e decida qual(is) tipos de
                  RED serão adquiridos pela sua rede
                </p>
              </main>
            </div>
          </div>
        </section>

        <section>
          <button onClick={() => navigate("/demanda-de-red/descricao")}>
            VAMOS COMEÇAR
          </button>
        </section>
      </S.Wrapper>
    </Layout>
  );
}
