import styled from "styled-components";

export const SVG = styled.svg`
  .cls-2 {
    fill: #e62270;
    cursor: default !important;
  }

  .cls-hover-competence {
    fill: #e62270;
  }

  .step-1-competence,
  .step-2-competence,
  .step-3-competence,
  .step-4-competence,
  .step-5-competence {
    fill: #e62270;
  }

  .circle-center-competence {
    fill: #e62270;
    cursor: pointer;
  }
`;
