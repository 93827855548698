import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner5 from "assets/images/red-demands/banner_5.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const ArrowDropDown = styled(ArrowDropDownIcon)`
  color: ${({ theme }) => theme.colors.white};
  &&& {
    font-size: 3rem;
  }
`;

export const ArrowDropUp = styled(ArrowDropUpIcon)`
  color: ${({ theme }) => theme.colors.white};
  &&& {
    font-size: 3rem;
  }
`;

export const WrapperLoading = styled.section`
  > section:nth-child(2) {
    display: flex;
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  margin: 7rem 0rem;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;

  > section {
    /* padding: 6rem 0rem; */
    h1,
    h2 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
      font-size: 3.2rem;
    }

    > div {
      p {
        color: ${({ theme }) => theme.colors.blue5};
        font-size: 2rem;
      }
    }
  }

  > section:nth-child(2) {
    > div {
      ${container};
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10rem 7rem;
      /* align-items: center; */
      gap: 2rem;

      > h1 {
        font-size: 2.7rem;
      }

      > p {
        /* padding-inline: 10rem; */
        font-size: 2rem;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 20rem;
      }

      > button {
        margin-top: 4rem;
        font-size: 1.8rem;
        border: none;
        padding: 1.5rem 2.2rem;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
          0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
        background-color: #054159;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
        border-radius: 0.5rem;
        width: fit-content;

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    align-items: center;
    background-color: #158bbc;
    height: 12rem;
    padding: 4rem;

    > p {
      ${container};
      color: ${({ theme }) => theme.colors.white};
      font-size: 2rem;
      font-weight: 500;
    }
  }

  > section:nth-child(5) {
    display: flex;
    align-items: center;
    background-color: #158bbc;
    height: 12rem;
    padding: 4rem;

    > p {
      ${container};
      color: ${({ theme }) => theme.colors.white};
      font-size: 2rem;
      font-weight: 500;
    }
  }

  > section:nth-last-child(2) {
    ${container};
    padding: 4rem 7rem;

    > p {
      font-size: 2rem;
    }
    > div {
      display: flex;
      justify-content: center;
      padding: 10rem 0rem;
      gap: 6rem;
      flex-wrap: wrap;

      > img {
        flex: 1 1;
        width: 50rem;
      }
      > div {
        flex: 1 1;
        > div {
          > p {
            border-radius: 10rem;
            color: ${({ theme }) => theme.colors.black};
            margin: 1rem;
            text-align: center;
            padding: 2rem;
          }
        }
      }
    }
  }

  /* > section:nth-last-child(2) {
    > div {
      ${container};
      display: flex;
      justify-content: center;
      padding: 10rem 0rem;
      > button {
        background-color: #054159;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        width: fit-content;
        font-size: 2.2rem;
        padding: 2rem 3rem;
        border-radius: 1rem;
        cursor: pointer;
        &:hover {
          opacity: 0.9;
        }
      }
    }
  } */

  > section:last-child {
    > div {
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
      }
      > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
        background-color: #148bac;
        > p {
          ${container};
          text-align: center;
          color: ${({ theme }) => theme.colors.white};
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        ${container};

        > div {
          height: 24rem;
          background-color: #dfeef3;
          flex: 1 1 25rem;
          gap: 2rem;
          display: grid;
          place-items: center;
          text-align: center;
        }
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner5});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 52rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;
