import { useEffect } from "react";
import * as S from "./styles";
import Layout from "shared-components/Layout";
import FourLeafClover from "shared-components/FourLeafClover/index.js";
import StepsArea from "../../components/StepsArea";
import useScrollToElement from "hooks/use-scroll-to-element";
import Header from "../../components/Header";
import FirstStep from "./components/FirstStep";
import SecondStep from "./components/SecondStep";
import ThirdStep from "./components/ThirdStep";
import FourthStep from "./components/FourthStep";
import ChangeStepsArea from "shared-components/ChangeStepsArea";
import { useSteps } from "hooks/use-steps";
import { MenuVision } from "shared-components/Menu";
import { useLocation } from "react-router-dom";
import Summary from "shared-components/Summary";

const VISION_STEP = [
  <FirstStep />,
  <SecondStep />,
  <ThirdStep />,
  <FourthStep />,
];

export default function JornadaVisao() {
  const dimension = "vision";
  const { goToSubStep, restartStep } = useSteps();
  const location = useLocation();
  const stepNumber = location?.state?.stepNumber;
  const menuDimension = location?.state?.menuDimension;
  const subStepNumber = location?.state?.subStepNumber;

  useEffect(() => {
    goToSubStep(stepNumber, menuDimension, subStepNumber);
  }, [stepNumber, menuDimension, subStepNumber, goToSubStep]);

  useEffect(() => {
    if (!stepNumber) {
      restartStep();
    }
  }, [stepNumber, menuDimension, subStepNumber, restartStep]);

  useScrollToElement();

  return (
    <S.Wrapper>
      <Layout>
        <Header dimension={dimension} />
        <S.Main>
          <Summary dimension={dimension} />
          <StepsArea>
            <div>
              <aside>
                <FourLeafClover width={150} dimension="vision" />
                <MenuVision hideTitle />
              </aside>
            </div>
            <div>
              <ChangeStepsArea dimension={dimension} steps={VISION_STEP} />
            </div>
          </StepsArea>
        </S.Main>
      </Layout>
    </S.Wrapper>
  );
}
