import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/red-demands/banner.svg";
import guiaedutec from "assets/images/red-demands/guiaedutec.jpg";
import image03 from "assets/images/red-demands/03.jpg";

export const Wrapper = styled.div`
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;
  > section:not(section:nth-last-child(1)) {
    font-size: 1.5rem;

    h1,
    h2 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
    }

    > div {
      > p {
        line-height: 1.6;
        color: ${({ theme }) => theme.colors.blue5};
      }
    }
  }

  > section:nth-child(2) {
    > div {
      ${container};
      display: flex;
      align-items: center;
      gap: 4rem;
      flex-wrap: wrap;

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        flex: 1 1 50rem;

        > p {
          text-align: left;
          font-size: 2.2rem;
        }
      }

      > div:nth-child(2) {
        flex: 1 1 50rem;
        > img {
          object-fit: contain;
        }
      }
    }
  }

  > section:nth-child(3) {
    > div {
      ${container};
      display: flex;
      align-items: center;
      gap: 4rem;
      flex-wrap: wrap;

      > div:nth-child(1) {
        flex: 1 1 50rem;

        > img {
          object-fit: contain;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        flex: 1 1 50rem;
        > h1 {
          text-align: right;
        }
        > p {
          text-align: right;
          font-size: 2.2rem;
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray6};
    padding: 14rem 0rem;

    > div {
      ${container};
      display: flex;
      flex-direction: column;
      gap: 4rem;

      > h2 {
        font-size: 3.2rem;
      }

      > p {
        font-size: 2.2rem;
        text-align: center;
      }

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4rem;
        flex-wrap: wrap;
        > div {
          display: flex;
          flex: 1 1 50rem;
          flex-direction: column;
          align-items: start;
          gap: 1rem;
          margin-top: 2rem;

          > button {
            margin-inline: 1.5rem;
            border: none;
            width: fit-content;
            padding: 1rem 1.4rem;
            border-radius: 0.5rem;
            background-color: ${({ theme }) => theme.colors.white};
            box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
              0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
            font-weight: 500;
            color: ${({ theme }) => theme.colors.black};
            cursor: pointer;
          }
        }

        > div:nth-child(1) {
          > div {
            border-radius: 1.3rem;
            height: 35rem;
            width: 100%;
            background-image: url(${guiaedutec});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 4rem;
            > img {
              width: 29.7rem;
            }

            > p {
              color: ${({ theme }) => theme.colors.white};
              font-size: 1.6rem;
              padding-inline: 4rem;
            }
          }
        }

        > div:nth-child(2) {
          > div {
            height: 35.5rem;
            width: 100%;
            background-image: url(${image03});
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
          }
        }
      }
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    ${container};
    > img {
      object-fit: contain;
    }
    > h1 {
      text-align: center;
    }
    > div {
      display: flex;
      justify-content: space-between;
      gap: 2.5rem;
      flex-wrap: wrap;
      > div {
        flex: 1 1 26rem;
        min-height: 40rem;
        /* height: fit-content; */
        border-radius: 1rem;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        > header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            font-style: italic;
            font-size: 4.4rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.white};
          }
          > img {
            width: 12rem;
          }
        }

        > main {
          > p {
            font-size: 1.6rem;
            color: ${({ theme }) => theme.colors.white};
          }
        }

        > footer {
          position: relative;
          > span {
            position: absolute;
            left: -4rem;
            top: 0rem;
            padding: 1rem 2.5rem;
            background-color: #f51111;
            color: ${({ theme }) => theme.colors.white};
            border-radius: 0rem 1rem 1rem 0rem;
            font-size: 1.8rem;
          }
        }
      }

      > div:nth-child(1) {
        background-color: #2ccef9;
      }

      > div:nth-child(2) {
        background-color: #2ea5e0;
      }

      > div:nth-child(3) {
        background-color: #2483ca;
      }

      > div:nth-child(4) {
        background-color: #1960b1;
      }
    }
  }

  > section:nth-child(6) {
    ${container};
    display: flex;
    padding: 8rem 0rem;
    justify-content: center;
    > button {
      font-size: 1.8rem;
      border: none;
      padding: 1.5rem 2.2rem;
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
        0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
      background-color: #054159;
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
      border-radius: 0.5rem;
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  > section {
    background-color: #085270;
    padding: 4rem;

    > p {
      ${container};
      color: #26d8fa;
      font-size: 2.5rem;
      text-align: center;
    }
  }
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 58rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;

export const JourneySection = styled.section`
  background-color: ${({ theme }) => theme.colors.gray6};
  padding: 6rem 0rem 3rem 0rem;

  > div {
    ${container}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 6rem;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      font-size: 1.6rem;
      > h1 {
        font-weight: 500;
        font-size: 2.2rem;
        color: ${({ theme }) => theme.colors.black};
        text-align: center;
      }

      > p {
        font-size: 1.6rem;
        text-align: center;
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      > img {
        margin-top: 1.5rem;
        width: 80rem;
      }
    }
  }
`;
