import React, { useState } from "react";
import * as S from "./styles";
import image42 from "assets/images/red-demands/042.svg";
import { QUESTIONS } from "../../subpages/Questions/data";
import Modal from "shared-components/Modal";

export default function GroupSection({
  groupKey,
  groupId,
  groupName,
  description,
  priorities,
  demand,
  schoolAmount,
  school,
}) {
  const [demandInfo, setDemandInfo] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const getDemandInfo = (demand, itemId) => {
    const item = QUESTIONS[demand].find((item) => item.id === itemId);
    setDemandInfo(item);
    setIsOpen(true);
  };

  const prioritiesSchoolSorting = () => {
    return Object.entries(priorities)
      .map(([key, value]) => [value, key])
      .sort((a, b) => a[0] - b[0]);
  };

  const prioritiesNetworkSorting = () => {
    const prioritiesAux = Object.entries(priorities).map(([key, value]) => ({
      ...value,
      key,
    }));

    return prioritiesAux.sort(
      (a, b) =>
        b["1"] - a["1"] ||
        b["2"] - a["2"] ||
        b["3"] - a["3"] ||
        b["4"] - a["4"] ||
        b["5"] - a["5"]
    );
  };

  return (
    <S.Wrapper key={groupKey} groupId={groupId} school={school}>
      <div>
        <div>
          <div>
            <img src={image42} alt="" />
          </div>
          <div>
            <div>
              {groupName}
              {description}
            </div>
            {!school && (
              <div>
                <div>
                  <span>
                    {(
                      (schoolAmount?.demands[demand] /
                        Object.values(schoolAmount?.demands).reduce(
                          (p, c) => p + c,
                          0
                        )) *
                      100
                    ).toFixed(1) || 0}
                    %
                  </span>
                  <p>% de escolas</p>
                  <p>
                    que escolheram esta
                    <br /> demanda
                  </p>
                </div>
                <div>
                  <span>{schoolAmount?.demands[demand]}</span>
                  <p>quantidade</p>
                  <p>de escolas</p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div>
        <div>
          {!school && (
            <>
              <p>Estes são os tipos de software que fazem parte desse grupo:</p>

              <div>
                <p>nº de escolas</p>
                <span>que escolheram o software por prioridade</span>

                <div>
                  <div>PRIORIDADE</div>
                  <div>
                    <ul>
                      {QUESTIONS[demand].map((_, index) => (
                        <li key={`item-${demand}-${index}`}>{index + 1}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )}

          {school && Object.keys(priorities).length !== 0 && (
            <>
              <p>
                Estes são os tipos de softwares que fazem parte desse grupo de
                acordo com a ordem de priorização que você estipulou
              </p>
              <p>
                Se quiser saber mais sobre outros tipos de softwares não
                priorizados pelas suas escolas, acesse{" "}
                <a
                  href="https://reds.cieb.net.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  reds.cieb.net.br
                </a>{" "}
                e conheça!
              </p>
            </>
          )}
        </div>

        <div>
          {!school &&
            prioritiesNetworkSorting(priorities).map((p, index) => (
              <div key={`${Object.keys(priorities)[index]}-${index}`}>
                <div>
                  <span>{index + 1}</span>
                </div>
                <div>
                  <div>
                    <h1>
                      {
                        QUESTIONS[demand].find((item) => p.key === item.id)
                          ?.name
                      }
                    </h1>
                    <button onClick={() => getDemandInfo(demand, p.key)}>
                      O que é isso?
                    </button>
                  </div>
                </div>
                <div>
                  {Object.values(priorities[p.key]).map((priority, index) => (
                    <div
                      key={`key-${priority.key}-priority-${priority}-${index}`}
                    >
                      <p>{priority}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}

          {school &&
            prioritiesSchoolSorting(priorities).map((p, index) => (
              <div key={`${p[1]}-${index}`}>
                <div>
                  <span>{p[0]}</span>
                </div>
                <div>
                  <div>
                    <h1>
                      {QUESTIONS[demand].find((item) => p[1] === item.id)?.name}
                    </h1>
                    <button onClick={() => getDemandInfo(demand, p[1])}>
                      O que é isso?
                    </button>
                  </div>
                </div>
                <div>
                  {Object.values(priorities[p[1]]).map((priority, index) => (
                    <div
                      key={`key-${priority.key}-priority-${priority}-${index}`}
                    >
                      <p>{priority}</p>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        title={<h1>{demandInfo?.name}</h1>}
        closeModal={() => setIsOpen(false)}
      >
        <S.TechnologyModalInfo>
          <h2>Termos internacionais:</h2>

          {demandInfo.what_is?.international_terms}

          <h2>Principais funcionalidades:</h2>

          {demandInfo.what_is?.definition}

          <h2>Exemplos:</h2>

          {demandInfo.what_is?.examples}
        </S.TechnologyModalInfo>
      </Modal>
    </S.Wrapper>
  );
}
