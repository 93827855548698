import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function FirstStep() {
  return (
    <S.Wrapper>
      <p>Olá gestor, olá gestora!</p>

      <p>
        Bem-vindo/a à Jornada de Competência! Essa dimensão indica a capacidade
        dos atores escolares utilizarem tecnologias digitais nos processos de
        ensino, de aprendizagem e de gestão. Essa dimensão contempla ainda a
        oferta, o incentivo e a participação em formações continuadas para uso
        de tecnologias digitais.
      </p>

      <p>
        Nesta caminhada, a Jornada de competência é composta por quatro etapas
        que te apoiarão no entendimento do que são competências digitais de
        docentes, como mapear o nível de desenvolvimento dessas, como planejar
        formações continuadas para avançar de nível e como acompanhar esse
        processo. Vamos lá?
      </p>

      <h1 id="c-e-1">1. CONCEITOS EM COMPETÊNCIAS DIGITAIS</h1>

      <p>
        O quanto você está familiarizado/a com o termo “competências digitais”?
        Bem, é sobre isso que vamos falar agora. Siga em frente e entenda de
        quais competências estamos falando e qual a sua importância nos dias de
        hoje.
      </p>

      <h2 id="c-e-1-s-1">1.1 O que são competências digitais</h2>

      <p>
        As Diretrizes Curriculares Nacionais (DCN) para a{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="http://portal.mec.gov.br/index.php?option=com_docman&view=download&alias=135951-rcp002-19&category_slug=dezembro-2019-pdf&Itemid=30192"
        >
          formação inicial
        </a>{" "}
        (2019) e para a formação{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.in.gov.br/web/dou/-/resolucao-cne/cp-n-1-de-27-de-outubro-de-2020-285609724"
        >
          continuada
        </a>{" "}
        (2020) de professores/as da Educação Básica indicam um conjunto de
        competências necessárias para o exercício profissional da docência por
        meio da Base Nacional Comum de Formação (BNC-F). Elas estão divididas em
        três dimensões: conhecimento profissional, prática profissional e
        engajamento profissional.
      </p>
      <p>
        Diante das políticas nacionais para a Educação Básica que complementam e
        colaboram com a implementação da base nacional comum curricular (BNCC),
        destacamos nas DCN o desenvolvimento das{" "}
        <span className="pink">competências digitais docentes</span>. Elas podem
        ser definidas como a mobilização de conhecimentos, atitudes, práticas,
        valores que docentes demonstram no uso mais efetivo de tecnologias
        digitais nos processos de ensino e de aprendizagem.
      </p>

      <h2 id="c-e-1-s-2">1.2 De quais competências digitais estamos falando</h2>

      <p>
        Elaboramos uma matriz de competências digitais para docentes que é
        composta por três áreas e 12 competências digitais, assim dispostas :
      </p>

      <ul>
        <li>Ensino e aprendizagem</li>
        <ul>
          <li>Prática Pedagógica</li>
          <li>Avaliação</li>
          <li>Personalizaçãoo</li>
          <li>Curadoria e criação</li>
        </ul>

        <li>Cidadania digital</li>
        <ul>
          <li>Uso responsável</li>
        </ul>

        <li>Desenvolvimento profissional</li>
      </ul>

      <p>
        Para se aprofundar nos conceitos e razões dessa matriz, acesse a Nota
        Técnica 15
      </p>

      <Card
        heading="NOTA"
        title="TÉCNICA 15"
        buttonText="LEIA MAIS"
        dimension="competence"
        backgroundName="notaTecnica15"
        link="https://cieb.net.br/cieb-notas-tecnicas-15-autoavaliacao-de-competencias-digitais-de-professores/"
      />

      <h2 id="c-e-1-s-3">1.3 Para que desenvolver competências digitais</h2>

      <p>
        Quando os/as docentes desenvolvem competências digitais, eles/as se
        tornam capazes de utilizar tecnologias digitais com intencionalidade
        pedagógica clara nos processos de ensino e aprendizagem. Com isso,
        conseguem planejar melhor o ensino híbrido e adotar práticas pedagógicas
        inovadoras para os/as estudantes desenvolverem competências e
        habilidades esperadas para cada etapa de ensino.
      </p>

      <p>
        Conheça o{" "}
        <a target="_blank" rel="noreferrer" href="https://ppi.cieb.net.br/">
          <i>Guia do Ensino Híbrido</i>
        </a>{" "}
        em Escolas Conectadas para saber mais sobre essa abordagem pedagógica e
        conferir exemplos de como ela pode acontecer na prática.
      </p>
    </S.Wrapper>
  );
}
