import styled from "styled-components";

const competencesColors = ["#7e255a", "#11817c", "#238ccc"];

export const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  max-width: fit-content;
  border-radius: 2rem;
  padding: 4rem 2rem;
  gap: 3.5rem;
  min-height: 55.2rem;
  min-width: 50rem;

  > header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding: 4rem;
    > img {
      width: 5.2rem;
      height: 5.2rem;
    }

    > h1 {
      font-size: 2.4rem;
      font-weight: 400;
      text-align: center;
      color: #1a2341;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    > div {
      display: flex;
      justify-content: space-between;
      border: 0.1rem solid rgba(0, 0, 0, 0.1);
      border-radius: 2rem;
      padding: 1rem 2rem;
      gap: 3rem;
      > span {
        font-weight: 400;
        font-size: 1.6rem;
        color: #707070;
      }
    }

    > p {
      padding: 2rem;
      font-size: 1.8rem;
    }
  }
`;

export const CompetenceLevel = styled.ul`
  display: flex;
  gap: 4rem;
  list-style: none;
  align-items: center;

  > li {
    > p {
      font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
    }
  }

  > li:nth-child(${({ value }) => value}) {
    position: relative;
    z-index: 2;

    > p {
      z-index: 1;
      font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
      color: ${({ theme }) => theme.colors.white};
    }

    > span {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background-color: ${({ competence }) =>
        competencesColors[competence - 1]};
      width: 3rem;
      height: 5rem;
      border-radius: 2rem;
      z-index: -1;
    }
  }
`;
