import IMask from "imask";

export const phoneMask = IMask.createMask({
  mask: "(00) 00000-0000",
});

export const number = IMask.createMask({
  mask: Number,
  thousandsSeparator: ".",
});
