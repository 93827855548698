import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/red-demands/banner.svg";

export const Wrapper = styled.div`
  margin-top: 7rem;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;

  > section:not(section:nth-last-child(1)) {
    font-size: 1.5rem;

    h1,
    h2 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
    }

    > div {
      > p {
        line-height: 1.6;
        color: ${({ theme }) => theme.colors.blue5};
      }
    }
  }

  > section:nth-child(2) {
    ${container};
    display: flex;
    align-items: center;
    gap: 2rem;
    > div {
      > img {
        flex: 0.3;
      }
    }
    > div {
      display: flex;
      flex-direction: column;
      gap: 2.4rem;
      flex: 0.7;
      > h1 {
        text-align: left;
      }
      > p {
        text-align: left;
        font-size: 2.2rem;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
    background-color: ${({ theme }) => theme.colors.gray6};
    padding: 8rem 0rem;

    > div {
      ${container};
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 6rem;

      > h2 {
        font-size: 3.2rem;
      }

      > p {
        font-size: 2.2rem;
        text-align: center;
      }

      > img {
        width: 70rem;
      }

      > div {
        display: flex;
        justify-content: center;
        gap: 4rem;
        > div {
          display: flex;
          flex-direction: column;
          gap: 1rem;

          > button {
            border: none;
            width: fit-content;
            padding: 1rem 1.4rem;
            background-color: ${({ theme }) => theme.colors.white};
            box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
              0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
            font-weight: 500;
            color: ${({ theme }) => theme.colors.black};
            cursor: pointer;
          }
        }
      }
    }
  }

  > section:nth-child(4) {
    > div {
      ${container};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8rem;
      padding: 12rem 0rem;
      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        > img {
          width: 22rem;
        }
      }
      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 2.4rem;
        width: 80rem;
        > h1 {
          text-align: left;
        }
        > ol {
          > li {
            font-size: 2.2rem;
            margin-bottom: 4rem;
            line-height: 1.6;
          }
        }
      }
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    ${container};
    > img {
      object-fit: contain;
    }
    > h1 {
      text-align: center;
    }
    > div {
      display: flex;
      flex-wrap: wrap;
      gap: 3rem;
      > div {
        flex: 1 1 30rem;
        height: 35rem;
        border-radius: 1rem;
        padding: 4rem;
        display: flex;
        flex-direction: column;
        gap: 5rem;

        > header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > span {
            font-style: italic;
            font-size: 4.4rem;
            font-weight: 600;
            color: ${({ theme }) => theme.colors.white};
          }
          > img {
            width: 12rem;
          }
        }

        > main {
          > p {
            font-size: 2.2rem;
            color: ${({ theme }) => theme.colors.white};
            line-height: 1.5;
          }
        }
      }

      > div:nth-child(1) {
        background-color: #2ccef9;
      }

      > div:nth-child(2) {
        background-color: #2ea5e0;
      }

      > div:nth-child(3) {
        background-color: #2483ca;
      }
    }
  }

  > section:nth-child(6) {
    ${container};
    display: flex;
    padding: 8rem 0rem;
    justify-content: center;
    > button {
      font-size: 1.8rem;
      border: none;
      padding: 1.5rem 2.2rem;
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
        0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
      background-color: #054159;
      color: ${({ theme }) => theme.colors.white};
      cursor: pointer;
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  > section {
    background-color: #085270;
    padding: 4rem;

    > p {
      ${container};
      color: #26d8fa;
      font-size: 2.5rem;
      text-align: center;
    }
  }
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 58rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;

export const JourneySection = styled.section`
  padding: 6rem 0rem 3rem 0rem;

  > div {
    ${container}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 6rem;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      font-size: 1.6rem;
      > h1 {
        font-weight: 600;
        font-size: 3rem;
        color: ${({ theme }) => theme.colors.blue5};
        text-align: center;
      }

      > p {
        font-size: 2.5rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.blue5};
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      > img {
        margin-top: 1.5rem;
        width: 110rem;
      }
    }
  }
`;
