import * as S from "./styles";
import OrbitalSVGInfra from "../../OrbitalsSVG/OrbitalSVGInfra";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function OrbitalInfra({ id, large }) {
  const navigate = useNavigate();

  return (
    <S.Wrapper id={id} large={large}>
      <OrbitalSVGInfra />
      <p>PLANEJAR A INFRAESTRUTURA E A CONECTIVIDADE</p>
      <p>DIAGNÓSTICO DA SITUAÇÃO DE INFRAESTRUTURA E CONECTIVIDADE DAS REDES</p>
      <p>
        PLANEJAMENTO, AQUISIÇÃO DE INFRAESTRUTURA E CONTRATAÇÃO DE CONECTIVIDADE
      </p>
      <p>MONITORAMENTO</p>
      <p onClick={() => navigate("/jornada/infraestrutura")}>
        QUAL O INVESTIMENTO EM EQUIPAMENTOS E CONECTIVIDADE?
      </p>
    </S.Wrapper>
  );
}

OrbitalInfra.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
};

export default OrbitalInfra;
