import { useEffect, useMemo, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";

import ScrollToTop from "utils/scroll-to-top";
import { JourneysProvider } from "hooks/use-journeys";
import MainGoal from "pages/Plans/subpages/MainGoal";
import { ResponsibleTableProvider } from "hooks/use-responsible-table";
import Home from "pages/Home";
import SignIn from "pages/SignIn";
import JornadaInfra from "pages/Jornadas/subpages/JornadaInfra";
import JornadaVisao from "pages/Jornadas/subpages/JornadaVisao";
import JornadaCompetencia from "pages/Jornadas/subpages/JornadaCompetencia";
import JornadaRed from "pages/Jornadas/subpages/JornadaRed";
import NetworkData from "pages/Plans/subpages/NetworkData";
import Plans from "pages/Plans";
import VisionPlan from "pages/Plans/subpages/VisionPlan";
import CompetencePlan from "pages/Plans/subpages/CompetencePlan";
import RedPlan from "pages/Plans/subpages/RedPlan";
import InfraPlan from "pages/Plans/subpages/InfrastructurePlan";
import FollowUp from "pages/Plans/subpages/FollowUp";

import {
  getStorageAuthenticityToken,
  setStorageAuthenticityToken,
  setStorageInstitutionId,
  setStorageSchoolId,
  setStorageUserId,
  setStorageUserName,
  setStorageProfile,
} from "utils/local-storage";

import { useURLQuery } from "utils/routes";
import { useJWTValidationMutation } from "services/common";
import REDDemands from "pages/REDDemands";
import AdminResults from "pages/REDDemands/subpages/AdminResults";
import Questions from "pages/REDDemands/subpages/Questions";
import FormHome from "pages/REDDemands/subpages/FormHome";
import SchoolDiagnosis from "pages/REDDemands/subpages/SchoolDiagnosis";
import SchoolDemands from "pages/REDDemands/subpages/SchoolDemands";
import Description from "pages/REDDemands/subpages/Description";
import { isAllowedProfile } from "utils/users";
import SchoolResults from "pages/REDDemands/subpages/SchoolResults";

import GENTeachersSelfAssessment from "pages/EdutecGuideInNumbers/subpages/TeachersSelfAssessment";
import GENSchoolDiagnosis from "pages/EdutecGuideInNumbers/subpages/SchoolDiagnosis";
import EdutecGuideInNumbers from "pages/EdutecGuideInNumbers";
import { GEINNumbersFilterProvider } from "hooks/use-ge-in-numbers-filter";
import InfraCalculatorHome from "pages/InfrastructureCalculator";
import InfraCalculatorReferenceValues from "pages/InfrastructureCalculator/subpages/InfraCalculatorReferenceValues";
import InfraCalculatorParameters from "pages/InfrastructureCalculator/subpages/InfraCalculatorParameters";
import InfraCalculatorNetworkData from "pages/InfrastructureCalculator/subpages/InfraCalculatorNetworkData";
import { InfraCalculatorFilterProvider } from "hooks/use-infra-calculator-filter";
import InfraCalculatorDistribution from "pages/InfrastructureCalculator/subpages/InfraCalculatorDistribution";
import InfraCalculatorFeedback from "pages/InfrastructureCalculator/subpages/InfraCalculatorFeedback";
import { PlansProvider } from "hooks/use-plans";

function ProtectedRoute({ children, profiles }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [haveToken, setHaveToken] = useState(true);
  const [haveProfile, setHaveProfile] = useState(null);

  const query = useURLQuery();
  const accessToken = useMemo(() => {
    return getStorageAuthenticityToken();
  }, []);

  const JWTValidation = useJWTValidationMutation();

  const {
    mutate: JWTValidationMutate,
    data: JWTValidationData,
    isSuccess: JWTValidationSuccess,
  } = JWTValidation;

  useEffect(() => {
    if (query.get("token")) {
      setHaveToken(true);
      JWTValidationMutate({ jwt: query.get("token") });
    } else {
      setHaveToken(false);
    }
  }, [query, JWTValidationMutate]);

  useEffect(() => {
    if (JWTValidationSuccess) {
      if (!JWTValidationData?.valid_token) {
        navigate("/login", { state: location });
      }

      if (!accessToken && !JWTValidationData?.valid_token) {
        navigate("/login", { state: location });
      }

      if (JWTValidationData?.valid_token) {
        setStorageProfile(JWTValidationData?.user._profile);
        setHaveProfile(JWTValidationData?.user._profile);
        setStorageAuthenticityToken(JWTValidationData?.access_token);
        setStorageUserId(JWTValidationData?.user?._id?.$oid);
        setStorageUserName(JWTValidationData?.user?.name);
        JWTValidationData?.user?.school_id &&
          setStorageSchoolId(JWTValidationData?.user.school_id?.$oid);
        JWTValidationData?.user?.institution_id &&
          setStorageInstitutionId(JWTValidationData?.user.institution_id?.$oid);
      }
    }
  }, [
    haveProfile,
    haveToken,
    navigate,
    JWTValidationSuccess,
    JWTValidationData,
    accessToken,
    location,
    profiles,
  ]);

  useEffect(() => {
    if (!haveToken && !accessToken && !isAllowedProfile(profiles)) {
      navigate("/login", { state: location });
    }
  }, [haveToken, haveProfile, location, navigate, profiles, accessToken]);

  return children;
}

export default function AppRoute() {
  return (
    <Router basename="/">
      <ScrollToTop />
      <JourneysProvider>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route
            path="/calculadora-de-infraestrutura/investimento"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorFilterProvider>
                  <InfraCalculatorFeedback />
                </InfraCalculatorFilterProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculadora-de-infraestrutura/distribuicao"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorFilterProvider>
                  <InfraCalculatorDistribution />
                </InfraCalculatorFilterProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculadora-de-infraestrutura/dados-da-rede"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorFilterProvider>
                  <InfraCalculatorNetworkData />
                </InfraCalculatorFilterProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculadora-de-infraestrutura/parametros"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorParameters />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculadora-de-infraestrutura/valores-de-referencia"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorReferenceValues />
              </ProtectedRoute>
            }
          />
          <Route
            path="/calculadora-de-infraestrutura"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <InfraCalculatorHome />
              </ProtectedRoute>
            }
          />

          <Route
            path="/planos/acompanhamento/:plan"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <FollowUp />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/acompanhamento"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <FollowUp />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/infraestrutura"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <InfraPlan />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/competencia"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <CompetencePlan />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/red"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <RedPlan />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/visao"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <VisionPlan />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/vigencia-objetivo-geral"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <MainGoal />
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos/dados-da-rede"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <ResponsibleTableProvider>
                    <NetworkData />
                  </ResponsibleTableProvider>
                </PlansProvider>
              </ProtectedRoute>
            }
          />
          <Route
            path="/planos"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <PlansProvider>
                  <Plans />
                </PlansProvider>
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/resultados-da-escola"
            element={
              <ProtectedRoute profiles={["principal"]}>
                <SchoolResults />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/resultados-da-rede"
            element={
              <ProtectedRoute profiles={["admin_city", "admin_state"]}>
                <AdminResults />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/questoes"
            element={
              <ProtectedRoute
                profiles={["admin_city", "admin_state", "principal"]}
              >
                <Questions />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/demandas-da-escola"
            element={
              <ProtectedRoute
                profiles={["admin_city", "admin_state", "principal"]}
              >
                <SchoolDemands />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/diagnostico-da-escola"
            element={
              <ProtectedRoute
                profiles={["admin_city", "admin_state", "principal"]}
              >
                <SchoolDiagnosis />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/escola"
            element={
              <ProtectedRoute
                profiles={["admin_city", "admin_state", "principal"]}
              >
                <FormHome />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red/descricao"
            element={
              <ProtectedRoute>
                <Description />
              </ProtectedRoute>
            }
          />

          <Route
            path="/demanda-de-red"
            element={
              <ProtectedRoute>
                <REDDemands />
              </ProtectedRoute>
            }
          />

          <Route
            path="/ge-em-numeros/autoavaliacao-professores"
            element={
              <GEINNumbersFilterProvider>
                <GENTeachersSelfAssessment />
              </GEINNumbersFilterProvider>
            }
          />

          <Route
            path="/ge-em-numeros/diagnostico-escolas"
            element={
              <GEINNumbersFilterProvider>
                <GENSchoolDiagnosis />
              </GEINNumbersFilterProvider>
            }
          />

          <Route path="/ge-em-numeros" element={<EdutecGuideInNumbers />} />
          <Route path="/login/" element={<SignIn />} />
          <Route path="/jornada/infraestrutura/" element={<JornadaInfra />} />
          <Route path="/jornada/visao/" element={<JornadaVisao />} />
          <Route
            path="/jornada/competencia/"
            element={<JornadaCompetencia />}
          />
          <Route path="/jornada/red/" element={<JornadaRed />} />
        </Routes>
      </JourneysProvider>
    </Router>
  );
}
