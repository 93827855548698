export const getStatus = (start, end) => {
  start = Date.parse(start).valueOf();
  end = Date.parse(end).valueOf();
  const now = new Date().valueOf();
  if (start <= now && now <= end) {
    return "in_progress";
  } else if (start > now) {
    return "not_started";
  } else if (now > end) {
    return "concluded";
  }
};
