import * as S from "./styles";
import PropTypes from "prop-types";

function StepsArea({ children }) {
  return <S.Wrapper>{children}</S.Wrapper>;
}

StepsArea.propTypes = {
  children: PropTypes.node.isRequired,
};

export default StepsArea;
