import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from "@mui/icons-material/Delete";
import PropTypes from "prop-types";
import { useEffect, useRef } from "react";
import * as S from "./styles";
import { Controller } from "react-hook-form";
import DatePicker from "shared-components/DatePicker";
import dayjs from "dayjs";
import Select from "shared-components/Select";
import ReactTooltip from "react-tooltip";
import { convertToMoneyFormat } from "utils/money";

const createData = () => {
  return { action: "", start: "", end: "", cost: "", responsible: "" };
};

const DIMENSIONS = {
  vision: {
    action_tooltip: (
      <p>
        As ações são as atividades que devem ser realizadas
        <br /> para atingir os objetivos específicos. Toda ação possui
        <br /> um responsável por desenvolvê-la e um prazo definido,
        <br /> podendo ter um custo.
        <br /> Exemplo: Elaborar diretrizes para o uso de tecnologias
        <br /> nas escolas.
      </p>
    ),
  },
  competence: {
    action_tooltip: (
      <p>
        As ações são as atividades que devem ser realizadas para
        <br /> atingir os objetivos específicos. Toda ação possui um responsável
        <br /> por desenvolvê-la e um prazo definido,
        <br /> podendo ter um custo. <br />
        Exemplo: Engajar os docentes da rede na realização de
        <br /> diagnóstico de competências digitais docentes.
      </p>
    ),
  },
  red: {
    action_tooltip: (
      <p>
        As ações são as atividades que devem ser realizadas
        <br /> para atingir os objetivos específicos. <br />
        Toda ação possui um responsável por desenvolvê-la e<br /> um prazo
        definido, podendo ter um custo. Exemplo: Analisar e <br />
        selecionar RED de recurso pedagógico para uso dos docentes nas escolas.
      </p>
    ),
  },
  infra: {
    action_tooltip: (
      <p>
        As ações são as atividades que devem ser realizadas
        <br /> para atingir os objetivos específicos. <br />
        Toda ação possui um responsável por desenvolvê-la <br />e um prazo
        definido, podendo ter um custo. <br />
        Exemplo: Calcular número de equipamentos a serem adquiridos.
      </p>
    ),
  },
};

function GoalActionsTable({
  editTableHookValues,
  columns,
  rows,
  tableKey,
  dimension,
  goalId,
}) {
  const {
    removeGoalActionFromDataBase,
    deleteActionFromState,
    saveGoalActionInDataBase,
    loadings,
    canEdit,
    register,
    setValue,
    addActionInState,
    changeCanEdit,
    deleteGoal,
    control,
    getCurrentPlan,
    cancelActionEdit,
  } = editTableHookValues;

  const tableRef = useRef();

  useEffect(() => {
    rows?.forEach((row, index) => {
      Object.keys(row).forEach((key) => {
        setValue(`goal_${tableKey}_row_${index}.${key}`, row[key]);
      });
    });
  }, [setValue, rows, tableKey]);

  return (
    <>
      <TableContainer ref={tableRef}>
        <S.StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) =>
                column === "AÇÃO" ? (
                  <TableCell key={column}>
                    <div>
                      {column}
                      <S.StyledHelpIcon
                        data-tip=""
                        data-for={`action_${dimension}`}
                      />
                      <ReactTooltip
                        effect="solid"
                        id={`action_${dimension}`}
                        aria-haspopup="true"
                      >
                        {DIMENSIONS[dimension]?.action_tooltip}
                      </ReactTooltip>
                    </div>
                  </TableCell>
                ) : (
                  <TableCell key={column}>{column}</TableCell>
                )
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows?.map((row, index) => (
              <TableRow key={`goal-${tableKey}_row-${index}`}>
                {Object.keys(createData()).map((key) => (
                  <TableCell key={key}>
                    {canEdit[tableKey] &&
                      canEdit[tableKey][index] &&
                      (key === "start" || key === "end") && (
                        <Controller
                          name={`goal_${tableKey}_row_${index}.${key}`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field: { onChange, value } }) => (
                            <DatePicker
                              onChange={(event) => {
                                onChange(event);
                                saveGoalActionInDataBase(index, tableKey, true);
                              }}
                              selected={value && dayjs(value).toDate()}
                              showMonthDropdown
                              showYearDropdown
                              dropdownMode="select"
                            />
                          )}
                        />
                      )}

                    {canEdit[tableKey] &&
                      canEdit[tableKey][index] &&
                      key !== "start" &&
                      key !== "end" &&
                      key === "cost" && (
                        <S.StyledTextField
                          {...register(`goal_${tableKey}_row_${index}.${key}`, {
                            required: true,
                          })}
                          variant="standard"
                          type="number"
                          inputProps={{ min: 0 }}
                          onBlur={() => saveGoalActionInDataBase(index, tableKey, true)}
                        />
                      )}

                    {canEdit[tableKey] &&
                      canEdit[tableKey][index] &&
                      key !== "start" &&
                      key !== "end" &&
                      key !== "cost" &&
                      key !== "action" && (
                        <Controller
                          name={`goal_${tableKey}_row_${index}.${key}`}
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => (
                            <Select
                              {...field}
                              menuPortalTarget={tableRef.current}
                              onBlur={() => saveGoalActionInDataBase(index, tableKey, true)}
                              options={getCurrentPlan()?.general_data?.responsible.map(
                                (resp) => ({
                                  label: resp?.name,
                                  value: resp?.name,
                                })
                              )}
                            />
                          )}
                        />
                      )}

                    {canEdit[tableKey] &&
                      canEdit[tableKey][index] &&
                      key !== "start" &&
                      key !== "end" &&
                      key !== "cost" &&
                      key !== "responsible" && (
                        <S.StyledTextField
                          {...register(`goal_${tableKey}_row_${index}.${key}`, {
                            required: true,
                          })}
                          variant="standard"
                          onBlur={() => saveGoalActionInDataBase(index, tableKey, true)}
                        />
                      )}

                    {canEdit[tableKey] &&
                      !canEdit[tableKey][index] &&
                      key !== "action" &&
                      key !== "responsible" &&
                      key !== "cost" && (
                        <p>{dayjs(row[key]).format("DD/MM/YYYY")}</p>
                      )}

                    {canEdit[tableKey] &&
                      !canEdit[tableKey][index] &&
                      key === "responsible" && <p>{row[key].value}</p>}

                    {canEdit[tableKey] &&
                      !canEdit[tableKey][index] &&
                      key === "cost" && <p>{convertToMoneyFormat(row[key])}</p>}

                    {canEdit[tableKey] &&
                      !canEdit[tableKey][index] &&
                      key === "action" && <p>{`${index + 1}. ${row[key]}`}</p>}
                  </TableCell>
                ))}
                <TableCell>
                  {canEdit[tableKey][index] &&
                    (loadings[tableKey][index] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <S.StyledCheckIcon
                          onClick={() => {
                            saveGoalActionInDataBase(index, tableKey);
                          }}
                        />
                        <S.StyledCloseIcon
                          onClick={() => cancelActionEdit(index, tableKey)}
                        />
                      </>
                    ))}
                  {!canEdit[tableKey][index] &&
                    (loadings[tableKey][index] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <S.StyledEditIcon
                          onClick={() => changeCanEdit(index, tableKey, true)}
                        />
                        <S.StyledDeleteIcon
                          onClick={() => {
                            removeGoalActionFromDataBase(index, tableKey);
                            deleteActionFromState(index, tableKey);
                          }}
                        />
                      </>
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </S.StyledTable>
      </TableContainer>
      <S.ButtonsWrapper>
        <S.Button onClick={() => addActionInState(createData(), tableKey)}>
          Adicionar
        </S.Button>
        <S.Button onClick={() => deleteGoal(tableKey, goalId)}>
          <DeleteIcon /> Excluir Objetivo
        </S.Button>
      </S.ButtonsWrapper>
    </>
  );
}

GoalActionsTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
};

export default GoalActionsTable;
