import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import SceneRed from "assets/images/jornada-red/cena_red.svg";
import FourLeafClover from "shared-components/FourLeafClover";
import image01 from "assets/images/red-demands/01.svg";
import image04 from "assets/images/red-demands/04.svg";

import image05 from "assets/images/red-demands/05.svg";

import image07 from "assets/images/red-demands/07.svg";
import image09 from "assets/images/red-demands/09.svg";
import image18 from "assets/images/red-demands/018.svg";

import image19 from "assets/images/red-demands/019.svg";
import image20 from "assets/images/red-demands/020.svg";
import { useNavigate } from "react-router-dom";
import { useGetAnswersQuery } from "services/red-demands";
import { useSurveyListQuery } from "services/common";
import { getSchoolSurveySchedules } from "utils/surveys";
import { isAdminCity, isAdminState, isPrincipal } from "utils/users";

const STATUS = {
  Completed: {
    label: "VER A DEVOLUTIVA",
    route: "/demanda-de-red/resultados-da-escola",
  },
  Started: {
    label: "CONTINUAR O QUESTIONÁRIO",
    route: "/demanda-de-red/diagnostico-da-escola",
  },
};

export default function REDDemands() {
  const navigate = useNavigate();
  const [surveyScheduleId, setSurveyScheduleId] = useState("");

  const { data: surveyList, isSuccess: surveyListSuccess } =
    useSurveyListQuery();

  useEffect(() => {
    if (surveyListSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      setSurveyScheduleId(schoolSchedules[0]?.id?.$oid);
    }
  }, [surveyList, surveyListSuccess]);

  const { data: getAnswers } = useGetAnswersQuery(surveyScheduleId);

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
          <section>
            <p>
              Com este questionário, você irá descobrir quais tipos de
              <br /> Recursos Educacionais Digitais são mais prioritários para
              sua rede
            </p>
          </section>
        </S.BannerWrapper>

        <section>
          <div>
            <h1>O que é este formulário?</h1>
            <p>
              A Secretaria de Educação está iniciando o processo de seleção e
              aquisição de Recursos Educacionais Digitais (REDs) que atendam às
              necessidades da rede de ensino. Por isso, preparamos este
              formulário para mapear e quantificar as demandas de cada escola.
            </p>

            <p>
              Recursos Educacionais Digitais, os REDs, são conteúdos,
              ferramentas e/ou plataformas em formato digital para fins
              educacionais (pedagógicos e/ou administrativos), que facilitam,
              potencializam e apoiam as atividades de docentes, estudantes e
              gestores(as).
            </p>
          </div>
          <div>
            <img src={image01} alt="" />
          </div>
        </section>

        <section>
          <div>
            <img src={image18} alt="" />
            <h2>Responda ao questionário</h2>
            <p>
              Orientamos que a equipe escolar se reúna para responder a este
              questionário a fim de avaliar como a escola está hoje e como é
              possível enxergá-la no futuro. Ao final do questionário, as
              respostas serão enviadas automaticamente à secretaria de educação
              e você receberá uma devolutiva apresentando o resultado das suas
              respostas.
            </p>

            <p>Contamos com a sua participação!</p>
          </div>
        </section>

        <section>
          <div>
            <div>
              <img src={image19} alt="" />
              <img src={image20} alt="" />
            </div>
            <div>
              <h1>Orientação de preenchimento</h1>

              <ol>
                <li>
                  Apesar de um único membro da equipe gestora ser responsável
                  pelo preenchimento deste instrumento, o processo de
                  identificação da demanda deve refletir a visão de toda a
                  equipe da escola.
                </li>

                <li>
                  Antes de preenchê-lo, realize discussões entre coordenadores e
                  professores a respeito das necessidades a escola com relação a
                  Recursos Educacionais Digitais. Para isso, baixe aqui o
                  questionário em PDF antes de iniciar o preenchimento.
                </li>
              </ol>
            </div>
          </div>
        </section>
        <section>
          <h1>ETAPAS DA FERRAMENTA</h1>
          <img src={image04} alt="" />

          <div>
            <div>
              <header>
                <span>1</span>
                <img src={image05} alt="" />
              </header>
              <main>
                <p>
                  Com o questionário, converse com os professores e
                  coordenadores da sua escola
                </p>
              </main>
            </div>
            <div>
              <header>
                <span>2</span>
                <img src={image07} alt="" />
              </header>
              <main>
                <p>Responda ao questionário</p>
              </main>
            </div>
            <div>
              <header>
                <span>3</span>
                <img src={image09} alt="" />
              </header>
              <main>
                <p>Acesse a devolutiva da sua escola</p>
              </main>
            </div>
          </div>
        </section>

        <section>
          {isPrincipal() && (
            <button
              onClick={() => {
                navigate(
                  STATUS[getAnswers?.status]?.route || STATUS["Started"]?.route
                );
              }}
            >
              {STATUS[getAnswers?.status]?.label || "INICIAR QUESTIONÁRIO"}
            </button>
          )}

          {(isAdminCity() || isAdminState()) && (
            <button
              onClick={() => navigate("/demanda-de-red/diagnostico-da-escola")}
            >
              INICIAR QUESTIONÁRIO
            </button>
          )}
        </section>

        <S.JourneySection>
          <div>
            <div>
              <FourLeafClover width={140} dimension="red" />
              <h1>Conheça a Jornada completa da dimensão RED</h1>
              <p>
                Confira conceitos, ferramentas e dicas para desenvolver Escolas
                Conectadas.
              </p>
            </div>
            <div>
              <img src={SceneRed} alt="" />
            </div>
          </div>
        </S.JourneySection>
      </S.Wrapper>
    </Layout>
  );
}
