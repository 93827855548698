import React, { useEffect, useState } from "react";

import * as S from "./styles";
import { getStorageAuthenticityToken } from "utils/local-storage";
import axios from "axios";
import Layout from "shared-components/Layout";
import CheckIcon from "@mui/icons-material/Check";
import EditIcon from "@mui/icons-material/Edit";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import image011 from "assets/images/calculadora/011.svg";
import InfraCalculatorFilter from "pages/InfrastructureCalculator/components/InfraCalculatorFilter";
import { useInfraCalculatorFilter } from "hooks/use-infra-calculator-filter";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { Survey, Schedule } from "../InfraCalculatorReferenceValues/index";
const KEYS_NOT_EDIT = ["inep_code", "name", "state_name", "city_name", "type"];

const KEYS = [
  "inep_code",
  "name",
  "state_name",
  "city_name",
  "type",
  "numbers_of_rooms",
  "total_classes_in_school.diurnal",
  "total_classes_in_school.vespertine",
  "total_classes_in_school.nocturnal",
  "total_classes_in_school.full",
  "students_in_largest_class.diurnal",
  "students_in_largest_class.vespertine",
  "students_in_largest_class.nocturnal",
  "students_in_largest_class.full",
  "student_diurnal_count",
  "student_vespertine_count",
  "student_nocturnal_count",
  "student_full_count",
  "switch",
  "wifi",
  "comp_students",
  "firewall",
  "rack",
  "nobreak",
  "comp_admins",
  "printers",
  "comp_teachers",
  "charger",
  "projector",
  "maker",
];

const CONF = process.env.REACT_APP_BASE_URL;

export interface Inventory {
  [key: string]: {
    total_classes_in_school: {
      diurnal: number;
      vespertine: number;
      nocturnal: number;
      full: number;
    };
    students_in_largest_class: {
      diurnal: number;
      vespertine: number;
      nocturnal: number;
      full: number;
    };
    firewall: number;
    switch: number;
    rack: number;
    nobreak: number;
    wifi: number;
    comp_admins: number;
    printers: number;
    comp_teachers: number;
    comp_students: number;
    charger: number;
    projector: number;
  };
}

export interface School {
  _id: { $oid: string };
  city_name?: string;
  inep_code: string;
  inventory?: Inventory;
  name: string;
  numbers_of_rooms?: number;
  state_name?: string;
  student_diurnal_count?: number;
  student_full_count?: number;
  student_nocturnal_count?: number;
  student_vespertine_count?: number;
  type?: string;
}

export interface SchoolOrganized extends School {
  [key: string]: any;
  "total_classes_in_school.diurnal"?: number;
  "total_classes_in_school.vespertine"?: number;
  "total_classes_in_school.nocturnal"?: number;
  "total_classes_in_school.full"?: number;
  "students_in_largest_class.diurnal"?: number;
  "students_in_largest_class.vespertine"?: number;
  "students_in_largest_class.nocturnal"?: number;
  "students_in_largest_class.full"?: number;
}

export default function InfraCalculatorNetworkData() {
  const [schools, setSchools] = useState<SchoolOrganized[]>([]);
  const [schoolsBackup, setSchoolsBackup] = useState<SchoolOrganized[]>([]);
  const [localSchools, setLocalSchools] = useState<SchoolOrganized[]>([]);

  const [schoolsData, setSchoolsData] = useState<School[]>([]);
  // const [isLoading, setIsLoading] = useState(true);
  const [canEdit, setCanEdit] = useState<boolean[]>([]);
  // const [schedule_id, setSchedule_id] = useState();
  // const [surveys, setSurveys] = useState();
  const [changedLines, setChangedLines] = useState<number[]>([]);
  const [dataIsLoading, setDataIsLoading] = useState(true);
  const [saveIsLoading, setSaveIsLoading] = useState<boolean[]>([]);
  // const [showModal, setShowModal] = useState();
  const [haveInventory, setHaveInventory] = useState<number>();
  const [cicles, setCicles] = useState();
  const [cicle, setCicle] = useState();

  const navigate = useNavigate();

  const { setSelectedCycle, selectedCycle, stageSelected, setLastCycles } =
    useInfraCalculatorFilter();

  const handleChange = (
    index: number,
    dataType: string,
    value: string,
    inepCode: string
  ) => {
    const schoolsAux = [...schools];
    const school = schoolsAux.find((school) => school.inep_code === inepCode);
    if (school) {
      school[dataType] = parseInt(value);
    }

    const localSchoolsAux = [...localSchools];
    const localSchool = localSchoolsAux.find(
      (school) => school.inep_code === inepCode
    );
    if (localSchool) {
      localSchool[dataType] = parseInt(value);
    }

    console.log(localSchool);

    setSchools(schoolsAux);
    setLocalSchools(localSchoolsAux);
    setChangedLines([...new Set([...changedLines, index])]);
  };

  const handleTextFilter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.toLowerCase();

    if (value === "") {
      setLocalSchools(schools);
    } else {
      const schoolsFiltered = schools.filter(
        (school) =>
          school?.inep_code?.toLowerCase().includes(value) ||
          school?.name?.toLowerCase().includes(value) ||
          school?.city_name?.toLowerCase().includes(value)
      );
      setLocalSchools(schoolsFiltered);
    }
  };

  const organizeSchoolData = useCallback(
    (schools: School[]): SchoolOrganized[] => {
      const data = [];
      let haveInventory = 0;
      for (let school of schools) {
        if (
          school.inventory &&
          school.inventory[selectedCycle.value] &&
          selectedCycle.value
        ) {
          haveInventory += 1;
          const { total_classes_in_school, students_in_largest_class } =
            school.inventory[selectedCycle.value];

          data.push({
            ...school,
            ...school.inventory[selectedCycle.value],
            "total_classes_in_school.diurnal": total_classes_in_school.diurnal,
            "total_classes_in_school.vespertine":
              total_classes_in_school.vespertine,
            "total_classes_in_school.nocturnal":
              total_classes_in_school.nocturnal,
            "total_classes_in_school.full": total_classes_in_school.full,
            "students_in_largest_class.diurnal":
              students_in_largest_class.diurnal,
            "students_in_largest_class.vespertine":
              students_in_largest_class.vespertine,
            "students_in_largest_class.nocturnal":
              students_in_largest_class.nocturnal,
            "students_in_largest_class.full": students_in_largest_class.full,
          });
        } else {
          data.push({ ...school });
        }
      }
      setHaveInventory(haveInventory);
      return data;
    },
    [selectedCycle.value]
  );

  const getSurveysList = useCallback(async () => {
    try {
      const params = { access_token: getStorageAuthenticityToken() };
      const response = await axios.get(CONF + "/api/v1/survey/surveys_list", {
        params,
      });

      const survey = response.data.surveys.find(
        (survey: Survey) => survey.type === "school"
      );

      const cicles = survey.schedule.map((cicle: Schedule) => ({
        value: cicle.id.$oid,
        label: cicle.name,
      }));

      // setSchedule_id(cicles[0].value);
      setLastCycles({ last: false, penultimate: true });

      setSelectedCycle(cicles[0]);
      setCicles(cicles);
      setCicle(cicles[0]);
    } catch (error: any) {
      console.log(error.message);
    }
  }, [setSelectedCycle, setLastCycles]);

  useEffect(() => {
    getSurveysList();
  }, [getSurveysList]);

  const getSchools = useCallback(async () => {
    // setIsLoading(true);
    setDataIsLoading(true);
    try {
      const params = {
        access_token: getStorageAuthenticityToken(),
        stage: stageSelected,
      };
      const response = await axios.get(CONF + "/api/v1/calc_infra_schools", {
        params,
      });
      const schools = organizeSchoolData(response.data);
      setSchoolsData(response.data);
      setLocalSchools(schools);
      setSchools(schools);
      setSchoolsBackup(schools);
      setDataIsLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  }, [organizeSchoolData, stageSelected]);

  useEffect(() => {
    getSchools();
  }, [selectedCycle.value, cicles, cicle, getSchools]);

  const updateInventoryData = useCallback(() => {
    setHaveInventory(0);
    setDataIsLoading(true);

    const schools = organizeSchoolData(schoolsData);
    setSchools(schools);
    setSchoolsBackup(schools);
    setDataIsLoading(false);
  }, [organizeSchoolData, schoolsData]);

  const changeCanEdit = (index: number) => {
    const candEditAux = [...canEdit];
    candEditAux[index] = !candEditAux[index];
    setCanEdit(candEditAux);
  };

  const joinSchoolInventoryData = (school: SchoolOrganized) => {
    console.log(school);
    const data = {
      schedule_id: selectedCycle.value,
      numbers_of_rooms: school.numbers_of_rooms,
      student_diurnal_count: school.student_diurnal_count,
      student_vespertine_count: school.student_vespertine_count,
      student_nocturnal_count: school.student_nocturnal_count,
      student_full_count: school.student_full_count,
      inventory: {
        total_classes_in_school: {
          diurnal: school["total_classes_in_school.diurnal"],
          vespertine: school["total_classes_in_school.vespertine"],
          nocturnal: school["total_classes_in_school.nocturnal"],
          full: school["total_classes_in_school.full"],
        },
        students_in_largest_class: {
          diurnal: school["students_in_largest_class.diurnal"],
          vespertine: school["students_in_largest_class.vespertine"],
          nocturnal: school["students_in_largest_class.nocturnal"],
          full: school["students_in_largest_class.full"],
        },
        firewall: school.firewall,
        switch: school.switch,
        rack: school.rack,
        nobreak: school.nobreak,
        wifi: school.wifi,
        comp_admins: school.comp_admins,
        printers: school.printers,
        comp_teachers: school.comp_teachers,
        comp_students: school.comp_students,
        charger: school.charger,
        projector: school.projector,
        maker: school.maker,
      },
    };

    return data;
  };

  const cancelSchoolInfraData = (index: number) => {
    changeCanEdit(index);
    schools[index] = schoolsBackup[index];
    setSchools(schools);
  };

  const saveSchoolInfraData = async (
    school: SchoolOrganized,
    index: number
  ) => {
    changeCanEdit(index);

    // Verifica se algum dado da linha realmente foi alterado
    if (!changedLines.includes(index)) return;

    setChangedLines(changedLines.filter((line) => line !== index));
    const schoolId = school._id.$oid;
    const data = joinSchoolInventoryData(school);
    try {
      saveIsLoading[index] = true;
      setSaveIsLoading(saveIsLoading);
      await axios.post(
        CONF +
          `/api/v1/school_inventory/${schoolId}.json?access_token=${getStorageAuthenticityToken()}`,
        data
      );
      saveIsLoading[index] = false;
      setSaveIsLoading(saveIsLoading);
      getSchools();
    } catch (error: any) {
      saveIsLoading[index] = false;
      setSaveIsLoading(saveIsLoading);
      // setShowModal(true);
      changeCanEdit(index);
      cancelSchoolInfraData(index);
      console.log(error.message);
    }
  };

  useEffect(() => {
    updateInventoryData();
  }, [cicle, selectedCycle.value, updateInventoryData]);

  return (
    <Layout hasNavigationBar name="infraCalculator">
      <S.Wrapper>
        <header></header>

        <div className={"content"}>
          <article>
            <div className={"description"}>
              <p>
                No quadro abaixo você irá visualizar os dados das escolas de sua
                rede. Nele constam todas as informações das escolas que
                responderam ao diagnóstico do Guia EduTec.
              </p>
              <p>
                Caso deseje, nessa tela você poderá editar os dados das escolas.
                Após a edição, é só clicar em continuar para ir para a próxima
                tela da ferramenta!
              </p>

              <div>
                <div>
                  <div>
                    <p>
                      <span>{haveInventory}</span>
                      <span>/{schoolsData.length}</span> ESCOLAS DA SUA REDE
                    </p>
                  </div>
                  <hr />
                  <div>
                    <p>
                      <span>responderam</span> o inventário de infraestrutura do
                      diagnóstico do Guia Edutec 2022
                    </p>
                  </div>
                </div>
                <div>
                  <img src={image011} alt="" />
                </div>
              </div>

              <div className="filter">
                {cicles && <InfraCalculatorFilter cycles={cicles} />}
                <S.SearchInput>
                  <input
                    type="text"
                    onChange={handleTextFilter}
                    placeholder="Pesquise pelo INEP, nome da escola ou município"
                  />
                  <SearchIcon fontSize="inherit" />
                </S.SearchInput>
              </div>

              <div>
                <h1>Baixar dados da rede</h1>
                <p>
                  Faça o download de uma planilha com os dados da rede de acordo
                  com os filtros selecionados.
                </p>
                <a
                  download
                  href={
                    CONF +
                    `/api/v1/calc_infra_export_network_data?access_token=${getStorageAuthenticityToken()}&stage=${
                      stageSelected || ""
                    }&survey_schedule_id=${selectedCycle.value}`
                  }
                >
                  Baixar
                </a>
              </div>
            </div>

            {dataIsLoading ? (
              <div className={"loading"}>
                <CircularProgress size={40} />
              </div>
            ) : (
              <div className="columns">
                <div className={"table"}>
                  <table>
                    <thead>
                      <tr>
                        <th colSpan={7}></th>
                        <th colSpan={4} style={{ textAlign: "center" }}>
                          Total de turmas por turno
                        </th>
                        <th colSpan={4} style={{ textAlign: "center" }}>
                          Número de estudantes da maior turma por período
                        </th>
                        <th colSpan={4} style={{ textAlign: "center" }}>
                          Alunos por turno
                        </th>
                        <th colSpan={3} style={{ textAlign: "center" }}>
                          Dispositivos mínimos
                        </th>
                        <th colSpan={4}></th>
                      </tr>

                      <tr>
                        <th></th>
                        <th>INEP</th>
                        <th className={"school_column"}>ESCOLA</th>
                        <th>ESTADO</th>
                        <th>MUNICÍPIO</th>
                        <th>REDE</th>
                        <th>Número de salas</th>
                        <th>Manhã</th>
                        <th>Tarde</th>
                        <th>Noite</th>
                        <th>Integral</th>
                        <th>Manhã</th>
                        <th>Tarde</th>
                        <th>Noite</th>
                        <th>Integral</th>
                        <th>Manhã</th>
                        <th>Tarde</th>
                        <th>Noite</th>
                        <th>Integral</th>
                        <th>Switch Layer 3</th>
                        <th>Wifi (acess point)</th>
                        <th>Kit Laptop (computador estudantes)</th>
                        <th>Firewall</th>
                        <th>Rack 6U</th>
                        <th>Nobreak</th>
                        <th>Comput. Admin</th>
                        <th>Impres. Multifunc.</th>
                        <th>Computador Professores</th>
                        <th>Carrinho de carregamento</th>
                        <th>Projetor Multimidia</th>
                        <th>Kit Robótica</th>
                      </tr>
                    </thead>

                    <tbody>
                      {localSchools?.map((school, index) => (
                        <tr key={index}>
                          <td>
                            {saveIsLoading[index] ? (
                              <p>Loading</p>
                            ) : // <PulseLoader color="#131D3C" size={8} />
                            canEdit[index] ? (
                              <div>
                                <CheckIcon
                                  fontSize="large"
                                  onClick={() =>
                                    saveSchoolInfraData(school, index)
                                  }
                                />

                                <CloseIcon
                                  fontSize="large"
                                  onClick={() => cancelSchoolInfraData(index)}
                                />
                              </div>
                            ) : (
                              <button onClick={() => changeCanEdit(index)}>
                                <EditIcon fontSize="large" />
                              </button>
                            )}
                          </td>
                          {KEYS.map((key: string) => (
                            <td key={key}>
                              {canEdit[index] &&
                              !KEYS_NOT_EDIT.includes(key) ? (
                                <input
                                  defaultValue={school[key]}
                                  type="number"
                                  onChange={(e) =>
                                    handleChange(
                                      index,
                                      key,
                                      e.target.value,
                                      school.inep_code
                                    )
                                  }
                                />
                              ) : (
                                school[key]
                              )}
                            </td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </article>
          <div className={"start"}>
            <button onClick={() => navigate("/calculadora-de-infraestrutura")}>
              VOLTAR PARA O PAINEL INICIAL
            </button>
            <button
              onClick={() =>
                navigate("/calculadora-de-infraestrutura/valores-de-referencia")
              }
            >
              CONTINUAR
            </button>
          </div>
        </div>

        {/* <Modal
          isActive={showModal}
          title={"Erro"}
          closeModal={() => setState({ showModal: false })}
          children={
            <div className={styles.modal_infra}>
              <p>Ocorreu um erro ao salvar os dados!</p>
            </div>
          }
        /> */}
      </S.Wrapper>
    </Layout>
  );
}
