import React from "react";
import * as S from "./styles";

const QuestionItem = React.forwardRef(
  (
    {
      id,
      img,
      imgActive,
      children,
      onChange,
      onBlur,
      name,
      selected,
      onClick,
      isDisabled,
    },
    ref
  ) => {
    return (
      <S.Wrapper key={id} id={id} selected={selected} onClick={onClick}>
        <div>
          <img src={selected ? imgActive : img} alt="" />
        </div>
        <div>
          <input
            disabled={isDisabled}
            type="checkbox"
            name={name}
            ref={ref}
            onChange={onChange}
            onBlur={onBlur}
          />
          <label>{children}</label>
        </div>
      </S.Wrapper>
    );
  }
);

export default QuestionItem;
