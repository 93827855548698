import React, { useEffect, useState } from "react";
import * as S from "./styles";
import CircularProgress from "@mui/material/CircularProgress";
import mainVideo from "assets/videos/home.mp4";
import useScrollToElement from "hooks/use-scroll-to-element";
import { useSignInMutation } from "services/common";
import {
  setStorageAuthenticityToken,
  setStorageInstitutionId,
  setStorageSchoolId,
  setStorageUserId,
  setStorageUserName,
  setStorageProfile,
} from "utils/local-storage";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "shared-components/Layout";
import { useURLQuery } from "utils/routes";
import { logout } from "utils/login-logout";

const schema = yup
  .object({
    email: yup
      .string()
      .required("Campo obrigatório !")
      .email("Digite um e-mail válido !"),
    password: yup
      .string()
      .required("Campo obrigatório !")
      .min(6, "A senha precisa ter no mínimo 6 caracteres !"),
  })
  .required("Campo obrigatório !");

export default function SignIn() {
  useScrollToElement();
  const signIn = useSignInMutation();
  const { data, isLoading, isSuccess } = signIn;
  const [showPassword, setShowPassword] = useState(false);
  const logoutParam = useURLQuery().get("logout");

  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    signIn.mutate(data);
  };

  useEffect(() => {
    if (!!logoutParam) {
      logout();
      window.close();
    }
  }, [logoutParam]);

  useEffect(() => {
    const setStorage = async () => {
      if (isSuccess) {
        localStorage.clear();
        setStorageAuthenticityToken(data?.authenticity_token);
        data?.institution_id &&
          setStorageInstitutionId(data?.institution_id?.$oid);
        data?.school_id && setStorageSchoolId(data?.school_id?.$oid);
        setStorageUserName(data?.name);
        setStorageUserId(data?._id?.$oid);
        setStorageProfile(data?._profile);
        if (location?.state?.pathname) {
          navigate(location?.state?.pathname);
        } else {
          navigate("/");
        }
      }
    };

    setStorage();
  }, [isSuccess, data, navigate, location]);

  return (
    <Layout>
      <S.Wrapper>
        <section>
          <div>
            <S.Heading>GUIA EDUTEC</S.Heading>
          </div>
          <div>
            <video loop={true} autoPlay={true} muted={true} src={mainVideo}>
              <source src={mainVideo} type="video/mp4" />
            </video>
          </div>
        </section>
        <section>
          <S.Title>Faça seu login na plataforma</S.Title>
          <S.SignInForm onSubmit={handleSubmit(onSubmit)}>
            <div>
              <S.StyledEmailIcon />
              <input placeholder="E-mail" {...register("email")} />
            </div>
            <S.ErrorArea>
              <p>{errors.email?.message}</p>
            </S.ErrorArea>
            <div>
              <S.StyledLockIcon />
              <input
                placeholder="Senha"
                type={showPassword ? "text" : "password"}
                {...register("password")}
              />
              {showPassword && (
                <S.StyledVisibilityIcon
                  onClick={() => setShowPassword(false)}
                />
              )}
              {!showPassword && (
                <S.StyledVisibilityOffIcon
                  onClick={() => setShowPassword(true)}
                />
              )}
            </div>
            <S.ErrorArea>
              <p>{errors.password?.message}</p>
            </S.ErrorArea>
            <p>
              <a href="/">Esqueci minha senha</a>
            </p>
            <button type="submit">
              {isLoading ? <CircularProgress size={30} /> : "ENTRAR"}
            </button>
            <p>
              Não tem uma conta? <a href="/">Registre-se</a>
            </p>
          </S.SignInForm>
        </section>
      </S.Wrapper>
    </Layout>
  );
}
