import styled from "styled-components";

export const Wrapper = styled.div`
  flex: 1 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  border-radius: 1rem;
  line-height: 1.5;
  outline: ${({ priorities }) =>
    priorities.indexOf("enabled") !== -1 && "0.3rem solid #1960b1"};
  /* max-width: 28rem; */
  max-height: 80rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  cursor: pointer;

  > header {
    display: flex;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gray5};
    padding: 4rem;
    border-radius: 1rem 1rem 0rem 0rem;
  }

  > main {
    padding: 4rem;
    flex: 1;
    > p {
      line-height: 1.5;
      font-size: 1.6rem;
      font-weight: 500;
    }
  }

  > footer {
    padding: 2rem 4rem 4rem 4rem;
    position: relative;
    height: 14rem;
    &::before {
      content: "";
      position: absolute;
      width: 8rem;
      height: 0.4rem;
      background-color: #303652;
      top: 0;
      left: 4rem;
    }

    > h2 {
      font-weight: 500;
      font-size: 1.6rem;
    }
  }
`;

const STATUS = {
  initial: "#303652",
  internalDisabled: "#dcdcdc",
  disabled: "#dcdcdc",
  enabled: "#1960b1",
};

export const Priority = styled.li`
  width: 2.6rem;
  height: 2.6rem;
  background-color: ${({ status }) => STATUS[status] ?? STATUS.disabled};
  color: ${({ theme }) => theme.colors.white};
  display: grid;
  place-items: center;
  border-radius: 50%;
`;

const isSelected = (priority) => {
  priority = priority.filter((value) => !isNaN(value));
  const aux = priority.map((value) => `:nth-child(${value})`);
  const selector = `li:not(${aux.join(",")})`;
  return selector;
};

export const Priorities = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 1rem;
  justify-content: center;

  > ${({ priority }) => isSelected(priority)} {
    background-color: ${({ status }) =>
      status === "disabled" && STATUS.initial};
  }

  > li:nth-child(${({ status }) => status}) {
    background-color: ${STATUS.enabled};
    color: ${({ theme }) => theme.colors.white};
  }
`;
