import { useQuery } from "@tanstack/react-query";
import { API_VERSION, instance as axios } from "services";
import { setStorageUpdateDate } from "utils/local-storage";

const useInNumbersQueryComparative = (
  aggregation,
  type = null,
  stateId = null,
  cityId = null,
  comparative1,
  comparative2
) => {
  let params;

  // REDE

  if (type === "Municipal" && cityId) {
    params = {
      aggregation: "rede",
      type,
      city_id: cityId,
    };
  }

  if (type === "Estadual" && stateId && !cityId) {
    params = {
      aggregation: "rede",
      type,
      state_id: stateId,
    };
  }

  // ESTADO

  if (aggregation === "estado" && stateId) {
    params = { aggregation: "estado", type, state_id: stateId };
  }

  if (["estadual", "municipal"].includes(aggregation)) {
    params = { aggregation };
  }

  return useQuery(
    [
      "inNumbersComparative",
      aggregation,
      cityId,
      stateId,
      type,
      comparative1,
      comparative2,
    ],
    async () => {
      const { data } = await axios.get(`${API_VERSION}/in_numbers`, {
        params,
      });
      return data;
    },
    {
      enabled:
        (!!aggregation || !!type || !cityId || !!stateId) &&
        (!!comparative1 || !!comparative2),
    }
  );
};

const useInNumbersQuery = (type = null, stateId = null, cityId = null) => {
  let params;

  // NACIONAL

  if (!["Estadual", "Municipal"].includes(type) && !stateId && !cityId) {
    params = { aggregation: "nacional" };
  }

  // ESTADUAL

  if (type === "Estadual" && !stateId && !cityId) {
    params = { aggregation: "estadual" };
  }

  // MUNICIPAL

  if (type === "Municipal" && !stateId && !cityId) {
    params = { aggregation: "municipal" };
  }

  // REDE

  if (type === "Estadual" && stateId && !cityId) {
    params = {
      aggregation: "rede",
      type,
      state_id: stateId,
    };
  }

  if (type === "Municipal" && stateId && cityId) {
    params = {
      aggregation: "rede",
      type,
      city_id: cityId,
    };
  }

  // CIDADE

  if (type === "Estadual" && cityId) {
    params = { aggregation: "cidade", type, city_id: cityId };
  }

  // ESTADO

  if (type === "Municipal" && stateId && !cityId) {
    params = { aggregation: "estado", type, state_id: stateId };
  }

  // GERAL

  if (["Todos"].includes(type) && stateId && !cityId) {
    params = {
      aggregation: "geral",
      state_id: stateId,
    };
  }

  if (["Todos"].includes(type) && stateId && cityId) {
    params = {
      aggregation: "geral",
      city_id: cityId,
    };
  }

  return useQuery(
    ["inNumbers", cityId, stateId, type],
    async () => {
      const { data } = await axios.get(`${API_VERSION}/in_numbers`, {
        params,
      });

      if (params.aggregation === "nacional") {
        setStorageUpdateDate(data.updated_at);
      }

      return data;
    },
    {
      enabled: !!type || !cityId || !!stateId,
    }
  );
};

const useMicrodataInfo = (type) => {
  return useQuery(
    ["inNumbers", type],
    async () => {
      const { data } = await axios.get(`${API_VERSION}/microdata_info`, {
        params: { type },
      });
      return data;
    },
    {
      enabled: !!type,
    }
  );
};

export { useInNumbersQuery, useInNumbersQueryComparative, useMicrodataInfo };
