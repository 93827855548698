import styled, { css } from "styled-components";

export const large = css`
  p:nth-child(2) {
    position: absolute;
    top: 30.4rem;
    right: 17.5rem;
    width: 11rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(3) {
    position: absolute;
    bottom: 19rem;
    right: 29.9rem;
    width: 9.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(4) {
    position: absolute;
    bottom: 30.3rem;
    left: 16.3rem;
    width: 11rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(5) {
    position: absolute;
    top: 24rem;
    left: 21.4rem;
    width: 8.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(6) {
    position: absolute;
    top: 40.6rem;
    left: 39.3rem;
    width: 15rem;
    text-align: center;
    font-size: 1.8rem;
  }
`;

export const Wrapper = styled.div`
  height: 94.3rem;
  width: 94.3rem;
  ${large}
  color: ${({ theme }) => theme.colors.white};

  > svg {
    .cls-4 {
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }

  > p {
    font-weight: 400;
  }

  > p:nth-last-child(1) {
    font-weight: 500;
    cursor: pointer;
  }
`;

export const Image = styled.img``;
