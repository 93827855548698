export const theme = {
  colors: {
    purple: "#713990",
    purple2: "#572E8A",
    purple3: "#914fe3",
    orange: "#e16714",
    blue: "#33a1c8",
    blue2: "#176dab",
    blue3: "#2e91b5",
    blue4: "#d9f0f9",
    blue5: "#131d3c",
    blue6: "#158CBD",
    green: "#85c440",
    green2: "#517828",
    green3: "#77b039",
    green4: "#ecf6e2",
    green5: "#85C23F",
    yellow: "#fad62b",
    yellow2: "#5f5210",
    yellow3: "#ddbd26",
    yellow4: "#fef8df",
    yellow5: "#7e7136",
    yellow6: "#e5cc65",
    pink: "#e62270",
    pink2: "#af0061",
    pink3: "#cf1f65",
    pink4: "#fbdee9",
    textGray: "#6d7170",
    textDarkGray: "#2c2c2c",
    gray: "#626262",
    gray98: "#fafafa",
    gray3: "#f4f4f4",
    gray4: "#ececec",
    gray5: "#f5f5f5",
    gray6: "#eef1f0",
    gray7: "#f6f7f9",
    gray8: "#e6e6e6",
    gray9: "#68706D",
    gray10: "#fefefe",
    gray11: "#f8f8f8",
    white: "#FFFFFF",
    black: "#303030",
    red: "#e42f16",
    warning: "#c24041",
  },
  fonts: {
    golos: "Golos",
    openSansLight: "Open Sans",
    firaSans: "Fira Sans",
    poppins: "Poppins",
  },
  fontSizes: {
    small: "1em",
    medium: "2em",
    large: "3em",
  },
};
