import React from "react";
import * as S from "./styles";

import image02 from "assets/images/plans/recursos/images/02.svg";
import image03 from "assets/images/plans/recursos/images/03.svg";
import image04 from "assets/images/plans/recursos/images/04.svg";
import image05 from "assets/images/plans/recursos/images/05.svg";
import image06 from "assets/images/plans/recursos/images/06.svg";
import {ReactComponent as Image07} from "assets/images/plans/recursos/images/07.svg";

import image08 from "assets/images/plans/recursos/images/08.svg";
import image09 from "assets/images/plans/recursos/images/09.svg";
import image10 from "assets/images/plans/recursos/images/010.svg";
import image11 from "assets/images/plans/recursos/images/011.svg";
import image12 from "assets/images/plans/recursos/images/012.svg";
import image13 from "assets/images/plans/recursos/images/013.svg";
import image14 from "assets/images/plans/recursos/images/014.svg";
import image15 from "assets/images/plans/recursos/images/015.svg";
import image16 from "assets/images/plans/recursos/images/016.svg";
import image17 from "assets/images/plans/recursos/images/017.svg";
import image18 from "assets/images/plans/recursos/images/018.svg";
import image19 from "assets/images/plans/recursos/images/019.svg";
import image20 from "assets/images/plans/recursos/images/020.svg";
import image21 from "assets/images/plans/recursos/images/021.svg";
import image22 from "assets/images/plans/recursos/images/022.svg";
import image23 from "assets/images/plans/recursos/images/023.svg";

export default function FourthStep() {
  return (
    <S.Wrapper>
      <h1 id="r-e-4">
        4. O CAMINHO ATÉ OS/AS ESTUDANTES: FASE DE IMPLEMENTAÇÃO E
        ACOMPANHAMENTO
      </h1>
      <p>
        Você já entendeu como selecionar, adotar e/ou adquirir o(s) RED que a
        rede irá utilizar. Agora é hora de saber como planejar a sua
        implementação, acompanhar se eles estão sendo utilizados, se as pessoas
        estão capacitadas para operá-lo(s) e se estão atingindo os objetivos a
        partir das demandas iniciais.
      </p>

      <p>
        Nesta etapa da jornada, você também vai entender a seguir os fatores
        fundamentais que garantem a utilização efetiva dos recursos.
      </p>

      <h2 id="r-e-4-s-1">4.1 Como colocar os RED em uso</h2>

      <p>
        É importante definir como será a disponibilização dos RED adquiridos nas
        unidades escolares, levando em consideração o tempo, a logística de
        cadastramento de usuários e a distribuição de licenças, além da
        formação/capacitação dos usuários.
      </p>

      <p>
        Combine com o fornecedor esses passos para agilizar a implementação.
      </p>

      <img alt="" src={image02} />
      <img alt="" src={image03} />
      <img alt="" src={image04} />
      <section>
        <img alt="" src={image05} />
        <img alt="" src={image06} />

      </section>

      <Image07/>

      <h2 id="r-e-4-s-2">
        4.2 Entenda a necessidade de oferecer formação para uso dos RED
      </h2>

      <p>
        É fundamental verificar se docentes e gestores/as escolares possuem as
        competências necessárias para fazer uso dos RED.
      </p>

      <p>
        Se você chegou nessa etapa e ainda não tem o diagnóstico das
        competências de seus/suas professores, recomendamos que você mobilize a
        rede para aplicar a Autoavaliação de Competências Digitais, pois as
        informações geradas pela ferramenta serão de extrema utilidade para
        verificar quais competências digitais eles/elas já possuem e quais
        precisam desenvolver por meio de programas de formação continuada ou
        apenas um treinamento para uso do(s) RED.
      </p>

      <p>
        Para a definição desses itens que envolvem a formação, considerem os
        seguintes elementos:
      </p>

      <img alt="" src={image08} />
      <img alt="" src={image09} />
      <img alt="" src={image10} />
      <img alt="" src={image11} />
      <img alt="" src={image12} />

      <h2 id="r-e-4-s-3">4.3 Utilize a comunicação a seu favor</h2>

      <p>
        Elabore estratégias de comunicação para incentivar o uso dos RED
        selecionados. Separamos estes cards para apoiar você nesse processo.
        Planeje também como será o suporte oferecido aos usuários, certifique-se
        que o fornecedor possa realizar essa tarefa se não tiver quem faça isso
        na secretaria. <strong>Conheça e adapte para sua realidade!</strong>
      </p>
      <section>
        <img alt="" src={image13} />
        <img alt="" src={image14} />
      </section>
      <img alt="" src={image15} />

      <h2 id="r-e-4-s-4">4.4 Suporte: dúvidas e desafios de implementação</h2>

      <p>
        A disponibilidade de suporte é um fator fundamental para a continuidade
        e engajamento dos usuários. Portanto, é importante definir o responsável
        pelo suporte e garantir sua disponibilidade desde o início do processo.
        O quadro a seguir exibe algumas alternativas para discussão e definição
        pelo grupo:
      </p>
      <section>
        <img alt="" src={image16} />
        <img alt="" src={image17} />
      </section>

      <h2 id="r-e-4-s-5">4.5 Defina indicadores para acompanhar</h2>

      <p>
        A definição de indicadores é um passo necessário para realizar o
        acompanhamento, a avaliação do uso e do impacto do(s) RED adotado(s).
      </p>

      <p>
        É importante ressaltar que os dados que serão monitorados deverão estar
        relacionados às demandas mapeadas no início desse processo (Etapa 2).
        Somente assim será possível medir a efetividade do uso previsto para os
        recursos.
      </p>

      <p>Veja aqui alguns exemplos de indicadores e suas funções:</p>

      <img alt="" src={image23} />

      <h2 id="r-e-4-s-6">
        4.6 Defina uma estratégia de coleta e frequência das informações
      </h2>

      <p>
        Além de definir um conjunto de indicadores para acompanhar, estabeleça
        também qual será a estratégia e a frequência de coleta dessas
        informações. Idealmente, esse processo deve ser feito de forma contínua
        para, assim, apoiar na tomada de qualquer decisão sobre os RED.
      </p>

      <p>
        Sendo assim, opte por indicadores, mesmo que sejam poucos, que você tem
        a certeza de que conseguirá acompanhar, medir e extrair informações de
        forma ininterrupta.
      </p>
      <section>
        <img alt="" src={image18} />
      </section>

      <p>Nesta etapa você deverá estabelecer:</p>

      <img alt="" src={image19} />
      <img alt="" src={image20} />
      <img alt="" src={image21} />
      <img alt="" src={image22} />

      <p>
        Com essa organização, será muito mais fácil elaborar relatórios de
        acompanhamento e saber se os REDs estão atendendo de forma satisfatória
        às necessidades da rede de ensino. Assim, você pode reavaliar as
        decisões tomadas nas etapas anteriores, definir novas estratégias e
        voltar para alguma etapa sempre que necessário.
      </p>

      <p>
        Após um ano de implementação, aplique o{" "}
        <a
          href="https://guiaedutec.com.br/escola"
          target="_blank"
          rel="noreferrer"
        >
          <i>questionário do Guia Edutec</i>
        </a>{" "}
        Diagnóstico e avalie se sua rede aumentou ou diminuiu sua nota na
        dimensão de RED.
      </p>
    </S.Wrapper>
  );
}
