import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/red-demands/banner.svg";

export const Wrapper = styled.div`
  margin: 7rem 0;
  display: flex;
  flex-direction: column;
  gap: 5rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;

  > section {
    ${container};
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.blue5};
  }

  > section:nth-child(2) {
    > div {
      > h1 {
        font-size: 3.2rem;
        font-weight: 600;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-direction: column;

    > div {
      display: flex;
      flex-direction: column;
      gap: 8rem;

      > h2 {
        font-weight: 500;
        font-size: 2rem;
      }
      > ul {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        margin-bottom: 2rem;

        > li {
          list-style: none;
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    justify-content: center;
    gap: 2rem;
    > button {
      border: none;
      background-color: #054159;
      padding: 1rem 2rem;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      border-radius: 1rem;
      font-size: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 52rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;
