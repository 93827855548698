import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/red-demands/banner.svg";

export const Wrapper = styled.div`
  margin-top: 7rem;
  margin-bottom: 7rem;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;

  > section {
    padding: 6rem 0rem;
    h1,
    h2 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
      font-size: 3.2rem;
    }

    > div {
      p {
        color: ${({ theme }) => theme.colors.blue5};
        font-size: 2.2rem;
        line-height: 1.6;
        margin-bottom: 1.4rem;
      }
    }
  }

  > section:nth-child(2) {
    > div {
      ${container};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3rem;
      padding: 6rem 0rem;
      flex-wrap: wrap;
      > div:nth-child(1) {
        flex: 1 1 50rem;
        > h1 {
          margin-bottom: 3rem;
        }
      }
      > div:nth-child(2) {
        flex: 1 1 50rem;
        > img {
          object-fit: contain;
        }
      }
    }
  }

  > section:nth-child(3) {
    background-color: ${({ theme }) => theme.colors.gray6};

    > div {
      ${container};
      padding: 4rem;
      /* text-align: center; */
      display: flex;
      justify-content: center;
      > div {
        max-width: 60rem;
        width: 100%;
        > img {
          object-fit: contain;
        }
      }
    }
  }

  > section:nth-child(4) {
    background-color: ${({ theme }) => theme.colors.gray6};
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-align: center;

    > div {
      ${container};
      > h1 {
        margin-bottom: 3rem;
      }
    }
  }

  > section:nth-child(5) {
    background-color: ${({ theme }) => theme.colors.gray6};
    padding: 2rem 0rem;
    > div {
      ${container};
      display: flex;
      gap: 4rem;
      text-align: center;
      flex-wrap: wrap;
      > div {
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
          0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
        flex: 1 1 45rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 2rem;
        border-radius: 2rem;
        background-color: #00a8e5;
        padding: 6rem 0rem;

        > img {
          width: 20rem;
        }
        > p:nth-child(2) {
          color: ${({ theme }) => theme.colors.white};
          font-size: 2.4rem;
        }

        > p:nth-child(3) {
          color: ${({ theme }) => theme.colors.white};
          font-size: 1.6rem;
          padding-inline: 4rem;
        }

        > button {
          border: none;
          border-radius: 0.5rem;
          padding: 1rem 2.5rem;
          font-size: 2.2rem;
          background-color: #054159;
          color: ${({ theme }) => theme.colors.white};
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        background-color: #b2e5f7;
        position: relative;

        > img {
          opacity: 0.5;
        }
        > span {
          position: absolute;
          left: 0;
          top: 6rem;
          padding: 1rem 2.5rem;
          background-color: #f51111;
          color: ${({ theme }) => theme.colors.white};
          border-radius: 0rem 1rem 1rem 0rem;
          font-size: 1.8rem;
        }

        > button {
          opacity: 0.5;
        }
      }
    }
  }

  > section:nth-child(6) {
    background-color: ${({ theme }) => theme.colors.gray6};
    > div {
      ${container};
      > p {
        text-align: center;
      }
    }
  }

  > section:nth-child(7) {
    > div {
      ${container};
      padding: 8rem;
      border-radius: 2rem;
      background-color: #e5f6fc;
      display: flex;
      flex-direction: column;
      gap: 6rem;

      div {
        > p {
          text-align: center;
          font-size: 1.7rem;
        }

        > h1 {
          color: #008bbc;
          font-weight: 400;
          font-size: 2.9rem;
          margin-bottom: 3rem;
          text-align: center;
        }

        > h1:nth-child(2) {
          color: ${({ theme }) => theme.colors.black};
        }
      }

      > div:nth-child(1) {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        > img {
          width: 100%;
          max-width: 60rem;
          margin-bottom: 6rem;
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 4rem;
        > div {
          flex: 1 1 50rem;
          height: fit-content;
          background-color: ${({ theme }) => theme.colors.white};
          padding: 4.5rem;
          border-radius: 1rem;
          box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
            0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          > ul {
            > li {
              font-size: 1.7rem;
            }
          }

          > a {
            > img {
              margin-top: 4rem;
              width: 12rem;
              cursor: pointer;
            }
          }
        }
      }

      > div:nth-child(3) {
        display: flex;
        flex-wrap: wrap;
        gap: 6rem;

        > div:nth-child(1) {
          display: flex;
          flex-direction: column;
          text-align: center;
          flex: 1 1 50rem;
          gap: 2rem;
        }

        > div:nth-child(2) {
          display: flex;
          gap: 2rem;
          flex: 1 1 51rem;
          flex-wrap: wrap;

          > div {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: center;
            justify-content: center;
            flex: 1 1 15rem;
            background-color: ${({ theme }) => theme.colors.white};
            padding: 2rem;
            box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
              0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
            border-radius: 1rem;
            cursor: pointer;
            > p {
              font-size: 1.4rem;
            }
            > img {
              width: 12rem;
            }
          }

          > div:nth-child(2) {
            padding: 0rem;
            > a {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              align-items: center;
              justify-content: center;
              text-decoration: none;
              padding: 2rem;
              height: 100%;
              width: 100%;
              > p {
                font-size: 1.4rem;
                text-align: center;
              }
              > img {
                width: 12rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  > section {
    background-color: #085270;
    padding: 4rem;

    > p {
      ${container};
      color: #26d8fa;
      font-size: 2.5rem;
      text-align: center;
    }
  }
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 58rem;

  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
    margin-bottom: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;

export const JourneySection = styled.section`
  padding: 6rem 0rem 3rem 0rem;

  > div {
    ${container}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 6rem;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      font-size: 1.6rem;
      > h1 {
        font-weight: 600;
        font-size: 3rem;
        color: ${({ theme }) => theme.colors.blue5};
        text-align: center;
      }

      > p {
        font-size: 2.2rem;
        text-align: center;
        color: ${({ theme }) => theme.colors.blue5};
      }
    }
    > div:nth-child(2) {
      display: flex;
      align-items: center;
      max-width: 110rem;
      width: 100%;
      > img {
        margin-top: 1.5rem;
        object-fit: contain;
      }
    }
  }
`;

export const LinkModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  gap: 3rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue5};

  > img {
    width: 12rem;
  }
  > h2 {
    font-size: 2.2rem;
    font-weight: 500;
  }

  > p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  > button {
    border: none;
    background-color: #054159;
    padding: 2rem 2rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`;
