import Card from "pages/Jornadas/components/Card";
import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

export default function SecondStep() {
  return (
    <S.Wrapper>
      <h1 id="r-e-2">
        2. ENTENDA AS DEMANDAS DA REDE E QUAIS RED SÃO MAIS ADEQUADOS
      </h1>

      <p>
        Chegou a hora de conhecer um pouco mais a sua rede, para entender a
        dimensão de RED é preciso olhar para além da disponibilidade de um
        sistema. É preciso saber como é feito o processo de curadoria e
        disponibilização, quais recursos já são usados, com qual finalidade e
        quais RED são mais demandados pelas escolas. Além disso, é preciso saber
        o nível dos seus professores em competências digitais.
      </p>

      <p>Assim, sugerimos que o/a gestor/a realize as seguintes etapas</p>

      <ul>
        <li>
          Aplicar o{" "}
          <a
            href="https://guiaedutec.com.br/escola"
            target="_blank"
            rel="noreferrer"
          >
            <i>questionário Guia EduTec</i>
          </a>{" "}
          em todas as escolas da rede de ensino. Com essa ferramenta é possível
          obter um diagnóstico do nível de adoção de tecnologias educacionais
          nas quatro dimensões da Escola Conectada (visão, competência, recursos
          educacionais digitais e infraestrutura). Além desse retrato, cada
          escola ainda recebe uma devolutiva sobre seus níveis dentro das quatro
          dimensões e traz uma visão mais ampliada da dimensão de RED.
        </li>
        <li>
          Identificar as competências digitais docentes através da ferramenta de{" "}
          <a
            href="https://guiaedutec.com.br/educador"
            target="_blank"
            rel="noreferrer"
          >
            <i>Autoavaliação das Competências Digitais</i>
          </a>
          . Essa ferramenta identifica o nível de desenvolvimento de
          competências para uso de tecnologias digitais pelos/as docentes das
          escolas. A ferramenta fornece devolutiva para o/a docente que
          responder e, por sua vez, a rede recebe um relatório dos dos níveis
          das escolas, mas sim um relatório do nível de desenvolvimento das
          competências digitais dos professores da rede.
        </li>
        <li>
          Identificar as demandas prioritárias da rede de ensino através da
          ferramenta de <i>Levantamento de Demanda por RED (em breve)</i>. Com
          essa ferramenta é possível identificar, de forma centralizada ou
          descentralizada, o que a rede usa atualmente e qual sua principal
          demanda de acordo com grupos da taxonomia de RED apresentados na etapa
          de conceito. Além desse retrato, essa ferramenta apresenta uma
          devolutiva que engloba os resultados das demais ferramentas em um
          lugar só e apoia o/a gestor/a no processo de decisão.
        </li>
      </ul>

      <Card
        heading="FERRAMENTA DE"
        title="DEMANDA POR RED"
        buttonText="LEIA MAIS"
        dimension="red"
        backgroundName="guiaDeEspecificacao"
        // link="https://reds.cieb.net.br/"
        disabled
      ></Card>

      <p>
        Como o processo de desenvolvimento de uma Escola Conectada não deve ser
        pensando em uma só dimensão de forma isolada, e sim fazer parte de um{" "}
        <Link to="/planos">
          <i>Plano de Inovação e Tecnologias (PIT)</i>
        </Link>
        , recomendamos que as ações que serão realizadas sejam incorporadas a
        esse plano. Esse instrumento funciona como bússola, orientando o que
        precisa ser feito, quando e quem são os responsáveis. Você pode entender
        mais sobre esse processo na{" "}
        <Link to="/jornada/visao">
          <i>Jornada de Visão</i>
        </Link>
        .
      </p>
    </S.Wrapper>
  );
}
