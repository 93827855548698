import { useParams } from "react-router-dom";
import { useEffect, useCallback } from "react";

const SCROLL_INTO_VIEW_OPTIONS = {
  behavior: "smooth",
  block: "center",
  inline: "nearest",
};

export default function useScrollToElement() {
  const { step, dimension } = useParams();

  const goToElement = useCallback(
    (id) => {
      if (!id) {
        id = step || dimension;
      }
      const element = document.getElementById(id);
      element?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS);
    },
    [dimension, step]
  );

  useEffect(() => {
    goToElement();
  }, [goToElement]);

  return { goToElement };
}
