import { createContext, useContext, useCallback } from "react";
import { useInstitutionQuery } from "services/common";

const PlansContext = createContext();

function PlansProvider({ children }) {
  const {
    data: institution,
    isSuccess: institutionSuccess,
    isLoading: institutionLoading,
  } = useInstitutionQuery();

  const getCurrentPlan = useCallback(
    (plan = null) => {
      if (!institutionSuccess) return;

      let date = new Date();
      date.setDate(new Date().getDate() + 1);
      const start = new Date();
      const end = date;

      const institutionAux = { ...institution };

      if (!institutionAux.plans) {
        institutionAux.plans = [
          {
            setting_plan: { validity: { start, end } },
            general_data: { network: {}, responsible: [] },
          },
        ];
      }

      let index = institutionAux?.plans?.length;
      if (plan) {
        index = plan;
      }

      const currentPlan = { ...institutionAux?.plans[index - 1] };
      return currentPlan;
    },
    [institution, institutionSuccess]
  );

  return (
    <PlansContext.Provider
      value={{
        getCurrentPlan,
        institution,
        institutionSuccess,
        institutionLoading,
      }}
    >
      {children}
    </PlansContext.Provider>
  );
}

const usePlans = () => useContext(PlansContext);

export { PlansProvider, usePlans };
