import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useForm } from "react-hook-form";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import CreatableMultiSelect from "shared-components/CreatableMultiSelect";
import QuestionItem from "./components/QuestionItem";

// import image31 from "assets/images/red-demands/031.svg";
// import image32 from "assets/images/red-demands/032.svg";
import image33 from "assets/images/red-demands/033.svg";
import image34 from "assets/images/red-demands/034.svg";
import image35 from "assets/images/red-demands/035.svg";
import image36 from "assets/images/red-demands/036.svg";
import image37 from "assets/images/red-demands/037.svg";
import image38 from "assets/images/red-demands/038.svg";

import image39 from "assets/images/red-demands/039.svg";
import image40 from "assets/images/red-demands/040.svg";
import image41 from "assets/images/red-demands/041.svg";
import image42 from "assets/images/red-demands/042.svg";
import image43 from "assets/images/red-demands/043.svg";
import image44 from "assets/images/red-demands/044.svg";
import Modal from "shared-components/Modal";

import { useNavigate } from "react-router-dom";
import { getSchoolSurveySchedules } from "utils/surveys";
import { useSurveyListQuery } from "services/common";
import { useGetAnswersQuery, useAnswersMutation } from "services/red-demands";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { isAdminCity, isAdminState } from "utils/users";

export default function SchoolDiagnosis() {
  const navigate = useNavigate();
  const { register, getValues, setValue, watch } = useForm();
  const [surveyScheduleId, setSurveyScheduleId] = useState("");
  const [options, setOptions] = useState([]);
  const [isOpen, setIsOpen] = useState(true);

  const { data: surveyList, isSuccess: surveyListSuccess } =
    useSurveyListQuery();

  useEffect(() => {
    if (surveyListSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      setSurveyScheduleId(schoolSchedules[0]?.id?.$oid);
    }
  }, [surveyList, surveyListSuccess]);

  const { data: getAnswers } = useGetAnswersQuery(surveyScheduleId);

  const answersMutation = useAnswersMutation();

  const handleSelectedItem = (id) => {
    if (isAdminCity() || isAdminState()) return;

    if (id === "checkbox-5" && !watch("diagnosis.checkbox-5")) {
      new Array(5).fill().forEach((_, index) => {
        setValue(`diagnosis.checkbox-${index}`, false);
      });
    }
    if (id !== "checkbox-5") {
      setValue("diagnosis.checkbox-5", false);
    }
    setValue(`diagnosis.${id}`, !watch(`diagnosis.${id}`));
  };

  const onSubmit = () => {
    if (isAdminCity() || isAdminState()) {
      return navigate("/demanda-de-red/demandas-da-escola");
    }
    const diagnosis = getValues("diagnosis");

    let checkbox = Object.entries(diagnosis).filter(
      ([key, value]) => key.includes("checkbox") && value === true
    );
    checkbox = checkbox.map((item) => item[0]);
    if (checkbox.length === 0) {
      return NotificationManager.error(
        "Selecione uma ou mais alternativas abaixo, qual(is) situação(ões) você identifica na sua escola."
      );
    }

    if (diagnosis.radios === null && options.length === 0) {
      return NotificationManager.error(
        "Coloque o(s) nome(s) (marca) dos softwares ou sistemas educacionais que sua escola utiliza."
      );
    }

    const answers = [...checkbox, diagnosis.radios];
    const red_demands = {
      ...getAnswers,
      answers,
      technologies: options.map((options) => options.label),
      status: "Started"
    };
    answersMutation.mutate({
      red_demands,
      survey_schedule_id: surveyScheduleId,
    });
  };

  const clearSelect = () => {
    setOptions([]);
  };

  useEffect(() => {
    if (answersMutation.isSuccess) {
      navigate("/demanda-de-red/demandas-da-escola");
    }
  }, [answersMutation.isSuccess, navigate]);

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Demandas de RED - Diagnóstico escola" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>
        <section>
          <span>Descentralizado</span>
          <h1>Diagnóstico escola</h1>
        </section>
        <section>
          <form>
            <div>
              <h2>
                Selecione uma ou mais alternativas abaixo, qual(is)
                situação(ões) você identifica na sua escola.
              </h2>
              <ul>
                <li>
                  <QuestionItem
                    img={image33}
                    imgActive={image42}
                    id="checkbox-0"
                    onClick={() => handleSelectedItem("checkbox-0")}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    selected={watch("diagnosis.checkbox-0")}
                    {...register("diagnosis.checkbox-0")}
                  >
                    Docentes e estudantes utilizam recursos, como vídeos,
                    imagens, textos, áudios, simulações, jogos educativos ou
                    cursos online, com o objetivo de desenvolver habilidades e
                    conteúdos previstos no currículo. Esses recursos são
                    encaminhados diretamente aos/às estudantes
                    oudisponibilizados em repositórios digitais e/ou plataformas
                    de oferta de conteúdo online, como banco de questões ou
                    biblioteca de livros.
                  </QuestionItem>
                </li>
                <li>
                  <QuestionItem
                    img={image34}
                    imgActive={image39}
                    id="checkbox-1"
                    onClick={() => handleSelectedItem("checkbox-1")}
                    selected={watch("diagnosis.checkbox-1")}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    {...register("diagnosis.checkbox-1")}
                  >
                    Docentes e estudantes utilizam, de forma individual ou
                    colaborativa, um ou mais softwares ou aplicativos para criar
                    apresentações de slides, desenvolver projetos em vídeo,
                    escrever e distribuir livros digitais e/ou realizar
                    simulações em 3D. Nos momentos de reforço, docentes e
                    estudantes interagem online por meio de ferramentas de
                    vídeo, áudio e/ou texto.
                  </QuestionItem>
                </li>
                <li>
                  <QuestionItem
                    img={image35}
                    imgActive={image40}
                    id="checkbox-2"
                    onClick={() => handleSelectedItem("checkbox-2")}
                    handleSelectedItem={handleSelectedItem}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    selected={watch("diagnosis.checkbox-2")}
                    {...register("diagnosis.checkbox-2")}
                  >
                    Docentes e estudantes utilizam ferramentas ou plataformas
                    que disponibilizam trilhas de conteúdos (personalizadas ou
                    não), materiais e/ou roteiros de estudo (atividades). Os/as
                    docentes utilizam ainda recursos digitais para criar planos
                    de aula, apresentar conteúdos, receber atividades realizadas
                    pelos/as estudantes e criar experiências de aprendizagem
                    presenciais, remotas ou híbridas com uso de tecnologias
                    digitais.
                  </QuestionItem>
                </li>
                <li>
                  <QuestionItem
                    img={image36}
                    imgActive={image43}
                    id="checkbox-3"
                    onClick={() => handleSelectedItem("checkbox-3")}
                    handleSelectedItem={handleSelectedItem}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    selected={watch("diagnosis.checkbox-3")}
                    {...register("diagnosis.checkbox-3")}
                  >
                    Coordenador/a pedagógico/a, docentes e estudantes utilizam
                    uma ou mais ferramentas para gerenciar a notas dos/das
                    alunos/as se comunicar com a comunidade escolar, acompanhar
                    a execução do currículo da rede ao longo do ano letivo, do
                    planejamento pedagógico docente e/ou da avaliação da
                    aprendizagem ou ainda para monitorar o uso pedagógico de
                    tablets ou computadores pelos estudantes.
                  </QuestionItem>
                </li>
                <li>
                  <QuestionItem
                    img={image37}
                    imgActive={image44}
                    id="checkbox-4"
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    onClick={() => handleSelectedItem("checkbox-4")}
                    selected={watch("diagnosis.checkbox-4")}
                    {...register("diagnosis.checkbox-4")}
                  >
                    Gestor/a escolar e profissionais do setor administrativo
                    utilizam um ou mais sistemas para gerenciar matrículas de
                    estudantes, grade horária, alimentação escolar, contratos
                    com fornecedores, verbas disponíveis e fluxo de caixa, vida
                    funcional dos profissionais da escola, entre outros
                    processos.
                  </QuestionItem>
                </li>
                <li>
                  <QuestionItem
                    img={image38}
                    imgActive={image41}
                    id="checkbox-5"
                    onClick={() => handleSelectedItem("checkbox-5")}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    selected={watch("diagnosis.checkbox-5")}
                    {...register("diagnosis.checkbox-5")}
                  >
                    Não identifico nenhuma situação similar na minha escola.
                  </QuestionItem>
                </li>
              </ul>
            </div>

            <div>
              <h2>
                Coloque o(s) nome(s) (marca) dos softwares ou sistemas
                educacionais que sua escola utiliza. Incluir tanto sistemas para
                fins pedagógicos, como administrativos
              </h2>
              <ul>
                <li>
                  <CreatableMultiSelect
                    setOptions={setOptions}
                    options={options}
                    clearSelect={clearSelect}
                    isDisabled={isAdminCity() || isAdminState() ? true : false}
                    clearRadios={() => setValue("diagnosis.radios", null)}
                  />
                </li>
                <li>
                  <input
                    type="radio"
                    id="radio-1"
                    value="radio-1"
                    onClick={clearSelect}
                    disabled={isAdminCity() || isAdminState() ? true : false}
                    {...register("diagnosis.radios")}
                  />
                  <label htmlFor="radio-1">
                    Desconheço o(s) nome(s) dos softwares ou sistemas
                    educacionais usados em minha escola.
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="radio-2"
                    name="radios"
                    value="radio-2"
                    onClick={clearSelect}
                    disabled={isAdminCity() || isAdminState() ? true : false}
                    {...register("diagnosis.radios")}
                  />
                  <label htmlFor="radio-2">
                    Não usamos softwares ou sistemas educacionais em minha
                    escola.
                  </label>
                </li>
              </ul>
            </div>
          </form>
        </section>
        <section>
          <button onClick={() => navigate("/demanda-de-red/escola")}>
            {" < "} ANTERIOR
          </button>
          <button onClick={onSubmit}>PRÓXIMA {" > "}</button>
        </section>
        {(isAdminState() || isAdminCity()) && (
          <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
            <S.AlertModal>
              <h2>Atenção!</h2>

              <p>
                Esta é uma visualização do questionário para o diretor.
                <br /> As suas respostas não serão contabilizadas.
              </p>

              <button onClick={() => setIsOpen(false)}>OK</button>
            </S.AlertModal>
          </Modal>
        )}
      </S.Wrapper>
    </Layout>
  );
}
