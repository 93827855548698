import React from "react";
import * as S from "./styles";
import GENSelect from "../GENSelect";
import { useInfraCalculatorFilter } from "hooks/use-infra-calculator-filter";

export interface Cycle {
  label: string;
  value: string;
}
export interface InfraCalculatorFilterProps {
  display?: string;
  boxShadow?: boolean;
  cycles: Cycle[];
}

function InfraCalculatorFilter({
  display,
  boxShadow,
  cycles,
}: InfraCalculatorFilterProps) {
  const {
    stageSelected,
    setStageSelected,
    lastCycles,
    setLastCycles,
    setSelectedCycle,
    selectedCycle,
  } = useInfraCalculatorFilter();

  const years = cycles.sort((a, b) => Number(b.value) - Number(a.value));
  const othersCycles = [
    { label: "ANOS ANTERIORES", value: "ANOS ANTERIORES" },
    ...years.slice(2, years.length),
  ];

  return (
    <S.Wrapper display={display} boxShadow={boxShadow}>
      <div>
        <div>
          <span>ETAPA DE ENSINO</span>
          <ul>
            <li>
              <S.Button
                isSelected={stageSelected === null}
                onClick={() => {
                  setStageSelected(null);
                }}
              >
                TODAS
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "kindergarten"}
                onClick={() => setStageSelected("kindergarten")}
              >
                ED.INFANTIL
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "elementary_1"}
                onClick={() => setStageSelected("elementary_1")}
              >
                FUNDAMENTAL 1
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "elementary_2"}
                onClick={() => setStageSelected("elementary_2")}
              >
                FUNDAMENTAL 2
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "highschool"}
                onClick={() => setStageSelected("highschool")}
              >
                ENS. MÉDIO
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "technical"}
                onClick={() => setStageSelected("technical")}
              >
                ENS. TÉCNICO
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={stageSelected === "adult"}
                onClick={() => setStageSelected("adult")}
              >
                EJA
              </S.Button>
            </li>
          </ul>
        </div>

        <div>
          <span>CICLO</span>
          <ul>
            {years
              .slice(0, 2)
              .sort((a, b) => Number(b.value) - Number(a.value))
              .map((year, index) => (
                <li key={year.value}>
                  <S.Button
                    isActive={
                      index === 0 ? lastCycles?.penultimate : lastCycles?.last
                    }
                    onClick={() => {
                      setLastCycles(() => {
                        if (index === 0) {
                          return { penultimate: true, last: false };
                        } else {
                          return { penultimate: false, last: true };
                        }
                      });

                      setSelectedCycle(year);
                    }}
                  >
                    {year.label}
                  </S.Button>
                </li>
              ))}
            <li>
              <GENSelect
                placeholder="ANOS ANTERIORES"
                value={
                  othersCycles.some(
                    (option) => option.value === selectedCycle?.value
                  )
                    ? selectedCycle
                    : { label: "ANOS ANTERIORES", value: "" }
                }
                onValueChange={(year: Cycle) => {
                  if (year?.label === "ANOS ANTERIORES") {
                    setSelectedCycle({ label: "TODOS", value: "" });
                  } else {
                    setSelectedCycle(year);
                  }
                  setLastCycles({ penultimate: false, last: false });
                }}
                data={othersCycles}
              />
            </li>
          </ul>
        </div>
      </div>
    </S.Wrapper>
  );
}

export default InfraCalculatorFilter;
