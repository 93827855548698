import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner5 from "assets/images/red-demands/banner_5.svg";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const ArrowDropDown = styled(ArrowDropDownIcon)`
  color: ${({ theme }) => theme.colors.white};
  &&& {
    font-size: 3rem;
  }
`;

export const ArrowDropUp = styled(ArrowDropUpIcon)`
  color: ${({ theme }) => theme.colors.white};
  &&& {
    font-size: 3rem;
  }
`;

export const Wrapper = styled.div`
  margin: 7rem 0rem;
  display: flex;
  flex-direction: column;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  line-height: 1.5;

  > section {
    h1,
    h2 {
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
      font-size: 3.2rem;
    }

    > div {
      p {
        color: ${({ theme }) => theme.colors.blue5};
        font-size: 2rem;
      }
    }
  }

  > section:nth-child(2) {
    padding: 6rem 0rem;
    > div {
      ${container};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 4rem;

      > h1 {
        text-align: center;
        font-size: 4rem;
      }

      > p {
        padding-inline: 10rem;
        text-align: center;
        font-size: 1.8rem;
      }
    }
  }

  > section:nth-child(3) {
    padding: 6rem 0rem;
    > div:nth-child(1) {
      ${container};
      display: flex;
      justify-content: center;
      gap: 10rem;
      > div {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;

          > p {
            flex: 1 1;
            color: ${({ theme }) => theme.colors.blue5};
            font-size: 14rem;
            font-weight: bold;
          }

          > p {
            color: #1ebad6;
          }

          > div:nth-child(2) {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            padding-top: 4rem;

            > span:nth-child(1) {
              font-weight: 600;
              font-size: 2.2rem;
              color: #1488a5;
            }

            > span:nth-child(2) {
              font-size: 1.6rem;
              font-weight: 500;
            }
          }
        }
      }

      > div:nth-child(2) {
        > div {
          > p {
            color: #9c3b85;

            > span {
              font-size: 6rem;
            }
          }

          > div:nth-child(2) {
            flex: 1 1;
            display: flex;
            flex-direction: column;
            gap: 0.2rem;
            padding-top: 4rem;

            > span:nth-child(1) {
              font-weight: 600;
              color: #79095c;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      ${container};

      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding-inline: 18rem;

      > p {
        padding: 1rem 2rem;
        border-radius: 1rem;
        background-color: #e6000e;
        color: ${({ theme }) => theme.colors.white};
        width: fit-content;
        font-size: 1.6rem;
      }
    }
  }

  > section:nth-child(4) {
    padding: 6rem 0rem;
    > div {
      ${container};
      display: flex;
      flex-direction: column;
      gap: 2rem;

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          justify-content: space-between;
          background-color: #158bbc;
          height: 10rem;
          padding: 2rem 4rem;
          border-radius: 1rem;
          cursor: pointer;
          > p {
            color: ${({ theme }) => theme.colors.white};
            font-size: 1.8rem;
          }
        }

        > div:nth-child(2) {
          display: flex;
          flex-direction: column;
          width: 100%;
          height: 100%;
          border-radius: 1rem;
          box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
            0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

          > div:nth-child(1) {
            background-color: ${({ theme }) => theme.colors.gray4};
            height: 12rem;
            padding: 2rem 4rem;
            border-radius: 1rem 1rem 0rem 0rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
              > p {
                font-size: 1.8rem;
              }
            }
          }

          > div:nth-child(2) {
            overflow-x: auto;
            display: flex;
            justify-content: center;
          }

          > div:nth-child(3) {
            padding: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4rem;
            margin-top: 16rem;
            margin-bottom: 6rem;

            > div {
              > button {
                background-color: #158bbc;
                border-radius: 1rem;
                border: none;
                cursor: pointer;
                color: ${({ theme }) => theme.colors.white};
              }
            }

            > div:nth-child(1) {
              position: relative;

              > img {
                position: absolute;
                top: -11.5rem;
                z-index: 1;
              }
              > button {
                padding: 2rem 4rem;
                font-size: 1.5rem;
                z-index: -1;
              }
            }

            > div:nth-child(2) {
              display: flex;
              gap: 2rem;
              > button {
                padding: 1rem 1.2rem;
                font-size: 1.4rem;
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > div:nth-child(1) {
          display: flex;
          align-items: center;
          background-color: #158bbc;
          justify-content: space-between;
          height: 10rem;
          padding: 2rem 4rem;
          border-radius: 1rem;
          cursor: pointer;
          > p {
            color: ${({ theme }) => theme.colors.white};
            font-size: 1.8rem;
          }
        }

        > div:nth-child(2) {
          width: 100%;
          height: fit-content;
          border-radius: 1rem;
          box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
            0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);

          > div:nth-child(1) {
            background-color: ${({ theme }) => theme.colors.gray4};
            height: 12rem;
            padding: 2rem 4rem;
            border-radius: 1rem 1rem 0rem 0rem;
            display: flex;
            align-items: center;
            justify-content: space-between;

            > div {
              > p {
                font-size: 1.8rem;
              }
            }
          }

          > div:nth-child(2) {
            overflow-x: auto;
            padding: 4rem;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: flex-start;
            gap: 4rem;

            > div {
              display: flex;
              flex-direction: column;
              gap: 4rem;
              margin-bottom: 6rem;
              align-items: center;
              justify-content: center;
              width: 35rem;

              > img {
                object-fit: contain;
              }
              > div {
                display: flex;
                flex-direction: column;
                gap: 2rem;
                text-align: center;
                > h1 {
                  font-size: 2rem;
                }

                > p {
                  font-size: 1.5rem;
                }
              }
            }
          }

          > div:nth-child(3) {
            padding: 4rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4rem;
            margin-top: 4rem;

            > div {
              > button {
                background-color: #158bbc;
                border-radius: 1rem;
                border: none;
                cursor: pointer;
                color: ${({ theme }) => theme.colors.white};
              }
            }

            > div:nth-child(1) {
              position: relative;

              > img {
                position: absolute;
                top: -11.5rem;
                z-index: 1;
              }
              > button {
                padding: 2rem 4rem;
                font-size: 1.5rem;
                z-index: -1;
              }
            }

            > div:nth-child(2) {
              display: flex;
              gap: 2rem;
              > button {
                padding: 1rem 2rem;
                font-size: 1.4rem;
              }
            }
          }
        }
      }
    }
  }

  > section:nth-child(5) {
    /* padding: 6rem 0rem; */
    > div {
      background-color: ${({ theme }) => theme.colors.gray5};
      > div:nth-child(1) {
        background-color: #158bbc;
        height: 10rem;
        padding: 4rem;
        display: flex;
        align-items: center;

        > p {
          ${container};
          color: ${({ theme }) => theme.colors.white};
        }
      }

      > div:nth-child(2) {
        ${container};

        padding: 4rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > div:nth-child(1) {
          > p {
            font-size: 1.8rem;
          }
        }

        > div:nth-child(2) {
          flex-wrap: wrap;
          width: 25rem;
          > div {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            flex: 1 1 10rem;
          }
        }
      }
    }
  }

  > section:nth-child(6) {
    padding: 6rem 0rem;
    background-color: ${({ theme }) => theme.colors.gray5};

    > div {
      ${container};
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      height: 100%;

      > div {
        flex: 1 1 50rem;
        width: 50rem;
        display: flex;
        flex-direction: column;
        padding: 4rem;
        justify-content: space-between;
        gap: 2rem;
        align-items: center;
        border-radius: 1rem;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
          0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
        background-color: ${({ theme }) => theme.colors.white};
      }

      > div:nth-child(1) {
        > div:nth-child(3) {
          align-self: flex-start;
          padding: 2rem 4rem;
          > ul {
            list-style: none;
            > li {
              margin-bottom: 2rem;
              font-weight: 600;
              display: flex;
              gap: 1rem;
              > div {
                height: 2rem;
                width: 2rem;
                border-radius: 50%;
              }
            }

            > li:nth-child(1) {
              > div {
                background-color: #f86920;
              }
            }
            > li:nth-child(2) {
              > div {
                background-color: #9c3b85;
              }
            }
            > li:nth-child(3) {
              > div {
                background-color: #63a54e;
              }
            }
            > li:nth-child(4) {
              > div {
                background-color: #1cb79c;
              }
            }
            > li:nth-child(5) {
              > div {
                background-color: #1ebad6;
              }
            }
          }
        }
      }
    }
  }

  > section:nth-child(7),
  > section:nth-child(9) {
    display: flex;
    align-items: center;
    background-color: #158bbc;
    height: 12rem;
    padding: 4rem;

    > p {
      ${container};
      color: ${({ theme }) => theme.colors.white};
      font-size: 2rem;
      font-weight: 500;
    }
  }

  > section:nth-last-child(2) {
    > div {
      ${container};
      display: flex;
      justify-content: center;
      > button {
        background-color: #054159;
        border: none;
        color: ${({ theme }) => theme.colors.white};
        width: fit-content;
        font-size: 2.2rem;
        padding: 2rem 3rem;
        border-radius: 1rem;
        cursor: pointer;
      }
    }
  }

  > section:last-child {
    > div {
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
      }

      > div:nth-child(1) {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 10rem;
        background-color: #148bac;
        > p {
          ${container};
          text-align: center;
          color: ${({ theme }) => theme.colors.white};
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        ${container};

        > div {
          height: 24rem;
          background-color: #dfeef3;
          flex: 1 1 25rem;
          gap: 2rem;
          display: grid;
          place-items: center;
          text-align: center;
        }
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner5});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 52rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;
