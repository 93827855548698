import styled from "styled-components";

export const Wrapper = styled.div`
  width: ${({ width }) => width + "px"};
  height: ${({ height }) => height + "px"};
  font-size: 1.5rem;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1), 0 2px 5px rgba(0, 0, 0, 0.1);
  margin: 40px 0;
`;
