import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import {
  useInstitutionPlansMutation,
  useInstitutionQuery,
} from "services/common";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { getStatus } from "utils/plans";
import { v4 as uuidv4 } from "uuid";
import { usePlans } from "./use-plans";

const GoalActionsTableContext = createContext();

function GoalActionsTableProvider({ children, dimension }) {
  const [goals, setGoals] = useState([]);
  const [targets, setTargets] = useState([]);
  const [canEdit, setCanEdit] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const [selectedGoal, setSelectedGoal] = useState();
  const {
    register,
    setValue,
    unregister,
    getValues,
    control,
    watch,
    resetField,
  } = useForm();

  const {
    data: institution,
    isLoading: institutionLoading,
    isSuccess: institutionSuccess,
  } = useInstitutionQuery();

  const { getCurrentPlan } = usePlans();

  useEffect(() => {
    if (institutionSuccess) {
      const currentPlan = getCurrentPlan();
      const goals = currentPlan[dimension]?.goals;
      if (goals) {
        setGoals(goals);
        setCanEdit([...Array(goals?.length)].map(() => []));
        setLoadings([...Array(goals?.length)].map(() => []));
      }
    }
  }, [dimension, getCurrentPlan, institutionSuccess]);

  const institutionPlans = useInstitutionPlansMutation();
  const {
    isLoading: institutionPlansLoading,
    isSuccess: institutionPlansSuccess,
    error: institutionPlansError,
  } = institutionPlans;

  useEffect(() => {
    if (institutionPlansSuccess) {
      NotificationManager.success("Os dados foram salvos com sucesso !");
    }
    if (institutionPlansError) {
      NotificationManager.error("Ocorreu um erro ao salvar os dados");
    }
  }, [institutionPlansSuccess, institutionPlansError]);

  useEffect(() => {
    if (selectedGoal !== undefined && selectedRow !== undefined) {
      setLoadings((loadings) => {
        const loadingsAux = [...loadings];
        loadingsAux[selectedGoal][selectedRow] = institutionPlansLoading;
        return loadingsAux;
      });
    }
  }, [selectedRow, institutionPlansLoading, selectedGoal]);

  const saveInfraParams = useCallback(
    (infraParams) => {
      const currentPlan = getCurrentPlan();

      if (!currentPlan?.infra) {
        currentPlan.infra = {};
      }
      Object.entries(infraParams).forEach(([key, value], index) => {
        currentPlan.infra[key] = value;
      });
      const length = institution?.plans.length;
      institution.plans[length - 1] = currentPlan;
      return institutionPlans.mutateAsync({ institution });
    },
    [getCurrentPlan, institution, institutionPlans]
  );

  const saveGoalDescription = useCallback(
    (targetsUpdated) => {
      const goalDescription = getValues("goal_description");
      const schoolNumber = getValues("school_number");
      const targetsAux = getValues("targets");
      const targetsCopy = [...targets];
      if (!goalDescription || !schoolNumber) return;
      const currentPlan = getCurrentPlan();
      const goals = currentPlan[dimension].goals;

      if (!targetsUpdated) {
        targetsUpdated = targetsCopy;
      }

      goals.forEach((goal) => {
        let newTarget;

        targetsUpdated.forEach((target, index) => {
          if (!target) return;
          if (!targetsAux) {
            newTarget = { ...target };
          } else {
            newTarget = {
              id: target.id,
              goalId: target.goalId,
              target:
                targetsAux[goal?.id] &&
                targetsAux[goal?.id][target.id] &&
                targetsAux[goal?.id][target.id]?.target,
              indicators: target.indicators.map((indicator) => ({
                id: indicator?.id,
                indicator:
                  targetsAux &&
                  targetsAux[goal?.id] &&
                  targetsAux[goal?.id][target.id] &&
                  targetsAux[goal?.id][target.id].indicators &&
                  targetsAux[goal?.id][target.id].indicators[indicator.id],
              })),
            };

            targetsUpdated[index] = newTarget;
          }
        });

        goal.targets = targetsUpdated.filter(
          (target) => target.goalId === goal.id
        );
      });

      Object.entries(goalDescription).forEach(([_, value], index) => {
        const goal = currentPlan[dimension].goals[index];
        if (!goal) return;
        goal.description = value;
        const actions = goal.actions;
        goal.actions = actions.filter(
          (action) => !Object.values(action).every((value) => value === "")
        );
      });

      Object.entries(schoolNumber).forEach(([_, value], index) => {
        const goal = currentPlan[dimension].goals[index];
        if (!goal) return;
        currentPlan[dimension].goals[index].school_number = value;
      });

      const length = institution?.plans.length;
      institution.plans[length - 1] = currentPlan;
      return institutionPlans.mutateAsync({ institution });
    },
    [
      dimension,
      getCurrentPlan,
      getValues,
      institution,
      institutionPlans,
      targets,
    ]
  );

  const changeCanEdit = useCallback(
    (index, tableKey, bool) => {
      const canEditAux = [...canEdit];
      canEditAux[tableKey][index] = bool;
      setCanEdit(canEditAux);
    },
    [canEdit, setCanEdit]
  );

  const saveGoalActionInDataBase = useCallback(
    (index, tableKey, disableChangeCanEdit = false) => {
      const data = getValues(`goal_${tableKey}_row_${index}`);
      const status = getStatus(data.start, data.end);
      if (!status) return;
      if (!disableChangeCanEdit) {
        changeCanEdit(index, tableKey, false);
      }
      setSelectedRow(index);
      setSelectedGoal(tableKey);

      data.status = {
        name: status,
        automatic: true,
      };
      data.goal_index = tableKey;
      const currentPlan = getCurrentPlan();
      goals[tableKey].actions[index] = data;
      const actionsAux2 = goals[tableKey]?.actions.filter(
        (action) => action.cost !== ""
      );

      goals[tableKey].totalBudget = actionsAux2.reduce(
        (p, c) => ({ cost: Number(p.cost) + Number(c.cost) }),
        { cost: 0 }
      ).cost;

      currentPlan[dimension] = {};
      currentPlan[dimension].goals = goals;
      const length = institution?.plans?.length;
      institution.plans[length - 1] = currentPlan;
      institutionPlans.mutate({ institution });
      setGoals(goals);
      if (!disableChangeCanEdit) {
        setCanEdit([...Array(goals?.length)].map(() => []));
      }
    },
    [
      dimension,
      getCurrentPlan,
      getValues,
      institution,
      goals,
      institutionPlans,
      changeCanEdit,
    ]
  );

  const removeGoalActionFromDataBase = useCallback(
    (index, tableKey) => {
      setSelectedRow(index);
      const currentPlan = getCurrentPlan();
      let actionsAux = [...currentPlan[dimension]?.goals[tableKey]?.actions];
      actionsAux = actionsAux.filter((_, i) => i !== index);
      const actionsAux2 = actionsAux.filter((action) => action.cost !== "");
      const totalBudget =
        actionsAux2.reduce(
          (p, c) => ({ cost: parseFloat(p.cost) + parseFloat(c.cost) }),
          { cost: 0 }
        ).cost || 0;
      currentPlan[dimension].goals[tableKey].totalBudget = totalBudget;
      currentPlan[dimension].goals[tableKey].actions = actionsAux;
      const length = institution?.plans?.length;
      institution.plans[length - 1] = currentPlan;
      institutionPlans.mutate({ institution });
    },
    [dimension, getCurrentPlan, institution, institutionPlans]
  );

  const deleteActionFromState = useCallback(
    (index, tableKey) => {
      const goalsAux = [...goals];
      goalsAux[tableKey]?.actions.filter((_, i) => i !== index);
      setGoals(goalsAux);
      unregister(`goal_${tableKey}_row_${index}`);
    },
    [goals, unregister, setGoals]
  );

  const addActionInState = useCallback(
    (row, tableKey) => {
      const goalsAux = [...goals];
      goalsAux[tableKey].actions = [...goalsAux[tableKey]?.actions, row];
      setGoals(goalsAux);
      changeCanEdit(goalsAux[tableKey]?.actions?.length - 1, tableKey, true);
    },
    [goals, changeCanEdit]
  );

  const addGoal = useCallback(() => {
    const newGoals = [
      ...goals,
      { id: uuidv4(), description: "", actions: [], school_number: null },
    ];
    setGoals(newGoals);
    const currentPlan = getCurrentPlan();
    currentPlan[dimension] = {};
    currentPlan[dimension].goals = newGoals;
    const length = institution?.plans?.length;
    institution.plans[length - 1] = currentPlan;
    institutionPlans.mutate({ institution });
    setCanEdit([...Array(newGoals?.length)].map(() => []));
    setLoadings([...Array(newGoals?.length)].map(() => []));
  }, [dimension, goals, institutionPlans, institution, getCurrentPlan]);

  const deleteGoal = useCallback(
    (tableKey, goalId) => {
      const newGoals = goals.filter((_, index) => tableKey !== index);
      setGoals(newGoals);
      const currentPlan = getCurrentPlan();
      currentPlan[dimension].goals = newGoals;
      const length = institution?.plans?.length;
      institution.plans[length - 1] = currentPlan;
      institutionPlans.mutate({ institution });
      unregister(`goal_description.goal_${tableKey}`);
      unregister(`school_number.goal_${tableKey}`);
      unregister(`targets.${goalId}`);
      setCanEdit([...Array(newGoals?.length + 1)].map(() => []));
      setLoadings([...Array(newGoals?.length + 1)].map(() => []));
    },
    [
      unregister,
      dimension,
      goals,
      institutionPlans,
      institution,
      getCurrentPlan,
    ]
  );

  const cancelActionEdit = useCallback(
    (index, tableKey) => {
      const data = getValues(`goal_${tableKey}_row_${index}`);
      const status = getStatus(data?.start, data?.end);
      if (!status) return;
      changeCanEdit(index, tableKey, false);
    },
    [changeCanEdit, getValues]
  );

  return (
    <GoalActionsTableContext.Provider
      value={{
        saveInfraParams,
        removeGoalActionFromDataBase,
        saveGoalActionInDataBase,
        deleteActionFromState,
        saveGoalDescription,
        getValues,
        loadings,
        canEdit,
        cancelActionEdit,
        goals,
        register,
        setValue,
        unregister,
        setCanEdit,
        setGoals,
        addActionInState,
        changeCanEdit,
        addGoal,
        deleteGoal,
        institutionPlansLoading,
        institutionPlansSuccess,
        institution,
        institutionSuccess,
        institutionLoading,
        getCurrentPlan,
        control,
        watch,
        targets,
        setTargets,
        resetField,
      }}
    >
      {children}
    </GoalActionsTableContext.Provider>
  );
}

const useGoalActionsTable = () => useContext(GoalActionsTableContext);
export { GoalActionsTableProvider, useGoalActionsTable };
