import styled from "styled-components";

const BAR_COLORS = ["#1ebad6", "#9c3b85", "#7ac368", "#1cb79c", "#f68c42"];
const TEXT_COLORS = ["#1488a5", "#79095c", "#63a54e", "#14917d", "#cc5315"];

export const Wrapper = styled.div`
  height: 54rem;
  max-width: 34rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 4rem;
  display: flex;
  gap: 2rem;
  flex-direction: column;
  justify-content: space-between;
  outline: ${({ isActive }) => isActive && "0.3rem solid #1960b1"};
  cursor: pointer;
`;
export const Header = styled.header`
  > img {
    width: 17rem;
  }
`;
export const Main = styled.main`
  > p {
    line-height: 1.5;
    font-size: 1.5rem;
    font-weight: 500;
  }
`;
export const Footer = styled.footer`
  position: relative;
  padding: 2rem 0rem;
  &::before {
    content: "";
    position: absolute;
    width: 10rem;
    height: 0.4rem;
    background-color: ${({ index }) => BAR_COLORS[index - 1]};
    top: 0;
    left: 0;
  }

  > h1 {
    font-weight: 600;
    font-size: 1.4rem;
    color: ${({ index }) => TEXT_COLORS[index - 1]};
  }
`;
