import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import { fontGolos } from "../assets/fonts";
import media from "styled-media-query";

export const GlobalStyles = createGlobalStyle`
    ${normalize};
    ${fontGolos}
    
    * {
			margin: 0;
			padding: 0;
			box-sizing: border-box;
    }

    html,
    body {
			width: 100%;
			height: 100%;
			font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
			color: ${({ theme }) => theme.colors.black};
			margin: 0;
			padding: 0 !important;
    }



    html{

			width: 100vw !important;
			
			${media.lessThan("huge")`
				font-size: 58%;
			`}

			${media.lessThan("large")`
				font-size: 50%;
			`}

			${media.lessThan("medium")`
				font-size: 50%;
			`}

			/* @media print{
				font-size: 62.5%;
			} */

			font-size: 62.5%;
		}


    body{
			overflow-x: hidden;
			font-size: 1.6rem;

		
			@media print{
				-webkit-print-color-adjust: exact !important; 
				color-adjust: exact !important;  

			}

			@page {
				size: A4;
				margin-left: 0cm;
				margin-right: 0cm;
				margin-top: 1cm;
				margin-bottom: 0cm;
				-webkit-print-color-adjust: exact;
			}

			@page :first{
				margin-top: 0cm;
			}
    }

    h1{
			margin: 0;
    }

    strong {
			font-weight: 500;
    }

    #root {
			height: 100vh;
			width: 100%;
			display: flex;
			flex-direction: column;
    }

    .notification-container{
			font-size: 1.6rem !important;
    }
`;
