import React from "react";
import * as S from "./styles";
import { convertToMoneyFormat } from "utils/money";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { DEVICES } from "pages/InfrastructureCalculator/subpages/InfraCalculatorReferenceValues";

export interface Card {
  name: string;
  imageName: string;
  key: string;
}

export interface CardReferenceValuesProps {
  card: Card;
  selectEquipament: (equipamentSelected: string) => void;
  references_values: Record<string, number>;
  setReferenceValue: (key: string, value: number) => void;
  canEdit: boolean[];
  setCanEdit: React.Dispatch<React.SetStateAction<boolean[]>>;
  index: number;
  setInfraCustomValue: () => void;
}

export default function CardReferenceValues({
  card,
  selectEquipament,
  references_values,
  setReferenceValue,
  setInfraCustomValue,
  canEdit,
  setCanEdit,
  index,
}: CardReferenceValuesProps) {
  const { name, imageName, key } = card;

  const changeCanEdit = (bool: boolean) => {
    const aux = [...canEdit];
    aux[index] = bool;
    setCanEdit(aux);
  };

  return (
    <S.Wrapper>
      <div>
        <img src={DEVICES[imageName]} alt={imageName} />
      </div>
      <div>
        <h1>
          {name} <button onClick={() => selectEquipament(card?.key)}>?</button>
        </h1>
      </div>
      <div>
        <div>
          {canEdit[index] && <span>R$</span>}

          {canEdit[index] ? (
            <input
              type="number"
              value={references_values[key]}
              onChange={(e) =>
                setReferenceValue(key, parseFloat(e.target.value))
              }
            />
          ) : (
            <div>{convertToMoneyFormat(references_values[key])}</div>
          )}
        </div>

        {!canEdit[index] ? (
          <div onClick={() => changeCanEdit(true)}>
            <EditIcon />
          </div>
        ) : (
          <div
            onClick={() => {
              setInfraCustomValue();
              changeCanEdit(false);
            }}
          >
            <CheckIcon />
          </div>
        )}
      </div>
    </S.Wrapper>
  );
}
