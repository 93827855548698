import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
    position: absolute;
    text-align: center;
    cursor: pointer;
    ${media.lessThan("small")`
      display: none;
    `}
  }

  > p:nth-child(1) {
    top: 8.5rem;
    left: 15.5rem;
  }

  > p:nth-child(2) {
    top: 8rem;
    right: 18.5rem;
  }

  > p:nth-child(3) {
    top: 25.7rem;
    left: 12.5rem;
  }

  > p:nth-child(4) {
    top: 27.7rem;
    right: 17.9rem;
  }
`;

export const SVG = styled.svg`
  & {
    cursor: pointer;
  }

  width: 60rem;

  ${media.lessThan("small")`
    width: 32rem;
  `}

  .cls-1 {
    isolation: isolate;
  }

  .cls-2 {
    fill: ${({ theme }) => theme.colors.green};
    opacity: ${({ infraIsActive }) => (infraIsActive ? 0.8 : 1)};
  }

  .cls-3 {
    fill: #008bbc;
    opacity: ${({ redIsActive }) => (redIsActive ? 0.8 : 1)};
  }

  .cls-4 {
    fill: ${({ theme }) => theme.colors.blue2};
    opacity: ${({ redIsActive }) => (redIsActive ? 0.8 : 1)};
  }

  .cls-5 {
    fill: ${({ theme }) => theme.colors.green2};
    opacity: ${({ infraIsActive }) => (infraIsActive ? 0.8 : 1)};
  }

  .cls-6 {
    fill: #79b142;
    opacity: ${({ infraIsActive }) => (infraIsActive ? 0.8 : 1)};
  }

  .cls-7 {
    fill: url(#Gradiente_sem_nome_90);
  }

  .cls-8 {
    fill: #fff;
  }

  .cls-9 {
    fill: url(#Gradiente_sem_nome_87);
  }

  .cls-10,
  .cls-19,
  .cls-21 {
    fill: #ffa480;
  }

  .cls-11 {
    fill: #f55a29;
  }

  .cls-12 {
    fill: #f7e4cb;
  }

  .cls-13 {
    fill: #2c878d;
  }

  .cls-14 {
    fill: #f9d8ad;
  }

  .cls-15 {
    fill: #b3d1ce;
  }

  .cls-16 {
    fill: #ff8f6a;
  }

  .cls-17 {
    fill: #e25d4b;
  }

  .cls-18 {
    fill: #3b3247;
  }

  .cls-19,
  .cls-21 {
    mix-blend-mode: multiply;
  }

  .cls-19 {
    opacity: 0.86;
  }

  .cls-20 {
    fill: #aa5753;
  }

  .cls-21 {
    opacity: 0.46;
  }

  .cls-22 {
    fill: #615310;
    opacity: ${({ visionIsActive }) => (visionIsActive ? 0.8 : 1)};
  }

  .cls-23 {
    fill: #fad62b;
    opacity: ${({ visionIsActive }) => (visionIsActive ? 0.8 : 1)};
  }

  .cls-24 {
    fill: #804a3d;
  }

  .cls-25 {
    fill: #69382c;
  }

  .cls-26 {
    fill: #af0061;
    opacity: ${({ competenceIsActive }) => (competenceIsActive ? 0.8 : 1)};
  }

  .cls-27 {
    fill: #e62270;
    opacity: ${({ competenceIsActive }) => (competenceIsActive ? 0.8 : 1)};
  }

  .cls-28 {
    fill: #e2c23a;
  }

  .cls-29 {
    opacity: 0.6;
    fill: url(#Gradiente_sem_nome_96);
  }

  .cls-30 {
    fill: url(#Gradiente_sem_nome_87-2);
  }

  .cls-30-vision {
    fill: url(#Gradiente_sem_nome_87-2);
  }

  .cls-31 {
    fill: #cf2467;
    opacity: ${({ competenceIsActive }) => (competenceIsActive ? 0.8 : 1)};
  }

  .cls-32 {
    fill: url(#Gradiente_sem_nome_88);
  }

  .cls-33 {
    fill: url(#Gradiente_sem_nome_87-3);
  }

  .cls-34 {
    fill: #127fa9;
    opacity: ${({ redIsActive }) => (redIsActive ? 0.8 : 1)};
  }

  .cls-35 {
    fill: url(#Gradiente_sem_nome_2);
  }

  .cls-36 {
    fill: url(#Gradiente_sem_nome_87-4);
  }

  .cls-37 {
    fill: #3b261d;
  }

  .cls-38 {
    fill: #261f1d;
  }

  .cls-38,
  .cls-39 {
    fill-rule: evenodd;
  }

  .cls-39 {
    fill: #130904;
  }
`;
