import styled, { css } from "styled-components";
import * as SelectPrimitive from "@radix-ui/react-select";
import { violet, mauve, blackA } from "@radix-ui/colors";
import media from "styled-media-query";

export const Select = styled(SelectPrimitive.Root)``;

export const StyledPortal = styled(SelectPrimitive.Portal)``;

export const StyledValue = styled(SelectPrimitive.Value)``;

export const StyledTrigger = styled(SelectPrimitive.SelectTrigger)`
  all: unset;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border-radius: 1rem;
  padding: 0 1.5rem;
  font-size: 1.3rem;

  line-height: 1;

  gap: 0.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: #707070;
  box-shadow: 0 0.2rem 1rem ${blackA.blackA7};
  font-weight: 500;

  ${media.greaterThan("medium")`
    height: 3.5rem;
  `}

  ${media.lessThan("medium")`
    font-size: 1.8rem;
    padding: 1rem 2rem;
    height: none;
  `}

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem black;
  }
  &[data-placeholder] {
    color: #707070;
  }

  &[data-disabled] {
    color: "gainsboro";
  }
`;

export const StyledIcon = styled(SelectPrimitive.SelectIcon)`
  color: "#707070";
`;

export const StyledContent = styled(SelectPrimitive.Content)`
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 0.6rem;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
  z-index: 26;
`;

export const StyledViewport = styled(SelectPrimitive.Viewport)`
  padding: 0.5rem;
`;

export const StyledItem = styled(SelectPrimitive.Item)`
  all: unset;
  font-size: 1.3rem;

  line-height: 1;
  color: ${violet.violet11};
  border-radius: 0.3rem;
  display: flex;
  align-items: center;
  height: 2.5rem;
  padding: 0 35px 0 25px;
  position: relative;
  user-select: none;

  ${media.lessThan("medium")`
    font-size: 1.8rem;
  `}

  &[data-disabled] {
    color: ${mauve.mauve8};
    pointer-events: none;
  }

  &[data-highlighted] {
    background-color: ${violet.violet9};
    color: ${violet.violet1};
  }
`;

export const StyledItemText = styled(SelectPrimitive.ItemText)``;

export const StyledItemIndicator = styled(SelectPrimitive.ItemIndicator)`
  position: absolute;
  left: 0;
  width: 2.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const scrollButtonStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.5rem;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${violet.violet11};
  cursor: default;
`;

export const StyledScrollUpButton = styled(SelectPrimitive.ScrollUpButton)`
  ${scrollButtonStyles}
`;

export const StyledScrollDownButton = styled(SelectPrimitive.ScrollDownButton)`
  ${scrollButtonStyles}
`;
