import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function ThirdStep() {
  return (
    <S.Wrapper>
      <h1 id="v-e-3">
        3. ELABORAÇÃO DO PLANO DE INOVAÇÃO & TECNOLOGIA DA REDE
      </h1>

      <p>
        Vamos construir um Plano de Inovação & Tecnologia para a sua rede? Além
        de informações, temos uma ferramenta para lhe apoiar nesta importante
        etapa da Jornada!
      </p>

      <h2 id="v-e-3-s-1">3.1 Construa o Plano de Inovação & Tecnologia</h2>

      <p>
        Agora que a rede já conhece qual o nível de adoção de tecnologias de
        suas escolas, é hora de elaborar o Plano de Inovação & Tecnologia!
      </p>

      <p>
        Como o nome indica, trata-se de um instrumento de planejamento, no qual
        a equipe interdisciplinar (<a href="/"><i>clique aqui</i></a> se você perdeu a
        explicação sobre a formação da equipe) define o objetivo geral e os
        objetivos específicos do plano, bem como as ações, prazos, responsáveis
        e custos (quando houver) para cada dimensão (visão, competência,
        recursos digitais educacionais e infraestrutura).
      </p>

      <Card
        heading="PLANO DE"
        title="INOVAÇÃO E TECNOLOGIA"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="planoDeInovacao"
        to="/planos"
      />

      <p>
        Se você ainda não fez o diagnóstico do nível de adoção de tecnologias de
        suas escolas, sugerimos que volte para a Etapa 2 antes de começar o
        Plano.
      </p>

      <h2 id="v-e-3-s-2">3.2 Acompanhe o Plano de Inovação & Tecnologia</h2>

      <p>
        Busque manter o Plano de Inovação & Tecnologia sempre atualizado para
        que haja ritmo, transparência e clareza sobre a implementação das ações
        planejadas. Na ferramenta do Plano é possível sinalizar e acompanhar o
        status de cada ação utilizando as marcações{" "}
        <span className="gray">"Não iniciado"</span>,{" "}
        <span className="brown">"Em andamento"</span>,{" "}
        <span className="green">"Concluído"</span> ou{" "}
        <span className="red">"Atrasado"</span>. Verifique periodicamente a
        situação das ações e a eventual necessidade de replanejamento.
      </p>

      <Card
        heading="ACOMPANHE"
        title="SEU PLANO"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="acompanheSeuPlano"
        to="/planos/acompanhamento"
      />

      <p>
        Confira neste material rotinas para o acompanhamento do Plano (Novo):
      </p>

      <Card
        heading="ROTINAS PARA O"
        title="ACOMPANHAMENTO"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="rotinasParaAcompanhamento"
        link="https://docs.google.com/document/d/18BFMLXJ06DjMhM-ZtOvtSNCKUphYWcGKYqU7q6TFJxg/edit"
      />
    </S.Wrapper>
  );
}
