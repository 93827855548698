import conceito from "assets/images/menu-etapas/conceito.svg";
import diagnostico from "assets/images/menu-etapas/diagnostico.svg";
import planejamento from "assets/images/menu-etapas/planejamento.svg";
import monitoramento from "assets/images/menu-etapas/monitoramento.svg";

const MENU_RED_STEPS = [
  {
    key: "concept",
    title: "CONCEITO",
    image: conceito,
    alt: "conceito",
    stepName: "ENTENDENDO RED",
    items: [
      {
        content: "Conceito de RED",
        background: false,
      },
      {
        content: "Como são organizados e classificados",
        background: false,
      },
      {
        content: "Taxonomia de RED",
        background: true,
        link: "https://reds.cieb.net.br/",
      },
    ],
  },
  {
    key: "diagnosis",
    title: "DIAGNÓSTICO",
    image: diagnostico,
    alt: "diagnóstico",
    stepName: "ENTENDA AS DEMANDAS DA REDE E QUAIS RED SÃO MAIS ADEQUADOS",
    items: [
      {
        content:
          "Faça o levantamento e a priorização de quais RED a rede precisa:",
        background: false,
      },
      {
        content: "Ferramenta de Identificação da Demanda por RED",
        background: true,
        to: "/jornada/red",
      },
    ],
  },
  {
    key: "planning",
    title: "PLANEJAMENTO",
    image: planejamento,
    alt: "planejamento",
    stepName: "APRENDA A FAZER CURADORIA E AQUISIÇÃO DA RED",
    items: [
      {
        content: "Faça uma pesquisa de mercado:",
        background: false,
      },
      {
        content: "Plataforma Edutec",
        background: true,
        link: "https://plataformaedutec.cieb.net.br/",
      },
      {
        content: "Contrate ou adote o RED para sua rede:",
        background: false,
      },
      {
        content: "Toolkit de Compras",
        background: true,
        link: "https://toolkit.plataformaedutec.com.br/",
      },
      {
        content: "Sabia como especificar um RED para aquisição:",
        background: false,
      },
      {
        content: "Guia de Especificação de RED",
        background: true,
        link: "https://reds.cieb.net.br/",
      },
    ],
  },
  {
    key: "monitoring",
    title: "MONITORAMENTO",
    image: monitoramento,
    alt: "monitoramento",
    stepName:
      "O CAMINHO ATÉ OS/AS ESTUDANTES: FASE DE IMPLEMENTAÇÃO E ACOMPANHAMENTO",
    items: [
      {
        content: "Dicas de implementação",
        background: false,
      },
      {
        content: "Acompanhe sua rede:",
        background: false,
      },
      {
        content:
          "Ferramenta de diagnóstico de adoção de tecnologia nas escolas",
        background: true,
        link: "https://guiaedutec.com.br/escola",
      },
    ],
  },
];

export default MENU_RED_STEPS;
