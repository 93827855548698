import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/calculadora/home/banner.jpg";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const InfraCalculatorTitle = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: end;
  background-size: cover;
  height: 60rem;
  justify-content: center;
  > div {
    > div {
      ${container}
    }
    > h1 {
      > span {
        color: ${({ theme }) => theme.colors.green};
        font-weight: 500;
        font-weight: 500;
      }
      color: ${({ theme }) => theme.colors.white};
      font-size: 5rem;
      font-weight: 500;
      margin-bottom: 2rem;
    }
    > p {
      text-align: center;
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.9rem;
    }
  }
`;

export const EquipmentsTableWrapper = styled.div`
  ${container};
  display: flex;
  flex-direction: column;

  gap: 5rem;

  > div:nth-child(1) {
    box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    padding: 4rem 6rem 4rem 6rem;
    background-color: ${({ theme }) => theme.colors.white};

    > p {
      font-size: 1.8rem;

      > strong:nth-child(1) {
        color: ${({ theme }) => theme.colors.green};
      }
    }
  }
`;

export const FilterWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 4rem;

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > label {
      font-size: 1.6rem;
      font-weight: 500;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const Button = styled.a`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  align-self: flex-end;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1.3rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 4.6rem;
  text-decoration: none;
  cursor: pointer;
  > svg {
    font-size: 2rem;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 6rem 6rem 4rem 6rem;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 1rem !important;

  > p {
    font-size: 2rem;
    > a {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ReportProblem = styled(ReportProblemIcon)`
  &&& {
    cursor: pointer;
    font-size: 10rem;
    color: #c90a12;
    margin-bottom: 3rem;
  }
`;
