import {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";

const MenuContext = createContext();

function MenuProvider({ children }) {
  const [open, setOpen] = useState({
    vision: [false, false, false, false],
    competence: [false, false, false, false],
    red: [false, false, false, false],
    infra: [false, false, false, false],
  });

  const [areAllOpen, setAreAllOpen] = useState({
    vision: false,
    competence: false,
    red: false,
    infra: false,
  });

  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = useCallback((state) => {
    setShowMenu(state);
  }, []);

  const handleMenu = useCallback((dimension, index) => {
    setOpen((open) => {
      const aux = [false, false, false, false];
      aux[index] = !open[dimension][index];
      return { ...open, [dimension]: [...aux] };
    });
  }, []);

  const openOrCloseAll = useCallback(
    (dimension) => {
      let aux = !areAllOpen[dimension];
      if (aux) {
        aux = [true, true, true, true];
        setOpen({ ...open, [dimension]: [...aux] });
      } else {
        aux = [false, false, false, false];
        setOpen({ ...open, [dimension]: [...aux] });
      }
      setAreAllOpen({ ...areAllOpen, [dimension]: !areAllOpen[dimension] });
    },
    [open, areAllOpen]
  );

  const openMemo = useMemo(() => open, [open]);
  const areAllOpenMemo = useMemo(() => areAllOpen, [areAllOpen]);

  return (
    <MenuContext.Provider
      value={{
        handleMenu,
        openOrCloseAll,
        areAllOpen: areAllOpenMemo,
        open: openMemo,
        showMenu,
        handleShowMenu,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
}

const useMenu = () => useContext(MenuContext);

export { MenuProvider, useMenu };
