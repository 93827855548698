import React from "react";
import * as S from "./styles";
import PropTypes from "prop-types";

function StepCircles({ numberOfSteps, dimension }) {
  if (numberOfSteps === 0) {
    return <S.Wrapper></S.Wrapper>;
  }
  return (
    <S.Wrapper>
      <S.Circle size="large" dimension={dimension}></S.Circle>
      {[...Array(numberOfSteps - 1).keys()].map((step) => (
        <React.Fragment key={step}>
          <S.Circle size="small" dimension={dimension}></S.Circle>
          <S.Circle size="small" dimension={dimension}></S.Circle>
          <S.Circle size="large" dimension={dimension}></S.Circle>
        </React.Fragment>
      ))}
    </S.Wrapper>
  );
}

StepCircles.propTypes = {
  numberOfSteps: PropTypes.number.isRequired,
  dimension: PropTypes.oneOf(["vision", "competence", "red", "infra"]),
};

export default StepCircles;
