import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";
import equipe from "assets/images/jornada-visao/equipe.jpg";

export default function SecondStep() {
  return (
    <S.Wrapper>
      <h1 id="v-e-2">
        2. CONHEÇA SUA REDE: DIAGNÓSTICO SOBRE O NÍVEL DE ADOÇÃO DE TECNOLOGIAS
        NAS ESCOLAS
      </h1>

      <p>
        Nesta etapa, você vai entender como e por que diagnosticar o nível de
        adoção de tecnologias digitais da sua rede. Tem uma ferramenta para
        apoiar esse processo! Confira!
      </p>

      <h2 id="v-e-2-s-1">2.1 Por que realizar o diagnóstico da sua rede</h2>

      <p>
        Conhecer o nível de adoção de tecnologias das escolas de sua rede ajuda
        a planejar melhor e com mais efetividade as ações previstas nesse
        processo de transformação digital.
      </p>

      <p>
        O CIEB tem uma ferramenta para fazer esse diagnóstico e ela consiste em
        um questionário a ser respondido por todas as escolas com perguntas
        sobre as quatro dimensões mencionadas:{" "}
        <span className="brown">
          visão, competências, recursos educacionais digitais e infraestrutura.
        </span>{" "}
      </p>

      <p>
        Com base nas respostas, a ferramenta disponibiliza um relatório e indica
        qual dimensão deve ser foco das ações da secretaria, além de fornecer
        orientações para avançar de nível em cada dimensão.
      </p>

      <h2 id="v-e-2-s-2">2.2 Monte a equipe de trabalho</h2>

      <p>
        Antes de realizar o diagnóstico, sugere-se que a secretaria nomeie
        representantes (diretores/as, coordenadores/as ou gerentes) para compor
        uma equipe interdisciplinar que será responsável pelo planejamento e
        aplicação do questionário diagnóstico e, posteriormente, pela elaboração
        e implementação do Plano de Inovação & Tecnologia.
      </p>

      <p>
        Essa equipe normalmente é composta por pessoas de diferentes áreas da
        secretaria como: área pedagógica (currículo, formação, gestão escolar),
        área de compras/aquisição e área de tecnologia (TI e TE).
      </p>

      <img src={equipe} alt="Equipe" />

      <p>
        A principal vantagem da criação dessa equipe interdisciplinar é o
        desenvolvimento de gestores/as{" "}
        <span className="brown">líderes/as de inovação na rede</span>. Nesse
        contexto, diferentes{" "}
        <span className="brown">áreas com poder decisório na secretaria</span>{" "}
        constroem uma <span className="brown">visão compartilhada</span> do uso
        de tecnologias e conquistam uma{" "}
        <span className="brown">atuação integrada</span>.
      </p>

      <h2 id="v-e-2-s-3">
        2.3 Conheça sua rede: Ferramenta de diagnóstico de adoção de tecnologia
        nas escolas
      </h2>

      <p>
        Chegou a hora de planejar e aplicar o diagnóstico do nível de adoção de
        tecnologias nos processos de ensino, aprendizagem e gestão das suas
        escolas!
      </p>

      <p>
        Feito o diagnóstico, você receberá um relatório descrevendo o nível de
        adoção de tecnologias em cada uma das quatro dimensões (visão,
        competência, recursos educacionais digitais e infraestrutura) e
        sugestões de como avançar de nível.
      </p>

      <Card
        heading="DIANÓSTICO DE"
        title="NÍVEIS DE ADOÇÃO"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="nivelDeAdocao"
        link="https://guiaedutec.com.br/escola"
      >
        Clique aqui para realizar o diagnóstico na sua rede.
      </Card>
    </S.Wrapper>
  );
}
