import styled from "styled-components";
import marco from "assets/images/jornada-visao/marco-conceitual.png";
import matriz from "assets/images/jornada-visao/matriz-lite.png";
import nivelDeAdocao from "assets/images/jornada-visao/nivel-de-adocao.png";
import planoDeInovacao from "assets/images/jornada-visao/plano-de-inovacao.png";
import rotinasParaAcompanhamento from "assets/images/jornada-visao/rotinas-para-acompanhamento.png";
import acompanheSeuPlano from "assets/images/jornada-visao/acompanhe-seu-plano.png";

import competenciasDigitais from "assets/images/jornada-competencia/competencias-digitais.png";
import matrizLiteCompetencia from "assets/images/jornada-competencia/matriz-lite-competencia.png";
import notaTecnica15 from "assets/images/jornada-competencia/nota-tecnica-15.png";

import guiaDeEspecificacao from "assets/images/jornada-red/guia-de-especificacao.png";
import investimentoDeCuradoria from "assets/images/jornada-red/investimento-de-curadoria.png";
import notasTecnicasRed from "assets/images/jornada-red/notas-tecnicas-red.png";
import plataformaEdutec from "assets/images/jornada-red/plataforma-edutec.png";
import toolkitDeCompras from "assets/images/jornada-red/toolkit-de-compras.png";

import calculadoraDeInfra from "assets/images/jornada-infra/calculadora-de-infra.png";
import guiaDeConectividade from "assets/images/jornada-infra/guia-de-conectividade.png";
import mapaDeConectividade from "assets/images/jornada-infra/mapa-de-conectividade.png";
import notasTecnicas from "assets/images/jornada-infra/notas-tecnicas.png";
import parametrosDeInfraestrutura from "assets/images/jornada-infra/parametros-de-infraestrutura.png";
import toolkitDeComprasInfra from "assets/images/jornada-infra/toolkit-de-compras-infra.png";

const BACKGROUNDS = {
  vision: {
    marco,
    matriz,
    nivelDeAdocao,
    planoDeInovacao,
    rotinasParaAcompanhamento,
    acompanheSeuPlano,
  },
  competence: { competenciasDigitais, matrizLiteCompetencia, notaTecnica15 },
  red: {
    guiaDeEspecificacao,
    investimentoDeCuradoria,
    notasTecnicasRed,
    plataformaEdutec,
    toolkitDeCompras,
  },
  infra: {
    calculadoraDeInfra,
    guiaDeConectividade,
    mapaDeConectividade,
    notasTecnicas,
    parametrosDeInfraestrutura,
    toolkitDeComprasInfra,
  },
};

const COLORS = {
  vision: "#f1d449",
  competence: "#e62270",
  red: "#00a8e6",
  infra: "#67ae3f",
};

const COLORS_BUTTON = {
  competence: "#af0161",
  vision: "#615310",
  red: "#004b66",
  infra: "#00672f",
};

export const Wrapper = styled.article`
  width: 54rem;
  height: 17rem;
  background-color: ${({ dimension }) => COLORS[dimension]};
  background-image: url(${({ backgroundName, dimension }) =>
    BACKGROUNDS[dimension][backgroundName]});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: ${({ dimension, theme }) =>
    dimension === "vision" ? theme.colors.black : theme.colors.white};
  border-radius: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  margin: 4rem 0;
  padding: 0.5rem 4rem;
  position: relative;

  &::before {
    position: absolute;
    content: "";
    width: 0.9rem;
    height: 0.9rem;
    background-color: ${({ theme }) => theme.colors.red};
    top: 3.98rem;
    left: -0.82rem;
    /* transform: rotate(30deg); */
    z-index: 0;
    clip-path: polygon(25% 0%, 100% 0%, 95% 100%);
    display: ${({ disabled }) => !disabled && "none"};
  }
  > div:nth-child(1) {
    position: absolute;
    left: -0.6rem;
    top: 1.5rem;
    padding: 0.5rem 1.3rem 0.5rem 2.3rem;
    font-weight: 500;
    font-size: 1.3rem;
    background-color: ${({ theme }) => theme.colors.red};
    width: fit-content;
    display: ${({ disabled }) => !disabled && "none"};
  }

  > div:nth-child(2) {
    width: 29rem;
    height: 100%;
    display: flex;

    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    > p {
      font-size: 1.3rem;
    }

    > div {
      > span {
        font-size: 1.3rem;
        font-weight: 500 !important;
      }
      > h1 {
        font-size: 2.4rem !important;
        margin: 0 !important;
        font-weight: bold !important;
      }
    }

    > p {
      margin: 0 !important;
    }

    > a {
      background-color: ${({ dimension }) => COLORS_BUTTON[dimension]};
      color: ${({ theme }) => theme.colors.white};
      border: none;
      padding: 1.1rem 2.5rem;
      border-radius: 1.5rem;
      font-size: 1.1rem;
      margin-top: 1.8rem;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;
