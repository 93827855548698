import React from "react";
import Card from "../../../../components/Card";
import levels from "assets/images/jornada-competencia/levels.jpg";
import feedbacks from "assets/images/jornada-competencia/feedbacks.jpg";
import * as S from "./styles";
import { Link } from "react-router-dom";

export default function SecondStep() {
  return (
    <S.Wrapper>
      <h1 id="c-e-2">
        2. DIAGNÓSTICO DO NÍVEL DE COMPETÊNCIAS DIGITAIS DOS/AS DOCENTES
      </h1>

      <p>
        O mapeamento do nível de competências digitais dos/as docentes da rede é
        essencial para planejar programas efetivos de formação continuada.
      </p>

      <h2 id="c-e-2-s-1">
        2.1 O que é a Autoavaliação de Competências Digitais ?
      </h2>

      <p>
        Trata-se de uma ferramenta, online e gratuita, que, por meio de um
        questionário, baseado na{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href="https://cieb.net.br/cieb-notas-tecnicas-15-autoavaliacao-de-competencias-digitais-de-professores/"
          >
            <i>Matriz de Competências Digitais Docentes</i>
          </a>
        , possibilita aos/às professores/as identificarem suas competências
        digitais e planejarem seu desenvolvimento profissional.
      </p>

      <p>
        A ferramenta de autoavaliação mapeia o desenvolvimento das competências
        digitais docentes em cinco níveis:
      </p>

      <img src={levels} alt="Níveis" />

      <p>
        Ao responderem à autoavaliação, os/as docentes recebem, automaticamente,
        uma devolutiva com a seguinte estrutura para cada uma das 12
        competências digitais:
      </p>

      <img src={feedbacks} alt="Devolutiva" />

      <p>
        E, claro, para que seja possível planejar formações continuadas que
        atendam às necessidades da rede, a ferramenta de autoavaliação, quando
        respondida pelos/as docentes da rede, emite um relatório, organizado por
        área e por competências da matriz, indicando o percentual de docentes em
        cada um dos cinco níveis de desenvolvimento de competências digitais. O
        sistema de autoavaliação, portanto, produz automaticamente dois
        relatórios: uma devolutiva para o docente respondente e um relatório
        para o gestor da rede que compila todos os dados dos docentes da rede
        que responderam ao questionário.
      </p>

      <Card
        heading="AUTOAVALIAÇÃO DE"
        title="COMPETÊNCIAS DIGITAIS"
        buttonText="LEIA MAIS"
        dimension="competence"
        backgroundName="competenciasDigitais"
        link="https://guiaedutec.com.br/educador"
      />

      <p>
        Além do diagnóstico do nível de desenvolvimento de competências digitais
        dos docentes, é importante ter mapeamento da rede em relação à dimensão
        de competência como um todo. E para isso, você pode mobilizar as escolas
        da rede de ensino para responderem ao Guia Edutec Diagnóstico. Para
        saber mais, acesse a Jornada de Visão.
      </p>

      <h2 id="c-e-2-s-2">2.2 Como aplicar a Autoavaliação em sua rede</h2>
      <p>
        Para conseguir um diagnóstico representativo da rede, é preciso engajar
        os/as docentes para que preencham a Autoavaliação de Competências
        Digitais. Sugerimos aqui alguns passos para mobilizá-los/as:
      </p>

      <h3>Composição de equipe</h3>

      <p>
        Na Jornada de Visão, explicamos a importância de constituir uma equipe
        interdisciplinar, composta, inclusive, por pessoas da área responsável
        pela formação continuada de professores/as. Elas terão um papel
        fundamental nesta etapa. Engaje-as primeiro!
      </p>

      <p>
        Ainda não montou sua equipe?{" "}
        <Link to="/jornada/visao">
          <i className="pink">Vamos fazer isso agora?</i>
        </Link>
      </p>

      <h3>Planejamento</h3>

      <p>
        Planeje ações de engajamento para atingir 85% do corpo docente da rede.
        É um parâmetro que pode dar um bom índice do nível de competências
        digitais dos docentes da rede. Alguns exemplos de ações: alinhamento com
        gestores/as escolares, ações de comunicação, indicação dos/as
        responsáveis, definição de cronograma para a aplicação da Autoavaliação
        etc.
      </p>

      <h3>Divulgação</h3>

      <p>
        Apresente a ferramenta de Autoavaliação de Competências Digitais aos/às
        professores/as e aos/às gestores/as escolares, que são atores
        importantes para estimular a participação dos/as docentes. Isso pode ser
        feito nas redes sociais da secretaria, em ofícios, em comunicados
        enviados por e-mail aos/às professores/as etc.
      </p>

      <p>
        Para lhe ajudar, preparamos um kit de materiais para comunicação e
        mobilização da rede.
      </p>

      <p>
        <a
          href="https://guiaedutec.com.br/mapeamento-professor/divulgacao"
          target="_blank"
          rel="noreferrer"
        >
          <i className="pink">Acesse o kit</i>
        </a>{" "}
        e mobilize os/as professores/as de sua rede a realizarem a Autoavaliação
        de Competências Digitais!
      </p>

      <h3>Monitoramento</h3>

      <p>
        Acompanhe o avanço da aplicação do questionário por meio da{" "}
          <a
            href="https://guiaedutec.com.br/gestor"
            target="_blank"
            rel="noreferrer"
          >
            <i>ferramenta de Autoavaliação</i>
          </a>
       {" "}
        e replaneje as ações de engajamento, se necessário.
      </p>

      <p>
        Vamos mapear o nível de competências digitais dos/as professores da
        rede?{" "}
        
          <a
            href="https://guiaedutec.com.br/gestor"
            target="_blank"
            rel="noreferrer"
          ><i >
            Acesse a ferramenta</i>
          </a>
        
        .
      </p>

      <h2 id="c-e-2-s-3">2.3 Como analisar os resultados</h2>

      <p>Chegou a hora de analisar os resultados!</p>

      <p>
        Acessando a ferramenta de Autoavaliação, você terá acesso ao relatório
        da rede e poderá conferir duas formas de visualização dos dados. Entenda
        o enfoque de cada uma:
      </p>

      <h3>Situação atual</h3>

      <p>
        Apresenta a quantidade de professores/as da rede, que responderam ao
        questionário, por nível de desenvolvimento das competências digitais.
        Use esta visualização para mapear quais competências os/as docentes
        ainda precisam desenvolver e direcionar os esforços de formação na rede
        de ensino.
      </p>

      <h3>Comparativo</h3>

      <p>
        Apresenta o resultado da rede de ensino em comparação com outras no
        Brasil.
      </p>

      <p>
        Use esta versão para localizar a posição da rede de ensino no cenário
        nacional, identificar tendências e se inspirar em práticas de sucesso.
      </p>
    </S.Wrapper>
  );
}
