import React from "react";
import Plan from "pages/Plans/components/Plans";
import { GoalActionsTableProvider } from "hooks/use-goal-actions-table";
//
export default function VisionPlan() {
  const dimension = "vision";
  return (
    <GoalActionsTableProvider dimension={dimension}>
      <Plan
        dimension={dimension}
        previous="/planos/vigencia-objetivo-geral"
        next="/planos/competencia"
      />
    </GoalActionsTableProvider>
  );
}
