import React from "react";
import * as S from "./styles";

export default function FourthStep() {
  return (
    <S.Wrapper>
      <h1 id="c-e-4">4. ACOMPANHAMENTO</h1>

      <p>
        Fazer o acompanhamento do processo de desenvolvimento das competências
        digitais dos/as docentes é essencial para entender se os esforços da
        rede estão sendo bem direcionados. Veja, a seguir, como esse
        acompanhamento pode ser feito!
      </p>

      <h2 id="c-e-4-s-1">
        4.1 Como acompanhar o desenvolvimento de competências digitais
      </h2>

      <p>
        As oportunidades para o desenvolvimento de competências digitais devem
        ser oferecidas constantemente aos/às docentes, pois há sempre novas
        tecnologias e inovações para aprenderem. O acompanhamento dos resultados
        também deve ser uma prática contínua.
      </p>

      <p>E como isso pode ser feito? </p>

      <p>
        Uma maneira de acompanhar o desenvolvimento das competências digitais é
        fazer uma observação empírica da evolução das práticas pedagógicas.
      </p>

      <p>
        Para isso, estimular que os/as docentes façam uma autorreflexão sobre
        seu desenvolvimento e compartilhe essa impressão com seus pares pode ser
        uma prática interessante. Mas atenção: é importante criar espaços para
        essa troca!
      </p>

      <h3>Sugestões de ações para esse fim:</h3>

      <ul>
        <li>
          Sugira a gestores/as escolares e/ou coordenadores/as para criarem
          grupos em que professores e professoras possam compartilhar e debater
          o desenvolvimento de suas práticas pedagógicas em relação ao uso de
          tecnologias;
        </li>

        <li>
          Incentive o trabalho em pares, para que um colabore com o outro,
          estimulando uma troca de saberes sobre tecnologias na educação;
        </li>

        <li>
          Identifique os/as docentes mais desenvolvidos/as e que possam atuar
          como mentores/as. Eles/as podem fazer um acompanhamento mais
          personalizado e dar feedbacks imediatos àqueles/as que estão no
          processo de incorporação das tecnologias digitais em sua prática
          pedagógica;
        </li>

        <li>
          Crie um observatório permanente na rede para acompanhar o progresso
          dos/as docentes e apoiá-los/as com novas oportunidades de
          desenvolvimento;
        </li>

        <li>
          Destaque experiências exitosas nas redes sociais da secretaria e
          incentive a comunidade de práticas.
        </li>
      </ul>

      <p>
        Essas são apenas algumas sugestões que podem ser adaptadas à realidade
        da sua rede de ensino, mas você e sua equipe podem pensar em muitas
        outras!
      </p>

      <h2 id="c-e-4-s-2">
        4.2 Como posso saber se houve avanço no nível de desenvolvimento das
        competências digitais
      </h2>

      <p>
        Você pode acompanhar os avanços aplicando a{" "}
        <a
          href="https://guiaedutec.com.br/educador"
          rel="noreferrer"
          target="_blank"
          className="pink"
        >
          <i>Autoavaliação de competências digitais</i>
        </a>{" "}
        novamente e realizar um novo mapeamento do nível de competências
        digitais . Recomendamos fazer com ao menos seis meses entre uma e outra.
        A partir dos resultados, recomece o processo, ou seja, planeje novas
        formações e trace novos objetivos.
      </p>

      <p>
        Como você pôde ver, a Jornada de Competências não tem um fim definido.
        Seu processo é cíclico, pois o desenvolvimento de competências digitais
        dos/as docentes deve ser uma prática constante – como todo processo de
        aprendizagem!
      </p>
    </S.Wrapper>
  );
}
