import styled from "styled-components";
import banner from "assets/images/calculadora/012.svg";

import { container } from "global-styles/shared-styles";

export const Wrapper = styled.div`
  margin-top: 7rem;
  background-color: #f0f0f0;
  font-family: ${({ theme }) => theme.fonts.poppins};
`;

export const Header = styled.header`
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 46rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #85c440;
`;

export const Content = styled.div`
  ${container};
  display: flex;
  flex-direction: column;
  align-items: center;

  .loading {
    margin: 5rem;
    display: flex;
    justify-content: center;
  }

  > div:nth-child(1) {
    > article {
      > div:nth-child(1) {
        padding-top: 3.5rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        > p {
          font-size: 2rem;
          line-height: 1.5;
          color: ${({ theme }) => theme.colors.blue5};
        }
      }

      > div:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(25rem, 1fr));
        gap: 4rem;
        place-items: center;
      }

      > div {
        margin-bottom: 5rem;
      }
    }
  }
`;

export const Start = styled.div`
  height: 20rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  h1 {
    span {
      color: ${({ theme }) => theme.colors.green};
    }
    color: ${({ theme }) => theme.colors.blue5};
    font-size: 1.9rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  > button {
    background-color: #141d3c;
    color: ${({ theme }) => theme.colors.white};
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    border: none;
    font-size: 1.8rem;
    cursor: pointer;
  }
`;

// .modal_infra{
//   h2{
//     font-weight: 500;
//     font-size: 1.2em;
//   }

//   .titulo_descritivo{
//     &:not(:first-child){
//       margin-top: 20px;
//     }
//     font-weight: 500;
//     display: flex;
//     justify-content: space-between;
//     border-bottom: 1px solid lightgray;
//     padding-bottom: 5px;
//     margin-bottom: 5px;
//     color: rgb(38,55,106);
//   }

// }

export const EquipamentsModal = styled.div`
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
  gap: 4rem;
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 2rem;

  > div:nth-child(1) {
    display: flex;
    gap: 2rem;
    align-items: center;
    > h2 {
      font-size: 1.8rem;
      font-weight: 500;
    }
    > img {
      width: 7rem;
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    > div {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > p {
        color: ${({ theme }) => theme.colors.blue5};
      }
    }
  }
`;
