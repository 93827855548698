import conceito from "assets/images/menu-etapas/conceito.svg";
import diagnostico from "assets/images/menu-etapas/diagnostico.svg";
import planejamento from "assets/images/menu-etapas/planejamento.svg";
import monitoramento from "assets/images/menu-etapas/monitoramento.svg";

const MENU_INFRA_STEPS = [
  {
    key: "concept",
    title: "CONCEITO",
    image: conceito,
    alt: "conceito",
    stepName: "PLANEJAR A INFRAESTRUTURA E CONECTIVIDADE",
    items: [
      {
        content: "Conceitos em infraestrutura e conectividade",
        background: false,
      },
      {
        content: "Níveis de adoção de tecnologia",
        background: true,
        link: "https://guiaedutec.com.br/",
      },

      {
        content: "Parâmetros de infraestrutura",
        background: true,
        link: "https://guiaedutec.com.br/calculadora-infra/parametros",
      },
    ],
  },
  {
    key: "diagnosis",
    title: "DIAGNÓSTICO",
    image: diagnostico,
    alt: "diagnóstico",
    stepName:
      "DIAGNÓSTICO DA SITUAÇÃO DE INFRAESTRUTURA E CONECTIVIDADE DAS REDES",
    items: [
      {
        content: "Diagnóstico da rede de ensino",
        background: false,
      },
      {
        content: "Mapa de conectividade",
        background: true,
        link: "https://conectividadenaeducacao.nic.br/",
      },
      {
        content: "Inventário de infraestrutura",
        background: true,
        link: "https://guiaedutec.com.br/escola",
      },
    ],
  },
  {
    key: "planning",
    title: "PLANEJAMENTO",
    image: planejamento,
    alt: "planejamento",
    stepName:
      "PLANEJAMENTO, AQUISIÇÃO DE INFRAESTRUTURA E CONTRATAÇÃO DE CONECTIVIDADE",
    items: [
      {
        content: "Calculadora de infraestrutura",
        background: true,
        link: "https://guiaedutec.com.br/calculadora-infra",
      },
      {
        content: "Guia de Conectividade",
        background: true,
        link: "https://cieb.net.br/wp-content/uploads/2021/09/Guia-Conectividade-na-Educacao.pdf",
      },
    ],
  },
  {
    key: "monitoring",
    title: "MONITORAMENTO",
    image: monitoramento,
    alt: "monitoramento",
    stepName: "ACOMPANHAMENTO",
    items: [],
  },
];

export default MENU_INFRA_STEPS;
