import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import Select from "shared-components/Select";
import image048 from "assets/images/red-demands/048.svg";
import GroupSection from "../../components/GroupSection";
import { useGetAnswersQuery } from "services/red-demands";
import { useSurveyListQuery } from "services/common";
import { getSchoolSurveySchedules } from "utils/surveys";
import { CircularProgress } from "@mui/material";
import Masonry from "react-responsive-masonry";
import { useNavigate } from "react-router-dom";

const DEMANDS = {
  CONTEUDO_ESTRUTURADO: {
    name: (
      <h1>
        CONTEÚDO
        <br /> ESTRUTURADO
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam recursos, como vídeos, imagens, textos,
        áudios, simulações, jogos educativos ou cursos online, com o objetivo de
        desenvolver habilidades e conteúdos previstos no currículo. Esses
        recursos são encaminhados diretamente aos/às estudantes ou
        disponibilizados em repositórios digitais e/ou plataformas de oferta de
        conteúdo online, como banco de questões ou biblioteca de livros.
      </p>
    ),
  },
  AUTORIA_E_COLABORACAO: {
    name: (
      <h1>
        AUTORIA E<br /> COLABORAÇÃO
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam, de forma individual ou colaborativa, um
        ou mais softwares ou aplicativos para criar apresentações de slides,
        desenvolver projetos em vídeo, escrever e distribuir livros digitais
        e/ou realizar simulações em 3D. Nos momentos de reforço, docentes e
        estudantes interagem online por meio de ferramentas de vídeo, áudio e/ou
        texto.
      </p>
    ),
  },
  ESPACOS_DE_APRENDIZAGEM: {
    name: (
      <h1>
        ESPAÇOS DE
        <br /> APRENDIZAGEM
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam ferramentas ou plataformas que
        disponibilizam trilhas de conteúdos (personalizadas ou não), materiais
        e/ou roteiros de estudo (atividades). Os/as docentes utilizam ainda
        recursos digitais para criar planos de aula, apresentar conteúdos,
        receber atividades realizadas pelos/as estudantes e criar experiências
        de aprendizagem presenciais, remotas ou híbridas com uso de tecnologias
        digitais.
      </p>
    ),
  },
  GESTAO_PEDAGOGICA: {
    name: (
      <h1>
        GESTÃO <br />
        PEDAGÓGICA
      </h1>
    ),
    description: (
      <p>
        Coordenador/a pedagógico/a, docentes e estudantes utilizam uma ou mais
        ferramentas para gerenciar a notas dos/das alunos/as se comunicar com a
        comunidade escolar, acompanhar a execução do currículo da rede ao longo
        do ano letivo, do planejamento pedagógico docente e/ou da avaliação da
        aprendizagem ou ainda para monitorar o uso pedagógico de tablets ou
        computadores pelos estudantes.
      </p>
    ),
  },
  GESTAO_ADMINISTRATIVA: {
    name: (
      <h1>
        GESTÃO <br />
        ADMINISTRATIVA
      </h1>
    ),
    description: (
      <p>
        Gestor/a escolar e profissionais do setor administrativo utilizam um ou
        mais sistemas para gerenciar matrículas de estudantes, grade horária,
        alimentação escolar, contratos com fornecedores, verbas disponíveis e
        fluxo de caixa, vida funcional dos profissionais da escola, entre outros
        processos.
      </p>
    ),
  },
};

const COLORS = ["#f3c740", "#f68c42", "#f26d68", "#6bac1e"];

const ANSWERS = {
  "checkbox-0": "CONTEUDO_ESTRUTURADO",
  "checkbox-1": "AUTORIA_E_COLABORACAO",
  "checkbox-2": "ESPACOS_DE_APRENDIZAGEM",
  "checkbox-3": "GESTAO_PEDAGOGICA",
  "checkbox-4": "GESTAO_ADMINISTRATIVA",
};

export default function SchoolResults() {
  const navigate = useNavigate();
  const [surveyScheduleId, setSurveyScheduleId] = useState("");

  const {
    data: surveyList,
    isLoading: surveyListLoading,
    isSuccess: surveyListSuccess,
  } = useSurveyListQuery();

  const { data: getAnswers } = useGetAnswersQuery(surveyScheduleId.value);

  useEffect(() => {
    if (surveyListSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      setSurveyScheduleId({
        label: schoolSchedules[0]?.name,
        value: schoolSchedules[0]?.id?.$oid,
      });
    }
  }, [surveyList, surveyListSuccess]);

  if (getAnswers?.status !== "Completed") {
    return (
      <Layout hasNavigationBar name="redDemands">
        <Helmet title="Guia EduTec | Planos - Início" />
        <S.Wrapper>
          <S.BannerWrapper>
            <S.Banner />
          </S.BannerWrapper>
          <section>
            <div>
              <h1>Olá, Gestor !</h1>
              <p>
                Esta é a devolutiva do questionário de levantamento de Demanda
                de Recursos Educacionais Digitais. Responda ao questionário para
                conseguir visualizar os resultados.
              </p>

              <button onClick={() => navigate("/demanda-de-red/escola")}>
                IR PARA O QUESTIONÁRIO
              </button>
            </div>
          </section>
        </S.Wrapper>
      </Layout>
    );
  }

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>

        <section>
          <div>
            <h1>Olá, Gestor !</h1>
            <p>
              Esta é a devolutiva do questionário de levantamento de Demanda de
              Recursos Educacionais Digitais. Todas as informações aqui são o
              resultado das suas respostas.
            </p>
            <p>
              Abaixo você encontrará a sua demanda principal para RED, e os
              tipos de softwares que fazem parte desse grupo.
            </p>

            <div>
              <label htmlFor="years">
                <strong>Ano</strong>
              </label>
              {surveyListLoading ? (
                <CircularProgress size={30} />
              ) : (
                <Select
                  labelId="years"
                  id="years"
                  name="years"
                  value={surveyScheduleId}
                  onChange={(event) => {
                    // setScheduleNameAndSurveyId(event.value);
                    setSurveyScheduleId(event);
                  }}
                  options={getSchoolSurveySchedules(surveyList?.surveys)
                    .map((schedule) => ({
                      label: schedule?.name,
                      value: schedule?.id?.$oid,
                    }))
                    .filter(({ label }) => label >= "2022")}
                />
              )}
            </div>
          </div>
        </section>

        <section>
          <p>Esta é a principal demanda da sua escola por RED</p>
        </section>

        <GroupSection
          key={DEMANDS[getAnswers?.name]?.name}
          groupId={Object.keys(DEMANDS).indexOf(getAnswers?.name)}
          demand={getAnswers?.name}
          groupName={DEMANDS[getAnswers?.name]?.name}
          schoolAmount={{}}
          priorities={getAnswers?.priorities || {}}
          description={DEMANDS[getAnswers?.name]?.description}
          school
        />

        <section>
          <p>
            A sua escola já utiliza Recursos Educacionais Digitais com o(s)
            seguinte(s) propósito(s):
          </p>
        </section>

        {getAnswers?.answers
          .filter(
            (answer) =>
              answer.includes("checkbox") && !answer.includes("checkbox-5")
          )
          ?.map((answer) => (
            <GroupSection
              key={`other-${answer}`}
              groupKey={`other-${answer}`}
              groupId={Object.keys(DEMANDS).indexOf(ANSWERS[answer])}
              demand={ANSWERS[answer]}
              groupName={DEMANDS[ANSWERS[answer]]?.name}
              schoolAmount={{}}
              priorities={{}}
              description={DEMANDS[ANSWERS[answer]]?.description}
              school
            />
          ))}

        <section>
          <p>Estas são as tecnologias que sua escola já utiliza:</p>
          <div>
            <img src={image048} alt="" />

            <Masonry columnsCount={1}>
              {getAnswers?.technologies.map((technology) => (
                <p
                  style={{
                    backgroundColor:
                      COLORS[Math.floor(Math.random() * COLORS.length)],
                  }}
                >
                  {technology}
                </p>
              ))}
            </Masonry>
          </div>
        </section>

        {/* <section>
          <div>
            <button>QUERO BAIXAR</button>
          </div>
        </section> */}

        <section>
          <div>
            <div>
              <p>SIGA PARA OS PRÓXIMOS PASSOS</p>
            </div>
            <div>
              <div>
                <a
                  href="https://plataformaedutec.cieb.net.br/"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAÇA SUA PESQUISA DE MERCADO
                </a>
              </div>
              <div>
                <a
                  href="https://docs.google.com/spreadsheets/u/1/d/15dwz-kmuND3ndF1iwacHEWyNyuCjLm-y9Gi3B7Hz-KA/copy?pli=1#gid=2073279345"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAÇA CURADORIADOS REDs SELECIONADOS
                </a>
              </div>
            </div>
          </div>
        </section>
      </S.Wrapper>
    </Layout>
  );
}
