import React from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";

export default function OrbitalSVGInfra() {
  const navigate = useNavigate();
  return (
    <S.SVG
      id="INFRA"
      data-name="INFRA"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1057 1082"
    >
      <g>
        <circle
          className="circle-center-infra"
          cx="527.45"
          cy="505.23"
          r="136.07"
          onClick={() => navigate("/jornada/infraestrutura")}
        />
        <g>
          <path
            className="cls-4"
            d="M602.71,653.62a3.16,3.16,0,1,0,.73,4.41A3.16,3.16,0,0,0,602.71,653.62Z"
          />
          <path
            className="cls-4"
            d="M592.38,658.29a3.16,3.16,0,1,0,.73,4.41A3.16,3.16,0,0,0,592.38,658.29Z"
          />
          <path
            className="cls-4"
            d="M582.48,666.65a3.16,3.16,0,1,0-4.41.73A3.16,3.16,0,0,0,582.48,666.65Z"
          />
          <path
            className="cls-4"
            d="M572.27,670.33a4,4,0,1,0-5.55.91A4,4,0,0,0,572.27,670.33Z"
          />
          <path
            className="cls-4"
            d="M560.2,672.06a2.74,2.74,0,1,0-3.83.63A2.75,2.75,0,0,0,560.2,672.06Z"
          />
          <path
            className="cls-4"
            d="M549.67,674.23a3.58,3.58,0,1,0-5,.82A3.57,3.57,0,0,0,549.67,674.23Z"
          />
          <path
            className="cls-4"
            d="M532.55,671a3.58,3.58,0,1,0,5-.82A3.57,3.57,0,0,0,532.55,671Z"
          />
          <path
            className="cls-4"
            d="M527,675.25a3.59,3.59,0,1,0-5,.82A3.59,3.59,0,0,0,527,675.25Z"
          />
          <path
            className="cls-4"
            d="M514.41,670.27a2.74,2.74,0,1,0,.63,3.83A2.75,2.75,0,0,0,514.41,670.27Z"
          />
          <path
            className="cls-4"
            d="M503.41,668.49a3.16,3.16,0,1,0,.72,4.41A3.16,3.16,0,0,0,503.41,668.49Z"
          />
          <path
            className="cls-4"
            d="M491.82,666.94a2.35,2.35,0,1,0,.54,3.27A2.35,2.35,0,0,0,491.82,666.94Z"
          />
          <path
            className="cls-4"
            d="M482.08,667.71a3.16,3.16,0,1,0-4.42.73A3.16,3.16,0,0,0,482.08,667.71Z"
          />
          <circle className="cls-4" cx="468.8" cy="662.14" r="3.16" />
          <path
            className="cls-4"
            d="M460.29,659.06a2.35,2.35,0,1,0-3.28.54A2.36,2.36,0,0,0,460.29,659.06Z"
          />
          <path
            className="cls-4"
            d="M446.06,650.93a2.74,2.74,0,1,0,3.83-.63A2.75,2.75,0,0,0,446.06,650.93Z"
          />
          <path
            className="cls-4"
            d="M440.8,648.28a2.74,2.74,0,1,0-3.82.63A2.74,2.74,0,0,0,440.8,648.28Z"
          />
          <path
            className="cls-4"
            d="M431.51,641.78a2.74,2.74,0,1,0-3.82.63A2.73,2.73,0,0,0,431.51,641.78Z"
          />
          <path
            className="cls-4"
            d="M422.38,634.43a2.35,2.35,0,1,0-3.28.53A2.34,2.34,0,0,0,422.38,634.43Z"
          />
          <path
            className="cls-4"
            d="M414.08,626.71a2.35,2.35,0,1,0-3.28.54A2.34,2.34,0,0,0,414.08,626.71Z"
          />
          <path
            className="cls-4"
            d="M402.58,619.63a3.16,3.16,0,1,0-.73-4.41A3.16,3.16,0,0,0,402.58,619.63Z"
          />
          <path
            className="cls-4"
            d="M395.35,606.91a2.35,2.35,0,1,0,3.28-.54A2.35,2.35,0,0,0,395.35,606.91Z"
          />
          <path
            className="cls-4"
            d="M392.63,600.39a2.35,2.35,0,1,0-3.28.54A2.34,2.34,0,0,0,392.63,600.39Z"
          />
          <path
            className="cls-4"
            d="M386.4,590.45a1.93,1.93,0,1,0-2.69.44A1.92,1.92,0,0,0,386.4,590.45Z"
          />
          <path
            className="cls-4"
            d="M378.51,580.82a1.93,1.93,0,1,0-.44-2.69A1.93,1.93,0,0,0,378.51,580.82Z"
          />
          <path
            className="cls-4"
            d="M373.92,568a1.51,1.51,0,1,0,2.1-.34A1.5,1.5,0,0,0,373.92,568Z"
          />
          <path
            className="cls-4"
            d="M369.78,560.38a2.74,2.74,0,1,0-.63-3.82A2.75,2.75,0,0,0,369.78,560.38Z"
          />
          <path
            className="cls-4"
            d="M366.76,549.46a2.74,2.74,0,1,0-.62-3.83A2.75,2.75,0,0,0,366.76,549.46Z"
          />
          <path
            className="cls-4"
            d="M364.73,538a2.35,2.35,0,1,0-.53-3.28A2.34,2.34,0,0,0,364.73,538Z"
          />
          <path
            className="cls-4"
            d="M363,527.11a2.74,2.74,0,1,0-.63-3.83A2.75,2.75,0,0,0,363,527.11Z"
          />
          <path
            className="cls-4"
            d="M366.14,515.17a2.74,2.74,0,1,0-3.83.63A2.75,2.75,0,0,0,366.14,515.17Z"
          />
          <path
            className="cls-4"
            d="M365.21,503.11a1.5,1.5,0,1,0-2.1.35A1.51,1.51,0,0,0,365.21,503.11Z"
          />
          <path
            className="cls-4"
            d="M366.41,492.05a1.92,1.92,0,1,0-2.69.45A1.92,1.92,0,0,0,366.41,492.05Z"
          />
          <path
            className="cls-4"
            d="M369.05,481.56a3.16,3.16,0,1,0-4.41.72A3.17,3.17,0,0,0,369.05,481.56Z"
          />
          <path
            className="cls-4"
            d="M370.45,469.76a1.93,1.93,0,1,0-2.69.44A1.92,1.92,0,0,0,370.45,469.76Z"
          />
          <path
            className="cls-4"
            d="M374,459.12a2.35,2.35,0,1,0-3.28.54A2.34,2.34,0,0,0,374,459.12Z"
          />
          <path
            className="cls-4"
            d="M378.53,449a3.16,3.16,0,1,0-4.42.73A3.16,3.16,0,0,0,378.53,449Z"
          />
          <path
            className="cls-4"
            d="M382.5,438.13a2.35,2.35,0,1,0-3.28.54A2.34,2.34,0,0,0,382.5,438.13Z"
          />
          <path
            className="cls-4"
            d="M387.84,428.13a2.35,2.35,0,1,0-3.28.54A2.36,2.36,0,0,0,387.84,428.13Z"
          />
          <path
            className="cls-4"
            d="M394.17,418.75a2.74,2.74,0,1,0-3.83.63A2.75,2.75,0,0,0,394.17,418.75Z"
          />
          <path
            className="cls-4"
            d="M400.83,409.58a2.74,2.74,0,1,0-3.83.63A2.75,2.75,0,0,0,400.83,409.58Z"
          />
          <path
            className="cls-4"
            d="M408.1,400.89a2.74,2.74,0,1,0-3.82.63A2.74,2.74,0,0,0,408.1,400.89Z"
          />
          <path
            className="cls-4"
            d="M415.65,392.49a2.35,2.35,0,1,0-3.28.54A2.36,2.36,0,0,0,415.65,392.49Z"
          />
          <path
            className="cls-4"
            d="M424.71,385.36a3.16,3.16,0,1,0-4.41.72A3.15,3.15,0,0,0,424.71,385.36Z"
          />
          <path
            className="cls-4"
            d="M433.62,378.35a3.16,3.16,0,1,0-4.41.73A3.16,3.16,0,0,0,433.62,378.35Z"
          />
          <path
            className="cls-4"
            d="M442.33,371.5a2.35,2.35,0,1,0-3.28.54A2.35,2.35,0,0,0,442.33,371.5Z"
          />
          <path
            className="cls-4"
            d="M448.37,367a3.16,3.16,0,1,0-.72-4.41A3.16,3.16,0,0,0,448.37,367Z"
          />
          <path
            className="cls-4"
            d="M459,361.3a2.35,2.35,0,1,0-.53-3.28A2.36,2.36,0,0,0,459,361.3Z"
          />
          <path
            className="cls-4"
            d="M472.08,356a1.51,1.51,0,1,0-2.1.35A1.51,1.51,0,0,0,472.08,356Z"
          />
          <circle className="cls-4" cx="481.61" cy="351.5" r="3.16" />
          <circle className="cls-4" cx="492.59" cy="348.67" r="3.58" />
          <path
            className="cls-4"
            d="M502.14,348.83a2.74,2.74,0,1,0-.63-3.83A2.74,2.74,0,0,0,502.14,348.83Z"
          />
          <path
            className="cls-4"
            d="M512.68,348.54a4,4,0,1,0-.91-5.55A4,4,0,0,0,512.68,348.54Z"
          />
          <path
            className="cls-4"
            d="M524.48,347.37a3.16,3.16,0,1,0-.73-4.42A3.16,3.16,0,0,0,524.48,347.37Z"
          />
          <path
            className="cls-4"
            d="M535.81,347.64a3.16,3.16,0,1,0-.72-4.41A3.18,3.18,0,0,0,535.81,347.64Z"
          />
          <path
            className="cls-4"
            d="M546.72,344.52a2.74,2.74,0,1,0,3.82-.62A2.74,2.74,0,0,0,546.72,344.52Z"
          />
          <path
            className="cls-4"
            d="M557.81,351.18a4,4,0,1,0-.91-5.55A4,4,0,0,0,557.81,351.18Z"
          />
          <path
            className="cls-4"
            d="M567.59,348a4.4,4.4,0,1,0,6.14-1A4.41,4.41,0,0,0,567.59,348Z"
          />
          <path
            className="cls-4"
            d="M580.39,356.13a2.74,2.74,0,1,0-.62-3.83A2.75,2.75,0,0,0,580.39,356.13Z"
          />
          <path
            className="cls-4"
            d="M590.34,356.39a2.74,2.74,0,1,0,3.82-.63A2.75,2.75,0,0,0,590.34,356.39Z"
          />
          <path
            className="cls-4"
            d="M604.67,360.23a3.16,3.16,0,1,0,.73,4.42A3.16,3.16,0,0,0,604.67,360.23Z"
          />
          <path
            className="cls-4"
            d="M610.17,366.46a3.16,3.16,0,1,0,4.41-.72A3.17,3.17,0,0,0,610.17,366.46Z"
          />
          <circle className="cls-4" cx="622.24" cy="374.49" r="3.58" />
          <path
            className="cls-4"
            d="M633.39,378.39a3.59,3.59,0,1,0,.82,5A3.58,3.58,0,0,0,633.39,378.39Z"
          />
          <path
            className="cls-4"
            d="M642.43,390.58a3.16,3.16,0,1,0-4.41.72A3.17,3.17,0,0,0,642.43,390.58Z"
          />
          <path
            className="cls-4"
            d="M649.74,394.16a3.16,3.16,0,1,0,.72,4.42A3.17,3.17,0,0,0,649.74,394.16Z"
          />
          <path
            className="cls-4"
            d="M653.13,403.67A2.74,2.74,0,1,0,657,403,2.73,2.73,0,0,0,653.13,403.67Z"
          />
          <path
            className="cls-4"
            d="M660,412.7a2.74,2.74,0,1,0,3.83-.63A2.74,2.74,0,0,0,660,412.7Z"
          />
          <path
            className="cls-4"
            d="M665.19,421.46a4,4,0,1,0,5.55-.91A4,4,0,0,0,665.19,421.46Z"
          />
          <path
            className="cls-4"
            d="M671.06,431.57a3.58,3.58,0,1,0,5-.82A3.57,3.57,0,0,0,671.06,431.57Z"
          />
          <path
            className="cls-4"
            d="M675.59,441.59a4,4,0,1,0,5.55-.91A4,4,0,0,0,675.59,441.59Z"
          />
          <path
            className="cls-4"
            d="M680.38,452.62a3.16,3.16,0,1,0,4.41-.73A3.15,3.15,0,0,0,680.38,452.62Z"
          />
          <circle className="cls-4" cx="686.34" cy="465.28" r="4.4" />
          <circle className="cls-4" cx="688.98" cy="476.3" r="3.98" />
          <path
            className="cls-4"
            d="M694.08,489.8a4,4,0,1,0-5.55.92A4,4,0,0,0,694.08,489.8Z"
          />
          <path
            className="cls-4"
            d="M694.51,495.2a4.4,4.4,0,1,0,1,6.13A4.39,4.39,0,0,0,694.51,495.2Z"
          />
          <path
            className="cls-4"
            d="M694.11,507.53a3.16,3.16,0,1,0,.72,4.41A3.16,3.16,0,0,0,694.11,507.53Z"
          />
          <path
            className="cls-4"
            d="M694.12,518.19a4,4,0,1,0,.91,5.55A4,4,0,0,0,694.12,518.19Z"
          />
          <path
            className="cls-4"
            d="M692.87,529.46a4,4,0,1,0,.91,5.55A4,4,0,0,0,692.87,529.46Z"
          />
          <path
            className="cls-4"
            d="M691.76,546.16a4,4,0,1,0-5.55.92A4,4,0,0,0,691.76,546.16Z"
          />
          <path
            className="cls-4"
            d="M689,557.15a4,4,0,1,0-5.55.91A4,4,0,0,0,689,557.15Z"
          />
          <path
            className="cls-4"
            d="M683.8,563.38a2.74,2.74,0,1,0,.63,3.82A2.74,2.74,0,0,0,683.8,563.38Z"
          />
          <path
            className="cls-4"
            d="M680.5,578a3.16,3.16,0,1,0-4.41.72A3.17,3.17,0,0,0,680.5,578Z"
          />
          <path
            className="cls-4"
            d="M675,583.37a3.59,3.59,0,1,0,.82,5A3.6,3.6,0,0,0,675,583.37Z"
          />
          <path
            className="cls-4"
            d="M669.36,593.18a3.59,3.59,0,1,0,.82,5A3.59,3.59,0,0,0,669.36,593.18Z"
          />
          <path
            className="cls-4"
            d="M663,602.58a3.58,3.58,0,1,0,.82,5A3.58,3.58,0,0,0,663,602.58Z"
          />
          <path
            className="cls-4"
            d="M655.8,611.86a3.16,3.16,0,1,0,.72,4.41A3.17,3.17,0,0,0,655.8,611.86Z"
          />
          <path
            className="cls-4"
            d="M649,624.71a3.16,3.16,0,1,0-4.42.72A3.17,3.17,0,0,0,649,624.71Z"
          />
          <path
            className="cls-4"
            d="M640.81,632.6a3.16,3.16,0,1,0-4.41.72A3.15,3.15,0,0,0,640.81,632.6Z"
          />
          <path
            className="cls-4"
            d="M631.18,635.84a2.74,2.74,0,1,0,.63,3.82A2.75,2.75,0,0,0,631.18,635.84Z"
          />
          <path
            className="cls-4"
            d="M624,647.33a4.4,4.4,0,1,0-6.14,1A4.41,4.41,0,0,0,624,647.33Z"
          />
          <circle className="cls-4" cx="610.85" cy="650.81" r="3.98" />
          <path
            className="cls-4"
            d="M607,667.91a.7.7,0,0,0-1.13-.81.7.7,0,1,0,1.13.81Z"
          />
          <path
            className="cls-4"
            d="M597.52,670.3a2.35,2.35,0,1,0,.54,3.28A2.34,2.34,0,0,0,597.52,670.3Z"
          />
          <path
            className="cls-4"
            d="M586.78,677.11a1.51,1.51,0,1,0-2.11.35A1.51,1.51,0,0,0,586.78,677.11Z"
          />
          <path
            className="cls-4"
            d="M575.63,680.22a1.11,1.11,0,1,0-1.56.26A1.12,1.12,0,0,0,575.63,680.22Z"
          />
          <path
            className="cls-4"
            d="M564.6,682.86a1.11,1.11,0,0,0-.25-1.55,1.11,1.11,0,1,0,.25,1.55Z"
          />
          <path
            className="cls-4"
            d="M554.09,685.26a1.92,1.92,0,1,0-2.69.45A1.93,1.93,0,0,0,554.09,685.26Z"
          />
          <path
            className="cls-4"
            d="M542.85,683.12a2.74,2.74,0,1,0,.63,3.83A2.74,2.74,0,0,0,542.85,683.12Z"
          />
          <path
            className="cls-4"
            d="M530.33,685.27a.7.7,0,0,0-1,.16.69.69,0,0,0,.16,1,.68.68,0,0,0,1-.16A.69.69,0,0,0,530.33,685.27Z"
          />
          <path
            className="cls-4"
            d="M518.19,686.15a.7.7,0,1,0-.16-1A.7.7,0,0,0,518.19,686.15Z"
          />
          <path
            className="cls-4"
            d="M508.18,683.39a1.51,1.51,0,1,0,.34,2.11A1.5,1.5,0,0,0,508.18,683.39Z"
          />
          <path
            className="cls-4"
            d="M497.66,684.05a1.93,1.93,0,1,0-2.69.44A1.93,1.93,0,0,0,497.66,684.05Z"
          />
          <path
            className="cls-4"
            d="M485.66,679.62a1.11,1.11,0,0,0-1.55.25,1.11,1.11,0,1,0,1.55-.25Z"
          />
          <path
            className="cls-4"
            d="M474.76,676.51a1.1,1.1,0,0,0-1.55.25,1.11,1.11,0,1,0,1.81,1.3A1.11,1.11,0,0,0,474.76,676.51Z"
          />
          <path
            className="cls-4"
            d="M465,674.73a1.93,1.93,0,1,0-2.69.45A1.93,1.93,0,0,0,465,674.73Z"
          />
          <path
            className="cls-4"
            d="M453.58,669.53a.69.69,0,0,0-.16-1,.69.69,0,0,0-.81,1.12A.69.69,0,0,0,453.58,669.53Z"
          />
          <path
            className="cls-4"
            d="M443.48,664.4a.7.7,0,1,0-1,.16A.7.7,0,0,0,443.48,664.4Z"
          />
          <path
            className="cls-4"
            d="M432.59,657.81a.7.7,0,0,0,.16,1,.69.69,0,1,0-.16-1Z"
          />
          <path
            className="cls-4"
            d="M423.39,652.39a.68.68,0,0,0,1-.15.69.69,0,0,0-.15-1,.7.7,0,0,0-1,.16A.69.69,0,0,0,423.39,652.39Z"
          />
          <path
            className="cls-4"
            d="M415.42,645.26a.7.7,0,0,0-1.13-.81.7.7,0,1,0,1.13.81Z"
          />
          <path
            className="cls-4"
            d="M405.82,636.92a.7.7,0,1,0,1-.16A.7.7,0,0,0,405.82,636.92Z"
          />
          <path
            className="cls-4"
            d="M400,630.39a1.93,1.93,0,1,0-2.69.44A1.93,1.93,0,0,0,400,630.39Z"
          />
          <path
            className="cls-4"
            d="M389.85,622.28a1.93,1.93,0,1,0-.44-2.69A1.93,1.93,0,0,0,389.85,622.28Z"
          />
          <path
            className="cls-4"
            d="M384.75,610.8a1.11,1.11,0,1,0,.25,1.55A1.1,1.1,0,0,0,384.75,610.8Z"
          />
          <path
            className="cls-4"
            d="M377.25,601.86a.7.7,0,1,0,1-.16A.7.7,0,0,0,377.25,601.86Z"
          />
          <path
            className="cls-4"
            d="M371.24,591.8a1.11,1.11,0,0,0,.25,1.55,1.11,1.11,0,1,0-.25-1.55Z"
          />
          <path
            className="cls-4"
            d="M367.68,582.69a.7.7,0,0,0-.16-1,.7.7,0,0,0-.81,1.13A.7.7,0,0,0,367.68,582.69Z"
          />
          <path
            className="cls-4"
            d="M361.53,571a1.51,1.51,0,1,0,2.1-.35A1.51,1.51,0,0,0,361.53,571Z"
          />
          <path
            className="cls-4"
            d="M359.63,561.51a.7.7,0,0,0-.16-1,.7.7,0,0,0-.81,1.13A.7.7,0,0,0,359.63,561.51Z"
          />
          <path
            className="cls-4"
            d="M357,550.82a1.11,1.11,0,1,0-1.55.26A1.12,1.12,0,0,0,357,550.82Z"
          />
          <path
            className="cls-4"
            d="M354.36,539.47a.7.7,0,1,0-1,.16A.7.7,0,0,0,354.36,539.47Z"
          />
          <path
            className="cls-4"
            d="M351.57,528.75a1.12,1.12,0,1,0-.25-1.56A1.12,1.12,0,0,0,351.57,528.75Z"
          />
          <path
            className="cls-4"
            d="M351,517.1a.7.7,0,0,0,.81-1.13.7.7,0,0,0-.81,1.13Z"
          />
          <path
            className="cls-4"
            d="M351.82,505.6a.69.69,0,0,0-.16-1,.69.69,0,0,0-.81,1.12A.69.69,0,0,0,351.82,505.6Z"
          />
          <path
            className="cls-4"
            d="M351.3,493.48a.69.69,0,1,0,1-.16A.69.69,0,0,0,351.3,493.48Z"
          />
          <path
            className="cls-4"
            d="M354.1,483.27a1.11,1.11,0,0,0-.25-1.55,1.11,1.11,0,1,0,.25,1.55Z"
          />
          <path
            className="cls-4"
            d="M355.81,471.88a.7.7,0,1,0-1,.16A.7.7,0,0,0,355.81,471.88Z"
          />
          <path
            className="cls-4"
            d="M358.91,461.13a1.12,1.12,0,0,0-.26-1.56,1.11,1.11,0,1,0,.26,1.56Z"
          />
          <path
            className="cls-4"
            d="M362.37,450.33a1.11,1.11,0,1,0-1.56.26A1.12,1.12,0,0,0,362.37,450.33Z"
          />
          <path
            className="cls-4"
            d="M367.17,440.25a1.92,1.92,0,1,0-2.69.45A1.93,1.93,0,0,0,367.17,440.25Z"
          />
          <path
            className="cls-4"
            d="M371,429.27a.7.7,0,1,0-1,.16A.7.7,0,0,0,371,429.27Z"
          />
          <path
            className="cls-4"
            d="M376.44,419.33a.69.69,0,1,0-1,.16A.69.69,0,0,0,376.44,419.33Z"
          />
          <path
            className="cls-4"
            d="M382.52,409.77a.7.7,0,1,0-1,.16A.7.7,0,0,0,382.52,409.77Z"
          />
          <path
            className="cls-4"
            d="M389.2,400.61a.68.68,0,0,0-.15-1,.69.69,0,1,0,.15,1Z"
          />
          <path
            className="cls-4"
            d="M396.46,391.9a.69.69,0,0,0-.16-1,.68.68,0,0,0-1,.15.69.69,0,1,0,1.12.81Z"
          />
          <path
            className="cls-4"
            d="M404.26,383.68a.7.7,0,0,0-.16-1,.7.7,0,0,0-.81,1.13A.7.7,0,0,0,404.26,383.68Z"
          />
          <path
            className="cls-4"
            d="M413.92,376.93a2.35,2.35,0,1,0-3.28.54A2.35,2.35,0,0,0,413.92,376.93Z"
          />
          <path
            className="cls-4"
            d="M422.36,369.53a1.93,1.93,0,1,0-2.69.44A1.92,1.92,0,0,0,422.36,369.53Z"
          />
          <path
            className="cls-4"
            d="M430.93,362.47a1.12,1.12,0,0,0-.26-1.56,1.11,1.11,0,1,0,.26,1.56Z"
          />
          <path
            className="cls-4"
            d="M440.22,356.24a.69.69,0,1,0-1,.16A.69.69,0,0,0,440.22,356.24Z"
          />
          <path
            className="cls-4"
            d="M450.53,349.27a1.5,1.5,0,1,0,.34,2.1A1.5,1.5,0,0,0,450.53,349.27Z"
          />
          <path
            className="cls-4"
            d="M460.53,346.2a.7.7,0,1,0-1,.16A.7.7,0,0,0,460.53,346.2Z"
          />
          <path
            className="cls-4"
            d="M471.79,342.64a1.51,1.51,0,1,0-2.1.35A1.51,1.51,0,0,0,471.79,342.64Z"
          />
          <path
            className="cls-4"
            d="M481.8,337.86a.7.7,0,1,0,.16,1A.7.7,0,0,0,481.8,337.86Z"
          />
          <path
            className="cls-4"
            d="M491.3,337.35a1.93,1.93,0,1,0-.44-2.69A1.92,1.92,0,0,0,491.3,337.35Z"
          />
          <path
            className="cls-4"
            d="M501.37,332.26a2.74,2.74,0,1,0,3.82-.63A2.74,2.74,0,0,0,501.37,332.26Z"
          />
          <path
            className="cls-4"
            d="M514,332a1.11,1.11,0,1,0,1.55-.25A1.11,1.11,0,0,0,514,332Z"
          />
          <path
            className="cls-4"
            d="M525.31,333.39a1.51,1.51,0,1,0-.34-2.1A1.5,1.5,0,0,0,525.31,333.39Z"
          />
          <path
            className="cls-4"
            d="M536.15,334.32a2.35,2.35,0,1,0-.53-3.28A2.34,2.34,0,0,0,536.15,334.32Z"
          />
          <path
            className="cls-4"
            d="M546.91,332a2.35,2.35,0,1,0,3.28-.54A2.34,2.34,0,0,0,546.91,332Z"
          />
          <path
            className="cls-4"
            d="M559.46,334.67a.7.7,0,1,0,1-.16A.7.7,0,0,0,559.46,334.67Z"
          />
          <path
            className="cls-4"
            d="M570,339a1.93,1.93,0,1,0-.44-2.69A1.92,1.92,0,0,0,570,339Z"
          />
          <path
            className="cls-4"
            d="M581.36,341.49a1.11,1.11,0,0,0,1.55-.25,1.11,1.11,0,1,0-1.55.25Z"
          />
          <path
            className="cls-4"
            d="M593.56,343.17a1.5,1.5,0,1,0,.35,2.1A1.51,1.51,0,0,0,593.56,343.17Z"
          />
          <path
            className="cls-4"
            d="M604.66,350a1.92,1.92,0,1,0-2.68.45A1.92,1.92,0,0,0,604.66,350Z"
          />
          <path
            className="cls-4"
            d="M615.11,355.38a2.35,2.35,0,1,0-3.28.54A2.35,2.35,0,0,0,615.11,355.38Z"
          />
          <path
            className="cls-4"
            d="M621.59,361.69a2.35,2.35,0,1,0-.54-3.28A2.34,2.34,0,0,0,621.59,361.69Z"
          />
          <path
            className="cls-4"
            d="M630.42,364.8a2.35,2.35,0,1,0,3.28-.54A2.35,2.35,0,0,0,630.42,364.8Z"
          />
          <path
            className="cls-4"
            d="M639.35,371.77a2.35,2.35,0,1,0,3.28-.54A2.36,2.36,0,0,0,639.35,371.77Z"
          />
          <path
            className="cls-4"
            d="M647.83,379.3a2.35,2.35,0,1,0,3.27-.54A2.35,2.35,0,0,0,647.83,379.3Z"
          />
          <path
            className="cls-4"
            d="M656.14,387.61a1.93,1.93,0,1,0,2.69-.44A1.93,1.93,0,0,0,656.14,387.61Z"
          />
          <path
            className="cls-4"
            d="M663.92,396.41a1.51,1.51,0,1,0,2.1-.35A1.51,1.51,0,0,0,663.92,396.41Z"
          />
          <path
            className="cls-4"
            d="M673.15,404.73a1.93,1.93,0,1,0,.44,2.69A1.93,1.93,0,0,0,673.15,404.73Z"
          />
          <path
            className="cls-4"
            d="M679.53,416.61a1.51,1.51,0,1,0-2.1.35A1.51,1.51,0,0,0,679.53,416.61Z"
          />
          <path
            className="cls-4"
            d="M684.85,424.33a1.5,1.5,0,1,0,.35,2.1A1.5,1.5,0,0,0,684.85,424.33Z"
          />
          <path
            className="cls-4"
            d="M687.15,438.28a3.16,3.16,0,1,0-.72-4.41A3.17,3.17,0,0,0,687.15,438.28Z"
          />
          <path
            className="cls-4"
            d="M694.73,444.27a2.35,2.35,0,1,0,.54,3.28A2.35,2.35,0,0,0,694.73,444.27Z"
          />
          <path
            className="cls-4"
            d="M695.14,455.52a2.35,2.35,0,1,0,3.28-.53A2.34,2.34,0,0,0,695.14,455.52Z"
          />
          <path
            className="cls-4"
            d="M698.13,466.46a2.35,2.35,0,1,0,3.28-.54A2.35,2.35,0,0,0,698.13,466.46Z"
          />
          <circle className="cls-4" cx="702.32" cy="478.93" r="3.58" />
          <path
            className="cls-4"
            d="M704.77,488.94a1.5,1.5,0,1,0,.35,2.1A1.5,1.5,0,0,0,704.77,488.94Z"
          />
          <path
            className="cls-4"
            d="M706.11,499.56a2.35,2.35,0,1,0,.54,3.27A2.35,2.35,0,0,0,706.11,499.56Z"
          />
          <path
            className="cls-4"
            d="M703,511.43a2.35,2.35,0,1,0,3.28-.54A2.34,2.34,0,0,0,703,511.43Z"
          />
          <path
            className="cls-4"
            d="M706.82,526a3.16,3.16,0,1,0-4.41.73A3.16,3.16,0,0,0,706.82,526Z"
          />
          <path
            className="cls-4"
            d="M702,534.73a1.11,1.11,0,0,0,.25,1.55,1.11,1.11,0,1,0,1.3-1.81A1.11,1.11,0,0,0,702,534.73Z"
          />
          <path
            className="cls-4"
            d="M699.65,545.65a1.51,1.51,0,1,0,2.1-.35A1.51,1.51,0,0,0,699.65,545.65Z"
          />
          <path
            className="cls-4"
            d="M696.55,556.4a1.93,1.93,0,1,0,2.69-.44A1.92,1.92,0,0,0,696.55,556.4Z"
          />
          <path
            className="cls-4"
            d="M695.53,567.09a1.51,1.51,0,1,0,.35,2.11A1.51,1.51,0,0,0,695.53,567.09Z"
          />
          <path
            className="cls-4"
            d="M692.08,580a1.93,1.93,0,1,0-2.69.45A1.93,1.93,0,0,0,692.08,580Z"
          />
          <path
            className="cls-4"
            d="M687.61,590.5a2.35,2.35,0,0,0-3.82-2.74,2.35,2.35,0,0,0,3.82,2.74Z"
          />
          <path
            className="cls-4"
            d="M682.15,600.44a2.35,2.35,0,1,0-3.28.54A2.34,2.34,0,0,0,682.15,600.44Z"
          />
          <path
            className="cls-4"
            d="M676.07,610a2.35,2.35,0,1,0-3.28.53A2.35,2.35,0,0,0,676.07,610Z"
          />
          <path
            className="cls-4"
            d="M669.38,619.16a2.35,2.35,0,1,0-3.27.54A2.35,2.35,0,0,0,669.38,619.16Z"
          />
          <path
            className="cls-4"
            d="M662.13,627.87a2.35,2.35,0,1,0-3.28.54A2.36,2.36,0,0,0,662.13,627.87Z"
          />
          <path
            className="cls-4"
            d="M654,635.85a1.93,1.93,0,1,0-2.69.44A1.93,1.93,0,0,0,654,635.85Z"
          />
          <path
            className="cls-4"
            d="M643,644a1.93,1.93,0,1,0-.44-2.69A1.93,1.93,0,0,0,643,644Z"
          />
          <path
            className="cls-4"
            d="M634.42,649a1.11,1.11,0,0,0,.25,1.55,1.11,1.11,0,1,0,1.3-1.81A1.11,1.11,0,0,0,634.42,649Z"
          />
          <path
            className="cls-4"
            d="M627.66,657.3a1.92,1.92,0,1,0-2.69.45A1.93,1.93,0,0,0,627.66,657.3Z"
          />
          <path
            className="cls-4"
            d="M616.06,662.72a.68.68,0,0,0,1-.16.69.69,0,0,0-1.12-.81A.69.69,0,0,0,616.06,662.72Z"
          />
          <path
            className="cls-4"
            d="M611.5,678.43a.69.69,0,1,0,1-.16A.69.69,0,0,0,611.5,678.43Z"
          />
          <path
            className="cls-4"
            d="M602.16,683a.67.67,0,0,0-1,.16.69.69,0,0,0,1.12.8A.67.67,0,0,0,602.16,683Z"
          />
          <path
            className="cls-4"
            d="M590.63,687.24a.69.69,0,1,0,1.12.81.69.69,0,1,0-1.12-.81Z"
          />
          <path
            className="cls-4"
            d="M580.8,690.54a.7.7,0,0,0-1,.16.69.69,0,1,0,1-.16Z"
          />
          <path
            className="cls-4"
            d="M568.85,693.5a.69.69,0,0,0,.15,1,.7.7,0,1,0,.81-1.13A.69.69,0,0,0,568.85,693.5Z"
          />
          <path
            className="cls-4"
            d="M557.87,696.61a.7.7,0,1,0-.16-1A.7.7,0,0,0,557.87,696.61Z"
          />
          <path
            className="cls-4"
            d="M546.63,698.08a.69.69,0,1,0,.81-1.12.69.69,0,1,0-.81,1.12Z"
          />
          <path
            className="cls-4"
            d="M536.13,697.75a.7.7,0,0,0-.81,1.13.7.7,0,1,0,.81-1.13Z"
          />
          <path
            className="cls-4"
            d="M525,698.84a.7.7,0,1,0-1,.16A.7.7,0,0,0,525,698.84Z"
          />
          <path
            className="cls-4"
            d="M513.47,697.32a.69.69,0,1,0-.8,1.12.69.69,0,0,0,1-.16A.68.68,0,0,0,513.47,697.32Z"
          />
          <path
            className="cls-4"
            d="M502.36,697.05a.69.69,0,0,0-.15-1,.7.7,0,0,0-.81,1.13A.69.69,0,0,0,502.36,697.05Z"
          />
          <path
            className="cls-4"
            d="M490.06,694.33a.7.7,0,0,0,1.13.81.7.7,0,0,0-1.13-.81Z"
          />
          <path
            className="cls-4"
            d="M480.12,692.55a.66.66,0,1,0-.92.15A.65.65,0,0,0,480.12,692.55Z"
          />
          <path
            className="cls-4"
            d="M469.26,689.33a.67.67,0,1,0-.93.15A.68.68,0,0,0,469.26,689.33Z"
          />
          <path
            className="cls-4"
            d="M458.63,685.47a.7.7,0,0,0-1.13-.81.7.7,0,0,0,1.13.81Z"
          />
          <path
            className="cls-4"
            d="M447.27,681.11a.67.67,0,0,0,.93-.16.66.66,0,0,0-1.08-.77A.68.68,0,0,0,447.27,681.11Z"
          />
          <path
            className="cls-4"
            d="M437,675.07a.66.66,0,0,0,1.08.77.66.66,0,1,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M428.14,669.2a.67.67,0,1,0-.78,1.08.67.67,0,0,0,.93-.15A.68.68,0,0,0,428.14,669.2Z"
          />
          <path
            className="cls-4"
            d="M417.93,664a.66.66,0,1,0-.15-.92A.65.65,0,0,0,417.93,664Z"
          />
          <path
            className="cls-4"
            d="M408.89,657.15a.66.66,0,1,0-.15-.92A.65.65,0,0,0,408.89,657.15Z"
          />
          <path
            className="cls-4"
            d="M401.05,648.71a.67.67,0,0,0-.93.15.68.68,0,0,0,.15.93.67.67,0,0,0,.78-1.08Z"
          />
          <path
            className="cls-4"
            d="M392.89,640.84a.66.66,0,1,0-.78,1.07.66.66,0,0,0,.93-.15A.67.67,0,0,0,392.89,640.84Z"
          />
          <path
            className="cls-4"
            d="M384.42,633.6a.7.7,0,1,0-.16-1A.7.7,0,0,0,384.42,633.6Z"
          />
          <path
            className="cls-4"
            d="M378.2,624.63a.66.66,0,1,0-.92.15A.65.65,0,0,0,378.2,624.63Z"
          />
          <path
            className="cls-4"
            d="M371.43,614.51a.66.66,0,1,0-.77,1.07.65.65,0,0,0,.92-.15A.66.66,0,0,0,371.43,614.51Z"
          />
          <path
            className="cls-4"
            d="M364.42,605.06a.7.7,0,0,0,.16,1,.7.7,0,0,0,.81-1.13A.7.7,0,0,0,364.42,605.06Z"
          />
          <path
            className="cls-4"
            d="M360.07,595.94a.69.69,0,1,0-1,.16A.69.69,0,0,0,360.07,595.94Z"
          />
          <path
            className="cls-4"
            d="M355.19,585.71a.69.69,0,0,0-.15-1,.7.7,0,1,0-.81,1.13A.69.69,0,0,0,355.19,585.71Z"
          />
          <path
            className="cls-4"
            d="M349.81,574.39a.7.7,0,1,0,1-.16A.7.7,0,0,0,349.81,574.39Z"
          />
          <path
            className="cls-4"
            d="M347.3,564.44a.66.66,0,1,0-.93.15A.67.67,0,0,0,347.3,564.44Z"
          />
          <path
            className="cls-4"
            d="M344.33,553.5a.67.67,0,0,0-.15-.93.68.68,0,0,0-.93.15.67.67,0,0,0,1.08.78Z"
          />
          <path
            className="cls-4"
            d="M342.05,542.42a.7.7,0,1,0-1,.16A.7.7,0,0,0,342.05,542.42Z"
          />
          <path
            className="cls-4"
            d="M339.46,531.33a.66.66,0,1,0-.15-.92A.65.65,0,0,0,339.46,531.33Z"
          />
          <path
            className="cls-4"
            d="M338.47,520.06a.69.69,0,1,0,.81-1.12.69.69,0,1,0-.81,1.12Z"
          />
          <path
            className="cls-4"
            d="M338,507.76a.69.69,0,0,0,.15,1,.7.7,0,1,0,.81-1.13A.69.69,0,0,0,338,507.76Z"
          />
          <path
            className="cls-4"
            d="M339.54,497.24a.7.7,0,0,0-1.13-.81.7.7,0,1,0,1.13.81Z"
          />
          <path
            className="cls-4"
            d="M340.61,486a.7.7,0,1,0-1,.16A.7.7,0,0,0,340.61,486Z"
          />
          <path
            className="cls-4"
            d="M342.34,474.75a.68.68,0,0,0-.15-1,.69.69,0,1,0-.81,1.12A.67.67,0,0,0,342.34,474.75Z"
          />
          <path
            className="cls-4"
            d="M344.73,463.66a.67.67,0,0,0-.16-.93.66.66,0,0,0-.77,1.08A.67.67,0,0,0,344.73,463.66Z"
          />
          <path
            className="cls-4"
            d="M347.79,452.74a.66.66,0,0,0-.15-.92.67.67,0,0,0-.93.15.68.68,0,0,0,.15.93A.67.67,0,0,0,347.79,452.74Z"
          />
          <path
            className="cls-4"
            d="M351.5,442a.66.66,0,1,0-.92.15A.65.65,0,0,0,351.5,442Z"
          />
          <path
            className="cls-4"
            d="M355.87,431.58a.7.7,0,1,0-1,.16A.7.7,0,0,0,355.87,431.58Z"
          />
          <circle
            className="cls-4"
            cx="360.27"
            cy="420.98"
            r="0.66"
            transform="translate(-191.82 468.08) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M366.37,411.49a.66.66,0,1,0-.92.15A.65.65,0,0,0,366.37,411.49Z"
          />
          <path
            className="cls-4"
            d="M372.52,402a.67.67,0,1,0-.93.15A.68.68,0,0,0,372.52,402Z"
          />
          <path
            className="cls-4"
            d="M379.22,392.82a.65.65,0,0,0-.15-.92.66.66,0,1,0,.15.92Z"
          />
          <path
            className="cls-4"
            d="M386.46,384.1a.68.68,0,0,0-.15-.93.67.67,0,0,0-.78,1.08A.66.66,0,0,0,386.46,384.1Z"
          />
          <path
            className="cls-4"
            d="M394.21,375.82a.66.66,0,0,0-1.08-.77.68.68,0,0,0,.15.93A.67.67,0,0,0,394.21,375.82Z"
          />
          <path
            className="cls-4"
            d="M402.46,368a.69.69,0,1,0-1,.16A.7.7,0,0,0,402.46,368Z"
          />
          <path
            className="cls-4"
            d="M411.14,360.75a.7.7,0,1,0-1,.16A.7.7,0,0,0,411.14,360.75Z"
          />
          <path
            className="cls-4"
            d="M420.22,354a.66.66,0,0,0-1.08-.77.66.66,0,1,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M429.7,347.77a.66.66,0,1,0-.92.15A.65.65,0,0,0,429.7,347.77Z"
          />
          <path
            className="cls-4"
            d="M439.57,342.16a.7.7,0,0,0-1.13-.81.7.7,0,0,0,.16,1A.69.69,0,0,0,439.57,342.16Z"
          />
          <path
            className="cls-4"
            d="M449.73,337.14a.69.69,0,1,0-1,.16A.7.7,0,0,0,449.73,337.14Z"
          />
          <path
            className="cls-4"
            d="M459.21,332.89a.7.7,0,1,0-.16-1A.7.7,0,0,0,459.21,332.89Z"
          />
          <path
            className="cls-4"
            d="M469.74,328.14a.69.69,0,1,0,1.12.81.69.69,0,1,0-1.12-.81Z"
          />
          <path
            className="cls-4"
            d="M480.63,325a.7.7,0,1,0,1-.16A.7.7,0,0,0,480.63,325Z"
          />
          <path
            className="cls-4"
            d="M491.72,322.55a.67.67,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <path
            className="cls-4"
            d="M504,321.54a.69.69,0,1,0-1,.16A.69.69,0,0,0,504,321.54Z"
          />
          <path
            className="cls-4"
            d="M515.29,320.4a.69.69,0,1,0-1,.16A.69.69,0,0,0,515.29,320.4Z"
          />
          <path
            className="cls-4"
            d="M526.46,319a.69.69,0,0,0-1,.15.7.7,0,0,0,1.13.81A.69.69,0,0,0,526.46,319Z"
          />
          <path
            className="cls-4"
            d="M536.83,319.35a.69.69,0,1,0,1.12.81.69.69,0,1,0-1.12-.81Z"
          />
          <path
            className="cls-4"
            d="M549.25,321.06a.69.69,0,0,0-.15-1,.7.7,0,0,0-.81,1.13A.69.69,0,0,0,549.25,321.06Z"
          />
          <path
            className="cls-4"
            d="M559.51,322.79a.7.7,0,1,0-.16-1A.7.7,0,0,0,559.51,322.79Z"
          />
          <path
            className="cls-4"
            d="M571.59,324.87a.69.69,0,0,0-.15-1,.7.7,0,1,0-.81,1.13A.69.69,0,0,0,571.59,324.87Z"
          />
          <path
            className="cls-4"
            d="M582.55,327.77a.69.69,0,1,0-1,.16A.69.69,0,0,0,582.55,327.77Z"
          />
          <path
            className="cls-4"
            d="M593.16,330.35a.7.7,0,1,0,.16,1A.7.7,0,0,0,593.16,330.35Z"
          />
          <path
            className="cls-4"
            d="M602.89,335.67a.69.69,0,1,0-.16-1A.69.69,0,0,0,602.89,335.67Z"
          />
          <path
            className="cls-4"
            d="M613.15,340.48a.7.7,0,1,0-.16-1A.7.7,0,0,0,613.15,340.48Z"
          />
          <path
            className="cls-4"
            d="M622.87,346.24a1.12,1.12,0,0,0,1.3-1.82,1.12,1.12,0,1,0-1.3,1.82Z"
          />
          <path
            className="cls-4"
            d="M632.48,352.24A1.12,1.12,0,0,0,634,352a1.11,1.11,0,0,0-1.81-1.3A1.12,1.12,0,0,0,632.48,352.24Z"
          />
          <path
            className="cls-4"
            d="M641.47,357.25a1.11,1.11,0,0,0,.25,1.55,1.11,1.11,0,1,0-.25-1.55Z"
          />
          <path
            className="cls-4"
            d="M651.86,364.1a1.11,1.11,0,1,0,.25,1.55A1.11,1.11,0,0,0,651.86,364.1Z"
          />
          <path
            className="cls-4"
            d="M660.5,373.28a1.11,1.11,0,1,0-1.55.25A1.11,1.11,0,0,0,660.5,373.28Z"
          />
          <path
            className="cls-4"
            d="M667.11,381.3a.7.7,0,1,0-.16-1A.7.7,0,0,0,667.11,381.3Z"
          />
          <path
            className="cls-4"
            d="M675.34,388.74a.7.7,0,1,0,.16,1A.7.7,0,0,0,675.34,388.74Z"
          />
          <path
            className="cls-4"
            d="M682.4,398.71a.7.7,0,1,0-1,.16A.7.7,0,0,0,682.4,398.71Z"
          />
          <path
            className="cls-4"
            d="M687.62,407.3a.68.68,0,0,0,.15,1,.69.69,0,1,0,.81-1.12A.67.67,0,0,0,687.62,407.3Z"
          />
          <path
            className="cls-4"
            d="M693.39,417.05a.69.69,0,1,0,1.12.81.69.69,0,1,0-1.12-.81Z"
          />
          <path
            className="cls-4"
            d="M700,428.19a1.1,1.1,0,0,0-.25-1.55,1.11,1.11,0,1,0,.25,1.55Z"
          />
          <path
            className="cls-4"
            d="M703.29,438.48a.7.7,0,0,0,.81-1.13.7.7,0,0,0-.81,1.13Z"
          />
          <path
            className="cls-4"
            d="M708.51,447.32a1.51,1.51,0,1,0,.34,2.11A1.5,1.5,0,0,0,708.51,447.32Z"
          />
          <path
            className="cls-4"
            d="M711.33,458.83a.7.7,0,1,0,.16,1A.7.7,0,0,0,711.33,458.83Z"
          />
          <path
            className="cls-4"
            d="M712.34,469.54a1.51,1.51,0,1,0,2.1-.34A1.5,1.5,0,0,0,712.34,469.54Z"
          />
          <path
            className="cls-4"
            d="M715,481.18a.7.7,0,1,0,1-.16A.7.7,0,0,0,715,481.18Z"
          />
          <path
            className="cls-4"
            d="M716.28,492.44a.7.7,0,0,0,.16,1,.69.69,0,1,0-.16-1Z"
          />
          <path
            className="cls-4"
            d="M718.35,502.94a1.51,1.51,0,1,0,.34,2.1A1.5,1.5,0,0,0,718.35,502.94Z"
          />
          <path
            className="cls-4"
            d="M717,516.06a.69.69,0,0,0,1-.16.68.68,0,0,0-.16-1,.69.69,0,0,0-.81,1.12Z"
          />
          <path
            className="cls-4"
            d="M716.29,527.38a.7.7,0,0,0,.81-1.13.7.7,0,0,0-.81,1.13Z"
          />
          <path
            className="cls-4"
            d="M714.89,538.62a.68.68,0,0,0,1-.15.69.69,0,1,0-1.12-.81A.67.67,0,0,0,714.89,538.62Z"
          />
          <path
            className="cls-4"
            d="M712.81,549.77a.7.7,0,1,0-.16-1A.7.7,0,0,0,712.81,549.77Z"
          />
          <path
            className="cls-4"
            d="M709.92,559.8a.69.69,0,1,0,1.12.81.69.69,0,0,0-1.12-.81Z"
          />
          <path
            className="cls-4"
            d="M707.5,570.46a.7.7,0,0,0-1,.16.69.69,0,1,0,1-.16Z"
          />
          <path
            className="cls-4"
            d="M703.47,581.06a.7.7,0,0,0-1,.16.69.69,0,0,0,.16,1,.68.68,0,0,0,1-.15A.69.69,0,0,0,703.47,581.06Z"
          />
          <path
            className="cls-4"
            d="M697.84,591.55a.7.7,0,1,0,1-.16A.7.7,0,0,0,697.84,591.55Z"
          />
          <path
            className="cls-4"
            d="M692.23,601.35a1.11,1.11,0,1,0,1.56-.26A1.12,1.12,0,0,0,692.23,601.35Z"
          />
          <path
            className="cls-4"
            d="M686.38,611.05a1.11,1.11,0,1,0,1.55-.25A1.11,1.11,0,0,0,686.38,611.05Z"
          />
          <path
            className="cls-4"
            d="M680,620.39a1.11,1.11,0,1,0,1.56-.25A1.11,1.11,0,0,0,680,620.39Z"
          />
          <path
            className="cls-4"
            d="M673,629.33a1.11,1.11,0,0,0,.25,1.55,1.11,1.11,0,1,0,1.3-1.81A1.11,1.11,0,0,0,673,629.33Z"
          />
          <path
            className="cls-4"
            d="M665.82,638.08a.7.7,0,0,0,1.13.81.7.7,0,0,0-1.13-.81Z"
          />
          <path
            className="cls-4"
            d="M657.83,646.12a.7.7,0,1,0,1-.16A.7.7,0,0,0,657.83,646.12Z"
          />
          <path
            className="cls-4"
            d="M649.53,654.63a.69.69,0,0,0,1-.15.7.7,0,0,0-1.13-.81A.69.69,0,0,0,649.53,654.63Z"
          />
          <path
            className="cls-4"
            d="M641.45,660.54a.69.69,0,1,0-.81,1.12.69.69,0,0,0,.81-1.12Z"
          />
          <path
            className="cls-4"
            d="M631.18,667.18a.7.7,0,1,0,1-.16A.7.7,0,0,0,631.18,667.18Z"
          />
          <path
            className="cls-4"
            d="M621.67,674.06a.69.69,0,0,0,1-.15.7.7,0,0,0-1.13-.81A.69.69,0,0,0,621.67,674.06Z"
          />
          <path
            className="cls-4"
            d="M617.11,689.75a.69.69,0,0,0,.15,1,.7.7,0,1,0,.81-1.13A.69.69,0,0,0,617.11,689.75Z"
          />
          <circle
            className="cls-4"
            cx="607.37"
            cy="694.89"
            r="0.66"
            transform="translate(-311.34 782.95) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M596.28,698.66a.66.66,0,0,0,.16.92.65.65,0,0,0,.92-.15.66.66,0,0,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M586.44,702.06a.66.66,0,0,0-.77,1.08.66.66,0,1,0,.77-1.08Z"
          />
          <path
            className="cls-4"
            d="M574.55,705.15a.68.68,0,0,0,.16,1,.69.69,0,0,0,.8-1.12A.68.68,0,0,0,574.55,705.15Z"
          />
          <path
            className="cls-4"
            d="M563.63,708.42a.66.66,0,1,0-.15-.93A.67.67,0,0,0,563.63,708.42Z"
          />
          <circle
            className="cls-4"
            cx="552.81"
            cy="709.58"
            r="0.66"
            transform="translate(-346.01 744.75) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M542.06,711a.66.66,0,1,0-.92.15A.65.65,0,0,0,542.06,711Z"
          />
          <path
            className="cls-4"
            d="M529.81,711.63a.66.66,0,1,0-.15-.92A.65.65,0,0,0,529.81,711.63Z"
          />
          <path
            className="cls-4"
            d="M519.4,711.28a.66.66,0,1,0-.93.15A.67.67,0,0,0,519.4,711.28Z"
          />
          <path
            className="cls-4"
            d="M507,709.67a.66.66,0,1,0,.92-.15A.66.66,0,0,0,507,709.67Z"
          />
          <path
            className="cls-4"
            d="M496.7,708.06a.66.66,0,1,0-.77,1.07.65.65,0,0,0,.92-.15A.66.66,0,0,0,496.7,708.06Z"
          />
          <path
            className="cls-4"
            d="M484.63,706.11a.68.68,0,0,0,.15.93.67.67,0,0,0,.78-1.08A.68.68,0,0,0,484.63,706.11Z"
          />
          <path
            className="cls-4"
            d="M473.63,703.4a.66.66,0,1,0,.92-.15A.65.65,0,0,0,473.63,703.4Z"
          />
          <path
            className="cls-4"
            d="M462.79,700.07a.66.66,0,1,0,1.08.77.66.66,0,0,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M453.23,696.91a.66.66,0,1,0-.92.15A.65.65,0,0,0,453.23,696.91Z"
          />
          <path
            className="cls-4"
            d="M442.84,692.39a.66.66,0,0,0-1.08-.77.66.66,0,0,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M431.63,686.52a.67.67,0,1,0,.93-.15A.67.67,0,0,0,431.63,686.52Z"
          />
          <path
            className="cls-4"
            d="M421.81,680.87a.66.66,0,0,0,.15.92.66.66,0,1,0,.77-1.08A.66.66,0,0,0,421.81,680.87Z"
          />
          <path
            className="cls-4"
            d="M412.32,674.67a.66.66,0,1,0,.92-.15A.65.65,0,0,0,412.32,674.67Z"
          />
          <path
            className="cls-4"
            d="M403.19,668a.65.65,0,0,0,.15.92.66.66,0,1,0,.77-1.07A.65.65,0,0,0,403.19,668Z"
          />
          <path
            className="cls-4"
            d="M395.37,660.57a.66.66,0,0,0-.77,1.08.66.66,0,1,0,.77-1.08Z"
          />
          <path
            className="cls-4"
            d="M386.28,654a.67.67,0,1,0-.15-.93A.67.67,0,0,0,386.28,654Z"
          />
          <path
            className="cls-4"
            d="M379.18,644.72a.66.66,0,1,0-.77,1.07.65.65,0,0,0,.92-.15A.66.66,0,0,0,379.18,644.72Z"
          />
          <circle
            className="cls-4"
            cx="371.39"
            cy="636.67"
            r="0.66"
            transform="matrix(0.58, -0.81, 0.81, 0.58, -362.39, 567)"
          />
          <path
            className="cls-4"
            d="M363.94,627.3a.66.66,0,1,0,1.07.77.65.65,0,0,0-.15-.92A.66.66,0,0,0,363.94,627.3Z"
          />
          <circle className="cls-4" cx="358.08" cy="618.33" r="0.63" />
          <path
            className="cls-4"
            d="M352.75,609a.66.66,0,1,0-.92.15A.65.65,0,0,0,352.75,609Z"
          />
          <path
            className="cls-4"
            d="M347.29,598.07a.66.66,0,0,0-.77,1.08.66.66,0,0,0,.77-1.08Z"
          />
          <path
            className="cls-4"
            d="M342.71,588.7a.67.67,0,1,0-.93.15A.68.68,0,0,0,342.71,588.7Z"
          />
          <path
            className="cls-4"
            d="M338.4,577.22a.67.67,0,0,0-.93.16.66.66,0,0,0,1.08.77A.67.67,0,0,0,338.4,577.22Z"
          />
          <path
            className="cls-4"
            d="M333.92,566.61a.66.66,0,1,0,.92-.15A.66.66,0,0,0,333.92,566.61Z"
          />
          <path
            className="cls-4"
            d="M332.05,556.44a.68.68,0,0,0-.15-.93.67.67,0,0,0-.93.16.66.66,0,0,0,.15.92A.67.67,0,0,0,332.05,556.44Z"
          />
          <path
            className="cls-4"
            d="M329.72,545.34a.66.66,0,0,0-1.08-.77.68.68,0,0,0,.15.93A.67.67,0,0,0,329.72,545.34Z"
          />
          <path
            className="cls-4"
            d="M328,534.12a.63.63,0,1,0-1-.74.63.63,0,0,0,1,.74Z"
          />
          <path
            className="cls-4"
            d="M326,523a.66.66,0,0,0,.77-1.07A.66.66,0,1,0,326,523Z"
          />
          <path
            className="cls-4"
            d="M325.58,511.67a.66.66,0,1,0-.15-.92A.65.65,0,0,0,325.58,511.67Z"
          />
          <path
            className="cls-4"
            d="M325.63,499.41a.66.66,0,1,0,.92-.15A.66.66,0,0,0,325.63,499.41Z"
          />
          <path
            className="cls-4"
            d="M327.37,488a.63.63,0,1,0-.74,1,.63.63,0,0,0,.74-1Z"
          />
          <path
            className="cls-4"
            d="M329,477.64a.66.66,0,1,0-.92.15A.65.65,0,0,0,329,477.64Z"
          />
          <path
            className="cls-4"
            d="M331.1,466.5a.67.67,0,0,0-.16-.93.66.66,0,0,0-.77,1.08A.67.67,0,0,0,331.1,466.5Z"
          />
          <path
            className="cls-4"
            d="M333.81,455.49a.66.66,0,1,0-.92.15A.65.65,0,0,0,333.81,455.49Z"
          />
          <path
            className="cls-4"
            d="M337.14,444.66a.67.67,0,0,0-.15-.93.67.67,0,1,0,.15.93Z"
          />
          <path
            className="cls-4"
            d="M341.07,434a.66.66,0,1,0-.92.15A.65.65,0,0,0,341.07,434Z"
          />
          <path
            className="cls-4"
            d="M345.59,423.63a.66.66,0,1,0-.92.15A.66.66,0,0,0,345.59,423.63Z"
          />
          <path
            className="cls-4"
            d="M350.69,413.5a.66.66,0,1,0-.93.15A.67.67,0,0,0,350.69,413.5Z"
          />
          <path
            className="cls-4"
            d="M356.34,403.68a.66.66,0,0,0-.15-.93.66.66,0,1,0,.15.93Z"
          />
          <path
            className="cls-4"
            d="M362.54,394.18a.66.66,0,1,0-1.07-.77.66.66,0,1,0,1.07.77Z"
          />
          <path
            className="cls-4"
            d="M369.26,385.05a.65.65,0,0,0-.15-.92.66.66,0,1,0,.15.92Z"
          />
          <path
            className="cls-4"
            d="M376.48,376.32a.66.66,0,0,0-.15-.93.66.66,0,1,0,.15.93Z"
          />
          <path
            className="cls-4"
            d="M384.19,368a.67.67,0,1,0-.93.15A.68.68,0,0,0,384.19,368Z"
          />
          <path
            className="cls-4"
            d="M392.34,360.12a.66.66,0,1,0-.92.15A.65.65,0,0,0,392.34,360.12Z"
          />
          <path
            className="cls-4"
            d="M400.92,352.72a.66.66,0,0,0-.15-.93.66.66,0,1,0,.15.93Z"
          />
          <path
            className="cls-4"
            d="M409.91,345.8a.66.66,0,0,0-.15-.92.67.67,0,0,0-.93.15.68.68,0,0,0,.15.93A.67.67,0,0,0,409.91,345.8Z"
          />
          <path
            className="cls-4"
            d="M419.27,339.41a.67.67,0,1,0-.93.15A.68.68,0,0,0,419.27,339.41Z"
          />
          <path
            className="cls-4"
            d="M429,333.54a.65.65,0,0,0-.15-.92.66.66,0,0,0-.92.15.66.66,0,1,0,1.07.77Z"
          />
          <path
            className="cls-4"
            d="M439,328.23a.66.66,0,0,0-1.08-.77.68.68,0,0,0,.15.93A.67.67,0,0,0,439,328.23Z"
          />
          <path
            className="cls-4"
            d="M449.29,323.49a.66.66,0,0,0-1.08-.77.66.66,0,1,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M459.83,319.34a.66.66,0,1,0-.92.15A.65.65,0,0,0,459.83,319.34Z"
          />
          <path
            className="cls-4"
            d="M470.45,314.86a.66.66,0,1,0,.15.92A.66.66,0,0,0,470.45,314.86Z"
          />
          <path
            className="cls-4"
            d="M481.39,311.91a.66.66,0,0,0-.77,1.08.68.68,0,0,0,.93-.15A.67.67,0,0,0,481.39,311.91Z"
          />
          <path
            className="cls-4"
            d="M492.49,309.58a.67.67,0,1,0,.15.93A.67.67,0,0,0,492.49,309.58Z"
          />
          <path
            className="cls-4"
            d="M503.69,307.88a.66.66,0,0,0-.92.15.68.68,0,0,0,.15.93.67.67,0,0,0,.93-.16A.66.66,0,0,0,503.69,307.88Z"
          />
          <path
            className="cls-4"
            d="M515.13,307.73a.66.66,0,1,0-.92.15A.65.65,0,0,0,515.13,307.73Z"
          />
          <path
            className="cls-4"
            d="M526.46,307.29a.66.66,0,0,0-.15-.92.66.66,0,1,0-.77,1.08A.66.66,0,0,0,526.46,307.29Z"
          />
          <path
            className="cls-4"
            d="M537.64,306.57a.65.65,0,0,0-.92.15.66.66,0,1,0,.92-.15Z"
          />
          <path
            className="cls-4"
            d="M549.1,308.33a.66.66,0,1,0-.92.15A.67.67,0,0,0,549.1,308.33Z"
          />
          <path
            className="cls-4"
            d="M559.42,309.94a.66.66,0,1,0-.15-.92A.65.65,0,0,0,559.42,309.94Z"
          />
          <path
            className="cls-4"
            d="M570.41,311.11a.66.66,0,1,0,.92-.15A.66.66,0,0,0,570.41,311.11Z"
          />
          <path
            className="cls-4"
            d="M581.39,313.81a.7.7,0,1,0,1-.16A.7.7,0,0,0,581.39,313.81Z"
          />
          <path
            className="cls-4"
            d="M593.18,317a.67.67,0,1,0,.15.93A.68.68,0,0,0,593.18,317Z"
          />
          <path
            className="cls-4"
            d="M604,321.86a.67.67,0,0,0-.15-.93.66.66,0,0,0-.92.16.65.65,0,0,0,.15.92A.66.66,0,0,0,604,321.86Z"
          />
          <path
            className="cls-4"
            d="M614.21,325.46a.66.66,0,0,0-.93.15.66.66,0,1,0,.93-.15Z"
          />
          <path
            className="cls-4"
            d="M624.33,330.55a.66.66,0,1,0,.15.93A.67.67,0,0,0,624.33,330.55Z"
          />
          <path
            className="cls-4"
            d="M634.31,337.13a.66.66,0,1,0-.93.15A.66.66,0,0,0,634.31,337.13Z"
          />
          <path
            className="cls-4"
            d="M643.8,343.33a.66.66,0,0,0-1.08-.77.66.66,0,0,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M652.78,349.13a.66.66,0,1,0,.15.92A.66.66,0,0,0,652.78,349.13Z"
          />
          <path
            className="cls-4"
            d="M660.59,356.5a.68.68,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <path
            className="cls-4"
            d="M670,365a.66.66,0,0,0-1.08-.77.68.68,0,0,0,.15.93A.67.67,0,0,0,670,365Z"
          />
          <path
            className="cls-4"
            d="M677.86,373.13a.66.66,0,1,0-.92.15A.66.66,0,0,0,677.86,373.13Z"
          />
          <path
            className="cls-4"
            d="M684.34,381.87a.67.67,0,1,0-.15-.93A.68.68,0,0,0,684.34,381.87Z"
          />
          <path
            className="cls-4"
            d="M691.08,389.91a.7.7,0,0,0,.16,1,.7.7,0,0,0,.81-1.13A.7.7,0,0,0,691.08,389.91Z"
          />
          <path
            className="cls-4"
            d="M698.44,399.11a.69.69,0,0,0-1,.16.69.69,0,0,0,1.12.81A.69.69,0,0,0,698.44,399.11Z"
          />
          <path
            className="cls-4"
            d="M703.36,409a.66.66,0,0,0,1.08.77.66.66,0,0,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M709.6,418.85a.67.67,0,1,0,.15.93A.68.68,0,0,0,709.6,418.85Z"
          />
          <path
            className="cls-4"
            d="M714.49,430.08a.67.67,0,1,0-.93.15A.68.68,0,0,0,714.49,430.08Z"
          />
          <path
            className="cls-4"
            d="M718.67,440.64a.7.7,0,1,0-1,.16A.7.7,0,0,0,718.67,440.64Z"
          />
          <path
            className="cls-4"
            d="M722.2,451.39a.66.66,0,1,0-1.08-.77.66.66,0,0,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M725,461.41a.67.67,0,0,0-.93.15.68.68,0,0,0,.15.93.67.67,0,0,0,.78-1.08Z"
          />
          <path
            className="cls-4"
            d="M726.4,472.66a.66.66,0,0,0,.15.92.66.66,0,1,0,.77-1.08A.66.66,0,0,0,726.4,472.66Z"
          />
          <path
            className="cls-4"
            d="M728.1,483.86a.67.67,0,1,0,.93-.15A.68.68,0,0,0,728.1,483.86Z"
          />
          <path
            className="cls-4"
            d="M729.18,495.15a.66.66,0,1,0,.92-.15A.65.65,0,0,0,729.18,495.15Z"
          />
          <path
            className="cls-4"
            d="M730.54,506.33a.66.66,0,1,0-.77,1.07.65.65,0,0,0,.92-.15A.67.67,0,0,0,730.54,506.33Z"
          />
          <path
            className="cls-4"
            d="M729.41,517.81a.67.67,0,1,0,.93-.15A.68.68,0,0,0,729.41,517.81Z"
          />
          <path
            className="cls-4"
            d="M729.66,529.89a.66.66,0,1,0-.93.15A.67.67,0,0,0,729.66,529.89Z"
          />
          <path
            className="cls-4"
            d="M727.27,541.28a.66.66,0,1,0-.15-.92A.65.65,0,0,0,727.27,541.28Z"
          />
          <path
            className="cls-4"
            d="M725,551.5a.68.68,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <path
            className="cls-4"
            d="M722.28,562.49a.7.7,0,1,0,1-.16A.7.7,0,0,0,722.28,562.49Z"
          />
          <path
            className="cls-4"
            d="M719.9,573.19a.67.67,0,0,0-.92.15.66.66,0,1,0,.92-.15Z"
          />
          <path
            className="cls-4"
            d="M716,583.83a.66.66,0,1,0,.15.92A.65.65,0,0,0,716,583.83Z"
          />
          <path
            className="cls-4"
            d="M710.53,594.37a.66.66,0,1,0,.92-.15A.66.66,0,0,0,710.53,594.37Z"
          />
          <path
            className="cls-4"
            d="M705.43,604.5a.66.66,0,1,0,1.08.77.66.66,0,0,0-.16-.92A.65.65,0,0,0,705.43,604.5Z"
          />
          <circle
            className="cls-4"
            cx="700.31"
            cy="614.71"
            r="0.66"
            transform="translate(-207.47 825.03) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M693.58,623.82a.65.65,0,0,0,.15.92.66.66,0,1,0-.15-.92Z"
          />
          <path
            className="cls-4"
            d="M686.85,633a.66.66,0,0,0,1.08.77.66.66,0,1,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M679.63,641.68a.67.67,0,0,0,1.08.78.67.67,0,0,0-.15-.93A.68.68,0,0,0,679.63,641.68Z"
          />
          <path
            className="cls-4"
            d="M671.93,650a.67.67,0,1,0,.93-.15A.68.68,0,0,0,671.93,650Z"
          />
          <path
            className="cls-4"
            d="M663.78,657.88a.66.66,0,1,0,.92-.15A.65.65,0,0,0,663.78,657.88Z"
          />
          <path
            className="cls-4"
            d="M656.12,665.13a.68.68,0,0,0-.93.15.67.67,0,1,0,.93-.15Z"
          />
          <path
            className="cls-4"
            d="M647.15,672a.7.7,0,1,0,.16,1A.7.7,0,0,0,647.15,672Z"
          />
          <path
            className="cls-4"
            d="M636.85,678.59a.67.67,0,0,0,.15.93.68.68,0,0,0,.93-.15.67.67,0,0,0-1.08-.78Z"
          />
          <path
            className="cls-4"
            d="M628.07,684.3a.66.66,0,0,0-.92.16.65.65,0,0,0,.15.92.66.66,0,0,0,.77-1.08Z"
          />
          <path
            className="cls-4"
            d="M622.73,701.09a.66.66,0,0,0,1.08.77.66.66,0,0,0-.16-.92A.65.65,0,0,0,622.73,701.09Z"
          />
          <path
            className="cls-4"
            d="M613.35,705.72a.64.64,0,0,0-.89.14.63.63,0,1,0,.89-.14Z"
          />
          <path
            className="cls-4"
            d="M601.91,710.05a.68.68,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <circle className="cls-4" cx="591.72" cy="714.08" r="0.63" />
          <circle className="cls-4" cx="580.8" cy="717.16" r="0.63" />
          <circle className="cls-4" cx="569.75" cy="719.65" r="0.63" />
          <path
            className="cls-4"
            d="M559,721a.66.66,0,1,0,.15.92A.67.67,0,0,0,559,721Z"
          />
          <circle className="cls-4" cx="547.31" cy="722.87" r="0.63" />
          <circle className="cls-4" cx="536" cy="723.59" r="0.63" />
          <path
            className="cls-4"
            d="M525.17,724.08a.64.64,0,0,0-.14-.89.65.65,0,0,0-.89.15.64.64,0,0,0,.15.88A.63.63,0,0,0,525.17,724.08Z"
          />
          <path
            className="cls-4"
            d="M513.7,722.72a.63.63,0,0,0-.88.14.63.63,0,0,0,1,.74A.63.63,0,0,0,513.7,722.72Z"
          />
          <path
            className="cls-4"
            d="M502.42,721.64a.63.63,0,1,0-.74,1,.63.63,0,0,0,.74-1Z"
          />
          <path
            className="cls-4"
            d="M490.32,720.11a.64.64,0,0,0,.15.89.63.63,0,1,0-.15-.89Z"
          />
          <path
            className="cls-4"
            d="M479.34,718.76a.66.66,0,1,0-.15-.92A.65.65,0,0,0,479.34,718.76Z"
          />
          <path
            className="cls-4"
            d="M469.12,714.87a.63.63,0,0,0-.88.14.64.64,0,0,0,.14.89.63.63,0,0,0,.74-1Z"
          />
          <circle className="cls-4" cx="457.94" cy="711.96" r="0.63" />
          <path
            className="cls-4"
            d="M446.79,707.59a.67.67,0,1,0,.92-.15A.67.67,0,0,0,446.79,707.59Z"
          />
          <path
            className="cls-4"
            d="M437.48,703.83a.66.66,0,0,0-.16-.92.65.65,0,0,0-.92.15.66.66,0,0,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M426.29,698a.65.65,0,0,0,.14.89.64.64,0,0,0,.89-.15.63.63,0,0,0-.15-.88A.64.64,0,0,0,426.29,698Z"
          />
          <path
            className="cls-4"
            d="M417.32,692.24a.63.63,0,1,0,.14.88A.63.63,0,0,0,417.32,692.24Z"
          />
          <path
            className="cls-4"
            d="M407.92,687a.63.63,0,1,0-.88.14A.64.64,0,0,0,407.92,687Z"
          />
          <circle className="cls-4" cx="398.2" cy="680.02" r="0.63" />
          <path
            className="cls-4"
            d="M388.84,672.56a.63.63,0,1,0,.89-.14A.64.64,0,0,0,388.84,672.56Z"
          />
          <path
            className="cls-4"
            d="M380.38,665a.65.65,0,0,0,.15.89.64.64,0,0,0,.88-.15.63.63,0,0,0-.14-.88A.64.64,0,0,0,380.38,665Z"
          />
          <path
            className="cls-4"
            d="M372.31,657a.68.68,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <path
            className="cls-4"
            d="M364.7,648.61a.66.66,0,1,0,.92-.15A.66.66,0,0,0,364.7,648.61Z"
          />
          <path
            className="cls-4"
            d="M357.56,639.84a.63.63,0,1,0,.88-.14A.63.63,0,0,0,357.56,639.84Z"
          />
          <path
            className="cls-4"
            d="M351.75,630.54a.63.63,0,1,0,.15.89A.64.64,0,0,0,351.75,630.54Z"
          />
          <path
            className="cls-4"
            d="M345.56,621.05a.63.63,0,0,0-.88.14.63.63,0,1,0,1,.74A.63.63,0,0,0,345.56,621.05Z"
          />
          <path
            className="cls-4"
            d="M340,612.12a.63.63,0,1,0-.88.15A.63.63,0,0,0,340,612.12Z"
          />
          <circle className="cls-4" cx="334.35" cy="601.66" r="0.63" />
          <path
            className="cls-4"
            d="M329.35,591.82a.63.63,0,1,0,.74-1,.63.63,0,0,0-.88.15A.64.64,0,0,0,329.35,591.82Z"
          />
          <path
            className="cls-4"
            d="M326.17,581.1a.65.65,0,0,0-.14-.89.64.64,0,0,0-.89.15.63.63,0,0,0,.15.88A.64.64,0,0,0,326.17,581.1Z"
          />
          <path
            className="cls-4"
            d="M321.62,569.56a.66.66,0,0,0,1.07.77.66.66,0,0,0-.15-.92A.65.65,0,0,0,321.62,569.56Z"
          />
          <path
            className="cls-4"
            d="M319.74,559.36a.63.63,0,0,0-.15-.88.63.63,0,1,0,.15.88Z"
          />
          <path
            className="cls-4"
            d="M317.39,548.27a.63.63,0,1,0-.88.15A.64.64,0,0,0,317.39,548.27Z"
          />
          <circle className="cls-4" cx="315.12" cy="536.7" r="0.63" />
          <path
            className="cls-4"
            d="M313.58,525.94a.64.64,0,0,0,.89-.15.63.63,0,1,0-.89.15Z"
          />
          <path
            className="cls-4"
            d="M313,514.64a.66.66,0,1,0-.15-.92A.65.65,0,0,0,313,514.64Z"
          />
          <circle className="cls-4" cx="313.41" cy="502.77" r="0.63" />
          <circle className="cls-4" cx="314.04" cy="491.45" r="0.63" />
          <circle className="cls-4" cx="315.27" cy="480.18" r="0.63" />
          <path
            className="cls-4"
            d="M317.6,469.36a.64.64,0,0,0-.15-.89.63.63,0,1,0-.73,1A.64.64,0,0,0,317.6,469.36Z"
          />
          <path
            className="cls-4"
            d="M320,458.28a.63.63,0,1,0-1-.74.63.63,0,1,0,1,.74Z"
          />
          <circle className="cls-4" cx="322.48" cy="446.97" r="0.63" />
          <path
            className="cls-4"
            d="M326.58,436.6a.67.67,0,1,0-.93.15A.68.68,0,0,0,326.58,436.6Z"
          />
          <path
            className="cls-4"
            d="M330.7,426a.67.67,0,1,0-.93.15A.68.68,0,0,0,330.7,426Z"
          />
          <circle className="cls-4" cx="334.84" cy="415.32" r="0.63" />
          <path
            className="cls-4"
            d="M340.56,405.62a.63.63,0,0,0-.14-.88.63.63,0,1,0-.74,1A.63.63,0,0,0,340.56,405.62Z"
          />
          <circle className="cls-4" cx="345.79" cy="395.48" r="0.63" />
          <circle className="cls-4" cx="352.03" cy="386.02" r="0.63" />
          <path
            className="cls-4"
            d="M359.28,377.27a.65.65,0,0,0-.14-.89.64.64,0,0,0-.89.15.63.63,0,0,0,.15.88A.64.64,0,0,0,359.28,377.27Z"
          />
          <path
            className="cls-4"
            d="M366.49,368.51a.63.63,0,1,0-.89.15A.64.64,0,0,0,366.49,368.51Z"
          />
          <circle className="cls-4" cx="373.63" cy="359.79" r="0.63" />
          <path
            className="cls-4"
            d="M382.26,352.23a.65.65,0,0,0-.15-.92.66.66,0,1,0-.77,1.07A.65.65,0,0,0,382.26,352.23Z"
          />
          <path
            className="cls-4"
            d="M390.76,344.73a.68.68,0,0,0-.15-.93.67.67,0,1,0,.15.93Z"
          />
          <path
            className="cls-4"
            d="M399.62,337.67a.64.64,0,0,0-.15-.89.63.63,0,0,0-.88.15.64.64,0,0,0,.14.88A.65.65,0,0,0,399.62,337.67Z"
          />
          <circle className="cls-4" cx="408.34" cy="330.73" r="0.63" />
          <circle
            className="cls-4"
            cx="417.92"
            cy="324.66"
            r="0.66"
            transform="translate(-89.55 474.77) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M428.32,319.47a.64.64,0,0,0-.15-.88.63.63,0,0,0-.74,1A.64.64,0,0,0,428.32,319.47Z"
          />
          <path
            className="cls-4"
            d="M438.48,314.45a.64.64,0,0,0-.15-.88.63.63,0,0,0-.88.14.63.63,0,0,0,1,.74Z"
          />
          <path
            className="cls-4"
            d="M448.89,310a.64.64,0,0,0-.15-.89.63.63,0,1,0,.15.89Z"
          />
          <circle className="cls-4" cx="459.01" cy="305.67" r="0.63" />
          <path
            className="cls-4"
            d="M470.35,302.68a.64.64,0,0,0-.14-.89.65.65,0,0,0-.89.15.63.63,0,0,0,1,.74Z"
          />
          <path
            className="cls-4"
            d="M481.21,299a.66.66,0,0,0-.77,1.07.67.67,0,0,0,.93-.15A.66.66,0,0,0,481.21,299Z"
          />
          <path
            className="cls-4"
            d="M491.41,296.94a.66.66,0,0,0,.15.92.66.66,0,1,0,.77-1.08A.66.66,0,0,0,491.41,296.94Z"
          />
          <path
            className="cls-4"
            d="M502.8,296.23a.64.64,0,0,0,.88-.15.63.63,0,1,0-1-.74A.64.64,0,0,0,502.8,296.23Z"
          />
          <path
            className="cls-4"
            d="M514,294.33a.63.63,0,1,0,.88-.15A.63.63,0,0,0,514,294.33Z"
          />
          <path
            className="cls-4"
            d="M525.42,294.79a.63.63,0,0,0,.74-1,.63.63,0,0,0-.74,1Z"
          />
          <path
            className="cls-4"
            d="M536.74,295a.66.66,0,1,0-.15-.92A.65.65,0,0,0,536.74,295Z"
          />
          <path
            className="cls-4"
            d="M549,295.62a.66.66,0,1,0-.92.15A.65.65,0,0,0,549,295.62Z"
          />
          <path
            className="cls-4"
            d="M560.23,297a.66.66,0,0,0-1.08-.77.66.66,0,1,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M571.22,298.06a.63.63,0,0,0-.88.14.63.63,0,1,0,1,.74A.63.63,0,0,0,571.22,298.06Z"
          />
          <path
            className="cls-4"
            d="M582.41,301.49a.63.63,0,0,0-.14-.88.63.63,0,1,0-.74,1A.63.63,0,0,0,582.41,301.49Z"
          />
          <path
            className="cls-4"
            d="M593.33,304.64a.66.66,0,1,0-.92.15A.65.65,0,0,0,593.33,304.64Z"
          />
          <path
            className="cls-4"
            d="M603.14,308.47a.63.63,0,0,0,.74-1,.63.63,0,0,0-.74,1Z"
          />
          <path
            className="cls-4"
            d="M614.55,312.61a.66.66,0,1,0-.92.15A.66.66,0,0,0,614.55,312.61Z"
          />
          <path
            className="cls-4"
            d="M623.74,316.64a.67.67,0,1,0,.93-.15A.68.68,0,0,0,623.74,316.64Z"
          />
          <path
            className="cls-4"
            d="M634.82,322.76a.66.66,0,0,0-.16-.92.65.65,0,0,0-.92.15.66.66,0,0,0,1.08.77Z"
          />
          <path
            className="cls-4"
            d="M644.36,327.7a.66.66,0,0,0-.77,1.08.68.68,0,0,0,.93-.15A.67.67,0,0,0,644.36,327.7Z"
          />
          <path
            className="cls-4"
            d="M653.89,335a.66.66,0,1,0-.92.15A.66.66,0,0,0,653.89,335Z"
          />
          <path
            className="cls-4"
            d="M661.85,341.08a.66.66,0,1,0,.92-.15A.65.65,0,0,0,661.85,341.08Z"
          />
          <path
            className="cls-4"
            d="M670.65,349.33a.67.67,0,1,0,.78-1.08.67.67,0,0,0-.93.15A.68.68,0,0,0,670.65,349.33Z"
          />
          <path
            className="cls-4"
            d="M678.76,356.17a.66.66,0,1,0,1.07.77.65.65,0,0,0-.15-.92A.66.66,0,0,0,678.76,356.17Z"
          />
          <circle
            className="cls-4"
            cx="687.13"
            cy="364.75"
            r="0.66"
            transform="translate(-9.93 710.15) rotate(-54.32)"
          />
          <circle className="cls-4" cx="694.52" cy="373.35" r="0.63" />
          <path
            className="cls-4"
            d="M702,382.71a.67.67,0,1,0-1.08-.78.67.67,0,0,0,1.08.78Z"
          />
          <path
            className="cls-4"
            d="M708.43,392a.67.67,0,0,0-.15-.93.68.68,0,0,0-.93.15.67.67,0,0,0,1.08.78Z"
          />
          <path
            className="cls-4"
            d="M714.22,400.77a.66.66,0,0,0-.77,1.07.66.66,0,0,0,.92-.15A.65.65,0,0,0,714.22,400.77Z"
          />
          <path
            className="cls-4"
            d="M719.8,411.64a.66.66,0,0,0-1.08-.77.68.68,0,0,0,.15.93A.67.67,0,0,0,719.8,411.64Z"
          />
          <path
            className="cls-4"
            d="M724.69,421.87a.66.66,0,0,0-1.08-.77.66.66,0,0,0,.16.92A.65.65,0,0,0,724.69,421.87Z"
          />
          <path
            className="cls-4"
            d="M728,431.57a.66.66,0,1,0,.92-.15A.65.65,0,0,0,728,431.57Z"
          />
          <path
            className="cls-4"
            d="M731.75,442.26a.65.65,0,0,0,.15.92.66.66,0,1,0-.15-.92Z"
          />
          <path
            className="cls-4"
            d="M735,453.12a.67.67,0,0,0,1.08.78.67.67,0,0,0-.15-.93A.68.68,0,0,0,735,453.12Z"
          />
          <circle className="cls-4" cx="738.14" cy="464.54" r="0.63" />
          <path
            className="cls-4"
            d="M739.66,475.3a.66.66,0,1,0,.92-.15A.65.65,0,0,0,739.66,475.3Z"
          />
          <path
            className="cls-4"
            d="M741.14,486.56a.63.63,0,0,0,1,.74.63.63,0,1,0-1-.74Z"
          />
          <path
            className="cls-4"
            d="M742,497.86a.63.63,0,1,0,.88-.14A.63.63,0,0,0,742,497.86Z"
          />
          <path
            className="cls-4"
            d="M743.18,509a.66.66,0,1,0,.15.92A.65.65,0,0,0,743.18,509Z"
          />
          <path
            className="cls-4"
            d="M741.93,520.51a.66.66,0,1,0,.92-.15A.66.66,0,0,0,741.93,520.51Z"
          />
          <path
            className="cls-4"
            d="M741,531.81a.66.66,0,1,0,.92-.15A.65.65,0,0,0,741,531.81Z"
          />
          <path
            className="cls-4"
            d="M739.48,543a.66.66,0,1,0,.92-.15A.66.66,0,0,0,739.48,543Z"
          />
          <path
            className="cls-4"
            d="M737.36,554.18a.67.67,0,0,0,.16.93.66.66,0,0,0,.77-1.08A.67.67,0,0,0,737.36,554.18Z"
          />
          <path
            className="cls-4"
            d="M734.67,565.19a.66.66,0,1,0,.92-.15A.66.66,0,0,0,734.67,565.19Z"
          />
          <path
            className="cls-4"
            d="M731.39,576.05a.66.66,0,1,0,.93-.16A.67.67,0,0,0,731.39,576.05Z"
          />
          <path
            className="cls-4"
            d="M728.47,586.56a.66.66,0,0,0-.92.15.68.68,0,0,0,.15.93.67.67,0,0,0,.93-.16A.66.66,0,0,0,728.47,586.56Z"
          />
          <path
            className="cls-4"
            d="M723.15,597.16a.66.66,0,1,0,.92-.15A.65.65,0,0,0,723.15,597.16Z"
          />
          <path
            className="cls-4"
            d="M718.2,607.36a.66.66,0,0,0,.16.92.65.65,0,0,0,.92-.15.66.66,0,0,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M712.73,617.29a.65.65,0,0,0,.15.92.66.66,0,1,0-.15-.92Z"
          />
          <circle
            className="cls-4"
            cx="707.27"
            cy="627.3"
            r="0.66"
            transform="translate(-214.79 835.93) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M700.24,636.2a.68.68,0,0,0,.15.93.67.67,0,1,0-.15-.93Z"
          />
          <path
            className="cls-4"
            d="M693.27,645.14a.66.66,0,1,0,.92-.15A.65.65,0,0,0,693.27,645.14Z"
          />
          <path
            className="cls-4"
            d="M685.83,653.7a.66.66,0,0,0,.15.92.67.67,0,0,0,.93-.15.68.68,0,0,0-.15-.93A.67.67,0,0,0,685.83,653.7Z"
          />
          <path
            className="cls-4"
            d="M678,661.85a.66.66,0,0,0,1.08.77.66.66,0,0,0-1.08-.77Z"
          />
          <path
            className="cls-4"
            d="M669.68,669.59a.63.63,0,1,0,1,.74.63.63,0,0,0-1-.74Z"
          />
          <path
            className="cls-4"
            d="M661.87,676.72a.64.64,0,0,0-.88.15.63.63,0,0,0,.14.88.64.64,0,0,0,.89-.14A.65.65,0,0,0,661.87,676.72Z"
          />
          <circle
            className="cls-4"
            cx="652.44"
            cy="684.05"
            r="0.66"
            transform="translate(-283.74 815.03) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M642.51,690a.63.63,0,0,0,.15.88.63.63,0,1,0-.15-.88Z"
          />
          <circle className="cls-4" cx="633.29" cy="696.18" r="0.63" />
          <circle className="cls-4" cx="628.87" cy="712.8" r="0.63" />
          <path
            className="cls-4"
            d="M618.94,717.08a.61.61,0,0,0-.71,1,.61.61,0,0,0,.84-.14A.59.59,0,0,0,618.94,717.08Z"
          />
          <path
            className="cls-4"
            d="M607.56,721.45a.63.63,0,0,0,1,.74.63.63,0,0,0-1-.74Z"
          />
          <circle className="cls-4" cx="597.37" cy="725.54" r="0.63" />
          <circle className="cls-4" cx="586.49" cy="728.73" r="0.63" />
          <path
            className="cls-4"
            d="M576,731.72a.6.6,0,0,0-.14-.84.6.6,0,1,0,.14.84Z"
          />
          <path
            className="cls-4"
            d="M563.83,733.11a.6.6,0,1,0,.84-.14A.6.6,0,0,0,563.83,733.11Z"
          />
          <path
            className="cls-4"
            d="M553.6,735.35a.63.63,0,0,0-.14-.88.64.64,0,0,0-.89.15.63.63,0,1,0,1,.73Z"
          />
          <path
            className="cls-4"
            d="M542.14,735.46a.6.6,0,1,0,.14.84A.6.6,0,0,0,542.14,735.46Z"
          />
          <path
            className="cls-4"
            d="M530.11,736.84a.59.59,0,0,0,.84-.13.61.61,0,1,0-.84.13Z"
          />
          <circle className="cls-4" cx="519.13" cy="736.19" r="0.63" />
          <circle className="cls-4" cx="507.81" cy="735.46" r="0.63" />
          <path
            className="cls-4"
            d="M497,734.52a.6.6,0,1,0-.84.14A.6.6,0,0,0,497,734.52Z"
          />
          <path
            className="cls-4"
            d="M484.85,732a.63.63,0,1,0,1,.74.63.63,0,0,0-1-.74Z"
          />
          <path
            className="cls-4"
            d="M474.66,729.4a.64.64,0,0,0-.89.15.63.63,0,0,0,.15.88.64.64,0,0,0,.88-.14A.65.65,0,0,0,474.66,729.4Z"
          />
          <path
            className="cls-4"
            d="M463.83,727.31a.6.6,0,1,0-.84.14A.61.61,0,0,0,463.83,727.31Z"
          />
          <path
            className="cls-4"
            d="M452.07,723.11a.61.61,0,0,0,1,.71.61.61,0,0,0-.14-.84A.59.59,0,0,0,452.07,723.11Z"
          />
          <path
            className="cls-4"
            d="M441.45,719.07a.63.63,0,1,0,.88-.15A.63.63,0,0,0,441.45,719.07Z"
          />
          <circle className="cls-4" cx="431.58" cy="714.88" r="0.63" />
          <path
            className="cls-4"
            d="M421,709.46a.61.61,0,0,0,.13.85.62.62,0,0,0,.85-.14.61.61,0,0,0-.14-.84A.59.59,0,0,0,421,709.46Z"
          />
          <path
            className="cls-4"
            d="M411.07,703.9a.61.61,0,0,0,.14.84.6.6,0,0,0,.84-.14.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M401.48,697.85a.61.61,0,0,0,.14.84.6.6,0,0,0,.84-.14.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M393.19,692a.6.6,0,0,0-1-.7.6.6,0,0,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M383.41,685.2a.6.6,0,1,0,.7-1,.6.6,0,0,0-.84.14A.61.61,0,0,0,383.41,685.2Z"
          />
          <path
            className="cls-4"
            d="M374.83,677.79a.6.6,0,0,0,.7-1,.59.59,0,0,0-.84.13A.62.62,0,0,0,374.83,677.79Z"
          />
          <path
            className="cls-4"
            d="M367.33,669a.6.6,0,0,0-.84.14.6.6,0,1,0,.84-.14Z"
          />
          <path
            className="cls-4"
            d="M358.69,660.89a.6.6,0,1,0,1,.7.59.59,0,0,0-.14-.84A.6.6,0,0,0,358.69,660.89Z"
          />
          <path
            className="cls-4"
            d="M352.29,653a.6.6,0,0,0-.13-.84.62.62,0,0,0-.85.14.61.61,0,0,0,.14.84A.6.6,0,0,0,352.29,653Z"
          />
          <path
            className="cls-4"
            d="M344.37,643.32a.6.6,0,1,0,1,.7.59.59,0,0,0-.13-.84A.61.61,0,0,0,344.37,643.32Z"
          />
          <path
            className="cls-4"
            d="M337.89,634a.6.6,0,1,0,.84-.14A.6.6,0,0,0,337.89,634Z"
          />
          <circle className="cls-4" cx="332.37" cy="624.76" r="0.63" />
          <path
            className="cls-4"
            d="M327.33,615.21a.6.6,0,1,0-.84.14A.61.61,0,0,0,327.33,615.21Z"
          />
          <path
            className="cls-4"
            d="M321.46,605.19a.61.61,0,0,0,.84-.14.6.6,0,0,0-1-.7A.61.61,0,0,0,321.46,605.19Z"
          />
          <path
            className="cls-4"
            d="M317,594.79a.6.6,0,1,0-.14-.84A.6.6,0,0,0,317,594.79Z"
          />
          <path
            className="cls-4"
            d="M312.94,584.2a.63.63,0,1,0-.15-.88A.64.64,0,0,0,312.94,584.2Z"
          />
          <path
            className="cls-4"
            d="M310.2,572.4a.6.6,0,1,0,.14.84A.6.6,0,0,0,310.2,572.4Z"
          />
          <path
            className="cls-4"
            d="M307.43,562.28a.6.6,0,1,0-.84.14A.59.59,0,0,0,307.43,562.28Z"
          />
          <path
            className="cls-4"
            d="M305.07,551.2a.61.61,0,0,0-.14-.84.6.6,0,0,0-.71,1A.61.61,0,0,0,305.07,551.2Z"
          />
          <path
            className="cls-4"
            d="M303.26,540a.62.62,0,0,0-.14-.85.61.61,0,0,0-.84.14.61.61,0,0,0,1,.71Z"
          />
          <circle className="cls-4" cx="301.52" cy="528.39" r="0.63" />
          <path
            className="cls-4"
            d="M300.48,517.56a.62.62,0,0,0,.85-.14.6.6,0,0,0-1-.7A.59.59,0,0,0,300.48,517.56Z"
          />
          <path
            className="cls-4"
            d="M300.23,505.38a.6.6,0,1,0,.84-.14A.61.61,0,0,0,300.23,505.38Z"
          />
          <path
            className="cls-4"
            d="M300.67,494.05a.6.6,0,0,0,.14.84.59.59,0,0,0,.84-.13.61.61,0,0,0-.14-.84A.6.6,0,0,0,300.67,494.05Z"
          />
          <path
            className="cls-4"
            d="M302.66,483.47a.62.62,0,0,0-.14-.85.6.6,0,0,0-.7,1A.59.59,0,0,0,302.66,483.47Z"
          />
          <path
            className="cls-4"
            d="M304.23,472.24a.61.61,0,0,0-.14-.84.59.59,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M306.38,461.12a.63.63,0,0,0-1-.74.63.63,0,1,0,1,.74Z"
          />
          <path
            className="cls-4"
            d="M309,450.09a.6.6,0,1,0-1-.7.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M312.27,439.22a.61.61,0,0,0-.14-.84.6.6,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M316,428.53a.59.59,0,0,0-.13-.84.6.6,0,1,0-.71,1A.59.59,0,0,0,316,428.53Z"
          />
          <circle className="cls-4" cx="319.84" cy="417.68" r="0.63" />
          <path
            className="cls-4"
            d="M325.14,407.77a.61.61,0,0,0-.14-.84.61.61,0,0,0-.71,1A.62.62,0,0,0,325.14,407.77Z"
          />
          <path
            className="cls-4"
            d="M330.45,397.76a.61.61,0,1,0-.84.13A.59.59,0,0,0,330.45,397.76Z"
          />
          <path
            className="cls-4"
            d="M336.26,388a.6.6,0,0,0-1-.7.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M342.55,378.59a.61.61,0,0,0-.14-.84.59.59,0,0,0-.84.13.61.61,0,0,0,.14.84A.6.6,0,0,0,342.55,378.59Z"
          />
          <path
            className="cls-4"
            d="M349.3,369.48a.61.61,0,0,0-.14-.84.6.6,0,1,0,.14.84Z"
          />
          <path
            className="cls-4"
            d="M356.49,360.72a.61.61,0,1,0-.84.13A.59.59,0,0,0,356.49,360.72Z"
          />
          <path
            className="cls-4"
            d="M364.12,352.32a.6.6,0,0,0-1-.7.59.59,0,0,0,.13.84A.6.6,0,0,0,364.12,352.32Z"
          />
          <path
            className="cls-4"
            d="M372.17,344.34a.63.63,0,0,0-.14-.88.63.63,0,1,0,.14.88Z"
          />
          <path
            className="cls-4"
            d="M380.57,336.73a.6.6,0,0,0-1-.7.6.6,0,0,0,.14.84A.61.61,0,0,0,380.57,336.73Z"
          />
          <path
            className="cls-4"
            d="M389.35,329.57a.59.59,0,0,0-.13-.84.61.61,0,0,0-.84.14.59.59,0,0,0,.13.84A.61.61,0,0,0,389.35,329.57Z"
          />
          <path
            className="cls-4"
            d="M398.49,322.86a.61.61,0,0,0-.14-.84.6.6,0,0,0-.84.13.61.61,0,0,0,.14.84A.59.59,0,0,0,398.49,322.86Z"
          />
          <path
            className="cls-4"
            d="M408,316.62a.63.63,0,0,0-.14-.88.65.65,0,0,0-.89.14.64.64,0,0,0,.15.89A.63.63,0,0,0,408,316.62Z"
          />
          <circle className="cls-4" cx="417.22" cy="310.48" r="0.63" />
          <path
            className="cls-4"
            d="M427.74,305.56a.62.62,0,0,0-.14-.85.6.6,0,0,0-.7,1A.6.6,0,0,0,427.74,305.56Z"
          />
          <path
            className="cls-4"
            d="M438.05,300.8a.63.63,0,0,0-1-.74.64.64,0,0,0,.14.89A.65.65,0,0,0,438.05,300.8Z"
          />
          <path
            className="cls-4"
            d="M448.56,296.55a.65.65,0,0,0-.15-.89.64.64,0,0,0-.88.15.63.63,0,0,0,.14.88A.64.64,0,0,0,448.56,296.55Z"
          />
          <path
            className="cls-4"
            d="M459.24,292.81a.61.61,0,0,0-.14-.84.59.59,0,0,0-.84.13.61.61,0,0,0,.14.84A.6.6,0,0,0,459.24,292.81Z"
          />
          <path
            className="cls-4"
            d="M469.28,289.76a.6.6,0,1,0-.14-.84A.61.61,0,0,0,469.28,289.76Z"
          />
          <circle className="cls-4" cx="480.65" cy="286.63" r="0.63" />
          <path
            className="cls-4"
            d="M491.43,285.06a.63.63,0,0,0,.88-.15.64.64,0,0,0-.14-.88.65.65,0,0,0-.89.14A.64.64,0,0,0,491.43,285.06Z"
          />
          <path
            className="cls-4"
            d="M502.54,282.66a.61.61,0,0,0,.14.84.59.59,0,0,0,.84-.13.61.61,0,0,0-.14-.84A.59.59,0,0,0,502.54,282.66Z"
          />
          <path
            className="cls-4"
            d="M514,282.56a.63.63,0,0,0,.88-.14.63.63,0,1,0-1-.74A.63.63,0,0,0,514,282.56Z"
          />
          <circle className="cls-4" cx="525.66" cy="281.65" r="0.63" />
          <circle className="cls-4" cx="536.99" cy="281.81" r="0.63" />
          <path
            className="cls-4"
            d="M548,283a.61.61,0,0,0,.84-.14.6.6,0,1,0-.84.14Z"
          />
          <circle className="cls-4" cx="559.57" cy="283.83" r="0.63" />
          <circle className="cls-4" cx="570.75" cy="285.68" r="0.63" />
          <path
            className="cls-4"
            d="M582.2,287.57a.63.63,0,1,0,.15.88A.64.64,0,0,0,582.2,287.57Z"
          />
          <path
            className="cls-4"
            d="M592.26,290.67a.63.63,0,0,0,1,.74.63.63,0,1,0-1-.74Z"
          />
          <path
            className="cls-4"
            d="M603.07,294.18a.61.61,0,0,0,.14.84.59.59,0,0,0,.84-.13.61.61,0,0,0-.14-.84A.6.6,0,0,0,603.07,294.18Z"
          />
          <path
            className="cls-4"
            d="M614.65,298.92a.61.61,0,0,0-.14-.84.59.59,0,0,0-.84.13.59.59,0,0,0,.14.84A.6.6,0,0,0,614.65,298.92Z"
          />
          <path
            className="cls-4"
            d="M624.19,303.61a.6.6,0,1,0-.14-.84A.61.61,0,0,0,624.19,303.61Z"
          />
          <path
            className="cls-4"
            d="M635.2,308.55a.63.63,0,1,0-.89.15A.64.64,0,0,0,635.2,308.55Z"
          />
          <circle className="cls-4" cx="644.56" cy="313.75" r="0.63" />
          <path
            className="cls-4"
            d="M653.63,319.43a.63.63,0,0,0,.15.88.64.64,0,0,0,.88-.14.65.65,0,0,0-.14-.89A.64.64,0,0,0,653.63,319.43Z"
          />
          <path
            className="cls-4"
            d="M662.91,326a.63.63,0,0,0,.14.88.63.63,0,1,0,.74-1A.63.63,0,0,0,662.91,326Z"
          />
          <circle className="cls-4" cx="672.36" cy="333.29" r="0.63" />
          <path
            className="cls-4"
            d="M680.42,340.33a.65.65,0,0,0,.15.89.63.63,0,0,0,.88-.15.64.64,0,0,0-.14-.88A.65.65,0,0,0,680.42,340.33Z"
          />
          <path
            className="cls-4"
            d="M689.51,348a.63.63,0,1,0-.74,1,.63.63,0,0,0,.88-.15A.64.64,0,0,0,689.51,348Z"
          />
          <path
            className="cls-4"
            d="M697.29,356.27a.62.62,0,0,0-.85.14.6.6,0,1,0,1,.7A.6.6,0,0,0,697.29,356.27Z"
          />
          <path
            className="cls-4"
            d="M703.82,365a.6.6,0,1,0,1,.7.59.59,0,0,0-.13-.84A.62.62,0,0,0,703.82,365Z"
          />
          <path
            className="cls-4"
            d="M710.74,374a.63.63,0,1,0,1,.74.63.63,0,0,0-.15-.88A.64.64,0,0,0,710.74,374Z"
          />
          <path
            className="cls-4"
            d="M718.11,383.12a.63.63,0,1,0-.74,1,.63.63,0,1,0,.74-1Z"
          />
          <path
            className="cls-4"
            d="M723.24,392.87a.64.64,0,0,0,.14.89.65.65,0,0,0,.89-.15.64.64,0,0,0-.15-.88A.63.63,0,0,0,723.24,392.87Z"
          />
          <path
            className="cls-4"
            d="M729.65,402.63a.63.63,0,1,0-.74,1,.63.63,0,1,0,.74-1Z"
          />
          <path
            className="cls-4"
            d="M733.94,413.82a.64.64,0,0,0,.88-.15.63.63,0,0,0-1-.74A.64.64,0,0,0,733.94,413.82Z"
          />
          <path
            className="cls-4"
            d="M738.28,423.31a.68.68,0,0,0,.15.93.67.67,0,0,0,.93-.15.67.67,0,0,0-1.08-.78Z"
          />
          <circle className="cls-4" cx="742.81" cy="434.31" r="0.63" />
          <path
            className="cls-4"
            d="M746.63,444.6a.63.63,0,1,0,.15.88A.63.63,0,0,0,746.63,444.6Z"
          />
          <circle className="cls-4" cx="749.18" cy="456.07" r="0.63" />
          <circle className="cls-4" cx="751.54" cy="467.15" r="0.63" />
          <path
            className="cls-4"
            d="M752.84,478a.63.63,0,1,0,.88-.15A.63.63,0,0,0,752.84,478Z"
          />
          <path
            className="cls-4"
            d="M754.08,489.24a.64.64,0,0,0,.15.89.63.63,0,0,0,.74-1A.65.65,0,0,0,754.08,489.24Z"
          />
          <path
            className="cls-4"
            d="M754.77,500.56a.63.63,0,0,0,.14.88.63.63,0,1,0,.74-1A.63.63,0,0,0,754.77,500.56Z"
          />
          <path
            className="cls-4"
            d="M755.92,512.64a.64.64,0,0,0-.15-.89.63.63,0,0,0-.88.15.64.64,0,0,0,.14.88A.65.65,0,0,0,755.92,512.64Z"
          />
          <path
            className="cls-4"
            d="M754.6,524.08a.61.61,0,0,0,.85-.13.62.62,0,0,0-.14-.85.61.61,0,0,0-.84.14A.59.59,0,0,0,754.6,524.08Z"
          />
          <path
            className="cls-4"
            d="M753.43,534.52a.63.63,0,1,0,.89-.15A.64.64,0,0,0,753.43,534.52Z"
          />
          <circle className="cls-4" cx="752.38" cy="546.11" r="0.63" />
          <circle className="cls-4" cx="750.25" cy="557.25" r="0.63" />
          <circle className="cls-4" cx="747.57" cy="568.26" r="0.63" />
          <path
            className="cls-4"
            d="M743.83,578.76a.63.63,0,1,0,.88-.14A.62.62,0,0,0,743.83,578.76Z"
          />
          <path
            className="cls-4"
            d="M740.93,589.34a.6.6,0,1,0-.71,1,.6.6,0,0,0,.71-1Z"
          />
          <path
            className="cls-4"
            d="M735.79,600a.6.6,0,1,0,1,.7.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M731,610.21a.64.64,0,0,0,.14.89.63.63,0,0,0,.74-1A.64.64,0,0,0,731,610.21Z"
          />
          <path
            className="cls-4"
            d="M725.64,620.23a.63.63,0,1,0,.88-.15A.63.63,0,0,0,725.64,620.23Z"
          />
          <path
            className="cls-4"
            d="M719.83,630a.63.63,0,1,0,1,.74.63.63,0,0,0-1-.74Z"
          />
          <path
            className="cls-4"
            d="M713.55,639.4a.63.63,0,1,0,.88-.15A.63.63,0,0,0,713.55,639.4Z"
          />
          <circle className="cls-4" cx="707.31" cy="648.87" r="0.63" />
          <path
            className="cls-4"
            d="M699.6,657.27a.63.63,0,1,0,.88-.15A.63.63,0,0,0,699.6,657.27Z"
          />
          <circle className="cls-4" cx="692.49" cy="666.03" r="0.63" />
          <path
            className="cls-4"
            d="M684,673.68a.6.6,0,1,0,.84-.14A.6.6,0,0,0,684,673.68Z"
          />
          <path
            className="cls-4"
            d="M675.55,681.27a.6.6,0,1,0,.84-.14A.6.6,0,0,0,675.55,681.27Z"
          />
          <path
            className="cls-4"
            d="M667.62,688.27a.63.63,0,0,0-.74,1,.63.63,0,1,0,.74-1Z"
          />
          <path
            className="cls-4"
            d="M657.6,695.13a.63.63,0,1,0,1,.73.63.63,0,1,0-1-.73Z"
          />
          <circle className="cls-4" cx="648.66" cy="701.75" r="0.63" />
          <circle className="cls-4" cx="638.9" cy="707.52" r="0.63" />
          <path
            className="cls-4"
            d="M634,723.77a.6.6,0,1,0,.84-.14A.61.61,0,0,0,634,723.77Z"
          />
          <path
            className="cls-4"
            d="M624.54,728.41a.6.6,0,0,0-.7,1,.59.59,0,0,0,.84-.14A.6.6,0,0,0,624.54,728.41Z"
          />
          <path
            className="cls-4"
            d="M613.21,732.85a.6.6,0,1,0,.84-.14A.6.6,0,0,0,613.21,732.85Z"
          />
          <path
            className="cls-4"
            d="M602.55,736.66a.57.57,0,0,0,.93.67.57.57,0,1,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M592.52,739.79a.61.61,0,0,0-.71,1,.62.62,0,0,0,.85-.14A.61.61,0,0,0,592.52,739.79Z"
          />
          <path
            className="cls-4"
            d="M580.68,742.7a.6.6,0,0,0,1,.7.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M569.57,744.94a.61.61,0,0,0,.14.84.59.59,0,0,0,.84-.13.62.62,0,0,0-.14-.85A.61.61,0,0,0,569.57,744.94Z"
          />
          <path
            className="cls-4"
            d="M558.39,746.68a.57.57,0,0,0,.13.8.58.58,0,0,0,.8-.13.57.57,0,0,0-.13-.8A.56.56,0,0,0,558.39,746.68Z"
          />
          <path
            className="cls-4"
            d="M547.12,747.87a.57.57,0,0,0,.13.8.57.57,0,0,0,.67-.93A.56.56,0,0,0,547.12,747.87Z"
          />
          <path
            className="cls-4"
            d="M536.6,748.39a.58.58,0,0,0-.8.13.57.57,0,0,0,.93.67A.58.58,0,0,0,536.6,748.39Z"
          />
          <path
            className="cls-4"
            d="M525.42,749.33a.61.61,0,0,0-.14-.84.61.61,0,0,0-.71,1A.62.62,0,0,0,525.42,749.33Z"
          />
          <path
            className="cls-4"
            d="M513.25,749.05a.6.6,0,0,0,.84-.14.6.6,0,0,0-1-.7A.6.6,0,0,0,513.25,749.05Z"
          />
          <path
            className="cls-4"
            d="M502.65,747.12a.6.6,0,0,0-.7,1,.6.6,0,0,0,.84-.14A.61.61,0,0,0,502.65,747.12Z"
          />
          <path
            className="cls-4"
            d="M491.39,745.66a.57.57,0,0,0-.79.14.55.55,0,0,0,.13.79.57.57,0,0,0,.8-.13A.58.58,0,0,0,491.39,745.66Z"
          />
          <path
            className="cls-4"
            d="M479.44,743.78a.57.57,0,0,0,.93.67.57.57,0,1,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M468.36,741.23a.6.6,0,1,0,1,.7.6.6,0,0,0-.13-.84A.62.62,0,0,0,468.36,741.23Z"
          />
          <circle className="cls-4" cx="457.94" cy="738.53" r="0.57" />
          <path
            className="cls-4"
            d="M447.53,734.47a.61.61,0,0,0-.71,1,.61.61,0,0,0,.84-.14A.59.59,0,0,0,447.53,734.47Z"
          />
          <path
            className="cls-4"
            d="M436.11,730.53a.6.6,0,1,0,1,.71.6.6,0,1,0-1-.71Z"
          />
          <path
            className="cls-4"
            d="M425.87,726.81a.6.6,0,1,0-.14-.84A.61.61,0,0,0,425.87,726.81Z"
          />
          <circle className="cls-4" cx="416.07" cy="721.27" r="0.57" />
          <path
            className="cls-4"
            d="M405.71,715.41a.57.57,0,0,0,.13.8.59.59,0,0,0,.8-.13.58.58,0,0,0-.14-.8A.55.55,0,0,0,405.71,715.41Z"
          />
          <path
            className="cls-4"
            d="M396.08,709.42a.56.56,0,0,0,.13.8.58.58,0,0,0,.8-.13.58.58,0,0,0-.13-.8A.57.57,0,0,0,396.08,709.42Z"
          />
          <path
            className="cls-4"
            d="M387.67,703.65a.55.55,0,0,0-.13-.79.57.57,0,0,0-.8.13.58.58,0,0,0,.14.8A.57.57,0,0,0,387.67,703.65Z"
          />
          <path
            className="cls-4"
            d="M377.72,696.12a.58.58,0,0,0,.14.8.57.57,0,0,0,.79-.13.57.57,0,1,0-.93-.67Z"
          />
          <circle className="cls-4" cx="369.5" cy="689.17" r="0.57" />
          <path
            className="cls-4"
            d="M360.73,681.16a.55.55,0,0,0,.13.76.54.54,0,0,0,.63-.88A.54.54,0,0,0,360.73,681.16Z"
          />
          <path
            className="cls-4"
            d="M353.7,673.75a.6.6,0,0,0-.13-.84.6.6,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M345.16,664.61a.6.6,0,1,0,.84-.14A.61.61,0,0,0,345.16,664.61Z"
          />
          <path
            className="cls-4"
            d="M338.13,656.66a.59.59,0,0,0,.84-.13.61.61,0,1,0-.84.13Z"
          />
          <path
            className="cls-4"
            d="M332.08,646.59a.58.58,0,0,0-.8.13.57.57,0,0,0,.13.8.56.56,0,0,0,.8-.13A.58.58,0,0,0,332.08,646.59Z"
          />
          <path
            className="cls-4"
            d="M325.11,638.1a.57.57,0,1,0,.67-.93.57.57,0,1,0-.67.93Z"
          />
          <path
            className="cls-4"
            d="M320.06,628.25a.57.57,0,0,0-.93-.66.57.57,0,1,0,.93.66Z"
          />
          <path
            className="cls-4"
            d="M313.88,618.41a.58.58,0,0,0,.8-.13.58.58,0,0,0-.13-.8.57.57,0,1,0-.67.93Z"
          />
          <path
            className="cls-4"
            d="M309.66,607.23a.61.61,0,0,0-.84.14.6.6,0,1,0,.84-.14Z"
          />
          <path
            className="cls-4"
            d="M304.55,597.75a.58.58,0,0,0,.8-.13.57.57,0,1,0-.93-.67A.58.58,0,0,0,304.55,597.75Z"
          />
          <path
            className="cls-4"
            d="M300.63,587.11a.57.57,0,0,0,.8-.13.56.56,0,0,0-.13-.8.57.57,0,1,0-.67.93Z"
          />
          <path
            className="cls-4"
            d="M298,576.17a.57.57,0,0,0-.13-.8.57.57,0,0,0-.67.93A.58.58,0,0,0,298,576.17Z"
          />
          <path
            className="cls-4"
            d="M295.12,565.21a.57.57,0,1,0-.8.13A.58.58,0,0,0,295.12,565.21Z"
          />
          <path
            className="cls-4"
            d="M292.74,554.12a.55.55,0,0,0-.13-.79.57.57,0,0,0-.8.13.57.57,0,0,0,.13.79A.56.56,0,0,0,292.74,554.12Z"
          />
          <path
            className="cls-4"
            d="M290.91,543a.6.6,0,1,0-.84.13A.59.59,0,0,0,290.91,543Z"
          />
          <path
            className="cls-4"
            d="M288.77,531.81a.58.58,0,0,0,.8-.13.57.57,0,1,0-.93-.67A.56.56,0,0,0,288.77,531.81Z"
          />
          <circle className="cls-4" cx="288.31" cy="520.04" r="0.57" />
          <circle className="cls-4" cx="288.06" cy="508.7" r="0.57" />
          <circle className="cls-4" cx="288.34" cy="497.37" r="0.57" />
          <path
            className="cls-4"
            d="M288.67,485.71a.6.6,0,1,0,.84-.14A.61.61,0,0,0,288.67,485.71Z"
          />
          <circle
            className="cls-4"
            cx="290.51"
            cy="474.8"
            r="0.54"
            transform="translate(-264.61 433.85) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M292.85,464a.56.56,0,0,0-.13-.8.57.57,0,1,0-.66.93A.56.56,0,0,0,292.85,464Z"
          />
          <path
            className="cls-4"
            d="M295.26,452.88a.58.58,0,0,0-.13-.8.58.58,0,0,0-.8.13.57.57,0,0,0,.13.8A.57.57,0,0,0,295.26,452.88Z"
          />
          <circle className="cls-4" cx="297.72" cy="441.59" r="0.57" />
          <path
            className="cls-4"
            d="M301.63,431.12a.57.57,0,0,0-.14-.79.55.55,0,0,0-.79.13.57.57,0,0,0,.13.8A.58.58,0,0,0,301.63,431.12Z"
          />
          <path
            className="cls-4"
            d="M305.6,420.51a.6.6,0,1,0-1-.7.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M310,410.08a.6.6,0,0,0-.14-.84.6.6,0,0,0-.7,1A.6.6,0,0,0,310,410.08Z"
          />
          <path
            className="cls-4"
            d="M314.92,399.84a.53.53,0,0,0-.12-.75.54.54,0,1,0,.12.75Z"
          />
          <path
            className="cls-4"
            d="M320.36,389.9a.58.58,0,0,0-.13-.8.57.57,0,1,0-.67.93A.59.59,0,0,0,320.36,389.9Z"
          />
          <path
            className="cls-4"
            d="M326.23,380.2a.58.58,0,0,0-.13-.8.56.56,0,0,0-.8.13.58.58,0,0,0,.13.8A.58.58,0,0,0,326.23,380.2Z"
          />
          <circle className="cls-4" cx="332.09" cy="370.46" r="0.57" />
          <path
            className="cls-4"
            d="M339.31,361.69a.56.56,0,0,0-.13-.8.57.57,0,1,0,.13.8Z"
          />
          <path
            className="cls-4"
            d="M346.5,352.92a.58.58,0,0,0-.13-.8.57.57,0,0,0-.67.93A.58.58,0,0,0,346.5,352.92Z"
          />
          <path
            className="cls-4"
            d="M354.09,344.5a.58.58,0,0,0-.13-.8.57.57,0,0,0-.67.93A.58.58,0,0,0,354.09,344.5Z"
          />
          <path
            className="cls-4"
            d="M362.09,336.46a.6.6,0,0,0-.14-.84.6.6,0,1,0,.14.84Z"
          />
          <path
            className="cls-4"
            d="M370.44,328.79a.6.6,0,0,0-.14-.84.6.6,0,1,0,.14.84Z"
          />
          <path
            className="cls-4"
            d="M379.14,321.53a.6.6,0,1,0-.84.13A.59.59,0,0,0,379.14,321.53Z"
          />
          <circle className="cls-4" cx="387.69" cy="314.33" r="0.57" />
          <path
            className="cls-4"
            d="M397.53,308.27a.6.6,0,0,0-1-.7.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M407.15,302.29a.58.58,0,0,0-.13-.8.58.58,0,0,0-.8.13.57.57,0,1,0,.93.67Z"
          />
          <path
            className="cls-4"
            d="M417.06,296.79a.56.56,0,0,0-.13-.8.58.58,0,0,0-.8.13.58.58,0,0,0,.13.8A.57.57,0,0,0,417.06,296.79Z"
          />
          <path
            className="cls-4"
            d="M427.25,291.78a.61.61,0,0,0-.14-.84.6.6,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <circle
            className="cls-4"
            cx="437.15"
            cy="286.89"
            r="0.6"
            transform="translate(-50.86 474.64) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M448.23,283.19a.6.6,0,1,0-1-.7.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M459,279.63a.55.55,0,0,0-.13-.79.57.57,0,0,0-.8.13.59.59,0,0,0,.13.8A.58.58,0,0,0,459,279.63Z"
          />
          <path
            className="cls-4"
            d="M469,275.94a.57.57,0,0,0,.13.8.57.57,0,0,0,.67-.93A.56.56,0,0,0,469,275.94Z"
          />
          <path
            className="cls-4"
            d="M480.15,274.23a.58.58,0,0,0,.8-.13.57.57,0,0,0-.93-.67A.58.58,0,0,0,480.15,274.23Z"
          />
          <path
            className="cls-4"
            d="M492.14,272.13a.6.6,0,1,0-.84.14A.61.61,0,0,0,492.14,272.13Z"
          />
          <path
            className="cls-4"
            d="M503.38,270.68a.61.61,0,1,0-.84.13A.59.59,0,0,0,503.38,270.68Z"
          />
          <path
            className="cls-4"
            d="M513.7,269.05a.61.61,0,0,0,.14.84.6.6,0,1,0,.7-1A.61.61,0,0,0,513.7,269.05Z"
          />
          <path
            className="cls-4"
            d="M526,269.35a.58.58,0,0,0-.13-.8.57.57,0,0,0-.8.13.56.56,0,0,0,.13.8A.58.58,0,0,0,526,269.35Z"
          />
          <path
            className="cls-4"
            d="M536.37,268.81a.61.61,0,0,0,.14.84.6.6,0,0,0,.84-.14.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M548.64,270.18a.58.58,0,0,0-.13-.8.58.58,0,0,0-.8.13.57.57,0,0,0,.13.8A.56.56,0,0,0,548.64,270.18Z"
          />
          <path
            className="cls-4"
            d="M559.93,271.41a.6.6,0,0,0-.13-.84.61.61,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M571,272.32a.61.61,0,0,0-.84.14.6.6,0,0,0,.14.84.6.6,0,0,0,.7-1Z"
          />
          <path
            className="cls-4"
            d="M581.29,274.75a.57.57,0,1,0,.93.67.57.57,0,0,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M592.25,277.53a.6.6,0,1,0,.84-.14A.61.61,0,0,0,592.25,277.53Z"
          />
          <path
            className="cls-4"
            d="M604.07,281.55a.61.61,0,1,0-.84.13A.59.59,0,0,0,604.07,281.55Z"
          />
          <path
            className="cls-4"
            d="M614.72,285.35a.56.56,0,0,0-.13-.8.58.58,0,0,0-.8.13.58.58,0,0,0,.13.8A.57.57,0,0,0,614.72,285.35Z"
          />
          <path
            className="cls-4"
            d="M624.39,289.83a.6.6,0,1,0,.7-1,.59.59,0,0,0-.84.13A.61.61,0,0,0,624.39,289.83Z"
          />
          <path
            className="cls-4"
            d="M634.51,293.8a.6.6,0,1,0,1,.7.59.59,0,0,0-.14-.84A.6.6,0,0,0,634.51,293.8Z"
          />
          <path
            className="cls-4"
            d="M645.52,299.79a.61.61,0,0,0-.14-.84.59.59,0,0,0-.84.14.6.6,0,1,0,1,.7Z"
          />
          <path
            className="cls-4"
            d="M654.31,304.84a.59.59,0,0,0,.13.84.6.6,0,1,0,.71-1A.59.59,0,0,0,654.31,304.84Z"
          />
          <path
            className="cls-4"
            d="M663.93,311.9a.6.6,0,1,0,.7-1,.6.6,0,0,0-.84.14A.61.61,0,0,0,663.93,311.9Z"
          />
          <path
            className="cls-4"
            d="M674,318.41a.6.6,0,1,0-.84.14A.61.61,0,0,0,674,318.41Z"
          />
          <path
            className="cls-4"
            d="M681.82,324.79a.6.6,0,1,0,1,.7.59.59,0,0,0-.13-.84A.62.62,0,0,0,681.82,324.79Z"
          />
          <path
            className="cls-4"
            d="M691.18,332.14a.61.61,0,0,0-.84.14.6.6,0,0,0,.13.84.61.61,0,0,0,.84-.14A.59.59,0,0,0,691.18,332.14Z"
          />
          <path
            className="cls-4"
            d="M699.32,340a.6.6,0,0,0-.7,1,.6.6,0,0,0,.7-1Z"
          />
          <path
            className="cls-4"
            d="M707.09,348.28a.61.61,0,0,0-.84.14.6.6,0,1,0,1,.7A.61.61,0,0,0,707.09,348.28Z"
          />
          <path
            className="cls-4"
            d="M713.64,357.05a.57.57,0,0,0,.13.8.56.56,0,0,0,.8-.13.58.58,0,0,0-.13-.8A.58.58,0,0,0,713.64,357.05Z"
          />
          <path
            className="cls-4"
            d="M721.55,366.7a.62.62,0,0,0-.14-.85.61.61,0,0,0-.84.14.61.61,0,0,0,1,.71Z"
          />
          <path
            className="cls-4"
            d="M727.09,375.27a.6.6,0,1,0,.84-.14A.6.6,0,0,0,727.09,375.27Z"
          />
          <path
            className="cls-4"
            d="M733.19,384.85a.57.57,0,0,0,.13.8.56.56,0,0,0,.8-.13.58.58,0,0,0-.13-.8A.58.58,0,0,0,733.19,384.85Z"
          />
          <path
            className="cls-4"
            d="M739.76,395.39a.61.61,0,0,0-1-.71.61.61,0,0,0,.14.84A.59.59,0,0,0,739.76,395.39Z"
          />
          <path
            className="cls-4"
            d="M744.07,405.63a.6.6,0,1,0,.7-1,.6.6,0,0,0-.84.14A.61.61,0,0,0,744.07,405.63Z"
          />
          <path
            className="cls-4"
            d="M748.73,416a.61.61,0,0,0,.84-.14.6.6,0,0,0-.14-.84.6.6,0,0,0-.7,1Z"
          />
          <path
            className="cls-4"
            d="M752.76,425.66a.61.61,0,0,0,.14.84.6.6,0,0,0,.7-1A.61.61,0,0,0,752.76,425.66Z"
          />
          <path
            className="cls-4"
            d="M756.43,436.39a.6.6,0,1,0,.84-.14A.61.61,0,0,0,756.43,436.39Z"
          />
          <path
            className="cls-4"
            d="M760.43,447.14a.6.6,0,0,0-.7,1,.6.6,0,1,0,.7-1Z"
          />
          <path
            className="cls-4"
            d="M763.07,458.16a.61.61,0,0,0-.84.14.61.61,0,0,0,1,.71A.62.62,0,0,0,763.07,458.16Z"
          />
          <path
            className="cls-4"
            d="M765.18,469.3a.6.6,0,1,0,.14.84A.6.6,0,0,0,765.18,469.3Z"
          />
          <path
            className="cls-4"
            d="M765.93,480.67a.6.6,0,1,0,.84-.14A.6.6,0,0,0,765.93,480.67Z"
          />
          <path
            className="cls-4"
            d="M767,492a.6.6,0,1,0,.84-.13A.59.59,0,0,0,767,492Z"
          />
          <path
            className="cls-4"
            d="M768.35,503.14a.61.61,0,0,0-.84.14.6.6,0,0,0,.14.84.6.6,0,0,0,.7-1Z"
          />
          <path
            className="cls-4"
            d="M768.33,514.48a.6.6,0,0,0-.84.13.62.62,0,0,0,.14.85.61.61,0,0,0,.84-.14A.6.6,0,0,0,768.33,514.48Z"
          />
          <path
            className="cls-4"
            d="M766.94,525.94a.6.6,0,1,0,1,.7.59.59,0,0,0-.14-.84A.61.61,0,0,0,766.94,525.94Z"
          />
          <path
            className="cls-4"
            d="M765.86,537.22a.61.61,0,0,0,.14.84.59.59,0,0,0,.84-.13.61.61,0,0,0-.14-.84A.6.6,0,0,0,765.86,537.22Z"
          />
          <path
            className="cls-4"
            d="M764.24,548.44a.62.62,0,0,0,.14.85.61.61,0,0,0,.84-.14.59.59,0,0,0-.13-.84A.61.61,0,0,0,764.24,548.44Z"
          />
          <path
            className="cls-4"
            d="M762.13,559.6a.55.55,0,0,0,.13.79.57.57,0,0,0,.8-.13.59.59,0,0,0-.13-.8A.58.58,0,0,0,762.13,559.6Z"
          />
          <path
            className="cls-4"
            d="M759.43,570.6a.6.6,0,1,0,1,.7.59.59,0,0,0-.13-.84A.61.61,0,0,0,759.43,570.6Z"
          />
          <path
            className="cls-4"
            d="M756.25,581.48a.6.6,0,1,0,.84-.14A.6.6,0,0,0,756.25,581.48Z"
          />
          <path
            className="cls-4"
            d="M753.38,592.08a.57.57,0,1,0-.67.93.57.57,0,0,0,.67-.93Z"
          />
          <path
            className="cls-4"
            d="M748.36,602.73a.6.6,0,1,0,.84-.14A.6.6,0,0,0,748.36,602.73Z"
          />
          <path
            className="cls-4"
            d="M743.67,613.05a.6.6,0,1,0,.84-.14A.61.61,0,0,0,743.67,613.05Z"
          />
          <path
            className="cls-4"
            d="M738.5,623.14a.6.6,0,1,0,.84-.14A.6.6,0,0,0,738.5,623.14Z"
          />
          <path
            className="cls-4"
            d="M732.86,633a.59.59,0,0,0,.13.84.61.61,0,1,0-.13-.84Z"
          />
          <path
            className="cls-4"
            d="M726.76,642.53a.59.59,0,0,0,.14.84.6.6,0,0,0,.7-1A.6.6,0,0,0,726.76,642.53Z"
          />
          <path
            className="cls-4"
            d="M720.21,651.78a.61.61,0,0,0,.14.84.6.6,0,1,0-.14-.84Z"
          />
          <path
            className="cls-4"
            d="M713.24,660.72a.6.6,0,1,0,1,.7.6.6,0,0,0-1-.7Z"
          />
          <path
            className="cls-4"
            d="M705.85,669.32a.6.6,0,1,0,.84-.14A.61.61,0,0,0,705.85,669.32Z"
          />
          <path
            className="cls-4"
            d="M698.06,677.56a.6.6,0,1,0,1,.7.6.6,0,0,0-.14-.84A.61.61,0,0,0,698.06,677.56Z"
          />
          <path
            className="cls-4"
            d="M689.9,685.42a.6.6,0,0,0,1,.71.6.6,0,1,0-1-.71Z"
          />
          <circle className="cls-4" cx="681.86" cy="693.24" r="0.57" />
          <path
            className="cls-4"
            d="M673.34,699.81a.61.61,0,0,0-.84.14.59.59,0,0,0,.13.84.61.61,0,0,0,.84-.14A.59.59,0,0,0,673.34,699.81Z"
          />
          <path
            className="cls-4"
            d="M663.3,706.58a.6.6,0,0,0,.14.84.6.6,0,1,0-.14-.84Z"
          />
          <circle className="cls-4" cx="654.29" cy="713.12" r="0.57" />
          <path
            className="cls-4"
            d="M644.86,718.36a.6.6,0,1,0-.7,1,.6.6,0,0,0,.84-.14A.61.61,0,0,0,644.86,718.36Z"
          />
          <path
            className="cls-4"
            d="M639.63,735.12a.54.54,0,1,0,.88.64.54.54,0,0,0-.12-.76A.55.55,0,0,0,639.63,735.12Z"
          />
          <path
            className="cls-4"
            d="M630.13,739.77a.57.57,0,1,0-.67.93.57.57,0,0,0,.67-.93Z"
          />
          <path
            className="cls-4"
            d="M618.88,744.25a.54.54,0,1,0,.88.64.54.54,0,0,0-.88-.64Z"
          />
          <path
            className="cls-4"
            d="M608.2,748.09a.57.57,0,1,0,.93.67.57.57,0,0,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M598.15,751.36a.54.54,0,1,0,.13.76A.55.55,0,0,0,598.15,751.36Z"
          />
          <path
            className="cls-4"
            d="M586.41,754.35a.57.57,0,1,0,.93.67.57.57,0,0,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M575.35,756.76a.54.54,0,1,0,.76-.12A.55.55,0,0,0,575.35,756.76Z"
          />
          <circle className="cls-4" cx="564.61" cy="758.97" r="0.57" />
          <path
            className="cls-4"
            d="M552.92,760a.54.54,0,1,0,.76-.12A.55.55,0,0,0,552.92,760Z"
          />
          <path
            className="cls-4"
            d="M542.38,760.8a.54.54,0,0,0-.76.13.53.53,0,0,0,.12.75.54.54,0,0,0,.76-.12A.55.55,0,0,0,542.38,760.8Z"
          />
          <path
            className="cls-4"
            d="M531.06,761.15a.57.57,0,1,0-.67.93.57.57,0,0,0,.8-.13A.56.56,0,0,0,531.06,761.15Z"
          />
          <circle className="cls-4" cx="519.39" cy="761.48" r="0.57" />
          <path
            className="cls-4"
            d="M507.63,760.52a.55.55,0,0,0,.13.76.54.54,0,0,0,.63-.88A.54.54,0,0,0,507.63,760.52Z"
          />
          <circle className="cls-4" cx="496.8" cy="759.69" r="0.57" />
          <path
            className="cls-4"
            d="M485.14,757.72a.53.53,0,0,0,.12.75.54.54,0,0,0,.76-.12.55.55,0,0,0-.12-.76A.54.54,0,0,0,485.14,757.72Z"
          />
          <path
            className="cls-4"
            d="M474.78,755.41a.59.59,0,0,0-.8.13.58.58,0,0,0,.14.8.55.55,0,0,0,.79-.13A.57.57,0,0,0,474.78,755.41Z"
          />
          <circle className="cls-4" cx="463.43" cy="753.22" r="0.57" />
          <path
            className="cls-4"
            d="M452.09,749.76a.56.56,0,0,0,.13.76.55.55,0,0,0,.76-.13.53.53,0,0,0-.13-.75A.54.54,0,0,0,452.09,749.76Z"
          />
          <path
            className="cls-4"
            d="M442.11,746a.55.55,0,1,0,.13.76A.54.54,0,0,0,442.11,746Z"
          />
          <path
            className="cls-4"
            d="M430.79,742a.54.54,0,1,0,.76-.12A.54.54,0,0,0,430.79,742Z"
          />
          <path
            className="cls-4"
            d="M420.54,738.2a.54.54,0,1,0-.12-.76A.55.55,0,0,0,420.54,738.2Z"
          />
          <path
            className="cls-4"
            d="M411,732.28a.54.54,0,1,0-.63.88.54.54,0,0,0,.63-.88Z"
          />
          <path
            className="cls-4"
            d="M401.1,726.79a.54.54,0,0,0-.76.12.56.56,0,0,0,.13.76.54.54,0,0,0,.63-.88Z"
          />
          <path
            className="cls-4"
            d="M390.81,721.74a.54.54,0,0,0,.63-.88.54.54,0,1,0-.63.88Z"
          />
          <path
            className="cls-4"
            d="M382.18,715.26a.55.55,0,0,0-.13-.76.54.54,0,0,0-.76.12.55.55,0,0,0,.89.64Z"
          />
          <path
            className="cls-4"
            d="M372.2,707.85a.55.55,0,0,0,.13.76.54.54,0,0,0,.63-.88A.54.54,0,0,0,372.2,707.85Z"
          />
          <path
            className="cls-4"
            d="M363.55,701.44a.55.55,0,1,0-.13-.76A.54.54,0,0,0,363.55,701.44Z"
          />
          <path
            className="cls-4"
            d="M355.73,693a.54.54,0,1,0,.13.76A.55.55,0,0,0,355.73,693Z"
          />
          <path
            className="cls-4"
            d="M347.75,685.82a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M339.89,676.78a.53.53,0,0,0-.75.12.54.54,0,1,0,.88.63A.53.53,0,0,0,339.89,676.78Z"
          />
          <path
            className="cls-4"
            d="M332.66,668.91a.54.54,0,0,0-.12-.76.54.54,0,0,0-.64.88A.54.54,0,0,0,332.66,668.91Z"
          />
          <path
            className="cls-4"
            d="M325.56,659.23a.51.51,0,1,0,.11.71A.5.5,0,0,0,325.56,659.23Z"
          />
          <path
            className="cls-4"
            d="M319.12,650.69a.51.51,0,0,0-.83-.6.51.51,0,1,0,.83.6Z"
          />
          <path
            className="cls-4"
            d="M312.13,640.54a.55.55,0,0,0,.12.76.54.54,0,0,0,.76-.13.53.53,0,0,0-.12-.75A.54.54,0,0,0,312.13,640.54Z"
          />
          <path
            className="cls-4"
            d="M307.19,630.62a.54.54,0,1,0-.63.88.54.54,0,0,0,.63-.88Z"
          />
          <path
            className="cls-4"
            d="M301.3,621.45a.54.54,0,0,0,.76-.12.54.54,0,0,0-.88-.64A.54.54,0,0,0,301.3,621.45Z"
          />
          <path
            className="cls-4"
            d="M297.26,611.05a.53.53,0,0,0-.12-.75.54.54,0,0,0-.76.12.55.55,0,0,0,.12.76A.54.54,0,0,0,297.26,611.05Z"
          />
          <path
            className="cls-4"
            d="M292.19,600.68a.51.51,0,0,0,.71-.12.5.5,0,0,0-.11-.71.51.51,0,0,0-.72.11A.52.52,0,0,0,292.19,600.68Z"
          />
          <path
            className="cls-4"
            d="M288.32,590a.53.53,0,0,0,.75-.12.54.54,0,1,0-.88-.63A.53.53,0,0,0,288.32,590Z"
          />
          <path
            className="cls-4"
            d="M284.79,578.44a.58.58,0,0,0,.13.8.57.57,0,1,0,.67-.93A.58.58,0,0,0,284.79,578.44Z"
          />
          <path
            className="cls-4"
            d="M281.93,567.5a.54.54,0,1,0,.76-.13A.55.55,0,0,0,281.93,567.5Z"
          />
          <path
            className="cls-4"
            d="M280.4,557a.52.52,0,0,0-.84-.6.52.52,0,1,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M278.5,545.85a.5.5,0,0,0-.11-.71.51.51,0,1,0-.6.83A.5.5,0,0,0,278.5,545.85Z"
          />
          <path
            className="cls-4"
            d="M277.14,534.62a.55.55,0,0,0-.12-.76.54.54,0,0,0-.76.13.53.53,0,0,0,.12.75A.54.54,0,0,0,277.14,534.62Z"
          />
          <path
            className="cls-4"
            d="M275.48,523.47a.58.58,0,0,0,.8-.14.55.55,0,0,0-.13-.79.57.57,0,0,0-.8.13A.59.59,0,0,0,275.48,523.47Z"
          />
          <path
            className="cls-4"
            d="M275.13,512.11a.54.54,0,1,0-.13-.76A.54.54,0,0,0,275.13,512.11Z"
          />
          <path
            className="cls-4"
            d="M276,500.65a.55.55,0,0,0-.13-.76.54.54,0,0,0-.63.88A.54.54,0,0,0,276,500.65Z"
          />
          <path
            className="cls-4"
            d="M275.78,488.7a.54.54,0,1,0,.76-.13A.55.55,0,0,0,275.78,488.7Z"
          />
          <path
            className="cls-4"
            d="M276.93,477.42a.55.55,0,0,0,.12.76.54.54,0,0,0,.76-.13.53.53,0,0,0-.12-.75A.54.54,0,0,0,276.93,477.42Z"
          />
          <path
            className="cls-4"
            d="M279.47,466.84a.54.54,0,1,0-.76.12A.54.54,0,0,0,279.47,466.84Z"
          />
          <path
            className="cls-4"
            d="M281.62,455.71a.54.54,0,0,0-.12-.76.55.55,0,0,0-.76.12.54.54,0,0,0,.13.76A.53.53,0,0,0,281.62,455.71Z"
          />
          <circle className="cls-4" cx="283.84" cy="444.37" r="0.57" />
          <path
            className="cls-4"
            d="M287.4,433.78a.52.52,0,0,0-.12-.72.51.51,0,0,0-.71.12.5.5,0,0,0,.11.71A.51.51,0,0,0,287.4,433.78Z"
          />
          <path
            className="cls-4"
            d="M291.05,423.05a.53.53,0,0,0-.12-.75.54.54,0,1,0-.63.88A.53.53,0,0,0,291.05,423.05Z"
          />
          <path
            className="cls-4"
            d="M295.16,412.49a.54.54,0,0,0-.12-.76.55.55,0,0,0-.76.12.54.54,0,0,0,.13.76A.53.53,0,0,0,295.16,412.49Z"
          />
          <path
            className="cls-4"
            d="M299.74,402.12a.54.54,0,1,0-.75.12A.54.54,0,0,0,299.74,402.12Z"
          />
          <path
            className="cls-4"
            d="M304.78,392a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M310.27,382a.53.53,0,0,0-.12-.75.54.54,0,1,0-.63.88A.53.53,0,0,0,310.27,382Z"
          />
          <path
            className="cls-4"
            d="M316.2,372.38a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <circle
            className="cls-4"
            cx="322.12"
            cy="362.68"
            r="0.54"
            transform="translate(-160.36 412.79) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M329.33,353.9a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M336.5,345.12a.54.54,0,0,0-.12-.76.56.56,0,0,0-.76.13.54.54,0,0,0,.13.75A.53.53,0,0,0,336.5,345.12Z"
          />
          <path
            className="cls-4"
            d="M344.06,336.67a.53.53,0,0,0-.12-.75.54.54,0,1,0-.63.88A.53.53,0,0,0,344.06,336.67Z"
          />
          <path
            className="cls-4"
            d="M352,328.57a.54.54,0,0,0-.88-.63.54.54,0,0,0,.12.76A.56.56,0,0,0,352,328.57Z"
          />
          <path
            className="cls-4"
            d="M360.28,320.84a.54.54,0,1,0-.75.12A.54.54,0,0,0,360.28,320.84Z"
          />
          <path
            className="cls-4"
            d="M368.91,313.48a.54.54,0,1,0-.76.12A.55.55,0,0,0,368.91,313.48Z"
          />
          <path
            className="cls-4"
            d="M377.86,306.51a.54.54,0,0,0-.13-.75.53.53,0,0,0-.75.12.54.54,0,1,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M387.11,300a.56.56,0,0,0-.13-.76.55.55,0,0,0-.76.13.55.55,0,0,0,.89.63Z"
          />
          <circle className="cls-4" cx="396.2" cy="293.51" r="0.57" />
          <path
            className="cls-4"
            d="M406.44,288.13a.54.54,0,1,0-.76.12A.54.54,0,0,0,406.44,288.13Z"
          />
          <path
            className="cls-4"
            d="M416.51,282.89a.57.57,0,0,0-.13-.8.58.58,0,0,0-.8.14.57.57,0,0,0,.13.79A.56.56,0,0,0,416.51,282.89Z"
          />
          <path
            className="cls-4"
            d="M426.78,278.1a.57.57,0,0,0-.93-.67.57.57,0,0,0,.93.67Z"
          />
          <circle
            className="cls-4"
            cx="436.8"
            cy="273.43"
            r="0.54"
            transform="translate(-40.07 468.75) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M447.92,269.91a.57.57,0,0,0-.93-.67.57.57,0,1,0,.93.67Z"
          />
          <path
            className="cls-4"
            d="M458,266.64a.54.54,0,1,0-.12-.76A.54.54,0,0,0,458,266.64Z"
          />
          <path
            className="cls-4"
            d="M469.68,263.63a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M480.79,261.25a.57.57,0,0,0-.93-.66.59.59,0,0,0,.13.8A.58.58,0,0,0,480.79,261.25Z"
          />
          <circle
            className="cls-4"
            cx="491.5"
            cy="259.03"
            r="0.54"
            transform="translate(-5.58 507.19) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M503.22,258a.57.57,0,0,0-.93-.66.57.57,0,0,0,.13.79A.56.56,0,0,0,503.22,258Z"
          />
          <path
            className="cls-4"
            d="M513.74,257.2a.55.55,0,0,0,.76-.13.54.54,0,0,0-.88-.63A.54.54,0,0,0,513.74,257.2Z"
          />
          <path
            className="cls-4"
            d="M525.07,256.82a.54.54,0,1,0,.63-.88.54.54,0,0,0-.63.88Z"
          />
          <path
            className="cls-4"
            d="M537,256.08a.54.54,0,0,0-.76.12.55.55,0,1,0,.76-.12Z"
          />
          <path
            className="cls-4"
            d="M548.51,257.5a.57.57,0,0,0-.13-.8.57.57,0,1,0-.67.93A.58.58,0,0,0,548.51,257.5Z"
          />
          <path
            className="cls-4"
            d="M559.76,258.63a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M571,260.28a.54.54,0,0,0-.88-.63.54.54,0,1,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M582,261.68a.54.54,0,0,0-.63.88.54.54,0,1,0,.63-.88Z"
          />
          <circle className="cls-4" cx="592.69" cy="264.78" r="0.57" />
          <path
            className="cls-4"
            d="M604.05,268.26a.58.58,0,0,0-.13-.8.57.57,0,1,0-.67.93A.58.58,0,0,0,604.05,268.26Z"
          />
          <path
            className="cls-4"
            d="M613.86,271.22a.57.57,0,0,0,.13.8.57.57,0,0,0,.67-.93A.57.57,0,0,0,613.86,271.22Z"
          />
          <path
            className="cls-4"
            d="M624.55,276.13a.58.58,0,0,0,.8-.13.57.57,0,0,0-.93-.67A.58.58,0,0,0,624.55,276.13Z"
          />
          <path
            className="cls-4"
            d="M634.93,280.71a.57.57,0,0,0,.79-.13.57.57,0,0,0-.93-.67A.58.58,0,0,0,634.93,280.71Z"
          />
          <path
            className="cls-4"
            d="M645.88,285.62a.57.57,0,0,0-.13-.8.57.57,0,1,0-.67.93A.58.58,0,0,0,645.88,285.62Z"
          />
          <path
            className="cls-4"
            d="M655.67,290.31a.57.57,0,1,0-.67.93.57.57,0,0,0,.67-.93Z"
          />
          <path
            className="cls-4"
            d="M664.66,297.17a.58.58,0,0,0,.8-.13.57.57,0,0,0-.93-.67A.58.58,0,0,0,664.66,297.17Z"
          />
          <circle className="cls-4" cx="674.38" cy="303.06" r="0.57" />
          <path
            className="cls-4"
            d="M683.94,310.17a.57.57,0,0,0-.13-.8.56.56,0,0,0-.8.13.58.58,0,0,0,.13.8A.58.58,0,0,0,683.94,310.17Z"
          />
          <path
            className="cls-4"
            d="M692.59,316.54a.58.58,0,0,0-.8.13.57.57,0,1,0,.93.67A.58.58,0,0,0,692.59,316.54Z"
          />
          <path
            className="cls-4"
            d="M700.24,324.23a.57.57,0,1,0,.93.67.58.58,0,0,0-.13-.8A.57.57,0,0,0,700.24,324.23Z"
          />
          <path
            className="cls-4"
            d="M708.47,333a.57.57,0,0,0,.8-.13.57.57,0,0,0-.93-.67A.56.56,0,0,0,708.47,333Z"
          />
          <path
            className="cls-4"
            d="M716.08,340.45a.57.57,0,0,0,.13.8.59.59,0,0,0,.8-.13.58.58,0,0,0-.14-.8A.55.55,0,0,0,716.08,340.45Z"
          />
          <path
            className="cls-4"
            d="M723.56,349.87a.57.57,0,1,0,.67-.93.57.57,0,0,0-.67.93Z"
          />
          <circle className="cls-4" cx="730.86" cy="358.36" r="0.57" />
          <path
            className="cls-4"
            d="M737,367.27a.57.57,0,0,0,.13.8.57.57,0,0,0,.8-.13.57.57,0,1,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M743.11,376.82a.54.54,0,1,0,.75-.12A.54.54,0,0,0,743.11,376.82Z"
          />
          <path
            className="cls-4"
            d="M748.78,386.61a.58.58,0,0,0,.13.8.58.58,0,0,0,.8-.13.57.57,0,1,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M754.83,396.52a.59.59,0,0,0-.8.13.58.58,0,0,0,.14.8.55.55,0,0,0,.79-.13A.57.57,0,0,0,754.83,396.52Z"
          />
          <path
            className="cls-4"
            d="M759.79,407.61a.6.6,0,1,0-1-.7.6.6,0,0,0,.14.84A.61.61,0,0,0,759.79,407.61Z"
          />
          <circle className="cls-4" cx="763.63" cy="417.74" r="0.57" />
          <circle className="cls-4" cx="767.49" cy="428.4" r="0.57" />
          <path
            className="cls-4"
            d="M771.18,438.78a.54.54,0,0,0-.64.88.54.54,0,1,0,.64-.88Z"
          />
          <path
            className="cls-4"
            d="M773.39,450.67a.61.61,0,0,0,.85-.13.62.62,0,0,0-.14-.85.61.61,0,0,0-.84.14A.59.59,0,0,0,773.39,450.67Z"
          />
          <path
            className="cls-4"
            d="M775.67,460.93a.58.58,0,0,0,.13.8.57.57,0,1,0,.67-.93A.58.58,0,0,0,775.67,460.93Z"
          />
          <path
            className="cls-4"
            d="M777.7,472.91a.57.57,0,0,0,.79-.13.56.56,0,0,0-.13-.8.57.57,0,1,0-.66.93Z"
          />
          <path
            className="cls-4"
            d="M778.93,483.34a.61.61,0,1,0,.84-.13A.59.59,0,0,0,778.93,483.34Z"
          />
          <path
            className="cls-4"
            d="M779.86,494.68a.54.54,0,0,0,.12.76.55.55,0,0,0,.76-.12.54.54,0,0,0-.88-.64Z"
          />
          <path
            className="cls-4"
            d="M780.21,506a.57.57,0,1,0,.93.66.59.59,0,0,0-.13-.8A.58.58,0,0,0,780.21,506Z"
          />
          <path
            className="cls-4"
            d="M780.87,517.2a.57.57,0,0,0-.79.13.57.57,0,0,0,.93.67A.58.58,0,0,0,780.87,517.2Z"
          />
          <circle className="cls-4" cx="779.9" cy="528.99" r="0.57" />
          <circle className="cls-4" cx="778.75" cy="540.26" r="0.57" />
          <path
            className="cls-4"
            d="M776.63,551.15a.55.55,0,0,0,.13.79.57.57,0,0,0,.8-.13.59.59,0,0,0-.13-.8A.58.58,0,0,0,776.63,551.15Z"
          />
          <path
            className="cls-4"
            d="M774.49,562.29a.55.55,0,1,0,.76-.12A.54.54,0,0,0,774.49,562.29Z"
          />
          <circle className="cls-4" cx="772.28" cy="573.63" r="0.57" />
          <path
            className="cls-4"
            d="M768.67,584.19a.58.58,0,0,0,.13.8.58.58,0,0,0,.8-.13.57.57,0,0,0-.13-.8A.57.57,0,0,0,768.67,584.19Z"
          />
          <path
            className="cls-4"
            d="M765,594.93a.58.58,0,0,0,.13.8.57.57,0,0,0,.67-.93A.56.56,0,0,0,765,594.93Z"
          />
          <path
            className="cls-4"
            d="M760.93,605.5a.57.57,0,0,0,.93.66.57.57,0,1,0-.93-.66Z"
          />
          <path
            className="cls-4"
            d="M756.35,615.87a.59.59,0,0,0,.13.8.58.58,0,0,0,.8-.14.55.55,0,0,0-.13-.79A.57.57,0,0,0,756.35,615.87Z"
          />
          <path
            className="cls-4"
            d="M751.31,626a.57.57,0,1,0,.93.67.56.56,0,0,0-.13-.8A.58.58,0,0,0,751.31,626Z"
          />
          <path
            className="cls-4"
            d="M745.82,635.94a.58.58,0,0,0,.13.8.58.58,0,0,0,.8-.13.57.57,0,0,0-.13-.8A.57.57,0,0,0,745.82,635.94Z"
          />
          <path
            className="cls-4"
            d="M739.89,645.6a.58.58,0,0,0,.13.8.57.57,0,0,0,.67-.93A.58.58,0,0,0,739.89,645.6Z"
          />
          <path
            className="cls-4"
            d="M733.53,655a.57.57,0,0,0,.93.67.57.57,0,0,0-.93-.67Z"
          />
          <circle className="cls-4" cx="727.23" cy="664.42" r="0.57" />
          <path
            className="cls-4"
            d="M719.59,672.86a.56.56,0,0,0,.13.8.58.58,0,0,0,.8-.13.58.58,0,0,0-.13-.8A.57.57,0,0,0,719.59,672.86Z"
          />
          <path
            className="cls-4"
            d="M712,681.31a.57.57,0,0,0,.13.8.57.57,0,0,0,.67-.93A.56.56,0,0,0,712,681.31Z"
          />
          <path
            className="cls-4"
            d="M704.1,689.41a.57.57,0,0,0,.13.8.57.57,0,0,0,.67-.93A.56.56,0,0,0,704.1,689.41Z"
          />
          <path
            className="cls-4"
            d="M695.81,697.15a.57.57,0,1,0,.93.66.57.57,0,0,0-.93-.66Z"
          />
          <path
            className="cls-4"
            d="M687.18,704.5a.58.58,0,1,0,.8-.13A.58.58,0,0,0,687.18,704.5Z"
          />
          <circle className="cls-4" cx="678.7" cy="711.8" r="0.57" />
          <path
            className="cls-4"
            d="M669,718a.56.56,0,0,0,.13.8.58.58,0,0,0,.8-.13.58.58,0,0,0-.13-.8A.57.57,0,0,0,669,718Z"
          />
          <path
            className="cls-4"
            d="M659.48,724.17a.54.54,0,1,0,.88.63.54.54,0,0,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M650.45,729.72a.57.57,0,1,0-.67.93.57.57,0,0,0,.67-.93Z"
          />
          <path
            className="cls-4"
            d="M645.25,746.46a.52.52,0,0,0,.84.6.52.52,0,0,0-.84-.6Z"
          />
          <path
            className="cls-4"
            d="M635.72,751.13a.54.54,0,1,0-.63.88.53.53,0,0,0,.75-.12A.54.54,0,0,0,635.72,751.13Z"
          />
          <path
            className="cls-4"
            d="M624.53,755.64a.5.5,0,0,0,.11.71.51.51,0,1,0,.6-.83A.5.5,0,0,0,624.53,755.64Z"
          />
          <path
            className="cls-4"
            d="M613.86,759.53a.54.54,0,1,0,.88.64.54.54,0,0,0-.12-.76A.55.55,0,0,0,613.86,759.53Z"
          />
          <path
            className="cls-4"
            d="M603.8,762.89a.51.51,0,0,0-.71.12.51.51,0,0,0,.83.59A.51.51,0,0,0,603.8,762.89Z"
          />
          <path
            className="cls-4"
            d="M592.13,766a.54.54,0,1,0,.88.63.54.54,0,0,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M581.1,768.52a.51.51,0,1,0,.71-.12A.51.51,0,0,0,581.1,768.52Z"
          />
          <path
            className="cls-4"
            d="M570,770.57a.51.51,0,1,0,.71-.12A.51.51,0,0,0,570,770.57Z"
          />
          <path
            className="cls-4"
            d="M559.45,772a.54.54,0,0,0-.63.88.54.54,0,0,0,.76-.12A.56.56,0,0,0,559.45,772Z"
          />
          <path
            className="cls-4"
            d="M547.43,773.22a.52.52,0,1,0,.84.6.52.52,0,0,0-.84-.6Z"
          />
          <path
            className="cls-4"
            d="M537,774.44a.54.54,0,0,0-.12-.76.55.55,0,0,0-.76.13.54.54,0,1,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M525.61,774.54a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,525.61,774.54Z"
          />
          <path
            className="cls-4"
            d="M513.45,773.58a.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12.51.51,0,0,0-.12-.71A.5.5,0,0,0,513.45,773.58Z"
          />
          <path
            className="cls-4"
            d="M502.86,772.62a.51.51,0,0,0-.72.11.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12A.5.5,0,0,0,502.86,772.62Z"
          />
          <path
            className="cls-4"
            d="M490.86,771.39a.53.53,0,0,0,.12.75.54.54,0,1,0,.63-.88A.53.53,0,0,0,490.86,771.39Z"
          />
          <path
            className="cls-4"
            d="M479.69,769.59a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,479.69,769.59Z"
          />
          <path
            className="cls-4"
            d="M469.3,767.19a.51.51,0,0,0-.6.83.52.52,0,0,0,.72-.12A.51.51,0,0,0,469.3,767.19Z"
          />
          <path
            className="cls-4"
            d="M457.59,764.55a.51.51,0,1,0,.72-.12A.51.51,0,0,0,457.59,764.55Z"
          />
          <path
            className="cls-4"
            d="M446.72,761.32a.51.51,0,0,0,.12.71.5.5,0,0,0,.71-.11.51.51,0,0,0-.11-.72A.52.52,0,0,0,446.72,761.32Z"
          />
          <path
            className="cls-4"
            d="M436.12,758.34a.5.5,0,0,0,.71-.11.51.51,0,0,0-.11-.72.52.52,0,0,0-.72.12A.51.51,0,0,0,436.12,758.34Z"
          />
          <path
            className="cls-4"
            d="M426.16,753.37a.51.51,0,1,0,.12.71A.5.5,0,0,0,426.16,753.37Z"
          />
          <path
            className="cls-4"
            d="M415.18,749.64a.55.55,0,0,0,.76-.13.55.55,0,0,0-.13-.76.53.53,0,0,0-.75.13A.54.54,0,0,0,415.18,749.64Z"
          />
          <path
            className="cls-4"
            d="M404.92,743.87a.51.51,0,0,0,.12.71.51.51,0,0,0,.6-.83A.52.52,0,0,0,404.92,743.87Z"
          />
          <path
            className="cls-4"
            d="M395,738.41a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,395,738.41Z"
          />
          <path
            className="cls-4"
            d="M385.29,732.53a.51.51,0,0,0,.12.71.5.5,0,0,0,.71-.11.51.51,0,0,0-.11-.72A.52.52,0,0,0,385.29,732.53Z"
          />
          <path
            className="cls-4"
            d="M376.69,726.84a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72A.52.52,0,0,0,376.69,726.84Z"
          />
          <path
            className="cls-4"
            d="M367.53,720.16a.51.51,0,1,0-.83-.6.51.51,0,0,0,.83.6Z"
          />
          <path
            className="cls-4"
            d="M358,713.2a.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72.51.51,0,0,0-.71.12A.5.5,0,0,0,358,713.2Z"
          />
          <path
            className="cls-4"
            d="M350.12,705.64a.51.51,0,0,0-.83-.59.5.5,0,0,0,.11.71A.51.51,0,0,0,350.12,705.64Z"
          />
          <path
            className="cls-4"
            d="M341.79,697.1a.56.56,0,0,0-.76.13.54.54,0,1,0,.76-.13Z"
          />
          <path
            className="cls-4"
            d="M333.17,689.1a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,333.17,689.1Z"
          />
          <path
            className="cls-4"
            d="M326.36,680.51a.51.51,0,1,0-.6.83.5.5,0,0,0,.71-.12A.51.51,0,0,0,326.36,680.51Z"
          />
          <path
            className="cls-4"
            d="M318.48,671.84a.51.51,0,0,0,.83.6.51.51,0,1,0-.83-.6Z"
          />
          <path
            className="cls-4"
            d="M312.51,663.33a.49.49,0,0,0-.11-.67.48.48,0,1,0,.11.67Z"
          />
          <path
            className="cls-4"
            d="M306,653.27a.51.51,0,0,0-.72.11.52.52,0,1,0,.84.6A.51.51,0,0,0,306,653.27Z"
          />
          <path
            className="cls-4"
            d="M300.05,643.64a.51.51,0,0,0-.72.11.52.52,0,1,0,.84.6A.51.51,0,0,0,300.05,643.64Z"
          />
          <path
            className="cls-4"
            d="M293.91,634.56a.47.47,0,0,0,.67-.11.48.48,0,0,0-.78-.56A.47.47,0,0,0,293.91,634.56Z"
          />
          <path
            className="cls-4"
            d="M289.47,624.36a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,289.47,624.36Z"
          />
          <path
            className="cls-4"
            d="M284.75,614a.48.48,0,1,0-.68.11A.5.5,0,0,0,284.75,614Z"
          />
          <path
            className="cls-4"
            d="M280.52,603.54a.52.52,0,0,0-.12-.72.51.51,0,0,0-.71.12.5.5,0,0,0,.11.71A.51.51,0,0,0,280.52,603.54Z"
          />
          <path
            className="cls-4"
            d="M276,593a.51.51,0,1,0,.6-.83.52.52,0,0,0-.72.12A.51.51,0,0,0,276,593Z"
          />
          <path
            className="cls-4"
            d="M273.38,582a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72A.52.52,0,0,0,273.38,582Z"
          />
          <path
            className="cls-4"
            d="M270.39,570.34a.51.51,0,1,0-.6.83.51.51,0,0,0,.6-.83Z"
          />
          <path
            className="cls-4"
            d="M268.1,560a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,268.1,560Z"
          />
          <path
            className="cls-4"
            d="M266.17,548.8a.51.51,0,1,0-.71.12A.5.5,0,0,0,266.17,548.8Z"
          />
          <path
            className="cls-4"
            d="M264.72,537.56a.51.51,0,0,0-.11-.72.52.52,0,1,0-.6.84A.51.51,0,0,0,264.72,537.56Z"
          />
          <path
            className="cls-4"
            d="M263,526.38a.51.51,0,1,0,.6-.83.51.51,0,0,0-.6.83Z"
          />
          <path
            className="cls-4"
            d="M262.58,515a.48.48,0,1,0,.56-.78.48.48,0,0,0-.56.78Z"
          />
          <path
            className="cls-4"
            d="M262.47,503a.48.48,0,1,0,.67-.11A.47.47,0,0,0,262.47,503Z"
          />
          <path
            className="cls-4"
            d="M263.74,492.25a.49.49,0,0,0-.11-.67.5.5,0,0,0-.68.11.48.48,0,0,0,.79.56Z"
          />
          <path
            className="cls-4"
            d="M263.9,480.38a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,263.9,480.38Z"
          />
          <path
            className="cls-4"
            d="M266.21,469.75a.55.55,0,1,0-.76.12A.55.55,0,0,0,266.21,469.75Z"
          />
          <path
            className="cls-4"
            d="M268.09,458.54a.49.49,0,0,0-.11-.67.5.5,0,0,0-.68.11.48.48,0,0,0,.79.56Z"
          />
          <path
            className="cls-4"
            d="M270.52,447.48a.51.51,0,1,0-.72.12A.52.52,0,0,0,270.52,447.48Z"
          />
          <path
            className="cls-4"
            d="M273.37,436.5a.48.48,0,0,0-.11-.67.48.48,0,1,0-.56.78A.48.48,0,0,0,273.37,436.5Z"
          />
          <path
            className="cls-4"
            d="M276.74,425.68a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,276.74,425.68Z"
          />
          <path
            className="cls-4"
            d="M280.54,415a.51.51,0,1,0-.72.12A.52.52,0,0,0,280.54,415Z"
          />
          <path
            className="cls-4"
            d="M284.8,404.5a.52.52,0,1,0-.84-.6.51.51,0,0,0,.12.71A.52.52,0,0,0,284.8,404.5Z"
          />
          <path
            className="cls-4"
            d="M289.52,394.2a.55.55,0,0,0-.12-.76.54.54,0,1,0-.64.88A.55.55,0,0,0,289.52,394.2Z"
          />
          <path
            className="cls-4"
            d="M294.63,384.07a.5.5,0,0,0-.11-.71.51.51,0,0,0-.72.11.52.52,0,0,0,.12.72A.51.51,0,0,0,294.63,384.07Z"
          />
          <path
            className="cls-4"
            d="M300.2,374.19a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,300.2,374.19Z"
          />
          <path
            className="cls-4"
            d="M306.18,364.56a.51.51,0,0,0-.12-.71.51.51,0,0,0-.6.83A.51.51,0,0,0,306.18,364.56Z"
          />
          <path
            className="cls-4"
            d="M312.57,355.2a.52.52,0,0,0-.12-.72.51.51,0,0,0-.71.12.5.5,0,0,0,.11.71A.51.51,0,0,0,312.57,355.2Z"
          />
          <path
            className="cls-4"
            d="M319.35,346.11a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,1,0,.83.6Z"
          />
          <path
            className="cls-4"
            d="M326.51,337.33a.51.51,0,1,0-.83-.6.51.51,0,0,0,.12.71A.5.5,0,0,0,326.51,337.33Z"
          />
          <path
            className="cls-4"
            d="M334.05,328.85a.52.52,0,0,0-.84-.6.52.52,0,0,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M342,320.73a.54.54,0,1,0-.76.12A.54.54,0,0,0,342,320.73Z"
          />
          <path
            className="cls-4"
            d="M350.16,312.91a.51.51,0,0,0-.83-.6.52.52,0,0,0,.12.72A.51.51,0,0,0,350.16,312.91Z"
          />
          <path
            className="cls-4"
            d="M358.72,305.47a.52.52,0,0,0-.84-.6.52.52,0,1,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M367.58,298.4a.51.51,0,1,0-.71.12A.5.5,0,0,0,367.58,298.4Z"
          />
          <path
            className="cls-4"
            d="M376.74,291.72a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,1,0,.83.6Z"
          />
          <path
            className="cls-4"
            d="M386.18,285.44a.52.52,0,0,0-.84-.6.52.52,0,0,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M395.87,279.56a.51.51,0,1,0-.71.12A.51.51,0,0,0,395.87,279.56Z"
          />
          <circle
            className="cls-4"
            cx="405.39"
            cy="273.81"
            r="0.48"
            transform="translate(-53.46 443.4) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M416,269.09a.53.53,0,0,0-.12-.75.54.54,0,0,0-.76.12.55.55,0,0,0,.13.76A.53.53,0,0,0,416,269.09Z"
          />
          <path
            className="cls-4"
            d="M426.34,264.49a.52.52,0,0,0-.12-.72.52.52,0,0,0-.6.84A.52.52,0,0,0,426.34,264.49Z"
          />
          <path
            className="cls-4"
            d="M436.89,260.35a.51.51,0,1,0-.71.12A.51.51,0,0,0,436.89,260.35Z"
          />
          <path
            className="cls-4"
            d="M446.92,256.75a.47.47,0,0,0,.67-.11.48.48,0,0,0-.11-.67.49.49,0,0,0-.67.11A.47.47,0,0,0,446.92,256.75Z"
          />
          <path
            className="cls-4"
            d="M458.51,253.46a.54.54,0,0,0-.13-.76.53.53,0,0,0-.75.12.54.54,0,0,0,.12.76A.55.55,0,0,0,458.51,253.46Z"
          />
          <path
            className="cls-4"
            d="M469.5,250.7a.54.54,0,1,0-.88-.63.54.54,0,0,0,.88.63Z"
          />
          <path
            className="cls-4"
            d="M479.87,248.51a.5.5,0,0,0,.71-.11.51.51,0,0,0-.11-.72.52.52,0,0,0-.72.12A.51.51,0,0,0,479.87,248.51Z"
          />
          <path
            className="cls-4"
            d="M491.8,246.61a.55.55,0,0,0-.12-.76.54.54,0,1,0-.64.88A.55.55,0,0,0,491.8,246.61Z"
          />
          <path
            className="cls-4"
            d="M503,245.26a.52.52,0,1,0-.72.12A.52.52,0,0,0,503,245.26Z"
          />
          <path
            className="cls-4"
            d="M513.51,243.82a.51.51,0,1,0,.71-.12A.5.5,0,0,0,513.51,243.82Z"
          />
          <path
            className="cls-4"
            d="M525.7,244.07a.56.56,0,0,0-.13-.76.54.54,0,1,0,.13.76Z"
          />
          <path
            className="cls-4"
            d="M536.18,243.58a.5.5,0,0,0,.11.71.51.51,0,1,0,.6-.83A.5.5,0,0,0,536.18,243.58Z"
          />
          <path
            className="cls-4"
            d="M548.33,244.78a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72A.52.52,0,0,0,548.33,244.78Z"
          />
          <path
            className="cls-4"
            d="M559.61,245.87a.5.5,0,0,0-.11-.71.51.51,0,0,0-.72.11.52.52,0,0,0,.12.72A.51.51,0,0,0,559.61,245.87Z"
          />
          <path
            className="cls-4"
            d="M570.72,246.73a.51.51,0,1,0-.59.83.51.51,0,0,0,.71-.12A.5.5,0,0,0,570.72,246.73Z"
          />
          <path
            className="cls-4"
            d="M581.89,248.75a.54.54,0,1,0,.13.76A.55.55,0,0,0,581.89,248.75Z"
          />
          <path
            className="cls-4"
            d="M592.19,251.4a.54.54,0,1,0,.88.63.54.54,0,0,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M603.25,255.15A.53.53,0,0,0,604,255a.54.54,0,1,0-.88-.63A.53.53,0,0,0,603.25,255.15Z"
          />
          <path
            className="cls-4"
            d="M613.92,257.85a.54.54,0,0,0,.12.76.54.54,0,0,0,.64-.88A.54.54,0,0,0,613.92,257.85Z"
          />
          <path
            className="cls-4"
            d="M625.41,262.38a.5.5,0,0,0-.11-.71.52.52,0,0,0-.72.12.51.51,0,0,0,.12.71A.5.5,0,0,0,625.41,262.38Z"
          />
          <path
            className="cls-4"
            d="M635.76,266a.51.51,0,1,0-.6.83.52.52,0,0,0,.72-.12A.51.51,0,0,0,635.76,266Z"
          />
          <path
            className="cls-4"
            d="M645.43,271.68a.51.51,0,0,0,.6-.83.51.51,0,1,0-.6.83Z"
          />
          <path
            className="cls-4"
            d="M655.36,276.21a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,655.36,276.21Z"
          />
          <circle
            className="cls-4"
            cx="665.6"
            cy="282.18"
            r="0.54"
            transform="translate(48.17 658.25) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M674.84,288.7a.54.54,0,1,0-.12-.75A.54.54,0,0,0,674.84,288.7Z"
          />
          <path
            className="cls-4"
            d="M684.77,294.31a.54.54,0,0,0-.63.88.54.54,0,1,0,.63-.88Z"
          />
          <path
            className="cls-4"
            d="M693.15,302.07a.54.54,0,1,0-.12-.76A.55.55,0,0,0,693.15,302.07Z"
          />
          <path
            className="cls-4"
            d="M701.74,308.57a.55.55,0,0,0,.12.76.54.54,0,0,0,.76-.13.53.53,0,0,0-.12-.75A.54.54,0,0,0,701.74,308.57Z"
          />
          <path
            className="cls-4"
            d="M711,316.81a.52.52,0,0,0-.12-.72.52.52,0,0,0-.6.84A.52.52,0,0,0,711,316.81Z"
          />
          <path
            className="cls-4"
            d="M718.33,324.9a.51.51,0,1,0,.6-.83.51.51,0,0,0-.6.83Z"
          />
          <path
            className="cls-4"
            d="M725.93,332.5a.5.5,0,0,0,.11.71.51.51,0,1,0-.11-.71Z"
          />
          <path
            className="cls-4"
            d="M734.11,341.73a.52.52,0,1,0-.72.12A.52.52,0,0,0,734.11,341.73Z"
          />
          <path
            className="cls-4"
            d="M740.22,350.05a.54.54,0,0,0,.88.64.54.54,0,1,0-.88-.64Z"
          />
          <path
            className="cls-4"
            d="M747.56,359.16a.53.53,0,0,0-.75.12.54.54,0,0,0,.12.76.55.55,0,0,0,.76-.12A.54.54,0,0,0,747.56,359.16Z"
          />
          <path
            className="cls-4"
            d="M753,368.78a.54.54,0,1,0,.88.64.54.54,0,1,0-.88-.64Z"
          />
          <path
            className="cls-4"
            d="M759.52,378.42a.54.54,0,1,0-.63.88.54.54,0,0,0,.63-.88Z"
          />
          <path
            className="cls-4"
            d="M764.11,388.54a.54.54,0,1,0,.76-.13A.55.55,0,0,0,764.11,388.54Z"
          />
          <path
            className="cls-4"
            d="M769.78,398.63a.53.53,0,0,0-.75.12.54.54,0,0,0,.12.76.55.55,0,0,0,.76-.12A.54.54,0,0,0,769.78,398.63Z"
          />
          <path
            className="cls-4"
            d="M774.39,409.8a.54.54,0,1,0-.76.13A.56.56,0,0,0,774.39,409.8Z"
          />
          <path
            className="cls-4"
            d="M778.27,419.67a.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12A.51.51,0,0,0,778.27,419.67Z"
          />
          <circle
            className="cls-4"
            cx="781.54"
            cy="430.84"
            r="0.54"
            transform="translate(-24.27 814.39) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M784.21,441.43a.54.54,0,1,0,.76-.13A.56.56,0,0,0,784.21,441.43Z"
          />
          <circle
            className="cls-4"
            cx="787.29"
            cy="452.77"
            r="0.54"
            transform="translate(-39.68 828.19) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M789.77,463.46a.53.53,0,0,0-.75.12.54.54,0,1,0,.88.63A.53.53,0,0,0,789.77,463.46Z"
          />
          <path
            className="cls-4"
            d="M790.73,474.81a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,790.73,474.81Z"
          />
          <path
            className="cls-4"
            d="M791.91,486.07a.54.54,0,0,0,.13.75.53.53,0,0,0,.75-.12.54.54,0,1,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M792.64,497.38a.54.54,0,1,0,.88.63.54.54,0,0,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M792.86,508.7a.57.57,0,0,0,.13.8.58.58,0,0,0,.8-.14.57.57,0,0,0-.14-.79A.55.55,0,0,0,792.86,508.7Z"
          />
          <path
            className="cls-4"
            d="M793.37,520a.51.51,0,1,0,.12.71A.5.5,0,0,0,793.37,520Z"
          />
          <path
            className="cls-4"
            d="M792.79,532a.54.54,0,0,0-.13-.76.53.53,0,0,0-.75.12.54.54,0,0,0,.12.76A.55.55,0,0,0,792.79,532Z"
          />
          <path
            className="cls-4"
            d="M790.7,542.64a.53.53,0,0,0,.12.75.54.54,0,1,0,.63-.88A.53.53,0,0,0,790.7,542.64Z"
          />
          <path
            className="cls-4"
            d="M789,553.85a.53.53,0,0,0,.12.75.54.54,0,1,0,.63-.88A.53.53,0,0,0,789,553.85Z"
          />
          <path
            className="cls-4"
            d="M786.86,565a.52.52,0,1,0,.72-.12A.52.52,0,0,0,786.86,565Z"
          />
          <path
            className="cls-4"
            d="M785,575.87a.55.55,0,0,0-.63.89.56.56,0,0,0,.76-.13A.55.55,0,0,0,785,575.87Z"
          />
          <path
            className="cls-4"
            d="M781.09,586.9a.54.54,0,1,0,.88.63.54.54,0,0,0-.88-.63Z"
          />
          <path
            className="cls-4"
            d="M777.51,597.66a.55.55,0,0,0,.13.76.54.54,0,0,0,.63-.88A.54.54,0,0,0,777.51,597.66Z"
          />
          <path
            className="cls-4"
            d="M774.22,608.16a.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12A.51.51,0,0,0,774.22,608.16Z"
          />
          <path
            className="cls-4"
            d="M769,618.69a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,769,618.69Z"
          />
          <path
            className="cls-4"
            d="M764.11,628.9a.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12.51.51,0,0,0-.12-.71A.5.5,0,0,0,764.11,628.9Z"
          />
          <path
            className="cls-4"
            d="M758.75,638.9a.51.51,0,1,0,.72-.12A.51.51,0,0,0,758.75,638.9Z"
          />
          <path
            className="cls-4"
            d="M753,648.63a.54.54,0,1,0,.75-.12A.54.54,0,0,0,753,648.63Z"
          />
          <circle
            className="cls-4"
            cx="747.21"
            cy="658.45"
            r="0.54"
            transform="translate(-223.46 881.35) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M740.18,667.36a.54.54,0,0,0,.13.76.53.53,0,0,0,.75-.12.54.54,0,1,0-.88-.64Z"
          />
          <path
            className="cls-4"
            d="M733.21,676.3a.54.54,0,1,0,.76-.12A.54.54,0,0,0,733.21,676.3Z"
          />
          <path
            className="cls-4"
            d="M725.88,685a.52.52,0,1,0,.72-.12A.52.52,0,0,0,725.88,685Z"
          />
          <path
            className="cls-4"
            d="M718.17,693.26a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,718.17,693.26Z"
          />
          <path
            className="cls-4"
            d="M710.11,701.23a.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12.5.5,0,0,0-.11-.71A.51.51,0,0,0,710.11,701.23Z"
          />
          <path
            className="cls-4"
            d="M701.72,708.85a.51.51,0,0,0,.12.72.5.5,0,0,0,.71-.12.51.51,0,0,0-.83-.6Z"
          />
          <path
            className="cls-4"
            d="M693,716.07a.57.57,0,1,0,.93.67.57.57,0,0,0-.93-.67Z"
          />
          <path
            className="cls-4"
            d="M684,723a.54.54,0,0,0,.12.76.55.55,0,0,0,.76-.13A.54.54,0,0,0,684,723Z"
          />
          <path
            className="cls-4"
            d="M674.67,729.45a.55.55,0,0,0,.12.76.54.54,0,0,0,.76-.12.55.55,0,0,0-.12-.76A.54.54,0,0,0,674.67,729.45Z"
          />
          <path
            className="cls-4"
            d="M665.13,735.55a.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12.51.51,0,0,0-.12-.71A.5.5,0,0,0,665.13,735.55Z"
          />
          <path
            className="cls-4"
            d="M656,741.1a.52.52,0,0,0-.6.84.52.52,0,1,0,.6-.84Z"
          />
          <path
            className="cls-4"
            d="M650.88,757.8a.48.48,0,1,0,.67-.11A.49.49,0,0,0,650.88,757.8Z"
          />
          <path
            className="cls-4"
            d="M641.29,762.51a.49.49,0,0,0-.67.11.48.48,0,1,0,.67-.11Z"
          />
          <path
            className="cls-4"
            d="M630.17,767a.48.48,0,1,0,.67-.11A.47.47,0,0,0,630.17,767Z"
          />
          <path
            className="cls-4"
            d="M619.55,771a.48.48,0,0,0,.11.68.49.49,0,0,0,.67-.11.5.5,0,0,0-.11-.68A.49.49,0,0,0,619.55,771Z"
          />
          <path
            className="cls-4"
            d="M608.77,774.51a.49.49,0,0,0,.79.57.49.49,0,0,0-.79-.57Z"
          />
          <path
            className="cls-4"
            d="M598,778.27a.48.48,0,0,0,.68-.11.49.49,0,0,0-.11-.67.48.48,0,1,0-.57.78Z"
          />
          <path
            className="cls-4"
            d="M587.51,780.13a.48.48,0,1,0,.11.67A.47.47,0,0,0,587.51,780.13Z"
          />
          <path
            className="cls-4"
            d="M575.71,782.43a.49.49,0,0,0,.11.67.5.5,0,0,0,.68-.11.48.48,0,0,0-.79-.56Z"
          />
          <path
            className="cls-4"
            d="M565.2,784a.52.52,0,1,0,.12.72A.52.52,0,0,0,565.2,784Z"
          />
          <path
            className="cls-4"
            d="M553.93,785.3a.5.5,0,0,0-.71.11.51.51,0,1,0,.83.6A.5.5,0,0,0,553.93,785.3Z"
          />
          <path
            className="cls-4"
            d="M542.61,786.13a.48.48,0,1,0,.11.68A.5.5,0,0,0,542.61,786.13Z"
          />
          <path
            className="cls-4"
            d="M531.28,786.49a.48.48,0,1,0-.56.78.48.48,0,0,0,.56-.78Z"
          />
          <path
            className="cls-4"
            d="M520.05,787.05a.48.48,0,1,0-.67.11A.49.49,0,0,0,520.05,787.05Z"
          />
          <path
            className="cls-4"
            d="M507.92,785.89a.51.51,0,1,0,.83.6.51.51,0,0,0-.83-.6Z"
          />
          <path
            className="cls-4"
            d="M496.68,784.89a.45.45,0,1,0,.63-.1A.45.45,0,0,0,496.68,784.89Z"
          />
          <path
            className="cls-4"
            d="M485.42,783.38a.48.48,0,1,0,.78.56.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M474.25,781.43a.48.48,0,1,0,.67-.11A.47.47,0,0,0,474.25,781.43Z"
          />
          <path
            className="cls-4"
            d="M463.28,779.7a.48.48,0,1,0-.11-.68A.49.49,0,0,0,463.28,779.7Z"
          />
          <path
            className="cls-4"
            d="M452.87,776.06a.48.48,0,1,0,.11.67A.47.47,0,0,0,452.87,776.06Z"
          />
          <path
            className="cls-4"
            d="M441.46,773.54a.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56A.49.49,0,0,0,441.46,773.54Z"
          />
          <path
            className="cls-4"
            d="M430.65,769.13a.47.47,0,0,0,.11.67.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M420.78,764.84a.49.49,0,0,0-.67.11.48.48,0,1,0,.67-.11Z"
          />
          <path
            className="cls-4"
            d="M409.72,760.33a.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12.5.5,0,0,0-.11-.71A.51.51,0,0,0,409.72,760.33Z"
          />
          <path
            className="cls-4"
            d="M399.61,755.35a.45.45,0,1,0,.63-.1A.45.45,0,0,0,399.61,755.35Z"
          />
          <path
            className="cls-4"
            d="M390.28,749.82a.45.45,0,1,0,.11.63A.45.45,0,0,0,390.28,749.82Z"
          />
          <path
            className="cls-4"
            d="M379.93,744.09a.45.45,0,0,0,.73.53.45.45,0,1,0-.73-.53Z"
          />
          <path
            className="cls-4"
            d="M370.45,737.87a.45.45,0,1,0,.63-.11A.45.45,0,0,0,370.45,737.87Z"
          />
          <path
            className="cls-4"
            d="M362,731.79a.45.45,0,1,0-.63.1A.45.45,0,0,0,362,731.79Z"
          />
          <path
            className="cls-4"
            d="M352.93,724.18a.46.46,0,1,0,.1.64A.46.46,0,0,0,352.93,724.18Z"
          />
          <path
            className="cls-4"
            d="M344.39,717.48a.46.46,0,0,0-.74-.53.47.47,0,0,0,.11.64A.46.46,0,0,0,344.39,717.48Z"
          />
          <circle className="cls-4" cx="335.68" cy="709.54" r="0.45" />
          <path
            className="cls-4"
            d="M328.05,701.81a.48.48,0,0,0-.11-.68.49.49,0,0,0-.67.11.5.5,0,0,0,.11.68A.49.49,0,0,0,328.05,701.81Z"
          />
          <path
            className="cls-4"
            d="M319.7,693.58a.47.47,0,0,0,.67-.11.49.49,0,0,0-.11-.67.48.48,0,1,0-.56.78Z"
          />
          <path
            className="cls-4"
            d="M313,684.83a.48.48,0,0,0-.79-.56.48.48,0,0,0,.12.67A.47.47,0,0,0,313,684.83Z"
          />
          <path
            className="cls-4"
            d="M306.05,675.9a.48.48,0,1,0-.68.11A.5.5,0,0,0,306.05,675.9Z"
          />
          <path
            className="cls-4"
            d="M298.76,666.8a.49.49,0,1,0,.57-.79.49.49,0,0,0-.57.79Z"
          />
          <path
            className="cls-4"
            d="M292.53,657.33a.49.49,0,1,0-.11-.68A.5.5,0,0,0,292.53,657.33Z"
          />
          <path
            className="cls-4"
            d="M287.24,646.85a.45.45,0,1,0,.1.63A.45.45,0,0,0,287.24,646.85Z"
          />
          <path
            className="cls-4"
            d="M281.26,637.66a.49.49,0,0,0,.67-.11.48.48,0,1,0-.67.11Z"
          />
          <path
            className="cls-4"
            d="M276.9,627.39a.48.48,0,0,0-.11-.68.49.49,0,0,0-.67.11.5.5,0,0,0,.11.68A.49.49,0,0,0,276.9,627.39Z"
          />
          <path
            className="cls-4"
            d="M272.25,617a.42.42,0,0,0-.69-.49.43.43,0,0,0,.1.59A.42.42,0,0,0,272.25,617Z"
          />
          <path
            className="cls-4"
            d="M267.33,605.93a.49.49,0,0,0,.11.67.48.48,0,1,0,.56-.78A.47.47,0,0,0,267.33,605.93Z"
          />
          <circle className="cls-4" cx="263.98" cy="595.51" r="0.45" />
          <path
            className="cls-4"
            d="M261.06,585a.5.5,0,0,0-.11-.68.49.49,0,0,0-.57.79A.5.5,0,0,0,261.06,585Z"
          />
          <path
            className="cls-4"
            d="M258.19,574a.48.48,0,1,0-.67.11A.48.48,0,0,0,258.19,574Z"
          />
          <path
            className="cls-4"
            d="M255.78,562.9a.48.48,0,0,0-.11-.67.48.48,0,1,0,.11.67Z"
          />
          <path
            className="cls-4"
            d="M253.82,551.73a.47.47,0,0,0-.11-.67.49.49,0,0,0-.67.11.48.48,0,0,0,.78.56Z"
          />
          <path
            className="cls-4"
            d="M252.32,540.5a.49.49,0,1,0-.68.11A.5.5,0,0,0,252.32,540.5Z"
          />
          <circle className="cls-4" cx="250.88" cy="528.93" r="0.45" />
          <path
            className="cls-4"
            d="M250,518a.49.49,0,0,0,.67-.11.48.48,0,1,0-.67.11Z"
          />
          <path
            className="cls-4"
            d="M249.9,506.66a.49.49,0,0,0,.67-.11.48.48,0,1,0-.67.11Z"
          />
          <path
            className="cls-4"
            d="M250.24,495.33a.48.48,0,1,0-.11-.68A.5.5,0,0,0,250.24,495.33Z"
          />
          <path
            className="cls-4"
            d="M251.67,483.87a.42.42,0,0,0-.1-.59.42.42,0,0,0-.49.69A.42.42,0,0,0,251.67,483.87Z"
          />
          <path
            className="cls-4"
            d="M252.23,472.1a.45.45,0,1,0,.73.52.46.46,0,0,0-.1-.63A.45.45,0,0,0,252.23,472.1Z"
          />
          <path
            className="cls-4"
            d="M254.68,461.42a.45.45,0,1,0-.63.1A.45.45,0,0,0,254.68,461.42Z"
          />
          <path
            className="cls-4"
            d="M256.86,450.29a.45.45,0,1,0-.63.11A.45.45,0,0,0,256.86,450.29Z"
          />
          <path
            className="cls-4"
            d="M259.52,439.28a.48.48,0,0,0-.79-.56.48.48,0,0,0,.12.67A.47.47,0,0,0,259.52,439.28Z"
          />
          <path
            className="cls-4"
            d="M262.57,428.35a.45.45,0,1,0-.63.11A.45.45,0,0,0,262.57,428.35Z"
          />
          <path
            className="cls-4"
            d="M266.12,417.6a.49.49,0,0,0-.79-.57.49.49,0,0,0,.79.57Z"
          />
          <path
            className="cls-4"
            d="M270.08,407a.49.49,0,0,0-.11-.67.48.48,0,1,0,.11.67Z"
          />
          <path
            className="cls-4"
            d="M274.46,396.52a.48.48,0,0,0-.78-.56.48.48,0,0,0,.78.56Z"
          />
          <path
            className="cls-4"
            d="M279.3,386.27a.51.51,0,0,0-.12-.71.5.5,0,0,0-.71.11.51.51,0,1,0,.83.6Z"
          />
          <path
            className="cls-4"
            d="M284.48,376.17a.45.45,0,1,0-.63.11A.45.45,0,0,0,284.48,376.17Z"
          />
          <path
            className="cls-4"
            d="M290.11,366.33a.45.45,0,1,0-.63.11A.45.45,0,0,0,290.11,366.33Z"
          />
          <path
            className="cls-4"
            d="M296.14,356.73a.45.45,0,0,0-.11-.63.45.45,0,1,0-.52.74A.45.45,0,0,0,296.14,356.73Z"
          />
          <path
            className="cls-4"
            d="M302.55,347.38a.45.45,0,1,0-.63.11A.45.45,0,0,0,302.55,347.38Z"
          />
          <path
            className="cls-4"
            d="M309.34,338.31a.45.45,0,1,0-.73-.53.45.45,0,1,0,.73.53Z"
          />
          <path
            className="cls-4"
            d="M316.5,329.51a.45.45,0,1,0-.63.11A.46.46,0,0,0,316.5,329.51Z"
          />
          <path
            className="cls-4"
            d="M324,321a.46.46,0,0,0-.11-.63.45.45,0,0,0-.63.1.46.46,0,0,0,.74.53Z"
          />
          <path
            className="cls-4"
            d="M331.9,312.87a.51.51,0,0,0-.83-.6.52.52,0,0,0,.12.72A.51.51,0,0,0,331.9,312.87Z"
          />
          <path
            className="cls-4"
            d="M340.05,305a.48.48,0,0,0-.78-.56.48.48,0,1,0,.78.56Z"
          />
          <path
            className="cls-4"
            d="M348.54,297.49a.48.48,0,0,0-.11-.68.49.49,0,0,0-.67.11.47.47,0,0,0,.11.67A.48.48,0,0,0,348.54,297.49Z"
          />
          <path
            className="cls-4"
            d="M357.33,290.32a.48.48,0,1,0-.67.11A.47.47,0,0,0,357.33,290.32Z"
          />
          <path
            className="cls-4"
            d="M366.41,283.53a.49.49,0,0,0-.79-.57.49.49,0,1,0,.79.57Z"
          />
          <path
            className="cls-4"
            d="M375.75,277.1a.49.49,0,0,0-.11-.67.5.5,0,0,0-.68.11.48.48,0,0,0,.12.67A.47.47,0,0,0,375.75,277.1Z"
          />
          <path
            className="cls-4"
            d="M385.35,271.07a.48.48,0,0,0-.12-.67.47.47,0,0,0-.67.11.49.49,0,0,0,.11.67A.5.5,0,0,0,385.35,271.07Z"
          />
          <path
            className="cls-4"
            d="M395.16,265.41a.45.45,0,1,0-.63.11A.46.46,0,0,0,395.16,265.41Z"
          />
          <path
            className="cls-4"
            d="M405.21,260.18a.45.45,0,1,0-.63.1A.45.45,0,0,0,405.21,260.18Z"
          />
          <path
            className="cls-4"
            d="M415.48,255.36a.46.46,0,0,0-.11-.63.45.45,0,1,0,.11.63Z"
          />
          <path
            className="cls-4"
            d="M426,251a.52.52,0,0,0-.84-.6.52.52,0,0,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M436.57,247a.48.48,0,1,0-.67.11A.48.48,0,0,0,436.57,247Z"
          />
          <path
            className="cls-4"
            d="M447.35,243.49a.49.49,0,0,0-.12-.68.48.48,0,0,0-.67.12.49.49,0,0,0,.11.67A.5.5,0,0,0,447.35,243.49Z"
          />
          <path
            className="cls-4"
            d="M457.58,240.51a.48.48,0,1,0-.11-.67A.49.49,0,0,0,457.58,240.51Z"
          />
          <path
            className="cls-4"
            d="M468.59,237.9a.52.52,0,0,0,.6-.84.52.52,0,0,0-.6.84Z"
          />
          <path
            className="cls-4"
            d="M479.73,235.68a.48.48,0,0,0,.56-.78.48.48,0,0,0-.67.11A.49.49,0,0,0,479.73,235.68Z"
          />
          <circle
            className="cls-4"
            cx="491.22"
            cy="233.56"
            r="0.48"
            transform="translate(14.99 496.34) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M502.87,232.57a.48.48,0,1,0-.78-.56.48.48,0,0,0,.11.67A.49.49,0,0,0,502.87,232.57Z"
          />
          <path
            className="cls-4"
            d="M513.49,231.89a.52.52,0,0,0,.72-.12.52.52,0,0,0-.84-.6A.52.52,0,0,0,513.49,231.89Z"
          />
          <path
            className="cls-4"
            d="M525.51,231.4a.47.47,0,0,0-.11-.67.49.49,0,0,0-.67.11.48.48,0,0,0,.78.56Z"
          />
          <path
            className="cls-4"
            d="M536.18,231.63a.49.49,0,0,0,.67-.11.48.48,0,1,0-.67.11Z"
          />
          <path
            className="cls-4"
            d="M548.17,232.09a.48.48,0,1,0-.67.11A.48.48,0,0,0,548.17,232.09Z"
          />
          <path
            className="cls-4"
            d="M559.46,233.12a.47.47,0,0,0-.11-.67.49.49,0,0,0-.67.11.48.48,0,0,0,.78.56Z"
          />
          <path
            className="cls-4"
            d="M570.59,234a.48.48,0,0,0-.56.78.49.49,0,0,0,.67-.11A.47.47,0,0,0,570.59,234Z"
          />
          <path
            className="cls-4"
            d="M581.78,235.87a.52.52,0,1,0-.6.84.52.52,0,0,0,.6-.84Z"
          />
          <path
            className="cls-4"
            d="M592.27,239.09a.49.49,0,0,0,.57-.79.49.49,0,0,0-.57.79Z"
          />
          <path
            className="cls-4"
            d="M603.25,241.94a.48.48,0,1,0-.11-.67A.47.47,0,0,0,603.25,241.94Z"
          />
          <path
            className="cls-4"
            d="M614.09,245.24a.48.48,0,0,0,.68-.11.48.48,0,1,0-.68.11Z"
          />
          <path
            className="cls-4"
            d="M624.8,249a.47.47,0,0,0,.67-.11.48.48,0,0,0-.79-.56A.48.48,0,0,0,624.8,249Z"
          />
          <path
            className="cls-4"
            d="M636,253.05a.5.5,0,0,0-.11-.68.48.48,0,0,0-.67.12.47.47,0,0,0,.11.67A.49.49,0,0,0,636,253.05Z"
          />
          <path
            className="cls-4"
            d="M646.37,257.65a.48.48,0,1,0-.67.11A.49.49,0,0,0,646.37,257.65Z"
          />
          <path
            className="cls-4"
            d="M656.56,262.68a.51.51,0,1,0-.71.12A.5.5,0,0,0,656.56,262.68Z"
          />
          <path
            className="cls-4"
            d="M665.8,268.23a.51.51,0,0,0,.6-.83.52.52,0,0,0-.72.12A.51.51,0,0,0,665.8,268.23Z"
          />
          <path
            className="cls-4"
            d="M676.24,274a.52.52,0,1,0-.84-.6.52.52,0,0,0,.84.6Z"
          />
          <path
            className="cls-4"
            d="M685,280.29a.52.52,0,0,0,.6-.84.52.52,0,1,0-.6.84Z"
          />
          <path
            className="cls-4"
            d="M694.1,286.18a.5.5,0,0,0,.11.71.51.51,0,1,0,.6-.83A.5.5,0,0,0,694.1,286.18Z"
          />
          <path
            className="cls-4"
            d="M703.75,293a.52.52,0,0,0-.72.12.52.52,0,0,0,.84.6A.52.52,0,0,0,703.75,293Z"
          />
          <path
            className="cls-4"
            d="M712.39,300.37a.5.5,0,0,0-.71.11.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12A.5.5,0,0,0,712.39,300.37Z"
          />
          <path
            className="cls-4"
            d="M720.73,308.05a.5.5,0,0,0-.71.11.51.51,0,1,0,.83.6A.5.5,0,0,0,720.73,308.05Z"
          />
          <path
            className="cls-4"
            d="M728.06,316.19a.49.49,0,1,0,.68-.11A.5.5,0,0,0,728.06,316.19Z"
          />
          <path
            className="cls-4"
            d="M735.75,324.53a.48.48,0,1,0,.67-.11A.47.47,0,0,0,735.75,324.53Z"
          />
          <path
            className="cls-4"
            d="M743.78,333a.52.52,0,1,0,.12.72A.52.52,0,0,0,743.78,333Z"
          />
          <path
            className="cls-4"
            d="M750.76,342a.5.5,0,0,0-.71.11.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12A.51.51,0,0,0,750.76,342Z"
          />
          <circle
            className="cls-4"
            cx="757.07"
            cy="351.59"
            r="0.48"
            transform="translate(29.91 761.48) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M763.58,360.67a.48.48,0,1,0,.11.68A.48.48,0,0,0,763.58,360.67Z"
          />
          <path
            className="cls-4"
            d="M768.72,370.49a.51.51,0,0,0,.12.71.52.52,0,0,0,.72-.12.51.51,0,0,0-.84-.59Z"
          />
          <path
            className="cls-4"
            d="M774.88,380.32a.51.51,0,0,0-.72.11.52.52,0,0,0,.12.72A.51.51,0,0,0,775,381,.5.5,0,0,0,774.88,380.32Z"
          />
          <path
            className="cls-4"
            d="M779.19,390.6a.5.5,0,0,0,.12.71.51.51,0,0,0,.71-.12.51.51,0,0,0-.83-.59Z"
          />
          <path
            className="cls-4"
            d="M783.82,401a.49.49,0,0,0,.11.67.48.48,0,1,0,.56-.78A.49.49,0,0,0,783.82,401Z"
          />
          <path
            className="cls-4"
            d="M788,411.51a.48.48,0,0,0,.79.56.48.48,0,0,0-.12-.67A.47.47,0,0,0,788,411.51Z"
          />
          <path
            className="cls-4"
            d="M792.42,422.1a.47.47,0,0,0-.67.11.48.48,0,0,0,.11.67.48.48,0,0,0,.56-.78Z"
          />
          <path
            className="cls-4"
            d="M795,433a.51.51,0,0,0,.11.72.52.52,0,0,0,.72-.12.51.51,0,0,0-.12-.71A.5.5,0,0,0,795,433Z"
          />
          <path
            className="cls-4"
            d="M797.9,444a.51.51,0,1,0,.83.6.51.51,0,0,0-.83-.6Z"
          />
          <path
            className="cls-4"
            d="M800.31,455.08a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,800.31,455.08Z"
          />
          <path
            className="cls-4"
            d="M803,466.16a.48.48,0,0,0-.56.78.49.49,0,0,0,.67-.11A.47.47,0,0,0,803,466.16Z"
          />
          <path
            className="cls-4"
            d="M803.78,477.49a.5.5,0,0,0,.11.71.51.51,0,1,0,.6-.83A.5.5,0,0,0,803.78,477.49Z"
          />
          <path
            className="cls-4"
            d="M804.82,488.77a.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12.51.51,0,0,0-.83-.6Z"
          />
          <path
            className="cls-4"
            d="M805.43,500.11a.48.48,0,0,0,.11.68.49.49,0,0,0,.67-.11.48.48,0,1,0-.78-.57Z"
          />
          <path
            className="cls-4"
            d="M805.55,511.45a.48.48,0,1,0,.78.56.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M805.88,522.67a.49.49,0,1,0-.57.79.49.49,0,0,0,.57-.79Z"
          />
          <path
            className="cls-4"
            d="M804.49,534.79a.52.52,0,0,0,.72-.12.52.52,0,0,0-.84-.6A.52.52,0,0,0,804.49,534.79Z"
          />
          <path
            className="cls-4"
            d="M803.13,545.36a.48.48,0,0,0,.12.67.47.47,0,0,0,.67-.11.48.48,0,0,0-.79-.56Z"
          />
          <path
            className="cls-4"
            d="M801.39,556.55a.5.5,0,0,0,.11.71.51.51,0,1,0,.6-.83A.5.5,0,0,0,801.39,556.55Z"
          />
          <path
            className="cls-4"
            d="M799.21,567.67a.51.51,0,0,0,.11.72.52.52,0,1,0,.6-.84A.51.51,0,0,0,799.21,567.67Z"
          />
          <path
            className="cls-4"
            d="M796.6,578.72a.47.47,0,0,0,.11.67.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M793.5,589.61a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,793.5,589.61Z"
          />
          <path
            className="cls-4"
            d="M790,600.39a.51.51,0,1,0,.72-.12A.52.52,0,0,0,790,600.39Z"
          />
          <path
            className="cls-4"
            d="M786,611a.49.49,0,0,0,.11.67A.48.48,0,1,0,786,611Z"
          />
          <path
            className="cls-4"
            d="M781.65,621.48a.48.48,0,1,0,.68-.11A.49.49,0,0,0,781.65,621.48Z"
          />
          <path
            className="cls-4"
            d="M776.82,631.73a.51.51,0,0,0,.83.6.52.52,0,0,0-.12-.72A.51.51,0,0,0,776.82,631.73Z"
          />
          <path
            className="cls-4"
            d="M771.59,641.79a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,771.59,641.79Z"
          />
          <path
            className="cls-4"
            d="M766,651.63a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,766,651.63Z"
          />
          <path
            className="cls-4"
            d="M759.93,661.23a.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12.5.5,0,0,0-.11-.71A.51.51,0,0,0,759.93,661.23Z"
          />
          <path
            className="cls-4"
            d="M753.52,670.58a.51.51,0,0,0,.83.6.52.52,0,0,0-.12-.72A.51.51,0,0,0,753.52,670.58Z"
          />
          <path
            className="cls-4"
            d="M746.73,679.66a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,746.73,679.66Z"
          />
          <path
            className="cls-4"
            d="M739.57,688.45a.52.52,0,0,0,.12.72.52.52,0,0,0,.6-.84A.52.52,0,0,0,739.57,688.45Z"
          />
          <path
            className="cls-4"
            d="M732.06,697a.51.51,0,1,0,.72-.12A.52.52,0,0,0,732.06,697Z"
          />
          <path
            className="cls-4"
            d="M724.22,705.13a.5.5,0,0,0,.11.71.51.51,0,0,0,.72-.11.52.52,0,0,0-.12-.72A.51.51,0,0,0,724.22,705.13Z"
          />
          <path
            className="cls-4"
            d="M716.06,713a.48.48,0,1,0,.68-.11A.49.49,0,0,0,716.06,713Z"
          />
          <path
            className="cls-4"
            d="M707.58,720.52a.47.47,0,0,0,.11.67.49.49,0,0,0,.67-.11.5.5,0,0,0-.11-.68A.48.48,0,0,0,707.58,720.52Z"
          />
          <path
            className="cls-4"
            d="M698.79,727.68a.47.47,0,0,0,.11.67.48.48,0,0,0,.67-.11.49.49,0,0,0-.11-.67A.47.47,0,0,0,698.79,727.68Z"
          />
          <path
            className="cls-4"
            d="M689.71,734.47a.49.49,0,1,0,.68-.11A.5.5,0,0,0,689.71,734.47Z"
          />
          <path
            className="cls-4"
            d="M680.37,740.9a.47.47,0,0,0,.11.67.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M670.75,746.91a.51.51,0,1,0,.71-.11A.5.5,0,0,0,670.75,746.91Z"
          />
          <path
            className="cls-4"
            d="M660.91,752.55a.52.52,0,0,0,.12.72.51.51,0,0,0,.71-.12.5.5,0,0,0-.11-.71A.51.51,0,0,0,660.91,752.55Z"
          />
          <path
            className="cls-4"
            d="M656.55,769.18a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54A.39.39,0,0,0,656.55,769.18Z"
          />
          <path
            className="cls-4"
            d="M646.25,774a.45.45,0,1,0,.63-.1A.45.45,0,0,0,646.25,774Z"
          />
          <circle className="cls-4" cx="636.18" cy="778.66" r="0.45" />
          <path
            className="cls-4"
            d="M625.84,782.3a.46.46,0,1,0-.53.74.45.45,0,0,0,.63-.11A.46.46,0,0,0,625.84,782.3Z"
          />
          <path
            className="cls-4"
            d="M614.48,786a.42.42,0,1,0,.59-.1A.43.43,0,0,0,614.48,786Z"
          />
          <path
            className="cls-4"
            d="M604.28,789.69a.43.43,0,1,0-.59.09A.42.42,0,0,0,604.28,789.69Z"
          />
          <path
            className="cls-4"
            d="M592.57,791.92a.45.45,0,1,0,.63-.1A.45.45,0,0,0,592.57,791.92Z"
          />
          <path
            className="cls-4"
            d="M581.47,794.24a.46.46,0,0,0,.1.63.45.45,0,0,0,.63-.1.44.44,0,0,0-.1-.63A.45.45,0,0,0,581.47,794.24Z"
          />
          <path
            className="cls-4"
            d="M570.29,796.12a.46.46,0,0,0,.1.63.45.45,0,1,0-.1-.63Z"
          />
          <path
            className="cls-4"
            d="M559.07,797.58a.42.42,0,0,0,.69.49.42.42,0,0,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M547.75,798.57a.45.45,0,1,0,.63-.11A.45.45,0,0,0,547.75,798.57Z"
          />
          <path
            className="cls-4"
            d="M537.06,799a.45.45,0,1,0,.1.63A.45.45,0,0,0,537.06,799Z"
          />
          <path
            className="cls-4"
            d="M525.8,799.76a.42.42,0,1,0-.68-.49.42.42,0,0,0,.68.49Z"
          />
          <path
            className="cls-4"
            d="M513.88,799.53a.43.43,0,0,0,.59-.09.42.42,0,0,0-.1-.59.42.42,0,0,0-.49.68Z"
          />
          <path
            className="cls-4"
            d="M503.06,798.08a.42.42,0,1,0,.1.59A.43.43,0,0,0,503.06,798.08Z"
          />
          <path
            className="cls-4"
            d="M491.2,797a.43.43,0,0,0,.1.59.42.42,0,0,0,.59-.09.43.43,0,0,0-.69-.5Z"
          />
          <path
            className="cls-4"
            d="M480,795.31a.45.45,0,1,0,.63-.1A.45.45,0,0,0,480,795.31Z"
          />
          <path
            className="cls-4"
            d="M468.84,793.25a.42.42,0,1,0,.59-.1A.43.43,0,0,0,468.84,793.25Z"
          />
          <path
            className="cls-4"
            d="M457.76,790.72a.45.45,0,0,0,.1.63.46.46,0,0,0,.53-.74A.45.45,0,0,0,457.76,790.72Z"
          />
          <path
            className="cls-4"
            d="M447.44,787.67a.45.45,0,0,0-.63.11.45.45,0,1,0,.73.52A.45.45,0,0,0,447.44,787.67Z"
          />
          <path
            className="cls-4"
            d="M436,784.42a.42.42,0,0,0,.1.59.42.42,0,0,0,.49-.68A.43.43,0,0,0,436,784.42Z"
          />
          <path
            className="cls-4"
            d="M425.32,780.64a.42.42,0,0,0,.69.49.43.43,0,0,0-.1-.59A.42.42,0,0,0,425.32,780.64Z"
          />
          <path
            className="cls-4"
            d="M414.79,776.43a.43.43,0,0,0,.1.59.42.42,0,1,0-.1-.59Z"
          />
          <circle className="cls-4" cx="404.78" cy="772.07" r="0.45" />
          <path
            className="cls-4"
            d="M394.27,766.81a.42.42,0,1,0,.58-.09A.42.42,0,0,0,394.27,766.81Z"
          />
          <path
            className="cls-4"
            d="M384.3,761.41a.42.42,0,0,0,.1.59.41.41,0,0,0,.58-.1.42.42,0,0,0-.09-.59A.43.43,0,0,0,384.3,761.41Z"
          />
          <path
            className="cls-4"
            d="M374.55,755.62a.42.42,0,0,0,.1.59.42.42,0,0,0,.49-.68A.42.42,0,0,0,374.55,755.62Z"
          />
          <path
            className="cls-4"
            d="M365.62,749.36a.42.42,0,1,0,.1.59A.42.42,0,0,0,365.62,749.36Z"
          />
          <path
            className="cls-4"
            d="M356.45,743.42a.42.42,0,0,0-.09-.59.43.43,0,1,0,.09.59Z"
          />
          <path
            className="cls-4"
            d="M347.45,736.53a.42.42,0,0,0-.69-.49.43.43,0,0,0,.1.59A.42.42,0,0,0,347.45,736.53Z"
          />
          <path
            className="cls-4"
            d="M338.62,728.71a.42.42,0,1,0,.1.59A.43.43,0,0,0,338.62,728.71Z"
          />
          <path
            className="cls-4"
            d="M330.18,721.14a.43.43,0,0,0-.59.1.42.42,0,0,0,.1.59.42.42,0,1,0,.49-.69Z"
          />
          <path
            className="cls-4"
            d="M322,713.25a.42.42,0,1,0,.1.59A.43.43,0,0,0,322,713.25Z"
          />
          <path
            className="cls-4"
            d="M314.31,705.64a.42.42,0,0,0-.1-.59.42.42,0,0,0-.49.68A.42.42,0,0,0,314.31,705.64Z"
          />
          <path
            className="cls-4"
            d="M306.71,696.55a.41.41,0,0,0-.58.09.42.42,0,1,0,.68.49A.41.41,0,0,0,306.71,696.55Z"
          />
          <path
            className="cls-4"
            d="M299,687.85a.43.43,0,1,0,.59-.09A.42.42,0,0,0,299,687.85Z"
          />
          <path
            className="cls-4"
            d="M292.81,679.27a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <circle className="cls-4" cx="286.04" cy="669.72" r="0.45" />
          <path
            className="cls-4"
            d="M279.61,659.91a.42.42,0,1,0,.59-.1A.42.42,0,0,0,279.61,659.91Z"
          />
          <path
            className="cls-4"
            d="M273.9,650.11a.42.42,0,1,0,.69.49.42.42,0,0,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M269.29,640.61a.46.46,0,0,0-.74-.53.46.46,0,0,0,.11.63A.45.45,0,0,0,269.29,640.61Z"
          />
          <path
            className="cls-4"
            d="M264.34,630.38a.42.42,0,1,0-.59.1A.42.42,0,0,0,264.34,630.38Z"
          />
          <path
            className="cls-4"
            d="M259.81,620a.42.42,0,0,0-.1-.59.42.42,0,0,0-.49.68A.42.42,0,0,0,259.81,620Z"
          />
          <path
            className="cls-4"
            d="M255.12,609.5a.41.41,0,0,0,.55-.09A.39.39,0,1,0,255,609,.41.41,0,0,0,255.12,609.5Z"
          />
          <path
            className="cls-4"
            d="M251.92,598.1a.46.46,0,0,0-.64.1.45.45,0,0,0,.11.63.45.45,0,0,0,.53-.73Z"
          />
          <path
            className="cls-4"
            d="M248.71,587.86a.42.42,0,1,0-.58.09A.41.41,0,0,0,248.71,587.86Z"
          />
          <path
            className="cls-4"
            d="M245.86,576.88a.42.42,0,0,0-.68-.49.42.42,0,1,0,.68.49Z"
          />
          <path
            className="cls-4"
            d="M243.44,565.81a.43.43,0,0,0-.1-.59.42.42,0,0,0-.59.1.42.42,0,0,0,.69.49Z"
          />
          <path
            className="cls-4"
            d="M241.47,554.66a.45.45,0,1,0-.63.11A.45.45,0,0,0,241.47,554.66Z"
          />
          <path
            className="cls-4"
            d="M239.89,543.42a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.09.42.42,0,0,0,.1.59A.41.41,0,0,0,239.89,543.42Z"
          />
          <path
            className="cls-4"
            d="M238.78,532.13a.41.41,0,0,0-.1-.58.42.42,0,0,0-.59.09.43.43,0,0,0,.1.59A.42.42,0,0,0,238.78,532.13Z"
          />
          <path
            className="cls-4"
            d="M238.11,520.82a.43.43,0,1,0-.59.09A.42.42,0,0,0,238.11,520.82Z"
          />
          <path
            className="cls-4"
            d="M237.27,509.6a.45.45,0,1,0-.1-.63A.45.45,0,0,0,237.27,509.6Z"
          />
          <path
            className="cls-4"
            d="M237.5,498.24a.42.42,0,1,0-.1-.59A.43.43,0,0,0,237.5,498.24Z"
          />
          <path
            className="cls-4"
            d="M238.74,486.83a.42.42,0,1,0-.58.1A.42.42,0,0,0,238.74,486.83Z"
          />
          <path
            className="cls-4"
            d="M239.74,475a.42.42,0,0,0-.59.09.43.43,0,0,0,.1.59.42.42,0,0,0,.59-.1A.42.42,0,0,0,239.74,475Z"
          />
          <path
            className="cls-4"
            d="M241.4,464.33a.46.46,0,1,0-.63.1A.45.45,0,0,0,241.4,464.33Z"
          />
          <path
            className="cls-4"
            d="M243.34,453.15a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.09.42.42,0,0,0,.1.59A.41.41,0,0,0,243.34,453.15Z"
          />
          <path
            className="cls-4"
            d="M245.78,442.08a.46.46,0,0,0-.11-.63.45.45,0,1,0,.11.63Z"
          />
          <path
            className="cls-4"
            d="M248.61,431.11a.45.45,0,1,0-.63.1A.45.45,0,0,0,248.61,431.11Z"
          />
          <path
            className="cls-4"
            d="M251.82,420.21a.39.39,0,1,0-.63-.45.39.39,0,0,0,.63.45Z"
          />
          <path
            className="cls-4"
            d="M255.53,409.51a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.1.42.42,0,0,0,.09.59A.43.43,0,0,0,255.53,409.51Z"
          />
          <path
            className="cls-4"
            d="M259.63,398.94a.42.42,0,1,0-.59.1A.43.43,0,0,0,259.63,398.94Z"
          />
          <path
            className="cls-4"
            d="M264.14,388.54a.42.42,0,1,0-.59.09A.42.42,0,0,0,264.14,388.54Z"
          />
          <path
            className="cls-4"
            d="M269.08,378.34a.46.46,0,0,0-.11-.63.45.45,0,1,0,.11.63Z"
          />
          <path
            className="cls-4"
            d="M274.36,368.3a.42.42,0,1,0-.59.1A.43.43,0,0,0,274.36,368.3Z"
          />
          <path
            className="cls-4"
            d="M280.05,358.5a.43.43,0,0,0-.69-.5.43.43,0,0,0,.1.59A.42.42,0,0,0,280.05,358.5Z"
          />
          <path
            className="cls-4"
            d="M286.12,348.92a.42.42,0,0,0-.1-.59.42.42,0,0,0-.49.69A.43.43,0,0,0,286.12,348.92Z"
          />
          <path
            className="cls-4"
            d="M292.56,339.59a.42.42,0,0,0-.1-.59.42.42,0,1,0,.1.59Z"
          />
          <path
            className="cls-4"
            d="M299.36,330.52a.43.43,0,0,0-.1-.59.42.42,0,1,0,.1.59Z"
          />
          <path
            className="cls-4"
            d="M306.51,321.72a.43.43,0,0,0-.1-.59.42.42,0,1,0,.1.59Z"
          />
          <path
            className="cls-4"
            d="M314,313.2a.42.42,0,1,0-.68-.49.42.42,0,0,0,.09.59A.43.43,0,0,0,314,313.2Z"
          />
          <path
            className="cls-4"
            d="M321.83,305a.45.45,0,1,0-.74-.52.46.46,0,0,0,.11.63A.45.45,0,0,0,321.83,305Z"
          />
          <path
            className="cls-4"
            d="M329.93,297.08a.42.42,0,1,0-.59.1A.43.43,0,0,0,329.93,297.08Z"
          />
          <path
            className="cls-4"
            d="M338.36,289.5a.42.42,0,1,0-.69-.49.42.42,0,0,0,.69.49Z"
          />
          <path
            className="cls-4"
            d="M347.08,282.25a.42.42,0,1,0-.59.1A.43.43,0,0,0,347.08,282.25Z"
          />
          <path
            className="cls-4"
            d="M356.07,275.35a.42.42,0,0,0-.09-.59.43.43,0,1,0,.09.59Z"
          />
          <path
            className="cls-4"
            d="M365.35,268.82a.45.45,0,0,0-.1-.63.46.46,0,0,0-.53.74A.45.45,0,0,0,365.35,268.82Z"
          />
          <path
            className="cls-4"
            d="M374.86,262.64a.45.45,0,0,0-.1-.63.46.46,0,1,0-.53.74A.45.45,0,0,0,374.86,262.64Z"
          />
          <path
            className="cls-4"
            d="M384.57,256.82a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.1.42.42,0,1,0,.68.49Z"
          />
          <path
            className="cls-4"
            d="M394.53,251.4a.42.42,0,1,0-.68-.49.42.42,0,0,0,.09.59A.43.43,0,0,0,394.53,251.4Z"
          />
          <path
            className="cls-4"
            d="M404.69,246.38a.42.42,0,1,0-.68-.5.42.42,0,1,0,.68.5Z"
          />
          <path
            className="cls-4"
            d="M415.07,241.77a.46.46,0,0,0-.1-.63.45.45,0,0,0-.63.1.44.44,0,0,0,.1.63A.45.45,0,0,0,415.07,241.77Z"
          />
          <path
            className="cls-4"
            d="M425.57,237.53a.42.42,0,1,0-.59.1A.43.43,0,0,0,425.57,237.53Z"
          />
          <path
            className="cls-4"
            d="M436.27,233.74a.45.45,0,1,0-.63.11A.46.46,0,0,0,436.27,233.74Z"
          />
          <path
            className="cls-4"
            d="M447.09,230.36a.45.45,0,1,0-.63.1A.45.45,0,0,0,447.09,230.36Z"
          />
          <path
            className="cls-4"
            d="M458,227.4a.47.47,0,0,0-.11-.64.46.46,0,0,0-.53.74A.47.47,0,0,0,458,227.4Z"
          />
          <path
            className="cls-4"
            d="M469.06,224.85a.42.42,0,1,0-.68-.5.42.42,0,1,0,.68.5Z"
          />
          <path
            className="cls-4"
            d="M480.23,222.76a.45.45,0,0,0-.11-.63.46.46,0,0,0-.63.11.45.45,0,1,0,.74.52Z"
          />
          <path
            className="cls-4"
            d="M490.81,221.21a.46.46,0,1,0-.1-.63A.45.45,0,0,0,490.81,221.21Z"
          />
          <path
            className="cls-4"
            d="M502.69,219.86a.42.42,0,1,0-.59.1A.43.43,0,0,0,502.69,219.86Z"
          />
          <path
            className="cls-4"
            d="M514,219.08a.43.43,0,0,0-.1-.59.42.42,0,0,0-.59.1.42.42,0,0,0,.69.49Z"
          />
          <path
            className="cls-4"
            d="M525.36,218.76a.47.47,0,0,0-.11-.64.46.46,0,0,0-.53.74A.47.47,0,0,0,525.36,218.76Z"
          />
          <path
            className="cls-4"
            d="M536.06,219a.45.45,0,1,0-.1-.63A.45.45,0,0,0,536.06,219Z"
          />
          <path
            className="cls-4"
            d="M547.28,218.87a.46.46,0,1,0,.63-.1A.45.45,0,0,0,547.28,218.87Z"
          />
          <path
            className="cls-4"
            d="M559.31,220.39a.45.45,0,1,0-.63.1A.46.46,0,0,0,559.31,220.39Z"
          />
          <path
            className="cls-4"
            d="M570.44,221.2a.43.43,0,0,0-.59.1.42.42,0,0,0,.1.59.41.41,0,0,0,.58-.1A.42.42,0,0,0,570.44,221.2Z"
          />
          <path
            className="cls-4"
            d="M581.13,223.75a.42.42,0,0,0,.59-.09.43.43,0,0,0-.1-.59.42.42,0,0,0-.59.09A.43.43,0,0,0,581.13,223.75Z"
          />
          <path
            className="cls-4"
            d="M592.84,226a.45.45,0,1,0-.63.1A.45.45,0,0,0,592.84,226Z"
          />
          <path
            className="cls-4"
            d="M603.11,228.17a.46.46,0,1,0,.63-.1A.45.45,0,0,0,603.11,228.17Z"
          />
          <path
            className="cls-4"
            d="M614.74,231.86a.46.46,0,1,0-.74-.53.46.46,0,0,0,.74.53Z"
          />
          <path
            className="cls-4"
            d="M624.76,234.91a.45.45,0,1,0,.63-.11A.45.45,0,0,0,624.76,234.91Z"
          />
          <circle className="cls-4" cx="635.74" cy="239.17" r="0.45" />
          <path
            className="cls-4"
            d="M646.45,243.21a.45.45,0,1,0,.1.63A.45.45,0,0,0,646.45,243.21Z"
          />
          <path
            className="cls-4"
            d="M656.08,248.12a.46.46,0,1,0,.63-.1A.45.45,0,0,0,656.08,248.12Z"
          />
          <path
            className="cls-4"
            d="M666.13,253.31a.48.48,0,1,0,.67-.11A.48.48,0,0,0,666.13,253.31Z"
          />
          <path
            className="cls-4"
            d="M676.1,259.58a.48.48,0,1,0-.11-.67A.47.47,0,0,0,676.1,259.58Z"
          />
          <path
            className="cls-4"
            d="M685.73,265.56a.48.48,0,1,0-.11-.67A.48.48,0,0,0,685.73,265.56Z"
          />
          <path
            className="cls-4"
            d="M695,271.23a.49.49,0,1,0,.68-.11A.5.5,0,0,0,695,271.23Z"
          />
          <path
            className="cls-4"
            d="M704.15,277.94a.49.49,0,1,0,.68-.11A.5.5,0,0,0,704.15,277.94Z"
          />
          <path
            className="cls-4"
            d="M713.13,285.68A.48.48,0,1,0,713,285,.5.5,0,0,0,713.13,285.68Z"
          />
          <path
            className="cls-4"
            d="M722.28,292.3a.48.48,0,1,0-.56.78.48.48,0,0,0,.67-.11A.47.47,0,0,0,722.28,292.3Z"
          />
          <path
            className="cls-4"
            d="M730.66,300.68a.45.45,0,1,0-.63.11A.45.45,0,0,0,730.66,300.68Z"
          />
          <path
            className="cls-4"
            d="M738,308.84a.46.46,0,0,0,.63-.11.45.45,0,1,0-.63.11Z"
          />
          <path
            className="cls-4"
            d="M745.57,316.56a.46.46,0,1,0,.63-.1A.45.45,0,0,0,745.57,316.56Z"
          />
          <path
            className="cls-4"
            d="M753.67,325.75a.48.48,0,0,0-.12-.67.47.47,0,0,0-.67.11.48.48,0,0,0,.79.56Z"
          />
          <path
            className="cls-4"
            d="M760,334.79a.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56A.48.48,0,0,0,760,334.79Z"
          />
          <path
            className="cls-4"
            d="M766.53,343.33a.46.46,0,0,0,.1.63.46.46,0,1,0-.1-.63Z"
          />
          <circle className="cls-4" cx="773.17" cy="353.03" r="0.45" />
          <path
            className="cls-4"
            d="M779.33,362.35a.45.45,0,0,0-.53.73.45.45,0,1,0,.53-.73Z"
          />
          <path
            className="cls-4"
            d="M784.22,372.36a.45.45,0,1,0,.63-.11A.45.45,0,0,0,784.22,372.36Z"
          />
          <path
            className="cls-4"
            d="M789.43,383.13a.48.48,0,0,0,.67-.12.47.47,0,0,0-.11-.67.48.48,0,0,0-.56.79Z"
          />
          <path
            className="cls-4"
            d="M794.72,392.65a.48.48,0,0,0-.56.78.48.48,0,0,0,.67-.11A.49.49,0,0,0,794.72,392.65Z"
          />
          <path
            className="cls-4"
            d="M799.1,403.77a.42.42,0,0,0-.1-.59.41.41,0,0,0-.58.09.42.42,0,0,0,.09.59A.43.43,0,0,0,799.1,403.77Z"
          />
          <path
            className="cls-4"
            d="M802.3,413.9a.46.46,0,0,0,.1.63.47.47,0,0,0,.64-.1.46.46,0,0,0-.11-.63A.45.45,0,0,0,802.3,413.9Z"
          />
          <path
            className="cls-4"
            d="M805.77,424.67a.48.48,0,1,0,.67-.11A.47.47,0,0,0,805.77,424.67Z"
          />
          <path
            className="cls-4"
            d="M808.86,435.6a.45.45,0,1,0,.63-.1A.45.45,0,0,0,808.86,435.6Z"
          />
          <path
            className="cls-4"
            d="M812.13,446.52a.46.46,0,1,0,.1.63A.45.45,0,0,0,812.13,446.52Z"
          />
          <path
            className="cls-4"
            d="M813.71,457.74a.45.45,0,1,0,.73.53.45.45,0,0,0-.1-.63A.46.46,0,0,0,813.71,457.74Z"
          />
          <circle className="cls-4" cx="815.85" cy="469.21" r="0.45" />
          <path
            className="cls-4"
            d="M816.81,480.2a.46.46,0,1,0,.63-.1A.45.45,0,0,0,816.81,480.2Z"
          />
          <path
            className="cls-4"
            d="M817.7,491.5a.46.46,0,0,0,.11.63.45.45,0,1,0-.11-.63Z"
          />
          <path
            className="cls-4"
            d="M818.16,502.83a.45.45,0,1,0,.63-.1A.45.45,0,0,0,818.16,502.83Z"
          />
          <path
            className="cls-4"
            d="M818.8,514.07a.45.45,0,1,0,.1.63A.45.45,0,0,0,818.8,514.07Z"
          />
          <path
            className="cls-4"
            d="M818.36,525.39a.45.45,0,0,0-.63.11.46.46,0,0,0,.1.63.47.47,0,0,0,.64-.1A.46.46,0,0,0,818.36,525.39Z"
          />
          <path
            className="cls-4"
            d="M817.62,537.35a.49.49,0,1,0-.68.11A.5.5,0,0,0,817.62,537.35Z"
          />
          <path
            className="cls-4"
            d="M815.57,548.08a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.1.42.42,0,1,0-.68-.49Z"
          />
          <circle className="cls-4" cx="814.16" cy="559.53" r="0.45" />
          <path
            className="cls-4"
            d="M811.58,570.37a.48.48,0,1,0,.78.56.49.49,0,0,0-.11-.67A.47.47,0,0,0,811.58,570.37Z"
          />
          <path
            className="cls-4"
            d="M809,581.42a.45.45,0,1,0,.63-.11A.45.45,0,0,0,809,581.42Z"
          />
          <path
            className="cls-4"
            d="M805.93,592.34a.45.45,0,1,0,.63-.11A.45.45,0,0,0,805.93,592.34Z"
          />
          <circle
            className="cls-4"
            cx="802.82"
            cy="603.39"
            r="0.48"
            transform="translate(-155.56 903.58) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M798.56,613.78a.45.45,0,1,0,.63-.11A.45.45,0,0,0,798.56,613.78Z"
          />
          <path
            className="cls-4"
            d="M794.26,624.26a.46.46,0,0,0,.1.64.46.46,0,0,0,.53-.74A.46.46,0,0,0,794.26,624.26Z"
          />
          <path
            className="cls-4"
            d="M789.52,634.56a.48.48,0,1,0,.68-.11A.49.49,0,0,0,789.52,634.56Z"
          />
          <path
            className="cls-4"
            d="M784.41,644.68a.48.48,0,1,0,.68-.11A.49.49,0,0,0,784.41,644.68Z"
          />
          <path
            className="cls-4"
            d="M778.91,654.6a.49.49,0,0,0,.11.67.49.49,0,0,0,.57-.79A.49.49,0,0,0,778.91,654.6Z"
          />
          <path
            className="cls-4"
            d="M773,664.29a.48.48,0,0,0,.11.67.49.49,0,0,0,.67-.11.48.48,0,0,0-.78-.56Z"
          />
          <path
            className="cls-4"
            d="M766.77,673.74a.48.48,0,1,0,.68-.11A.49.49,0,0,0,766.77,673.74Z"
          />
          <path
            className="cls-4"
            d="M760.15,682.94a.49.49,0,1,0,.68-.11A.5.5,0,0,0,760.15,682.94Z"
          />
          <path
            className="cls-4"
            d="M753.18,691.88a.48.48,0,1,0,.78.56.47.47,0,0,0-.11-.67A.49.49,0,0,0,753.18,691.88Z"
          />
          <path
            className="cls-4"
            d="M745.86,700.54a.48.48,0,0,0,.11.67.48.48,0,0,0,.56-.78A.49.49,0,0,0,745.86,700.54Z"
          />
          <path
            className="cls-4"
            d="M738.21,708.91a.49.49,0,0,0,.11.67.48.48,0,1,0-.11-.67Z"
          />
          <path
            className="cls-4"
            d="M730.26,717a.46.46,0,0,0,.11.63.45.45,0,0,0,.63-.11.46.46,0,0,0-.1-.63A.47.47,0,0,0,730.26,717Z"
          />
          <path
            className="cls-4"
            d="M722,724.73a.46.46,0,0,0,.11.63.45.45,0,0,0,.63-.1.46.46,0,0,0-.74-.53Z"
          />
          <path
            className="cls-4"
            d="M713.43,732.17a.42.42,0,1,0,.69.49.42.42,0,1,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M704.55,739.22a.46.46,0,1,0,.74.53.45.45,0,0,0-.11-.63A.46.46,0,0,0,704.55,739.22Z"
          />
          <circle className="cls-4" cx="695.79" cy="746.21" r="0.45" />
          <path
            className="cls-4"
            d="M686,752.31a.46.46,0,0,0,.74.53.46.46,0,0,0-.11-.63A.45.45,0,0,0,686,752.31Z"
          />
          <path
            className="cls-4"
            d="M676.42,758.31a.45.45,0,1,0,.63-.11A.45.45,0,0,0,676.42,758.31Z"
          />
          <path
            className="cls-4"
            d="M666.59,763.94a.42.42,0,0,0,.69.49.43.43,0,0,0-.1-.59A.42.42,0,0,0,666.59,763.94Z"
          />
          <path
            className="cls-4"
            d="M662.15,780.5a.39.39,0,1,0,.64.46.39.39,0,1,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M651.9,785.34a.38.38,0,0,0,.09.54.39.39,0,0,0,.55-.09.39.39,0,0,0-.09-.54A.39.39,0,0,0,651.9,785.34Z"
          />
          <path
            className="cls-4"
            d="M641.5,789.8a.37.37,0,0,0,.08.51.36.36,0,1,0-.08-.51Z"
          />
          <path
            className="cls-4"
            d="M630.86,793.82a.42.42,0,0,0,.1.59.42.42,0,1,0,.49-.68A.42.42,0,0,0,630.86,793.82Z"
          />
          <path
            className="cls-4"
            d="M620.15,797.5a.39.39,0,0,0,.64.46.39.39,0,1,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M609.84,800.66a.39.39,0,1,0,.09.55A.4.4,0,0,0,609.84,800.66Z"
          />
          <path
            className="cls-4"
            d="M598.29,803.58a.42.42,0,0,0,.69.49.42.42,0,0,0-.1-.59A.43.43,0,0,0,598.29,803.58Z"
          />
          <path
            className="cls-4"
            d="M587.25,806a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,587.25,806Z"
          />
          <path
            className="cls-4"
            d="M576.09,808.05a.38.38,0,0,0,.09.54.39.39,0,0,0,.55-.09.39.39,0,0,0-.09-.54A.39.39,0,0,0,576.09,808.05Z"
          />
          <path
            className="cls-4"
            d="M564.87,809.65a.39.39,0,1,0,.63.45.39.39,0,1,0-.63-.45Z"
          />
          <path
            className="cls-4"
            d="M553.59,810.82a.39.39,0,0,0,.09.55.38.38,0,0,0,.54-.09.4.4,0,0,0-.08-.55A.41.41,0,0,0,553.59,810.82Z"
          />
          <path
            className="cls-4"
            d="M542.28,811.58a.39.39,0,1,0,.54-.09A.38.38,0,0,0,542.28,811.58Z"
          />
          <path
            className="cls-4"
            d="M531.49,811.82a.39.39,0,0,0-.55.09.39.39,0,0,0,.64.46A.39.39,0,0,0,531.49,811.82Z"
          />
          <path
            className="cls-4"
            d="M519.72,812.34a.36.36,0,0,0,.5-.08.36.36,0,1,0-.59-.42A.36.36,0,0,0,519.72,812.34Z"
          />
          <path
            className="cls-4"
            d="M508.28,811.3a.39.39,0,1,0,.55-.09A.41.41,0,0,0,508.28,811.3Z"
          />
          <path
            className="cls-4"
            d="M497,810.36a.41.41,0,0,0,.09.55.39.39,0,0,0,.46-.64A.41.41,0,0,0,497,810.36Z"
          />
          <path
            className="cls-4"
            d="M485.7,809a.43.43,0,0,0,.1.59.42.42,0,1,0-.1-.59Z"
          />
          <path
            className="cls-4"
            d="M474.51,807.2a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.09.42.42,0,0,0-.68-.5Z"
          />
          <path
            className="cls-4"
            d="M463.41,805a.39.39,0,0,0,.64.46.39.39,0,1,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M452.38,802.41a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,452.38,802.41Z"
          />
          <path
            className="cls-4"
            d="M442,799.3a.39.39,0,0,0-.55.09.38.38,0,0,0,.09.54.39.39,0,0,0,.55-.09A.39.39,0,0,0,442,799.3Z"
          />
          <circle className="cls-4" cx="430.96" cy="796.19" r="0.39" />
          <path
            className="cls-4"
            d="M420,792.13a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54A.39.39,0,0,0,420,792.13Z"
          />
          <path
            className="cls-4"
            d="M410,787.81a.39.39,0,0,0-.55.09.39.39,0,0,0,.64.46A.39.39,0,0,0,410,787.81Z"
          />
          <path
            className="cls-4"
            d="M399.1,783.28a.39.39,0,0,0,.09.55.39.39,0,0,0,.46-.64A.39.39,0,0,0,399.1,783.28Z"
          />
          <path
            className="cls-4"
            d="M388.9,778.26a.43.43,0,0,0,.1.59.42.42,0,0,0,.59-.1.42.42,0,0,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M379,772.9a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,379,772.9Z"
          />
          <path
            className="cls-4"
            d="M369.17,767.15a.39.39,0,0,0,.64.46.39.39,0,0,0-.09-.55A.41.41,0,0,0,369.17,767.15Z"
          />
          <path
            className="cls-4"
            d="M360.17,761a.39.39,0,1,0,.09.55A.39.39,0,0,0,360.17,761Z"
          />
          <path
            className="cls-4"
            d="M350.4,755.12a.39.39,0,1,0,.45-.63.39.39,0,1,0-.45.63Z"
          />
          <path
            className="cls-4"
            d="M341.33,748.32a.39.39,0,1,0-.09-.55A.41.41,0,0,0,341.33,748.32Z"
          />
          <path
            className="cls-4"
            d="M332.44,740.63a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,332.44,740.63Z"
          />
          <path
            className="cls-4"
            d="M323.9,733.17a.39.39,0,0,0,.09.54.39.39,0,1,0-.09-.54Z"
          />
          <path
            className="cls-4"
            d="M315.73,726a.42.42,0,0,0,.49-.69.43.43,0,0,0-.59.1A.42.42,0,0,0,315.73,726Z"
          />
          <path
            className="cls-4"
            d="M307.7,717.31a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54A.39.39,0,0,0,307.7,717.31Z"
          />
          <path
            className="cls-4"
            d="M300.7,709.39a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M292.74,700.28a.4.4,0,0,0,.08.55.41.41,0,0,0,.55-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,292.74,700.28Z"
          />
          <path
            className="cls-4"
            d="M286.35,691.8a.37.37,0,0,0-.08-.51.38.38,0,0,0-.51.09.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M279.72,682.64a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M273.38,673.2a.36.36,0,0,0-.08-.5.37.37,0,0,0-.51.08.38.38,0,0,0,.09.51A.36.36,0,0,0,273.38,673.2Z"
          />
          <path
            className="cls-4"
            d="M267.43,663.55a.36.36,0,0,0-.08-.5.37.37,0,1,0,.08.5Z"
          />
          <path
            className="cls-4"
            d="M261.2,653.23a.43.43,0,1,0,.59-.09A.42.42,0,0,0,261.2,653.23Z"
          />
          <path
            className="cls-4"
            d="M256.65,643.64a.39.39,0,0,0-.09-.54.4.4,0,0,0-.55.08.41.41,0,0,0,.09.55A.39.39,0,0,0,256.65,643.64Z"
          />
          <path
            className="cls-4"
            d="M251.28,633.45a.36.36,0,1,0-.08-.5A.35.35,0,0,0,251.28,633.45Z"
          />
          <path
            className="cls-4"
            d="M247.27,622.41a.39.39,0,0,0-.54.09.39.39,0,0,0,.09.55.38.38,0,0,0,.54-.09A.39.39,0,0,0,247.27,622.41Z"
          />
          <path
            className="cls-4"
            d="M242.76,612.46a.39.39,0,0,0,.55-.09.39.39,0,0,0-.09-.54.39.39,0,1,0-.46.63Z"
          />
          <path
            className="cls-4"
            d="M239.63,601.62a.37.37,0,0,0-.09-.5.35.35,0,0,0-.5.08.36.36,0,1,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M235.85,590.87a.39.39,0,0,0,.55-.09.39.39,0,0,0-.64-.46A.39.39,0,0,0,235.85,590.87Z"
          />
          <path
            className="cls-4"
            d="M233.55,579.81a.39.39,0,0,0-.64-.46.4.4,0,0,0,.1.55A.39.39,0,0,0,233.55,579.81Z"
          />
          <path
            className="cls-4"
            d="M231.12,568.73a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,231.12,568.73Z"
          />
          <path
            className="cls-4"
            d="M229.08,557.56a.36.36,0,1,0-.59-.42.36.36,0,0,0,.08.5A.37.37,0,0,0,229.08,557.56Z"
          />
          <path
            className="cls-4"
            d="M227.5,546.35a.39.39,0,1,0-.54.09A.38.38,0,0,0,227.5,546.35Z"
          />
          <path
            className="cls-4"
            d="M226.33,535.08a.41.41,0,0,0-.09-.55.39.39,0,0,0-.46.64A.41.41,0,0,0,226.33,535.08Z"
          />
          <path
            className="cls-4"
            d="M225.55,523.75a.38.38,0,0,0-.09-.51.36.36,0,0,0-.42.59A.37.37,0,0,0,225.55,523.75Z"
          />
          <path
            className="cls-4"
            d="M224.71,512.5a.36.36,0,1,0-.08-.51A.37.37,0,0,0,224.71,512.5Z"
          />
          <path
            className="cls-4"
            d="M224.78,501.18a.4.4,0,0,0,.55-.08.39.39,0,0,0-.64-.46A.39.39,0,0,0,224.78,501.18Z"
          />
          <path
            className="cls-4"
            d="M225.3,489.86a.39.39,0,0,0,.55-.09.39.39,0,0,0-.64-.46A.39.39,0,0,0,225.3,489.86Z"
          />
          <path
            className="cls-4"
            d="M226.76,478.45a.36.36,0,0,0-.08-.5.36.36,0,1,0-.42.59A.36.36,0,0,0,226.76,478.45Z"
          />
          <path
            className="cls-4"
            d="M228.15,467.22a.41.41,0,0,0-.09-.55.39.39,0,1,0-.46.64A.41.41,0,0,0,228.15,467.22Z"
          />
          <path
            className="cls-4"
            d="M230,456a.42.42,0,1,0-.59.09A.42.42,0,0,0,230,456Z"
          />
          <path
            className="cls-4"
            d="M232.1,444.88a.35.35,0,0,0-.08-.5.37.37,0,0,0-.51.08.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M234.74,433.86a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,234.74,433.86Z"
          />
          <path
            className="cls-4"
            d="M237.76,422.94a.39.39,0,0,0-.09-.55.4.4,0,0,0-.55.09.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M241.17,412.11a.36.36,0,0,0-.59-.42.36.36,0,1,0,.59.42Z"
          />
          <circle className="cls-4" cx="244.7" cy="401.23" r="0.39" />
          <path
            className="cls-4"
            d="M249.25,390.94a.38.38,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,249.25,390.94Z"
          />
          <path
            className="cls-4"
            d="M253.87,380.59a.39.39,0,1,0-.55.09A.41.41,0,0,0,253.87,380.59Z"
          />
          <path
            className="cls-4"
            d="M258.89,370.43a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.1.42.42,0,0,0,.1.59A.42.42,0,0,0,258.89,370.43Z"
          />
          <path
            className="cls-4"
            d="M264.25,360.43a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,264.25,360.43Z"
          />
          <path
            className="cls-4"
            d="M270,350.66a.39.39,0,1,0-.55.09A.39.39,0,0,0,270,350.66Z"
          />
          <path
            className="cls-4"
            d="M276.11,341.11a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M282.57,331.8a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M289.38,322.73a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M296.52,313.92a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.38.38,0,0,0,.09.54A.39.39,0,0,0,296.52,313.92Z"
          />
          <path
            className="cls-4"
            d="M304,305.39a.39.39,0,0,0-.09-.55.41.41,0,0,0-.55.09.4.4,0,0,0,.1.55A.38.38,0,0,0,304,305.39Z"
          />
          <path
            className="cls-4"
            d="M311.78,297.16a.42.42,0,0,0-.09-.59.43.43,0,0,0-.59.1.42.42,0,1,0,.68.49Z"
          />
          <path
            className="cls-4"
            d="M319.84,289.19a.39.39,0,0,0-.09-.55.41.41,0,0,0-.55.09.4.4,0,0,0,.1.55A.38.38,0,0,0,319.84,289.19Z"
          />
          <path
            className="cls-4"
            d="M328.21,281.55a.39.39,0,1,0-.63-.46.39.39,0,0,0,.09.55A.39.39,0,0,0,328.21,281.55Z"
          />
          <path
            className="cls-4"
            d="M336.87,274.22a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,336.87,274.22Z"
          />
          <path
            className="cls-4"
            d="M345.79,267.23a.39.39,0,1,0-.64-.46.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M355,260.57a.41.41,0,0,0-.09-.55.4.4,0,0,0-.55.09.39.39,0,0,0,.09.55A.41.41,0,0,0,355,260.57Z"
          />
          <path
            className="cls-4"
            d="M364.39,254.26a.39.39,0,0,0-.64-.46.39.39,0,0,0,.09.55A.41.41,0,0,0,364.39,254.26Z"
          />
          <path
            className="cls-4"
            d="M374,248.3a.39.39,0,1,0-.54.09A.38.38,0,0,0,374,248.3Z"
          />
          <path
            className="cls-4"
            d="M383.92,242.73a.42.42,0,1,0-.59.1A.42.42,0,0,0,383.92,242.73Z"
          />
          <path
            className="cls-4"
            d="M394,237.5a.39.39,0,0,0-.09-.55.39.39,0,0,0-.54.09.39.39,0,0,0,.09.55A.38.38,0,0,0,394,237.5Z"
          />
          <path
            className="cls-4"
            d="M404.22,232.66a.39.39,0,0,0-.09-.54.39.39,0,1,0,.09.54Z"
          />
          <path
            className="cls-4"
            d="M414.65,228.21a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.38.38,0,0,0,.09.54A.39.39,0,0,0,414.65,228.21Z"
          />
          <path
            className="cls-4"
            d="M425.21,224.14a.36.36,0,1,0-.51.08A.37.37,0,0,0,425.21,224.14Z"
          />
          <path
            className="cls-4"
            d="M436,220.5a.39.39,0,0,0-.09-.55.39.39,0,0,0-.54.09.39.39,0,0,0,.09.55A.38.38,0,0,0,436,220.5Z"
          />
          <path
            className="cls-4"
            d="M446.85,217.26a.42.42,0,0,0-.69-.49.43.43,0,0,0,.1.59A.42.42,0,0,0,446.85,217.26Z"
          />
          <path
            className="cls-4"
            d="M457.8,214.4a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,457.8,214.4Z"
          />
          <path
            className="cls-4"
            d="M468.87,212a.39.39,0,0,0-.09-.55.39.39,0,0,0-.54.09.39.39,0,0,0,.09.55A.38.38,0,0,0,468.87,212Z"
          />
          <path
            className="cls-4"
            d="M480,210a.39.39,0,0,0-.09-.54.39.39,0,0,0-.55.09.39.39,0,0,0,.09.54A.39.39,0,0,0,480,210Z"
          />
          <path
            className="cls-4"
            d="M491.25,208.35a.39.39,0,1,0-.54.09A.38.38,0,0,0,491.25,208.35Z"
          />
          <path
            className="cls-4"
            d="M502.55,207.19a.41.41,0,0,0-.09-.58.42.42,0,0,0-.59.09.43.43,0,0,0,.09.59A.42.42,0,0,0,502.55,207.19Z"
          />
          <circle className="cls-4" cx="513.52" cy="206.19" r="0.39" />
          <path
            className="cls-4"
            d="M525.17,206.09a.39.39,0,0,0-.09-.55.39.39,0,0,0-.54.09.39.39,0,0,0,.09.55A.38.38,0,0,0,525.17,206.09Z"
          />
          <path
            className="cls-4"
            d="M536,206.3a.42.42,0,0,0,.49-.69.43.43,0,0,0-.59.1A.42.42,0,0,0,536,206.3Z"
          />
          <path
            className="cls-4"
            d="M547.84,206.7a.39.39,0,1,0-.64-.46.39.39,0,0,0,.09.55A.41.41,0,0,0,547.84,206.7Z"
          />
          <path
            className="cls-4"
            d="M558.59,207.73a.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55.38.38,0,0,0-.54.09A.39.39,0,0,0,558.59,207.73Z"
          />
          <path
            className="cls-4"
            d="M569.84,209.09a.41.41,0,0,0,.55-.09.39.39,0,1,0-.64-.46A.41.41,0,0,0,569.84,209.09Z"
          />
          <path
            className="cls-4"
            d="M580.92,210.3a.43.43,0,0,0,.1.59.42.42,0,1,0-.1-.59Z"
          />
          <path
            className="cls-4"
            d="M592.05,212.5a.42.42,0,1,0,.68.49.41.41,0,0,0-.09-.58A.42.42,0,0,0,592.05,212.5Z"
          />
          <path
            className="cls-4"
            d="M603.77,215.61a.43.43,0,0,0-.69-.5.43.43,0,0,0,.69.5Z"
          />
          <path
            className="cls-4"
            d="M614.69,218.63a.42.42,0,1,0-.59.1A.43.43,0,0,0,614.69,218.63Z"
          />
          <path
            className="cls-4"
            d="M625.5,222.06a.42.42,0,1,0-.59.1A.43.43,0,0,0,625.5,222.06Z"
          />
          <path
            className="cls-4"
            d="M636.17,225.89a.42.42,0,1,0-.59.1A.43.43,0,0,0,636.17,225.89Z"
          />
          <path
            className="cls-4"
            d="M646,229.64a.39.39,0,0,0,.09.55.39.39,0,0,0,.45-.63A.38.38,0,0,0,646,229.64Z"
          />
          <path
            className="cls-4"
            d="M657,234.72a.39.39,0,1,0-.64-.46.4.4,0,0,0,.09.55A.39.39,0,0,0,657,234.72Z"
          />
          <path
            className="cls-4"
            d="M666.53,239.25a.42.42,0,1,0,.59-.1A.42.42,0,0,0,666.53,239.25Z"
          />
          <path
            className="cls-4"
            d="M676.61,245.21a.42.42,0,0,0,.59-.09.43.43,0,0,0-.1-.59.42.42,0,0,0-.59.1A.41.41,0,0,0,676.61,245.21Z"
          />
          <path
            className="cls-4"
            d="M687,250.87a.43.43,0,1,0-.59.09A.42.42,0,0,0,687,250.87Z"
          />
          <path
            className="cls-4"
            d="M695.93,257.07a.42.42,0,0,0,.59-.09.43.43,0,0,0-.1-.59.42.42,0,0,0-.59.09A.43.43,0,0,0,695.93,257.07Z"
          />
          <path
            className="cls-4"
            d="M705.15,263a.42.42,0,1,0,.68.49.42.42,0,1,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M714.22,269.75a.42.42,0,1,0,.68.49.42.42,0,0,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M723,276.89a.43.43,0,1,0,.59-.09A.42.42,0,0,0,723,276.89Z"
          />
          <path
            className="cls-4"
            d="M732.24,284.85a.42.42,0,1,0-.59.1A.43.43,0,0,0,732.24,284.85Z"
          />
          <path
            className="cls-4"
            d="M739.8,292.14a.42.42,0,1,0,.59-.1A.42.42,0,0,0,739.8,292.14Z"
          />
          <path
            className="cls-4"
            d="M747.78,300.24a.39.39,0,1,0,.54-.09A.38.38,0,0,0,747.78,300.24Z"
          />
          <path
            className="cls-4"
            d="M755.42,308.61a.39.39,0,1,0,.55-.09A.39.39,0,0,0,755.42,308.61Z"
          />
          <path
            className="cls-4"
            d="M762.7,317.23a.45.45,0,1,0,.63-.11A.45.45,0,0,0,762.7,317.23Z"
          />
          <circle className="cls-4" cx="770.06" cy="326.41" r="0.45" />
          <path
            className="cls-4"
            d="M776.38,335.34a.42.42,0,1,0,.68.49.42.42,0,0,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M782.69,344.76a.42.42,0,1,0,.58-.1A.42.42,0,0,0,782.69,344.76Z"
          />
          <path
            className="cls-4"
            d="M788.64,354.41a.42.42,0,0,0,.1.59.42.42,0,1,0-.1-.59Z"
          />
          <path
            className="cls-4"
            d="M794.25,364.29a.39.39,0,0,0,.64.46.39.39,0,0,0-.09-.55A.41.41,0,0,0,794.25,364.29Z"
          />
          <path
            className="cls-4"
            d="M799.44,374.34a.42.42,0,0,0,.69.49.42.42,0,0,0-.1-.59A.43.43,0,0,0,799.44,374.34Z"
          />
          <path
            className="cls-4"
            d="M804.87,384.5a.42.42,0,1,0-.49.68.42.42,0,0,0,.59-.09A.43.43,0,0,0,804.87,384.5Z"
          />
          <path
            className="cls-4"
            d="M808.84,395.59a.41.41,0,0,0,.55-.09.39.39,0,1,0-.64-.46A.41.41,0,0,0,808.84,395.59Z"
          />
          <path
            className="cls-4"
            d="M812.79,405.61a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.1.42.42,0,1,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M816.47,416.36a.39.39,0,1,0,.63.45.39.39,0,1,0-.63-.45Z"
          />
          <path
            className="cls-4"
            d="M819.7,427.2a.42.42,0,1,0,.58-.1A.42.42,0,0,0,819.7,427.2Z"
          />
          <path
            className="cls-4"
            d="M822.54,438.18a.42.42,0,0,0,.69.49.43.43,0,0,0-.1-.59A.42.42,0,0,0,822.54,438.18Z"
          />
          <path
            className="cls-4"
            d="M825,449.27a.39.39,0,0,0,.63.45.38.38,0,0,0-.08-.54A.39.39,0,0,0,825,449.27Z"
          />
          <path
            className="cls-4"
            d="M827,460.41a.42.42,0,0,0,.69.49.43.43,0,0,0-.1-.59A.42.42,0,0,0,827,460.41Z"
          />
          <path
            className="cls-4"
            d="M828.61,471.65a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54A.39.39,0,0,0,828.61,471.65Z"
          />
          <path
            className="cls-4"
            d="M829.77,482.9a.42.42,0,1,0,.59-.09A.43.43,0,0,0,829.77,482.9Z"
          />
          <path
            className="cls-4"
            d="M830.52,494.22a.42.42,0,1,0,.59-.1A.43.43,0,0,0,830.52,494.22Z"
          />
          <path
            className="cls-4"
            d="M830.86,505.55a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.1.42.42,0,0,0-.1-.59A.42.42,0,0,0,830.86,505.55Z"
          />
          <path
            className="cls-4"
            d="M830.76,516.89a.42.42,0,1,0,.59-.1A.42.42,0,0,0,830.76,516.89Z"
          />
          <path
            className="cls-4"
            d="M830.82,528.14a.39.39,0,0,0-.55.09.39.39,0,1,0,.55-.09Z"
          />
          <path
            className="cls-4"
            d="M829.4,540.1a.43.43,0,0,0,.5-.69.43.43,0,0,0-.59.1A.42.42,0,0,0,829.4,540.1Z"
          />
          <path
            className="cls-4"
            d="M828,550.77a.41.41,0,0,0,.09.58.42.42,0,1,0-.09-.58Z"
          />
          <path
            className="cls-4"
            d="M826.19,562a.39.39,0,0,0,.09.55.39.39,0,0,0,.46-.64A.39.39,0,0,0,826.19,562Z"
          />
          <path
            className="cls-4"
            d="M824,573.09a.42.42,0,1,0,.59-.1A.42.42,0,0,0,824,573.09Z"
          />
          <path
            className="cls-4"
            d="M821.35,584.12a.42.42,0,1,0,.69.49.42.42,0,1,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M818.33,595a.43.43,0,0,0,.1.59.42.42,0,0,0,.59-.09.43.43,0,0,0-.1-.59A.42.42,0,0,0,818.33,595Z"
          />
          <path
            className="cls-4"
            d="M814.88,605.83a.45.45,0,1,0,.63-.1A.45.45,0,0,0,814.88,605.83Z"
          />
          <path
            className="cls-4"
            d="M811.05,616.5a.45.45,0,1,0,.63-.1A.45.45,0,0,0,811.05,616.5Z"
          />
          <path
            className="cls-4"
            d="M806.87,627.06a.39.39,0,1,0,.54-.09A.38.38,0,0,0,806.87,627.06Z"
          />
          <path
            className="cls-4"
            d="M802.25,637.41a.39.39,0,0,0,.09.55.39.39,0,1,0-.09-.55Z"
          />
          <path
            className="cls-4"
            d="M797.22,647.57a.42.42,0,1,0,.59-.1A.43.43,0,0,0,797.22,647.57Z"
          />
          <path
            className="cls-4"
            d="M791.84,657.55a.42.42,0,1,0,.69.49.42.42,0,0,0-.1-.59A.43.43,0,0,0,791.84,657.55Z"
          />
          <path
            className="cls-4"
            d="M786.1,667.32a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.1.42.42,0,1,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M780,676.87a.42.42,0,1,0,.58-.1A.42.42,0,0,0,780,676.87Z"
          />
          <path
            className="cls-4"
            d="M773.52,686.19a.42.42,0,0,0,.69.49.43.43,0,0,0-.1-.59A.42.42,0,0,0,773.52,686.19Z"
          />
          <path
            className="cls-4"
            d="M766.72,695.25a.42.42,0,1,0,.59-.09A.42.42,0,0,0,766.72,695.25Z"
          />
          <path
            className="cls-4"
            d="M759.58,704.06a.42.42,0,0,0,.09.59.43.43,0,0,0,.59-.1.42.42,0,1,0-.68-.49Z"
          />
          <path
            className="cls-4"
            d="M752.11,712.59a.43.43,0,0,0,.1.59.42.42,0,0,0,.59-.1.42.42,0,0,0-.69-.49Z"
          />
          <path
            className="cls-4"
            d="M744.33,720.84a.42.42,0,1,0,.59-.1A.43.43,0,0,0,744.33,720.84Z"
          />
          <path
            className="cls-4"
            d="M736.28,728.81a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,736.28,728.81Z"
          />
          <path
            className="cls-4"
            d="M727.9,736.45a.39.39,0,1,0,.64.46.4.4,0,0,0-.09-.55A.41.41,0,0,0,727.9,736.45Z"
          />
          <path
            className="cls-4"
            d="M719.22,743.76a.42.42,0,1,0,.69.49.42.42,0,0,0-.1-.59A.43.43,0,0,0,719.22,743.76Z"
          />
          <path
            className="cls-4"
            d="M710.3,750.76a.42.42,0,1,0,.59-.1A.42.42,0,0,0,710.3,750.76Z"
          />
          <path
            className="cls-4"
            d="M701.13,757.41a.43.43,0,0,0,.09.59.42.42,0,0,0,.5-.68A.43.43,0,0,0,701.13,757.41Z"
          />
          <path
            className="cls-4"
            d="M691.73,763.74a.39.39,0,1,0,.64.46.4.4,0,0,0-.09-.55A.41.41,0,0,0,691.73,763.74Z"
          />
          <path
            className="cls-4"
            d="M682.06,769.68a.42.42,0,0,0,.1.59.42.42,0,1,0-.1-.59Z"
          />
          <path
            className="cls-4"
            d="M672.2,775.27a.41.41,0,0,0,.09.58.42.42,0,1,0,.49-.68A.41.41,0,0,0,672.2,775.27Z"
          />
          <path
            className="cls-4"
            d="M667.76,791.82a.4.4,0,0,0,.08.55.41.41,0,0,0,.55-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,667.76,791.82Z"
          />
          <path
            className="cls-4"
            d="M657.53,796.68a.36.36,0,1,0,.59.42.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M647.11,801.16a.36.36,0,0,0,.08.5.37.37,0,0,0,.51-.08.38.38,0,0,0-.08-.51A.37.37,0,0,0,647.11,801.16Z"
          />
          <path
            className="cls-4"
            d="M636.56,805.27a.33.33,0,0,0,.08.46.33.33,0,0,0,.46-.07.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M625.83,809a.36.36,0,1,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,625.83,809Z"
          />
          <path
            className="cls-4"
            d="M615.49,812.21a.36.36,0,0,0-.5.08.37.37,0,0,0,.08.51.38.38,0,0,0,.51-.09A.36.36,0,0,0,615.49,812.21Z"
          />
          <path
            className="cls-4"
            d="M604,815.23a.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08.38.38,0,0,0-.09-.51A.36.36,0,0,0,604,815.23Z"
          />
          <path
            className="cls-4"
            d="M593,817.78a.35.35,0,0,0,.08.47.33.33,0,0,0,.46-.08.33.33,0,0,0-.08-.46A.33.33,0,0,0,593,817.78Z"
          />
          <path
            className="cls-4"
            d="M581.86,819.91a.36.36,0,1,0,.5-.08A.35.35,0,0,0,581.86,819.91Z"
          />
          <path
            className="cls-4"
            d="M571.16,821.57a.37.37,0,0,0-.51.08.36.36,0,1,0,.51-.08Z"
          />
          <path
            className="cls-4"
            d="M559.42,823a.33.33,0,0,0,.08.46.33.33,0,0,0,.38-.54A.33.33,0,0,0,559.42,823Z"
          />
          <path
            className="cls-4"
            d="M548.6,823.84a.35.35,0,0,0-.5.08.37.37,0,0,0,.08.51.36.36,0,0,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M537.27,824.37a.36.36,0,1,0-.42.58.37.37,0,0,0,.51-.08A.36.36,0,0,0,537.27,824.37Z"
          />
          <path
            className="cls-4"
            d="M525.43,824.57a.36.36,0,1,0,.51-.08A.37.37,0,0,0,525.43,824.57Z"
          />
          <path
            className="cls-4"
            d="M514.1,824.28a.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M503.37,824a.36.36,0,1,0-.59-.42.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M491.52,822.5a.35.35,0,0,0,.08.47.33.33,0,0,0,.46-.08.32.32,0,0,0-.07-.46A.34.34,0,0,0,491.52,822.5Z"
          />
          <path
            className="cls-4"
            d="M480.29,821a.33.33,0,0,0,.53.39.33.33,0,1,0-.53-.39Z"
          />
          <path
            className="cls-4"
            d="M469.09,819.08a.36.36,0,1,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,469.09,819.08Z"
          />
          <path
            className="cls-4"
            d="M458.49,816.69a.36.36,0,1,0,.08.5A.35.35,0,0,0,458.49,816.69Z"
          />
          <path
            className="cls-4"
            d="M447,814.07a.36.36,0,1,0,.58.42.36.36,0,1,0-.58-.42Z"
          />
          <path
            className="cls-4"
            d="M436.66,811.39a.36.36,0,1,0-.51.08A.37.37,0,0,0,436.66,811.39Z"
          />
          <path
            className="cls-4"
            d="M425.79,807.4a.37.37,0,0,0-.43.59.38.38,0,0,0,.51-.08A.37.37,0,0,0,425.79,807.4Z"
          />
          <path
            className="cls-4"
            d="M415.13,803.53a.35.35,0,0,0-.5.08.37.37,0,0,0,.08.51.36.36,0,0,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M404.62,799.28a.35.35,0,0,0-.5.08.37.37,0,0,0,.08.51.36.36,0,0,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M393.76,794.74a.37.37,0,0,0,.09.5.35.35,0,0,0,.5-.08.36.36,0,1,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M384.11,789.63a.39.39,0,0,0-.46.64.39.39,0,0,0,.55-.09A.41.41,0,0,0,384.11,789.63Z"
          />
          <path
            className="cls-4"
            d="M373.62,784.4a.33.33,0,0,0,.54.39.33.33,0,0,0-.08-.46A.32.32,0,0,0,373.62,784.4Z"
          />
          <path
            className="cls-4"
            d="M363.83,778.69a.33.33,0,0,0,.53.39.33.33,0,1,0-.53-.39Z"
          />
          <path
            className="cls-4"
            d="M354.24,772.63a.33.33,0,0,0,.08.46.32.32,0,0,0,.46-.07.33.33,0,0,0-.07-.46A.34.34,0,0,0,354.24,772.63Z"
          />
          <path
            className="cls-4"
            d="M345.42,766.62a.34.34,0,0,0-.07-.47.35.35,0,0,0-.47.08.33.33,0,0,0,.08.46A.32.32,0,0,0,345.42,766.62Z"
          />
          <path
            className="cls-4"
            d="M336.3,759.89a.33.33,0,1,0-.54-.39.33.33,0,0,0,.54.39Z"
          />
          <path
            className="cls-4"
            d="M327.43,752.83a.33.33,0,0,0-.08-.46.33.33,0,0,0-.39.54A.35.35,0,0,0,327.43,752.83Z"
          />
          <path
            className="cls-4"
            d="M318.73,745a.33.33,0,1,0,.08.46A.33.33,0,0,0,318.73,745Z"
          />
          <path
            className="cls-4"
            d="M310.39,737.33a.34.34,0,0,0-.47.07.35.35,0,0,0,.08.47.33.33,0,0,0,.46-.08A.32.32,0,0,0,310.39,737.33Z"
          />
          <path
            className="cls-4"
            d="M302.42,729.84a.37.37,0,0,0-.08-.51.38.38,0,0,0-.51.09.36.36,0,0,0,.09.5A.35.35,0,0,0,302.42,729.84Z"
          />
          <path
            className="cls-4"
            d="M294.06,721.17a.36.36,0,0,0,.08.5.37.37,0,0,0,.51-.08.38.38,0,0,0-.08-.51A.37.37,0,0,0,294.06,721.17Z"
          />
          <path
            className="cls-4"
            d="M286.59,712.64a.35.35,0,0,0,.08.5.36.36,0,1,0,.42-.58A.35.35,0,0,0,286.59,712.64Z"
          />
          <path
            className="cls-4"
            d="M280,704.26a.33.33,0,1,0-.54-.39.33.33,0,0,0,.08.46A.33.33,0,0,0,280,704.26Z"
          />
          <path
            className="cls-4"
            d="M273.15,695.22a.35.35,0,0,0-.08-.47.33.33,0,0,0-.39.54A.34.34,0,0,0,273.15,695.22Z"
          />
          <path
            className="cls-4"
            d="M266.58,685.45a.37.37,0,0,0-.43.59.38.38,0,0,0,.51-.09A.36.36,0,0,0,266.58,685.45Z"
          />
          <path
            className="cls-4"
            d="M260.38,676a.33.33,0,0,0-.46.08.33.33,0,0,0,.54.39A.35.35,0,0,0,260.38,676Z"
          />
          <path
            className="cls-4"
            d="M254.63,666.7a.34.34,0,0,0-.08-.46.33.33,0,1,0,.08.46Z"
          />
          <path
            className="cls-4"
            d="M249.15,656.78a.33.33,0,1,0-.54-.39.33.33,0,0,0,.08.46A.32.32,0,0,0,249.15,656.78Z"
          />
          <path
            className="cls-4"
            d="M244,646.64a.3.3,0,0,0-.49-.35.3.3,0,0,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M239.3,636.38a.36.36,0,0,0-.58-.42.37.37,0,0,0,.08.51A.36.36,0,0,0,239.3,636.38Z"
          />
          <path
            className="cls-4"
            d="M234.84,625.42a.36.36,0,0,0-.42.59.36.36,0,1,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M230.36,614.91a.35.35,0,0,0,.08.47.33.33,0,0,0,.39-.54A.34.34,0,0,0,230.36,614.91Z"
          />
          <path
            className="cls-4"
            d="M227.28,604.56a.33.33,0,1,0-.53-.39.32.32,0,0,0,.07.46A.33.33,0,0,0,227.28,604.56Z"
          />
          <path
            className="cls-4"
            d="M223.57,593.79a.37.37,0,1,0,.43-.59.37.37,0,0,0-.43.59Z"
          />
          <path
            className="cls-4"
            d="M221.22,582.71a.33.33,0,1,0-.46.08A.34.34,0,0,0,221.22,582.71Z"
          />
          <path
            className="cls-4"
            d="M218.78,571.64a.33.33,0,0,0-.54-.39.33.33,0,1,0,.54.39Z"
          />
          <path
            className="cls-4"
            d="M216.76,560.51a.37.37,0,0,0-.08-.51.37.37,0,0,0-.43.59A.37.37,0,0,0,216.76,560.51Z"
          />
          <path
            className="cls-4"
            d="M215.09,549.27a.33.33,0,1,0-.53-.39.33.33,0,1,0,.53.39Z"
          />
          <path
            className="cls-4"
            d="M213.86,538a.33.33,0,1,0-.46.08A.33.33,0,0,0,213.86,538Z"
          />
          <path
            className="cls-4"
            d="M213.05,526.71a.35.35,0,0,0-.08-.5.37.37,0,0,0-.51.08.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M212.6,515.36a.33.33,0,0,0-.07-.46.35.35,0,0,0-.47.08.33.33,0,0,0,.08.46A.33.33,0,0,0,212.6,515.36Z"
          />
          <path
            className="cls-4"
            d="M212.12,504.1a.32.32,0,0,0,.46-.07.34.34,0,0,0-.07-.47.35.35,0,0,0-.47.08A.33.33,0,0,0,212.12,504.1Z"
          />
          <path
            className="cls-4"
            d="M212.49,492.8a.37.37,0,0,0,.51-.09.36.36,0,0,0-.59-.42A.37.37,0,0,0,212.49,492.8Z"
          />
          <path
            className="cls-4"
            d="M213.29,481.49a.36.36,0,0,0,.42-.59.36.36,0,1,0-.42.59Z"
          />
          <path
            className="cls-4"
            d="M214.87,469.68a.3.3,0,0,0-.42.06.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.06A.29.29,0,0,0,214.87,469.68Z"
          />
          <path
            className="cls-4"
            d="M216.57,458.89a.32.32,0,0,0-.07-.46.34.34,0,0,0-.47.07.35.35,0,0,0,.08.47A.33.33,0,0,0,216.57,458.89Z"
          />
          <path
            className="cls-4"
            d="M218.58,447.73a.33.33,0,1,0-.46.08A.33.33,0,0,0,218.58,447.73Z"
          />
          <path
            className="cls-4"
            d="M221,436.65a.33.33,0,0,0-.08-.46.33.33,0,0,0-.39.54A.34.34,0,0,0,221,436.65Z"
          />
          <path
            className="cls-4"
            d="M223.81,425.69a.37.37,0,1,0-.59-.43.38.38,0,0,0,.09.51A.36.36,0,0,0,223.81,425.69Z"
          />
          <path
            className="cls-4"
            d="M227,414.81a.37.37,0,1,0-.51.08A.37.37,0,0,0,227,414.81Z"
          />
          <path
            className="cls-4"
            d="M230.56,404a.33.33,0,1,0-.46.08A.32.32,0,0,0,230.56,404Z"
          />
          <path
            className="cls-4"
            d="M234.56,393.43a.37.37,0,0,0-.09-.51.36.36,0,0,0-.5.09.35.35,0,0,0,.08.5A.37.37,0,0,0,234.56,393.43Z"
          />
          <path
            className="cls-4"
            d="M238.9,383a.37.37,0,0,0-.08-.51.36.36,0,0,0-.5.09.35.35,0,0,0,.08.5A.35.35,0,0,0,238.9,383Z"
          />
          <path
            className="cls-4"
            d="M243.62,372.65a.37.37,0,0,0-.08-.51.38.38,0,0,0-.51.09.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M248.68,362.5a.33.33,0,0,0-.07-.46.33.33,0,1,0-.39.53A.32.32,0,0,0,248.68,362.5Z"
          />
          <path
            className="cls-4"
            d="M254.13,352.56a.35.35,0,0,0-.08-.47.33.33,0,0,0-.39.54A.34.34,0,0,0,254.13,352.56Z"
          />
          <path
            className="cls-4"
            d="M259.93,342.81a.33.33,0,0,0-.08-.46.33.33,0,0,0-.46.08.33.33,0,0,0,.07.46A.34.34,0,0,0,259.93,342.81Z"
          />
          <path
            className="cls-4"
            d="M266.07,333.29a.33.33,0,1,0-.54-.39.33.33,0,0,0,.54.39Z"
          />
          <path
            className="cls-4"
            d="M272.56,324a.35.35,0,0,0-.08-.47.33.33,0,0,0-.46.08.32.32,0,0,0,.07.46A.34.34,0,0,0,272.56,324Z"
          />
          <path
            className="cls-4"
            d="M279.37,314.92a.33.33,0,1,0-.46.08A.33.33,0,0,0,279.37,314.92Z"
          />
          <path
            className="cls-4"
            d="M286.5,306.11a.32.32,0,0,0-.07-.46.33.33,0,1,0-.39.54A.33.33,0,0,0,286.5,306.11Z"
          />
          <path
            className="cls-4"
            d="M294,297.56a.33.33,0,1,0-.46.08A.34.34,0,0,0,294,297.56Z"
          />
          <path
            className="cls-4"
            d="M301.75,289.32a.4.4,0,0,0-.1-.55.39.39,0,0,0-.54.09.39.39,0,0,0,.64.46Z"
          />
          <path
            className="cls-4"
            d="M309.76,281.31a.37.37,0,0,0-.08-.51.38.38,0,0,0-.51.09.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M318.08,273.61a.36.36,0,1,0-.58-.42.36.36,0,0,0,.58.42Z"
          />
          <path
            className="cls-4"
            d="M326.67,266.21a.36.36,0,0,0-.58-.42.37.37,0,0,0,.08.51A.36.36,0,0,0,326.67,266.21Z"
          />
          <path
            className="cls-4"
            d="M335.53,259.13a.36.36,0,0,0-.59-.42.35.35,0,0,0,.08.5A.37.37,0,0,0,335.53,259.13Z"
          />
          <path
            className="cls-4"
            d="M344.63,252.37A.36.36,0,1,0,344,252a.36.36,0,0,0,.59.42Z"
          />
          <path
            className="cls-4"
            d="M354,245.94a.36.36,0,1,0-.51.08A.37.37,0,0,0,354,245.94Z"
          />
          <path
            className="cls-4"
            d="M363.53,239.85a.35.35,0,0,0-.08-.5.36.36,0,1,0,.08.5Z"
          />
          <path
            className="cls-4"
            d="M373.3,234.11a.37.37,0,0,0-.08-.51.36.36,0,1,0,.08.51Z"
          />
          <path
            className="cls-4"
            d="M383.26,228.7a.33.33,0,0,0-.08-.46.33.33,0,0,0-.46.07.34.34,0,0,0,.07.47A.35.35,0,0,0,383.26,228.7Z"
          />
          <path
            className="cls-4"
            d="M393.42,223.68a.35.35,0,0,0-.08-.47.33.33,0,0,0-.39.54A.34.34,0,0,0,393.42,223.68Z"
          />
          <path
            className="cls-4"
            d="M403.78,219a.36.36,0,1,0-.51.09A.36.36,0,0,0,403.78,219Z"
          />
          <path
            className="cls-4"
            d="M414.27,214.75a.36.36,0,1,0-.58-.42.36.36,0,1,0,.58.42Z"
          />
          <path
            className="cls-4"
            d="M424.92,210.84a.36.36,0,0,0-.08-.5.37.37,0,1,0,.08.5Z"
          />
          <path
            className="cls-4"
            d="M435.69,207.32a.36.36,0,0,0-.58-.42.36.36,0,1,0,.58.42Z"
          />
          <path
            className="cls-4"
            d="M446.59,204.19a.35.35,0,0,0-.08-.5.36.36,0,1,0-.42.58A.36.36,0,0,0,446.59,204.19Z"
          />
          <path
            className="cls-4"
            d="M457,201.05a.33.33,0,0,0,.08.46.33.33,0,0,0,.46-.07.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M468.69,199.11a.36.36,0,1,0-.51.08A.37.37,0,0,0,468.69,199.11Z"
          />
          <path
            className="cls-4"
            d="M479.86,197.17a.36.36,0,0,0-.09-.5.36.36,0,1,0-.42.58A.37.37,0,0,0,479.86,197.17Z"
          />
          <path
            className="cls-4"
            d="M491.06,195.61a.33.33,0,1,0-.46.08A.32.32,0,0,0,491.06,195.61Z"
          />
          <path
            className="cls-4"
            d="M502.37,194.49a.36.36,0,0,0-.08-.5.37.37,0,1,0,.08.5Z"
          />
          <path
            className="cls-4"
            d="M513.68,193.76a.36.36,0,0,0-.08-.5.37.37,0,0,0-.51.08.38.38,0,0,0,.09.51A.36.36,0,0,0,513.68,193.76Z"
          />
          <path
            className="cls-4"
            d="M525,193.44a.36.36,0,1,0-.5.08A.35.35,0,0,0,525,193.44Z"
          />
          <path
            className="cls-4"
            d="M535.87,193.58a.32.32,0,0,0,.46-.07.33.33,0,0,0-.08-.46.33.33,0,1,0-.38.53Z"
          />
          <path
            className="cls-4"
            d="M547.6,193.51a.37.37,0,1,0-.43.59.38.38,0,0,0,.51-.08A.37.37,0,0,0,547.6,193.51Z"
          />
          <path
            className="cls-4"
            d="M558.48,195a.37.37,0,0,0,.5-.09.35.35,0,0,0-.08-.5.36.36,0,1,0-.42.59Z"
          />
          <path
            className="cls-4"
            d="M569.74,196.3a.36.36,0,1,0-.08-.51A.37.37,0,0,0,569.74,196.3Z"
          />
          <path
            className="cls-4"
            d="M581.37,197.41a.38.38,0,0,0-.51.09.36.36,0,0,0,.59.42A.37.37,0,0,0,581.37,197.41Z"
          />
          <path
            className="cls-4"
            d="M592.53,199.5a.39.39,0,0,0-.55.09.38.38,0,0,0,.09.54.39.39,0,0,0,.55-.09A.39.39,0,0,0,592.53,199.5Z"
          />
          <path
            className="cls-4"
            d="M603.67,202.55a.39.39,0,0,0-.09-.55.39.39,0,0,0-.54.09.39.39,0,1,0,.63.46Z"
          />
          <path
            className="cls-4"
            d="M614.53,204.93a.37.37,0,0,0-.51.08.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08A.35.35,0,0,0,614.53,204.93Z"
          />
          <circle
            className="cls-4"
            cx="625.16"
            cy="208.51"
            r="0.36"
            transform="translate(91.16 594.71) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M635.59,212a.37.37,0,0,0,.09.51.36.36,0,0,0,.42-.59A.37.37,0,0,0,635.59,212Z"
          />
          <path
            className="cls-4"
            d="M646.18,216a.36.36,0,1,0,.59.42.36.36,0,0,0-.09-.5A.36.36,0,0,0,646.18,216Z"
          />
          <path
            className="cls-4"
            d="M657.12,220.4a.37.37,0,0,0-.51.08.38.38,0,0,0,.08.51.37.37,0,0,0,.43-.59Z"
          />
          <path
            className="cls-4"
            d="M667.38,225.21a.35.35,0,0,0-.5.08.36.36,0,1,0,.5-.08Z"
          />
          <path
            className="cls-4"
            d="M677.49,230.36a.41.41,0,0,0-.55.09.39.39,0,1,0,.64.46A.41.41,0,0,0,677.49,230.36Z"
          />
          <path
            className="cls-4"
            d="M686.92,236.53a.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54.39.39,0,0,0-.55.09A.39.39,0,0,0,686.92,236.53Z"
          />
          <path
            className="cls-4"
            d="M696.61,242.42a.39.39,0,1,0,.46-.64.39.39,0,1,0-.46.64Z"
          />
          <path
            className="cls-4"
            d="M706.09,248.65a.39.39,0,0,0,.45-.63.39.39,0,1,0-.45.63Z"
          />
          <path
            className="cls-4"
            d="M715.24,254.67a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,715.24,254.67Z"
          />
          <path
            className="cls-4"
            d="M724.24,261.57a.39.39,0,1,0,.54-.09A.38.38,0,0,0,724.24,261.57Z"
          />
          <path
            className="cls-4"
            d="M733,268.78a.39.39,0,0,0,.64.46.39.39,0,1,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M742,276.21a.39.39,0,0,0-.45.64.39.39,0,0,0,.54-.09A.39.39,0,0,0,742,276.21Z"
          />
          <path
            className="cls-4"
            d="M750.2,284.06a.36.36,0,0,0-.42.58.37.37,0,0,0,.51-.08A.36.36,0,0,0,750.2,284.06Z"
          />
          <path
            className="cls-4"
            d="M757.7,292.76a.37.37,0,0,0,.51-.09.36.36,0,0,0-.59-.42A.37.37,0,0,0,757.7,292.76Z"
          />
          <path
            className="cls-4"
            d="M765.24,300.64a.37.37,0,0,0,.09.51.36.36,0,0,0,.42-.59A.37.37,0,0,0,765.24,300.64Z"
          />
          <path
            className="cls-4"
            d="M773.08,309.19a.39.39,0,0,0-.54.09.39.39,0,1,0,.54-.09Z"
          />
          <path
            className="cls-4"
            d="M780.07,318.13a.36.36,0,1,0,.08.51A.37.37,0,0,0,780.07,318.13Z"
          />
          <path
            className="cls-4"
            d="M786.24,327.38a.36.36,0,0,0,.09.5.36.36,0,1,0,.42-.58A.37.37,0,0,0,786.24,327.38Z"
          />
          <path
            className="cls-4"
            d="M792.59,336.77a.36.36,0,1,0,.59.42.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M798.57,346.37a.39.39,0,0,0,.64.46.39.39,0,1,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M804.25,356.22a.36.36,0,0,0,.08.5.36.36,0,1,0,.42-.59A.36.36,0,0,0,804.25,356.22Z"
          />
          <path
            className="cls-4"
            d="M809.52,366.22a.41.41,0,0,0,.09.55.39.39,0,1,0,.46-.64A.41.41,0,0,0,809.52,366.22Z"
          />
          <path
            className="cls-4"
            d="M814.48,376.45a.36.36,0,0,0,.59.42.36.36,0,1,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M819.13,387.33a.38.38,0,0,0,.51-.08.37.37,0,0,0-.09-.51.35.35,0,0,0-.5.08A.37.37,0,0,0,819.13,387.33Z"
          />
          <path
            className="cls-4"
            d="M823.24,397.36a.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M827.05,408a.36.36,0,0,0,.08.5.36.36,0,1,0-.08-.5Z"
          />
          <circle
            className="cls-4"
            cx="830.77"
            cy="419.06"
            r="0.36"
            transform="translate(5.82 849.46) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M833.48,429.75a.4.4,0,0,0,.09.55.39.39,0,1,0-.09-.55Z"
          />
          <path
            className="cls-4"
            d="M836.15,440.79a.37.37,0,0,0,.08.51.35.35,0,0,0,.5-.08.36.36,0,1,0-.58-.43Z"
          />
          <circle
            className="cls-4"
            cx="838.68"
            cy="452.12"
            r="0.36"
            transform="translate(-17.74 869.66) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M840.73,463a.36.36,0,0,0-.42.59.36.36,0,1,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M842.17,474.26a.35.35,0,0,0-.5.08.36.36,0,1,0,.58.42A.35.35,0,0,0,842.17,474.26Z"
          />
          <path
            className="cls-4"
            d="M842.68,485.61a.39.39,0,0,0,.09.55.38.38,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,842.68,485.61Z"
          />
          <path
            className="cls-4"
            d="M843.33,497a.36.36,0,1,0,.59.42.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M843.55,508.29a.36.36,0,1,0,.51-.09A.36.36,0,0,0,843.55,508.29Z"
          />
          <path
            className="cls-4"
            d="M843.37,519.62a.37.37,0,0,0,.08.51.36.36,0,1,0-.08-.51Z"
          />
          <path
            className="cls-4"
            d="M843.28,530.86a.36.36,0,1,0-.42.59.36.36,0,0,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M841.86,542.74a.35.35,0,0,0,.5-.08.36.36,0,1,0-.58-.42A.35.35,0,0,0,841.86,542.74Z"
          />
          <path
            className="cls-4"
            d="M840.35,553.47a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,840.35,553.47Z"
          />
          <path
            className="cls-4"
            d="M838.57,564.68a.36.36,0,1,0,.5-.08A.35.35,0,0,0,838.57,564.68Z"
          />
          <path
            className="cls-4"
            d="M836.34,575.78a.39.39,0,0,0,.09.55.38.38,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,836.34,575.78Z"
          />
          <path
            className="cls-4"
            d="M833.76,586.84a.36.36,0,0,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,833.76,586.84Z"
          />
          <path
            className="cls-4"
            d="M830.76,597.77a.36.36,0,1,0,.5-.08A.35.35,0,0,0,830.76,597.77Z"
          />
          <path
            className="cls-4"
            d="M827.37,608.59a.35.35,0,0,0,.08.5A.37.37,0,0,0,828,609a.38.38,0,0,0-.09-.51A.36.36,0,0,0,827.37,608.59Z"
          />
          <path
            className="cls-4"
            d="M823.57,619.26a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,0,0-.09-.55A.38.38,0,0,0,823.57,619.26Z"
          />
          <path
            className="cls-4"
            d="M819.44,629.83a.36.36,0,0,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,819.44,629.83Z"
          />
          <path
            className="cls-4"
            d="M814.9,640.22a.36.36,0,0,0,.59.42.37.37,0,0,0-.08-.51A.38.38,0,0,0,814.9,640.22Z"
          />
          <path
            className="cls-4"
            d="M810,650.42a.4.4,0,0,0,.08.55.41.41,0,0,0,.55-.09.39.39,0,0,0-.09-.55A.39.39,0,0,0,810,650.42Z"
          />
          <path
            className="cls-4"
            d="M804.71,660.46a.39.39,0,0,0,.09.55.39.39,0,1,0-.09-.55Z"
          />
          <path
            className="cls-4"
            d="M799.09,670.31a.39.39,0,1,0,.54-.09A.38.38,0,0,0,799.09,670.31Z"
          />
          <path
            className="cls-4"
            d="M793.11,679.94a.41.41,0,0,0,.09.55.4.4,0,0,0,.55-.09.39.39,0,0,0-.64-.46Z"
          />
          <path
            className="cls-4"
            d="M786.8,689.36a.38.38,0,0,0,.09.54.39.39,0,1,0,.45-.63A.38.38,0,0,0,786.8,689.36Z"
          />
          <path
            className="cls-4"
            d="M780.15,698.54a.39.39,0,0,0,.09.55.39.39,0,0,0,.54-.09.39.39,0,1,0-.63-.46Z"
          />
          <circle className="cls-4" cx="773.49" cy="707.71" r="0.39" />
          <path
            className="cls-4"
            d="M765.88,716.16a.39.39,0,1,0,.55-.09A.41.41,0,0,0,765.88,716.16Z"
          />
          <path
            className="cls-4"
            d="M758.28,724.58a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.38.38,0,0,0-.09-.54A.39.39,0,0,0,758.28,724.58Z"
          />
          <path
            className="cls-4"
            d="M750.41,732.73a.36.36,0,1,0,.51-.08A.37.37,0,0,0,750.41,732.73Z"
          />
          <path
            className="cls-4"
            d="M742.23,740.58a.35.35,0,0,0,.08.5.36.36,0,1,0-.08-.5Z"
          />
          <path
            className="cls-4"
            d="M733.77,748.13a.36.36,0,0,0,.59.42.36.36,0,1,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M725.05,755.37a.36.36,0,0,0,.08.5.36.36,0,1,0-.08-.5Z"
          />
          <path
            className="cls-4"
            d="M716.07,762.29a.37.37,0,0,0,.08.51.38.38,0,0,0,.51-.09.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M706.85,768.89a.36.36,0,0,0,.08.5.36.36,0,1,0,.42-.59A.36.36,0,0,0,706.85,768.89Z"
          />
          <path
            className="cls-4"
            d="M697.37,775.13a.39.39,0,1,0,.55-.09A.39.39,0,0,0,697.37,775.13Z"
          />
          <path
            className="cls-4"
            d="M687.7,781.05a.39.39,0,0,0,.09.54.39.39,0,0,0,.55-.09.39.39,0,0,0-.09-.54A.39.39,0,0,0,687.7,781.05Z"
          />
          <path
            className="cls-4"
            d="M677.85,786.63a.36.36,0,1,0,.59.42.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M673.4,803.18a.33.33,0,0,0,.08.46.33.33,0,0,0,.46-.07.34.34,0,0,0-.07-.47A.35.35,0,0,0,673.4,803.18Z"
          />
          <path
            className="cls-4"
            d="M663.16,808a.32.32,0,0,0,.07.46.33.33,0,1,0,.39-.54A.33.33,0,0,0,663.16,808Z"
          />
          <path
            className="cls-4"
            d="M652.77,812.54a.31.31,0,0,0,.07.42.3.3,0,1,0,.35-.49A.31.31,0,0,0,652.77,812.54Z"
          />
          <path
            className="cls-4"
            d="M642.22,816.67a.3.3,0,0,0,.06.42.31.31,0,0,0,.43-.06.32.32,0,0,0-.07-.43A.31.31,0,0,0,642.22,816.67Z"
          />
          <path
            className="cls-4"
            d="M631.5,820.42a.33.33,0,1,0,.46-.07A.32.32,0,0,0,631.5,820.42Z"
          />
          <path
            className="cls-4"
            d="M620.7,823.83a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.31.31,0,0,0,620.7,823.83Z"
          />
          <path
            className="cls-4"
            d="M609.73,826.82a.36.36,0,1,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,609.73,826.82Z"
          />
          <path
            className="cls-4"
            d="M598.75,829.49a.3.3,0,0,0,.49.35.3.3,0,1,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M587.64,831.75a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.07-.42A.29.29,0,0,0,587.64,831.75Z"
          />
          <path
            className="cls-4"
            d="M576.43,833.6a.35.35,0,0,0,.08.47A.33.33,0,0,0,577,834a.33.33,0,0,0-.07-.46A.34.34,0,0,0,576.43,833.6Z"
          />
          <path
            className="cls-4"
            d="M565.22,835.11a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,565.22,835.11Z"
          />
          <path
            className="cls-4"
            d="M553.93,836.2a.31.31,0,0,0,.07.42.3.3,0,1,0,.35-.49A.31.31,0,0,0,553.93,836.2Z"
          />
          <path
            className="cls-4"
            d="M542.59,836.89a.33.33,0,0,0,.08.46.32.32,0,0,0,.46-.07.33.33,0,0,0-.07-.46A.34.34,0,0,0,542.59,836.89Z"
          />
          <path
            className="cls-4"
            d="M531.77,837.58a.3.3,0,0,0-.06-.42.31.31,0,0,0-.43.06.32.32,0,0,0,.07.43A.31.31,0,0,0,531.77,837.58Z"
          />
          <path
            className="cls-4"
            d="M520,837.59a.32.32,0,0,0,.46-.07.33.33,0,1,0-.54-.39A.33.33,0,0,0,520,837.59Z"
          />
          <path
            className="cls-4"
            d="M508.62,836.68a.3.3,0,1,0,.42-.07A.31.31,0,0,0,508.62,836.68Z"
          />
          <path
            className="cls-4"
            d="M497.32,835.82a.3.3,0,0,0,.06.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.31.31,0,0,0,497.32,835.82Z"
          />
          <path
            className="cls-4"
            d="M486.07,834.59a.27.27,0,1,0,.38-.06A.27.27,0,0,0,486.07,834.59Z"
          />
          <path
            className="cls-4"
            d="M474.83,832.94a.3.3,0,1,0,.49.35.31.31,0,0,0-.07-.42A.29.29,0,0,0,474.83,832.94Z"
          />
          <path
            className="cls-4"
            d="M463.65,830.9a.32.32,0,0,0,.07.46.34.34,0,0,0,.47-.07.35.35,0,0,0-.08-.47A.33.33,0,0,0,463.65,830.9Z"
          />
          <path
            className="cls-4"
            d="M452.57,828.49a.33.33,0,1,0,.54.39.33.33,0,0,0-.08-.46A.33.33,0,0,0,452.57,828.49Z"
          />
          <path
            className="cls-4"
            d="M441.58,825.71a.33.33,0,0,0,.07.46.33.33,0,1,0-.07-.46Z"
          />
          <path
            className="cls-4"
            d="M431.15,822.47a.32.32,0,0,0-.46.07.33.33,0,1,0,.54.39A.32.32,0,0,0,431.15,822.47Z"
          />
          <path
            className="cls-4"
            d="M420,819a.27.27,0,1,0,.38-.06A.27.27,0,0,0,420,819Z"
          />
          <path
            className="cls-4"
            d="M409.3,815.11a.32.32,0,0,0,.07.43.31.31,0,0,0,.42-.07.29.29,0,0,0-.07-.42A.3.3,0,0,0,409.3,815.11Z"
          />
          <path
            className="cls-4"
            d="M399.22,810.77a.31.31,0,0,0-.42.07.3.3,0,0,0,.07.42.3.3,0,0,0,.35-.49Z"
          />
          <path
            className="cls-4"
            d="M388.87,806.14a.3.3,0,0,0-.42.07.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.07A.29.29,0,0,0,388.87,806.14Z"
          />
          <path
            className="cls-4"
            d="M378.79,801.59a.33.33,0,0,0-.08-.46.32.32,0,0,0-.46.07.34.34,0,0,0,.07.47A.35.35,0,0,0,378.79,801.59Z"
          />
          <path
            className="cls-4"
            d="M368.27,795.88a.31.31,0,0,0,.07.43.31.31,0,0,0,.42-.07.3.3,0,0,0-.49-.36Z"
          />
          <path
            className="cls-4"
            d="M358.46,790.21a.3.3,0,0,0,.06.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.29.29,0,0,0,358.46,790.21Z"
          />
          <path
            className="cls-4"
            d="M349.26,784.12a.31.31,0,0,0-.42.07.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.06A.31.31,0,0,0,349.26,784.12Z"
          />
          <path
            className="cls-4"
            d="M339.87,777.78a.31.31,0,0,0-.42.07.3.3,0,0,0,.06.42.31.31,0,0,0,.43-.07A.31.31,0,0,0,339.87,777.78Z"
          />
          <path
            className="cls-4"
            d="M330.76,771.54a.3.3,0,0,0-.07-.42.3.3,0,0,0-.42.07.31.31,0,0,0,.07.42A.29.29,0,0,0,330.76,771.54Z"
          />
          <path
            className="cls-4"
            d="M321.82,764.57a.29.29,0,0,0-.07-.42.28.28,0,0,0-.41.07.3.3,0,0,0,.06.42A.31.31,0,0,0,321.82,764.57Z"
          />
          <path
            className="cls-4"
            d="M312.64,756.94a.3.3,0,1,0,.49.35.3.3,0,0,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M304.21,749.36a.3.3,0,1,0,.49.35.31.31,0,0,0-.07-.42A.31.31,0,0,0,304.21,749.36Z"
          />
          <path
            className="cls-4"
            d="M296.11,741.92a.3.3,0,0,0,.42-.07.31.31,0,0,0-.07-.42.3.3,0,0,0-.42.07A.29.29,0,0,0,296.11,741.92Z"
          />
          <path
            className="cls-4"
            d="M288.14,733.36a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.31.31,0,0,0,288.14,733.36Z"
          />
          <path
            className="cls-4"
            d="M280.54,725a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,280.54,725Z"
          />
          <path
            className="cls-4"
            d="M273.25,716.31a.27.27,0,0,0,.06.38.28.28,0,0,0,.38-.06.27.27,0,0,0-.06-.38A.27.27,0,0,0,273.25,716.31Z"
          />
          <path
            className="cls-4"
            d="M266.3,707.78a.27.27,0,0,0,.38-.07.26.26,0,0,0-.06-.37.27.27,0,0,0-.32.44Z"
          />
          <path
            className="cls-4"
            d="M259.49,698.22a.34.34,0,0,0,.08.46.33.33,0,0,0,.46-.08.33.33,0,0,0-.07-.46A.34.34,0,0,0,259.49,698.22Z"
          />
          <path
            className="cls-4"
            d="M253.56,688.79a.3.3,0,0,0-.42.07.3.3,0,1,0,.42-.07Z"
          />
          <path
            className="cls-4"
            d="M247.16,679.69a.3.3,0,0,0,.42-.07.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07A.29.29,0,0,0,247.16,679.69Z"
          />
          <path
            className="cls-4"
            d="M241.37,669.48a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.31.31,0,0,0,241.37,669.48Z"
          />
          <path
            className="cls-4"
            d="M236.49,659.85a.31.31,0,0,0-.07-.42.3.3,0,0,0-.35.49A.3.3,0,0,0,236.49,659.85Z"
          />
          <path
            className="cls-4"
            d="M231,649.34a.31.31,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,1,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M226.37,639.43a.31.31,0,0,0,.42-.07.3.3,0,0,0-.07-.42.3.3,0,0,0-.35.49Z"
          />
          <path
            className="cls-4"
            d="M222.45,628.86a.27.27,0,0,0-.06-.38.27.27,0,0,0-.32.44A.28.28,0,0,0,222.45,628.86Z"
          />
          <path
            className="cls-4"
            d="M218,617.89a.3.3,0,0,0,.06.42.3.3,0,1,0,.36-.48A.3.3,0,0,0,218,617.89Z"
          />
          <path
            className="cls-4"
            d="M214.94,607.49a.3.3,0,0,0-.06-.42.29.29,0,0,0-.42.07.3.3,0,0,0,.06.42A.31.31,0,0,0,214.94,607.49Z"
          />
          <path
            className="cls-4"
            d="M211.74,596.61a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M208.91,585.64a.31.31,0,0,0-.07-.43.31.31,0,0,0-.42.07.29.29,0,0,0,.07.42A.3.3,0,0,0,208.91,585.64Z"
          />
          <path
            className="cls-4"
            d="M206.46,574.57a.29.29,0,0,0-.07-.42.3.3,0,1,0,.07.42Z"
          />
          <path
            className="cls-4"
            d="M204.4,563.42a.31.31,0,0,0-.07-.42.29.29,0,0,0-.42.07.3.3,0,0,0,.06.42A.32.32,0,0,0,204.4,563.42Z"
          />
          <path
            className="cls-4"
            d="M202.69,552.19a.27.27,0,1,0-.38.06A.27.27,0,0,0,202.69,552.19Z"
          />
          <path
            className="cls-4"
            d="M201.42,540.94a.3.3,0,0,0-.06-.42.3.3,0,1,0-.36.49A.29.29,0,0,0,201.42,540.94Z"
          />
          <path
            className="cls-4"
            d="M200.52,529.64a.3.3,0,1,0-.49-.35.31.31,0,0,0,.07.42A.29.29,0,0,0,200.52,529.64Z"
          />
          <path
            className="cls-4"
            d="M200,518.31a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.31.31,0,0,0,.07.42A.29.29,0,0,0,200,518.31Z"
          />
          <path
            className="cls-4"
            d="M199.89,507a.3.3,0,0,0-.49-.35.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M199.73,495.74a.34.34,0,0,0,.46-.08.33.33,0,0,0-.08-.46.33.33,0,0,0-.46.07A.34.34,0,0,0,199.73,495.74Z"
          />
          <path
            className="cls-4"
            d="M200.41,484.39a.3.3,0,0,0,.42-.06.32.32,0,0,0-.07-.43.31.31,0,0,0-.42.07A.29.29,0,0,0,200.41,484.39Z"
          />
          <path
            className="cls-4"
            d="M201.39,472.69a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.07-.42A.29.29,0,0,0,201.39,472.69Z"
          />
          <path
            className="cls-4"
            d="M203.32,461.79a.3.3,0,0,0-.49-.35.3.3,0,0,0,.07.42A.29.29,0,0,0,203.32,461.79Z"
          />
          <path
            className="cls-4"
            d="M205.15,450.6a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M207.36,439.48a.3.3,0,0,0-.06-.42.32.32,0,0,0-.43.07.31.31,0,0,0,.07.42A.29.29,0,0,0,207.36,439.48Z"
          />
          <path
            className="cls-4"
            d="M210,428.45a.3.3,0,0,0-.49-.35.3.3,0,0,0,.07.42A.31.31,0,0,0,210,428.45Z"
          />
          <path
            className="cls-4"
            d="M213,417.52a.33.33,0,0,0-.07-.46.35.35,0,0,0-.47.08.33.33,0,0,0,.08.46A.33.33,0,0,0,213,417.52Z"
          />
          <circle
            className="cls-4"
            cx="216.04"
            cy="406.5"
            r="0.27"
            transform="translate(-240.16 344.9) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M220,396a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.06.27.27,0,0,0,.06.38A.27.27,0,0,0,220,396Z"
          />
          <path
            className="cls-4"
            d="M224.1,385.4A.31.31,0,0,0,224,385a.32.32,0,0,0-.43.07.31.31,0,0,0,.07.42A.3.3,0,0,0,224.1,385.4Z"
          />
          <path
            className="cls-4"
            d="M228.56,375a.31.31,0,0,0-.07-.42.3.3,0,0,0-.35.49A.3.3,0,0,0,228.56,375Z"
          />
          <path
            className="cls-4"
            d="M233.37,364.7a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,0,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M238.53,354.61a.3.3,0,0,0-.49-.35.3.3,0,0,0,.07.42A.31.31,0,0,0,238.53,354.61Z"
          />
          <path
            className="cls-4"
            d="M244,344.7a.3.3,0,0,0-.07-.42.3.3,0,1,0-.35.49A.3.3,0,0,0,244,344.7Z"
          />
          <path
            className="cls-4"
            d="M249.89,335a.3.3,0,0,0-.49-.35.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M256.06,325.48a.3.3,0,0,0-.49-.35.31.31,0,0,0,.07.42A.3.3,0,0,0,256.06,325.48Z"
          />
          <path
            className="cls-4"
            d="M262.57,316.19a.31.31,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M269.39,307.13a.31.31,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,0,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M276.51,298.32A.3.3,0,0,0,276,298a.31.31,0,0,0,.07.42A.31.31,0,0,0,276.51,298.32Z"
          />
          <path
            className="cls-4"
            d="M283.94,289.75a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.31.31,0,0,0,.07.42A.29.29,0,0,0,283.94,289.75Z"
          />
          <path
            className="cls-4"
            d="M291.69,281.47a.35.35,0,0,0-.08-.47.33.33,0,0,0-.46.08.32.32,0,0,0,.07.46A.34.34,0,0,0,291.69,281.47Z"
          />
          <path
            className="cls-4"
            d="M299.66,273.42a.3.3,0,1,0-.49-.35.31.31,0,0,0,.07.42A.31.31,0,0,0,299.66,273.42Z"
          />
          <path
            className="cls-4"
            d="M307.94,265.67a.31.31,0,0,0-.07-.42.3.3,0,1,0-.36.48A.31.31,0,0,0,307.94,265.67Z"
          />
          <path
            className="cls-4"
            d="M316.47,258.21a.31.31,0,0,0-.07-.43.31.31,0,0,0-.42.07.31.31,0,0,0,.07.42A.3.3,0,0,0,316.47,258.21Z"
          />
          <path
            className="cls-4"
            d="M325.26,251a.3.3,0,0,0-.07-.42.3.3,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M334.32,244.21a.35.35,0,0,0-.08-.47.33.33,0,0,0-.39.54A.34.34,0,0,0,334.32,244.21Z"
          />
          <path
            className="cls-4"
            d="M343.58,237.67a.35.35,0,0,0-.08-.47.33.33,0,0,0-.46.08.33.33,0,0,0,.54.39Z"
          />
          <path
            className="cls-4"
            d="M353.06,231.45a.33.33,0,1,0-.54-.38.34.34,0,0,0,.08.46A.33.33,0,0,0,353.06,231.45Z"
          />
          <path
            className="cls-4"
            d="M362.73,225.55a.31.31,0,0,0-.07-.42.3.3,0,1,0,.07.42Z"
          />
          <path
            className="cls-4"
            d="M372.61,220a.3.3,0,0,0-.06-.42.32.32,0,0,0-.43.07.31.31,0,0,0,.07.42A.29.29,0,0,0,372.61,220Z"
          />
          <path
            className="cls-4"
            d="M382.69,214.8a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M392.94,210a.31.31,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,0,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M403.35,205.46a.31.31,0,0,0-.07-.42.29.29,0,0,0-.42.07.3.3,0,0,0,.06.42A.32.32,0,0,0,403.35,205.46Z"
          />
          <path
            className="cls-4"
            d="M413.93,201.34a.34.34,0,0,0-.08-.46.33.33,0,1,0,.08.46Z"
          />
          <path
            className="cls-4"
            d="M424.62,197.58a.33.33,0,0,0-.54-.39.33.33,0,0,0,.08.46A.32.32,0,0,0,424.62,197.58Z"
          />
          <path
            className="cls-4"
            d="M435.44,194.18a.33.33,0,1,0-.54-.38.33.33,0,0,0,.54.38Z"
          />
          <path
            className="cls-4"
            d="M446.34,191.15a.3.3,0,0,0-.49-.35.31.31,0,0,0,.07.42A.31.31,0,0,0,446.34,191.15Z"
          />
          <path
            className="cls-4"
            d="M457.34,188.49a.27.27,0,1,0-.44-.31.27.27,0,0,0,.44.31Z"
          />
          <path
            className="cls-4"
            d="M468.08,186.3a.27.27,0,0,0,.31-.44.27.27,0,1,0-.31.44Z"
          />
          <path
            className="cls-4"
            d="M479.66,184.38a.3.3,0,1,0-.42.07A.31.31,0,0,0,479.66,184.38Z"
          />
          <path
            className="cls-4"
            d="M490.92,182.91a.33.33,0,1,0-.46.08A.33.33,0,0,0,490.92,182.91Z"
          />
          <path
            className="cls-4"
            d="M502.18,181.8a.29.29,0,0,0-.07-.42.28.28,0,0,0-.41.07.3.3,0,0,0,.06.42A.31.31,0,0,0,502.18,181.8Z"
          />
          <path
            className="cls-4"
            d="M513.5,181.09a.3.3,0,1,0-.42.07A.3.3,0,0,0,513.5,181.09Z"
          />
          <path
            className="cls-4"
            d="M524.39,180.87a.33.33,0,1,0,.39-.54.33.33,0,0,0-.46.08A.33.33,0,0,0,524.39,180.87Z"
          />
          <path
            className="cls-4"
            d="M535.75,180.92a.3.3,0,1,0-.07-.42A.3.3,0,0,0,535.75,180.92Z"
          />
          <path
            className="cls-4"
            d="M547.5,181.32a.31.31,0,0,0-.07-.42.3.3,0,1,0-.35.49A.31.31,0,0,0,547.5,181.32Z"
          />
          <path
            className="cls-4"
            d="M558.83,182.19a.34.34,0,0,0-.08-.46.33.33,0,0,0-.46.08.33.33,0,0,0,.07.46A.34.34,0,0,0,558.83,182.19Z"
          />
          <path
            className="cls-4"
            d="M570.1,183.44A.33.33,0,0,0,570,183a.33.33,0,0,0-.46.07.34.34,0,0,0,.07.47A.35.35,0,0,0,570.1,183.44Z"
          />
          <path
            className="cls-4"
            d="M580.87,185.13a.3.3,0,0,0,.42-.07.3.3,0,1,0-.42.07Z"
          />
          <path
            className="cls-4"
            d="M592.38,186.66a.3.3,0,1,0,.07.42A.31.31,0,0,0,592.38,186.66Z"
          />
          <path
            className="cls-4"
            d="M603.55,189.5a.33.33,0,0,0-.08-.46.34.34,0,0,0-.46.08.33.33,0,0,0,.54.38Z"
          />
          <path
            className="cls-4"
            d="M614,191.92a.31.31,0,0,0,.07.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.32.32,0,0,0,614,191.92Z"
          />
          <path
            className="cls-4"
            d="M625.43,195.46a.35.35,0,0,0-.08-.47.33.33,0,0,0-.46.08.32.32,0,0,0,.07.46A.34.34,0,0,0,625.43,195.46Z"
          />
          <path
            className="cls-4"
            d="M636.2,199a.34.34,0,0,0-.08-.46.32.32,0,0,0-.46.08.33.33,0,0,0,.07.46A.34.34,0,0,0,636.2,199Z"
          />
          <path
            className="cls-4"
            d="M646.28,202.5a.36.36,0,1,0,.5-.08A.35.35,0,0,0,646.28,202.5Z"
          />
          <path
            className="cls-4"
            d="M656.83,206.81a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,656.83,206.81Z"
          />
          <path
            className="cls-4"
            d="M667.17,211.44a.31.31,0,0,0,.07.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.31.31,0,0,0,667.17,211.44Z"
          />
          <path
            className="cls-4"
            d="M677.36,216.43a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,677.36,216.43Z"
          />
          <path
            className="cls-4"
            d="M687.36,221.76a.3.3,0,1,0,.42-.07A.31.31,0,0,0,687.36,221.76Z"
          />
          <path
            className="cls-4"
            d="M697.69,227.81a.33.33,0,0,0-.54-.39.33.33,0,1,0,.54.39Z"
          />
          <path
            className="cls-4"
            d="M707.3,233.82a.33.33,0,0,0-.08-.46.33.33,0,1,0,.08.46Z"
          />
          <path
            className="cls-4"
            d="M716.16,239.78a.33.33,0,0,0,.07.46.33.33,0,1,0,.39-.54A.33.33,0,0,0,716.16,239.78Z"
          />
          <path
            className="cls-4"
            d="M725.33,246.44a.32.32,0,0,0,.08.46.33.33,0,0,0,.46-.07.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M734.27,253.42a.33.33,0,0,0,.07.46.35.35,0,0,0,.47-.08.33.33,0,0,0-.08-.46A.33.33,0,0,0,734.27,253.42Z"
          />
          <path
            className="cls-4"
            d="M743,260.69a.35.35,0,0,0,.08.47.33.33,0,0,0,.46-.08.32.32,0,0,0-.08-.46A.33.33,0,0,0,743,260.69Z"
          />
          <path
            className="cls-4"
            d="M751.84,268.22a.3.3,0,0,0-.42.07.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07A.29.29,0,0,0,751.84,268.22Z"
          />
          <path
            className="cls-4"
            d="M759.59,276.15a.3.3,0,1,0,.49.35.3.3,0,1,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M767.9,284.22a.3.3,0,0,0-.42.07.31.31,0,0,0,.07.42.31.31,0,0,0,.42-.07A.29.29,0,0,0,767.9,284.22Z"
          />
          <path
            className="cls-4"
            d="M775.51,292.62a.31.31,0,0,0-.42.07.3.3,0,0,0,.07.42.3.3,0,0,0,.35-.49Z"
          />
          <path
            className="cls-4"
            d="M782.86,301.24a.37.37,0,0,0-.51.08.38.38,0,0,0,.09.51.36.36,0,0,0,.42-.59Z"
          />
          <path
            className="cls-4"
            d="M789.46,310.71a.33.33,0,1,0,.39-.54.33.33,0,0,0-.46.08A.33.33,0,0,0,789.46,310.71Z"
          />
          <path
            className="cls-4"
            d="M796.09,319.4a.33.33,0,1,0,.46-.08A.32.32,0,0,0,796.09,319.4Z"
          />
          <path
            className="cls-4"
            d="M802.91,328.72a.3.3,0,1,0,.07.42A.31.31,0,0,0,802.91,328.72Z"
          />
          <path
            className="cls-4"
            d="M808.51,338.36a.33.33,0,0,0,.08.46.33.33,0,0,0,.46-.07.34.34,0,0,0-.07-.47A.35.35,0,0,0,808.51,338.36Z"
          />
          <path
            className="cls-4"
            d="M814.23,348.15a.32.32,0,0,0,.08.46.33.33,0,0,0,.46-.07.35.35,0,0,0-.08-.47A.33.33,0,0,0,814.23,348.15Z"
          />
          <path
            className="cls-4"
            d="M819.58,358.11a.37.37,0,0,0,.59.43.37.37,0,1,0-.59-.43Z"
          />
          <path
            className="cls-4"
            d="M824.63,368.29a.33.33,0,1,0,.54.39.33.33,0,0,0-.08-.46A.33.33,0,0,0,824.63,368.29Z"
          />
          <path
            className="cls-4"
            d="M829.77,378.55a.33.33,0,1,0-.39.54.33.33,0,0,0,.46-.08A.32.32,0,0,0,829.77,378.55Z"
          />
          <path
            className="cls-4"
            d="M833.62,389.11a.33.33,0,0,0,.07.46.33.33,0,1,0,.39-.54A.33.33,0,0,0,833.62,389.11Z"
          />
          <path
            className="cls-4"
            d="M837.57,399.74a.32.32,0,0,0,.07.46.33.33,0,1,0,.39-.54A.32.32,0,0,0,837.57,399.74Z"
          />
          <path
            className="cls-4"
            d="M841.17,410.51a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.3.3,0,0,0,841.17,410.51Z"
          />
          <path
            className="cls-4"
            d="M844.33,421.35a.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M847.16,432.33a.35.35,0,0,0,.08.5.37.37,0,0,0,.51-.08.36.36,0,0,0-.59-.42Z"
          />
          <path
            className="cls-4"
            d="M850.09,443.34a.33.33,0,0,0-.46.08.33.33,0,0,0,.08.46.33.33,0,0,0,.38-.54Z"
          />
          <path
            className="cls-4"
            d="M851.72,454.58a.31.31,0,0,0,.07.42.3.3,0,1,0,.35-.49A.31.31,0,0,0,851.72,454.58Z"
          />
          <path
            className="cls-4"
            d="M853.38,465.78a.33.33,0,1,0,.46-.08A.32.32,0,0,0,853.38,465.78Z"
          />
          <path
            className="cls-4"
            d="M854.64,477a.37.37,0,0,0,.09.51.36.36,0,0,0,.42-.59A.37.37,0,0,0,854.64,477Z"
          />
          <path
            className="cls-4"
            d="M855.55,488.32a.36.36,0,1,0,.5-.08A.37.37,0,0,0,855.55,488.32Z"
          />
          <path
            className="cls-4"
            d="M856.11,499.68a.3.3,0,1,0,.48.36.3.3,0,0,0-.48-.36Z"
          />
          <path
            className="cls-4"
            d="M856.18,511a.36.36,0,0,0,.58.42.36.36,0,1,0-.58-.42Z"
          />
          <path
            className="cls-4"
            d="M855.93,522.34a.32.32,0,0,0,.08.46.33.33,0,0,0,.46-.07.35.35,0,0,0-.08-.47A.33.33,0,0,0,855.93,522.34Z"
          />
          <path
            className="cls-4"
            d="M855.73,533.58a.33.33,0,0,0-.39.54.33.33,0,1,0,.39-.54Z"
          />
          <path
            className="cls-4"
            d="M854.29,545.41a.33.33,0,0,0,.39-.54.33.33,0,1,0-.39.54Z"
          />
          <path
            className="cls-4"
            d="M852.75,556.17a.37.37,0,0,0,.08.51.36.36,0,1,0-.08-.51Z"
          />
          <path
            className="cls-4"
            d="M850.94,567.38a.33.33,0,0,0,.54.39.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M848.73,578.5a.33.33,0,0,0,.07.46.33.33,0,1,0,.39-.54A.33.33,0,0,0,848.73,578.5Z"
          />
          <path
            className="cls-4"
            d="M846.13,589.53a.33.33,0,1,0,.54.39.33.33,0,0,0-.08-.46A.33.33,0,0,0,846.13,589.53Z"
          />
          <path
            className="cls-4"
            d="M843.16,600.47a.33.33,0,1,0,.53.39.32.32,0,0,0-.07-.46A.33.33,0,0,0,843.16,600.47Z"
          />
          <path
            className="cls-4"
            d="M839.8,611.31a.33.33,0,0,0,.54.38.32.32,0,0,0-.07-.46A.34.34,0,0,0,839.8,611.31Z"
          />
          <path
            className="cls-4"
            d="M836.06,622a.36.36,0,0,0,.58.42.37.37,0,0,0-.08-.51A.36.36,0,0,0,836.06,622Z"
          />
          <path
            className="cls-4"
            d="M832,632.6a.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.06.29.29,0,0,0-.07-.42A.3.3,0,0,0,832,632.6Z"
          />
          <path
            className="cls-4"
            d="M827.56,643A.3.3,0,1,0,828,643,.31.31,0,0,0,827.56,643Z"
          />
          <path
            className="cls-4"
            d="M822.75,653.3a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.07-.42A.29.29,0,0,0,822.75,653.3Z"
          />
          <path
            className="cls-4"
            d="M817.56,663.37a.33.33,0,1,0,.54.39.33.33,0,0,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M812.05,673.28a.35.35,0,0,0,.08.47.33.33,0,1,0-.08-.47Z"
          />
          <path
            className="cls-4"
            d="M806.21,683a.33.33,0,0,0,.07.46.33.33,0,1,0,.39-.54A.32.32,0,0,0,806.21,683Z"
          />
          <path
            className="cls-4"
            d="M800,692.5a.33.33,0,0,0,.54.39.33.33,0,0,0-.08-.46A.32.32,0,0,0,800,692.5Z"
          />
          <path
            className="cls-4"
            d="M793.53,701.79a.32.32,0,0,0,.07.46.33.33,0,0,0,.46-.07.33.33,0,1,0-.53-.39Z"
          />
          <path
            className="cls-4"
            d="M786.71,710.85a.32.32,0,0,0,.07.46.33.33,0,1,0,.39-.54A.32.32,0,0,0,786.71,710.85Z"
          />
          <path
            className="cls-4"
            d="M779.58,719.66a.33.33,0,1,0,.54.39.33.33,0,0,0-.08-.46A.32.32,0,0,0,779.58,719.66Z"
          />
          <path
            className="cls-4"
            d="M772.15,728.23a.33.33,0,0,0,.08.46.33.33,0,0,0,.46-.07.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M764.43,736.53a.33.33,0,1,0,.54.39.33.33,0,0,0-.07-.46A.34.34,0,0,0,764.43,736.53Z"
          />
          <path
            className="cls-4"
            d="M756.46,744.58a.3.3,0,0,0,.06.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.31.31,0,0,0,756.46,744.58Z"
          />
          <path
            className="cls-4"
            d="M748.18,752.33a.3.3,0,0,0,.07.42.3.3,0,1,0,.35-.49A.31.31,0,0,0,748.18,752.33Z"
          />
          <path
            className="cls-4"
            d="M739.6,759.76a.36.36,0,1,0,.5-.08A.35.35,0,0,0,739.6,759.76Z"
          />
          <path
            className="cls-4"
            d="M730.83,766.94a.33.33,0,0,0,.54.39.33.33,0,1,0-.54-.39Z"
          />
          <path
            className="cls-4"
            d="M721.8,773.79a.33.33,0,1,0,.54.39.33.33,0,0,0-.08-.46A.33.33,0,0,0,721.8,773.79Z"
          />
          <path
            className="cls-4"
            d="M712.56,780.35a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.3.3,0,0,0,712.56,780.35Z"
          />
          <path
            className="cls-4"
            d="M703.06,786.55a.32.32,0,0,0,.07.46.33.33,0,1,0,.39-.54A.33.33,0,0,0,703.06,786.55Z"
          />
          <path
            className="cls-4"
            d="M693.37,792.43a.33.33,0,1,0,.46-.07A.32.32,0,0,0,693.37,792.43Z"
          />
          <path
            className="cls-4"
            d="M683.5,798a.32.32,0,0,0,.07.43.31.31,0,0,0,.42-.07.29.29,0,0,0-.07-.42A.3.3,0,0,0,683.5,798Z"
          />
          <path
            className="cls-4"
            d="M679.05,814.54a.27.27,0,0,0,.44.31.27.27,0,1,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M668.81,819.39a.27.27,0,1,0,.38-.06A.27.27,0,0,0,668.81,819.39Z"
          />
          <path
            className="cls-4"
            d="M658.39,823.89a.3.3,0,0,0,.06.42.29.29,0,0,0,.42-.07.3.3,0,1,0-.48-.35Z"
          />
          <path
            className="cls-4"
            d="M647.86,828.07a.28.28,0,0,0,.07.38.27.27,0,0,0,.38-.06.28.28,0,0,0-.07-.38A.27.27,0,0,0,647.86,828.07Z"
          />
          <path
            className="cls-4"
            d="M637.16,831.87a.31.31,0,0,0,.07.42.3.3,0,1,0-.07-.42Z"
          />
          <path
            className="cls-4"
            d="M626.39,835.34a.27.27,0,1,0,.38-.06A.27.27,0,0,0,626.39,835.34Z"
          />
          <path
            className="cls-4"
            d="M615.48,838.44a.27.27,0,0,0,.07.38.27.27,0,0,0,.31-.44A.27.27,0,0,0,615.48,838.44Z"
          />
          <path
            className="cls-4"
            d="M604.84,841.13a.24.24,0,0,0-.34.06.24.24,0,0,0,.4.28A.25.25,0,0,0,604.84,841.13Z"
          />
          <path
            className="cls-4"
            d="M593.37,843.52a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,593.37,843.52Z"
          />
          <path
            className="cls-4"
            d="M582.23,845.53a.26.26,0,0,0,.06.37.27.27,0,1,0,.32-.44A.28.28,0,0,0,582.23,845.53Z"
          />
          <path
            className="cls-4"
            d="M571,847.17a.24.24,0,0,0,.4.28.25.25,0,0,0-.06-.34A.24.24,0,0,0,571,847.17Z"
          />
          <circle
            className="cls-4"
            cx="559.96"
            cy="848.55"
            r="0.27"
            transform="translate(-455.91 808.47) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M548.44,849.27a.27.27,0,0,0,.44.32.28.28,0,0,0-.06-.38A.27.27,0,0,0,548.44,849.27Z"
          />
          <path
            className="cls-4"
            d="M537.49,849.7a.27.27,0,0,0-.38.07.26.26,0,0,0,.06.37.27.27,0,1,0,.32-.44Z"
          />
          <path
            className="cls-4"
            d="M525.8,849.9a.25.25,0,0,0,.05.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.05-.33A.25.25,0,0,0,525.8,849.9Z"
          />
          <path
            className="cls-4"
            d="M514.44,849.63a.27.27,0,1,0,.44.31.26.26,0,0,0-.06-.37A.27.27,0,0,0,514.44,849.63Z"
          />
          <path
            className="cls-4"
            d="M503.12,849a.26.26,0,0,0,.06.37.27.27,0,1,0-.06-.37Z"
          />
          <path
            className="cls-4"
            d="M491.83,848a.26.26,0,0,0,.06.37.27.27,0,0,0,.38-.06.28.28,0,0,0-.06-.38A.27.27,0,0,0,491.83,848Z"
          />
          <path
            className="cls-4"
            d="M480.57,846.6a.28.28,0,0,0,.07.38.27.27,0,0,0,.38-.06.28.28,0,0,0-.07-.38A.27.27,0,0,0,480.57,846.6Z"
          />
          <path
            className="cls-4"
            d="M469.37,844.84a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M458.21,842.7a.31.31,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.49-.35Z"
          />
          <circle
            className="cls-4"
            cx="447.4"
            cy="840.37"
            r="0.27"
            transform="translate(-496.17 713.63) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M436.21,837.35a.27.27,0,1,0,.38-.06A.27.27,0,0,0,436.21,837.35Z"
          />
          <path
            className="cls-4"
            d="M425.34,834.13a.26.26,0,0,0,.06.37.27.27,0,1,0-.06-.37Z"
          />
          <circle
            className="cls-4"
            cx="414.81"
            cy="830.7"
            r="0.27"
            transform="translate(-501.9 683.13) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M404.33,826.53a.27.27,0,0,0-.31.45.28.28,0,0,0,.38-.07A.27.27,0,0,0,404.33,826.53Z"
          />
          <path
            className="cls-4"
            d="M393.84,822.24a.27.27,0,0,0-.38.06.28.28,0,0,0,.06.38.27.27,0,0,0,.38-.06A.27.27,0,0,0,393.84,822.24Z"
          />
          <path
            className="cls-4"
            d="M383.12,817.66a.27.27,0,0,0,.06.38.27.27,0,1,0-.06-.38Z"
          />
          <path
            className="cls-4"
            d="M372.91,812.67a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.07-.42A.29.29,0,0,0,372.91,812.67Z"
          />
          <path
            className="cls-4"
            d="M362.94,807.38a.25.25,0,0,0,.06.34.24.24,0,1,0,.28-.39A.25.25,0,0,0,362.94,807.38Z"
          />
          <path
            className="cls-4"
            d="M353.11,801.74a.24.24,0,0,0,.06.33.23.23,0,0,0,.33-.05.24.24,0,1,0-.39-.28Z"
          />
          <circle className="cls-4" cx="343.67" cy="795.91" r="0.24" />
          <path
            className="cls-4"
            d="M334.38,789.42a.25.25,0,0,0-.34.06.24.24,0,1,0,.39.28A.25.25,0,0,0,334.38,789.42Z"
          />
          <circle className="cls-4" cx="325.02" cy="783.02" r="0.24" />
          <path
            className="cls-4"
            d="M316.22,776.26a.24.24,0,0,0-.4-.28.25.25,0,0,0,.06.34A.24.24,0,0,0,316.22,776.26Z"
          />
          <path
            className="cls-4"
            d="M307.4,768.73a.24.24,0,0,0-.34.06.23.23,0,0,0,.06.33.23.23,0,0,0,.33,0A.24.24,0,0,0,307.4,768.73Z"
          />
          <path
            className="cls-4"
            d="M298.88,761.25a.24.24,0,0,0-.33,0,.24.24,0,0,0,.05.34.24.24,0,1,0,.28-.39Z"
          />
          <path
            className="cls-4"
            d="M290.7,753.84a.27.27,0,0,0-.07-.38.27.27,0,0,0-.31.44A.27.27,0,0,0,290.7,753.84Z"
          />
          <path
            className="cls-4"
            d="M282.63,745.43a.27.27,0,0,0-.38.06.28.28,0,0,0,.07.38.27.27,0,0,0,.38-.06A.28.28,0,0,0,282.63,745.43Z"
          />
          <path
            className="cls-4"
            d="M274.9,737.14a.27.27,0,0,0-.38.06.28.28,0,0,0,.07.38.27.27,0,0,0,.38-.06A.28.28,0,0,0,274.9,737.14Z"
          />
          <path
            className="cls-4"
            d="M267.45,728.59a.27.27,0,1,0,.07.38A.28.28,0,0,0,267.45,728.59Z"
          />
          <path
            className="cls-4"
            d="M260.35,720.18a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.06.26.26,0,0,0,.07.38A.26.26,0,0,0,260.35,720.18Z"
          />
          <path
            className="cls-4"
            d="M253.49,711.16a.28.28,0,0,0-.06-.38.27.27,0,0,0-.38.06.27.27,0,1,0,.44.32Z"
          />
          <path
            className="cls-4"
            d="M246.92,701.91a.26.26,0,0,0-.06-.37.27.27,0,1,0-.31.44A.26.26,0,0,0,246.92,701.91Z"
          />
          <path
            className="cls-4"
            d="M240.59,692.1a.23.23,0,0,0-.33.06.23.23,0,0,0,.05.33.24.24,0,1,0,.28-.39Z"
          />
          <path
            className="cls-4"
            d="M234.74,682.79a.26.26,0,0,0-.06-.37.27.27,0,1,0,.06.37Z"
          />
          <path
            className="cls-4"
            d="M228.69,672.63a.26.26,0,0,0,.06.37.27.27,0,1,0-.06-.37Z"
          />
          <path
            className="cls-4"
            d="M223.85,662.91a.27.27,0,1,0-.38.06A.27.27,0,0,0,223.85,662.91Z"
          />
          <path
            className="cls-4"
            d="M218.47,652.39a.27.27,0,0,0,.06.38.28.28,0,0,0,.38-.06.27.27,0,0,0-.06-.38A.27.27,0,0,0,218.47,652.39Z"
          />
          <path
            className="cls-4"
            d="M214.23,642a.24.24,0,1,0-.28.39.24.24,0,0,0,.33,0A.24.24,0,0,0,214.23,642Z"
          />
          <path
            className="cls-4"
            d="M210,631.82a.24.24,0,0,0-.39-.29.24.24,0,0,0,.06.34A.24.24,0,0,0,210,631.82Z"
          />
          <path
            className="cls-4"
            d="M205.79,621.23a.25.25,0,1,0,0-.34A.25.25,0,0,0,205.79,621.23Z"
          />
          <path
            className="cls-4"
            d="M202.61,610.42a.27.27,0,1,0-.38.06A.27.27,0,0,0,202.61,610.42Z"
          />
          <circle className="cls-4" cx="199.2" cy="599.38" r="0.24" />
          <path
            className="cls-4"
            d="M196.6,588.56a.27.27,0,1,0-.38.06A.27.27,0,0,0,196.6,588.56Z"
          />
          <path
            className="cls-4"
            d="M194.12,577.47a.24.24,0,0,0-.39-.28.25.25,0,0,0,.05.34A.24.24,0,0,0,194.12,577.47Z"
          />
          <path
            className="cls-4"
            d="M192,566.33A.24.24,0,0,0,192,566a.25.25,0,0,0-.34.06.24.24,0,0,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M190.34,555.14a.27.27,0,1,0-.38.06A.27.27,0,0,0,190.34,555.14Z"
          />
          <path
            className="cls-4"
            d="M189,543.86a.24.24,0,0,0-.4-.28.25.25,0,0,0,.06.34A.24.24,0,0,0,189,543.86Z"
          />
          <path
            className="cls-4"
            d="M188,532.57a.25.25,0,0,0,0-.34.24.24,0,0,0-.34.06.24.24,0,0,0,0,.33A.24.24,0,0,0,188,532.57Z"
          />
          <path
            className="cls-4"
            d="M187.44,521.26a.26.26,0,0,0-.06-.37.27.27,0,1,0,.06.37Z"
          />
          <path
            className="cls-4"
            d="M187.21,509.91a.24.24,0,1,0-.34.06A.25.25,0,0,0,187.21,509.91Z"
          />
          <path
            className="cls-4"
            d="M187,498.63a.24.24,0,0,0,.34-.06.24.24,0,0,0-.39-.28A.24.24,0,0,0,187,498.63Z"
          />
          <path
            className="cls-4"
            d="M187.57,487.3a.24.24,0,1,0,.28-.39.24.24,0,0,0-.28.39Z"
          />
          <path
            className="cls-4"
            d="M188.47,476a.27.27,0,0,0,.38-.06.27.27,0,0,0-.07-.38.26.26,0,0,0-.37.06A.27.27,0,0,0,188.47,476Z"
          />
          <path
            className="cls-4"
            d="M190.11,464.68a.23.23,0,0,0-.05-.33.25.25,0,0,0-.34,0,.24.24,0,0,0,.06.34A.23.23,0,0,0,190.11,464.68Z"
          />
          <path
            className="cls-4"
            d="M191.8,453.49a.27.27,0,1,0-.44-.32.28.28,0,0,0,.06.38A.27.27,0,0,0,191.8,453.49Z"
          />
          <circle
            className="cls-4"
            cx="193.62"
            cy="442.18"
            r="0.27"
            transform="translate(-278.48 341.55) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M196.22,431.24a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,0,.33A.24.24,0,0,0,196.22,431.24Z"
          />
          <path
            className="cls-4"
            d="M199,420.26a.27.27,0,1,0-.38.07A.28.28,0,0,0,199,420.26Z"
          />
          <path
            className="cls-4"
            d="M202.15,409.37a.27.27,0,1,0-.37.06A.26.26,0,0,0,202.15,409.37Z"
          />
          <path
            className="cls-4"
            d="M205.63,398.56a.24.24,0,0,0-.06-.33.24.24,0,0,0-.33,0,.24.24,0,1,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M209.5,387.92a.27.27,0,0,0-.06-.38.26.26,0,0,0-.37.06.27.27,0,0,0,.06.38A.26.26,0,0,0,209.5,387.92Z"
          />
          <path
            className="cls-4"
            d="M213.71,377.39a.27.27,0,1,0-.44-.31.26.26,0,0,0,.06.37A.27.27,0,0,0,213.71,377.39Z"
          />
          <path
            className="cls-4"
            d="M218.27,367a.28.28,0,0,0-.07-.38.27.27,0,1,0,.07.38Z"
          />
          <path
            className="cls-4"
            d="M223.16,356.78a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.07.27.27,0,1,0,.44.31Z"
          />
          <path
            className="cls-4"
            d="M228.37,346.71a.24.24,0,0,0-.06-.34.23.23,0,0,0-.33.06.24.24,0,0,0,.05.33A.24.24,0,0,0,228.37,346.71Z"
          />
          <path
            className="cls-4"
            d="M233.93,336.83a.24.24,0,0,0-.05-.34.25.25,0,0,0-.34.06.24.24,0,0,0,.06.34A.24.24,0,0,0,233.93,336.83Z"
          />
          <path
            className="cls-4"
            d="M239.82,327.14a.23.23,0,0,0,0-.33.24.24,0,1,0-.28.39A.23.23,0,0,0,239.82,327.14Z"
          />
          <path
            className="cls-4"
            d="M246,317.66a.24.24,0,1,0-.39-.28.23.23,0,0,0,.06.33A.24.24,0,0,0,246,317.66Z"
          />
          <path
            className="cls-4"
            d="M252.55,308.38a.23.23,0,0,0-.05-.33.24.24,0,1,0,.05.33Z"
          />
          <path
            className="cls-4"
            d="M259.38,299.33a.24.24,0,0,0-.06-.34.24.24,0,0,0-.33.06.23.23,0,0,0,.05.33A.24.24,0,0,0,259.38,299.33Z"
          />
          <path
            className="cls-4"
            d="M266.5,290.51a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,.05.33A.24.24,0,0,0,266.5,290.51Z"
          />
          <path
            className="cls-4"
            d="M273.91,281.93a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M281.65,273.63a.29.29,0,0,0-.06-.41.3.3,0,1,0-.36.48A.29.29,0,0,0,281.65,273.63Z"
          />
          <path
            className="cls-4"
            d="M289.59,265.55a.27.27,0,1,0-.44-.32.28.28,0,0,0,.07.38A.26.26,0,0,0,289.59,265.55Z"
          />
          <path
            className="cls-4"
            d="M297.82,257.75a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.06.28.28,0,0,0,.06.38A.27.27,0,0,0,297.82,257.75Z"
          />
          <path
            className="cls-4"
            d="M306.31,250.23a.28.28,0,0,0-.06-.38.27.27,0,0,0-.38.06.27.27,0,0,0,.44.32Z"
          />
          <path
            className="cls-4"
            d="M315,243a.27.27,0,0,0-.44-.31.27.27,0,1,0,.44.31Z"
          />
          <path
            className="cls-4"
            d="M324,236.05a.26.26,0,0,0-.06-.37.27.27,0,1,0,.06.37Z"
          />
          <path
            className="cls-4"
            d="M333.19,229.42a.27.27,0,0,0-.06-.38.27.27,0,1,0-.32.44A.28.28,0,0,0,333.19,229.42Z"
          />
          <path
            className="cls-4"
            d="M342.6,223.09a.27.27,0,0,0-.07-.38.26.26,0,0,0-.37.06.27.27,0,0,0,.06.38A.28.28,0,0,0,342.6,223.09Z"
          />
          <path
            className="cls-4"
            d="M352.18,217.05a.23.23,0,0,0-.05-.33.24.24,0,1,0-.28.39A.23.23,0,0,0,352.18,217.05Z"
          />
          <path
            className="cls-4"
            d="M362,211.38A.27.27,0,0,0,362,211a.28.28,0,0,0-.38.06.27.27,0,0,0,.07.38A.26.26,0,0,0,362,211.38Z"
          />
          <path
            className="cls-4"
            d="M372,206a.27.27,0,1,0-.37.06A.26.26,0,0,0,372,206Z"
          />
          <path
            className="cls-4"
            d="M382.17,201a.27.27,0,1,0-.45-.31.27.27,0,0,0,.45.31Z"
          />
          <path
            className="cls-4"
            d="M392.49,196.31a.27.27,0,1,0-.38.06A.27.27,0,0,0,392.49,196.31Z"
          />
          <circle
            className="cls-4"
            cx="402.74"
            cy="191.81"
            r="0.27"
            transform="translate(12.04 407.08) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M413.55,188a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,0,.33A.24.24,0,0,0,413.55,188Z"
          />
          <path
            className="cls-4"
            d="M424.34,184.36a.3.3,0,0,0-.07-.42.3.3,0,1,0-.35.49A.31.31,0,0,0,424.34,184.36Z"
          />
          <path
            className="cls-4"
            d="M435.17,181.06a.26.26,0,0,0-.06-.37.27.27,0,0,0-.32.44A.27.27,0,0,0,435.17,181.06Z"
          />
          <path
            className="cls-4"
            d="M446.12,178.15a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.06.27.27,0,0,0,.07.38A.26.26,0,0,0,446.12,178.15Z"
          />
          <path
            className="cls-4"
            d="M457.17,175.6a.27.27,0,1,0-.38.07A.28.28,0,0,0,457.17,175.6Z"
          />
          <path
            className="cls-4"
            d="M468.3,173.42a.26.26,0,0,0-.06-.37.27.27,0,0,0-.38.06.27.27,0,1,0,.44.31Z"
          />
          <path
            className="cls-4"
            d="M479.52,171.63a.31.31,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,0,0,.06.42A.31.31,0,0,0,479.52,171.63Z"
          />
          <path
            className="cls-4"
            d="M490.76,170.2a.3.3,0,0,0-.49-.35.3.3,0,0,0,.07.42A.31.31,0,0,0,490.76,170.2Z"
          />
          <path
            className="cls-4"
            d="M502,169.12a.27.27,0,1,0-.37.06A.26.26,0,0,0,502,169.12Z"
          />
          <path
            className="cls-4"
            d="M513.37,168.45a.31.31,0,0,0-.07-.42.3.3,0,0,0-.42.07.3.3,0,1,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M524.67,168.13a.27.27,0,1,0-.37.06A.26.26,0,0,0,524.67,168.13Z"
          />
          <path
            className="cls-4"
            d="M536,168.2a.27.27,0,0,0-.06-.38.27.27,0,1,0,.06.38Z"
          />
          <path
            className="cls-4"
            d="M547.37,168.66a.31.31,0,0,0-.07-.42.3.3,0,1,0-.35.49A.31.31,0,0,0,547.37,168.66Z"
          />
          <path
            className="cls-4"
            d="M558.62,169a.32.32,0,0,0-.46.07.33.33,0,0,0,.07.46.33.33,0,1,0,.39-.53Z"
          />
          <circle
            className="cls-4"
            cx="569.7"
            cy="170.5"
            r="0.27"
            transform="translate(98.92 533.82) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M581.15,172.23a.27.27,0,0,0-.06-.38.28.28,0,0,0-.38.06.27.27,0,0,0,.06.38A.27.27,0,0,0,581.15,172.23Z"
          />
          <path
            className="cls-4"
            d="M592.32,174.17a.26.26,0,0,0-.06-.37.27.27,0,1,0,.06.37Z"
          />
          <path
            className="cls-4"
            d="M603.44,176.51a.29.29,0,0,0-.07-.42.3.3,0,0,0-.42.06.31.31,0,0,0,.07.42A.3.3,0,0,0,603.44,176.51Z"
          />
          <path
            className="cls-4"
            d="M614.39,178.77a.3.3,0,0,0-.35.49.31.31,0,0,0,.42-.07A.29.29,0,0,0,614.39,178.77Z"
          />
          <path
            className="cls-4"
            d="M625.33,182.2a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,0,.33A.24.24,0,0,0,625.33,182.2Z"
          />
          <path
            className="cls-4"
            d="M636.17,185.62a.26.26,0,0,0-.06-.37.26.26,0,0,0-.38.06.27.27,0,0,0,.06.38A.28.28,0,0,0,636.17,185.62Z"
          />
          <path
            className="cls-4"
            d="M646.42,189.07a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M657.36,193.13a.26.26,0,0,0-.37.06.27.27,0,1,0,.37-.06Z"
          />
          <path
            className="cls-4"
            d="M667.78,197.59a.27.27,0,0,0-.31.45.28.28,0,0,0,.38-.07A.27.27,0,0,0,667.78,197.59Z"
          />
          <path
            className="cls-4"
            d="M678.05,202.41a.27.27,0,1,0,.06.37A.26.26,0,0,0,678.05,202.41Z"
          />
          <path
            className="cls-4"
            d="M688.15,207.56a.27.27,0,1,0-.31.44.27.27,0,0,0,.31-.44Z"
          />
          <path
            className="cls-4"
            d="M698.16,213.43a.3.3,0,0,0-.07-.42.31.31,0,0,0-.42.07.3.3,0,0,0,.07.42A.29.29,0,0,0,698.16,213.43Z"
          />
          <path
            className="cls-4"
            d="M707.9,219.24a.3.3,0,0,0-.49-.35.3.3,0,0,0,.49.35Z"
          />
          <path
            className="cls-4"
            d="M717,225.44a.31.31,0,0,0,.43-.07.31.31,0,0,0-.07-.42.3.3,0,0,0-.36.49Z"
          />
          <path
            className="cls-4"
            d="M726.34,231.88a.29.29,0,0,0,.42-.07.28.28,0,0,0-.07-.41.3.3,0,0,0-.42.06A.31.31,0,0,0,726.34,231.88Z"
          />
          <path
            className="cls-4"
            d="M735.38,238.21a.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.06.31.31,0,0,0-.07-.43A.31.31,0,0,0,735.38,238.21Z"
          />
          <path
            className="cls-4"
            d="M744.26,245.26a.31.31,0,0,0,.07.42.3.3,0,0,0,.35-.49A.31.31,0,0,0,744.26,245.26Z"
          />
          <path
            className="cls-4"
            d="M753.32,252.53a.31.31,0,0,0-.42.07.31.31,0,0,0,.07.42.3.3,0,1,0,.35-.49Z"
          />
          <path
            className="cls-4"
            d="M761.7,260.18a.27.27,0,1,0,.06.38A.27.27,0,0,0,761.7,260.18Z"
          />
          <circle
            className="cls-4"
            cx="769.68"
            cy="268.3"
            r="0.27"
            transform="translate(102.82 737.01) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M777.7,276.24a.27.27,0,0,0-.38.07.26.26,0,0,0,.06.37.27.27,0,0,0,.32-.44Z"
          />
          <path
            className="cls-4"
            d="M784.91,284.73a.27.27,0,0,0,.44.31.27.27,0,1,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M792.2,293.38a.3.3,0,0,0,.06.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.29.29,0,0,0,792.2,293.38Z"
          />
          <path
            className="cls-4"
            d="M799.61,302.24a.26.26,0,0,0-.37.06.27.27,0,1,0,.37-.06Z"
          />
          <path
            className="cls-4"
            d="M806,311.44a.26.26,0,0,0,.06.37.27.27,0,1,0-.06-.37Z"
          />
          <path
            className="cls-4"
            d="M812.75,320.7a.3.3,0,1,0-.35.49.3.3,0,0,0,.35-.49Z"
          />
          <path
            className="cls-4"
            d="M818.43,330.33a.3.3,0,0,0,.49.35.31.31,0,0,0-.07-.42A.31.31,0,0,0,818.43,330.33Z"
          />
          <path
            className="cls-4"
            d="M824.22,340.11a.27.27,0,0,0,.07.38.27.27,0,0,0,.31-.44A.27.27,0,0,0,824.22,340.11Z"
          />
          <path
            className="cls-4"
            d="M829.64,350a.32.32,0,0,0,.07.43.31.31,0,0,0,.42-.07.29.29,0,0,0-.07-.42A.3.3,0,0,0,829.64,350Z"
          />
          <path
            className="cls-4"
            d="M834.78,360.17a.27.27,0,1,0,.38-.06A.27.27,0,0,0,834.78,360.17Z"
          />
          <path
            className="cls-4"
            d="M839.55,370.46a.26.26,0,0,0,.06.37.26.26,0,0,0,.38-.06.27.27,0,1,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M844.37,380.81a.3.3,0,0,0-.42.07.31.31,0,0,0,.07.42.31.31,0,0,0,.42-.07A.29.29,0,0,0,844.37,380.81Z"
          />
          <path
            className="cls-4"
            d="M848.45,391.39a.3.3,0,0,0-.42.06.31.31,0,0,0,.07.42.3.3,0,0,0,.42-.06A.29.29,0,0,0,848.45,391.39Z"
          />
          <path
            className="cls-4"
            d="M851.76,402.16a.29.29,0,0,0,.07.42.3.3,0,0,0,.42-.06.32.32,0,0,0-.07-.43A.31.31,0,0,0,851.76,402.16Z"
          />
          <path
            className="cls-4"
            d="M855.15,413a.27.27,0,0,0,.06.38.28.28,0,0,0,.38-.07.27.27,0,0,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M858.15,423.94a.27.27,0,0,0,.06.38.28.28,0,0,0,.38-.06.27.27,0,0,0-.06-.38A.27.27,0,0,0,858.15,423.94Z"
          />
          <path
            className="cls-4"
            d="M860.77,435a.3.3,0,0,0,.07.42.3.3,0,0,0,.35-.49A.29.29,0,0,0,860.77,435Z"
          />
          <path
            className="cls-4"
            d="M863.06,446.07a.28.28,0,0,0,.06.38.27.27,0,0,0,.38-.06.27.27,0,1,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M865,457.25a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,1,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M866.47,468.47a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,866.47,468.47Z"
          />
          <path
            className="cls-4"
            d="M867.65,479.76a.27.27,0,0,0,.44.32.28.28,0,0,0-.06-.38A.27.27,0,0,0,867.65,479.76Z"
          />
          <path
            className="cls-4"
            d="M868.4,491.06a.29.29,0,0,0,.07.41.3.3,0,0,0,.42-.06.3.3,0,0,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M868.8,502.39a.31.31,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,0,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M868.83,513.72a.3.3,0,0,0,.49.35.3.3,0,0,0-.07-.42A.31.31,0,0,0,868.83,513.72Z"
          />
          <path
            className="cls-4"
            d="M868.48,525.06a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,868.48,525.06Z"
          />
          <path
            className="cls-4"
            d="M868.19,536.28a.33.33,0,1,0,.07.46A.32.32,0,0,0,868.19,536.28Z"
          />
          <path
            className="cls-4"
            d="M866.73,548.05a.27.27,0,1,0-.06-.38A.27.27,0,0,0,866.73,548.05Z"
          />
          <path
            className="cls-4"
            d="M865.19,558.91a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M863.34,570.1a.27.27,0,0,0,.44.31.27.27,0,1,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M861.1,581.2a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,861.1,581.2Z"
          />
          <path
            className="cls-4"
            d="M858.51,592.23a.29.29,0,0,0,.07.42.3.3,0,0,0,.42-.06.31.31,0,0,0-.07-.42A.3.3,0,0,0,858.51,592.23Z"
          />
          <path
            className="cls-4"
            d="M855.58,603.2a.27.27,0,1,0,.44.31.27.27,0,0,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M852.26,614a.27.27,0,1,0,.44.31.26.26,0,0,0-.06-.37A.27.27,0,0,0,852.26,614Z"
          />
          <path
            className="cls-4"
            d="M848.58,624.76a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M844.53,635.34a.3.3,0,0,0,.49.35.3.3,0,1,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M840.17,645.82a.27.27,0,0,0,.07.38.27.27,0,0,0,.31-.44A.27.27,0,0,0,840.17,645.82Z"
          />
          <path
            className="cls-4"
            d="M835.45,656.12a.27.27,0,1,0,.38-.06A.27.27,0,0,0,835.45,656.12Z"
          />
          <path
            className="cls-4"
            d="M830.38,666.27a.26.26,0,0,0,.06.37.27.27,0,0,0,.38-.06.27.27,0,0,0-.06-.38A.28.28,0,0,0,830.38,666.27Z"
          />
          <path
            className="cls-4"
            d="M825,676.22a.3.3,0,0,0,.07.42.3.3,0,0,0,.42-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,825,676.22Z"
          />
          <path
            className="cls-4"
            d="M819.23,686a.3.3,0,1,0,.42-.07A.31.31,0,0,0,819.23,686Z"
          />
          <path
            className="cls-4"
            d="M813.18,695.59a.3.3,0,1,0,.42-.07A.3.3,0,0,0,813.18,695.59Z"
          />
          <path
            className="cls-4"
            d="M806.81,705a.31.31,0,0,0,.07.42.3.3,0,1,0,.35-.49A.31.31,0,0,0,806.81,705Z"
          />
          <path
            className="cls-4"
            d="M800.14,714.14a.3.3,0,0,0,.07.42.31.31,0,0,0,.42-.07.3.3,0,1,0-.49-.35Z"
          />
          <path
            className="cls-4"
            d="M793.17,723.08a.3.3,0,0,0,.06.42.32.32,0,0,0,.43-.07.31.31,0,0,0-.07-.42A.29.29,0,0,0,793.17,723.08Z"
          />
          <path
            className="cls-4"
            d="M785.9,731.78a.3.3,0,0,0,.07.42.3.3,0,1,0,.35-.49A.29.29,0,0,0,785.9,731.78Z"
          />
          <path
            className="cls-4"
            d="M778.34,740.23a.31.31,0,0,0,.07.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.32.32,0,0,0,778.34,740.23Z"
          />
          <path
            className="cls-4"
            d="M770.54,748.45a.27.27,0,0,0,.06.38.28.28,0,0,0,.38-.06.27.27,0,0,0-.06-.38A.27.27,0,0,0,770.54,748.45Z"
          />
          <path
            className="cls-4"
            d="M762.44,756.38a.27.27,0,0,0,.06.38.27.27,0,1,0-.06-.38Z"
          />
          <path
            className="cls-4"
            d="M754.08,764a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,1,0-.44-.32Z"
          />
          <circle
            className="cls-4"
            cx="745.7"
            cy="771.58"
            r="0.27"
            transform="translate(-315.98 927.27) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M736.63,778.51a.27.27,0,1,0,.38-.06A.27.27,0,0,0,736.63,778.51Z"
          />
          <path
            className="cls-4"
            d="M727.55,785.3a.27.27,0,1,0,.38-.06A.27.27,0,0,0,727.55,785.3Z"
          />
          <path
            className="cls-4"
            d="M718.27,791.81a.24.24,0,0,0,.4.28.25.25,0,0,0-.06-.34A.24.24,0,0,0,718.27,791.81Z"
          />
          <path
            className="cls-4"
            d="M708.72,797.94a.3.3,0,0,0,.06.42.29.29,0,0,0,.42-.07.3.3,0,0,0-.06-.42A.31.31,0,0,0,708.72,797.94Z"
          />
          <path
            className="cls-4"
            d="M699,803.81a.27.27,0,0,0,.06.38.27.27,0,1,0-.06-.38Z"
          />
          <path
            className="cls-4"
            d="M689.13,809.34a.27.27,0,1,0,.38-.06A.27.27,0,0,0,689.13,809.34Z"
          />
          <circle className="cls-4" cx="684.87" cy="826.02" r="0.24" />
          <path
            className="cls-4"
            d="M674.46,830.76a.21.21,0,0,0,0,.29.2.2,0,0,0,.29,0,.21.21,0,0,0,0-.29A.21.21,0,0,0,674.46,830.76Z"
          />
          <path
            className="cls-4"
            d="M664,835.27a.25.25,0,0,0,.06.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.06-.33A.24.24,0,0,0,664,835.27Z"
          />
          <path
            className="cls-4"
            d="M653.54,839.49a.21.21,0,1,0,.29,0A.2.2,0,0,0,653.54,839.49Z"
          />
          <path
            className="cls-4"
            d="M642.85,843.33a.23.23,0,0,0,.06.33.23.23,0,0,0,.33,0,.24.24,0,1,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M632.1,846.86a.21.21,0,1,0,.29-.05A.2.2,0,0,0,632.1,846.86Z"
          />
          <path
            className="cls-4"
            d="M621.52,850a.24.24,0,0,0-.28.39.24.24,0,1,0,.28-.39Z"
          />
          <path
            className="cls-4"
            d="M610.2,852.82a.25.25,0,0,0,.06.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.4-.28Z"
          />
          <path
            className="cls-4"
            d="M599.16,855.3a.22.22,0,0,0,.05.3.22.22,0,0,0,.25-.35A.23.23,0,0,0,599.16,855.3Z"
          />
          <path
            className="cls-4"
            d="M588,857.39a.24.24,0,0,0,0,.34.25.25,0,0,0,.34-.06.24.24,0,0,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M577.13,859.08a.24.24,0,0,0-.33.06.24.24,0,0,0,.05.34.25.25,0,0,0,.34-.06A.24.24,0,0,0,577.13,859.08Z"
          />
          <path
            className="cls-4"
            d="M565.57,860.55a.21.21,0,1,0,.29,0A.2.2,0,0,0,565.57,860.55Z"
          />
          <path
            className="cls-4"
            d="M554.25,861.55a.24.24,0,0,0,.06.34.24.24,0,0,0,.33,0,.24.24,0,0,0,0-.34A.25.25,0,0,0,554.25,861.55Z"
          />
          <path
            className="cls-4"
            d="M543,862.24a.21.21,0,1,0,.29,0A.2.2,0,0,0,543,862.24Z"
          />
          <path
            className="cls-4"
            d="M532,862.8a.23.23,0,0,0,0-.33.24.24,0,1,0-.28.39A.23.23,0,0,0,532,862.8Z"
          />
          <path
            className="cls-4"
            d="M520.32,862.8a.24.24,0,1,0-.06-.34A.25.25,0,0,0,520.32,862.8Z"
          />
          <path
            className="cls-4"
            d="M508.91,862a.26.26,0,0,0,.06.37.27.27,0,1,0,.32-.44A.28.28,0,0,0,508.91,862Z"
          />
          <circle className="cls-4" cx="497.82" cy="861.39" r="0.24" />
          <path
            className="cls-4"
            d="M486.34,860.1a.24.24,0,0,0,.06.33.24.24,0,1,0,.28-.39A.24.24,0,0,0,486.34,860.1Z"
          />
          <path
            className="cls-4"
            d="M475.13,858.6a.22.22,0,0,0,.05.3.22.22,0,1,0-.05-.3Z"
          />
          <path
            className="cls-4"
            d="M463.93,856.71a.24.24,0,1,0,.33-.05A.24.24,0,0,0,463.93,856.71Z"
          />
          <path
            className="cls-4"
            d="M453.15,854.43a.24.24,0,0,0-.34.06.23.23,0,0,0,.06.33.24.24,0,0,0,.33-.05A.25.25,0,0,0,453.15,854.43Z"
          />
          <path
            className="cls-4"
            d="M441.8,851.92a.2.2,0,0,0,0,.29.21.21,0,1,0,.25-.34A.21.21,0,0,0,441.8,851.92Z"
          />
          <path
            className="cls-4"
            d="M431.16,848.91a.24.24,0,0,0-.34.06.24.24,0,0,0,.05.33.24.24,0,0,0,.34,0A.25.25,0,0,0,431.16,848.91Z"
          />
          <path
            className="cls-4"
            d="M420,845.68a.25.25,0,0,0,0,.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.05-.33A.24.24,0,0,0,420,845.68Z"
          />
          <circle
            className="cls-4"
            cx="409.42"
            cy="842.19"
            r="0.21"
            transform="translate(-513.48 683.54) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M398.64,838.09a.21.21,0,1,0,.29,0A.2.2,0,0,0,398.64,838.09Z"
          />
          <path
            className="cls-4"
            d="M388.15,833.78a.21.21,0,1,0,.29,0A.22.22,0,0,0,388.15,833.78Z"
          />
          <path
            className="cls-4"
            d="M377.78,829.12a.25.25,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0-.05-.34A.25.25,0,0,0,377.78,829.12Z"
          />
          <path
            className="cls-4"
            d="M367.59,824.14a.25.25,0,1,0,.34,0A.25.25,0,0,0,367.59,824.14Z"
          />
          <path
            className="cls-4"
            d="M357.6,818.86a.21.21,0,1,0,.29-.05A.22.22,0,0,0,357.6,818.86Z"
          />
          <path
            className="cls-4"
            d="M347.75,813.25a.21.21,0,1,0,.29,0A.2.2,0,0,0,347.75,813.25Z"
          />
          <path
            className="cls-4"
            d="M338.09,807.32a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.2.2,0,0,0,338.09,807.32Z"
          />
          <path
            className="cls-4"
            d="M328.91,801a.21.21,0,1,0,0,.29A.2.2,0,0,0,328.91,801Z"
          />
          <path
            className="cls-4"
            d="M319.65,794.49a.22.22,0,1,0,.05.3A.23.23,0,0,0,319.65,794.49Z"
          />
          <path
            className="cls-4"
            d="M310.65,788a.22.22,0,0,0,0-.3.21.21,0,0,0-.29.05.22.22,0,0,0,0,.3A.23.23,0,0,0,310.65,788Z"
          />
          <path
            className="cls-4"
            d="M301.48,780.59a.21.21,0,1,0,.34.25.2.2,0,0,0-.05-.29A.21.21,0,0,0,301.48,780.59Z"
          />
          <path
            className="cls-4"
            d="M292.89,773.2a.21.21,0,1,0,.29-.05A.2.2,0,0,0,292.89,773.2Z"
          />
          <path
            className="cls-4"
            d="M284.51,765.51a.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.39A.24.24,0,0,0,284.51,765.51Z"
          />
          <path
            className="cls-4"
            d="M276.41,757.58a.24.24,0,1,0,.39.28.24.24,0,0,0-.05-.33A.24.24,0,0,0,276.41,757.58Z"
          />
          <path
            className="cls-4"
            d="M268.59,749.41a.22.22,0,0,0,.05.3.22.22,0,0,0,.25-.35A.22.22,0,0,0,268.59,749.41Z"
          />
          <path
            className="cls-4"
            d="M261,741a.21.21,0,1,0,.29-.05A.2.2,0,0,0,261,741Z"
          />
          <path
            className="cls-4"
            d="M253.76,732.6a.22.22,0,0,0,.3-.05.21.21,0,0,0-.05-.29.22.22,0,0,0-.3,0A.23.23,0,0,0,253.76,732.6Z"
          />
          <path
            className="cls-4"
            d="M246.69,723.4a.22.22,0,1,0,.3,0A.22.22,0,0,0,246.69,723.4Z"
          />
          <path
            className="cls-4"
            d="M239.94,714.26a.24.24,0,0,0,.05.34.25.25,0,0,0,.34-.06.24.24,0,0,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M233.82,704.9a.21.21,0,0,0-.29,0,.21.21,0,1,0,.34.25A.2.2,0,0,0,233.82,704.9Z"
          />
          <path
            className="cls-4"
            d="M227.39,695.41a.22.22,0,0,0,.05.29.21.21,0,0,0,.3-.05.22.22,0,0,0-.05-.29A.21.21,0,0,0,227.39,695.41Z"
          />
          <path
            className="cls-4"
            d="M221.91,685.93a.21.21,0,0,0,0-.3.22.22,0,0,0-.29,0,.21.21,0,0,0,.05.3A.22.22,0,0,0,221.91,685.93Z"
          />
          <path
            className="cls-4"
            d="M216.06,675.77a.21.21,0,1,0,.29,0A.22.22,0,0,0,216.06,675.77Z"
          />
          <path
            className="cls-4"
            d="M211.24,666a.24.24,0,0,0-.06-.34.24.24,0,0,0-.33,0,.24.24,0,0,0,.05.34A.25.25,0,0,0,211.24,666Z"
          />
          <path
            className="cls-4"
            d="M206.35,655.7a.21.21,0,0,0,0-.3.22.22,0,0,0-.29.05.2.2,0,0,0,.05.29A.21.21,0,0,0,206.35,655.7Z"
          />
          <path
            className="cls-4"
            d="M201.82,645.31a.22.22,0,0,0,0-.3.2.2,0,0,0-.29,0,.21.21,0,0,0,0,.29A.22.22,0,0,0,201.82,645.31Z"
          />
          <path
            className="cls-4"
            d="M197.25,634.51a.25.25,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0,0-.34A.25.25,0,0,0,197.25,634.51Z"
          />
          <path
            className="cls-4"
            d="M193.47,624.16a.21.21,0,1,0-.05-.29A.2.2,0,0,0,193.47,624.16Z"
          />
          <path
            className="cls-4"
            d="M190.25,613.33a.21.21,0,1,0-.29,0A.2.2,0,0,0,190.25,613.33Z"
          />
          <path
            className="cls-4"
            d="M186.74,602.2a.2.2,0,0,0,0,.29.21.21,0,0,0,.29,0,.21.21,0,1,0-.34-.25Z"
          />
          <path
            className="cls-4"
            d="M184.29,591.48a.24.24,0,0,0,0-.33.24.24,0,1,0,0,.33Z"
          />
          <path
            className="cls-4"
            d="M181.8,580.4a.21.21,0,1,0-.29.05A.22.22,0,0,0,181.8,580.4Z"
          />
          <path
            className="cls-4"
            d="M179.7,569.26a.22.22,0,0,0,0-.3.22.22,0,1,0,0,.3Z"
          />
          <path
            className="cls-4"
            d="M178,558.06a.21.21,0,0,0,0-.3.21.21,0,1,0-.24.34A.21.21,0,0,0,178,558.06Z"
          />
          <path
            className="cls-4"
            d="M176.59,546.82a.24.24,0,1,0-.34.06A.25.25,0,0,0,176.59,546.82Z"
          />
          <path
            className="cls-4"
            d="M175.56,535.53a.24.24,0,0,0-.06-.34.24.24,0,0,0-.28.4A.25.25,0,0,0,175.56,535.53Z"
          />
          <path
            className="cls-4"
            d="M174.87,524.19a.21.21,0,1,0-.34-.24.21.21,0,0,0,0,.29A.21.21,0,0,0,174.87,524.19Z"
          />
          <path
            className="cls-4"
            d="M174.59,512.88a.24.24,0,1,0-.39-.28.24.24,0,0,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M174.63,501.52a.21.21,0,1,0-.29.05A.2.2,0,0,0,174.63,501.52Z"
          />
          <path
            className="cls-4"
            d="M174.74,490.27a.25.25,0,0,0,.34-.06.24.24,0,0,0-.39-.28A.25.25,0,0,0,174.74,490.27Z"
          />
          <path
            className="cls-4"
            d="M175.5,478.64a.21.21,0,0,0,.34.24.21.21,0,0,0,0-.29A.21.21,0,0,0,175.5,478.64Z"
          />
          <path
            className="cls-4"
            d="M177,467.29a.23.23,0,0,0-.33,0,.24.24,0,1,0,.39.28A.23.23,0,0,0,177,467.29Z"
          />
          <path
            className="cls-4"
            d="M178.51,456.37a.22.22,0,1,0-.35-.25.22.22,0,0,0,.05.3A.23.23,0,0,0,178.51,456.37Z"
          />
          <path
            className="cls-4"
            d="M180.38,445.19a.23.23,0,0,0,0-.3.22.22,0,0,0-.25.35A.23.23,0,0,0,180.38,445.19Z"
          />
          <path
            className="cls-4"
            d="M182.6,434.07a.2.2,0,0,0,0-.29.21.21,0,0,0-.29,0,.21.21,0,0,0,.05.3A.21.21,0,0,0,182.6,434.07Z"
          />
          <path
            className="cls-4"
            d="M185.19,423a.22.22,0,0,0-.35-.25.23.23,0,0,0,0,.3A.22.22,0,0,0,185.19,423Z"
          />
          <path
            className="cls-4"
            d="M188.15,412.1a.25.25,0,0,0-.06-.34.24.24,0,0,0-.28.39A.24.24,0,0,0,188.15,412.1Z"
          />
          <path
            className="cls-4"
            d="M191.43,401.25A.24.24,0,1,0,191,401a.23.23,0,0,0,.06.33A.24.24,0,0,0,191.43,401.25Z"
          />
          <path
            className="cls-4"
            d="M195,390.47a.18.18,0,1,0-.25,0A.18.18,0,0,0,195,390.47Z"
          />
          <path
            className="cls-4"
            d="M199,379.87a.2.2,0,0,0,0-.29.21.21,0,0,0-.29,0,.21.21,0,1,0,.34.25Z"
          />
          <path
            className="cls-4"
            d="M203.32,369.38a.21.21,0,0,0,0-.29.21.21,0,0,0-.3.05.21.21,0,0,0,.34.24Z"
          />
          <path
            className="cls-4"
            d="M208,359a.21.21,0,1,0-.29,0A.2.2,0,0,0,208,359Z"
          />
          <path
            className="cls-4"
            d="M213,348.87a.24.24,0,0,0-.39-.28.24.24,0,0,0,0,.34A.25.25,0,0,0,213,348.87Z"
          />
          <path
            className="cls-4"
            d="M218.24,338.83a.21.21,0,0,0,0-.29.21.21,0,1,0-.25.34A.2.2,0,0,0,218.24,338.83Z"
          />
          <path
            className="cls-4"
            d="M223.86,329a.21.21,0,1,0-.29,0A.2.2,0,0,0,223.86,329Z"
          />
          <path
            className="cls-4"
            d="M229.79,319.32a.2.2,0,0,0,0-.29.21.21,0,0,0-.29,0,.21.21,0,1,0,.34.25Z"
          />
          <path
            className="cls-4"
            d="M236,309.85a.21.21,0,0,0-.35-.24.22.22,0,0,0,0,.29A.21.21,0,0,0,236,309.85Z"
          />
          <path
            className="cls-4"
            d="M242.56,300.59a.2.2,0,0,0,0-.29.21.21,0,1,0-.25.34A.21.21,0,0,0,242.56,300.59Z"
          />
          <path
            className="cls-4"
            d="M249.4,291.54a.22.22,0,0,0-.35-.25.22.22,0,1,0,.35.25Z"
          />
          <path
            className="cls-4"
            d="M256.51,282.72a.21.21,0,0,0-.05-.3.21.21,0,0,0-.24.34A.21.21,0,0,0,256.51,282.72Z"
          />
          <path
            className="cls-4"
            d="M263.91,274.12a.21.21,0,1,0-.29.05A.2.2,0,0,0,263.91,274.12Z"
          />
          <path
            className="cls-4"
            d="M271.6,265.79a.25.25,0,0,0-.05-.34.24.24,0,0,0-.34.06.24.24,0,0,0,.05.33A.24.24,0,0,0,271.6,265.79Z"
          />
          <path
            className="cls-4"
            d="M279.53,257.69a.24.24,0,1,0-.39-.28.24.24,0,0,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M287.7,249.83a.22.22,0,1,0-.3,0A.23.23,0,0,0,287.7,249.83Z"
          />
          <path
            className="cls-4"
            d="M296.13,242.25a.21.21,0,1,0-.29.05A.2.2,0,0,0,296.13,242.25Z"
          />
          <path
            className="cls-4"
            d="M304.8,235a.2.2,0,0,0-.05-.29.21.21,0,0,0-.29,0,.21.21,0,0,0,.05.3A.21.21,0,0,0,304.8,235Z"
          />
          <path
            className="cls-4"
            d="M313.71,227.93a.22.22,0,0,0,0-.29.22.22,0,0,0-.3,0,.22.22,0,1,0,.35.25Z"
          />
          <path
            className="cls-4"
            d="M322.85,221.22a.24.24,0,0,0-.05-.34.25.25,0,0,0-.34.05.24.24,0,0,0,.06.34A.24.24,0,0,0,322.85,221.22Z"
          />
          <path
            className="cls-4"
            d="M332.19,214.78a.24.24,0,1,0-.4-.28.24.24,0,0,0,.06.33A.24.24,0,0,0,332.19,214.78Z"
          />
          <path
            className="cls-4"
            d="M341.7,208.63a.21.21,0,0,0-.05-.29.22.22,0,0,0-.3,0,.23.23,0,0,0,0,.3A.22.22,0,0,0,341.7,208.63Z"
          />
          <path
            className="cls-4"
            d="M351.45,202.82a.24.24,0,0,0-.39-.28.24.24,0,1,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M361.33,197.29a.21.21,0,0,0,0-.29.21.21,0,0,0-.3.05.21.21,0,0,0,.34.24Z"
          />
          <path
            className="cls-4"
            d="M371.46,192.14a.27.27,0,1,0-.44-.31.27.27,0,0,0,.44.31Z"
          />
          <path
            className="cls-4"
            d="M381.68,187.26a.24.24,0,1,0-.39-.28.24.24,0,1,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M392.07,182.73a.24.24,0,1,0-.39-.28.24.24,0,0,0,.39.28Z"
          />
          <path
            className="cls-4"
            d="M402.58,178.51a.21.21,0,1,0-.29.05A.2.2,0,0,0,402.58,178.51Z"
          />
          <path
            className="cls-4"
            d="M413.24,174.66a.21.21,0,1,0-.34-.25.2.2,0,0,0,.05.29A.21.21,0,0,0,413.24,174.66Z"
          />
          <path
            className="cls-4"
            d="M424,171.16a.24.24,0,0,0-.05-.34.25.25,0,0,0-.34.06.24.24,0,0,0,.06.33A.23.23,0,0,0,424,171.16Z"
          />
          <path
            className="cls-4"
            d="M434.91,168a.21.21,0,1,0-.3.05A.21.21,0,0,0,434.91,168Z"
          />
          <path
            className="cls-4"
            d="M445.89,165.16a.2.2,0,0,0-.05-.29.21.21,0,0,0-.29,0,.21.21,0,1,0,.34.25Z"
          />
          <path
            className="cls-4"
            d="M457,162.71a.23.23,0,0,0-.06-.33.24.24,0,0,0-.28.39A.24.24,0,0,0,457,162.71Z"
          />
          <path
            className="cls-4"
            d="M468.1,160.59a.22.22,0,0,0-.35-.25.23.23,0,0,0,.05.3A.21.21,0,0,0,468.1,160.59Z"
          />
          <path
            className="cls-4"
            d="M479.3,158.84a.21.21,0,0,0-.35-.24.22.22,0,0,0,.05.29A.21.21,0,0,0,479.3,158.84Z"
          />
          <path
            className="cls-4"
            d="M490.57,157.47a.23.23,0,0,0-.05-.33.24.24,0,0,0-.34.05.25.25,0,0,0,.06.34A.24.24,0,0,0,490.57,157.47Z"
          />
          <path
            className="cls-4"
            d="M501.55,156.47a.21.21,0,0,0,.29,0,.21.21,0,0,0,0-.3.22.22,0,0,0-.29.05A.2.2,0,0,0,501.55,156.47Z"
          />
          <path
            className="cls-4"
            d="M513.18,155.78a.24.24,0,0,0,0-.34.25.25,0,0,0-.34.06.24.24,0,0,0,.06.34A.24.24,0,0,0,513.18,155.78Z"
          />
          <path
            className="cls-4"
            d="M524.52,155.48a.25.25,0,0,0-.06-.34.24.24,0,0,0-.34.06.24.24,0,0,0,.06.33A.24.24,0,0,0,524.52,155.48Z"
          />
          <path
            className="cls-4"
            d="M535.46,155.26a.25.25,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0-.05-.34A.25.25,0,0,0,535.46,155.26Z"
          />
          <path
            className="cls-4"
            d="M547.16,156a.21.21,0,0,0,0-.3.22.22,0,0,0-.29,0,.21.21,0,0,0,0,.3A.22.22,0,0,0,547.16,156Z"
          />
          <circle className="cls-4" cx="558.3" cy="156.61" r="0.24" />
          <path
            className="cls-4"
            d="M569.77,157.9a.23.23,0,0,0,0-.33.24.24,0,1,0-.28.39A.23.23,0,0,0,569.77,157.9Z"
          />
          <path
            className="cls-4"
            d="M581,159.42a.24.24,0,0,0-.06-.34.24.24,0,0,0-.28.39A.25.25,0,0,0,581,159.42Z"
          />
          <path
            className="cls-4"
            d="M591.82,161a.22.22,0,1,0,.3-.05A.23.23,0,0,0,591.82,161Z"
          />
          <path
            className="cls-4"
            d="M603,163.54a.21.21,0,0,0,.29,0,.21.21,0,1,0-.34-.25A.2.2,0,0,0,603,163.54Z"
          />
          <path
            className="cls-4"
            d="M614.35,166.1a.25.25,0,0,0-.06-.34.24.24,0,0,0-.28.39A.25.25,0,0,0,614.35,166.1Z"
          />
          <path
            className="cls-4"
            d="M625.22,168.72a.21.21,0,1,0-.24.34.21.21,0,0,0,.29,0A.21.21,0,0,0,625.22,168.72Z"
          />
          <path
            className="cls-4"
            d="M635.76,172a.24.24,0,0,0,0,.33.24.24,0,1,0,.28-.39A.23.23,0,0,0,635.76,172Z"
          />
          <path
            className="cls-4"
            d="M646.89,176a.24.24,0,0,0-.06-.34.23.23,0,0,0-.33.06.23.23,0,0,0,0,.33A.24.24,0,0,0,646.89,176Z"
          />
          <path
            className="cls-4"
            d="M657.45,179.59a.23.23,0,0,0-.33,0,.24.24,0,0,0,0,.34.25.25,0,0,0,.34-.06A.23.23,0,0,0,657.45,179.59Z"
          />
          <path
            className="cls-4"
            d="M667.94,183.9a.25.25,0,0,0-.34,0,.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.39Z"
          />
          <path
            className="cls-4"
            d="M677.94,188.6a.24.24,0,1,0,.34-.06A.25.25,0,0,0,677.94,188.6Z"
          />
          <path
            className="cls-4"
            d="M688.13,193.57a.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.39A.25.25,0,0,0,688.13,193.57Z"
          />
          <path
            className="cls-4"
            d="M698.51,198.79a.27.27,0,0,0-.38.07.26.26,0,0,0,.06.37.27.27,0,1,0,.32-.44Z"
          />
          <path
            className="cls-4"
            d="M708,204.47a.27.27,0,1,0,.38-.06A.27.27,0,0,0,708,204.47Z"
          />
          <path
            className="cls-4"
            d="M718,210.34a.27.27,0,1,0,.06.38A.27.27,0,0,0,718,210.34Z"
          />
          <path
            className="cls-4"
            d="M727.17,217a.27.27,0,0,0,.38-.06.27.27,0,0,0-.06-.38.27.27,0,0,0-.32.44Z"
          />
          <path
            className="cls-4"
            d="M736.37,223.18a.26.26,0,0,0,.06.37.27.27,0,0,0,.38-.06.27.27,0,1,0-.44-.31Z"
          />
          <path
            className="cls-4"
            d="M745.8,229.94a.28.28,0,0,0-.38.07.27.27,0,1,0,.38-.07Z"
          />
          <path
            className="cls-4"
            d="M754.25,237.12a.27.27,0,0,0,.06.38.27.27,0,1,0,.31-.44A.27.27,0,0,0,754.25,237.12Z"
          />
          <path
            className="cls-4"
            d="M762.84,244.52a.27.27,0,1,0,.38-.06A.27.27,0,0,0,762.84,244.52Z"
          />
          <path
            className="cls-4"
            d="M771.19,252.19a.27.27,0,1,0,.44.31.26.26,0,0,0-.06-.37A.27.27,0,0,0,771.19,252.19Z"
          />
          <path
            className="cls-4"
            d="M779.31,260.14a.24.24,0,0,0,.06.33.24.24,0,0,0,.34-.05.25.25,0,0,0-.06-.34A.24.24,0,0,0,779.31,260.14Z"
          />
          <path
            className="cls-4"
            d="M787.49,268.27a.24.24,0,0,0-.28.39.25.25,0,0,0,.34-.05A.24.24,0,0,0,787.49,268.27Z"
          />
          <path
            className="cls-4"
            d="M795.09,276.68a.27.27,0,1,0,.06.38A.27.27,0,0,0,795.09,276.68Z"
          />
          <path
            className="cls-4"
            d="M802,285.43a.24.24,0,1,0,.39.28.23.23,0,0,0-.06-.33A.23.23,0,0,0,802,285.43Z"
          />
          <path
            className="cls-4"
            d="M809.06,294.33a.24.24,0,0,0,.05.34.25.25,0,0,0,.34-.05.24.24,0,0,0-.06-.34A.24.24,0,0,0,809.06,294.33Z"
          />
          <path
            className="cls-4"
            d="M815.79,303.46a.24.24,0,0,0,.05.33.24.24,0,1,0,.28-.39A.23.23,0,0,0,815.79,303.46Z"
          />
          <path
            className="cls-4"
            d="M822.2,312.77a.27.27,0,0,0,.06.38.27.27,0,0,0,.32-.44A.28.28,0,0,0,822.2,312.77Z"
          />
          <path
            className="cls-4"
            d="M828.69,322.27a.23.23,0,0,0-.33.06.23.23,0,0,0,0,.33.24.24,0,1,0,.28-.39Z"
          />
          <path
            className="cls-4"
            d="M834.18,332.05a.24.24,0,0,0,.06.34.24.24,0,0,0,.33-.05.24.24,0,0,0-.05-.34A.25.25,0,0,0,834.18,332.05Z"
          />
          <path
            className="cls-4"
            d="M839.69,342a.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.39A.25.25,0,0,0,839.69,342Z"
          />
          <path
            className="cls-4"
            d="M844.88,352a.25.25,0,0,0,0,.34.24.24,0,0,0,.34-.06.24.24,0,0,0,0-.33A.25.25,0,0,0,844.88,352Z"
          />
          <path
            className="cls-4"
            d="M850.08,362.23a.24.24,0,1,0-.28.39.24.24,0,0,0,.33-.05A.24.24,0,0,0,850.08,362.23Z"
          />
          <path
            className="cls-4"
            d="M854.33,373a.24.24,0,0,0,.34-.05.25.25,0,0,0-.06-.34.24.24,0,0,0-.28.39Z"
          />
          <path
            className="cls-4"
            d="M858.5,383.23a.21.21,0,1,0,.29-.05A.2.2,0,0,0,858.5,383.23Z"
          />
          <path
            className="cls-4"
            d="M862.31,393.85a.27.27,0,0,0,.44.32.28.28,0,0,0-.07-.38A.26.26,0,0,0,862.31,393.85Z"
          />
          <path
            className="cls-4"
            d="M865.84,404.65a.25.25,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0-.05-.34A.25.25,0,0,0,865.84,404.65Z"
          />
          <path
            className="cls-4"
            d="M869,415.54a.23.23,0,0,0,.06.33.24.24,0,0,0,.28-.39A.24.24,0,0,0,869,415.54Z"
          />
          <path
            className="cls-4"
            d="M871.83,426.52a.23.23,0,0,0,0,.33.24.24,0,1,0,.28-.39A.23.23,0,0,0,871.83,426.52Z"
          />
          <path
            className="cls-4"
            d="M874.29,437.58a.24.24,0,1,0,.34-.05A.25.25,0,0,0,874.29,437.58Z"
          />
          <path
            className="cls-4"
            d="M876.37,448.71a.27.27,0,1,0,.44.31.26.26,0,0,0-.06-.37A.27.27,0,0,0,876.37,448.71Z"
          />
          <path
            className="cls-4"
            d="M878.17,459.94a.21.21,0,0,0,.05.3.21.21,0,0,0,.24-.34A.21.21,0,0,0,878.17,459.94Z"
          />
          <path
            className="cls-4"
            d="M879.53,471.18a.24.24,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0,0-.34A.25.25,0,0,0,879.53,471.18Z"
          />
          <path
            className="cls-4"
            d="M880.56,482.47a.24.24,0,1,0,.39.28.24.24,0,1,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M881.22,493.79a.24.24,0,0,0,.06.33.24.24,0,0,0,.34-.05.24.24,0,0,0-.4-.28Z"
          />
          <path
            className="cls-4"
            d="M881.52,505.12a.25.25,0,0,0,.06.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.4-.28Z"
          />
          <path
            className="cls-4"
            d="M881.44,516.44a.27.27,0,1,0,.38-.06A.27.27,0,0,0,881.44,516.44Z"
          />
          <path
            className="cls-4"
            d="M881,527.79a.24.24,0,0,0,.05.33.24.24,0,1,0-.05-.33Z"
          />
          <circle className="cls-4" cx="880.45" cy="539.24" r="0.24" />
          <path
            className="cls-4"
            d="M879.1,550.38a.24.24,0,0,0,0,.33.24.24,0,0,0,.34-.05.25.25,0,0,0,0-.34A.24.24,0,0,0,879.1,550.38Z"
          />
          <path
            className="cls-4"
            d="M877.59,561.61a.25.25,0,0,0,0,.34.24.24,0,0,0,.34-.06.23.23,0,0,0-.06-.33A.24.24,0,0,0,877.59,561.61Z"
          />
          <path
            className="cls-4"
            d="M875.72,572.8a.23.23,0,0,0,0,.33.24.24,0,1,0,.28-.39A.23.23,0,0,0,875.72,572.8Z"
          />
          <path
            className="cls-4"
            d="M873.49,583.91a.25.25,0,0,0,0,.34.24.24,0,0,0,.34-.06.24.24,0,0,0,0-.33A.24.24,0,0,0,873.49,583.91Z"
          />
          <path
            className="cls-4"
            d="M870.91,595a.24.24,0,1,0,.39.28.24.24,0,0,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M868,605.9a.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.39A.24.24,0,0,0,868,605.9Z"
          />
          <circle className="cls-4" cx="864.88" cy="616.89" r="0.24" />
          <path
            className="cls-4"
            d="M861.05,627.49a.25.25,0,0,0,.06.34.24.24,0,1,0,.28-.39A.25.25,0,0,0,861.05,627.49Z"
          />
          <path
            className="cls-4"
            d="M857.06,638.09a.27.27,0,0,0,.06.38.27.27,0,1,0,.31-.44A.27.27,0,0,0,857.06,638.09Z"
          />
          <path
            className="cls-4"
            d="M852.77,648.6a.24.24,0,0,0,.05.33.24.24,0,0,0,.34,0,.24.24,0,1,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M848.12,658.94a.25.25,0,0,0,.06.34.24.24,0,0,0,.33-.06.24.24,0,0,0,0-.34A.25.25,0,0,0,848.12,658.94Z"
          />
          <path
            className="cls-4"
            d="M843.12,669.11a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,1,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M837.83,679.13a.27.27,0,1,0,.44.32.28.28,0,0,0-.07-.38A.26.26,0,0,0,837.83,679.13Z"
          />
          <path
            className="cls-4"
            d="M832.21,689a.27.27,0,0,0,.44.32.28.28,0,0,0-.06-.38A.27.27,0,0,0,832.21,689Z"
          />
          <path
            className="cls-4"
            d="M826.28,698.64a.27.27,0,0,0,.06.38.27.27,0,0,0,.32-.44A.28.28,0,0,0,826.28,698.64Z"
          />
          <path
            className="cls-4"
            d="M820,708.11a.27.27,0,1,0,.38-.06A.27.27,0,0,0,820,708.11Z"
          />
          <path
            className="cls-4"
            d="M813.5,717.37a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.06-.38A.28.28,0,0,0,813.5,717.37Z"
          />
          <path
            className="cls-4"
            d="M806.67,726.42a.27.27,0,0,0,.07.38.26.26,0,0,0,.37-.06.27.27,0,0,0-.44-.32Z"
          />
          <path
            className="cls-4"
            d="M799.56,735.25a.27.27,0,1,0,.37-.06A.26.26,0,0,0,799.56,735.25Z"
          />
          <path
            className="cls-4"
            d="M792.16,743.84a.27.27,0,1,0,.38-.06A.27.27,0,0,0,792.16,743.84Z"
          />
          <path
            className="cls-4"
            d="M784.52,752.21a.24.24,0,0,0,.05.34.25.25,0,0,0,.34-.06.24.24,0,0,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M776.58,760.31a.25.25,0,0,0,.06.34.24.24,0,0,0,.34-.06.24.24,0,0,0-.06-.33A.24.24,0,0,0,776.58,760.31Z"
          />
          <path
            className="cls-4"
            d="M768.4,768.15a.24.24,0,0,0,.05.34.25.25,0,0,0,.34,0,.24.24,0,0,0-.06-.34A.24.24,0,0,0,768.4,768.15Z"
          />
          <path
            className="cls-4"
            d="M759.94,775.71a.27.27,0,1,0,.38-.06A.27.27,0,0,0,759.94,775.71Z"
          />
          <path
            className="cls-4"
            d="M751.29,783a.24.24,0,0,0,.06.34.23.23,0,0,0,.33-.06.24.24,0,0,0,0-.33A.25.25,0,0,0,751.29,783Z"
          />
          <path
            className="cls-4"
            d="M742.39,790.05a.24.24,0,1,0,.39.28.23.23,0,0,0-.06-.33A.24.24,0,0,0,742.39,790.05Z"
          />
          <path
            className="cls-4"
            d="M733.29,796.8a.21.21,0,0,0,.05.3.22.22,0,0,0,.29-.05.21.21,0,0,0,0-.3A.22.22,0,0,0,733.29,796.8Z"
          />
          <path
            className="cls-4"
            d="M723.93,803.22a.24.24,0,1,0,.39.28.24.24,0,1,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M714.4,809.35a.24.24,0,1,0,.33-.05A.23.23,0,0,0,714.4,809.35Z"
          />
          <path
            className="cls-4"
            d="M704.69,815.2a.21.21,0,0,0,.35.24.22.22,0,0,0,0-.29A.21.21,0,0,0,704.69,815.2Z"
          />
          <path
            className="cls-4"
            d="M694.73,820.67a.28.28,0,0,0,.45.32.28.28,0,0,0-.07-.38A.27.27,0,0,0,694.73,820.67Z"
          />
          <circle className="cls-4" cx="690.48" cy="837.34" r="0.18" />
          <path
            className="cls-4"
            d="M680.06,842.08a.23.23,0,0,0,.05.3.21.21,0,0,0,.3,0,.21.21,0,0,0,0-.29A.22.22,0,0,0,680.06,842.08Z"
          />
          <path
            className="cls-4"
            d="M669.68,846.63a.21.21,0,1,0,.34.25.2.2,0,0,0,0-.29A.21.21,0,0,0,669.68,846.63Z"
          />
          <path
            className="cls-4"
            d="M659.16,850.86a.2.2,0,0,0,.05.29.21.21,0,0,0,.29,0,.21.21,0,1,0-.34-.25Z"
          />
          <path
            className="cls-4"
            d="M648.51,854.76a.22.22,0,0,0,.35.25.23.23,0,0,0-.05-.3A.21.21,0,0,0,648.51,854.76Z"
          />
          <path
            className="cls-4"
            d="M637.75,858.33a.21.21,0,0,0,0,.29.2.2,0,0,0,.29,0,.21.21,0,0,0,0-.29A.21.21,0,0,0,637.75,858.33Z"
          />
          <path
            className="cls-4"
            d="M627.18,861.51a.2.2,0,0,0-.29,0,.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34Z"
          />
          <circle className="cls-4" cx="616.09" cy="864.58" r="0.18" />
          <circle className="cls-4" cx="605.05" cy="867.13" r="0.18" />
          <path
            className="cls-4"
            d="M593.78,869.24a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.19.19,0,0,0-.05-.25A.18.18,0,0,0,593.78,869.24Z"
          />
          <path
            className="cls-4"
            d="M582.58,871.09a.21.21,0,0,0,0,.29.21.21,0,0,0,.3,0,.22.22,0,0,0,0-.29A.2.2,0,0,0,582.58,871.09Z"
          />
          <path
            className="cls-4"
            d="M571.34,872.6a.21.21,0,1,0,.34.25.2.2,0,0,0,0-.29A.21.21,0,0,0,571.34,872.6Z"
          />
          <path
            className="cls-4"
            d="M560.09,873.79a.18.18,0,1,0,.25,0A.18.18,0,0,0,560.09,873.79Z"
          />
          <path
            className="cls-4"
            d="M548.75,874.6a.22.22,0,0,0,0,.29.21.21,0,1,0,0-.29Z"
          />
          <path
            className="cls-4"
            d="M537.45,875.08a.19.19,0,0,0,0,.26.19.19,0,0,0,.26-.05.19.19,0,0,0,0-.25A.18.18,0,0,0,537.45,875.08Z"
          />
          <path
            className="cls-4"
            d="M526.09,875.19a.21.21,0,1,0,.29-.05A.2.2,0,0,0,526.09,875.19Z"
          />
          <path
            className="cls-4"
            d="M514.75,875a.23.23,0,0,0,0,.3.21.21,0,0,0,.3,0,.21.21,0,0,0-.05-.29A.22.22,0,0,0,514.75,875Z"
          />
          <path
            className="cls-4"
            d="M503.43,874.37a.22.22,0,0,0,.05.3.22.22,0,1,0-.05-.3Z"
          />
          <path
            className="cls-4"
            d="M492.16,873.46a.18.18,0,1,0,.25,0A.18.18,0,0,0,492.16,873.46Z"
          />
          <path
            className="cls-4"
            d="M480.87,872.16a.2.2,0,0,0,.05.29.21.21,0,0,0,.29,0,.21.21,0,1,0-.34-.25Z"
          />
          <path
            className="cls-4"
            d="M469.67,870.55a.18.18,0,0,0,.3.21.18.18,0,0,0-.05-.25A.18.18,0,0,0,469.67,870.55Z"
          />
          <path
            className="cls-4"
            d="M458.78,868.51a.22.22,0,0,0-.3,0,.23.23,0,0,0,0,.3.21.21,0,0,0,.3-.05A.22.22,0,0,0,458.78,868.51Z"
          />
          <path
            className="cls-4"
            d="M447.39,866.23a.21.21,0,0,0,.05.3.21.21,0,1,0,.24-.34A.21.21,0,0,0,447.39,866.23Z"
          />
          <path
            className="cls-4"
            d="M436.39,863.59a.18.18,0,1,0,.25,0A.18.18,0,0,0,436.39,863.59Z"
          />
          <path
            className="cls-4"
            d="M425.46,860.59a.18.18,0,1,0,.25,0A.18.18,0,0,0,425.46,860.59Z"
          />
          <path
            className="cls-4"
            d="M414.62,857.25a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.05-.25A.18.18,0,0,0,414.62,857.25Z"
          />
          <circle className="cls-4" cx="404.04" cy="853.68" r="0.18" />
          <circle className="cls-4" cx="393.44" cy="849.68" r="0.18" />
          <circle className="cls-4" cx="382.96" cy="845.35" r="0.18" />
          <circle className="cls-4" cx="372.62" cy="840.7" r="0.18" />
          <path
            className="cls-4"
            d="M362.26,835.61a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.2.2,0,0,0,362.26,835.61Z"
          />
          <path
            className="cls-4"
            d="M352.25,830.34a.18.18,0,1,0,.25,0A.18.18,0,0,0,352.25,830.34Z"
          />
          <circle className="cls-4" cx="342.53" cy="824.86" r="0.18" />
          <circle className="cls-4" cx="332.85" cy="818.96" r="0.18" />
          <path
            className="cls-4"
            d="M323.2,812.67a.18.18,0,1,0,.25,0A.18.18,0,0,0,323.2,812.67Z"
          />
          <circle className="cls-4" cx="314.04" cy="806.29" r="0.18" />
          <path
            className="cls-4"
            d="M304.8,799.42a.18.18,0,1,0,.25,0A.18.18,0,0,0,304.8,799.42Z"
          />
          <path
            className="cls-4"
            d="M295.92,792.38a.18.18,0,1,0,.25,0A.18.18,0,0,0,295.92,792.38Z"
          />
          <path
            className="cls-4"
            d="M287.25,785.06a.19.19,0,0,0,.05.26.18.18,0,0,0,.25-.05.18.18,0,0,0,0-.25A.19.19,0,0,0,287.25,785.06Z"
          />
          <path
            className="cls-4"
            d="M278.82,777.48a.19.19,0,0,0,.05.26.18.18,0,0,0,.25,0,.18.18,0,0,0,0-.25A.19.19,0,0,0,278.82,777.48Z"
          />
          <path
            className="cls-4"
            d="M270.65,769.92a.21.21,0,1,0,.25-.34.2.2,0,0,0-.29,0A.21.21,0,0,0,270.65,769.92Z"
          />
          <path
            className="cls-4"
            d="M262.66,761.54a.21.21,0,0,0,0,.3.22.22,0,0,0,.29-.05.21.21,0,0,0-.05-.3A.22.22,0,0,0,262.66,761.54Z"
          />
          <circle className="cls-4" cx="255.14" cy="753.34" r="0.18" />
          <path
            className="cls-4"
            d="M247.56,744.67a.18.18,0,0,0,.29.21.17.17,0,0,0,0-.25A.18.18,0,0,0,247.56,744.67Z"
          />
          <path
            className="cls-4"
            d="M240.65,735.84a.18.18,0,1,0,0,.25A.18.18,0,0,0,240.65,735.84Z"
          />
          <path
            className="cls-4"
            d="M233.81,727.08a.18.18,0,0,0-.05-.25.18.18,0,0,0-.21.29A.19.19,0,0,0,233.81,727.08Z"
          />
          <path
            className="cls-4"
            d="M226.91,717.66a.18.18,0,1,0,.25,0A.18.18,0,0,0,226.91,717.66Z"
          />
          <circle className="cls-4" cx="220.74" cy="708.35" r="0.18" />
          <path
            className="cls-4"
            d="M214.59,698.91a.21.21,0,1,0,.25-.34.2.2,0,0,0-.29,0A.21.21,0,0,0,214.59,698.91Z"
          />
          <path
            className="cls-4"
            d="M208.85,688.85a.18.18,0,1,0,.25,0A.18.18,0,0,0,208.85,688.85Z"
          />
          <path
            className="cls-4"
            d="M203.73,679.1a.18.18,0,0,0,0-.25.18.18,0,1,0-.21.29A.19.19,0,0,0,203.73,679.1Z"
          />
          <circle className="cls-4" cx="198.47" cy="668.87" r="0.18" />
          <path
            className="cls-4"
            d="M193.83,658.7a.18.18,0,1,0-.25,0A.18.18,0,0,0,193.83,658.7Z"
          />
          <path
            className="cls-4"
            d="M189.39,648.3a.22.22,0,1,0-.3.05A.23.23,0,0,0,189.39,648.3Z"
          />
          <path
            className="cls-4"
            d="M184.92,637.52a.18.18,0,0,0,.3.21.19.19,0,0,0-.05-.26A.19.19,0,0,0,184.92,637.52Z"
          />
          <path
            className="cls-4"
            d="M181.15,627.09a.18.18,0,1,0,0-.25A.18.18,0,0,0,181.15,627.09Z"
          />
          <path
            className="cls-4"
            d="M177.92,616.26a.18.18,0,0,0-.3-.21.18.18,0,0,0,0,.25A.19.19,0,0,0,177.92,616.26Z"
          />
          <path
            className="cls-4"
            d="M174.77,605.37a.18.18,0,0,0,0-.25.18.18,0,1,0-.21.29A.18.18,0,0,0,174.77,605.37Z"
          />
          <path
            className="cls-4"
            d="M171.93,594.37a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,0,.21A.15.15,0,0,0,171.93,594.37Z"
          />
          <path
            className="cls-4"
            d="M169.49,583.32a.18.18,0,1,0-.25,0A.18.18,0,0,0,169.49,583.32Z"
          />
          <path
            className="cls-4"
            d="M167.37,572.19a.2.2,0,0,0-.05-.26.18.18,0,0,0-.25,0,.19.19,0,0,0,0,.26A.2.2,0,0,0,167.37,572.19Z"
          />
          <path
            className="cls-4"
            d="M165.29,560.78a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.3-.21Z"
          />
          <circle className="cls-4" cx="164.01" cy="549.64" r="0.18" />
          <path
            className="cls-4"
            d="M163.05,538.44a.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <circle className="cls-4" cx="162.19" cy="527.04" r="0.18" />
          <circle className="cls-4" cx="161.81" cy="515.71" r="0.18" />
          <path
            className="cls-4"
            d="M161.92,504.47a.18.18,0,1,0-.25,0A.18.18,0,0,0,161.92,504.47Z"
          />
          <path
            className="cls-4"
            d="M162.24,493.14a.18.18,0,1,0-.25,0A.18.18,0,0,0,162.24,493.14Z"
          />
          <path
            className="cls-4"
            d="M162.68,481.84a.15.15,0,0,0,.21,0,.16.16,0,0,0,0-.21.16.16,0,0,0-.21,0A.15.15,0,0,0,162.68,481.84Z"
          />
          <path
            className="cls-4"
            d="M163.68,470.57a.18.18,0,1,0,0-.25A.18.18,0,0,0,163.68,470.57Z"
          />
          <path
            className="cls-4"
            d="M165.32,459.29a.21.21,0,1,0-.29,0A.2.2,0,0,0,165.32,459.29Z"
          />
          <path
            className="cls-4"
            d="M167,448.07a.18.18,0,1,0-.25,0A.18.18,0,0,0,167,448.07Z"
          />
          <path
            className="cls-4"
            d="M169.07,436.92a.18.18,0,1,0-.25,0A.18.18,0,0,0,169.07,436.92Z"
          />
          <path
            className="cls-4"
            d="M171.46,425.82a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.17Z"
          />
          <path
            className="cls-4"
            d="M174.23,414.84a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.19.19,0,0,0,0,.25A.18.18,0,0,0,174.23,414.84Z"
          />
          <circle className="cls-4" cx="177.17" cy="403.83" r="0.18" />
          <path
            className="cls-4"
            d="M180.74,393.12a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.19.19,0,0,0,.05.26A.19.19,0,0,0,180.74,393.12Z"
          />
          <path
            className="cls-4"
            d="M184.49,382.43a.18.18,0,1,0-.29-.21.18.18,0,1,0,.29.21Z"
          />
          <path
            className="cls-4"
            d="M188.58,371.85a.18.18,0,0,0,0-.25.18.18,0,1,0-.21.29A.18.18,0,0,0,188.58,371.85Z"
          />
          <circle className="cls-4" cx="192.84" cy="361.3" r="0.18" />
          <circle className="cls-4" cx="197.57" cy="351" r="0.18" />
          <path
            className="cls-4"
            d="M202.79,341a.22.22,0,0,0,0-.3.23.23,0,0,0-.3.05.21.21,0,0,0,.05.3A.22.22,0,0,0,202.79,341Z"
          />
          <path
            className="cls-4"
            d="M208.13,331a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.18.18,0,0,0,0,.25A.18.18,0,0,0,208.13,331Z"
          />
          <path
            className="cls-4"
            d="M213.79,321.14a.18.18,0,1,0-.25,0A.18.18,0,0,0,213.79,321.14Z"
          />
          <circle className="cls-4" cx="219.61" cy="311.4" r="0.18" />
          <circle className="cls-4" cx="225.88" cy="301.94" r="0.18" />
          <path
            className="cls-4"
            d="M232.58,292.8a.19.19,0,0,0,0-.26.2.2,0,0,0-.26,0,.18.18,0,0,0,.3.21Z"
          />
          <circle className="cls-4" cx="239.27" cy="283.65" r="0.18" />
          <path
            className="cls-4"
            d="M246.52,274.92a.17.17,0,0,0,0-.25.18.18,0,1,0,0,.25Z"
          />
          <circle className="cls-4" cx="253.76" cy="266.21" r="0.18" />
          <path
            className="cls-4"
            d="M261.55,258a.19.19,0,0,0,0-.26.18.18,0,0,0-.25.05.18.18,0,0,0,0,.25A.19.19,0,0,0,261.55,258Z"
          />
          <path
            className="cls-4"
            d="M269.48,249.83a.22.22,0,0,0-.05-.29.21.21,0,0,0-.3.05.22.22,0,0,0,.05.29A.21.21,0,0,0,269.48,249.83Z"
          />
          <path
            className="cls-4"
            d="M277.6,241.93a.18.18,0,1,0-.25,0A.18.18,0,0,0,277.6,241.93Z"
          />
          <circle className="cls-4" cx="285.84" cy="234.2" r="0.18" />
          <path
            className="cls-4"
            d="M294.61,226.94a.18.18,0,1,0-.25,0A.18.18,0,0,0,294.61,226.94Z"
          />
          <circle className="cls-4" cx="303.31" cy="219.74" r="0.18" />
          <path
            className="cls-4"
            d="M312.51,213a.18.18,0,1,0-.25,0A.18.18,0,0,0,312.51,213Z"
          />
          <path
            className="cls-4"
            d="M321.78,206.49a.18.18,0,1,0-.29-.21.18.18,0,1,0,.29.21Z"
          />
          <path
            className="cls-4"
            d="M331.24,200.25a.18.18,0,1,0-.25,0A.18.18,0,0,0,331.24,200.25Z"
          />
          <path
            className="cls-4"
            d="M340.92,194.32a.21.21,0,1,0-.34-.24.21.21,0,0,0,0,.29A.21.21,0,0,0,340.92,194.32Z"
          />
          <path
            className="cls-4"
            d="M350.73,188.66a.18.18,0,0,0-.05-.25.18.18,0,0,0-.21.29A.19.19,0,0,0,350.73,188.66Z"
          />
          <path
            className="cls-4"
            d="M360.73,183.32a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.18.18,0,0,0,.05.25A.18.18,0,0,0,360.73,183.32Z"
          />
          <path
            className="cls-4"
            d="M370.91,178.31a.21.21,0,0,0,0-.29.21.21,0,1,0-.25.34A.21.21,0,0,0,370.91,178.31Z"
          />
          <circle className="cls-4" cx="381.06" cy="173.48" r="0.18" />
          <path
            className="cls-4"
            d="M391.68,169.21a.21.21,0,1,0-.29,0A.2.2,0,0,0,391.68,169.21Z"
          />
          <path
            className="cls-4"
            d="M402.24,165.13a.18.18,0,1,0-.25,0A.18.18,0,0,0,402.24,165.13Z"
          />
          <path
            className="cls-4"
            d="M413,161.42a.21.21,0,0,0-.05-.3.21.21,0,1,0-.24.34A.21.21,0,0,0,413,161.42Z"
          />
          <circle className="cls-4" cx="423.62" cy="157.89" r="0.18" />
          <path
            className="cls-4"
            d="M434.68,154.93a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.19.19,0,0,0,0,.25A.19.19,0,0,0,434.68,154.93Z"
          />
          <circle className="cls-4" cx="445.54" cy="152.1" r="0.18" />
          <path
            className="cls-4"
            d="M456.79,149.84a.21.21,0,1,0-.29,0A.2.2,0,0,0,456.79,149.84Z"
          />
          <circle className="cls-4" cx="467.77" cy="147.68" r="0.18" />
          <circle className="cls-4" cx="478.98" cy="145.99" r="0.18" />
          <path
            className="cls-4"
            d="M490.39,144.75a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.18.18,0,0,0,.05.25A.18.18,0,0,0,490.39,144.75Z"
          />
          <path
            className="cls-4"
            d="M501.66,143.48a.21.21,0,0,0-.3.05.22.22,0,0,0,.05.29.21.21,0,0,0,.3,0A.22.22,0,0,0,501.66,143.48Z"
          />
          <path
            className="cls-4"
            d="M513,143.13a.21.21,0,1,0-.34-.25.21.21,0,0,0,.05.29A.2.2,0,0,0,513,143.13Z"
          />
          <path
            className="cls-4"
            d="M524.33,142.81a.18.18,0,1,0-.25,0A.18.18,0,0,0,524.33,142.81Z"
          />
          <path
            className="cls-4"
            d="M535.4,142.93a.22.22,0,0,0,.3,0,.23.23,0,0,0-.05-.3.22.22,0,0,0-.3,0A.22.22,0,0,0,535.4,142.93Z"
          />
          <path
            className="cls-4"
            d="M546.68,143.05a.22.22,0,0,0,.05.29.21.21,0,0,0,.3-.05.22.22,0,0,0,0-.29A.21.21,0,0,0,546.68,143.05Z"
          />
          <path
            className="cls-4"
            d="M558.34,144.05a.21.21,0,0,0-.34-.25.21.21,0,1,0,.34.25Z"
          />
          <path
            className="cls-4"
            d="M569.6,145.14a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.18.18,0,0,0,.05.25A.18.18,0,0,0,569.6,145.14Z"
          />
          <circle className="cls-4" cx="580.69" cy="146.49" r="0.18" />
          <path
            className="cls-4"
            d="M591.72,148.17a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.2.2,0,0,0,591.72,148.17Z"
          />
          <path
            className="cls-4"
            d="M602.87,150.33a.2.2,0,0,0,0,.26.19.19,0,0,0,.26-.05.18.18,0,0,0-.3-.21Z"
          />
          <path
            className="cls-4"
            d="M614.23,153a.19.19,0,0,0,0-.26.2.2,0,0,0-.26,0,.19.19,0,0,0,.3.22Z"
          />
          <path
            className="cls-4"
            d="M624.89,155.64a.2.2,0,0,0,.05.29.21.21,0,0,0,.29,0,.21.21,0,0,0-.05-.3A.21.21,0,0,0,624.89,155.64Z"
          />
          <circle
            className="cls-4"
            cx="635.94"
            cy="158.93"
            r="0.21"
            transform="translate(135.92 582.8) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M646.85,162.27a.21.21,0,0,0-.3,0,.22.22,0,0,0,.05.29.21.21,0,0,0,.3-.05A.22.22,0,0,0,646.85,162.27Z"
          />
          <path
            className="cls-4"
            d="M657.22,166.16a.21.21,0,0,0,.35.24.22.22,0,0,0-.05-.29A.21.21,0,0,0,657.22,166.16Z"
          />
          <path
            className="cls-4"
            d="M667.8,170.64a.24.24,0,0,0,.33,0,.24.24,0,0,0,0-.34.25.25,0,0,0-.34.05A.24.24,0,0,0,667.8,170.64Z"
          />
          <path
            className="cls-4"
            d="M678.49,175a.18.18,0,1,0-.25,0A.18.18,0,0,0,678.49,175Z"
          />
          <path
            className="cls-4"
            d="M688.47,179.64a.18.18,0,1,0,.25,0A.18.18,0,0,0,688.47,179.64Z"
          />
          <circle className="cls-4" cx="698.73" cy="184.87" r="0.18" />
          <path
            className="cls-4"
            d="M708.5,190.19a.22.22,0,0,0,0,.29.21.21,0,0,0,.3,0,.22.22,0,0,0-.05-.29A.21.21,0,0,0,708.5,190.19Z"
          />
          <path
            className="cls-4"
            d="M718.62,196.18a.21.21,0,0,0,0-.3.21.21,0,1,0-.24.34A.21.21,0,0,0,718.62,196.18Z"
          />
          <path
            className="cls-4"
            d="M728.22,202.22a.22.22,0,1,0-.3.05A.23.23,0,0,0,728.22,202.22Z"
          />
          <path
            className="cls-4"
            d="M737.27,208.31a.22.22,0,0,0,.05.29.21.21,0,1,0-.05-.29Z"
          />
          <path
            className="cls-4"
            d="M746.48,214.93a.22.22,0,0,0,0,.3.23.23,0,0,0,.3,0,.21.21,0,0,0-.05-.3A.21.21,0,0,0,746.48,214.93Z"
          />
          <path
            className="cls-4"
            d="M755.47,221.84a.21.21,0,1,0,.29-.05A.2.2,0,0,0,755.47,221.84Z"
          />
          <path
            className="cls-4"
            d="M764.24,229a.21.21,0,0,0,.35.24.22.22,0,0,0-.05-.29A.21.21,0,0,0,764.24,229Z"
          />
          <path
            className="cls-4"
            d="M772.79,236.47a.21.21,0,1,0,.29-.05A.2.2,0,0,0,772.79,236.47Z"
          />
          <path
            className="cls-4"
            d="M781.1,244.17a.23.23,0,0,0,0,.3.21.21,0,0,0,.3-.05.22.22,0,0,0-.35-.25Z"
          />
          <circle className="cls-4" cx="789.34" cy="252.26" r="0.18" />
          <path
            className="cls-4"
            d="M797.27,260.32a.18.18,0,0,0-.25,0,.18.18,0,1,0,.25,0Z"
          />
          <path
            className="cls-4"
            d="M804.53,268.78a.24.24,0,0,0,.39.28.24.24,0,1,0-.39-.28Z"
          />
          <path
            className="cls-4"
            d="M811.85,277.47a.21.21,0,0,0,0,.29.22.22,0,0,0,.3,0,.23.23,0,0,0-.05-.3A.22.22,0,0,0,811.85,277.47Z"
          />
          <path
            className="cls-4"
            d="M818.88,286.37a.21.21,0,1,0,.29-.05A.2.2,0,0,0,818.88,286.37Z"
          />
          <path
            className="cls-4"
            d="M825.62,295.48a.22.22,0,1,0,.35.25.22.22,0,1,0-.35-.25Z"
          />
          <path
            className="cls-4"
            d="M832.06,304.78a.24.24,0,0,0,.06.34.24.24,0,0,0,.28-.4A.25.25,0,0,0,832.06,304.78Z"
          />
          <path
            className="cls-4"
            d="M838.26,314.31a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.2.2,0,0,0,838.26,314.31Z"
          />
          <path
            className="cls-4"
            d="M844.13,324a.21.21,0,1,0,.29-.05A.2.2,0,0,0,844.13,324Z"
          />
          <path
            className="cls-4"
            d="M849.7,333.88a.21.21,0,0,0,0,.3.22.22,0,0,0,.29-.05.21.21,0,0,0,0-.3A.22.22,0,0,0,849.7,333.88Z"
          />
          <circle className="cls-4" cx="855.13" cy="344.05" r="0.18" />
          <path
            className="cls-4"
            d="M859.93,354.14a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.05-.25A.18.18,0,0,0,859.93,354.14Z"
          />
          <path
            className="cls-4"
            d="M864.85,364.4a.24.24,0,0,0-.34.06.23.23,0,0,0,.06.33.23.23,0,0,0,.33-.05A.24.24,0,0,0,864.85,364.4Z"
          />
          <path
            className="cls-4"
            d="M868.85,375a.22.22,0,0,0,0,.3.23.23,0,0,0,.3-.05.22.22,0,0,0-.05-.3A.22.22,0,0,0,868.85,375Z"
          />
          <path
            className="cls-4"
            d="M872.83,385.58a.21.21,0,0,0,0,.29.21.21,0,0,0,.3-.05.21.21,0,0,0-.34-.24Z"
          />
          <path
            className="cls-4"
            d="M876.48,396.31a.22.22,0,0,0,0,.3.23.23,0,0,0,.3-.05.22.22,0,0,0-.05-.3A.21.21,0,0,0,876.48,396.31Z"
          />
          <path
            className="cls-4"
            d="M879.79,407.15a.22.22,0,1,0,.3,0A.23.23,0,0,0,879.79,407.15Z"
          />
          <path
            className="cls-4"
            d="M882.77,418.09a.22.22,0,0,0,.05.3.22.22,0,1,0-.05-.3Z"
          />
          <path
            className="cls-4"
            d="M885.41,429.12a.21.21,0,0,0,.05.29.2.2,0,0,0,.29-.05.21.21,0,0,0,0-.29A.21.21,0,0,0,885.41,429.12Z"
          />
          <path
            className="cls-4"
            d="M888,440.17a.21.21,0,0,0-.29.05.2.2,0,0,0,0,.29.21.21,0,0,0,.29,0A.21.21,0,0,0,888,440.17Z"
          />
          <path
            className="cls-4"
            d="M889.69,451.41a.18.18,0,1,0,.25,0A.18.18,0,0,0,889.69,451.41Z"
          />
          <path
            className="cls-4"
            d="M891.27,462.61a.21.21,0,0,0,.05.3.22.22,0,0,0,.29-.05.21.21,0,0,0-.05-.3A.22.22,0,0,0,891.27,462.61Z"
          />
          <path
            className="cls-4"
            d="M892.53,473.88a.21.21,0,0,0,0,.29.22.22,0,0,0,.3,0,.23.23,0,0,0,0-.3A.21.21,0,0,0,892.53,473.88Z"
          />
          <path
            className="cls-4"
            d="M893.43,485.18a.22.22,0,0,0,.35.25.23.23,0,0,0,0-.3A.22.22,0,0,0,893.43,485.18Z"
          />
          <path
            className="cls-4"
            d="M894,496.5a.22.22,0,0,0,0,.3.2.2,0,0,0,.29-.05.21.21,0,0,0,0-.29A.22.22,0,0,0,894,496.5Z"
          />
          <path
            className="cls-4"
            d="M894.2,507.84a.2.2,0,0,0,0,.29.21.21,0,0,0,.29,0,.21.21,0,1,0-.34-.25Z"
          />
          <path
            className="cls-4"
            d="M894.06,519.18a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.2.2,0,0,0,894.06,519.18Z"
          />
          <path
            className="cls-4"
            d="M893.56,530.5a.22.22,0,0,0,.05.3.22.22,0,0,0,.25-.35A.23.23,0,0,0,893.56,530.5Z"
          />
          <path
            className="cls-4"
            d="M893,541.74a.24.24,0,0,0-.34,0,.25.25,0,0,0,.06.34.24.24,0,0,0,.28-.39Z"
          />
          <circle className="cls-4" cx="891.69" cy="553.21" r="0.18" />
          <path
            className="cls-4"
            d="M890,564.32a.21.21,0,0,0,0,.29.2.2,0,0,0,.29-.05.21.21,0,0,0,0-.29A.21.21,0,0,0,890,564.32Z"
          />
          <path
            className="cls-4"
            d="M888.09,575.49a.22.22,0,0,0,0,.3.2.2,0,0,0,.29,0,.21.21,0,0,0,0-.29A.22.22,0,0,0,888.09,575.49Z"
          />
          <path
            className="cls-4"
            d="M885.86,586.61a.21.21,0,0,0,0,.3.22.22,0,0,0,.29,0,.21.21,0,1,0-.34-.25Z"
          />
          <path
            className="cls-4"
            d="M883.28,597.65a.21.21,0,0,0,.05.3.22.22,0,0,0,.25-.35A.23.23,0,0,0,883.28,597.65Z"
          />
          <path
            className="cls-4"
            d="M880.36,608.61a.22.22,0,0,0,0,.29.21.21,0,0,0,.3,0,.22.22,0,0,0-.05-.29A.21.21,0,0,0,880.36,608.61Z"
          />
          <path
            className="cls-4"
            d="M877.11,619.47a.21.21,0,1,0,.29-.05A.2.2,0,0,0,877.11,619.47Z"
          />
          <path
            className="cls-4"
            d="M873.52,630.22a.21.21,0,0,0,.05.3.22.22,0,0,0,.29,0,.21.21,0,0,0-.05-.3A.22.22,0,0,0,873.52,630.22Z"
          />
          <path
            className="cls-4"
            d="M869.6,640.86a.21.21,0,1,0,.29-.05A.21.21,0,0,0,869.6,640.86Z"
          />
          <circle className="cls-4" cx="865.52" cy="651.49" r="0.18" />
          <circle className="cls-4" cx="860.95" cy="661.87" r="0.18" />
          <path
            className="cls-4"
            d="M855.91,672a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.05-.25A.18.18,0,0,0,855.91,672Z"
          />
          <path
            className="cls-4"
            d="M850.68,682.05a.22.22,0,0,0,.05.29.21.21,0,0,0,.3-.05.22.22,0,0,0,0-.29A.21.21,0,0,0,850.68,682.05Z"
          />
          <path
            className="cls-4"
            d="M845.17,692a.21.21,0,1,0,.34.25.2.2,0,0,0,0-.29A.21.21,0,0,0,845.17,692Z"
          />
          <path
            className="cls-4"
            d="M839.35,701.69a.21.21,0,0,0,0,.29.2.2,0,0,0,.29-.05.21.21,0,0,0,0-.29A.21.21,0,0,0,839.35,701.69Z"
          />
          <path
            className="cls-4"
            d="M833.24,711.23a.22.22,0,0,0,0,.3.23.23,0,0,0,.3,0,.22.22,0,0,0-.05-.3A.22.22,0,0,0,833.24,711.23Z"
          />
          <path
            className="cls-4"
            d="M826.83,720.58a.21.21,0,1,0,.34.25.2.2,0,0,0,0-.29A.21.21,0,0,0,826.83,720.58Z"
          />
          <path
            className="cls-4"
            d="M820.13,729.73a.22.22,0,0,0,.35.25.22.22,0,0,0-.05-.29A.22.22,0,0,0,820.13,729.73Z"
          />
          <path
            className="cls-4"
            d="M813.16,738.67a.21.21,0,0,0,.05.3.22.22,0,0,0,.29-.05.21.21,0,0,0,0-.3A.22.22,0,0,0,813.16,738.67Z"
          />
          <path
            className="cls-4"
            d="M805.91,747.39a.21.21,0,0,0,.05.3.22.22,0,0,0,.25-.35A.22.22,0,0,0,805.91,747.39Z"
          />
          <path
            className="cls-4"
            d="M798.4,755.88a.21.21,0,0,0,0,.29.21.21,0,1,0,.25-.34A.21.21,0,0,0,798.4,755.88Z"
          />
          <path
            className="cls-4"
            d="M790.65,764.15a.18.18,0,1,0,.25,0A.18.18,0,0,0,790.65,764.15Z"
          />
          <path
            className="cls-4"
            d="M782.62,772.16a.18.18,0,1,0,.25,0A.18.18,0,0,0,782.62,772.16Z"
          />
          <path
            className="cls-4"
            d="M774.35,779.92a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.2.2,0,0,0,0-.26A.19.19,0,0,0,774.35,779.92Z"
          />
          <path
            className="cls-4"
            d="M765.82,787.39a.22.22,0,0,0,0,.3.21.21,0,0,0,.3-.05.22.22,0,0,0-.35-.25Z"
          />
          <path
            className="cls-4"
            d="M757.09,794.62a.21.21,0,0,0,0,.3.21.21,0,1,0,.24-.34A.21.21,0,0,0,757.09,794.62Z"
          />
          <path
            className="cls-4"
            d="M748.14,801.58a.21.21,0,0,0,0,.29.22.22,0,0,0,.3,0,.21.21,0,0,0-.05-.3A.21.21,0,0,0,748.14,801.58Z"
          />
          <path
            className="cls-4"
            d="M739,808.26a.22.22,0,0,0,0,.29.21.21,0,0,0,.3,0,.22.22,0,0,0-.05-.29A.21.21,0,0,0,739,808.26Z"
          />
          <path
            className="cls-4"
            d="M729.61,814.65a.21.21,0,1,0,.29,0A.2.2,0,0,0,729.61,814.65Z"
          />
          <path
            className="cls-4"
            d="M720.07,820.76a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.3-.21Z"
          />
          <path
            className="cls-4"
            d="M710.33,826.56a.18.18,0,1,0,.25,0A.18.18,0,0,0,710.33,826.56Z"
          />
          <circle className="cls-4" cx="700.56" cy="832.15" r="0.18" />
          <path
            className="cls-4"
            d="M696,848.57a.15.15,0,1,0,.21,0A.15.15,0,0,0,696,848.57Z"
          />
          <path
            className="cls-4"
            d="M685.72,853.45a.15.15,0,1,0,.24.17.16.16,0,0,0,0-.21A.15.15,0,0,0,685.72,853.45Z"
          />
          <path
            className="cls-4"
            d="M675.34,858a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <circle className="cls-4" cx="664.95" cy="862.35" r="0.18" />
          <path
            className="cls-4"
            d="M654.2,866.2a.15.15,0,0,0,0,.21.15.15,0,0,0,.18-.24A.16.16,0,0,0,654.2,866.2Z"
          />
          <path
            className="cls-4"
            d="M643.45,869.82a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M632.6,873.11a.17.17,0,0,0,0,.22.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.16.16,0,0,0,632.6,873.11Z"
          />
          <path
            className="cls-4"
            d="M621.64,876.07a.18.18,0,0,0,.29.21.19.19,0,0,0,0-.26A.18.18,0,0,0,621.64,876.07Z"
          />
          <path
            className="cls-4"
            d="M610.64,878.72a.15.15,0,0,0,.24.18.16.16,0,0,0,0-.21A.14.14,0,0,0,610.64,878.72Z"
          />
          <path
            className="cls-4"
            d="M599.51,881a.19.19,0,0,0,0,.26.19.19,0,0,0,.26,0,.18.18,0,0,0,0-.25A.18.18,0,0,0,599.51,881Z"
          />
          <circle className="cls-4" cx="588.49" cy="883.09" r="0.18" />
          <path
            className="cls-4"
            d="M577.15,884.65a.15.15,0,1,0,.21,0A.15.15,0,0,0,577.15,884.65Z"
          />
          <circle className="cls-4" cx="566.01" cy="886.04" r="0.18" />
          <path
            className="cls-4"
            d="M554.57,886.9a.18.18,0,1,0,.25,0A.18.18,0,0,0,554.57,886.9Z"
          />
          <path
            className="cls-4"
            d="M543.25,887.53a.18.18,0,1,0,.25,0A.18.18,0,0,0,543.25,887.53Z"
          />
          <path
            className="cls-4"
            d="M532.15,887.8a.15.15,0,0,0-.17.25.16.16,0,0,0,.21,0A.16.16,0,0,0,532.15,887.8Z"
          />
          <circle className="cls-4" cx="520.73" cy="887.87" r="0.18" />
          <path
            className="cls-4"
            d="M509.27,887.4a.15.15,0,1,0,.21,0A.15.15,0,0,0,509.27,887.4Z"
          />
          <path
            className="cls-4"
            d="M498,886.67a.16.16,0,0,0,0,.21.15.15,0,0,0,.18-.25A.16.16,0,0,0,498,886.67Z"
          />
          <circle className="cls-4" cx="486.79" cy="885.69" r="0.18" />
          <path
            className="cls-4"
            d="M475.4,884.18a.18.18,0,1,0,.25,0A.18.18,0,0,0,475.4,884.18Z"
          />
          <path
            className="cls-4"
            d="M464.22,882.46a.15.15,0,0,0,0,.21.15.15,0,1,0,.18-.24A.15.15,0,0,0,464.22,882.46Z"
          />
          <path
            className="cls-4"
            d="M453.07,880.39a.15.15,0,0,0,.25.17.16.16,0,0,0,0-.21A.15.15,0,0,0,453.07,880.39Z"
          />
          <path
            className="cls-4"
            d="M442,878a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.15.15,0,0,0,442,878Z"
          />
          <path
            className="cls-4"
            d="M431,875.25a.15.15,0,1,0,.24.17.15.15,0,0,0,0-.21A.15.15,0,0,0,431,875.25Z"
          />
          <path
            className="cls-4"
            d="M420.07,872.18a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M409.23,868.77a.19.19,0,0,0,0,.26.19.19,0,0,0,.25,0,.19.19,0,0,0,0-.26A.18.18,0,0,0,409.23,868.77Z"
          />
          <path
            className="cls-4"
            d="M398.54,865.08a.15.15,0,0,0,0,.21.15.15,0,1,0,.17-.24A.15.15,0,0,0,398.54,865.08Z"
          />
          <path
            className="cls-4"
            d="M388,861.05a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.13.13,0,0,0,388,861.05Z"
          />
          <path
            className="cls-4"
            d="M377.45,856.69a.18.18,0,1,0,.25,0A.18.18,0,0,0,377.45,856.69Z"
          />
          <circle className="cls-4" cx="367.26" cy="852.14" r="0.18" />
          <circle className="cls-4" cx="357.06" cy="847.17" r="0.18" />
          <path
            className="cls-4"
            d="M346.9,841.82a.15.15,0,1,0,.21,0A.15.15,0,0,0,346.9,841.82Z"
          />
          <path
            className="cls-4"
            d="M337,836.25a.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0,.16.16,0,0,0,0-.21A.16.16,0,0,0,337,836.25Z"
          />
          <path
            className="cls-4"
            d="M327.32,830.39a.15.15,0,1,0,.21,0A.16.16,0,0,0,327.32,830.39Z"
          />
          <path
            className="cls-4"
            d="M317.79,824.25a.15.15,0,0,0,0,.21.14.14,0,0,0,.2,0,.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M308.66,817.79a.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21.15.15,0,1,0,.18-.24Z"
          />
          <path
            className="cls-4"
            d="M299.55,811.29a.15.15,0,1,0-.24-.17.16.16,0,0,0,0,.21A.15.15,0,0,0,299.55,811.29Z"
          />
          <path
            className="cls-4"
            d="M290.58,804.11a.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21.15.15,0,1,0,.18-.24Z"
          />
          <path
            className="cls-4"
            d="M281.64,796.9a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M273.14,789.41a.16.16,0,0,0,0,.21.15.15,0,0,0,.18-.25A.15.15,0,0,0,273.14,789.41Z"
          />
          <path
            className="cls-4"
            d="M264.84,781.64a.18.18,0,1,0,.25,0A.18.18,0,0,0,264.84,781.64Z"
          />
          <path
            className="cls-4"
            d="M256.8,773.65a.18.18,0,1,0,.25,0A.18.18,0,0,0,256.8,773.65Z"
          />
          <path
            className="cls-4"
            d="M249.23,765.4a.15.15,0,1,0,0,.21A.16.16,0,0,0,249.23,765.4Z"
          />
          <path
            className="cls-4"
            d="M241.47,757a.15.15,0,1,0,.21,0A.16.16,0,0,0,241.47,757Z"
          />
          <path
            className="cls-4"
            d="M234.39,748.26a.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21.15.15,0,0,0,.18-.25Z"
          />
          <circle className="cls-4" cx="227.27" cy="739.49" r="0.18" />
          <path
            className="cls-4"
            d="M220.6,730.27a.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0A.15.15,0,0,0,220.6,730.27Z"
          />
          <path
            className="cls-4"
            d="M214.11,721a.15.15,0,1,0-.17.24.16.16,0,0,0,.21,0A.15.15,0,0,0,214.11,721Z"
          />
          <path
            className="cls-4"
            d="M207.72,711.53a.13.13,0,0,0,0,.17.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17A.13.13,0,0,0,207.72,711.53Z"
          />
          <path
            className="cls-4"
            d="M201.78,701.84a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.15.15,0,0,0,201.78,701.84Z"
          />
          <path
            className="cls-4"
            d="M196.16,692a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,1,0-.24-.17Z"
          />
          <path
            className="cls-4"
            d="M191.1,682.18a.18.18,0,0,0-.3-.21.19.19,0,0,0,0,.26A.19.19,0,0,0,191.1,682.18Z"
          />
          <path
            className="cls-4"
            d="M185.8,671.83a.15.15,0,0,0,.25.17.15.15,0,0,0-.25-.17Z"
          />
          <path
            className="cls-4"
            d="M181.32,661.7a.15.15,0,0,0-.24-.18.16.16,0,0,0,0,.21A.15.15,0,0,0,181.32,661.7Z"
          />
          <path
            className="cls-4"
            d="M176.91,651.25a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21A.15.15,0,0,0,176.91,651.25Z"
          />
          <path
            className="cls-4"
            d="M172.77,640.49a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,172.77,640.49Z"
          />
          <path
            className="cls-4"
            d="M168.83,630a.15.15,0,0,0,.21,0,.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0A.14.14,0,0,0,168.83,630Z"
          />
          <path
            className="cls-4"
            d="M165.58,619.19a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21A.15.15,0,0,0,165.58,619.19Z"
          />
          <path
            className="cls-4"
            d="M162.21,608.12a.15.15,0,1,0,.24.18.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M159.65,597.31a.16.16,0,0,0,0-.21.17.17,0,0,0-.22,0,.17.17,0,0,0,0,.22A.16.16,0,0,0,159.65,597.31Z"
          />
          <path
            className="cls-4"
            d="M157.18,586.25a.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21A.17.17,0,0,0,157.18,586.25Z"
          />
          <path
            className="cls-4"
            d="M155,575.11a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,0,.21A.15.15,0,0,0,155,575.11Z"
          />
          <path
            className="cls-4"
            d="M153.23,563.92a.16.16,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,0,.21A.16.16,0,0,0,153.23,563.92Z"
          />
          <path
            className="cls-4"
            d="M151.75,552.68a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,1,0,.24.18Z"
          />
          <path
            className="cls-4"
            d="M150.62,541.4a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <path
            className="cls-4"
            d="M149.82,530.09a.15.15,0,1,0-.21,0A.15.15,0,0,0,149.82,530.09Z"
          />
          <path
            className="cls-4"
            d="M149.36,518.76a.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,.24.18Z"
          />
          <path
            className="cls-4"
            d="M149.24,507.42a.16.16,0,0,0,0-.21.16.16,0,0,0-.22,0,.16.16,0,0,0,0,.21A.15.15,0,0,0,149.24,507.42Z"
          />
          <path
            className="cls-4"
            d="M149.46,496.09a.16.16,0,0,0,0-.21.15.15,0,1,0-.18.24A.15.15,0,0,0,149.46,496.09Z"
          />
          <path
            className="cls-4"
            d="M150,484.76a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.17Z"
          />
          <path
            className="cls-4"
            d="M150.7,473.5a.15.15,0,0,0,.18-.25.15.15,0,0,0-.21,0A.16.16,0,0,0,150.7,473.5Z"
          />
          <path
            className="cls-4"
            d="M152.12,462a.15.15,0,0,0-.18.25.15.15,0,0,0,.21,0A.16.16,0,0,0,152.12,462Z"
          />
          <path
            className="cls-4"
            d="M153.72,451a.15.15,0,1,0-.24-.17.15.15,0,0,0,0,.21A.15.15,0,0,0,153.72,451Z"
          />
          <path
            className="cls-4"
            d="M155.63,439.79a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,0,.21A.15.15,0,0,0,155.63,439.79Z"
          />
          <path
            className="cls-4"
            d="M157.87,428.67a.15.15,0,1,0-.21,0A.15.15,0,0,0,157.87,428.67Z"
          />
          <path
            className="cls-4"
            d="M160.39,417.45a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.11.11,0,0,0,160.39,417.45Z"
          />
          <path
            className="cls-4"
            d="M163.34,406.67a.15.15,0,1,0-.25-.17.16.16,0,0,0,0,.21A.16.16,0,0,0,163.34,406.67Z"
          />
          <path
            className="cls-4"
            d="M166.57,395.8a.16.16,0,0,0,0-.21.15.15,0,1,0,0,.21Z"
          />
          <path
            className="cls-4"
            d="M170.12,385a.15.15,0,0,0-.25-.18.15.15,0,0,0,0,.21A.16.16,0,0,0,170.12,385Z"
          />
          <path
            className="cls-4"
            d="M174,374.38a.15.15,0,0,0,0-.21.15.15,0,1,0-.17.24A.15.15,0,0,0,174,374.38Z"
          />
          <path
            className="cls-4"
            d="M178.18,363.84a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,1,0,.25.17Z"
          />
          <path
            className="cls-4"
            d="M182.68,353.44a.15.15,0,1,0-.21,0A.15.15,0,0,0,182.68,353.44Z"
          />
          <path
            className="cls-4"
            d="M187.51,343.19a.18.18,0,1,0-.25,0A.18.18,0,0,0,187.51,343.19Z"
          />
          <circle className="cls-4" cx="192.48" cy="332.97" r="0.18" />
          <path
            className="cls-4"
            d="M198,323.09a.15.15,0,1,0-.24-.17.15.15,0,0,0,0,.21A.15.15,0,0,0,198,323.09Z"
          />
          <path
            className="cls-4"
            d="M203.73,313.3a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.17.17,0,0,0,0,.22A.16.16,0,0,0,203.73,313.3Z"
          />
          <path
            className="cls-4"
            d="M209.74,303.68a.16.16,0,0,0,0-.21.15.15,0,1,0,0,.21Z"
          />
          <path
            className="cls-4"
            d="M216,294.25a.15.15,0,1,0-.24-.18.15.15,0,0,0,.24.18Z"
          />
          <path
            className="cls-4"
            d="M222.59,285a.15.15,0,0,0,0-.21.13.13,0,0,0-.2,0,.15.15,0,0,0,0,.21A.16.16,0,0,0,222.59,285Z"
          />
          <path
            className="cls-4"
            d="M229.43,276a.16.16,0,0,0,0-.21.15.15,0,0,0-.18.25A.15.15,0,0,0,229.43,276Z"
          />
          <path
            className="cls-4"
            d="M236.54,267.13a.15.15,0,1,0-.21,0A.16.16,0,0,0,236.54,267.13Z"
          />
          <path
            className="cls-4"
            d="M243.91,258.51a.16.16,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.17Z"
          />
          <path
            className="cls-4"
            d="M251.53,250.12a.16.16,0,0,0,0-.21.15.15,0,1,0-.18.25A.15.15,0,0,0,251.53,250.12Z"
          />
          <circle className="cls-4" cx="259.28" cy="241.87" r="0.18" />
          <path
            className="cls-4"
            d="M267.54,234.06a.18.18,0,1,0-.26,0A.19.19,0,0,0,267.54,234.06Z"
          />
          <circle className="cls-4" cx="275.73" cy="226.28" r="0.18" />
          <path
            className="cls-4"
            d="M284.43,218.94a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.17Z"
          />
          <path
            className="cls-4"
            d="M293.22,211.78a.15.15,0,1,0-.21,0A.16.16,0,0,0,293.22,211.78Z"
          />
          <path
            className="cls-4"
            d="M302.24,204.9a.18.18,0,1,0-.25,0A.18.18,0,0,0,302.24,204.9Z"
          />
          <path
            className="cls-4"
            d="M311.42,198.26a.15.15,0,1,0-.21,0A.15.15,0,0,0,311.42,198.26Z"
          />
          <path
            className="cls-4"
            d="M320.81,191.92a.15.15,0,1,0-.24-.18.15.15,0,0,0,.24.18Z"
          />
          <path
            className="cls-4"
            d="M330.39,185.86a.15.15,0,1,0-.24-.18.16.16,0,0,0,0,.21A.15.15,0,0,0,330.39,185.86Z"
          />
          <path
            className="cls-4"
            d="M340.15,180.08a.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,0,.21A.15.15,0,0,0,340.15,180.08Z"
          />
          <path
            className="cls-4"
            d="M350,174.42a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.16,0A.12.12,0,0,0,350,174.42Z"
          />
          <circle className="cls-4" cx="360.04" cy="169.34" r="0.18" />
          <path
            className="cls-4"
            d="M370.4,164.55a.16.16,0,0,0,0-.21.15.15,0,0,0-.18.25A.15.15,0,0,0,370.4,164.55Z"
          />
          <path
            className="cls-4"
            d="M380.78,160a.15.15,0,1,0-.21,0A.16.16,0,0,0,380.78,160Z"
          />
          <path
            className="cls-4"
            d="M391.29,155.73a.15.15,0,1,0-.21,0A.15.15,0,0,0,391.29,155.73Z"
          />
          <path
            className="cls-4"
            d="M401.94,151.81a.18.18,0,0,0-.29-.21.19.19,0,0,0,0,.26A.19.19,0,0,0,401.94,151.81Z"
          />
          <path
            className="cls-4"
            d="M412.66,148.18a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,0,.21A.13.13,0,0,0,412.66,148.18Z"
          />
          <path
            className="cls-4"
            d="M423.51,144.89a.15.15,0,1,0-.24-.18.15.15,0,0,0,0,.21A.16.16,0,0,0,423.51,144.89Z"
          />
          <path
            className="cls-4"
            d="M434.45,141.92a.15.15,0,1,0-.24-.18.15.15,0,0,0,0,.21A.14.14,0,0,0,434.45,141.92Z"
          />
          <circle className="cls-4" cx="445.36" cy="139.19" r="0.18" />
          <path
            className="cls-4"
            d="M456.37,137a.15.15,0,0,0,.21,0,.15.15,0,0,0-.24-.18A.14.14,0,0,0,456.37,137Z"
          />
          <path
            className="cls-4"
            d="M467.75,135a.16.16,0,0,0,0-.21.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21A.16.16,0,0,0,467.75,135Z"
          />
          <path
            className="cls-4"
            d="M479,133.37a.18.18,0,0,0,0-.25.19.19,0,0,0-.26,0,.18.18,0,0,0,.05.25A.18.18,0,0,0,479,133.37Z"
          />
          <path
            className="cls-4"
            d="M490.23,132.05a.16.16,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <path
            className="cls-4"
            d="M501.51,130.85a.19.19,0,0,0-.26,0,.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0A.18.18,0,0,0,501.51,130.85Z"
          />
          <path
            className="cls-4"
            d="M512.6,130.28a.15.15,0,0,0,0,.21.15.15,0,0,0,.18-.25A.16.16,0,0,0,512.6,130.28Z"
          />
          <path
            className="cls-4"
            d="M523.93,130a.15.15,0,0,0,.25.17.16.16,0,0,0,0-.21A.16.16,0,0,0,523.93,130Z"
          />
          <path
            className="cls-4"
            d="M535.3,130.25a.15.15,0,0,0,.18-.25.15.15,0,0,0-.21,0A.16.16,0,0,0,535.3,130.25Z"
          />
          <path
            className="cls-4"
            d="M546.87,130.62a.18.18,0,1,0-.25,0A.18.18,0,0,0,546.87,130.62Z"
          />
          <path
            className="cls-4"
            d="M558.16,131.33a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.17.17,0,0,0,0,.22A.16.16,0,0,0,558.16,131.33Z"
          />
          <path
            className="cls-4"
            d="M569.47,132.41a.18.18,0,0,0-.29-.21.19.19,0,0,0,0,.26A.18.18,0,0,0,569.47,132.41Z"
          />
          <path
            className="cls-4"
            d="M580.45,133.62a.15.15,0,0,0,.25.18.15.15,0,0,0,0-.21A.16.16,0,0,0,580.45,133.62Z"
          />
          <path
            className="cls-4"
            d="M591.93,135.56a.2.2,0,0,0,0-.26.18.18,0,0,0-.25,0,.18.18,0,0,0,0,.25A.19.19,0,0,0,591.93,135.56Z"
          />
          <path
            className="cls-4"
            d="M603.05,137.61a.15.15,0,1,0-.21,0A.16.16,0,0,0,603.05,137.61Z"
          />
          <circle className="cls-4" cx="614" cy="139.93" r="0.18" />
          <path
            className="cls-4"
            d="M624.86,142.56a.18.18,0,1,0,.25,0A.18.18,0,0,0,624.86,142.56Z"
          />
          <path
            className="cls-4"
            d="M635.8,145.64a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <path
            className="cls-4"
            d="M646.62,149a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <path
            className="cls-4"
            d="M657.31,152.73a.18.18,0,1,0,.29.21.19.19,0,0,0,0-.26A.18.18,0,0,0,657.31,152.73Z"
          />
          <circle className="cls-4" cx="668.05" cy="156.86" r="0.18" />
          <path
            className="cls-4"
            d="M678.4,161.12a.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,1,0-.24-.18Z"
          />
          <circle className="cls-4" cx="688.86" cy="165.86" r="0.18" />
          <path
            className="cls-4"
            d="M698.91,170.72a.18.18,0,1,0,.25,0A.18.18,0,0,0,698.91,170.72Z"
          />
          <circle className="cls-4" cx="709.09" cy="176.09" r="0.18" />
          <circle className="cls-4" cx="718.97" cy="181.66" r="0.18" />
          <path
            className="cls-4"
            d="M728.82,187.62a.18.18,0,1,0-.25,0A.18.18,0,0,0,728.82,187.62Z"
          />
          <path
            className="cls-4"
            d="M738.31,193.52a.18.18,0,1,0,0,.25A.18.18,0,0,0,738.31,193.52Z"
          />
          <circle className="cls-4" cx="747.54" cy="200.09" r="0.18" />
          <circle className="cls-4" cx="756.69" cy="206.79" r="0.18" />
          <circle className="cls-4" cx="765.63" cy="213.77" r="0.18" />
          <path
            className="cls-4"
            d="M774.46,220.86a.19.19,0,0,0-.26,0,.19.19,0,0,0,0,.26.19.19,0,0,0,.25,0A.18.18,0,0,0,774.46,220.86Z"
          />
          <path
            className="cls-4"
            d="M782.71,228.4a.18.18,0,1,0,.25,0A.18.18,0,0,0,782.71,228.4Z"
          />
          <path
            className="cls-4"
            d="M791,236.15a.18.18,0,1,0,.25,0A.18.18,0,0,0,791,236.15Z"
          />
          <circle className="cls-4" cx="799.18" cy="244.25" r="0.18" />
          <path
            className="cls-4"
            d="M806.83,252.37a.18.18,0,1,0,.25,0A.18.18,0,0,0,806.83,252.37Z"
          />
          <circle className="cls-4" cx="814.52" cy="260.93" r="0.18" />
          <circle className="cls-4" cx="821.82" cy="269.61" r="0.18" />
          <path
            className="cls-4"
            d="M828.7,278.4a.19.19,0,0,0,0,.26.18.18,0,0,0,.25-.05.18.18,0,1,0-.29-.21Z"
          />
          <path
            className="cls-4"
            d="M835.69,287.49a.15.15,0,1,0-.17.24.15.15,0,0,0,.21,0A.15.15,0,0,0,835.69,287.49Z"
          />
          <path
            className="cls-4"
            d="M842,296.8a.19.19,0,0,0,0,.26.18.18,0,0,0,.25-.05.18.18,0,1,0-.29-.21Z"
          />
          <path
            className="cls-4"
            d="M848.44,306.5a.18.18,0,1,0-.29-.21.19.19,0,0,0,0,.26A.18.18,0,0,0,848.44,306.5Z"
          />
          <circle className="cls-4" cx="854.21" cy="316.07" r="0.18" />
          <path
            className="cls-4"
            d="M859.69,325.81a.18.18,0,0,0,0,.25.19.19,0,0,0,.26,0,.18.18,0,0,0-.3-.21Z"
          />
          <circle className="cls-4" cx="865.17" cy="335.92" r="0.18" />
          <path
            className="cls-4"
            d="M870.07,346a.16.16,0,0,0,0,.22.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.16.16,0,0,0,870.07,346Z"
          />
          <path
            className="cls-4"
            d="M874.79,356.3a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0,0-.21A.16.16,0,0,0,874.79,356.3Z"
          />
          <path
            className="cls-4"
            d="M879.41,366.71a.16.16,0,0,0-.21,0,.15.15,0,1,0,.21,0Z"
          />
          <path
            className="cls-4"
            d="M883.27,377.3a.18.18,0,1,0,.26,0A.19.19,0,0,0,883.27,377.3Z"
          />
          <path
            className="cls-4"
            d="M887.08,388a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.14.14,0,0,0,887.08,388Z"
          />
          <path
            className="cls-4"
            d="M890.51,398.79a.18.18,0,1,0,.25,0A.18.18,0,0,0,890.51,398.79Z"
          />
          <path
            className="cls-4"
            d="M893.66,409.7a.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0,0-.21A.16.16,0,0,0,893.66,409.7Z"
          />
          <circle className="cls-4" cx="896.59" cy="420.78" r="0.18" />
          <circle className="cls-4" cx="899.06" cy="431.84" r="0.18" />
          <path
            className="cls-4"
            d="M901.06,442.87a.18.18,0,1,0,.25,0A.18.18,0,0,0,901.06,442.87Z"
          />
          <path
            className="cls-4"
            d="M902.87,454.06a.18.18,0,1,0,.25,0A.18.18,0,0,0,902.87,454.06Z"
          />
          <path
            className="cls-4"
            d="M904.32,465.29a.21.21,0,0,0,0,.29.21.21,0,0,0,.3-.05.21.21,0,0,0-.34-.24Z"
          />
          <circle className="cls-4" cx="905.62" cy="476.69" r="0.18" />
          <path
            className="cls-4"
            d="M906.3,487.91a.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0-.24-.18Z"
          />
          <circle className="cls-4" cx="906.88" cy="499.33" r="0.18" />
          <path
            className="cls-4"
            d="M906.88,510.58a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,906.88,510.58Z"
          />
          <path
            className="cls-4"
            d="M906.63,521.89a.19.19,0,0,0,0,.26.18.18,0,0,0,.25,0,.18.18,0,0,0-.3-.21Z"
          />
          <path
            className="cls-4"
            d="M906.1,533.24a.15.15,0,1,0,.24.17.15.15,0,0,0,0-.21A.15.15,0,0,0,906.1,533.24Z"
          />
          <path
            className="cls-4"
            d="M905.43,544.48a.18.18,0,1,0,0,.25A.18.18,0,0,0,905.43,544.48Z"
          />
          <path
            className="cls-4"
            d="M904,555.81a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <circle className="cls-4" cx="902.52" cy="567.12" r="0.18" />
          <circle className="cls-4" cx="900.61" cy="578.3" r="0.18" />
          <circle className="cls-4" cx="898.37" cy="589.41" r="0.18" />
          <circle className="cls-4" cx="895.8" cy="600.46" r="0.18" />
          <path
            className="cls-4"
            d="M892.75,611.31a.18.18,0,0,0,0,.25.18.18,0,1,0,.21-.29A.19.19,0,0,0,892.75,611.31Z"
          />
          <path
            className="cls-4"
            d="M889.55,622.2a.15.15,0,1,0,.25.17.16.16,0,0,0,0-.21A.15.15,0,0,0,889.55,622.2Z"
          />
          <circle className="cls-4" cx="886.12" cy="633.05" r="0.18" />
          <circle className="cls-4" cx="882.25" cy="643.71" r="0.18" />
          <path
            className="cls-4"
            d="M877.92,654.14a.18.18,0,1,0,.25,0A.18.18,0,0,0,877.92,654.14Z"
          />
          <path
            className="cls-4"
            d="M873.42,664.54a.18.18,0,1,0,.29.21.18.18,0,1,0-.29-.21Z"
          />
          <path
            className="cls-4"
            d="M868.6,674.81a.18.18,0,0,0,0,.25.18.18,0,1,0,.21-.29A.19.19,0,0,0,868.6,674.81Z"
          />
          <circle className="cls-4" cx="863.64" cy="685.03" r="0.18" />
          <circle className="cls-4" cx="858.22" cy="694.99" r="0.18" />
          <path
            className="cls-4"
            d="M852.36,704.68a.18.18,0,1,0,.25,0A.18.18,0,0,0,852.36,704.68Z"
          />
          <circle className="cls-4" cx="846.5" cy="714.41" r="0.18" />
          <path
            className="cls-4"
            d="M840.07,723.73a.19.19,0,0,0,0,.26.18.18,0,0,0,.21-.3A.19.19,0,0,0,840.07,723.73Z"
          />
          <path
            className="cls-4"
            d="M833.5,733a.18.18,0,0,0,.3.21.18.18,0,0,0,0-.25A.19.19,0,0,0,833.5,733Z"
          />
          <circle className="cls-4" cx="826.81" cy="742.12" r="0.18" />
          <path
            className="cls-4"
            d="M819.56,750.85a.18.18,0,1,0,.25,0A.18.18,0,0,0,819.56,750.85Z"
          />
          <path
            className="cls-4"
            d="M812.19,759.47a.18.18,0,1,0,.25,0A.18.18,0,0,0,812.19,759.47Z"
          />
          <path
            className="cls-4"
            d="M804.56,767.86a.18.18,0,0,0,.05.25.18.18,0,1,0,.21-.29A.19.19,0,0,0,804.56,767.86Z"
          />
          <circle className="cls-4" cx="796.84" cy="776.13" r="0.18" />
          <circle className="cls-4" cx="788.73" cy="784.05" r="0.18" />
          <path
            className="cls-4"
            d="M780.26,791.63a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,780.26,791.63Z"
          />
          <path
            className="cls-4"
            d="M771.67,799a.18.18,0,1,0,.25,0A.18.18,0,0,0,771.67,799Z"
          />
          <path
            className="cls-4"
            d="M762.88,806.2a.18.18,0,1,0,.25,0A.18.18,0,0,0,762.88,806.2Z"
          />
          <path
            className="cls-4"
            d="M753.9,813.11a.15.15,0,1,0,.21,0A.16.16,0,0,0,753.9,813.11Z"
          />
          <path
            className="cls-4"
            d="M744.7,819.74a.16.16,0,0,0,0,.21.15.15,0,1,0,.18-.25A.15.15,0,0,0,744.7,819.74Z"
          />
          <circle className="cls-4" cx="735.43" cy="826.17" r="0.18" />
          <path
            className="cls-4"
            d="M725.7,832.13a.18.18,0,1,0,.25,0A.18.18,0,0,0,725.7,832.13Z"
          />
          <path
            className="cls-4"
            d="M716,837.92a.15.15,0,1,0,.24.17.16.16,0,0,0,0-.21A.15.15,0,0,0,716,837.92Z"
          />
          <path
            className="cls-4"
            d="M706,843.38a.19.19,0,0,0,0,.25.18.18,0,0,0,.21-.29A.19.19,0,0,0,706,843.38Z"
          />
          <path
            className="cls-4"
            d="M701.61,860.08a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,701.61,860.08Z"
          />
          <path
            className="cls-4"
            d="M691.32,864.77a.15.15,0,0,0,0,.21.15.15,0,1,0,.18-.24A.16.16,0,0,0,691.32,864.77Z"
          />
          <path
            className="cls-4"
            d="M681,869.37a.14.14,0,0,0,0,.17.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16A.12.12,0,0,0,681,869.37Z"
          />
          <path
            className="cls-4"
            d="M670.5,873.82a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.13.13,0,0,0-.17,0A.14.14,0,0,0,670.5,873.82Z"
          />
          <path
            className="cls-4"
            d="M659.88,877.79a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.11.11,0,0,0-.16,0A.12.12,0,0,0,659.88,877.79Z"
          />
          <path
            className="cls-4"
            d="M649.1,881.27a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M638.32,884.81a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,638.32,884.81Z"
          />
          <path
            className="cls-4"
            d="M627.4,887.84a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,627.4,887.84Z"
          />
          <circle className="cls-4" cx="616.46" cy="890.47" r="0.12" />
          <path
            className="cls-4"
            d="M605.26,892.78a.15.15,0,0,0,0,.21.14.14,0,0,0,.2,0,.15.15,0,1,0-.24-.17Z"
          />
          <path
            className="cls-4"
            d="M594.17,895a.11.11,0,0,0,.16,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,594.17,895Z"
          />
          <path
            className="cls-4"
            d="M582.94,896.63a.12.12,0,0,0,0,.17.12.12,0,0,0,.16,0,.12.12,0,0,0,0-.17A.12.12,0,0,0,582.94,896.63Z"
          />
          <path
            className="cls-4"
            d="M571.72,898.22a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,571.72,898.22Z"
          />
          <path
            className="cls-4"
            d="M560.38,899.14a.15.15,0,1,0,.21,0A.15.15,0,0,0,560.38,899.14Z"
          />
          <path
            className="cls-4"
            d="M549.12,900.1a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,549.12,900.1Z"
          />
          <path
            className="cls-4"
            d="M538,900.33a.15.15,0,1,0-.17.25.16.16,0,0,0,.21,0A.15.15,0,0,0,538,900.33Z"
          />
          <path
            className="cls-4"
            d="M526.46,900.67a.12.12,0,0,0,.17,0,.12.12,0,0,0-.2-.14A.12.12,0,0,0,526.46,900.67Z"
          />
          <path
            className="cls-4"
            d="M515.12,900.46a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.11.11,0,0,0-.16,0A.12.12,0,0,0,515.12,900.46Z"
          />
          <path
            className="cls-4"
            d="M503.77,899.76a.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0,.14.14,0,0,0,0-.17A.12.12,0,0,0,503.77,899.76Z"
          />
          <path
            className="cls-4"
            d="M492.44,898.88a.16.16,0,0,0,0,.21.17.17,0,0,0,.22,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,492.44,898.88Z"
          />
          <path
            className="cls-4"
            d="M481.22,897.87a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,481.22,897.87Z"
          />
          <path
            className="cls-4"
            d="M470,896.35a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.11.11,0,0,0,470,896.35Z"
          />
          <path
            className="cls-4"
            d="M458.79,894.51a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.15.19Z"
          />
          <path
            className="cls-4"
            d="M447.67,892.35a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,447.67,892.35Z"
          />
          <path
            className="cls-4"
            d="M436.55,889.68a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.17Z"
          />
          <circle className="cls-4" cx="425.69" cy="886.96" r="0.12" />
          <path
            className="cls-4"
            d="M414.67,883.75a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <path
            className="cls-4"
            d="M403.86,880.31a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M393.22,876.75a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,393.22,876.75Z"
          />
          <path
            className="cls-4"
            d="M382.63,872.69a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,382.63,872.69Z"
          />
          <path
            className="cls-4"
            d="M372.16,868.33a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,372.16,868.33Z"
          />
          <path
            className="cls-4"
            d="M361.83,863.67a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,361.83,863.67Z"
          />
          <path
            className="cls-4"
            d="M351.63,858.71a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.14.14,0,0,0,351.63,858.71Z"
          />
          <path
            className="cls-4"
            d="M341.58,853.46a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,341.58,853.46Z"
          />
          <path
            className="cls-4"
            d="M331.83,847.72a.12.12,0,0,0-.14.2.12.12,0,0,0,.17,0A.12.12,0,0,0,331.83,847.72Z"
          />
          <path
            className="cls-4"
            d="M322,842.09a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,322,842.09Z"
          />
          <path
            className="cls-4"
            d="M312.41,836a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.14.14,0,0,0,312.41,836Z"
          />
          <path
            className="cls-4"
            d="M303,829.61a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,303,829.61Z"
          />
          <path
            className="cls-4"
            d="M293.85,823a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,293.85,823Z"
          />
          <path
            className="cls-4"
            d="M284.87,816.06a.12.12,0,0,0,.14-.2.12.12,0,0,0-.17,0A.12.12,0,0,0,284.87,816.06Z"
          />
          <path
            className="cls-4"
            d="M276.08,808.89a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.13.13,0,0,0-.17,0A.14.14,0,0,0,276.08,808.89Z"
          />
          <path
            className="cls-4"
            d="M267.51,801.47a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,267.51,801.47Z"
          />
          <path
            className="cls-4"
            d="M259.12,793.64a.14.14,0,0,0,0,.17.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17A.12.12,0,0,0,259.12,793.64Z"
          />
          <path
            className="cls-4"
            d="M251,785.9a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,251,785.9Z"
          />
          <path
            className="cls-4"
            d="M243.12,777.77a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,243.12,777.77Z"
          />
          <path
            className="cls-4"
            d="M235.44,769.24a.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17A.14.14,0,0,0,235.44,769.24Z"
          />
          <circle className="cls-4" cx="228.12" cy="760.73" r="0.12" />
          <path
            className="cls-4"
            d="M221,751.85a.12.12,0,1,0,0,.17A.12.12,0,0,0,221,751.85Z"
          />
          <path
            className="cls-4"
            d="M214.18,743a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <path
            className="cls-4"
            d="M207.34,733.86a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.11.11,0,0,0-.16,0A.12.12,0,0,0,207.34,733.86Z"
          />
          <path
            className="cls-4"
            d="M201.11,724.29a.12.12,0,0,0-.17,0,.11.11,0,0,0,0,.16.12.12,0,0,0,.17,0A.12.12,0,0,0,201.11,724.29Z"
          />
          <path
            className="cls-4"
            d="M195,714.73a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,195,714.73Z"
          />
          <circle className="cls-4" cx="189.12" cy="705.1" r="0.12" />
          <path
            className="cls-4"
            d="M183.66,695.11a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,183.66,695.11Z"
          />
          <path
            className="cls-4"
            d="M178.41,685.06a.12.12,0,0,0-.17,0,.14.14,0,0,0,0,.17.12.12,0,0,0,.17,0A.11.11,0,0,0,178.41,685.06Z"
          />
          <path
            className="cls-4"
            d="M173.46,674.86a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,1,0,.14-.19Z"
          />
          <path
            className="cls-4"
            d="M168.66,664.72a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,168.66,664.72Z"
          />
          <circle className="cls-4" cx="164.38" cy="654.15" r="0.12" />
          <path
            className="cls-4"
            d="M160.26,643.66a.12.12,0,1,0,0-.17A.12.12,0,0,0,160.26,643.66Z"
          />
          <path
            className="cls-4"
            d="M156.66,632.76a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.14-.2Z"
          />
          <path
            className="cls-4"
            d="M153.23,622a.13.13,0,0,0-.17,0,.12.12,0,0,0,.2.14A.14.14,0,0,0,153.23,622Z"
          />
          <path
            className="cls-4"
            d="M150.11,611.05a.12.12,0,1,0-.14.2.13.13,0,0,0,.17,0A.12.12,0,0,0,150.11,611.05Z"
          />
          <path
            className="cls-4"
            d="M147.31,600.06a.12.12,0,0,0-.14.2.12.12,0,0,0,.17,0A.12.12,0,0,0,147.31,600.06Z"
          />
          <path
            className="cls-4"
            d="M144.83,589a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,144.83,589Z"
          />
          <path
            className="cls-4"
            d="M142.67,577.87a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,142.67,577.87Z"
          />
          <path
            className="cls-4"
            d="M140.84,566.68a.14.14,0,0,0-.17,0,.13.13,0,0,0,0,.17.12.12,0,0,0,.17,0A.13.13,0,0,0,140.84,566.68Z"
          />
          <path
            className="cls-4"
            d="M139.33,555.45a.12.12,0,0,0-.17,0,.11.11,0,0,0,0,.16.12.12,0,1,0,.14-.19Z"
          />
          <path
            className="cls-4"
            d="M138.14,544.17a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,138.14,544.17Z"
          />
          <path
            className="cls-4"
            d="M137.29,532.87a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,137.29,532.87Z"
          />
          <path
            className="cls-4"
            d="M136.76,521.54a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,136.76,521.54Z"
          />
          <path
            className="cls-4"
            d="M136.56,510.21a.12.12,0,1,0-.15.19.12.12,0,0,0,.17,0A.11.11,0,0,0,136.56,510.21Z"
          />
          <path
            className="cls-4"
            d="M136.51,498.9a.11.11,0,0,0,0,.16.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17A.12.12,0,0,0,136.51,498.9Z"
          />
          <path
            className="cls-4"
            d="M137.14,487.54a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,137.14,487.54Z"
          />
          <path
            className="cls-4"
            d="M138,476.4a.13.13,0,0,0,0-.17.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17A.14.14,0,0,0,138,476.4Z"
          />
          <path
            className="cls-4"
            d="M139,465a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,139,465Z"
          />
          <path
            className="cls-4"
            d="M140.46,453.7a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,140.46,453.7Z"
          />
          <path
            className="cls-4"
            d="M142.22,442.5a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.12.12,0,0,0,142.22,442.5Z"
          />
          <path
            className="cls-4"
            d="M144.31,431.36a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,144.31,431.36Z"
          />
          <circle className="cls-4" cx="146.64" cy="420.37" r="0.12" />
          <path
            className="cls-4"
            d="M149.44,409.27a.12.12,0,0,0-.17,0,.12.12,0,1,0,.17,0Z"
          />
          <path
            className="cls-4"
            d="M152.48,398.35a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.12.12,0,0,0,152.48,398.35Z"
          />
          <path
            className="cls-4"
            d="M155.89,387.71a.15.15,0,1,0-.21,0A.16.16,0,0,0,155.89,387.71Z"
          />
          <path
            className="cls-4"
            d="M159.51,376.79a.13.13,0,0,0-.17,0,.13.13,0,0,0,0,.17.12.12,0,0,0,.17,0A.13.13,0,0,0,159.51,376.79Z"
          />
          <path
            className="cls-4"
            d="M163.49,366.18a.12.12,0,1,0,0,.17A.12.12,0,0,0,163.49,366.18Z"
          />
          <path
            className="cls-4"
            d="M167.77,355.68a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,167.77,355.68Z"
          />
          <path
            className="cls-4"
            d="M172.36,345.31a.13.13,0,0,0-.17,0,.14.14,0,0,0,0,.17.13.13,0,0,0,.17,0A.12.12,0,0,0,172.36,345.31Z"
          />
          <path
            className="cls-4"
            d="M177.25,335.08a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,177.25,335.08Z"
          />
          <path
            className="cls-4"
            d="M182.43,325a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.11.11,0,0,0,182.43,325Z"
          />
          <path
            className="cls-4"
            d="M187.9,315.06a.14.14,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,187.9,315.06Z"
          />
          <path
            className="cls-4"
            d="M193.65,305.3a.12.12,0,1,0-.14.19.12.12,0,0,0,.17,0A.11.11,0,0,0,193.65,305.3Z"
          />
          <path
            className="cls-4"
            d="M199.68,295.7a.11.11,0,0,0-.16,0,.11.11,0,0,0,0,.16.12.12,0,0,0,.17,0A.12.12,0,0,0,199.68,295.7Z"
          />
          <path
            className="cls-4"
            d="M206,286.28a.11.11,0,0,0-.16,0,.11.11,0,0,0,0,.16.12.12,0,0,0,.17,0A.12.12,0,0,0,206,286.28Z"
          />
          <path
            className="cls-4"
            d="M212.58,277.05a.12.12,0,1,0-.15.19.12.12,0,0,0,.17,0A.11.11,0,0,0,212.58,277.05Z"
          />
          <path
            className="cls-4"
            d="M219.42,268a.12.12,0,1,0,0,.17A.12.12,0,0,0,219.42,268Z"
          />
          <path
            className="cls-4"
            d="M226.52,259.17a.12.12,0,0,0-.17,0,.14.14,0,0,0,0,.17.14.14,0,0,0,.17,0A.12.12,0,0,0,226.52,259.17Z"
          />
          <path
            className="cls-4"
            d="M233.88,250.54a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.13.13,0,0,0,233.88,250.54Z"
          />
          <path
            className="cls-4"
            d="M241.48,242.13a.14.14,0,0,0-.17,0,.14.14,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,241.48,242.13Z"
          />
          <path
            className="cls-4"
            d="M249.33,234a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.11.11,0,0,0,249.33,234Z"
          />
          <path
            className="cls-4"
            d="M257.4,226a.12.12,0,0,0-.17,0,.14.14,0,0,0,0,.17.13.13,0,0,0,.17,0A.12.12,0,0,0,257.4,226Z"
          />
          <path
            className="cls-4"
            d="M265.71,218.27a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,265.71,218.27Z"
          />
          <path
            className="cls-4"
            d="M274.23,210.8a.12.12,0,1,0-.14.19.12.12,0,0,0,.17,0A.11.11,0,0,0,274.23,210.8Z"
          />
          <path
            className="cls-4"
            d="M283,203.57a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.14-.2Z"
          />
          <path
            className="cls-4"
            d="M292,196.79a.15.15,0,0,0,0-.21.15.15,0,1,0-.18.24A.16.16,0,0,0,292,196.79Z"
          />
          <path
            className="cls-4"
            d="M301.1,190.08a.16.16,0,0,0,0-.21.15.15,0,1,0,0,.21Z"
          />
          <circle className="cls-4" cx="310.31" cy="183.55" r="0.12" />
          <path
            className="cls-4"
            d="M320,177.47a.15.15,0,1,0-.25-.17.14.14,0,0,0,0,.2A.15.15,0,0,0,320,177.47Z"
          />
          <path
            className="cls-4"
            d="M329.58,171.39a.14.14,0,0,0-.17,0,.14.14,0,0,0,0,.17.12.12,0,1,0,.14-.2Z"
          />
          <path
            className="cls-4"
            d="M339.44,165.79a.12.12,0,0,0-.17,0,.11.11,0,0,0,0,.16.11.11,0,0,0,.16,0A.12.12,0,0,0,339.44,165.79Z"
          />
          <path
            className="cls-4"
            d="M349.45,160.47a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.13.13,0,0,0,349.45,160.47Z"
          />
          <path
            className="cls-4"
            d="M359.61,155.44a.12.12,0,1,0,0,.17A.13.13,0,0,0,359.61,155.44Z"
          />
          <path
            className="cls-4"
            d="M370,150.9a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <path
            className="cls-4"
            d="M380.35,146.28a.12.12,0,1,0,0,.17A.13.13,0,0,0,380.35,146.28Z"
          />
          <path
            className="cls-4"
            d="M390.91,142.16a.12.12,0,1,0-.14.19.12.12,0,0,0,.17,0A.11.11,0,0,0,390.91,142.16Z"
          />
          <path
            className="cls-4"
            d="M401.59,138.34a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,401.59,138.34Z"
          />
          <path
            className="cls-4"
            d="M412.42,135a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.16.16,0,0,0,0,.21A.15.15,0,0,0,412.42,135Z"
          />
          <path
            className="cls-4"
            d="M423.24,131.63a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,423.24,131.63Z"
          />
          <path
            className="cls-4"
            d="M434.26,128.94a.15.15,0,0,0,0-.21.15.15,0,0,0-.21,0,.15.15,0,1,0,.24.17Z"
          />
          <path
            className="cls-4"
            d="M445.26,126.2a.12.12,0,0,0-.15.19.12.12,0,0,0,.17,0A.11.11,0,0,0,445.26,126.2Z"
          />
          <path
            className="cls-4"
            d="M456.37,124a.12.12,0,1,0,0,.17A.12.12,0,0,0,456.37,124Z"
          />
          <path
            className="cls-4"
            d="M467.54,122a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.12.12,0,0,0,467.54,122Z"
          />
          <path
            className="cls-4"
            d="M478.82,120.63a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,0,.21A.15.15,0,0,0,478.82,120.63Z"
          />
          <path
            className="cls-4"
            d="M490,119.18a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,490,119.18Z"
          />
          <path
            className="cls-4"
            d="M501.33,118.24a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,501.33,118.24Z"
          />
          <path
            className="cls-4"
            d="M512.52,117.83a.11.11,0,0,0,.16,0,.12.12,0,1,0-.19-.14A.12.12,0,0,0,512.52,117.83Z"
          />
          <path
            className="cls-4"
            d="M524,117.53a.15.15,0,1,0-.24-.17.16.16,0,0,0,0,.21A.15.15,0,0,0,524,117.53Z"
          />
          <path
            className="cls-4"
            d="M535.36,117.56a.14.14,0,0,0,0-.17.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17A.13.13,0,0,0,535.36,117.56Z"
          />
          <path
            className="cls-4"
            d="M546.66,117.76a.14.14,0,0,0-.17,0,.13.13,0,0,0,0,.17.12.12,0,0,0,.17,0A.13.13,0,0,0,546.66,117.76Z"
          />
          <path
            className="cls-4"
            d="M558,118.46a.14.14,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,558,118.46Z"
          />
          <path
            className="cls-4"
            d="M569.32,119.68a.15.15,0,0,0,0-.21.15.15,0,1,0-.17.24A.15.15,0,0,0,569.32,119.68Z"
          />
          <path
            className="cls-4"
            d="M580.38,121a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.11.11,0,0,0,580.38,121Z"
          />
          <path
            className="cls-4"
            d="M591.74,122.53a.12.12,0,1,0-.15.19.12.12,0,0,0,.17,0A.11.11,0,0,0,591.74,122.53Z"
          />
          <path
            className="cls-4"
            d="M602.7,124.54a.15.15,0,0,0,0,.21.15.15,0,1,0,.17-.24A.15.15,0,0,0,602.7,124.54Z"
          />
          <path
            className="cls-4"
            d="M613.85,127.05a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.14.14,0,0,0,613.85,127.05Z"
          />
          <path
            className="cls-4"
            d="M625,129.5a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,625,129.5Z"
          />
          <path
            className="cls-4"
            d="M635.77,132.47a.15.15,0,1,0,.24.18.16.16,0,0,0,0-.21A.15.15,0,0,0,635.77,132.47Z"
          />
          <path
            className="cls-4"
            d="M646.81,135.74a.14.14,0,0,0-.17,0,.14.14,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,646.81,135.74Z"
          />
          <path
            className="cls-4"
            d="M657.58,139.31a.15.15,0,0,0-.21,0,.16.16,0,0,0,0,.21.15.15,0,1,0,.17-.25Z"
          />
          <path
            className="cls-4"
            d="M668.26,143.42a.13.13,0,0,0,0-.2.15.15,0,0,0-.18.24A.15.15,0,0,0,668.26,143.42Z"
          />
          <path
            className="cls-4"
            d="M678.55,147.46a.15.15,0,0,0,0,.21.15.15,0,0,0,.17-.25A.15.15,0,0,0,678.55,147.46Z"
          />
          <path
            className="cls-4"
            d="M689.14,152a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.13.13,0,0,0,689.14,152Z"
          />
          <path
            className="cls-4"
            d="M699.21,156.78a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M709.34,161.89a.15.15,0,1,0,.24.17.16.16,0,0,0,0-.21A.15.15,0,0,0,709.34,161.89Z"
          />
          <path
            className="cls-4"
            d="M719.31,167.28a.15.15,0,1,0,.24.18.15.15,0,0,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M729.12,173a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,729.12,173Z"
          />
          <path
            className="cls-4"
            d="M738.76,178.93a.15.15,0,1,0,.21,0A.16.16,0,0,0,738.76,178.93Z"
          />
          <path
            className="cls-4"
            d="M748.47,185.35a.15.15,0,0,0,0-.21.16.16,0,0,0-.21,0,.15.15,0,0,0,.25.18Z"
          />
          <path
            className="cls-4"
            d="M757.71,191.65a.15.15,0,1,0,0,.21A.15.15,0,0,0,757.71,191.65Z"
          />
          <path
            className="cls-4"
            d="M766.59,198.47a.16.16,0,0,0,0,.21.15.15,0,0,0,.18-.25A.15.15,0,0,0,766.59,198.47Z"
          />
          <path
            className="cls-4"
            d="M775.48,205.51a.15.15,0,1,0,.21,0A.15.15,0,0,0,775.48,205.51Z"
          />
          <path
            className="cls-4"
            d="M784.37,212.77a.15.15,0,0,0-.18.24.16.16,0,0,0,.21,0A.14.14,0,0,0,784.37,212.77Z"
          />
          <path
            className="cls-4"
            d="M792.62,220.34a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M800.87,228.13a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,800.87,228.13Z"
          />
          <path
            className="cls-4"
            d="M808.88,236.14a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,808.88,236.14Z"
          />
          <path
            className="cls-4"
            d="M816.66,244.39a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <path
            className="cls-4"
            d="M824.2,252.86a.15.15,0,0,0,.24.18.16.16,0,0,0,0-.21A.15.15,0,0,0,824.2,252.86Z"
          />
          <path
            className="cls-4"
            d="M831.49,261.55a.15.15,0,1,0,.24.17.16.16,0,0,0,0-.21A.15.15,0,0,0,831.49,261.55Z"
          />
          <path
            className="cls-4"
            d="M838.52,270.44a.16.16,0,0,0,0,.21.15.15,0,0,0,.18-.25A.15.15,0,0,0,838.52,270.44Z"
          />
          <path
            className="cls-4"
            d="M845.5,279.5a.15.15,0,1,0-.17.24.16.16,0,0,0,.21,0A.15.15,0,0,0,845.5,279.5Z"
          />
          <path
            className="cls-4"
            d="M851.8,288.81a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,851.8,288.81Z"
          />
          <path
            className="cls-4"
            d="M858,298.28a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M864.05,308.11a.12.12,0,1,0,0-.17A.12.12,0,0,0,864.05,308.11Z"
          />
          <path
            className="cls-4"
            d="M869.68,317.74a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.14.14,0,0,0,869.68,317.74Z"
          />
          <path
            className="cls-4"
            d="M875.07,327.71a.15.15,0,1,0,.24.18.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M880.17,337.84a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.14.14,0,0,0,0-.2A.15.15,0,0,0,880.17,337.84Z"
          />
          <path
            className="cls-4"
            d="M885,348.11a.15.15,0,0,0,.25.17.16.16,0,0,0,0-.21A.16.16,0,0,0,885,348.11Z"
          />
          <path
            className="cls-4"
            d="M889.67,358.5a.12.12,0,1,0,0,.17A.13.13,0,0,0,889.67,358.5Z"
          />
          <path
            className="cls-4"
            d="M893.68,369a.16.16,0,0,0,0,.21.15.15,0,1,0,0-.21Z"
          />
          <path
            className="cls-4"
            d="M897.63,379.88a.14.14,0,0,0,.17,0,.13.13,0,0,0,0-.17.12.12,0,1,0-.14.2Z"
          />
          <path
            className="cls-4"
            d="M901.16,390.44a.15.15,0,1,0,.25.18.15.15,0,0,0,0-.21A.16.16,0,0,0,901.16,390.44Z"
          />
          <path
            className="cls-4"
            d="M904.65,401.27a.15.15,0,1,0-.18.24.16.16,0,0,0,.21,0A.15.15,0,0,0,904.65,401.27Z"
          />
          <path
            className="cls-4"
            d="M907.39,412.25a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,1,0-.25-.17Z"
          />
          <path
            className="cls-4"
            d="M910,423.27a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.15.15,0,0,0,910,423.27Z"
          />
          <path
            className="cls-4"
            d="M912.35,434.37a.15.15,0,0,0,.25.17.16.16,0,0,0,0-.21A.16.16,0,0,0,912.35,434.37Z"
          />
          <path
            className="cls-4"
            d="M914.35,445.53a.16.16,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M916,456.74a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,916,456.74Z"
          />
          <path
            className="cls-4"
            d="M917.37,468a.15.15,0,0,0,.24.18.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M918.39,479.29a.15.15,0,0,0,0,.21.15.15,0,1,0,.17-.24A.15.15,0,0,0,918.39,479.29Z"
          />
          <path
            className="cls-4"
            d="M919.08,490.61a.16.16,0,0,0,0,.21.15.15,0,0,0,.17-.25A.16.16,0,0,0,919.08,490.61Z"
          />
          <path
            className="cls-4"
            d="M919.45,501.94a.16.16,0,0,0,0,.21.17.17,0,0,0,.22,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,919.45,501.94Z"
          />
          <path
            className="cls-4"
            d="M919.54,513.46a.12.12,0,1,0,0-.17A.12.12,0,0,0,919.54,513.46Z"
          />
          <path
            className="cls-4"
            d="M919.2,524.61a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M918.63,536.12a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.14.2Z"
          />
          <path
            className="cls-4"
            d="M917.84,547.2a.15.15,0,1,0,0,.2A.14.14,0,0,0,917.84,547.2Z"
          />
          <path
            className="cls-4"
            d="M916.36,558.5a.16.16,0,0,0,0,.21.17.17,0,0,0,.22,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,916.36,558.5Z"
          />
          <path
            className="cls-4"
            d="M914.76,569.72a.15.15,0,0,0,0,.21.15.15,0,1,0,.17-.24A.15.15,0,0,0,914.76,569.72Z"
          />
          <path
            className="cls-4"
            d="M912.84,580.89a.16.16,0,0,0,0,.21.15.15,0,1,0,.18-.24A.16.16,0,0,0,912.84,580.89Z"
          />
          <path
            className="cls-4"
            d="M910.65,592.19a.11.11,0,0,0,.16,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,910.65,592.19Z"
          />
          <path
            className="cls-4"
            d="M908,603.05a.15.15,0,0,0,.24.18.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M905.14,614a.16.16,0,0,0,0,.22.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.16.16,0,0,0,905.14,614Z"
          />
          <path
            className="cls-4"
            d="M901.94,624.89a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M898.43,635.67a.15.15,0,0,0,.24.18.15.15,0,1,0-.24-.18Z"
          />
          <path
            className="cls-4"
            d="M894.6,646.34a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M890.47,656.9a.15.15,0,0,0,0,.21.15.15,0,1,0,.17-.24A.15.15,0,0,0,890.47,656.9Z"
          />
          <path
            className="cls-4"
            d="M886,667.33a.15.15,0,1,0,.24.18.15.15,0,0,0,0-.21A.14.14,0,0,0,886,667.33Z"
          />
          <path
            className="cls-4"
            d="M881.3,677.64a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.15.15,0,0,0,881.3,677.64Z"
          />
          <path
            className="cls-4"
            d="M876.27,687.79a.15.15,0,0,0,.24.18.16.16,0,0,0,0-.21A.15.15,0,0,0,876.27,687.79Z"
          />
          <path
            className="cls-4"
            d="M870.94,697.8a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,870.94,697.8Z"
          />
          <path
            className="cls-4"
            d="M865.33,707.65a.15.15,0,1,0,.24.18.15.15,0,0,0,0-.21A.16.16,0,0,0,865.33,707.65Z"
          />
          <path
            className="cls-4"
            d="M859.43,717.34a.15.15,0,1,0,.21,0A.16.16,0,0,0,859.43,717.34Z"
          />
          <path
            className="cls-4"
            d="M853.26,726.85a.16.16,0,0,0,0,.21.15.15,0,1,0,.18-.25A.16.16,0,0,0,853.26,726.85Z"
          />
          <path
            className="cls-4"
            d="M846.81,736.18a.16.16,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,1,0-.25-.17Z"
          />
          <path
            className="cls-4"
            d="M840.1,745.31a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,840.1,745.31Z"
          />
          <path
            className="cls-4"
            d="M833.13,754.25a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0,0-.21A.15.15,0,0,0,833.13,754.25Z"
          />
          <path
            className="cls-4"
            d="M825.9,763a.15.15,0,0,0,.24.18.16.16,0,0,0,0-.21A.15.15,0,0,0,825.9,763Z"
          />
          <path
            className="cls-4"
            d="M818.42,771.5a.15.15,0,0,0,.24.18.16.16,0,0,0,0-.21A.15.15,0,0,0,818.42,771.5Z"
          />
          <path
            className="cls-4"
            d="M810.69,779.8a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.15.15,0,0,0,810.69,779.8Z"
          />
          <path
            className="cls-4"
            d="M802.73,787.88a.15.15,0,1,0,.24.17.15.15,0,0,0,0-.21A.15.15,0,0,0,802.73,787.88Z"
          />
          <path
            className="cls-4"
            d="M794.59,795.9a.12.12,0,1,0,.14-.2.13.13,0,0,0-.17,0A.13.13,0,0,0,794.59,795.9Z"
          />
          <path
            className="cls-4"
            d="M786.12,803.31a.16.16,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,0,0-.25-.18Z"
          />
          <path
            className="cls-4"
            d="M777.49,810.66a.15.15,0,1,0,.21,0A.15.15,0,0,0,777.49,810.66Z"
          />
          <path
            className="cls-4"
            d="M768.7,818a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,768.7,818Z"
          />
          <path
            className="cls-4"
            d="M759.66,824.79a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,759.66,824.79Z"
          />
          <path
            className="cls-4"
            d="M750.37,831.18a.15.15,0,0,0,0,.21.16.16,0,0,0,.21,0,.15.15,0,1,0-.25-.17Z"
          />
          <path
            className="cls-4"
            d="M741,837.67a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,741,837.67Z"
          />
          <path
            className="cls-4"
            d="M731.4,843.69a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,731.4,843.69Z"
          />
          <path
            className="cls-4"
            d="M721.58,849.26a.15.15,0,0,0,0,.21.15.15,0,0,0,.21,0,.16.16,0,0,0,0-.21A.15.15,0,0,0,721.58,849.26Z"
          />
          <path
            className="cls-4"
            d="M711.69,854.91a.13.13,0,0,0,.15-.2.12.12,0,0,0-.17,0A.12.12,0,0,0,711.69,854.91Z"
          />
          <path
            className="cls-4"
            d="M707.21,871.4a.11.11,0,0,0,.16,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,707.21,871.4Z"
          />
          <path
            className="cls-4"
            d="M697,876.29a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,697,876.29Z"
          />
          <path
            className="cls-4"
            d="M686.58,880.71a.13.13,0,0,0,0,.17.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16A.12.12,0,0,0,686.58,880.71Z"
          />
          <path
            className="cls-4"
            d="M676.12,885.18a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,676.12,885.18Z"
          />
          <path
            className="cls-4"
            d="M665.53,889.17a.09.09,0,1,0,0-.13A.09.09,0,0,0,665.53,889.17Z"
          />
          <circle
            className="cls-4"
            cx="654.87"
            cy="892.8"
            r="0.09"
            transform="translate(-452.3 904.01) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M644,896.28a.09.09,0,1,0,0-.13A.09.09,0,0,0,644,896.28Z"
          />
          <path
            className="cls-4"
            d="M633.08,899.4a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,633.08,899.4Z"
          />
          <path
            className="cls-4"
            d="M622.09,902.2a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,622.09,902.2Z"
          />
          <path
            className="cls-4"
            d="M611,904.68a.12.12,0,1,0,0-.17A.12.12,0,0,0,611,904.68Z"
          />
          <path
            className="cls-4"
            d="M599.9,906.86a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,599.9,906.86Z"
          />
          <path
            className="cls-4"
            d="M588.74,908.69a.08.08,0,0,0,.12,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M577.49,910.26a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,577.49,910.26Z"
          />
          <path
            className="cls-4"
            d="M566.22,911.49a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,566.22,911.49Z"
          />
          <path
            className="cls-4"
            d="M554.92,912.41a.11.11,0,0,0,.16,0,.12.12,0,1,0-.19-.14A.12.12,0,0,0,554.92,912.41Z"
          />
          <path
            className="cls-4"
            d="M543.61,913a.09.09,0,1,0,0-.13A.09.09,0,0,0,543.61,913Z"
          />
          <path
            className="cls-4"
            d="M532.28,913.26a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,532.28,913.26Z"
          />
          <path
            className="cls-4"
            d="M520.9,913.08a.11.11,0,0,0,0,.16.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17A.11.11,0,0,0,520.9,913.08Z"
          />
          <path
            className="cls-4"
            d="M509.61,912.86a.08.08,0,0,0,.12,0,.08.08,0,0,0,0-.12.08.08,0,0,0-.12,0A.08.08,0,0,0,509.61,912.86Z"
          />
          <circle
            className="cls-4"
            cx="498.34"
            cy="912.11"
            r="0.09"
            transform="matrix(0.58, -0.81, 0.81, 0.58, -533.22, 784.91)"
          />
          <path
            className="cls-4"
            d="M487,911.2a.1.1,0,0,0,.12,0,.08.08,0,0,0,0-.12.08.08,0,0,0-.12,0A.09.09,0,0,0,487,911.2Z"
          />
          <path
            className="cls-4"
            d="M475.72,909.91a.12.12,0,0,0,.17,0,.12.12,0,0,0-.2-.14A.12.12,0,0,0,475.72,909.91Z"
          />
          <path
            className="cls-4"
            d="M464.5,908.29a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,464.5,908.29Z"
          />
          <path
            className="cls-4"
            d="M453.32,906.2a.09.09,0,0,0,0,.13.11.11,0,0,0,.13,0,.08.08,0,0,0,0-.12A.09.09,0,0,0,453.32,906.2Z"
          />
          <circle className="cls-4" cx="442.28" cy="904" r="0.12" />
          <path
            className="cls-4"
            d="M431.17,901.54a.12.12,0,1,0,0-.17A.13.13,0,0,0,431.17,901.54Z"
          />
          <path
            className="cls-4"
            d="M420.22,898.64a.08.08,0,0,0,.12,0,.09.09,0,1,0-.12,0Z"
          />
          <circle
            className="cls-4"
            cx="409.39"
            cy="895.39"
            r="0.09"
            transform="translate(-556.71 705.69) rotate(-54.32)"
          />
          <circle
            className="cls-4"
            cx="398.6"
            cy="891.91"
            r="0.09"
            transform="translate(-558.38 695.47) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M387.83,888.08a.11.11,0,0,0,0,.13.09.09,0,0,0,.1-.15A.09.09,0,0,0,387.83,888.08Z"
          />
          <circle
            className="cls-4"
            cx="377.33"
            cy="884.05"
            r="0.09"
            transform="translate(-560.85 674.92) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M366.82,879.75a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.12,0A.08.08,0,0,0,366.82,879.75Z"
          />
          <path
            className="cls-4"
            d="M356.47,875.11a.12.12,0,0,0,.17,0,.13.13,0,0,0,0-.17.13.13,0,0,0-.17,0A.12.12,0,0,0,356.47,875.11Z"
          />
          <circle className="cls-4" cx="346.34" cy="870.06" r="0.12" />
          <path
            className="cls-4"
            d="M336.21,864.92a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,336.21,864.92Z"
          />
          <path
            className="cls-4"
            d="M326.33,859.37a.09.09,0,1,0,.1-.14.09.09,0,1,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M316.58,853.58a.09.09,0,1,0,0-.13A.09.09,0,0,0,316.58,853.58Z"
          />
          <path
            className="cls-4"
            d="M307,847.51a.09.09,0,0,0,.1-.14.09.09,0,0,0-.13,0A.08.08,0,0,0,307,847.51Z"
          />
          <path
            className="cls-4"
            d="M297.6,841.18a.09.09,0,0,0,.13,0,.09.09,0,0,0,0-.12.09.09,0,0,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M288.48,834.44a.09.09,0,0,0-.13,0,.11.11,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,288.48,834.44Z"
          />
          <path
            className="cls-4"
            d="M279.45,827.59a.11.11,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,1,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M270.48,820.52a.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12A.09.09,0,0,0,270.48,820.52Z"
          />
          <path
            className="cls-4"
            d="M261.84,813.17a.09.09,0,1,0,.13,0A.09.09,0,0,0,261.84,813.17Z"
          />
          <path
            className="cls-4"
            d="M253.44,805.71a.09.09,0,1,0,.1-.14.08.08,0,0,0-.12,0A.08.08,0,0,0,253.44,805.71Z"
          />
          <path
            className="cls-4"
            d="M245.21,797.92a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,245.21,797.92Z"
          />
          <path
            className="cls-4"
            d="M237.22,789.88a.14.14,0,0,0,.17,0,.13.13,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,237.22,789.88Z"
          />
          <path
            className="cls-4"
            d="M229.47,781.59a.09.09,0,0,0,.11-.15.09.09,0,0,0-.13,0A.11.11,0,0,0,229.47,781.59Z"
          />
          <path
            className="cls-4"
            d="M222,773.11a.11.11,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,222,773.11Z"
          />
          <path
            className="cls-4"
            d="M214.67,764.42a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.1.1,0,0,0,214.67,764.42Z"
          />
          <path
            className="cls-4"
            d="M207.61,755.4a.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13A.08.08,0,0,0,207.61,755.4Z"
          />
          <path
            className="cls-4"
            d="M201,746.3a.09.09,0,1,0,0,.13A.09.09,0,0,0,201,746.3Z"
          />
          <path
            className="cls-4"
            d="M194.31,737.18a.09.09,0,1,0,0-.13A.09.09,0,0,0,194.31,737.18Z"
          />
          <path
            className="cls-4"
            d="M188.05,727.73a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,188.05,727.73Z"
          />
          <path
            className="cls-4"
            d="M182,718.13a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,182,718.13Z"
          />
          <path
            className="cls-4"
            d="M176.32,708.32a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,176.32,708.32Z"
          />
          <path
            className="cls-4"
            d="M171,698.24s-.1,0-.13,0a.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,171,698.24Z"
          />
          <path
            className="cls-4"
            d="M165.7,688.29a.09.09,0,1,0,.11-.15.11.11,0,0,0-.13,0A.08.08,0,0,0,165.7,688.29Z"
          />
          <path
            className="cls-4"
            d="M160.92,677.91a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,160.92,677.91Z"
          />
          <path
            className="cls-4"
            d="M156.2,667.57a.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12A.09.09,0,0,0,156.2,667.57Z"
          />
          <path
            className="cls-4"
            d="M152,657.06a.09.09,0,0,0-.13,0,.09.09,0,0,0,0,.13.1.1,0,0,0,.13,0A.08.08,0,0,0,152,657.06Z"
          />
          <circle
            className="cls-4"
            cx="147.97"
            cy="646.53"
            r="0.09"
            transform="translate(-463.5 389.62) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M144.31,635.74a.09.09,0,0,0-.13,0,.09.09,0,0,0,0,.12.09.09,0,1,0,.1-.14Z"
          />
          <path
            className="cls-4"
            d="M140.91,624.92a.09.09,0,0,0-.11.15.09.09,0,1,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M137.8,614a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.09.09,0,0,0,.12,0A.08.08,0,0,0,137.8,614Z"
          />
          <path
            className="cls-4"
            d="M135,603a.09.09,0,0,0-.13,0,.1.1,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,135,603Z"
          />
          <path
            className="cls-4"
            d="M132.54,591.94a.11.11,0,0,0-.16,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.12.12,0,0,0,132.54,591.94Z"
          />
          <path
            className="cls-4"
            d="M130.35,580.84a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,130.35,580.84Z"
          />
          <path
            className="cls-4"
            d="M128.49,569.66a.09.09,0,1,0-.1.14.09.09,0,0,0,.1-.14Z"
          />
          <path
            className="cls-4"
            d="M127,558.4a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,127,558.4Z"
          />
          <path
            className="cls-4"
            d="M125.72,547.16a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,125.72,547.16Z"
          />
          <path
            className="cls-4"
            d="M124.82,535.83a.12.12,0,1,0-.14.2.14.14,0,0,0,.17,0A.13.13,0,0,0,124.82,535.83Z"
          />
          <path
            className="cls-4"
            d="M124.2,524.53a.08.08,0,0,0-.12,0,.09.09,0,1,0,.15.11A.09.09,0,0,0,124.2,524.53Z"
          />
          <path
            className="cls-4"
            d="M123.93,513.2a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,123.93,513.2Z"
          />
          <path
            className="cls-4"
            d="M124,501.86a.09.09,0,1,0,0,.13A.09.09,0,0,0,124,501.86Z"
          />
          <path
            className="cls-4"
            d="M124.22,490.68a.09.09,0,0,0,.12,0,.09.09,0,0,0-.14-.1A.09.09,0,0,0,124.22,490.68Z"
          />
          <path
            className="cls-4"
            d="M125,479.21a.09.09,0,1,0,0,.13A.09.09,0,0,0,125,479.21Z"
          />
          <path
            className="cls-4"
            d="M126,468a.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12A.09.09,0,0,0,126,468Z"
          />
          <path
            className="cls-4"
            d="M127.17,456.68a.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12A.09.09,0,0,0,127.17,456.68Z"
          />
          <path
            className="cls-4"
            d="M128.92,445.43a.09.09,0,0,0-.12,0,.09.09,0,0,0,.14.1A.09.09,0,0,0,128.92,445.43Z"
          />
          <path
            className="cls-4"
            d="M130.86,434.26a.09.09,0,1,0,0,.13A.09.09,0,0,0,130.86,434.26Z"
          />
          <path
            className="cls-4"
            d="M133.13,423.13a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0A.14.14,0,0,0,133.13,423.13Z"
          />
          <path
            className="cls-4"
            d="M135.69,412.08a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.13.13,0,0,0,.17,0A.14.14,0,0,0,135.69,412.08Z"
          />
          <path
            className="cls-4"
            d="M138.54,401.14a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.09.09,0,0,0,.12,0A.08.08,0,0,0,138.54,401.14Z"
          />
          <path
            className="cls-4"
            d="M141.72,390.26a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,141.72,390.26Z"
          />
          <path
            className="cls-4"
            d="M145.2,379.46a.11.11,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M149,368.78a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,149,368.78Z"
          />
          <path
            className="cls-4"
            d="M153.06,358.2a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,153.06,358.2Z"
          />
          <path
            className="cls-4"
            d="M157.43,347.74a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.09.09,0,0,0,.12,0A.08.08,0,0,0,157.43,347.74Z"
          />
          <path
            className="cls-4"
            d="M162.1,337.41a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,162.1,337.41Z"
          />
          <circle className="cls-4" cx="167" cy="327.28" r="0.12" />
          <path
            className="cls-4"
            d="M172.31,317.13a.12.12,0,0,0-.17,0,.11.11,0,0,0,0,.16.12.12,0,1,0,.14-.19Z"
          />
          <path
            className="cls-4"
            d="M177.81,307.25a.09.09,0,0,0-.13,0,.1.1,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,177.81,307.25Z"
          />
          <path
            className="cls-4"
            d="M183.61,297.5a.09.09,0,0,0-.11.15.09.09,0,1,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M189.67,287.93a.09.09,0,0,0-.13,0,.09.09,0,0,0,0,.12.08.08,0,0,0,.12,0A.08.08,0,0,0,189.67,287.93Z"
          />
          <path
            className="cls-4"
            d="M196,278.52a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,196,278.52Z"
          />
          <circle
            className="cls-4"
            cx="202.54"
            cy="269.37"
            r="0.09"
            transform="translate(-134.4 276.78) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M209.44,260.26a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,209.44,260.26Z"
          />
          <path
            className="cls-4"
            d="M216.54,251.42a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,216.54,251.42Z"
          />
          <path
            className="cls-4"
            d="M223.89,242.78a.11.11,0,0,0-.13,0,.09.09,0,1,0,.13,0Z"
          />
          <path
            className="cls-4"
            d="M231.47,234.36a.08.08,0,0,0-.12,0,.09.09,0,1,0,.12,0Z"
          />
          <path
            className="cls-4"
            d="M239.31,226.12a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,239.31,226.12Z"
          />
          <path
            className="cls-4"
            d="M247.35,218.13a.12.12,0,0,0-.17,0,.12.12,0,0,0,.2.14A.12.12,0,0,0,247.35,218.13Z"
          />
          <path
            className="cls-4"
            d="M255.6,210.4a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,255.6,210.4Z"
          />
          <path
            className="cls-4"
            d="M264.08,202.87a.09.09,0,1,0,0,.13A.09.09,0,0,0,264.08,202.87Z"
          />
          <circle
            className="cls-4"
            cx="272.71"
            cy="195.66"
            r="0.09"
            transform="translate(-45.28 303.06) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M281.67,188.52a.13.13,0,0,0-.17,0,.14.14,0,0,0,0,.17.13.13,0,0,0,.17,0A.12.12,0,0,0,281.67,188.52Z"
          />
          <path
            className="cls-4"
            d="M290.74,181.76a.09.09,0,1,0,0,.13A.09.09,0,0,0,290.74,181.76Z"
          />
          <path
            className="cls-4"
            d="M300,175.23a.09.09,0,1,0,0,.13A.09.09,0,0,0,300,175.23Z"
          />
          <path
            className="cls-4"
            d="M309.46,169a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0S309.5,169,309.46,169Z"
          />
          <path
            className="cls-4"
            d="M319.09,162.94a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,319.09,162.94Z"
          />
          <path
            className="cls-4"
            d="M328.86,157.24a.09.09,0,0,0-.13,0,.11.11,0,0,0,0,.13.09.09,0,0,0,.13,0A.09.09,0,0,0,328.86,157.24Z"
          />
          <path
            className="cls-4"
            d="M338.82,151.77a.12.12,0,1,0-.14.19.12.12,0,0,0,.17,0A.11.11,0,0,0,338.82,151.77Z"
          />
          <path
            className="cls-4"
            d="M348.91,146.6a.12.12,0,0,0-.17,0,.11.11,0,0,0,0,.16.12.12,0,1,0,.14-.19Z"
          />
          <path
            className="cls-4"
            d="M359.13,141.74a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.09.09,0,0,0,359.13,141.74Z"
          />
          <path
            className="cls-4"
            d="M369.49,137.14a.09.09,0,0,0-.13,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,369.49,137.14Z"
          />
          <path
            className="cls-4"
            d="M380,132.84a.09.09,0,0,0-.11.15.09.09,0,0,0,.13,0A.09.09,0,0,0,380,132.84Z"
          />
          <path
            className="cls-4"
            d="M390.6,128.81a.12.12,0,0,0-.17,0,.12.12,0,0,0,.2.14A.12.12,0,0,0,390.6,128.81Z"
          />
          <path
            className="cls-4"
            d="M401.3,125.13a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,401.3,125.13Z"
          />
          <circle
            className="cls-4"
            cx="412.06"
            cy="121.79"
            r="0.09"
            transform="translate(72.79 385.47) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M423,118.62a.09.09,0,1,0,0,.13A.09.09,0,0,0,423,118.62Z"
          />
          <path
            className="cls-4"
            d="M434.05,116a.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0,.12.12,0,1,0,.19.14Z"
          />
          <path
            className="cls-4"
            d="M445.09,113.32a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.11.11,0,0,0,445.09,113.32Z"
          />
          <path
            className="cls-4"
            d="M456.2,111.17a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,456.2,111.17Z"
          />
          <path
            className="cls-4"
            d="M467.4,109.28a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.13.13,0,0,0,.17,0A.14.14,0,0,0,467.4,109.28Z"
          />
          <path
            className="cls-4"
            d="M478.63,107.74a.12.12,0,1,0,0,.17A.12.12,0,0,0,478.63,107.74Z"
          />
          <path
            className="cls-4"
            d="M489.9,106.51a.12.12,0,1,0-.14.19.12.12,0,0,0,.17,0A.11.11,0,0,0,489.9,106.51Z"
          />
          <path
            className="cls-4"
            d="M501.2,105.59a.12.12,0,1,0,0,.17A.13.13,0,0,0,501.2,105.59Z"
          />
          <path
            className="cls-4"
            d="M512.38,105.19a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.14.14,0,0,0,512.38,105.19Z"
          />
          <path
            className="cls-4"
            d="M523.84,104.74a.09.09,0,1,0,0,.13A.09.09,0,0,0,523.84,104.74Z"
          />
          <path
            className="cls-4"
            d="M535.07,104.93a.09.09,0,1,0,0-.13A.09.09,0,0,0,535.07,104.93Z"
          />
          <path
            className="cls-4"
            d="M546.53,105.11a.13.13,0,0,0-.17,0,.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0A.12.12,0,0,0,546.53,105.11Z"
          />
          <path
            className="cls-4"
            d="M557.7,106a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.11.11,0,0,0,557.7,106Z"
          />
          <path
            className="cls-4"
            d="M569.14,106.78a.12.12,0,0,0-.17,0,.12.12,0,0,0,0,.17.14.14,0,0,0,.17,0A.13.13,0,0,0,569.14,106.78Z"
          />
          <path
            className="cls-4"
            d="M580.23,108.12a.11.11,0,0,0,0,.16.12.12,0,1,0,.14-.19A.12.12,0,0,0,580.23,108.12Z"
          />
          <path
            className="cls-4"
            d="M591.5,109.88a.08.08,0,0,0,.12,0,.09.09,0,1,0-.12,0Z"
          />
          <path
            className="cls-4"
            d="M602.79,111.65a.12.12,0,0,0-.17,0,.12.12,0,0,0,.2.14A.12.12,0,0,0,602.79,111.65Z"
          />
          <path
            className="cls-4"
            d="M613.89,113.92a.1.1,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,1,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M624.83,116.63a.09.09,0,1,0,.1-.14.08.08,0,0,0-.12,0A.08.08,0,0,0,624.83,116.63Z"
          />
          <path
            className="cls-4"
            d="M635.78,119.53a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,635.78,119.53Z"
          />
          <circle className="cls-4" cx="646.73" cy="122.6" r="0.12" />
          <path
            className="cls-4"
            d="M657.45,126.18a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,657.45,126.18Z"
          />
          <path
            className="cls-4"
            d="M668.11,129.8a.13.13,0,0,0,0,.17.14.14,0,0,0,.17,0,.13.13,0,0,0,0-.17A.12.12,0,0,0,668.11,129.8Z"
          />
          <path
            className="cls-4"
            d="M678.72,134a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,678.72,134Z"
          />
          <circle
            className="cls-4"
            cx="689.25"
            cy="138.32"
            r="0.09"
            transform="translate(174.88 617.51) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M699.53,143.06a.08.08,0,0,0,.12,0,.09.09,0,1,0-.14-.1A.08.08,0,0,0,699.53,143.06Z"
          />
          <path
            className="cls-4"
            d="M709.72,148a.11.11,0,0,0,.13,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.13,0A.11.11,0,0,0,709.72,148Z"
          />
          <path
            className="cls-4"
            d="M719.73,153.11a.14.14,0,0,0,0,.17.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17A.13.13,0,0,0,719.73,153.11Z"
          />
          <path
            className="cls-4"
            d="M729.64,158.63a.12.12,0,0,0,0,.17.11.11,0,0,0,.16,0,.12.12,0,1,0-.19-.14Z"
          />
          <path
            className="cls-4"
            d="M739.38,164.42a.13.13,0,0,0,0,.17.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16A.12.12,0,0,0,739.38,164.42Z"
          />
          <path
            className="cls-4"
            d="M749.13,170.46a.12.12,0,0,0-.17,0,.12.12,0,1,0,.17,0Z"
          />
          <path
            className="cls-4"
            d="M758.53,176.79a.11.11,0,0,0-.16,0,.12.12,0,1,0,.19.14A.12.12,0,0,0,758.53,176.79Z"
          />
          <path
            className="cls-4"
            d="M767.62,183.58a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,1,0-.14.19Z"
          />
          <path
            className="cls-4"
            d="M776.63,190.26a.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17A.12.12,0,0,0,776.63,190.26Z"
          />
          <path
            className="cls-4"
            d="M785.49,197.53a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,785.49,197.53Z"
          />
          <path
            className="cls-4"
            d="M794.13,204.87a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,794.13,204.87Z"
          />
          <path
            className="cls-4"
            d="M802.56,212.46a.12.12,0,0,0,.14-.2.13.13,0,0,0-.17,0A.12.12,0,0,0,802.56,212.46Z"
          />
          <path
            className="cls-4"
            d="M810.79,220.25a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,810.79,220.25Z"
          />
          <path
            className="cls-4"
            d="M818.78,228.3a.1.1,0,0,0,.12,0,.08.08,0,0,0,0-.12.08.08,0,0,0-.12,0A.09.09,0,0,0,818.78,228.3Z"
          />
          <circle
            className="cls-4"
            cx="826.59"
            cy="236.49"
            r="0.09"
            transform="translate(152.38 769.98) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M834.05,245.07a.12.12,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,834.05,245.07Z"
          />
          <path
            className="cls-4"
            d="M841.33,253.75a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,841.33,253.75Z"
          />
          <path
            className="cls-4"
            d="M848.37,262.64a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,848.37,262.64Z"
          />
          <path
            className="cls-4"
            d="M855.15,271.72a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.11.11,0,0,0,855.15,271.72Z"
          />
          <circle className="cls-4" cx="861.75" cy="280.89" r="0.12" />
          <path
            className="cls-4"
            d="M868,290.44a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,868,290.44Z"
          />
          <path
            className="cls-4"
            d="M873.94,299.91a.11.11,0,0,0,0,.13.09.09,0,0,0,.13,0,.09.09,0,0,0-.15-.11Z"
          />
          <path
            className="cls-4"
            d="M879.67,309.85a.13.13,0,0,0,.15-.2.12.12,0,0,0-.17,0A.12.12,0,0,0,879.67,309.85Z"
          />
          <path
            className="cls-4"
            d="M885.12,319.79a.12.12,0,0,0,.17,0,.12.12,0,1,0-.17,0Z"
          />
          <path
            className="cls-4"
            d="M890.24,329.69a.15.15,0,0,0,.25.18.16.16,0,0,0,0-.21A.16.16,0,0,0,890.24,329.69Z"
          />
          <path
            className="cls-4"
            d="M895.18,340.11a.11.11,0,0,0,.16,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,895.18,340.11Z"
          />
          <path
            className="cls-4"
            d="M899.77,350.48a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,899.77,350.48Z"
          />
          <path
            className="cls-4"
            d="M904.2,360.79a.11.11,0,0,0-.13,0,.09.09,0,0,0,0,.13.09.09,0,1,0,.11-.15Z"
          />
          <path
            className="cls-4"
            d="M908.1,371.55a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,908.1,371.55Z"
          />
          <path
            className="cls-4"
            d="M911.79,382.29a.13.13,0,0,0,.17,0,.13.13,0,0,0,0-.17.12.12,0,0,0-.17,0A.13.13,0,0,0,911.79,382.29Z"
          />
          <path
            className="cls-4"
            d="M915.21,393.08a.1.1,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,1,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M918.3,404a.12.12,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,918.3,404Z"
          />
          <path
            className="cls-4"
            d="M921.09,415a.12.12,0,1,0,0-.17A.12.12,0,0,0,921.09,415Z"
          />
          <path
            className="cls-4"
            d="M923.57,426.06a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,923.57,426.06Z"
          />
          <path
            className="cls-4"
            d="M925.75,437.18a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,925.75,437.18Z"
          />
          <path
            className="cls-4"
            d="M927.61,448.37a.13.13,0,0,0,.17,0,.14.14,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,927.61,448.37Z"
          />
          <path
            className="cls-4"
            d="M929.13,459.43a.12.12,0,0,0,0,.17.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17A.11.11,0,0,0,929.13,459.43Z"
          />
          <path
            className="cls-4"
            d="M930.38,470.87a.13.13,0,1,0,0-.17A.12.12,0,0,0,930.38,470.87Z"
          />
          <path
            className="cls-4"
            d="M931.3,482.17a.12.12,0,0,0,.17,0,.13.13,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,931.3,482.17Z"
          />
          <path
            className="cls-4"
            d="M931.89,493.49a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.15.19Z"
          />
          <path
            className="cls-4"
            d="M932.17,504.83a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,932.17,504.83Z"
          />
          <path
            className="cls-4"
            d="M932.15,516.14a.09.09,0,1,0,0-.13A.09.09,0,0,0,932.15,516.14Z"
          />
          <path
            className="cls-4"
            d="M931.8,527.47a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.09.09,0,0,0,931.8,527.47Z"
          />
          <path
            className="cls-4"
            d="M931.1,538.81a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,931.1,538.81Z"
          />
          <path
            className="cls-4"
            d="M930.08,549.94a.12.12,0,1,0,.17,0A.12.12,0,0,0,930.08,549.94Z"
          />
          <path
            className="cls-4"
            d="M928.75,561.18a.15.15,0,0,0,0,.21.15.15,0,1,0,.18-.24A.16.16,0,0,0,928.75,561.18Z"
          />
          <path
            className="cls-4"
            d="M927.2,572.56a.09.09,0,1,0,.1-.14.09.09,0,1,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M925.24,583.76a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.13.13,0,0,0,925.24,583.76Z"
          />
          <path
            className="cls-4"
            d="M923,594.85a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,923,594.85Z"
          />
          <path
            className="cls-4"
            d="M920.43,605.92a.12.12,0,1,0,.14-.2.12.12,0,0,0-.17,0A.13.13,0,0,0,920.43,605.92Z"
          />
          <path
            className="cls-4"
            d="M917.56,616.89a.14.14,0,0,0,.17,0,.13.13,0,0,0,0-.17.12.12,0,0,0-.17,0A.13.13,0,0,0,917.56,616.89Z"
          />
          <path
            className="cls-4"
            d="M914.38,627.77a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,914.38,627.77Z"
          />
          <path
            className="cls-4"
            d="M910.9,638.56a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,910.9,638.56Z"
          />
          <path
            className="cls-4"
            d="M907.12,649.25a.13.13,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.14.2Z"
          />
          <path
            className="cls-4"
            d="M903,659.83a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,903,659.83Z"
          />
          <path
            className="cls-4"
            d="M898.68,670.26a.09.09,0,0,0,.13,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.13,0A.08.08,0,0,0,898.68,670.26Z"
          />
          <path
            className="cls-4"
            d="M894,680.59a.09.09,0,1,0,0-.12A.08.08,0,0,0,894,680.59Z"
          />
          <path
            className="cls-4"
            d="M889,690.82a.14.14,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,889,690.82Z"
          />
          <path
            className="cls-4"
            d="M883.81,700.87a.12.12,0,0,0,.17,0,.12.12,0,0,0-.2-.14A.12.12,0,0,0,883.81,700.87Z"
          />
          <circle className="cls-4" cx="878.36" cy="710.68" r="0.12" />
          <path
            className="cls-4"
            d="M872.49,720.52a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.14.14,0,0,0-.17,0A.12.12,0,0,0,872.49,720.52Z"
          />
          <path
            className="cls-4"
            d="M866.43,730.1a.12.12,0,0,0,.17,0,.12.12,0,1,0-.17,0Z"
          />
          <path
            className="cls-4"
            d="M860.1,739.5a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,860.1,739.5Z"
          />
          <path
            className="cls-4"
            d="M853.5,748.73a.13.13,0,1,0,0-.17A.13.13,0,0,0,853.5,748.73Z"
          />
          <path
            className="cls-4"
            d="M846.66,757.76a.11.11,0,0,0,.16,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,846.66,757.76Z"
          />
          <path
            className="cls-4"
            d="M839.56,766.6a.12.12,0,0,0,.17,0,.14.14,0,0,0,0-.17.12.12,0,0,0-.17,0A.12.12,0,0,0,839.56,766.6Z"
          />
          <path
            className="cls-4"
            d="M832.21,775.24a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,832.21,775.24Z"
          />
          <path
            className="cls-4"
            d="M824.63,783.67a.13.13,0,0,0,.17,0,.14.14,0,0,0,0-.17.13.13,0,0,0-.17,0A.12.12,0,0,0,824.63,783.67Z"
          />
          <path
            className="cls-4"
            d="M816.83,791.85a.08.08,0,0,0,.12,0,.09.09,0,1,0-.14-.1A.08.08,0,0,0,816.83,791.85Z"
          />
          <path
            className="cls-4"
            d="M808.78,799.84a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,808.78,799.84Z"
          />
          <path
            className="cls-4"
            d="M800.52,807.6a.08.08,0,0,0,.12,0,.09.09,0,1,0-.14-.1A.08.08,0,0,0,800.52,807.6Z"
          />
          <path
            className="cls-4"
            d="M792,815.15a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.11.11,0,0,0,792,815.15Z"
          />
          <path
            className="cls-4"
            d="M783.33,822.44a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.11.11,0,0,0-.16,0A.12.12,0,0,0,783.33,822.44Z"
          />
          <path
            className="cls-4"
            d="M774.44,829.48a.13.13,0,0,0,.15-.2.12.12,0,0,0-.17,0A.12.12,0,0,0,774.44,829.48Z"
          />
          <path
            className="cls-4"
            d="M765.36,836.26a.12.12,0,0,0,.17,0,.12.12,0,0,0,0-.17.12.12,0,0,0-.17,0A.14.14,0,0,0,765.36,836.26Z"
          />
          <path
            className="cls-4"
            d="M756.09,842.79a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.11.11,0,0,0-.16,0A.12.12,0,0,0,756.09,842.79Z"
          />
          <path
            className="cls-4"
            d="M746.66,849a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,746.66,849Z"
          />
          <path
            className="cls-4"
            d="M737,855a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,737,855Z"
          />
          <path
            className="cls-4"
            d="M727.24,860.78a.12.12,0,1,0,.14-.19.12.12,0,0,0-.17,0A.11.11,0,0,0,727.24,860.78Z"
          />
          <path
            className="cls-4"
            d="M717.32,866.21a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.1.1,0,0,0,717.32,866.21Z"
          />
          <path
            className="cls-4"
            d="M712.83,882.7a.08.08,0,0,0,.12,0,.09.09,0,0,0-.15-.11A.09.09,0,0,0,712.83,882.7Z"
          />
          <path
            className="cls-4"
            d="M702.59,887.59a.09.09,0,0,0,.13,0,.09.09,0,1,0-.15-.11A.11.11,0,0,0,702.59,887.59Z"
          />
          <path
            className="cls-4"
            d="M692.24,892.2a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,692.24,892.2Z"
          />
          <path
            className="cls-4"
            d="M681.76,896.52a.08.08,0,0,0,.12,0,.09.09,0,1,0-.12,0Z"
          />
          <path
            className="cls-4"
            d="M671.16,900.56a.09.09,0,1,0,0-.13A.09.09,0,0,0,671.16,900.56Z"
          />
          <path
            className="cls-4"
            d="M660.46,904.31a.09.09,0,1,0,0-.13A.09.09,0,0,0,660.46,904.31Z"
          />
          <path
            className="cls-4"
            d="M649.66,907.76a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,649.66,907.76Z"
          />
          <path
            className="cls-4"
            d="M638.78,910.92a.08.08,0,0,0,.12,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M627.81,913.79a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.1.1,0,0,0,627.81,913.79Z"
          />
          <path
            className="cls-4"
            d="M616.76,916.35a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,616.76,916.35Z"
          />
          <path
            className="cls-4"
            d="M605.65,918.62a.09.09,0,1,0,0-.13A.09.09,0,0,0,605.65,918.62Z"
          />
          <path
            className="cls-4"
            d="M594.48,920.58a.09.09,0,0,0,.13,0,.09.09,0,1,0-.15-.11A.11.11,0,0,0,594.48,920.58Z"
          />
          <path
            className="cls-4"
            d="M583.27,922.23a.09.09,0,0,0,.13,0,.09.09,0,0,0,0-.12.09.09,0,0,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M572,923.58a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,572,923.58Z"
          />
          <path
            className="cls-4"
            d="M560.72,924.63a.09.09,0,1,0,0-.13A.09.09,0,0,0,560.72,924.63Z"
          />
          <path
            className="cls-4"
            d="M549.43,925.34a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,549.43,925.34Z"
          />
          <path
            className="cls-4"
            d="M538.08,925.79a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.09.09,0,0,0-.1.15Z"
          />
          <path
            className="cls-4"
            d="M526.76,925.89a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,526.76,925.89Z"
          />
          <path
            className="cls-4"
            d="M515.42,925.7a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,515.42,925.7Z"
          />
          <path
            className="cls-4"
            d="M504.08,925.12a.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09A.07.07,0,0,0,504.08,925.12Z"
          />
          <path
            className="cls-4"
            d="M492.77,924.42a.09.09,0,1,0,0-.13A.09.09,0,0,0,492.77,924.42Z"
          />
          <path
            className="cls-4"
            d="M481.49,923.31a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,481.49,923.31Z"
          />
          <path
            className="cls-4"
            d="M470.24,921.89a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,470.24,921.89Z"
          />
          <path
            className="cls-4"
            d="M459,920.17a.09.09,0,0,0,.13,0,.09.09,0,0,0,0-.13.09.09,0,1,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M447.9,918.11a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,447.9,918.11Z"
          />
          <path
            className="cls-4"
            d="M436.78,915.81a.09.09,0,1,0,0-.13A.09.09,0,0,0,436.78,915.81Z"
          />
          <path
            className="cls-4"
            d="M425.75,913.18a.09.09,0,0,0,.13,0,.09.09,0,1,0-.15-.11A.11.11,0,0,0,425.75,913.18Z"
          />
          <path
            className="cls-4"
            d="M414.78,910.12a.09.09,0,1,0,.15.11.09.09,0,0,0,0-.13A.09.09,0,0,0,414.78,910.12Z"
          />
          <path
            className="cls-4"
            d="M403.93,907a.09.09,0,1,0,0-.13A.09.09,0,0,0,403.93,907Z"
          />
          <path
            className="cls-4"
            d="M393.16,903.5a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,393.16,903.5Z"
          />
          <path
            className="cls-4"
            d="M382.5,899.66a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,382.5,899.66Z"
          />
          <path
            className="cls-4"
            d="M371.93,895.56a.06.06,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,371.93,895.56Z"
          />
          <path
            className="cls-4"
            d="M361.47,891.17a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,361.47,891.17Z"
          />
          <path
            className="cls-4"
            d="M351.14,886.5a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,351.14,886.5Z"
          />
          <path
            className="cls-4"
            d="M340.92,881.57a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.09.09,0,0,0,340.92,881.57Z"
          />
          <path
            className="cls-4"
            d="M330.86,876.35a.09.09,0,1,0,0-.13A.09.09,0,0,0,330.86,876.35Z"
          />
          <circle className="cls-4" cx="321" cy="870.78" r="0.06" />
          <path
            className="cls-4"
            d="M311.2,865.06a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.07.07,0,0,0,311.2,865.06Z"
          />
          <path
            className="cls-4"
            d="M301.6,859a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,301.6,859Z"
          />
          <circle className="cls-4" cx="292.2" cy="852.7" r="0.06" />
          <path
            className="cls-4"
            d="M282.89,846.12a.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09A.07.07,0,0,0,282.89,846.12Z"
          />
          <path
            className="cls-4"
            d="M273.83,839.41a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,273.83,839.41Z"
          />
          <path
            className="cls-4"
            d="M264.94,832.38a.07.07,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,264.94,832.38Z"
          />
          <path
            className="cls-4"
            d="M256.24,825.1a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,256.24,825.1Z"
          />
          <path
            className="cls-4"
            d="M247.75,817.59a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.08.08,0,0,0-.09,0A.05.05,0,0,0,247.75,817.59Z"
          />
          <path
            className="cls-4"
            d="M239.44,809.88a.09.09,0,1,0,0-.13A.09.09,0,0,0,239.44,809.88Z"
          />
          <path
            className="cls-4"
            d="M231.37,801.92a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.08.08,0,0,0,231.37,801.92Z"
          />
          <path
            className="cls-4"
            d="M223.53,793.72a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M215.9,785.33s.07,0,.08,0a.06.06,0,0,0-.09-.07S215.87,785.32,215.9,785.33Z"
          />
          <path
            className="cls-4"
            d="M208.57,776.65a.06.06,0,0,0-.07.09.06.06,0,0,0,.09,0S208.6,776.66,208.57,776.65Z"
          />
          <path
            className="cls-4"
            d="M201.32,768a.09.09,0,1,0,0-.13A.09.09,0,0,0,201.32,768Z"
          />
          <path
            className="cls-4"
            d="M194.4,759a.09.09,0,1,0,0-.13A.09.09,0,0,0,194.4,759Z"
          />
          <path
            className="cls-4"
            d="M187.74,749.81a.07.07,0,0,0,.09,0,.06.06,0,0,0-.1-.06A.06.06,0,0,0,187.74,749.81Z"
          />
          <path
            className="cls-4"
            d="M181.39,740.37a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.08.08,0,0,0,.09,0A.05.05,0,0,0,181.39,740.37Z"
          />
          <path
            className="cls-4"
            d="M175.14,731a.11.11,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.09.09,0,0,0,175.14,731Z"
          />
          <path
            className="cls-4"
            d="M169.25,721.27a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,169.25,721.27Z"
          />
          <path
            className="cls-4"
            d="M163.61,711.44a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,163.61,711.44Z"
          />
          <path
            className="cls-4"
            d="M158.23,701.47a.09.09,0,1,0,0-.12A.08.08,0,0,0,158.23,701.47Z"
          />
          <path
            className="cls-4"
            d="M153.24,691.2a.09.09,0,1,0,0,.13A.09.09,0,0,0,153.24,691.2Z"
          />
          <path
            className="cls-4"
            d="M148.31,681.08a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,148.31,681.08Z"
          />
          <path
            className="cls-4"
            d="M143.86,670.57a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,143.86,670.57Z"
          />
          <path
            className="cls-4"
            d="M139.52,660.18a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,139.52,660.18Z"
          />
          <path
            className="cls-4"
            d="M135.64,649.44a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,135.64,649.44Z"
          />
          <path
            className="cls-4"
            d="M132,638.69a.09.09,0,0,0-.13,0,.09.09,0,1,0,.13,0Z"
          />
          <path
            className="cls-4"
            d="M128.52,628a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.05.05,0,0,0,128.52,628Z"
          />
          <path
            className="cls-4"
            d="M125.52,617a.09.09,0,1,0,0,.13A.09.09,0,0,0,125.52,617Z"
          />
          <path
            className="cls-4"
            d="M122.73,606a.09.09,0,1,0,0,.13A.09.09,0,0,0,122.73,606Z"
          />
          <path
            className="cls-4"
            d="M120.24,594.91a.09.09,0,1,0,0,.13A.09.09,0,0,0,120.24,594.91Z"
          />
          <path
            className="cls-4"
            d="M118,583.81a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.06.06,0,1,0,.07-.1Z"
          />
          <path
            className="cls-4"
            d="M116.16,572.61a.09.09,0,1,0-.1.14.09.09,0,0,0,.13,0A.08.08,0,0,0,116.16,572.61Z"
          />
          <path
            className="cls-4"
            d="M114.46,561.4a.09.09,0,1,0,.15.11.09.09,0,0,0-.15-.11Z"
          />
          <path
            className="cls-4"
            d="M113.3,550.14a.06.06,0,0,0-.09,0,.06.06,0,1,0,.1.07A.06.06,0,0,0,113.3,550.14Z"
          />
          <path
            className="cls-4"
            d="M112.35,538.82a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,112.35,538.82Z"
          />
          <path
            className="cls-4"
            d="M111.67,527.52a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.06.06,0,0,0,111.67,527.52Z"
          />
          <path
            className="cls-4"
            d="M111.34,516.17a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,111.34,516.17Z"
          />
          <path
            className="cls-4"
            d="M111.28,504.85a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,111.28,504.85Z"
          />
          <path
            className="cls-4"
            d="M111.56,493.49a.09.09,0,1,0,0,.13A.09.09,0,0,0,111.56,493.49Z"
          />
          <path
            className="cls-4"
            d="M112.11,482.19a.07.07,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,112.11,482.19Z"
          />
          <path
            className="cls-4"
            d="M113,471a.09.09,0,1,0-.12,0A.08.08,0,0,0,113,471Z"
          />
          <path
            className="cls-4"
            d="M114.11,459.71a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,114.11,459.71Z"
          />
          <path
            className="cls-4"
            d="M115.7,448.35a.09.09,0,1,0,0,.13A.09.09,0,0,0,115.7,448.35Z"
          />
          <path
            className="cls-4"
            d="M117.5,437.16a.09.09,0,1,0,0,.13A.09.09,0,0,0,117.5,437.16Z"
          />
          <circle className="cls-4" cx="119.55" cy="426.09" r="0.06" />
          <path
            className="cls-4"
            d="M122,414.94a.09.09,0,1,0-.11.15.11.11,0,0,0,.13,0A.09.09,0,0,0,122,414.94Z"
          />
          <path
            className="cls-4"
            d="M124.7,404a.07.07,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.07.07,0,0,0,124.7,404Z"
          />
          <path
            className="cls-4"
            d="M127.7,393a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0A.06.06,0,0,0,127.7,393Z"
          />
          <path
            className="cls-4"
            d="M131,382.15a.09.09,0,1,0,0,.13A.09.09,0,0,0,131,382.15Z"
          />
          <path
            className="cls-4"
            d="M134.61,371.4a.08.08,0,0,0-.12,0,.09.09,0,1,0,.14.1A.08.08,0,0,0,134.61,371.4Z"
          />
          <path
            className="cls-4"
            d="M138.48,360.77a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,138.48,360.77Z"
          />
          <path
            className="cls-4"
            d="M142.66,350.23a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.07.07,0,0,0,142.66,350.23Z"
          />
          <path
            className="cls-4"
            d="M147.12,339.81a.06.06,0,0,0-.09,0,.06.06,0,0,0,.1.07A.06.06,0,0,0,147.12,339.81Z"
          />
          <circle className="cls-4" cx="151.82" cy="329.56" r="0.06" />
          <path
            className="cls-4"
            d="M156.89,319.32a.08.08,0,0,0-.12,0,.08.08,0,0,0,0,.12.09.09,0,1,0,.1-.14Z"
          />
          <path
            className="cls-4"
            d="M162.17,309.32a.06.06,0,1,0-.07.09.06.06,0,0,0,.07-.09Z"
          />
          <path
            className="cls-4"
            d="M167.73,299.44a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.08.08,0,0,0,.09,0A.06.06,0,0,0,167.73,299.44Z"
          />
          <path
            className="cls-4"
            d="M173.57,289.72a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,173.57,289.72Z"
          />
          <path
            className="cls-4"
            d="M179.66,280.15a.06.06,0,1,0-.07.1.06.06,0,0,0,.08,0A.06.06,0,0,0,179.66,280.15Z"
          />
          <path
            className="cls-4"
            d="M186,270.76a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0A.06.06,0,0,0,186,270.76Z"
          />
          <path
            className="cls-4"
            d="M192.62,261.55a.06.06,0,0,0-.09,0,.06.06,0,1,0,.1.07A.06.06,0,0,0,192.62,261.55Z"
          />
          <path
            className="cls-4"
            d="M199.47,252.51a.08.08,0,0,0-.09,0,.06.06,0,0,0,0,.08.05.05,0,0,0,.08,0A.07.07,0,0,0,199.47,252.51Z"
          />
          <circle className="cls-4" cx="206.53" cy="243.72" r="0.06" />
          <path
            className="cls-4"
            d="M213.9,235a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,213.9,235Z"
          />
          <path
            className="cls-4"
            d="M221.46,226.58a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,221.46,226.58Z"
          />
          <circle
            className="cls-4"
            cx="229.22"
            cy="218.4"
            r="0.09"
            transform="translate(-81.87 277.21) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M237.27,210.33a.05.05,0,0,0-.08,0,.06.06,0,1,0,.09.07A.05.05,0,0,0,237.27,210.33Z"
          />
          <path
            className="cls-4"
            d="M245.5,202.53a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,245.5,202.53Z"
          />
          <path
            className="cls-4"
            d="M253.94,195a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,253.94,195Z"
          />
          <path
            className="cls-4"
            d="M262.58,187.62a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.07-.09Z"
          />
          <path
            className="cls-4"
            d="M271.43,180.49a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,271.43,180.49Z"
          />
          <path
            className="cls-4"
            d="M280.46,173.63a.09.09,0,0,0-.13,0,.1.1,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,280.46,173.63Z"
          />
          <path
            className="cls-4"
            d="M289.67,167a.08.08,0,0,0-.12,0,.09.09,0,1,0,.14.1A.08.08,0,0,0,289.67,167Z"
          />
          <path
            className="cls-4"
            d="M299,160.68a.06.06,0,0,0-.08,0,.06.06,0,1,0,.09.07A.06.06,0,0,0,299,160.68Z"
          />
          <circle
            className="cls-4"
            cx="308.56"
            cy="154.63"
            r="0.09"
            transform="translate(2.98 315.08) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M318.32,148.75a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,318.32,148.75Z"
          />
          <path
            className="cls-4"
            d="M328.19,143.17a.06.06,0,1,0-.07.1.06.06,0,0,0,.08,0A.06.06,0,0,0,328.19,143.17Z"
          />
          <path
            className="cls-4"
            d="M338.23,137.85a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,338.23,137.85Z"
          />
          <path
            className="cls-4"
            d="M348.39,132.82a.09.09,0,0,0-.13,0,.1.1,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,348.39,132.82Z"
          />
          <path
            className="cls-4"
            d="M358.69,128.07a.09.09,0,1,0,0,.13A.09.09,0,0,0,358.69,128.07Z"
          />
          <path
            className="cls-4"
            d="M369.09,123.63a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,369.09,123.63Z"
          />
          <path
            className="cls-4"
            d="M379.65,119.42a.09.09,0,0,0-.13,0,.09.09,0,0,0,.15.11A.11.11,0,0,0,379.65,119.42Z"
          />
          <path
            className="cls-4"
            d="M390.28,115.55a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.05.05,0,0,0,.08,0A.07.07,0,0,0,390.28,115.55Z"
          />
          <path
            className="cls-4"
            d="M401,111.93a.09.09,0,1,0,0,.12A.08.08,0,0,0,401,111.93Z"
          />
          <path
            className="cls-4"
            d="M411.87,108.64a.06.06,0,0,0-.07.1.06.06,0,0,0,.08,0A.06.06,0,0,0,411.87,108.64Z"
          />
          <circle
            className="cls-4"
            cx="422.76"
            cy="105.68"
            r="0.09"
            transform="translate(90.34 387.45) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M433.81,102.92a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.07-.09Z"
          />
          <path
            className="cls-4"
            d="M444.9,100.48a.09.09,0,1,0,0,.12A.08.08,0,0,0,444.9,100.48Z"
          />
          <path
            className="cls-4"
            d="M456,98.36a.09.09,0,0,0-.12,0,.09.09,0,1,0,.14.1A.09.09,0,0,0,456,98.36Z"
          />
          <circle
            className="cls-4"
            cx="467.18"
            cy="96.63"
            r="0.09"
            transform="translate(116.2 419.75) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M478.47,95.05a.08.08,0,0,0-.12,0,.09.09,0,0,0,0,.13.08.08,0,0,0,.12,0A.09.09,0,0,0,478.47,95.05Z"
          />
          <path
            className="cls-4"
            d="M489.75,93.86a.09.09,0,0,0-.13,0,.08.08,0,0,0,0,.12.09.09,0,0,0,.13,0A.08.08,0,0,0,489.75,93.86Z"
          />
          <path
            className="cls-4"
            d="M501,93a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,501,93Z"
          />
          <path
            className="cls-4"
            d="M512.37,92.38a.09.09,0,1,0,0,.13A.09.09,0,0,0,512.37,92.38Z"
          />
          <path
            className="cls-4"
            d="M523.71,92.11a.09.09,0,1,0,0,.13A.09.09,0,0,0,523.71,92.11Z"
          />
          <path
            className="cls-4"
            d="M535,92.14a.1.1,0,0,0-.12,0,.08.08,0,0,0,0,.12.08.08,0,0,0,.12,0A.09.09,0,0,0,535,92.14Z"
          />
          <path
            className="cls-4"
            d="M546.27,92.64a.11.11,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M557.59,93.28a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.08.08,0,0,0-.12,0A.08.08,0,0,0,557.59,93.28Z"
          />
          <path
            className="cls-4"
            d="M569,94.1a.09.09,0,1,0,0,.12A.08.08,0,0,0,569,94.1Z"
          />
          <path
            className="cls-4"
            d="M580.26,95.36a.09.09,0,1,0,0,.13A.09.09,0,0,0,580.26,95.36Z"
          />
          <path
            className="cls-4"
            d="M591.49,96.93a.09.09,0,1,0,0,.13A.09.09,0,0,0,591.49,96.93Z"
          />
          <circle
            className="cls-4"
            cx="602.62"
            cy="98.88"
            r="0.09"
            transform="translate(170.81 530.7) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M613.8,101a.09.09,0,0,0-.11.15.1.1,0,0,0,.13,0A.09.09,0,0,0,613.8,101Z"
          />
          <path
            className="cls-4"
            d="M624.73,103.49a.09.09,0,1,0,.13,0A.09.09,0,0,0,624.73,103.49Z"
          />
          <path
            className="cls-4"
            d="M635.85,106.25a.09.09,0,1,0,0,.13A.09.09,0,0,0,635.85,106.25Z"
          />
          <path
            className="cls-4"
            d="M646.66,109.48a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.1.1,0,0,0,646.66,109.48Z"
          />
          <path
            className="cls-4"
            d="M657.48,112.85a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.09.09,0,0,0-.12,0A.08.08,0,0,0,657.48,112.85Z"
          />
          <path
            className="cls-4"
            d="M668.21,116.52a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,668.21,116.52Z"
          />
          <path
            className="cls-4"
            d="M678.81,120.35a.09.09,0,1,0,.13,0A.09.09,0,0,0,678.81,120.35Z"
          />
          <path
            className="cls-4"
            d="M689.34,124.72a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,689.34,124.72Z"
          />
          <circle
            className="cls-4"
            cx="699.79"
            cy="129.18"
            r="0.09"
            transform="translate(186.7 622.26) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M710,134.06a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,710,134.06Z"
          />
          <path
            className="cls-4"
            d="M720.11,139a.09.09,0,0,0,.15.1.08.08,0,0,0,0-.12A.09.09,0,0,0,720.11,139Z"
          />
          <path
            className="cls-4"
            d="M730.12,144.51a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,730.12,144.51Z"
          />
          <path
            className="cls-4"
            d="M740,150.15a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.13,0A.09.09,0,0,0,740,150.15Z"
          />
          <path
            className="cls-4"
            d="M749.65,156a.09.09,0,1,0,0-.12A.08.08,0,0,0,749.65,156Z"
          />
          <path
            className="cls-4"
            d="M759.27,162.06a.09.09,0,1,0,0,.12A.08.08,0,0,0,759.27,162.06Z"
          />
          <circle
            className="cls-4"
            cx="768.57"
            cy="168.54"
            r="0.09"
            transform="matrix(0.58, -0.81, 0.81, 0.58, 183.39, 694.54)"
          />
          <path
            className="cls-4"
            d="M777.69,175.28a.09.09,0,0,0,.1-.14.09.09,0,1,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M786.67,182.2a.09.09,0,1,0,0-.13A.09.09,0,0,0,786.67,182.2Z"
          />
          <circle
            className="cls-4"
            cx="795.52"
            cy="189.28"
            r="0.09"
            transform="translate(177.77 725.07) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M804.06,196.75a.11.11,0,0,0,.13,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M812.45,204.37a.09.09,0,1,0,0-.13A.09.09,0,0,0,812.45,204.37Z"
          />
          <path
            className="cls-4"
            d="M820.63,212.22a.09.09,0,0,0,.13,0,.09.09,0,1,0-.15-.11A.11.11,0,0,0,820.63,212.22Z"
          />
          <path
            className="cls-4"
            d="M828.6,220.29a.09.09,0,1,0,0-.13A.09.09,0,0,0,828.6,220.29Z"
          />
          <path
            className="cls-4"
            d="M836.34,228.57a.09.09,0,1,0,0-.13A.09.09,0,0,0,836.34,228.57Z"
          />
          <path
            className="cls-4"
            d="M843.86,237.06a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,843.86,237.06Z"
          />
          <path
            className="cls-4"
            d="M851.14,245.75a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,851.14,245.75Z"
          />
          <path
            className="cls-4"
            d="M858.18,254.63a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,858.18,254.63Z"
          />
          <path
            className="cls-4"
            d="M865,263.71a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,865,263.71Z"
          />
          <path
            className="cls-4"
            d="M871.51,273a.12.12,0,1,0,0-.17A.13.13,0,0,0,871.51,273Z"
          />
          <path
            className="cls-4"
            d="M877.82,282.39a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,877.82,282.39Z"
          />
          <path
            className="cls-4"
            d="M883.86,292a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.1.15Z"
          />
          <path
            className="cls-4"
            d="M889.63,301.75a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,889.63,301.75Z"
          />
          <path
            className="cls-4"
            d="M895.13,311.66a.09.09,0,1,0,0-.13A.09.09,0,0,0,895.13,311.66Z"
          />
          <path
            className="cls-4"
            d="M900.37,321.72a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,900.37,321.72Z"
          />
          <path
            className="cls-4"
            d="M905.33,331.91a.09.09,0,1,0,.1-.14.09.09,0,0,0-.13,0A.08.08,0,0,0,905.33,331.91Z"
          />
          <path
            className="cls-4"
            d="M910,342.24a.09.09,0,1,0,.11-.15.11.11,0,0,0-.13,0A.08.08,0,0,0,910,342.24Z"
          />
          <path
            className="cls-4"
            d="M914.4,352.69a.09.09,0,0,0,.13,0,.09.09,0,0,0-.15-.1A.08.08,0,0,0,914.4,352.69Z"
          />
          <path
            className="cls-4"
            d="M918.51,363.26a.09.09,0,0,0,.13,0,.11.11,0,0,0,0-.13.09.09,0,0,0-.13,0A.09.09,0,0,0,918.51,363.26Z"
          />
          <path
            className="cls-4"
            d="M922.33,373.93a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,922.33,373.93Z"
          />
          <path
            className="cls-4"
            d="M925.86,384.71a.09.09,0,1,0,0-.13A.09.09,0,0,0,925.86,384.71Z"
          />
          <path
            className="cls-4"
            d="M929.1,395.57a.09.09,0,1,0,.1-.14.08.08,0,0,0-.12,0A.08.08,0,0,0,929.1,395.57Z"
          />
          <path
            className="cls-4"
            d="M932,406.55a.11.11,0,0,0,.16,0,.12.12,0,1,0-.19-.14A.12.12,0,0,0,932,406.55Z"
          />
          <path
            className="cls-4"
            d="M934.67,417.55a.09.09,0,1,0,0-.13A.09.09,0,0,0,934.67,417.55Z"
          />
          <path
            className="cls-4"
            d="M937,428.64a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,937,428.64Z"
          />
          <path
            className="cls-4"
            d="M939.05,439.8a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,939.05,439.8Z"
          />
          <path
            className="cls-4"
            d="M940.78,451a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,940.78,451Z"
          />
          <path
            className="cls-4"
            d="M942.21,462.25a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,942.21,462.25Z"
          />
          <path
            className="cls-4"
            d="M943.33,473.53a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13s-.1,0-.12,0A.09.09,0,0,0,943.33,473.53Z"
          />
          <path
            className="cls-4"
            d="M944.14,484.84a.09.09,0,0,0,.13,0,.11.11,0,0,0,0-.13.09.09,0,1,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M944.65,496.17a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.09.09,0,0,0-.13,0A.11.11,0,0,0,944.65,496.17Z"
          />
          <path
            className="cls-4"
            d="M944.84,507.5a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,944.84,507.5Z"
          />
          <path
            className="cls-4"
            d="M944.73,518.84a.09.09,0,1,0,0-.13A.09.09,0,0,0,944.73,518.84Z"
          />
          <path
            className="cls-4"
            d="M944.31,530.17a.09.09,0,1,0,0-.13A.09.09,0,0,0,944.31,530.17Z"
          />
          <path
            className="cls-4"
            d="M943.57,541.51a.11.11,0,0,0,.16,0,.12.12,0,1,0-.19-.14A.12.12,0,0,0,943.57,541.51Z"
          />
          <path
            className="cls-4"
            d="M942.53,552.65a.09.09,0,1,0,.12,0A.08.08,0,0,0,942.53,552.65Z"
          />
          <path
            className="cls-4"
            d="M941.21,564a.08.08,0,0,0,.12,0,.08.08,0,0,0,0-.12.08.08,0,0,0-.12,0A.08.08,0,0,0,941.21,564Z"
          />
          <path
            className="cls-4"
            d="M939.56,575.25a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,939.56,575.25Z"
          />
          <path
            className="cls-4"
            d="M937.61,586.42a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,937.61,586.42Z"
          />
          <path
            className="cls-4"
            d="M935.35,597.53a.09.09,0,1,0,0-.13A.09.09,0,0,0,935.35,597.53Z"
          />
          <path
            className="cls-4"
            d="M932.8,608.57a.09.09,0,0,0,.1-.14.08.08,0,0,0-.12,0A.08.08,0,0,0,932.8,608.57Z"
          />
          <path
            className="cls-4"
            d="M929.94,619.55a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,929.94,619.55Z"
          />
          <path
            className="cls-4"
            d="M926.77,630.46a.12.12,0,0,0,.17,0,.11.11,0,0,0,0-.16.12.12,0,0,0-.17,0A.12.12,0,0,0,926.77,630.46Z"
          />
          <path
            className="cls-4"
            d="M923.34,641.24a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,923.34,641.24Z"
          />
          <path
            className="cls-4"
            d="M919.6,651.94a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,919.6,651.94Z"
          />
          <path
            className="cls-4"
            d="M915.57,662.54a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,915.57,662.54Z"
          />
          <path
            className="cls-4"
            d="M911.25,673a.09.09,0,0,0,.11-.15.09.09,0,1,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M906.65,683.38a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.1.14Z"
          />
          <path
            className="cls-4"
            d="M901.77,693.62a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,901.77,693.62Z"
          />
          <path
            className="cls-4"
            d="M896.61,703.71a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,896.61,703.71Z"
          />
          <path
            className="cls-4"
            d="M891.18,713.67a.09.09,0,0,0,.13,0,.09.09,0,0,0,0-.13.09.09,0,1,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M885.48,723.47a.09.09,0,1,0,0-.13A.09.09,0,0,0,885.48,723.47Z"
          />
          <path
            className="cls-4"
            d="M879.52,733.11a.09.09,0,0,0,.11-.15.09.09,0,0,0-.13,0A.09.09,0,0,0,879.52,733.11Z"
          />
          <path
            className="cls-4"
            d="M873.3,742.59a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,873.3,742.59Z"
          />
          <path
            className="cls-4"
            d="M866.82,751.89a.08.08,0,0,0,.12,0,.09.09,0,1,0-.14-.1A.08.08,0,0,0,866.82,751.89Z"
          />
          <path
            className="cls-4"
            d="M860.09,761a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,860.09,761Z"
          />
          <circle
            className="cls-4"
            cx="853.17"
            cy="769.88"
            r="0.09"
            transform="translate(-269.81 1013.86) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M845.9,778.7a.09.09,0,0,0,.13,0,.11.11,0,0,0,0-.13.09.09,0,0,0-.13,0A.09.09,0,0,0,845.9,778.7Z"
          />
          <path
            className="cls-4"
            d="M838.45,787.25a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,838.45,787.25Z"
          />
          <path
            className="cls-4"
            d="M830.77,795.59a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,830.77,795.59Z"
          />
          <path
            className="cls-4"
            d="M822.86,803.71a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,822.86,803.71Z"
          />
          <path
            className="cls-4"
            d="M814.74,811.62a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,814.74,811.62Z"
          />
          <path
            className="cls-4"
            d="M806.41,819.31a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,806.41,819.31Z"
          />
          <path
            className="cls-4"
            d="M797.87,826.77a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,797.87,826.77Z"
          />
          <path
            className="cls-4"
            d="M789.13,834a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,789.13,834Z"
          />
          <path
            className="cls-4"
            d="M780.19,841a.09.09,0,1,0,0-.13A.09.09,0,0,0,780.19,841Z"
          />
          <path
            className="cls-4"
            d="M771.07,847.71a.09.09,0,1,0,0-.13A.09.09,0,0,0,771.07,847.71Z"
          />
          <path
            className="cls-4"
            d="M761.78,854.19a.08.08,0,0,0,.12,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,761.78,854.19Z"
          />
          <path
            className="cls-4"
            d="M752.3,860.42a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,752.3,860.42Z"
          />
          <path
            className="cls-4"
            d="M742.66,866.39a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,742.66,866.39Z"
          />
          <circle
            className="cls-4"
            cx="732.92"
            cy="872.02"
            r="0.09"
            transform="translate(-402.9 958.75) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M722.92,877.54a.1.1,0,0,0,.12,0,.09.09,0,0,0-.14-.1A.09.09,0,0,0,722.92,877.54Z"
          />
          <path
            className="cls-4"
            d="M718.44,894a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,718.44,894Z"
          />
          <path
            className="cls-4"
            d="M708.22,898.89a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,708.22,898.89Z"
          />
          <path
            className="cls-4"
            d="M697.86,903.51a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.06.06,0,0,0-.08,0A.05.05,0,0,0,697.86,903.51Z"
          />
          <path
            className="cls-4"
            d="M687.39,907.86a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,687.39,907.86Z"
          />
          <path
            className="cls-4"
            d="M676.81,911.92a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,676.81,911.92Z"
          />
          <path
            className="cls-4"
            d="M666.12,915.71a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,666.12,915.71Z"
          />
          <path
            className="cls-4"
            d="M655.34,919.21a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0S655.31,919.2,655.34,919.21Z"
          />
          <path
            className="cls-4"
            d="M644.47,922.43a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.08.08,0,0,0-.09,0A.05.05,0,0,0,644.47,922.43Z"
          />
          <path
            className="cls-4"
            d="M633.51,925.36a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,633.51,925.36Z"
          />
          <path
            className="cls-4"
            d="M622.49,928a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.06.06,0,1,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M611.4,930.35a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,611.4,930.35Z"
          />
          <path
            className="cls-4"
            d="M600.25,932.4a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,600.25,932.4Z"
          />
          <circle className="cls-4" cx="589.08" cy="934.11" r="0.06" />
          <path
            className="cls-4"
            d="M577.8,935.62a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,577.8,935.62Z"
          />
          <path
            className="cls-4"
            d="M566.51,936.81a.09.09,0,1,0,0-.12A.08.08,0,0,0,566.51,936.81Z"
          />
          <path
            className="cls-4"
            d="M555.22,937.66a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,555.22,937.66Z"
          />
          <path
            className="cls-4"
            d="M543.9,938.23a.07.07,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,543.9,938.23Z"
          />
          <path
            className="cls-4"
            d="M532.56,938.5a.08.08,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,532.56,938.5Z"
          />
          <path
            className="cls-4"
            d="M521.22,938.47a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,521.22,938.47Z"
          />
          <path
            className="cls-4"
            d="M509.89,938.14a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,509.89,938.14Z"
          />
          <path
            className="cls-4"
            d="M498.57,937.51a.06.06,0,1,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,498.57,937.51Z"
          />
          <path
            className="cls-4"
            d="M487.27,936.58a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.05.05,0,0,0,487.27,936.58Z"
          />
          <path
            className="cls-4"
            d="M476,935.36a.06.06,0,1,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,476,935.36Z"
          />
          <path
            className="cls-4"
            d="M464.77,933.84a.07.07,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,464.77,933.84Z"
          />
          <path
            className="cls-4"
            d="M453.56,931.93a.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08A.06.06,0,0,0,453.56,931.93Z"
          />
          <circle className="cls-4" cx="442.47" cy="929.86" r="0.06" />
          <path
            className="cls-4"
            d="M431.36,927.5a.06.06,0,0,0,.08,0,.06.06,0,1,0-.1-.08A.07.07,0,0,0,431.36,927.5Z"
          />
          <path
            className="cls-4"
            d="M420.34,924.8a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,420.34,924.8Z"
          />
          <path
            className="cls-4"
            d="M409.41,921.81a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,409.41,921.81Z"
          />
          <path
            className="cls-4"
            d="M398.55,918.54a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,398.55,918.54Z"
          />
          <path
            className="cls-4"
            d="M387.79,915a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,1,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M377.12,911.14a.08.08,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,377.12,911.14Z"
          />
          <path
            className="cls-4"
            d="M366.56,907a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,366.56,907Z"
          />
          <path
            className="cls-4"
            d="M356.11,902.61a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,356.11,902.61Z"
          />
          <path
            className="cls-4"
            d="M345.78,897.94a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,345.78,897.94Z"
          />
          <path
            className="cls-4"
            d="M335.57,892.91a.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09A.05.05,0,0,0,335.57,892.91Z"
          />
          <path
            className="cls-4"
            d="M325.51,887.78a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,325.51,887.78Z"
          />
          <path
            className="cls-4"
            d="M315.59,882.3a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,315.59,882.3Z"
          />
          <path
            className="cls-4"
            d="M305.81,876.56a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,305.81,876.56Z"
          />
          <path
            className="cls-4"
            d="M296.18,870.57a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,296.18,870.57Z"
          />
          <path
            className="cls-4"
            d="M286.72,864.32a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,286.72,864.32Z"
          />
          <path
            className="cls-4"
            d="M277.43,857.83a.06.06,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.08.08,0,0,0,277.43,857.83Z"
          />
          <circle className="cls-4" cx="268.34" cy="851.04" r="0.06" />
          <path
            className="cls-4"
            d="M259.37,844.12a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,259.37,844.12Z"
          />
          <path
            className="cls-4"
            d="M250.62,836.91a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,250.62,836.91Z"
          />
          <path
            className="cls-4"
            d="M242.06,829.47a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.06.06,0,0,0-.08,0A.05.05,0,0,0,242.06,829.47Z"
          />
          <path
            className="cls-4"
            d="M233.7,821.81a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,233.7,821.81Z"
          />
          <path
            className="cls-4"
            d="M225.62,813.83a.06.06,0,0,0-.08,0,.06.06,0,1,0,.09.07A.06.06,0,0,0,225.62,813.83Z"
          />
          <path
            className="cls-4"
            d="M217.61,805.84a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.09.08.08,0,0,0-.09,0A.05.05,0,0,0,217.61,805.84Z"
          />
          <path
            className="cls-4"
            d="M209.88,797.55a.08.08,0,0,0,.09,0,.06.06,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,209.88,797.55Z"
          />
          <path
            className="cls-4"
            d="M202.38,789.05a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,202.38,789.05Z"
          />
          <path
            className="cls-4"
            d="M195.1,780.35a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.09s-.07,0-.08,0A.05.05,0,0,0,195.1,780.35Z"
          />
          <path
            className="cls-4"
            d="M188.05,771.47a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,188.05,771.47Z"
          />
          <path
            className="cls-4"
            d="M181.24,762.41a.08.08,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,181.24,762.41Z"
          />
          <path
            className="cls-4"
            d="M174.68,753.16a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,174.68,753.16Z"
          />
          <path
            className="cls-4"
            d="M168.36,743.75a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,168.36,743.75Z"
          />
          <path
            className="cls-4"
            d="M162.29,734.17a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,162.29,734.17Z"
          />
          <path
            className="cls-4"
            d="M156.54,724.34a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.08.08,0,0,0,156.54,724.34Z"
          />
          <path
            className="cls-4"
            d="M151,714.46a.07.07,0,0,0-.09,0,.06.06,0,1,0,.1.06A.06.06,0,0,0,151,714.46Z"
          />
          <path
            className="cls-4"
            d="M145.62,704.53a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,145.62,704.53Z"
          />
          <path
            className="cls-4"
            d="M140.67,694.27a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08-.1Z"
          />
          <path
            className="cls-4"
            d="M135.91,684a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.07.07,0,0,0,135.91,684Z"
          />
          <path
            className="cls-4"
            d="M131.35,673.67a.08.08,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,131.35,673.67Z"
          />
          <path
            className="cls-4"
            d="M127.15,663.14a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,127.15,663.14Z"
          />
          <path
            className="cls-4"
            d="M123.29,652.4a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0A.06.06,0,0,0,123.29,652.4Z"
          />
          <path
            className="cls-4"
            d="M119.57,641.77a.07.07,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,119.57,641.77Z"
          />
          <path
            className="cls-4"
            d="M116.21,630.94a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,116.21,630.94Z"
          />
          <path
            className="cls-4"
            d="M113.14,620a.06.06,0,0,0,.08,0,.06.06,0,0,0-.09-.07A.06.06,0,0,0,113.14,620Z"
          />
          <path
            className="cls-4"
            d="M110.42,608.94a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,110.42,608.94Z"
          />
          <path
            className="cls-4"
            d="M107.93,597.88a.06.06,0,1,0,0,.08A.05.05,0,0,0,107.93,597.88Z"
          />
          <path
            className="cls-4"
            d="M105.73,586.75s-.07,0-.08,0a.06.06,0,1,0,.09.07A.06.06,0,0,0,105.73,586.75Z"
          />
          <path
            className="cls-4"
            d="M103.82,575.58a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,103.82,575.58Z"
          />
          <circle className="cls-4" cx="102.18" cy="564.4" r="0.06" />
          <path
            className="cls-4"
            d="M100.9,553.1a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,100.9,553.1Z"
          />
          <path
            className="cls-4"
            d="M99.88,541.8a.06.06,0,0,0-.08,0,.05.05,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,99.88,541.8Z"
          />
          <path
            className="cls-4"
            d="M99.16,530.49a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.08.08,0,0,0,.09,0A.06.06,0,0,0,99.16,530.49Z"
          />
          <path
            className="cls-4"
            d="M98.74,519.16a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.05.05,0,0,0,98.74,519.16Z"
          />
          <path
            className="cls-4"
            d="M98.63,507.82a.06.06,0,1,0-.08.1.07.07,0,0,0,.09,0A.06.06,0,0,0,98.63,507.82Z"
          />
          <path
            className="cls-4"
            d="M98.8,496.48a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,98.8,496.48Z"
          />
          <path
            className="cls-4"
            d="M99.28,485.16a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.08.08,0,0,0,.09,0A.05.05,0,0,0,99.28,485.16Z"
          />
          <circle className="cls-4" cx="100.03" cy="473.89" r="0.06" />
          <path
            className="cls-4"
            d="M101.15,462.64a.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09A.06.06,0,0,0,101.15,462.64Z"
          />
          <path
            className="cls-4"
            d="M102.51,451.31a.06.06,0,1,0,0,.08S102.54,451.32,102.51,451.31Z"
          />
          <path
            className="cls-4"
            d="M104.18,440.09a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,104.18,440.09Z"
          />
          <path
            className="cls-4"
            d="M106.14,428.93a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,106.14,428.93Z"
          />
          <path
            className="cls-4"
            d="M108.4,417.81a.07.07,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,108.4,417.81Z"
          />
          <path
            className="cls-4"
            d="M111,406.77a.06.06,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,111,406.77Z"
          />
          <path
            className="cls-4"
            d="M113.78,395.82h0s0,0,0,.05,0,0,0,0A0,0,0,0,0,113.78,395.82Z"
          />
          <path
            className="cls-4"
            d="M116.93,384.9a.06.06,0,1,0-.07.09s.07,0,.08,0A.05.05,0,0,0,116.93,384.9Z"
          />
          <path
            className="cls-4"
            d="M120.35,374.09a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.06.06,0,1,0,.07-.09Z"
          />
          <path
            className="cls-4"
            d="M124.05,363.37a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,124.05,363.37Z"
          />
          <path
            className="cls-4"
            d="M128,352.75a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,128,352.75Z"
          />
          <path
            className="cls-4"
            d="M132.3,342.25a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,132.3,342.25Z"
          />
          <path
            className="cls-4"
            d="M136.84,331.86a.06.06,0,0,0-.08.1.07.07,0,0,0,.09,0A.06.06,0,0,0,136.84,331.86Z"
          />
          <path
            className="cls-4"
            d="M141.65,321.59a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,141.65,321.59Z"
          />
          <path
            className="cls-4"
            d="M146.73,311.46a.06.06,0,0,0-.09,0,.06.06,0,1,0,.1.07A.06.06,0,0,0,146.73,311.46Z"
          />
          <path
            className="cls-4"
            d="M152.07,301.46a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,152.07,301.46Z"
          />
          <path
            className="cls-4"
            d="M157.68,291.61a.06.06,0,1,0,0,.08A.05.05,0,0,0,157.68,291.61Z"
          />
          <path
            className="cls-4"
            d="M163.55,281.9a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,163.55,281.9Z"
          />
          <path
            className="cls-4"
            d="M169.67,272.36a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,169.67,272.36Z"
          />
          <path
            className="cls-4"
            d="M176,263a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,176,263Z"
          />
          <path
            className="cls-4"
            d="M182.65,253.77a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,182.65,253.77Z"
          />
          <circle className="cls-4" cx="189.47" cy="244.79" r="0.06" />
          <path
            className="cls-4"
            d="M196.6,235.9a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.09.06.06,0,1,0,.07-.1Z"
          />
          <path
            className="cls-4"
            d="M203.92,227.24a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,203.92,227.24Z"
          />
          <path
            className="cls-4"
            d="M211.47,218.78a.07.07,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,211.47,218.78Z"
          />
          <path
            className="cls-4"
            d="M219.24,210.53a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.05.05,0,0,0,219.24,210.53Z"
          />
          <path
            className="cls-4"
            d="M227.23,202.48a.07.07,0,0,0-.09,0,.08.08,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,227.23,202.48Z"
          />
          <path
            className="cls-4"
            d="M235.42,194.64a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,235.42,194.64Z"
          />
          <path
            className="cls-4"
            d="M243.82,187a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.09.08.08,0,0,0,.09,0A.05.05,0,0,0,243.82,187Z"
          />
          <path
            className="cls-4"
            d="M252.42,179.64s-.07,0-.08,0a.06.06,0,1,0,.08,0Z"
          />
          <path
            className="cls-4"
            d="M261.21,172.47a.07.07,0,0,0-.09,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,261.21,172.47Z"
          />
          <path
            className="cls-4"
            d="M270.18,165.55a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,270.18,165.55Z"
          />
          <circle className="cls-4" cx="279.3" cy="158.91" r="0.06" />
          <circle className="cls-4" cx="288.63" cy="152.46" r="0.06" />
          <path
            className="cls-4"
            d="M298.16,146.22a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,298.16,146.22Z"
          />
          <circle className="cls-4" cx="307.78" cy="140.32" r="0.06" />
          <path
            className="cls-4"
            d="M317.62,134.59a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,317.62,134.59Z"
          />
          <path
            className="cls-4"
            d="M327.58,129.16a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0A.06.06,0,0,0,327.58,129.16Z"
          />
          <path
            className="cls-4"
            d="M337.67,124a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,337.67,124Z"
          />
          <path
            className="cls-4"
            d="M347.9,119.11a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,347.9,119.11Z"
          />
          <path
            className="cls-4"
            d="M358.26,114.49a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,358.26,114.49Z"
          />
          <path
            className="cls-4"
            d="M368.73,110.14a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,368.73,110.14Z"
          />
          <circle className="cls-4" cx="379.28" cy="106.12" r="0.06" />
          <path
            className="cls-4"
            d="M390,102.29a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,390,102.29Z"
          />
          <path
            className="cls-4"
            d="M400.78,98.79a.06.06,0,1,0,0,.08S400.81,98.8,400.78,98.79Z"
          />
          <path
            className="cls-4"
            d="M411.65,95.57a.05.05,0,0,0-.08,0s0,.07,0,.08.07,0,.08,0A.05.05,0,0,0,411.65,95.57Z"
          />
          <path
            className="cls-4"
            d="M422.6,92.64a.05.05,0,0,0-.08,0,.07.07,0,0,0,0,.09.08.08,0,0,0,.09,0A.05.05,0,0,0,422.6,92.64Z"
          />
          <path
            className="cls-4"
            d="M433.63,90a.05.05,0,0,0-.08,0,.06.06,0,1,0,.09.07A.05.05,0,0,0,433.63,90Z"
          />
          <path
            className="cls-4"
            d="M444.72,87.65a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.06.06,0,1,0,.07-.1Z"
          />
          <path
            className="cls-4"
            d="M455.87,85.6a.05.05,0,0,0-.08,0s0,.07,0,.08a.06.06,0,0,0,.09,0A.05.05,0,0,0,455.87,85.6Z"
          />
          <path
            className="cls-4"
            d="M467.07,83.84a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.09.08.08,0,0,0,.09,0A.05.05,0,0,0,467.07,83.84Z"
          />
          <path
            className="cls-4"
            d="M478.31,82.37a.07.07,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,478.31,82.37Z"
          />
          <path
            className="cls-4"
            d="M489.59,81.21a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,489.59,81.21Z"
          />
          <path
            className="cls-4"
            d="M500.81,80.36a.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09A.07.07,0,0,0,500.81,80.36Z"
          />
          <circle className="cls-4" cx="512.19" cy="79.82" r="0.06" />
          <path
            className="cls-4"
            d="M523.56,79.5a.07.07,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.07.07,0,0,0,523.56,79.5Z"
          />
          <path
            className="cls-4"
            d="M534.89,79.53a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,534.89,79.53Z"
          />
          <path
            className="cls-4"
            d="M546.23,79.86a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0A.07.07,0,0,0,546.23,79.86Z"
          />
          <path
            className="cls-4"
            d="M557.55,80.49a.07.07,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.07.07,0,0,0,557.55,80.49Z"
          />
          <circle className="cls-4" cx="568.81" cy="81.47" r="0.06" />
          <circle className="cls-4" cx="580.08" cy="82.69" r="0.06" />
          <path
            className="cls-4"
            d="M591.26,84.29a.09.09,0,1,0,0-.13A.09.09,0,0,0,591.26,84.29Z"
          />
          <path
            className="cls-4"
            d="M602.56,86.07a.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08A.07.07,0,0,0,602.56,86.07Z"
          />
          <path
            className="cls-4"
            d="M613.61,88.19a.06.06,0,0,0,.09,0,.06.06,0,1,0-.09,0Z"
          />
          <path
            className="cls-4"
            d="M624.69,90.6a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,624.69,90.6Z"
          />
          <path
            className="cls-4"
            d="M635.69,93.21a.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08A.06.06,0,0,0,635.69,93.21Z"
          />
          <path
            className="cls-4"
            d="M646.64,96.28a.06.06,0,1,0,0-.08A.05.05,0,0,0,646.64,96.28Z"
          />
          <path
            className="cls-4"
            d="M657.49,99.56a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,657.49,99.56Z"
          />
          <path
            className="cls-4"
            d="M668.26,103.12a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,668.26,103.12Z"
          />
          <path
            className="cls-4"
            d="M679,106.86a.07.07,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.07.07,0,0,0,679,106.86Z"
          />
          <path
            className="cls-4"
            d="M689.49,111.09a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,689.49,111.09Z"
          />
          <circle
            className="cls-4"
            cx="699.97"
            cy="115.44"
            r="0.09"
            transform="translate(197.94 616.68) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M710.26,120.16a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,710.26,120.16Z"
          />
          <path
            className="cls-4"
            d="M720.47,125.11a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,720.47,125.11Z"
          />
          <path
            className="cls-4"
            d="M730.53,130.32a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,730.53,130.32Z"
          />
          <path
            className="cls-4"
            d="M740.46,135.8a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,740.46,135.8Z"
          />
          <path
            className="cls-4"
            d="M750.24,141.54a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.08.08,0,0,0,750.24,141.54Z"
          />
          <path
            className="cls-4"
            d="M759.86,147.53a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,759.86,147.53Z"
          />
          <circle className="cls-4" cx="769.36" cy="153.73" r="0.06" />
          <path
            className="cls-4"
            d="M778.61,160.19a.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09A.05.05,0,0,0,778.61,160.19Z"
          />
          <path
            className="cls-4"
            d="M787.74,167a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.09,0A.08.08,0,0,0,787.74,167Z"
          />
          <path
            className="cls-4"
            d="M796.68,174a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,796.68,174Z"
          />
          <path
            className="cls-4"
            d="M805.43,181.19a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.06.06,0,1,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M814,188.63a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,814,188.63Z"
          />
          <path
            className="cls-4"
            d="M822.34,196.29a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,822.34,196.29Z"
          />
          <path
            className="cls-4"
            d="M830.5,204.16a.06.06,0,0,0,.07-.09.06.06,0,0,0-.09,0A.05.05,0,0,0,830.5,204.16Z"
          />
          <path
            className="cls-4"
            d="M838.44,212.25a.06.06,0,1,0,0-.08S838.41,212.24,838.44,212.25Z"
          />
          <path
            className="cls-4"
            d="M846.15,220.58a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,846.15,220.58Z"
          />
          <path
            className="cls-4"
            d="M853.65,229.08a.1.1,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M861,237.74a.06.06,0,1,0,.07-.09.06.06,0,0,0-.09,0S860.92,237.73,861,237.74Z"
          />
          <path
            className="cls-4"
            d="M868,246.63a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,868,246.63Z"
          />
          <path
            className="cls-4"
            d="M874.8,255.69a.06.06,0,0,0,.09,0,.06.06,0,0,0-.1-.07A.06.06,0,0,0,874.8,255.69Z"
          />
          <path
            className="cls-4"
            d="M881.37,264.94a.06.06,0,0,0,.08,0,.06.06,0,1,0-.09-.07A.06.06,0,0,0,881.37,264.94Z"
          />
          <path
            className="cls-4"
            d="M887.69,274.35a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,887.69,274.35Z"
          />
          <path
            className="cls-4"
            d="M893.76,283.92a.06.06,0,1,0,0-.08A.05.05,0,0,0,893.76,283.92Z"
          />
          <path
            className="cls-4"
            d="M899.56,293.68a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,899.56,293.68Z"
          />
          <path
            className="cls-4"
            d="M905.11,303.56a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,905.11,303.56Z"
          />
          <path
            className="cls-4"
            d="M910.41,313.59a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,910.41,313.59Z"
          />
          <path
            className="cls-4"
            d="M915.45,323.73a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,915.45,323.73Z"
          />
          <path
            className="cls-4"
            d="M920.21,334a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.08.08,0,0,0,920.21,334Z"
          />
          <path
            className="cls-4"
            d="M924.69,344.43a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,924.69,344.43Z"
          />
          <path
            className="cls-4"
            d="M928.9,355a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,928.9,355Z"
          />
          <path
            className="cls-4"
            d="M932.81,365.62a.09.09,0,1,0,.11-.15.09.09,0,0,0-.11.15Z"
          />
          <path
            className="cls-4"
            d="M936.46,376.36a.09.09,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,936.46,376.36Z"
          />
          <path
            className="cls-4"
            d="M939.83,387.16a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,939.83,387.16Z"
          />
          <path
            className="cls-4"
            d="M942.91,398.07a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.09.08.08,0,0,0-.09,0A.06.06,0,0,0,942.91,398.07Z"
          />
          <path
            className="cls-4"
            d="M945.69,409.06a.06.06,0,0,0,.09,0,.06.06,0,1,0-.1-.07A.06.06,0,0,0,945.69,409.06Z"
          />
          <path
            className="cls-4"
            d="M948.17,420.15a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,948.17,420.15Z"
          />
          <path
            className="cls-4"
            d="M950.39,431.24a.06.06,0,0,0,.07-.09.06.06,0,0,0-.09,0A.05.05,0,0,0,950.39,431.24Z"
          />
          <path
            className="cls-4"
            d="M952.3,442.42a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.06.1Z"
          />
          <path
            className="cls-4"
            d="M953.89,453.67a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,953.89,453.67Z"
          />
          <path
            className="cls-4"
            d="M955.22,464.91a.06.06,0,0,0,.08,0,.06.06,0,0,0-.09-.07A.06.06,0,0,0,955.22,464.91Z"
          />
          <path
            className="cls-4"
            d="M956.24,476.2a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,956.24,476.2Z"
          />
          <path
            className="cls-4"
            d="M957,487.51a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,957,487.51Z"
          />
          <path
            className="cls-4"
            d="M957.37,498.84a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,957.37,498.84Z"
          />
          <path
            className="cls-4"
            d="M957.48,510.2a.09.09,0,1,0,.1-.14.09.09,0,0,0-.13,0A.09.09,0,0,0,957.48,510.2Z"
          />
          <path
            className="cls-4"
            d="M957.3,521.54a.08.08,0,0,0,.12,0,.09.09,0,1,0-.15-.11A.11.11,0,0,0,957.3,521.54Z"
          />
          <path
            className="cls-4"
            d="M956.84,532.84a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,956.84,532.84Z"
          />
          <path
            className="cls-4"
            d="M956.06,544.15a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,956.06,544.15Z"
          />
          <path
            className="cls-4"
            d="M955,555.44a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,955,555.44Z"
          />
          <path
            className="cls-4"
            d="M953.59,566.72a.09.09,0,1,0,0-.13A.09.09,0,0,0,953.59,566.72Z"
          />
          <path
            className="cls-4"
            d="M951.92,577.93a.09.09,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,0,0-.13,0A.08.08,0,0,0,951.92,577.93Z"
          />
          <circle
            className="cls-4"
            cx="950.01"
            cy="589.03"
            r="0.09"
            transform="translate(-82.55 1017.15) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M947.7,600.21a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,947.7,600.21Z"
          />
          <path
            className="cls-4"
            d="M945.16,611.23a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,945.16,611.23Z"
          />
          <path
            className="cls-4"
            d="M942.32,622.21a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,942.32,622.21Z"
          />
          <path
            className="cls-4"
            d="M939.17,633.13a.09.09,0,0,0,.13,0,.1.1,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,939.17,633.13Z"
          />
          <path
            className="cls-4"
            d="M935.77,643.91a.06.06,0,0,0,.07-.09s-.07,0-.08,0A.05.05,0,0,0,935.77,643.91Z"
          />
          <path
            className="cls-4"
            d="M932.07,654.63a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,932.07,654.63Z"
          />
          <path
            className="cls-4"
            d="M928.08,665.24a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,928.08,665.24Z"
          />
          <path
            className="cls-4"
            d="M923.8,675.78a.11.11,0,0,0,.13,0,.08.08,0,0,0,0-.12.09.09,0,1,0-.11.15Z"
          />
          <circle className="cls-4" cx="919.32" cy="686.09" r="0.06" />
          <path
            className="cls-4"
            d="M914.47,696.41a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,914.47,696.41Z"
          />
          <path
            className="cls-4"
            d="M909.39,706.54a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,909.39,706.54Z"
          />
          <path
            className="cls-4"
            d="M904.05,716.54a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,904.05,716.54Z"
          />
          <path
            className="cls-4"
            d="M898.44,726.39a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,898.44,726.39Z"
          />
          <path
            className="cls-4"
            d="M892.57,736.1a.06.06,0,1,0,0-.09A.06.06,0,0,0,892.57,736.1Z"
          />
          <path
            className="cls-4"
            d="M886.45,745.64a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,886.45,745.64Z"
          />
          <path
            className="cls-4"
            d="M880.08,755a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,880.08,755Z"
          />
          <path
            className="cls-4"
            d="M873.46,764.23a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,873.46,764.23Z"
          />
          <path
            className="cls-4"
            d="M866.61,773.26a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,866.61,773.26Z"
          />
          <circle className="cls-4" cx="859.55" cy="782.05" r="0.06" />
          <path
            className="cls-4"
            d="M852.19,790.76a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,852.19,790.76Z"
          />
          <path
            className="cls-4"
            d="M844.64,799.22a.07.07,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,844.64,799.22Z"
          />
          <circle className="cls-4" cx="836.91" cy="807.42" r="0.06" />
          <path
            className="cls-4"
            d="M828.89,815.52a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M820.69,823.36a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,820.69,823.36Z"
          />
          <path
            className="cls-4"
            d="M812.28,831a.08.08,0,0,0,.12,0,.09.09,0,0,0,0-.13.08.08,0,0,0-.12,0A.09.09,0,0,0,812.28,831Z"
          />
          <path
            className="cls-4"
            d="M803.7,838.36a.05.05,0,0,0,.08,0s0-.07,0-.08a.06.06,0,1,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M794.91,845.53a.06.06,0,0,0,.08,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,794.91,845.53Z"
          />
          <path
            className="cls-4"
            d="M785.94,852.45a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,785.94,852.45Z"
          />
          <path
            className="cls-4"
            d="M776.78,859.14s.07,0,.08,0a.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,776.78,859.14Z"
          />
          <path
            className="cls-4"
            d="M767.45,865.59a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,767.45,865.59Z"
          />
          <path
            className="cls-4"
            d="M758,871.78a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.05.05,0,0,0,758,871.78Z"
          />
          <path
            className="cls-4"
            d="M748.28,877.75a.09.09,0,0,0,.13,0,.09.09,0,1,0-.13,0Z"
          />
          <path
            className="cls-4"
            d="M738.49,883.41a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,738.49,883.41Z"
          />
          <path
            className="cls-4"
            d="M728.54,888.84a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,728.54,888.84Z"
          />
          <path
            className="cls-4"
            d="M724,905.32a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,724,905.32Z"
          />
          <path
            className="cls-4"
            d="M713.82,910.22a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,713.82,910.22Z"
          />
          <path
            className="cls-4"
            d="M703.47,914.85a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.08.08,0,0,0,703.47,914.85Z"
          />
          <path
            className="cls-4"
            d="M693,919.19s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,693,919.19Z"
          />
          <path
            className="cls-4"
            d="M682.43,923.31a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,682.43,923.31Z"
          />
          <path
            className="cls-4"
            d="M671.78,927.11h0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,671.78,927.11Z"
          />
          <path
            className="cls-4"
            d="M661,930.68a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,661,930.68Z"
          />
          <path
            className="cls-4"
            d="M650.14,934a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.05.05,0,0,0,650.14,934Z"
          />
          <path
            className="cls-4"
            d="M639.2,936.94s.07,0,.08,0,0-.07,0-.08a.06.06,0,0,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M628.21,939.63a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S628.2,939.62,628.21,939.63Z"
          />
          <path
            className="cls-4"
            d="M617.12,942.08a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,617.12,942.08Z"
          />
          <path
            className="cls-4"
            d="M606,944.22a.06.06,0,0,0,.09,0,.06.06,0,1,0-.1-.07A.06.06,0,0,0,606,944.22Z"
          />
          <path
            className="cls-4"
            d="M594.82,946.05h0v0s0,0-.06,0S594.8,946,594.82,946.05Z"
          />
          <path
            className="cls-4"
            d="M583.57,947.65a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,583.57,947.65Z"
          />
          <path
            className="cls-4"
            d="M572.31,948.93a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,572.31,948.93Z"
          />
          <path
            className="cls-4"
            d="M561,949.89h0v0s-.05,0-.06,0S561,949.88,561,949.89Z"
          />
          <path
            className="cls-4"
            d="M549.69,950.62a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,549.69,950.62Z"
          />
          <path
            className="cls-4"
            d="M538.38,951h0a0,0,0,0,0,0,0h0A0,0,0,0,0,538.38,951Z"
          />
          <path
            className="cls-4"
            d="M527,951.12h.05s0,0,0,0h0S527,951.11,527,951.12Z"
          />
          <path
            className="cls-4"
            d="M515.69,951a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,515.69,951Z"
          />
          <path
            className="cls-4"
            d="M504.36,950.52a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,504.36,950.52Z"
          />
          <path
            className="cls-4"
            d="M493.07,949.74a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0A0,0,0,0,0,493.07,949.74Z"
          />
          <path
            className="cls-4"
            d="M481.78,948.7a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,481.78,948.7Z"
          />
          <path
            className="cls-4"
            d="M470.52,947.37a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,470.55,947.32Z"
          />
          <path
            className="cls-4"
            d="M459.3,945.75a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,459.3,945.75Z"
          />
          <path
            className="cls-4"
            d="M448.1,943.87a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,448.1,943.87Z"
          />
          <path
            className="cls-4"
            d="M437,941.65a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,437,941.6Z"
          />
          <path
            className="cls-4"
            d="M425.93,939.17H426a.07.07,0,0,0,0,0s0,0,0,0A0,0,0,0,0,425.93,939.17Z"
          />
          <path
            className="cls-4"
            d="M414.94,936.41h0s0,0,0,0,0,0,0,0A0,0,0,0,0,414.94,936.41Z"
          />
          <path
            className="cls-4"
            d="M404,933.37h.05a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,404,933.37Z"
          />
          <path
            className="cls-4"
            d="M393.17,930.05h.05a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,393.17,930.05Z"
          />
          <path
            className="cls-4"
            d="M382.4,926.48a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,382.4,926.48Z"
          />
          <path
            className="cls-4"
            d="M371.76,922.58h.05s0,0,0,0h0S371.75,922.57,371.76,922.58Z"
          />
          <path
            className="cls-4"
            d="M361.21,918.44a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,361.21,918.44Z"
          />
          <path
            className="cls-4"
            d="M350.77,914s0,0,0,0a0,0,0,0,0-.05,0A0,0,0,0,0,350.81,914Z"
          />
          <path
            className="cls-4"
            d="M340.42,909.37a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,340.42,909.37Z"
          />
          <path
            className="cls-4"
            d="M330.21,904.35a.05.05,0,0,0,0,.08.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09A.05.05,0,0,0,330.21,904.35Z"
          />
          <path
            className="cls-4"
            d="M320.13,899.15a.06.06,0,0,0,0,.08.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09A.07.07,0,0,0,320.13,899.15Z"
          />
          <path
            className="cls-4"
            d="M310.26,893.7h0s0,0,0,0,0,0,0,0A0,0,0,0,0,310.26,893.7Z"
          />
          <path
            className="cls-4"
            d="M300.43,888h0a.1.1,0,0,0,0,0h-.05S300.42,888,300.43,888Z"
          />
          <path
            className="cls-4"
            d="M290.79,882.07h0s0,0,0-.05,0,0,0,0A0,0,0,0,0,290.79,882.07Z"
          />
          <path
            className="cls-4"
            d="M281.3,875.87s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,281.3,875.87Z"
          />
          <path
            className="cls-4"
            d="M272,869.42s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,272,869.42Z"
          />
          <path
            className="cls-4"
            d="M262.82,862.73h0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,262.82,862.73Z"
          />
          <path
            className="cls-4"
            d="M253.83,855.82a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,253.83,855.82Z"
          />
          <path
            className="cls-4"
            d="M245,848.67s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,245,848.67Z"
          />
          <path
            className="cls-4"
            d="M236.42,841.3h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,236.42,841.3Z"
          />
          <path
            className="cls-4"
            d="M228,833.74a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.07.07,0,0,0,228,833.74Z"
          />
          <path
            className="cls-4"
            d="M219.75,825.94a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,219.75,825.94Z"
          />
          <path
            className="cls-4"
            d="M211.72,817.93a.07.07,0,0,0,.09,0,.08.08,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,211.72,817.93Z"
          />
          <path
            className="cls-4"
            d="M203.92,809.69H204a.07.07,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,203.92,809.69Z"
          />
          <path
            className="cls-4"
            d="M196.32,801.28a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S196.31,801.27,196.32,801.28Z"
          />
          <path
            className="cls-4"
            d="M188.93,792.64a0,0,0,0,0,0,0s.05,0,.06,0,0,0,0,0S188.94,792.62,188.93,792.64Z"
          />
          <path
            className="cls-4"
            d="M181.75,783.92a.08.08,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.07.07,0,0,0,181.75,783.92Z"
          />
          <path
            className="cls-4"
            d="M174.84,774.92a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,174.84,774.92Z"
          />
          <path
            className="cls-4"
            d="M168.13,765.78a0,0,0,0,0,.05,0s0,0,0,0h0A0,0,0,0,0,168.13,765.78Z"
          />
          <path
            className="cls-4"
            d="M161.7,756.41s0,0,0,0,0,0,0,0h0S161.72,756.42,161.7,756.41Z"
          />
          <path
            className="cls-4"
            d="M155.45,747s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0S155.43,747,155.45,747Z"
          />
          <path
            className="cls-4"
            d="M149.47,737.35h0a0,0,0,0,0,0-.05s0,0,0,0S149.45,737.34,149.47,737.35Z"
          />
          <path
            className="cls-4"
            d="M143.74,727.57s0,0,0,0,0,0,0,0a0,0,0,0,0-.05,0A0,0,0,0,0,143.74,727.57Z"
          />
          <path
            className="cls-4"
            d="M138.24,717.67a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,138.24,717.67Z"
          />
          <path
            className="cls-4"
            d="M133.07,707.53s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S133.08,707.54,133.07,707.53Z"
          />
          <path
            className="cls-4"
            d="M128.07,697.39h.05s0,0,0,0,0,0-.05,0A0,0,0,0,0,128.07,697.39Z"
          />
          <path
            className="cls-4"
            d="M123.37,687.07a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,123.37,687.07Z"
          />
          <path
            className="cls-4"
            d="M118.92,676.66a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,118.92,676.66Z"
          />
          <path
            className="cls-4"
            d="M114.81,666a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,114.81,666Z"
          />
          <path
            className="cls-4"
            d="M110.88,655.4a0,0,0,0,0,0,0,0,0,0,0,0,.06,0,0,0,0,0,0,0,0S110.89,655.38,110.88,655.4Z"
          />
          <path
            className="cls-4"
            d="M107.26,644.65s0,0,0,0h0a.1.1,0,0,0,0,0A0,0,0,0,0,107.26,644.65Z"
          />
          <path
            className="cls-4"
            d="M104,633.79a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,104,633.79Z"
          />
          <path
            className="cls-4"
            d="M100.9,622.89s0,0,0,0a0,0,0,0,0,0,0h0S100.92,622.9,100.9,622.89Z"
          />
          <path
            className="cls-4"
            d="M98.09,612a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,98.09,612Z"
          />
          <path
            className="cls-4"
            d="M95.62,600.84s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,95.59,600.89Z"
          />
          <path
            className="cls-4"
            d="M93.38,589.77h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,93.38,589.77Z"
          />
          <path
            className="cls-4"
            d="M91.48,578.55a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,91.48,578.55Z"
          />
          <path
            className="cls-4"
            d="M89.84,567.33s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,89.84,567.33Z"
          />
          <path
            className="cls-4"
            d="M88.46,556.12h0s0,0,0,0h0S88.44,556.11,88.46,556.12Z"
          />
          <path
            className="cls-4"
            d="M87.44,544.76a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,87.44,544.76Z"
          />
          <path
            className="cls-4"
            d="M86.65,533.48h0s0,0,0,0,0,0,0,0A0,0,0,0,0,86.65,533.48Z"
          />
          <path
            className="cls-4"
            d="M86.17,522.15a0,0,0,1,0,0,.05,0,0,0,0,0,0-.05S86.18,522.16,86.17,522.15Z"
          />
          <path
            className="cls-4"
            d="M85.94,510.86s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,85.94,510.86Z"
          />
          <path
            className="cls-4"
            d="M86.08,499.48h0s0,0,0,0h0S86.09,499.48,86.08,499.48Z"
          />
          <path
            className="cls-4"
            d="M86.46,488.14h0s0,0,0,0,0,0,0,0S86.48,488.15,86.46,488.14Z"
          />
          <path
            className="cls-4"
            d="M87.14,476.83h0a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,87.14,476.83Z"
          />
          <path
            className="cls-4"
            d="M88.11,465.53a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0-.05Z"
          />
          <path
            className="cls-4"
            d="M89.4,454.32a.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09A.05.05,0,0,0,89.4,454.32Z"
          />
          <path
            className="cls-4"
            d="M90.92,443s0,0,0,0a0,0,0,0,0,0,0s0,0,.05,0S90.93,443,90.92,443Z"
          />
          <path
            className="cls-4"
            d="M92.77,431.82a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,92.77,431.82Z"
          />
          <path
            className="cls-4"
            d="M94.88,420.71h0a0,0,0,0,0,0,.05h0S94.89,420.72,94.88,420.71Z"
          />
          <path
            className="cls-4"
            d="M97.28,409.63h0s0,0,0,.05h0S97.29,409.64,97.28,409.63Z"
          />
          <path
            className="cls-4"
            d="M100,398.61s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S100,398.62,100,398.61Z"
          />
          <path
            className="cls-4"
            d="M102.94,387.67s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,102.94,387.67Z"
          />
          <path
            className="cls-4"
            d="M106.19,376.81a0,0,0,1,0,0,.05s0,0,0,0A0,0,0,0,0,106.19,376.81Z"
          />
          <circle
            className="cls-4"
            cx="109.7"
            cy="366.06"
            r="0.03"
            transform="translate(-251.63 241.66) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M113.52,355.35s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S113.54,355.36,113.52,355.35Z"
          />
          <path
            className="cls-4"
            d="M117.6,344.77s0,0,0,0a0,0,0,0,0,0,0h0A.07.07,0,0,0,117.6,344.77Z"
          />
          <path
            className="cls-4"
            d="M121.94,334.3h0s0,0,0,0,0,0,0,0A0,0,0,0,0,121.94,334.3Z"
          />
          <path
            className="cls-4"
            d="M126.57,323.92a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,126.57,323.92Z"
          />
          <path
            className="cls-4"
            d="M131.45,313.69a.06.06,0,0,0-.07.09s.07,0,.08,0S131.48,313.7,131.45,313.69Z"
          />
          <path
            className="cls-4"
            d="M136.59,303.58a.06.06,0,0,0-.09,0,.07.07,0,0,0,0,.09.05.05,0,0,0,.08,0A.05.05,0,0,0,136.59,303.58Z"
          />
          <path
            className="cls-4"
            d="M142,293.63s0,0-.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,142,293.63Z"
          />
          <path
            className="cls-4"
            d="M147.62,283.8s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,147.62,283.8Z"
          />
          <path
            className="cls-4"
            d="M153.51,274.12a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,153.51,274.12Z"
          />
          <path
            className="cls-4"
            d="M159.66,264.59a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S159.67,264.6,159.66,264.59Z"
          />
          <path
            className="cls-4"
            d="M166.05,255.22s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,166.05,255.22Z"
          />
          <path
            className="cls-4"
            d="M172.67,246h0s0,0,0,0a0,0,0,0,0,0,0S172.69,246,172.67,246Z"
          />
          <path
            className="cls-4"
            d="M179.53,237h0s0,0,0,.05,0,0,0,0A0,0,0,0,0,179.53,237Z"
          />
          <path
            className="cls-4"
            d="M186.62,228.15a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,186.59,228.2Z"
          />
          <path
            className="cls-4"
            d="M193.94,219.49a0,0,0,0,0-.05,0s0,0,0,0h0S194,219.5,193.94,219.49Z"
          />
          <path
            className="cls-4"
            d="M201.47,211h0a0,0,0,0,0,0,0s.05,0,.06,0A0,0,0,0,0,201.47,211Z"
          />
          <path
            className="cls-4"
            d="M209.23,202.71a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,209.23,202.71Z"
          />
          <path
            className="cls-4"
            d="M217.19,194.64a.06.06,0,1,0-.07.09.06.06,0,0,0,.09,0A.05.05,0,0,0,217.19,194.64Z"
          />
          <path
            className="cls-4"
            d="M225.35,186.77a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,225.35,186.77Z"
          />
          <path
            className="cls-4"
            d="M233.7,179.13s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,233.7,179.13Z"
          />
          <path
            className="cls-4"
            d="M242.25,171.69h0a0,0,0,0,0,0,0s.05,0,.06,0S242.27,171.7,242.25,171.69Z"
          />
          <path
            className="cls-4"
            d="M251,164.45a.06.06,0,0,0-.08,0,.07.07,0,0,0,0,.09.07.07,0,0,0,.09,0A.06.06,0,0,0,251,164.45Z"
          />
          <circle className="cls-4" cx="259.9" cy="157.51" r="0.06" />
          <path
            className="cls-4"
            d="M269,150.72h0a0,0,0,0,0,0,0s0,0,.06,0A0,0,0,0,0,269,150.72Z"
          />
          <path
            className="cls-4"
            d="M278.29,144.2h0a0,0,0,0,0,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,278.29,144.2Z"
          />
          <path
            className="cls-4"
            d="M287.73,137.91s0,0,0,0a0,0,0,0,0,0,0s0,0,.05,0S287.74,137.92,287.73,137.91Z"
          />
          <path
            className="cls-4"
            d="M297.32,131.87a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,297.29,131.92Z"
          />
          <path
            className="cls-4"
            d="M307.07,126.08a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,.05,0S307.08,126.09,307.07,126.08Z"
          />
          <path
            className="cls-4"
            d="M317,120.51a.06.06,0,0,0-.07.1.06.06,0,0,0,.08,0A.06.06,0,0,0,317,120.51Z"
          />
          <path
            className="cls-4"
            d="M327,115.25a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,327,115.25Z"
          />
          <path
            className="cls-4"
            d="M337.17,110.2a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.09.06.06,0,1,0,.07-.1Z"
          />
          <path
            className="cls-4"
            d="M347.44,105.46a0,0,0,1,0,0,.05h0S347.45,105.47,347.44,105.46Z"
          />
          <path
            className="cls-4"
            d="M357.85,101a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,.05,0S357.86,101,357.85,101Z"
          />
          <path
            className="cls-4"
            d="M368.39,96.7a.07.07,0,0,0-.09,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.07.07,0,0,0,368.39,96.7Z"
          />
          <circle className="cls-4" cx="378.97" cy="92.79" r="0.06" />
          <path
            className="cls-4"
            d="M389.71,89.08s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,389.71,89.08Z"
          />
          <path
            className="cls-4"
            d="M400.53,85.67a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,400.53,85.67Z"
          />
          <path
            className="cls-4"
            d="M411.42,82.54s0,0,0,0a0,0,0,0,0,0,0s.05,0,.06,0A0,0,0,0,0,411.42,82.54Z"
          />
          <path
            className="cls-4"
            d="M422.41,79.67a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,422.41,79.67Z"
          />
          <path
            className="cls-4"
            d="M433.44,77.12s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,433.44,77.12Z"
          />
          <circle className="cls-4" cx="444.53" cy="74.86" r="0.06" />
          <path
            className="cls-4"
            d="M455.7,72.84a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,455.7,72.84Z"
          />
          <path
            className="cls-4"
            d="M466.91,71.12h0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,466.91,71.12Z"
          />
          <path
            className="cls-4"
            d="M478.18,69.68a.06.06,0,0,0-.09,0,.05.05,0,0,0,0,.08.05.05,0,0,0,.08,0A.06.06,0,0,0,478.18,69.68Z"
          />
          <path
            className="cls-4"
            d="M489.4,68.61a0,0,0,0,0,.05,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,489.4,68.61Z"
          />
          <path
            className="cls-4"
            d="M500.68,67.71a.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0,.06.06,0,1,0-.1-.07Z"
          />
          <path
            className="cls-4"
            d="M512.09,67.14a.06.06,0,0,0-.09,0,.07.07,0,0,0,0,.09.06.06,0,0,0,.08,0A.06.06,0,0,0,512.09,67.14Z"
          />
          <path
            className="cls-4"
            d="M523.42,66.87a.05.05,0,0,0-.08,0,.05.05,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,523.42,66.87Z"
          />
          <path
            className="cls-4"
            d="M534.74,66.92s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S534.76,66.93,534.74,66.92Z"
          />
          <path
            className="cls-4"
            d="M546.09,67.22a.05.05,0,0,0-.08,0,.06.06,0,0,0,0,.08.06.06,0,0,0,.09,0A.05.05,0,0,0,546.09,67.22Z"
          />
          <path
            className="cls-4"
            d="M557.34,67.92a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,557.34,67.92Z"
          />
          <circle className="cls-4" cx="568.68" cy="68.77" r="0.06" />
          <path
            className="cls-4"
            d="M580,69.91a.06.06,0,0,0-.07.09.06.06,0,1,0,.07-.09Z"
          />
          <circle className="cls-4" cx="591.2" cy="71.43" r="0.06" />
          <path
            className="cls-4"
            d="M602.41,73.17a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,602.41,73.17Z"
          />
          <path
            className="cls-4"
            d="M613.58,75.19a.06.06,0,0,0-.08,0,.06.06,0,0,0,0,.08.07.07,0,0,0,.09,0A.07.07,0,0,0,613.58,75.19Z"
          />
          <path
            className="cls-4"
            d="M624.61,77.63a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,624.61,77.63Z"
          />
          <path
            className="cls-4"
            d="M635.64,80.24a.06.06,0,1,0,0-.08A.05.05,0,0,0,635.64,80.24Z"
          />
          <path
            className="cls-4"
            d="M646.67,83.05s-.07,0-.08,0,0,.07,0,.08a.06.06,0,0,0,.07-.09Z"
          />
          <path
            className="cls-4"
            d="M657.48,86.33a.08.08,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,657.48,86.33Z"
          />
          <path
            className="cls-4"
            d="M668.3,89.76a0,0,0,1,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,668.3,89.76Z"
          />
          <path
            className="cls-4"
            d="M679,93.49s0,0,0,0a0,0,0,0,0,0,0h0S679,93.48,679,93.49Z"
          />
          <path
            className="cls-4"
            d="M689.59,97.53a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,689.59,97.53Z"
          />
          <path
            className="cls-4"
            d="M700.09,101.8a.06.06,0,1,0,0-.08S700.06,101.79,700.09,101.8Z"
          />
          <path
            className="cls-4"
            d="M710.49,106.33s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,710.49,106.33Z"
          />
          <path
            className="cls-4"
            d="M720.76,111.14h0a0,0,0,0,0,0,0s0,0-.06,0S720.75,111.13,720.76,111.14Z"
          />
          <path
            className="cls-4"
            d="M730.88,116.23a.06.06,0,1,0,.07-.09.05.05,0,0,0-.08,0S730.85,116.22,730.88,116.23Z"
          />
          <path
            className="cls-4"
            d="M740.87,121.48a.06.06,0,1,0,.1.07.06.06,0,0,0,0-.08A.07.07,0,0,0,740.87,121.48Z"
          />
          <path
            className="cls-4"
            d="M750.75,127.15a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,750.75,127.15Z"
          />
          <path
            className="cls-4"
            d="M760.47,133a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.07.07,0,0,0,760.47,133Z"
          />
          <path
            className="cls-4"
            d="M770,139a.06.06,0,1,0,.09.07s0-.07,0-.08A.05.05,0,0,0,770,139Z"
          />
          <circle className="cls-4" cx="779.48" cy="145.35" r="0.06" />
          <path
            className="cls-4"
            d="M788.69,152a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,788.69,152Z"
          />
          <path
            className="cls-4"
            d="M797.76,158.77a.06.06,0,1,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,797.76,158.77Z"
          />
          <path
            className="cls-4"
            d="M806.65,165.8a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,806.65,165.8Z"
          />
          <path
            className="cls-4"
            d="M815.36,173.06a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,815.36,173.06Z"
          />
          <path
            className="cls-4"
            d="M823.89,180.54a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,823.89,180.54Z"
          />
          <path
            className="cls-4"
            d="M832.21,188.23a.06.06,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,832.21,188.23Z"
          />
          <path
            className="cls-4"
            d="M840.33,196.05a.06.06,0,0,0,0,.09.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08A.06.06,0,0,0,840.33,196.05Z"
          />
          <path
            className="cls-4"
            d="M848.28,204.22h0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S848.26,204.21,848.28,204.22Z"
          />
          <path
            className="cls-4"
            d="M856,212.56a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,856,212.56Z"
          />
          <path
            className="cls-4"
            d="M863.47,221.07a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,863.47,221.07Z"
          />
          <path
            className="cls-4"
            d="M870.74,229.77a.06.06,0,0,0,.08,0,.06.06,0,1,0-.09-.07A.06.06,0,0,0,870.74,229.77Z"
          />
          <path
            className="cls-4"
            d="M877.79,238.65a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08s-.07,0-.08,0A.06.06,0,0,0,877.79,238.65Z"
          />
          <path
            className="cls-4"
            d="M884.63,247.68a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,884.63,247.68Z"
          />
          <path
            className="cls-4"
            d="M891.19,256.93a.06.06,0,0,0,.09,0,.06.06,0,0,0-.1-.07A.06.06,0,0,0,891.19,256.93Z"
          />
          <path
            className="cls-4"
            d="M897.54,266.33a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,897.54,266.33Z"
          />
          <path
            className="cls-4"
            d="M903.66,275.86a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,903.66,275.86Z"
          />
          <circle className="cls-4" cx="909.53" cy="285.54" r="0.06" />
          <path
            className="cls-4"
            d="M915.1,295.45a.06.06,0,0,0,.07-.1.06.06,0,0,0-.08,0A.06.06,0,0,0,915.1,295.45Z"
          />
          <path
            className="cls-4"
            d="M920.45,305.44s.07,0,.08,0a.06.06,0,1,0-.08,0Z"
          />
          <path
            className="cls-4"
            d="M925.54,315.57a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,925.54,315.57Z"
          />
          <path
            className="cls-4"
            d="M930.37,325.83a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,930.37,325.83Z"
          />
          <path
            className="cls-4"
            d="M934.94,336.21a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.06.06,0,0,0-.08,0A.06.06,0,0,0,934.94,336.21Z"
          />
          <circle className="cls-4" cx="939.27" cy="346.65" r="0.06" />
          <path
            className="cls-4"
            d="M943.27,357.3a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,943.27,357.3Z"
          />
          <path
            className="cls-4"
            d="M947,368a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,947,368Z"
          />
          <path
            className="cls-4"
            d="M950.5,378.79a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,950.5,378.79Z"
          />
          <path
            className="cls-4"
            d="M953.7,389.66a.06.06,0,0,0,.09,0s0-.07,0-.08a.06.06,0,1,0-.07.09Z"
          />
          <circle className="cls-4" cx="956.66" cy="400.57" r="0.06" />
          <path
            className="cls-4"
            d="M959.26,411.64a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.05.05,0,0,0,959.26,411.64Z"
          />
          <path
            className="cls-4"
            d="M961.62,422.73a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,1,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M963.69,433.88a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,963.69,433.88Z"
          />
          <path
            className="cls-4"
            d="M965.47,445.08a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.08,0A.07.07,0,0,0,965.47,445.08Z"
          />
          <path
            className="cls-4"
            d="M967,456.32a.06.06,0,0,0,.08,0,.06.06,0,0,0-.09-.07A.06.06,0,0,0,967,456.32Z"
          />
          <path
            className="cls-4"
            d="M968.18,467.59a.06.06,0,0,0,.08,0,.06.06,0,1,0-.1-.08A.07.07,0,0,0,968.18,467.59Z"
          />
          <path
            className="cls-4"
            d="M969.1,478.89a.06.06,0,0,0,.08,0,.07.07,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,969.1,478.89Z"
          />
          <path
            className="cls-4"
            d="M969.72,490.21a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,969.72,490.21Z"
          />
          <path
            className="cls-4"
            d="M970.06,501.54a.06.06,0,0,0,.09,0,.06.06,0,1,0-.1-.07A.06.06,0,0,0,970.06,501.54Z"
          />
          <path
            className="cls-4"
            d="M970.11,512.88a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M969.87,524.21a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.05.05,0,0,0,969.87,524.21Z"
          />
          <path
            className="cls-4"
            d="M969.33,535.54a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,969.33,535.54Z"
          />
          <path
            className="cls-4"
            d="M968.51,546.85a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,968.51,546.85Z"
          />
          <circle className="cls-4" cx="967.43" cy="558.08" r="0.06" />
          <path
            className="cls-4"
            d="M966,569.38a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,966,569.38Z"
          />
          <path
            className="cls-4"
            d="M964.32,580.57h0s0,0,0,0,0,0-.05,0A0,0,0,0,0,964.32,580.57Z"
          />
          <circle className="cls-4" cx="962.36" cy="591.71" r="0.06" />
          <path
            className="cls-4"
            d="M960.06,602.87a.07.07,0,0,0,.08,0,.06.06,0,0,0,0-.08.06.06,0,0,0-.09,0A.07.07,0,0,0,960.06,602.87Z"
          />
          <path
            className="cls-4"
            d="M957.51,613.91a.05.05,0,0,0,.08,0s0-.07,0-.08a.06.06,0,1,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M954.68,624.89a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,1,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M951.57,635.79a.06.06,0,0,0,.07-.09.06.06,0,0,0-.09,0A.05.05,0,0,0,951.57,635.79Z"
          />
          <path
            className="cls-4"
            d="M948.2,646.59a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M944.51,657.34a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.06.06,0,0,0,944.51,657.34Z"
          />
          <path
            className="cls-4"
            d="M940.57,668a.06.06,0,0,0,.09,0,.06.06,0,0,0-.1-.07A.06.06,0,0,0,940.57,668Z"
          />
          <circle className="cls-4" cx="936.4" cy="678.45" r="0.06" />
          <path
            className="cls-4"
            d="M931.9,688.89a0,0,0,0,0,0,0,0,0,0,0,0,0,0h-.05S931.89,688.88,931.9,688.89Z"
          />
          <path
            className="cls-4"
            d="M927.14,699.21a.05.05,0,0,0,.08,0,.06.06,0,0,0,0-.09.08.08,0,0,0-.09,0A.06.06,0,0,0,927.14,699.21Z"
          />
          <path
            className="cls-4"
            d="M922.13,709.38a.06.06,0,1,0,.07-.09.06.06,0,1,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M916.86,719.42a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,916.86,719.42Z"
          />
          <path
            className="cls-4"
            d="M911.34,729.33a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,911.34,729.33Z"
          />
          <path
            className="cls-4"
            d="M905.57,739.08a.05.05,0,0,0,.08,0,.07.07,0,0,0,0-.09.08.08,0,0,0-.09,0A.05.05,0,0,0,905.57,739.08Z"
          />
          <path
            className="cls-4"
            d="M899.54,748.69a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.06.06,0,0,0-.08,0A.07.07,0,0,0,899.54,748.69Z"
          />
          <path
            className="cls-4"
            d="M893.28,758.14a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,893.28,758.14Z"
          />
          <path
            className="cls-4"
            d="M886.77,767.42a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.07.1Z"
          />
          <circle className="cls-4" cx="880.06" cy="776.49" r="0.06" />
          <path
            className="cls-4"
            d="M873.05,785.47s.07,0,.08,0,0-.07,0-.08a.06.06,0,1,0-.07.09Z"
          />
          <path
            className="cls-4"
            d="M865.85,794.23a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.06.06,0,0,0-.07.1Z"
          />
          <path
            className="cls-4"
            d="M858.42,802.8a.07.07,0,0,0,.09,0,.06.06,0,0,0,0-.08.06.06,0,1,0-.08.1Z"
          />
          <circle className="cls-4" cx="850.82" cy="811.13" r="0.06" />
          <path
            className="cls-4"
            d="M842.93,819.35s.07,0,.08,0a.06.06,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,842.93,819.35Z"
          />
          <path
            className="cls-4"
            d="M834.89,827.3a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,834.89,827.3Z"
          />
          <path
            className="cls-4"
            d="M826.61,835.09a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.08.07.07,0,0,0-.09,0A.07.07,0,0,0,826.61,835.09Z"
          />
          <path
            className="cls-4"
            d="M818.15,842.64a.05.05,0,0,0,.08,0,.05.05,0,0,0,0-.08.07.07,0,0,0-.09,0A.08.08,0,0,0,818.15,842.64Z"
          />
          <path
            className="cls-4"
            d="M809.52,849.94h0s0,0,0-.05,0,0,0,0S809.5,849.93,809.52,849.94Z"
          />
          <path
            className="cls-4"
            d="M800.68,857.05s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A.06.06,0,0,0,800.68,857.05Z"
          />
          <path
            className="cls-4"
            d="M791.67,863.93s0,0,0,0a0,0,0,0,0,0,0h0S791.65,863.92,791.67,863.93Z"
          />
          <path
            className="cls-4"
            d="M782.46,870.59a.06.06,0,0,0,.09,0,.05.05,0,0,0,0-.08.05.05,0,0,0-.08,0A.06.06,0,0,0,782.46,870.59Z"
          />
          <path
            className="cls-4"
            d="M773.11,877a.06.06,0,0,0,.08,0,.06.06,0,0,0,0-.09.07.07,0,0,0-.09,0A.06.06,0,0,0,773.11,877Z"
          />
          <path
            className="cls-4"
            d="M763.59,883.16a.06.06,0,0,0,.09,0,.06.06,0,0,0-.1-.07A.06.06,0,0,0,763.59,883.16Z"
          />
          <path
            className="cls-4"
            d="M753.92,889.08a.07.07,0,0,0,.09,0,.07.07,0,0,0,0-.09.05.05,0,0,0-.08,0A.05.05,0,0,0,753.92,889.08Z"
          />
          <path
            className="cls-4"
            d="M744.12,894.72h0v0s0,0-.06,0S744.11,894.71,744.12,894.72Z"
          />
          <path
            className="cls-4"
            d="M734.16,900.14a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,734.19,900.09Z"
          />
          <path
            className="cls-4"
            d="M729.66,916.62s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,729.66,916.62Z"
          />
          <path
            className="cls-4"
            d="M719.44,921.52s0,0,0,0a0,0,0,0,0,0,0h0S719.42,921.51,719.44,921.52Z"
          />
          <path
            className="cls-4"
            d="M709.09,926.16s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,709.09,926.16Z"
          />
          <path
            className="cls-4"
            d="M698.64,930.54h0s0,0,0,0h0S698.62,930.53,698.64,930.54Z"
          />
          <path
            className="cls-4"
            d="M688.08,934.67h0a0,0,0,0,0,0,0s0,0-.06,0S688.06,934.66,688.08,934.67Z"
          />
          <path
            className="cls-4"
            d="M677.42,938.52h0a0,0,0,0,0,0,0h0S677.4,938.51,677.42,938.52Z"
          />
          <path
            className="cls-4"
            d="M666.66,942.11h0s0,0,0,0a0,0,0,0,0-.05,0S666.65,942.1,666.66,942.11Z"
          />
          <path
            className="cls-4"
            d="M655.82,945.44s0,0,0,0a0,0,0,0,0,0,0h-.05A0,0,0,0,0,655.82,945.44Z"
          />
          <path
            className="cls-4"
            d="M644.9,948.49s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,644.9,948.49Z"
          />
          <circle
            className="cls-4"
            cx="633.93"
            cy="951.24"
            r="0.03"
            transform="translate(-508.49 911.35) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M622.85,953.76h0s0,0,0,0h0S622.84,953.75,622.85,953.76Z"
          />
          <path
            className="cls-4"
            d="M611.73,956s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,611.73,956Z"
          />
          <path
            className="cls-4"
            d="M600.56,957.94a0,0,0,0,0,.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,600.56,957.94Z"
          />
          <path
            className="cls-4"
            d="M589.35,959.61s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,589.35,959.61Z"
          />
          <path
            className="cls-4"
            d="M578.1,961h0s0,0,0,0h0S578.08,961,578.1,961Z"
          />
          <path
            className="cls-4"
            d="M566.81,962.1h.05a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,566.81,962.1Z"
          />
          <path
            className="cls-4"
            d="M555.51,962.93s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,555.54,962.88Z"
          />
          <path
            className="cls-4"
            d="M544.18,963.47s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,544.18,963.47Z"
          />
          <path
            className="cls-4"
            d="M532.85,963.73h0s0,0,0-.05,0,0,0,0A0,0,0,0,0,532.85,963.73Z"
          />
          <path
            className="cls-4"
            d="M521.51,963.71a0,0,0,0,0,0-.05h0S521.5,963.7,521.51,963.71Z"
          />
          <path
            className="cls-4"
            d="M510.18,963.41s0,0,0,0,0,0,0,0h0S510.16,963.4,510.18,963.41Z"
          />
          <path
            className="cls-4"
            d="M498.85,962.82s0,0,0,0a.06.06,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,498.85,962.82Z"
          />
          <path
            className="cls-4"
            d="M487.55,962a0,0,0,0,0,0-.05h0S487.54,961.94,487.55,962Z"
          />
          <path
            className="cls-4"
            d="M476.27,960.8a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S476.26,960.79,476.27,960.8Z"
          />
          <path
            className="cls-4"
            d="M465,959.37s0,0,.05,0v0s0,0-.05,0A0,0,0,0,0,465,959.37Z"
          />
          <path
            className="cls-4"
            d="M453.81,957.66s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,453.81,957.66Z"
          />
          <path
            className="cls-4"
            d="M442.65,955.67s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,442.65,955.67Z"
          />
          <path
            className="cls-4"
            d="M431.54,953.4s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,431.54,953.4Z"
          />
          <path
            className="cls-4"
            d="M420.5,950.86s0,0,0,0,0,0,0,0a0,0,0,0,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M409.51,948s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,409.51,948Z"
          />
          <circle
            className="cls-4"
            cx="398.62"
            cy="944.92"
            r="0.03"
            transform="translate(-601.43 717.58) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M387.78,941.59s0,0,0,0,0,0,0,0h0S387.76,941.58,387.78,941.59Z"
          />
          <circle
            className="cls-4"
            cx="377.06"
            cy="937.93"
            r="0.03"
            transform="translate(-604.73 697.15) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M366.39,934.06s0,0,0,0a0,0,0,0,0,0,0h-.05A0,0,0,0,0,366.39,934.06Z"
          />
          <path
            className="cls-4"
            d="M355.85,929.89h0a0,0,0,0,0,0,0s0,0,0,0S355.83,929.88,355.85,929.89Z"
          />
          <path
            className="cls-4"
            d="M345.41,925.47a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M335.08,920.79s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S335.07,920.78,335.08,920.79Z"
          />
          <path
            className="cls-4"
            d="M324.87,915.85s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,324.87,915.85Z"
          />
          <path
            className="cls-4"
            d="M314.8,910.66s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,314.8,910.66Z"
          />
          <path
            className="cls-4"
            d="M304.88,905.17h0a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,304.88,905.17Z"
          />
          <path
            className="cls-4"
            d="M295,899.53s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A.06.06,0,0,0,295,899.53Z"
          />
          <path
            className="cls-4"
            d="M285.38,893.6s0,0,0,0a0,0,0,1,0-.05,0A0,0,0,0,0,285.42,893.59Z"
          />
          <path
            className="cls-4"
            d="M275.87,887.43s0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,275.87,887.43Z"
          />
          <path
            className="cls-4"
            d="M266.51,881h0s0,0,0,0h-.05S266.5,881,266.51,881Z"
          />
          <path
            className="cls-4"
            d="M257.32,874.39a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,257.32,874.39Z"
          />
          <path
            className="cls-4"
            d="M248.29,867.53a0,0,0,0,0,.05,0,.06.06,0,0,0,0,0h0A0,0,0,0,0,248.29,867.53Z"
          />
          <path
            className="cls-4"
            d="M239.44,860.44s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S239.43,860.43,239.44,860.44Z"
          />
          <path
            className="cls-4"
            d="M230.77,853.14s0,0,0,0a0,0,0,0,0,0,0h-.05A0,0,0,0,0,230.77,853.14Z"
          />
          <path
            className="cls-4"
            d="M222.28,845.62a0,0,0,0,0,.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,222.28,845.62Z"
          />
          <path
            className="cls-4"
            d="M214,837.89a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,214,837.84Z"
          />
          <path
            className="cls-4"
            d="M205.89,830a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M198,821.83s0,0,0,0,0,0,0,0h0S198,821.82,198,821.83Z"
          />
          <path
            className="cls-4"
            d="M190.29,813.5a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S190.28,813.49,190.29,813.5Z"
          />
          <path
            className="cls-4"
            d="M182.8,805h0s0,0,0,0,0,0,0,0A0,0,0,0,0,182.8,805Z"
          />
          <path
            className="cls-4"
            d="M175.53,796.29h0a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,175.53,796.29Z"
          />
          <path
            className="cls-4"
            d="M168.48,787.41a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S168.47,787.4,168.48,787.41Z"
          />
          <path
            className="cls-4"
            d="M161.65,778.36s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,161.65,778.36Z"
          />
          <path
            className="cls-4"
            d="M155.09,769.09a0,0,0,0,0-.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,155.09,769.09Z"
          />
          <path
            className="cls-4"
            d="M148.68,759.76s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A.06.06,0,0,0,148.68,759.76Z"
          />
          <path
            className="cls-4"
            d="M142.55,750.23h0a0,0,0,0,0,0,0s0,0-.06,0A0,0,0,0,0,142.55,750.23Z"
          />
          <path
            className="cls-4"
            d="M136.69,740.49a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S136.7,740.5,136.69,740.49Z"
          />
          <path
            className="cls-4"
            d="M131,730.66h0a0,0,0,0,0,0,0s0,0,.06,0S131.05,730.67,131,730.66Z"
          />
          <path
            className="cls-4"
            d="M125.6,720.74h0a0,0,0,0,0,0,0h0A.06.06,0,0,0,125.6,720.74Z"
          />
          <path
            className="cls-4"
            d="M120.45,710.64h0s0,0,0,0h0S120.43,710.63,120.45,710.64Z"
          />
          <path
            className="cls-4"
            d="M115.55,700.42a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S115.54,700.41,115.55,700.42Z"
          />
          <path
            className="cls-4"
            d="M110.94,690a0,0,0,1,0,0,.05s0,0,0,0S111,690,110.94,690Z"
          />
          <path
            className="cls-4"
            d="M106.56,679.57h0s0,0,0,0,0,0,0,0A0,0,0,0,0,106.56,679.57Z"
          />
          <path
            className="cls-4"
            d="M102.43,669a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,102.43,669Z"
          />
          <path
            className="cls-4"
            d="M98.54,658.4s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S98.53,658.39,98.54,658.4Z"
          />
          <path
            className="cls-4"
            d="M95,647.59h0s0,0,0,0,0,0,0,0A0,0,0,0,0,95,647.59Z"
          />
          <path
            className="cls-4"
            d="M91.63,636.8h0s0,0,0,0h0S91.62,636.79,91.63,636.8Z"
          />
          <circle
            className="cls-4"
            cx="88.6"
            cy="625.86"
            r="0.03"
            transform="translate(-471.45 332.79) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M85.8,614.89s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,85.8,614.89Z"
          />
          <path
            className="cls-4"
            d="M83.34,603.78a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,83.34,603.78Z"
          />
          <path
            className="cls-4"
            d="M81.11,592.67h0a0,0,0,0,0,0,.05,0,0,0,0,0,.05,0S81.12,592.68,81.11,592.67Z"
          />
          <path
            className="cls-4"
            d="M79.16,581.5a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,79.16,581.5Z"
          />
          <path
            className="cls-4"
            d="M77.49,570.28h0s0,0,0,0,0,0,0,0S77.51,570.29,77.49,570.28Z"
          />
          <path
            className="cls-4"
            d="M76.07,559.08a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A.06.06,0,0,0,76.07,559.08Z"
          />
          <path
            className="cls-4"
            d="M75,547.75h0a.1.1,0,0,0,0,0,0,0,0,0,0,.06,0S75,547.76,75,547.75Z"
          />
          <path
            className="cls-4"
            d="M74.17,536.44a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,74.14,536.49Z"
          />
          <path
            className="cls-4"
            d="M73.63,525.12h0s0,0,0,0h0S73.64,525.13,73.63,525.12Z"
          />
          <path
            className="cls-4"
            d="M73.37,513.78s0,0,0,0a0,0,0,0,0,0,0s0,0,.05,0A0,0,0,0,0,73.37,513.78Z"
          />
          <path
            className="cls-4"
            d="M73.39,502.44s0,0,0,0a0,0,0,0,0,0,0h.05A0,0,0,0,0,73.39,502.44Z"
          />
          <path
            className="cls-4"
            d="M73.69,491.11s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,73.69,491.11Z"
          />
          <path
            className="cls-4"
            d="M74.28,479.79a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0S74.29,479.8,74.28,479.79Z"
          />
          <path
            className="cls-4"
            d="M75.15,468.48s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,75.15,468.48Z"
          />
          <path
            className="cls-4"
            d="M76.3,457.2s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,76.3,457.2Z"
          />
          <path
            className="cls-4"
            d="M77.73,446h0a0,0,0,0,0,0,.05,0,0,0,0,0,0,0S77.74,446,77.73,446Z"
          />
          <path
            className="cls-4"
            d="M79.44,434.75a0,0,0,1,0,0,.05h0A0,0,0,0,0,79.44,434.75Z"
          />
          <path
            className="cls-4"
            d="M81.43,423.59h0s0,0,0,.05,0,0,0,0A0,0,0,0,0,81.43,423.59Z"
          />
          <path
            className="cls-4"
            d="M83.7,412.48a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S83.71,412.49,83.7,412.48Z"
          />
          <path
            className="cls-4"
            d="M86.24,401.43a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0-.05Z"
          />
          <path
            className="cls-4"
            d="M89.06,390.45a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,89.06,390.45Z"
          />
          <path
            className="cls-4"
            d="M92.15,379.54a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,92.12,379.59Z"
          />
          <path
            className="cls-4"
            d="M95.52,368.71a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,95.52,368.71Z"
          />
          <path
            className="cls-4"
            d="M99.15,358a0,0,0,0,0-.05,0s0,0,0,0,0,0,0,0A0,0,0,0,0,99.15,358Z"
          />
          <path
            className="cls-4"
            d="M103.05,347.33h0s0,0,0,0,0,0,0,0S103.06,347.34,103.05,347.33Z"
          />
          <path
            className="cls-4"
            d="M107.21,336.78s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,107.21,336.78Z"
          />
          <path
            className="cls-4"
            d="M111.63,326.34a0,0,0,0,0,0,.05,0,0,0,0,0,0-.05S111.64,326.35,111.63,326.34Z"
          />
          <path
            className="cls-4"
            d="M116.31,316h0s0,0,0,0h0S116.33,316,116.31,316Z"
          />
          <path
            className="cls-4"
            d="M121.25,305.81a0,0,0,0,0,0,.05h0A0,0,0,0,0,121.25,305.81Z"
          />
          <path
            className="cls-4"
            d="M126.44,295.73a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,126.41,295.78Z"
          />
          <path
            className="cls-4"
            d="M131.88,285.78s0,0,0,0,0,0,0,0h0S131.9,285.79,131.88,285.78Z"
          />
          <path
            className="cls-4"
            d="M137.57,276h0s0,0,0,0h0S137.59,276,137.57,276Z"
          />
          <path
            className="cls-4"
            d="M143.5,266.31h0a0,0,0,0,0,0,0s.05,0,.06,0A0,0,0,0,0,143.5,266.31Z"
          />
          <path
            className="cls-4"
            d="M149.67,256.8a0,0,0,1,0,0,.05s0,0,0,0S149.68,256.81,149.67,256.8Z"
          />
          <path
            className="cls-4"
            d="M156.08,247.44a0,0,0,0,0-.05,0,.06.06,0,0,0,0,0h0A0,0,0,0,0,156.08,247.44Z"
          />
          <path
            className="cls-4"
            d="M162.71,238.25s0,0,0,0a0,0,0,0,0,0,0s.05,0,.06,0A0,0,0,0,0,162.71,238.25Z"
          />
          <path
            className="cls-4"
            d="M169.57,229.23h0a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,169.57,229.23Z"
          />
          <path
            className="cls-4"
            d="M176.66,220.38h0s0,0,0,0a0,0,0,0,0,0,0S176.67,220.39,176.66,220.38Z"
          />
          <path
            className="cls-4"
            d="M184,211.7h0s0,0,0,0h0S184,211.71,184,211.7Z"
          />
          <path
            className="cls-4"
            d="M191.48,203.22a0,0,0,1,0,0,.05s0,0,0,0A0,0,0,0,0,191.48,203.22Z"
          />
          <path
            className="cls-4"
            d="M199.21,194.92s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S199.22,194.93,199.21,194.92Z"
          />
          <path
            className="cls-4"
            d="M207.14,186.82a0,0,0,1,0,0,.05,0,0,0,0,0,0-.05A0,0,0,0,0,207.14,186.82Z"
          />
          <path
            className="cls-4"
            d="M215.27,178.92a0,0,0,1,0,0,.05s0,0,0,0A0,0,0,0,0,215.27,178.92Z"
          />
          <path
            className="cls-4"
            d="M223.6,171.22s0,0,0,0a0,0,0,0,0,0,0h.05A0,0,0,0,0,223.6,171.22Z"
          />
          <path
            className="cls-4"
            d="M232.11,163.74s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,232.11,163.74Z"
          />
          <path
            className="cls-4"
            d="M240.81,156.47h0a0,0,0,0,0,0,.05h0S240.83,156.48,240.81,156.47Z"
          />
          <path
            className="cls-4"
            d="M249.69,149.42h0a0,0,0,0,0,0,0s.05,0,.06,0S249.7,149.42,249.69,149.42Z"
          />
          <path
            className="cls-4"
            d="M258.74,142.59h0s0,0,0,0h0S258.76,142.6,258.74,142.59Z"
          />
          <path
            className="cls-4"
            d="M268,136s0,0,0,0,0,0,0,0H268A0,0,0,0,0,268,136Z"
          />
          <path
            className="cls-4"
            d="M277.34,129.62h0a0,0,0,0,0,0,0h.05S277.35,129.63,277.34,129.62Z"
          />
          <path
            className="cls-4"
            d="M286.87,123.48s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0S286.89,123.49,286.87,123.48Z"
          />
          <path
            className="cls-4"
            d="M296.56,117.59a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S296.57,117.6,296.56,117.59Z"
          />
          <path
            className="cls-4"
            d="M306.39,111.94a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S306.4,112,306.39,111.94Z"
          />
          <path
            className="cls-4"
            d="M316.36,106.54a0,0,0,0,0-.05,0s0,0,0,0,0,0,0,0S316.37,106.55,316.36,106.54Z"
          />
          <path
            className="cls-4"
            d="M326.46,101.38a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,326.46,101.38Z"
          />
          <path
            className="cls-4"
            d="M336.68,96.48s0,0,0,0a0,0,0,0,0,.05,0S336.69,96.49,336.68,96.48Z"
          />
          <path
            className="cls-4"
            d="M347,91.84s0,0,0,0a0,0,0,0,0,0,0s0,0,.06,0A0,0,0,0,0,347,91.84Z"
          />
          <path
            className="cls-4"
            d="M357.48,87.46h0s0,0,0,0a0,0,0,0,0,.05,0S357.49,87.46,357.48,87.46Z"
          />
          <path
            className="cls-4"
            d="M368,83.33s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,368,83.33Z"
          />
          <path
            className="cls-4"
            d="M378.7,79.48a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0S378.72,79.48,378.7,79.48Z"
          />
          <path
            className="cls-4"
            d="M389.46,75.89a0,0,0,0,0,0,0s0,0,0,0h0S389.47,75.89,389.46,75.89Z"
          />
          <path
            className="cls-4"
            d="M400.3,72.56a0,0,0,0,0-.05,0s0,0,0,0,0,0,0,0A0,0,0,0,0,400.3,72.56Z"
          />
          <path
            className="cls-4"
            d="M411.22,69.51s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,411.22,69.51Z"
          />
          <path
            className="cls-4"
            d="M422.21,66.74h0a0,0,0,0,0,0,.05,0,0,0,0,0,.05,0S422.22,66.75,422.21,66.74Z"
          />
          <path
            className="cls-4"
            d="M433.23,64.28s0,0,0,0,0,0,0,0h0S433.22,64.27,433.23,64.28Z"
          />
          <path
            className="cls-4"
            d="M444.38,62a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0-.05Z"
          />
          <path
            className="cls-4"
            d="M455.55,60.06s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,455.52,60.11Z"
          />
          <path
            className="cls-4"
            d="M466.77,58.39s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,466.77,58.39Z"
          />
          <path
            className="cls-4"
            d="M478,57h0s0,0,0,0a0,0,0,0,0,0,0S478,57,478,57Z"
          />
          <path
            className="cls-4"
            d="M489.3,55.9h0s0,0,0,.05,0,0,0,0A0,0,0,0,0,489.3,55.9Z"
          />
          <path
            className="cls-4"
            d="M500.61,55.07a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,500.58,55.12Z"
          />
          <path
            className="cls-4"
            d="M511.9,54.58h0a0,0,0,0,0,0,0s-.05,0-.06,0S511.89,54.57,511.9,54.58Z"
          />
          <path
            className="cls-4"
            d="M523.24,54.32s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0S523.22,54.31,523.24,54.32Z"
          />
          <path
            className="cls-4"
            d="M534.61,54.29s0,0,0,0a0,0,0,0,0,0,0h0S534.62,54.3,534.61,54.29Z"
          />
          <path
            className="cls-4"
            d="M545.91,54.64a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,545.91,54.64Z"
          />
          <path
            className="cls-4"
            d="M557.23,55.23s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S557.22,55.22,557.23,55.23Z"
          />
          <path
            className="cls-4"
            d="M568.57,56.05a0,0,0,0,0,0,0,0,0,0,0,0,0,0h.05S568.58,56.06,568.57,56.05Z"
          />
          <path
            className="cls-4"
            d="M579.81,57.25h.05v0s0,0-.05,0A0,0,0,0,0,579.81,57.25Z"
          />
          <path
            className="cls-4"
            d="M591.06,58.68a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S591.05,58.67,591.06,58.68Z"
          />
          <path
            className="cls-4"
            d="M602.3,60.34a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,602.27,60.39Z"
          />
          <path
            className="cls-4"
            d="M613.46,62.33s0,0,0,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,613.46,62.33Z"
          />
          <path
            className="cls-4"
            d="M624.54,64.65s0,0,0,0a0,0,0,1,0-.05,0A0,0,0,0,0,624.58,64.64Z"
          />
          <path
            className="cls-4"
            d="M635.59,67.19a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,635.59,67.19Z"
          />
          <path
            className="cls-4"
            d="M646.6,70a0,0,0,0,0,0,.05,0,0,0,0,0,0-.05A0,0,0,0,0,646.6,70Z"
          />
          <path
            className="cls-4"
            d="M657.51,73.05h0a0,0,0,0,0,0,0s0,0,.06,0A0,0,0,0,0,657.51,73.05Z"
          />
          <path
            className="cls-4"
            d="M668.3,76.46a0,0,0,0,0,.05,0,0,0,0,0,0,0,0s0,0,0,0S668.29,76.45,668.3,76.46Z"
          />
          <path
            className="cls-4"
            d="M679,80.09h.05s0,0,0,0h0S679,80.09,679,80.09Z"
          />
          <path
            className="cls-4"
            d="M689.69,84a0,0,0,0,0,0,0,0,0,0,0,0,0,0h-.05S689.68,84,689.69,84Z"
          />
          <path
            className="cls-4"
            d="M700.24,88.15h0s0,0,0,0a0,0,0,0,0,0,0S700.22,88.15,700.24,88.15Z"
          />
          <path
            className="cls-4"
            d="M710.68,92.58s0,0,0,0a0,0,0,1,0-.05,0A0,0,0,0,0,710.72,92.57Z"
          />
          <path
            className="cls-4"
            d="M721,97.26s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,721,97.26Z"
          />
          <path
            className="cls-4"
            d="M731.21,102.2a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,731.24,102.15Z"
          />
          <path
            className="cls-4"
            d="M741.29,107.39s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0A0,0,0,0,0,741.29,107.39Z"
          />
          <path
            className="cls-4"
            d="M751.23,112.83s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,751.23,112.83Z"
          />
          <path
            className="cls-4"
            d="M761,118.52a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S761,118.51,761,118.52Z"
          />
          <path
            className="cls-4"
            d="M770.7,124.41a0,0,0,0,0,0,0s0,0,.06,0,0,0,0,0S770.71,124.39,770.7,124.41Z"
          />
          <path
            className="cls-4"
            d="M780.22,130.62a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,780.22,130.62Z"
          />
          <path
            className="cls-4"
            d="M789.57,137h0s0,0,0,0h-.05S789.56,137,789.57,137Z"
          />
          <path
            className="cls-4"
            d="M798.77,143.66s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,798.77,143.66Z"
          />
          <path
            className="cls-4"
            d="M807.79,150.52h0s0,0,0-.05,0,0,0,0S807.78,150.51,807.79,150.52Z"
          />
          <path
            className="cls-4"
            d="M816.64,157.61a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S816.63,157.6,816.64,157.61Z"
          />
          <path
            className="cls-4"
            d="M825.31,164.91h0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0S825.3,164.9,825.31,164.91Z"
          />
          <path
            className="cls-4"
            d="M833.8,172.43a0,0,0,0,0,0-.05s0,0,0,0S833.79,172.42,833.8,172.43Z"
          />
          <path
            className="cls-4"
            d="M842.1,180.16s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,842.13,180.11Z"
          />
          <path
            className="cls-4"
            d="M850.2,188.09a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,850.2,188.09Z"
          />
          <path
            className="cls-4"
            d="M858.1,196.22a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,858.1,196.22Z"
          />
          <path
            className="cls-4"
            d="M865.79,204.55s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,865.79,204.55Z"
          />
          <path
            className="cls-4"
            d="M873.28,213.06h0s0,0,0-.05h0A0,0,0,0,0,873.28,213.06Z"
          />
          <path
            className="cls-4"
            d="M880.55,221.76a0,0,0,0,0,0,0,0,0,0,0,0,0,0h-.05S880.54,221.75,880.55,221.76Z"
          />
          <path
            className="cls-4"
            d="M887.6,230.64s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,887.6,230.64Z"
          />
          <path
            className="cls-4"
            d="M894.43,239.69a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S894.42,239.68,894.43,239.69Z"
          />
          <path
            className="cls-4"
            d="M901,248.91s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,901,248.91Z"
          />
          <path
            className="cls-4"
            d="M907.4,258.29s0,0,0,0a0,0,0,0,0,0,0h-.05A0,0,0,0,0,907.4,258.29Z"
          />
          <path
            className="cls-4"
            d="M913.53,267.82h.05a0,0,0,0,0,0-.05h0A0,0,0,0,0,913.53,267.82Z"
          />
          <path
            className="cls-4"
            d="M919.43,277.51s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,919.43,277.51Z"
          />
          <path
            className="cls-4"
            d="M925.08,287.34s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,925.08,287.34Z"
          />
          <path
            className="cls-4"
            d="M930.48,297.31s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,930.48,297.31Z"
          />
          <path
            className="cls-4"
            d="M935.63,307.4a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S935.62,307.39,935.63,307.4Z"
          />
          <path
            className="cls-4"
            d="M940.53,317.63s0,0,.05,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,940.53,317.63Z"
          />
          <path
            className="cls-4"
            d="M945.18,328a0,0,0,1,0,0-.05s0,0,0,0A0,0,0,0,0,945.18,328Z"
          />
          <path
            className="cls-4"
            d="M949.56,338.43h0a0,0,0,0,0,0,0s-.05,0-.05,0A0,0,0,0,0,949.56,338.43Z"
          />
          <path
            className="cls-4"
            d="M953.68,349s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,953.68,349Z"
          />
          <path
            className="cls-4"
            d="M957.54,359.65s0,0,0,0a0,0,0,0,0,0,0s-.05,0-.06,0S957.53,359.64,957.54,359.65Z"
          />
          <path
            className="cls-4"
            d="M961.13,370.4s0,0,0,0v0s0,0-.06,0S961.12,370.39,961.13,370.4Z"
          />
          <path
            className="cls-4"
            d="M964.45,381.24h0a.06.06,0,0,0,0,0h0S964.44,381.23,964.45,381.24Z"
          />
          <path
            className="cls-4"
            d="M967.5,392.16h0a0,0,0,0,0,0-.05h0S967.49,392.15,967.5,392.16Z"
          />
          <path
            className="cls-4"
            d="M970.28,403.16h0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,970.28,403.16Z"
          />
          <path
            className="cls-4"
            d="M972.78,414.21s0,0,0,0,0,0,0,0h-.05S972.77,414.21,972.78,414.21Z"
          />
          <path
            className="cls-4"
            d="M975,425.33h0s0,0,0-.05,0,0,0,0A0,0,0,0,0,975,425.33Z"
          />
          <path
            className="cls-4"
            d="M977,436.5s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,977,436.5Z"
          />
          <path
            className="cls-4"
            d="M978.62,447.72a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,978.62,447.72Z"
          />
          <path
            className="cls-4"
            d="M980,459a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S980,459,980,459Z"
          />
          <path
            className="cls-4"
            d="M981.12,470.25s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0S981.11,470.24,981.12,470.25Z"
          />
          <path
            className="cls-4"
            d="M981.94,481.56s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,981.94,481.56Z"
          />
          <path
            className="cls-4"
            d="M982.49,492.88h0s0,0,0-.05h0S982.48,492.87,982.49,492.88Z"
          />
          <path
            className="cls-4"
            d="M982.75,504.22s0,0,0,0a0,0,0,0,0,0,0s0,0-.06,0S982.74,504.21,982.75,504.22Z"
          />
          <path
            className="cls-4"
            d="M982.73,515.56s0,0,0,0a0,0,0,0,0,0,0h0S982.71,515.55,982.73,515.56Z"
          />
          <path
            className="cls-4"
            d="M982.42,526.89s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,982.42,526.89Z"
          />
          <path
            className="cls-4"
            d="M981.84,538.21a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,981.84,538.21Z"
          />
          <path
            className="cls-4"
            d="M981,549.52s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,981,549.52Z"
          />
          <path
            className="cls-4"
            d="M979.82,560.8s0,0,0,0a0,0,0,0,0,0,0h0S979.8,560.79,979.82,560.8Z"
          />
          <circle
            className="cls-4"
            cx="978.4"
            cy="572.02"
            r="0.03"
            transform="translate(-56.9 1033.13) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M976.68,583.25a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,976.68,583.25Z"
          />
          <path
            className="cls-4"
            d="M974.69,594.41h0a0,0,0,0,0,0,0h0A0,0,0,0,0,974.69,594.41Z"
          />
          <path
            className="cls-4"
            d="M972.42,605.52s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0A0,0,0,0,0,972.42,605.52Z"
          />
          <path
            className="cls-4"
            d="M969.87,616.57s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,969.87,616.57Z"
          />
          <path
            className="cls-4"
            d="M967.06,627.55h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,967.06,627.55Z"
          />
          <path
            className="cls-4"
            d="M964,638.46a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M960.6,649.29s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,960.6,649.29Z"
          />
          <path
            className="cls-4"
            d="M957,660h0a0,0,0,0,0,0,0s0,0-.06,0S957,660,957,660Z"
          />
          <path
            className="cls-4"
            d="M953.07,670.67h0v0h0S953.06,670.66,953.07,670.67Z"
          />
          <path
            className="cls-4"
            d="M948.91,681.22a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S948.9,681.21,948.91,681.22Z"
          />
          <path
            className="cls-4"
            d="M944.49,691.66a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,944.52,691.61Z"
          />
          <path
            className="cls-4"
            d="M939.8,702h.05s0,0,0,0a0,0,0,0,0,0,0S939.79,702,939.8,702Z"
          />
          <path
            className="cls-4"
            d="M934.87,712.19a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,934.87,712.19Z"
          />
          <path
            className="cls-4"
            d="M929.67,722.27s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,929.67,722.27Z"
          />
          <path
            className="cls-4"
            d="M924.23,732.22a0,0,0,0,0,0,0,.06.06,0,0,0,0,0h0A0,0,0,0,0,924.23,732.22Z"
          />
          <path
            className="cls-4"
            d="M918.55,742h0s0,0,0,0h0S918.53,742,918.55,742Z"
          />
          <path
            className="cls-4"
            d="M912.62,751.69s0,0,0,0,0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M906.45,761.2a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,906.45,761.2Z"
          />
          <path
            className="cls-4"
            d="M900,770.56s0,0,0,0,0,0,0,0H900A.07.07,0,0,0,900,770.56Z"
          />
          <path
            className="cls-4"
            d="M893.41,779.75h0s0,0,0,0,0,0-.05,0A0,0,0,0,0,893.41,779.75Z"
          />
          <path
            className="cls-4"
            d="M886.54,788.77h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,886.54,788.77Z"
          />
          <path
            className="cls-4"
            d="M879.46,797.62h0s0,0,0,0h0S879.44,797.61,879.46,797.62Z"
          />
          <path
            className="cls-4"
            d="M872.15,806.29h.05s0,0,0,0h0S872.14,806.29,872.15,806.29Z"
          />
          <path
            className="cls-4"
            d="M864.63,814.78h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,864.63,814.78Z"
          />
          <path
            className="cls-4"
            d="M856.91,823.08s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,856.91,823.08Z"
          />
          <path
            className="cls-4"
            d="M849,831.18a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,849,831.13Z"
          />
          <path
            className="cls-4"
            d="M840.84,839.08s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,840.84,839.08Z"
          />
          <path
            className="cls-4"
            d="M832.52,846.78s0,0,0,0a0,0,0,0,0,0,0h0S832.5,846.77,832.52,846.78Z"
          />
          <path
            className="cls-4"
            d="M824,854.26s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,824,854.26Z"
          />
          <path
            className="cls-4"
            d="M815.3,861.53h.05a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,815.3,861.53Z"
          />
          <path
            className="cls-4"
            d="M806.43,868.59s0,0,0,0,0,0,0,0h0S806.41,868.58,806.43,868.59Z"
          />
          <path
            className="cls-4"
            d="M797.38,875.41h0a0,0,0,0,0,0,0h0S797.36,875.4,797.38,875.41Z"
          />
          <path
            className="cls-4"
            d="M788.16,882h0s0,0,0,0h0S788.15,882,788.16,882Z"
          />
          <path
            className="cls-4"
            d="M778.78,888.38h0s0,0,0,0,0,0,0,0S778.76,888.37,778.78,888.38Z"
          />
          <path
            className="cls-4"
            d="M769.24,894.52a0,0,0,0,0,0,0,.06.06,0,0,0,0,0h0A0,0,0,0,0,769.24,894.52Z"
          />
          <path
            className="cls-4"
            d="M759.56,900.41s0,0,0,0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,759.56,900.41Z"
          />
          <path
            className="cls-4"
            d="M749.73,906.06a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,749.76,906Z"
          />
          <path
            className="cls-4"
            d="M739.76,911.46h0a0,0,0,0,0,0-.05h0S739.75,911.45,739.76,911.46Z"
          />
          <path
            className="cls-4"
            d="M735.26,927.94s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A.06.06,0,0,0,735.26,927.94Z"
          />
          <path
            className="cls-4"
            d="M725,932.84h0a0,0,0,0,0,0-.05,0,0,0,0,0-.05,0S725,932.83,725,932.84Z"
          />
          <path
            className="cls-4"
            d="M714.7,937.5s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,714.7,937.5Z"
          />
          <path
            className="cls-4"
            d="M704.25,941.9s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,704.25,941.9Z"
          />
          <path
            className="cls-4"
            d="M693.7,946h0v0s0,0-.06,0S693.69,946,693.7,946Z"
          />
          <path
            className="cls-4"
            d="M683.05,949.93s0,0,0,0,0,0,0,0H683S683,949.92,683.05,949.93Z"
          />
          <path
            className="cls-4"
            d="M672.31,953.57h0s0,0,0,0h-.05A0,0,0,0,0,672.31,953.57Z"
          />
          <path
            className="cls-4"
            d="M661.48,956.93a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S661.47,956.92,661.48,956.93Z"
          />
          <path
            className="cls-4"
            d="M650.58,960a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S650.57,960,650.58,960Z"
          />
          <path
            className="cls-4"
            d="M639.6,962.88s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,639.6,962.88Z"
          />
          <path
            className="cls-4"
            d="M628.56,965.45h0a0,0,0,0,0,0-.05h0A0,0,0,0,0,628.56,965.45Z"
          />
          <path
            className="cls-4"
            d="M617.46,967.76h0a0,0,0,0,0,0,0s0,0-.06,0A0,0,0,0,0,617.46,967.76Z"
          />
          <path
            className="cls-4"
            d="M606.31,969.79a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,606.34,969.74Z"
          />
          <path
            className="cls-4"
            d="M595.11,971.55h0s0,0,0,0h0A0,0,0,0,0,595.11,971.55Z"
          />
          <path
            className="cls-4"
            d="M583.87,973h0s0,0,0,0h0S583.85,973,583.87,973Z"
          />
          <path
            className="cls-4"
            d="M572.6,974.26a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,572.63,974.21Z"
          />
          <path
            className="cls-4"
            d="M561.3,975.2h0s0,0,0,0h0S561.28,975.2,561.3,975.2Z"
          />
          <path
            className="cls-4"
            d="M550,975.87h0s0,0,0,0h0S550,975.86,550,975.87Z"
          />
          <path
            className="cls-4"
            d="M538.65,976.27a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S538.64,976.26,538.65,976.27Z"
          />
          <path
            className="cls-4"
            d="M527.31,976.39a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0A.06.06,0,0,0,527.31,976.39Z"
          />
          <path
            className="cls-4"
            d="M516,976.23h0a0,0,0,0,0,0-.05h0S516,976.22,516,976.23Z"
          />
          <path
            className="cls-4"
            d="M504.64,975.8h.05a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,504.64,975.8Z"
          />
          <path
            className="cls-4"
            d="M493.33,975.1a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05S493.32,975.09,493.33,975.1Z"
          />
          <path
            className="cls-4"
            d="M482,974.12s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,482,974.12Z"
          />
          <path
            className="cls-4"
            d="M470.77,972.87s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0S470.75,972.86,470.77,972.87Z"
          />
          <path
            className="cls-4"
            d="M459.53,971.34a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.05,0S459.52,971.33,459.53,971.34Z"
          />
          <path
            className="cls-4"
            d="M448.34,969.54h0s0,0,0,0h0A0,0,0,0,0,448.34,969.54Z"
          />
          <path
            className="cls-4"
            d="M437.19,967.47h0s0,0,0,0h-.05S437.18,967.46,437.19,967.47Z"
          />
          <path
            className="cls-4"
            d="M426.1,965.14h0s0,0,0,0h0S426.08,965.13,426.1,965.14Z"
          />
          <path
            className="cls-4"
            d="M415.06,962.53s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S415.05,962.52,415.06,962.53Z"
          />
          <path
            className="cls-4"
            d="M404.09,959.65h.05a0,0,0,0,0,0,0h-.05S404.08,959.64,404.09,959.65Z"
          />
          <path
            className="cls-4"
            d="M393.2,956.51h0v0s-.05,0-.06,0S393.19,956.51,393.2,956.51Z"
          />
          <path
            className="cls-4"
            d="M382.39,953.11h0a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,382.39,953.11Z"
          />
          <path
            className="cls-4"
            d="M371.66,949.45a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,371.66,949.45Z"
          />
          <path
            className="cls-4"
            d="M361,945.53s0,0,0,0a0,0,0,0,0,0,0h0S361,945.52,361,945.53Z"
          />
          <path
            className="cls-4"
            d="M350.48,941.35s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,350.48,941.35Z"
          />
          <path
            className="cls-4"
            d="M340,936.91s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,340,936.91Z"
          />
          <path
            className="cls-4"
            d="M329.72,932.23h0s0,0,0,0h-.05A0,0,0,0,0,329.72,932.23Z"
          />
          <path
            className="cls-4"
            d="M319.51,927.29s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,319.51,927.29Z"
          />
          <path
            className="cls-4"
            d="M309.43,922.11s0,0,0,0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,309.43,922.11Z"
          />
          <circle
            className="cls-4"
            cx="299.49"
            cy="916.66"
            r="0.03"
            transform="translate(-619.78 625.28) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M289.65,911s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,289.65,911Z"
          />
          <path
            className="cls-4"
            d="M280,905.12a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S280,905.11,280,905.12Z"
          />
          <path
            className="cls-4"
            d="M270.44,899h0s0,0,0,0a0,0,0,0,0,0,0S270.42,899,270.44,899Z"
          />
          <path
            className="cls-4"
            d="M261.05,892.62s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A.06.06,0,0,0,261.05,892.62Z"
          />
          <path
            className="cls-4"
            d="M251.86,886s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,251.86,886Z"
          />
          <path
            className="cls-4"
            d="M242.76,879.22s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,242.76,879.22Z"
          />
          <path
            className="cls-4"
            d="M233.87,872.19h0a0,0,0,0,0,0-.05h0A0,0,0,0,0,233.87,872.19Z"
          />
          <path
            className="cls-4"
            d="M225.14,865s0,0,.05,0a.06.06,0,0,0,0,0s0,0,0,0A0,0,0,0,0,225.14,865Z"
          />
          <path
            className="cls-4"
            d="M216.6,857.5s0,0,0,0a0,0,0,0,0,0,0h0S216.58,857.49,216.6,857.5Z"
          />
          <path
            className="cls-4"
            d="M208.24,849.84a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,208.24,849.84Z"
          />
          <path
            className="cls-4"
            d="M200.06,842h.05a.07.07,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,200.06,842Z"
          />
          <path
            className="cls-4"
            d="M192.08,833.93s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,192.08,833.93Z"
          />
          <path
            className="cls-4"
            d="M184.3,825.68s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0S184.29,825.67,184.3,825.68Z"
          />
          <path
            className="cls-4"
            d="M176.72,817.25h0a0,0,0,0,0,0,0h0S176.71,817.24,176.72,817.25Z"
          />
          <path
            className="cls-4"
            d="M169.35,808.64a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,169.38,808.59Z"
          />
          <path
            className="cls-4"
            d="M162.19,799.85a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,162.19,799.85Z"
          />
          <path
            className="cls-4"
            d="M155.24,790.89a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S155.23,790.88,155.24,790.89Z"
          />
          <path
            className="cls-4"
            d="M135.73,763h0a0,0,0,0,0,0,0s-.05,0-.06,0S135.72,763,135.73,763Z"
          />
          <path
            className="cls-4"
            d="M129.72,753.4h0a0,0,0,0,0,0,.05,0,0,0,0,0,0,0S129.73,753.41,129.72,753.4Z"
          />
          <path
            className="cls-4"
            d="M123.87,743.71s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,123.87,743.71Z"
          />
          <path
            className="cls-4"
            d="M118.3,733.84s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,118.3,733.84Z"
          />
          <path
            className="cls-4"
            d="M107.88,713.7a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05A0,0,0,0,0,107.88,713.7Z"
          />
          <path
            className="cls-4"
            d="M103,703.45a0,0,0,0,0,0,0s0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M98.44,693.08h0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,98.44,693.08Z"
          />
          <path
            className="cls-4"
            d="M94.14,682.56s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,.06,0A0,0,0,0,0,94.14,682.56Z"
          />
          <path
            className="cls-4"
            d="M90.06,672a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S90.07,672,90.06,672Z"
          />
          <path
            className="cls-4"
            d="M86.2,661.36h0a0,0,0,0,0,0,0s0,0-.06,0A0,0,0,0,0,86.2,661.36Z"
          />
          <path
            className="cls-4"
            d="M82.67,650.55h0s0,0,0,0h.05S82.68,650.55,82.67,650.55Z"
          />
          <path
            className="cls-4"
            d="M79.33,639.75s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S79.32,639.74,79.33,639.75Z"
          />
          <path
            className="cls-4"
            d="M76.29,628.83s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,76.29,628.83Z"
          />
          <path
            className="cls-4"
            d="M73.55,617.78h0s0,0,0,0,0,0,0,0S73.57,617.79,73.55,617.78Z"
          />
          <path
            className="cls-4"
            d="M71,606.77h0a.06.06,0,0,0,0,0h0S71,606.76,71,606.77Z"
          />
          <path
            className="cls-4"
            d="M68.81,595.61s0,0,0,0a0,0,0,0,0,0,0s0,0,.06,0S68.82,595.62,68.81,595.61Z"
          />
          <path
            className="cls-4"
            d="M66.84,584.44h0s0,0,0,0h0S66.86,584.45,66.84,584.44Z"
          />
          <path
            className="cls-4"
            d="M65.15,573.23h0s0,0,0,0h0A0,0,0,0,0,65.15,573.23Z"
          />
          <path
            className="cls-4"
            d="M63.73,562h0a.07.07,0,0,0,0,0s0,0,0,0A0,0,0,0,0,63.73,562Z"
          />
          <path
            className="cls-4"
            d="M62.58,550.71h0a.1.1,0,0,0,0,0,0,0,0,0,0,.06,0S62.59,550.72,62.58,550.71Z"
          />
          <path
            className="cls-4"
            d="M61.7,539.4s0,0,0,0a0,0,0,0,0,0,0h0S61.72,539.41,61.7,539.4Z"
          />
          <path
            className="cls-4"
            d="M61.1,528.08s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,61.1,528.08Z"
          />
          <path
            className="cls-4"
            d="M60.78,516.75s0,0-.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,60.78,516.75Z"
          />
          <path
            className="cls-4"
            d="M60.72,505.41s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,60.72,505.41Z"
          />
          <path
            className="cls-4"
            d="M61,494.08h0v0s.05,0,.06,0S61,494.09,61,494.08Z"
          />
          <path
            className="cls-4"
            d="M61.45,482.75s0,0,0,0a0,0,0,0,0,0,0h.05A.06.06,0,0,0,61.45,482.75Z"
          />
          <path
            className="cls-4"
            d="M62.19,471.49s0,0,0,0,0,0,0,0h0S62.17,471.48,62.19,471.49Z"
          />
          <path
            className="cls-4"
            d="M63.27,460.15h0a0,0,0,0,0,0,.05,0,0,0,0,0,.05,0A.06.06,0,0,0,63.27,460.15Z"
          />
          <path
            className="cls-4"
            d="M64.59,448.89a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S64.6,448.9,64.59,448.89Z"
          />
          <path
            className="cls-4"
            d="M66.18,437.66h0s0,0,0,0,0,0,0,0A0,0,0,0,0,66.18,437.66Z"
          />
          <path
            className="cls-4"
            d="M68.05,426.48a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S68.06,426.49,68.05,426.48Z"
          />
          <path
            className="cls-4"
            d="M70.19,415.35a0,0,0,0,0,0,0s0,0,0,0h0S70.2,415.35,70.19,415.35Z"
          />
          <path
            className="cls-4"
            d="M72.59,404.27h0s0,0,0,0h0S72.61,404.28,72.59,404.27Z"
          />
          <path
            className="cls-4"
            d="M75.27,393.25h0a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,75.27,393.25Z"
          />
          <path
            className="cls-4"
            d="M84.88,360.63a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S84.89,360.64,84.88,360.63Z"
          />
          <path
            className="cls-4"
            d="M88.61,349.92a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,.05,0S88.62,349.93,88.61,349.92Z"
          />
          <path
            className="cls-4"
            d="M92.6,339.31s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,92.6,339.31Z"
          />
          <path
            className="cls-4"
            d="M96.84,328.79s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,96.84,328.79Z"
          />
          <path
            className="cls-4"
            d="M101.34,318.39h0a0,0,0,0,0,0,.05s0,0,0,0A0,0,0,0,0,101.34,318.39Z"
          />
          <path
            className="cls-4"
            d="M106.09,308.09h0s0,0,0,0,0,0,0,0A0,0,0,0,0,106.09,308.09Z"
          />
          <path
            className="cls-4"
            d="M111.08,297.91s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0S111.1,297.92,111.08,297.91Z"
          />
          <path
            className="cls-4"
            d="M116.33,287.86s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,116.33,287.86Z"
          />
          <path
            className="cls-4"
            d="M121.81,277.94s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,.06,0S121.82,278,121.81,277.94Z"
          />
          <path
            className="cls-4"
            d="M127.53,268.15s0,0,0,0,0,0,0,0a0,0,0,0,0,0-.05Z"
          />
          <path
            className="cls-4"
            d="M133.49,258.51h0s0,0,0,.05,0,0,0,0A0,0,0,0,0,133.49,258.51Z"
          />
          <path
            className="cls-4"
            d="M139.68,249a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,139.68,249Z"
          />
          <path
            className="cls-4"
            d="M146.11,239.66a0,0,0,0,0-.05,0s0,0,0,0h0A0,0,0,0,0,146.11,239.66Z"
          />
          <path
            className="cls-4"
            d="M152.75,230.48h0s0,0,0,.05,0,0,0,0S152.76,230.49,152.75,230.48Z"
          />
          <path
            className="cls-4"
            d="M159.62,221.46s0,0-.05,0,0,0,0,0,0,0,.05,0S159.63,221.47,159.62,221.46Z"
          />
          <path
            className="cls-4"
            d="M166.7,212.6s0,0,0,0a0,0,0,0,0,0,0h.05A0,0,0,0,0,166.7,212.6Z"
          />
          <path
            className="cls-4"
            d="M174,203.92s0,0,0,0,0,0,0,0h0S174,203.93,174,203.92Z"
          />
          <path
            className="cls-4"
            d="M181.5,195.42h0s0,0,0,0h0A0,0,0,0,0,181.5,195.42Z"
          />
          <path
            className="cls-4"
            d="M189.21,187.11s0,0-.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,189.21,187.11Z"
          />
          <path
            className="cls-4"
            d="M197.11,179h0s0,0,0,0h0S197.13,179,197.11,179Z"
          />
          <path
            className="cls-4"
            d="M205.22,171.05a0,0,0,0,0-.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,205.22,171.05Z"
          />
          <path
            className="cls-4"
            d="M213.51,163.32s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,213.51,163.32Z"
          />
          <path
            className="cls-4"
            d="M222,155.79s0,0,0,0,0,0,0,0a0,0,0,1,0,0-.05Z"
          />
          <path
            className="cls-4"
            d="M230.64,148.47a0,0,0,0,0,0,.05,0,0,0,0,0,0-.05A0,0,0,0,0,230.64,148.47Z"
          />
          <path
            className="cls-4"
            d="M239.47,141.36h0s0,0,0,0,0,0,0,0A0,0,0,0,0,239.47,141.36Z"
          />
          <path
            className="cls-4"
            d="M248.48,134.47s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,248.48,134.47Z"
          />
          <path
            className="cls-4"
            d="M257.64,127.8h0s0,0,0,0,0,0,0,0A0,0,0,0,0,257.64,127.8Z"
          />
          <path
            className="cls-4"
            d="M267,121.35s0,0,0,0a0,0,0,0,0,0,0s.05,0,.06,0S267,121.36,267,121.35Z"
          />
          <path
            className="cls-4"
            d="M276.45,115.13s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,276.45,115.13Z"
          />
          <path
            className="cls-4"
            d="M286.08,109.14H286s0,0,0,0,0,0,.05,0A0,0,0,0,0,286.08,109.14Z"
          />
          <path
            className="cls-4"
            d="M295.85,103.39s0,0-.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,295.85,103.39Z"
          />
          <path
            className="cls-4"
            d="M305.75,97.88h0a.1.1,0,0,0,0,0,0,0,0,0,0,.06,0S305.77,97.89,305.75,97.88Z"
          />
          <path
            className="cls-4"
            d="M315.79,92.6h0s0,0,0,0h.05A0,0,0,0,0,315.79,92.6Z"
          />
          <path
            className="cls-4"
            d="M326,87.58a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,326,87.58Z"
          />
          <path
            className="cls-4"
            d="M336.23,82.8a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,336.23,82.8Z"
          />
          <path
            className="cls-4"
            d="M346.63,78.27s0,0-.05,0a.06.06,0,0,0,0,0s0,0,0,0A0,0,0,0,0,346.63,78.27Z"
          />
          <path
            className="cls-4"
            d="M357.13,74s0,0,0,0a.1.1,0,0,0,0,0h.06S357.14,74,357.13,74Z"
          />
          <path
            className="cls-4"
            d="M367.73,70s0,0,0,0a.1.1,0,0,0,0,0,0,0,0,0,0,.06,0S367.74,70,367.73,70Z"
          />
          <path
            className="cls-4"
            d="M378.39,66.27s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,378.39,66.27Z"
          />
          <circle
            className="cls-4"
            cx="389.19"
            cy="62.74"
            r="0.03"
            transform="translate(111.23 342.28) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M400.08,59.48s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,400.08,59.48Z"
          />
          <path
            className="cls-4"
            d="M411,56.51h0a0,0,0,0,0,0,.05h0A0,0,0,0,0,411,56.51Z"
          />
          <path
            className="cls-4"
            d="M422,53.8s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,422,53.8Z"
          />
          <path
            className="cls-4"
            d="M433.06,51.41s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,433.06,51.41Z"
          />
          <path
            className="cls-4"
            d="M444.23,49.19s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,444.23,49.19Z"
          />
          <path className="cls-4" d="M455.36,47.3s0,0,0,0h0s0,0,0,0Z" />
          <path
            className="cls-4"
            d="M466.62,45.67s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,.05,0A0,0,0,0,0,466.62,45.67Z"
          />
          <path
            className="cls-4"
            d="M477.85,44.36h0s0,0,0,0,0,0,0,0A0,0,0,0,0,477.85,44.36Z"
          />
          <path
            className="cls-4"
            d="M489.13,43.28s0,0,.05,0a.06.06,0,0,0,0,0s0,0,0,0A0,0,0,0,0,489.13,43.28Z"
          />
          <path
            className="cls-4"
            d="M500.48,42.43h-.05s0,0,0,0h0S500.49,42.44,500.48,42.43Z"
          />
          <path
            className="cls-4"
            d="M511.77,41.94a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,511.77,41.94Z"
          />
          <path
            className="cls-4"
            d="M523.1,41.69s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,523.1,41.69Z"
          />
          <path
            className="cls-4"
            d="M534.48,41.65a0,0,0,0,0-.05,0s0,0,0,0h0A0,0,0,0,0,534.48,41.65Z"
          />
          <path
            className="cls-4"
            d="M545.81,42s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,.05,0S545.82,42,545.81,42Z"
          />
          <path
            className="cls-4"
            d="M557.13,42.51s0,0,0,0a0,0,0,0,0,0,0h0S557.15,42.52,557.13,42.51Z"
          />
          <circle
            className="cls-4"
            cx="568.42"
            cy="43.38"
            r="0.03"
            transform="translate(201.65 479.8) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M579.69,44.52h0s0,0,0-.05,0,0,0,0A0,0,0,0,0,579.69,44.52Z"
          />
          <path
            className="cls-4"
            d="M590.94,45.91a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,590.94,45.91Z"
          />
          <path
            className="cls-4"
            d="M602.15,47.57h.05a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,602.15,47.57Z"
          />
          <path
            className="cls-4"
            d="M613.33,49.51h0s0,0,0,0,0,0,0,0S613.31,49.5,613.33,49.51Z"
          />
          <path
            className="cls-4"
            d="M624.45,51.71s0,0,0,0a0,0,0,0,0,0,0s0,0-.06,0A0,0,0,0,0,624.45,51.71Z"
          />
          <path
            className="cls-4"
            d="M635.51,54.18h0s0,0,0,0h0S635.5,54.17,635.51,54.18Z"
          />
          <path
            className="cls-4"
            d="M646.55,56.88s0,0,0,0,0,0,0,0h0S646.56,56.88,646.55,56.88Z"
          />
          <path
            className="cls-4"
            d="M657.45,59.93a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,657.45,59.93Z"
          />
          <path
            className="cls-4"
            d="M668.3,63.2a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0-.05,0S668.29,63.19,668.3,63.2Z"
          />
          <path
            className="cls-4"
            d="M679.07,66.74a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,679.07,66.74Z"
          />
          <path
            className="cls-4"
            d="M689.76,70.53a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,689.79,70.48Z"
          />
          <path
            className="cls-4"
            d="M700.35,74.58a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,700.35,74.58Z"
          />
          <path
            className="cls-4"
            d="M710.83,78.89s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,710.83,78.89Z"
          />
          <path
            className="cls-4"
            d="M721.21,83.45s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,721.21,83.45Z"
          />
          <path
            className="cls-4"
            d="M731.48,88.26s0,0,0,0a0,0,0,0,0,0,0s0,0-.06,0S731.47,88.25,731.48,88.26Z"
          />
          <path
            className="cls-4"
            d="M741.63,93.32s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,741.63,93.32Z"
          />
          <path
            className="cls-4"
            d="M751.65,98.62h0s0,0,0-.05h0A0,0,0,0,0,751.65,98.62Z"
          />
          <path
            className="cls-4"
            d="M761.54,104.17h0a0,0,0,0,0,0,0s-.05,0-.06,0S761.52,104.16,761.54,104.17Z"
          />
          <path
            className="cls-4"
            d="M771.29,110a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S771.28,109.94,771.29,110Z"
          />
          <path
            className="cls-4"
            d="M780.9,116s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S780.89,116,780.9,116Z"
          />
          <path
            className="cls-4"
            d="M790.35,122.17s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,790.35,122.17Z"
          />
          <path
            className="cls-4"
            d="M799.66,128.69h.05a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,799.66,128.69Z"
          />
          <path
            className="cls-4"
            d="M808.81,135.39a0,0,0,0,0,0,0s0,0,0,0h0S808.8,135.38,808.81,135.39Z"
          />
          <path
            className="cls-4"
            d="M817.79,142.31h0a0,0,0,0,0,0,0s-.05,0-.06,0S817.78,142.3,817.79,142.31Z"
          />
          <path
            className="cls-4"
            d="M826.6,149.45a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0S826.59,149.44,826.6,149.45Z"
          />
          <path
            className="cls-4"
            d="M835.23,156.8s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,835.23,156.8Z"
          />
          <path
            className="cls-4"
            d="M843.69,164.35a0,0,0,0,0,0,0s0,0,0,0h0S843.67,164.34,843.69,164.35Z"
          />
          <path
            className="cls-4"
            d="M852,172.11h0a0,0,0,0,0,0-.05h0A0,0,0,0,0,852,172.11Z"
          />
          <path
            className="cls-4"
            d="M860,180.07s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,860,180.07Z"
          />
          <path
            className="cls-4"
            d="M867.91,188.22s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,867.91,188.22Z"
          />
          <path
            className="cls-4"
            d="M875.6,196.56s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,875.6,196.56Z"
          />
          <path
            className="cls-4"
            d="M883.07,205.08s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,883.07,205.08Z"
          />
          <path
            className="cls-4"
            d="M890.34,213.78s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A.06.06,0,0,0,890.34,213.78Z"
          />
          <path
            className="cls-4"
            d="M897.4,222.66h0a0,0,0,0,0,0,0s-.05,0-.06,0S897.38,222.65,897.4,222.66Z"
          />
          <path
            className="cls-4"
            d="M904.23,231.7s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,904.23,231.7Z"
          />
          <path
            className="cls-4"
            d="M910.85,240.91s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S910.84,240.9,910.85,240.91Z"
          />
          <path
            className="cls-4"
            d="M917.24,250.27h0a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,917.24,250.27Z"
          />
          <path
            className="cls-4"
            d="M923.4,259.79s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,923.4,259.79Z"
          />
          <path
            className="cls-4"
            d="M929.33,269.45h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,929.33,269.45Z"
          />
          <path
            className="cls-4"
            d="M935,279.26s0,0,0,0a0,0,0,1,0-.05,0A0,0,0,0,0,935.07,279.25Z"
          />
          <path
            className="cls-4"
            d="M940.48,289.2s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S940.47,289.19,940.48,289.2Z"
          />
          <path
            className="cls-4"
            d="M945.69,299.27s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,945.69,299.27Z"
          />
          <path
            className="cls-4"
            d="M950.66,309.46a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,950.69,309.41Z"
          />
          <path
            className="cls-4"
            d="M955.37,319.77s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,955.37,319.77Z"
          />
          <path
            className="cls-4"
            d="M959.84,330.19s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0A0,0,0,0,0,959.84,330.19Z"
          />
          <path
            className="cls-4"
            d="M964.08,340.67a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,964.08,340.67Z"
          />
          <path
            className="cls-4"
            d="M968,351.34a0,0,0,0,0,0,0,.07.07,0,0,0,0,0s0,0,0,0A0,0,0,0,0,968,351.34Z"
          />
          <path
            className="cls-4"
            d="M971.7,362.06h0s0,0,0-.05,0,0,0,0S971.69,362.05,971.7,362.06Z"
          />
          <path
            className="cls-4"
            d="M975.13,372.87a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,975.13,372.87Z"
          />
          <path
            className="cls-4"
            d="M978.3,383.75h.05a0,0,0,0,0,0-.05h0A0,0,0,0,0,978.3,383.75Z"
          />
          <path
            className="cls-4"
            d="M981.21,394.71a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S981.2,394.7,981.21,394.71Z"
          />
          <path
            className="cls-4"
            d="M983.85,405.74s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,983.85,405.74Z"
          />
          <path
            className="cls-4"
            d="M986.22,416.82h0s0,0,0,0h0S986.21,416.81,986.22,416.82Z"
          />
          <path
            className="cls-4"
            d="M988.32,428a0,0,0,0,0,0,0s0,0,0,0h0A0,0,0,0,0,988.32,428Z"
          />
          <path
            className="cls-4"
            d="M990.15,439.15a0,0,0,0,0,.05,0s0,0,0,0h0S990.14,439.14,990.15,439.15Z"
          />
          <path
            className="cls-4"
            d="M991.71,450.38h0s0,0,0,0,0,0,0,0S991.7,450.37,991.71,450.38Z"
          />
          <path
            className="cls-4"
            d="M993,461.65h0a0,0,0,0,0,0,0s0,0-.06,0S993,461.64,993,461.65Z"
          />
          <path
            className="cls-4"
            d="M994,472.94a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S994,472.93,994,472.94Z"
          />
          <path
            className="cls-4"
            d="M994.75,484.26s0,0,0,0,0,0,0,0h0A0,0,0,0,0,994.75,484.26Z"
          />
          <path
            className="cls-4"
            d="M995.21,495.58h0a.07.07,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,995.21,495.58Z"
          />
          <path
            className="cls-4"
            d="M995.4,506.92a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S995.39,506.91,995.4,506.92Z"
          />
          <path
            className="cls-4"
            d="M995.32,518.26h0s0,0,0,0,0,0-.05,0A0,0,0,0,0,995.32,518.26Z"
          />
          <path
            className="cls-4"
            d="M995,529.59s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,995,529.59Z"
          />
          <path
            className="cls-4"
            d="M994.32,540.91a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,994.35,540.86Z"
          />
          <path
            className="cls-4"
            d="M993.41,552.21a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,993.41,552.21Z"
          />
          <path
            className="cls-4"
            d="M992.22,563.49s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,992.22,563.49Z"
          />
          <path
            className="cls-4"
            d="M990.76,574.73s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,990.76,574.73Z"
          />
          <path
            className="cls-4"
            d="M989,585.93a0,0,0,0,0,0,0s0,0,0,0h0S989,585.92,989,585.93Z"
          />
          <path
            className="cls-4"
            d="M987,597.09h.05a0,0,0,0,0,0-.05h0S987,597.08,987,597.09Z"
          />
          <path
            className="cls-4"
            d="M984.76,608.2s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,984.76,608.2Z"
          />
          <path
            className="cls-4"
            d="M982.22,619.25a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,982.22,619.25Z"
          />
          <path
            className="cls-4"
            d="M979.41,630.24a0,0,0,0,0,.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,979.41,630.24Z"
          />
          <path
            className="cls-4"
            d="M976.34,641.15s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S976.33,641.14,976.34,641.15Z"
          />
          <path
            className="cls-4"
            d="M973,652h0s0,0,0,0a0,0,0,0,0,0,0S973,652,973,652Z"
          />
          <path
            className="cls-4"
            d="M969.41,662.74s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0S969.39,662.73,969.41,662.74Z"
          />
          <path
            className="cls-4"
            d="M965.55,673.4s0,0,0,0a0,0,0,0,0,0,0h0S965.53,673.39,965.55,673.4Z"
          />
          <circle
            className="cls-4"
            cx="961.45"
            cy="683.94"
            r="0.03"
            transform="translate(-154.88 1065.99) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M957.06,694.42h0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0S957.05,694.41,957.06,694.42Z"
          />
          <path
            className="cls-4"
            d="M952.44,704.78h0s0,0,0,0h0S952.42,704.77,952.44,704.78Z"
          />
          <path
            className="cls-4"
            d="M947.56,715s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,947.56,715Z"
          />
          <path
            className="cls-4"
            d="M942.44,725.13a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,942.44,725.13Z"
          />
          <path
            className="cls-4"
            d="M937.08,735.12s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,937.08,735.12Z"
          />
          <path
            className="cls-4"
            d="M931.48,745s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,931.48,745Z"
          />
          <path
            className="cls-4"
            d="M925.63,754.69s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,925.63,754.69Z"
          />
          <path
            className="cls-4"
            d="M919.56,764.26s0,0,0,0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,919.56,764.26Z"
          />
          <path
            className="cls-4"
            d="M913.25,773.68a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S913.24,773.67,913.25,773.68Z"
          />
          <path
            className="cls-4"
            d="M906.72,783s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,906.72,783Z"
          />
          <path
            className="cls-4"
            d="M900,792.05h0a0,0,0,0,0,0,0s0,0,0,0S900,792,900,792.05Z"
          />
          <path
            className="cls-4"
            d="M893,801s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0A0,0,0,0,0,893,801Z"
          />
          <path
            className="cls-4"
            d="M885.8,809.76h0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,885.8,809.76Z"
          />
          <path
            className="cls-4"
            d="M878.4,818.35s0,0,0,0,0,0,0,0a0,0,0,1,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M870.79,826.76h0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,870.79,826.76Z"
          />
          <path
            className="cls-4"
            d="M863,835s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,863,835Z"
          />
          <path className="cls-4" d="M855,843h0v0s0,0-.06,0S855,843,855,843Z" />
          <path
            className="cls-4"
            d="M846.78,850.84s0,0,0,0a0,0,0,0,0-.05,0S846.77,850.83,846.78,850.84Z"
          />
          <path
            className="cls-4"
            d="M838.39,858.47h.05s0,0,0,0,0,0-.05,0A0,0,0,0,0,838.39,858.47Z"
          />
          <path
            className="cls-4"
            d="M829.83,865.89h0s0,0,0,0h0S829.81,865.88,829.83,865.89Z"
          />
          <path
            className="cls-4"
            d="M821.08,873.11a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A.06.06,0,0,0,821.08,873.11Z"
          />
          <path
            className="cls-4"
            d="M812.16,880.11s0,0,.05,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,812.16,880.11Z"
          />
          <path
            className="cls-4"
            d="M803.08,886.89h0s0,0,0,0,0,0,0,0A0,0,0,0,0,803.08,886.89Z"
          />
          <path
            className="cls-4"
            d="M793.83,893.45h0a0,0,0,0,0,0-.05,0,0,0,0,0,0,0S793.82,893.44,793.83,893.45Z"
          />
          <path
            className="cls-4"
            d="M784.43,899.79s0,0,0,0a0,0,0,1,0-.05,0A0,0,0,0,0,784.47,899.78Z"
          />
          <path
            className="cls-4"
            d="M774.87,905.89s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,774.87,905.89Z"
          />
          <path
            className="cls-4"
            d="M765.18,911.76h0a0,0,0,0,0,0,0,0,0,0,0,0-.05,0A0,0,0,0,0,765.18,911.76Z"
          />
          <path
            className="cls-4"
            d="M755.34,917.4s0,0,0,0,0,0,0,0h0S755.32,917.39,755.34,917.4Z"
          />
          <path
            className="cls-4"
            d="M745.36,922.79s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,745.36,922.79Z"
          />
          <path
            className="cls-4"
            d="M730.64,944.17s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,730.64,944.17Z"
          />
          <path
            className="cls-4"
            d="M720.31,948.83a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,720.31,948.83Z"
          />
          <path
            className="cls-4"
            d="M709.87,953.25a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,709.9,953.2Z"
          />
          <path
            className="cls-4"
            d="M688.69,961.34a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,688.69,961.34Z"
          />
          <path
            className="cls-4"
            d="M678,965a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,678,965Z"
          />
          <path
            className="cls-4"
            d="M667.14,968.42h.05a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,667.14,968.42Z"
          />
          <path
            className="cls-4"
            d="M645.3,974.48a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,.05Z"
          />
          <path
            className="cls-4"
            d="M623.18,979.5a0,0,0,0,0,.05,0s0,0,0,0h0A0,0,0,0,0,623.18,979.5Z"
          />
          <path
            className="cls-4"
            d="M612,981.61h.05a0,0,0,0,0,0-.05h0A0,0,0,0,0,612,981.61Z"
          />
          <path
            className="cls-4"
            d="M555.77,988.22a0,0,0,0,0,.05,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,555.77,988.22Z"
          />
          <path
            className="cls-4"
            d="M544.45,988.74a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S544.44,988.73,544.45,988.74Z"
          />
          <path
            className="cls-4"
            d="M533.11,989h0s0,0,0,0h0S533.1,989,533.11,989Z"
          />
          <path
            className="cls-4"
            d="M510.44,988.7s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0A0,0,0,0,0,510.44,988.7Z"
          />
          <path
            className="cls-4"
            d="M431.73,979.26a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S431.72,979.25,431.73,979.26Z"
          />
          <path
            className="cls-4"
            d="M324.36,943.66h0a0,0,0,0,0,0,0h0S324.35,943.65,324.36,943.66Z"
          />
          <path
            className="cls-4"
            d="M314.15,938.73s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S314.14,938.72,314.15,938.73Z"
          />
          <path
            className="cls-4"
            d="M304.06,933.56s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S304.05,933.55,304.06,933.56Z"
          />
          <path
            className="cls-4"
            d="M202.51,861.77h0v0s-.05,0-.06,0A0,0,0,0,0,202.51,861.77Z"
          />
          <path
            className="cls-4"
            d="M194.27,854s0,0,0,0a0,0,0,0,0,0,0s0,0-.05,0S194.26,854,194.27,854Z"
          />
          <path
            className="cls-4"
            d="M186.22,846a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,186.25,846Z"
          />
          <path
            className="cls-4"
            d="M77.65,675s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,77.65,675Z"
          />
          <path
            className="cls-4"
            d="M64,631.72a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S64.05,631.73,64,631.72Z"
          />
          <path
            className="cls-4"
            d="M49.24,542.37h0s0,0,0,0h0S49.26,542.38,49.24,542.37Z"
          />
          <path
            className="cls-4"
            d="M48.23,497h0a0,0,0,0,0,0,0s0,0,.06,0S48.24,497.05,48.23,497Z"
          />
          <path
            className="cls-4"
            d="M100.93,290h-.05a0,0,0,0,0,0,0h.05S100.94,290,100.93,290Z"
          />
          <path
            className="cls-4"
            d="M106.22,280s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,106.22,280Z"
          />
          <path
            className="cls-4"
            d="M179.2,179.3a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,179.17,179.35Z"
          />
          <path
            className="cls-4"
            d="M187.09,171.15s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,187.09,171.15Z"
          />
          <path
            className="cls-4"
            d="M195.16,163.19s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,195.16,163.19Z"
          />
          <path
            className="cls-4"
            d="M285.33,94.89a0,0,0,0,0-.05,0,0,0,0,0,0,0,0h0A0,0,0,0,0,285.33,94.89Z"
          />
          <path
            className="cls-4"
            d="M295.17,89.27a0,0,0,0,0,0,0s0,0,0,0,0,0,0,0A0,0,0,0,0,295.17,89.27Z"
          />
          <path
            className="cls-4"
            d="M356.79,60.58s0,0,0,0,0,0,0,0a0,0,0,0,0,.05,0A0,0,0,0,0,356.79,60.58Z"
          />
          <path
            className="cls-4"
            d="M378.16,53a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S378.17,53,378.16,53Z"
          />
          <path
            className="cls-4"
            d="M388.94,49.63s0,0,0,0a0,0,0,0,0,0,0h0S388.92,49.62,388.94,49.63Z"
          />
          <path
            className="cls-4"
            d="M399.86,46.42a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,399.86,46.42Z"
          />
          <path
            className="cls-4"
            d="M421.85,40.88a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,.05,0S421.86,40.89,421.85,40.88Z"
          />
          <path
            className="cls-4"
            d="M455.26,34.53s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,455.26,34.53Z"
          />
          <path
            className="cls-4"
            d="M466.48,33a0,0,0,0,0,0,0,0,0,0,0,0,0,0h0A0,0,0,0,0,466.48,33Z"
          />
          <path
            className="cls-4"
            d="M489,30.57a0,0,0,1,0,0,.05s0,0,0,0A0,0,0,0,0,489,30.57Z"
          />
          <path
            className="cls-4"
            d="M511.63,29.31a0,0,0,0,0,.05,0s0,0,0,0,0,0,0,0A0,0,0,0,0,511.63,29.31Z"
          />
          <path
            className="cls-4"
            d="M523,29a0,0,0,0,0,0,0s0,0,0,0h0S523,29,523,29Z"
          />
          <path
            className="cls-4"
            d="M534.31,29.07s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,534.31,29.07Z"
          />
          <path
            className="cls-4"
            d="M557,29.9s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,557,29.9Z"
          />
          <path
            className="cls-4"
            d="M568.27,30.72a0,0,0,0,0,.05,0s0,0,0,0,0,0,0,0A0,0,0,0,0,568.27,30.72Z"
          />
          <path
            className="cls-4"
            d="M579.56,31.8a0,0,0,0,0,0-.05,0,0,0,0,0,0,.05S579.55,31.79,579.56,31.8Z"
          />
          <path
            className="cls-4"
            d="M624.35,38.79s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,624.35,38.79Z"
          />
          <path
            className="cls-4"
            d="M635.43,41.19s0,0,0,0a0,0,0,0,0,0,0h0S635.42,41.19,635.43,41.19Z"
          />
          <path
            className="cls-4"
            d="M657.4,46.79s0,0,.05,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,657.4,46.79Z"
          />
          <path
            className="cls-4"
            d="M668.32,49.92s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,668.32,49.92Z"
          />
          <path
            className="cls-4"
            d="M711,65.21s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,711,65.21Z"
          />
          <path
            className="cls-4"
            d="M721.4,69.7s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,721.4,69.7Z"
          />
          <path
            className="cls-4"
            d="M752,84.49a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S752,84.48,752,84.49Z"
          />
          <path
            className="cls-4"
            d="M762,89.9a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,762,89.9Z"
          />
          <path
            className="cls-4"
            d="M771.82,95.54a0,0,0,1,0,0-.05h0A0,0,0,0,0,771.82,95.54Z"
          />
          <path
            className="cls-4"
            d="M781.52,101.41a0,0,0,0,0,0,0s0,0,0,0h0S781.5,101.4,781.52,101.41Z"
          />
          <path
            className="cls-4"
            d="M791.07,107.51h0a0,0,0,0,0,0-.05h0S791.06,107.5,791.07,107.51Z"
          />
          <path
            className="cls-4"
            d="M800.48,113.84s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,800.48,113.84Z"
          />
          <path
            className="cls-4"
            d="M809.73,120.34a.06.06,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,809.73,120.34Z"
          />
          <path
            className="cls-4"
            d="M818.84,127.14s0,0,0,0a0,0,0,0,0,0,0h0S818.83,127.13,818.84,127.14Z"
          />
          <path
            className="cls-4"
            d="M827.78,134.12a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0-.05,0S827.77,134.11,827.78,134.12Z"
          />
          <circle
            className="cls-4"
            cx="836.57"
            cy="141.28"
            r="0.03"
            transform="translate(233.87 738.41) rotate(-54.32)"
          />
          <path
            className="cls-4"
            d="M845.15,148.69h0s0,0,0-.05,0,0,0,0S845.14,148.68,845.15,148.69Z"
          />
          <path
            className="cls-4"
            d="M853.57,156.28h0a.1.1,0,0,0,0,0s0,0,0,0A0,0,0,0,0,853.57,156.28Z"
          />
          <path
            className="cls-4"
            d="M861.81,164.07s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,861.81,164.07Z"
          />
          <path
            className="cls-4"
            d="M885.4,188.57a0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0S885.39,188.56,885.4,188.57Z"
          />
          <path
            className="cls-4"
            d="M892.87,197.1s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,892.87,197.1Z"
          />
          <path
            className="cls-4"
            d="M900.13,205.8h0a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,900.13,205.8Z"
          />
          <path
            className="cls-4"
            d="M914,223.71h0s0,0,0-.05,0,0,0,0S914,223.7,914,223.71Z"
          />
          <path
            className="cls-4"
            d="M920.67,232.91a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S920.66,232.9,920.67,232.91Z"
          />
          <path
            className="cls-4"
            d="M927.08,242.26s0,0,0,0a0,0,0,0,0,0,0s0,0-.06,0S927.07,242.25,927.08,242.26Z"
          />
          <path
            className="cls-4"
            d="M933.27,251.76s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S933.26,251.75,933.27,251.76Z"
          />
          <path
            className="cls-4"
            d="M939.23,261.4s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,939.23,261.4Z"
          />
          <path
            className="cls-4"
            d="M945,271.18a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,945,271.18Z"
          />
          <path
            className="cls-4"
            d="M950.47,281.1h0a0,0,0,0,0,0,0s0,0-.06,0S950.45,281.09,950.47,281.1Z"
          />
          <path
            className="cls-4"
            d="M965.55,311.58s0,0,0,0a0,0,0,0,0,0,0h0S965.53,311.57,965.55,311.58Z"
          />
          <path
            className="cls-4"
            d="M970.09,322h0a0,0,0,0,0,0,0s0,0-.06,0S970.07,322,970.09,322Z"
          />
          <path
            className="cls-4"
            d="M974.38,332.46s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,974.38,332.46Z"
          />
          <path
            className="cls-4"
            d="M982.22,353.73h0a0,0,0,0,0,0-.05h0S982.21,353.72,982.22,353.73Z"
          />
          <path
            className="cls-4"
            d="M985.76,364.5h0a0,0,0,0,0,0-.05h0S985.75,364.49,985.76,364.5Z"
          />
          <path
            className="cls-4"
            d="M989.05,375.36h0v0s-.05,0-.06,0A0,0,0,0,0,989.05,375.36Z"
          />
          <path
            className="cls-4"
            d="M992.08,386.28a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S992.07,386.27,992.08,386.28Z"
          />
          <path
            className="cls-4"
            d="M994.85,397.28s0,0,0,0,0,0,0,0h0S994.83,397.27,994.85,397.28Z"
          />
          <path
            className="cls-4"
            d="M997.36,408.33a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,997.36,408.33Z"
          />
          <path
            className="cls-4"
            d="M999.6,419.44h0s0,0,0,0h0S999.59,419.44,999.6,419.44Z"
          />
          <path
            className="cls-4"
            d="M1001.59,430.61s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0S1001.58,430.6,1001.59,430.61Z"
          />
          <path
            className="cls-4"
            d="M1004.76,453.06a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,1004.76,453.06Z"
          />
          <path
            className="cls-4"
            d="M1006,464.33h0a0,0,0,0,0,0-.05h0A0,0,0,0,0,1006,464.33Z"
          />
          <path
            className="cls-4"
            d="M1006.87,475.63h0a0,0,0,0,0,0-.05h0A0,0,0,0,0,1006.87,475.63Z"
          />
          <path
            className="cls-4"
            d="M1007.53,487h0s0,0,0-.05,0,0,0,0S1007.52,486.94,1007.53,487Z"
          />
          <path
            className="cls-4"
            d="M1007.92,498.28a0,0,0,0,0,0,0s0,0,0,0h0S1007.9,498.27,1007.92,498.28Z"
          />
          <path
            className="cls-4"
            d="M1008,509.62a0,0,0,0,0,0-.05h0A0,0,0,0,0,1008,509.62Z"
          />
          <path
            className="cls-4"
            d="M1007.47,532.29a0,0,0,0,0,0,0s0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,1007.47,532.29Z"
          />
          <path
            className="cls-4"
            d="M1006.79,543.6h0s0,0,0,0h-.05S1006.78,543.59,1006.79,543.6Z"
          />
          <path
            className="cls-4"
            d="M1005.84,554.9s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0-.06,0S1005.83,554.89,1005.84,554.9Z"
          />
          <path
            className="cls-4"
            d="M1001.39,588.62s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,1001.39,588.62Z"
          />
          <path
            className="cls-4"
            d="M999.38,599.77h0s0,0,0,0h0S999.37,599.76,999.38,599.77Z"
          />
          <path
            className="cls-4"
            d="M997.11,610.88a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,997.11,610.88Z"
          />
          <path
            className="cls-4"
            d="M994.57,621.93s0,0,0,0a.1.1,0,0,0,0,0,0,0,0,0,0-.06,0S994.56,621.92,994.57,621.93Z"
          />
          <path
            className="cls-4"
            d="M991.77,632.92s0,0,.05,0a.06.06,0,0,0,0,0s0,0,0,0A0,0,0,0,0,991.77,632.92Z"
          />
          <path
            className="cls-4"
            d="M988.72,643.84s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,988.72,643.84Z"
          />
          <path
            className="cls-4"
            d="M985.4,654.68s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,0,985.4,654.68Z"
          />
          <path
            className="cls-4"
            d="M978,676.12s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,978.05,676.07Z"
          />
          <path
            className="cls-4"
            d="M973.94,686.7s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,973.94,686.7Z"
          />
          <path
            className="cls-4"
            d="M969.62,697.18s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A.06.06,0,0,0,969.62,697.18Z"
          />
          <path
            className="cls-4"
            d="M955.19,728h0a0,0,0,0,0,0-.05s0,0,0,0S955.18,728,955.19,728Z"
          />
          <path
            className="cls-4"
            d="M949.9,738s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,949.9,738Z"
          />
          <path
            className="cls-4"
            d="M944.38,747.9s0,0,0,0,0,0,0,0a0,0,0,0,0,0,0A0,0,0,0,0,944.38,747.9Z"
          />
          <path
            className="cls-4"
            d="M938.62,757.67s0,0,0,0a0,0,0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,0,938.62,757.67Z"
          />
          <path
            className="cls-4"
            d="M932.63,767.3s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,932.63,767.3Z"
          />
          <path
            className="cls-4"
            d="M926.42,776.78s0,0,0,0a0,0,0,0,0,0,0s-.05,0-.06,0A0,0,0,0,0,926.42,776.78Z"
          />
          <path
            className="cls-4"
            d="M920,786.11h0s0,0,0,0,0,0,0,0S920,786.1,920,786.11Z"
          />
          <path
            className="cls-4"
            d="M913.33,795.29h0s0,0,0,0h0S913.32,795.28,913.33,795.29Z"
          />
          <path
            className="cls-4"
            d="M906.46,804.31a0,0,0,0,0,0,0s0,0,0,0h0S906.45,804.3,906.46,804.31Z"
          />
          <path
            className="cls-4"
            d="M899.38,813.17a0,0,0,0,0,0,0,0,0,0,0,0,0,0s0,0,0,0S899.37,813.16,899.38,813.17Z"
          />
          <path
            className="cls-4"
            d="M892.09,821.86h0v0s0,0-.06,0A.07.07,0,0,0,892.09,821.86Z"
          />
          <path
            className="cls-4"
            d="M884.6,830.37s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,884.6,830.37Z"
          />
          <path
            className="cls-4"
            d="M876.91,838.7s0,0,0,0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,876.91,838.7Z"
          />
          <path
            className="cls-4"
            d="M852.69,862.57h0a0,0,0,0,0,0-.05s0,0,0,0A0,0,0,0,0,852.69,862.57Z"
          />
          <path
            className="cls-4"
            d="M844.25,870.14h0a0,0,0,0,0,0,0s0,0,0,0A0,0,0,0,0,844.25,870.14Z"
          />
          <path
            className="cls-4"
            d="M808.77,898.36h.05a0,0,0,0,0,0-.05h0S808.76,898.35,808.77,898.36Z"
          />
          <path
            className="cls-4"
            d="M799.5,904.89s0,0,0,0a0,0,0,0,0,0,0h0A0,0,0,0,0,799.5,904.89Z"
          />
          <path
            className="cls-4"
            d="M780.5,917.26h0a0,0,0,0,0,0,0s0,0,0,0S780.49,917.25,780.5,917.26Z"
          />
          <path
            className="cls-4"
            d="M770.79,923.11h0a.1.1,0,0,0,0,0,0,0,0,0,0-.06,0S770.78,923.1,770.79,923.11Z"
          />
        </g>
        <polygon
          className="cls-4"
          points="509.42 185.72 547.12 218.57 508 248.26 509.42 185.72"
        />
        <circle className="cls-4" cx="797.35" cy="368.28" r="84.72" />
        <g>
          <path
            className="cls-4"
            d="M670.74,777.12a5.86,5.86,0,1,0,1.34,8.18A5.87,5.87,0,0,0,670.74,777.12Z"
          />
          <path
            className="cls-4"
            d="M614.31,808.11A7.37,7.37,0,1,0,604,809.79,7.38,7.38,0,0,0,614.31,808.11Z"
          />
          <path
            className="cls-4"
            d="M591.93,811.31a5.08,5.08,0,1,0-7.1,1.16A5.09,5.09,0,0,0,591.93,811.31Z"
          />
          <path
            className="cls-4"
            d="M572.41,815.33a6.64,6.64,0,1,0-9.27,1.53A6.64,6.64,0,0,0,572.41,815.33Z"
          />
          <path
            className="cls-4"
            d="M540.67,809.25a6.65,6.65,0,1,0,9.27-1.52A6.65,6.65,0,0,0,540.67,809.25Z"
          />
          <path
            className="cls-4"
            d="M530.45,817.23a6.64,6.64,0,1,0-9.27,1.52A6.65,6.65,0,0,0,530.45,817.23Z"
          />
          <path
            className="cls-4"
            d="M507,808a5.08,5.08,0,1,0,1.17,7.09A5.08,5.08,0,0,0,507,808Z"
          />
          <path
            className="cls-4"
            d="M486.65,804.69a5.86,5.86,0,1,0,1.35,8.18A5.86,5.86,0,0,0,486.65,804.69Z"
          />
          <path
            className="cls-4"
            d="M465.16,801.81a4.35,4.35,0,1,0,1,6.08A4.34,4.34,0,0,0,465.16,801.81Z"
          />
          <path
            className="cls-4"
            d="M447.11,803.25a5.86,5.86,0,1,0-8.18,1.34A5.86,5.86,0,0,0,447.11,803.25Z"
          />
          <circle className="cls-4" cx="422.5" cy="792.93" r="5.86" />
          <path
            className="cls-4"
            d="M406.71,787.21a4.35,4.35,0,1,0-6.08,1A4.33,4.33,0,0,0,406.71,787.21Z"
          />
          <path
            className="cls-4"
            d="M380.34,772.14a5.08,5.08,0,1,0,7.09-1.16A5.07,5.07,0,0,0,380.34,772.14Z"
          />
          <path
            className="cls-4"
            d="M370.59,767.23a5.08,5.08,0,1,0-7.09,1.17A5.09,5.09,0,0,0,370.59,767.23Z"
          />
          <path
            className="cls-4"
            d="M353.37,755.18a5.08,5.08,0,1,0-7.09,1.16A5.07,5.07,0,0,0,353.37,755.18Z"
          />
          <path
            className="cls-4"
            d="M336.44,741.54a4.35,4.35,0,1,0-6.08,1A4.33,4.33,0,0,0,336.44,741.54Z"
          />
          <path
            className="cls-4"
            d="M321.05,727.24a4.36,4.36,0,1,0-6.08,1A4.36,4.36,0,0,0,321.05,727.24Z"
          />
          <path
            className="cls-4"
            d="M299.73,714.12a5.86,5.86,0,1,0-1.35-8.18A5.86,5.86,0,0,0,299.73,714.12Z"
          />
          <path
            className="cls-4"
            d="M286.33,690.53a4.36,4.36,0,1,0,6.08-1A4.36,4.36,0,0,0,286.33,690.53Z"
          />
          <path
            className="cls-4"
            d="M255.12,642.17a3.58,3.58,0,1,0-.82-5A3.57,3.57,0,0,0,255.12,642.17Z"
          />
          <path
            className="cls-4"
            d="M233.34,584a5.08,5.08,0,1,0-1.17-7.1A5.09,5.09,0,0,0,233.34,584Z"
          />
          <path
            className="cls-4"
            d="M229.58,562.84a4.35,4.35,0,1,0-1-6.08A4.33,4.33,0,0,0,229.58,562.84Z"
          />
          <path
            className="cls-4"
            d="M226.4,542.6a5.08,5.08,0,1,0-1.16-7.09A5.08,5.08,0,0,0,226.4,542.6Z"
          />
          <path
            className="cls-4"
            d="M232.18,520.46a5.08,5.08,0,1,0-7.09,1.17A5.08,5.08,0,0,0,232.18,520.46Z"
          />
          <path
            className="cls-4"
            d="M230.46,498.11a2.79,2.79,0,1,0-3.89.64A2.78,2.78,0,0,0,230.46,498.11Z"
          />
          <path
            className="cls-4"
            d="M232.69,477.61a3.57,3.57,0,1,0-5,.82A3.57,3.57,0,0,0,232.69,477.61Z"
          />
          <path
            className="cls-4"
            d="M237.58,458.16a5.87,5.87,0,1,0-8.18,1.34A5.87,5.87,0,0,0,237.58,458.16Z"
          />
          <path
            className="cls-4"
            d="M240.18,436.29a3.58,3.58,0,1,0-5,.82A3.58,3.58,0,0,0,240.18,436.29Z"
          />
          <path
            className="cls-4"
            d="M246.68,416.56a4.36,4.36,0,1,0-6.08,1A4.36,4.36,0,0,0,246.68,416.56Z"
          />
          <path
            className="cls-4"
            d="M255.15,397.72a5.87,5.87,0,1,0-8.18,1.34A5.87,5.87,0,0,0,255.15,397.72Z"
          />
          <path
            className="cls-4"
            d="M262.51,377.66a4.36,4.36,0,1,0-6.08,1A4.35,4.35,0,0,0,262.51,377.66Z"
          />
          <path
            className="cls-4"
            d="M272.4,359.12a4.36,4.36,0,1,0-6.08,1A4.35,4.35,0,0,0,272.4,359.12Z"
          />
          <path
            className="cls-4"
            d="M284.14,341.72a5.08,5.08,0,1,0-7.1,1.17A5.09,5.09,0,0,0,284.14,341.72Z"
          />
          <path
            className="cls-4"
            d="M296.48,324.72a5.08,5.08,0,1,0-7.09,1.16A5.08,5.08,0,0,0,296.48,324.72Z"
          />
          <path
            className="cls-4"
            d="M340.76,279.82a5.86,5.86,0,1,0-8.18,1.35A5.86,5.86,0,0,0,340.76,279.82Z"
          />
          <path
            className="cls-4"
            d="M358.57,262.58a5.87,5.87,0,1,0-5.86-5.87A5.87,5.87,0,0,0,358.57,262.58Z"
          />
          <path
            className="cls-4"
            d="M371.62,246.1a5.09,5.09,0,1,0,5.09-5.09A5.09,5.09,0,0,0,371.62,246.1Z"
          />
          <path
            className="cls-4"
            d="M395.53,244.12a7.37,7.37,0,1,0-7.37-7.37A7.37,7.37,0,0,0,395.53,244.12Z"
          />
          <path
            className="cls-4"
            d="M406.8,228.73a8.15,8.15,0,1,0,8.15-8.15A8.15,8.15,0,0,0,406.8,228.73Z"
          />
          <path
            className="cls-4"
            d="M434.88,227.15a5.08,5.08,0,1,0-5.08-5.08A5.07,5.07,0,0,0,434.88,227.15Z"
          />
          <path
            className="cls-4"
            d="M450.14,216.8a5.09,5.09,0,1,0,5.09-5.08A5.09,5.09,0,0,0,450.14,216.8Z"
          />
          <path
            className="cls-4"
            d="M475.88,207.08a5.87,5.87,0,1,0,5.87,5.86A5.86,5.86,0,0,0,475.88,207.08Z"
          />
          <path
            className="cls-4"
            d="M490.89,210.52a5.87,5.87,0,1,0,5.87-5.86A5.87,5.87,0,0,0,490.89,210.52Z"
          />
          <path
            className="cls-4"
            d="M764.2,313.76a5.08,5.08,0,1,0,7.09-1.16A5.09,5.09,0,0,0,764.2,313.76Z"
          />
          <circle className="cls-4" cx="825.77" cy="427.98" r="8.15" />
          <circle className="cls-4" cx="830.66" cy="448.42" r="7.37" />
          <path
            className="cls-4"
            d="M840.12,473.44a7.37,7.37,0,1,0-10.28,1.69A7.36,7.36,0,0,0,840.12,473.44Z"
          />
          <path
            className="cls-4"
            d="M840.92,483.44a8.15,8.15,0,1,0,1.87,11.38A8.16,8.16,0,0,0,840.92,483.44Z"
          />
          <path
            className="cls-4"
            d="M840.17,506.3a5.87,5.87,0,1,0,1.34,8.19A5.86,5.86,0,0,0,840.17,506.3Z"
          />
          <path
            className="cls-4"
            d="M840.18,526.07a7.37,7.37,0,1,0,1.69,10.29A7.37,7.37,0,0,0,840.18,526.07Z"
          />
          <path
            className="cls-4"
            d="M837.87,547a7.37,7.37,0,1,0,1.69,10.29A7.38,7.38,0,0,0,837.87,547Z"
          />
          <path
            className="cls-4"
            d="M835.81,577.92a7.37,7.37,0,1,0-10.28,1.69A7.36,7.36,0,0,0,835.81,577.92Z"
          />
          <path
            className="cls-4"
            d="M830.65,598.29A7.37,7.37,0,1,0,820.36,600,7.36,7.36,0,0,0,830.65,598.29Z"
          />
          <path
            className="cls-4"
            d="M821.06,609.83a5.08,5.08,0,1,0,1.17,7.09A5.08,5.08,0,0,0,821.06,609.83Z"
          />
          <path
            className="cls-4"
            d="M814.94,636.84a5.86,5.86,0,1,0-8.18,1.35A5.86,5.86,0,0,0,814.94,636.84Z"
          />
          <path
            className="cls-4"
            d="M804.81,646.9a6.64,6.64,0,1,0,1.52,9.27A6.64,6.64,0,0,0,804.81,646.9Z"
          />
          <path
            className="cls-4"
            d="M794.29,665.09a6.64,6.64,0,1,0,1.52,9.27A6.66,6.66,0,0,0,794.29,665.09Z"
          />
          <path
            className="cls-4"
            d="M782.54,682.51a6.65,6.65,0,1,0,1.52,9.27A6.65,6.65,0,0,0,782.54,682.51Z"
          />
          <path
            className="cls-4"
            d="M769.15,699.71a5.86,5.86,0,1,0,1.34,8.18A5.86,5.86,0,0,0,769.15,699.71Z"
          />
          <path
            className="cls-4"
            d="M756.46,723.53a5.87,5.87,0,1,0-8.19,1.34A5.88,5.88,0,0,0,756.46,723.53Z"
          />
          <path
            className="cls-4"
            d="M741.37,738.16a5.87,5.87,0,1,0-8.18,1.34A5.87,5.87,0,0,0,741.37,738.16Z"
          />
          <path
            className="cls-4"
            d="M723.51,744.16a5.08,5.08,0,1,0,1.17,7.09A5.09,5.09,0,0,0,723.51,744.16Z"
          />
          <path
            className="cls-4"
            d="M710.21,765.46a8.15,8.15,0,1,0-11.37,1.87A8.16,8.16,0,0,0,710.21,765.46Z"
          />
          <circle className="cls-4" cx="685.82" cy="771.92" r="7.37" />
        </g>
        <circle className="cls-4" cx="669.12" cy="802.06" r="84.88" />
        <circle className="cls-4" cx="246.55" cy="676.35" r="91.65" />
        <circle className="cls-4" cx="294.14" cy="274.91" r="82.91" />
      </g>
    </S.SVG>
  );
}
