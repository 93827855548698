import styled from "styled-components";

export const Wrapper = styled.div`
  margin-bottom: 8rem;
  > h1 {
    font-weight: 500;
    font-size: 2.4rem;
    border-bottom: 0.1rem solid rgb(207, 222, 215);
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    width: 100%;
  }
`;

export const SummaryTableWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
  gap: 2rem;
  flex-wrap: wrap;
  > textarea {
    flex: 1 1 45rem;
    resize: none;
    border: none;
    background-color: ${({ theme }) => theme.colors.gray5};
    padding: 2rem;
    border-radius: 1rem;
    border: 1px solid #e5e5e5;
  }
  > div {
    flex: 1 1 45rem;
  }
`;

export const SpecificGoalsTableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
