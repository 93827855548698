import React from "react";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";

export default function EdutecGuideInNumbers() {
  return (
    <Layout hasNavigationBar name="geInNumbers">
      <Helmet title="Guia EduTec em Números" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner>
            <S.InfoDataWrapper>
              <S.BannerTitle>
                <span>GUIA EDUTEC</span> EM NÚMEROS
              </S.BannerTitle>
              <p>Dados extraídos do Guia Edutec - CIEB</p>
              <p>19/05/2022 às 17h23</p>
            </S.InfoDataWrapper>
            <S.Links>
              <ul>
                <li>
                  <a href="/">Conheça o CIEB</a>
                </li>
                <li>
                  <a href="/">Precisa de ajuda?</a>
                </li>
              </ul>
            </S.Links>
          </S.Banner>
        </S.BannerWrapper>
        <S.Content></S.Content>
      </S.Wrapper>
    </Layout>
  );
}
