import React from "react";
import * as S from "./styles";
import vision from "assets/images/ge-in-numbers/vision.svg";
import competence from "assets/images/ge-in-numbers/competence.svg";
import red from "assets/images/ge-in-numbers/red.svg";
import infra from "assets/images/ge-in-numbers/infra.svg";
import { number } from "utils/masks";
import { useMediaQuery } from "react-responsive";

const DIMENSIONS = {
  vision: { title: "VISÃO", image: vision },
  competence: { title: "COMPETÊNCIA", image: competence },
  red: { title: "RECURSOS EDUCACIONAIS DIGITAIS", image: red },
  infra: { title: "INFRAESTRUTURA", image: infra },
};

function SchoolDiagnosisChart({ dimension, data, emphasize }) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 920px)" });

  return (
    <S.Wrapper dimension={dimension} data={data} emphasize={emphasize}>
      <header>
        <img
          src={DIMENSIONS[dimension]?.image}
          alt={DIMENSIONS[dimension]?.title}
        />
        <h1>{DIMENSIONS[dimension]?.title}</h1>
      </header>

      {!isTabletOrMobile && (
        <main>
          <div>
            <div>
              <span>EME</span>
            </div>
            <div>
              <span>BÁS</span>
            </div>
            <div>
              <span>INT</span>
            </div>
            <div>
              <span>AVA</span>
            </div>
          </div>
        </main>
      )}

      <footer>
        <div>
          <div>
            <p>
              {data[0]?.percent}% <span />
            </p>
            <p>EMERGENTE (EME)</p>
            <p>
              {number.resolve(String(data[0]?.amount))} <span>ESCOLAS</span>
            </p>
          </div>
          <div>
            <p>
              {data[1]?.percent}% <span />
            </p>
            <p>BÁSICO (BÁS)</p>
            <p>
              {number.resolve(String(data[1]?.amount))} <span>ESCOLAS</span>
            </p>
          </div>
          <div>
            <p>
              {data[2]?.percent}% <span />
            </p>
            <p>INTERMEDIÁRIO (INT)</p>
            <p>
              {number.resolve(String(data[2]?.amount))} <span>ESCOLAS</span>
            </p>
          </div>
          <div>
            <p>
              {data[3]?.percent}% <span />
            </p>
            <p>AVANÇADO (AVA)</p>
            <p>
              {number.resolve(String(data[3]?.amount))} <span>ESCOLAS</span>
            </p>
          </div>
        </div>
      </footer>
    </S.Wrapper>
  );
}

export default SchoolDiagnosisChart;
