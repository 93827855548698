import { getStorageProfile } from "./local-storage";

export const isAllowedProfile = (profiles, profile = getStorageProfile()) => {
  if (!!profiles === false) return true;
  return profiles?.includes(profile);
};

export const isPrincipal = (profile = getStorageProfile()) => {
  if (profile === "principal") return true;
  return false;
};

export const isAdminCity = (profile = getStorageProfile()) => {
  if (profile === "admin_city") return true;
  return false;
};

export const isAdminState = (profile = getStorageProfile()) => {
  if (profile === "admin_state") return true;
  return false;
};
