import React, { useEffect, useState } from "react";
import * as S from "./styles";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import ReactTooltip from "react-tooltip";

function SideNavigationBar({ isExpanded, setIsExpanded, routes }) {
  const location = useLocation();

  const [state, setState] = useState(
    routes.reduce(
      (p, c) => ({
        ...p,
        [c.name]: { route: c.route, active: false, hover: false },
      }),
      {}
    )
  );

  useEffect(() => {
    setState((state) => {
      const pathName = location?.pathname;
      return {
        ...state,
        [routes.find((route) => route?.route() === pathName)?.name]: {
          active: true,
          hover: false,
        },
      };
    });
  }, [location, routes]);

  const handleMouseOver = (path) => {
    setState({
      ...state,
      [path]: { active: state[path]?.active, hover: true },
    });
  };

  const handleMouseOut = (path) => {
    setState({
      ...state,
      [path]: { active: state[path]?.active, hover: false },
    });
  };

  return (
    <S.Wrapper isExpanded={isExpanded}>
      <div>
        <S.Header isExpanded={isExpanded}>
          {isExpanded ? (
            <S.StyledXIcon onClick={() => setIsExpanded(!isExpanded)} />
          ) : (
            <S.StyledMenuIcon onClick={() => setIsExpanded(!isExpanded)} />
          )}
        </S.Header>

        <S.Content isExpanded={isExpanded}>
          <ul>
            {routes.map(
              (route, index) =>
                !route?.disabled && (
                  <React.Fragment key={index}>
                    <ReactTooltip
                      effect="solid"
                      key={`tooltip-${route?.name}`}
                      id={`item-${route?.name}`}
                      aria-haspopup="true"
                      place="right"
                      className="tooltip"
                    >
                      {route?.label}
                    </ReactTooltip>
                    <S.Item
                      data-tip
                      data-for={`item-${route?.name}`}
                      key={route?.name}
                      isExpanded={isExpanded}
                      state={state[route?.name]}
                      onMouseOver={() => handleMouseOver(route?.name)}
                      onMouseOut={() => handleMouseOut(route?.name)}
                    >
                      <Link to={route?.route()}>
                        {route?.icon &&
                          React.cloneElement(route?.icon, {
                            state: state[route?.name],
                          })}
                        <span>{route?.label}</span>
                      </Link>
                    </S.Item>
                  </React.Fragment>
                )
            )}
          </ul>
        </S.Content>
      </div>
    </S.Wrapper>
  );
}

SideNavigationBar.propTypes = {
  isExpanded: PropTypes.bool,
  setIsExpanded: PropTypes.func,
};

export default SideNavigationBar;
