import React, { useState } from "react";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import SceneRed from "assets/images/jornada-red/cena_red.svg";
import FourLeafClover from "shared-components/FourLeafClover";
import image13 from "assets/images/red-demands/013.svg";
import image14 from "assets/images/red-demands/014.svg";
import image15 from "assets/images/red-demands/015.svg";
import image16 from "assets/images/red-demands/016.svg";
import image24 from "assets/images/red-demands/024.svg";
import image25 from "assets/images/red-demands/025.svg";

import image49 from "assets/images/red-demands/049.svg";
import image50 from "assets/images/red-demands/050.svg";
import image51 from "assets/images/red-demands/051.svg";
import image52 from "assets/images/red-demands/052.svg";
import image53 from "assets/images/red-demands/053.svg";
import Modal from "shared-components/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useNavigate } from "react-router-dom";
import useScrollToElement from "hooks/use-scroll-to-element";
import { isAdminCity, isAdminState } from "utils/users";

export default function Description() {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const { goToElement } = useScrollToElement();

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
          <section>
            <p>
              Com este questionário, você irá descobrir quais tipos de
              <br /> Recursos Educacionais Digitais são mais prioritários para
              sua rede
            </p>
          </section>
        </S.BannerWrapper>

        <section>
          <div>
            <div>
              <h1>O que é este questionário?</h1>

              <p>
                Este questionário tem por objetivo sistematizar e apresentar,
                por ordem de prioridade, as principais necessidades da escola
                e/ou rede de ensino por Recursos Educacionais Digitais (REDs).
              </p>

              <p>
                Neste questionário você encontrará alguns exemplos de uso
                prático, possibilitando identificar essas necessidades de forma
                sistematizada e de fácil entendimento, integrando com outras
                ferramentas do CIEB e lhe apoiando no processo de tomada de
                decisão sobre qual o tipo de tecnologia é mais necessária para a
                rede/escola no momento.
              </p>
            </div>
            <div>
              <img src={image13} alt="" />
            </div>
          </div>
        </section>

        <section>
          <div>
            <div>
              <img src={image14} alt="" />
            </div>
          </div>
        </section>

        <section>
          <div>
            <h1>Devo consultar as escolas?</h1>
            <p>
              Para ter um melhor retrato da rede de ensino e potencializar os
              resultados e impactos da tecnologia escolhida, orientamos o gestor
              a enviar o questionário para todas as escolas da sua rede e
              consultar a opinião dos diretores. No entanto entendemos que nem
              sempre há tempo hábil para fazer isso, por isso disponibilizamos o
              questionário para ser respondido apenas pelo gestor de forma
              centralizada. Mas sugerimos que ele seja respondido junto com
              outras pessoas da secretaria.
            </p>
          </div>
        </section>

        <section>
          <div>
            <div>
              <img src={image15} alt="" />
              <p>COM CONSULTA ÀS ESCOLAS</p>
              <button onClick={() => goToElement("consultationWithSchools")}>
                INICIAR
              </button>
            </div>
            <div>
              <span>EM BREVE</span>
              <img src={image16} alt="" />
              <p>SEM CONSULTA ÀS ESCOLAS</p>
              <button>INICIAR</button>
            </div>
          </div>
        </section>

        <section>
          <div>
            <p>
              Fique tranquilo! Independente da opção escolhida, consultando ou
              não as escolas, você poderá voltar a qualquer momento e optar por
              outro formato sem perder suas respostas anteriores.
            </p>
          </div>
        </section>

        <section id="consultationWithSchools">
          <div>
            <div>
              <img src={image24} alt="" />
              <h1>ANTES DE ENVIAR PARA AS ESCOLAS</h1>
              <p>
                Antes de enviar o questionário para os diretores das escolas da
                sua rede você deverá escolher a(s) melhor(es) estratégias de
                comunicação para realmente engajá-los a preencherem e assim
                conseguir um alto índice de respostas. Veja as opções que você
                possui para comunicar esta ação de forma efetiva para os
                diretores.
              </p>
            </div>

            <div>
              <div>
                <h1>Material de divulgação</h1>
                <p>
                  Aqui você encontra opções de cards para baixar e assim
                  divulgar o formulário
                </p>
                <ul>
                  <li>Cards imagem PPT com data customizável</li>
                  <li>Cards com conceitos resumidos</li>
                </ul>
                <a
                  href="https://drive.google.com/drive/u/1/folders/1YAnew6dYLAjKFExzm2yAJuLIc7Fcc5Tv"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={image50} alt="" />
                </a>
              </div>
              <div>
                <h1>Material de orientação</h1>
                <p>
                  Aqui você encontra gifs e pequenos vídeos para orientar o
                  preenchimento do formulário e também com informações sobre os
                  conceitos teóricos da{" "}
                  <strong>Ferramenta de Identificação de Demanda</strong>
                </p>
                <a href="/">
                  <img src={image49} alt="" />
                </a>
              </div>
            </div>

            <div>
              <div>
                <h1>Material de comunicação</h1>
                <p>
                  Depois de escolher o material de comunicação, você também pode
                  selecionar o meio de envio:
                </p>
              </div>
              <div>
                <CopyToClipboard text="https://plataforma.guiaedutec.com.br/demanda-de-red/escola">
                  <div
                    onClick={() => {
                      setIsOpen(true);
                    }}
                  >
                    <img src={image53} alt="" />
                    <p>Copie e cole o link do formulário</p>
                  </div>
                </CopyToClipboard>
                <div>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://api.whatsapp.com/send?text=A%20Secretaria%20de%20Educa%C3%A7%C3%A3o%20est%C3%A1%20realizando%20o%20levantamento%20da%20demanda%20de%20Recursos%20Educacionais%20Digitais%20nas%20escolas%20da%20rede.%20%0A%20%0APara%20acessar%20a%20ferramenta%20e%20registrar%20as%20respostas%20da%20sua%20escola%2C%20siga%20estes%20passos%3A%0A%F0%9F%94%B9%20Acesse%20https%3A%2F%2Fplataforma.guiaedutec.com.br%2Fdemanda-de-red%2Fescola%0A%F0%9F%94%B9%20Registre-se%20%28se%20for%20o%20primeiro%20acesso%29%20ou%20fa%C3%A7a%20login%0A%F0%9F%94%B9%20Responda%20ao%20question%C3%A1rio%0A%0ASe%20tiver%20alguma%20d%C3%BAvida%20durante%20o%20preenchimento%2C%20basta%20clicar%20em%20_Preciso%20de%20Ajuda_%20na%20plataforma"
                  >
                    <img src={image52} alt="" />

                    <p>Envie a seus contatos por meio do WhatsApp</p>
                  </a>
                </div>
                <div>
                  <img src={image51} alt="" />
                  <p>Envie a seus contatos por meio do Email</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <S.JourneySection>
          <div>
            <div>
              <FourLeafClover width={140} dimension="red" />
              <h1>Conheça a Jornada completa da dimensão RED</h1>
              <p>
                Confira conceitos, ferramentas e dicas para desenvolver Escolas
                Conectadas.
              </p>
            </div>
            <div>
              <img src={SceneRed} alt="" />
            </div>
          </div>
        </S.JourneySection>
        <Modal isOpen={isOpen} closeModal={() => setIsOpen(false)}>
          <S.LinkModal>
            <img src={image25} alt="" />
            <h2>Link copiado!</h2>

            <p>https://plataforma.guiaedutec.com.br/demanda-de-red/escola</p>

            <button
              onClick={() => {
                setIsOpen(false);
                navigate(
                  isAdminState() || isAdminCity()
                    ? "/demanda-de-red/resultados-da-rede"
                    : "/demanda-de-red/resultados-da-escola"
                );
              }}
            >
              ACOMPANHE AS RESPOSTAS E ACESSE A DEVOLUTIVA
            </button>
          </S.LinkModal>
        </Modal>
      </S.Wrapper>
    </Layout>
  );
}
