import styled, { css } from "styled-components";
import backgroundMap from "../../assets/images/home/background-map.jpg";
import { container } from "../../global-styles/shared-styles";
import media from "styled-media-query";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

const COLORS = {
  vision: "#d8bb27",
  competence: "#b30063",
  red: "#176eab",
  infra: "#50792a",
};

const wrapperModifiers = {
  generalStylesIcons: css`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textDarkGray};
    &&& {
      font-size: 4rem;
    }
    &:hover {
      opacity: 0.8;
    }
  `,
};

export const RestartAlt = styled(RestartAltIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const ZoomIn = styled(ZoomInIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const ZoomOut = styled(ZoomOutIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const Header = styled.header`
  ${container};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18rem 1rem 12rem 1rem;
  gap: 3rem;
  flex-wrap: wrap;

  ${media.lessThan("large")`
    padding: 14rem 1rem 11rem 1rem;
    gap: 4.3rem;
  `}

  > div:nth-child(1) {
    width: 69rem;
    > video {
      object-fit: contain;
      width: 100%;
      height: auto;
    }
  }

  > div:nth-child(2) {
    width: 50rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.3rem;
    ${media.lessThan("large")`
      align-items: center;
    `}
  }
`;

export const Main = styled.main`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.gray3};
`;

export const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.green};
  font-size: 1.9rem;
  font-weight: 500;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 3.6rem;
  font-weight: 500;
  ${media.lessThan("large")`
    text-align: center;
  `}
`;

export const SubTitle = styled.p`
  color: ${({ theme }) => theme.colors.black};
  font-size: clamp(1.7rem, 1.3vw, 1.9rem);
  font-weight: 300;
  font-style: italic;
  width: 76%;
  ${media.lessThan("large")`
    text-align: center;
  `}
`;

export const ConnectedSchoolSection = styled.section`
  ${container};
  display: flex;
  flex-wrap: wrap;
  padding: 2rem 5rem;
  align-items: center;
  justify-content: center;

  ${media.lessThan("small")`
    padding: 0rem 0rem 2rem 0rem;
  `}

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    gap: 3rem;
    padding: 13rem 4.5rem;
    width: 56rem;

    ${media.lessThan("small")`
      padding: 10rem 2rem 5rem 2rem;
    `}

    > div {
      ${media.lessThan("large")`
        text-align: center;
      `}
      > h3 {
        font-size: 3.6rem;
        font-weight: 300;
      }

      > h1 {
        font-size: 4.8rem;
        font-weight: 500;
      }
    }

    > p {
      font-size: clamp(1.6rem, 1.3vw, 1.9rem);
      text-align: left;
      font-weight: 300;
      line-height: 1.5;

      > span {
        font-weight: 500;
      }
      > span:nth-child(1),
      > span:nth-child(2),
      > span:nth-child(3) {
        color: ${({ theme }) => theme.colors.yellow6};
      }

      > span:nth-child(4) {
        color: ${({ theme }) => theme.colors.pink};
      }

      > span:nth-child(5) {
        color: ${({ theme }) => theme.colors.blue};
      }

      > span:nth-child(6) {
        color: ${({ theme }) => theme.colors.green};
      }

      ${media.lessThan("large")`
        text-align: center;
      `}
    }

    > p:last-child {
      font-weight: 500;
      font-style: italic;
      line-height: 1.5;
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 3rem;
    width: 60rem;

    > img {
      object-fit: cover;
      width: 100%;
    }
  }
`;

export const CallToAction = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 13rem 15rem;
  gap: 1rem;

  ${media.lessThan("large")`
    padding: 9rem 10rem 1rem 10rem;
  `}

  > div:nth-child(1) {
    width: 62rem;
    > img {
      object-fit: contain;
    }

    ${media.lessThan("huge")`
      width: 55rem;
    `}
  }

  > div:nth-child(2) {
    width: 40rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10rem 0rem;
    gap: 2rem;
    > h3 {
      font-size: 3.4rem;
      font-weight: 300;
    }

    > h1 {
      font-size: 3.8rem;
      font-weight: 500;
    }
  }
`;

export const DimensionsSection = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  gap: 6rem;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  padding: 9rem 7rem 16rem 7rem;
  width: 100%;

  ${media.lessThan("small")`
    padding: 0rem 0rem 7rem 0rem;
  `}

  > section:nth-child(1) {
    ${container};
    display: flex;
    gap: 3rem;
    padding: 2rem 0rem;
    margin-bottom: 2rem;

    > div:nth-child(1) {
      width: 7rem;
      > img {
        width: 100%;
        object-fit: contain;
      }
    }

    > div:nth-child(2) {
      > h1 {
        font-size: 4rem;
        font-weight: 500;
        margin-bottom: 0.4rem;
      }

      > p {
        font-size: 1.7rem;
        font-style: italic;
        text-align: center;
        font-weight: 300;
      }
    }
  }

  > section:nth-child(2) {
    ${container};
    display: grid;
    place-items: center;
    grid-template-areas:
      "BV FLC BR"
      "BC FLC BI";
    grid-row-gap: 1.5rem;
    grid-column-gap: 1.5rem;

    ${media.lessThan("large")`
      grid-template-areas:
        "BV BC"
        "FLC FLC"
        "BR BI";
      grid-row-gap: 5rem;
      grid-column-gap: 5rem;
    `}

    ${media.lessThan("medium")`
      grid-template-areas:
        "BV" 
        "BC"
        "FLC"
        "BR" 
        "BI";
      grid-row-gap: 4.5rem;
      grid-column-gap: 4.5rem;
    `}

    .box-vision {
      grid-area: BV;
    }

    .box-red {
      grid-area: BR;
    }

    .box-competence {
      grid-area: BC;
    }

    .box-infra {
      grid-area: BI;
    }

    .four-leaf-clover {
      grid-area: FLC;
    }
  }
`;

export const OrbitalSection = styled.section`
  background-image: url(${backgroundMap});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-bottom: 5rem;
  > div:nth-child(1) {
    ${container};
    display: flex;
    align-items: center;
    gap: 3rem;
    padding: 5rem 5rem;

    > div:nth-child(1) {
      width: 7rem;
      > img {
        width: 100%;
        object-fit: contain;
      }
    }

    > div:nth-child(2) {
      padding-top: 1.5rem;
      > h3 {
        font-size: 3.9rem;
        font-weight: 300;
      }

      > h1 {
        font-size: 4.7rem;
        font-weight: 500;
      }
    }
  }

  > div:nth-child(2) {
    ${container};
    position: relative;
    padding: 6.4rem;
    margin: 0 auto;
    height: 300rem;

    > p {
      position: absolute;
      right: 37rem;
      top: 28.3rem;
      width: 17rem;
      font-size: 1.3rem;
      font-weight: 300;
      z-index: 1;

      ${media.lessThan("huge")`
        right: 29rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    > img:nth-child(1) {
      width: 28rem;
      position: absolute;
      right: 34rem;
      top: 22.5rem;

      ${media.lessThan("huge")`
        right: 26rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    > img:nth-child(2) {
      width: 28rem;
      position: absolute;
      right: 14rem;
      top: 20.1rem;

      ${media.lessThan("huge")`
        right: 6.5rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    > img:nth-child(3) {
      width: 32rem;
      position: absolute;
      left: 28rem;
      top: 95rem;

      ${media.lessThan("huge")`
        left: 22rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    > img:nth-child(4) {
      width: 33rem;
      position: absolute;
      right: 30rem;
      top: 165rem;

      ${media.lessThan("huge")`
        right: 22rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    > img:nth-child(5) {
      width: 28rem;
      position: absolute;
      left: 34rem;
      top: 238rem;

      ${media.lessThan("huge")`
        left: 25rem;
      `}

      ${media.lessThan("large")`
        display: none;
      `}
    }

    #visao {
      position: absolute;
      top: 0;
      left: 5rem;

      ${media.lessThan("huge")`
        left: 2.5rem;
      `}
    }

    #competencia {
      position: absolute;
      top: 70rem;
      right: 5rem;

      ${media.lessThan("huge")`
        right: 4rem;
      `}
    }

    #red {
      position: absolute;
      top: 140rem;
      left: -6rem;
    }

    #infraestrutura {
      position: absolute;
      top: 217rem;
      right: -6rem;
    }

    > div {
      ${media.lessThan("large")`
      left: 50% !important;
      transform: translateX(-50%);
    `}
    }
  }
`;

export const SchoolSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-self: center;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 6rem 0rem;
  .tools {
    padding: 5rem 6rem 1rem 16rem;
  }

  div {
    ${container};
    width: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-self: center;
  }
  > img {
    width: 100%;
    object-fit: contain;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray3};
  justify-content: center;
  width: 27rem;
  height: 22rem;

  ${media.greaterThan("huge")`
    width: 30rem;
    font-size: 1.4rem;
  `}

  border-radius: 1rem;
  padding: 3.1rem 2.1rem 3.1rem 3rem;
  gap: 1rem;
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  > div {
    width: 3.5rem;
    > img {
      width: 100%;
      object-fit: cover;
    }
  }

  > h1 {
    font-size: 1.1rem;
    font-size: 500;
    color: ${({ dimension }) => COLORS[dimension]};
  }

  > p {
    font-size: 1.3rem;
    text-align: left;
    font-weight: 300;
  }
`;

export const Image = styled.img`
  width: 15rem;
`;
