import React, { useEffect } from "react";
import Helmet from "react-helmet";
import * as S from "./styles.js";
import Layout from "shared-components/Layout";
import CircularProgress from "@mui/material/CircularProgress";
import FollowUpTable from "../../components/FollowUpTable";
import {
  useInstitutionQuery,
  useResultAvgQuery,
  useResultPerDimensionQuery,
  useSurveyAnswersCicleQuery,
} from "services/common.js";
// import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";
import image46 from "assets/images/plans/046.svg";
import image20 from "assets/images/plans/follow-up-table/020.svg";
import image21 from "assets/images/plans/follow-up-table/021.svg";
import image22 from "assets/images/plans/follow-up-table/022.svg";
import image23 from "assets/images/plans/follow-up-table/023.svg";
import image26 from "assets/images/plans/26.svg";
import image25 from "assets/images/plans/25.svg";

// import useScrollToElement from "hooks/use-scroll-to-element.js";

import { useNavigate, useParams } from "react-router-dom";
import DimensionsTable from "shared-components/DimensionsTable";
import DimensionsChart from "shared-components/DimensionsChart";
import { useCalcInfraDashboardQuery } from "services/infra-calculator.js";
import EquipmentsTable from "pages/Plans/components/EquipmentsTable";
import { useForm } from "react-hook-form";
import NetworkDataTable from "pages/Plans/components/NetworkDataTable";

import dayjs from "dayjs";
import { usePlans } from "hooks/use-plans.js";

export default function FollowUp() {
  // const { goToElement } = useScrollToElement();
  const { plan } = useParams();
  const navigate = useNavigate();
  const {
    data: institution,
    // isLoading: institutionLoading,
    isSuccess: institutionSuccess,
  } = useInstitutionQuery();

  const {
    register,
    setValue,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { getCurrentPlan } = usePlans();

  useEffect(() => {
    if (institutionSuccess) {
      const currentPlan = getCurrentPlan(plan);
      const network = currentPlan?.general_data?.network;
      setValue("network", network);
    }
  }, [institutionSuccess, getCurrentPlan, setValue, plan]);

  const {
    data: surveyAnswers,
    isLoading: surveyAnswersLoading,
    isSuccess: surveyAnswersSuccess,
  } = useSurveyAnswersCicleQuery(
    getCurrentPlan(plan)?.setting_plan?.survey_id,
    getCurrentPlan(plan)?.setting_plan?.survey_schedule_id
  );

  const {
    data: resultsAvg,
    isLoading: resultsAvgLoading,
    isSuccess: resultsAvgSuccess,
  } = useResultAvgQuery(
    getCurrentPlan(plan)?.setting_plan?.survey_id,
    getCurrentPlan(plan)?.setting_plan?.survey_schedule_id
  );

  const {
    data: resultPerDimension,
    isLoading: resultPerDimensionLoading,
    isSuccess: resultPerDimensionSuccess,
  } = useResultPerDimensionQuery(
    getCurrentPlan(plan)?.setting_plan?.survey_id,
    getCurrentPlan(plan)?.setting_plan?.survey_schedule_id
  );

  const {
    data: calcInfraDashboard,
    isLoading: calcInfraDashboardLoading,
    isSuccess: calcInfraDashboardSuccess,
  } = useCalcInfraDashboardQuery(
    getCurrentPlan(plan)?.infra?.survey_schedule_id,
    getCurrentPlan(plan)?.infra?.stage
  );

  useEffect(() => {
    if (institutionSuccess) {
      if (institution?.plans?.length === 0) {
        navigate("/planos");
      }
    }
  }, [institutionSuccess, navigate, institution]);

  // const tasks = useMemo(() => {
  //   if (institutionSuccess && institution?.plans) {
  //     let actionsVision = [];

  //     getCurrentPlan(plan)?.vision?.goals.forEach((goal) => {
  //       actionsVision = [...actionsVision, ...goal?.actions];
  //     });

  //     actionsVision = actionsVision.map((action, index) => {
  //       if (!!action?.start && !!action?.end) {
  //         return {
  //           id: `O-${action?.goal_index + 1}-V-${index + 1}`,
  //           name: `O-${action?.goal_index + 1}-V-${index + 1}`,
  //           key: `V-O-${action?.goal_index + 1}`,
  //           start: new Date(action?.start),
  //           end: new Date(action?.end),
  //           styles: { backgroundColor: "#fad62b" },
  //         };
  //       }

  //       return null;
  //     });

  //     let actionsCompetence = [];
  //     getCurrentPlan(plan)?.competence?.goals.forEach((goal) => {
  //       actionsCompetence = [...actionsCompetence, ...goal?.actions];
  //     });

  //     actionsCompetence = actionsCompetence.map((action, index) => {
  //       if (!!action?.start && !!action?.end) {
  //         return {
  //           id: `O-${action?.goal_index + 1}-C-${index + 1}`,
  //           name: `O-${action?.goal_index + 1}-C-${index + 1}`,
  //           key: `C-O-${action?.goal_index + 1}`,
  //           start: new Date(action?.start),
  //           end: new Date(action?.end),
  //           styles: { backgroundColor: "#e62270" },
  //         };
  //       }

  //       return null;
  //     });

  //     let actionsRed = [];
  //     getCurrentPlan(plan)?.red?.goals.forEach((goal) => {
  //       actionsRed = [...actionsRed, ...goal?.actions];
  //     });

  //     actionsRed = actionsRed.map((action, index) => {
  //       if (!!action?.start && !!action?.end) {
  //         return {
  //           id: `O-${action?.goal_index + 1}-R-${index + 1}`,
  //           name: `O-${action?.goal_index + 1}-R-${index + 1}`,
  //           key: `R-O-${action?.goal_index + 1}`,
  //           start: new Date(action?.start),
  //           end: new Date(action?.end),
  //           styles: { backgroundColor: "#33a1c8" },
  //         };
  //       }
  //       return null;
  //     });

  //     let actionsInfra = [];
  //     getCurrentPlan(plan)?.infra?.goals.forEach((goal) => {
  //       actionsInfra = [...actionsInfra, ...goal?.actions];
  //     });

  //     actionsInfra = actionsInfra.map((action, index) => {
  //       if (!!action?.start && !!action?.end) {
  //         return {
  //           id: `O-${action?.goal_index + 1}-I-${index + 1}`,
  //           name: `O-${action?.goal_index + 1}-I-${index + 1}`,
  //           key: `I-O-${action?.goal_index + 1}`,
  //           start: new Date(action?.start),
  //           end: new Date(action?.end),
  //           styles: { backgroundColor: "#85c440" },
  //         };
  //       }
  //       return null;
  //     });

  //     const tasks = [
  //       ...actionsVision,
  //       ...actionsCompetence,
  //       ...actionsRed,
  //       ...actionsInfra,
  //     ];

  //     return tasks.filter((task) => task !== null);
  //   } else {
  //     return [];
  //   }
  // }, [getCurrentPlan, institutionSuccess, institution]);

  return (
    <Layout hasNavigationBar name="plans">
      <Helmet title="Guia EduTec | Planos - Dados da Rede" />

      <S.Wrapper>
        <S.WrapperBanner>
          <S.Banner />
        </S.WrapperBanner>

        <S.Contents>
          <S.TermPlanWrapper>
            <S.GeneralInfo>
              <img alt="Rede de ensino" src={image25} />
              <div>
                <h1>Nome da Rede</h1>
                <p>{institution?.name}</p>
              </div>
            </S.GeneralInfo>
            <S.GeneralInfo>
              <img alt="Vigência do Plano" src={image26} />
              <div>
                <h1>Vigência do Plano</h1>
                <p>
                  {dayjs(
                    getCurrentPlan(plan)?.setting_plan?.validity?.start
                  ).format("DD/MM/YYYY")}{" "}
                  -{" "}
                  {dayjs(
                    getCurrentPlan(plan)?.setting_plan?.validity?.end
                  ).format("DD/MM/YYYY")}
                </p>
              </div>
            </S.GeneralInfo>
          </S.TermPlanWrapper>

          <S.NetworkTableFollowUpWrapper>
            <S.TitleWrapper>
              <S.Title>DADOS DA REDE</S.Title>
            </S.TitleWrapper>

            <NetworkDataTable
              register={register}
              getValues={getValues}
              title="CONTEMPLADO"
              type="contemp"
              isEditable={false}
              setValue={setValue}
              setError={setError}
              errors={errors}
              clearErrors={clearErrors}
            />
          </S.NetworkTableFollowUpWrapper>

          <S.MainGoalWrapper>
            <S.TitleWrapper>
              <S.Title>OBJETIVO GERAL DO PLANO</S.Title>
            </S.TitleWrapper>
            <div>
              <p>{getCurrentPlan(plan)?.setting_plan?.main_goal}</p>
            </div>
          </S.MainGoalWrapper>

          <S.DimensionsTableWrapper>
            <S.TitleWrapper>
              <S.Title>
                RELATÓRIO DIAGNÓSTICO DO NÍVEL DE ADOÇÃO DE TECNOLOGIA NAS
                ESCOLAS
              </S.Title>
              <S.SubTitle>
                <em>
                  Como está a sua rede de ensino em relação à adoção de
                  tecnologias?
                </em>
              </S.SubTitle>
            </S.TitleWrapper>
            <DimensionsTable
              results={surveyAnswers}
              resultsAvg={resultsAvg?.length > 0 ? resultsAvg[0] : {}}
              resultsAvgLoading={resultsAvgLoading}
              resultsAvgSuccess={resultsAvgSuccess}
              surveyAnswersLoading={surveyAnswersLoading}
              surveyAnswersSuccess={surveyAnswersSuccess}
              yearOfCycle={getCurrentPlan(plan)?.setting_plan?.year_of_cycle}
            />
          </S.DimensionsTableWrapper>

          <S.DimensionsChartWrapper>
            {!resultPerDimensionLoading ? (
              <>
                <DimensionsChart
                  dimension="vision"
                  dimensionData={resultPerDimension}
                  resultPerDimensionLoading={resultPerDimensionLoading}
                  resultPerDimensionSuccess={resultPerDimensionSuccess}
                  title="VISÃO"
                />

                <DimensionsChart
                  dimension="competence"
                  dimensionData={resultPerDimension}
                  resultPerDimensionLoading={resultPerDimensionLoading}
                  resultPerDimensionSuccess={resultPerDimensionSuccess}
                  title="COMPETÊNCIA/FORMAÇÃO"
                />

                <DimensionsChart
                  dimension="red"
                  dimensionData={resultPerDimension}
                  resultPerDimensionLoading={resultPerDimensionLoading}
                  resultPerDimensionSuccess={resultPerDimensionSuccess}
                  title="RECURSOS EDUCACIONAIS DIGITAIS"
                />

                <DimensionsChart
                  dimension="infra"
                  dimensionData={resultPerDimension}
                  resultPerDimensionLoading={resultPerDimensionLoading}
                  resultPerDimensionSuccess={resultPerDimensionSuccess}
                  title="INFRAESTRUTURA"
                />
              </>
            ) : (
              <CircularProgress size={30} />
            )}
          </S.DimensionsChartWrapper>

          {/* <S.GanttChartWrapper>
            <S.TitleWrapper>
              <S.Title>ACOMPANHAMENTO ANUAL - Visão geral</S.Title>
            </S.TitleWrapper>

            {institutionLoading && !!institution?.plans ? (
              <CircularProgress size={30} />
            ) : (
              institutionSuccess &&
              tasks.length !== 0 && (
                <S.StyledGantt
                  locale="pt-BR"
                  listCellWidth=""
                  onDoubleClick={(event) => {
                    goToElement(event.key);
                  }}
                  tasks={tasks}
                />
              )
            )}

            {tasks.length === 0 && (
              <div>
                <p>Não há ações a serem exibidas.</p>
              </div>
            )}
          </S.GanttChartWrapper> */}

          <S.FollowUpTableWrapper>
            <S.TitleWrapper>
              <S.Title>OBJETIVOS ESPECÍFICOS E AÇÕES</S.Title>
            </S.TitleWrapper>
            {getCurrentPlan(plan)?.vision?.goals?.length === 0 &&
              getCurrentPlan(plan)?.competence?.goals?.length === 0 &&
              getCurrentPlan(plan)?.red?.goals?.length === 0 &&
              getCurrentPlan(plan)?.infra?.goals?.length === 0 && (
                <div>
                  <p>Não há objetivos a serem exibidos.</p>
                </div>
              )}
            <div>
              {getCurrentPlan(plan)?.vision?.goals?.map((goal, index) => (
                <FollowUpTable
                  key={`goal-vision-${index}`}
                  dimension="vision"
                  title="VISÃO"
                  data={goal}
                  goalIndex={index}
                  goalId={goal?.id}
                  image={image20}
                  id={`V-O-${index + 1}`}
                />
              ))}

              {getCurrentPlan(plan)?.competence?.goals?.map((goal, index) => (
                <FollowUpTable
                  key={`goal-competence-${index}`}
                  dimension="competence"
                  title="COMPETÊNCIA"
                  data={goal}
                  goalIndex={index}
                  goalId={goal?.id}
                  image={image21}
                  id={`C-O-${index + 1}`}
                />
              ))}

              {getCurrentPlan(plan)?.red?.goals?.map((goal, index) => (
                <FollowUpTable
                  key={`goal-red-${index}`}
                  dimension="red"
                  title="RED"
                  data={goal}
                  goalIndex={index}
                  goalId={goal?.id}
                  image={image22}
                  id={`R-O-${index + 1}`}
                />
              ))}

              {getCurrentPlan(plan)?.infra?.goals?.map((goal, index) => (
                <FollowUpTable
                  key={`goal-infra-${index}`}
                  dimension="infra"
                  title="INFRAESTRUTURA"
                  data={goal}
                  goalIndex={index}
                  goalId={goal?.id}
                  image={image23}
                  id={`I-O-${index + 1}`}
                />
              ))}
            </div>
          </S.FollowUpTableWrapper>

          {calcInfraDashboard?.data[getCurrentPlan(plan)?.infra?.level] ? (
            <S.EquipmentsTableWrapper>
              <S.TitleWrapper>
                <S.Title>
                  EQUIPAMENTOS DE INFRAESTRUTURA A SEREM ADQUIRIDOS PELA REDE
                </S.Title>
              </S.TitleWrapper>
              {calcInfraDashboardSuccess ? (
                <EquipmentsTable
                  rows={
                    calcInfraDashboard?.data[getCurrentPlan(plan)?.infra?.level]
                      ?.total
                  }
                  calcInfraDashboardLoading={calcInfraDashboardLoading}
                  calcInfraDashboardSuccess={calcInfraDashboardSuccess}
                  columns={[
                    "EQUIPAMENTOS QUE SERÃO ADQUIRIDOS",
                    "QUANTIDADE",
                    "VALOR TOTAL",
                  ]}
                />
              ) : (
                <S.ErrorMessage>
                  <S.ReportProblem />
                  <p>Um erro inesperado aconteceu !</p>
                  <p>
                    Caso o erro continue entre em contato com o email:{" "}
                    <a
                      href="mailto:guiaedutec@cieb.net.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      guiaedutec@cieb.net.br
                    </a>
                  </p>
                </S.ErrorMessage>
              )}
            </S.EquipmentsTableWrapper>
          ) : (
            <S.EquipmentsTableWrapper>
              <S.TitleWrapper>
                <S.Title>
                  EQUIPAMENTOS DE INFRAESTRUTURA A SEREM ADQUIRIDOS PELA REDE
                </S.Title>
              </S.TitleWrapper>
              <p>
                Na dimensão de INFRAESTRUTURA selecione os filtros que
                possibilitem a visualização dos dados.
              </p>
            </S.EquipmentsTableWrapper>
          )}
          {/* <S.PrintWrapper>
            <button onClick={window.print}>
              <S.StyledPrintIcon />
              IMPRIMIR
            </button>
          </S.PrintWrapper> */}

          <S.Reminder>
            <S.TitleWrapper>
              <div>
                <img src={image46} alt="Vigência do Planejamento" />
                <S.Title>Vigência do Planejamento</S.Title>
              </div>
            </S.TitleWrapper>
            <div>
              <p>
                O planejamento construído nesta plataforma pode servir de
                subsídios para que a rede elabore o Plano Anual de Compras.
                Confira neste link mais informações.
              </p>
            </div>
          </S.Reminder>

          <S.ButtonWrapper>
            <button
              onClick={() =>
                navigate("/planos/infraestrutura", { replace: true })
              }
            >
              VOLTAR
            </button>
          </S.ButtonWrapper>
        </S.Contents>
      </S.Wrapper>
    </Layout>
  );
}
