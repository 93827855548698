import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { ReactComponent as vision } from "assets/images/menu/vision.svg";
import { ReactComponent as competence } from "assets/images/menu/competence.svg";
import { ReactComponent as red } from "assets/images/menu/red.svg";
import { ReactComponent as infra } from "assets/images/menu/infra.svg";
import { ReactComponent as trapezio } from "assets/images/menu/trapezio.svg";

const COLORS = {
  vision: "yellow",
  competence: "pink",
  red: "blue",
  infra: "green",
};

const COLORS2 = {
  vision: "yellow2",
  competence: "pink2",
  red: "blue2",
  infra: "green2",
};

const COLORS3 = {
  vision: "yellow3",
  competence: "pink3",
  red: "blue3",
  infra: "green3",
};

const top = (scrollY) => (scrollY !== 0 ? "0rem" : "14.5rem");

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  gap: 1.5rem;

  > section {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 1;
    top: 14.5rem;
    top: ${({ scrollY }) => top(scrollY)};
    left: 0;
    margin: auto;
  }
`;

export const MenuDimension = styled.div`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.gray4};
  min-width: 27rem;
  z-index: 3;
  display: flex;
  flex-direction: column;
`;

export const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  padding: 1.5rem 1.1rem;
  > h3 {
    font-size: 1.1rem;
    font-weight: 500;
  }

  > img {
    width: 4rem;
  }
`;

export const MenuBody = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 27rem;
  border: 0.01px solid ${({ theme }) => theme.colors.gray3};
`;

export const Header = styled.div``;

export const MenuStep = styled.details`
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 1;
`;

export const MenuStepTitle = styled.summary`
  background-color: ${({ theme, dimension }) =>
    theme.colors[COLORS[dimension]]};
  padding: 0rem 1.5rem 0rem 0rem;
  color: ${({ theme, dimension }) =>
    dimension === "vision" ? theme.colors.black : theme.colors.white};
  font-size: 1.15rem;
  z-index: 1;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items:center; */
  gap: 1.5rem;
  height: 7.5rem;
  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 4.6rem;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1;
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
  }
`;

export const StepNumber = styled.div`
  width: 4.75rem;
  flex: 0.5;
  background-color: ${({ theme, dimension }) =>
    theme.colors[COLORS2[dimension]]};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.7rem;
  font-weight: 500;
  font-family: ${({ theme }) => theme.fonts.openSansLight}, sans-serif;
`;

export const MenuSubStep = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  padding-right: 1.5rem;
  padding-left: ${({ indentation }) => (indentation ? "8rem" : "4rem")};
  p {
    font-size: 1.3rem;
    font-weight: 500;
  }

  z-index: 1;
  border-bottom: 0.01rem solid ${({ theme }) => theme.colors.gray3};
  background-color: ${({ theme }) => theme.colors.white};
`;

export const ArrowDropDown = styled(ArrowDropDownIcon)`
  ${({ open }) => !open && "transform: rotate(180deg);"}
`;

export const VisionIcon = styled(vision)`
  width: 4.6rem;
`;

export const CompetenceIcon = styled(competence)`
  width: 4.6rem;
`;

export const RedIcon = styled(red)`
  width: 4.6rem;
`;

export const InfraIcon = styled(infra)`
  width: 4.6rem;
`;

export const TrapezioIcon = styled(trapezio)`
  flex: 0.5;
  .trapezio {
    fill: ${({ dimension, theme }) => theme.colors[COLORS3[dimension]]};
  }
`;
