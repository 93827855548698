import React, { useEffect, useState } from "react";
import * as S from "./styles";

function Tab1({ dimension, dimensionData }) {
  const [velocimeter, setVelocimeter] = useState();

  useEffect(() => {
    import(
      `assets/images/plans/velocimetro/${dimension[0]}_level_${dimensionData["level"]}.svg`
    ).then((image) => setVelocimeter(image.default));
  }, [dimension, dimensionData]);

  return (
    <section>
      <S.BoxDescription dimension={dimension}>
        <div
          className="description_area first"
          dangerouslySetInnerHTML={{ __html: dimensionData?.feedback[0] }}
        />
        {velocimeter && (
          <img alt="" className="speedometer" src={velocimeter} />
        )}
      </S.BoxDescription>
    </section>
  );
}

function Tab2({ dimensionData, dimension }) {
  return (
    <S.BoxDescription dimension={dimension}>
      <div
        className="description_area"
        dangerouslySetInnerHTML={{ __html: dimensionData?.feedback[1] }}
      />
    </S.BoxDescription>
  );
}

function Tab3({ dimension, dimensionData }) {
  return (
    <section>
      <S.BoxDescription dimension={dimension}>
        <div
          className="description_area"
          dangerouslySetInnerHTML={{ __html: dimensionData?.feedback[2] }}
        />
        <div
          className={`box box-${dimension}`}
          dangerouslySetInnerHTML={{ __html: dimensionData?.feedback[3] }}
        />
      </S.BoxDescription>
    </section>
  );
}

function Message() {
  return (
    <S.Wrapper>
      <S.MessageWrapper>
        <p>Caro/a gestor/a,</p>
        <p>
          A sua rede ainda não realizou o diagnóstico do nível de adoção de
          tecnologias nas escolas.
        </p>
        <p>
          Antes de iniciar a construção do PIT, é recomendado que as escolas da
          rede respondam o questionário sobre o nível de adoção de tecnologia em
          quatro dimensões: visão, competência, recursos educacionais digitais e
          infraestrutura.
        </p>
        <p>Clique aqui para realizar o diagnóstico da sua rede.</p>
      </S.MessageWrapper>
    </S.Wrapper>
  );
}

export default function DimensionsSections({ dimension, dimensionData }) {
  const [selectedTab, setSelectedTab] = useState(0);
  const [tabs] = useState([
    <Tab1 dimensionData={dimensionData} dimension={dimension} />,
    <Tab2 dimensionData={dimensionData} dimension={dimension} />,
    <Tab3 dimensionData={dimensionData} dimension={dimension} />,
  ]);

  if (!dimensionData) {
    return <Message />;
  }

  return (
    <S.Wrapper>
      <S.Tabs>
        <S.ButtonsWrapper dimension={dimension} selectedTab={selectedTab}>
          <button onClick={() => setSelectedTab(0)}>
            Onde a maioria das escolas da rede está?
          </button>
          <button onClick={() => setSelectedTab(1)}>
            O que isso significa?
          </button>
          <button onClick={() => setSelectedTab(2)}>
            Como a rede pode evoluir?
          </button>
        </S.ButtonsWrapper>
        <S.Content>{tabs[selectedTab]}</S.Content>
      </S.Tabs>
    </S.Wrapper>
  );
}
