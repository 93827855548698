import React from "react";
import { useMediaQuery } from "react-responsive";
import * as S from "./styles";

function RegionFilter({
  setRegionViewType,
  regionViewType,
  regionSelected,
  setRegionSelected,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 920px)" });

  return (
    <S.Wrapper>
      <div>
        <ul>
          <li>
            <S.Button
              isSelected={regionSelected === "allRegions"}
              onClick={() => setRegionSelected("allRegions")}
            >
              TODAS AS REGIÕES
            </S.Button>
          </li>
          <li>
            <S.Button
              isSelected={regionSelected === "north"}
              onClick={() => setRegionSelected("north")}
            >
              NORTE
            </S.Button>
          </li>
          <li>
            <S.Button
              isSelected={regionSelected === "northeast"}
              onClick={() => setRegionSelected("northeast")}
            >
              NORDESTE
            </S.Button>
          </li>
          <li>
            <S.Button
              isSelected={regionSelected === "midwest"}
              onClick={() => setRegionSelected("midwest")}
            >
              CENTRO-OESTE
            </S.Button>
          </li>
          <li>
            <S.Button
              isSelected={regionSelected === "southeast"}
              onClick={() => setRegionSelected("southeast")}
            >
              SUDESTE
            </S.Button>
          </li>
          <li>
            <S.Button
              isSelected={regionSelected === "south"}
              onClick={() => setRegionSelected("south")}
            >
              SUL
            </S.Button>
          </li>
        </ul>
      </div>
      {!isTabletOrMobile && (
        <div>
          <S.ButtonTypeView
            onClick={() => setRegionViewType("gridView")}
            isActive={regionViewType === "gridView"}
          >
            <S.StyledGridViewIcon />
          </S.ButtonTypeView>
          <S.ButtonTypeView
            onClick={() => setRegionViewType("listView")}
            isActive={regionViewType === "listView"}
          >
            <S.StyledViewListIcon />
          </S.ButtonTypeView>
        </div>
      )}
    </S.Wrapper>
  );
}

export default RegionFilter;
