import styled, { css } from "styled-components";
import GroupsIcon from "@mui/icons-material/Groups";
// import { container } from "global-styles/shared-styles";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ShareIcon from "@mui/icons-material/Share";
import BarChartIcon from "@mui/icons-material/BarChart";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { LinkIcon } from "@heroicons/react/outline";
import media from "styled-media-query";

import image83a from "assets/images/ge-in-numbers/083a.png";

const regionsTableColors = {
  state: ["#159ad4", "#158cbd"],
  city: ["#8747d6", "#572e8a"],
  allNetworks: ["#F09035", "#E08631"],
};

const backgroundColor = ["#3f764c", "#a0941d", "#fd8925"];

const progressBarColors = ["#158cbd", "#572e8a", "#85c23f"];

const competencesColors = ["#7e255a", "#11817c", "#238ccc"];

const backgroundCompetencesColors = ["#f8f0f4", "#f3f9f8", "#eef7fc"];

const continuingTrainingColors = [
  "#a0941d",
  "#f89426",
  "#e271a9",
  "#c0ca33",
  "#7cb342",
  "#159688",
  "#1bacc1",
];

const icons = css`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
    color: ${({ theme }) => theme.colors.white};
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    padding: 0.5rem;
  }
`;

export const StyledBarChartIcon = styled(BarChartIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;

export const StyledCalendarMonthIcon = styled(CalendarMonthIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;

export const StyledShareIcon = styled(ShareIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    font-size: 1.8rem;
  }
`;

export const Legend = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  list-style: none;
`;

export const LegendItem = styled.li`
  display: flex;
  gap: 1.5rem;
  align-items: center;

  > p {
    font-size: 1.9rem;
  }

  > p:nth-child(1) {
    width: 6rem;
    min-width: 6rem;
    text-align: right;
    font-weight: 500;
  }

  > p:nth-child(3) {
    font-weight: 400;
  }

  > div {
    margin-inline: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    min-width: 1.5rem;
    min-height: 1.5rem;
    background-color: ${({ item }) => continuingTrainingColors[item]};
    border-radius: 50%;
  }
`;

export const Competence = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  flex-wrap: wrap;

  > div:nth-child(1) {
    flex: 1 1;
    position: relative;
    background-color: ${({ item }) => backgroundCompetencesColors[item]};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
    border-radius: 4rem 2rem 2rem 4rem;
    height: 10rem;
    padding: 2rem 4rem 2rem 12rem;

    ${media.lessThan("medium")`
      border-radius: 2rem;
      padding: 2rem 4rem 2rem 4rem;
    `}

    > img {
      position: absolute;
      width: 10rem;
      left: 0rem;
      top: 50%;
      transform: translateY(-50%);

      ${media.lessThan("medium")`
        display: none;
      `}
    }

    > span {
      color: ${({ theme }) => theme.colors.blue5};
      font-size: 2.5rem;

      ${media.lessThan("medium")} {
        font-size: 2rem;
      }
    }

    > div {
      display: flex;
      align-items: center;
      gap: 2rem;
      color: ${({ item }) => competencesColors[item]};
      font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;

      > p {
        font-size: 2.2rem;
        font-weight: 500;
      }
      > span {
        font-size: 5.55rem;
        font-weight: 600;
      }
    }
  }
  > img {
    /* flex: 1 1; */
    width: 26.7rem;
    /* width: fit-content; */
  }
`;

export const CompetencesLevels = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.8rem;

  > article {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6rem;

    > article {
      flex: 1 1;
    }
  }

  > h1 {
    font-size: 2.25rem;
    font-weight: 500;
    color: ${({ item }) => competencesColors[item]};
    margin-top: 1.6rem;
    margin-bottom: 1rem;
  }
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.1rem solid rgba(0, 0, 0, 0.1);
    border-radius: 2rem;
    padding: 1rem 2rem;

    ${media.lessThan("medium")`
      flex-direction: column;
      gap: 3rem;
      padding: 3rem 2rem 3.5rem 2rem;
    `}

    > span {
      font-weight: 400;
      font-size: 1.8rem;
      display: flex;
      gap: 1rem;

      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 2rem;
        height: 2rem;
        box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1),
          0 1rem 2.5rem rgba(0, 0, 0, 0.1);
        font-size: 1.5rem;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
`;

export const CompetenceLevel = styled.ul`
  display: flex;
  gap: 4rem;
  list-style: none;
  align-items: center;

  > li:nth-child(${({ value }) => value}) {
    position: relative;
    z-index: 2;
    position: relative;

    > p {
      z-index: 1;
      color: white;
    }

    > span {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      background-color: ${({ competence }) =>
        competencesColors[competence - 1]};
      width: 3rem;
      height: 5rem;
      border-radius: 2rem;
      z-index: -1;
    }
  }
`;

export const StyledYouTubeIcon = styled(YouTubeIcon)`
  ${icons};
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
  ${icons};
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${icons};
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  ${icons};
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  ${icons};
`;

export const StyledGroupsIcon = styled(GroupsIcon)`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
    color: ${({ item }) => progressBarColors[item]};
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  margin-right: 1.7rem;

  ${media.lessThan("large")`
    margin-right: 0rem;
  `}

  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  > img {
    flex: 0.1 1;
  }

  > div {
    padding: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    flex: 0.9 1;

    > p {
      flex: 1 1;
      font-size: 2rem;
      > span {
        font-size: 3rem;
        font-weight: 500;
      }
    }

    > progress {
      width: 100%;
      background-color: ${({ theme }) => theme.colors.white};
      height: 0.9rem;
    }

    > progress::-webkit-progress-bar {
      background-color: #f0f0f0;
      border-radius: 0.7rem;
    }

    > progress::-moz-progress-bar {
      background-color: ${({ item }) => progressBarColors[item]};
      border-radius: 0.7rem;
    }

    > progress::-webkit-progress-value {
      background-color: ${({ item }) => progressBarColors[item]};
      border-radius: 0.7rem;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 4rem 4rem 4rem 2rem !important; */
  padding: 4rem 2rem 4rem 2rem !important;
  background-color: #f0f0f0;
  position: relative;

  > section {
    padding: 2rem 3rem 4rem 3rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);
  }

  > section:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    ${media.lessThan("medium")`
      flex-direction: column;
    `}

    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    > div {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem 3rem 4rem 3rem;
      background-color: ${({ theme }) => theme.colors.gray10};
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
        0 1rem 2.5rem rgba(0, 0, 0, 0.05);
      min-height: 50rem;
    }

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      > main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.colors.blue5};
        flex: 1 1 0%;
        padding: 4rem 2rem;
        gap: 1rem;
        font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;

        > div:nth-child(1) {
          align-self: flex-start;
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          > span {
            font-size: 7.5rem;
            font-weight: 600;
          }
        }

        > div:nth-child(2) {
          > p {
            align-self: center;
            font-size: 2.77rem;
            font-weight: 400;

            > div {
              display: inline-block;
              justify-content: center;
              align-items: center;
              background-color: ${({ theme }) => theme.colors.black};
              width: 2.5rem;
              height: 2.5rem;
              min-width: 2.5rem;
              min-height: 2.5rem;
              border-radius: 50%;
              position: relative;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > header {
        margin-bottom: 3rem;
      }
      > main {
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: space-around;
        color: ${({ theme }) => theme.colors.blue5};
        gap: 3rem;

        > div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;

          > p {
            font-size: 2.25rem;
            color: ${({ theme }) => theme.colors.gray9};
          }

          > span {
            font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
            font-size: 5.55rem;
            font-weight: bold;
          }
        }

        > div:nth-child(1) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.blue6};
          }
        }

        > div:nth-child(2) {
          > span:nth-child(1) {
            color: ${({ theme }) => theme.colors.purple2};
          }
        }

        > div:nth-child(3) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.green5};
          }
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > header {
        margin-bottom: 3rem;
      }

      > main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;
        color: ${({ theme }) => theme.colors.blue5};
        gap: 2rem;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem 4rem 3rem;
    gap: 3rem;

    > main {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      > p {
        font-size: 1.7rem;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.gray};

        > a {
          text-decoration: none;
          color: ${({ theme }) => theme.colors.black};
          font-weight: 600;
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    min-height: 68rem;
    position: relative;

    > main {
      display: flex;
      justify-content: space-between;
      ${media.lessThan("large")`
        flex-direction: column;
      `}
      /* flex-wrap: wrap; */
      gap: 4rem;

      > div {
        width: 100%;
      }

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        flex: 1 1 80%;
        ${media.lessThan("large")`
          flex: 1 1 100%;
        `}
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 20%;
        ${media.lessThan("large")`
          flex: 1 1 100%;
        `}

        > table {
          border: 2px solid #6e5acc;
          border-collapse: collapse;
          width: 80%;
          height: 100%;

          border-radius: 1rem;

          > tr {
            padding: 1rem;

            > th {
              padding: 1.5rem 1rem;
              border: 2px solid #6e5acc;
              font-size: 1.4rem;
              font-weight: 400;
              background-color: #6e5acc;
              color: ${({ theme }) => theme.colors.white};
              /* border-radius: 1rem; */

              /* &:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              &:last-child {
                border-radius: 0rem 1rem 0rem 0rem;
              } */

              &:last-child {
                text-align: left;
              }
            }

            > td {
              text-align: center;
              padding: 2rem 1rem;
              /* border: 2px solid #6e5acc; */
              border-radius: 1rem;
              > div {
                display: flex;
                text-align: center;
                justify-content: flex-start;
                gap: 1rem;

                > div {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  border-radius: 50%;
                  width: 2rem;
                  height: 2rem;
                  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1),
                    0 1rem 2.5rem rgba(0, 0, 0, 0.1);
                  font-size: 1.5rem;
                  font-weight: 600;
                  cursor: pointer;
                }
              }
            }
          }
        }

        > div {
          width: 100%;
          display: flex;
          padding: 3rem;
          border: 0.1rem solid rgba(0, 0, 0, 0.1);
          height: 100%;
          border-radius: 1rem;

          > ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 6rem;
            list-style: none;

            > li {
              font-size: 2.2rem;
              display: flex;
              gap: 1rem;

              > div {
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                width: 2rem;
                height: 2rem;
                box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1),
                  0 1rem 2.5rem rgba(0, 0, 0, 0.1);
                font-size: 1.5rem;
                font-weight: 600;
                cursor: pointer;
              }
              > span {
                font-size: 2.5rem;
                font-weight: 500;
              }
            }
          }
        }
      }
    }

    > article {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 4rem;
      width: 100%;

      > div:nth-child(1) {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 3rem;
        flex: 1 1;

        > div {
          display: flex;
          justify-content: center;
          gap: 6rem;
          margin: 2rem 0rem;
          flex-wrap: wrap;
        }
      }
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    min-height: 145rem;

    > div:nth-child(1) {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: column;
      gap: 6rem;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem 3rem 4rem 3rem;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
        0 1rem 2.5rem rgba(0, 0, 0, 0.05);
    }

    > div:nth-child(2) {
      width: 100%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      ${({ comparative2 }) => (comparative2 ? "width: 100%" : "flex: 1 1")};

      > div {
        flex: 1 1;
        padding: 2rem 3rem 4rem 3rem;
        border-radius: 1rem;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
          0 1rem 2.5rem rgba(0, 0, 0, 0.05);
        width: 100%;
      }

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          gap: 4rem;
        }
      }
    }
  }

  > section:nth-child(6) {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    min-height: 61.5rem;
  }

  > section:nth-child(7) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    min-height: 64rem;

    > div {
      width: 100%;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem 3rem 4rem 3rem;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
        0 1rem 2.5rem rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      gap: 8rem;
    }

    > div:nth-child(2) {
      > div:nth-child(2) {
        align-self: center;
        width: 35rem;
      }

      > div:nth-child(3) {
        > ul {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          list-style: none;
          gap: 6rem;

          ${media.lessThan("medium")`
            flex-direction: column;
          `}

          > li {
            /* flex: 1 1 2rem; */
            position: relative;
            font-size: 2rem;

            &::before {
              content: "";
              position: absolute;
              width: 1.5rem;
              height: 1.5rem;
              border-radius: 50%;
              left: -3rem;
              top: 50%;
              transform: translateY(-50%);
            }
          }

          > li:nth-child(1) {
            &::before {
              background-color: ${backgroundColor[2]};
            }
          }

          > li:nth-child(2) {
            &::before {
              background-color: ${backgroundColor[1]};
            }
          }

          > li:nth-child(3) {
            &::before {
              background-color: ${backgroundColor[0]};
            }
          }
        }
      }
    }
  }

  > section:nth-child(8) {
    display: flex;
    flex-direction: column;
    gap: 6rem;
    min-height: 84.5rem;

    > div {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      gap: 6rem;
      align-items: center;
      /* justify-content: space-between; */
      /* padding: 2rem 3rem 4rem 3rem; */

      > div:nth-child(1) {
        flex: 1 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > div:nth-child(2) {
        flex: 1 1;
      }
    }
  }

  > section:nth-child(9) {
    display: flex;
    flex-direction: column;
    gap: 5rem;
    padding: 2rem 3rem 4rem 3rem;

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;

      > main {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 2.5rem;
        width: 90%;
      }
    }
  }

  > section:nth-child(10) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0rem;

    > header {
      border-radius: 1rem 1rem 0rem 0rem;
      background-color: #e05887;
      background-image: url(${image83a});
      height: 31.1rem;
      background-position: right top;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding-inline: 10rem;

      ${media.lessThan("large")`
        padding-inline: 2rem;
      `}

      > img {
        width: 48rem;

        ${media.lessThan("large")`
          width: 40rem;
        `}
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > h1 {
          padding: 4rem 8rem 0rem 8rem;
          font-size: 2.1rem;
          font-weight: 500;
          margin-bottom: 2.1rem;
          color: #1a2341;

          ${media.lessThan("large")`
            padding: 4rem 4rem 0rem 4rem;
          `}
        }

        > p {
          padding-inline: 8rem;
          ${media.lessThan("large")`
            padding-inline: 4rem;
          `}
          font-size: 1.65rem;
          font-weight: 400;
          line-height: 1.5;
          color: #707070;
        }
      }

      > div:nth-child(2) {
        padding-inline: 8rem;
        ${media.lessThan("large")`
          padding-inline: 4rem;
        `}
        > a {
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
          text-decoration: none;
          font-size: 1.5rem;
          padding: 1.4rem 3rem;
          border-radius: 1rem;
          color: ${({ theme }) => theme.colors.white};
          background-color: #1a2341;
          border: none;
          width: fit-content;
          cursor: pointer;
        }
      }

      > div:nth-child(3) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-inline: 8rem;
        ${media.lessThan("large")`
          padding-inline: 4rem;
        `}

        > h1 {
          font-size: 1.65rem;
          font-weight: 500;
          color: #b53661;
        }

        > table {
          width: fit-content;
          box-shadow: 0rem 0.1rem 2.5rem rgba(0, 0, 0, 0.05);
          border-radius: 1rem;
          border: none;
          > thead {
            background-color: #b53661;

            > tr {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2.4rem;
                background-color: #75233f;
              }
              > th {
                padding: 3.5rem 2rem;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 500;
                text-align: left;
              }

              > th:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              > th:nth-child(2) {
                border-radius: 0rem 1rem 0rem 0rem;
              }
            }
          }

          > tbody {
            font-size: 1.5rem;
            font-weight: 500;
            > tr {
              > td {
                width: 40rem;
                padding: 1.6rem 2rem;
                > a {
                  text-decoration: none;
                  font-weight: 600;
                }
              }
            }
            > tr:nth-child(odd) {
              background-color: ${({ theme }) => theme.colors.white};
              > td {
                color: #b53661;
                > a {
                  color: #b53661;
                }
              }
            }
            > tr:nth-child(even) {
              background-color: #fef8f9;
              color: ${({ theme }) => theme.colors.gray};
            }

            > tr:last-child {
              > td:nth-child(1) {
                border-radius: 0rem 0rem 0rem 1rem;
              }

              > td:nth-child(2) {
                border-radius: 0rem 0rem 1rem 0rem;
              }
            }
          }
        }
      }
    }

    > footer {
      padding: 4rem 8rem;
      ${media.lessThan("large")`
        padding: 4rem 4.5rem;
      `}

      > div {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 0.83rem;
        > img {
          width: 2.5rem;
        }

        > h1 {
          color: #1a2341;
          font-size: 1.88rem;
          font-weight: 500;
        }
      }

      > p {
        font-size: 1.5rem;
        margin-top: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #707070;
      }
    }
  }

  /* > section:nth-child(10) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 6rem;
    > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 10rem;

      > input {
        border: none;
        border-bottom: 0.1rem solid #707070;
        padding: 2rem;
        flex: 1;
        max-width: 40rem;

        &::placeholder {
          color: #9a9a9a;
          font-size: 500;
        }
      }
      > button {
        font-family: ${({ theme }) =>
    theme.fonts.firaSans}, sans-serif !important;
        font-size: 1.5rem;
        padding: 1rem 3rem;
        border-radius: 1rem;
        color: ${({ theme }) => theme.colors.white};
        background-color: #1a2341;
        border: none;
        width: fit-content;
        cursor: pointer;
      }
    }
  } */
`;

export const NoDataContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 2rem 4rem 2rem !important;
  background-color: #f0f0f0;
  position: relative;

  > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 4rem 3rem 4rem 3rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);

    > img {
      width: 20rem;
    }

    > p {
      font-size: 1.9rem;
    }
  }
`;

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue5};
  display: flex;
  flex-direction: column;
  gap: 2.2rem;

  ${media.lessThan("medium")`
    gap: 1.5rem;
  `}

  > div:nth-child(1) {
    width: 3.4rem;
    height: 3.4rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0.1rem 0.4rem 0.8rem rgba(164, 168, 177, 0.2),
      0.1rem 0.4rem 0.8rem rgba(164, 168, 177, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 2rem;
    > h1 {
      font-weight: 400;
      font-size: 2.6rem;
      line-height: 1.5;
    }
    > div {
      display: flex;
      align-items: center;
      gap: 1rem;
      background-color: #f8f8f8;
      width: fit-content;
      padding: 1.5rem 2.5rem;
      border-radius: 1rem;

      > p {
        font-size: 1.6rem;
      }
    }
  }
  > span {
    font-weight: 400;
    font-size: 1.8rem;
  }
  > h1 {
    font-weight: 400;
    font-size: 2.2rem;
    line-height: 1.5;
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  > svg {
    width: 10rem;
  }

  > p {
    font-size: 1.6rem;
  }
`;

export const RegionsTable = styled.table`
  width: fit-content;
  border-radius: 1rem;
  border: none;
  border-spacing: 0 1rem;
  max-width: 95.6rem;
  > thead {
    background-color: ${({ networkTypeSelected }) =>
      regionsTableColors[networkTypeSelected][0]};

    > tr {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 2.4rem;
        background-color: ${({ networkTypeSelected }) =>
          regionsTableColors[networkTypeSelected][1]};
      }

      > th {
        padding: 2.8rem 3.5rem;
        color: ${({ theme }) => theme.colors.white};
        font-weight: 500;
        text-align: left;
        font-size: 1.4rem;

        > img {
          width: 6rem;
          height: 6rem;
        }
      }

      > th:nth-child(1) {
        border-radius: 1rem 0rem 0rem 0rem;
      }

      > th:last-child {
        border-radius: 0rem 1rem 0rem 0rem;
      }
    }
  }

  > tbody {
    font-size: 1.5rem;
    font-weight: 500;
    > tr {
      box-shadow: 0rem 0.1rem 2.5rem rgba(0, 0, 0, 0.05);
      border-radius: 1rem;
      > td {
        width: 40rem;
        padding: 1.6rem 3.5rem;
        height: 6.47rem;
        font-size: 1.5rem;
        color: #707070;

        &:nth-last-child(1),
        &:nth-last-child(2) {
          font-size: 2.2rem;
          color: ${({ networkTypeSelected }) =>
            regionsTableColors[networkTypeSelected][0]};
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
        }

        > div {
          display: flex;
          align-items: center;
          gap: 3rem;
          > img {
            width: 6rem;
            height: 6rem;
          }

          > span {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
`;

export const StateCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.white};
  height: 36rem;
  width: 15rem;
  border-radius: 1.5rem;
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05);
  padding: 2rem 1.4rem;
  gap: 2rem;

  > header {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
    > img {
      width: 4rem;
    }
    > span {
      font-size: 1.5rem;
    }
  }

  > main {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > p {
      height: 3.5rem;
      margin-bottom: 1rem;
      font-size: 1.65rem;
      word-break: break-word;
      width: fit-content;
    }

    > span {
      font-size: 2.85rem;
      font-weight: 500;
      color: ${({ networkTypeSelected }) =>
        regionsTableColors[networkTypeSelected][1]};
      text-align: center;
      width: fit-content;
      word-break: break-word;
    }
  }

  > footer {
    flex: 1;
    > p {
      font-size: 1.2rem;
      margin-bottom: 1rem;
    }
    > span {
      font-size: 2.25rem;
    }
  }
`;

export const StyledLinkIcon = styled(LinkIcon)`
  width: 5rem;
`;

export const ClipboardModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 2rem;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f9f8;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
  }

  > h1 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #85c440;
  }

  > p {
    font-size: 1.6rem;
    text-align: center;
  }
`;

export const GeneralFilterModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 2rem;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f9f8;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
  }

  > h1 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #85c440;
  }

  > p {
    font-size: 1.6rem;
    text-align: center;
  }
`;

export const DigitalCompetenceByAreaModal = styled.div``;

export const InnerModal = styled.div`
  border-radius: 1rem;
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  > div {
    position: absolute;
    background-color: #fefefe;
    margin: auto;
    padding: 2rem;
    width: 90%;

    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 1rem;
    > div:nth-child(1) {
      align-self: flex-end;
      width: 3rem;
      height: 3rem;
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        color: #aaaaaa;
        font-size: 2.8rem;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    > div:nth-child(2) {
      padding: 2rem 6rem 6rem 6rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      ${media.lessThan("medium")`
        padding: 2rem 2rem 6rem 2rem;
      `}

      > h1 {
        font-size: 2rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.blue5};
      }

      > p {
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
  }
`;

export const Modal = styled.div`
  border-radius: 1rem;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("medium")`
    display: none;
  `}

  > div {
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    padding: 2rem;
    width: 100%;
    bottom: 58px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 2rem 2rem 0rem 0rem;
    > div:nth-child(1) {
      align-self: flex-end;
      width: 3rem;
      height: 3rem;
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        color: #aaaaaa;
        font-size: 2.8rem;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    > div:nth-child(2) {
      padding: 2rem 6rem 6rem 6rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      ${media.lessThan("medium")`
        padding: 2rem 0rem 6rem 0rem;
      `}

      > h1 {
        font-size: 2rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.blue5};
      }

      > p {
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2rem;
  }
`;

export const TabsWrapper = styled.ul`
  display: flex;
  padding-inline: 8rem;
  gap: 4rem;
  list-style: none;
  border-bottom: 1rem solid #eaeaea;
  margin-bottom: 2rem;
`;

export const Tab = styled.li`
  width: fit-content;
  position: relative;
  padding: 1.5rem 1rem;
  text-align: center;
  color: ${({ active }) => (active ? "#b53661" : "#eaeaea")};

  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 0;
    height: 1rem;
    background-color: ${({ active }) => (active ? "#b53661" : "#eaeaea")};
    width: 100%;
  }
`;
