import styled from "styled-components";

export const IndicatorsAndGoals = styled.section`
  margin-top: 5rem;
  margin-bottom: 5rem;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4rem;
    > h1 {
      font-weight: 500;
      font-size: 2.4rem;
      border-bottom: 0.1rem solid rgb(207, 222, 215);
      padding-bottom: 1rem;
    }
  }

  > div:last-child {
    > p {
      font-size: 1.8rem;
    }
  }
`;

export const Button = styled.button`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1.3rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 4.6rem;
  cursor: pointer;
`;

export const Target = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 6rem;
  border: 1px solid #e5e5e5;
  padding: 2rem 4rem 4rem 4rem;
  border-radius: 1rem;
  background-color: #f5f5f5;

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* margin-bottom: 2rem; */
    > label {
      font-weight: 500;
      font-size: 1.8rem;
      margin-bottom: 2rem;
      margin-top: 2rem;
    }
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 2rem;
      width: 100%;
      > textarea {
        width: 100%;
        resize: vertical;
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
        padding: 2rem;
        border-radius: 1rem;
        border: 1px solid #e5e5e5;
      }

      > p {
        font-weight: 600;
        font-size: 2rem;
      }
    }
  }

  > div:last-child {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    align-self: end;
    margin-top: 2rem;
    width: 100%;
  }
`;

export const EmptyTarget = styled.div``;
