import styled from "styled-components";

const COLORS = [
  "#a0941d",
  "#f89426",
  "#e271a9",
  "#c0ca33",
  "#7cb342",
  "#159688",
  "#1bacc1",
];

export const Wrapper = styled.div`
  position: relative;
  width: ${({ maxWidth }) => maxWidth}rem;
  height: ${({ maxWidth }) => maxWidth}rem;
`;

export const CircleWrapper = styled.div`
  display: flex;
  width: ${({ width }) => width}rem;
  height: ${({ width }) => width}rem;
  border-radius: 50%;
  background: ${({ progress, zIndex }) =>
    `conic-gradient(${COLORS[zIndex - 1]} ${progress}deg, #f0f0f0 0deg)`};
  background-position: center;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  position: relative;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};

  &::after {
    content: "";
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin: ${({ width }) => 1.6}rem;

    border-radius: 50%;
    background: ${({ theme }) => theme.colors.white};
    text-align: center;
  }
`;
