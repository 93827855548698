import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import media from "styled-media-query";

export const Wrapper = styled.header`
  ${container}
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 12rem 0rem 6rem 0rem;
  gap: 4.2rem;
  ${media.lessThan("huge")`
    gap: 0;
  `}
  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 78rem;
    img {
      width: 100%;
      object-fit: cover;
    }
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    width: 48rem;

    ${media.lessThan("large")`
      width: 100%;
      align-items: center;
    `}

    > h3 {
      font-size: 2.5rem;
      font-weight: 400;
      color: ${({ theme }) => theme.colors.textGray};
      font-family: ${({ theme }) => theme.fonts.openSansLight}, sans-serif;
    }
    > h1 {
      font-size: 3.6rem;
      font-weight: 500;
      margin: 0.5rem 0 2rem 0;
      color: ${({ theme }) => theme.colors.textDarkGray};
      ${media.lessThan("large")`
        text-align: center;
      `}
      > br {
        ${media.lessThan("large")`
          display: none;
          text-align: center;
        `}
      }
    }
    > div {
      display: inline-block;
      border: 1px dashed;
      padding: 1.5rem;
      color: ${({ theme }) => theme.colors.textGray};
      font-weight: 500;
      background-color: ${({ theme }) => theme.colors.white};

      > p {
        font-size: 1.4rem;
        font-weight: 400;
      }
    }
  }
`;
