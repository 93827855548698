import { createContext, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useSteps } from "hooks/use-steps";

const JourneyContext = createContext();

export default function JourneysProvider({ children }) {
  const navigate = useNavigate();
  const { restartStep } = useSteps();

  const pushToVision = useCallback(() => {
    navigate("/jornada/visao");
    restartStep("vision");
  }, [navigate, restartStep]);

  const pushToCompetence = useCallback(() => {
    navigate("/jornada/competencia");
    restartStep("competence");
  }, [navigate, restartStep]);

  const pushToRed = useCallback(() => {
    navigate("/jornada/red");
    restartStep("red");
  }, [navigate, restartStep]);

  const pushToInfrastructure = useCallback(() => {
    navigate("/jornada/infraestrutura");
    restartStep("infra");
  }, [navigate, restartStep]);

  return (
    <JourneyContext.Provider
      value={{
        pushToVision,
        pushToCompetence,
        pushToRed,
        pushToInfrastructure,
      }}
    >
      {children}
    </JourneyContext.Provider>
  );
}

const useJourneys = () => useContext(JourneyContext);

export { JourneysProvider, useJourneys };
