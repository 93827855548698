import React from "react";
import * as S from "./styles";

export default function Modal(props) {
  const { children, title, closeModal, footer, ...rest } = props;

  return (
    <S.StyledModal
      {...rest}
      appElement={document.getElementById("root") || undefined}
      closeTimeoutMS={0}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.45)",
          zIndex: 999,
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "20px",
          outline: "none",
          padding: "40px",
        },
      }}
      shouldFocusAfterRender={true}
      shouldCloseOnOverlayClick={true}
      shouldCloseOnEsc={true}
      shouldReturnFocusAfterClose={true}
      role="dialog"
      preventScroll={false}
      onRequestClose={closeModal}
    >
      {!!title && (
        <header>
          {title}
          <button onClick={closeModal}>
            <S.StyledCloseIcon />
          </button>
        </header>
      )}
      <main>{children}</main>
      {!!footer && <footer></footer>}
    </S.StyledModal>
  );
}
