import React, { useCallback, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import image23 from "assets/images/ge-in-numbers/023.svg";
import image83 from "assets/images/ge-in-numbers/083.png";
import image185 from "assets/images/ge-in-numbers/0185.svg";

import useClipboard from "react-hook-clipboard";
import GeneralFilter from "pages/EdutecGuideInNumbers/components/GeneralFilter";
import DimensionsTable from "shared-components/DimensionsTable";
import SchoolDiagnosisChart from "../../components/SchoolDiagnosisChart";
import Modal from "../../components/Modal";

import { useGEINNumbersFilter } from "hooks/use-ge-in-numbers-filter";
import { number } from "utils/masks";
import { useEffect } from "react";
import { useMicrodataInfo } from "services/ge-in-numbers";
import { getStorageUpdateDate } from "utils/local-storage";
import useScrollToElement from "hooks/use-scroll-to-element";
import Banner from "pages/EdutecGuideInNumbers/components/Banner";
import boxEmpty from "assets/images/ge-in-numbers/box_empty.svg";

import ReactTooltip from "react-tooltip";

export default function GENSchoolDiagnosis() {
  const {
    dataFiltered,
    selectedCycle,
    aggregationType,
    setShowGeneralFilterModal,
    showGeneralFilterModal,
  } = useGEINNumbersFilter();
  const schoolsData = dataFiltered?.schools;

  const [clipboard, copyToClipboard] = useClipboard();

  const [sectionId, setSectionId] = useState("");
  const [openClipboardModal, setOpenClipboardModal] = useState(false);

  const [openInfoBox, setOpenInfoBox] = useState(false);

  useEffect(() => {
    copyToClipboard(
      `https://plataforma.guiaedutec.com.br/ge-em-numeros/diagnostico-escolas#${sectionId}`
    );
  }, [sectionId, copyToClipboard]);

  const dimensionsTableData = useMemo(() => {
    if (!schoolsData?.avg_level) return {};

    const { levels, ...rest } = schoolsData.avg_level;
    return {
      resultsAvgLoading: false,
      resultsAvgSuccess: true,
      surveyAnswersLoading: false,
      results: rest,
      resultsAvg: {
        competence_avg: levels.competence,
        crd_avg: levels.resource,
        infra_avg: levels.infrastructure,
        vision_avg: levels.vision,
      },
      yearOfCycle: selectedCycle?.value
        ? selectedCycle?.value
        : "TODOS OS CICLOS",
      sampleAnswer:
        aggregationType === "rede" && selectedCycle?.label !== "TODOS",
    };
  }, [schoolsData, selectedCycle, aggregationType]);

  const schoolDiagnosisChartData = useCallback(
    (dimension) => {
      if (!schoolsData?.distribution_by_level) return;
      const data = schoolsData?.distribution_by_level[dimension]?.map(
        (item) => item.percent
      );

      return data.indexOf(Math.min(...data));
    },
    [schoolsData?.distribution_by_level]
  );

  const { data: microdataInfo, isSuccess: microdataInfoSuccess } =
    useMicrodataInfo("school");

  const { data: microdataInfoInventory, isSuccess: microdataInfoInventorySuccess } =
    useMicrodataInfo("inventory");

  const update_at = new Date(getStorageUpdateDate());

  const [version, setVersion] = useState(1);
  const [versionInventory, setVersionInventory] = useState(1);

  useEffect(() => {
    if (microdataInfoSuccess) {
      setVersion(microdataInfo[microdataInfo.length - 1].version);
    }
  }, [microdataInfo, microdataInfoSuccess]);

  useEffect(() => {
    if (microdataInfoInventorySuccess) {
      setVersionInventory(microdataInfoInventory[microdataInfoInventory.length - 1].version);
    }
  }, [microdataInfoInventory, microdataInfoInventorySuccess]);

  const { goToElement } = useScrollToElement();

  useEffect(() => {
    if (openInfoBox) {
      goToElement("infobox");
    }
  }, [openInfoBox, goToElement]);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 920px)" });

  useEffect(() => {
    setShowGeneralFilterModal(false);
  }, [setShowGeneralFilterModal]);

  const thereIsNoData = schoolsData?.schools_answers?.total_amount === 0;

  return (
    <Layout hasNavigationBar={!isTabletOrMobile} name="geInNumbers">
      <Helmet title="Guia EduTec em Números" />
      <S.Wrapper>
        <Banner
          update_at={update_at}
          tool={
            <p>
              Diagnóstico de Adoção <br /> de Tecnologias das escolas
            </p>
          }
        />

        {!thereIsNoData ? (
          <S.Content>
            <GeneralFilter display={isTabletOrMobile && "none"} boxShadow />
            <div>
              <div>
                <S.StyledReportProblemIcon />
                <h1> Mudanças no cálculo do diagnóstico</h1>
              </div>
              <p>
                <a
                  href="https://guiaedutec.com.br/calculo-do-diagnostico"
                  target="_blank"
                  rel="noreferrer"
                >
                  Saiba mais sobre a mudança aqui.
                </a>
              </p>
            </div>
            <section>
              <div id="gen-diagnostico-1">
                <header>
                  <S.SectionTitle>
                    <div
                      onClick={() => {
                        setOpenClipboardModal(true);
                        setSectionId("gen-diagnostico-1");
                      }}
                    >
                      <S.StyledShareIcon />
                    </div>
                  </S.SectionTitle>
                </header>
                <main>
                  <div>
                    <S.StyledSchoolIcon />
                    <span>
                      {number.resolve(
                        String(schoolsData?.schools_answers?.total_amount)
                      )}
                    </span>
                  </div>
                  <div>
                    <p>
                      ESCOLAS QUE RESPONDERAM AO DIAGNÓSTICO DE ADOÇÃO DE
                      TECNOLOGIA{" "}
                      {openInfoBox ? (
                        <div>
                          <S.StyledRemoveIcon
                            onClick={() => setOpenInfoBox(false)}
                          />
                        </div>
                      ) : (
                        <div>
                          <S.StyledAddIcon
                            onClick={() => setOpenInfoBox(true)}
                          />
                        </div>
                      )}
                    </p>
                  </div>
                </main>
              </div>
              <div id="gen-diagnostico-2">
                <header>
                  <S.SectionTitle>
                    <div
                      onClick={() => {
                        setOpenClipboardModal(true);
                        setSectionId("gen-diagnostico-2");
                      }}
                    >
                      <S.StyledShareIcon />
                    </div>
                    <h1>ONDE ESTÃO DISTRIBUÍDAS?</h1>
                  </S.SectionTitle>
                </header>
                <main>
                  <div>
                    <p>REDE ESTADUAL</p>
                    <span>
                      {number.resolve(
                        String(
                          schoolsData?.schools_answers?.network?.state?.amount
                        )
                      )}
                    </span>
                  </div>
                  <div>
                    <p>REDE MUNICIPAL</p>
                    <span>
                      {number.resolve(
                        String(
                          schoolsData?.schools_answers?.network?.city?.amount
                        )
                      )}
                    </span>
                  </div>
                  <div>
                    <p>DEMAIS REDES</p>
                    <span>
                      {number.resolve(
                        String(
                          schoolsData?.schools_answers?.network?.others?.amount
                        )
                      )}
                    </span>
                  </div>
                </main>
              </div>
            </section>
            <section style={{ display: !openInfoBox && "none" }} id="infobox">
              <header>
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenInfoBox(false);
                    }}
                  >
                    <S.StyledCloseIcon />
                  </div>
                  <h1>
                    DADOS DO DIAGNÓSTICO DE ADOÇÃO DE TECNOLOGIAS DAS ESCOLAS
                  </h1>
                </S.SectionTitle>
              </header>
              <main>
                <p>
                  A ferramenta de Diagnóstico, desenvolvida pelo CIEB, permite
                  identificar o nível de adoção de tecnologias nas escolas da
                  Educação Básica. A metodologia baseia-se no modelo de quatro
                  dimensões em equilíbrio (visão, competências, recursos
                  educacionais digitais e infraestrutura) e consiste em um
                  questionário online com perguntas relacionadas aos 36
                  indicadores da Matriz da Escola Conectada. Acesse o nosso
                  Marco Conceitual para saber mais:{" "}
                  <a
                    href="https://cieb.net.br/escola-conectada/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Escola Conectada
                  </a>
                  .
                </p>
                <p>
                  Os dados abaixo apresentam os resultados agregados de todas as
                  escolas* que responderam ao Diagnóstico, no portal Guia
                  Edutec, desde 2016.
                </p>
                <p>
                  *Caso a escola tenha respondido ao Diagnóstico mais de uma
                  vez, são contabilizadas as respostas mais recentes.
                </p>
              </main>
            </section>
            <section id="gen-diagnostico-3">
              <header>
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenClipboardModal(true);
                      setSectionId("gen-diagnostico-3");
                    }}
                  >
                    <S.StyledShareIcon />
                  </div>
                  <h1>
                    DISTRIBUIÇÃO DAS ESCOLAS EM CADA NÍVEL DE ADOÇÃO DE
                    TECNOLOGIA
                  </h1>
                </S.SectionTitle>
              </header>
              <main>
                <DimensionsTable {...dimensionsTableData} />

                {schoolsData?.distribuition_by_level && (
                  <>
                    <SchoolDiagnosisChart
                      dimension="vision"
                      emphasize={schoolDiagnosisChartData("vision")}
                      data={schoolsData?.distribuition_by_level?.vision}
                    />
                    <SchoolDiagnosisChart
                      dimension="competence"
                      emphasize={schoolDiagnosisChartData("competence")}
                      data={schoolsData?.distribuition_by_level?.competence}
                    />
                    <SchoolDiagnosisChart
                      dimension="red"
                      emphasize={schoolDiagnosisChartData("resource")}
                      data={schoolsData?.distribuition_by_level?.resource}
                    />
                    <SchoolDiagnosisChart
                      dimension="infra"
                      emphasize={schoolDiagnosisChartData("infrastructure")}
                      data={schoolsData?.distribuition_by_level?.infrastructure}
                    />
                  </>
                )}
              </main>
            </section>
            <section>
              <div>
                <div>
                  <h1>RELATÓRIO GUIA EDUTEC 2022</h1>
                  <p>
                    Um retrato exclusivo que analisa os resultados obtidos a
                    partir da <br />
                    ferramenta Guia Edutec Diagnóstico:{" "}
                    <strong>o maior banco de dados</strong> sobre o nível
                    <br /> de adoção de tecnologia nas escolas públicas
                    brasileiras
                  </p>
                  <a download href="https://link.cieb.net.br/relatorio-2022" data-href="https://link.cieb.net.br/relatorio-2022" target="_blank" rel="noopener noreferrer">
                    FAÇA O DOWNLOAD
                  </a>
                </div>
                <img src={image185} alt="" />
              </div>
            </section>
            <section>
              <header>
                <img src={image83} alt="" />
              </header>
              <main>
                <div>
                  <h1>ACESSO AOS MICRODADOS</h1>
                  <p>
                    O CIEB disponibiliza os microdados do <strong>Diagnóstico de
                    Adoção de Tecnologia das Escolas</strong> e <strong>Inventário de 
                    Infraestrutura das Escolas</strong> de forma aberta e
                    totalmente anônima para fins de pesquisa e análise. Alinhado
                    ao conceito de dados abertos, o CIEB acredita que todas as
                    matérias e/ou estudos publicados por terceiros, a partir os
                    microdados aqui disponibilizados, contenham a devida
                    referência bibliográfica.
                  </p>

                  <div>
                    <S.TabsWrapper>
                      {microdataInfo
                        ?.sort((a, b) => a.version - b.version)
                        ?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item.disabled && (
                              <ReactTooltip
                                effect="solid"
                                id={`item-${item?.version}`}
                                aria-haspopup="true"
                                place="top"
                                className="tooltip"
                              >
                                Temporariamente indisponível
                              </ReactTooltip>
                            )}
                            <S.Tab
                              key={`tab-${index}`}
                              onClick={() => {
                                !item.disabled && setVersion(item.version);
                              }}
                              active={version === item.version}
                              disabled={item.disabled}
                              data-tip
                              data-for={`item-${item?.version}`}
                            >
                              Versão {item.version}
                            </S.Tab>
                          </React.Fragment>
                        ))}
                    </S.TabsWrapper>
                  </div>
                </div>
                <div>
                  {microdataInfoSuccess && (
                    <a
                      // target="_blank"
                      // rel="noreferrer"
                      download
                      href={microdataInfo[version - 1]?.data}
                      // href="javascript:void(0)"
                      data-href={microdataInfo[version - 1]?.data}
                    >
                      FAÇA O DOWNLOAD
                    </a>
                  )}
                </div>
                <div>
                  {microdataInfoSuccess && (
                    <table cellSpacing="0" cellPadding="0">
                      <thead>
                        <tr>
                          <th>CAMPO</th>
                          <th>VALOR</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fonte</td>
                          <td>
                            <a href="https://guiaedutec.com.br">
                              guiaedutec.com.br
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Autor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Mantenedor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Versão</td>
                          <td>{microdataInfo[version - 1]?.version}</td>
                        </tr>
                        <tr>
                          <td>Última atualização</td>
                          <td>
                            {new Date(
                              microdataInfo[version - 1]?.updated_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Criado</td>
                          <td>
                            {new Date(
                              microdataInfo[version - 1]?.created_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Cobertura geográfica</td>
                          <td>Brasil</td>
                        </tr>
                        <tr>
                          <td>Cobertura temporal</td>
                          <td>{microdataInfo[version - 1]?.coverage}</td>
                        </tr>
                        <tr>
                          <td>Frequência de atualização</td>
                          <td>Mensal</td>
                        </tr>
                        <tr>
                          <td>
                            Codificação (<span>encoding</span>)
                          </td>
                          <td>UTF-8</td>
                        </tr>
                        <tr>
                          <td>Dicionário de dados</td>
                          <td>
                            <a
                              href={microdataInfo[version - 1]?.dictionary}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Baixe o dicionário de dados.
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>

                <div>
                  <h1>INVENTÁRIO DE INFRAESTRUTURA DAS ESCOLAS</h1>
                  <div>
                    <S.TabsWrapper>
                      {microdataInfoInventory
                        ?.sort((a, b) => a.version - b.version)
                        ?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item.disabled && (
                              <ReactTooltip
                                effect="solid"
                                id={`item-inventario-${item?.version}`}
                                aria-haspopup="true"
                                place="top"
                                className="tooltip"
                              >
                                Temporariamente indisponível
                              </ReactTooltip>
                            )}
                            <S.Tab
                              key={`tab-${index}`}
                              onClick={() => {
                                !item.disabled && setVersionInventory(item.version);
                              }}
                              active={versionInventory === item.version}
                              disabled={item.disabled}
                              data-tip
                              data-for={`item-inventario-${item?.version}`}
                            >
                              Versão {item.version}
                            </S.Tab>
                          </React.Fragment>
                        ))}
                    </S.TabsWrapper>
                  </div>
                </div>
                <div>
                  {microdataInfoInventorySuccess && (
                    <a
                      // target="_blank"
                      // rel="noreferrer"
                      download
                      href={microdataInfoInventory[versionInventory - 1]?.data}
                      // href="javascript:void(0)"
                      data-href={microdataInfoInventory[versionInventory - 1]?.data}
                    >
                      FAÇA O DOWNLOAD
                    </a>
                  )}
                </div>
                <div>
                  {microdataInfoInventorySuccess && (
                    <table cellSpacing="0" cellPadding="0">
                      <thead>
                        <tr>
                          <th>CAMPO</th>
                          <th>VALOR</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fonte</td>
                          <td>
                            <a href="https://guiaedutec.com.br">
                              guiaedutec.com.br
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Autor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Mantenedor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Versão</td>
                          <td>{microdataInfoInventory[versionInventory - 1]?.version}</td>
                        </tr>
                        <tr>
                          <td>Última atualização</td>
                          <td>
                            {new Date(
                              microdataInfoInventory[versionInventory - 1]?.updated_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Criado</td>
                          <td>
                            {new Date(
                              microdataInfoInventory[versionInventory - 1]?.created_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Cobertura geográfica</td>
                          <td>Brasil</td>
                        </tr>
                        <tr>
                          <td>Cobertura temporal</td>
                          <td>{microdataInfoInventory[versionInventory - 1]?.coverage}</td>
                        </tr>
                        <tr>
                          <td>Frequência de atualização</td>
                          <td>Mensal</td>
                        </tr>
                        <tr>
                          <td>
                            Codificação (<span>encoding</span>)
                          </td>
                          <td>UTF-8</td>
                        </tr>
                        <tr>
                          <td>Dicionário de dados</td>
                          <td>
                            <a
                              href={microdataInfoInventory[versionInventory - 1]?.dictionary}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Baixe o dicionário de dados.
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </main>
              <footer>
                <div>
                  <img src={image23} alt="" /> <h1>OBSERVAÇÃO</h1>
                </div>
                <p>
                  1. As variações entre os microdados e o dashboard apresentado
                  nesta página ocorrem porque a frequência de atualização é
                  diferente, sendo que a do dashboard é semanal e a do
                  microdados é mensal.
                </p>
                <p>
                  2. É importante notar que os dados do inventário são auto declaratórios, 
                  o que significa que os valores são fornecidos pelos próprios participantes. 
                  Portanto, não nos responsabilizamos pela precisão das informações.
                </p>
              </footer>
            </section>
          </S.Content>
        ) : (
          <S.NoDataContent>
            <GeneralFilter display={isTabletOrMobile && "none"} boxShadow />
            <section>
              <img src={boxEmpty} alt="Sem dados" />
              <p>Não há dados para os filtros selecionados</p>
            </section>
          </S.NoDataContent>
        )}
      </S.Wrapper>
      <Modal
        open={openClipboardModal}
        onClose={() => setOpenClipboardModal(false)}
      >
        <S.ClipboardModal>
          <div>
            <S.StyledLinkIcon />
          </div>
          <h1>Link copiado!</h1>
          <p>{clipboard}</p>
        </S.ClipboardModal>
      </Modal>

      {showGeneralFilterModal && (
        <S.Modal>
          <div>
            <div onClick={() => setShowGeneralFilterModal(false)}>
              <span>&times;</span>
            </div>
            <div>
              <GeneralFilter display />
            </div>
          </div>
        </S.Modal>
      )}
    </Layout>
  );
}
