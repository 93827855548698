import Table from "@mui/material/Table";
import styled, { css } from "styled-components";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpIcon from "@mui/icons-material/Help";
import { TextField } from "@mui/material";
import { DatePicker } from "@mui/lab";

export const wrapperModifiers = {
  generalStylesIcons: css`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textDarkGray};
    &&& {
      font-size: 2.8rem;
    }
    &:hover {
      opacity: 0.8;
      background-color: ${({ theme }) => theme.colors.textDarkGray};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.5s;
    }
  `,
};

export const StyledCheckIcon = styled(CheckIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledEditIcon = styled(EditIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledDeleteIcon = styled(DeleteIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledTextField = styled(TextField)`
  &&& {
    > div {
      font-size: 1.5rem;
    }
  }
`;

export const StyledHelpIcon = styled(HelpIcon)`
  &&& {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

export const StyledDatePicker = styled(DatePicker)`
  &&& {
    font-size: initial;
    .MuiOutlinedInput-input {
      font-size: initial !important;
    }
  }
`;
export const StyledTable = styled(Table)`
  &&& {
    box-shadow: 0 1rem 1.5rem 0.3rem rgb(0 0 0 / 10%) !important;
    > thead {
      > tr {
        > th:last-child {
          border-radius: 1rem 1rem 0rem 0rem;
          background-color: ${({ theme }) => theme.colors.gray5};
          width: 20rem;
        }
        > th:nth-child(1) {
          border-radius: 1rem 0rem 0rem 0rem;
        }
        > th:nth-last-child(2) {
          border-radius: 0rem 1rem 0rem 0rem;
        }
        > th {
          text-align: center;
          text-transform: uppercase;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          background-color: #cfded7;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;

          > div {
            text-transform: none;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: left;
            gap: 1rem;
            > div {
              font-weight: 400;
            }
          }
        }
      }
    }
    > tbody {
      > tr {
        > td {
          background-color: #f5f5f5;
          padding-inline: 3rem;
          text-align: center;
          font-size: 1.4rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;

          > input {
            border: none;
            height: 3rem;
            padding: 1rem;
          }
        }

        > td:nth-child(1) {
          text-align: center;
          > svg {
            margin-right: 1.2rem;
          }
        }
      }
    }
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 2rem;
  padding-top: 1rem;
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  align-self: flex-end;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1.3rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 4.6rem;
  cursor: pointer;
  > svg {
    font-size: 2rem;
  }
`;
