import styled from "styled-components";
const competencesColors = ["#7e255a", "#11817c", "#238ccc"];
const backgroundCompetencesColors = ["#f8f0f4", "#f3f9f8", "#eef7fc"];

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  width: fit-content;
  border-radius: 2rem;
  min-height: 54.5rem;

  > header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    padding: 4rem;
    > img {
      width: 5.2rem;
      height: 5.2rem;
    }

    > p {
      font-size: 2.2rem;
      text-align: center;
      color: #1a2341;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    ${({ level }) => !level && "flex: 1"};

    > p {
      padding: 6rem 4rem 2rem 4rem;
      width: 35.3rem;
      font-size: 1.8rem;
      color: ${({ theme }) => theme.colors.black};
    }

    > div:nth-child(1) {
      background-color: ${({ competence }) =>
        backgroundCompetencesColors[competence - 1]};
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      color: ${({ competence }) => competencesColors[competence - 1]};
      padding: 1rem;
      > p {
        font-size: 2.2rem;
      }

      > span {
        font-size: 5rem;
        font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
        font-weight: 600;
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;
      padding: 6rem 4rem 2rem 4rem;

      > img {
        width: 30rem;
      }
    }
  }
`;
