import Table from "@mui/material/Table";
import styled, { css } from "styled-components";

import AssessmentIcon from "@mui/icons-material/Assessment";

export const wrapperModifiers = {
  generalStylesIcons: css`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.textDarkGray};
    &&& {
      font-size: 2.8rem;
    }
    &:hover {
      opacity: 0.8;
      background-color: ${({ theme }) => theme.colors.textDarkGray};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.white};
      transition: background 0.5s;
    }
  `,
};

export const StyledAssessmentIcon = styled(AssessmentIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const Wrapper = styled.div``;

export const StyledTable = styled(Table)`
  &&& {
    box-shadow: 0 1rem 1.5rem 0.3rem rgb(0 0 0 / 10%) !important;
    > thead {
      > tr {
        > th:nth-child(1) {
          border-radius: 1rem 1rem 0rem 0rem;
          background-color: ${({ theme }) => theme.colors.gray5};
          width: 20rem;
        }
        > th:nth-child(2) {
          border-radius: 1rem 0rem 0rem 0rem;
        }
        > th:nth-last-child(1) {
          border-radius: 0rem 1rem 0rem 0rem;
        }
        > th {
          text-align: center;
          text-transform: uppercase;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          background-color: #cfded7;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          background-color: #f5f5f5;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          text-align: center;
          > input {
            border: none;
            height: 3rem;
            padding: 1rem;
          }
        }

        > td:nth-child(1) {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 2rem;
        }
      }
    }
  }
`;
