import styled from "styled-components";

export const Wrapper = styled.div`
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  background-color: white;
  width: 40rem;
  max-height: 140rem;
  padding: 6rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 1rem;
    padding-inline: 4rem;

    > h1 {
      color: ${({ theme }) => theme.colors.blue5};
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
    }

    > h2 {
      color: #597e32;
      font-size: 1.8rem;
      font-weight: 500;
      text-align: center;
    }
  }
`;

export const Content = styled.div`
  border: none;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 6rem;

  background-color: ${({ theme }) => theme.colors.white};
  > div:nth-child(1) {
    padding-inline: 4rem;
    > img {
      object-fit: contain;
      width: 100%;
    }
  }

  > div:nth-child(2) {
    > div {
      display: flex;
      align-items: center;
      min-height: 5rem;
      margin-bottom: 1rem;
      padding: 1rem 2rem;

      gap: 1rem;
      > div {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 5rem;
        color: ${({ theme }) => theme.colors.white};
        min-width: 5rem;
        background-color: #3c591d;
        margin-right: 0.5rem;
        font-size: 2.5rem;
        font-weight: 500;
        font-family: ${({ theme }) => theme.fonts.firaSans};
        border-radius: 1rem;
      }

      > p {
        font-size: 1.4rem;
        line-height: 1.5;
      }
    }

    > div:nth-child(even) {
      background-color: #f2f8ec;
      > div {
        background-color: #7eb846;
      }
    }
  }
`;
