import React from "react";
import * as S from "./styles";

export default function NetworkDataTable({
  register,
  title,
  type,
  getValues,
  setValue,
  setError,
  errors,
  clearErrors,
  isEditable,
  disabled,
  maxValuesType = null,
}) {
  const handleChangeValue = (event, type, maxValuesType, inputName) => {
    const lastState = getValues(`network.${type}_${inputName}`);

    if (!maxValuesType) {
      setValue(`network.${type}_${inputName}`, event.target.value);
      return;
    }
    const value = getValues(`network.${maxValuesType}_${inputName}`);

    if (value && Number(event.target.value) <= Number(value)) {
      setValue(`network.${type}_${inputName}`, event.target.value);
      clearErrors(`network.${type}_${inputName}`);
      return;
    }
    setValue(`network.${type}_${inputName}`, lastState);
    setError(`network.${type}_${inputName}`, {
      type: "custom",
      message: "max value",
    });
  };

  return (
    <S.Wrapper title={title}>
      <span>{title}</span>
      <table cellSpacing="0" cellPadding="0">
        <tbody>
          <tr>
            <td></td>
            <td>Quantidade</td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_amount_schools`),
                  }}
                  label="Escolas"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_amount_schools`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_amount_schools`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "amount_schools"
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_amount_teachers`),
                  }}
                  label="Docentes"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_amount_teachers`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_amount_teachers`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "amount_teachers"
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_amount_students`),
                  }}
                  label="Estudantes"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_amount_students`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_amount_students`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "amount_students"
                    )
                  }
                />
              </div>
            </td>
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td>
              <label>Nº de estudantes</label>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(
                      `network.${maxValuesType}_students_kindergarten`
                    ),
                  }}
                  label="Educação Infantil"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_students_kindergarten`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_students_kindergarten`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "students_kindergarten"
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_students_elem1`),
                  }}
                  label="Ens. Fund. Anos Iniciais"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_students_elem1`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "students_elem1"
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_students_elem2`),
                  }}
                  label="Ens. Fund. Anos Finais"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_students_elem2`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_students_elem2`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "students_elem2"
                    )
                  }
                />
              </div>
            </td>
            <td>
              <div>
                <S.StyledTextField
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    readOnly: !isEditable,
                    min: 0,
                    max: getValues(`network.${maxValuesType}_students_high`),
                  }}
                  label="Ensino Médio"
                  variant="standard"
                  defaultValue={0}
                  type="number"
                  disabled={disabled}
                  {...register(`network.${type}_students_high`)}
                  error={
                    errors["network"] &&
                    errors["network"][`${type}_students_high`]
                  }
                  onChange={(event) =>
                    handleChangeValue(
                      event,
                      type,
                      maxValuesType,
                      "students_high"
                    )
                  }
                />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </S.Wrapper>
  );
}
