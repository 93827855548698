import React from "react";
import * as S from "./styles";
import {
  OrbitalVision,
  OrbitalRed,
  OrbitalInfra,
  OrbitalCompetence,
} from "shared-components/Orbitals";
import Layout from "shared-components/Layout";
import FourLeafClover2 from "shared-components/FourLeafClover2/index.js";
import competence from "assets/images/home/icons/competence.svg";
import red from "assets/images/home/icons/red.svg";
import infra from "assets/images/home/icons/infra.svg";
import vision from "assets/images/home/icons/vision.svg";
import balance from "assets/images/home/icons/balance.svg";
import journey from "assets/images/home/icons/journey.svg";
import board from "assets/images/home/board.svg";
import womanLeft from "assets/images/home/woman-left.svg";
import womanLeft2 from "assets/images/home/woman-left2.svg";
import womanRight from "assets/images/home/woman-right.svg";
import womanRight2 from "assets/images/home/woman-right2.svg";
import callToAction from "assets/images/home/call-to-action.svg";
import ConnectedSchool from "assets/images/home/connected-school.png";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import SceneConnectedSchool from "assets/videos/home/connected-school.gif";
import mainVideo from "assets/videos/home.mp4";
import useScrollToElement from "hooks/use-scroll-to-element";
import InnerImageZoom from "react-inner-image-zoom";

export default function Home() {
  useScrollToElement();

  return (
    <Layout>
      <S.Header>
        <div>
          <video loop={true} autoPlay={true} muted={true} src={mainVideo}>
            <source src={mainVideo} type="video/mp4" />
          </video>
        </div>
        <div>
          <S.Heading>JORNADA ESCOLA CONECTADA</S.Heading>
          <S.Title>TRANSFORME SUAS ESCOLAS EM ESCOLAS CONECTADAS</S.Title>
          <S.SubTitle>
            Aqui você encontra conceitos, ferramentas e dicas para transformar
            as escolas da sua rede em escolas conectadas
          </S.SubTitle>
        </div>
      </S.Header>
      <S.Main>
        <S.ConnectedSchoolSection>
          <div>
            <div>
              <h3>Descubra o que é a</h3>
              <h1>ESCOLA CONECTADA</h1>
            </div>
            <p>
              Possui uma <span>visão estratégica e planejada</span> para
              incorporação da tecnologia em seu <span>currículo</span> e nas
              <span> práticas pedagógicas</span>, com{" "}
              <span>equipe capacitada</span> para uso de tecnologia, que utiliza{" "}
              <span>recursos educacionais digitais</span> selecionados e que
              dispõe de <span>equipamentos e conectividade</span> adequada.
            </p>

            <p>
              Sendo capaz de ofertar ensino híbrido, utilizando a tecnologia
              para ampliar o tempo, espaço e ritmo das experiências de
              aprendizagem dos/as estudantes.
            </p>
          </div>
          <div>
            <img src={SceneConnectedSchool} alt="" loading="lazy" />
          </div>
        </S.ConnectedSchoolSection>

        <S.CallToAction>
          <div>
            <img src={callToAction} alt="" loading="lazy" />
          </div>
          <div>
            <h3>
              Agora que você já sabe o<br /> que é escola conectada
            </h3>
            <h1>VAMOS LÁ?</h1>
          </div>
        </S.CallToAction>

        <S.OrbitalSection>
          <div>
            <div>
              <img src={journey} alt="" loading="lazy" />
            </div>
            <div>
              <h3>JORNADA DE</h3>
              <h1>TRANSFORMAÇÃO DIGITAL</h1>
            </div>
          </div>
          <div>
            <img src={board} alt="" loading="lazy" />
            <img src={womanRight} alt="" loading="lazy" />
            <img src={womanLeft2} alt="" loading="lazy" />
            <img src={womanRight2} alt="" loading="lazy" />
            <img src={womanLeft} alt="" loading="lazy" />
            <p>
              Se você não sabe por onde começar, dê o primeiro passo começando
              por aqui
            </p>

            <OrbitalVision id="visao" large />
            <OrbitalCompetence id="competencia" large />
            <OrbitalRed id="red" large />
            <OrbitalInfra id="infraestrutura" large />
          </div>
        </S.OrbitalSection>

        <S.SchoolSection>
          <InnerImageZoom
            src={ConnectedSchool}
            zoomScale={1.3}
            imgAttributes={{ alt: "Escola  Conectada" }}
          />
        </S.SchoolSection>

        <S.DimensionsSection>
          <section>
            <div>
              <img src={balance} alt="" loading="lazy" />
            </div>

            <div>
              <h1>As quatro dimensões em equilíbrio</h1>
              <p>
                As jornadas de transformação digital e suas ferramentas foram
                elaboradas a partir do conceito do equilíbrio das 4 dimensões:
              </p>
            </div>
          </section>

          <section>
            <S.Box dimension="vision" className="box-vision">
              <div>
                <img src={vision} alt="" loading="lazy" />
              </div>
              <h1>VISÃO</h1>
              <p>
                Secretaria de Educação, gestores escolares, docentes e
                estudantes compartilham uma visão estratégicas e planejada sobre
                como deve ser o uso de tecnologias digitais para atingir os
                objetivos educacionais priorizados pela Rede.
              </p>
            </S.Box>

            <S.Box dimension="red" className="box-red">
              <div>
                <img src={red} alt="" loading="lazy" />
              </div>
              <h1>RECURSOS EDUCACIONAIS DIGITAIS</h1>
              <p>
                Recursos Educacionais Digitais implementados e alinhados com a
                visão educacional da Rede, que facilitem, potencializem e apoiem
                as atividades de docentes, estudantes e gestores(as).
              </p>
            </S.Box>

            <FourLeafClover2 className="four-leaf-clover" />

            <S.Box dimension="competence" className="box-competence">
              <div>
                <img src={competence} alt="" loading="lazy" />
              </div>
              <h1>COMPETÊNCIAS DIGITAIS</h1>
              <p>
                Docentes da rede com competências digitais desenvolvidas,
                capazes de usar as tecnologias digitais de forma inovadora,
                inclusive para planejamento e implementação do Ensino Híbrido.
              </p>
            </S.Box>

            <S.Box dimension="infra" className="box-infra">
              <div>
                <img src={infra} alt="" loading="lazy" />
              </div>
              <h1>INFRAESTRUTURA</h1>
              <p>
                Escolas equipadas com infraestrutura e conectividade adequadas,
                de acordo com o planejamento pedagógico da rede de ensino.
              </p>
            </S.Box>
          </section>
        </S.DimensionsSection>
      </S.Main>
    </Layout>
  );
}
