import React from "react";
import * as S from "./styles";

export default function Select(props) {
  return (
    <S.StyledSelect
      {...props}
      menuPortalTarget={document.getElementById("root")}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
    />
  );
}
