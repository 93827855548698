import React from "react";
import * as S from "./styles";

function PercentageChart({ label, amount, progress }) {
  return (
    <S.Wrapper data-progress={progress} progress={progress} label={label}>
      <div>
        <span />
        <div>
          <span>{progress}%</span>
          <p>{amount}</p>
        </div>
      </div>
      <div>
        <p>{label}</p>
      </div>
    </S.Wrapper>
  );
}

export default PercentageChart;
