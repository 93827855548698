import React from "react";
import * as S from "./styles";
import CardParameters from "../../components/CardParameters";
import Layout from "shared-components/Layout";

import IMAGE_01_01 from "assets/images/calculadora/019.svg";
import IMAGE_01_02 from "assets/images/calculadora/022.svg";
import IMAGE_01_03 from "assets/images/calculadora/025.svg";

import IMAGE_02_01 from "assets/images/calculadora/020.svg";
import IMAGE_02_02 from "assets/images/calculadora/023.svg";
import IMAGE_02_03 from "assets/images/calculadora/026.svg";

import IMAGE_03_01 from "assets/images/calculadora/021.svg";
import IMAGE_03_02 from "assets/images/calculadora/024.svg";
import IMAGE_03_03 from "assets/images/calculadora/027.svg";

import { Card } from "../../components/CardParameters";

const CARD_PARAMETERS_DATA: Card[] = [
  {
    id: "0101",
    title: "EQUIPAMENTO PARA AS SALAS DE AULA",
    image: IMAGE_01_01,
    level: "BÁSICO",
    parameters: [
      {
        amount: 1,
        description: "Kit de dispositivos para cada 8 salas de aula",
      },
      {
        amount: 1,
        description: "Access Point (wi fi) para cada 4 salas de aula",
      },
      {
        amount: 1,
        description:
          "computador para cada 8 salas de aula (para uso do professor em sala)",
      },
      { amount: 1, description: "projetor ou tv para cada 8 salas de aula" },
    ],
  },
  {
    id: "0201",
    title: "EQUIPAMENTO PARA AS SALAS DE AULA",
    image: IMAGE_02_01,
    level: "INTERMEDIÁRIO",
    parameters: [
      {
        amount: 1,
        description: "Kit de equipamentos para cada 5 salas de aula",
      },
      {
        amount: 1,
        description: "Wi-fi (access point) para cada 2 salas de aula",
      },
      {
        amount: 1,
        description:
          "Computador de uso dos professores para cada 5 salas de aula",
      },
      { amount: 1, description: "Projetor ou TV para cada 5 salas de aula" },
    ],
  },
  {
    id: "0301",
    title: "EQUIPAMENTO PARA AS SALAS DE AULA",
    image: IMAGE_03_01,
    level: "AVANÇADO",
    parameters: [
      {
        amount: 1,
        description: "Kit de equipamentos para cada 2 salas de aula",
      },
      {
        amount: 1,
        description: "Wi-fi (access point) para cada 2 salas de aula",
      },
      { amount: 1, description: "Computador para cada 2 salas de aula" },
      { amount: 1, description: "Projetor ou TV para cada 2 salas de aula" },
    ],
  },
  {
    id: "0102",
    title: "EQUIPAMENTO DE CONECTIVIDADE PARA TODA ESCOLA",
    image: IMAGE_01_02,
    level: "BÁSICO",
    parameters: [
      {
        amount: 1,
        description: "Wi-fi (access point) para a área administrativa",
      },
      {
        amount: 1,
        description: "Wi-fi (access point) para a sala dos professores",
      },
      { amount: 1, description: "Switch layer 3 (24 portas no mínimo)" },
      {
        amount: 1,
        description:
          "Rack 6U ou 8U por escola para switch, roteador, firewall, servidores e/ou outros equipamentos que houverem na escola",
      },
      {
        amount: 1,
        description:
          "Nobreak para proteger equipamentos que estiverem no Rack de oscilações de energia",
      },
      {
        amount: 1,
        description:
          "Rack 6U ou 8U por escola para switch, roteador, firewall, servidores e/ou outros equipamentos que houverem na escInfraestrutura de cabeamento para cada 4 salas (1 por access point)",
      },
      {
        amount: "",
        description:
          "Não há necessidade de firewall, pode-se utilizar o filtro switch",
      },
    ],
  },
  {
    id: "0202",
    title: "EQUIPAMENTO DE CONECTIVIDADE PARA TODA ESCOLA",
    image: IMAGE_02_02,
    level: "INTERMEDIÁRIO",
    parameters: [
      { amount: 1, description: "Switch layer 3 (24 portas no mínimo)" },
      { amount: 1, description: "Firewall para toda a escola" },
      { amount: 1, description: "Access point para a área administrativa" },
      { amount: 1, description: "Access point para a sala dos professores" },
      {
        amount: 1,
        description:
          "Rack 6U ou 8U por escola para switch, roteador, firewall, servidores e/ou outros equipamentos que houverem na escola",
      },
      {
        amount: 1,
        description:
          "Nobreak para proteger equipamentos que estiverem no Rack de oscilações de energia",
      },
      {
        amount: 1,
        description:
          "Infraestrutura de cabeamento para cada 2 salas de aula (1 por access point)",
      },
    ],
  },
  {
    id: "0302",
    title: "EQUIPAMENTO DE CONECTIVIDADE PARA TODA ESCOLA",
    image: IMAGE_03_02,
    level: "AVANÇADO",
    parameters: [
      { amount: 1, description: "Switch layer 3 (24 portas no mínimo)" },
      { amount: 1, description: "Firewall para toda a escola" },
      { amount: 2, description: "Access point para a área administrativa" },
      { amount: 1, description: "Access point para sala dos professores" },
      {
        amount: 2,
        description:
          "Access point para locais de uso comum na escola (pátio, corredores, refeitório, etc)",
      },
      {
        amount: 2,
        description:
          "Rack 6U ou 8U por escola para switch, roteador, firewall, servidores e/ou outros equipamentos que houverem na escola",
      },
      {
        amount: 1,
        description:
          "Nobreak para proteger equipamentos que estiverem no Rack de oscilações de energia",
      },
      {
        amount: "",
        description:
          "Infraestrutura de cabeamento para cada 2 salas de aula (1 para cada access point)",
      },
    ],
  },
  {
    id: "0103",
    title: "OUTROS EQUIPAMENTOS",
    image: IMAGE_01_03,
    level: "BÁSICO",
    parameters: [
      {
        amount: 1,
        description:
          "impressora multifuncional para ser compartilhada entre equipe gestora e professores;",
      },
      {
        amount: 1,
        description: "carrinho de carregamento para cada 8 salas de aula",
      },
      {
        amount: 1,
        description:
          "Entre 5 e 10 dispositivos para serem compartilhados entre a equipe gestora e professores",
      },
    ],
  },
  {
    id: "0203",
    title: "OUTROS EQUIPAMENTOS",
    image: IMAGE_02_03,
    level: "INTERMEDIÁRIO",
    parameters: [
      {
        amount: 1,
        description:
          "Impressora multifuncional para ser compartilhada entre equipe gestora e professores",
      },
      {
        amount: 1,
        description: "Carrinho de carregamento para cada 5 salas de aula",
      },
      {
        amount: 1,
        description:
          "Entre 6 e 11 dispositivos para serem compartilhados entre a equipe gestora e professores",
      },
    ],
  },
  {
    id: "0303",
    title: "OUTROS EQUIPAMENTOS",
    image: IMAGE_03_03,
    level: "AVANÇADO",
    parameters: [
      { amount: 1, description: "Dispositivo para cada professor da escola" },
      {
        amount: 1,
        description: "Computador por colaborador da equipe gestora",
      },
      {
        amount: 2,
        description:
          "Impressoras multifuncional para ser compartilhada entre equipe gestora e professores",
      },
      {
        amount: 1,
        description: "Ponto de carregamento para cada 2 salas de aula",
      },
    ],
  },
];

export default function InfraCalculatorParameters() {
  return (
    <Layout hasNavigationBar name="infraCalculator">
      <S.Wrapper>
        <header></header>
        <div>
          <article>
            <div>
              <p>
                A ferramenta considera três níveis de adoção de tecnologia
                digital e cada uma delas possui premissas e parâmetros
                específicos.
              </p>
            </div>
            <div>
              {CARD_PARAMETERS_DATA.map((card: Card) => (
                <CardParameters card={card} key={`${card?.id}`} />
              ))}
            </div>
          </article>
        </div>
      </S.Wrapper>
    </Layout>
  );
}
