import styled, { css } from "styled-components";

import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

const top = css`
  margin-bottom: 7rem;
`;

const bottom = css`
  margin-top: 8rem;
`;

const wrapperModifiers = {
  top,
  bottom,
};

export const Wrapper = styled.section`
  display: flex;
  justify-content: ${({ step }) => (step > 0 ? "space-between" : "flex-end")};
  ${({ position }) => wrapperModifiers[position]}
`;

export const NextIcon = styled(ArrowForwardIosIcon)``;

export const PreviousIcon = styled(ArrowBackIosNewIcon)``;
