import { createContext, useContext, useMemo, useState } from "react";

const InfraCalculatorFilterContext = createContext();

function InfraCalculatorFilterProvider({ children }) {
  const [stageSelected, setStageSelected] = useState(null);

  const [showGeneralFilterModal, setShowGeneralFilterModal] = useState(false);

  const [lastCycles, setLastCycles] = useState({
    penultimate: false,
    last: false,
  });

  const [selectedCycle, setSelectedCycle] = useState({
    label: "TODOS",
    value: "",
  });

  const showGeneralFilterModalMemo = useMemo(
    () => showGeneralFilterModal,
    [showGeneralFilterModal]
  );

  return (
    <InfraCalculatorFilterContext.Provider
      value={{
        stageSelected,
        setStageSelected,
        lastCycles,
        setLastCycles,
        selectedCycle,
        setSelectedCycle,
        setShowGeneralFilterModal,
        showGeneralFilterModal: showGeneralFilterModalMemo,
      }}
    >
      {children}
    </InfraCalculatorFilterContext.Provider>
  );
}

const useInfraCalculatorFilter = () => useContext(InfraCalculatorFilterContext);

export { InfraCalculatorFilterProvider, useInfraCalculatorFilter };
