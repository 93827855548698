import * as S from "./styles";
import SceneCompetence from "assets/videos/jornada-competencia/scene-competence.gif";
import SceneVision from "assets/videos/jornada-visao/scene-vision.gif";
import SceneRed from "assets/videos/jornada-red/scene-red.gif";
import SceneInfra from "assets/videos/jornada-infra/scene-infra.gif";
import PropTypes from "prop-types";

const DIMENSIONS = {
  vision: { scene: SceneVision, title: "VISÃO" },
  competence: { scene: SceneCompetence, title: "COMPETÊNCIAS DIGITAIS" },
  red: { scene: SceneRed, title: "RECURSOS EDUCACIONAIS DIGITAIS" },
  infra: { scene: SceneInfra, title: "INFRAESTRUTURA" },
};

function Header({ dimension }) {
  return (
    <S.Wrapper>
      <div>
        <img src={DIMENSIONS[dimension].scene} alt="" />
      </div>
      <div>
        <h3>Bem-vindo (a) à</h3>
        <h1>
          JORNADA DE <br />
          {DIMENSIONS[dimension].title}
        </h1>
      </div>
    </S.Wrapper>
  );
}

Header.propTypes = {
  dimension: PropTypes.string.isRequired,
};

export default Header;
