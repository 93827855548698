import React from "react";
import * as S from "./styles";
import dayjs from "dayjs";

import {
  TableContainer,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableFooter,
} from "@mui/material";

import Targets from "../Targets";
import { GoalActionsTableProvider } from "hooks/use-goal-actions-table";
import { convertToMoneyFormat } from "utils/money";

const STATUS = {
  concluded: "Concluído",
  in_progress: "Em andamento",
  not_started: "Não iniciado",
  late: "Atrasado",
};

export default function FollowUpTable({
  dimension,
  title,
  data,
  goalIndex,
  goalId,
  image,
  id,
}) {
  return (
    <GoalActionsTableProvider dimension={dimension}>
      <S.Wrapper id={id}>
        <S.TitleWrapper dimension={dimension}>
          <div>DIMENSÃO</div>
          <div>
            <h1>{title}</h1>
            <img src={image} alt="" />
          </div>
        </S.TitleWrapper>
        <S.DescriptionWrapper dimension={dimension}>
          <div>OBJETIVO ESPECÍFICO</div>
          <div>
            <div>
              <p>
                <strong>{goalIndex + 1}.</strong> {data?.description}
              </p>
            </div>
            <div>
              <span>ORÇAMENTO TOTAL</span>
              <p>{convertToMoneyFormat(data?.totalBudget)}</p>
            </div>
          </div>
        </S.DescriptionWrapper>

        <Targets dimension="dimension" goalId={goalId} />
        <h1>Ações</h1>
        <S.DataWrapper>
          <TableContainer>
            <S.StyledTable aria-label="simple table" dimension={dimension}>
              <TableHead>
                <TableRow>
                  <TableCell>Nº</TableCell>
                  <TableCell>AÇÕES</TableCell>
                  <TableCell>INÍCIO</TableCell>
                  <TableCell>FIM</TableCell>
                  <TableCell>STATUS</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.actions.map((action, index) => (
                  <TableRow
                    key={`${dimension}_goal_${goalIndex}_action${index}`}
                  >
                    <TableCell>
                      <p>{index + 1}</p>
                    </TableCell>
                    <TableCell>
                      <p>{action?.action}</p>
                    </TableCell>
                    <TableCell>
                      <p>{dayjs(action?.start).format("DD/MM/YYYY")}</p>
                    </TableCell>
                    <TableCell>
                      <p>{dayjs(action?.end).format("DD/MM/YYYY")}</p>
                    </TableCell>
                    <S.StyledTableCell status={action?.status?.name}>
                      <p>{STATUS[action?.status?.name]}</p>
                    </S.StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <p>{data?.actions?.length}</p>
                </TableRow>
              </TableFooter>
            </S.StyledTable>
          </TableContainer>
        </S.DataWrapper>
      </S.Wrapper>
    </GoalActionsTableProvider>
  );
}
