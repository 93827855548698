import React, { useCallback, useEffect, useState } from "react";
import CardReferenceValues from "../../components/CardReferenceValues";
import { getStorageAuthenticityToken } from "utils/local-storage";
import CircularProgress from "@mui/material/CircularProgress";
import Layout from "shared-components/Layout";
import axios from "axios";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";

import carrinho from "assets/images/calculadora/valores-de-referencia/devices/carrinho.jpg";
import computer from "assets/images/calculadora/valores-de-referencia/devices/computer.jpg";
import firewall from "assets/images/calculadora/valores-de-referencia/devices/firewall.jpg";
import nobreak from "assets/images/calculadora/valores-de-referencia/devices/nobreak.jpg";
import notebooks from "assets/images/calculadora/valores-de-referencia/devices/notebooks.jpg";
import printers from "assets/images/calculadora/valores-de-referencia/devices/printers.jpg";
import projector from "assets/images/calculadora/valores-de-referencia/devices/projector.jpg";
import rack from "assets/images/calculadora/valores-de-referencia/devices/rack.jpg";
import robotica from "assets/images/calculadora/valores-de-referencia/devices/robotica.jpg";
import switchDevice from "assets/images/calculadora/valores-de-referencia/devices/switch.jpg";
import wifi from "assets/images/calculadora/valores-de-referencia/devices/wifi.jpg";

export const DEVICES: Record<string, string> = {
  carrinho,
  computer,
  firewall,
  nobreak,
  notebooks,
  printers,
  projector,
  rack,
  robotica,
  switch: switchDevice,
  wifi,
};

export interface InfraModalData {
  [key: string]: {
    titulo: string;
    o_que_e: string;
    quem_usa: string;
    onde_encontrar: string;
    imageName: string;
  };
}

const INFRA_MODAL_DATA: InfraModalData = {
  device: {
    titulo: "Computador",
    o_que_e:
      "Normalmente são notebooks, em alguns casos computadores de mesa em locais específicos.",
    quem_usa: "Destinados apenas para uso dos professores da escola.",
    onde_encontrar:
      "Podem estar disponíveis na sala de professores, disponíveis para os professores levar para sala de aula ou fixos dentro das salas de aula.",
    imageName: "computer",
  },
  notebooks: {
    titulo: "Dispositivo para uso dos estudantes",
    o_que_e:
      "Podem ser notebooks, cloudbooks, tablets e outros que possibilitam o uso de aplicativos, ferramentas e sites",
    quem_usa: "Estudantes",
    onde_encontrar:
      "Podem estar disponíveis na sala de professores para serem levados à sala de aula ou fixos dentro das salas de aula.",
    imageName: "notebooks",
  },
  printers: {
    titulo: "Impressoras/Multifuncional",
    o_que_e:
      "São dispositivos para impressão de conteúdos em papel, que em alguns casos possuem múltiplas funções, como: digitalizar um documento impresso.",
    quem_usa:
      "Em sua maioria são disponibilizados para uso dos funcionários administrativos da escola, também podem estar disponíveis para os professores.",
    onde_encontrar:
      "Na diretoria, secretaria escolar e/ou sala de professores.",
    imageName: "printers",
  },
  rack: {
    titulo: "Rack de equipamentos",
    o_que_e:
      "É um tipo de armário fechado feito de metal (aço carbono) e tampa frontal de vidro ou acrílico, onde ficam guardados os equipamentos. É dentro do rack que são instalados os equipamentos de rede (modem, roteador, switch, firewall, servidor de cache e até alguns modelos de nobreak), pode ser um armário grande apoiado no chão, ou pequeno preso na parede.",
    quem_usa: "Técnicos em informática da escola e/ou terceirizado.",
    onde_encontrar:
      "Geralmente são instalados na sala do diretor, salas da secretaria escolar ou laboratório de informática.",
    imageName: "rack",
  },
  nobreak: {
    titulo: "Nobreak",
    o_que_e:
      "Equipamento que utiliza da carga de baterias instaladas em seu interior para alimentar os aparelhos, enquanto se recarregam com a energia oriunda da rede. Quando ocorre uma queda de energia ele passa a alimentar os aparelhos com a energia acumulada nas baterias por alguns minutos, até que a energia se restabeleça.",
    quem_usa:
      "Geralmente é utilizado por técnicos de informática ou funcionários administrativos que cuidam dos equipamentos tecnológicos.",
    onde_encontrar:
      "Geralmente são instalados na sala do diretor, salas da secretaria escolar ou laboratório de informática dentro do rack de equipamentos.",
    imageName: "nobreak",
  },
  switch: {
    titulo: "Switch",
    o_que_e:
      'São equipamentos que conectam todos os dispositivos na rede, organizando a troca de dados entre eles. Possuem número variado de conectores (chamados de "portas") para ligar os cabos de redes e os mais comuns são os de 8, 12, 16, 24, 32 ou 48 portas.',
    quem_usa: "Técnicos em informática da escola e/ou terceirizado.",
    onde_encontrar:
      "Geralmente são instalados na sala do diretor, salas da secretaria escolar ou laboratório de informática dentro do rack de equipamentos.",
    imageName: "switch",
  },
  firewall: {
    titulo: "Firewall",
    o_que_e:
      'Controla a transferência de dados entre o computador e a internet. Dessa forma, impede que o dispositivo (ex.: computador, celular, tablet) ou a rede sejam invadidos por hackers ou acessados por softwares maliciosos O firewall pode ser um programa ou um computador dedicado apenas a cumprir a função de "guardião" da rede.',
    quem_usa: "Técnicos em informática da escola e/ou terceirizado.",
    onde_encontrar:
      "Geralmente são instalados na sala do diretor, salas da secretaria escolar ou laboratório de informática dentro do rack de equipamentos.",
    imageName: "firewall",
  },
  access_point: {
    titulo: "Wifi/Access Point",
    o_que_e:
      "<em>Access Point</em>, cuja tradução é ponto de acesso, são os equipamentos utilizados para transmitir o sinal da rede Wifi pela escola. Também são conhecidos como roteador sem fio",
    quem_usa:
      "Geralmente são configurados e mantidos pelos técnicos em informática da escola e/ou terceirizado, mas são utilizados por todos que acessam a internet da escola.",
    onde_encontrar:
      "Podem ser instalados em qualquer lugar da escola, preferencialmente em locais centrais, corredores, pátio etc. Geralmente estão fixados no teto ou em estão em local alto que facilita a difusão do sinal sem fio.",
    imageName: "wifi",
  },
  projector: {
    titulo: "Projetor Multimídia",
    o_que_e:
      "É um equipamento que projeta em paredes ou estruturas próprias uma imagem da tela de um computador utilizando tecnologia baseada em uma lâmpada.",
    quem_usa: "Professores para apresentação de conteúdo.",
    onde_encontrar:
      "Geralmente são instalados no laboratório de informática, em salas de aula e/ou auditórios, ou estão disponíveis para que os professores levem para sala de aulas.",
    imageName: "projector",
  },
  charger: {
    titulo: "Carrinho Carregador",
    o_que_e:
      "Armazena e carrega de 20 a 60 dispositivos simultaneamente dependendo do tamanho. Possui rodas que oferecem mobilidade para atender diferentes salas e ambientes da escola.",
    quem_usa:
      "Pode ser usado por docentes, assistentes e demais funcionários da escola.",
    onde_encontrar:
      "Devido a facilidade de transporte, podem ser encontrados em salas de aula, salas administrativas e demais ambientes",
    imageName: "carrinho",
  },
  maker: {
    titulo: "Kit de robótica",
    o_que_e:
      "É um kit especial com peças e instruções para montagem de um robô, especialmente com movimentação autônoma. São formados pelos seguintes componentes básicos, como: partes mecânicas, motores, circuito elétrico, circuito eletrônico, sensores etc.",
    quem_usa: "Professores e alunos para uso pedagógico.",
    onde_encontrar:
      "Geralmente são armazenados no laboratório de informática ou até mesmo em sala de aula.",
    imageName: "maker",
  },
};

const CARD_REFERENCE_VALUES_DATA = [
  {
    name: "Dispositivo para uso dos estudantes",
    imageName: "notebooks",
    key: "notebooks",
  },
  { name: "Carrinho", imageName: "carrinho", key: "charger" },
  { name: "Projetor", imageName: "projector", key: "projector" },
  { name: "Rack", imageName: "rack", key: "rack" },
  { name: "Switch", imageName: "switch", key: "switch" },
  { name: "Wifi/Access Point", imageName: "wifi", key: "access_point" },
  { name: "Nobreak", imageName: "nobreak", key: "nobreak" },
  { name: "Impressora", imageName: "printers", key: "printers" },
  { name: "Computador", imageName: "computer", key: "device" },
  { name: "Firewall", imageName: "firewall", key: "firewall" },
];

export interface Schedule {
  answer: [];
  id: { $oid: string };
  is_cyclic: boolean;
  name: string;
  survey_description: string;
  survey_name: string;
  survey_end_date: Date;
  survey_start_date: Date;
  mec2018: boolean;
}

export interface Survey {
  id: string;
  is_cyclic: boolean;
  schedule: Schedule[];
  type: string;
}

const CONF = process.env.REACT_APP_BASE_URL;

export default function InfraCalculatorReferenceValues() {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [equipamentSelected, setEquipamentSelected] = useState<string>("");
  const [scheduleId, setScheduleId] = useState<string>("");
  const [referencesValues, setReferencesValues] = useState<
    Record<string, number>
  >({});
  const [canEdit, setCanEdit] = useState<boolean[]>([]);
  const [dataIsLoading, setDataIsLoading] = useState(true);

  const navigate = useNavigate();

  const getSurveysList = useCallback(async () => {
    try {
      const params = { access_token: getStorageAuthenticityToken() };
      const response = await axios.get(CONF + "/api/v1/survey/surveys_list", {
        params,
      });
      const { surveys } = response.data;
      const filteredSurvey = surveys.find(
        (survey: Survey) => survey.type === "school"
      );
      const scheduleId = filteredSurvey.schedule[0].id.$oid;
      setScheduleId(scheduleId);
    } catch (error: any) {
      console.log(error.message);
    }
  }, []);

  useEffect(() => {
    getSurveysList();
  }, [getSurveysList]);

  useEffect(() => {
    getReferencesValues(scheduleId);
  }, [scheduleId]);

  const getReferencesValues = async (scheduleId: string) => {
    try {
      const params = {
        access_token: getStorageAuthenticityToken(),
        survey_schedule_id: scheduleId,
      };
      setDataIsLoading(true);
      const response = await axios.get(CONF + "/api/v1/calc_infra_reference", {
        params,
      });
      console.log(response.data);
      setReferencesValues(response.data);
      setDataIsLoading(false);
      // setReferencesValuesBackup({ ...response.data });
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const setInfraCustomValue = async () => {
    // setCanEdit(!canEdit);
    if (!canEdit) return;
    // setSaveIsLoading(true);
    try {
      await axios.post(
        CONF +
          `/api/v1/calc_infra_custom_reference?access_token=${getStorageAuthenticityToken()}&survey_schedule_id=${scheduleId}`,
        {
          values: referencesValues,
        }
      );
      // setSaveIsLoading(false);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  const setReferenceValue = (key: string, value: number) => {
    const referencesValuesAux = { ...referencesValues };
    referencesValuesAux[key] = value;
    setReferencesValues(referencesValuesAux);
  };

  const selectEquipament = (equipamentSelected: string) => {
    setEquipamentSelected(equipamentSelected);
    setShowModal(true);
  };

  // const cancelReferencesValues = () => {
  //   setCanEdit(false);
  //   setReferencesValues({ ...referencesValuesBackup });
  // };

  return (
    <Layout hasNavigationBar name="infraCalculator">
      <S.Wrapper>
        {/* <Fab /> */}
        <S.Header></S.Header>
        <S.Content>
          {dataIsLoading ? (
            <div className="loading">
              <CircularProgress size={30} />
            </div>
          ) : (
            <div>
              <article>
                <div>
                  <p>
                    Aqui você encontra os valores de referência dos equipamentos
                    que são usados para cálculo do investimento.
                  </p>
                  <p>
                    Revise e ajuste o valor de cada equipamento de acordo com a
                    realidade local, se necessário. Basta clicar em “editar
                    valores”, fazer os ajustes, e clicar em “salvar”.
                  </p>
                  <p>Clique em Calcular para seguir para a próxima etapa.</p>
                </div>
                <div>
                  {CARD_REFERENCE_VALUES_DATA.map((card, index) => (
                    <CardReferenceValues
                      card={card}
                      key={card.name}
                      selectEquipament={selectEquipament}
                      references_values={referencesValues}
                      setReferenceValue={setReferenceValue}
                      canEdit={canEdit}
                      setCanEdit={setCanEdit}
                      index={index}
                      setInfraCustomValue={setInfraCustomValue}
                    />
                  ))}
                </div>
              </article>
              <S.Start>
                <button
                  onClick={() =>
                    navigate("/calculadora-de-infraestrutura/investimento")
                  }
                >
                  CALCULAR
                </button>
              </S.Start>
            </div>
          )}
        </S.Content>

        <Modal title="" open={showModal} onClose={setShowModal}>
          <S.EquipamentsModal>
            <div>
              <img
                src={DEVICES[INFRA_MODAL_DATA[equipamentSelected]?.imageName]}
                alt=""
              />
              <h2>{INFRA_MODAL_DATA[equipamentSelected]?.titulo}</h2>
            </div>
            <div>
              <div>
                <span>O que é?</span>
                <hr />
                <p
                  dangerouslySetInnerHTML={{
                    __html: INFRA_MODAL_DATA[equipamentSelected]?.o_que_e,
                  }}
                />
              </div>
              <div>
                <span>Quem usa?</span>
                <hr />
                <p
                  dangerouslySetInnerHTML={{
                    __html: INFRA_MODAL_DATA[equipamentSelected]?.quem_usa,
                  }}
                />
              </div>
              <div>
                <span>Onde encontrar?</span>
                <hr />
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      INFRA_MODAL_DATA[equipamentSelected]?.onde_encontrar,
                  }}
                />
              </div>
            </div>
          </S.EquipamentsModal>
        </Modal>
      </S.Wrapper>
    </Layout>
  );
}
