import { container } from "global-styles/shared-styles";
import styled from "styled-components";
import banner from "assets/images/red-demands/banner.svg";

export const Wrapper = styled.div`
  margin: 7rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  font-size: 1.5rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;

  > section {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.blue5};
  }

  > section:nth-child(2) {
    ${container};
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > span {
      font-size: 2.4rem;
    }

    > h1 {
      font-size: 4rem;
    }
  }

  > section:nth-child(3) {
    ${container};
    display: flex;
    flex-direction: column;
    gap: 2rem;

    > h2 {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.5;
    }

    > p {
      font-size: 2rem;
      line-height: 1.5;
    }
  }

  > section:nth-child(4) {
    ${container};
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    background-color: #d6e4e8;
    padding: 10rem 0rem;
    gap: 6rem;

    > div {
      ${container};
    }
    > div:nth-child(1) {
      position: relative;
      > p {
        font-size: clamp(1.2rem, 1.2vw, 1.8rem);
      }
      > p:nth-child(2) {
        position: absolute;
        top: 63%;
        transform: translateY(-50%);
        left: 13%;
        color: ${({ theme }) => theme.colors.white};
      }
      > p:nth-child(3) {
        right: 11%;
        top: 63%;
        transform: translateY(-50%);
        position: absolute;
        color: ${({ theme }) => theme.colors.black};
      }
    }

    > div:nth-child(2) {
      display: flex;
      justify-content: center;
      gap: 2rem;

      > div {
        flex: 1 1 12rem;
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 2rem;
        > div {
          font-weight: 600;
          font-size: 2.2rem;
        }
        > div:nth-child(2) {
          min-width: 12rem;
          height: 16rem;
          background-color: ${({ theme }) => theme.colors.white};
          border-radius: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          > p {
            font-weight: 500;
            text-align: center;
            font-size: clamp(1.6rem, 1.5vw, 1.8rem);
            line-height: 1.5;
          }
        }
      }
    }
  }

  > section:nth-child(6) {
    display: flex;
    justify-content: center;
    gap: 2rem;
    > button {
      border: none;
      background-color: #054159;
      padding: 1rem 2rem;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      border-radius: 1rem;
      font-size: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 52rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;

export const SubmitFormModalConfirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  gap: 3rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue5};

  > img {
    width: 12rem;
  }
  > h2 {
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
  }

  > div {
    display: flex;
    gap: 2rem;
    margin-top: 2rem;
    > button {
      border: none;
      background-color: #054159;
      padding: 1rem 2rem;
      color: ${({ theme }) => theme.colors.white};
      font-size: 1.6rem;
      border-radius: 1rem;
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;

export const SubmitFormModalSuccess = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  gap: 3rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue5};

  > img {
    width: 12rem;
  }
  > h2 {
    font-size: 2.2rem;
    font-weight: 500;
    text-align: center;
  }

  > button {
    border: none;
    background-color: #054159;
    padding: 2rem 2rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`;
