import React from "react";
import * as S from "./styles";
import PropTypes from "prop-types";

function Box({ children, width, height }) {
  return (
    <S.Wrapper width={width} height={height}>
      {children}
    </S.Wrapper>
  );
}

Box.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default Box;
