import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/calculadora/010.svg";

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-family: ${({ theme }) => theme.fonts.poppins};
  background-color: #f0f0f0;

  > header {
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #85c440;
  }

  .content {
    padding-right: 3rem;
    padding-left: 1.5rem;
    article {
      ${container};
      .description {
        > p {
          font-size: 2rem;
          margin-bottom: 2rem;
          line-height: 1.5;
        }

        > span {
          font-weight: 500;
        }

        margin-bottom: 5rem;

        > div:nth-child(3) {
          display: flex;
          gap: 4rem;
          flex-wrap: wrap;
          justify-content: space-between;
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          padding: 4rem 6rem;
          background-color: ${({ theme }) => theme.colors.white};
          margin-top: 5rem;
          margin-bottom: 5rem;
          > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            > div:nth-child(1) {
              > p {
                font-size: 2rem;
                > span:nth-child(1) {
                  font-size: 3.4rem;
                }

                > span:nth-child(2) {
                  color: #9e9e9e;
                  margin-right: 1rem;
                }
              }
            }

            > div:nth-child(3) {
              > p {
                font-size: 2rem;
                > span {
                  color: #94c85d;
                }
              }
            }
          }
          > div:nth-child(2) {
            > img {
              width: 27rem;
            }
          }
        }

        > div:nth-child(5) {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          flex-wrap: wrap;
          justify-content: space-between;
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          padding: 4rem 6rem;
          background-color: ${({ theme }) => theme.colors.white};
          margin-top: 5rem;
          margin-bottom: 5rem;

          > h1 {
            font-weight: 500;
            font-size: 2.2rem;
            color: ${({ theme }) => theme.colors.blue5};
          }

          > p {
            font-size: 1.8rem;
          }
          > a {
            text-decoration: none;
            width: fit-content;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            border: none;
            background-color: ${({ theme }) => theme.colors.blue5};

            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }

      margin: 5rem auto;
      .loading {
        text-align: center;
      }

      .filter {
        box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 4rem 6rem;
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        flex-direction: column;

        gap: 2.5rem;
        background-color: white;
        .title {
          display: flex;
          justify-content: center;
          > h1 {
            font-size: 1.4rem;
            font-weight: 500;
          }
        }

        .options {
          display: flex;
          flex-direction: column;
          margin-inline: auto;

          gap: 1rem;
          label {
            margin-inline: auto;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    padding: 10rem 0rem;
    justify-content: center;
    > span {
      > svg {
        color: #87c249;
      }
    }
  }

  .start {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    > button {
      background-color: #141d3c;
      color: ${({ theme }) => theme.colors.white};
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      border: none;
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .table {
    max-height: 60vh;
    overflow-x: scroll;
    overflow-y: scroll;

    > table {
      width: 110rem;
      max-height: 50rem;
      border-collapse: collapse;

      > thead {
        > tr:nth-child(1) {
          > th:nth-child(1) {
            background-color: #f0f0f0;
          }

          > th:nth-child(2) {
            border-radius: 2rem 0rem 0rem 0rem;
          }

          > th:nth-child(5) {
            border-radius: 0rem 2rem 0rem 0rem;
          }

          > th:nth-child(6) {
            background-color: #f0f0f0;
          }
        }
        > tr:nth-child(2) {
          > th:nth-child(1) {
            background-color: #f0f0f0;
          }
          > th:nth-child(2) {
            border-radius: 2rem 0rem 0rem 0rem;
          }

          > th:last-child {
            border-radius: 0rem 2rem 0rem 0rem;
          }
        }

        > tr {
          padding: 1rem;
          background-color: #cfded7;
          font-size: 1.6rem;

          > th {
            border: 0.4rem solid #f0f0f0;
            color: ${({ theme }) => theme.colors.black};
            padding: 1.2rem 2rem;
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            text-transform: uppercase;
          }

          .school_column {
            min-width: 25vw;
          }
        }
      }

      > tbody {
        > tr {
          padding: 1rem;
          background-color: ${({ theme }) => theme.colors.white};
          > td:nth-child(1) {
            padding: 1rem 2rem;

            > div {
              display: flex;
              gap: 1rem;

              > svg {
                cursor: pointer;
              }
            }
          }
          > td {
            padding: 1rem;
            border: 0.1rem solid #d3d3d38a;
            text-align: center;
            vertical-align: middle;

            > input {
              border: 0.1rem solid gray;
              border-radius: 0 !important;
              padding: 1rem;
            }

            > button {
              border: none;
              background-color: white;
              cursor: pointer;
              font-weight: bold;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }
`;

export const SearchInput = styled.div`
  position: relative;
  font-size: 2.6rem;
  > input {
    width: 100%;
    border: 0.1rem solid lightgray;
    border-radius: 1rem;
    padding: 1.5rem 2rem 1.5rem 4.5rem;
    font-size: 1.6rem;
  }
  > svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
