import React from "react";
import * as S from "./styles";

const TITLES = {
  rede: "DA",
  brasil: "DO",
};

const PARSE = {
  STATE: "ESTADUAIS",
  CITY: "MUNICIPAIS",
};

function ComparativeCardCompetences1({ local, type, level, competence }) {
  let localImage;
  let velocimeterImage;

  try {
    localImage = require(`assets/images/ge-in-numbers/states_${competence}/${local}.svg`);
  } catch (error) {
    localImage = null;
  }
  if (level) {
    try {
      velocimeterImage = require(`assets/images/ge-in-numbers/velocimeter/${competence}_${level}.svg`);
    } catch (error) {
      velocimeterImage = null;
    }
  }

  return (
    <S.Wrapper competence={competence} level={level}>
      <header>
        {localImage && <img src={localImage} alt={`${local}`} />}
        <p>
          DOCENTES {PARSE[type?.toUpperCase()]}
          <br /> {TITLES[local]} {local?.toUpperCase()}
        </p>
      </header>
      <main>
        {level ? (
          <>
            <div>
              <p>NÍVEL</p>
              <span>{level}</span>
            </div>
            <div>
              {velocimeterImage && <img src={velocimeterImage} alt="" />}
            </div>
          </>
        ) : (
          <p>Não há dados disponíveis.</p>
        )}
      </main>
    </S.Wrapper>
  );
}

export default ComparativeCardCompetences1;
