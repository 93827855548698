import styled from "styled-components";

export const SVG = styled.svg`
  .circle-center-infra {
    fill: #85c440;
    cursor: pointer;
  }
  .cls-4 {
    fill: #85c440;
    cursor: default !important;
  }

  .cls-hover-infra {
    fill: #85c440;
  }

  .step-1-infra,
  .step-2-infra,
  .step-3-infra,
  .step-4-infra,
  .step-5-infra {
    fill: #85c440;
  }
`;
