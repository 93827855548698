import React from "react";
import Plan from "pages/Plans/components/Plans";
import { GoalActionsTableProvider } from "hooks/use-goal-actions-table";

export default function CompetencePlan() {
  const dimension = "competence";
  return (
    <GoalActionsTableProvider dimension={dimension}>
      <Plan dimension={dimension} previous="/planos/visao" next="/planos/red" />
    </GoalActionsTableProvider>
  );
}
