import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner2 from "assets/images/red-demands/banner_2.svg";

export const Wrapper = styled.div`
  margin: 7rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 8rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  color: ${({ theme }) => theme.colors.blue5};

  > section {
    ${container};
    font-size: 1.4rem;

    h2 {
      font-size: 2rem;
      font-weight: 500;
    }
  }

  > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    > span {
      font-size: 1.8rem;
    }

    > h1 {
      font-size: 3.2rem;
    }
  }

  > section:nth-child(3) {
    > form {
      display: flex;
      flex-direction: column;
      gap: 8rem;
      > div {
        display: flex;
        flex-direction: column;
        gap: 4rem;

        > ul {
          display: flex;
          flex-direction: column;
          gap: 3rem;
          > li {
            display: flex;
            align-items: center;
            gap: 1rem;
            width: 100%;
            > div {
              width: 100%;
            }

            > label {
              font-size: clamp(1.2rem, 1.5vw, 1.8rem);
            }
          }
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    justify-content: center;
    gap: 2rem;
    > button {
      border: none;
      background-color: #054159;
      padding: 1rem 2rem;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
      border-radius: 1rem;
      font-size: 1.8rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      &:hover {
        opacity: 0.9;
      }
    }
  }
`;

export const BannerWrapper = styled.div`
  background-color: #008bbc;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  background-image: url(${banner2});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  height: 52rem;
  display: flex;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  gap: 2rem;
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 4rem;
  }

  > p {
    color: #18bde4;
    font-size: 2.2rem;
  }
`;

export const AlertModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;
  gap: 3rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue5};

  > h2 {
    font-size: 2.4rem;
    font-weight: 500;
  }

  > p {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    text-align: center;
  }

  > button {
    border: none;
    background-color: #054159;
    padding: 2rem 2rem;
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    border-radius: 1rem;
    cursor: pointer;
    &:hover {
      opacity: 0.9;
    }
  }
`;
