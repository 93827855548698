import React, { useCallback, useEffect, useRef, useState } from "react";
import * as S from "./styles";

export const useContainerDimensions = (myRef) => {
  const getDimensions = useCallback(
    () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    }),
    [myRef]
  );

  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, getDimensions]);

  return dimensions;
};

function PyramidChart({ data }) {
  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);
  const maxLeft = Math.max(...data?.left);
  const maxRight = Math.max(...data?.right);
  const rightIsEmpty = data?.right?.some((item) => item === 0);
  const leftIsEmpty = data?.left?.some((item) => item === 0);

  return (
    <S.Wrapper ref={componentRef} rightIsEmpty={rightIsEmpty}>
      {!leftIsEmpty && (
        <S.Bars left={!rightIsEmpty} right={!leftIsEmpty}>
          <S.Title left={!leftIsEmpty} right={leftIsEmpty}>
            {data?.titleLeft}
          </S.Title>
          <div>
            {data?.left?.map((value, index) => (
              <S.BarWrapper
                key={`left-${value}-${index}`}
                left={!rightIsEmpty}
                right={rightIsEmpty}
              >
                <span>{value}</span>
                <S.Bar
                  value={value}
                  left={!leftIsEmpty}
                  right={leftIsEmpty}
                  max={maxLeft}
                  width={width}
                />
              </S.BarWrapper>
            ))}
          </div>
        </S.Bars>
      )}
      <S.CentralAxis>
        <S.Title>{data?.titleCenter}</S.Title>
        <div>
          {data?.center?.map((value, index) => (
            <span key={`${value}-${index}`}>{value}</span>
          ))}
        </div>
      </S.CentralAxis>
      {!rightIsEmpty && (
        <S.Bars right={!rightIsEmpty} left={rightIsEmpty}>
          <S.Title right={!rightIsEmpty} left={rightIsEmpty}>
            {data?.titleRight}
          </S.Title>
          <div>
            {data?.right?.map((value, index) => (
              <S.BarWrapper
                key={`right-${value}-${index}`}
                right={!rightIsEmpty}
                left={rightIsEmpty}
              >
                <span>{value}</span>
                <S.Bar
                  value={value}
                  right={!rightIsEmpty}
                  left={rightIsEmpty}
                  max={maxRight}
                  width={width}
                />
              </S.BarWrapper>
            ))}
          </div>
        </S.Bars>
      )}
    </S.Wrapper>
  );
}

export default PyramidChart;
