import React from "react";
import Button from "shared-components/Button/index.js";
import * as S from "./styles";
import PropTypes from "prop-types";
import { useSteps } from "hooks/use-steps";

function ButtonsArea({
  dimension,
  step,
  nextStep,
  previousStep,
  goToStep,
  position,
}) {
  return (
    <S.Wrapper step={step} position={position}>
      {step > 0 && (
        <Button
          dimension={dimension}
          onClick={() => {
            goToStep(step + 2, dimension.slice(0, 1));
            previousStep();
          }}
        >
          <S.PreviousIcon />
          <p>ETAPA ANTERIOR</p>
        </Button>
      )}
      {step <= 2 && (
        <Button
          dimension={dimension}
          onClick={() => {
            nextStep();
            goToStep(step + 2, dimension.slice(0, 1));
          }}
        >
          <p>PRÓXIMA ETAPA</p>
          <S.NextIcon />
        </Button>
      )}
    </S.Wrapper>
  );
}

function ChangeStepsArea({ dimension, steps }) {
  const { step, nextStep, previousStep, goToStep } = useSteps();

  return (
    <>
      <ButtonsArea
        {...{ dimension, step, nextStep, previousStep, goToStep }}
        position="top"
      />
      {steps[step]}
      <ButtonsArea
        {...{
          dimension,
          step,
          nextStep,
          previousStep,
          goToStep,
        }}
        position="bottom"
      />
    </>
  );
}

const DIMENSIONS = ["vision", "competence", "red", "infra"];

ChangeStepsArea.propTypes = {
  dimension: PropTypes.oneOf(DIMENSIONS).isRequired,
  steps: PropTypes.array.isRequired,
};

export default ChangeStepsArea;
