import styled, { css } from "styled-components";

export const large = css`
  p:nth-child(2) {
    position: absolute;
    top: 41.2rem;
    right: 9.9rem;
    width: 7rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(3) {
    position: absolute;
    bottom: 12.7rem;
    right: 30rem;
    width: 7rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(4) {
    position: absolute;
    bottom: 25.5rem;
    left: 10.8rem;
    width: 7rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(5) {
    position: absolute;
    top: 14.5rem;
    left: 22.5rem;
    width: 10rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(6) {
    position: absolute;
    top: 36rem;
    left: 32.5rem;
    width: 14rem;
    text-align: center;
    font-size: 1.8rem;
  }
`;

export const normal = css`
  p:nth-child(2) {
    position: absolute;
    top: 306px;
    right: 65px;
    width: 70px;
    text-align: center;
    font-size: 1rem;
  }

  p:nth-child(3) {
    position: absolute;
    bottom: 95px;
    right: 215px;
    width: 70px;
    text-align: center;
    font-size: 1.04rem;
  }

  p:nth-child(4) {
    position: absolute;
    bottom: 192px;
    left: 73px;
    width: 70px;
    text-align: center;
    font-size: 0.96rem;
  }

  p:nth-child(5) {
    position: absolute;
    top: 103px;
    left: 170px;
    width: 75px;
    text-align: center;
    font-size: 1.04rem;
  }

  p:nth-child(6) {
    position: absolute;
    top: 265px;
    left: 235px;
    width: 130px;
    text-align: center;
    font-size: 1.4rem;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  height: ${({ large }) => (large ? "80rem" : "60rem")};
  width: ${({ large }) => (large ? "80rem" : "60rem")};
  ${(props) => (props.large ? large : normal)}

  svg {
    .cls-1 {
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }
  > p {
    font-weight: 400;
  }

  > p:nth-last-child(1) {
    font-weight: 500;
    cursor: pointer;
  }
`;
