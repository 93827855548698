import React, { useEffect } from "react";
import Helmet from "react-helmet";
import FourLeafClover2 from "shared-components/FourLeafClover2";
import Layout from "shared-components/Layout";
import * as S from "./styles";

import image02 from "assets/images/plans/02.svg";
import image03 from "assets/images/plans/03.svg";
import image04 from "assets/images/plans/04.svg";
import image05 from "assets/images/plans/05.svg";
import image06 from "assets/images/plans/06.svg";
import image07 from "assets/images/plans/07.svg";
import { useNavigate } from "react-router-dom";
import PlansTable from "./components/PlansTable";
import {
  useInstitutionPlansMutation,
  useInstitutionQuery,
} from "services/common";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { getStatus } from "utils/plans";
import { usePlans } from "hooks/use-plans";

export default function Plans() {
  const navigate = useNavigate();

  const {
    data: institution,
    isLoading: institutionLoading,
    isSuccess: institutionSuccess,
  } = useInstitutionQuery();

  const institutionPlans = useInstitutionPlansMutation();
  const { isSuccess: institutionPlansSuccess, error: institutionPlansError } =
    institutionPlans;

  useEffect(() => {
    if (institutionPlansSuccess) {
      NotificationManager.success("Os dados foram salvos com sucesso !");
    }
    if (institutionPlansError) {
      NotificationManager.error("Ocorreu um erro ao salvar os dados");
    }
  }, [institutionPlansSuccess, institutionPlansError]);

  const { getCurrentPlan } = usePlans();

  const createPlan = () => {
    const institutionAux = { ...institution };

    if (institutionAux?.plans) {
      const length = institutionAux?.plans?.length;
      const currentPlan = { ...institutionAux?.plans[length - 1] };
      const validity = currentPlan?.setting_plan?.validity;

      if (validity) {
        const { start, end } = currentPlan?.setting_plan?.validity;
        if (getStatus(start, end) === "concluded") {
          let date = new Date();
          date.setDate(new Date().getDate() + 1);
          const start = new Date();
          const end = date;

          institutionAux.plans = [
            ...institutionAux?.plans,
            {
              general_data: { network: {}, responsible: [] },
              setting_plan: {
                main_goal: "",
                validity: { start, end },
              },
              vision: { goals: [] },
              competence: { goals: [] },
              red: { goals: [] },
              infra: { goals: [] },
            },
          ];
        }
      }
    }

    if (!institutionAux?.plans || institutionAux?.plans?.length === 0) {
      let date = new Date();
      date.setDate(new Date().getDate() + 1);
      const start = new Date();
      const end = date;
      institutionAux.plans = [
        {
          setting_plan: { validity: { start, end } },
          general_data: { network: {}, responsible: [] },
        },
      ];
    }

    institutionPlans.mutate({ institution: institutionAux });
  };

  useEffect(() => {
    if (institutionPlansSuccess) {
      navigate("/planos/dados-da-rede");
    }
  }, [institutionPlansSuccess, navigate]);

  return (
    <Layout hasNavigationBar name="plans">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>
        <S.FirstText>
          <p>
            O Centro de inovação para Educação Brasileira (CIEB) acredita que
            para que as tecnologias digitais <br />
            tenham impacto positivo nos processos de ensino, aprendizagem e
            gestão, é preciso o <br />
            equilíbrio entre quatro dimensões:
          </p>
        </S.FirstText>
        <S.Dimensions>
          <FourLeafClover2 className="four-leaf-clover" />
        </S.Dimensions>
        <S.Balance>
          <p>
            O equilíbrio entre essas dimensões é traduzido no conceito de Escola
            Conectada:
          </p>
        </S.Balance>
        <S.BalanceText>
          <p>
            Uma escola que possui{" "}
            <span className="amarelo">visão estratégica e planejada</span> para
            o uso da tecnologia na educação, expressa em seu{" "}
            <span className="amarelo">currículo</span> e nas{" "}
            <span className="amarelo">práticas pedagógicas</span>, com
            gestores/as e docentes com{" "}
            <span className="vermelho">competências digitais</span>{" "}
            desenvolvidas, com{" "}
            <span className="azul">recursos educacionais digitais</span>{" "}
            selecionados e alinhados ao currículo e com a{" "}
            <span className="verde">infraestrutura adequada</span>. Capaz de
            oferecer o ensino híbrido, utilizando a tecnologia para ampliar o
            tempo, o espaço, o ritmo e os formatos dos processos de ensino e de
            aprendizagem. Desenvolver Escolas Conectadas requer ações ao mesmo
            tempo diversas e complementares que somem esforços entre si. Ou
            seja, exige um planejamento. Por isso, com o objetivo de apoiar
            você, gestor/a de secretaria de educação, o CIEB desenvolveu uma
            ferramenta para elaboração do Plano de Inovação e Tecnologia (PIT)
            da sua rede de ensino.
          </p>
        </S.BalanceText>
        <S.TwoText>
          <p>
            Transformar as escolas da rede pública de ensino em Escolas
            Conectadas, requer ações ao mesmo <br />
            tempo diversas e complementares, que somem esforços entre si. Ou
            seja, exige um planejamento. Por <br />
            isso, com o objetivo de apoiar você, gestor/ técnico da secretaria
            de educação, o CIEB desenvolveu uma <br />
            ferramenta para elaboração do Plano de Inovação e Tecnologia (PIT)
            da sua rede de ensino.
          </p>
        </S.TwoText>
        <S.School>
          <img src={image02} alt="" />
        </S.School>
        <S.Flow>
          <S.TwoText>
            <p>
              O PIT tem o papel de orientar e sistematizar ações efetivas para
              avançar nos níveis da Escola Conectada. Por isso, sugerimos que o
              plano seja feito a partir do diagnóstico do nível de adoção de
              tecnologias da rede de ensino.
            </p>
            <p>
              Não existe uma maneira única para desenvolver um PIT, mas o modelo
              elaborado pelo CIEB tem a seguinte estrutura:
            </p>
          </S.TwoText>
          <S.FlowImageWrapper>
            <S.FlowItem>
              <img alt="" src={image03} />
              <p>
                definição da
                <br />
                equipe que
                <br />
                será envolvida
              </p>
            </S.FlowItem>
            <S.FlowItem>
              <img alt="" src={image04} />
              <p>
                definição da
                <br />
                vigência do
                <br />
                plano
              </p>
            </S.FlowItem>
            <S.FlowItem>
              <img alt="" src={image05} />
              <p>
                construção
                <br />
                do objetivo
                <br />
                geral do PIT
                <br />
                para a rede
                <br />
                de ensino
              </p>
            </S.FlowItem>
            <S.FlowItem>
              <img alt="" src={image06} />
              <p>
                definição
                <br />
                dos objetivos
                <br />
                específicos para
                <br />
                cada dimensão
              </p>
            </S.FlowItem>
            <S.FlowItem>
              <img alt="" src={image07} />
              <p>
                detalhamento das
                <br />
                ações, considerando
                <br />
                responsáveis,
                <br />
                custos e prazos
              </p>
            </S.FlowItem>
          </S.FlowImageWrapper>
        </S.Flow>
        <S.Start>
          <button className="button" onClick={() => createPlan()}>
            {getStatus(
              getCurrentPlan()?.setting_plan?.validity?.start,
              getCurrentPlan()?.setting_plan?.validity?.end
            ) === "concluded" || institution?.plans?.length === 0
              ? "VAMOS COMEÇAR"
              : "ACESSE SEU PLANO"}
          </button>

          <PlansTable
            columns={[
              "Acompanhamento",
              "Objetivo Geral",
              "Início",
              "Fim",
              "Status",
            ]}
            plans={institution?.plans}
            institutionLoading={institutionLoading}
            institutionSuccess={institutionSuccess}
          />
        </S.Start>
      </S.Wrapper>
    </Layout>
  );
}
