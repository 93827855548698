import { useQuery, useMutation } from "@tanstack/react-query";
import { getStorageAuthenticityToken } from "utils/local-storage";
import { API_VERSION, instance as axios } from "services";

const useCalcInfraDashboardQuery = (survey_schedule_id, stage) => {
  return useQuery(
    ["calcInfraDashboard", survey_schedule_id, stage],
    async () => {
      const params = { survey_schedule_id };
      if (stage) {
        params.stage = stage;
      }
      params.access_token = getStorageAuthenticityToken();
      const { data } = await axios.get(`${API_VERSION}/calc_infra_dashboard`, {
        params,
      });
      return data;
    },
    {
      enabled: !!survey_schedule_id,
    }
  );
};

const useCalcInfraSchoolsQuery = () => {
  return useQuery("calcInfraSchools", async () => {
    const { data } = await axios.get(`${API_VERSION}/calc_infra_schools`, {
      params: { access_token: getStorageAuthenticityToken() },
    });
    return data;
  });
};

const useCalcInfraReferenceQuery = () => {
  return useQuery("calcInfraSchools", async ({ survey_schedule_id }) => {
    const { data } = await axios.get(`${API_VERSION}/calc_infra_reference`, {
      params: {
        survey_schedule_id,
        access_token: getStorageAuthenticityToken(),
      },
    });
    return data;
  });
};

const useSchoolInventoryMutation = () => {
  return useMutation(async ({ school_id, inventory }) => {
    const { data } = await axios.post(
      `${API_VERSION}/school_inventory/${school_id}.json`,
      inventory,
      {
        params: { access_token: getStorageAuthenticityToken() },
      }
    );
    return data;
  });
};

const useCalcInfraCustomReferenceMutation = () => {
  return useMutation(async ({ survey_schedule_id, references_values }) => {
    const { data } = await axios.post(
      `${API_VERSION}/calc_infra_custom_reference`,
      { values: references_values },
      {
        params: {
          survey_schedule_id,
          access_token: getStorageAuthenticityToken(),
        },
      }
    );
    return data;
  });
};

export {
  useCalcInfraDashboardQuery,
  useCalcInfraSchoolsQuery,
  useSchoolInventoryMutation,
  useCalcInfraReferenceQuery,
  useCalcInfraCustomReferenceMutation,
};
