import styled, { css } from "styled-components";
import Table from "@mui/material/Table";
import TableCell from "@mui/material/TableCell";
import { MenuItem, Select } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";

const COLORS = {
  vision: "yellow",
  competence: "pink",
  red: "blue",
  infra: "green",
};

const STATUS = {
  concluded: "#168c2e",
  in_progress: "#d9ba0d",
  not_started: "#9d02f0",
  late: "#e63809",
};

const pageBreak = css`
  @media print {
    page-break-before: always;
    page-break-inside: avoid;
  }
`;

export const wrapperModifiers = {
  generalStylesIcons: css`
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    &&& {
      font-size: 2.8rem;
    }
    &:hover {
      opacity: 0.8;
      background-color: ${({ theme }) => theme.colors.white};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.black};
      transition: background 0.5s;
    }
  `,
};

export const StyledCheckIcon = styled(CheckIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledCloseIcon = styled(CloseIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledEditIcon = styled(EditIcon)`
  ${wrapperModifiers.generalStylesIcons};
`;

export const StyledSelect = styled(Select)`
  &&& {
    width: 80%;
    > div {
      font-size: initial;
      min-height: 1.4375rem;
    }
  }
`;

export const StyledMenuItem = styled(MenuItem)`
  &&& {
    font-size: initial;
  }
`;

export const Wrapper = styled.div`
  ${pageBreak};
  font-size: 1.5rem;
  width: 100%;
  margin-bottom: 6rem;
  > h1 {
    font-weight: 500;
    font-size: 2.4rem;
    border-bottom: 0.1rem solid rgb(207, 222, 215);
    padding-bottom: 1rem;
    margin-bottom: 4rem;
    width: fit-content;
  }
`;

export const TitleWrapper = styled.div`
  margin-bottom: 1rem;
  > div:nth-child(1) {
    background-color: ${({ theme, dimension }) =>
      theme.colors[`${COLORS[dimension]}2`]};
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    align-items: center;
    height: 3.8rem;
    padding: 0rem 2.5rem;
    border-radius: 1rem 1rem 0rem 0rem;
  }
  > div:nth-child(2) {
    background-color: ${({ theme, dimension }) =>
      theme.colors[COLORS[dimension]]};
    display: flex;
    color: ${({ theme, dimension }) =>
      dimension !== "vision" && theme.colors.white};
    align-items: center;
    padding: 3rem 3rem;
    justify-content: space-between;
    border-radius: 0rem 0rem 1rem 1rem;

    > h1 {
      font-weight: 500;
    }
    > img {
      width: 6rem;
    }
  }
`;
export const DataWrapper = styled.div``;

export const DescriptionWrapper = styled.div`
  > div:nth-child(1) {
    background-color: #cfded7;
    height: 4.75rem;
    display: flex;
    align-items: center;
    padding: 0rem 2.5rem;
    color: ${({ theme }) => theme.colors.black};
    font-weight: 500;
    border-radius: 1rem 1rem 0rem 0rem;
  }

  > div:nth-child(2) {
    display: flex;
    > div:nth-child(1) {
      word-break: break-all;
      flex: 0.7;
      min-height: 12rem;
      border: none;
      background-color: ${({ theme }) => theme.colors.gray5};
      padding: 2rem;
    }
    > div:nth-child(2) {
      border: 0.2rem solid #e5e7e5;
      border-top: none;
      flex: 0.3;
      min-height: 12rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 1.5rem;
      padding: 3rem;
      > span {
        font-size: 1.4rem;
        font-weight: 500;
      }

      > p {
        font-size: 2.4rem;
      }
    }
  }
`;
export const StyledTable = styled(Table)`
  &&& {
    box-shadow: 0 1rem 1.5rem 0.3rem rgb(0 0 0 / 10%) !important;
    > thead {
      > tr {
        > th {
          text-align: center;
          text-transform: uppercase;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          background-color: #cfded7;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          color: ${({ theme }) => theme.colors.black};
        }
      }
    }
    > tbody {
      > tr {
        page-break-before: always;
        > td {
          background-color: #f5f5f5;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          text-align: center;

          > p {
            text-align: center;
          }

          > input {
            border: none;
            height: 3rem;
            padding: 1rem;
          }
        }

        > td:nth-child(2) {
          max-width: 40rem !important;
          > p {
            overflow-wrap: anywhere;
            text-align: left;
          }
        }
      }
    }

    tfoot {
      tr {
        > p {
          visibility: hidden;
        }
      }
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  background-color: ${({ status }) => STATUS[status]} !important;
  color: ${({ theme }) => theme.colors.white} !important;
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
