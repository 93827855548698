import styled from "styled-components";
import image09 from "assets/images/plans/09.svg";
import { container } from "global-styles/shared-styles";
import { TextField } from "@mui/material";

export const CircularProgressWrapper = styled.div`
  text-align: center;
`;

export const StyledTextField = styled(TextField)`
  &&& {
    > div {
      font-size: 1.5rem;
    }
    > label {
      font-size: 1.6rem;
    }
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  flex: 1;
`;

export const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 2rem;
  > img {
    width: 6rem;
  }
  border-bottom: 0.1rem solid #cfded7;
  padding: 1rem 2rem;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

export const WrapperBanner = styled.section`
  background: #fad62b;
`;

export const Banner = styled.header`
  ${container}
  background: url(${image09});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  height: 42rem;
  width: 100%;
  margin-bottom: 5rem;
`;

export const Contents = styled.div`
  ${container}
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  gap: 6rem;
`;

export const NetworkName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.black};
  > h1 {
    font-size: 2.2rem;
    font-weight: 400;
  }
  > p {
    font-size: 2.8rem;
    font-weight: 500;
  }

  margin-bottom: 2rem;
  padding: 1rem 2rem;
`;

export const Responsible = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const Icons = styled.div`
  font-size: 1.1rem;
  vertical-align: middle;
  margin-right: 1rem;
`;

export const TableWrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export const AlignButton = styled.div`
  text-align: right;
`;

export const BtnNext = styled.div`
  margin-top: 3rem;
  text-align: right;
`;

export const NetworkData = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;

  > p {
    font-size: 1.8rem;
  }
`;

export const Data = styled.div`
  display: flex;
  width: 100%;
  height: 16rem;
  margin-top: 1rem;
`;

export const TableName = styled.div`
  width: 10%;
  height: 100%;
  display: table;

  > h2 {
    text-align: center;
    vertical-align: middle;
    display: table-cell;
    transform: rotate(-90deg);
    width: 10%;
    font-weight: 500;
    font-size: 1.8rem;

    .span1 {
      margin-top: 0.5rem;
      padding: 1rem 4.5rem 1rem 4.5rem;
      background: #e5e5e5;
    }
    .span2 {
      padding: 1rem;
      background: #e5e5e5;
    }
  }
`;

export const Header = styled.div`
  display: table;
  width: 100%;
  height: 50%;
`;

export const Line = styled.div`
  border-left: 0.1rem dotted;
  height: 50%;
  text-align: left;

  > input {
    width: 30%;
    margin: 2rem 0 0 2rem;
  }

  > label {
    width: 50%;
    vertical-align: middle;
    margin-left: 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  > button {
    background-color: ${({ theme }) => theme.colors.blue5};
    color: ${({ theme }) => theme.colors.white};
    padding: 0rem 2rem;
    height: 4rem;
    min-width: 10rem;
    border: none;
    border-radius: 0.5rem;
    font-size: 1.4rem;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue2};
      transition: 0.1s ease-in-out;
    }
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  align-self: flex-end;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
`;
