import { useQuery, useMutation } from "@tanstack/react-query";
import {
  getStorageAuthenticityToken,
  getStorageSchoolId,
} from "utils/local-storage";
import { API_VERSION, instance as axios } from "services";

const useAnswersMutation = () => {
  const school_id = getStorageSchoolId();
  return useMutation(async ({ red_demands, survey_schedule_id }) => {
    const { data } = await axios.post(
      `${API_VERSION}/red_demands/answers`,
      { red_demands },
      {
        params: {
          access_token: getStorageAuthenticityToken(),
          survey_schedule_id,
          school_id,
        },
      }
    );
    return data;
  });
};

const useGetAnswersQuery = (survey_schedule_id) => {
  const school_id = getStorageSchoolId();
  return useQuery(
    ["getAnswers", survey_schedule_id],
    async () => {
      const { data } = await axios.get(
        `${API_VERSION}/red_demands/get_answers`,
        {
          params: {
            access_token: getStorageAuthenticityToken(),
            survey_schedule_id,
            school_id,
          },
        }
      );
      return data;
    },
    {
      enabled: !!survey_schedule_id,
    }
  );
};

const useSchoolsAmountQuery = (survey_schedule_id, stage) => {
  return useQuery(
    ["schoolsAmount", survey_schedule_id, stage],
    async () => {
      const { data } = await axios.get(
        `${API_VERSION}/red_demands/schools_amount`,
        {
          params: {
            access_token: getStorageAuthenticityToken(),
            survey_schedule_id,
            stage,
          },
        }
      );
      return data;
    },
    {
      enabled: !!survey_schedule_id,
    }
  );
};

const useDigitalCompetencesByAreaQuery = (survey_id, survey_schedule_id) => {
  return useQuery(
    ["digitalCompetencesByArea", survey_id, survey_schedule_id],
    async () => {
      const { data } = await axios.get(
        `${API_VERSION}/red_demands/digital_competences_by_area`,
        {
          params: {
            access_token: getStorageAuthenticityToken(),
            survey_schedule_id,
            survey_id,
          },
        }
      );
      return data;
    },
    {
      enabled: !!survey_schedule_id && !!survey_id,
    }
  );
};

const useAllTechnologiesQuery = () => {
  return useQuery(["allTechnologies"], async () => {
    const { data } = await axios.get(
      `${API_VERSION}/red_demands/all_technologies`,
      {
        params: {
          access_token: getStorageAuthenticityToken(),
        },
      }
    );
    return data;
  });
};

const useAllSolutionsEdutecPlataformQuery = () => {
  return useQuery(["allSolutionsEdutecPlataform"], async () => {
    const { data } = await axios.get(
      "https://api.bdedutec.com.br/solucao/7t66k41C122FT7K/busca?nome=&limit=2000&offset=0"
    );
    return data;
  });
};

const useAllNetworkTechnologiesQuery = (survey_schedule_id, stage) => {
  return useQuery(
    ["allNetworkTechnologies", survey_schedule_id, stage],
    async () => {
      const { data } = await axios.get(
        `${API_VERSION}/red_demands/all_network_technologies`,
        {
          params: {
            access_token: getStorageAuthenticityToken(),
            survey_schedule_id,
            stage,
          },
        }
      );
      return data;
    },
    {
      enabled: !!survey_schedule_id,
    }
  );
};

const useTechnologyMutation = () => {
  return useMutation(async ({ name }) => {
    const { data } = await axios.post(
      `${API_VERSION}/red_demands/technology`,
      { name },
      {
        params: {
          access_token: getStorageAuthenticityToken(),
        },
      }
    );
    return data;
  });
};

export {
  useAllSolutionsEdutecPlataformQuery,
  useGetAnswersQuery,
  useSchoolsAmountQuery,
  useAnswersMutation,
  useDigitalCompetencesByAreaQuery,
  useAllTechnologiesQuery,
  useAllNetworkTechnologiesQuery,
  useTechnologyMutation,
};
