import styled, { css } from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/calculadora/013.svg";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const pageBreak = css`
  @media print {
    page-break-before: always;
    page-break-inside: avoid;
  }
`;

export const Wrapper = styled.div`
  font-family: ${({ theme }) => theme.fonts.poppins};
  background-color: #f0f0f0;
  margin-bottom: 0;
  @media print {
    @page {
      size: A4;
      margin: 0;
    }
    margin-top: 0;
    margin-bottom: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    padding-right: 20px;
    ${container};
    > article {
      margin: 50px 20px;

      > div {
        margin-bottom: 50px;
        font-size: 1rem;
        > p {
          margin-bottom: 10px;
        }
      }

      > div {
        > p {
          font-size: 2rem;
          line-height: 1.5;
          color: ${({ theme }) => theme.colors.blue5};
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 6rem 6rem 4rem 6rem;
        background-color: ${({ theme }) => theme.colors.white};
        > div:nth-child(1) {
          display: flex;
          gap: 2rem;
          align-items: center;
          > img {
            width: 5rem;
          }
          > h1 {
            color: ${({ theme }) => theme.colors.blue5};
            font-weight: 500;
            font-size: 2.4rem;
          }
        }
        > div:nth-child(2) {
          display: flex;
          gap: 4rem;
          flex-wrap: wrap;

          @media print {
            flex-wrap: nowrap;
          }

          justify-content: space-between;
          margin-top: 5rem;
          margin-bottom: 5rem;
          > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 1rem;

            > div:nth-child(1) {
              > p {
                font-size: 2rem;
                > span:nth-child(1) {
                  font-size: 3.4rem;
                }

                > span:nth-child(2) {
                  color: #9e9e9e;
                  margin-right: 1rem;
                }
              }
            }

            > div:nth-child(3) {
              > p {
                font-size: 2rem;
                > span {
                  color: #94c85d;
                }
              }
            }
          }
          > div:nth-child(2) {
            > img {
              width: 27rem;
            }
          }
        }
      }

      > div:nth-child(5) {
        @media print {
          display: none;
        }
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-wrap: wrap;
        justify-content: space-between;
        box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 4rem 6rem;
        background-color: ${({ theme }) => theme.colors.white};
        margin-top: 5rem;
        margin-bottom: 5rem;

        > h1 {
          font-weight: 500;
          font-size: 2.2rem;
          color: ${({ theme }) => theme.colors.blue5};
        }

        > p {
          font-size: 1.8rem;
        }
        > a {
          text-decoration: none;
          width: fit-content;
          padding: 1rem 2rem;
          border-radius: 0.5rem;
          border: none;
          font-size: 1.6rem;
          background-color: ${({ theme }) => theme.colors.blue5};

          font-weight: 500;
          color: ${({ theme }) => theme.colors.white};
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
      }

      > div:nth-child(3) {
        ${pageBreak};
        box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 1rem;
        padding: 4rem 6rem;
        background-color: ${({ theme }) => theme.colors.white};
        display: flex;
        flex-direction: column;

        gap: 25px;
        background-color: white;

        @media print {
          margin-top: 50%;
        }

        > p {
          font-size: 2rem;
          color: ${({ theme }) => theme.colors.blue5};
        }
      }

      .boxes {
        display: flex;
        justify-content: space-between;
        gap: 5rem;
        flex-wrap: wrap;
        width: 100%;

        @media print {
          flex-direction: column;
          margin-top: 10rem;
        }

        .level {
          border: 4px solid #99ce5f;
        }

        > div {
          ${pageBreak};
          position: relative;
          cursor: pointer;
          width: 310px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap: 4rem;
          padding: 6rem 4rem 4rem 4rem;
          background-color: white;
          border-radius: 1rem;
          height: fit-content;
          min-height: 950px;
          flex: 1 1 310px;
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);

          @media print {
            width: 100%;
          }

          > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4rem;
            > p {
              text-align: center;
              font-size: 2rem;
              color: ${({ theme }) => theme.colors.blue5};
            }
            > h1 {
              text-align: center;
              font-weight: 500;
              font-size: 2.5rem;
              color: #3c591d;
              > span {
                font-weight: 600;
              }
            }
          }

          > div:nth-child(2) {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4rem;

            > p {
              display: flex;
              flex-direction: column;
              gap: 0.1rem;
              align-items: center;
              text-align: center;
              font-size: 3rem;
              font-weight: 500;
              color: ${({ theme }) => theme.colors.blue5};

              > span {
                font-size: 2rem;
                font-weight: 400;
                color: #828282;
              }

              > button {
                font-size: 1.4rem;
                display: grid;
                place-items: center;
                width: 18px !important;
                height: 18px !important;
                border-radius: 50%;
                border: none;
                background-color: ${({ theme }) => theme.colors.white};
                box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.2);
                cursor: pointer;
              }
            }
          }

          > div:nth-child(3) {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            > div {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              align-items: center;
              > img {
                width: 3rem;
              }

              > p {
                font-size: 2rem;
                text-align: center;
                color: ${({ theme }) => theme.colors.blue5};

                > strong {
                  font-weight: 600;
                }
              }
            }
          }

          > div:nth-child(4) {
            > p {
              text-decoration: none;
              display: flex;
              flex-direction: row;
              align-items: center;
              text-align: center;
              gap: 1rem;
              justify-content: center;

              background-color: #141d3c;
              color: ${({ theme }) => theme.colors.white};
              padding: 1.5rem 2rem;
              border-radius: 0.5rem;
              border: none;
              font-size: 1.6rem;
              cursor: pointer;

              > i {
                cursor: pointer;
              }

              &:hover {
                opacity: 0.9;
              }
            }
          }

          > div:nth-child(5) {
            position: absolute;
            text-align: center;
            top: 0;
            left: 0;
            width: 100%;

            background-color: #87c249;

            padding: 1rem 2rem;
            font-size: 1.6rem;
            font-weight: 500;
            color: white;
          }
        }

        > div:nth-child(1) {
          cursor: default;
          padding: 6rem 2rem;
          min-width: 15rem;
          flex: 1 1 10rem;
          display: flex;
          flex-direction: column;
          gap: 15rem;
          justify-content: center;
          > div {
            > p {
              font-size: 1.8rem;
              font-weight: 500;
            }
          }
        }

        > div:nth-child(3){
          gap: 2rem;
          > div {
            > .destaque {
                display: flex;
                align-items: center;
                font-size: 1.7rem;
                > img {
                    width: 24px;
                    margin-right: 10px;
                }
                > button {
                    margin-left: 10px;
                    font-size: 1.4rem;
                    display: grid;
                    place-items: center;
                    width: 18px !important;
                    height: 18px !important;
                    border-radius: 50%;
                    border: none;
                    background-color: ${({ theme }) => theme.colors.white};
                    box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.2);
                    cursor: pointer;
                }
              }
          }
        }
      }

      > div:nth-last-child(2) {
        > p {
          font-size: 2rem;
          line-height: 1.5;
          color: ${({ theme }) => theme.colors.blue5};
        }
      }

      > div:last-child {
        @media print {
          display: none;
        }
        background-color: ${({ theme }) => theme.colors.white};
        border-radius: 1rem;
        padding: 4rem 8rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 4rem;

        > img {
          width: 60rem;
        }

        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 3rem;
          > h1 {
            font-size: 3rem;
            font-weight: 500;
            color: ${({ theme }) => theme.colors.blue5};
            text-align: center;
          }
          > button {
            background-color: #141d3c;
            color: ${({ theme }) => theme.colors.white};
            padding: 1.5rem 2rem;
            border-radius: 0.5rem;
            border: none;
            font-size: 1.8rem;
            width: fit-content;
            cursor: pointer;
          }
        }
      }

      .fallback {
        > p {
          font-size: 20px;
        }
      }
    }

    .margin_auto {
      margin: 0 auto;
    }

    .schools_number {
      margin: 100px 0;

      div:nth-child(1) {
        > div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin: 15px 0;
          gap: 12px;

          > h2 {
            font-size: 1.3rem;
            font-weight: bold;
          }

          > img {
            width: 70px;
          }
        }
      }
      .amount {
        background-color: rgb(248, 248, 248);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        height: 232px;
        gap: 60px;

        .amount_on_level {
          text-align: center;
          background-color: rgb(248, 248, 248);
          > span {
            font-size: 3.7rem;
            font-weight: bold;
          }

          > strong {
            text-transform: uppercase;
          }

          > p {
            text-align: center;
            font-size: 0.9rem;

            > a {
              color: #416ab2;
              &:hover {
                opacity: 0.9;
              }
            }
          }
        }

        .divider {
          height: 60%;
          width: 1px;
          background-color: rgb(195, 197, 202);
        }
      }
    }

    .data_summary {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 5px;
      flex-wrap: wrap;

      > div {
        display: flex;
        gap: 80px;

        > div:nth-child(1) {
          > h1 {
            text-align: center;
            font-size: 0.85rem;
            font-weight: bold;
            margin-bottom: 20px;
          }
        }

        > div:nth-child(2) {
          > h1 {
            text-align: center;
            font-size: 0.85rem;
            font-weight: bold;
            margin-bottom: 20px;
          }
        }
      }

      > h2 {
        font-size: 1.3rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      .box {
        > div {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          gap: 5px;
          > h2 {
            font-size: 0.9rem;
            text-transform: uppercase;
          }

          > img {
            width: 40px;
          }
        }
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: rgb(248, 248, 248);
        border-radius: 14px;
        width: 260px;
        height: 210px;
        gap: 20px;
        > p {
          font-size: 1.3rem;
          font-weight: bold;
          > span {
            font-size: 0.9rem;
          }
        }
      }
    }

    .computer_for_students {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 15px;
      margin: 70px;
      > h2 {
        font-size: 1.5rem;
        font-weight: bold;
        margin-bottom: 20px;
      }

      > img {
        width: 80px;
      }
    }

    .table {
      display: flex;
      justify-content: center;
      margin: 70px 0;
      margin-bottom: 90px;
      /* margin-inline: 20px; */
      overflow-x: auto;
      position: relative;

      @media print {
        margin: 0;
      }

      > table {
        border-collapse: separate;
        border-spacing: 0 15px;
        width: 99%;

        @media print {
          width: 99%;
          page-break-inside: auto;
        }

        > thead {
          > tr {
            background-color: #4e9608;
            page-break-inside: avoid;
            page-break-after: auto;
            > th {
              padding: 3rem;
              font-size: 1.8rem;
              font-weight: 500;
              color: white;
              text-align: left;
              &:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              &:last-child {
                border-radius: 0rem 1rem 0rem 0rem;
              }
            }
          }
        }

        > tbody {
          > tr {
            border-radius: 1rem;
            border: none;
            font-size: 0.8rem;
            font-weight: 400;
            page-break-inside: avoid;
            page-break-after: auto;

            > th {
              padding: 18px 40px;
              color: white;
              border: none;
            }

            > th:first-child {
              border-radius: 6px 0 0 6px;
            }

            > th:last-child {
              border-radius: 0 6px 6px 0;
            }

            > td:nth-child(1) {
              > div {
                display: flex;
                align-items: center;
                gap: 2rem;
                > img {
                  width: 50px;
                }
                > p {
                  line-height: 1.5;
                  > span {
                    color: #4e9608;
                  }
                }
              }
            }

            > td {
              padding: 3rem 4rem;
              background-color: white;
              margin: 20px 0px;
              font-size: 1.8rem;
              color: ${({ theme }) => theme.colors.blue5};

              &:nth-child(1) {
                border-radius: 1rem 0rem 0rem 1rem;
              }

              &:last-child {
                border-radius: 0rem 1rem 1rem 0rem;
              }
            }
          }

          > tr:nth-child(1),
          > tr:nth-child(2),
          > tr:nth-child(3) {
            outline: 0.4rem solid #87c249;
          }
        }
      }
    }
  }

  .loading {
    display: flex;
    padding: 100px 0px;
    justify-content: center;
    > span {
      > svg {
        color: #87c249;
      }
    }
  }

  .start {
    height: 19rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    > h1 {
      text-align: center;
      font-size: 1.9rem;
      font-weight: 600;
      margin-bottom: 10px;
    }

    > button {
      border-radius: 4px;
      padding: 0.7rem 3rem;
      border: none;
      font-size: 0.9rem;
      font-weight: 600;
      color: white;
      cursor: pointer;
    }
  }

  > header {
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #85c440;
  }

  .box_title {
    text-align: center;
    > h1 {
      color: white;
      font-size: 2.8rem;
      margin-bottom: 1.2rem;
      font-weight: bold;
    }
    > p {
      > span {
        font-weight: bold;
      }
      text-align: center;
      color: white;
      font-size: 1.4rem;
    }
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 6rem 6rem 4rem 6rem;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 1rem !important;

  > p {
    font-size: 2rem;
    > a {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ReportProblem = styled(ReportProblemIcon)`
  &&& {
    cursor: pointer;
    font-size: 10rem;
    color: #c90a12;
    margin-bottom: 3rem;
  }
`;

export const MinTotalModal = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4rem;
  font-family: ${({ theme }) => theme.fonts.poppins};
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 2rem;

  > div:nth-child(1) {
    display: flex;
    gap: 2rem;
    > h2 {
      font-size: 1.8rem;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.blue5};
    }
    > img {
      width: 7rem;
    }
  }

  > div {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    > p {
      color: ${({ theme }) => theme.colors.blue5};
      line-height: 1.5;
      > span {
        color: #85c440;
        font-weight: 600;
      }
    }
  }
`;

export const SearchInput = styled.div`
  position: relative;
  font-size: 2.6rem;
  width: 100%;
  > input {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 1.5rem 2rem 1.5rem 4.5rem;
    font-size: 1.6rem;
  }
  > svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
