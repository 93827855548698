import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSteps } from "hooks/use-steps";
import * as S from "./styles";

import trapezio from "assets/images/menu-etapas/trapezio.svg";
import MENU_COMPETENCE_STEPS from "./data/menu-competence-steps";
import MENU_RED_STEPS from "./data/menu-red-steps";
import MENU_VISION_STEPS from "./data/menu-vision-steps";
import MENU_INFRA_STEPS from "./data/menu-infra-steps";
import StepCircles from "pages/Jornadas/components/StepCircles";
import { Link } from "react-router-dom";

const STEPS_BY_DIMENSION = {
  vision: MENU_VISION_STEPS,
  competence: MENU_COMPETENCE_STEPS,
  red: MENU_RED_STEPS,
  infra: MENU_INFRA_STEPS,
};

const STEPS = ["concept", "diagnosis", "planning", "monitoring"];

function Summary({ dimension }) {
  const { goToStep, step } = useSteps();

  const [isVisible, setIsVisible] = useState({
    concept: false,
    diagnosis: false,
    planning: false,
    monitoring: false,
  });

  const [isActive, setIsActive] = useState({
    concept: false,
    diagnosis: false,
    planning: false,
    monitoring: false,
  });

  const handleVisibility = useCallback((stepName, boolean) => {
    setIsVisible((isVisible) => ({ ...isVisible, [stepName]: boolean }));
  }, []);

  const handleActivity = useCallback((stepName, boolean) => {
    setIsActive({
      concept: false,
      diagnosis: false,
      planning: false,
      monitoring: false,
    });
    setIsActive((isActive) => ({ ...isActive, [stepName]: boolean }));
  }, []);

  useEffect(() => {
    handleActivity(STEPS[step], true);
  }, [step, handleActivity]);

  return (
    <S.Wrapper>
      <S.Title>A PARTIR DE AGORA VAMOS PERCORRER QUATRO ETAPAS</S.Title>
      <S.Menu dimension={dimension}>
        {STEPS_BY_DIMENSION[dimension]?.map(
          ({ key, image, title, alt, items, stepName }, index) => (
            <S.MenuItem
              onMouseOver={() => handleVisibility(key, true)}
              onMouseLeave={() => handleVisibility(key, false)}
              key={`${key}-${dimension}`}
              dimension={dimension}
              stepNumber={index + 1}
              isActive={isActive[key]}
            >
              <img src={image} alt={alt} />
              {title}
              {isVisible[key] && (
                <section>
                  <article>
                    <div>
                      <div>{index + 1}</div>
                      <img src={trapezio} alt="trapezio" />
                    </div>
                    <div onClick={() => goToStep(index + 1, "c")}>
                      <S.Cicle dimension={dimension} />
                      <p>{stepName}</p>
                    </div>
                  </article>
                  <div>
                    <StepCircles
                      dimension={dimension}
                      numberOfSteps={
                        items.filter((item) => item?.background).length
                      }
                    />
                    <S.ItemsList>
                      {items.map((item, index) => (
                        <S.Item
                          background={item?.background}
                          dimension={dimension}
                          key={`${dimension}-${index}`}
                        >
                          {item?.background ? (
                            item?.link ? (
                              <a
                                href={item.link}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {item.content}
                              </a>
                            ) : (
                              <Link to={item?.to}>{item.content}</Link>
                            )
                          ) : (
                            item.content
                          )}
                        </S.Item>
                      ))}
                    </S.ItemsList>
                  </div>
                </section>
              )}
            </S.MenuItem>
          )
        )}
      </S.Menu>
    </S.Wrapper>
  );
}

Summary.propTypes = {
  dimension: PropTypes.oneOf(["vision", "competence", "red", "infra"])
    .isRequired,
};

export default Summary;
