import React from "react";
import Card from "../../../../components/Card";
import Box from "shared-components/Box";
import * as S from "./styles";

export default function FirstStep() {
  return (
    <S.Wrapper>
      <p>Olá gestor, olá gestora!</p>
      <p>
        Bem-vindo/a à Jornada de Visão! O processo de transformação digital das
        escolas começa idealmente aqui, na Jornada de Visão. Vamos percorrer
        quatro etapas:
      </p>
      <h1 id="v-e-1">1. COMO A TECNOLOGIA PODE IMPACTAR A SUA REDE</h1>
      <p>
        Ao longo desta Jornada você terá acesso a ferramentas para colocar a mão
        na massa e começar um processo real transformação de sua rede de ensino.
      </p>
      <p>
        Contudo, antes de partir para a ação, vamos entender de maneira
        definitiva como as tecnologias efetivamente impactam a educação? Tudo o
        que vamos realizar daqui para frente – tudo mesmo! – parte desse
        entendimento. Então, vamos lá?
      </p>

      <h2 id="v-e-1-s-1">
        1.1 O que significa ter uma Visão para o uso de tecnologias na sua rede
      </h2>
      <p>
        A <span className="brown">Visão</span> significa que a rede tem{" "}
        <strong>clareza sobre os objetivos educacionais</strong> e qual o papel
        das tecnologias para alcançá-los. Essa concepção é compartilhada com os
        diferentes atores do processo educacional (secretaria, educadores e
        estudantes) e se expressa em documentos oficiais e de planejamento.
      </p>
      <p>
        A dimensão Visão faz parte do modelo conceitual Four in Balance (Quatro
        em equilíbrio), segundo o qual a tecnologia pode contribuir com a
        aprendizagem, desde que haja ações e investimentos simultâneos em quatro
        dimensões: visão, competências, recursos educacionais digitais e
        infraestrutura. Esse modelo foi desenvolvido pela Fundação Kennisnet,
        organização pública para educação e TIC da Holanda, e adotado pelo CIEB.
        Segundo essa concepção, nenhuma das dimensões isoladas tem a capacidade
        de melhorar a qualidade e a equidade da educação.
      </p>

      <p>
        A partir do conceito de <em>Four in Balance</em> e das referências
        nacionais e internacionais, o CIEB elaborou o conceito de{" "}
        <span className="brown">Escola Conectada</span>, que também envolve as
        quatro dimensões citadas:
      </p>

      <p>
        <em>
          Uma escola que possui <strong>visão estratégica e planejada</strong>{" "}
          para o uso da tecnologia na educação, expressa em seu currículo e nas
          práticas pedagógicas, com{" "}
          <strong>
            gestores/as e docentes com competências digitais desenvolvidas
          </strong>
          ,
          <strong>
            {" "}
            com recursos educacionais digitais selecionados e alinhados ao
            currículo
          </strong>
          , e com a infraestrutura adequada .
        </em>
      </p>

      <p>
        Acesse o Marco Conceitual do CIEB para compreender melhor sobre a base
        conceitual usada pelo CIEB.
      </p>

      <Card
        title="MARCO CONCEITUAL"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="marco"
        link="https://cieb.net.br/wp-content/uploads/2021/07/Marco-Conceitual-Escola-Conectada.pdf"
      >
        Acesse o Marco Conceitual do CIEB para compreender melhor sobre a base
        conceitual usada pelo CIEB.
      </Card>

      <h2 id="v-e-1-s-2">
        1.2 Currículos de Referência da Educação ao Ensino Médio
      </h2>

      <p>
        Para apoiar a construção de uma Visão estratégica e planejada para o uso
        da tecnologia da rede, que pode estar expressa em sua política
        curricular, o CIEB oferece quatro currículos de referência em Tecnologia
        e Computação, todos alinhados à BNCC e ao Novo Ensino Médio:
      </p>
      <S.Curriculum>
        <Box width={190} height={230}>
          <p>
            Currículo de Referência em Tecnologia e Computação - da Educação
            Infantil ao Ensino Fundamental Anos Finais
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://curriculo.cieb.net.br/"
          >
            LEIA MAIS
          </a>
        </Box>
        <Box width={190} height={230}>
          <p>
            Currículo de Referência em Tecnologia e Computação - Ensino Médio
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://curriculo.cieb.net.br/medio"
          >
            LEIA MAIS
          </a>
        </Box>
        <Box width={190} height={230}>
          <p>
            Currículo de Referência para a Educação Profissional Técnica de
            Nível Médio em Tecnologia e Computação
          </p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://curriculo.cieb.net.br/profissional/prof_tec"
          >
            LEIA MAIS
          </a>
        </Box>
        <Box width={190} height={230}>
          <p>Currículo de Referência em Ciência de Dados</p>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://curriculo.cieb.net.br/profissional/prof_cdd"
          >
            LEIA MAIS
          </a>
        </Box>
      </S.Curriculum>
    </S.Wrapper>
  );
}
