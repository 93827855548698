import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { forwardRef, useEffect } from "react";
import * as S from "./styles";
import { useResponsibleTable } from "hooks/use-responsible-table";
import { Controller, useFormContext } from "react-hook-form";
import { phoneMask } from "utils/masks";

const NumberFormatCustom = forwardRef(function NumberFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      format="(##) #####-####"
      allowEmptyFormatting
      mask="_"
      isNumericString
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

const createData = () => {
  return { name: "", email: "", phone: "", role: "" };
};

function ResponsibleTable({ columns }) {
  const { setValue, control } = useFormContext();
  const {
    removeResponsibleFromDataBase,
    deleteResponsibleFromState,
    saveResponsibleInDataBase,
    loadings,
    canEdit,
    responsible,
    changeCanEdit,
  } = useResponsibleTable();

  useEffect(() => {
    responsible?.forEach((row, index) => {
      Object.keys(row).forEach((key) => {
        setValue(`row_${index}.${key}`, row[key]);
      });
    });
  }, [setValue, responsible]);

  return (
    <>
      <TableContainer>
        <S.StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {responsible?.map((row, index) => (
              <TableRow key={`row-${index}`}>
                {Object.keys(createData()).map((key) => (
                  <TableCell key={key}>
                    {canEdit && canEdit[index] ? (
                      <>
                        <Controller
                          control={control}
                          name={`row_${index}.${key}`}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <S.StyledTextField
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              InputProps={
                                key === "phone" && {
                                  inputComponent: NumberFormatCustom,
                                }
                              }
                              variant="standard"
                            />
                          )}
                        />
                      </>
                    ) : key === "action" ? (
                      `${index + 1}. ${row[key]}`
                    ) : key === "phone" ? (
                      phoneMask.resolve(row[key])
                    ) : (
                      row[key]
                    )}
                  </TableCell>
                ))}
                <TableCell>
                  {canEdit[index] &&
                    (loadings[index] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <S.StyledCheckIcon
                          onClick={() => {
                            saveResponsibleInDataBase(index);
                            changeCanEdit(index, false);
                          }}
                        />
                        <S.StyledCloseIcon
                          onClick={() => changeCanEdit(index, false)}
                        />
                      </>
                    ))}
                  {!canEdit[index] &&
                    (loadings[index] ? (
                      <CircularProgress size={20} />
                    ) : (
                      <>
                        <S.StyledEditIcon
                          onClick={() => changeCanEdit(index, true)}
                        />
                        <S.StyledDeleteIcon
                          onClick={() => {
                            removeResponsibleFromDataBase(index);
                            deleteResponsibleFromState(index);
                          }}
                        />
                      </>
                    ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </S.StyledTable>
      </TableContainer>
    </>
  );
}

ResponsibleTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
};

export default ResponsibleTable;
