import styled from "styled-components";
import banner from "assets/images/calculadora/018.svg";
import { container } from "global-styles/shared-styles";

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-family: ${({ theme }) => theme.fonts.poppins};
  padding-bottom: 8rem;

  background-color: #f0f0f0;

  > header {
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #85c440;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    padding-right: 3rem;
    ${container}
    > article:nth-child(1) {
      display: flex;
      flex-direction: column;
      align-items: center;

      > div:nth-child(1) {
        margin-bottom: 5rem;
        padding: 5.5rem 8rem 2rem 8rem;

        > p {
          margin-bottom: 1rem;
          font-size: 2rem;
          line-height: 1.5;
          color: ${({ theme }) => theme.colors.blue5};
        }

        > ul {
          > li {
            font-size: 2rem;
            margin-left: 1em;
            margin-bottom: 1rem;
            > ul {
              > li {
                font-size: 2rem;
                margin-left: 1em;
                margin-bottom: 1rem;
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        justify-content: center;
        gap: 4rem;
        flex-wrap: wrap;
      }
    }

    > div:nth-child(2) {
      height: 15rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      > h1 {
        > span {
          color: ${({ theme }) => theme.colors.green};
        }
        color: ${({ theme }) => theme.colors.blue5};
        font-size: 1.9rem;
        font-weight: 500;
        margin-bottom: 1rem;
      }
      > button {
        background-color: ${({ theme }) => theme.colors.blue5};
        border-radius: 0.4rem;
        padding: 0.8rem 3.5rem;
        border: none;
        font-size: 1.3rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.white};
        cursor: pointer;
      }
    }
  }
`;
