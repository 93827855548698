import React from "react";
import * as S from "./styles";
import { useNavigate } from "react-router-dom";

export default function OrbitalSVGRed() {
  const navigate = useNavigate();
  return (
    <S.SVG
      id="RED"
      data-name="RED"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="65.05 84.15 963.95 964.08"
    >
      <circle
        className="circle-center-red"
        cx="549.59"
        cy="563.35"
        r="136.66"
        onClick={() => navigate("/jornada/red")}
      ></circle>
      <g>
        <path
          className="cls-3"
          d="M472.1,711.42a3.18,3.18,0,1,1-.72,4.44A3.19,3.19,0,0,1,472.1,711.42Z"
        ></path>
        <path
          className="cls-3"
          d="M482.48,716.12a3.17,3.17,0,1,1-.73,4.43A3.17,3.17,0,0,1,482.48,716.12Z"
        ></path>
        <path
          className="cls-3"
          d="M492.42,724.51a3.17,3.17,0,1,1,4.43.73A3.17,3.17,0,0,1,492.42,724.51Z"
        ></path>
        <path
          className="cls-3"
          d="M502.67,728.21a4,4,0,1,1,5.58.91A4,4,0,0,1,502.67,728.21Z"
        ></path>
        <path
          className="cls-3"
          d="M514.8,730a2.75,2.75,0,1,1,3.84.63A2.75,2.75,0,0,1,514.8,730Z"
        ></path>
        <path
          className="cls-3"
          d="M525.37,732.13a3.6,3.6,0,1,1,5,.82A3.61,3.61,0,0,1,525.37,732.13Z"
        ></path>
        <path
          className="cls-3"
          d="M542.57,728.83a3.6,3.6,0,1,1-5-.82A3.61,3.61,0,0,1,542.57,728.83Z"
        ></path>
        <path
          className="cls-3"
          d="M548.1,733.15a3.6,3.6,0,1,1,5,.83A3.6,3.6,0,0,1,548.1,733.15Z"
        ></path>
        <path
          className="cls-3"
          d="M560.78,728.15a2.75,2.75,0,1,1-.63,3.84A2.75,2.75,0,0,1,560.78,728.15Z"
        ></path>
        <path
          className="cls-3"
          d="M571.83,726.36a3.17,3.17,0,1,1-.73,4.43A3.17,3.17,0,0,1,571.83,726.36Z"
        ></path>
        <path
          className="cls-3"
          d="M583.47,724.8a2.36,2.36,0,1,1-.54,3.29A2.35,2.35,0,0,1,583.47,724.8Z"
        ></path>
        <path
          className="cls-3"
          d="M593.25,725.58a3.18,3.18,0,1,1,4.43.73A3.17,3.17,0,0,1,593.25,725.58Z"
        ></path>
        <circle className="cls-3" cx="606.58" cy="719.99" r="3.18"></circle>
        <path
          className="cls-3"
          d="M615.13,716.89a2.36,2.36,0,1,1,3.3.54A2.36,2.36,0,0,1,615.13,716.89Z"
        ></path>
        <path
          className="cls-3"
          d="M629.42,708.73a2.75,2.75,0,1,1-3.84-.63A2.74,2.74,0,0,1,629.42,708.73Z"
        ></path>
        <path
          className="cls-3"
          d="M634.7,706.07a2.76,2.76,0,1,1,3.85.63A2.76,2.76,0,0,1,634.7,706.07Z"
        ></path>
        <path
          className="cls-3"
          d="M644,699.54a2.75,2.75,0,1,1,3.84.63A2.75,2.75,0,0,1,644,699.54Z"
        ></path>
        <path
          className="cls-3"
          d="M653.2,692.15a2.36,2.36,0,1,1,3.3.54A2.35,2.35,0,0,1,653.2,692.15Z"
        ></path>
        <path
          className="cls-3"
          d="M661.54,684.4a2.36,2.36,0,1,1,3.29.54A2.35,2.35,0,0,1,661.54,684.4Z"
        ></path>
        <path
          className="cls-3"
          d="M673.09,677.3a3.17,3.17,0,1,1,.73-4.44A3.18,3.18,0,0,1,673.09,677.3Z"
        ></path>
        <path
          className="cls-3"
          d="M680.35,664.51a2.36,2.36,0,1,1-3.3-.53A2.37,2.37,0,0,1,680.35,664.51Z"
        ></path>
        <path
          className="cls-3"
          d="M683.08,658a2.36,2.36,0,1,1,3.29.54A2.37,2.37,0,0,1,683.08,658Z"
        ></path>
        <path
          className="cls-3"
          d="M689.33,648a1.94,1.94,0,1,1,2.7.44A1.94,1.94,0,0,1,689.33,648Z"
        ></path>
        <path
          className="cls-3"
          d="M697.26,638.32a1.94,1.94,0,1,1,.44-2.7A1.94,1.94,0,0,1,697.26,638.32Z"
        ></path>
        <path
          className="cls-3"
          d="M701.87,625.41a1.52,1.52,0,0,1-2.46,1.77,1.52,1.52,0,1,1,2.46-1.77Z"
        ></path>
        <path
          className="cls-3"
          d="M706,617.79a2.75,2.75,0,1,1,.63-3.84A2.75,2.75,0,0,1,706,617.79Z"
        ></path>
        <path
          className="cls-3"
          d="M709.06,606.82a2.75,2.75,0,1,1,.63-3.84A2.76,2.76,0,0,1,709.06,606.82Z"
        ></path>
        <path
          className="cls-3"
          d="M711.1,595.34a2.36,2.36,0,1,1,.54-3.29A2.37,2.37,0,0,1,711.1,595.34Z"
        ></path>
        <path
          className="cls-3"
          d="M712.82,584.38a2.76,2.76,0,1,1,.63-3.85A2.77,2.77,0,0,1,712.82,584.38Z"
        ></path>
        <path
          className="cls-3"
          d="M709.68,572.38a2.76,2.76,0,1,1,3.84.63A2.76,2.76,0,0,1,709.68,572.38Z"
        ></path>
        <path
          className="cls-3"
          d="M710.62,560.28a1.51,1.51,0,1,1,2.11.34A1.51,1.51,0,0,1,710.62,560.28Z"
        ></path>
        <path
          className="cls-3"
          d="M709.41,549.17a1.93,1.93,0,1,1,2.7.45A1.93,1.93,0,0,1,709.41,549.17Z"
        ></path>
        <path
          className="cls-3"
          d="M706.76,538.63a3.17,3.17,0,1,1,4.43.73A3.17,3.17,0,0,1,706.76,538.63Z"
        ></path>
        <path
          className="cls-3"
          d="M705.35,526.78a1.94,1.94,0,1,1,2.7.45A1.94,1.94,0,0,1,705.35,526.78Z"
        ></path>
        <path
          className="cls-3"
          d="M701.83,516.1a2.36,2.36,0,1,1,3.29.54A2.35,2.35,0,0,1,701.83,516.1Z"
        ></path>
        <path
          className="cls-3"
          d="M697.24,505.89a3.18,3.18,0,1,1,4.43.73A3.17,3.17,0,0,1,697.24,505.89Z"
        ></path>
        <path
          className="cls-3"
          d="M693.25,495a2.36,2.36,0,1,1,3.3.54A2.35,2.35,0,0,1,693.25,495Z"
        ></path>
        <path
          className="cls-3"
          d="M687.9,485a2.36,2.36,0,1,1,3.29.54A2.37,2.37,0,0,1,687.9,485Z"
        ></path>
        <path
          className="cls-3"
          d="M681.54,475.56a2.75,2.75,0,1,1,3.84.63A2.75,2.75,0,0,1,681.54,475.56Z"
        ></path>
        <path
          className="cls-3"
          d="M674.85,466.34a2.75,2.75,0,1,1,3.84.63A2.74,2.74,0,0,1,674.85,466.34Z"
        ></path>
        <path
          className="cls-3"
          d="M667.54,457.62a2.75,2.75,0,1,1,3.84.63A2.75,2.75,0,0,1,667.54,457.62Z"
        ></path>
        <path
          className="cls-3"
          d="M660,449.18a2.36,2.36,0,1,1,3.29.54A2.36,2.36,0,0,1,660,449.18Z"
        ></path>
        <path
          className="cls-3"
          d="M650.86,442a3.17,3.17,0,1,1,4.43.73A3.17,3.17,0,0,1,650.86,442Z"
        ></path>
        <path
          className="cls-3"
          d="M641.91,435a3.17,3.17,0,1,1,4.44.73A3.18,3.18,0,0,1,641.91,435Z"
        ></path>
        <path
          className="cls-3"
          d="M633.16,428.1a2.36,2.36,0,1,1,3.3.54A2.36,2.36,0,0,1,633.16,428.1Z"
        ></path>
        <path
          className="cls-3"
          d="M627.1,423.57a3.17,3.17,0,1,1,.73-4.44A3.18,3.18,0,0,1,627.1,423.57Z"
        ></path>
        <path
          className="cls-3"
          d="M616.42,417.86a2.36,2.36,0,1,1,.54-3.3A2.35,2.35,0,0,1,616.42,417.86Z"
        ></path>
        <path
          className="cls-3"
          d="M603.29,412.49a1.51,1.51,0,1,1,2.11.35A1.51,1.51,0,0,1,603.29,412.49Z"
        ></path>
        <circle className="cls-3" cx="593.72" cy="408.01" r="3.18"></circle>
        <circle className="cls-3" cx="582.69" cy="405.17" r="3.6"></circle>
        <path
          className="cls-3"
          d="M573.11,405.33a2.75,2.75,0,1,1,.63-3.84A2.75,2.75,0,0,1,573.11,405.33Z"
        ></path>
        <path
          className="cls-3"
          d="M562.52,405.05a4,4,0,1,1,.91-5.58A4,4,0,0,1,562.52,405.05Z"
        ></path>
        <path
          className="cls-3"
          d="M550.67,403.87a3.18,3.18,0,1,1,.73-4.43A3.17,3.17,0,0,1,550.67,403.87Z"
        ></path>
        <path
          className="cls-3"
          d="M539.29,404.14a3.18,3.18,0,1,1,.73-4.43A3.16,3.16,0,0,1,539.29,404.14Z"
        ></path>
        <path
          className="cls-3"
          d="M528.34,401a2.75,2.75,0,1,1-3.84-.63A2.76,2.76,0,0,1,528.34,401Z"
        ></path>
        <path
          className="cls-3"
          d="M517.19,407.7a4,4,0,1,1,.92-5.57A4,4,0,0,1,517.19,407.7Z"
        ></path>
        <path
          className="cls-3"
          d="M507.37,404.49a4.41,4.41,0,1,1-6.16-1A4.42,4.42,0,0,1,507.37,404.49Z"
        ></path>
        <path
          className="cls-3"
          d="M494.52,412.66a2.75,2.75,0,1,1,.63-3.84A2.75,2.75,0,0,1,494.52,412.66Z"
        ></path>
        <path
          className="cls-3"
          d="M484.53,412.93a2.75,2.75,0,1,1-3.84-.63A2.75,2.75,0,0,1,484.53,412.93Z"
        ></path>
        <path
          className="cls-3"
          d="M470.13,416.79a3.18,3.18,0,1,1-.73,4.43A3.17,3.17,0,0,1,470.13,416.79Z"
        ></path>
        <path
          className="cls-3"
          d="M464.61,423a3.18,3.18,0,1,1-4.43-.72A3.18,3.18,0,0,1,464.61,423Z"
        ></path>
        <circle className="cls-3" cx="452.49" cy="431.1" r="3.6"></circle>
        <path
          className="cls-3"
          d="M441.29,435a3.6,3.6,0,1,1-.82,5A3.6,3.6,0,0,1,441.29,435Z"
        ></path>
        <path
          className="cls-3"
          d="M432.21,447.26a3.17,3.17,0,1,1,4.44.73A3.18,3.18,0,0,1,432.21,447.26Z"
        ></path>
        <path
          className="cls-3"
          d="M424.88,450.86a3.17,3.17,0,1,1-.73,4.43A3.17,3.17,0,0,1,424.88,450.86Z"
        ></path>
        <path
          className="cls-3"
          d="M421.47,460.41a2.75,2.75,0,1,1-3.84-.63A2.74,2.74,0,0,1,421.47,460.41Z"
        ></path>
        <path
          className="cls-3"
          d="M414.59,469.48a2.75,2.75,0,1,1-3.84-.63A2.75,2.75,0,0,1,414.59,469.48Z"
        ></path>
        <path
          className="cls-3"
          d="M409.36,478.27a4,4,0,1,1-5.58-.91A4,4,0,0,1,409.36,478.27Z"
        ></path>
        <path
          className="cls-3"
          d="M403.47,488.43a3.6,3.6,0,1,1-5-.82A3.6,3.6,0,0,1,403.47,488.43Z"
        ></path>
        <path
          className="cls-3"
          d="M398.92,498.49a4,4,0,1,1-5.58-.91A4,4,0,0,1,398.92,498.49Z"
        ></path>
        <path
          className="cls-3"
          d="M394.1,509.57a3.18,3.18,0,1,1-4.43-.73A3.17,3.17,0,0,1,394.1,509.57Z"
        ></path>
        <circle className="cls-3" cx="388.12" cy="522.28" r="4.42"></circle>
        <circle className="cls-3" cx="385.47" cy="533.36" r="3.99"></circle>
        <path
          className="cls-3"
          d="M380.34,546.91a4,4,0,1,1,5.57.92A4,4,0,0,1,380.34,546.91Z"
        ></path>
        <path
          className="cls-3"
          d="M379.91,552.33a4.41,4.41,0,1,1-1,6.16A4.41,4.41,0,0,1,379.91,552.33Z"
        ></path>
        <path
          className="cls-3"
          d="M380.32,564.71a3.17,3.17,0,1,1-.73,4.44A3.18,3.18,0,0,1,380.32,564.71Z"
        ></path>
        <path
          className="cls-3"
          d="M380.31,575.42a4,4,0,1,1-.92,5.58A4,4,0,0,1,380.31,575.42Z"
        ></path>
        <path
          className="cls-3"
          d="M381.56,586.74a4,4,0,1,1-.91,5.57A4,4,0,0,1,381.56,586.74Z"
        ></path>
        <path
          className="cls-3"
          d="M382.68,603.51a4,4,0,1,1,5.57.92A4,4,0,0,1,382.68,603.51Z"
        ></path>
        <path
          className="cls-3"
          d="M385.47,614.55a4,4,0,1,1,5.58.91A4,4,0,0,1,385.47,614.55Z"
        ></path>
        <path
          className="cls-3"
          d="M390.67,620.8a2.75,2.75,0,1,1-.63,3.84A2.74,2.74,0,0,1,390.67,620.8Z"
        ></path>
        <path
          className="cls-3"
          d="M394,635.43a3.17,3.17,0,1,1,4.44.73A3.17,3.17,0,0,1,394,635.43Z"
        ></path>
        <path
          className="cls-3"
          d="M399.47,640.88a3.6,3.6,0,1,1-.82,5A3.6,3.6,0,0,1,399.47,640.88Z"
        ></path>
        <path
          className="cls-3"
          d="M405.17,650.73a3.6,3.6,0,1,1-.82,5A3.6,3.6,0,0,1,405.17,650.73Z"
        ></path>
        <path
          className="cls-3"
          d="M411.54,660.17a3.6,3.6,0,1,1-.83,5A3.6,3.6,0,0,1,411.54,660.17Z"
        ></path>
        <path
          className="cls-3"
          d="M418.79,669.49a3.18,3.18,0,1,1-.73,4.43A3.17,3.17,0,0,1,418.79,669.49Z"
        ></path>
        <path
          className="cls-3"
          d="M425.67,682.39a3.17,3.17,0,1,1,4.43.73A3.16,3.16,0,0,1,425.67,682.39Z"
        ></path>
        <path
          className="cls-3"
          d="M433.84,690.32a3.18,3.18,0,1,1,4.43.72A3.18,3.18,0,0,1,433.84,690.32Z"
        ></path>
        <path
          className="cls-3"
          d="M443.51,693.57a2.75,2.75,0,1,1-.63,3.84A2.76,2.76,0,0,1,443.51,693.57Z"
        ></path>
        <path
          className="cls-3"
          d="M450.72,705.11a4.41,4.41,0,1,1,6.16,1A4.42,4.42,0,0,1,450.72,705.11Z"
        ></path>
        <circle className="cls-3" cx="463.93" cy="708.61" r="3.99"></circle>
        <path
          className="cls-3"
          d="M467.77,725.78a.68.68,0,0,1,.16-1,.69.69,0,0,1,1,.16.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M477.32,728.17a2.36,2.36,0,1,1-.54,3.3A2.36,2.36,0,0,1,477.32,728.17Z"
        ></path>
        <path
          className="cls-3"
          d="M488.11,735a1.51,1.51,0,1,1,2.11.34A1.51,1.51,0,0,1,488.11,735Z"
        ></path>
        <path
          className="cls-3"
          d="M499.3,738.14a1.12,1.12,0,1,1,1.57.26A1.12,1.12,0,0,1,499.3,738.14Z"
        ></path>
        <path
          className="cls-3"
          d="M510.38,740.79a1.11,1.11,0,1,1,1.56.26A1.11,1.11,0,0,1,510.38,740.79Z"
        ></path>
        <path
          className="cls-3"
          d="M520.93,743.21a1.94,1.94,0,1,1,3.15-2.26,1.94,1.94,0,0,1-3.15,2.26Z"
        ></path>
        <path
          className="cls-3"
          d="M532.22,741.05a2.76,2.76,0,1,1-.63,3.84A2.76,2.76,0,0,1,532.22,741.05Z"
        ></path>
        <path
          className="cls-3"
          d="M544.79,743.21a.7.7,0,0,1,1,.16.69.69,0,0,1-.16,1,.7.7,0,0,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M557,744.1a.7.7,0,1,1-.81-1.13.7.7,0,1,1,.81,1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M567,741.33a1.51,1.51,0,1,1-.35,2.11A1.52,1.52,0,0,1,567,741.33Z"
        ></path>
        <path
          className="cls-3"
          d="M577.61,742a1.93,1.93,0,1,1,2.7.44A1.93,1.93,0,0,1,577.61,742Z"
        ></path>
        <path
          className="cls-3"
          d="M589.65,737.53a1.12,1.12,0,1,1-.25,1.57A1.12,1.12,0,0,1,589.65,737.53Z"
        ></path>
        <path
          className="cls-3"
          d="M600.6,734.41a1.12,1.12,0,1,1-.26,1.56A1.11,1.11,0,0,1,600.6,734.41Z"
        ></path>
        <path
          className="cls-3"
          d="M610.4,732.63a1.94,1.94,0,1,1,2.7.44A1.94,1.94,0,0,1,610.4,732.63Z"
        ></path>
        <path
          className="cls-3"
          d="M621.87,727.41a.69.69,0,0,1,.16-1,.68.68,0,0,1,1,.16.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M632,722.25a.68.68,0,0,1,.16-1,.69.69,0,0,1,1,.16.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M642.94,715.64a.69.69,0,0,1-.15,1,.7.7,0,1,1,.15-1Z"
        ></path>
        <path
          className="cls-3"
          d="M652.19,710.2a.7.7,0,0,1-.81-1.13.69.69,0,0,1,1,.15A.71.71,0,0,1,652.19,710.2Z"
        ></path>
        <path
          className="cls-3"
          d="M660.2,703a.69.69,0,0,1,.15-1,.71.71,0,0,1,1,.16.7.7,0,0,1-.16,1A.68.68,0,0,1,660.2,703Z"
        ></path>
        <path
          className="cls-3"
          d="M669.83,694.66a.68.68,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.69.69,0,0,1,669.83,694.66Z"
        ></path>
        <path
          className="cls-3"
          d="M675.7,688.1a1.94,1.94,0,1,1,2.7.44A1.94,1.94,0,0,1,675.7,688.1Z"
        ></path>
        <path
          className="cls-3"
          d="M685.88,680a1.93,1.93,0,1,1,.44-2.7A1.94,1.94,0,0,1,685.88,680Z"
        ></path>
        <path
          className="cls-3"
          d="M691,668.42a1.12,1.12,0,1,1-.26,1.56A1.11,1.11,0,0,1,691,668.42Z"
        ></path>
        <path
          className="cls-3"
          d="M698.53,659.45a.7.7,0,0,1-1.13.81.68.68,0,0,1,.16-1A.69.69,0,0,1,698.53,659.45Z"
        ></path>
        <path
          className="cls-3"
          d="M704.56,649.34a1.12,1.12,0,0,1-1.82,1.31,1.12,1.12,0,1,1,1.82-1.31Z"
        ></path>
        <path
          className="cls-3"
          d="M708.14,640.19a.69.69,0,0,1,.15-1,.71.71,0,0,1,1,.16.7.7,0,0,1-.16,1A.68.68,0,0,1,708.14,640.19Z"
        ></path>
        <path
          className="cls-3"
          d="M714.31,628.4a1.52,1.52,0,1,1-2.11-.35A1.52,1.52,0,0,1,714.31,628.4Z"
        ></path>
        <path
          className="cls-3"
          d="M716.22,618.92a.7.7,0,1,1,1,.16A.69.69,0,0,1,716.22,618.92Z"
        ></path>
        <path
          className="cls-3"
          d="M718.88,608.19a1.12,1.12,0,1,1,1.56.25A1.11,1.11,0,0,1,718.88,608.19Z"
        ></path>
        <path
          className="cls-3"
          d="M721.52,596.79a.69.69,0,0,1,.16-1,.7.7,0,1,1-.16,1Z"
        ></path>
        <path
          className="cls-3"
          d="M724.31,586a1.12,1.12,0,1,1,.26-1.56A1.12,1.12,0,0,1,724.31,586Z"
        ></path>
        <path
          className="cls-3"
          d="M724.91,574.32a.68.68,0,0,1-1-.16.7.7,0,1,1,1.13-.81A.68.68,0,0,1,724.91,574.32Z"
        ></path>
        <path
          className="cls-3"
          d="M724.06,562.78a.7.7,0,0,1,.16-1,.69.69,0,0,1,1,.16.68.68,0,0,1-.16,1A.7.7,0,0,1,724.06,562.78Z"
        ></path>
        <path
          className="cls-3"
          d="M724.58,550.6a.68.68,0,0,1-.16,1,.69.69,0,0,1-1-.16.7.7,0,1,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M721.77,540.35a1.12,1.12,0,1,1,1.57.26A1.12,1.12,0,0,1,721.77,540.35Z"
        ></path>
        <path
          className="cls-3"
          d="M720.06,528.91a.7.7,0,1,1,1.13-.81.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M716.94,518.11a1.12,1.12,0,1,1,1.57.26A1.12,1.12,0,0,1,716.94,518.11Z"
        ></path>
        <path
          className="cls-3"
          d="M713.47,507.27a1.12,1.12,0,1,1,1.56.26A1.12,1.12,0,0,1,713.47,507.27Z"
        ></path>
        <path
          className="cls-3"
          d="M708.65,497.15a1.93,1.93,0,1,1,2.7.44A1.93,1.93,0,0,1,708.65,497.15Z"
        ></path>
        <path
          className="cls-3"
          d="M704.82,486.12a.7.7,0,0,1,.16-1,.7.7,0,0,1,.81,1.13A.7.7,0,0,1,704.82,486.12Z"
        ></path>
        <path
          className="cls-3"
          d="M699.34,476.14a.7.7,0,1,1,1.13-.81.68.68,0,0,1-.16,1A.69.69,0,0,1,699.34,476.14Z"
        ></path>
        <path
          className="cls-3"
          d="M693.23,466.54a.71.71,0,0,1,.16-1,.7.7,0,0,1,1,.16.68.68,0,0,1-.16,1A.69.69,0,0,1,693.23,466.54Z"
        ></path>
        <path
          className="cls-3"
          d="M686.52,457.34a.7.7,0,1,1,1.13-.81.69.69,0,0,1-.16,1A.7.7,0,0,1,686.52,457.34Z"
        ></path>
        <path
          className="cls-3"
          d="M679.23,448.59a.7.7,0,1,1,1.13-.81.7.7,0,1,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M671.4,440.33a.7.7,0,1,1,1,.16A.68.68,0,0,1,671.4,440.33Z"
        ></path>
        <path
          className="cls-3"
          d="M661.7,433.56a2.36,2.36,0,1,1,3.3.54A2.35,2.35,0,0,1,661.7,433.56Z"
        ></path>
        <path
          className="cls-3"
          d="M653.22,426.12a1.94,1.94,0,1,1,2.7.45A1.94,1.94,0,0,1,653.22,426.12Z"
        ></path>
        <path
          className="cls-3"
          d="M644.62,419a1.11,1.11,0,1,1,1.56.26A1.12,1.12,0,0,1,644.62,419Z"
        ></path>
        <path
          className="cls-3"
          d="M635.29,412.78a.68.68,0,0,1,.16-1,.7.7,0,1,1,.81,1.13A.68.68,0,0,1,635.29,412.78Z"
        ></path>
        <path
          className="cls-3"
          d="M624.93,405.78a1.51,1.51,0,1,1-.34,2.11A1.52,1.52,0,0,1,624.93,405.78Z"
        ></path>
        <path
          className="cls-3"
          d="M614.89,402.69a.7.7,0,1,1,1.13-.81.69.69,0,0,1-.16,1A.68.68,0,0,1,614.89,402.69Z"
        ></path>
        <path
          className="cls-3"
          d="M603.58,399.12a1.51,1.51,0,1,1,2.12.35A1.52,1.52,0,0,1,603.58,399.12Z"
        ></path>
        <path
          className="cls-3"
          d="M593.53,394.32a.7.7,0,0,1,.81,1.13.68.68,0,0,1-1-.16A.7.7,0,0,1,593.53,394.32Z"
        ></path>
        <path
          className="cls-3"
          d="M584,393.81a1.94,1.94,0,1,1,.45-2.7A1.93,1.93,0,0,1,584,393.81Z"
        ></path>
        <path
          className="cls-3"
          d="M573.88,388.7a2.75,2.75,0,1,1-3.84-.63A2.75,2.75,0,0,1,573.88,388.7Z"
        ></path>
        <path
          className="cls-3"
          d="M561.23,388.44a1.11,1.11,0,1,1-1.56-.26A1.12,1.12,0,0,1,561.23,388.44Z"
        ></path>
        <path
          className="cls-3"
          d="M549.83,389.83a1.51,1.51,0,1,1,.35-2.11A1.51,1.51,0,0,1,549.83,389.83Z"
        ></path>
        <path
          className="cls-3"
          d="M539,390.77a2.36,2.36,0,1,1,.54-3.3A2.38,2.38,0,0,1,539,390.77Z"
        ></path>
        <path
          className="cls-3"
          d="M528.14,388.45a2.36,2.36,0,1,1-3.29-.54A2.36,2.36,0,0,1,528.14,388.45Z"
        ></path>
        <path
          className="cls-3"
          d="M515.53,391.11a.69.69,0,0,1-.16,1,.7.7,0,0,1-.81-1.13A.69.69,0,0,1,515.53,391.11Z"
        ></path>
        <path
          className="cls-3"
          d="M505,395.51a1.94,1.94,0,1,1,.45-2.7A1.95,1.95,0,0,1,505,395.51Z"
        ></path>
        <path
          className="cls-3"
          d="M493.55,398a1.12,1.12,0,0,1-1.31-1.82,1.13,1.13,0,0,1,1.57.26A1.12,1.12,0,0,1,493.55,398Z"
        ></path>
        <path
          className="cls-3"
          d="M481.29,399.65a1.51,1.51,0,1,1-.35,2.11A1.51,1.51,0,0,1,481.29,399.65Z"
        ></path>
        <path
          className="cls-3"
          d="M470.14,406.51a1.94,1.94,0,1,1,3.15-2.26,1.94,1.94,0,0,1-3.15,2.26Z"
        ></path>
        <path
          className="cls-3"
          d="M459.65,411.91a2.36,2.36,0,1,1,3.29.54A2.35,2.35,0,0,1,459.65,411.91Z"
        ></path>
        <path
          className="cls-3"
          d="M453.14,418.25a2.36,2.36,0,1,1,.54-3.29A2.36,2.36,0,0,1,453.14,418.25Z"
        ></path>
        <path
          className="cls-3"
          d="M444.28,421.37a2.36,2.36,0,1,1-3.29-.54A2.35,2.35,0,0,1,444.28,421.37Z"
        ></path>
        <path
          className="cls-3"
          d="M435.3,428.38a2.36,2.36,0,1,1-3.29-.54A2.36,2.36,0,0,1,435.3,428.38Z"
        ></path>
        <path
          className="cls-3"
          d="M426.8,435.94a2.36,2.36,0,1,1-3.3-.54A2.36,2.36,0,0,1,426.8,435.94Z"
        ></path>
        <path
          className="cls-3"
          d="M418.45,444.28a1.94,1.94,0,1,1-2.71-.44A1.94,1.94,0,0,1,418.45,444.28Z"
        ></path>
        <path
          className="cls-3"
          d="M410.63,453.12a1.51,1.51,0,1,1-2.11-.35A1.51,1.51,0,0,1,410.63,453.12Z"
        ></path>
        <path
          className="cls-3"
          d="M401.37,461.48a1.93,1.93,0,1,1-.44,2.7A1.93,1.93,0,0,1,401.37,461.48Z"
        ></path>
        <path
          className="cls-3"
          d="M395,473.41a1.51,1.51,0,1,1,2.11.34A1.52,1.52,0,0,1,395,473.41Z"
        ></path>
        <path
          className="cls-3"
          d="M389.61,481.16a1.51,1.51,0,1,1-.34,2.11A1.51,1.51,0,0,1,389.61,481.16Z"
        ></path>
        <path
          className="cls-3"
          d="M387.3,495.17a3.17,3.17,0,1,1,.73-4.43A3.17,3.17,0,0,1,387.3,495.17Z"
        ></path>
        <path
          className="cls-3"
          d="M379.69,501.18a2.36,2.36,0,1,1-.54,3.29A2.35,2.35,0,0,1,379.69,501.18Z"
        ></path>
        <path
          className="cls-3"
          d="M379.28,512.49A2.36,2.36,0,1,1,376,512,2.36,2.36,0,0,1,379.28,512.49Z"
        ></path>
        <path
          className="cls-3"
          d="M376.28,523.47a2.36,2.36,0,1,1-3.3-.54A2.36,2.36,0,0,1,376.28,523.47Z"
        ></path>
        <circle className="cls-3" cx="372.06" cy="535.99" r="3.6"></circle>
        <path
          className="cls-3"
          d="M369.61,546a1.51,1.51,0,1,1-.35,2.11A1.51,1.51,0,0,1,369.61,546Z"
        ></path>
        <path
          className="cls-3"
          d="M368.26,556.71a2.36,2.36,0,1,1-.54,3.29A2.36,2.36,0,0,1,368.26,556.71Z"
        ></path>
        <path
          className="cls-3"
          d="M371.44,568.63a2.36,2.36,0,1,1-3.3-.54A2.36,2.36,0,0,1,371.44,568.63Z"
        ></path>
        <path
          className="cls-3"
          d="M367.55,583.23A3.18,3.18,0,1,1,372,584,3.18,3.18,0,0,1,367.55,583.23Z"
        ></path>
        <path
          className="cls-3"
          d="M372.38,592a1.12,1.12,0,1,1-1.57-.26A1.12,1.12,0,0,1,372.38,592Z"
        ></path>
        <path
          className="cls-3"
          d="M374.75,603a1.52,1.52,0,1,1-2.11-.34A1.51,1.51,0,0,1,374.75,603Z"
        ></path>
        <path
          className="cls-3"
          d="M377.87,613.79a1.94,1.94,0,1,1-2.7-.44A1.94,1.94,0,0,1,377.87,613.79Z"
        ></path>
        <path
          className="cls-3"
          d="M378.89,624.53a1.51,1.51,0,1,1-.35,2.11A1.51,1.51,0,0,1,378.89,624.53Z"
        ></path>
        <path
          className="cls-3"
          d="M382.36,637.49a1.93,1.93,0,1,1,2.7.44A1.94,1.94,0,0,1,382.36,637.49Z"
        ></path>
        <path
          className="cls-3"
          d="M386.84,648a2.36,2.36,0,1,1,3.3.54A2.35,2.35,0,0,1,386.84,648Z"
        ></path>
        <path
          className="cls-3"
          d="M392.32,658a2.36,2.36,0,1,1,3.3.54A2.36,2.36,0,0,1,392.32,658Z"
        ></path>
        <path
          className="cls-3"
          d="M398.43,667.63a2.36,2.36,0,1,1,3.3.54A2.38,2.38,0,0,1,398.43,667.63Z"
        ></path>
        <path
          className="cls-3"
          d="M405.15,676.82a2.36,2.36,0,1,1,3.29.54A2.35,2.35,0,0,1,405.15,676.82Z"
        ></path>
        <path
          className="cls-3"
          d="M412.43,685.57a2.36,2.36,0,1,1,3.3.54A2.38,2.38,0,0,1,412.43,685.57Z"
        ></path>
        <path
          className="cls-3"
          d="M420.61,693.58a1.94,1.94,0,1,1,2.7.44A1.94,1.94,0,0,1,420.61,693.58Z"
        ></path>
        <path
          className="cls-3"
          d="M431.66,701.77a1.94,1.94,0,1,1,.44-2.7A1.94,1.94,0,0,1,431.66,701.77Z"
        ></path>
        <path
          className="cls-3"
          d="M440.26,706.73a1.12,1.12,0,1,1-1.56-.25A1.12,1.12,0,0,1,440.26,706.73Z"
        ></path>
        <path
          className="cls-3"
          d="M447.05,715.13a1.93,1.93,0,1,1,3.14-2.26,1.93,1.93,0,0,1-3.14,2.26Z"
        ></path>
        <path
          className="cls-3"
          d="M458.7,720.57a.7.7,0,0,1-.81-1.13.68.68,0,0,1,1,.16A.7.7,0,0,1,458.7,720.57Z"
        ></path>
        <path
          className="cls-3"
          d="M463.27,736.34a.68.68,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.69.69,0,0,1,463.27,736.34Z"
        ></path>
        <path
          className="cls-3"
          d="M472.65,740.92a.7.7,0,0,1,1,.16.68.68,0,0,1-.16,1,.7.7,0,0,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M484.24,745.19a.7.7,0,0,1-.16,1,.68.68,0,0,1-1-.16.69.69,0,0,1,.16-1A.7.7,0,0,1,484.24,745.19Z"
        ></path>
        <path
          className="cls-3"
          d="M494.11,748.5a.7.7,0,0,1,1,.16.69.69,0,0,1-.16,1,.68.68,0,0,1-1-.16A.7.7,0,0,1,494.11,748.5Z"
        ></path>
        <path
          className="cls-3"
          d="M506.11,751.48a.68.68,0,0,1-.16,1,.7.7,0,0,1-.81-1.13A.68.68,0,0,1,506.11,751.48Z"
        ></path>
        <path
          className="cls-3"
          d="M517.13,754.6a.7.7,0,1,1,.16-1A.68.68,0,0,1,517.13,754.6Z"
        ></path>
        <path
          className="cls-3"
          d="M528.42,756.08a.7.7,0,0,1-.81-1.13.69.69,0,0,1,1,.16A.7.7,0,0,1,528.42,756.08Z"
        ></path>
        <path
          className="cls-3"
          d="M539,755.75a.7.7,0,1,1-.16,1A.68.68,0,0,1,539,755.75Z"
        ></path>
        <path
          className="cls-3"
          d="M550.19,756.84a.7.7,0,1,1,1.13-.81.69.69,0,0,1-.16,1A.7.7,0,0,1,550.19,756.84Z"
        ></path>
        <path
          className="cls-3"
          d="M561.72,755.31a.7.7,0,0,1,.81,1.13.7.7,0,1,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M572.88,755a.68.68,0,0,1,.16-1,.69.69,0,0,1,1,.16.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M585.23,752.31a.7.7,0,0,1-1.13.82.71.71,0,0,1,.16-1A.7.7,0,0,1,585.23,752.31Z"
        ></path>
        <path
          className="cls-3"
          d="M595.21,750.53a.67.67,0,0,1,.16-.93.66.66,0,0,1,.93.15.67.67,0,0,1-.16.93A.68.68,0,0,1,595.21,750.53Z"
        ></path>
        <path
          className="cls-3"
          d="M606.13,747.28a.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15.66.66,0,1,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M616.8,743.42a.71.71,0,0,1,.16-1,.7.7,0,0,1,1,.16.68.68,0,0,1-.16,1A.69.69,0,0,1,616.8,743.42Z"
        ></path>
        <path
          className="cls-3"
          d="M628.21,739a.67.67,0,0,1-.93-.15.67.67,0,1,1,1.08-.78A.68.68,0,0,1,628.21,739Z"
        ></path>
        <path
          className="cls-3"
          d="M638.52,733a.68.68,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.68.68,0,0,1,638.52,733Z"
        ></path>
        <path
          className="cls-3"
          d="M647.42,727.08a.67.67,0,0,1,.78,1.08.67.67,0,0,1-.93-.16A.66.66,0,0,1,647.42,727.08Z"
        ></path>
        <path
          className="cls-3"
          d="M657.67,721.84a.66.66,0,0,1-.93-.15.67.67,0,1,1,1.09-.77A.66.66,0,0,1,657.67,721.84Z"
        ></path>
        <path
          className="cls-3"
          d="M666.75,715a.66.66,0,0,1-.93-.15.67.67,0,0,1,.16-.93.66.66,0,0,1,.92.16A.65.65,0,0,1,666.75,715Z"
        ></path>
        <path
          className="cls-3"
          d="M674.63,706.49a.67.67,0,0,1,.77,1.09.67.67,0,0,1-.77-1.09Z"
        ></path>
        <path
          className="cls-3"
          d="M682.82,698.59a.67.67,0,0,1,.93.15.67.67,0,0,1-1.08.78A.67.67,0,0,1,682.82,698.59Z"
        ></path>
        <path
          className="cls-3"
          d="M691.32,691.32a.7.7,0,0,1-.81-1.13.7.7,0,0,1,.81,1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M697.57,682.31a.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15.66.66,0,1,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M704.37,672.15a.67.67,0,0,1,.78,1.08.67.67,0,0,1-.93-.16A.66.66,0,0,1,704.37,672.15Z"
        ></path>
        <path
          className="cls-3"
          d="M711.41,662.66a.69.69,0,0,1-.16,1,.7.7,0,1,1,.16-1Z"
        ></path>
        <path
          className="cls-3"
          d="M715.78,653.5a.7.7,0,1,1,1.13-.81.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M720.68,643.23a.69.69,0,0,1,.16-1,.7.7,0,0,1,.81,1.13A.69.69,0,0,1,720.68,643.23Z"
        ></path>
        <path
          className="cls-3"
          d="M726.08,631.86a.7.7,0,0,1-.16,1,.7.7,0,0,1-.81-1.13A.7.7,0,0,1,726.08,631.86Z"
        ></path>
        <path
          className="cls-3"
          d="M728.6,621.87a.67.67,0,0,1,.16-.93.66.66,0,0,1,.93.15.67.67,0,0,1-.16.93A.68.68,0,0,1,728.6,621.87Z"
        ></path>
        <path
          className="cls-3"
          d="M731.59,610.88a.67.67,0,1,1,1.08-.78.67.67,0,0,1-.16.93A.66.66,0,0,1,731.59,610.88Z"
        ></path>
        <path
          className="cls-3"
          d="M733.88,599.75a.7.7,0,1,1,1.13-.81.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M736.48,588.62a.68.68,0,0,1-.93-.15.67.67,0,0,1,.15-.93.67.67,0,0,1,.78,1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M737.47,577.3a.7.7,0,0,1-1-.16.68.68,0,0,1,.16-1,.7.7,0,0,1,.81,1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M737.92,565a.7.7,0,0,1-1.13.81.68.68,0,0,1,.16-1A.69.69,0,0,1,737.92,565Z"
        ></path>
        <path
          className="cls-3"
          d="M736.4,554.38a.68.68,0,0,1,.16-1,.7.7,0,0,1,.81,1.13A.69.69,0,0,1,736.4,554.38Z"
        ></path>
        <path
          className="cls-3"
          d="M735.33,543.05a.7.7,0,1,1,1.13-.81.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M733.58,531.8a.7.7,0,0,1,.16-1,.68.68,0,0,1,1,.16.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M731.19,520.65a.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15.66.66,0,1,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M728.11,509.69a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-.15.93A.67.67,0,0,1,728.11,509.69Z"
        ></path>
        <path
          className="cls-3"
          d="M724.39,498.94a.67.67,0,0,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M720,488.44a.7.7,0,1,1,1.13-.81.7.7,0,1,1-1.13.81Z"
        ></path>
        <circle
          className="cls-3"
          cx="715.58"
          cy="477.8"
          r="0.66"
          transform="translate(-144.36 507.06) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M709.45,468.27a.67.67,0,0,1,1.08-.78.67.67,0,1,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M703.28,458.7a.66.66,0,0,1,.15-.93.68.68,0,0,1,.93.15.67.67,0,0,1-.15.93A.66.66,0,0,1,703.28,458.7Z"
        ></path>
        <path
          className="cls-3"
          d="M696.55,449.52a.66.66,0,0,1,.15-.93.66.66,0,1,1,.77,1.08A.65.65,0,0,1,696.55,449.52Z"
        ></path>
        <path
          className="cls-3"
          d="M689.28,440.76a.67.67,0,0,1,1.08-.78.68.68,0,0,1-.15.93A.67.67,0,0,1,689.28,440.76Z"
        ></path>
        <path
          className="cls-3"
          d="M681.5,432.45a.67.67,0,0,1,1.08-.78.67.67,0,1,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M673.21,424.63a.69.69,0,0,1,.16-1,.7.7,0,0,1,.81,1.13A.69.69,0,0,1,673.21,424.63Z"
        ></path>
        <path
          className="cls-3"
          d="M664.49,417.31a.7.7,0,0,1,.16-1,.69.69,0,0,1,1,.16.68.68,0,0,1-.16,1A.7.7,0,0,1,664.49,417.31Z"
        ></path>
        <path
          className="cls-3"
          d="M655.38,410.5a.66.66,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.66.66,0,0,1,655.38,410.5Z"
        ></path>
        <path
          className="cls-3"
          d="M645.85,404.27a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M635.94,398.64a.7.7,0,1,1,1,.16A.69.69,0,0,1,635.94,398.64Z"
        ></path>
        <path
          className="cls-3"
          d="M625.74,393.59a.69.69,0,0,1,.15-1,.71.71,0,0,1,1,.16.7.7,0,0,1-.16,1A.68.68,0,0,1,625.74,393.59Z"
        ></path>
        <path
          className="cls-3"
          d="M616.22,389.32a.7.7,0,1,1-.81-1.13.7.7,0,0,1,.81,1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M605.64,384.56a.68.68,0,0,1-.16,1,.69.69,0,0,1-1-.16.7.7,0,0,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M594.7,381.42a.69.69,0,0,1-.16,1,.7.7,0,0,1-.81-1.13A.68.68,0,0,1,594.7,381.42Z"
        ></path>
        <path
          className="cls-3"
          d="M583.57,379a.67.67,0,0,1-.16.93.66.66,0,0,1-.92-.16.65.65,0,0,1,.15-.92A.66.66,0,0,1,583.57,379Z"
        ></path>
        <path
          className="cls-3"
          d="M571.22,377.93a.7.7,0,0,1,.16-1,.68.68,0,0,1,1,.16.69.69,0,0,1-.16,1A.7.7,0,0,1,571.22,377.93Z"
        ></path>
        <path
          className="cls-3"
          d="M559.9,376.79a.69.69,0,0,1,.15-1,.7.7,0,0,1,1,.15.71.71,0,0,1-.16,1A.7.7,0,0,1,559.9,376.79Z"
        ></path>
        <path
          className="cls-3"
          d="M548.68,375.35a.7.7,0,0,1,1,.16.7.7,0,0,1-1.13.82A.71.71,0,0,1,548.68,375.35Z"
        ></path>
        <path
          className="cls-3"
          d="M538.27,375.73a.71.71,0,0,1-.16,1,.7.7,0,1,1,.16-1Z"
        ></path>
        <path
          className="cls-3"
          d="M525.79,377.45a.7.7,0,0,1,1.13-.81.7.7,0,1,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M515.49,379.18a.69.69,0,0,1-1-.16.7.7,0,0,1,1.13-.81A.69.69,0,0,1,515.49,379.18Z"
        ></path>
        <path
          className="cls-3"
          d="M503.35,381.27a.7.7,0,0,1,1.13-.81.7.7,0,0,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M492.35,384.19a.7.7,0,1,1,1.13-.82.71.71,0,0,1-.16,1A.7.7,0,0,1,492.35,384.19Z"
        ></path>
        <path
          className="cls-3"
          d="M481.69,386.78a.71.71,0,0,1,1,.16.7.7,0,0,1-1.13.81A.69.69,0,0,1,481.69,386.78Z"
        ></path>
        <path
          className="cls-3"
          d="M471.93,392.12a.7.7,0,0,1-1-.16.7.7,0,0,1,1.13-.81A.7.7,0,0,1,471.93,392.12Z"
        ></path>
        <path
          className="cls-3"
          d="M461.62,397a.7.7,0,0,1-1-.16.7.7,0,1,1,1.13-.81A.7.7,0,0,1,461.62,397Z"
        ></path>
        <path
          className="cls-3"
          d="M451.86,402.73a1.12,1.12,0,1,1,.26-1.56A1.11,1.11,0,0,1,451.86,402.73Z"
        ></path>
        <path
          className="cls-3"
          d="M442.21,408.76a1.12,1.12,0,1,1-1.31-1.82,1.12,1.12,0,0,1,1.31,1.82Z"
        ></path>
        <path
          className="cls-3"
          d="M433.18,413.79a1.12,1.12,0,0,1-1.82,1.31,1.12,1.12,0,1,1,1.82-1.31Z"
        ></path>
        <path
          className="cls-3"
          d="M422.75,420.67a1.12,1.12,0,1,1-.26,1.56A1.11,1.11,0,0,1,422.75,420.67Z"
        ></path>
        <path
          className="cls-3"
          d="M414.07,429.89a1.12,1.12,0,1,1,1.56.25A1.12,1.12,0,0,1,414.07,429.89Z"
        ></path>
        <path
          className="cls-3"
          d="M407.43,437.94a.7.7,0,1,1-.81-1.13.7.7,0,0,1,.81,1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M399.16,445.42a.7.7,0,0,1,1,.16.68.68,0,0,1-.16,1,.69.69,0,0,1-1-.16A.7.7,0,0,1,399.16,445.42Z"
        ></path>
        <path
          className="cls-3"
          d="M392.08,455.43a.7.7,0,0,1,.16-1,.69.69,0,0,1,1,.16.7.7,0,1,1-1.13.81Z"
        ></path>
        <path
          className="cls-3"
          d="M386.84,464.05a.7.7,0,0,1-.16,1,.7.7,0,0,1-.81-1.13A.7.7,0,0,1,386.84,464.05Z"
        ></path>
        <path
          className="cls-3"
          d="M381,473.85a.69.69,0,0,1-.16,1,.7.7,0,1,1,.16-1Z"
        ></path>
        <path
          className="cls-3"
          d="M374.37,485a1.12,1.12,0,1,1,1.56.25A1.13,1.13,0,0,1,374.37,485Z"
        ></path>
        <path
          className="cls-3"
          d="M371.1,495.37a.7.7,0,0,1-1-.16.68.68,0,0,1,.16-1,.69.69,0,0,1,1,.16A.7.7,0,0,1,371.1,495.37Z"
        ></path>
        <path
          className="cls-3"
          d="M365.86,504.25a1.51,1.51,0,1,1-.35,2.11A1.51,1.51,0,0,1,365.86,504.25Z"
        ></path>
        <path
          className="cls-3"
          d="M363,515.81a.7.7,0,0,1,.82,1.13.71.71,0,0,1-1-.16A.7.7,0,0,1,363,515.81Z"
        ></path>
        <path
          className="cls-3"
          d="M362,526.56a1.52,1.52,0,1,1-2.11-.34A1.51,1.51,0,0,1,362,526.56Z"
        ></path>
        <path
          className="cls-3"
          d="M359.36,538.25a.7.7,0,0,1-1.13.81.7.7,0,0,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M358.05,549.56a.7.7,0,0,1-1.13.81.7.7,0,1,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M356,560.1a1.52,1.52,0,1,1-.34,2.11A1.52,1.52,0,0,1,356,560.1Z"
        ></path>
        <path
          className="cls-3"
          d="M357.31,573.28a.69.69,0,0,1-1-.16.7.7,0,0,1,1.13-.81A.69.69,0,0,1,357.31,573.28Z"
        ></path>
        <path
          className="cls-3"
          d="M358,584.64a.68.68,0,0,1-1-.16.7.7,0,0,1,1.13-.81A.69.69,0,0,1,358,584.64Z"
        ></path>
        <path
          className="cls-3"
          d="M359.45,595.94a.7.7,0,1,1,.16-1A.7.7,0,0,1,359.45,595.94Z"
        ></path>
        <path
          className="cls-3"
          d="M361.53,607.13a.68.68,0,0,1-1-.16.7.7,0,0,1,1.13-.81A.69.69,0,0,1,361.53,607.13Z"
        ></path>
        <path
          className="cls-3"
          d="M364.44,617.21a.7.7,0,0,1-.16,1,.69.69,0,0,1-1-.16.7.7,0,0,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M366.87,627.92a.7.7,0,1,1,.81,1.13.7.7,0,1,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M370.92,638.56a.68.68,0,0,1,1,.16.7.7,0,1,1-1-.16Z"
        ></path>
        <path
          className="cls-3"
          d="M376.57,649.1a.7.7,0,0,1-1.13.81.7.7,0,0,1,1.13-.81Z"
        ></path>
        <path
          className="cls-3"
          d="M382.2,658.93a1.12,1.12,0,0,1-1.82,1.31,1.12,1.12,0,1,1,1.82-1.31Z"
        ></path>
        <path
          className="cls-3"
          d="M388.08,668.68a1.12,1.12,0,1,1-1.56-.26A1.12,1.12,0,0,1,388.08,668.68Z"
        ></path>
        <path
          className="cls-3"
          d="M394.54,678.06a1.12,1.12,0,1,1-1.57-.26A1.12,1.12,0,0,1,394.54,678.06Z"
        ></path>
        <path
          className="cls-3"
          d="M401.54,687a1.12,1.12,0,0,1-1.82,1.31,1.12,1.12,0,1,1,1.82-1.31Z"
        ></path>
        <path
          className="cls-3"
          d="M408.72,695.82a.68.68,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.69.69,0,0,1,408.72,695.82Z"
        ></path>
        <path
          className="cls-3"
          d="M416.75,703.89a.7.7,0,0,1-.16,1,.69.69,0,0,1-1-.15.7.7,0,1,1,1.13-.82Z"
        ></path>
        <path
          className="cls-3"
          d="M425.08,712.45a.7.7,0,0,1-1-.16.71.71,0,0,1,.16-1,.7.7,0,0,1,.81,1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M433.2,718.37a.7.7,0,0,1,1,.16.7.7,0,1,1-1.13.81A.7.7,0,0,1,433.2,718.37Z"
        ></path>
        <path
          className="cls-3"
          d="M443.51,725.05a.68.68,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.68.68,0,0,1,443.51,725.05Z"
        ></path>
        <path
          className="cls-3"
          d="M453.06,732a.7.7,0,1,1-.81-1.13.69.69,0,0,1,1,.16A.68.68,0,0,1,453.06,732Z"
        ></path>
        <path
          className="cls-3"
          d="M457.65,747.71a.7.7,0,0,1-1.13.81.68.68,0,0,1,.16-1A.7.7,0,0,1,457.65,747.71Z"
        ></path>
        <circle
          className="cls-3"
          cx="467.43"
          cy="752.87"
          r="0.66"
          transform="matrix(0.81, -0.58, 0.58, 0.81, -351.38, 413.96)"
        ></circle>
        <path
          className="cls-3"
          d="M478.56,756.66a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16.65.65,0,0,1,.15-.92A.66.66,0,0,1,478.56,756.66Z"
        ></path>
        <path
          className="cls-3"
          d="M488.44,760.08a.66.66,0,0,1,.93.15.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.15A.66.66,0,0,1,488.44,760.08Z"
        ></path>
        <path
          className="cls-3"
          d="M500.39,763.17a.7.7,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.7.7,0,0,1,500.39,763.17Z"
        ></path>
        <path
          className="cls-3"
          d="M511.35,766.46a.68.68,0,0,1-.93-.15.67.67,0,0,1,.16-.93.65.65,0,0,1,.92.15A.66.66,0,0,1,511.35,766.46Z"
        ></path>
        <circle
          className="cls-3"
          cx="522.22"
          cy="767.63"
          r="0.67"
          transform="translate(-349.7 448.68) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M533,769.09a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-.15.93A.67.67,0,0,1,533,769.09Z"
        ></path>
        <path
          className="cls-3"
          d="M545.32,769.68a.67.67,0,1,1-.78-1.08.67.67,0,0,1,.93.16A.66.66,0,0,1,545.32,769.68Z"
        ></path>
        <path
          className="cls-3"
          d="M555.77,769.33a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-.15.93A.67.67,0,0,1,555.77,769.33Z"
        ></path>
        <path
          className="cls-3"
          d="M568.21,767.72a.67.67,0,0,1-1.08.78.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M578.57,766.1a.66.66,0,0,1,.93.15.67.67,0,0,1-1.09.77A.66.66,0,0,1,578.57,766.1Z"
        ></path>
        <path
          className="cls-3"
          d="M590.69,764.14a.67.67,0,0,1-.16.93.66.66,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.68.68,0,0,1,590.69,764.14Z"
        ></path>
        <path
          className="cls-3"
          d="M601.74,761.42a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16.65.65,0,0,1,.15-.92A.66.66,0,0,1,601.74,761.42Z"
        ></path>
        <path
          className="cls-3"
          d="M612.62,758.08a.65.65,0,0,1-.15.92.66.66,0,0,1-.93-.15.66.66,0,1,1,1.08-.77Z"
        ></path>
        <path
          className="cls-3"
          d="M622.22,754.9a.66.66,0,0,1,.15-.93.67.67,0,1,1-.15.93Z"
        ></path>
        <path
          className="cls-3"
          d="M632.66,750.36a.66.66,0,1,1,.93.16A.67.67,0,0,1,632.66,750.36Z"
        ></path>
        <path
          className="cls-3"
          d="M643.91,744.47a.67.67,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M653.78,738.79a.67.67,0,0,1-1.08.78.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M663.31,732.56a.67.67,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.68.68,0,0,1,663.31,732.56Z"
        ></path>
        <path
          className="cls-3"
          d="M672.48,725.81a.68.68,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.68.68,0,0,1,672.48,725.81Z"
        ></path>
        <path
          className="cls-3"
          d="M680.32,718.41a.67.67,0,0,1,.93.15.66.66,0,0,1-.15.93.67.67,0,0,1-.93-.15A.68.68,0,0,1,680.32,718.41Z"
        ></path>
        <path
          className="cls-3"
          d="M689.46,711.76a.67.67,0,0,1-.93-.16.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15A.68.68,0,0,1,689.46,711.76Z"
        ></path>
        <path
          className="cls-3"
          d="M696.59,702.49a.66.66,0,0,1,.93.15.67.67,0,0,1-.16.93.66.66,0,0,1-.92-.16A.65.65,0,0,1,696.59,702.49Z"
        ></path>
        <circle
          className="cls-3"
          cx="704.41"
          cy="694.41"
          r="0.66"
          transform="translate(-272.79 541.21) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M711.9,685a.67.67,0,0,1-.16.93.66.66,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.68.68,0,0,1,711.9,685Z"
        ></path>
        <circle className="cls-3" cx="717.78" cy="675.98" r="0.64"></circle>
        <path
          className="cls-3"
          d="M723.13,666.63a.67.67,0,1,1,1.08-.78.67.67,0,0,1-.16.93A.65.65,0,0,1,723.13,666.63Z"
        ></path>
        <path
          className="cls-3"
          d="M728.61,655.64a.67.67,0,0,1,.78,1.08.67.67,0,1,1-.78-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M733.22,646.23a.67.67,0,1,1,1.08-.78.68.68,0,0,1-.15.93A.67.67,0,0,1,733.22,646.23Z"
        ></path>
        <path
          className="cls-3"
          d="M737.54,634.71a.67.67,0,0,1,.78,1.08.67.67,0,0,1-.93-.16A.66.66,0,0,1,737.54,634.71Z"
        ></path>
        <path
          className="cls-3"
          d="M742,624.05a.66.66,0,0,1-.15.93.67.67,0,0,1-.93-.16.68.68,0,0,1,.15-.93A.67.67,0,0,1,742,624.05Z"
        ></path>
        <path
          className="cls-3"
          d="M743.92,613.83a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M746.26,602.69a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M748,591.42a.64.64,0,1,1,.88.14A.63.63,0,0,1,748,591.42Z"
        ></path>
        <path
          className="cls-3"
          d="M750,580.25a.67.67,0,1,1-.78-1.08.67.67,0,0,1,.78,1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M750.42,568.88a.67.67,0,0,1-.93-.16.66.66,0,0,1,.15-.93.67.67,0,0,1,.78,1.09Z"
        ></path>
        <path
          className="cls-3"
          d="M750.37,556.56a.67.67,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.67.67,0,0,1,750.37,556.56Z"
        ></path>
        <path
          className="cls-3"
          d="M748.62,545.08a.64.64,0,0,1,.89.15.63.63,0,0,1-1,.74A.64.64,0,0,1,748.62,545.08Z"
        ></path>
        <path
          className="cls-3"
          d="M747,534.7a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M744.88,523.51a.67.67,0,1,1,1.08-.78.68.68,0,0,1-.15.93A.67.67,0,0,1,744.88,523.51Z"
        ></path>
        <path
          className="cls-3"
          d="M742.15,512.45a.66.66,0,0,1,.15-.93.67.67,0,1,1-.15.93Z"
        ></path>
        <path
          className="cls-3"
          d="M738.81,501.57a.66.66,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.66.66,0,0,1,738.81,501.57Z"
        ></path>
        <path
          className="cls-3"
          d="M734.86,490.89A.66.66,0,0,1,735,490a.67.67,0,0,1,.93.16.66.66,0,0,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M730.32,480.45a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M725.2,470.28a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16.65.65,0,0,1-.15.92A.66.66,0,0,1,725.2,470.28Z"
        ></path>
        <path
          className="cls-3"
          d="M719.52,460.42a.67.67,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.68.68,0,0,1,719.52,460.42Z"
        ></path>
        <path
          className="cls-3"
          d="M713.3,450.88a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.16.68.68,0,0,1-.15.93A.67.67,0,0,1,713.3,450.88Z"
        ></path>
        <path
          className="cls-3"
          d="M706.55,441.71a.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15.68.68,0,0,1-.15.93A.67.67,0,0,1,706.55,441.71Z"
        ></path>
        <path
          className="cls-3"
          d="M699.29,432.94a.67.67,0,1,1,.93.15A.66.66,0,0,1,699.29,432.94Z"
        ></path>
        <path
          className="cls-3"
          d="M691.56,424.58a.66.66,0,1,1,.93.16A.67.67,0,0,1,691.56,424.58Z"
        ></path>
        <path
          className="cls-3"
          d="M683.37,416.68a.67.67,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.68.68,0,0,1,683.37,416.68Z"
        ></path>
        <path
          className="cls-3"
          d="M674.75,409.24a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M665.73,402.3a.67.67,0,1,1,1.08-.78.68.68,0,0,1-.15.93A.67.67,0,0,1,665.73,402.3Z"
        ></path>
        <path
          className="cls-3"
          d="M656.33,395.87a.66.66,0,0,1,.15-.93.67.67,0,1,1-.15.93Z"
        ></path>
        <path
          className="cls-3"
          d="M646.58,390a.67.67,0,0,1,.16-.93.65.65,0,0,1,.92.15.66.66,0,0,1-.15.93A.68.68,0,0,1,646.58,390Z"
        ></path>
        <path
          className="cls-3"
          d="M636.53,384.65a.66.66,0,0,1,.15-.93.67.67,0,1,1,.77,1.09A.66.66,0,0,1,636.53,384.65Z"
        ></path>
        <path
          className="cls-3"
          d="M626.18,379.89a.67.67,0,0,1,.16-.93.67.67,0,0,1,.77,1.09A.67.67,0,0,1,626.18,379.89Z"
        ></path>
        <path
          className="cls-3"
          d="M615.59,375.72a.67.67,0,0,1,.15-.93.68.68,0,0,1,.93.15.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M604.93,371.22a.67.67,0,0,1,.78,1.08.67.67,0,0,1-.78-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M593.94,368.26a.66.66,0,1,1,.77,1.08.65.65,0,0,1-.92-.15A.66.66,0,0,1,593.94,368.26Z"
        ></path>
        <path
          className="cls-3"
          d="M582.8,365.92a.66.66,0,1,1,.77,1.08.66.66,0,0,1-.93-.15A.67.67,0,0,1,582.8,365.92Z"
        ></path>
        <path
          className="cls-3"
          d="M571.54,364.21a.67.67,0,1,1,.78,1.08.67.67,0,0,1-.78-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M560.06,364.06a.66.66,0,0,1,.15-.93.67.67,0,1,1,.77,1.09A.66.66,0,0,1,560.06,364.06Z"
        ></path>
        <path
          className="cls-3"
          d="M548.68,363.62a.65.65,0,0,1,.15-.92.66.66,0,0,1,.93.15.66.66,0,1,1-1.08.77Z"
        ></path>
        <path
          className="cls-3"
          d="M537.45,362.89a.67.67,0,0,1,.93.16.66.66,0,0,1-.16.92.65.65,0,0,1-.92-.15A.66.66,0,0,1,537.45,362.89Z"
        ></path>
        <path
          className="cls-3"
          d="M525.94,364.66a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.15.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M515.58,366.28a.66.66,0,0,1-.93-.15.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16A.65.65,0,0,1,515.58,366.28Z"
        ></path>
        <path
          className="cls-3"
          d="M504.54,367.46a.66.66,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.65.65,0,0,1,504.54,367.46Z"
        ></path>
        <path
          className="cls-3"
          d="M493.51,370.17a.69.69,0,0,1-.15,1,.71.71,0,0,1-1-.16.7.7,0,0,1,.16-1A.69.69,0,0,1,493.51,370.17Z"
        ></path>
        <path
          className="cls-3"
          d="M481.68,373.38a.66.66,0,0,1,.93.15.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16A.65.65,0,0,1,481.68,373.38Z"
        ></path>
        <path
          className="cls-3"
          d="M470.85,378.25a.66.66,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.66.66,0,0,1,470.85,378.25Z"
        ></path>
        <path
          className="cls-3"
          d="M460.56,381.86a.67.67,0,0,1,.78,1.08.67.67,0,1,1-.78-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M450.39,387a.67.67,0,0,1,.78,1.08.67.67,0,1,1-.78-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M440.37,393.59a.67.67,0,0,1,.15-.93.68.68,0,0,1,.93.15.67.67,0,1,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M430.84,399.82a.67.67,0,0,1,1.08-.78.67.67,0,0,1-.15.93A.68.68,0,0,1,430.84,399.82Z"
        ></path>
        <path
          className="cls-3"
          d="M421.82,405.64a.67.67,0,1,1,.78,1.08.67.67,0,0,1-.93-.16A.66.66,0,0,1,421.82,405.64Z"
        ></path>
        <path
          className="cls-3"
          d="M414,413a.67.67,0,0,1-.16.93A.66.66,0,1,1,414,413Z"
        ></path>
        <path
          className="cls-3"
          d="M404.54,421.55a.67.67,0,0,1,.15-.93.68.68,0,0,1,.93.15.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M396.63,429.74a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M390.12,438.51a.66.66,0,0,1-.93-.15.67.67,0,0,1,.16-.93.68.68,0,0,1,.93.15A.67.67,0,0,1,390.12,438.51Z"
        ></path>
        <path
          className="cls-3"
          d="M383.36,446.59a.7.7,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.7.7,0,0,1,383.36,446.59Z"
        ></path>
        <path
          className="cls-3"
          d="M376,455.83a.7.7,0,0,1,1,.16.68.68,0,0,1-.16,1,.7.7,0,0,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M371,465.75a.66.66,0,0,1-.15.93.67.67,0,0,1-.78-1.08A.66.66,0,0,1,371,465.75Z"
        ></path>
        <path
          className="cls-3"
          d="M364.76,475.66a.66.66,0,0,1,.93.15.67.67,0,0,1-.15.93.68.68,0,0,1-.93-.15A.66.66,0,0,1,364.76,475.66Z"
        ></path>
        <path
          className="cls-3"
          d="M359.85,486.93A.66.66,0,0,1,360,486a.67.67,0,0,1,.78,1.08A.66.66,0,0,1,359.85,486.93Z"
        ></path>
        <path
          className="cls-3"
          d="M355.65,497.54a.7.7,0,0,1,.16-1,.69.69,0,0,1,1,.16.68.68,0,0,1-.16,1A.7.7,0,0,1,355.65,497.54Z"
        ></path>
        <path
          className="cls-3"
          d="M352.1,508.33a.67.67,0,0,1,1.09-.77.67.67,0,0,1-1.09.77Z"
        ></path>
        <path
          className="cls-3"
          d="M349.3,518.4a.66.66,0,0,1,.93.15.67.67,0,0,1-1.08.78A.66.66,0,0,1,349.3,518.4Z"
        ></path>
        <path
          className="cls-3"
          d="M347.89,529.69a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.15.67.67,0,0,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M346.18,541a.66.66,0,0,1-.16.92.65.65,0,0,1-.92-.15.66.66,0,0,1,.15-.93A.67.67,0,0,1,346.18,541Z"
        ></path>
        <path
          className="cls-3"
          d="M345.1,552.28a.67.67,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M343.73,563.5a.67.67,0,1,1-.15.93A.67.67,0,0,1,343.73,563.5Z"
        ></path>
        <path
          className="cls-3"
          d="M344.86,575a.67.67,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,0,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M344.62,587.17a.66.66,0,0,1,.15-.93.66.66,0,1,1,.77,1.08A.65.65,0,0,1,344.62,587.17Z"
        ></path>
        <path
          className="cls-3"
          d="M347,598.61a.67.67,0,1,1-.78-1.08.67.67,0,0,1,.93.15A.68.68,0,0,1,347,598.61Z"
        ></path>
        <path
          className="cls-3"
          d="M349.27,608.87a.66.66,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.66.66,0,0,1,349.27,608.87Z"
        ></path>
        <path
          className="cls-3"
          d="M352,619.91a.68.68,0,0,1-.16,1,.7.7,0,1,1-.81-1.13A.69.69,0,0,1,352,619.91Z"
        ></path>
        <path
          className="cls-3"
          d="M354.41,630.66a.66.66,0,0,1,.93.15.66.66,0,1,1-1.08.77A.66.66,0,0,1,354.41,630.66Z"
        ></path>
        <path
          className="cls-3"
          d="M358.36,641.33a.67.67,0,0,1,.93.16.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16A.66.66,0,0,1,358.36,641.33Z"
        ></path>
        <path
          className="cls-3"
          d="M363.83,651.93a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16.66.66,0,0,1,.15-.93A.67.67,0,0,1,363.83,651.93Z"
        ></path>
        <path
          className="cls-3"
          d="M369,662.1a.67.67,0,0,1-.16.93.67.67,0,0,1-.77-1.09A.67.67,0,0,1,369,662.1Z"
        ></path>
        <circle
          className="cls-3"
          cx="374.09"
          cy="672.35"
          r="0.67"
          transform="translate(-321.93 344.4) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M380.85,681.5a.65.65,0,0,1-.15.92.66.66,0,0,1-.93-.15.66.66,0,1,1,1.08-.77Z"
        ></path>
        <path
          className="cls-3"
          d="M387.6,690.66a.67.67,0,1,1-1.08.78.67.67,0,0,1,.15-.93A.68.68,0,0,1,387.6,690.66Z"
        ></path>
        <path
          className="cls-3"
          d="M394.85,699.44a.66.66,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.65.65,0,0,1,394.85,699.44Z"
        ></path>
        <path
          className="cls-3"
          d="M402.59,707.79a.67.67,0,0,1-.16.93.65.65,0,0,1-.92-.15.66.66,0,0,1,.15-.93A.68.68,0,0,1,402.59,707.79Z"
        ></path>
        <path
          className="cls-3"
          d="M410.78,715.7a.68.68,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.67.67,0,0,1,410.78,715.7Z"
        ></path>
        <path
          className="cls-3"
          d="M418.47,723a.66.66,0,0,1,.93.15.67.67,0,0,1-1.08.78A.66.66,0,0,1,418.47,723Z"
        ></path>
        <path
          className="cls-3"
          d="M427.48,729.9a.7.7,0,1,1,.81,1.13.7.7,0,1,1-.81-1.13Z"
        ></path>
        <path
          className="cls-3"
          d="M437.82,736.51a.66.66,0,1,1-1.08.77.66.66,0,0,1,.15-.93A.67.67,0,0,1,437.82,736.51Z"
        ></path>
        <path
          className="cls-3"
          d="M446.64,742.24a.67.67,0,0,1,.92.15.67.67,0,1,1-1.08.78A.67.67,0,0,1,446.64,742.24Z"
        ></path>
        <path
          className="cls-3"
          d="M452,759.1a.66.66,0,1,1-.93-.16A.67.67,0,0,1,452,759.1Z"
        ></path>
        <path
          className="cls-3"
          d="M461.42,763.75a.63.63,0,1,1,.74,1,.63.63,0,0,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M472.91,768.1a.67.67,0,1,1-1.09.77.67.67,0,0,1,1.09-.77Z"
        ></path>
        <circle className="cls-3" cx="483.15" cy="772.15" r="0.64"></circle>
        <circle className="cls-3" cx="494.1" cy="775.23" r="0.64"></circle>
        <circle className="cls-3" cx="505.21" cy="777.74" r="0.64"></circle>
        <path
          className="cls-3"
          d="M516,779.11a.68.68,0,0,1,.93.15.67.67,0,1,1-1.08.78A.68.68,0,0,1,516,779.11Z"
        ></path>
        <circle className="cls-3" cx="527.74" cy="780.97" r="0.64"></circle>
        <circle className="cls-3" cx="539.1" cy="781.69" r="0.64"></circle>
        <path
          className="cls-3"
          d="M550,782.18a.63.63,0,0,1,1-.74.63.63,0,1,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M561.49,780.82a.63.63,0,1,1,.74,1,.63.63,0,0,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M572.83,779.74a.63.63,0,0,1,.88.14.63.63,0,1,1-1,.74A.62.62,0,0,1,572.83,779.74Z"
        ></path>
        <path
          className="cls-3"
          d="M585,778.21a.63.63,0,0,1-.14.88.63.63,0,1,1-.74-1A.62.62,0,0,1,585,778.21Z"
        ></path>
        <path
          className="cls-3"
          d="M596,776.85a.68.68,0,0,1-.93-.15.67.67,0,0,1,.16-.93.65.65,0,0,1,.92.15A.66.66,0,0,1,596,776.85Z"
        ></path>
        <path
          className="cls-3"
          d="M606.26,772.94a.64.64,0,1,1-.14.88A.63.63,0,0,1,606.26,772.94Z"
        ></path>
        <circle className="cls-3" cx="617.49" cy="770.02" r="0.64"></circle>
        <path
          className="cls-3"
          d="M628.69,765.63a.67.67,0,0,1-1.08.78.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M638,761.85a.67.67,0,0,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M649.28,756a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.15.64.64,0,0,1,.14-.89A.65.65,0,0,1,649.28,756Z"
        ></path>
        <path
          className="cls-3"
          d="M658.29,750.21a.63.63,0,0,1,.88.15.64.64,0,0,1-.14.89.65.65,0,0,1-.89-.15A.64.64,0,0,1,658.29,750.21Z"
        ></path>
        <path
          className="cls-3"
          d="M667.72,745a.63.63,0,0,1,1-.74.63.63,0,1,1-1,.74Z"
        ></path>
        <circle className="cls-3" cx="677.49" cy="737.94" r="0.64"></circle>
        <path
          className="cls-3"
          d="M686.88,730.45a.63.63,0,0,1-.14.88.63.63,0,1,1-.74-1A.63.63,0,0,1,686.88,730.45Z"
        ></path>
        <path
          className="cls-3"
          d="M695.38,722.87a.63.63,0,0,1-1,.74.63.63,0,1,1,1-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M703.48,714.83a.66.66,0,0,1-.15.93.66.66,0,1,1-.77-1.08A.66.66,0,0,1,703.48,714.83Z"
        ></path>
        <path
          className="cls-3"
          d="M711.13,706.4a.66.66,0,0,1-.15.93.67.67,0,0,1-.93-.16.66.66,0,0,1,1.08-.77Z"
        ></path>
        <path
          className="cls-3"
          d="M718.3,697.59a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.15.63.63,0,1,1,1-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M724.13,688.25a.64.64,0,0,1,.89.15.63.63,0,0,1-.15.88.63.63,0,1,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M730.35,678.72a.63.63,0,1,1,.74,1,.63.63,0,0,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M735.92,669.75a.63.63,0,0,1,.14-.88.63.63,0,1,1,.74,1A.63.63,0,0,1,735.92,669.75Z"
        ></path>
        <circle className="cls-3" cx="741.61" cy="659.25" r="0.64"></circle>
        <path
          className="cls-3"
          d="M746.63,649.36a.64.64,0,1,1,.14-.88A.63.63,0,0,1,746.63,649.36Z"
        ></path>
        <path
          className="cls-3"
          d="M749.82,638.59a.63.63,0,0,1,.15-.88.64.64,0,0,1,.89.14.65.65,0,0,1-.15.89A.64.64,0,0,1,749.82,638.59Z"
        ></path>
        <path
          className="cls-3"
          d="M754.4,627a.67.67,0,0,1-.15.92.67.67,0,1,1-.78-1.08A.67.67,0,0,1,754.4,627Z"
        ></path>
        <path
          className="cls-3"
          d="M756.28,616.77a.64.64,0,1,1,.89.14A.64.64,0,0,1,756.28,616.77Z"
        ></path>
        <path
          className="cls-3"
          d="M758.64,605.63a.64.64,0,0,1,.15-.89.63.63,0,0,1,.74,1A.64.64,0,0,1,758.64,605.63Z"
        ></path>
        <circle className="cls-3" cx="760.92" cy="594.01" r="0.64"></circle>
        <path
          className="cls-3"
          d="M762.46,583.2a.63.63,0,0,1-.88-.14.64.64,0,1,1,.88.14Z"
        ></path>
        <path
          className="cls-3"
          d="M763.05,571.86a.67.67,0,1,1-.77-1.09.67.67,0,0,1,.77,1.09Z"
        ></path>
        <circle className="cls-3" cx="762.63" cy="559.93" r="0.64"></circle>
        <circle className="cls-3" cx="762" cy="548.56" r="0.64"></circle>
        <circle className="cls-3" cx="760.77" cy="537.24" r="0.64"></circle>
        <path
          className="cls-3"
          d="M758.43,526.38a.64.64,0,0,1,.15-.89.63.63,0,0,1,.74,1A.64.64,0,0,1,758.43,526.38Z"
        ></path>
        <path
          className="cls-3"
          d="M756,515.25a.64.64,0,0,1,.14-.89.65.65,0,0,1,.89.15.64.64,0,0,1-.15.89A.63.63,0,0,1,756,515.25Z"
        ></path>
        <circle className="cls-3" cx="753.53" cy="503.9" r="0.64"></circle>
        <path
          className="cls-3"
          d="M749.42,493.48a.67.67,0,1,1,1.08-.78.67.67,0,0,1-.16.93A.66.66,0,0,1,749.42,493.48Z"
        ></path>
        <path
          className="cls-3"
          d="M745.28,482.87a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-.16.92A.65.65,0,0,1,745.28,482.87Z"
        ></path>
        <circle className="cls-3" cx="741.12" cy="472.11" r="0.64"></circle>
        <path
          className="cls-3"
          d="M735.37,462.37a.63.63,0,1,1,.89.15A.64.64,0,0,1,735.37,462.37Z"
        ></path>
        <circle className="cls-3" cx="730.12" cy="452.18" r="0.64"></circle>
        <circle className="cls-3" cx="723.85" cy="442.68" r="0.64"></circle>
        <path
          className="cls-3"
          d="M716.57,433.89a.63.63,0,0,1,1-.74.63.63,0,1,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M709.33,425.11a.64.64,0,1,1,.89.14A.64.64,0,0,1,709.33,425.11Z"
        ></path>
        <circle className="cls-3" cx="702.16" cy="416.34" r="0.64"></circle>
        <path
          className="cls-3"
          d="M693.49,408.75a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.16.66.66,0,0,1-.15.93A.67.67,0,0,1,693.49,408.75Z"
        ></path>
        <path
          className="cls-3"
          d="M685,401.22a.66.66,0,0,1,.15-.93.67.67,0,1,1-.15.93Z"
        ></path>
        <path
          className="cls-3"
          d="M676.06,394.13a.64.64,0,0,1,1-.75.65.65,0,0,1-.15.89A.64.64,0,0,1,676.06,394.13Z"
        ></path>
        <circle className="cls-3" cx="667.3" cy="387.16" r="0.64"></circle>
        <circle
          className="cls-3"
          cx="657.68"
          cy="381.07"
          r="0.66"
          transform="translate(-98.8 455.13) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M647.24,375.86a.64.64,0,1,1,.89.14A.64.64,0,0,1,647.24,375.86Z"
        ></path>
        <path
          className="cls-3"
          d="M637,370.81a.63.63,0,1,1,1-.74.63.63,0,0,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M626.58,366.31a.64.64,0,0,1,.15-.89.62.62,0,0,1,.88.15.63.63,0,0,1-.14.88A.64.64,0,0,1,626.58,366.31Z"
        ></path>
        <circle className="cls-3" cx="616.42" cy="361.99" r="0.64"></circle>
        <path
          className="cls-3"
          d="M605,359a.63.63,0,1,1,1-.74.63.63,0,0,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M594.12,355.28a.66.66,0,0,1,.93.15.67.67,0,0,1-.16.93.66.66,0,0,1-.77-1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M583.88,353.22a.67.67,0,1,1-1.08.78.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M572.44,352.51a.64.64,0,0,1-.89-.15.63.63,0,0,1,.15-.88.63.63,0,1,1,.74,1Z"
        ></path>
        <path
          className="cls-3"
          d="M561.25,350.6a.64.64,0,1,1-.89-.14A.64.64,0,0,1,561.25,350.6Z"
        ></path>
        <path
          className="cls-3"
          d="M549.72,351.07a.63.63,0,0,1-.88-.15A.64.64,0,0,1,549,350a.65.65,0,0,1,.89.15A.64.64,0,0,1,549.72,351.07Z"
        ></path>
        <path
          className="cls-3"
          d="M538.36,351.27a.67.67,0,0,1-.78-1.08.67.67,0,0,1,.78,1.08Z"
        ></path>
        <path
          className="cls-3"
          d="M526.07,351.9a.67.67,0,1,1,1.08-.78.67.67,0,0,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M514.77,353.28a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.15.67.67,0,1,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M503.73,354.35a.63.63,0,0,1,.88.14.63.63,0,1,1-1,.74A.63.63,0,0,1,503.73,354.35Z"
        ></path>
        <path
          className="cls-3"
          d="M492.49,357.8a.63.63,0,1,1,1-.74.63.63,0,0,1-.14.88A.64.64,0,0,1,492.49,357.8Z"
        ></path>
        <path
          className="cls-3"
          d="M481.52,361a.68.68,0,0,1,.15-.93.67.67,0,0,1,.93.15.67.67,0,1,1-1.08.78Z"
        ></path>
        <path
          className="cls-3"
          d="M471.67,364.81a.64.64,0,1,1,.15-.89A.64.64,0,0,1,471.67,364.81Z"
        ></path>
        <path
          className="cls-3"
          d="M460.21,369a.67.67,0,0,1,.15-.93.67.67,0,1,1,.78,1.08A.67.67,0,0,1,460.21,369Z"
        ></path>
        <path
          className="cls-3"
          d="M451,373a.67.67,0,0,1-1.08.78.68.68,0,0,1,.15-.93A.67.67,0,0,1,451,373Z"
        ></path>
        <path
          className="cls-3"
          d="M439.86,379.16a.68.68,0,0,1,.15-.93.67.67,0,0,1,.78,1.08A.67.67,0,0,1,439.86,379.16Z"
        ></path>
        <path
          className="cls-3"
          d="M430.27,384.12a.67.67,0,1,1-.15.93A.67.67,0,0,1,430.27,384.12Z"
        ></path>
        <path
          className="cls-3"
          d="M420.7,391.44a.66.66,0,1,1,.93.16A.67.67,0,0,1,420.7,391.44Z"
        ></path>
        <path
          className="cls-3"
          d="M412.72,397.55a.67.67,0,0,1-.16.93.66.66,0,0,1-.93-.15.67.67,0,0,1,1.09-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M403.87,405.84a.67.67,0,1,1-.77-1.09.67.67,0,0,1,.77,1.09Z"
        ></path>
        <path
          className="cls-3"
          d="M395.73,412.71a.66.66,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.66.66,0,0,1,395.73,412.71Z"
        ></path>
        <circle
          className="cls-3"
          cx="387.32"
          cy="421.33"
          r="0.66"
          transform="translate(-173.04 305) rotate(-35.68)"
        ></circle>
        <circle className="cls-3" cx="379.9" cy="429.96" r="0.64"></circle>
        <path
          className="cls-3"
          d="M372.4,439.36a.67.67,0,1,1,.93.15A.66.66,0,0,1,372.4,439.36Z"
        ></path>
        <path
          className="cls-3"
          d="M365.93,448.73a.67.67,0,0,1,.16-.93.65.65,0,0,1,.92.15.66.66,0,0,1-.15.93A.67.67,0,0,1,365.93,448.73Z"
        ></path>
        <path
          className="cls-3"
          d="M360.12,457.49a.67.67,0,0,1,.93.16.66.66,0,0,1-.16.92.65.65,0,0,1-.92-.15A.66.66,0,0,1,360.12,457.49Z"
        ></path>
        <path
          className="cls-3"
          d="M354.52,468.42a.66.66,0,0,1,.15-.93.67.67,0,0,1,.93.15.68.68,0,0,1-.15.93A.66.66,0,0,1,354.52,468.42Z"
        ></path>
        <path
          className="cls-3"
          d="M349.6,478.69a.67.67,0,1,1,.93.15A.66.66,0,0,1,349.6,478.69Z"
        ></path>
        <path
          className="cls-3"
          d="M346.32,488.43a.66.66,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,0,1,.15-.93A.66.66,0,0,1,346.32,488.43Z"
        ></path>
        <path
          className="cls-3"
          d="M342.52,499.16a.67.67,0,0,1-1.08.78.67.67,0,0,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M339.29,510.08a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16.66.66,0,0,1,.15-.93A.67.67,0,0,1,339.29,510.08Z"
        ></path>
        <circle className="cls-3" cx="336.1" cy="521.54" r="0.64"></circle>
        <path
          className="cls-3"
          d="M334.58,532.35a.67.67,0,0,1-1.09.77.67.67,0,1,1,1.09-.77Z"
        ></path>
        <path
          className="cls-3"
          d="M333.08,543.65a.63.63,0,1,1-1,.74.63.63,0,0,1,.14-.88A.64.64,0,0,1,333.08,543.65Z"
        ></path>
        <path
          className="cls-3"
          d="M332.21,555a.64.64,0,1,1-.89-.14A.65.65,0,0,1,332.21,555Z"
        ></path>
        <path
          className="cls-3"
          d="M331,566.22a.65.65,0,0,1,.92.15.66.66,0,0,1-.15.93.68.68,0,0,1-.93-.15A.67.67,0,0,1,331,566.22Z"
        ></path>
        <path
          className="cls-3"
          d="M332.29,577.75a.66.66,0,0,1-.15.93.68.68,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.65.65,0,0,1,332.29,577.75Z"
        ></path>
        <path
          className="cls-3"
          d="M333.23,589.1a.67.67,0,1,1-.93-.16A.67.67,0,0,1,333.23,589.1Z"
        ></path>
        <path
          className="cls-3"
          d="M334.75,600.38a.66.66,0,0,1-.15.93.67.67,0,0,1-.78-1.08A.66.66,0,0,1,334.75,600.38Z"
        ></path>
        <path
          className="cls-3"
          d="M336.88,611.56a.67.67,0,0,1-.16.93.66.66,0,0,1-.93-.15.67.67,0,0,1,.16-.93A.68.68,0,0,1,336.88,611.56Z"
        ></path>
        <path
          className="cls-3"
          d="M339.58,622.62a.67.67,0,0,1-1.08.78.68.68,0,0,1,.15-.93A.66.66,0,0,1,339.58,622.62Z"
        ></path>
        <path
          className="cls-3"
          d="M342.87,633.52a.66.66,0,0,1-.15.93.67.67,0,1,1,.15-.93Z"
        ></path>
        <path
          className="cls-3"
          d="M345.8,644.08a.67.67,0,0,1,.93.15.67.67,0,1,1-1.08.78A.67.67,0,0,1,345.8,644.08Z"
        ></path>
        <path
          className="cls-3"
          d="M351.15,654.72a.67.67,0,0,1-.15.93.66.66,0,0,1-.93-.15.67.67,0,0,1,.15-.93A.68.68,0,0,1,351.15,654.72Z"
        ></path>
        <path
          className="cls-3"
          d="M356.12,665a.67.67,0,0,1-1.08.78.67.67,0,1,1,1.08-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M361.62,674.94a.68.68,0,0,1-.15.93.67.67,0,0,1-.93-.16.65.65,0,0,1,.15-.92A.66.66,0,0,1,361.62,674.94Z"
        ></path>
        <circle
          className="cls-3"
          cx="367.1"
          cy="684.99"
          r="0.67"
          transform="translate(-330.62 342.7) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M374.16,693.93a.67.67,0,0,1-.16.93.65.65,0,0,1-.92-.15.66.66,0,0,1,.15-.93A.68.68,0,0,1,374.16,693.93Z"
        ></path>
        <path
          className="cls-3"
          d="M381.16,702.91a.66.66,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.66.66,0,0,1,381.16,702.91Z"
        ></path>
        <path
          className="cls-3"
          d="M388.63,711.5a.67.67,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.67.67,0,0,1,388.63,711.5Z"
        ></path>
        <path
          className="cls-3"
          d="M396.54,719.69a.66.66,0,0,1-.15.93.67.67,0,1,1-.78-1.08A.66.66,0,0,1,396.54,719.69Z"
        ></path>
        <path
          className="cls-3"
          d="M404.85,727.47a.63.63,0,0,1-1,.74.63.63,0,1,1,1-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M412.69,734.63a.64.64,0,0,1,.89.15.63.63,0,0,1-1,.74A.64.64,0,0,1,412.69,734.63Z"
        ></path>
        <circle
          className="cls-3"
          cx="422.17"
          cy="741.98"
          r="0.67"
          transform="translate(-353.52 385.51) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M432.13,748a.63.63,0,1,1-.88-.15A.63.63,0,0,1,432.13,748Z"
        ></path>
        <circle className="cls-3" cx="441.39" cy="754.17" r="0.64"></circle>
        <circle className="cls-3" cx="445.83" cy="770.85" r="0.64"></circle>
        <path
          className="cls-3"
          d="M455.81,775.16a.6.6,0,1,1,.7,1,.61.61,0,0,1-.84-.14A.6.6,0,0,1,455.81,775.16Z"
        ></path>
        <path
          className="cls-3"
          d="M467.23,779.55a.63.63,0,0,1-.14.88.64.64,0,1,1,.14-.88Z"
        ></path>
        <circle className="cls-3" cx="477.47" cy="783.66" r="0.64"></circle>
        <circle className="cls-3" cx="488.39" cy="786.86" r="0.64"></circle>
        <path
          className="cls-3"
          d="M499,789.86a.6.6,0,1,1,1-.7.6.6,0,0,1-1,.7Z"
        ></path>
        <path
          className="cls-3"
          d="M511.15,791.25a.61.61,0,0,1-.14.84.59.59,0,0,1-.84-.13.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M521.42,793.51a.64.64,0,0,1,.15-.89.63.63,0,0,1,.74,1A.64.64,0,0,1,521.42,793.51Z"
        ></path>
        <path
          className="cls-3"
          d="M532.93,793.62a.59.59,0,0,1,.84.14.59.59,0,0,1-.13.84.61.61,0,1,1-.71-1Z"
        ></path>
        <path
          className="cls-3"
          d="M545,795a.59.59,0,0,1-.84-.13.61.61,0,1,1,1-.71A.59.59,0,0,1,545,795Z"
        ></path>
        <circle className="cls-3" cx="556.04" cy="794.35" r="0.64"></circle>
        <circle className="cls-3" cx="567.41" cy="793.62" r="0.64"></circle>
        <path
          className="cls-3"
          d="M578.23,792.68a.61.61,0,0,1,.13-.85.62.62,0,0,1,.85.14.61.61,0,0,1-.14.84A.59.59,0,0,1,578.23,792.68Z"
        ></path>
        <path
          className="cls-3"
          d="M590.47,790.09a.64.64,0,0,1-1,.74.63.63,0,0,1,.15-.88A.64.64,0,0,1,590.47,790.09Z"
        ></path>
        <path
          className="cls-3"
          d="M600.71,787.53a.63.63,0,0,1,.74,1,.63.63,0,1,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M611.58,785.43a.59.59,0,0,1,.13-.84.61.61,0,1,1,.71,1A.59.59,0,0,1,611.58,785.43Z"
        ></path>
        <path
          className="cls-3"
          d="M623.39,781.22a.61.61,0,0,1-.14.84.61.61,0,1,1,.14-.84Z"
        ></path>
        <path
          className="cls-3"
          d="M634.05,777.15a.63.63,0,0,1-.14.89.64.64,0,0,1-.89-.15.63.63,0,0,1,1-.74Z"
        ></path>
        <circle className="cls-3" cx="643.97" cy="772.95" r="0.64"></circle>
        <path
          className="cls-3"
          d="M654.64,767.51a.59.59,0,0,1-.14.84.59.59,0,0,1-.84-.13.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M664.56,761.92a.61.61,0,1,1-1,.71.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M674.19,755.85a.6.6,0,1,1-.84-.14A.59.59,0,0,1,674.19,755.85Z"
        ></path>
        <path
          className="cls-3"
          d="M682.52,750a.61.61,0,0,1,.14-.84.62.62,0,0,1,.85.14.61.61,0,0,1-1,.7Z"
        ></path>
        <path
          className="cls-3"
          d="M692.34,743.14a.61.61,0,0,1-.84-.14.61.61,0,0,1,1-.71A.62.62,0,0,1,692.34,743.14Z"
        ></path>
        <path
          className="cls-3"
          d="M701,735.7a.61.61,0,1,1,.14-.85A.62.62,0,0,1,701,735.7Z"
        ></path>
        <path
          className="cls-3"
          d="M708.49,726.85a.6.6,0,0,1,.7,1,.6.6,0,1,1-.7-1Z"
        ></path>
        <path
          className="cls-3"
          d="M717.16,718.72a.61.61,0,1,1-1,.71.59.59,0,0,1,.14-.84A.59.59,0,0,1,717.16,718.72Z"
        ></path>
        <path
          className="cls-3"
          d="M723.59,710.79a.61.61,0,1,1,.84.14A.61.61,0,0,1,723.59,710.79Z"
        ></path>
        <path
          className="cls-3"
          d="M731.54,701.08a.6.6,0,0,1-.14.84.59.59,0,0,1-.84-.14.6.6,0,0,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M738.05,691.74a.59.59,0,0,1-.13.84.61.61,0,0,1-.85-.13.62.62,0,0,1,.14-.85A.61.61,0,0,1,738.05,691.74Z"
        ></path>
        <circle className="cls-3" cx="743.6" cy="682.44" r="0.64"></circle>
        <path
          className="cls-3"
          d="M748.66,672.85a.61.61,0,0,1,.14-.84.61.61,0,1,1-.14.84Z"
        ></path>
        <path
          className="cls-3"
          d="M754.55,662.79a.61.61,0,1,1,.14-.85A.61.61,0,0,1,754.55,662.79Z"
        ></path>
        <path
          className="cls-3"
          d="M759.09,652.34a.6.6,0,0,1-.85-.14.6.6,0,1,1,1-.7A.59.59,0,0,1,759.09,652.34Z"
        ></path>
        <path
          className="cls-3"
          d="M763.11,641.71a.63.63,0,1,1,.15-.89A.64.64,0,0,1,763.11,641.71Z"
        ></path>
        <path
          className="cls-3"
          d="M765.86,629.86a.61.61,0,0,1,.84.14.6.6,0,0,1-.14.84.59.59,0,0,1-.84-.14A.6.6,0,0,1,765.86,629.86Z"
        ></path>
        <path
          className="cls-3"
          d="M768.64,619.7a.61.61,0,1,1,.85.14A.61.61,0,0,1,768.64,619.7Z"
        ></path>
        <path
          className="cls-3"
          d="M771,608.57a.61.61,0,1,1,1-.71.61.61,0,0,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M772.83,597.33a.61.61,0,1,1,.85.14A.62.62,0,0,1,772.83,597.33Z"
        ></path>
        <circle className="cls-3" cx="774.58" cy="585.66" r="0.64"></circle>
        <path
          className="cls-3"
          d="M775.62,574.79a.61.61,0,1,1,.14-.85A.62.62,0,0,1,775.62,574.79Z"
        ></path>
        <path
          className="cls-3"
          d="M775.88,562.56a.61.61,0,0,1-.14.84.61.61,0,0,1-.71-1A.61.61,0,0,1,775.88,562.56Z"
        ></path>
        <path
          className="cls-3"
          d="M775.43,551.18a.61.61,0,0,1-1,.71.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M773.43,540.55a.61.61,0,0,1,1-.71.61.61,0,0,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M771.86,529.27a.6.6,0,1,1,1-.7.6.6,0,1,1-1,.7Z"
        ></path>
        <path
          className="cls-3"
          d="M769.69,518.11a.64.64,0,1,1,.89.14A.64.64,0,0,1,769.69,518.11Z"
        ></path>
        <path
          className="cls-3"
          d="M767,507a.61.61,0,0,1,.13-.85.62.62,0,0,1,.85.14.61.61,0,0,1-.14.84A.59.59,0,0,1,767,507Z"
        ></path>
        <path
          className="cls-3"
          d="M763.79,496.11a.6.6,0,1,1,1-.7.61.61,0,0,1-.14.84A.6.6,0,0,1,763.79,496.11Z"
        ></path>
        <path
          className="cls-3"
          d="M760,485.37a.61.61,0,1,1,.85.14A.61.61,0,0,1,760,485.37Z"
        ></path>
        <circle className="cls-3" cx="756.18" cy="474.48" r="0.64"></circle>
        <path
          className="cls-3"
          d="M750.86,464.53a.62.62,0,0,1,.14-.85.61.61,0,0,1,.84.14.59.59,0,0,1-.13.84A.61.61,0,0,1,750.86,464.53Z"
        ></path>
        <path
          className="cls-3"
          d="M745.52,454.47a.61.61,0,0,1,1-.71.61.61,0,0,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M739.69,444.69a.6.6,0,1,1,1-.7.59.59,0,0,1-.14.84A.6.6,0,0,1,739.69,444.69Z"
        ></path>
        <path
          className="cls-3"
          d="M733.38,435.22a.59.59,0,0,1,.13-.84.61.61,0,0,1,.85.13.62.62,0,0,1-.14.85A.61.61,0,0,1,733.38,435.22Z"
        ></path>
        <path
          className="cls-3"
          d="M726.6,426.07a.6.6,0,0,1,.14-.84.59.59,0,0,1,.84.14.6.6,0,0,1-.14.84A.61.61,0,0,1,726.6,426.07Z"
        ></path>
        <path
          className="cls-3"
          d="M719.37,417.27a.61.61,0,0,1,.14-.84.61.61,0,1,1-.14.84Z"
        ></path>
        <path
          className="cls-3"
          d="M711.72,408.85a.6.6,0,0,1,.14-.85.6.6,0,0,1,.7,1A.59.59,0,0,1,711.72,408.85Z"
        ></path>
        <path
          className="cls-3"
          d="M703.63,400.83a.63.63,0,1,1,1-.74.63.63,0,0,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M695.2,393.19a.61.61,0,0,1,.13-.85.61.61,0,1,1,.71,1A.61.61,0,0,1,695.2,393.19Z"
        ></path>
        <path
          className="cls-3"
          d="M686.37,386a.61.61,0,1,1,1-.71.61.61,0,0,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M677.2,379.25a.59.59,0,0,1,.13-.84.61.61,0,1,1,.71,1A.6.6,0,0,1,677.2,379.25Z"
        ></path>
        <path
          className="cls-3"
          d="M667.67,373a.64.64,0,0,1,.15-.89.64.64,0,0,1,.74,1A.64.64,0,0,1,667.67,373Z"
        ></path>
        <circle className="cls-3" cx="658.39" cy="366.82" r="0.64"></circle>
        <path
          className="cls-3"
          d="M647.82,361.88a.61.61,0,1,1,.85.14A.62.62,0,0,1,647.82,361.88Z"
        ></path>
        <path
          className="cls-3"
          d="M637.47,357.1a.63.63,0,0,1,1-.74.63.63,0,1,1-1,.74Z"
        ></path>
        <path
          className="cls-3"
          d="M626.92,352.83a.64.64,0,0,1,.14-.89.65.65,0,0,1,.89.15.64.64,0,0,1-.15.89A.63.63,0,0,1,626.92,352.83Z"
        ></path>
        <path
          className="cls-3"
          d="M616.19,349.07a.59.59,0,0,1,.14-.84.6.6,0,0,1,.7,1A.59.59,0,0,1,616.19,349.07Z"
        ></path>
        <path
          className="cls-3"
          d="M606.11,346a.6.6,0,0,1-.85-.14.6.6,0,0,1,1-.7A.59.59,0,0,1,606.11,346Z"
        ></path>
        <circle className="cls-3" cx="594.68" cy="342.87" r="0.64"></circle>
        <path
          className="cls-3"
          d="M583.86,341.29a.63.63,0,1,1,.15-.88A.63.63,0,0,1,583.86,341.29Z"
        ></path>
        <path
          className="cls-3"
          d="M572.7,338.89a.6.6,0,0,1-1,.7.59.59,0,0,1,.14-.84A.6.6,0,0,1,572.7,338.89Z"
        ></path>
        <path
          className="cls-3"
          d="M561.24,338.79a.64.64,0,1,1,.14-.89A.64.64,0,0,1,561.24,338.79Z"
        ></path>
        <circle className="cls-3" cx="549.49" cy="337.87" r="0.64"></circle>
        <circle className="cls-3" cx="538.1" cy="338.03" r="0.64"></circle>
        <path
          className="cls-3"
          d="M527.09,339.25a.6.6,0,1,1,.14-.84A.59.59,0,0,1,527.09,339.25Z"
        ></path>
        <circle className="cls-3" cx="515.43" cy="340.06" r="0.64"></circle>
        <circle className="cls-3" cx="504.2" cy="341.91" r="0.64"></circle>
        <path
          className="cls-3"
          d="M492.7,343.82a.63.63,0,1,1,.74,1,.63.63,0,0,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M482.6,346.93a.63.63,0,0,1-.15.88.63.63,0,1,1,.15-.88Z"
        ></path>
        <path
          className="cls-3"
          d="M471.74,350.46a.6.6,0,1,1-.84-.14A.6.6,0,0,1,471.74,350.46Z"
        ></path>
        <path
          className="cls-3"
          d="M460.12,355.21a.61.61,0,0,1,1-.71.61.61,0,1,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M450.53,359.92a.6.6,0,0,1-.84-.14.61.61,0,0,1,.14-.84.6.6,0,0,1,.84.14A.59.59,0,0,1,450.53,359.92Z"
        ></path>
        <path
          className="cls-3"
          d="M439.48,364.89a.64.64,0,0,1,.15-.89.63.63,0,0,1,.74,1A.64.64,0,0,1,439.48,364.89Z"
        ></path>
        <circle className="cls-3" cx="430.08" cy="370.1" r="0.64"></circle>
        <path
          className="cls-3"
          d="M421,375.81a.63.63,0,1,1-.88-.15A.63.63,0,0,1,421,375.81Z"
        ></path>
        <path
          className="cls-3"
          d="M411.65,382.36a.62.62,0,0,1-.15.88.63.63,0,0,1-.88-.14.63.63,0,1,1,1-.74Z"
        ></path>
        <circle className="cls-3" cx="402.16" cy="389.73" r="0.64"></circle>
        <path
          className="cls-3"
          d="M394.06,396.8a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.14.64.64,0,1,1,1-.75Z"
        ></path>
        <path
          className="cls-3"
          d="M384.94,404.52a.63.63,0,0,1,.74,1,.63.63,0,1,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M377.13,412.81a.6.6,0,1,1,.7,1,.6.6,0,1,1-.7-1Z"
        ></path>
        <path
          className="cls-3"
          d="M370.56,421.59a.62.62,0,0,1-.14.85.61.61,0,0,1-.71-1A.62.62,0,0,1,370.56,421.59Z"
        ></path>
        <path
          className="cls-3"
          d="M363.61,430.58a.63.63,0,0,1-.14.88.63.63,0,1,1-.74-1A.63.63,0,0,1,363.61,430.58Z"
        ></path>
        <path
          className="cls-3"
          d="M356.21,439.77a.64.64,0,0,1,.89.15.63.63,0,0,1-.14.88.64.64,0,1,1-.75-1Z"
        ></path>
        <path
          className="cls-3"
          d="M351.06,449.57a.63.63,0,0,1-.14.88.63.63,0,1,1-.74-1A.63.63,0,0,1,351.06,449.57Z"
        ></path>
        <path
          className="cls-3"
          d="M344.63,459.36a.63.63,0,0,1,.74,1,.63.63,0,1,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M340.32,470.6a.64.64,0,0,1-.89-.15.63.63,0,0,1,1-.74A.64.64,0,0,1,340.32,470.6Z"
        ></path>
        <path
          className="cls-3"
          d="M336,480.14a.67.67,0,0,1-.16.93.68.68,0,0,1-.93-.15A.67.67,0,0,1,335,480,.66.66,0,0,1,336,480.14Z"
        ></path>
        <circle className="cls-3" cx="331.41" cy="491.18" r="0.64"></circle>
        <path
          className="cls-3"
          d="M327.57,501.51a.63.63,0,0,1,.74,1,.63.63,0,1,1-.74-1Z"
        ></path>
        <circle className="cls-3" cx="325.01" cy="513.03" r="0.64"></circle>
        <circle className="cls-3" cx="322.64" cy="524.17" r="0.64"></circle>
        <path
          className="cls-3"
          d="M321.34,535a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.14.64.64,0,1,1,1-.75Z"
        ></path>
        <path
          className="cls-3"
          d="M320.09,546.35a.65.65,0,0,1-.15.89.64.64,0,1,1-.74-1A.65.65,0,0,1,320.09,546.35Z"
        ></path>
        <path
          className="cls-3"
          d="M319.4,557.71a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.14.64.64,0,1,1,1-.75Z"
        ></path>
        <path
          className="cls-3"
          d="M318.24,569.84a.65.65,0,0,1,.15-.89.64.64,0,1,1,.74,1A.65.65,0,0,1,318.24,569.84Z"
        ></path>
        <path
          className="cls-3"
          d="M319.56,581.34a.61.61,0,1,1,.14-.85A.61.61,0,0,1,319.56,581.34Z"
        ></path>
        <path
          className="cls-3"
          d="M320.74,591.82a.63.63,0,0,1-1,.74.63.63,0,1,1,1-.74Z"
        ></path>
        <circle className="cls-3" cx="321.8" cy="603.46" r="0.64"></circle>
        <circle className="cls-3" cx="323.94" cy="614.64" r="0.64"></circle>
        <circle className="cls-3" cx="326.63" cy="625.71" r="0.64"></circle>
        <path
          className="cls-3"
          d="M330.39,636.25a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.15.63.63,0,1,1,1-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M333.3,646.87a.6.6,0,1,1,.7,1,.6.6,0,0,1-.84-.14A.61.61,0,0,1,333.3,646.87Z"
        ></path>
        <path
          className="cls-3"
          d="M338.45,657.54a.61.61,0,0,1-.13.85.61.61,0,0,1-.71-1A.61.61,0,0,1,338.45,657.54Z"
        ></path>
        <path
          className="cls-3"
          d="M343.31,667.84a.63.63,0,0,1-.14.88.64.64,0,1,1-.75-1A.63.63,0,0,1,343.31,667.84Z"
        ></path>
        <path
          className="cls-3"
          d="M348.65,677.89a.64.64,0,0,1-.15.89.63.63,0,0,1-.74-1A.64.64,0,0,1,348.65,677.89Z"
        ></path>
        <path
          className="cls-3"
          d="M354.48,687.67a.63.63,0,0,1-.14.88.63.63,0,1,1-.74-1A.63.63,0,0,1,354.48,687.67Z"
        ></path>
        <path
          className="cls-3"
          d="M360.8,697.14a.64.64,0,0,1-1,.74.63.63,0,0,1,.15-.88A.64.64,0,0,1,360.8,697.14Z"
        ></path>
        <circle className="cls-3" cx="367.06" cy="706.66" r="0.64"></circle>
        <path
          className="cls-3"
          d="M374.8,715.09a.63.63,0,0,1-.14.88.64.64,0,1,1,.14-.88Z"
        ></path>
        <circle className="cls-3" cx="381.94" cy="723.89" r="0.64"></circle>
        <path
          className="cls-3"
          d="M390.5,731.57a.61.61,0,1,1-.85-.14A.61.61,0,0,1,390.5,731.57Z"
        ></path>
        <path
          className="cls-3"
          d="M399,739.19a.61.61,0,0,1-.13.85.61.61,0,0,1-.71-1A.61.61,0,0,1,399,739.19Z"
        ></path>
        <path
          className="cls-3"
          d="M406.92,746.22a.63.63,0,0,1,.74,1,.63.63,0,1,1-.74-1Z"
        ></path>
        <path
          className="cls-3"
          d="M417,753.11a.64.64,0,0,1-.15.89.63.63,0,0,1-.88-.15.63.63,0,1,1,1-.74Z"
        ></path>
        <circle className="cls-3" cx="425.96" cy="759.76" r="0.64"></circle>
        <circle className="cls-3" cx="435.76" cy="765.55" r="0.64"></circle>
        <path
          className="cls-3"
          d="M440.7,781.87a.61.61,0,1,1-1,.71.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M450.18,786.54a.6.6,0,0,1,.84.14.6.6,0,0,1-1,.7A.6.6,0,0,1,450.18,786.54Z"
        ></path>
        <path
          className="cls-3"
          d="M461.56,791a.62.62,0,0,1-.14.85.61.61,0,1,1,.14-.85Z"
        ></path>
        <path
          className="cls-3"
          d="M472.26,794.82a.57.57,0,1,1-.8-.13A.56.56,0,0,1,472.26,794.82Z"
        ></path>
        <path
          className="cls-3"
          d="M482.34,798a.61.61,0,0,1,.71,1,.61.61,0,0,1-.71-1Z"
        ></path>
        <path
          className="cls-3"
          d="M494.22,800.88a.61.61,0,0,1-.13.85.61.61,0,0,1-.71-1A.61.61,0,0,1,494.22,800.88Z"
        ></path>
        <path
          className="cls-3"
          d="M505.38,803.14a.6.6,0,1,1-1,.7.6.6,0,0,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M516.61,804.88a.57.57,0,1,1-.93.67.57.57,0,0,1,.13-.8A.58.58,0,0,1,516.61,804.88Z"
        ></path>
        <path
          className="cls-3"
          d="M527.94,806.08a.58.58,0,1,1-.81-.13A.58.58,0,0,1,527.94,806.08Z"
        ></path>
        <path
          className="cls-3"
          d="M538.5,806.6a.58.58,0,0,1,.8.14.57.57,0,0,1-.13.8.58.58,0,0,1-.67-.94Z"
        ></path>
        <path
          className="cls-3"
          d="M549.73,807.54a.6.6,0,1,1,.84.14A.59.59,0,0,1,549.73,807.54Z"
        ></path>
        <path
          className="cls-3"
          d="M562,807.26a.59.59,0,0,1-.84-.13.61.61,0,0,1,.13-.85.62.62,0,0,1,.85.14A.61.61,0,0,1,562,807.26Z"
        ></path>
        <path
          className="cls-3"
          d="M572.59,805.33a.59.59,0,0,1,.84.14.59.59,0,0,1-.13.84.61.61,0,1,1-.71-1Z"
        ></path>
        <path
          className="cls-3"
          d="M583.89,803.86a.58.58,0,0,1,.68.94.58.58,0,0,1-.68-.94Z"
        ></path>
        <path
          className="cls-3"
          d="M595.9,802a.57.57,0,1,1-.93.67.57.57,0,0,1,.13-.8A.58.58,0,0,1,595.9,802Z"
        ></path>
        <path
          className="cls-3"
          d="M607,799.41a.59.59,0,0,1-.14.84.6.6,0,0,1-.84-.13.62.62,0,0,1,.14-.85A.61.61,0,0,1,607,799.41Z"
        ></path>
        <circle className="cls-3" cx="617.5" cy="796.69" r="0.57"></circle>
        <path
          className="cls-3"
          d="M628,792.62a.6.6,0,0,1,.84.14.6.6,0,0,1-1,.7A.61.61,0,0,1,628,792.62Z"
        ></path>
        <path
          className="cls-3"
          d="M639.42,788.67a.61.61,0,0,1-.14.84.59.59,0,0,1-.84-.13.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M649.7,784.93a.61.61,0,1,1,.14-.85A.62.62,0,0,1,649.7,784.93Z"
        ></path>
        <circle className="cls-3" cx="659.54" cy="779.36" r="0.57"></circle>
        <path
          className="cls-3"
          d="M670,773.48a.57.57,0,1,1-.93.67.57.57,0,0,1,.93-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M679.62,767.47a.59.59,0,0,1-.13.8.6.6,0,0,1-.81-.13.59.59,0,0,1,.14-.81A.58.58,0,0,1,679.62,767.47Z"
        ></path>
        <path
          className="cls-3"
          d="M688.06,761.67a.57.57,0,0,1,.13-.8.58.58,0,0,1,.67.94A.58.58,0,0,1,688.06,761.67Z"
        ></path>
        <path
          className="cls-3"
          d="M698.05,754.11a.57.57,0,1,1-.93.67.57.57,0,1,1,.93-.67Z"
        ></path>
        <circle className="cls-3" cx="706.3" cy="747.12" r="0.57"></circle>
        <path
          className="cls-3"
          d="M715.11,739.08a.54.54,0,1,1-.76-.12A.54.54,0,0,1,715.11,739.08Z"
        ></path>
        <path
          className="cls-3"
          d="M722.17,731.65a.61.61,0,1,1,.85.14A.62.62,0,0,1,722.17,731.65Z"
        ></path>
        <path
          className="cls-3"
          d="M730.76,722.47a.61.61,0,0,1-.14.84.62.62,0,0,1-.85-.14.61.61,0,0,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M737.81,714.48a.6.6,0,1,1-.7-1,.6.6,0,1,1,.7,1Z"
        ></path>
        <path
          className="cls-3"
          d="M743.89,704.37a.57.57,0,1,1-.13.8A.56.56,0,0,1,743.89,704.37Z"
        ></path>
        <path
          className="cls-3"
          d="M750.89,695.84a.58.58,0,0,1-.67-.94.58.58,0,0,1,.8.14A.57.57,0,0,1,750.89,695.84Z"
        ></path>
        <path
          className="cls-3"
          d="M756,686a.57.57,0,0,1,.13-.8.57.57,0,0,1,.8.13.57.57,0,1,1-.93.67Z"
        ></path>
        <path
          className="cls-3"
          d="M762.17,676.06a.57.57,0,0,1-.67-.93.58.58,0,0,1,.8.13A.56.56,0,0,1,762.17,676.06Z"
        ></path>
        <path
          className="cls-3"
          d="M766.41,664.84a.61.61,0,1,1-.14.85A.61.61,0,0,1,766.41,664.84Z"
        ></path>
        <path
          className="cls-3"
          d="M771.53,655.32a.58.58,0,0,1-.8-.14.57.57,0,0,1,.13-.8.58.58,0,0,1,.67.94Z"
        ></path>
        <path
          className="cls-3"
          d="M775.47,644.63a.57.57,0,1,1,.13-.8A.57.57,0,0,1,775.47,644.63Z"
        ></path>
        <path
          className="cls-3"
          d="M778.09,633.64a.58.58,0,0,1,.14-.8.59.59,0,0,1,.8.13.6.6,0,0,1-.13.81A.59.59,0,0,1,778.09,633.64Z"
        ></path>
        <path
          className="cls-3"
          d="M781,622.64a.57.57,0,1,1,.8.13A.57.57,0,0,1,781,622.64Z"
        ></path>
        <path
          className="cls-3"
          d="M783.39,611.51a.58.58,0,0,1,.94-.68.58.58,0,1,1-.94.68Z"
        ></path>
        <path
          className="cls-3"
          d="M785.23,600.29a.6.6,0,0,1,.14-.84.6.6,0,0,1,.7,1A.61.61,0,0,1,785.23,600.29Z"
        ></path>
        <path
          className="cls-3"
          d="M787.38,589.1a.59.59,0,0,1-.8-.13.6.6,0,0,1,.13-.81.58.58,0,0,1,.8.14A.57.57,0,0,1,787.38,589.1Z"
        ></path>
        <circle className="cls-3" cx="787.84" cy="577.27" r="0.57"></circle>
        <circle className="cls-3" cx="788.1" cy="565.89" r="0.57"></circle>
        <circle className="cls-3" cx="787.81" cy="554.51" r="0.57"></circle>
        <path
          className="cls-3"
          d="M787.48,542.8a.59.59,0,0,1-.13.84.61.61,0,0,1-.85-.13.62.62,0,0,1,.14-.85A.61.61,0,0,1,787.48,542.8Z"
        ></path>
        <circle
          className="cls-3"
          cx="785.64"
          cy="531.85"
          r="0.55"
          transform="translate(-162.73 558.07) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M783.28,521a.6.6,0,0,1,.13-.81.59.59,0,0,1,.81.14.58.58,0,0,1-.14.8A.59.59,0,0,1,783.28,521Z"
        ></path>
        <path
          className="cls-3"
          d="M780.87,509.83a.57.57,0,0,1,.93-.67.57.57,0,0,1-.13.8A.56.56,0,0,1,780.87,509.83Z"
        ></path>
        <circle className="cls-3" cx="778.39" cy="498.49" r="0.57"></circle>
        <path
          className="cls-3"
          d="M774.47,488a.58.58,0,0,1,.13-.8.57.57,0,1,1,.67.93A.57.57,0,0,1,774.47,488Z"
        ></path>
        <path
          className="cls-3"
          d="M770.48,477.33a.61.61,0,1,1,.85.14A.62.62,0,0,1,770.48,477.33Z"
        ></path>
        <path
          className="cls-3"
          d="M766,466.85a.61.61,0,0,1,.14-.84.61.61,0,0,1,.71,1A.61.61,0,0,1,766,466.85Z"
        ></path>
        <path
          className="cls-3"
          d="M761.12,456.57a.54.54,0,1,1,.76.12A.54.54,0,0,1,761.12,456.57Z"
        ></path>
        <path
          className="cls-3"
          d="M755.66,446.58a.58.58,0,1,1,.8.13A.58.58,0,0,1,755.66,446.58Z"
        ></path>
        <path
          className="cls-3"
          d="M749.76,436.84a.58.58,0,0,1,.14-.8.57.57,0,0,1,.8.13.58.58,0,0,1-.94.67Z"
        ></path>
        <circle className="cls-3" cx="743.88" cy="427.05" r="0.57"></circle>
        <path
          className="cls-3"
          d="M736.62,418.25a.58.58,0,1,1,.81.13A.58.58,0,0,1,736.62,418.25Z"
        ></path>
        <path
          className="cls-3"
          d="M729.41,409.44a.57.57,0,1,1,.8.13A.57.57,0,0,1,729.41,409.44Z"
        ></path>
        <path
          className="cls-3"
          d="M721.79,401a.58.58,0,0,1,.13-.81.58.58,0,0,1,.8.13.58.58,0,1,1-.93.68Z"
        ></path>
        <path
          className="cls-3"
          d="M713.75,392.91a.6.6,0,0,1,.14-.84.61.61,0,0,1,.84.14.6.6,0,0,1-.14.84A.59.59,0,0,1,713.75,392.91Z"
        ></path>
        <path
          className="cls-3"
          d="M705.36,385.21a.61.61,0,1,1,.85.14A.61.61,0,0,1,705.36,385.21Z"
        ></path>
        <path
          className="cls-3"
          d="M696.62,377.92a.61.61,0,1,1,.85.14A.62.62,0,0,1,696.62,377.92Z"
        ></path>
        <circle className="cls-3" cx="688.04" cy="370.69" r="0.57"></circle>
        <path
          className="cls-3"
          d="M678.16,364.6a.59.59,0,0,1,.14-.84.6.6,0,1,1-.14.84Z"
        ></path>
        <path
          className="cls-3"
          d="M668.5,358.6a.57.57,0,1,1,.8.13A.58.58,0,0,1,668.5,358.6Z"
        ></path>
        <path
          className="cls-3"
          d="M658.54,353.07a.58.58,0,1,1,.81.13A.58.58,0,0,1,658.54,353.07Z"
        ></path>
        <path
          className="cls-3"
          d="M648.32,348a.59.59,0,0,1,.13-.84.61.61,0,1,1,.71,1A.6.6,0,0,1,648.32,348Z"
        ></path>
        <circle
          className="cls-3"
          cx="638.37"
          cy="343.13"
          r="0.61"
          transform="translate(-80.3 436.75) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M627.25,339.42a.59.59,0,0,1,.14-.84.59.59,0,0,1,.84.13.61.61,0,1,1-1,.71Z"
        ></path>
        <path
          className="cls-3"
          d="M616.46,335.85a.57.57,0,1,1,.8.13A.58.58,0,0,1,616.46,335.85Z"
        ></path>
        <path
          className="cls-3"
          d="M606.42,332.13a.6.6,0,0,1-.13.81.59.59,0,0,1-.81-.14.58.58,0,0,1,.14-.8A.59.59,0,0,1,606.42,332.13Z"
        ></path>
        <path
          className="cls-3"
          d="M595.18,330.42a.59.59,0,0,1-.8-.13.6.6,0,0,1,.13-.81.58.58,0,0,1,.67.94Z"
        ></path>
        <path
          className="cls-3"
          d="M583.15,328.31a.59.59,0,0,1,.14-.84.6.6,0,0,1,.84.13.62.62,0,0,1-.14.85A.61.61,0,0,1,583.15,328.31Z"
        ></path>
        <path
          className="cls-3"
          d="M571.86,326.85A.61.61,0,0,1,572,326a.61.61,0,0,1,.71,1A.61.61,0,0,1,571.86,326.85Z"
        ></path>
        <path
          className="cls-3"
          d="M561.49,325.22a.6.6,0,0,1-.14.84.59.59,0,0,1-.84-.14.6.6,0,0,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M549.15,325.52a.59.59,0,0,1,.14-.81.58.58,0,0,1,.8.14.59.59,0,0,1-.13.8A.6.6,0,0,1,549.15,325.52Z"
        ></path>
        <path
          className="cls-3"
          d="M538.73,325a.61.61,0,0,1-.14.84.61.61,0,0,1-.71-1A.61.61,0,0,1,538.73,325Z"
        ></path>
        <path
          className="cls-3"
          d="M526.4,326.35a.58.58,0,0,1,.14-.8.57.57,0,0,1,.8.13.58.58,0,0,1-.94.67Z"
        ></path>
        <path
          className="cls-3"
          d="M515.06,327.59a.61.61,0,0,1,.14-.84.59.59,0,0,1,.84.14.6.6,0,0,1-.13.84A.62.62,0,0,1,515.06,327.59Z"
        ></path>
        <path
          className="cls-3"
          d="M504,328.5a.61.61,0,0,1,.84.14.59.59,0,0,1-.13.84.61.61,0,0,1-.85-.13A.62.62,0,0,1,504,328.5Z"
        ></path>
        <path
          className="cls-3"
          d="M493.62,330.94a.58.58,0,0,1-.14.8.57.57,0,0,1-.8-.13.59.59,0,0,1,.13-.8A.58.58,0,0,1,493.62,330.94Z"
        ></path>
        <path
          className="cls-3"
          d="M482.61,333.73a.61.61,0,1,1-.85-.14A.62.62,0,0,1,482.61,333.73Z"
        ></path>
        <path
          className="cls-3"
          d="M470.74,337.77a.6.6,0,0,1,.14-.85.6.6,0,0,1,.7,1A.59.59,0,0,1,470.74,337.77Z"
        ></path>
        <path
          className="cls-3"
          d="M460,341.59a.58.58,0,0,1,.94-.68.58.58,0,0,1-.94.68Z"
        ></path>
        <path
          className="cls-3"
          d="M450.34,346.08a.62.62,0,0,1-.85-.14.61.61,0,0,1,.14-.84.59.59,0,0,1,.84.14A.59.59,0,0,1,450.34,346.08Z"
        ></path>
        <path
          className="cls-3"
          d="M440.16,350.07a.59.59,0,0,1-.13.84.61.61,0,1,1-.71-1A.6.6,0,0,1,440.16,350.07Z"
        ></path>
        <path
          className="cls-3"
          d="M429.11,356.09a.62.62,0,0,1,.14-.85.61.61,0,0,1,.84.14.59.59,0,0,1-.13.84A.61.61,0,0,1,429.11,356.09Z"
        ></path>
        <path
          className="cls-3"
          d="M420.29,361.16a.61.61,0,1,1-.85-.14A.62.62,0,0,1,420.29,361.16Z"
        ></path>
        <path
          className="cls-3"
          d="M410.63,368.25a.61.61,0,0,1-.71-1,.61.61,0,0,1,.71,1Z"
        ></path>
        <path
          className="cls-3"
          d="M400.56,374.79a.61.61,0,1,1,.85.14A.62.62,0,0,1,400.56,374.79Z"
        ></path>
        <path
          className="cls-3"
          d="M392.65,381.19a.61.61,0,1,1-.84-.14A.61.61,0,0,1,392.65,381.19Z"
        ></path>
        <path
          className="cls-3"
          d="M383.26,388.58a.61.61,0,0,1,.85.13.61.61,0,0,1-1,.71A.61.61,0,0,1,383.26,388.58Z"
        ></path>
        <path
          className="cls-3"
          d="M375.08,396.49a.61.61,0,1,1-.14.85A.61.61,0,0,1,375.08,396.49Z"
        ></path>
        <path
          className="cls-3"
          d="M367.28,404.79a.59.59,0,0,1,.84.14.6.6,0,0,1-.13.84.62.62,0,0,1-.85-.14A.61.61,0,0,1,367.28,404.79Z"
        ></path>
        <path
          className="cls-3"
          d="M360.7,413.6a.57.57,0,0,1-.13.8.58.58,0,0,1-.8-.13.57.57,0,1,1,.93-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M352.76,423.28a.62.62,0,0,1,.14-.85.61.61,0,0,1,.71,1A.62.62,0,0,1,352.76,423.28Z"
        ></path>
        <path
          className="cls-3"
          d="M347.19,431.89a.6.6,0,0,1-.13.84.62.62,0,0,1-.85-.14.61.61,0,0,1,.14-.84A.59.59,0,0,1,347.19,431.89Z"
        ></path>
        <path
          className="cls-3"
          d="M341.07,441.51a.6.6,0,0,1-.13.81.59.59,0,0,1-.81-.14.58.58,0,0,1,.14-.8A.59.59,0,0,1,341.07,441.51Z"
        ></path>
        <path
          className="cls-3"
          d="M334.47,452.09a.59.59,0,0,1,.14-.84.6.6,0,1,1-.14.84Z"
        ></path>
        <path
          className="cls-3"
          d="M330.14,462.38a.61.61,0,0,1-.84-.14.62.62,0,0,1,.14-.85.61.61,0,0,1,.7,1Z"
        ></path>
        <path
          className="cls-3"
          d="M325.46,472.75a.6.6,0,0,1-.7-1,.6.6,0,0,1,.84.14A.61.61,0,0,1,325.46,472.75Z"
        ></path>
        <path
          className="cls-3"
          d="M321.41,482.5a.6.6,0,1,1-.84-.14A.59.59,0,0,1,321.41,482.5Z"
        ></path>
        <path
          className="cls-3"
          d="M317.73,493.27a.61.61,0,0,1-.14.84.59.59,0,0,1-.84-.14.59.59,0,0,1,.13-.84A.62.62,0,0,1,317.73,493.27Z"
        ></path>
        <path
          className="cls-3"
          d="M313.71,504.07a.61.61,0,0,1,.85.13.61.61,0,0,1-1,.71A.61.61,0,0,1,313.71,504.07Z"
        ></path>
        <path
          className="cls-3"
          d="M311.06,515.14a.61.61,0,1,1,.71,1,.61.61,0,1,1-.71-1Z"
        ></path>
        <path
          className="cls-3"
          d="M308.94,526.32a.61.61,0,1,1-.14.85A.61.61,0,0,1,308.94,526.32Z"
        ></path>
        <path
          className="cls-3"
          d="M308.19,537.73a.61.61,0,0,1-1,.71.61.61,0,0,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M307.13,549.07a.61.61,0,0,1-.14.84.59.59,0,0,1-.84-.13.61.61,0,0,1,.13-.85A.62.62,0,0,1,307.13,549.07Z"
        ></path>
        <path
          className="cls-3"
          d="M305.76,560.3a.61.61,0,0,1,.71,1,.61.61,0,0,1-.71-1Z"
        ></path>
        <path
          className="cls-3"
          d="M305.77,571.69a.62.62,0,0,1,.85.14.61.61,0,1,1-.85-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M307.17,583.2a.61.61,0,0,1-.14.84.6.6,0,1,1-.7-1A.61.61,0,0,1,307.17,583.2Z"
        ></path>
        <path
          className="cls-3"
          d="M308.26,594.53a.62.62,0,0,1-.14.85.61.61,0,0,1-.71-1A.62.62,0,0,1,308.26,594.53Z"
        ></path>
        <path
          className="cls-3"
          d="M309.88,605.8a.61.61,0,1,1-1,.71.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M312,617a.58.58,0,0,1-.94.67.58.58,0,0,1,.14-.8A.57.57,0,0,1,312,617Z"
        ></path>
        <path
          className="cls-3"
          d="M314.71,628.05a.6.6,0,0,1-.14.84.6.6,0,0,1-.7-1A.61.61,0,0,1,314.71,628.05Z"
        ></path>
        <path
          className="cls-3"
          d="M317.91,639a.6.6,0,1,1-1,.7.6.6,0,1,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M320.79,649.63a.57.57,0,0,1,.67.93.57.57,0,1,1-.67-.93Z"
        ></path>
        <path
          className="cls-3"
          d="M325.83,660.32a.59.59,0,0,1-.14.84.6.6,0,0,1-.84-.14.61.61,0,0,1,.14-.84A.59.59,0,0,1,325.83,660.32Z"
        ></path>
        <path
          className="cls-3"
          d="M330.54,670.68a.61.61,0,1,1-1,.71.61.61,0,1,1,1-.71Z"
        ></path>
        <path
          className="cls-3"
          d="M335.74,680.81a.61.61,0,1,1-.85-.14A.62.62,0,0,1,335.74,680.81Z"
        ></path>
        <path
          className="cls-3"
          d="M341.4,690.69a.6.6,0,0,1-1,.7.6.6,0,1,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M347.53,700.29a.61.61,0,0,1-.14.84.62.62,0,0,1-.85-.14.61.61,0,0,1,1-.7Z"
        ></path>
        <path
          className="cls-3"
          d="M354.1,709.58a.62.62,0,0,1-.14.85.61.61,0,1,1,.14-.85Z"
        ></path>
        <path
          className="cls-3"
          d="M361.11,718.56a.61.61,0,1,1-.85-.14A.61.61,0,0,1,361.11,718.56Z"
        ></path>
        <path
          className="cls-3"
          d="M368.53,727.19a.61.61,0,1,1-.85-.14A.62.62,0,0,1,368.53,727.19Z"
        ></path>
        <path
          className="cls-3"
          d="M376.34,735.47a.59.59,0,0,1-.13.84.61.61,0,1,1,.13-.84Z"
        ></path>
        <path
          className="cls-3"
          d="M384.55,743.36a.61.61,0,0,1-1,.71.61.61,0,0,1,1-.71Z"
        ></path>
        <circle className="cls-3" cx="392.62" cy="751.22" r="0.57"></circle>
        <path
          className="cls-3"
          d="M401.18,757.81a.61.61,0,1,1-.14.85A.61.61,0,0,1,401.18,757.81Z"
        ></path>
        <path
          className="cls-3"
          d="M411.26,764.61a.61.61,0,1,1-.85-.14A.62.62,0,0,1,411.26,764.61Z"
        ></path>
        <circle className="cls-3" cx="420.3" cy="771.18" r="0.57"></circle>
        <path
          className="cls-3"
          d="M429.77,776.45a.61.61,0,0,1,.85.13.62.62,0,0,1-.14.85.61.61,0,0,1-.84-.14A.59.59,0,0,1,429.77,776.45Z"
        ></path>
        <path
          className="cls-3"
          d="M435,793.28a.55.55,0,1,1-.76-.13A.55.55,0,0,1,435,793.28Z"
        ></path>
        <path
          className="cls-3"
          d="M444.57,798a.57.57,0,0,1,.8.13.58.58,0,0,1-.94.67A.58.58,0,0,1,444.57,798Z"
        ></path>
        <path
          className="cls-3"
          d="M455.86,802.45a.54.54,0,1,1-.88.63.54.54,0,1,1,.88-.63Z"
        ></path>
        <path
          className="cls-3"
          d="M466.59,806.3a.57.57,0,1,1-.93.67.58.58,0,0,1,.13-.8A.57.57,0,0,1,466.59,806.3Z"
        ></path>
        <path
          className="cls-3"
          d="M476.68,809.58a.55.55,0,0,1,.64.89.55.55,0,1,1-.64-.89Z"
        ></path>
        <path
          className="cls-3"
          d="M488.47,812.59a.57.57,0,0,1-.93.67.58.58,0,0,1,.13-.8A.56.56,0,0,1,488.47,812.59Z"
        ></path>
        <path
          className="cls-3"
          d="M499.58,815a.55.55,0,1,1-.89.63.55.55,0,0,1,.89-.63Z"
        ></path>
        <circle className="cls-3" cx="510.36" cy="817.23" r="0.57"></circle>
        <path
          className="cls-3"
          d="M522.1,818.3a.53.53,0,0,1-.12.76.54.54,0,0,1-.76-.12.54.54,0,0,1,.88-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M532.7,819.06a.55.55,0,0,1,.63.89.55.55,0,1,1-.63-.89Z"
        ></path>
        <path
          className="cls-3"
          d="M544.06,819.42a.57.57,0,0,1,.8.13.57.57,0,1,1-.93.67A.56.56,0,0,1,544.06,819.42Z"
        ></path>
        <circle className="cls-3" cx="555.78" cy="819.75" r="0.57"></circle>
        <path
          className="cls-3"
          d="M567.59,818.78a.55.55,0,1,1-.76-.12A.54.54,0,0,1,567.59,818.78Z"
        ></path>
        <circle className="cls-3" cx="578.47" cy="817.95" r="0.57"></circle>
        <path
          className="cls-3"
          d="M590.18,816a.55.55,0,1,1-.76-.13A.55.55,0,0,1,590.18,816Z"
        ></path>
        <path
          className="cls-3"
          d="M600.58,813.65a.59.59,0,0,1,.8.13.6.6,0,0,1-.13.81.58.58,0,0,1-.8-.14A.57.57,0,0,1,600.58,813.65Z"
        ></path>
        <circle className="cls-3" cx="611.98" cy="811.45" r="0.57"></circle>
        <path
          className="cls-3"
          d="M623.36,808a.54.54,0,1,1-.76-.13A.54.54,0,0,1,623.36,808Z"
        ></path>
        <path
          className="cls-3"
          d="M633.39,804.2a.56.56,0,0,1,.76.13.55.55,0,0,1-.89.64A.56.56,0,0,1,633.39,804.2Z"
        ></path>
        <path
          className="cls-3"
          d="M644.76,800.2a.55.55,0,1,1-.76-.12A.54.54,0,0,1,644.76,800.2Z"
        ></path>
        <path
          className="cls-3"
          d="M655.05,796.36a.54.54,0,1,1,.12-.76A.54.54,0,0,1,655.05,796.36Z"
        ></path>
        <path
          className="cls-3"
          d="M664.61,790.42a.54.54,0,1,1-.12.76A.54.54,0,0,1,664.61,790.42Z"
        ></path>
        <path
          className="cls-3"
          d="M674.57,784.9a.55.55,0,0,1,.76.13.54.54,0,1,1-.76-.13Z"
        ></path>
        <path
          className="cls-3"
          d="M684.91,779.83a.54.54,0,1,1,.13-.76A.54.54,0,0,1,684.91,779.83Z"
        ></path>
        <path
          className="cls-3"
          d="M693.58,773.32a.53.53,0,0,1,.12-.75.54.54,0,1,1-.12.75Z"
        ></path>
        <path
          className="cls-3"
          d="M703.59,765.89a.54.54,0,1,1-.88.63.54.54,0,1,1,.88-.63Z"
        ></path>
        <path
          className="cls-3"
          d="M712.29,759.45a.55.55,0,0,1-.76-.13.54.54,0,1,1,.88-.64A.56.56,0,0,1,712.29,759.45Z"
        ></path>
        <path
          className="cls-3"
          d="M720.14,751a.54.54,0,1,1-.13.76A.54.54,0,0,1,720.14,751Z"
        ></path>
        <path
          className="cls-3"
          d="M728.15,743.76a.54.54,0,1,1,.76.13A.54.54,0,0,1,728.15,743.76Z"
        ></path>
        <path
          className="cls-3"
          d="M736,734.68a.55.55,0,0,1,.64.89.55.55,0,0,1-.76-.13A.54.54,0,0,1,736,734.68Z"
        ></path>
        <path
          className="cls-3"
          d="M743.3,726.78a.55.55,0,0,1,.13-.76.54.54,0,1,1,.64.88A.56.56,0,0,1,743.3,726.78Z"
        ></path>
        <path
          className="cls-3"
          d="M750.44,717.06a.51.51,0,1,1-.12.72A.52.52,0,0,1,750.44,717.06Z"
        ></path>
        <path
          className="cls-3"
          d="M756.9,708.49a.52.52,0,0,1,.12-.72.51.51,0,1,1-.12.72Z"
        ></path>
        <path
          className="cls-3"
          d="M763.92,698.29a.54.54,0,1,1-.76-.12A.54.54,0,0,1,763.92,698.29Z"
        ></path>
        <path
          className="cls-3"
          d="M768.89,688.33a.54.54,0,1,1-.13.75A.53.53,0,0,1,768.89,688.33Z"
        ></path>
        <path
          className="cls-3"
          d="M774.8,679.12a.54.54,0,1,1,.12-.76A.54.54,0,0,1,774.8,679.12Z"
        ></path>
        <path
          className="cls-3"
          d="M778.86,668.68a.54.54,0,1,1,.88-.64.54.54,0,1,1-.88.64Z"
        ></path>
        <path
          className="cls-3"
          d="M784,658.26a.52.52,0,0,1-.72-.12.51.51,0,0,1,.12-.72.52.52,0,1,1,.6.84Z"
        ></path>
        <path
          className="cls-3"
          d="M787.84,647.58a.55.55,0,1,1,.12-.76A.55.55,0,0,1,787.84,647.58Z"
        ></path>
        <path
          className="cls-3"
          d="M791.38,635.93a.58.58,0,0,1-.94.67.58.58,0,0,1,.14-.8A.57.57,0,0,1,791.38,635.93Z"
        ></path>
        <path
          className="cls-3"
          d="M794.25,624.94a.54.54,0,1,1-.76-.13A.54.54,0,0,1,794.25,624.94Z"
        ></path>
        <path
          className="cls-3"
          d="M795.79,614.43a.52.52,0,0,1,.12-.72.51.51,0,0,1,.72.12.5.5,0,0,1-.12.71A.51.51,0,0,1,795.79,614.43Z"
        ></path>
        <path
          className="cls-3"
          d="M797.69,603.2a.52.52,0,1,1,.72.12A.52.52,0,0,1,797.69,603.2Z"
        ></path>
        <path
          className="cls-3"
          d="M799.06,591.92a.55.55,0,1,1,.76.12A.54.54,0,0,1,799.06,591.92Z"
        ></path>
        <path
          className="cls-3"
          d="M800.73,580.72a.59.59,0,0,1-.81-.14.58.58,0,0,1,.14-.8.59.59,0,0,1,.8.13A.6.6,0,0,1,800.73,580.72Z"
        ></path>
        <path
          className="cls-3"
          d="M801.08,569.31a.54.54,0,0,1-.76-.12.55.55,0,0,1,.13-.76.54.54,0,1,1,.63.88Z"
        ></path>
        <path
          className="cls-3"
          d="M800.19,557.8a.54.54,0,1,1,.76.13A.54.54,0,0,1,800.19,557.8Z"
        ></path>
        <path
          className="cls-3"
          d="M800.43,545.8a.55.55,0,1,1-.76-.12A.54.54,0,0,1,800.43,545.8Z"
        ></path>
        <path
          className="cls-3"
          d="M799.27,534.47a.54.54,0,1,1-.76-.12A.54.54,0,0,1,799.27,534.47Z"
        ></path>
        <path
          className="cls-3"
          d="M796.73,523.85a.54.54,0,1,1,.76.12A.54.54,0,0,1,796.73,523.85Z"
        ></path>
        <path
          className="cls-3"
          d="M794.56,512.67a.55.55,0,0,1,.89-.64.55.55,0,0,1-.13.76A.54.54,0,0,1,794.56,512.67Z"
        ></path>
        <circle className="cls-3" cx="792.34" cy="501.28" r="0.57"></circle>
        <path
          className="cls-3"
          d="M788.76,490.64a.52.52,0,0,1,.84-.6.52.52,0,0,1-.12.72A.51.51,0,0,1,788.76,490.64Z"
        ></path>
        <path
          className="cls-3"
          d="M785.09,479.88a.54.54,0,1,1,.76.12A.54.54,0,0,1,785.09,479.88Z"
        ></path>
        <path
          className="cls-3"
          d="M781,469.26a.53.53,0,0,1,.13-.75.54.54,0,1,1-.13.75Z"
        ></path>
        <path
          className="cls-3"
          d="M776.36,458.85a.55.55,0,1,1,.76.12A.55.55,0,0,1,776.36,458.85Z"
        ></path>
        <path
          className="cls-3"
          d="M771.3,448.65a.55.55,0,1,1,.76.13A.56.56,0,0,1,771.3,448.65Z"
        ></path>
        <path
          className="cls-3"
          d="M765.79,438.69a.54.54,0,0,1,.88-.64.54.54,0,1,1-.88.64Z"
        ></path>
        <path
          className="cls-3"
          d="M759.83,429a.55.55,0,1,1,.76.12A.54.54,0,0,1,759.83,429Z"
        ></path>
        <circle
          className="cls-3"
          cx="753.89"
          cy="419.24"
          r="0.55"
          transform="matrix(0.81, -0.58, 0.58, 0.81, -103.01, 518.41)"
        ></circle>
        <path
          className="cls-3"
          d="M746.65,410.43a.54.54,0,1,1,.76.12A.54.54,0,0,1,746.65,410.43Z"
        ></path>
        <path
          className="cls-3"
          d="M739.44,401.61a.56.56,0,0,1,.13-.76.55.55,0,0,1,.76.13.55.55,0,1,1-.89.63Z"
        ></path>
        <path
          className="cls-3"
          d="M731.85,393.13a.55.55,0,1,1,.76.12A.54.54,0,0,1,731.85,393.13Z"
        ></path>
        <path
          className="cls-3"
          d="M723.89,385a.54.54,0,1,1,.76.13A.54.54,0,0,1,723.89,385Z"
        ></path>
        <path
          className="cls-3"
          d="M715.56,377.22a.56.56,0,0,1,.13-.76.55.55,0,0,1,.76.13.55.55,0,1,1-.89.63Z"
        ></path>
        <path
          className="cls-3"
          d="M706.9,369.83a.55.55,0,1,1,.76.13A.55.55,0,0,1,706.9,369.83Z"
        ></path>
        <path
          className="cls-3"
          d="M697.92,362.84a.54.54,0,1,1,.76.13A.54.54,0,0,1,697.92,362.84Z"
        ></path>
        <path
          className="cls-3"
          d="M688.63,356.26a.54.54,0,1,1,.76.12A.54.54,0,0,1,688.63,356.26Z"
        ></path>
        <circle className="cls-3" cx="679.49" cy="349.78" r="0.57"></circle>
        <path
          className="cls-3"
          d="M669.21,344.38a.54.54,0,0,1,.88-.64.54.54,0,0,1-.88.64Z"
        ></path>
        <path
          className="cls-3"
          d="M659.1,339.12a.57.57,0,0,1,.93-.67.57.57,0,0,1-.13.8A.56.56,0,0,1,659.1,339.12Z"
        ></path>
        <path
          className="cls-3"
          d="M648.78,334.3a.57.57,0,0,1,.13-.8.57.57,0,1,1-.13.8Z"
        ></path>
        <circle
          className="cls-3"
          cx="638.73"
          cy="329.62"
          r="0.55"
          transform="translate(-72.35 434.42) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M627.55,326.08a.6.6,0,0,1,.13-.81.59.59,0,0,1,.81.14.58.58,0,0,1-.14.8A.59.59,0,0,1,627.55,326.08Z"
        ></path>
        <path
          className="cls-3"
          d="M617.47,322.8a.55.55,0,0,1-.76-.13.54.54,0,0,1,.88-.64A.56.56,0,0,1,617.47,322.8Z"
        ></path>
        <path
          className="cls-3"
          d="M605.7,319.77a.54.54,0,1,1,.76.13A.54.54,0,0,1,605.7,319.77Z"
        ></path>
        <path
          className="cls-3"
          d="M594.54,317.39a.59.59,0,0,1,.14-.81.58.58,0,0,1,.67.94A.59.59,0,0,1,594.54,317.39Z"
        ></path>
        <circle
          className="cls-3"
          cx="583.79"
          cy="315.15"
          r="0.55"
          transform="translate(-74.23 399.66) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M572,314.09a.57.57,0,0,1,.13-.8.58.58,0,0,1,.8.13.57.57,0,0,1-.93.67Z"
        ></path>
        <path
          className="cls-3"
          d="M561.45,313.31a.54.54,0,1,1,.13-.75A.53.53,0,0,1,561.45,313.31Z"
        ></path>
        <path
          className="cls-3"
          d="M550.07,312.94a.55.55,0,1,1,.13-.76A.55.55,0,0,1,550.07,312.94Z"
        ></path>
        <path
          className="cls-3"
          d="M538.05,312.19a.54.54,0,1,1-.12.76A.54.54,0,0,1,538.05,312.19Z"
        ></path>
        <path
          className="cls-3"
          d="M526.54,313.61a.57.57,0,0,1,.13-.8.57.57,0,0,1,.8.13.57.57,0,0,1-.93.67Z"
        ></path>
        <path
          className="cls-3"
          d="M515.24,314.75a.54.54,0,0,1,.12-.76.54.54,0,0,1,.76.13.54.54,0,1,1-.88.63Z"
        ></path>
        <path
          className="cls-3"
          d="M504,316.41a.55.55,0,1,1,.76.13A.55.55,0,0,1,504,316.41Z"
        ></path>
        <path
          className="cls-3"
          d="M492.92,317.82a.54.54,0,1,1-.13.76A.54.54,0,0,1,492.92,317.82Z"
        ></path>
        <circle className="cls-3" cx="482.17" cy="320.93" r="0.57"></circle>
        <path
          className="cls-3"
          d="M470.76,324.42a.58.58,0,0,1,.94-.67.58.58,0,0,1-.14.8A.57.57,0,0,1,470.76,324.42Z"
        ></path>
        <path
          className="cls-3"
          d="M460.91,327.4a.58.58,0,0,1-.13.8.58.58,0,1,1,.13-.8Z"
        ></path>
        <path
          className="cls-3"
          d="M450.17,332.32a.57.57,0,1,1,.13-.8A.58.58,0,0,1,450.17,332.32Z"
        ></path>
        <path
          className="cls-3"
          d="M439.75,336.92a.57.57,0,1,1,.13-.8A.56.56,0,0,1,439.75,336.92Z"
        ></path>
        <path
          className="cls-3"
          d="M428.75,341.85a.58.58,0,0,1,.13-.8.58.58,0,1,1,.67.94A.58.58,0,0,1,428.75,341.85Z"
        ></path>
        <path
          className="cls-3"
          d="M418.92,346.56a.58.58,0,0,1,.8.14.57.57,0,0,1-.13.8.58.58,0,0,1-.67-.94Z"
        ></path>
        <path
          className="cls-3"
          d="M409.89,353.45a.57.57,0,1,1,.13-.8A.57.57,0,0,1,409.89,353.45Z"
        ></path>
        <circle className="cls-3" cx="400.13" cy="359.37" r="0.57"></circle>
        <path
          className="cls-3"
          d="M390.53,366.51a.57.57,0,1,1,.8.13A.57.57,0,0,1,390.53,366.51Z"
        ></path>
        <path
          className="cls-3"
          d="M381.84,372.91a.57.57,0,1,1-.13.8A.58.58,0,0,1,381.84,372.91Z"
        ></path>
        <path
          className="cls-3"
          d="M374.16,380.63a.57.57,0,1,1-.93.67.57.57,0,0,1,.13-.8A.57.57,0,0,1,374.16,380.63Z"
        ></path>
        <path
          className="cls-3"
          d="M365.89,389.4a.57.57,0,0,1-.8-.13.58.58,0,0,1,.94-.67A.58.58,0,0,1,365.89,389.4Z"
        ></path>
        <path
          className="cls-3"
          d="M358.26,396.92a.58.58,0,0,1-.14.8.57.57,0,0,1-.8-.13.58.58,0,0,1,.94-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M350.74,406.39a.59.59,0,0,1-.81-.14.58.58,0,0,1,.14-.8.59.59,0,0,1,.8.13A.6.6,0,0,1,350.74,406.39Z"
        ></path>
        <circle className="cls-3" cx="343.41" cy="414.9" r="0.57"></circle>
        <path
          className="cls-3"
          d="M337.29,423.86a.57.57,0,0,1-.13.8.57.57,0,0,1-.8-.13.57.57,0,1,1,.93-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M331.11,433.45a.54.54,0,0,1-.13.76.54.54,0,1,1,.13-.76Z"
        ></path>
        <path
          className="cls-3"
          d="M325.41,443.28a.57.57,0,0,1-.13.8.58.58,0,0,1-.67-.94A.58.58,0,0,1,325.41,443.28Z"
        ></path>
        <path
          className="cls-3"
          d="M319.33,453.23a.58.58,0,0,1,.8.14.57.57,0,0,1-.13.8.58.58,0,0,1-.67-.94Z"
        ></path>
        <path
          className="cls-3"
          d="M314.36,464.37a.61.61,0,0,1,1-.71.61.61,0,1,1-1,.71Z"
        ></path>
        <circle className="cls-3" cx="310.5" cy="474.54" r="0.57"></circle>
        <circle className="cls-3" cx="306.63" cy="485.24" r="0.57"></circle>
        <path
          className="cls-3"
          d="M302.92,495.67a.55.55,0,0,1,.76.13.55.55,0,1,1-.89.63A.55.55,0,0,1,302.92,495.67Z"
        ></path>
        <path
          className="cls-3"
          d="M300.69,507.62a.61.61,0,1,1,.14-.85A.62.62,0,0,1,300.69,507.62Z"
        ></path>
        <path
          className="cls-3"
          d="M298.4,517.92a.57.57,0,0,1-.13.8.57.57,0,0,1-.8-.13.57.57,0,1,1,.93-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M296.37,530a.58.58,0,1,1,.13-.81A.58.58,0,0,1,296.37,530Z"
        ></path>
        <path
          className="cls-3"
          d="M295.13,540.42a.61.61,0,0,1-.13.85.62.62,0,0,1-.85-.14.61.61,0,0,1,.14-.84A.59.59,0,0,1,295.13,540.42Z"
        ></path>
        <path
          className="cls-3"
          d="M294.2,551.81a.54.54,0,0,1-.13.76.54.54,0,1,1,.13-.76Z"
        ></path>
        <path
          className="cls-3"
          d="M293.85,563.17a.6.6,0,0,1-.13.81.59.59,0,0,1-.81-.14.58.58,0,0,1,.14-.8A.59.59,0,0,1,293.85,563.17Z"
        ></path>
        <path
          className="cls-3"
          d="M293.18,574.43a.57.57,0,1,1-.13.8A.56.56,0,0,1,293.18,574.43Z"
        ></path>
        <circle className="cls-3" cx="294.16" cy="586.26" r="0.57"></circle>
        <circle className="cls-3" cx="295.32" cy="597.59" r="0.57"></circle>
        <path
          className="cls-3"
          d="M297.45,608.51a.58.58,0,0,1-.94.68.58.58,0,0,1,.94-.68Z"
        ></path>
        <path
          className="cls-3"
          d="M299.59,619.71a.55.55,0,1,1-.76-.12A.55.55,0,0,1,299.59,619.71Z"
        ></path>
        <circle className="cls-3" cx="301.81" cy="631.1" r="0.57"></circle>
        <path
          className="cls-3"
          d="M305.44,641.7a.58.58,0,0,1-.14.8.57.57,0,0,1-.8-.13.58.58,0,0,1,.94-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M309.08,652.49a.57.57,0,0,1-.13.8.58.58,0,0,1-.67-.94A.58.58,0,0,1,309.08,652.49Z"
        ></path>
        <path
          className="cls-3"
          d="M313.21,663.1a.58.58,0,0,1-.13.8.6.6,0,0,1-.81-.13.59.59,0,0,1,.14-.81A.58.58,0,0,1,313.21,663.1Z"
        ></path>
        <path
          className="cls-3"
          d="M317.81,673.51a.58.58,0,1,1-.8-.13A.58.58,0,0,1,317.81,673.51Z"
        ></path>
        <path
          className="cls-3"
          d="M322.87,683.71a.57.57,0,0,1-.13.8.57.57,0,1,1,.13-.8Z"
        ></path>
        <path
          className="cls-3"
          d="M328.38,693.67a.57.57,0,1,1-.8-.13A.56.56,0,0,1,328.38,693.67Z"
        ></path>
        <path
          className="cls-3"
          d="M334.34,703.38a.59.59,0,0,1-.13.8.6.6,0,0,1-.81-.13.59.59,0,0,1,.14-.81A.58.58,0,0,1,334.34,703.38Z"
        ></path>
        <path
          className="cls-3"
          d="M340.72,712.8a.57.57,0,1,1-.8-.13A.56.56,0,0,1,340.72,712.8Z"
        ></path>
        <circle className="cls-3" cx="347.06" cy="722.27" r="0.57"></circle>
        <path
          className="cls-3"
          d="M354.73,730.75a.58.58,0,0,1-.94.67.58.58,0,0,1,.14-.8A.57.57,0,0,1,354.73,730.75Z"
        ></path>
        <path
          className="cls-3"
          d="M362.32,739.23a.57.57,0,1,1-.93.67.57.57,0,0,1,.13-.8A.58.58,0,0,1,362.32,739.23Z"
        ></path>
        <path
          className="cls-3"
          d="M370.29,747.37a.58.58,0,0,1-.14.8.57.57,0,0,1-.8-.13.58.58,0,0,1,.94-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M378.61,755.14a.58.58,0,0,1-.94.67.58.58,0,0,1,.14-.8A.57.57,0,0,1,378.61,755.14Z"
        ></path>
        <path
          className="cls-3"
          d="M387.27,762.53a.57.57,0,1,1-.8-.13A.57.57,0,0,1,387.27,762.53Z"
        ></path>
        <circle className="cls-3" cx="395.79" cy="769.86" r="0.57"></circle>
        <path
          className="cls-3"
          d="M405.55,776.1a.58.58,0,0,1-.14.8.57.57,0,1,1-.67-.93A.59.59,0,0,1,405.55,776.1Z"
        ></path>
        <path
          className="cls-3"
          d="M415.1,782.28a.56.56,0,0,1-.13.76.55.55,0,1,1,.13-.76Z"
        ></path>
        <path
          className="cls-3"
          d="M424.16,787.85a.58.58,0,1,1-.13.81A.58.58,0,0,1,424.16,787.85Z"
        ></path>
        <path
          className="cls-3"
          d="M429.38,804.67a.52.52,0,0,1-.84.6.52.52,0,0,1,.12-.72A.51.51,0,0,1,429.38,804.67Z"
        ></path>
        <path
          className="cls-3"
          d="M439,809.35a.55.55,0,1,1-.12.76A.54.54,0,0,1,439,809.35Z"
        ></path>
        <path
          className="cls-3"
          d="M450.2,813.88a.52.52,0,1,1-.72-.12A.52.52,0,0,1,450.2,813.88Z"
        ></path>
        <path
          className="cls-3"
          d="M460.91,817.79a.55.55,0,1,1-.76-.12A.54.54,0,0,1,460.91,817.79Z"
        ></path>
        <path
          className="cls-3"
          d="M471,821.16a.52.52,0,1,1-.12.72A.51.51,0,0,1,471,821.16Z"
        ></path>
        <path
          className="cls-3"
          d="M482.73,824.27a.54.54,0,1,1-.76-.13A.54.54,0,0,1,482.73,824.27Z"
        ></path>
        <path
          className="cls-3"
          d="M493.81,826.82a.52.52,0,0,1-.84.6.52.52,0,0,1,.12-.72A.51.51,0,0,1,493.81,826.82Z"
        ></path>
        <path
          className="cls-3"
          d="M505,828.87a.52.52,0,0,1-.12.72.52.52,0,0,1-.6-.84A.52.52,0,0,1,505,828.87Z"
        ></path>
        <path
          className="cls-3"
          d="M515.55,830.31a.54.54,0,1,1-.13.75A.53.53,0,0,1,515.55,830.31Z"
        ></path>
        <path
          className="cls-3"
          d="M527.62,831.54a.52.52,0,0,1-.84.6.51.51,0,0,1,.12-.72A.52.52,0,0,1,527.62,831.54Z"
        ></path>
        <path
          className="cls-3"
          d="M538.12,832.76a.55.55,0,0,1,.89-.63.54.54,0,0,1-.13.76A.55.55,0,0,1,538.12,832.76Z"
        ></path>
        <path
          className="cls-3"
          d="M549.53,832.87a.52.52,0,0,1,.12-.72.51.51,0,1,1-.12.72Z"
        ></path>
        <path
          className="cls-3"
          d="M561.75,831.9a.52.52,0,1,1-.72-.12A.52.52,0,0,1,561.75,831.9Z"
        ></path>
        <path
          className="cls-3"
          d="M572.38,830.93a.51.51,0,0,1,.72.12.51.51,0,1,1-.83.6A.51.51,0,0,1,572.38,830.93Z"
        ></path>
        <path
          className="cls-3"
          d="M584.43,829.69a.54.54,0,1,1-.76-.12A.54.54,0,0,1,584.43,829.69Z"
        ></path>
        <path
          className="cls-3"
          d="M595.65,827.89a.52.52,0,0,1-.12.72.51.51,0,0,1-.72-.12.52.52,0,1,1,.84-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M606.08,825.48a.51.51,0,0,1,.72.12.51.51,0,0,1-.83.6A.51.51,0,0,1,606.08,825.48Z"
        ></path>
        <path
          className="cls-3"
          d="M617.84,822.82a.5.5,0,0,1-.12.72.51.51,0,0,1-.59-.83A.5.5,0,0,1,617.84,822.82Z"
        ></path>
        <path
          className="cls-3"
          d="M628.76,819.58a.52.52,0,0,1-.12.72.51.51,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M639.41,816.6a.52.52,0,0,1-.72-.12.51.51,0,1,1,.72.12Z"
        ></path>
        <path
          className="cls-3"
          d="M649.4,811.6a.52.52,0,0,1,.72.12.51.51,0,1,1-.72-.12Z"
        ></path>
        <path
          className="cls-3"
          d="M660.43,807.85a.54.54,0,1,1,.13-.76A.54.54,0,0,1,660.43,807.85Z"
        ></path>
        <path
          className="cls-3"
          d="M670.74,802.06a.52.52,0,0,1-.84.6.51.51,0,0,1,.12-.72A.52.52,0,0,1,670.74,802.06Z"
        ></path>
        <path
          className="cls-3"
          d="M680.71,796.58a.5.5,0,0,1-.11.71.51.51,0,1,1-.6-.83A.5.5,0,0,1,680.71,796.58Z"
        ></path>
        <path
          className="cls-3"
          d="M690.45,790.67a.51.51,0,0,1-.12.72.51.51,0,0,1-.6-.83A.51.51,0,0,1,690.45,790.67Z"
        ></path>
        <path
          className="cls-3"
          d="M699.09,785a.51.51,0,1,1,.72.12A.52.52,0,0,1,699.09,785Z"
        ></path>
        <path
          className="cls-3"
          d="M708.29,778.25a.51.51,0,1,1,.83-.6.51.51,0,0,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M717.9,771.26a.5.5,0,0,1-.71-.11.51.51,0,1,1,.83-.6A.5.5,0,0,1,717.9,771.26Z"
        ></path>
        <path
          className="cls-3"
          d="M725.77,763.67a.52.52,0,1,1,.72.12A.51.51,0,0,1,725.77,763.67Z"
        ></path>
        <path
          className="cls-3"
          d="M734.14,755.1a.54.54,0,1,1-.13.76A.54.54,0,0,1,734.14,755.1Z"
        ></path>
        <path
          className="cls-3"
          d="M742.79,747.06a.51.51,0,1,1-.83.6.51.51,0,0,1,.11-.72A.52.52,0,0,1,742.79,747.06Z"
        ></path>
        <path
          className="cls-3"
          d="M749.64,738.43a.52.52,0,1,1-.12.72A.51.51,0,0,1,749.64,738.43Z"
        ></path>
        <path
          className="cls-3"
          d="M757.55,729.72a.52.52,0,0,1-.12.72.51.51,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M763.55,721.18a.48.48,0,1,1,.78-.56.48.48,0,0,1-.78.56Z"
        ></path>
        <path
          className="cls-3"
          d="M770.05,711.07a.52.52,0,1,1-.12.72A.52.52,0,0,1,770.05,711.07Z"
        ></path>
        <path
          className="cls-3"
          d="M776.06,701.4a.51.51,0,0,1,.6.83.51.51,0,0,1-.72-.11A.5.5,0,0,1,776.06,701.4Z"
        ></path>
        <path
          className="cls-3"
          d="M782.22,692.29a.49.49,0,0,1-.67-.11.48.48,0,1,1,.67.11Z"
        ></path>
        <path
          className="cls-3"
          d="M786.68,682a.51.51,0,1,1,.72.12A.51.51,0,0,1,786.68,682Z"
        ></path>
        <path
          className="cls-3"
          d="M791.42,671.67a.49.49,0,0,1,.12-.68.47.47,0,0,1,.67.11.48.48,0,0,1-.11.68A.5.5,0,0,1,791.42,671.67Z"
        ></path>
        <path
          className="cls-3"
          d="M795.67,661.13a.52.52,0,1,1,.72.12A.52.52,0,0,1,795.67,661.13Z"
        ></path>
        <path
          className="cls-3"
          d="M800.21,650.52a.52.52,0,1,1,.11-.72A.51.51,0,0,1,800.21,650.52Z"
        ></path>
        <path
          className="cls-3"
          d="M802.84,639.52a.52.52,0,1,1,.72.12A.51.51,0,0,1,802.84,639.52Z"
        ></path>
        <path
          className="cls-3"
          d="M805.85,627.79a.52.52,0,0,1,.6.84.52.52,0,0,1-.72-.12A.51.51,0,0,1,805.85,627.79Z"
        ></path>
        <path
          className="cls-3"
          d="M808.14,617.38a.52.52,0,1,1,.72.12A.51.51,0,0,1,808.14,617.38Z"
        ></path>
        <path
          className="cls-3"
          d="M810.08,606.16a.51.51,0,1,1,.83-.6.51.51,0,1,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M811.53,594.87a.52.52,0,1,1,.72.12A.52.52,0,0,1,811.53,594.87Z"
        ></path>
        <path
          className="cls-3"
          d="M813.22,583.64a.51.51,0,0,1-.72-.11.52.52,0,0,1,.12-.72.51.51,0,1,1,.6.83Z"
        ></path>
        <path
          className="cls-3"
          d="M813.69,572.24a.48.48,0,0,1-.68-.11.49.49,0,0,1,.11-.67.48.48,0,0,1,.68.11A.47.47,0,0,1,813.69,572.24Z"
        ></path>
        <path
          className="cls-3"
          d="M813.8,560.18a.5.5,0,0,1-.11.68.49.49,0,0,1-.68-.11.49.49,0,1,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M812.52,549.37a.49.49,0,0,1,.11-.67.48.48,0,0,1,.68.11.47.47,0,0,1-.11.67A.48.48,0,0,1,812.52,549.37Z"
        ></path>
        <path
          className="cls-3"
          d="M812.36,537.45a.51.51,0,0,1-.83.6.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M810,526.77a.55.55,0,1,1,.76.13A.55.55,0,0,1,810,526.77Z"
        ></path>
        <path
          className="cls-3"
          d="M808.15,515.52a.49.49,0,0,1,.79-.57.48.48,0,0,1-.11.68A.49.49,0,0,1,808.15,515.52Z"
        ></path>
        <path
          className="cls-3"
          d="M805.71,504.41a.52.52,0,1,1,.72.12A.52.52,0,0,1,805.71,504.41Z"
        ></path>
        <path
          className="cls-3"
          d="M802.85,493.38a.48.48,0,0,1,.11-.68.5.5,0,0,1,.68.11.49.49,0,0,1-.12.68A.47.47,0,0,1,802.85,493.38Z"
        ></path>
        <path
          className="cls-3"
          d="M799.47,482.52a.51.51,0,1,1,.83-.6.5.5,0,0,1-.11.71A.51.51,0,0,1,799.47,482.52Z"
        ></path>
        <path
          className="cls-3"
          d="M795.65,471.79a.51.51,0,1,1,.72.12A.52.52,0,0,1,795.65,471.79Z"
        ></path>
        <path
          className="cls-3"
          d="M791.37,461.24a.52.52,0,0,1,.84-.6.51.51,0,0,1-.12.72A.52.52,0,0,1,791.37,461.24Z"
        ></path>
        <path
          className="cls-3"
          d="M786.63,450.9a.54.54,0,1,1,.76.12A.54.54,0,0,1,786.63,450.9Z"
        ></path>
        <path
          className="cls-3"
          d="M781.49,440.73a.52.52,0,0,1,.84-.6.51.51,0,0,1-.12.72A.52.52,0,0,1,781.49,440.73Z"
        ></path>
        <path
          className="cls-3"
          d="M775.91,430.81a.51.51,0,0,1,.11-.72.52.52,0,0,1,.72.12.51.51,0,1,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M769.9,421.14a.52.52,0,1,1,.72.11A.51.51,0,0,1,769.9,421.14Z"
        ></path>
        <path
          className="cls-3"
          d="M763.48,411.73a.52.52,0,0,1,.84-.6.51.51,0,0,1-.12.72A.52.52,0,0,1,763.48,411.73Z"
        ></path>
        <path
          className="cls-3"
          d="M756.67,402.61a.52.52,0,0,1,.12-.72.51.51,0,0,1,.72.12.5.5,0,0,1-.12.71A.51.51,0,0,1,756.67,402.61Z"
        ></path>
        <path
          className="cls-3"
          d="M749.48,393.78a.51.51,0,0,1,.12-.72.52.52,0,0,1,.6.84A.51.51,0,0,1,749.48,393.78Z"
        ></path>
        <path
          className="cls-3"
          d="M741.91,385.27a.52.52,0,1,1,.72.12A.51.51,0,0,1,741.91,385.27Z"
        ></path>
        <path
          className="cls-3"
          d="M734,377.11a.54.54,0,1,1,.76.13A.54.54,0,0,1,734,377.11Z"
        ></path>
        <path
          className="cls-3"
          d="M725.73,369.26a.51.51,0,0,1,.83-.6.51.51,0,1,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M717.14,361.79a.51.51,0,1,1,.72.12A.51.51,0,0,1,717.14,361.79Z"
        ></path>
        <path
          className="cls-3"
          d="M708.24,354.69a.5.5,0,0,1,.11-.71.51.51,0,1,1,.6.83A.49.49,0,0,1,708.24,354.69Z"
        ></path>
        <path
          className="cls-3"
          d="M699,348a.51.51,0,0,1,.83-.6.51.51,0,1,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M689.56,341.67a.52.52,0,1,1,.72.12A.51.51,0,0,1,689.56,341.67Z"
        ></path>
        <path
          className="cls-3"
          d="M679.83,335.77a.51.51,0,0,1,.11-.72.52.52,0,0,1,.72.12.51.51,0,1,1-.83.6Z"
        ></path>
        <circle
          className="cls-3"
          cx="670.26"
          cy="329.99"
          r="0.48"
          transform="translate(-66.65 452.88) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M659.62,325.26a.54.54,0,1,1,.76.13A.54.54,0,0,1,659.62,325.26Z"
        ></path>
        <path
          className="cls-3"
          d="M649.23,320.64a.51.51,0,0,1,.12-.72.51.51,0,0,1,.6.83A.51.51,0,0,1,649.23,320.64Z"
        ></path>
        <path
          className="cls-3"
          d="M638.63,316.48a.52.52,0,1,1,.84-.6.52.52,0,0,1-.84.6Z"
        ></path>
        <path
          className="cls-3"
          d="M628.56,312.87a.47.47,0,0,1-.67-.11.48.48,0,1,1,.67.11Z"
        ></path>
        <path
          className="cls-3"
          d="M616.92,309.56a.55.55,0,1,1,.76.12A.54.54,0,0,1,616.92,309.56Z"
        ></path>
        <path
          className="cls-3"
          d="M605.88,306.79a.54.54,0,1,1,.76.12A.54.54,0,0,1,605.88,306.79Z"
        ></path>
        <path
          className="cls-3"
          d="M595.47,304.59a.51.51,0,1,1-.6-.83.51.51,0,0,1,.72.11A.52.52,0,0,1,595.47,304.59Z"
        ></path>
        <path
          className="cls-3"
          d="M583.49,302.68a.54.54,0,1,1,.76.12A.54.54,0,0,1,583.49,302.68Z"
        ></path>
        <path
          className="cls-3"
          d="M572.2,301.33a.52.52,0,0,1,.12-.72.51.51,0,1,1-.12.72Z"
        ></path>
        <path
          className="cls-3"
          d="M561.69,299.88a.52.52,0,0,1-.84.6.52.52,0,0,1,.12-.72A.51.51,0,0,1,561.69,299.88Z"
        ></path>
        <path
          className="cls-3"
          d="M549.45,300.13a.54.54,0,1,1,.76.12A.54.54,0,0,1,549.45,300.13Z"
        ></path>
        <path
          className="cls-3"
          d="M538.92,299.63a.5.5,0,0,1-.12.72.49.49,0,0,1-.71-.12.5.5,0,0,1,.11-.71A.51.51,0,0,1,538.92,299.63Z"
        ></path>
        <path
          className="cls-3"
          d="M526.72,300.84a.49.49,0,0,1,.12-.71.5.5,0,0,1,.71.11.51.51,0,1,1-.83.6Z"
        ></path>
        <path
          className="cls-3"
          d="M515.38,301.94a.52.52,0,0,1,.84-.6.51.51,0,0,1-.12.72A.52.52,0,0,1,515.38,301.94Z"
        ></path>
        <path
          className="cls-3"
          d="M504.23,302.8a.51.51,0,1,1-.12.72A.51.51,0,0,1,504.23,302.8Z"
        ></path>
        <path
          className="cls-3"
          d="M493,304.83a.55.55,0,1,1-.12.76A.54.54,0,0,1,493,304.83Z"
        ></path>
        <path
          className="cls-3"
          d="M482.67,307.49a.54.54,0,1,1-.76-.12A.54.54,0,0,1,482.67,307.49Z"
        ></path>
        <path
          className="cls-3"
          d="M471.57,311.26a.54.54,0,0,1-.76-.13.53.53,0,0,1,.12-.75.54.54,0,1,1,.64.88Z"
        ></path>
        <path
          className="cls-3"
          d="M460.85,314a.55.55,0,1,1-.76-.12A.54.54,0,0,1,460.85,314Z"
        ></path>
        <path
          className="cls-3"
          d="M449.3,318.52a.52.52,0,0,1,.84-.6.52.52,0,0,1-.12.72A.51.51,0,0,1,449.3,318.52Z"
        ></path>
        <path
          className="cls-3"
          d="M438.91,322.19a.52.52,0,0,1,.72.12.51.51,0,1,1-.72-.12Z"
        ></path>
        <path
          className="cls-3"
          d="M429.2,327.86a.52.52,0,0,1-.6-.84.52.52,0,0,1,.72.12A.51.51,0,0,1,429.2,327.86Z"
        ></path>
        <path
          className="cls-3"
          d="M419.23,332.41a.52.52,0,1,1-.72-.12A.51.51,0,0,1,419.23,332.41Z"
        ></path>
        <circle
          className="cls-3"
          cx="408.95"
          cy="338.4"
          r="0.55"
          transform="translate(-120.61 302.05) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M399.66,345a.54.54,0,1,1-.63-.88.54.54,0,1,1,.63.88Z"
        ></path>
        <path
          className="cls-3"
          d="M389.69,350.58a.56.56,0,0,1,.76.13.55.55,0,1,1-.76-.13Z"
        ></path>
        <path
          className="cls-3"
          d="M381.27,358.38a.55.55,0,1,1,.13-.76A.55.55,0,0,1,381.27,358.38Z"
        ></path>
        <path
          className="cls-3"
          d="M372.65,364.91a.54.54,0,1,1-.76-.13A.54.54,0,0,1,372.65,364.91Z"
        ></path>
        <path
          className="cls-3"
          d="M363.36,373.18a.52.52,0,0,1,.84-.6.51.51,0,0,1-.12.72A.52.52,0,0,1,363.36,373.18Z"
        ></path>
        <path
          className="cls-3"
          d="M356,381.31a.52.52,0,1,1-.6-.84.51.51,0,0,1,.72.12A.52.52,0,0,1,356,381.31Z"
        ></path>
        <path
          className="cls-3"
          d="M348.36,388.93a.51.51,0,1,1-.83.6.51.51,0,0,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M340.15,398.2a.5.5,0,0,1,.12-.71.49.49,0,0,1,.71.12.5.5,0,0,1-.11.71A.5.5,0,0,1,340.15,398.2Z"
        ></path>
        <path
          className="cls-3"
          d="M334,406.57a.53.53,0,0,1-.13.75.54.54,0,1,1-.63-.88A.54.54,0,0,1,334,406.57Z"
        ></path>
        <path
          className="cls-3"
          d="M326.64,415.71a.53.53,0,0,1,.75.12.54.54,0,0,1-.88.64A.54.54,0,0,1,326.64,415.71Z"
        ></path>
        <path
          className="cls-3"
          d="M321.19,425.38a.56.56,0,0,1-.13.76.55.55,0,0,1-.64-.89A.56.56,0,0,1,321.19,425.38Z"
        ></path>
        <path
          className="cls-3"
          d="M314.63,435.05a.55.55,0,1,1,.63.89.56.56,0,0,1-.76-.13A.55.55,0,0,1,314.63,435.05Z"
        ></path>
        <path
          className="cls-3"
          d="M310,445.21a.55.55,0,0,1-.89.64.55.55,0,0,1,.89-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M304.32,455.35a.54.54,0,1,1-.13.76A.54.54,0,0,1,304.32,455.35Z"
        ></path>
        <path
          className="cls-3"
          d="M299.7,466.57a.54.54,0,1,1,.76.12A.54.54,0,0,1,299.7,466.57Z"
        ></path>
        <path
          className="cls-3"
          d="M295.79,476.47a.52.52,0,0,1,.72.12.51.51,0,0,1-.12.72.5.5,0,0,1-.71-.12A.51.51,0,0,1,295.79,476.47Z"
        ></path>
        <circle
          className="cls-3"
          cx="292.51"
          cy="487.7"
          r="0.55"
          transform="translate(-229.55 262.16) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M289.83,498.33a.55.55,0,1,1-.76-.12A.55.55,0,0,1,289.83,498.33Z"
        ></path>
        <circle
          className="cls-3"
          cx="286.74"
          cy="509.72"
          r="0.55"
          transform="matrix(0.81, -0.58, 0.58, 0.81, -243.48, 262.92)"
        ></circle>
        <path
          className="cls-3"
          d="M284.24,520.45a.55.55,0,1,1-.12.76A.54.54,0,0,1,284.24,520.45Z"
        ></path>
        <path
          className="cls-3"
          d="M283.28,531.86a.49.49,0,0,1-.12.71.5.5,0,0,1-.71-.11.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M282.09,543.16a.54.54,0,1,1-.76-.13A.54.54,0,0,1,282.09,543.16Z"
        ></path>
        <path
          className="cls-3"
          d="M281.37,554.52a.55.55,0,1,1-.76-.12A.54.54,0,0,1,281.37,554.52Z"
        ></path>
        <path
          className="cls-3"
          d="M281.15,565.89a.58.58,0,0,1-.13.8.6.6,0,0,1-.81-.13.59.59,0,0,1,.14-.81A.58.58,0,0,1,281.15,565.89Z"
        ></path>
        <path
          className="cls-3"
          d="M280.63,577.19a.5.5,0,0,1,.71.11.51.51,0,1,1-.83.6A.5.5,0,0,1,280.63,577.19Z"
        ></path>
        <path
          className="cls-3"
          d="M281.21,589.28a.55.55,0,1,1,.76.13A.55.55,0,0,1,281.21,589.28Z"
        ></path>
        <path
          className="cls-3"
          d="M283.32,600a.56.56,0,0,1-.13.76.55.55,0,0,1-.76-.13.55.55,0,1,1,.89-.63Z"
        ></path>
        <path
          className="cls-3"
          d="M285,611.23a.54.54,0,1,1-.76-.13A.54.54,0,0,1,285,611.23Z"
        ></path>
        <path
          className="cls-3"
          d="M287.17,622.42a.52.52,0,0,1-.84.6.51.51,0,0,1,.12-.72A.52.52,0,0,1,287.17,622.42Z"
        ></path>
        <path
          className="cls-3"
          d="M289.08,633.35a.54.54,0,1,1-.12.76A.54.54,0,0,1,289.08,633.35Z"
        ></path>
        <path
          className="cls-3"
          d="M293,644.42a.55.55,0,1,1-.76-.12A.54.54,0,0,1,293,644.42Z"
        ></path>
        <path
          className="cls-3"
          d="M296.56,655.23a.55.55,0,1,1-.76-.13A.55.55,0,0,1,296.56,655.23Z"
        ></path>
        <path
          className="cls-3"
          d="M299.86,665.77a.51.51,0,0,1,.72.12.5.5,0,0,1-.12.71.51.51,0,0,1-.72-.11A.52.52,0,0,1,299.86,665.77Z"
        ></path>
        <path
          className="cls-3"
          d="M305.08,676.35a.52.52,0,0,1-.84.6.52.52,0,0,1,.12-.72A.51.51,0,0,1,305.08,676.35Z"
        ></path>
        <path
          className="cls-3"
          d="M310,686.6a.52.52,0,0,1-.12.72.51.51,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M315.39,696.64a.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M321.22,706.42a.55.55,0,1,1-.76-.12A.55.55,0,0,1,321.22,706.42Z"
        ></path>
        <circle
          className="cls-3"
          cx="326.99"
          cy="716.28"
          r="0.55"
          transform="translate(-356.4 325.18) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M334,725.23a.54.54,0,0,1-.12.76.54.54,0,0,1-.76-.13.54.54,0,1,1,.88-.63Z"
        ></path>
        <path
          className="cls-3"
          d="M341.05,734.2a.55.55,0,1,1-.89.64.55.55,0,0,1,.89-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M348.41,742.89a.52.52,0,0,1-.84.6.51.51,0,0,1,.12-.72A.52.52,0,0,1,348.41,742.89Z"
        ></path>
        <path
          className="cls-3"
          d="M356.15,751.24a.51.51,0,0,1-.83.6.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M364.24,759.24a.51.51,0,1,1-.83.6.51.51,0,0,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M372.67,766.9a.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12.51.51,0,0,1,.12-.72A.5.5,0,0,1,372.67,766.9Z"
        ></path>
        <path
          className="cls-3"
          d="M381.47,774.15a.57.57,0,1,1-.8-.13A.57.57,0,0,1,381.47,774.15Z"
        ></path>
        <path
          className="cls-3"
          d="M390.5,781.07a.54.54,0,0,1-.13.76.54.54,0,0,1-.76-.12.55.55,0,0,1,.13-.76A.54.54,0,0,1,390.5,781.07Z"
        ></path>
        <path
          className="cls-3"
          d="M399.84,787.58a.55.55,0,1,1-.76-.12A.54.54,0,0,1,399.84,787.58Z"
        ></path>
        <path
          className="cls-3"
          d="M409.42,793.71a.51.51,0,1,1-.83.6.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M418.56,799.28a.52.52,0,0,1,.72.12.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12A.51.51,0,0,1,418.56,799.28Z"
        ></path>
        <path
          className="cls-3"
          d="M423.73,816.05a.49.49,0,0,1-.79.57.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M433.36,820.78a.5.5,0,0,1,.68.11.49.49,0,0,1-.79.57A.48.48,0,0,1,433.36,820.78Z"
        ></path>
        <path
          className="cls-3"
          d="M444.53,825.31a.47.47,0,0,1-.11.67.48.48,0,0,1-.68-.11.49.49,0,0,1,.11-.67A.48.48,0,0,1,444.53,825.31Z"
        ></path>
        <path
          className="cls-3"
          d="M455.2,829.29a.49.49,0,0,1-.79.57.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M466,832.84a.49.49,0,0,1-.11.67.49.49,0,1,1-.57-.79A.49.49,0,0,1,466,832.84Z"
        ></path>
        <path
          className="cls-3"
          d="M476.86,836.61a.47.47,0,0,1-.67-.11.48.48,0,0,1,.11-.68.49.49,0,0,1,.67.11A.48.48,0,0,1,476.86,836.61Z"
        ></path>
        <path
          className="cls-3"
          d="M487.37,838.47a.49.49,0,0,1,.67.11.48.48,0,0,1-.11.68.47.47,0,0,1-.67-.11A.48.48,0,0,1,487.37,838.47Z"
        ></path>
        <path
          className="cls-3"
          d="M499.22,840.78a.49.49,0,0,1-.79.57.48.48,0,0,1,.11-.68A.5.5,0,0,1,499.22,840.78Z"
        ></path>
        <path
          className="cls-3"
          d="M509.77,842.39a.51.51,0,0,1,.72.11.5.5,0,0,1-.12.72.49.49,0,0,1-.71-.12A.5.5,0,0,1,509.77,842.39Z"
        ></path>
        <path
          className="cls-3"
          d="M521.09,843.67a.51.51,0,0,1,.72.11.52.52,0,0,1-.12.72.51.51,0,0,1-.72-.12A.5.5,0,0,1,521.09,843.67Z"
        ></path>
        <path
          className="cls-3"
          d="M532.46,844.51a.48.48,0,0,1,.68.11.49.49,0,0,1-.11.67.48.48,0,1,1-.57-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M543.84,844.86a.48.48,0,0,1,.68.11.5.5,0,0,1-.11.68.49.49,0,0,1-.68-.12A.48.48,0,0,1,543.84,844.86Z"
        ></path>
        <path
          className="cls-3"
          d="M555.12,845.42a.48.48,0,1,1,.67.11A.47.47,0,0,1,555.12,845.42Z"
        ></path>
        <path
          className="cls-3"
          d="M567.3,844.26a.52.52,0,0,1-.12.72.51.51,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M578.59,843.26a.46.46,0,1,1-.64-.1A.46.46,0,0,1,578.59,843.26Z"
        ></path>
        <path
          className="cls-3"
          d="M589.9,841.74a.5.5,0,0,1-.11.68.49.49,0,0,1-.57-.79A.48.48,0,0,1,589.9,841.74Z"
        ></path>
        <path
          className="cls-3"
          d="M601.11,839.78a.48.48,0,1,1-.78.56.48.48,0,1,1,.78-.56Z"
        ></path>
        <path
          className="cls-3"
          d="M612.13,838a.48.48,0,0,1-.68-.11.49.49,0,1,1,.79-.57A.49.49,0,0,1,612.13,838Z"
        ></path>
        <path
          className="cls-3"
          d="M622.58,834.39a.48.48,0,0,1,.68.11.49.49,0,0,1-.11.67.48.48,0,1,1-.57-.78Z"
        ></path>
        <path
          className="cls-3"
          d="M634,831.86a.49.49,0,1,1-.57-.79.49.49,0,0,1,.68.11A.48.48,0,0,1,634,831.86Z"
        ></path>
        <path
          className="cls-3"
          d="M644.9,827.42a.5.5,0,0,1-.11.68.48.48,0,0,1-.68-.11.49.49,0,1,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M654.81,823.12a.48.48,0,0,1,.56.79.48.48,0,0,1-.56-.79Z"
        ></path>
        <path
          className="cls-3"
          d="M665.92,818.6a.52.52,0,1,1-.72-.12A.51.51,0,0,1,665.92,818.6Z"
        ></path>
        <path
          className="cls-3"
          d="M676.07,813.59a.45.45,0,1,1-.63-.1A.45.45,0,0,1,676.07,813.59Z"
        ></path>
        <path
          className="cls-3"
          d="M685.44,808a.46.46,0,0,1,.53.74.46.46,0,1,1-.53-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M695.83,802.28a.45.45,0,1,1-.63-.1A.45.45,0,0,1,695.83,802.28Z"
        ></path>
        <path
          className="cls-3"
          d="M705.35,796a.45.45,0,0,1-.1.63.45.45,0,1,1,.1-.63Z"
        ></path>
        <path
          className="cls-3"
          d="M713.87,789.93a.45.45,0,0,1,.73-.53.44.44,0,0,1-.1.63A.45.45,0,0,1,713.87,789.93Z"
        ></path>
        <path
          className="cls-3"
          d="M723,782.29a.44.44,0,0,1,.63.11.45.45,0,0,1-.1.63.47.47,0,0,1-.64-.1A.46.46,0,0,1,723,782.29Z"
        ></path>
        <path
          className="cls-3"
          d="M731.53,775.56a.45.45,0,1,1,.63.1A.45.45,0,0,1,731.53,775.56Z"
        ></path>
        <circle className="cls-3" cx="740.27" cy="767.58" r="0.45"></circle>
        <path
          className="cls-3"
          d="M747.93,759.82a.49.49,0,1,1,.68.11A.5.5,0,0,1,747.93,759.82Z"
        ></path>
        <path
          className="cls-3"
          d="M756.33,751.56a.49.49,0,0,1-.68-.11.48.48,0,0,1,.11-.68.49.49,0,0,1,.57.79Z"
        ></path>
        <path
          className="cls-3"
          d="M763,742.77a.5.5,0,0,1,.11-.68.49.49,0,0,1,.57.79A.48.48,0,0,1,763,742.77Z"
        ></path>
        <path
          className="cls-3"
          d="M770,733.8a.49.49,0,0,1,.79-.57.49.49,0,0,1-.11.68A.48.48,0,0,1,770,733.8Z"
        ></path>
        <path
          className="cls-3"
          d="M777.35,724.66a.48.48,0,0,1-.68-.11.48.48,0,1,1,.68.11Z"
        ></path>
        <path
          className="cls-3"
          d="M783.6,715.15a.48.48,0,0,1-.56-.79.48.48,0,0,1,.56.79Z"
        ></path>
        <path
          className="cls-3"
          d="M788.92,704.63a.45.45,0,1,1-.1.63A.45.45,0,0,1,788.92,704.63Z"
        ></path>
        <path
          className="cls-3"
          d="M794.93,695.4a.49.49,0,1,1-.57-.79.49.49,0,0,1,.68.11A.5.5,0,0,1,794.93,695.4Z"
        ></path>
        <path
          className="cls-3"
          d="M799.3,685.08a.47.47,0,0,1,.11-.67.48.48,0,1,1,.56.78A.47.47,0,0,1,799.3,685.08Z"
        ></path>
        <path
          className="cls-3"
          d="M804,674.64a.42.42,0,0,1,.09-.59.43.43,0,0,1,.59.1.42.42,0,1,1-.68.49Z"
        ></path>
        <path
          className="cls-3"
          d="M808.91,663.53a.48.48,0,0,1-.11.68.49.49,0,0,1-.67-.11.48.48,0,0,1,.11-.68A.47.47,0,0,1,808.91,663.53Z"
        ></path>
        <circle className="cls-3" cx="812.28" cy="653.07" r="0.45"></circle>
        <path
          className="cls-3"
          d="M815.21,642.46a.48.48,0,0,1,.12-.67.48.48,0,0,1,.56.78A.48.48,0,0,1,815.21,642.46Z"
        ></path>
        <path
          className="cls-3"
          d="M818.09,631.44a.47.47,0,0,1,.11-.67.48.48,0,0,1,.68.11.5.5,0,0,1-.11.68A.49.49,0,0,1,818.09,631.44Z"
        ></path>
        <path
          className="cls-3"
          d="M820.51,620.32a.49.49,0,0,1,.12-.68.48.48,0,0,1,.67.12.47.47,0,0,1-.11.67A.48.48,0,0,1,820.51,620.32Z"
        ></path>
        <path
          className="cls-3"
          d="M822.48,609.11a.5.5,0,0,1,.11-.68.48.48,0,0,1,.68.11.49.49,0,0,1-.11.68A.5.5,0,0,1,822.48,609.11Z"
        ></path>
        <path
          className="cls-3"
          d="M824,597.82a.48.48,0,1,1,.68.11A.49.49,0,0,1,824,597.82Z"
        ></path>
        <circle className="cls-3" cx="825.43" cy="586.2" r="0.45"></circle>
        <path
          className="cls-3"
          d="M826.3,575.22a.48.48,0,1,1-.56-.78.48.48,0,0,1,.56.78Z"
        ></path>
        <path
          className="cls-3"
          d="M826.42,563.84a.47.47,0,0,1-.67-.11.48.48,0,0,1,.11-.68.49.49,0,0,1,.67.11A.48.48,0,0,1,826.42,563.84Z"
        ></path>
        <path
          className="cls-3"
          d="M826.08,552.46a.5.5,0,0,1-.68-.11.49.49,0,0,1,.11-.68.49.49,0,0,1,.57.79Z"
        ></path>
        <path
          className="cls-3"
          d="M824.64,541a.44.44,0,0,1,.1-.6.43.43,0,0,1,.59.1.43.43,0,0,1-.69.5Z"
        ></path>
        <path
          className="cls-3"
          d="M824.08,529.13a.45.45,0,1,1-.63-.1A.45.45,0,0,1,824.08,529.13Z"
        ></path>
        <path
          className="cls-3"
          d="M821.62,518.41a.46.46,0,0,1,.1-.64.46.46,0,1,1-.1.64Z"
        ></path>
        <path
          className="cls-3"
          d="M819.43,507.23a.45.45,0,1,1,.73-.53.46.46,0,0,1-.1.64A.45.45,0,0,1,819.43,507.23Z"
        ></path>
        <path
          className="cls-3"
          d="M816.76,496.18a.49.49,0,1,1,.79-.57.49.49,0,0,1-.79.57Z"
        ></path>
        <path
          className="cls-3"
          d="M813.69,485.2a.46.46,0,1,1,.64.1A.46.46,0,0,1,813.69,485.2Z"
        ></path>
        <path
          className="cls-3"
          d="M810.13,474.4a.5.5,0,0,1,.11-.68.48.48,0,0,1,.68.11.49.49,0,0,1-.79.57Z"
        ></path>
        <path
          className="cls-3"
          d="M806.16,463.73a.48.48,0,0,1,.11-.68.47.47,0,0,1,.67.11.48.48,0,0,1-.11.68A.49.49,0,0,1,806.16,463.73Z"
        ></path>
        <path
          className="cls-3"
          d="M801.75,453.23a.48.48,0,0,1,.11-.68.49.49,0,0,1,.57.79A.49.49,0,0,1,801.75,453.23Z"
        ></path>
        <path
          className="cls-3"
          d="M796.89,442.94a.52.52,0,0,1,.12-.72.51.51,0,0,1,.72.12.5.5,0,0,1-.12.71A.51.51,0,0,1,796.89,442.94Z"
        ></path>
        <path
          className="cls-3"
          d="M791.7,432.8a.46.46,0,0,1,.1-.64.46.46,0,1,1-.1.64Z"
        ></path>
        <path
          className="cls-3"
          d="M786,422.92a.46.46,0,1,1,.74-.53.46.46,0,0,1-.74.53Z"
        ></path>
        <path
          className="cls-3"
          d="M780,413.27a.45.45,0,0,1,.73-.53.46.46,0,0,1-.1.64A.45.45,0,0,1,780,413.27Z"
        ></path>
        <path
          className="cls-3"
          d="M773.54,403.89a.46.46,0,0,1,.11-.64.46.46,0,0,1,.53.74A.46.46,0,0,1,773.54,403.89Z"
        ></path>
        <path
          className="cls-3"
          d="M766.72,394.77a.46.46,0,0,1,.11-.64.46.46,0,0,1,.53.74A.46.46,0,0,1,766.72,394.77Z"
        ></path>
        <path
          className="cls-3"
          d="M759.54,385.94a.46.46,0,0,1,.1-.64.46.46,0,1,1-.1.64Z"
        ></path>
        <path
          className="cls-3"
          d="M752,377.41a.46.46,0,0,1,.1-.64.45.45,0,0,1,.63.11.44.44,0,0,1-.1.63A.45.45,0,0,1,752,377.41Z"
        ></path>
        <path
          className="cls-3"
          d="M744.07,369.22a.5.5,0,0,1,.11-.71.51.51,0,1,1,.6.83A.49.49,0,0,1,744.07,369.22Z"
        ></path>
        <path
          className="cls-3"
          d="M735.88,361.32a.49.49,0,0,1,.11-.68.49.49,0,0,1,.57.79A.48.48,0,0,1,735.88,361.32Z"
        ></path>
        <path
          className="cls-3"
          d="M727.36,353.77a.48.48,0,1,1,.67.11A.47.47,0,0,1,727.36,353.77Z"
        ></path>
        <path
          className="cls-3"
          d="M718.53,346.58a.48.48,0,0,1,.11-.68.49.49,0,0,1,.57.79A.48.48,0,0,1,718.53,346.58Z"
        ></path>
        <path
          className="cls-3"
          d="M709.42,339.75a.48.48,0,1,1,.67.11A.47.47,0,0,1,709.42,339.75Z"
        ></path>
        <path
          className="cls-3"
          d="M700,333.3a.49.49,0,0,1,.11-.67.49.49,0,0,1,.57.79A.49.49,0,0,1,700,333.3Z"
        ></path>
        <path
          className="cls-3"
          d="M690.4,327.24a.48.48,0,1,1,.67.11A.47.47,0,0,1,690.4,327.24Z"
        ></path>
        <path
          className="cls-3"
          d="M680.54,321.56a.46.46,0,0,1,.74-.53.46.46,0,1,1-.74.53Z"
        ></path>
        <path
          className="cls-3"
          d="M670.44,316.31a.46.46,0,0,1,.11-.64.46.46,0,0,1,.53.74A.46.46,0,0,1,670.44,316.31Z"
        ></path>
        <path
          className="cls-3"
          d="M660.14,311.47a.45.45,0,1,1,.63.1A.45.45,0,0,1,660.14,311.47Z"
        ></path>
        <path
          className="cls-3"
          d="M649.59,307.09a.52.52,0,1,1,.72.12A.52.52,0,0,1,649.59,307.09Z"
        ></path>
        <path
          className="cls-3"
          d="M639,303.09a.49.49,0,0,1,.11-.68.49.49,0,1,1,.57.79A.48.48,0,0,1,639,303.09Z"
        ></path>
        <path
          className="cls-3"
          d="M628.13,299.54a.49.49,0,0,1,.11-.67.48.48,0,0,1,.68.11.5.5,0,0,1-.11.68A.49.49,0,0,1,628.13,299.54Z"
        ></path>
        <path
          className="cls-3"
          d="M617.85,296.56a.48.48,0,0,1-.67-.12.48.48,0,1,1,.78-.56A.48.48,0,0,1,617.85,296.56Z"
        ></path>
        <path
          className="cls-3"
          d="M606.8,293.93a.52.52,0,0,1-.72-.12.51.51,0,1,1,.72.12Z"
        ></path>
        <path
          className="cls-3"
          d="M595.61,291.71a.49.49,0,1,1,.11-.68A.5.5,0,0,1,595.61,291.71Z"
        ></path>
        <circle
          className="cls-3"
          cx="584.07"
          cy="289.58"
          r="0.48"
          transform="translate(-59.26 395.02) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M572.37,288.58a.48.48,0,0,1,.11-.68.49.49,0,0,1,.67.11.48.48,0,0,1-.11.68A.47.47,0,0,1,572.37,288.58Z"
        ></path>
        <path
          className="cls-3"
          d="M561.7,287.9a.52.52,0,0,1-.6-.84.52.52,0,0,1,.72.12A.51.51,0,0,1,561.7,287.9Z"
        ></path>
        <path
          className="cls-3"
          d="M549.63,287.41a.48.48,0,0,1,.11-.68.49.49,0,0,1,.57.79A.48.48,0,0,1,549.63,287.41Z"
        ></path>
        <path
          className="cls-3"
          d="M538.92,287.63A.48.48,0,1,1,539,287,.47.47,0,0,1,538.92,287.63Z"
        ></path>
        <path
          className="cls-3"
          d="M526.87,288.1a.49.49,0,0,1,.79-.57.48.48,0,0,1-.11.68A.49.49,0,0,1,526.87,288.1Z"
        ></path>
        <path
          className="cls-3"
          d="M515.54,289.14a.48.48,0,0,1,.11-.68.49.49,0,0,1,.67.11.48.48,0,0,1-.11.68A.49.49,0,0,1,515.54,289.14Z"
        ></path>
        <path
          className="cls-3"
          d="M504.36,290a.49.49,0,0,1,.57.79.49.49,0,1,1-.57-.79Z"
        ></path>
        <path
          className="cls-3"
          d="M493.13,291.9a.51.51,0,0,1,.6.83.51.51,0,1,1-.6-.83Z"
        ></path>
        <path
          className="cls-3"
          d="M482.58,295.13a.49.49,0,0,1-.67-.11.48.48,0,0,1,.11-.68.47.47,0,0,1,.67.11A.48.48,0,0,1,482.58,295.13Z"
        ></path>
        <path
          className="cls-3"
          d="M471.57,298a.48.48,0,0,1-.68-.11.47.47,0,0,1,.11-.67.48.48,0,1,1,.57.78Z"
        ></path>
        <path
          className="cls-3"
          d="M460.67,301.31a.49.49,0,0,1-.67-.11.48.48,0,1,1,.67.11Z"
        ></path>
        <path
          className="cls-3"
          d="M449.92,305.07a.48.48,0,0,1-.67-.12.47.47,0,0,1,.11-.67.48.48,0,0,1,.68.11A.49.49,0,0,1,449.92,305.07Z"
        ></path>
        <path
          className="cls-3"
          d="M438.66,309.15a.5.5,0,0,1,.11-.68.49.49,0,0,1,.57.79A.5.5,0,0,1,438.66,309.15Z"
        ></path>
        <path
          className="cls-3"
          d="M428.26,313.77a.48.48,0,0,1,.11-.68.47.47,0,0,1,.67.11.48.48,0,0,1-.11.68A.49.49,0,0,1,428.26,313.77Z"
        ></path>
        <path
          className="cls-3"
          d="M418,318.82a.52.52,0,1,1,.72.12A.51.51,0,0,1,418,318.82Z"
        ></path>
        <path
          className="cls-3"
          d="M408.75,324.4a.52.52,0,0,1-.72-.12.51.51,0,0,1,.12-.72.5.5,0,0,1,.71.12A.51.51,0,0,1,408.75,324.4Z"
        ></path>
        <path
          className="cls-3"
          d="M398.27,330.13a.5.5,0,0,1,.11-.71.51.51,0,1,1,.6.83A.5.5,0,0,1,398.27,330.13Z"
        ></path>
        <path
          className="cls-3"
          d="M389.47,336.5a.51.51,0,0,1-.72-.12.51.51,0,1,1,.83-.6A.51.51,0,0,1,389.47,336.5Z"
        ></path>
        <path
          className="cls-3"
          d="M380.33,342.41a.52.52,0,0,1-.12.72.51.51,0,1,1-.6-.83A.51.51,0,0,1,380.33,342.41Z"
        ></path>
        <path
          className="cls-3"
          d="M370.63,349.3a.52.52,0,0,1,.72.12.51.51,0,1,1-.83.6A.51.51,0,0,1,370.63,349.3Z"
        ></path>
        <path
          className="cls-3"
          d="M362,356.67a.51.51,0,1,1-.12.71A.51.51,0,0,1,362,356.67Z"
        ></path>
        <path
          className="cls-3"
          d="M353.58,364.38a.51.51,0,1,1-.12.72A.51.51,0,0,1,353.58,364.38Z"
        ></path>
        <path
          className="cls-3"
          d="M346.22,372.56a.49.49,0,0,1-.79.57.48.48,0,0,1,.11-.68A.49.49,0,0,1,346.22,372.56Z"
        ></path>
        <path
          className="cls-3"
          d="M338.5,380.93a.49.49,0,1,1-.68-.11A.48.48,0,0,1,338.5,380.93Z"
        ></path>
        <path
          className="cls-3"
          d="M330.43,389.47a.52.52,0,0,1,.72.12.51.51,0,1,1-.72-.12Z"
        ></path>
        <path
          className="cls-3"
          d="M323.42,398.44a.52.52,0,0,1,.72.12.52.52,0,1,1-.84.6A.52.52,0,0,1,323.42,398.44Z"
        ></path>
        <circle
          className="cls-3"
          cx="317.08"
          cy="408.11"
          r="0.48"
          transform="translate(-178.51 261.55) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M310.55,417.23a.47.47,0,0,1,.67.11.48.48,0,0,1-.11.68.5.5,0,0,1-.68-.11A.49.49,0,0,1,310.55,417.23Z"
        ></path>
        <path
          className="cls-3"
          d="M305.38,427.08a.51.51,0,0,1-.12.72.51.51,0,0,1-.6-.83A.51.51,0,0,1,305.38,427.08Z"
        ></path>
        <path
          className="cls-3"
          d="M299.2,437a.51.51,0,1,1,.6.83.49.49,0,0,1-.71-.12A.5.5,0,0,1,299.2,437Z"
        ></path>
        <path
          className="cls-3"
          d="M294.87,447.28a.51.51,0,1,1-.71-.12A.51.51,0,0,1,294.87,447.28Z"
        ></path>
        <path
          className="cls-3"
          d="M290.22,457.7a.48.48,0,1,1-.68-.11A.48.48,0,0,1,290.22,457.7Z"
        ></path>
        <path
          className="cls-3"
          d="M286,468.28a.49.49,0,0,1-.79.57.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M281.58,478.92a.48.48,0,1,1-.11.67A.48.48,0,0,1,281.58,478.92Z"
        ></path>
        <path
          className="cls-3"
          d="M279,489.9a.52.52,0,1,1-.72-.12A.52.52,0,0,1,279,489.9Z"
        ></path>
        <path
          className="cls-3"
          d="M276.08,500.92a.51.51,0,0,1-.83.6.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M273.66,512a.52.52,0,1,1-.72-.12A.52.52,0,0,1,273.66,512Z"
        ></path>
        <path
          className="cls-3"
          d="M271,523.16a.5.5,0,0,1,.68.11.49.49,0,0,1-.79.57A.48.48,0,0,1,271,523.16Z"
        ></path>
        <path
          className="cls-3"
          d="M270.18,534.54a.51.51,0,0,1-.12.72.52.52,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M269.13,545.88a.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M268.52,557.27a.48.48,0,0,1-.78.56.48.48,0,1,1,.78-.56Z"
        ></path>
        <path
          className="cls-3"
          d="M268.4,568.65a.48.48,0,1,1-.67-.11A.47.47,0,0,1,268.4,568.65Z"
        ></path>
        <path
          className="cls-3"
          d="M268.07,579.92a.48.48,0,0,1,.68.11.49.49,0,0,1-.79.57A.49.49,0,0,1,268.07,579.92Z"
        ></path>
        <path
          className="cls-3"
          d="M269.46,592.09a.52.52,0,1,1,.12-.72A.51.51,0,0,1,269.46,592.09Z"
        ></path>
        <path
          className="cls-3"
          d="M270.83,602.7a.49.49,0,0,1-.79.57.48.48,0,0,1,.11-.68A.5.5,0,0,1,270.83,602.7Z"
        ></path>
        <path
          className="cls-3"
          d="M272.58,613.94a.51.51,0,1,1-.72-.12A.51.51,0,0,1,272.58,613.94Z"
        ></path>
        <path
          className="cls-3"
          d="M274.77,625.11a.52.52,0,0,1-.84.6.52.52,0,1,1,.84-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M277.39,636.2a.5.5,0,0,1-.11.68.48.48,0,0,1-.68-.11.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M280.5,647.14a.51.51,0,1,1-.83.6.51.51,0,0,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M284,658a.5.5,0,0,1-.12.71.51.51,0,0,1-.72-.11.52.52,0,0,1,.12-.72A.51.51,0,0,1,284,658Z"
        ></path>
        <path
          className="cls-3"
          d="M288,668.65a.48.48,0,0,1-.11.68.48.48,0,1,1,.11-.68Z"
        ></path>
        <path
          className="cls-3"
          d="M292.4,679.15a.49.49,0,1,1-.68-.11A.48.48,0,0,1,292.4,679.15Z"
        ></path>
        <path
          className="cls-3"
          d="M297.25,689.44a.51.51,0,1,1-.83.6.51.51,0,0,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M302.5,699.55a.51.51,0,0,1-.83.6.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M308.16,709.43a.52.52,0,1,1-.72-.12A.52.52,0,0,1,308.16,709.43Z"
        ></path>
        <path
          className="cls-3"
          d="M314.21,719.07a.51.51,0,1,1-.72-.12A.52.52,0,0,1,314.21,719.07Z"
        ></path>
        <path
          className="cls-3"
          d="M320.65,728.46a.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M327.47,737.58a.5.5,0,0,1-.11.71.51.51,0,0,1-.72-.11.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M334.66,746.41a.5.5,0,0,1-.12.71.51.51,0,0,1-.72-.11.52.52,0,0,1,.12-.72A.51.51,0,0,1,334.66,746.41Z"
        ></path>
        <path
          className="cls-3"
          d="M342.2,754.94a.51.51,0,0,1-.12.72.52.52,0,1,1,.12-.72Z"
        ></path>
        <path
          className="cls-3"
          d="M350.08,763.15a.51.51,0,0,1-.12.72.49.49,0,0,1-.71-.12.5.5,0,0,1,.11-.71A.51.51,0,0,1,350.08,763.15Z"
        ></path>
        <path
          className="cls-3"
          d="M358.27,771.06a.49.49,0,1,1-.79.57.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M366.79,778.61a.48.48,0,1,1-.67-.11A.47.47,0,0,1,366.79,778.61Z"
        ></path>
        <path
          className="cls-3"
          d="M375.62,785.8a.48.48,0,1,1-.68-.11A.49.49,0,0,1,375.62,785.8Z"
        ></path>
        <path
          className="cls-3"
          d="M384.73,792.62a.49.49,0,1,1-.79.57.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M394.11,799.07a.48.48,0,0,1-.11.68.47.47,0,0,1-.67-.11.48.48,0,0,1,.11-.68A.49.49,0,0,1,394.11,799.07Z"
        ></path>
        <path
          className="cls-3"
          d="M403.78,805.12a.52.52,0,1,1-.72-.12A.51.51,0,0,1,403.78,805.12Z"
        ></path>
        <path
          className="cls-3"
          d="M413.65,810.78a.51.51,0,0,1-.11.72.52.52,0,0,1-.72-.12.51.51,0,1,1,.83-.6Z"
        ></path>
        <path
          className="cls-3"
          d="M418,827.48a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,418,827.48Z"
        ></path>
        <path
          className="cls-3"
          d="M428.38,832.29a.45.45,0,1,1-.73.53.44.44,0,0,1,.1-.63A.45.45,0,0,1,428.38,832.29Z"
        ></path>
        <circle className="cls-3" cx="438.5" cy="837" r="0.45"></circle>
        <path
          className="cls-3"
          d="M448.88,840.66a.45.45,0,0,1,.63.1.46.46,0,0,1-.1.64.46.46,0,0,1-.53-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M460.29,844.39a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.09.43.43,0,1,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M470.53,848.07a.42.42,0,0,1,.1-.59.42.42,0,0,1,.49.69A.42.42,0,0,1,470.53,848.07Z"
        ></path>
        <path
          className="cls-3"
          d="M482.29,850.32a.46.46,0,0,1-.74.53.46.46,0,0,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M493.43,852.65a.45.45,0,0,1-.1.63.44.44,0,0,1-.63-.11.45.45,0,1,1,.73-.52Z"
        ></path>
        <path
          className="cls-3"
          d="M504.66,854.53a.46.46,0,1,1-.74.53.46.46,0,0,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M515.93,856a.43.43,0,0,1-.69.5.44.44,0,0,1,.1-.6A.43.43,0,0,1,515.93,856Z"
        ></path>
        <path
          className="cls-3"
          d="M527.3,857a.46.46,0,0,1-.11.63.45.45,0,0,1-.53-.73A.46.46,0,0,1,527.3,857Z"
        ></path>
        <path
          className="cls-3"
          d="M538,857.45a.46.46,0,0,1,.64.11.46.46,0,0,1-.74.53A.46.46,0,0,1,538,857.45Z"
        ></path>
        <path
          className="cls-3"
          d="M549.34,858.19a.43.43,0,0,1,.69-.5.43.43,0,0,1-.1.59A.42.42,0,0,1,549.34,858.19Z"
        ></path>
        <path
          className="cls-3"
          d="M561.31,858a.42.42,0,0,1-.49-.69.42.42,0,1,1,.49.69Z"
        ></path>
        <path
          className="cls-3"
          d="M572.18,856.51a.42.42,0,1,1-.1.59A.42.42,0,0,1,572.18,856.51Z"
        ></path>
        <path
          className="cls-3"
          d="M584.09,855.39a.43.43,0,1,1-.59-.09A.42.42,0,0,1,584.09,855.39Z"
        ></path>
        <path
          className="cls-3"
          d="M595.38,853.72a.46.46,0,1,1-.74.53.46.46,0,0,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M606.55,851.65a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.09.43.43,0,1,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M617.67,849.11a.45.45,0,1,1-.63-.1A.45.45,0,0,1,617.67,849.11Z"
        ></path>
        <path
          className="cls-3"
          d="M628,846.05a.45.45,0,0,1,.63.1.46.46,0,0,1-.11.64.45.45,0,1,1-.52-.74Z"
        ></path>
        <path
          className="cls-3"
          d="M639.52,842.79a.43.43,0,0,1-.1.59.42.42,0,1,1-.49-.69A.43.43,0,0,1,639.52,842.79Z"
        ></path>
        <path
          className="cls-3"
          d="M650.25,839a.43.43,0,0,1-.1.59.42.42,0,1,1,.1-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M660.82,834.76a.42.42,0,0,1-.1.6.42.42,0,0,1-.49-.69A.42.42,0,0,1,660.82,834.76Z"
        ></path>
        <circle className="cls-3" cx="670.88" cy="830.38" r="0.45"></circle>
        <path
          className="cls-3"
          d="M681.44,825.1a.44.44,0,0,1-.1.6.43.43,0,0,1-.59-.1.43.43,0,0,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M691.45,819.68a.42.42,0,1,1-.69.49.42.42,0,1,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M701.24,813.87a.42.42,0,1,1-.69.49.42.42,0,0,1,.1-.59A.41.41,0,0,1,701.24,813.87Z"
        ></path>
        <path
          className="cls-3"
          d="M710.2,807.58a.42.42,0,0,1,.59.09.43.43,0,0,1-.09.6.44.44,0,0,1-.6-.1A.43.43,0,0,1,710.2,807.58Z"
        ></path>
        <path
          className="cls-3"
          d="M719.41,801.61a.43.43,0,0,1,.1-.59.42.42,0,1,1,.49.69A.43.43,0,0,1,719.41,801.61Z"
        ></path>
        <path
          className="cls-3"
          d="M728.46,794.69a.42.42,0,0,1,.09-.59.43.43,0,0,1,.59.1.42.42,0,1,1-.68.49Z"
        ></path>
        <path
          className="cls-3"
          d="M737.32,786.84a.42.42,0,0,1,.49.69.42.42,0,1,1-.49-.69Z"
        ></path>
        <path
          className="cls-3"
          d="M745.8,779.24a.42.42,0,0,1,.59.1.42.42,0,1,1-.69.49A.42.42,0,0,1,745.8,779.24Z"
        ></path>
        <path
          className="cls-3"
          d="M754,771.31a.43.43,0,0,1,.59.1.42.42,0,0,1-.09.59.43.43,0,1,1-.5-.69Z"
        ></path>
        <path
          className="cls-3"
          d="M761.73,763.66a.42.42,0,0,1,.69-.49.42.42,0,1,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M769.36,754.54a.42.42,0,0,1,.5.68.42.42,0,1,1-.5-.68Z"
        ></path>
        <path
          className="cls-3"
          d="M777.15,745.81a.42.42,0,0,1-.69.49.42.42,0,0,1,.09-.59A.43.43,0,0,1,777.15,745.81Z"
        ></path>
        <path
          className="cls-3"
          d="M783.32,737.18a.4.4,0,0,1,.1-.55.39.39,0,0,1,.54.09.39.39,0,0,1-.64.46Z"
        ></path>
        <circle className="cls-3" cx="790.13" cy="727.59" r="0.45"></circle>
        <path
          className="cls-3"
          d="M796.58,717.74a.42.42,0,1,1-.59-.1A.43.43,0,0,1,796.58,717.74Z"
        ></path>
        <path
          className="cls-3"
          d="M802.32,707.9a.43.43,0,0,1-.1.59.42.42,0,1,1-.5-.68A.43.43,0,0,1,802.32,707.9Z"
        ></path>
        <path
          className="cls-3"
          d="M807,698.36a.45.45,0,0,1,.1-.63.46.46,0,0,1,.64.1.47.47,0,0,1-.11.64A.45.45,0,0,1,807,698.36Z"
        ></path>
        <path
          className="cls-3"
          d="M811.92,688.09a.42.42,0,1,1,.59.1A.43.43,0,0,1,811.92,688.09Z"
        ></path>
        <path
          className="cls-3"
          d="M816.47,677.65a.42.42,0,1,1,.59.1A.43.43,0,0,1,816.47,677.65Z"
        ></path>
        <path
          className="cls-3"
          d="M821.17,667.11a.39.39,0,0,1-.55-.09.39.39,0,0,1,.09-.54.39.39,0,0,1,.55.09A.38.38,0,0,1,821.17,667.11Z"
        ></path>
        <path
          className="cls-3"
          d="M824.39,655.66a.46.46,0,0,1,.64.11.44.44,0,0,1-.11.63.45.45,0,0,1-.63-.1A.46.46,0,0,1,824.39,655.66Z"
        ></path>
        <path
          className="cls-3"
          d="M827.61,645.38a.42.42,0,0,1,.69-.49.43.43,0,0,1-.1.59A.42.42,0,0,1,827.61,645.38Z"
        ></path>
        <path
          className="cls-3"
          d="M830.48,634.36a.42.42,0,0,1,.09-.59.43.43,0,0,1,.59.1.42.42,0,1,1-.68.49Z"
        ></path>
        <path
          className="cls-3"
          d="M832.91,623.24a.42.42,0,0,1,.69-.49.42.42,0,1,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M834.88,612.05a.46.46,0,1,1,.64.1A.46.46,0,0,1,834.88,612.05Z"
        ></path>
        <path
          className="cls-3"
          d="M836.47,600.75a.43.43,0,0,1,.09-.59.44.44,0,0,1,.6.1.43.43,0,0,1-.1.59A.42.42,0,0,1,836.47,600.75Z"
        ></path>
        <path
          className="cls-3"
          d="M837.59,589.42a.42.42,0,1,1,.59.1A.42.42,0,0,1,837.59,589.42Z"
        ></path>
        <path
          className="cls-3"
          d="M838.26,578.06a.43.43,0,0,1,.1-.59.42.42,0,1,1-.1.59Z"
        ></path>
        <path
          className="cls-3"
          d="M839.1,566.79a.45.45,0,1,1-.53-.73.46.46,0,0,1,.64.1A.45.45,0,0,1,839.1,566.79Z"
        ></path>
        <path
          className="cls-3"
          d="M838.87,555.39a.43.43,0,0,1-.59-.1.42.42,0,1,1,.69-.49A.43.43,0,0,1,838.87,555.39Z"
        ></path>
        <path
          className="cls-3"
          d="M837.62,543.92a.42.42,0,0,1,.69-.49.42.42,0,1,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M836.62,532a.42.42,0,0,1,.59.1.41.41,0,0,1-.1.59.42.42,0,0,1-.49-.69Z"
        ></path>
        <path
          className="cls-3"
          d="M835,521.33a.46.46,0,1,1,.63.1A.45.45,0,0,1,835,521.33Z"
        ></path>
        <path
          className="cls-3"
          d="M833,510.1a.43.43,0,0,1,.1-.59.42.42,0,0,1,.59.09.43.43,0,1,1-.69.5Z"
        ></path>
        <path
          className="cls-3"
          d="M830.56,499a.46.46,0,0,1,.74-.53.44.44,0,0,1-.11.63A.45.45,0,0,1,830.56,499Z"
        ></path>
        <path
          className="cls-3"
          d="M827.71,488a.46.46,0,0,1,.11-.64.46.46,0,0,1,.53.74A.46.46,0,0,1,827.71,488Z"
        ></path>
        <path
          className="cls-3"
          d="M824.49,477a.39.39,0,0,1,.09-.55.38.38,0,0,1,.54.09.39.39,0,0,1-.09.55A.39.39,0,0,1,824.49,477Z"
        ></path>
        <path
          className="cls-3"
          d="M820.76,466.28a.44.44,0,0,1,.1-.6.43.43,0,0,1,.59.1.43.43,0,0,1-.69.5Z"
        ></path>
        <path
          className="cls-3"
          d="M816.65,455.66a.42.42,0,1,1,.59.1A.43.43,0,0,1,816.65,455.66Z"
        ></path>
        <path
          className="cls-3"
          d="M812.12,445.21a.41.41,0,0,1,.1-.59.42.42,0,0,1,.49.69A.42.42,0,0,1,812.12,445.21Z"
        ></path>
        <path
          className="cls-3"
          d="M807.16,435a.44.44,0,0,1,.11-.63.45.45,0,0,1,.63.1.44.44,0,0,1-.11.63A.45.45,0,0,1,807.16,435Z"
        ></path>
        <path
          className="cls-3"
          d="M801.86,424.89a.42.42,0,1,1,.59.1A.43.43,0,0,1,801.86,424.89Z"
        ></path>
        <path
          className="cls-3"
          d="M796.14,415a.42.42,0,0,1,.1-.59.43.43,0,0,1,.59.1.42.42,0,0,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M790,405.43a.43.43,0,0,1,.1-.59.42.42,0,0,1,.5.68A.43.43,0,0,1,790,405.43Z"
        ></path>
        <path
          className="cls-3"
          d="M783.58,396.06a.42.42,0,0,1,.09-.59.42.42,0,1,1,.5.68A.42.42,0,0,1,783.58,396.06Z"
        ></path>
        <path
          className="cls-3"
          d="M776.75,387a.43.43,0,0,1,.69-.5.43.43,0,0,1-.1.59A.42.42,0,0,1,776.75,387Z"
        ></path>
        <path
          className="cls-3"
          d="M769.57,378.11a.43.43,0,0,1,.69-.5.44.44,0,0,1-.1.6A.43.43,0,0,1,769.57,378.11Z"
        ></path>
        <path
          className="cls-3"
          d="M762.05,369.56a.43.43,0,0,1,.1-.59.42.42,0,1,1-.1.59Z"
        ></path>
        <path
          className="cls-3"
          d="M754.18,361.32a.46.46,0,0,1,.74-.53.46.46,0,1,1-.74.53Z"
        ></path>
        <path
          className="cls-3"
          d="M746.05,353.37a.42.42,0,1,1,.59.09A.42.42,0,0,1,746.05,353.37Z"
        ></path>
        <path
          className="cls-3"
          d="M737.58,345.75a.42.42,0,1,1,.69-.49.42.42,0,0,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M728.83,338.48a.42.42,0,1,1,.68-.5.42.42,0,0,1-.68.5Z"
        ></path>
        <path
          className="cls-3"
          d="M719.79,331.54a.42.42,0,0,1,.69-.49.42.42,0,0,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M710.47,325a.45.45,0,0,1,.11-.63.44.44,0,0,1,.63.1.45.45,0,0,1-.1.63A.46.46,0,0,1,710.47,325Z"
        ></path>
        <path
          className="cls-3"
          d="M700.93,318.78a.45.45,0,0,1,.73-.53.46.46,0,0,1-.1.64A.45.45,0,0,1,700.93,318.78Z"
        ></path>
        <path
          className="cls-3"
          d="M691.17,312.94a.43.43,0,1,1,.59.09A.42.42,0,0,1,691.17,312.94Z"
        ></path>
        <path
          className="cls-3"
          d="M681.17,307.49a.42.42,0,0,1,.1-.59.42.42,0,1,1,.49.69A.42.42,0,0,1,681.17,307.49Z"
        ></path>
        <path
          className="cls-3"
          d="M671,302.45a.44.44,0,0,1,.1-.6.43.43,0,0,1,.59.1.42.42,0,0,1-.09.59A.43.43,0,0,1,671,302.45Z"
        ></path>
        <path
          className="cls-3"
          d="M660.55,297.82a.46.46,0,0,1,.1-.64.46.46,0,1,1-.1.64Z"
        ></path>
        <path
          className="cls-3"
          d="M650,293.56a.43.43,0,0,1,.1-.59.42.42,0,1,1-.1.59Z"
        ></path>
        <path
          className="cls-3"
          d="M639.25,289.76a.46.46,0,1,1,.64.1A.46.46,0,0,1,639.25,289.76Z"
        ></path>
        <path
          className="cls-3"
          d="M628.39,286.36a.45.45,0,1,1,.73-.53.45.45,0,1,1-.73.53Z"
        ></path>
        <path
          className="cls-3"
          d="M617.4,283.38a.45.45,0,0,1,.1-.63.46.46,0,0,1,.53.74A.45.45,0,0,1,617.4,283.38Z"
        ></path>
        <path
          className="cls-3"
          d="M606.32,280.82a.42.42,0,1,1,.69-.49.42.42,0,0,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M595.11,278.73a.45.45,0,0,1,.1-.63.46.46,0,1,1,.53.74A.45.45,0,0,1,595.11,278.73Z"
        ></path>
        <path
          className="cls-3"
          d="M584.48,277.17a.46.46,0,0,1-.53-.74.46.46,0,0,1,.53.74Z"
        ></path>
        <path
          className="cls-3"
          d="M572.55,275.82a.43.43,0,0,1,.1-.59.42.42,0,0,1,.59.09.43.43,0,1,1-.69.5Z"
        ></path>
        <path
          className="cls-3"
          d="M561.19,275a.43.43,0,0,1,.1-.59.42.42,0,0,1,.59.1.41.41,0,0,1-.1.59A.42.42,0,0,1,561.19,275Z"
        ></path>
        <path
          className="cls-3"
          d="M549.79,274.71a.46.46,0,1,1,.74-.53.46.46,0,0,1-.74.53Z"
        ></path>
        <path
          className="cls-3"
          d="M539,274.91a.46.46,0,0,1-.64-.1.46.46,0,1,1,.64.1Z"
        ></path>
        <path
          className="cls-3"
          d="M527.77,274.83a.46.46,0,0,1-.74.53.47.47,0,0,1,.1-.64A.46.46,0,0,1,527.77,274.83Z"
        ></path>
        <path
          className="cls-3"
          d="M515.69,276.34a.45.45,0,0,1,.1-.63.46.46,0,1,1,.53.74A.45.45,0,0,1,515.69,276.34Z"
        ></path>
        <path
          className="cls-3"
          d="M504.51,277.17a.43.43,0,0,1,.6.09.44.44,0,0,1-.1.6.43.43,0,0,1-.5-.69Z"
        ></path>
        <path
          className="cls-3"
          d="M493.78,279.73a.43.43,0,0,1-.59-.1.42.42,0,0,1,.09-.59.43.43,0,0,1,.6.09A.44.44,0,0,1,493.78,279.73Z"
        ></path>
        <path
          className="cls-3"
          d="M482,282a.46.46,0,0,1,.74-.53.46.46,0,1,1-.74.53Z"
        ></path>
        <path
          className="cls-3"
          d="M471.7,284.17a.45.45,0,1,1-.74.52.45.45,0,0,1,.11-.63A.44.44,0,0,1,471.7,284.17Z"
        ></path>
        <path
          className="cls-3"
          d="M460,287.86a.45.45,0,0,1,.73-.53.46.46,0,0,1-.1.64A.45.45,0,0,1,460,287.86Z"
        ></path>
        <path
          className="cls-3"
          d="M450,290.93a.45.45,0,0,1-.1.63.46.46,0,1,1-.53-.74A.45.45,0,0,1,450,290.93Z"
        ></path>
        <circle className="cls-3" cx="438.94" cy="295.21" r="0.45"></circle>
        <path
          className="cls-3"
          d="M428.18,299.26a.46.46,0,1,1-.1.64A.46.46,0,0,1,428.18,299.26Z"
        ></path>
        <path
          className="cls-3"
          d="M418.51,304.2a.46.46,0,0,1-.11.63.45.45,0,0,1-.63-.1.46.46,0,1,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M408.42,309.41a.49.49,0,0,1-.79.57.49.49,0,1,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M398.4,315.71a.49.49,0,0,1-.67-.11.49.49,0,1,1,.79-.57A.49.49,0,0,1,398.4,315.71Z"
        ></path>
        <path
          className="cls-3"
          d="M388.73,321.71a.49.49,0,0,1-.68-.11.49.49,0,1,1,.79-.57A.48.48,0,0,1,388.73,321.71Z"
        ></path>
        <path
          className="cls-3"
          d="M379.41,327.41a.48.48,0,0,1-.79.56.47.47,0,0,1,.11-.67A.48.48,0,0,1,379.41,327.41Z"
        ></path>
        <path
          className="cls-3"
          d="M370.23,334.15a.47.47,0,0,1-.11.67.48.48,0,1,1,.11-.67Z"
        ></path>
        <path
          className="cls-3"
          d="M361.21,341.91a.48.48,0,1,1,.11-.67A.47.47,0,0,1,361.21,341.91Z"
        ></path>
        <path
          className="cls-3"
          d="M352,348.56a.49.49,0,1,1-.11.68A.5.5,0,0,1,352,348.56Z"
        ></path>
        <path
          className="cls-3"
          d="M343.61,357a.45.45,0,0,1,.1-.63.46.46,0,0,1,.64.11.45.45,0,1,1-.74.52Z"
        ></path>
        <path
          className="cls-3"
          d="M336.22,365.17a.45.45,0,0,1-.63-.1.46.46,0,1,1,.74-.53A.45.45,0,0,1,336.22,365.17Z"
        ></path>
        <path
          className="cls-3"
          d="M328.63,372.93a.45.45,0,0,1-.1.63.46.46,0,0,1-.64-.1.45.45,0,0,1,.11-.63A.44.44,0,0,1,328.63,372.93Z"
        ></path>
        <path
          className="cls-3"
          d="M320.51,382.16a.48.48,0,0,1,.11-.68.49.49,0,0,1,.67.11.48.48,0,0,1-.11.68A.47.47,0,0,1,320.51,382.16Z"
        ></path>
        <path
          className="cls-3"
          d="M314.16,391.24a.48.48,0,0,1-.67-.12.48.48,0,1,1,.78-.56A.48.48,0,0,1,314.16,391.24Z"
        ></path>
        <path
          className="cls-3"
          d="M307.58,399.81a.44.44,0,0,1-.1.63.45.45,0,1,1,.1-.63Z"
        ></path>
        <circle className="cls-3" cx="300.92" cy="409.56" r="0.45"></circle>
        <path
          className="cls-3"
          d="M294.73,418.91a.46.46,0,1,1-.1.64A.46.46,0,0,1,294.73,418.91Z"
        ></path>
        <path
          className="cls-3"
          d="M289.82,429a.46.46,0,0,1-.1.64.46.46,0,1,1,.1-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M284.59,439.78a.49.49,0,1,1,.11-.68A.48.48,0,0,1,284.59,439.78Z"
        ></path>
        <path
          className="cls-3"
          d="M279.28,449.34a.47.47,0,0,1,.67.11.48.48,0,0,1-.11.68.49.49,0,0,1-.67-.11A.48.48,0,0,1,279.28,449.34Z"
        ></path>
        <path
          className="cls-3"
          d="M274.88,460.51a.42.42,0,1,1,.59.09A.42.42,0,0,1,274.88,460.51Z"
        ></path>
        <path
          className="cls-3"
          d="M271.66,470.68a.46.46,0,0,1-.1.64.46.46,0,1,1,.1-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M268.18,481.5a.48.48,0,1,1-.67-.11A.47.47,0,0,1,268.18,481.5Z"
        ></path>
        <path
          className="cls-3"
          d="M265.08,492.48a.45.45,0,0,1-.11.63.44.44,0,0,1-.63-.11.45.45,0,0,1,.1-.63A.46.46,0,0,1,265.08,492.48Z"
        ></path>
        <path
          className="cls-3"
          d="M261.79,503.44a.46.46,0,0,1,.63.11.44.44,0,0,1-.1.63.45.45,0,0,1-.63-.1A.46.46,0,0,1,261.79,503.44Z"
        ></path>
        <path
          className="cls-3"
          d="M260.21,514.72a.46.46,0,0,1-.74.53.46.46,0,0,1,.11-.64A.45.45,0,0,1,260.21,514.72Z"
        ></path>
        <circle className="cls-3" cx="258.06" cy="526.23" r="0.45"></circle>
        <path
          className="cls-3"
          d="M257.09,537.27a.45.45,0,0,1-.1.63.46.46,0,1,1-.53-.74A.45.45,0,0,1,257.09,537.27Z"
        ></path>
        <path
          className="cls-3"
          d="M256.19,548.62a.45.45,0,0,1-.73.53.46.46,0,0,1,.1-.64A.45.45,0,0,1,256.19,548.62Z"
        ></path>
        <path
          className="cls-3"
          d="M255.74,560a.45.45,0,0,1-.1.63.46.46,0,0,1-.64-.11.45.45,0,0,1,.11-.63A.44.44,0,0,1,255.74,560Z"
        ></path>
        <path
          className="cls-3"
          d="M255.1,571.28a.45.45,0,1,1,.53.73.46.46,0,0,1-.64-.1A.45.45,0,0,1,255.1,571.28Z"
        ></path>
        <path
          className="cls-3"
          d="M255.53,582.65a.46.46,0,0,1,.64.11.46.46,0,0,1-.74.53A.46.46,0,0,1,255.53,582.65Z"
        ></path>
        <path
          className="cls-3"
          d="M256.28,594.66a.5.5,0,0,1,.11-.68.49.49,0,0,1,.57.79A.5.5,0,0,1,256.28,594.66Z"
        ></path>
        <path
          className="cls-3"
          d="M258.34,605.44a.42.42,0,0,1-.1.59.42.42,0,1,1-.49-.69A.42.42,0,0,1,258.34,605.44Z"
        ></path>
        <circle className="cls-3" cx="259.76" cy="616.93" r="0.45"></circle>
        <path
          className="cls-3"
          d="M262.35,627.82a.49.49,0,0,1-.11.68.49.49,0,1,1-.57-.79A.48.48,0,0,1,262.35,627.82Z"
        ></path>
        <path
          className="cls-3"
          d="M265,638.92a.45.45,0,0,1-.11.63.44.44,0,0,1-.63-.11.45.45,0,0,1,.1-.63A.46.46,0,0,1,265,638.92Z"
        ></path>
        <path
          className="cls-3"
          d="M268,649.88a.45.45,0,0,1-.1.63.46.46,0,0,1-.64-.1.46.46,0,0,1,.74-.53Z"
        ></path>
        <circle
          className="cls-3"
          cx="271.14"
          cy="660.98"
          r="0.48"
          transform="translate(-334.63 282.22) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M275.42,671.41a.46.46,0,0,1-.11.64.46.46,0,0,1-.53-.74A.46.46,0,0,1,275.42,671.41Z"
        ></path>
        <path
          className="cls-3"
          d="M279.74,682a.45.45,0,0,1-.1.63.46.46,0,1,1-.53-.74A.45.45,0,0,1,279.74,682Z"
        ></path>
        <path
          className="cls-3"
          d="M284.49,692.28a.48.48,0,0,1-.11.68.47.47,0,0,1-.67-.11.48.48,0,1,1,.78-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M289.63,702.45a.48.48,0,0,1-.79.56.47.47,0,0,1,.11-.67A.48.48,0,0,1,289.63,702.45Z"
        ></path>
        <path
          className="cls-3"
          d="M295.15,712.41a.47.47,0,0,1-.11.67.48.48,0,0,1-.68-.11.5.5,0,0,1,.11-.68A.49.49,0,0,1,295.15,712.41Z"
        ></path>
        <path
          className="cls-3"
          d="M301.06,722.14a.48.48,0,0,1-.79.56.48.48,0,0,1,.79-.56Z"
        ></path>
        <path
          className="cls-3"
          d="M307.34,731.63a.48.48,0,0,1-.11.68.5.5,0,0,1-.68-.11.49.49,0,0,1,.79-.57Z"
        ></path>
        <path
          className="cls-3"
          d="M314,740.88a.47.47,0,0,1-.11.67.48.48,0,0,1-.68-.11.5.5,0,0,1,.11-.68A.49.49,0,0,1,314,740.88Z"
        ></path>
        <path
          className="cls-3"
          d="M321,749.85a.48.48,0,0,1-.11.68.49.49,0,0,1-.67-.11.48.48,0,0,1,.11-.68A.47.47,0,0,1,321,749.85Z"
        ></path>
        <path
          className="cls-3"
          d="M328.34,758.55a.48.48,0,1,1-.67-.11A.47.47,0,0,1,328.34,758.55Z"
        ></path>
        <path
          className="cls-3"
          d="M336,767a.49.49,0,0,1-.79.57A.49.49,0,0,1,336,767Z"
        ></path>
        <path
          className="cls-3"
          d="M344,775.06a.46.46,0,0,1-.11.64.46.46,0,0,1-.53-.74A.46.46,0,0,1,344,775.06Z"
        ></path>
        <path
          className="cls-3"
          d="M352.32,782.84a.46.46,0,1,1-.74.53.46.46,0,0,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M360.91,790.31a.42.42,0,1,1-.59-.1A.43.43,0,0,1,360.91,790.31Z"
        ></path>
        <path
          className="cls-3"
          d="M369.83,797.4a.46.46,0,0,1-.74.53.46.46,0,0,1,.11-.64A.45.45,0,0,1,369.83,797.4Z"
        ></path>
        <circle className="cls-3" cx="378.63" cy="804.41" r="0.45"></circle>
        <path
          className="cls-3"
          d="M388.42,810.54a.46.46,0,1,1-.64-.1A.46.46,0,0,1,388.42,810.54Z"
        ></path>
        <path
          className="cls-3"
          d="M398.08,816.56a.45.45,0,1,1-.73.53.45.45,0,1,1,.73-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M408,822.21a.43.43,0,0,1-.69.5.43.43,0,0,1,.1-.59A.42.42,0,0,1,408,822.21Z"
        ></path>
        <path
          className="cls-3"
          d="M412.41,838.85a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M422.7,843.7a.39.39,0,0,1-.09.55.38.38,0,0,1-.54-.09.39.39,0,0,1,.09-.55A.39.39,0,0,1,422.7,843.7Z"
        ></path>
        <path
          className="cls-3"
          d="M433.15,848.19a.36.36,0,1,1-.59.42.36.36,0,0,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M443.83,852.23a.42.42,0,1,1-.59-.1A.42.42,0,0,1,443.83,852.23Z"
        ></path>
        <path
          className="cls-3"
          d="M454.59,855.92a.39.39,0,1,1-.64.46.39.39,0,1,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M464.94,859.1a.39.39,0,0,1,.55.09.38.38,0,0,1-.09.54.39.39,0,0,1-.55-.09A.39.39,0,0,1,464.94,859.1Z"
        ></path>
        <path
          className="cls-3"
          d="M476.54,862a.42.42,0,0,1-.69.49.42.42,0,1,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M487.63,864.49a.39.39,0,1,1-.63.45.39.39,0,0,1,.63-.45Z"
        ></path>
        <path
          className="cls-3"
          d="M498.84,866.51a.4.4,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,498.84,866.51Z"
        ></path>
        <path
          className="cls-3"
          d="M510.11,868.12a.39.39,0,0,1-.09.55.41.41,0,0,1-.55-.09.4.4,0,0,1,.09-.55A.39.39,0,0,1,510.11,868.12Z"
        ></path>
        <path
          className="cls-3"
          d="M521.43,869.3a.39.39,0,1,1-.54-.09A.38.38,0,0,1,521.43,869.3Z"
        ></path>
        <path
          className="cls-3"
          d="M532.8,870.06a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M543.63,870.3a.4.4,0,0,1,.55.09.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M555.45,870.82a.35.35,0,0,1-.5-.08.36.36,0,1,1,.59-.42A.36.36,0,0,1,555.45,870.82Z"
        ></path>
        <path
          className="cls-3"
          d="M566.94,869.78a.41.41,0,0,1-.09.55.39.39,0,1,1,.09-.55Z"
        ></path>
        <path
          className="cls-3"
          d="M578.28,868.84a.39.39,0,0,1-.09.55.39.39,0,0,1-.46-.64A.39.39,0,0,1,578.28,868.84Z"
        ></path>
        <path
          className="cls-3"
          d="M589.61,867.45a.43.43,0,1,1-.59-.09A.42.42,0,0,1,589.61,867.45Z"
        ></path>
        <path
          className="cls-3"
          d="M600.85,865.67a.42.42,0,0,1-.09.59.43.43,0,0,1-.59-.1.42.42,0,1,1,.68-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M612,863.47a.4.4,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,612,863.47Z"
        ></path>
        <path
          className="cls-3"
          d="M623.08,860.85a.4.4,0,0,1-.09.55.39.39,0,1,1,.09-.55Z"
        ></path>
        <path
          className="cls-3"
          d="M633.5,857.72a.4.4,0,0,1,.55.1.38.38,0,0,1-.09.54.39.39,0,0,1-.55-.09A.41.41,0,0,1,633.5,857.72Z"
        ></path>
        <circle className="cls-3" cx="644.58" cy="854.6" r="0.39"></circle>
        <path
          className="cls-3"
          d="M655.62,850.52a.39.39,0,1,1-.55-.09A.41.41,0,0,1,655.62,850.52Z"
        ></path>
        <path
          className="cls-3"
          d="M665.63,846.19a.39.39,0,1,1,.46.64.39.39,0,0,1-.46-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M676.58,841.64a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,676.58,841.64Z"
        ></path>
        <path
          className="cls-3"
          d="M686.82,836.6a.42.42,0,1,1-.59-.1A.42.42,0,0,1,686.82,836.6Z"
        ></path>
        <path
          className="cls-3"
          d="M696.82,831.21a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M706.64,825.44a.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64A.4.4,0,0,1,706.64,825.44Z"
        ></path>
        <path
          className="cls-3"
          d="M715.68,819.22a.39.39,0,0,1,.55.09.39.39,0,0,1-.09.54.39.39,0,0,1-.55-.09A.38.38,0,0,1,715.68,819.22Z"
        ></path>
        <path
          className="cls-3"
          d="M725.49,813.36a.4.4,0,0,1-.55-.08.41.41,0,0,1,.09-.55.39.39,0,0,1,.55.09A.38.38,0,0,1,725.49,813.36Z"
        ></path>
        <path
          className="cls-3"
          d="M734.6,806.53a.4.4,0,0,1-.55-.09.39.39,0,0,1,.64-.46A.41.41,0,0,1,734.6,806.53Z"
        ></path>
        <path
          className="cls-3"
          d="M743.53,798.81a.39.39,0,0,1-.09.55.4.4,0,0,1-.55-.09.41.41,0,0,1,.09-.55A.39.39,0,0,1,743.53,798.81Z"
        ></path>
        <path
          className="cls-3"
          d="M752.1,791.31a.39.39,0,1,1-.64.46.4.4,0,0,1,.09-.55A.41.41,0,0,1,752.1,791.31Z"
        ></path>
        <path
          className="cls-3"
          d="M760.31,784.07a.42.42,0,1,1,.1-.59A.43.43,0,0,1,760.31,784.07Z"
        ></path>
        <path
          className="cls-3"
          d="M768.37,775.38a.4.4,0,0,1-.09.55.39.39,0,0,1-.46-.64A.41.41,0,0,1,768.37,775.38Z"
        ></path>
        <path
          className="cls-3"
          d="M775.41,767.43A.39.39,0,0,1,776,767a.39.39,0,1,1-.63.45Z"
        ></path>
        <path
          className="cls-3"
          d="M783.4,758.29a.38.38,0,0,1-.09.54.39.39,0,0,1-.55-.09.39.39,0,0,1,.09-.54A.39.39,0,0,1,783.4,758.29Z"
        ></path>
        <path
          className="cls-3"
          d="M789.81,749.77a.37.37,0,0,1,.09-.51.35.35,0,0,1,.5.08.37.37,0,0,1-.59.43Z"
        ></path>
        <path
          className="cls-3"
          d="M796.48,740.57a.4.4,0,0,1,.08-.55.39.39,0,1,1,.46.64A.38.38,0,0,1,796.48,740.57Z"
        ></path>
        <path
          className="cls-3"
          d="M802.84,731.09a.35.35,0,0,1,.08-.5.36.36,0,1,1,.42.59A.36.36,0,0,1,802.84,731.09Z"
        ></path>
        <path
          className="cls-3"
          d="M808.82,721.4a.35.35,0,0,1,.08-.5.36.36,0,1,1,.42.59A.36.36,0,0,1,808.82,721.4Z"
        ></path>
        <path
          className="cls-3"
          d="M815.07,711a.43.43,0,0,1-.1.59.42.42,0,1,1-.49-.69A.43.43,0,0,1,815.07,711Z"
        ></path>
        <path
          className="cls-3"
          d="M819.64,701.41a.4.4,0,0,1,.09-.55.39.39,0,0,1,.46.64A.41.41,0,0,1,819.64,701.41Z"
        ></path>
        <path
          className="cls-3"
          d="M825,691.17a.36.36,0,1,1-.42-.59.36.36,0,0,1,.42.59Z"
        ></path>
        <path
          className="cls-3"
          d="M829.06,680.09a.39.39,0,0,1,.55.09.39.39,0,1,1-.64.46A.39.39,0,0,1,829.06,680.09Z"
        ></path>
        <path
          className="cls-3"
          d="M833.59,670.09a.39.39,0,0,1-.46-.64.41.41,0,0,1,.55.09A.4.4,0,0,1,833.59,670.09Z"
        ></path>
        <path
          className="cls-3"
          d="M836.74,659.21a.37.37,0,0,1,.08-.51.38.38,0,0,1,.51.08.37.37,0,0,1-.09.51A.35.35,0,0,1,836.74,659.21Z"
        ></path>
        <path
          className="cls-3"
          d="M840.53,648.41a.39.39,0,1,1,.09-.55A.39.39,0,0,1,840.53,648.41Z"
        ></path>
        <path
          className="cls-3"
          d="M842.84,637.3a.39.39,0,0,1,.64-.46.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M845.28,626.18a.39.39,0,1,1,.55.09A.41.41,0,0,1,845.28,626.18Z"
        ></path>
        <path
          className="cls-3"
          d="M847.33,615a.37.37,0,1,1,.51.08A.37.37,0,0,1,847.33,615Z"
        ></path>
        <path
          className="cls-3"
          d="M848.91,603.7a.4.4,0,0,1,.09-.55.41.41,0,0,1,.55.09.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M850.09,592.38a.41.41,0,0,1,.09-.55.39.39,0,0,1,.46.64A.41.41,0,0,1,850.09,592.38Z"
        ></path>
        <path
          className="cls-3"
          d="M850.88,581a.36.36,0,1,1,.59-.42.36.36,0,0,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M851.72,569.7a.37.37,0,1,1,.08-.5A.36.36,0,0,1,851.72,569.7Z"
        ></path>
        <path
          className="cls-3"
          d="M851.64,558.34a.39.39,0,0,1-.55-.09.39.39,0,1,1,.64-.46A.39.39,0,0,1,851.64,558.34Z"
        ></path>
        <path
          className="cls-3"
          d="M851.12,547a.41.41,0,0,1-.55-.09.4.4,0,0,1,.1-.55.38.38,0,0,1,.54.09A.39.39,0,0,1,851.12,547Z"
        ></path>
        <path
          className="cls-3"
          d="M849.66,535.51a.35.35,0,0,1,.08-.5.36.36,0,1,1,.42.59A.36.36,0,0,1,849.66,535.51Z"
        ></path>
        <path
          className="cls-3"
          d="M848.26,524.23a.4.4,0,0,1,.1-.55.38.38,0,0,1,.54.09.39.39,0,0,1-.09.55A.41.41,0,0,1,848.26,524.23Z"
        ></path>
        <path
          className="cls-3"
          d="M846.45,513a.43.43,0,0,1,.1-.59.42.42,0,1,1,.49.69A.43.43,0,0,1,846.45,513Z"
        ></path>
        <path
          className="cls-3"
          d="M844.29,501.8a.37.37,0,0,1,.09-.51.35.35,0,0,1,.5.08.37.37,0,1,1-.59.43Z"
        ></path>
        <path
          className="cls-3"
          d="M841.64,490.73a.4.4,0,0,1,.1-.55.39.39,0,0,1,.54.09.39.39,0,1,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M838.61,479.76a.39.39,0,0,1,.09-.55.39.39,0,0,1,.46.64A.39.39,0,0,1,838.61,479.76Z"
        ></path>
        <path
          className="cls-3"
          d="M835.19,468.89a.36.36,0,1,1,.59-.42.35.35,0,0,1-.08.5A.37.37,0,0,1,835.19,468.89Z"
        ></path>
        <circle className="cls-3" cx="831.64" cy="457.96" r="0.39"></circle>
        <path
          className="cls-3"
          d="M827.07,447.63a.4.4,0,0,1,.09-.55.39.39,0,1,1,.46.64A.41.41,0,0,1,827.07,447.63Z"
        ></path>
        <path
          className="cls-3"
          d="M822.43,437.23a.41.41,0,0,1,.09-.55.4.4,0,0,1,.55.09.39.39,0,1,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M817.39,427a.42.42,0,1,1,.59.1A.43.43,0,0,1,817.39,427Z"
        ></path>
        <path
          className="cls-3"
          d="M812,417a.39.39,0,0,1,.64-.46.41.41,0,0,1-.09.55A.39.39,0,0,1,812,417Z"
        ></path>
        <path
          className="cls-3"
          d="M806.24,407.18a.39.39,0,0,1,.09-.55.38.38,0,0,1,.54.09.39.39,0,0,1-.09.55A.39.39,0,0,1,806.24,407.18Z"
        ></path>
        <path
          className="cls-3"
          d="M800.1,397.58a.38.38,0,0,1,.09-.54.39.39,0,0,1,.46.64A.4.4,0,0,1,800.1,397.58Z"
        ></path>
        <path
          className="cls-3"
          d="M793.61,388.23a.39.39,0,0,1,.09-.55.4.4,0,0,1,.55.09.39.39,0,1,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M786.77,379.12a.39.39,0,0,1,.09-.54.39.39,0,0,1,.55.09.38.38,0,0,1-.09.54A.39.39,0,0,1,786.77,379.12Z"
        ></path>
        <path
          className="cls-3"
          d="M779.6,370.28a.39.39,0,0,1,.64-.46.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M772.11,361.71a.39.39,0,0,1,.09-.55.39.39,0,1,1,.46.64A.39.39,0,0,1,772.11,361.71Z"
        ></path>
        <path
          className="cls-3"
          d="M764.27,353.45a.43.43,0,0,1,.69-.5.43.43,0,0,1-.1.59A.42.42,0,0,1,764.27,353.45Z"
        ></path>
        <path
          className="cls-3"
          d="M756.18,345.44a.39.39,0,0,1,.09-.55.41.41,0,0,1,.55.09.4.4,0,0,1-.09.55A.39.39,0,0,1,756.18,345.44Z"
        ></path>
        <path
          className="cls-3"
          d="M747.77,337.77a.39.39,0,1,1,.55.09A.41.41,0,0,1,747.77,337.77Z"
        ></path>
        <path
          className="cls-3"
          d="M739.08,330.41a.39.39,0,0,1,.09-.55.41.41,0,0,1,.55.09.39.39,0,1,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M730.12,323.38a.38.38,0,0,1,.09-.54.39.39,0,0,1,.55.09.38.38,0,0,1-.09.54A.39.39,0,0,1,730.12,323.38Z"
        ></path>
        <path
          className="cls-3"
          d="M720.91,316.7a.39.39,0,0,1,.09-.55.38.38,0,0,1,.54.09.39.39,0,1,1-.63.46Z"
        ></path>
        <path
          className="cls-3"
          d="M711.45,310.36a.39.39,0,0,1,.09-.55.41.41,0,0,1,.55.09.4.4,0,0,1-.1.55A.38.38,0,0,1,711.45,310.36Z"
        ></path>
        <path
          className="cls-3"
          d="M701.76,304.38a.39.39,0,0,1,.09-.55.39.39,0,0,1,.46.64A.39.39,0,0,1,701.76,304.38Z"
        ></path>
        <path
          className="cls-3"
          d="M691.83,298.79a.42.42,0,1,1,.59.09A.42.42,0,0,1,691.83,298.79Z"
        ></path>
        <path
          className="cls-3"
          d="M681.74,293.53a.4.4,0,0,1,.09-.55.41.41,0,0,1,.55.09.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M671.44,288.68a.41.41,0,0,1,.09-.55.39.39,0,1,1,.46.64A.41.41,0,0,1,671.44,288.68Z"
        ></path>
        <path
          className="cls-3"
          d="M661,284.21a.4.4,0,0,1,.09-.55.39.39,0,1,1,.46.64A.41.41,0,0,1,661,284.21Z"
        ></path>
        <path
          className="cls-3"
          d="M650.36,280.12a.37.37,0,0,1,.09-.51.35.35,0,0,1,.5.08.37.37,0,1,1-.59.43Z"
        ></path>
        <path
          className="cls-3"
          d="M639.56,276.46a.4.4,0,0,1,.09-.55.41.41,0,0,1,.55.09.39.39,0,1,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M628.63,273.21a.42.42,0,1,1,.59.1A.43.43,0,0,1,628.63,273.21Z"
        ></path>
        <path
          className="cls-3"
          d="M617.63,270.34a.41.41,0,0,1,.09-.55.39.39,0,1,1,.46.64A.41.41,0,0,1,617.63,270.34Z"
        ></path>
        <path
          className="cls-3"
          d="M606.51,267.89a.39.39,0,1,1,.64-.46.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M595.31,265.87a.39.39,0,0,1,.64-.46.4.4,0,0,1-.09.55A.41.41,0,0,1,595.31,265.87Z"
        ></path>
        <path
          className="cls-3"
          d="M584,264.26a.39.39,0,0,1,.09-.55.39.39,0,0,1,.46.64A.39.39,0,0,1,584,264.26Z"
        ></path>
        <path
          className="cls-3"
          d="M572.69,263.1a.43.43,0,0,1,.69-.5.43.43,0,0,1-.1.59A.42.42,0,0,1,572.69,263.1Z"
        ></path>
        <circle className="cls-3" cx="561.67" cy="262.09" r="0.39"></circle>
        <path
          className="cls-3"
          d="M550,262a.4.4,0,0,1,.09-.55.39.39,0,1,1,.46.64A.41.41,0,0,1,550,262Z"
        ></path>
        <path
          className="cls-3"
          d="M539.15,262.19a.42.42,0,0,1-.59-.09.43.43,0,0,1,.1-.59.42.42,0,1,1,.49.68Z"
        ></path>
        <path
          className="cls-3"
          d="M527.21,262.6a.41.41,0,0,1,.09-.55.39.39,0,1,1,.46.64A.4.4,0,0,1,527.21,262.6Z"
        ></path>
        <path
          className="cls-3"
          d="M516.41,263.63a.38.38,0,0,1-.54-.09A.39.39,0,0,1,516,263a.41.41,0,0,1,.55.09A.4.4,0,0,1,516.41,263.63Z"
        ></path>
        <path
          className="cls-3"
          d="M505.11,265a.39.39,0,1,1-.46-.64.39.39,0,0,1,.46.64Z"
        ></path>
        <path
          className="cls-3"
          d="M494,266.22a.43.43,0,0,1-.09.59.43.43,0,1,1,.09-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M482.81,268.43a.42.42,0,0,1-.09.59.43.43,0,1,1-.5-.69A.42.42,0,0,1,482.81,268.43Z"
        ></path>
        <path
          className="cls-3"
          d="M471,271.55a.44.44,0,0,1,.1-.6.43.43,0,0,1,.59.1.42.42,0,0,1-.09.59A.43.43,0,0,1,471,271.55Z"
        ></path>
        <path
          className="cls-3"
          d="M460.07,274.58a.43.43,0,0,1,.1-.59.42.42,0,0,1,.49.69A.43.43,0,0,1,460.07,274.58Z"
        ></path>
        <path
          className="cls-3"
          d="M449.22,278a.43.43,0,0,1,.69-.5.43.43,0,0,1-.1.59A.42.42,0,0,1,449.22,278Z"
        ></path>
        <path
          className="cls-3"
          d="M438.5,281.87a.42.42,0,0,1,.69-.49.41.41,0,0,1-.1.59A.42.42,0,0,1,438.5,281.87Z"
        ></path>
        <path
          className="cls-3"
          d="M428.6,285.64a.39.39,0,0,1-.64.46.39.39,0,1,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M417.57,290.74a.39.39,0,0,1,.09-.55.38.38,0,0,1,.54.09.39.39,0,0,1-.09.55A.38.38,0,0,1,417.57,290.74Z"
        ></path>
        <path
          className="cls-3"
          d="M408,295.29a.42.42,0,0,1-.1.59.42.42,0,1,1-.49-.69A.42.42,0,0,1,408,295.29Z"
        ></path>
        <path
          className="cls-3"
          d="M397.89,301.28a.42.42,0,1,1,.1-.59A.42.42,0,0,1,397.89,301.28Z"
        ></path>
        <path
          className="cls-3"
          d="M387.49,307a.42.42,0,0,1,.09-.59.43.43,0,0,1,.59.1.42.42,0,0,1-.09.59A.41.41,0,0,1,387.49,307Z"
        ></path>
        <path
          className="cls-3"
          d="M378.49,313.19a.43.43,0,0,1-.59-.1.42.42,0,1,1,.59.1Z"
        ></path>
        <path
          className="cls-3"
          d="M369.23,319.09a.42.42,0,1,1-.59-.1A.42.42,0,0,1,369.23,319.09Z"
        ></path>
        <path
          className="cls-3"
          d="M360.12,325.92a.42.42,0,1,1-.59-.09A.42.42,0,0,1,360.12,325.92Z"
        ></path>
        <path
          className="cls-3"
          d="M351.28,333.09a.43.43,0,0,1-.69.5.43.43,0,0,1,.1-.59A.42.42,0,0,1,351.28,333.09Z"
        ></path>
        <path
          className="cls-3"
          d="M342,341.08a.42.42,0,1,1,.69-.49.42.42,0,0,1-.69.49Z"
        ></path>
        <path
          className="cls-3"
          d="M334.43,348.4a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.09.43.43,0,1,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M326.42,356.54a.39.39,0,0,1-.09.54.39.39,0,0,1-.55-.09.38.38,0,0,1,.09-.54A.39.39,0,0,1,326.42,356.54Z"
        ></path>
        <path
          className="cls-3"
          d="M318.74,364.94a.39.39,0,1,1-.64.46.39.39,0,1,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M311.43,373.6a.45.45,0,1,1-.63-.1A.45.45,0,0,1,311.43,373.6Z"
        ></path>
        <circle className="cls-3" cx="304.04" cy="382.82" r="0.45"></circle>
        <path
          className="cls-3"
          d="M297.7,391.79a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.09.43.43,0,1,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M291.36,401.25a.42.42,0,0,1-.1.59.41.41,0,0,1-.59-.1.42.42,0,1,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M285.38,410.94a.42.42,0,0,1-.69.49.42.42,0,0,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M279.74,420.86a.39.39,0,0,1-.09.55.38.38,0,0,1-.54-.09.39.39,0,0,1,.09-.55A.39.39,0,0,1,279.74,420.86Z"
        ></path>
        <path
          className="cls-3"
          d="M274.53,431a.42.42,0,0,1-.1.59.42.42,0,1,1-.49-.69A.42.42,0,0,1,274.53,431Z"
        ></path>
        <path
          className="cls-3"
          d="M269.08,441.16a.42.42,0,1,1-.09.59A.42.42,0,0,1,269.08,441.16Z"
        ></path>
        <path
          className="cls-3"
          d="M265.09,452.29a.39.39,0,0,1-.55-.09.39.39,0,0,1,.64-.46A.39.39,0,0,1,265.09,452.29Z"
        ></path>
        <path
          className="cls-3"
          d="M261.13,462.36a.42.42,0,0,1-.09.59.43.43,0,1,1-.5-.69A.43.43,0,0,1,261.13,462.36Z"
        ></path>
        <path
          className="cls-3"
          d="M257.44,473.15a.4.4,0,0,1-.1.55.38.38,0,0,1-.54-.09.39.39,0,0,1,.09-.55A.41.41,0,0,1,257.44,473.15Z"
        ></path>
        <path
          className="cls-3"
          d="M254.19,484a.42.42,0,0,1-.09.59.43.43,0,0,1-.6-.09.44.44,0,0,1,.1-.6A.43.43,0,0,1,254.19,484Z"
        ></path>
        <path
          className="cls-3"
          d="M251.34,495.06a.43.43,0,0,1-.1.59.42.42,0,1,1-.5-.68A.43.43,0,0,1,251.34,495.06Z"
        ></path>
        <path
          className="cls-3"
          d="M248.87,506.2a.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64A.4.4,0,0,1,248.87,506.2Z"
        ></path>
        <path
          className="cls-3"
          d="M246.87,517.39a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.1.41.41,0,0,1,.1-.59A.42.42,0,0,1,246.87,517.39Z"
        ></path>
        <path
          className="cls-3"
          d="M245.24,528.68a.39.39,0,0,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M244.08,540a.43.43,0,1,1-.59-.09A.42.42,0,0,1,244.08,540Z"
        ></path>
        <path
          className="cls-3"
          d="M243.32,551.34a.43.43,0,1,1-.69.5.43.43,0,0,1,.1-.59A.42.42,0,0,1,243.32,551.34Z"
        ></path>
        <path
          className="cls-3"
          d="M243,562.73a.42.42,0,0,1-.69.49.42.42,0,0,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M243.08,574.11a.42.42,0,0,1-.69.49.42.42,0,1,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M243,585.41a.4.4,0,0,1,.55.09.39.39,0,0,1-.64.46A.41.41,0,0,1,243,585.41Z"
        ></path>
        <path
          className="cls-3"
          d="M244.44,597.42a.42.42,0,0,1-.59-.09.44.44,0,0,1,.1-.6.43.43,0,0,1,.59.1A.42.42,0,0,1,244.44,597.42Z"
        ></path>
        <path
          className="cls-3"
          d="M245.91,608.13a.44.44,0,0,1-.1.6.43.43,0,0,1-.59-.1.43.43,0,0,1,.69-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M247.67,619.4a.38.38,0,0,1-.09.54.4.4,0,0,1-.55-.08.41.41,0,0,1,.09-.55A.39.39,0,0,1,247.67,619.4Z"
        ></path>
        <path
          className="cls-3"
          d="M249.9,630.55a.42.42,0,1,1-.59-.1A.42.42,0,0,1,249.9,630.55Z"
        ></path>
        <path
          className="cls-3"
          d="M252.53,641.63a.43.43,0,0,1-.1.59.42.42,0,1,1,.1-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M255.56,652.6a.42.42,0,0,1-.09.59.43.43,0,1,1-.5-.69A.43.43,0,0,1,255.56,652.6Z"
        ></path>
        <path
          className="cls-3"
          d="M259,663.44a.45.45,0,0,1-.1.63.46.46,0,0,1-.53-.74A.45.45,0,0,1,259,663.44Z"
        ></path>
        <path
          className="cls-3"
          d="M262.88,674.15a.45.45,0,0,1-.1.63.46.46,0,0,1-.64-.1.46.46,0,0,1,.74-.53Z"
        ></path>
        <path
          className="cls-3"
          d="M267.08,684.75a.41.41,0,0,1-.09.55.39.39,0,1,1,.09-.55Z"
        ></path>
        <path
          className="cls-3"
          d="M271.71,695.15a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,271.71,695.15Z"
        ></path>
        <path
          className="cls-3"
          d="M276.76,705.35a.43.43,0,0,1-.09.59.43.43,0,1,1-.5-.69A.43.43,0,0,1,276.76,705.35Z"
        ></path>
        <path
          className="cls-3"
          d="M282.16,715.37a.42.42,0,0,1-.68.5.42.42,0,1,1,.68-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M287.94,725.19a.42.42,0,1,1-.69.49.42.42,0,0,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M294.07,734.78a.43.43,0,0,1-.09.59.44.44,0,0,1-.6-.1.43.43,0,0,1,.1-.59A.42.42,0,0,1,294.07,734.78Z"
        ></path>
        <path
          className="cls-3"
          d="M300.56,744.13a.42.42,0,0,1-.09.59.43.43,0,0,1-.6-.09.44.44,0,0,1,.1-.6A.43.43,0,0,1,300.56,744.13Z"
        ></path>
        <path
          className="cls-3"
          d="M307.4,753.24a.42.42,0,0,1-.69.49.42.42,0,1,1,.69-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M314.57,762.08a.43.43,0,0,1-.1.59A.42.42,0,0,1,314,762,.43.43,0,0,1,314.57,762.08Z"
        ></path>
        <path
          className="cls-3"
          d="M322.07,770.65a.43.43,0,0,1-.1.59.43.43,0,0,1-.5-.69A.44.44,0,0,1,322.07,770.65Z"
        ></path>
        <path
          className="cls-3"
          d="M329.88,778.93a.43.43,0,0,1-.1.59.42.42,0,1,1,.1-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M338,786.94a.39.39,0,0,1-.09.55.4.4,0,0,1-.55-.1.38.38,0,0,1,.09-.54A.39.39,0,0,1,338,786.94Z"
        ></path>
        <path
          className="cls-3"
          d="M346.38,794.61a.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,346.38,794.61Z"
        ></path>
        <path
          className="cls-3"
          d="M355.09,802a.42.42,0,1,1-.59-.1A.43.43,0,0,1,355.09,802Z"
        ></path>
        <path
          className="cls-3"
          d="M364.05,809a.41.41,0,0,1-.1.59.42.42,0,0,1-.49-.69A.42.42,0,0,1,364.05,809Z"
        ></path>
        <path
          className="cls-3"
          d="M373.27,815.66a.43.43,0,0,1-.1.59.42.42,0,1,1,.1-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M382.7,822a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,382.7,822Z"
        ></path>
        <path
          className="cls-3"
          d="M392.42,828a.43.43,0,0,1-.1.59.42.42,0,0,1-.59-.1.43.43,0,0,1,.09-.59A.44.44,0,0,1,392.42,828Z"
        ></path>
        <path
          className="cls-3"
          d="M402.32,833.59a.43.43,0,0,1-.69.5.44.44,0,0,1,.1-.6A.43.43,0,0,1,402.32,833.59Z"
        ></path>
        <path
          className="cls-3"
          d="M406.78,850.22a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,406.78,850.22Z"
        ></path>
        <path
          className="cls-3"
          d="M417.05,855.1a.36.36,0,1,1-.59.42.36.36,0,0,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M427.51,859.59a.37.37,0,0,1-.08.51.36.36,0,1,1-.42-.59A.35.35,0,0,1,427.51,859.59Z"
        ></path>
        <path
          className="cls-3"
          d="M438.11,863.72a.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08.32.32,0,0,1,.07-.46A.34.34,0,0,1,438.11,863.72Z"
        ></path>
        <path
          className="cls-3"
          d="M448.89,867.43a.37.37,0,1,1-.51-.08A.37.37,0,0,1,448.89,867.43Z"
        ></path>
        <path
          className="cls-3"
          d="M459.27,870.69a.35.35,0,0,1,.5.08.37.37,0,1,1-.5-.08Z"
        ></path>
        <path
          className="cls-3"
          d="M470.77,873.72a.37.37,0,1,1-.51-.08A.37.37,0,0,1,470.77,873.72Z"
        ></path>
        <path
          className="cls-3"
          d="M481.84,876.29a.34.34,0,0,1-.07.47.35.35,0,0,1-.47-.08.34.34,0,0,1,.08-.47A.33.33,0,0,1,481.84,876.29Z"
        ></path>
        <path
          className="cls-3"
          d="M493.05,878.42a.37.37,0,0,1-.09.51.35.35,0,0,1-.5-.08.37.37,0,0,1,.08-.51A.38.38,0,0,1,493.05,878.42Z"
        ></path>
        <path
          className="cls-3"
          d="M503.79,880.09a.37.37,0,0,1,.51.08.38.38,0,0,1-.08.51.37.37,0,0,1-.43-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M515.58,881.53a.34.34,0,0,1-.08.47.33.33,0,1,1,.08-.47Z"
        ></path>
        <path
          className="cls-3"
          d="M526.44,882.37a.37.37,0,1,1-.08.51A.37.37,0,0,1,526.44,882.37Z"
        ></path>
        <path
          className="cls-3"
          d="M537.82,882.9a.37.37,0,0,1,.51.08.38.38,0,0,1-.09.51.37.37,0,0,1-.51-.08A.38.38,0,0,1,537.82,882.9Z"
        ></path>
        <path
          className="cls-3"
          d="M549.71,883.1a.37.37,0,1,1-.51-.08A.37.37,0,0,1,549.71,883.1Z"
        ></path>
        <path
          className="cls-3"
          d="M561.09,882.82a.36.36,0,0,1-.08.5.36.36,0,1,1-.42-.59A.35.35,0,0,1,561.09,882.82Z"
        ></path>
        <path
          className="cls-3"
          d="M571.87,882.54a.36.36,0,1,1,.59-.42.36.36,0,0,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M583.76,881a.34.34,0,0,1-.07.47.35.35,0,0,1-.47-.08.33.33,0,0,1,.08-.46A.32.32,0,0,1,583.76,881Z"
        ></path>
        <path
          className="cls-3"
          d="M595.05,879.52a.33.33,0,1,1-.46-.08A.34.34,0,0,1,595.05,879.52Z"
        ></path>
        <path
          className="cls-3"
          d="M606.3,877.59a.36.36,0,0,1-.09.51.36.36,0,0,1-.42-.59A.37.37,0,0,1,606.3,877.59Z"
        ></path>
        <path
          className="cls-3"
          d="M616.94,875.19a.37.37,0,0,1,.51.08.38.38,0,0,1-.09.51.35.35,0,0,1-.5-.08A.37.37,0,0,1,616.94,875.19Z"
        ></path>
        <path
          className="cls-3"
          d="M628.5,872.56a.35.35,0,0,1-.08.5.36.36,0,1,1,.08-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M638.87,869.87a.36.36,0,1,1,.59-.42.36.36,0,0,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M649.78,865.86a.37.37,0,0,1,.51.09.35.35,0,0,1-.08.5.37.37,0,0,1-.43-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M660.48,862a.37.37,0,1,1-.08.5A.35.35,0,0,1,660.48,862Z"
        ></path>
        <path
          className="cls-3"
          d="M671,857.71a.36.36,0,1,1,.42.59.36.36,0,0,1-.42-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M681.94,853.14a.37.37,0,1,1-.51-.08A.37.37,0,0,1,681.94,853.14Z"
        ></path>
        <path
          className="cls-3"
          d="M691.64,848a.39.39,0,0,1,.55.09.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M702.17,842.77a.33.33,0,0,1-.07.46.34.34,0,0,1-.47-.08.33.33,0,1,1,.54-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M712,837a.33.33,0,0,1-.08.46.32.32,0,0,1-.46-.07.34.34,0,0,1,.07-.47A.35.35,0,0,1,712,837Z"
        ></path>
        <path
          className="cls-3"
          d="M721.63,830.94a.34.34,0,0,1-.08.47.33.33,0,1,1-.38-.54A.33.33,0,0,1,721.63,830.94Z"
        ></path>
        <path
          className="cls-3"
          d="M730.49,824.91a.34.34,0,0,1,.08-.47.33.33,0,0,1,.38.54A.32.32,0,0,1,730.49,824.91Z"
        ></path>
        <path
          className="cls-3"
          d="M739.65,818.15a.34.34,0,0,1,.08-.47.33.33,0,1,1,.38.54A.33.33,0,0,1,739.65,818.15Z"
        ></path>
        <path
          className="cls-3"
          d="M748.56,811.06a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,748.56,811.06Z"
        ></path>
        <path
          className="cls-3"
          d="M757.29,803.2a.34.34,0,0,1,.47.07.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08A.32.32,0,0,1,757.29,803.2Z"
        ></path>
        <path
          className="cls-3"
          d="M765.67,795.49a.34.34,0,0,1,.47.08.33.33,0,0,1-.08.46.33.33,0,0,1-.46-.07A.34.34,0,0,1,765.67,795.49Z"
        ></path>
        <path
          className="cls-3"
          d="M773.67,788a.37.37,0,0,1,.09-.51.36.36,0,0,1,.42.59A.37.37,0,0,1,773.67,788Z"
        ></path>
        <path
          className="cls-3"
          d="M782.07,779.26a.36.36,0,1,1-.59.42.35.35,0,0,1,.08-.5A.37.37,0,0,1,782.07,779.26Z"
        ></path>
        <path
          className="cls-3"
          d="M789.58,770.7a.37.37,0,0,1-.6.42.38.38,0,0,1,.09-.51A.37.37,0,0,1,789.58,770.7Z"
        ></path>
        <path
          className="cls-3"
          d="M796.2,762.28a.34.34,0,0,1,.08-.47.33.33,0,0,1,.46.08.32.32,0,0,1-.07.46A.34.34,0,0,1,796.2,762.28Z"
        ></path>
        <path
          className="cls-3"
          d="M803.07,753.2a.35.35,0,0,1,.08-.47.33.33,0,0,1,.46.08.32.32,0,0,1-.07.46A.34.34,0,0,1,803.07,753.2Z"
        ></path>
        <path
          className="cls-3"
          d="M809.67,743.39a.37.37,0,1,1-.08.51A.37.37,0,0,1,809.67,743.39Z"
        ></path>
        <path
          className="cls-3"
          d="M815.89,733.87a.34.34,0,0,1,.47.07.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08A.32.32,0,0,1,815.89,733.87Z"
        ></path>
        <path
          className="cls-3"
          d="M821.67,724.57a.34.34,0,0,1,.08-.47.33.33,0,0,1,.39.54A.34.34,0,0,1,821.67,724.57Z"
        ></path>
        <path
          className="cls-3"
          d="M827.17,714.6a.35.35,0,0,1,.08-.47.33.33,0,0,1,.46.08.32.32,0,0,1-.07.46A.34.34,0,0,1,827.17,714.6Z"
        ></path>
        <path
          className="cls-3"
          d="M832.34,704.42a.3.3,0,0,1,.49-.35.31.31,0,0,1-.07.42A.29.29,0,0,1,832.34,704.42Z"
        ></path>
        <path
          className="cls-3"
          d="M837.06,694.12a.37.37,0,1,1,.51.08A.37.37,0,0,1,837.06,694.12Z"
        ></path>
        <path
          className="cls-3"
          d="M841.54,683.11a.36.36,0,1,1,.42.59.35.35,0,0,1-.5-.08A.37.37,0,0,1,841.54,683.11Z"
        ></path>
        <path
          className="cls-3"
          d="M846,672.56a.33.33,0,1,1-.46-.08A.33.33,0,0,1,846,672.56Z"
        ></path>
        <path
          className="cls-3"
          d="M849.13,662.15a.33.33,0,1,1,.54-.38.33.33,0,0,1-.07.46A.34.34,0,0,1,849.13,662.15Z"
        ></path>
        <path
          className="cls-3"
          d="M852.86,651.34a.37.37,0,1,1,.08-.51A.37.37,0,0,1,852.86,651.34Z"
        ></path>
        <path
          className="cls-3"
          d="M855.22,640.22a.35.35,0,0,1,.08-.47.33.33,0,0,1,.39.54A.34.34,0,0,1,855.22,640.22Z"
        ></path>
        <path
          className="cls-3"
          d="M857.67,629.1a.34.34,0,1,1,.55-.39.34.34,0,0,1-.55.39Z"
        ></path>
        <path
          className="cls-3"
          d="M859.7,617.92a.37.37,0,1,1,.51.08A.37.37,0,0,1,859.7,617.92Z"
        ></path>
        <path
          className="cls-3"
          d="M861.37,606.63a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.33.33,0,1,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M862.61,595.31a.33.33,0,1,1,.47.08A.34.34,0,0,1,862.61,595.31Z"
        ></path>
        <path
          className="cls-3"
          d="M863.43,584a.37.37,0,1,1,.5.08A.35.35,0,0,1,863.43,584Z"
        ></path>
        <path
          className="cls-3"
          d="M863.88,572.58a.32.32,0,0,1,.07-.46.33.33,0,1,1,.39.54A.32.32,0,0,1,863.88,572.58Z"
        ></path>
        <path
          className="cls-3"
          d="M864.36,561.27a.33.33,0,0,1-.46-.07.33.33,0,1,1,.46.07Z"
        ></path>
        <path
          className="cls-3"
          d="M864,549.92a.37.37,0,0,1-.51-.09.35.35,0,0,1,.08-.5.37.37,0,0,1,.43.59Z"
        ></path>
        <path
          className="cls-3"
          d="M863.19,538.56a.37.37,0,1,1,.08-.51A.37.37,0,0,1,863.19,538.56Z"
        ></path>
        <path
          className="cls-3"
          d="M861.59,526.7a.31.31,0,0,1,.43.07.31.31,0,1,1-.43-.07Z"
        ></path>
        <path
          className="cls-3"
          d="M859.89,515.87a.33.33,0,1,1,.54-.39.33.33,0,0,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M857.87,504.66a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,857.87,504.66Z"
        ></path>
        <path
          className="cls-3"
          d="M855.46,493.53a.32.32,0,0,1,.07-.46.33.33,0,1,1,.39.54A.32.32,0,0,1,855.46,493.53Z"
        ></path>
        <path
          className="cls-3"
          d="M852.62,482.52a.36.36,0,1,1,.59-.42.36.36,0,0,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M849.41,471.59a.35.35,0,0,1,.08-.5.37.37,0,1,1-.08.5Z"
        ></path>
        <path
          className="cls-3"
          d="M845.84,460.77a.32.32,0,0,1,.07-.46.33.33,0,1,1,.39.54A.32.32,0,0,1,845.84,460.77Z"
        ></path>
        <path
          className="cls-3"
          d="M841.83,450.13a.37.37,0,1,1,.51.08A.37.37,0,0,1,841.83,450.13Z"
        ></path>
        <path
          className="cls-3"
          d="M837.46,439.61a.37.37,0,0,1,.09-.51.36.36,0,0,1,.42.59A.37.37,0,0,1,837.46,439.61Z"
        ></path>
        <path
          className="cls-3"
          d="M832.73,429.26a.37.37,0,1,1,.59-.43.38.38,0,0,1-.09.51A.35.35,0,0,1,832.73,429.26Z"
        ></path>
        <path
          className="cls-3"
          d="M827.64,419.06a.33.33,0,0,1,.08-.46.33.33,0,0,1,.39.54A.34.34,0,0,1,827.64,419.06Z"
        ></path>
        <path
          className="cls-3"
          d="M822.17,409.08a.35.35,0,0,1,.08-.47.33.33,0,0,1,.46.08.32.32,0,0,1-.07.46A.34.34,0,0,1,822.17,409.08Z"
        ></path>
        <path
          className="cls-3"
          d="M816.35,399.29a.33.33,0,1,1,.46.08A.33.33,0,0,1,816.35,399.29Z"
        ></path>
        <path
          className="cls-3"
          d="M810.18,389.73a.33.33,0,1,1,.54-.39.33.33,0,0,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M803.67,380.39a.33.33,0,1,1,.46.07A.32.32,0,0,1,803.67,380.39Z"
        ></path>
        <path
          className="cls-3"
          d="M796.82,371.29a.35.35,0,0,1,.08-.47.33.33,0,1,1,.39.54A.34.34,0,0,1,796.82,371.29Z"
        ></path>
        <path
          className="cls-3"
          d="M789.66,362.44a.34.34,0,0,1,.08-.47.33.33,0,1,1-.08.47Z"
        ></path>
        <path
          className="cls-3"
          d="M782.18,353.85a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,782.18,353.85Z"
        ></path>
        <path
          className="cls-3"
          d="M774.35,345.57a.39.39,0,1,1,.64-.46.39.39,0,0,1-.64.46Z"
        ></path>
        <path
          className="cls-3"
          d="M766.3,337.53a.37.37,0,0,1,.09-.51.35.35,0,0,1,.5.08.37.37,0,1,1-.59.43Z"
        ></path>
        <path
          className="cls-3"
          d="M758,329.8a.37.37,0,1,1,.5.08A.35.35,0,0,1,758,329.8Z"
        ></path>
        <path
          className="cls-3"
          d="M749.32,322.37a.37.37,0,1,1,.5.08A.35.35,0,0,1,749.32,322.37Z"
        ></path>
        <path
          className="cls-3"
          d="M740.43,315.26a.37.37,0,0,1,.59-.43.37.37,0,0,1-.09.51A.36.36,0,0,1,740.43,315.26Z"
        ></path>
        <path
          className="cls-3"
          d="M731.29,308.47a.37.37,0,0,1,.59-.43.37.37,0,0,1-.09.51A.36.36,0,0,1,731.29,308.47Z"
        ></path>
        <path
          className="cls-3"
          d="M721.91,302a.37.37,0,1,1,.51.08A.37.37,0,0,1,721.91,302Z"
        ></path>
        <path
          className="cls-3"
          d="M712.3,295.89a.37.37,0,0,1,.6-.42.38.38,0,0,1-.09.51A.37.37,0,0,1,712.3,295.89Z"
        ></path>
        <path
          className="cls-3"
          d="M702.49,290.12a.36.36,0,0,1,.08-.5.37.37,0,0,1,.51.08.38.38,0,0,1-.08.51A.36.36,0,0,1,702.49,290.12Z"
        ></path>
        <path
          className="cls-3"
          d="M692.49,284.7a.35.35,0,0,1,.08-.47.33.33,0,1,1-.08.47Z"
        ></path>
        <path
          className="cls-3"
          d="M682.29,279.65a.33.33,0,1,1,.54-.39.33.33,0,0,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M671.88,275a.38.38,0,0,1,.09-.51.37.37,0,0,1,.51.08.38.38,0,0,1-.09.51A.37.37,0,0,1,671.88,275Z"
        ></path>
        <path
          className="cls-3"
          d="M661.34,270.68a.36.36,0,0,1,.59-.42.36.36,0,1,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M650.65,266.76a.36.36,0,0,1,.59-.42.36.36,0,1,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M639.83,263.23a.38.38,0,0,1,.08-.51.37.37,0,1,1-.08.51Z"
        ></path>
        <path
          className="cls-3"
          d="M628.89,260.08a.37.37,0,0,1,.08-.51.38.38,0,0,1,.51.09.35.35,0,0,1-.08.5A.37.37,0,0,1,628.89,260.08Z"
        ></path>
        <path
          className="cls-3"
          d="M618.41,256.93a.33.33,0,1,1-.47-.08A.34.34,0,0,1,618.41,256.93Z"
        ></path>
        <path
          className="cls-3"
          d="M606.7,255a.36.36,0,1,1,.59-.42.35.35,0,0,1-.08.5A.37.37,0,0,1,606.7,255Z"
        ></path>
        <path
          className="cls-3"
          d="M595.48,253a.37.37,0,0,1,.09-.51.36.36,0,0,1,.42.59A.37.37,0,0,1,595.48,253Z"
        ></path>
        <path
          className="cls-3"
          d="M584.23,251.47a.33.33,0,1,1,.46.07A.32.32,0,0,1,584.23,251.47Z"
        ></path>
        <path
          className="cls-3"
          d="M572.87,250.34a.36.36,0,0,1,.59-.42.36.36,0,1,1-.59.42Z"
        ></path>
        <path
          className="cls-3"
          d="M561.51,249.61a.36.36,0,1,1,.59-.42.35.35,0,0,1-.08.5A.37.37,0,0,1,561.51,249.61Z"
        ></path>
        <path
          className="cls-3"
          d="M550.13,249.29a.37.37,0,1,1,.51.08A.37.37,0,0,1,550.13,249.29Z"
        ></path>
        <path
          className="cls-3"
          d="M539.23,249.43a.33.33,0,1,1,.08-.47A.34.34,0,0,1,539.23,249.43Z"
        ></path>
        <path
          className="cls-3"
          d="M527.45,249.35a.38.38,0,0,1,.51.09.35.35,0,0,1-.08.5.37.37,0,1,1-.43-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M516.53,250.85a.37.37,0,0,1-.51-.09.35.35,0,0,1,.08-.5.37.37,0,0,1,.43.59Z"
        ></path>
        <path
          className="cls-3"
          d="M505.22,252.15a.37.37,0,1,1,.08-.5A.35.35,0,0,1,505.22,252.15Z"
        ></path>
        <path
          className="cls-3"
          d="M493.54,253.28a.35.35,0,0,1,.5.08.36.36,0,1,1-.59.42A.36.36,0,0,1,493.54,253.28Z"
        ></path>
        <path
          className="cls-3"
          d="M482.33,255.37a.39.39,0,1,1,.46.64.41.41,0,0,1-.55-.09A.4.4,0,0,1,482.33,255.37Z"
        ></path>
        <path
          className="cls-3"
          d="M471.14,258.43a.39.39,0,0,1,.09-.55.39.39,0,0,1,.46.64A.39.39,0,0,1,471.14,258.43Z"
        ></path>
        <path
          className="cls-3"
          d="M460.24,260.82a.37.37,0,0,1,.51.08.38.38,0,0,1-.09.51.37.37,0,0,1-.51-.08A.38.38,0,0,1,460.24,260.82Z"
        ></path>
        <circle
          className="cls-3"
          cx="449.55"
          cy="264.42"
          r="0.36"
          transform="translate(-69.84 311.84) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M439.08,267.9a.37.37,0,1,1-.51-.08A.38.38,0,0,1,439.08,267.9Z"
        ></path>
        <path
          className="cls-3"
          d="M428.45,272a.37.37,0,1,1-.51-.08A.37.37,0,0,1,428.45,272Z"
        ></path>
        <path
          className="cls-3"
          d="M417.47,276.36a.35.35,0,0,1,.5.08.37.37,0,1,1-.5-.08Z"
        ></path>
        <path
          className="cls-3"
          d="M407.16,281.19a.35.35,0,0,1,.5.08.37.37,0,1,1-.5-.08Z"
        ></path>
        <path
          className="cls-3"
          d="M397,286.36a.41.41,0,0,1,.55.09.4.4,0,0,1-.09.55.39.39,0,1,1-.46-.64Z"
        ></path>
        <path
          className="cls-3"
          d="M387.53,292.56a.39.39,0,0,1-.55-.09.39.39,0,1,1,.64-.46A.39.39,0,0,1,387.53,292.56Z"
        ></path>
        <path
          className="cls-3"
          d="M377.8,298.47a.39.39,0,0,1-.55-.09.39.39,0,0,1,.09-.54.39.39,0,0,1,.55.09A.38.38,0,0,1,377.8,298.47Z"
        ></path>
        <path
          className="cls-3"
          d="M368.29,304.73a.39.39,0,1,1-.46-.64.41.41,0,0,1,.55.09A.4.4,0,0,1,368.29,304.73Z"
        ></path>
        <path
          className="cls-3"
          d="M359.1,310.78a.39.39,0,0,1-.64.46.39.39,0,1,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M350.06,317.7a.39.39,0,1,1-.55-.09A.39.39,0,0,1,350.06,317.7Z"
        ></path>
        <path
          className="cls-3"
          d="M341.27,324.94a.39.39,0,0,1-.09.55.38.38,0,0,1-.54-.09.39.39,0,0,1,.09-.55A.39.39,0,0,1,341.27,324.94Z"
        ></path>
        <path
          className="cls-3"
          d="M332.21,332.41a.39.39,0,0,1,.55.09.39.39,0,1,1-.64.46A.39.39,0,0,1,332.21,332.41Z"
        ></path>
        <path
          className="cls-3"
          d="M324,340.29a.37.37,0,1,1-.08.5A.35.35,0,0,1,324,340.29Z"
        ></path>
        <path
          className="cls-3"
          d="M316.45,349a.37.37,0,0,1-.51-.08.38.38,0,0,1,.09-.51.37.37,0,0,1,.51.09A.36.36,0,0,1,316.45,349Z"
        ></path>
        <path
          className="cls-3"
          d="M308.88,356.94a.37.37,0,0,1-.09.51.35.35,0,0,1-.5-.08.37.37,0,1,1,.59-.43Z"
        ></path>
        <path
          className="cls-3"
          d="M301,365.53a.39.39,0,1,1-.09.55A.41.41,0,0,1,301,365.53Z"
        ></path>
        <path
          className="cls-3"
          d="M294,374.51a.35.35,0,0,1,.5.08.37.37,0,1,1-.5-.08Z"
        ></path>
        <path
          className="cls-3"
          d="M287.79,383.79a.37.37,0,0,1-.09.51.35.35,0,0,1-.5-.08.37.37,0,0,1,.59-.43Z"
        ></path>
        <path
          className="cls-3"
          d="M281.41,393.23a.36.36,0,1,1-.5-.08A.35.35,0,0,1,281.41,393.23Z"
        ></path>
        <path
          className="cls-3"
          d="M275.41,402.87a.39.39,0,0,1-.09.55.41.41,0,0,1-.55-.09.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M269.71,412.75a.37.37,0,0,1-.09.51.35.35,0,0,1-.5-.08.37.37,0,1,1,.59-.43Z"
        ></path>
        <path
          className="cls-3"
          d="M264.41,422.8a.4.4,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,264.41,422.8Z"
        ></path>
        <path
          className="cls-3"
          d="M259.43,433.07a.36.36,0,1,1-.59.42.35.35,0,0,1,.08-.5A.37.37,0,0,1,259.43,433.07Z"
        ></path>
        <path
          className="cls-3"
          d="M254.76,444a.36.36,0,1,1-.42-.59.35.35,0,0,1,.5.08A.37.37,0,0,1,254.76,444Z"
        ></path>
        <path
          className="cls-3"
          d="M250.64,454.07a.38.38,0,0,1-.09.51.35.35,0,0,1-.5-.08.37.37,0,0,1,.59-.43Z"
        ></path>
        <path
          className="cls-3"
          d="M246.81,464.8a.35.35,0,0,1-.08.5.37.37,0,1,1,.08-.5Z"
        ></path>
        <circle
          className="cls-3"
          cx="243.08"
          cy="475.86"
          r="0.36"
          transform="translate(-231.92 231.1) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M240.35,486.6a.39.39,0,1,1-.55-.09A.41.41,0,0,1,240.35,486.6Z"
        ></path>
        <path
          className="cls-3"
          d="M237.67,497.69a.37.37,0,1,1-.5-.08A.35.35,0,0,1,237.67,497.69Z"
        ></path>
        <circle
          className="cls-3"
          cx="235.13"
          cy="509.07"
          r="0.36"
          transform="translate(-252.78 232.7) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M233.07,520a.36.36,0,0,1,.5.08.36.36,0,1,1-.59.42A.36.36,0,0,1,233.07,520Z"
        ></path>
        <path
          className="cls-3"
          d="M231.62,531.3a.37.37,0,0,1,.51.08.36.36,0,0,1-.09.51.35.35,0,0,1-.5-.08A.37.37,0,0,1,231.62,531.3Z"
        ></path>
        <path
          className="cls-3"
          d="M231.11,542.7a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M230.46,554.09a.37.37,0,0,1-.09.51A.36.36,0,0,1,230,554,.37.37,0,0,1,230.46,554.09Z"
        ></path>
        <path
          className="cls-3"
          d="M230.23,565.47a.36.36,0,1,1-.58.43.37.37,0,0,1,.08-.51A.35.35,0,0,1,230.23,565.47Z"
        ></path>
        <path
          className="cls-3"
          d="M230.42,576.86a.35.35,0,0,1-.08.5.37.37,0,0,1-.51-.08.36.36,0,0,1,.09-.51A.36.36,0,0,1,230.42,576.86Z"
        ></path>
        <path
          className="cls-3"
          d="M230.51,588.14a.37.37,0,0,1,.51.09.36.36,0,0,1-.59.42A.37.37,0,0,1,230.51,588.14Z"
        ></path>
        <path
          className="cls-3"
          d="M231.94,600.08a.37.37,0,0,1-.51-.09.35.35,0,0,1,.08-.5.37.37,0,1,1,.43.59Z"
        ></path>
        <path
          className="cls-3"
          d="M233.45,610.85a.39.39,0,0,1-.09.55.4.4,0,0,1-.55-.09.41.41,0,0,1,.09-.55A.39.39,0,0,1,233.45,610.85Z"
        ></path>
        <path
          className="cls-3"
          d="M235.24,622.11a.35.35,0,0,1-.08.5.37.37,0,1,1,.08-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M237.48,633.26a.39.39,0,0,1-.09.55.39.39,0,0,1-.46-.64A.39.39,0,0,1,237.48,633.26Z"
        ></path>
        <path
          className="cls-3"
          d="M240.07,644.36a.35.35,0,0,1-.08.5.36.36,0,1,1-.42-.59A.36.36,0,0,1,240.07,644.36Z"
        ></path>
        <path
          className="cls-3"
          d="M243.09,655.34a.37.37,0,0,1-.6.42.38.38,0,0,1,.09-.51A.37.37,0,0,1,243.09,655.34Z"
        ></path>
        <path
          className="cls-3"
          d="M246.49,666.2a.37.37,0,1,1-.51-.08A.37.37,0,0,1,246.49,666.2Z"
        ></path>
        <path
          className="cls-3"
          d="M250.3,676.92a.39.39,0,1,1-.54-.09A.38.38,0,0,1,250.3,676.92Z"
        ></path>
        <path
          className="cls-3"
          d="M254.45,687.53a.36.36,0,1,1-.59.42.36.36,0,0,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M259,698a.36.36,0,0,1-.59.42A.36.36,0,1,1,259,698Z"
        ></path>
        <path
          className="cls-3"
          d="M264,708.22a.39.39,0,0,1-.09.54.39.39,0,1,1-.46-.64A.4.4,0,0,1,264,708.22Z"
        ></path>
        <path
          className="cls-3"
          d="M269.24,718.3a.39.39,0,0,1-.09.55.39.39,0,0,1-.54-.09.39.39,0,0,1,.09-.55A.38.38,0,0,1,269.24,718.3Z"
        ></path>
        <path
          className="cls-3"
          d="M274.89,728.18a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M280.89,737.86a.39.39,0,1,1-.64.46.39.39,0,0,1,.64-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M287.23,747.32a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,287.23,747.32Z"
        ></path>
        <path
          className="cls-3"
          d="M293.91,756.54a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,293.91,756.54Z"
        ></path>
        <circle className="cls-3" cx="300.6" cy="765.74" r="0.39"></circle>
        <path
          className="cls-3"
          d="M308.24,774.23a.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,308.24,774.23Z"
        ></path>
        <path
          className="cls-3"
          d="M315.87,782.68a.41.41,0,0,1-.09.55.39.39,0,0,1-.55-.09.38.38,0,0,1,.09-.54A.4.4,0,0,1,315.87,782.68Z"
        ></path>
        <path
          className="cls-3"
          d="M323.77,790.87a.37.37,0,1,1-.5-.08A.36.36,0,0,1,323.77,790.87Z"
        ></path>
        <path
          className="cls-3"
          d="M332,798.76a.35.35,0,0,1-.09.5.36.36,0,0,1-.5-.08.36.36,0,1,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M340.48,806.34a.35.35,0,0,1-.08.5.36.36,0,1,1-.42-.59A.36.36,0,0,1,340.48,806.34Z"
        ></path>
        <path
          className="cls-3"
          d="M349.24,813.61a.36.36,0,0,1-.58.42.36.36,0,0,1,.08-.51A.36.36,0,0,1,349.24,813.61Z"
        ></path>
        <path
          className="cls-3"
          d="M358.26,820.56a.36.36,0,1,1-.59.42.35.35,0,0,1,.08-.5A.37.37,0,0,1,358.26,820.56Z"
        ></path>
        <path
          className="cls-3"
          d="M367.52,827.18a.37.37,0,1,1-.51-.08A.37.37,0,0,1,367.52,827.18Z"
        ></path>
        <path
          className="cls-3"
          d="M377,833.45a.41.41,0,0,1-.09.55.39.39,0,1,1-.46-.64A.41.41,0,0,1,377,833.45Z"
        ></path>
        <path
          className="cls-3"
          d="M386.75,839.4a.39.39,0,0,1-.09.55.39.39,0,1,1-.46-.64A.39.39,0,0,1,386.75,839.4Z"
        ></path>
        <path
          className="cls-3"
          d="M396.64,845a.37.37,0,0,1-.59.43.38.38,0,0,1,.09-.51A.35.35,0,0,1,396.64,845Z"
        ></path>
        <path
          className="cls-3"
          d="M401.11,861.62a.33.33,0,1,1-.54.39.32.32,0,0,1,.07-.46A.34.34,0,0,1,401.11,861.62Z"
        ></path>
        <path
          className="cls-3"
          d="M411.4,866.5a.33.33,0,1,1-.54.38.33.33,0,1,1,.54-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M421.83,871a.3.3,0,0,1-.07.42.31.31,0,0,1-.42-.07.3.3,0,0,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M432.43,875.18a.3.3,0,0,1-.07.42.31.31,0,0,1-.42-.07.3.3,0,1,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M443.19,878.94a.32.32,0,0,1-.07.46.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47A.33.33,0,0,1,443.19,878.94Z"
        ></path>
        <path
          className="cls-3"
          d="M454,882.36a.31.31,0,0,1-.07.43.31.31,0,0,1-.42-.07.3.3,0,0,1,.49-.36Z"
        ></path>
        <path
          className="cls-3"
          d="M465.06,885.36a.37.37,0,0,1-.09.51.36.36,0,0,1-.42-.59A.37.37,0,0,1,465.06,885.36Z"
        ></path>
        <path
          className="cls-3"
          d="M476.08,888.05a.3.3,0,1,1-.42-.07A.3.3,0,0,1,476.08,888.05Z"
        ></path>
        <path
          className="cls-3"
          d="M487.24,890.31a.31.31,0,0,1-.07.43.31.31,0,0,1-.42-.07.31.31,0,0,1,.07-.43A.31.31,0,0,1,487.24,890.31Z"
        ></path>
        <path
          className="cls-3"
          d="M498.49,892.18a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,498.49,892.18Z"
        ></path>
        <path
          className="cls-3"
          d="M509.76,893.69a.31.31,0,0,1-.07.42.3.3,0,0,1-.36-.49A.31.31,0,0,1,509.76,893.69Z"
        ></path>
        <path
          className="cls-3"
          d="M521.09,894.79a.3.3,0,0,1-.07.42.3.3,0,1,1,.07-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M532.48,895.48a.33.33,0,0,1-.08.46.32.32,0,0,1-.46-.07.34.34,0,0,1,.07-.47A.35.35,0,0,1,532.48,895.48Z"
        ></path>
        <path
          className="cls-3"
          d="M543.34,896.17a.31.31,0,0,1,.07-.42.3.3,0,0,1,.42.06.31.31,0,0,1-.07.43A.31.31,0,0,1,543.34,896.17Z"
        ></path>
        <path
          className="cls-3"
          d="M555.17,896.18a.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47.33.33,0,0,1,.46.08A.32.32,0,0,1,555.17,896.18Z"
        ></path>
        <path
          className="cls-3"
          d="M566.6,895.27a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,566.6,895.27Z"
        ></path>
        <path
          className="cls-3"
          d="M578,894.41a.3.3,0,1,1-.42-.07A.3.3,0,0,1,578,894.41Z"
        ></path>
        <path
          className="cls-3"
          d="M589.24,893.17a.27.27,0,1,1-.38-.06A.27.27,0,0,1,589.24,893.17Z"
        ></path>
        <path
          className="cls-3"
          d="M600.53,891.51a.3.3,0,1,1-.42-.07A.31.31,0,0,1,600.53,891.51Z"
        ></path>
        <path
          className="cls-3"
          d="M611.76,889.46a.34.34,0,0,1-.08.47.33.33,0,1,1,.08-.47Z"
        ></path>
        <path
          className="cls-3"
          d="M622.89,887.05a.33.33,0,0,1-.08.46.33.33,0,0,1-.46-.07.33.33,0,1,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M633.92,884.25a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,633.92,884.25Z"
        ></path>
        <path
          className="cls-3"
          d="M644.39,881a.34.34,0,1,1,.39.55.34.34,0,0,1-.39-.55Z"
        ></path>
        <path
          className="cls-3"
          d="M655.62,877.55a.27.27,0,1,1-.38-.06A.27.27,0,0,1,655.62,877.55Z"
        ></path>
        <path
          className="cls-3"
          d="M666.34,873.61a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,666.34,873.61Z"
        ></path>
        <path
          className="cls-3"
          d="M676.46,869.25a.3.3,0,1,1-.07.42A.3.3,0,0,1,676.46,869.25Z"
        ></path>
        <path
          className="cls-3"
          d="M686.85,864.6a.29.29,0,0,1,.42.07.3.3,0,0,1-.06.42.31.31,0,0,1-.43-.07A.31.31,0,0,1,686.85,864.6Z"
        ></path>
        <path
          className="cls-3"
          d="M697,860a.35.35,0,0,1,.08-.47.33.33,0,0,1,.46.08.32.32,0,0,1-.07.46A.34.34,0,0,1,697,860Z"
        ></path>
        <path
          className="cls-3"
          d="M707.55,854.3a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,707.55,854.3Z"
        ></path>
        <path
          className="cls-3"
          d="M717.4,848.6a.3.3,0,0,1-.07.42.3.3,0,0,1-.42-.07.3.3,0,1,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M726.63,842.49a.3.3,0,1,1-.07.42A.29.29,0,0,1,726.63,842.49Z"
        ></path>
        <path
          className="cls-3"
          d="M736.07,836.12a.3.3,0,1,1-.07.42A.3.3,0,0,1,736.07,836.12Z"
        ></path>
        <path
          className="cls-3"
          d="M745.21,829.85a.31.31,0,0,1,.07-.42.29.29,0,0,1,.42.07.3.3,0,0,1-.06.42A.31.31,0,0,1,745.21,829.85Z"
        ></path>
        <path
          className="cls-3"
          d="M754.19,822.85a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.31.31,0,0,1-.07.43A.31.31,0,0,1,754.19,822.85Z"
        ></path>
        <path
          className="cls-3"
          d="M763.41,815.18a.31.31,0,0,1-.07.43.31.31,0,0,1-.42-.07.29.29,0,0,1,.07-.42A.3.3,0,0,1,763.41,815.18Z"
        ></path>
        <path
          className="cls-3"
          d="M771.88,807.58a.3.3,0,1,1-.42-.07A.3.3,0,0,1,771.88,807.58Z"
        ></path>
        <path
          className="cls-3"
          d="M780,800.1a.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42.31.31,0,0,1,.42.07A.3.3,0,0,1,780,800.1Z"
        ></path>
        <path
          className="cls-3"
          d="M788,791.51a.3.3,0,1,1-.42-.07A.3.3,0,0,1,788,791.51Z"
        ></path>
        <path
          className="cls-3"
          d="M795.65,783.07a.3.3,0,1,1-.42-.07A.3.3,0,0,1,795.65,783.07Z"
        ></path>
        <path
          className="cls-3"
          d="M803,774.38a.27.27,0,0,1-.06.38.27.27,0,1,1,.06-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M810,765.81a.27.27,0,1,1,.06-.38A.27.27,0,0,1,810,765.81Z"
        ></path>
        <path
          className="cls-3"
          d="M816.79,756.21a.34.34,0,1,1-.55.39.34.34,0,0,1,.55-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M822.74,746.75a.29.29,0,0,1,.42.07.3.3,0,0,1-.06.42.31.31,0,0,1-.43-.07A.31.31,0,0,1,822.74,746.75Z"
        ></path>
        <path
          className="cls-3"
          d="M829.17,737.61a.3.3,0,1,1,.07-.42A.3.3,0,0,1,829.17,737.61Z"
        ></path>
        <path
          className="cls-3"
          d="M835,727.36a.3.3,0,0,1-.07.42.31.31,0,0,1-.42-.07.3.3,0,0,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M839.89,717.68a.3.3,0,1,1,.42.07A.3.3,0,0,1,839.89,717.68Z"
        ></path>
        <path
          className="cls-3"
          d="M845.43,707.12a.32.32,0,0,1-.07.43.31.31,0,0,1-.42-.07.29.29,0,0,1,.07-.42A.3.3,0,0,1,845.43,707.12Z"
        ></path>
        <path
          className="cls-3"
          d="M850.05,697.17a.3.3,0,0,1-.35-.49.3.3,0,0,1,.42.07A.29.29,0,0,1,850.05,697.17Z"
        ></path>
        <path
          className="cls-3"
          d="M854,686.56a.27.27,0,0,1,.06-.38.27.27,0,1,1,.32.44A.27.27,0,0,1,854,686.56Z"
        ></path>
        <path
          className="cls-3"
          d="M858.42,675.55a.3.3,0,1,1-.42-.07A.3.3,0,0,1,858.42,675.55Z"
        ></path>
        <path
          className="cls-3"
          d="M861.52,665.1a.31.31,0,0,1,.07-.42.29.29,0,0,1,.42.07.3.3,0,0,1-.06.42A.32.32,0,0,1,861.52,665.1Z"
        ></path>
        <path
          className="cls-3"
          d="M864.74,654.18a.31.31,0,0,1,.5-.36.31.31,0,1,1-.5.36Z"
        ></path>
        <path
          className="cls-3"
          d="M867.58,643.15a.31.31,0,0,1,.07-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.31.31,0,0,1,867.58,643.15Z"
        ></path>
        <path
          className="cls-3"
          d="M870,632a.31.31,0,0,1,.07-.42.32.32,0,0,1,.43.07.31.31,0,0,1-.07.42A.31.31,0,0,1,870,632Z"
        ></path>
        <path
          className="cls-3"
          d="M872.12,620.84a.3.3,0,1,1,.42.07A.29.29,0,0,1,872.12,620.84Z"
        ></path>
        <path
          className="cls-3"
          d="M873.83,609.56a.27.27,0,0,1,.44-.32.27.27,0,0,1-.06.38A.27.27,0,0,1,873.83,609.56Z"
        ></path>
        <path
          className="cls-3"
          d="M875.1,598.27a.29.29,0,0,1,.07-.42.3.3,0,0,1,.42.06.31.31,0,0,1-.07.43A.31.31,0,0,1,875.1,598.27Z"
        ></path>
        <path
          className="cls-3"
          d="M876,586.92a.29.29,0,0,1,.07-.42.3.3,0,0,1,.42.06.31.31,0,0,1-.07.43A.31.31,0,0,1,876,586.92Z"
        ></path>
        <path
          className="cls-3"
          d="M876.52,575.54a.3.3,0,0,1,.07-.42.3.3,0,0,1,.42.07.3.3,0,1,1-.49.35Z"
        ></path>
        <path
          className="cls-3"
          d="M876.64,564.16a.3.3,0,1,1,.42.07A.3.3,0,0,1,876.64,564.16Z"
        ></path>
        <path
          className="cls-3"
          d="M876.81,552.87a.33.33,0,1,1-.39-.54.33.33,0,0,1,.39.54Z"
        ></path>
        <path
          className="cls-3"
          d="M876.12,541.48a.3.3,0,1,1,.07-.42A.3.3,0,0,1,876.12,541.48Z"
        ></path>
        <path
          className="cls-3"
          d="M875.14,529.72a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,875.14,529.72Z"
        ></path>
        <path
          className="cls-3"
          d="M873.2,518.78a.3.3,0,0,1,.49-.35.3.3,0,0,1-.07.42A.29.29,0,0,1,873.2,518.78Z"
        ></path>
        <path
          className="cls-3"
          d="M871.36,507.54a.3.3,0,1,1,.42.07A.3.3,0,0,1,871.36,507.54Z"
        ></path>
        <path
          className="cls-3"
          d="M869.14,496.38a.31.31,0,0,1,.07-.43.31.31,0,0,1,.35.5A.31.31,0,0,1,869.14,496.38Z"
        ></path>
        <path
          className="cls-3"
          d="M866.53,485.29a.3.3,0,0,1,.07-.42.3.3,0,0,1,.42.07.3.3,0,0,1-.49.35Z"
        ></path>
        <path
          className="cls-3"
          d="M863.52,474.32a.32.32,0,0,1,.07-.46.33.33,0,1,1,.39.54A.32.32,0,0,1,863.52,474.32Z"
        ></path>
        <circle
          className="cls-3"
          cx="860.42"
          cy="463.25"
          r="0.27"
          transform="translate(-108.68 588.81) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M856.46,452.66a.27.27,0,1,1,.38.06A.27.27,0,0,1,856.46,452.66Z"
        ></path>
        <path
          className="cls-3"
          d="M852.32,442.06a.31.31,0,1,1,.43.07A.32.32,0,0,1,852.32,442.06Z"
        ></path>
        <path
          className="cls-3"
          d="M847.85,431.59a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.31.31,0,0,1-.07.43A.31.31,0,0,1,847.85,431.59Z"
        ></path>
        <path
          className="cls-3"
          d="M843,421.28a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.31.31,0,0,1-.07.43A.31.31,0,0,1,843,421.28Z"
        ></path>
        <path
          className="cls-3"
          d="M837.84,411.14a.3.3,0,1,1,.49-.35.3.3,0,0,1-.07.42A.29.29,0,0,1,837.84,411.14Z"
        ></path>
        <path
          className="cls-3"
          d="M832.31,401.19a.3.3,0,0,1,.06-.42.31.31,0,0,1,.43.06.32.32,0,0,1-.07.43A.3.3,0,0,1,832.31,401.19Z"
        ></path>
        <path
          className="cls-3"
          d="M826.43,391.43a.31.31,0,0,1,.07-.42.29.29,0,0,1,.42.07.3.3,0,0,1-.06.42A.31.31,0,0,1,826.43,391.43Z"
        ></path>
        <path
          className="cls-3"
          d="M820.23,381.89a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.31.31,0,0,1-.07.43A.31.31,0,0,1,820.23,381.89Z"
        ></path>
        <path
          className="cls-3"
          d="M813.7,372.56a.3.3,0,1,1,.42.07A.29.29,0,0,1,813.7,372.56Z"
        ></path>
        <path
          className="cls-3"
          d="M806.85,363.46a.3.3,0,1,1,.42.07A.3.3,0,0,1,806.85,363.46Z"
        ></path>
        <path
          className="cls-3"
          d="M799.69,354.61a.3.3,0,1,1,.42.07A.29.29,0,0,1,799.69,354.61Z"
        ></path>
        <path
          className="cls-3"
          d="M792.23,346a.3.3,0,0,1,.49-.36.3.3,0,1,1-.49.36Z"
        ></path>
        <path
          className="cls-3"
          d="M784.46,337.69a.34.34,0,0,1,.07-.47.35.35,0,0,1,.47.08.33.33,0,0,1-.08.46A.32.32,0,0,1,784.46,337.69Z"
        ></path>
        <path
          className="cls-3"
          d="M776.44,329.6a.31.31,0,0,1,.07-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.31.31,0,0,1,776.44,329.6Z"
        ></path>
        <path
          className="cls-3"
          d="M768.14,321.82a.3.3,0,1,1,.49-.35.31.31,0,0,1-.07.42A.31.31,0,0,1,768.14,321.82Z"
        ></path>
        <path
          className="cls-3"
          d="M759.56,314.33a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.29.29,0,0,1-.07.42A.3.3,0,0,1,759.56,314.33Z"
        ></path>
        <path
          className="cls-3"
          d="M750.74,307.13a.3.3,0,1,1,.49-.35.3.3,0,0,1-.07.42A.29.29,0,0,1,750.74,307.13Z"
        ></path>
        <path
          className="cls-3"
          d="M741.64,300.27a.35.35,0,0,1,.08-.47.33.33,0,0,1,.39.54A.34.34,0,0,1,741.64,300.27Z"
        ></path>
        <path
          className="cls-3"
          d="M732.34,293.7a.33.33,0,0,1,.54-.39.33.33,0,1,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M722.82,287.46a.33.33,0,1,1,.54-.39.33.33,0,0,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M713.11,281.53a.3.3,0,1,1,.42.07A.3.3,0,0,1,713.11,281.53Z"
        ></path>
        <path
          className="cls-3"
          d="M703.18,276a.3.3,0,1,1,.42.07A.3.3,0,0,1,703.18,276Z"
        ></path>
        <path
          className="cls-3"
          d="M693.06,270.74a.31.31,0,0,1,.07-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.32.32,0,0,1,693.06,270.74Z"
        ></path>
        <path
          className="cls-3"
          d="M682.77,265.87a.31.31,0,0,1,.07-.42.3.3,0,0,1,.42.06.31.31,0,0,1-.07.43A.31.31,0,0,1,682.77,265.87Z"
        ></path>
        <path
          className="cls-3"
          d="M672.32,261.35a.3.3,0,0,1,.07-.42.3.3,0,0,1,.42.07.3.3,0,1,1-.49.35Z"
        ></path>
        <path
          className="cls-3"
          d="M661.69,257.22a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,661.69,257.22Z"
        ></path>
        <path
          className="cls-3"
          d="M651,253.44A.33.33,0,0,1,651,253a.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,651,253.44Z"
        ></path>
        <path
          className="cls-3"
          d="M640.09,250a.33.33,0,0,1,.54-.39.34.34,0,0,1-.08.47A.33.33,0,0,1,640.09,250Z"
        ></path>
        <path
          className="cls-3"
          d="M629.14,247a.3.3,0,1,1,.42.07A.3.3,0,0,1,629.14,247Z"
        ></path>
        <path
          className="cls-3"
          d="M618.09,244.31a.27.27,0,1,1,.38.07A.27.27,0,0,1,618.09,244.31Z"
        ></path>
        <path
          className="cls-3"
          d="M607.31,242.11a.27.27,0,0,1-.38-.06.28.28,0,1,1,.38.06Z"
        ></path>
        <path
          className="cls-3"
          d="M595.68,240.18a.3.3,0,0,1,.07-.42.3.3,0,0,1,.42.07.3.3,0,0,1-.49.35Z"
        ></path>
        <path
          className="cls-3"
          d="M584.37,238.71a.32.32,0,0,1,.07-.46.34.34,0,0,1,.47.07.35.35,0,0,1-.08.47A.33.33,0,0,1,584.37,238.71Z"
        ></path>
        <path
          className="cls-3"
          d="M573.06,237.59a.3.3,0,1,1,.42.07A.3.3,0,0,1,573.06,237.59Z"
        ></path>
        <path
          className="cls-3"
          d="M561.7,236.88a.3.3,0,0,1,.06-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.29.29,0,0,1,561.7,236.88Z"
        ></path>
        <path
          className="cls-3"
          d="M550.75,236.66a.33.33,0,1,1-.38-.54.33.33,0,0,1,.46.07A.34.34,0,0,1,550.75,236.66Z"
        ></path>
        <path
          className="cls-3"
          d="M539.35,236.71a.3.3,0,1,1,.07-.42A.29.29,0,0,1,539.35,236.71Z"
        ></path>
        <path
          className="cls-3"
          d="M527.55,237.11a.3.3,0,0,1,.07-.42.3.3,0,1,1-.07.42Z"
        ></path>
        <path
          className="cls-3"
          d="M516.17,238a.34.34,0,1,1,.47.08A.35.35,0,0,1,516.17,238Z"
        ></path>
        <path
          className="cls-3"
          d="M504.86,239.24a.32.32,0,0,1,.07-.46.33.33,0,1,1,.39.54A.33.33,0,0,1,504.86,239.24Z"
        ></path>
        <path
          className="cls-3"
          d="M494,240.94a.31.31,0,0,1-.42-.07.29.29,0,0,1,.07-.42.3.3,0,0,1,.42.06A.31.31,0,0,1,494,240.94Z"
        ></path>
        <path
          className="cls-3"
          d="M482.48,242.48a.3.3,0,0,1,.35.49.3.3,0,0,1-.42-.07A.31.31,0,0,1,482.48,242.48Z"
        ></path>
        <path
          className="cls-3"
          d="M471.26,245.33a.33.33,0,0,1,.54-.39.34.34,0,0,1-.07.47A.34.34,0,0,1,471.26,245.33Z"
        ></path>
        <path
          className="cls-3"
          d="M460.74,247.76a.3.3,0,1,1-.42-.07A.29.29,0,0,1,460.74,247.76Z"
        ></path>
        <path
          className="cls-3"
          d="M449.29,251.31a.34.34,0,0,1,.08-.47.34.34,0,0,1,.39.55A.35.35,0,0,1,449.29,251.31Z"
        ></path>
        <path
          className="cls-3"
          d="M438.47,254.86a.34.34,0,0,1,.55-.39.35.35,0,0,1-.08.47A.34.34,0,0,1,438.47,254.86Z"
        ></path>
        <path
          className="cls-3"
          d="M428.35,258.38a.37.37,0,0,1-.08.51.36.36,0,1,1-.42-.59A.36.36,0,0,1,428.35,258.38Z"
        ></path>
        <path
          className="cls-3"
          d="M417.76,262.71a.31.31,0,0,1-.07.42.29.29,0,0,1-.42-.07.3.3,0,0,1,.06-.42A.31.31,0,0,1,417.76,262.71Z"
        ></path>
        <path
          className="cls-3"
          d="M407.37,267.36a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,407.37,267.36Z"
        ></path>
        <path
          className="cls-3"
          d="M397.14,272.37a.3.3,0,1,1-.42-.07A.3.3,0,0,1,397.14,272.37Z"
        ></path>
        <path
          className="cls-3"
          d="M387.09,277.73a.3.3,0,0,1-.49.35.31.31,0,0,1,.07-.42A.29.29,0,0,1,387.09,277.73Z"
        ></path>
        <path
          className="cls-3"
          d="M376.72,283.8a.33.33,0,0,1,.08-.46.32.32,0,0,1,.46.07.34.34,0,0,1-.07.47A.35.35,0,0,1,376.72,283.8Z"
        ></path>
        <path
          className="cls-3"
          d="M367.07,289.84a.33.33,0,1,1,.54-.39.33.33,0,1,1-.54.39Z"
        ></path>
        <path
          className="cls-3"
          d="M358.17,295.82a.32.32,0,0,1-.07.46.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47A.33.33,0,0,1,358.17,295.82Z"
        ></path>
        <path
          className="cls-3"
          d="M349,302.51a.33.33,0,0,1-.54.39.33.33,0,1,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M340,309.52a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,340,309.52Z"
        ></path>
        <path
          className="cls-3"
          d="M331.25,316.83a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,331.25,316.83Z"
        ></path>
        <path
          className="cls-3"
          d="M322.34,324.38a.3.3,0,0,1,.42.07.3.3,0,0,1-.07.42.3.3,0,1,1-.35-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M314.56,332.34a.31.31,0,1,1-.43-.07A.32.32,0,0,1,314.56,332.34Z"
        ></path>
        <path
          className="cls-3"
          d="M306.21,340.45a.3.3,0,0,1,.42.07.3.3,0,0,1-.07.42.3.3,0,1,1-.35-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M298.57,348.89a.3.3,0,0,1,.42.07.31.31,0,0,1-.07.42.3.3,0,1,1-.35-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M291.19,357.54a.36.36,0,0,1,.42.59.36.36,0,1,1-.42-.59Z"
        ></path>
        <path
          className="cls-3"
          d="M284.55,367.06a.33.33,0,0,1-.46-.08.33.33,0,0,1,.54-.39A.34.34,0,0,1,284.55,367.06Z"
        ></path>
        <path
          className="cls-3"
          d="M277.9,375.78a.32.32,0,0,1-.07.46.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47A.33.33,0,0,1,277.9,375.78Z"
        ></path>
        <path
          className="cls-3"
          d="M271.05,385.14a.3.3,0,1,1-.07.42A.31.31,0,0,1,271.05,385.14Z"
        ></path>
        <path
          className="cls-3"
          d="M265.42,394.82a.33.33,0,1,1-.54.39.33.33,0,1,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M259.68,404.65a.34.34,0,0,1-.07.47.34.34,0,0,1-.47-.08.33.33,0,0,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M254.31,414.66a.37.37,0,0,1-.08.51.38.38,0,0,1-.51-.08.37.37,0,0,1,.59-.43Z"
        ></path>
        <path
          className="cls-3"
          d="M249.24,424.88a.35.35,0,0,1-.08.47.33.33,0,0,1-.39-.54A.34.34,0,0,1,249.24,424.88Z"
        ></path>
        <path
          className="cls-3"
          d="M244.08,435.18a.33.33,0,0,1,.39.54.33.33,0,1,1-.39-.54Z"
        ></path>
        <path
          className="cls-3"
          d="M240.21,445.79a.33.33,0,0,1-.08.46.33.33,0,0,1-.46-.07.34.34,0,0,1,.08-.47A.33.33,0,0,1,240.21,445.79Z"
        ></path>
        <path
          className="cls-3"
          d="M236.25,456.46a.34.34,0,0,1-.55.39.34.34,0,1,1,.55-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M232.63,467.28a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,232.63,467.28Z"
        ></path>
        <path
          className="cls-3"
          d="M229.45,478.17a.35.35,0,0,1-.08.5.36.36,0,1,1-.42-.59A.36.36,0,0,1,229.45,478.17Z"
        ></path>
        <path
          className="cls-3"
          d="M226.61,489.19a.37.37,0,1,1-.5-.08A.35.35,0,0,1,226.61,489.19Z"
        ></path>
        <path
          className="cls-3"
          d="M223.66,500.25a.34.34,0,0,1,.47.08.33.33,0,0,1-.08.46.33.33,0,0,1-.46-.07A.34.34,0,0,1,223.66,500.25Z"
        ></path>
        <path
          className="cls-3"
          d="M222,511.54a.3.3,0,1,1-.42-.07A.29.29,0,0,1,222,511.54Z"
        ></path>
        <path
          className="cls-3"
          d="M220.37,522.78a.33.33,0,1,1-.54.39.33.33,0,0,1,.07-.46A.34.34,0,0,1,220.37,522.78Z"
        ></path>
        <path
          className="cls-3"
          d="M219.1,534.08a.36.36,0,0,1-.59.42.36.36,0,1,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M218.19,545.43a.35.35,0,0,1-.08.5.37.37,0,1,1,.08-.5Z"
        ></path>
        <path
          className="cls-3"
          d="M217.63,556.83a.31.31,0,0,1-.07.43.3.3,0,0,1-.42-.07.29.29,0,0,1,.07-.42A.3.3,0,0,1,217.63,556.83Z"
        ></path>
        <path
          className="cls-3"
          d="M217.56,568.19a.36.36,0,0,1-.59.42.36.36,0,1,1,.59-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M217.8,579.59a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,217.8,579.59Z"
        ></path>
        <path
          className="cls-3"
          d="M218,590.88a.32.32,0,0,1,.46.07.33.33,0,1,1-.54.39A.32.32,0,0,1,218,590.88Z"
        ></path>
        <path
          className="cls-3"
          d="M219.45,602.75a.32.32,0,0,1-.46-.07.33.33,0,1,1,.46.07Z"
        ></path>
        <path
          className="cls-3"
          d="M221,613.56a.37.37,0,1,1-.51-.08A.37.37,0,0,1,221,613.56Z"
        ></path>
        <path
          className="cls-3"
          d="M222.81,624.82a.32.32,0,0,1-.07.46.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47A.33.33,0,0,1,222.81,624.82Z"
        ></path>
        <path
          className="cls-3"
          d="M225,636a.35.35,0,0,1-.08.47.33.33,0,0,1-.39-.54A.34.34,0,0,1,225,636Z"
        ></path>
        <path
          className="cls-3"
          d="M227.64,647.07a.32.32,0,0,1-.07.46.34.34,0,0,1-.47-.07.35.35,0,0,1,.08-.47A.32.32,0,0,1,227.64,647.07Z"
        ></path>
        <path
          className="cls-3"
          d="M230.63,658.05a.33.33,0,1,1-.46-.07A.33.33,0,0,1,230.63,658.05Z"
        ></path>
        <path
          className="cls-3"
          d="M234,668.93a.33.33,0,1,1-.54.39.33.33,0,0,1,.07-.46A.34.34,0,0,1,234,668.93Z"
        ></path>
        <path
          className="cls-3"
          d="M237.76,679.67a.35.35,0,0,1-.08.5.36.36,0,1,1-.42-.59A.36.36,0,0,1,237.76,679.67Z"
        ></path>
        <path
          className="cls-3"
          d="M241.82,690.32a.3.3,0,1,1-.42-.07A.3.3,0,0,1,241.82,690.32Z"
        ></path>
        <path
          className="cls-3"
          d="M246.3,700.79a.31.31,0,0,1-.5.36.31.31,0,1,1,.5-.36Z"
        ></path>
        <path
          className="cls-3"
          d="M251.13,711.1a.3.3,0,0,1-.49.36.31.31,0,0,1,.07-.43A.31.31,0,0,1,251.13,711.1Z"
        ></path>
        <path
          className="cls-3"
          d="M256.34,721.22a.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08.32.32,0,0,1,.07-.46A.34.34,0,0,1,256.34,721.22Z"
        ></path>
        <path
          className="cls-3"
          d="M261.87,731.17a.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08.32.32,0,0,1,.07-.46A.34.34,0,0,1,261.87,731.17Z"
        ></path>
        <path
          className="cls-3"
          d="M267.74,740.93a.33.33,0,0,1-.54.39.33.33,0,1,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M273.94,750.48a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,273.94,750.48Z"
        ></path>
        <path
          className="cls-3"
          d="M280.47,759.8a.34.34,0,0,1-.07.47.35.35,0,0,1-.47-.08.33.33,0,0,1,.08-.46A.32.32,0,0,1,280.47,759.8Z"
        ></path>
        <path
          className="cls-3"
          d="M287.32,768.9a.32.32,0,0,1-.07.46.33.33,0,1,1-.39-.54A.32.32,0,0,1,287.32,768.9Z"
        ></path>
        <path
          className="cls-3"
          d="M294.48,777.75a.33.33,0,1,1-.54.39.33.33,0,0,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M301.94,786.35a.34.34,0,0,1-.08.47.33.33,0,1,1,.08-.47Z"
        ></path>
        <path
          className="cls-3"
          d="M309.69,794.69a.34.34,0,0,1-.07.47.35.35,0,0,1-.47-.08.33.33,0,0,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M317.7,802.78a.3.3,0,1,1-.49.35.3.3,0,0,1,.07-.42A.29.29,0,0,1,317.7,802.78Z"
        ></path>
        <path
          className="cls-3"
          d="M326,810.56a.3.3,0,1,1-.42-.07A.3.3,0,0,1,326,810.56Z"
        ></path>
        <path
          className="cls-3"
          d="M334.63,818a.37.37,0,0,1-.08.51.38.38,0,0,1-.51-.09.36.36,0,0,1,.09-.5A.35.35,0,0,1,334.63,818Z"
        ></path>
        <path
          className="cls-3"
          d="M343.44,825.23a.34.34,0,0,1-.55.39.34.34,0,0,1,.55-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M352.5,832.11a.34.34,0,0,1-.07.47.35.35,0,0,1-.47-.08A.33.33,0,0,1,352,832,.32.32,0,0,1,352.5,832.11Z"
        ></path>
        <path
          className="cls-3"
          d="M361.78,838.7a.3.3,0,1,1-.42-.07A.3.3,0,0,1,361.78,838.7Z"
        ></path>
        <path
          className="cls-3"
          d="M371.33,844.92a.33.33,0,1,1-.54.39.33.33,0,1,1,.54-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M381.06,850.83a.33.33,0,0,1-.07.46.33.33,0,1,1,.07-.46Z"
        ></path>
        <path
          className="cls-3"
          d="M391,856.42a.3.3,0,0,1-.07.42.3.3,0,1,1,.07-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M395.44,873a.28.28,0,0,1-.07.38.27.27,0,0,1-.38-.06.26.26,0,0,1,.07-.38A.27.27,0,0,1,395.44,873Z"
        ></path>
        <path
          className="cls-3"
          d="M405.72,877.91a.27.27,0,1,1-.44.31.26.26,0,0,1,.06-.37A.26.26,0,0,1,405.72,877.91Z"
        ></path>
        <path
          className="cls-3"
          d="M416.19,882.42a.31.31,0,0,1-.07.43.31.31,0,0,1-.42-.07.31.31,0,0,1,.07-.43A.31.31,0,0,1,416.19,882.42Z"
        ></path>
        <path
          className="cls-3"
          d="M426.76,886.62a.28.28,0,0,1-.45.32.28.28,0,0,1,.45-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M437.5,890.44a.3.3,0,0,1-.06.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.29.29,0,0,1,437.5,890.44Z"
        ></path>
        <path
          className="cls-3"
          d="M448.32,893.92a.27.27,0,0,1-.06.38.27.27,0,0,1-.32-.44A.27.27,0,0,1,448.32,893.92Z"
        ></path>
        <path
          className="cls-3"
          d="M459.28,897a.28.28,0,1,1-.38-.06A.27.27,0,0,1,459.28,897Z"
        ></path>
        <path
          className="cls-3"
          d="M470,899.74a.24.24,0,0,1,.34,0,.25.25,0,0,1-.05.34.24.24,0,0,1-.34-.06A.24.24,0,0,1,470,899.74Z"
        ></path>
        <path
          className="cls-3"
          d="M481.49,902.13a.3.3,0,0,1-.07.42.3.3,0,0,1-.35-.49A.31.31,0,0,1,481.49,902.13Z"
        ></path>
        <path
          className="cls-3"
          d="M492.67,904.15a.27.27,0,1,1-.38-.06A.27.27,0,0,1,492.67,904.15Z"
        ></path>
        <path
          className="cls-3"
          d="M503.92,905.8a.24.24,0,0,1-.4.28.24.24,0,1,1,.4-.28Z"
        ></path>
        <circle
          className="cls-3"
          cx="515.04"
          cy="907.19"
          r="0.27"
          transform="translate(-432.45 470.69) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M526.61,907.91a.27.27,0,0,1-.06.38.27.27,0,0,1-.38-.06.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M537.6,908.35a.27.27,0,0,1,.38.06.27.27,0,0,1-.44.32A.27.27,0,0,1,537.6,908.35Z"
        ></path>
        <path
          className="cls-3"
          d="M549.34,908.55a.23.23,0,0,1-.05.33.24.24,0,1,1-.28-.39A.23.23,0,0,1,549.34,908.55Z"
        ></path>
        <path
          className="cls-3"
          d="M560.75,908.27a.27.27,0,1,1-.38-.06A.27.27,0,0,1,560.75,908.27Z"
        ></path>
        <path
          className="cls-3"
          d="M572.12,907.63a.27.27,0,0,1-.44.32.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M583.46,906.62a.27.27,0,1,1-.38-.06A.27.27,0,0,1,583.46,906.62Z"
        ></path>
        <path
          className="cls-3"
          d="M594.76,905.23a.27.27,0,1,1-.38-.06A.27.27,0,0,1,594.76,905.23Z"
        ></path>
        <path
          className="cls-3"
          d="M606,903.47a.27.27,0,1,1-.38-.07A.27.27,0,0,1,606,903.47Z"
        ></path>
        <path
          className="cls-3"
          d="M617.22,901.31a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,617.22,901.31Z"
        ></path>
        <circle
          className="cls-3"
          cx="628.08"
          cy="898.98"
          r="0.27"
          transform="translate(-406.44 535.08) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M639.31,895.94a.26.26,0,0,1-.06.38.27.27,0,0,1-.32-.44A.27.27,0,0,1,639.31,895.94Z"
        ></path>
        <path
          className="cls-3"
          d="M650.23,892.7a.27.27,0,1,1-.38-.06A.27.27,0,0,1,650.23,892.7Z"
        ></path>
        <circle
          className="cls-3"
          cx="660.81"
          cy="889.26"
          r="0.27"
          transform="translate(-394.63 552.35) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M671.33,885.08a.27.27,0,1,1-.07.38A.27.27,0,0,1,671.33,885.08Z"
        ></path>
        <path
          className="cls-3"
          d="M681.86,880.77a.27.27,0,0,1,.38.06.27.27,0,1,1-.38-.06Z"
        ></path>
        <path
          className="cls-3"
          d="M692.63,876.17a.27.27,0,1,1-.38-.06A.27.27,0,0,1,692.63,876.17Z"
        ></path>
        <path
          className="cls-3"
          d="M702.89,871.15a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,702.89,871.15Z"
        ></path>
        <path
          className="cls-3"
          d="M712.89,865.85a.23.23,0,0,1,0,.33.24.24,0,1,1-.28-.39A.23.23,0,0,1,712.89,865.85Z"
        ></path>
        <path
          className="cls-3"
          d="M722.77,860.18a.24.24,0,0,1-.4.28.24.24,0,0,1,.4-.28Z"
        ></path>
        <circle className="cls-3" cx="732.25" cy="854.32" r="0.24"></circle>
        <path
          className="cls-3"
          d="M741.58,847.81a.24.24,0,0,1,.34.06.24.24,0,0,1-.39.28A.24.24,0,0,1,741.58,847.81Z"
        ></path>
        <circle className="cls-3" cx="750.98" cy="841.38" r="0.24"></circle>
        <path
          className="cls-3"
          d="M759.82,834.59a.24.24,0,0,1,.39-.28.24.24,0,1,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M768.68,827a.24.24,0,1,1-.06.34A.24.24,0,0,1,768.68,827Z"
        ></path>
        <path
          className="cls-3"
          d="M777.23,819.51a.24.24,0,1,1,.28.4.24.24,0,0,1-.34-.06A.25.25,0,0,1,777.23,819.51Z"
        ></path>
        <path
          className="cls-3"
          d="M785.45,812.08a.27.27,0,1,1,.44-.32.27.27,0,0,1-.06.38A.28.28,0,0,1,785.45,812.08Z"
        ></path>
        <path
          className="cls-3"
          d="M793.55,803.63a.27.27,0,1,1-.07.38A.27.27,0,0,1,793.55,803.63Z"
        ></path>
        <path
          className="cls-3"
          d="M801.31,795.3a.27.27,0,1,1,.32.44.27.27,0,0,1-.38-.06A.27.27,0,0,1,801.31,795.3Z"
        ></path>
        <path
          className="cls-3"
          d="M808.79,786.72a.27.27,0,1,1-.06.38A.27.27,0,0,1,808.79,786.72Z"
        ></path>
        <path
          className="cls-3"
          d="M815.92,778.27a.27.27,0,0,1,.06-.38.27.27,0,1,1,.32.44A.27.27,0,0,1,815.92,778.27Z"
        ></path>
        <path
          className="cls-3"
          d="M822.82,769.21a.27.27,0,1,1,.44-.32.27.27,0,0,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M829.41,759.93a.27.27,0,0,1,.06-.38.27.27,0,1,1-.06.38Z"
        ></path>
        <path
          className="cls-3"
          d="M835.77,750.07a.23.23,0,0,1,.33.06.24.24,0,0,1-.05.34.25.25,0,0,1-.34-.06A.24.24,0,0,1,835.77,750.07Z"
        ></path>
        <path
          className="cls-3"
          d="M841.64,740.73a.28.28,0,0,1,.45-.32.28.28,0,0,1-.45.32Z"
        ></path>
        <path
          className="cls-3"
          d="M847.72,730.51a.27.27,0,0,1-.44.32.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M852.58,720.75a.27.27,0,1,1,.38.07A.27.27,0,0,1,852.58,720.75Z"
        ></path>
        <path
          className="cls-3"
          d="M858,710.19a.27.27,0,1,1-.38-.06A.27.27,0,0,1,858,710.19Z"
        ></path>
        <path
          className="cls-3"
          d="M862.24,699.75a.24.24,0,1,1,.28.39.24.24,0,0,1-.28-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M866.46,689.53a.24.24,0,1,1,.33.06A.24.24,0,0,1,866.46,689.53Z"
        ></path>
        <path
          className="cls-3"
          d="M870.71,678.89a.23.23,0,0,1-.33,0,.24.24,0,1,1,.39-.28A.23.23,0,0,1,870.71,678.89Z"
        ></path>
        <path
          className="cls-3"
          d="M873.91,668a.27.27,0,0,1,.06-.38.27.27,0,1,1,.32.44A.27.27,0,0,1,873.91,668Z"
        ></path>
        <circle className="cls-3" cx="877.33" cy="656.96" r="0.24"></circle>
        <path
          className="cls-3"
          d="M880,646.09a.27.27,0,1,1,.38.06A.27.27,0,0,1,880,646.09Z"
        ></path>
        <path
          className="cls-3"
          d="M882.44,635a.23.23,0,0,1,0-.33.24.24,0,1,1,.28.39A.23.23,0,0,1,882.44,635Z"
        ></path>
        <path
          className="cls-3"
          d="M884.53,623.76a.23.23,0,0,1,.06-.33.24.24,0,0,1,.34.05.25.25,0,0,1-.06.34A.24.24,0,0,1,884.53,623.76Z"
        ></path>
        <path
          className="cls-3"
          d="M886.23,612.53a.27.27,0,0,1,.06-.38.27.27,0,1,1-.06.38Z"
        ></path>
        <path
          className="cls-3"
          d="M887.6,601.2a.24.24,0,0,1,.06-.34.24.24,0,0,1,.28.4A.24.24,0,0,1,887.6,601.2Z"
        ></path>
        <path
          className="cls-3"
          d="M888.57,589.86a.24.24,0,0,1,.4-.28.24.24,0,0,1-.4.28Z"
        ></path>
        <path
          className="cls-3"
          d="M889.14,578.51a.27.27,0,0,1,.44-.32.27.27,0,0,1-.06.38A.28.28,0,0,1,889.14,578.51Z"
        ></path>
        <path
          className="cls-3"
          d="M889.38,567.1a.23.23,0,0,1,.06-.33.23.23,0,0,1,.33.05.24.24,0,1,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M889.56,555.77a.24.24,0,1,1,.05-.33A.24.24,0,0,1,889.56,555.77Z"
        ></path>
        <path
          className="cls-3"
          d="M889,544.4a.24.24,0,0,1-.33,0,.24.24,0,0,1,.05-.34.25.25,0,0,1,.34,0A.24.24,0,0,1,889,544.4Z"
        ></path>
        <path
          className="cls-3"
          d="M888.11,533.08a.27.27,0,0,1-.38-.06.27.27,0,0,1,.44-.32A.27.27,0,0,1,888.11,533.08Z"
        ></path>
        <path
          className="cls-3"
          d="M886.46,521.69a.24.24,0,0,1,.06-.34.23.23,0,0,1,.33,0,.24.24,0,0,1-.05.34A.25.25,0,0,1,886.46,521.69Z"
        ></path>
        <path
          className="cls-3"
          d="M884.76,510.44a.28.28,0,0,1,.45-.32.28.28,0,0,1-.45.32Z"
        ></path>
        <circle
          className="cls-3"
          cx="882.94"
          cy="499.08"
          r="0.27"
          transform="matrix(0.81, -0.58, 0.58, 0.81, -125.35, 608.67)"
        ></circle>
        <path
          className="cls-3"
          d="M880.33,488.1a.24.24,0,0,1,0-.34.25.25,0,0,1,.34.05.24.24,0,0,1-.06.34A.24.24,0,0,1,880.33,488.1Z"
        ></path>
        <path
          className="cls-3"
          d="M877.52,477.07a.27.27,0,1,1,.38.07A.27.27,0,0,1,877.52,477.07Z"
        ></path>
        <path
          className="cls-3"
          d="M874.37,466.13a.27.27,0,0,1,.44-.32.27.27,0,0,1-.06.38A.27.27,0,0,1,874.37,466.13Z"
        ></path>
        <path
          className="cls-3"
          d="M870.88,455.28a.23.23,0,0,1,.06-.33.23.23,0,0,1,.33.05.24.24,0,1,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M867,444.59a.27.27,0,1,1,.38.07A.27.27,0,0,1,867,444.59Z"
        ></path>
        <path
          className="cls-3"
          d="M862.76,434a.27.27,0,0,1,.07-.38.26.26,0,0,1,.37.06.27.27,0,0,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M858.19,423.59a.27.27,0,1,1,.38.07A.27.27,0,0,1,858.19,423.59Z"
        ></path>
        <path
          className="cls-3"
          d="M853.27,413.32a.27.27,0,1,1,.38.07A.27.27,0,0,1,853.27,413.32Z"
        ></path>
        <path
          className="cls-3"
          d="M848,403.21a.24.24,0,0,1,.06-.34.24.24,0,0,1,.33.05.24.24,0,0,1,0,.34A.25.25,0,0,1,848,403.21Z"
        ></path>
        <path
          className="cls-3"
          d="M842.45,393.29a.25.25,0,0,1,.4-.29.25.25,0,0,1-.4.29Z"
        ></path>
        <path
          className="cls-3"
          d="M836.54,383.56a.24.24,0,0,1,.05-.34.24.24,0,0,1,.29.39A.24.24,0,0,1,836.54,383.56Z"
        ></path>
        <path
          className="cls-3"
          d="M830.3,374a.25.25,0,0,1,.06-.34.24.24,0,0,1,.34.06.24.24,0,0,1-.4.28Z"
        ></path>
        <path
          className="cls-3"
          d="M823.75,364.72a.25.25,0,0,1,.4-.29.24.24,0,0,1-.06.34A.25.25,0,0,1,823.75,364.72Z"
        ></path>
        <path
          className="cls-3"
          d="M816.9,355.62a.23.23,0,0,1,.06-.33.23.23,0,0,1,.33,0,.24.24,0,1,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M809.75,346.77a.24.24,0,1,1,.39-.29.24.24,0,0,1-.39.29Z"
        ></path>
        <path
          className="cls-3"
          d="M802.3,338.15a.25.25,0,0,1,.06-.34.24.24,0,1,1,.28.4A.25.25,0,0,1,802.3,338.15Z"
        ></path>
        <path
          className="cls-3"
          d="M794.53,329.82a.3.3,0,1,1,.42.07A.3.3,0,0,1,794.53,329.82Z"
        ></path>
        <path
          className="cls-3"
          d="M786.55,321.7a.28.28,0,0,1,.07-.38.27.27,0,0,1,.38.06.28.28,0,0,1-.06.38A.28.28,0,0,1,786.55,321.7Z"
        ></path>
        <path
          className="cls-3"
          d="M778.29,313.87a.27.27,0,1,1,.38.06A.27.27,0,0,1,778.29,313.87Z"
        ></path>
        <path
          className="cls-3"
          d="M769.77,306.32a.27.27,0,1,1,.44-.32.27.27,0,0,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M761,299.05a.26.26,0,0,1,.06-.38.27.27,0,1,1,.31.44A.26.26,0,0,1,761,299.05Z"
        ></path>
        <path
          className="cls-3"
          d="M752,292.08a.27.27,0,0,1,.06-.38.27.27,0,1,1,.32.44A.27.27,0,0,1,752,292.08Z"
        ></path>
        <path
          className="cls-3"
          d="M742.77,285.41a.28.28,0,0,1,.06-.38.26.26,0,0,1,.38.07.26.26,0,0,1-.06.37A.27.27,0,0,1,742.77,285.41Z"
        ></path>
        <path
          className="cls-3"
          d="M733.33,279.06a.27.27,0,0,1,.06-.38.27.27,0,0,1,.32.44A.27.27,0,0,1,733.33,279.06Z"
        ></path>
        <path
          className="cls-3"
          d="M723.7,273a.25.25,0,0,1,0-.34.24.24,0,0,1,.34.06.23.23,0,0,1-.05.33A.24.24,0,0,1,723.7,273Z"
        ></path>
        <path
          className="cls-3"
          d="M713.83,267.3a.27.27,0,0,1,.44-.32.27.27,0,1,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M703.79,261.92a.28.28,0,0,1,.45-.32.28.28,0,0,1-.45.32Z"
        ></path>
        <path
          className="cls-3"
          d="M693.59,256.87a.27.27,0,1,1,.38.06A.27.27,0,0,1,693.59,256.87Z"
        ></path>
        <path
          className="cls-3"
          d="M683.22,252.16a.27.27,0,0,1,.06-.38.27.27,0,0,1,.32.44A.27.27,0,0,1,683.22,252.16Z"
        ></path>
        <circle
          className="cls-3"
          cx="672.92"
          cy="247.64"
          r="0.27"
          transform="translate(-18.12 438.98) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M662.07,243.78a.24.24,0,0,1,.06-.34.24.24,0,0,1,.28.39A.24.24,0,0,1,662.07,243.78Z"
        ></path>
        <path
          className="cls-3"
          d="M651.24,240.16a.3.3,0,1,1,.49-.35.31.31,0,0,1-.07.42A.29.29,0,0,1,651.24,240.16Z"
        ></path>
        <path
          className="cls-3"
          d="M640.36,236.86a.27.27,0,1,1,.44-.32.27.27,0,1,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M629.36,233.93a.27.27,0,1,1,.38.06A.27.27,0,0,1,629.36,233.93Z"
        ></path>
        <path
          className="cls-3"
          d="M618.26,231.37a.27.27,0,0,1,.06-.38.27.27,0,1,1,.32.44A.27.27,0,0,1,618.26,231.37Z"
        ></path>
        <path
          className="cls-3"
          d="M607.09,229.18a.27.27,0,0,1,.06-.38.28.28,0,1,1-.06.38Z"
        ></path>
        <path
          className="cls-3"
          d="M595.82,227.38a.31.31,0,0,1,.07-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.31.31,0,0,1,595.82,227.38Z"
        ></path>
        <path
          className="cls-3"
          d="M584.53,225.94a.3.3,0,1,1,.42.07A.3.3,0,0,1,584.53,225.94Z"
        ></path>
        <path
          className="cls-3"
          d="M573.22,224.86a.27.27,0,1,1,.38.06A.27.27,0,0,1,573.22,224.86Z"
        ></path>
        <path
          className="cls-3"
          d="M561.83,224.19a.3.3,0,1,1,.42.07A.29.29,0,0,1,561.83,224.19Z"
        ></path>
        <path
          className="cls-3"
          d="M550.47,223.86a.28.28,0,0,1,.06-.38.28.28,0,0,1,.32.45A.28.28,0,0,1,550.47,223.86Z"
        ></path>
        <path
          className="cls-3"
          d="M539.09,223.93a.27.27,0,0,1,.44-.32.27.27,0,1,1-.44.32Z"
        ></path>
        <path
          className="cls-3"
          d="M527.69,224.4a.31.31,0,0,1,.06-.43.31.31,0,1,1,.36.5A.31.31,0,0,1,527.69,224.4Z"
        ></path>
        <path
          className="cls-3"
          d="M516.38,224.78a.34.34,0,0,1,.47.07.35.35,0,0,1-.08.47.33.33,0,0,1-.46-.08A.32.32,0,0,1,516.38,224.78Z"
        ></path>
        <circle
          className="cls-3"
          cx="505.25"
          cy="226.25"
          r="0.27"
          transform="translate(-37.12 337.16) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M493.76,228a.27.27,0,0,1,.06-.38.28.28,0,0,1,.38.07.27.27,0,1,1-.44.31Z"
        ></path>
        <path
          className="cls-3"
          d="M482.54,229.94a.27.27,0,0,1,.06-.38.27.27,0,1,1-.06.38Z"
        ></path>
        <path
          className="cls-3"
          d="M471.37,232.28a.29.29,0,0,1,.07-.42.3.3,0,0,1,.42.06.31.31,0,0,1-.07.43A.31.31,0,0,1,471.37,232.28Z"
        ></path>
        <path
          className="cls-3"
          d="M460.37,234.55a.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42.29.29,0,0,1-.42-.07A.3.3,0,0,1,460.37,234.55Z"
        ></path>
        <path
          className="cls-3"
          d="M449.39,238a.23.23,0,0,1,.05-.33.24.24,0,1,1,.28.39A.23.23,0,0,1,449.39,238Z"
        ></path>
        <path
          className="cls-3"
          d="M438.5,241.43a.27.27,0,0,1,.07-.38.27.27,0,0,1,.38.07.28.28,0,0,1-.07.38A.27.27,0,0,1,438.5,241.43Z"
        ></path>
        <path
          className="cls-3"
          d="M428.21,244.9a.28.28,0,0,1-.07.38.27.27,0,1,1,.07-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M417.22,249a.27.27,0,0,1,.38.06.28.28,0,0,1-.06.38.27.27,0,0,1-.38-.06A.26.26,0,0,1,417.22,249Z"
        ></path>
        <path
          className="cls-3"
          d="M406.75,253.46a.27.27,0,1,1-.06.38A.27.27,0,0,1,406.75,253.46Z"
        ></path>
        <path
          className="cls-3"
          d="M396.44,258.29a.27.27,0,1,1-.06.38A.27.27,0,0,1,396.44,258.29Z"
        ></path>
        <path
          className="cls-3"
          d="M386.3,263.46a.27.27,0,1,1,.32.44.27.27,0,0,1-.38-.06A.27.27,0,0,1,386.3,263.46Z"
        ></path>
        <path
          className="cls-3"
          d="M376.25,269.36a.3.3,0,0,1,.06-.42.31.31,0,0,1,.43.07.31.31,0,0,1-.07.42A.29.29,0,0,1,376.25,269.36Z"
        ></path>
        <path
          className="cls-3"
          d="M366.47,275.2a.31.31,0,0,1,.07-.43.31.31,0,0,1,.42.07.31.31,0,0,1-.07.43A.31.31,0,0,1,366.47,275.2Z"
        ></path>
        <path
          className="cls-3"
          d="M357.31,281.42a.3.3,0,1,1,.07-.42A.3.3,0,0,1,357.31,281.42Z"
        ></path>
        <path
          className="cls-3"
          d="M347.94,287.89a.3.3,0,0,1-.42-.07.3.3,0,0,1,.07-.42.3.3,0,1,1,.35.49Z"
        ></path>
        <path
          className="cls-3"
          d="M338.86,294.25a.3.3,0,0,1-.06.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.29.29,0,0,1,338.86,294.25Z"
        ></path>
        <path
          className="cls-3"
          d="M330,301.33a.31.31,0,0,1-.07.42.3.3,0,1,1,.07-.42Z"
        ></path>
        <path
          className="cls-3"
          d="M320.85,308.63a.3.3,0,1,1-.07.42A.3.3,0,0,1,320.85,308.63Z"
        ></path>
        <path
          className="cls-3"
          d="M312.44,316.31a.27.27,0,1,1,.31.44.26.26,0,0,1-.37-.06A.26.26,0,0,1,312.44,316.31Z"
        ></path>
        <circle
          className="cls-3"
          cx="304.43"
          cy="324.46"
          r="0.27"
          transform="translate(-132.1 238.47) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M296.37,332.44a.27.27,0,0,1,.32.44.27.27,0,0,1-.38-.06A.27.27,0,0,1,296.37,332.44Z"
        ></path>
        <path
          className="cls-3"
          d="M289.12,341a.27.27,0,1,1-.44.32.27.27,0,0,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M281.81,349.65a.3.3,0,1,1-.42-.07A.3.3,0,0,1,281.81,349.65Z"
        ></path>
        <path
          className="cls-3"
          d="M274.36,358.55a.27.27,0,1,1-.06.38A.27.27,0,0,1,274.36,358.55Z"
        ></path>
        <path
          className="cls-3"
          d="M268,367.78a.28.28,0,0,1-.45.32.28.28,0,0,1,.07-.38A.27.27,0,0,1,268,367.78Z"
        ></path>
        <path
          className="cls-3"
          d="M261.17,377.09a.3.3,0,0,1,.42.07.31.31,0,0,1-.07.42.3.3,0,0,1-.35-.49Z"
        ></path>
        <path
          className="cls-3"
          d="M255.47,386.76a.31.31,0,0,1-.07.42.29.29,0,0,1-.42-.07.3.3,0,0,1,.06-.42A.31.31,0,0,1,255.47,386.76Z"
        ></path>
        <path
          className="cls-3"
          d="M249.65,396.58a.28.28,0,0,1-.45.32.27.27,0,0,1,.07-.38A.26.26,0,0,1,249.65,396.58Z"
        ></path>
        <path
          className="cls-3"
          d="M244.2,406.55a.3.3,0,1,1-.42-.07A.3.3,0,0,1,244.2,406.55Z"
        ></path>
        <path
          className="cls-3"
          d="M239.05,416.73a.28.28,0,0,1-.45.32.28.28,0,0,1,.07-.39A.28.28,0,0,1,239.05,416.73Z"
        ></path>
        <path
          className="cls-3"
          d="M234.25,427.06a.26.26,0,0,1-.06.37.26.26,0,0,1-.38-.06.27.27,0,1,1,.44-.31Z"
        ></path>
        <path
          className="cls-3"
          d="M229.41,437.45a.3.3,0,1,1-.07.42A.3.3,0,0,1,229.41,437.45Z"
        ></path>
        <path
          className="cls-3"
          d="M225.31,448.07a.31.31,0,1,1-.07.43A.32.32,0,0,1,225.31,448.07Z"
        ></path>
        <path
          className="cls-3"
          d="M222,458.9a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,222,458.9Z"
        ></path>
        <path
          className="cls-3"
          d="M218.59,469.79a.27.27,0,0,1-.06.38.27.27,0,0,1-.38-.07.26.26,0,0,1,.06-.37A.27.27,0,0,1,218.59,469.79Z"
        ></path>
        <path
          className="cls-3"
          d="M215.57,480.77a.27.27,0,1,1-.38-.07A.27.27,0,0,1,215.57,480.77Z"
        ></path>
        <path
          className="cls-3"
          d="M213,491.82a.31.31,0,0,1-.07.42.3.3,0,0,1-.42-.06.3.3,0,1,1,.49-.36Z"
        ></path>
        <path
          className="cls-3"
          d="M210.64,503a.27.27,0,0,1-.06.38.27.27,0,1,1,.06-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M208.73,514.22a.27.27,0,1,1-.44.32.27.27,0,0,1,.06-.38A.27.27,0,0,1,208.73,514.22Z"
        ></path>
        <path
          className="cls-3"
          d="M207.22,525.48a.31.31,0,0,1-.07.43.31.31,0,0,1-.42-.07.31.31,0,0,1,.07-.43A.31.31,0,0,1,207.22,525.48Z"
        ></path>
        <path
          className="cls-3"
          d="M206,536.83a.27.27,0,0,1-.44.32.27.27,0,0,1,.06-.38A.26.26,0,0,1,206,536.83Z"
        ></path>
        <path
          className="cls-3"
          d="M205.28,548.17a.3.3,0,1,1-.42-.07A.3.3,0,0,1,205.28,548.17Z"
        ></path>
        <path
          className="cls-3"
          d="M204.88,559.55a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,204.88,559.55Z"
        ></path>
        <path
          className="cls-3"
          d="M204.85,570.93a.29.29,0,0,1-.07.42.3.3,0,0,1-.42-.06.31.31,0,0,1,.07-.43A.31.31,0,0,1,204.85,570.93Z"
        ></path>
        <path
          className="cls-3"
          d="M205.2,582.31a.31.31,0,0,1-.07.43.31.31,0,0,1-.35-.5A.31.31,0,0,1,205.2,582.31Z"
        ></path>
        <path
          className="cls-3"
          d="M205.49,593.58a.34.34,0,0,1,.47.08.33.33,0,0,1-.54.39A.34.34,0,0,1,205.49,593.58Z"
        ></path>
        <path
          className="cls-3"
          d="M207,605.41a.27.27,0,0,1-.37-.07.26.26,0,0,1,.06-.38.27.27,0,0,1,.38.06A.28.28,0,0,1,207,605.41Z"
        ></path>
        <path
          className="cls-3"
          d="M208.5,616.31a.27.27,0,0,1-.06.38.27.27,0,1,1,.06-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M210.36,627.55a.27.27,0,1,1-.38-.07A.27.27,0,0,1,210.36,627.55Z"
        ></path>
        <path
          className="cls-3"
          d="M212.62,638.69a.31.31,0,0,1-.5.36.31.31,0,0,1,.5-.36Z"
        ></path>
        <path
          className="cls-3"
          d="M215.22,649.78a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,215.22,649.78Z"
        ></path>
        <path
          className="cls-3"
          d="M218.16,660.79a.27.27,0,0,1-.44.32.27.27,0,0,1,.06-.38A.27.27,0,0,1,218.16,660.79Z"
        ></path>
        <path
          className="cls-3"
          d="M221.49,671.68a.27.27,0,1,1-.38-.07A.27.27,0,0,1,221.49,671.68Z"
        ></path>
        <path
          className="cls-3"
          d="M225.18,682.45a.27.27,0,0,1-.44.32.27.27,0,0,1,.06-.38A.27.27,0,0,1,225.18,682.45Z"
        ></path>
        <path
          className="cls-3"
          d="M229.25,693.07a.3.3,0,0,1-.49.35.31.31,0,0,1,.07-.42A.31.31,0,0,1,229.25,693.07Z"
        ></path>
        <path
          className="cls-3"
          d="M233.63,703.59a.27.27,0,0,1-.06.38.27.27,0,0,1-.38-.06.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M238.37,713.94a.27.27,0,1,1-.38-.06A.27.27,0,0,1,238.37,713.94Z"
        ></path>
        <path
          className="cls-3"
          d="M243.46,724.13a.27.27,0,0,1-.06.38.28.28,0,0,1-.32-.45A.27.27,0,0,1,243.46,724.13Z"
        ></path>
        <path
          className="cls-3"
          d="M248.91,734.12a.3.3,0,0,1-.07.42.3.3,0,1,1-.35-.49A.3.3,0,0,1,248.91,734.12Z"
        ></path>
        <path
          className="cls-3"
          d="M254.66,744a.3.3,0,1,1-.42-.07A.3.3,0,0,1,254.66,744Z"
        ></path>
        <path
          className="cls-3"
          d="M260.74,753.58a.31.31,0,0,1-.07.42.3.3,0,0,1-.42-.07.3.3,0,1,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M267.13,763a.3.3,0,1,1-.42-.07A.3.3,0,0,1,267.13,763Z"
        ></path>
        <path
          className="cls-3"
          d="M273.83,772.2a.29.29,0,0,1-.07.42.3.3,0,0,1-.42-.06.31.31,0,0,1,.07-.43A.31.31,0,0,1,273.83,772.2Z"
        ></path>
        <path
          className="cls-3"
          d="M280.84,781.18a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,280.84,781.18Z"
        ></path>
        <path
          className="cls-3"
          d="M288.14,789.92a.31.31,0,0,1-.07.42.31.31,0,0,1-.43-.07.31.31,0,0,1,.07-.42A.31.31,0,0,1,288.14,789.92Z"
        ></path>
        <path
          className="cls-3"
          d="M295.72,798.41a.3.3,0,1,1-.42-.07A.3.3,0,0,1,295.72,798.41Z"
        ></path>
        <path
          className="cls-3"
          d="M303.56,806.66a.27.27,0,1,1-.38-.06A.27.27,0,0,1,303.56,806.66Z"
        ></path>
        <path
          className="cls-3"
          d="M311.69,814.63a.27.27,0,0,1-.44.32.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M320.09,822.32a.28.28,0,1,1-.38-.06A.27.27,0,0,1,320.09,822.32Z"
        ></path>
        <circle
          className="cls-3"
          cx="328.51"
          cy="829.89"
          r="0.27"
          transform="translate(-422.38 347.39) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M337.62,836.85a.28.28,0,0,1-.45.32.27.27,0,0,1,.07-.38A.27.27,0,0,1,337.62,836.85Z"
        ></path>
        <path
          className="cls-3"
          d="M346.73,843.67a.27.27,0,1,1-.38-.06A.27.27,0,0,1,346.73,843.67Z"
        ></path>
        <path
          className="cls-3"
          d="M356.05,850.2a.24.24,0,1,1-.4.28.24.24,0,0,1,.4-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M365.65,856.37a.31.31,0,0,1-.07.42.31.31,0,0,1-.42-.07.3.3,0,0,1,.49-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M375.37,862.26a.27.27,0,1,1-.38-.06A.27.27,0,0,1,375.37,862.26Z"
        ></path>
        <path
          className="cls-3"
          d="M385.31,867.81a.27.27,0,1,1-.37-.06A.26.26,0,0,1,385.31,867.81Z"
        ></path>
        <circle className="cls-3" cx="389.59" cy="884.56" r="0.24"></circle>
        <path
          className="cls-3"
          d="M400.05,889.32a.22.22,0,0,1-.35.25.22.22,0,0,1,.05-.3A.21.21,0,0,1,400.05,889.32Z"
        ></path>
        <path
          className="cls-3"
          d="M410.51,893.85a.24.24,0,0,1-.06.34.24.24,0,1,1-.28-.39A.24.24,0,0,1,410.51,893.85Z"
        ></path>
        <path
          className="cls-3"
          d="M421.06,898.09a.21.21,0,0,1-.34.24.21.21,0,0,1,0-.29A.21.21,0,0,1,421.06,898.09Z"
        ></path>
        <path
          className="cls-3"
          d="M431.79,901.94a.25.25,0,0,1-.05.34.24.24,0,0,1-.34-.06.23.23,0,0,1,.05-.33A.24.24,0,0,1,431.79,901.94Z"
        ></path>
        <path
          className="cls-3"
          d="M442.59,905.49a.2.2,0,0,1,0,.29.21.21,0,0,1-.29,0,.21.21,0,0,1,.05-.3A.2.2,0,0,1,442.59,905.49Z"
        ></path>
        <path
          className="cls-3"
          d="M453.21,908.59a.25.25,0,0,1,.34.06.24.24,0,0,1-.06.34.23.23,0,0,1-.33-.06A.24.24,0,0,1,453.21,908.59Z"
        ></path>
        <path
          className="cls-3"
          d="M464.58,911.48a.24.24,0,0,1-.06.34.24.24,0,1,1,.06-.34Z"
        ></path>
        <path
          className="cls-3"
          d="M475.67,914a.21.21,0,0,1-.05.29.22.22,0,0,1-.3,0,.23.23,0,0,1,.05-.3A.21.21,0,0,1,475.67,914Z"
        ></path>
        <path
          className="cls-3"
          d="M486.88,916.07a.24.24,0,0,1-.39.28.24.24,0,1,1,.39-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M497.79,917.77a.24.24,0,1,1,.28.39.24.24,0,0,1-.33,0A.24.24,0,0,1,497.79,917.77Z"
        ></path>
        <path
          className="cls-3"
          d="M509.41,919.23a.23.23,0,0,1-.05.3.21.21,0,0,1-.3,0,.2.2,0,0,1,.05-.29A.22.22,0,0,1,509.41,919.23Z"
        ></path>
        <path
          className="cls-3"
          d="M520.77,920.25a.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.4A.24.24,0,0,1,520.77,920.25Z"
        ></path>
        <path
          className="cls-3"
          d="M532.11,920.93a.21.21,0,0,1-.05.3.2.2,0,0,1-.29-.05.21.21,0,0,1,.05-.3A.22.22,0,0,1,532.11,920.93Z"
        ></path>
        <path
          className="cls-3"
          d="M543.12,921.5a.25.25,0,0,1,.06-.34.24.24,0,1,1,.28.4A.25.25,0,0,1,543.12,921.5Z"
        ></path>
        <path
          className="cls-3"
          d="M554.85,921.49a.24.24,0,0,1-.34,0,.25.25,0,0,1,0-.34.24.24,0,0,1,.34.06A.23.23,0,0,1,554.85,921.49Z"
        ></path>
        <path
          className="cls-3"
          d="M566.3,920.71a.27.27,0,1,1-.44.32.28.28,0,0,1,.06-.38A.27.27,0,0,1,566.3,920.71Z"
        ></path>
        <circle className="cls-3" cx="577.44" cy="920.08" r="0.24"></circle>
        <path
          className="cls-3"
          d="M589,918.78a.25.25,0,0,1-.06.34.24.24,0,0,1-.34-.06.23.23,0,0,1,.06-.33A.24.24,0,0,1,589,918.78Z"
        ></path>
        <path
          className="cls-3"
          d="M600.23,917.28a.23.23,0,0,1-.05.3.22.22,0,0,1-.3-.05.21.21,0,0,1,0-.3A.23.23,0,0,1,600.23,917.28Z"
        ></path>
        <path
          className="cls-3"
          d="M611.48,915.39a.24.24,0,0,1-.39.28.24.24,0,1,1,.39-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M622.31,913.09a.24.24,0,0,1,.28.4.25.25,0,0,1-.34-.06A.24.24,0,0,1,622.31,913.09Z"
        ></path>
        <path
          className="cls-3"
          d="M633.71,910.57a.23.23,0,0,1-.05.3.22.22,0,1,1-.25-.35A.23.23,0,0,1,633.71,910.57Z"
        ></path>
        <path
          className="cls-3"
          d="M644.39,907.55a.24.24,0,0,1,.34.06.23.23,0,0,1-.06.33.24.24,0,0,1-.34-.05A.25.25,0,0,1,644.39,907.55Z"
        ></path>
        <path
          className="cls-3"
          d="M655.62,904.31a.24.24,0,0,1,0,.34.25.25,0,0,1-.34-.06.24.24,0,0,1,.06-.34A.23.23,0,0,1,655.62,904.31Z"
        ></path>
        <circle
          className="cls-3"
          cx="666.21"
          cy="900.8"
          r="0.21"
          transform="translate(-400.34 557.67) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M677.05,896.69a.21.21,0,1,1-.29-.05A.2.2,0,0,1,677.05,896.69Z"
        ></path>
        <path
          className="cls-3"
          d="M687.58,892.36a.21.21,0,1,1-.29-.05A.2.2,0,0,1,687.58,892.36Z"
        ></path>
        <path
          className="cls-3"
          d="M698,887.68a.24.24,0,0,1-.39.28.25.25,0,0,1,0-.34A.24.24,0,0,1,698,887.68Z"
        ></path>
        <path
          className="cls-3"
          d="M708.22,882.68a.24.24,0,0,1-.05.34.25.25,0,0,1-.29-.4A.24.24,0,0,1,708.22,882.68Z"
        ></path>
        <path
          className="cls-3"
          d="M718.26,877.37a.22.22,0,0,1,0,.3.21.21,0,0,1-.3,0,.2.2,0,0,1,.05-.29A.21.21,0,0,1,718.26,877.37Z"
        ></path>
        <path
          className="cls-3"
          d="M728.15,871.73a.21.21,0,1,1-.29-.05A.22.22,0,0,1,728.15,871.73Z"
        ></path>
        <path
          className="cls-3"
          d="M737.86,865.78a.21.21,0,0,1-.05.29.21.21,0,0,1-.3-.05.22.22,0,0,1,0-.29A.21.21,0,0,1,737.86,865.78Z"
        ></path>
        <path
          className="cls-3"
          d="M747.07,859.47a.22.22,0,0,1,.3,0,.23.23,0,0,1,0,.3.21.21,0,0,1-.3,0A.21.21,0,0,1,747.07,859.47Z"
        ></path>
        <path
          className="cls-3"
          d="M756.37,852.9a.22.22,0,1,1,.25.35.22.22,0,0,1-.25-.35Z"
        ></path>
        <path
          className="cls-3"
          d="M765.41,846.34a.22.22,0,0,1,.05-.3.21.21,0,0,1,.3.05.22.22,0,1,1-.35.25Z"
        ></path>
        <path
          className="cls-3"
          d="M774.62,838.94a.21.21,0,0,1,0,.3.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.3A.23.23,0,0,1,774.62,838.94Z"
        ></path>
        <path
          className="cls-3"
          d="M783.25,831.51a.22.22,0,0,1,0,.3.21.21,0,0,1-.3,0,.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M791.66,823.8a.24.24,0,0,1-.39.28.24.24,0,1,1,.39-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M799.8,815.83a.25.25,0,0,1-.06.34.24.24,0,1,1-.28-.4A.25.25,0,0,1,799.8,815.83Z"
        ></path>
        <path
          className="cls-3"
          d="M807.65,807.63a.22.22,0,0,1,0,.29.21.21,0,0,1-.3,0,.22.22,0,0,1,.05-.29A.21.21,0,0,1,807.65,807.63Z"
        ></path>
        <path
          className="cls-3"
          d="M815.26,799.16a.22.22,0,0,1,0,.29.21.21,0,0,1-.3-.05.2.2,0,0,1,.05-.29A.21.21,0,0,1,815.26,799.16Z"
        ></path>
        <path
          className="cls-3"
          d="M822.54,790.74a.21.21,0,0,1-.3,0,.22.22,0,0,1,0-.29.21.21,0,0,1,.3.05A.2.2,0,0,1,822.54,790.74Z"
        ></path>
        <path
          className="cls-3"
          d="M829.64,781.5a.22.22,0,0,1-.35.25.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M836.42,772.33a.23.23,0,0,1,0,.33.24.24,0,1,1-.28-.39A.23.23,0,0,1,836.42,772.33Z"
        ></path>
        <path
          className="cls-3"
          d="M842.57,762.92a.2.2,0,0,1,.29.05.21.21,0,0,1-.05.3.22.22,0,0,1-.29,0A.21.21,0,0,1,842.57,762.92Z"
        ></path>
        <path
          className="cls-3"
          d="M849,753.39a.21.21,0,0,1,0,.3.2.2,0,0,1-.29-.05.21.21,0,0,1,.05-.3A.22.22,0,0,1,849,753.39Z"
        ></path>
        <path
          className="cls-3"
          d="M854.53,743.87a.2.2,0,0,1,.05-.29.21.21,0,0,1,.29,0,.21.21,0,0,1,0,.3A.2.2,0,0,1,854.53,743.87Z"
        ></path>
        <path
          className="cls-3"
          d="M860.4,733.67a.22.22,0,0,1,0,.3.22.22,0,0,1-.25-.35A.22.22,0,0,1,860.4,733.67Z"
        ></path>
        <path
          className="cls-3"
          d="M865.25,723.82a.24.24,0,1,1,.33,0A.24.24,0,0,1,865.25,723.82Z"
        ></path>
        <path
          className="cls-3"
          d="M870.15,713.51a.22.22,0,0,1,.05-.29.21.21,0,1,1-.05.29Z"
        ></path>
        <path
          className="cls-3"
          d="M874.71,703.08a.21.21,0,0,1,0-.3.21.21,0,1,1,.24.34A.21.21,0,0,1,874.71,703.08Z"
        ></path>
        <path
          className="cls-3"
          d="M879.29,692.23a.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.39A.25.25,0,0,1,879.29,692.23Z"
        ></path>
        <path
          className="cls-3"
          d="M883.09,681.84a.2.2,0,0,1-.29-.05.21.21,0,0,1,0-.29.22.22,0,0,1,.3,0A.21.21,0,0,1,883.09,681.84Z"
        ></path>
        <path
          className="cls-3"
          d="M886.32,671a.23.23,0,0,1,0-.3.22.22,0,1,1,.25.35A.23.23,0,0,1,886.32,671Z"
        ></path>
        <path
          className="cls-3"
          d="M889.85,659.79a.21.21,0,0,1-.05.29.21.21,0,0,1-.3-.05.22.22,0,0,1,0-.29A.21.21,0,0,1,889.85,659.79Z"
        ></path>
        <path
          className="cls-3"
          d="M892.31,649a.23.23,0,0,1,.05-.33.24.24,0,0,1,.34,0,.25.25,0,0,1-.05.34A.24.24,0,0,1,892.31,649Z"
        ></path>
        <path
          className="cls-3"
          d="M894.8,637.89a.22.22,0,0,1,.35-.25.22.22,0,1,1-.35.25Z"
        ></path>
        <path
          className="cls-3"
          d="M896.92,626.7a.2.2,0,0,1,.05-.29.21.21,0,0,1,.3.05.22.22,0,0,1,0,.29A.21.21,0,0,1,896.92,626.7Z"
        ></path>
        <path
          className="cls-3"
          d="M898.68,615.45a.21.21,0,0,1,0-.29.21.21,0,0,1,.3.05.21.21,0,1,1-.34.24Z"
        ></path>
        <path
          className="cls-3"
          d="M900.05,604.17a.24.24,0,0,1,.05-.34.25.25,0,0,1,.34.06.24.24,0,0,1-.06.34A.23.23,0,0,1,900.05,604.17Z"
        ></path>
        <path
          className="cls-3"
          d="M901.08,592.83a.24.24,0,0,1,0-.34.25.25,0,0,1,.29.4A.24.24,0,0,1,901.08,592.83Z"
        ></path>
        <path
          className="cls-3"
          d="M901.77,581.45a.21.21,0,1,1,.34-.25.21.21,0,1,1-.34.25Z"
        ></path>
        <path
          className="cls-3"
          d="M902.05,570.08a.23.23,0,0,1,.05-.33.24.24,0,1,1,.28.39A.23.23,0,0,1,902.05,570.08Z"
        ></path>
        <path
          className="cls-3"
          d="M902,558.68a.2.2,0,0,1,0-.29.21.21,0,0,1,.29,0,.22.22,0,0,1,0,.3A.21.21,0,0,1,902,558.68Z"
        ></path>
        <path
          className="cls-3"
          d="M901.9,547.38a.25.25,0,0,1-.34-.06.24.24,0,0,1,.05-.34.25.25,0,0,1,.29.4Z"
        ></path>
        <path
          className="cls-3"
          d="M901.14,535.7a.22.22,0,0,1,0,.29.21.21,0,0,1-.3,0,.2.2,0,0,1,.05-.29A.21.21,0,0,1,901.14,535.7Z"
        ></path>
        <path
          className="cls-3"
          d="M899.67,524.3a.24.24,0,0,1,.33.05.24.24,0,0,1,0,.34.25.25,0,0,1-.34-.05A.24.24,0,0,1,899.67,524.3Z"
        ></path>
        <path
          className="cls-3"
          d="M898.12,513.33a.21.21,0,1,1,.29,0A.2.2,0,0,1,898.12,513.33Z"
        ></path>
        <path
          className="cls-3"
          d="M896.24,502.1a.21.21,0,1,1,.34-.24.21.21,0,0,1,0,.29A.21.21,0,0,1,896.24,502.1Z"
        ></path>
        <path
          className="cls-3"
          d="M894,490.94a.22.22,0,0,1,0-.3.22.22,0,0,1,.25.35A.21.21,0,0,1,894,490.94Z"
        ></path>
        <path
          className="cls-3"
          d="M891.41,479.85a.21.21,0,1,1,.29,0A.2.2,0,0,1,891.41,479.85Z"
        ></path>
        <path
          className="cls-3"
          d="M888.44,468.87a.23.23,0,0,1,0-.33.24.24,0,1,1,.28.39A.23.23,0,0,1,888.44,468.87Z"
        ></path>
        <path
          className="cls-3"
          d="M885.14,458a.24.24,0,0,1,.05-.34.25.25,0,0,1,.34.05.24.24,0,0,1-.06.34A.24.24,0,0,1,885.14,458Z"
        ></path>
        <path
          className="cls-3"
          d="M881.54,447.15a.18.18,0,0,1,.29-.21.19.19,0,0,1,0,.26A.17.17,0,0,1,881.54,447.15Z"
        ></path>
        <path
          className="cls-3"
          d="M877.52,436.51a.23.23,0,0,1,.05-.3.22.22,0,1,1-.05.3Z"
        ></path>
        <path
          className="cls-3"
          d="M873.19,426a.23.23,0,0,1,0-.3.22.22,0,1,1,0,.3Z"
        ></path>
        <path
          className="cls-3"
          d="M868.53,415.59a.21.21,0,1,1,.29.05A.2.2,0,0,1,868.53,415.59Z"
        ></path>
        <path
          className="cls-3"
          d="M863.51,405.38a.24.24,0,1,1,.39-.28.24.24,0,0,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M858.21,395.3a.21.21,0,0,1,0-.3.2.2,0,0,1,.29.05.21.21,0,0,1,0,.29A.22.22,0,0,1,858.21,395.3Z"
        ></path>
        <path
          className="cls-3"
          d="M852.57,385.41a.21.21,0,0,1,0-.3.2.2,0,0,1,.29.05.21.21,0,0,1,0,.29A.22.22,0,0,1,852.57,385.41Z"
        ></path>
        <path
          className="cls-3"
          d="M846.61,375.7a.22.22,0,0,1,.35-.25.22.22,0,0,1-.05.3A.21.21,0,0,1,846.61,375.7Z"
        ></path>
        <path
          className="cls-3"
          d="M840.35,366.19a.21.21,0,0,1,.34-.24.21.21,0,0,1,0,.29A.21.21,0,0,1,840.35,366.19Z"
        ></path>
        <path
          className="cls-3"
          d="M833.79,356.89a.21.21,0,0,1,0-.29.22.22,0,0,1,.3,0,.21.21,0,0,1,0,.3A.2.2,0,0,1,833.79,356.89Z"
        ></path>
        <path
          className="cls-3"
          d="M826.93,347.8a.21.21,0,0,1,0-.29.21.21,0,0,1,.3.05.21.21,0,1,1-.34.24Z"
        ></path>
        <path
          className="cls-3"
          d="M819.78,338.94a.21.21,0,0,1,.05-.3.22.22,0,0,1,.29.05.21.21,0,0,1,0,.3A.2.2,0,0,1,819.78,338.94Z"
        ></path>
        <path
          className="cls-3"
          d="M812.35,330.31a.2.2,0,0,1,0-.29.21.21,0,0,1,.29,0,.22.22,0,0,1,0,.3A.21.21,0,0,1,812.35,330.31Z"
        ></path>
        <path
          className="cls-3"
          d="M804.63,321.94a.24.24,0,0,1,0-.34.25.25,0,0,1,.34.06.24.24,0,0,1-.06.34A.24.24,0,0,1,804.63,321.94Z"
        ></path>
        <path
          className="cls-3"
          d="M796.66,313.81a.24.24,0,0,1,.06-.34.23.23,0,0,1,.33,0,.24.24,0,0,1,0,.34A.25.25,0,0,1,796.66,313.81Z"
        ></path>
        <path
          className="cls-3"
          d="M788.46,305.91a.21.21,0,0,1,.35-.24.22.22,0,0,1,0,.29A.21.21,0,0,1,788.46,305.91Z"
        ></path>
        <path
          className="cls-3"
          d="M780,298.3a.2.2,0,0,1,0-.29.21.21,0,0,1,.3.05.22.22,0,0,1-.05.29A.21.21,0,0,1,780,298.3Z"
        ></path>
        <path
          className="cls-3"
          d="M771.28,291a.21.21,0,0,1,.05-.29.22.22,0,0,1,.3,0,.23.23,0,0,1,0,.3A.21.21,0,0,1,771.28,291Z"
        ></path>
        <path
          className="cls-3"
          d="M762.34,283.92a.22.22,0,0,1,0-.3.22.22,0,0,1,.25.35A.22.22,0,0,1,762.34,283.92Z"
        ></path>
        <path
          className="cls-3"
          d="M753.15,277.18a.25.25,0,0,1,.06-.34.24.24,0,0,1,.34.06.23.23,0,0,1-.06.33A.24.24,0,0,1,753.15,277.18Z"
        ></path>
        <path
          className="cls-3"
          d="M743.78,270.71a.23.23,0,0,1,.06-.33.23.23,0,0,1,.33.05.24.24,0,1,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M734.23,264.54a.21.21,0,0,1,0-.3.21.21,0,0,1,.29.05.21.21,0,0,1-.05.3A.22.22,0,0,1,734.23,264.54Z"
        ></path>
        <path
          className="cls-3"
          d="M724.44,258.71a.24.24,0,0,1,0-.34.25.25,0,0,1,.34.05.24.24,0,0,1-.06.34A.24.24,0,0,1,724.44,258.71Z"
        ></path>
        <path
          className="cls-3"
          d="M714.51,253.16a.21.21,0,0,1,0-.3.21.21,0,0,1,.24.34A.21.21,0,0,1,714.51,253.16Z"
        ></path>
        <path
          className="cls-3"
          d="M704.34,248a.27.27,0,1,1,.38.06A.27.27,0,0,1,704.34,248Z"
        ></path>
        <path
          className="cls-3"
          d="M694.08,243.08a.24.24,0,1,1,.39-.28.24.24,0,0,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M683.64,238.53a.24.24,0,0,1,.06-.34.24.24,0,0,1,.33.05.24.24,0,0,1,0,.34A.25.25,0,0,1,683.64,238.53Z"
        ></path>
        <path
          className="cls-3"
          d="M673.09,234.29a.21.21,0,1,1,.29.05A.2.2,0,0,1,673.09,234.29Z"
        ></path>
        <path
          className="cls-3"
          d="M662.38,230.42a.22.22,0,0,1,0-.3.22.22,0,0,1,.25.35A.22.22,0,0,1,662.38,230.42Z"
        ></path>
        <path
          className="cls-3"
          d="M651.53,226.91a.24.24,0,0,1,.06-.34.24.24,0,0,1,.28.39A.24.24,0,0,1,651.53,226.91Z"
        ></path>
        <path
          className="cls-3"
          d="M640.62,223.71a.22.22,0,0,1,.35-.25.22.22,0,0,1-.05.3A.21.21,0,0,1,640.62,223.71Z"
        ></path>
        <path
          className="cls-3"
          d="M629.59,220.88a.22.22,0,0,1,0-.29.21.21,0,0,1,.3,0,.2.2,0,0,1-.05.29A.21.21,0,0,1,629.59,220.88Z"
        ></path>
        <path
          className="cls-3"
          d="M618.46,218.43a.24.24,0,1,1,.39-.28.23.23,0,0,1-.06.33A.23.23,0,0,1,618.46,218.43Z"
        ></path>
        <path
          className="cls-3"
          d="M607.29,216.29a.21.21,0,0,1,.35-.24.22.22,0,0,1,0,.29A.21.21,0,0,1,607.29,216.29Z"
        ></path>
        <path
          className="cls-3"
          d="M596,214.54a.22.22,0,1,1,.3.05A.22.22,0,0,1,596,214.54Z"
        ></path>
        <path
          className="cls-3"
          d="M584.72,213.16a.24.24,0,1,1,.34.06A.24.24,0,0,1,584.72,213.16Z"
        ></path>
        <path
          className="cls-3"
          d="M573.7,212.16a.21.21,0,0,1-.3,0,.22.22,0,0,1,.05-.29.21.21,0,1,1,.25.34Z"
        ></path>
        <path
          className="cls-3"
          d="M562,211.46a.23.23,0,0,1,.06-.33.24.24,0,0,1,.34.05.25.25,0,0,1-.06.34A.24.24,0,0,1,562,211.46Z"
        ></path>
        <path
          className="cls-3"
          d="M550.63,211.16a.24.24,0,0,1,.06-.34.23.23,0,0,1,.33.06.24.24,0,0,1,0,.34A.25.25,0,0,1,550.63,211.16Z"
        ></path>
        <path
          className="cls-3"
          d="M539.64,210.94a.24.24,0,0,1-.06.34.23.23,0,0,1-.33-.06.24.24,0,0,1,0-.34A.25.25,0,0,1,539.64,210.94Z"
        ></path>
        <path
          className="cls-3"
          d="M527.89,211.63a.21.21,0,0,1,.05-.29.21.21,0,0,1,.3,0,.22.22,0,0,1,0,.29A.21.21,0,0,1,527.89,211.63Z"
        ></path>
        <circle className="cls-3" cx="516.71" cy="212.3" r="0.24"></circle>
        <path
          className="cls-3"
          d="M505.18,213.6a.25.25,0,0,1,.4-.29.25.25,0,0,1-.06.34A.24.24,0,0,1,505.18,213.6Z"
        ></path>
        <path
          className="cls-3"
          d="M493.9,215.11a.23.23,0,0,1,.05-.33.24.24,0,0,1,.34.05.25.25,0,0,1-.05.34A.24.24,0,0,1,493.9,215.11Z"
        ></path>
        <path
          className="cls-3"
          d="M483,216.73a.21.21,0,0,1-.05.29.21.21,0,1,1-.25-.34A.21.21,0,0,1,483,216.73Z"
        ></path>
        <path
          className="cls-3"
          d="M471.82,219.26a.21.21,0,1,1,.05-.29A.2.2,0,0,1,471.82,219.26Z"
        ></path>
        <path
          className="cls-3"
          d="M460.42,221.82a.24.24,0,0,1,.05-.33.24.24,0,0,1,.34,0,.25.25,0,0,1-.05.34A.24.24,0,0,1,460.42,221.82Z"
        ></path>
        <path
          className="cls-3"
          d="M449.49,224.46a.21.21,0,0,1,.3,0,.2.2,0,0,1-.05.29.21.21,0,0,1-.3-.05A.22.22,0,0,1,449.49,224.46Z"
        ></path>
        <path
          className="cls-3"
          d="M438.92,227.79a.25.25,0,0,1-.06.34.24.24,0,1,1-.28-.4A.25.25,0,0,1,438.92,227.79Z"
        ></path>
        <path
          className="cls-3"
          d="M427.74,231.72a.24.24,0,1,1,.39-.28.24.24,0,0,1-.39.28Z"
        ></path>
        <path
          className="cls-3"
          d="M417.13,235.37a.24.24,0,0,1,.34.06.24.24,0,0,1-.06.33.24.24,0,0,1-.34,0A.25.25,0,0,1,417.13,235.37Z"
        ></path>
        <path
          className="cls-3"
          d="M406.6,239.7a.24.24,0,0,1,.34.06.24.24,0,0,1-.06.33.24.24,0,1,1-.28-.39Z"
        ></path>
        <path
          className="cls-3"
          d="M396.55,244.42a.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.39A.24.24,0,0,1,396.55,244.42Z"
        ></path>
        <path
          className="cls-3"
          d="M386.32,249.42a.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.4A.24.24,0,0,1,386.32,249.42Z"
        ></path>
        <path
          className="cls-3"
          d="M375.9,254.66a.27.27,0,0,1,.32.44.27.27,0,1,1-.32-.44Z"
        ></path>
        <path
          className="cls-3"
          d="M366.39,260.36a.27.27,0,0,1-.06.38.27.27,0,1,1-.32-.44A.27.27,0,0,1,366.39,260.36Z"
        ></path>
        <path
          className="cls-3"
          d="M356.3,266.26a.27.27,0,1,1-.06.38A.27.27,0,0,1,356.3,266.26Z"
        ></path>
        <path
          className="cls-3"
          d="M347.11,273a.27.27,0,0,1-.32-.44.27.27,0,1,1,.32.44Z"
        ></path>
        <path
          className="cls-3"
          d="M337.87,279.15a.27.27,0,1,1-.44.32.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M328.41,285.94a.27.27,0,0,1,.37.07.26.26,0,0,1-.06.38.28.28,0,0,1-.38-.06A.28.28,0,0,1,328.41,285.94Z"
        ></path>
        <path
          className="cls-3"
          d="M319.92,293.15a.27.27,0,0,1-.06.38.27.27,0,1,1,.06-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M311.29,300.58a.27.27,0,1,1-.38-.06A.27.27,0,0,1,311.29,300.58Z"
        ></path>
        <path
          className="cls-3"
          d="M302.91,308.28a.28.28,0,0,1-.45.32.28.28,0,0,1,.45-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M294.75,316.27a.24.24,0,0,1-.4.28.24.24,0,0,1,.4-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M286.53,324.43a.24.24,0,1,1,.28.4.24.24,0,0,1-.33-.06A.24.24,0,0,1,286.53,324.43Z"
        ></path>
        <path
          className="cls-3"
          d="M278.91,332.88a.27.27,0,1,1-.07.38A.27.27,0,0,1,278.91,332.88Z"
        ></path>
        <path
          className="cls-3"
          d="M271.93,341.67a.24.24,0,0,1-.06.34.24.24,0,1,1,.06-.34Z"
        ></path>
        <path
          className="cls-3"
          d="M264.88,350.61a.24.24,0,0,1-.06.34.24.24,0,1,1,.06-.34Z"
        ></path>
        <path
          className="cls-3"
          d="M258.12,359.77a.24.24,0,1,1-.4.28.24.24,0,0,1,.06-.33A.24.24,0,0,1,258.12,359.77Z"
        ></path>
        <path
          className="cls-3"
          d="M251.68,369.13a.27.27,0,0,1-.06.38.26.26,0,0,1-.38-.07.26.26,0,0,1,.06-.37A.26.26,0,0,1,251.68,369.13Z"
        ></path>
        <path
          className="cls-3"
          d="M245.16,378.67a.23.23,0,0,1,.33.05.24.24,0,1,1-.39.28A.23.23,0,0,1,245.16,378.67Z"
        ></path>
        <path
          className="cls-3"
          d="M239.65,388.49a.24.24,0,1,1-.34-.06A.25.25,0,0,1,239.65,388.49Z"
        ></path>
        <path
          className="cls-3"
          d="M234.11,398.44a.24.24,0,1,1-.33-.06A.24.24,0,0,1,234.11,398.44Z"
        ></path>
        <path
          className="cls-3"
          d="M228.9,408.56a.24.24,0,0,1-.05.34.24.24,0,1,1,.05-.34Z"
        ></path>
        <path
          className="cls-3"
          d="M223.68,418.79a.25.25,0,0,1,.34.06.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.4Z"
        ></path>
        <path
          className="cls-3"
          d="M219.41,429.62a.24.24,0,1,1,.06-.33A.24.24,0,0,1,219.41,429.62Z"
        ></path>
        <path
          className="cls-3"
          d="M215.23,439.88a.22.22,0,0,1,0,.3.23.23,0,0,1-.3-.05.22.22,0,0,1,.05-.3A.21.21,0,0,1,215.23,439.88Z"
        ></path>
        <path
          className="cls-3"
          d="M211.4,450.55a.27.27,0,1,1-.38-.06A.27.27,0,0,1,211.4,450.55Z"
        ></path>
        <path
          className="cls-3"
          d="M207.85,461.39a.24.24,0,0,1-.06.34.24.24,0,0,1-.33-.05.24.24,0,0,1,0-.34A.25.25,0,0,1,207.85,461.39Z"
        ></path>
        <path
          className="cls-3"
          d="M204.67,472.33a.24.24,0,0,1-.39.28.24.24,0,1,1,.39-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M201.84,483.36a.24.24,0,0,1-.05.33.24.24,0,1,1,.05-.33Z"
        ></path>
        <path
          className="cls-3"
          d="M199.37,494.47a.24.24,0,0,1-.4.28.24.24,0,0,1,.06-.33A.24.24,0,0,1,199.37,494.47Z"
        ></path>
        <path
          className="cls-3"
          d="M197.28,505.64a.28.28,0,1,1-.38-.06A.27.27,0,0,1,197.28,505.64Z"
        ></path>
        <path
          className="cls-3"
          d="M195.47,516.92a.21.21,0,0,1-.05.3.21.21,0,0,1-.24-.34A.21.21,0,0,1,195.47,516.92Z"
        ></path>
        <path
          className="cls-3"
          d="M194.1,528.21a.24.24,0,1,1-.34-.06A.24.24,0,0,1,194.1,528.21Z"
        ></path>
        <path
          className="cls-3"
          d="M193.07,539.55a.24.24,0,0,1,0,.33.24.24,0,1,1,0-.33Z"
        ></path>
        <path
          className="cls-3"
          d="M192.4,550.91a.24.24,0,0,1-.05.34.25.25,0,0,1-.34,0,.24.24,0,0,1,.06-.34A.23.23,0,0,1,192.4,550.91Z"
        ></path>
        <path
          className="cls-3"
          d="M192.1,562.29a.24.24,0,0,1-.06.34.23.23,0,0,1-.33,0,.24.24,0,0,1,0-.34A.25.25,0,0,1,192.1,562.29Z"
        ></path>
        <path
          className="cls-3"
          d="M192.19,573.66a.28.28,0,0,1-.06.38.27.27,0,0,1-.38-.06.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M192.59,585.06a.24.24,0,1,1-.34-.06A.25.25,0,0,1,192.59,585.06Z"
        ></path>
        <circle className="cls-3" cx="193.18" cy="596.56" r="0.24"></circle>
        <path
          className="cls-3"
          d="M194.54,607.74a.25.25,0,0,1-.4.29.24.24,0,0,1,.06-.34A.25.25,0,0,1,194.54,607.74Z"
        ></path>
        <path
          className="cls-3"
          d="M196.05,619a.24.24,0,0,1-.05.34.25.25,0,0,1-.34-.06.24.24,0,0,1,.06-.34A.23.23,0,0,1,196.05,619Z"
        ></path>
        <path
          className="cls-3"
          d="M197.93,630.26a.24.24,0,0,1-.05.34.25.25,0,0,1-.29-.4A.24.24,0,0,1,197.93,630.26Z"
        ></path>
        <path
          className="cls-3"
          d="M200.17,641.42a.24.24,0,0,1-.06.34.24.24,0,0,1-.28-.39A.24.24,0,0,1,200.17,641.42Z"
        ></path>
        <path
          className="cls-3"
          d="M202.76,652.51a.24.24,0,0,1,0,.34.25.25,0,0,1-.34-.06.24.24,0,0,1,.06-.34A.23.23,0,0,1,202.76,652.51Z"
        ></path>
        <path
          className="cls-3"
          d="M205.71,663.51a.23.23,0,0,1-.05.33.24.24,0,0,1-.34-.05.25.25,0,0,1,.05-.34A.24.24,0,0,1,205.71,663.51Z"
        ></path>
        <circle className="cls-3" cx="208.81" cy="674.54" r="0.24"></circle>
        <path
          className="cls-3"
          d="M212.66,685.19a.24.24,0,0,1-.06.34.24.24,0,0,1-.33-.06.24.24,0,0,1,0-.34A.25.25,0,0,1,212.66,685.19Z"
        ></path>
        <path
          className="cls-3"
          d="M216.67,695.84a.27.27,0,0,1-.06.38.28.28,0,0,1-.38-.07.27.27,0,1,1,.44-.31Z"
        ></path>
        <path
          className="cls-3"
          d="M221,706.38a.24.24,0,0,1-.06.34.24.24,0,0,1-.33-.05.24.24,0,0,1,.39-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M225.65,716.77a.24.24,0,0,1-.4.28.24.24,0,0,1,.06-.34A.25.25,0,0,1,225.65,716.77Z"
        ></path>
        <path
          className="cls-3"
          d="M230.67,727a.28.28,0,0,1-.45.32.27.27,0,0,1,.07-.38A.27.27,0,0,1,230.67,727Z"
        ></path>
        <path
          className="cls-3"
          d="M236,737.05a.28.28,0,0,1-.45.32.28.28,0,0,1,.07-.38A.27.27,0,0,1,236,737.05Z"
        ></path>
        <path
          className="cls-3"
          d="M241.63,746.94a.27.27,0,0,1-.06.38.27.27,0,0,1-.32-.44A.27.27,0,0,1,241.63,746.94Z"
        ></path>
        <path
          className="cls-3"
          d="M247.58,756.64a.27.27,0,1,1-.38-.06A.27.27,0,0,1,247.58,756.64Z"
        ></path>
        <path
          className="cls-3"
          d="M253.85,766.15a.28.28,0,0,1-.45.32.28.28,0,0,1,.07-.38A.27.27,0,0,1,253.85,766.15Z"
        ></path>
        <path
          className="cls-3"
          d="M260.41,775.45a.27.27,0,0,1-.06.38.27.27,0,1,1,.06-.38Z"
        ></path>
        <path
          className="cls-3"
          d="M267.27,784.54a.27.27,0,1,1-.38-.06A.27.27,0,0,1,267.27,784.54Z"
        ></path>
        <path
          className="cls-3"
          d="M274.42,793.4a.27.27,0,0,1-.44.32.27.27,0,1,1,.44-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M281.85,802a.28.28,0,0,1-.45.32.28.28,0,0,1,.45-.32Z"
        ></path>
        <path
          className="cls-3"
          d="M289.52,810.44a.24.24,0,0,1,0,.34.25.25,0,0,1-.34-.06.24.24,0,0,1,.06-.34A.23.23,0,0,1,289.52,810.44Z"
        ></path>
        <path
          className="cls-3"
          d="M297.49,818.57a.24.24,0,0,1-.06.34.24.24,0,0,1-.33,0,.24.24,0,1,1,.39-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M305.71,826.45a.25.25,0,0,1,0,.34.25.25,0,0,1-.29-.4A.25.25,0,0,1,305.71,826.45Z"
        ></path>
        <path
          className="cls-3"
          d="M314.21,834a.28.28,0,0,1-.45.32.27.27,0,0,1,.07-.38A.27.27,0,0,1,314.21,834Z"
        ></path>
        <path
          className="cls-3"
          d="M322.89,841.39a.25.25,0,0,1-.05.34.24.24,0,0,1-.34-.06.23.23,0,0,1,.05-.33A.24.24,0,0,1,322.89,841.39Z"
        ></path>
        <path
          className="cls-3"
          d="M331.83,848.44a.25.25,0,0,1-.05.34.24.24,0,0,1-.34-.06.23.23,0,0,1,.05-.33A.24.24,0,0,1,331.83,848.44Z"
        ></path>
        <path
          className="cls-3"
          d="M341,855.22a.21.21,0,0,1-.05.3.22.22,0,0,1-.25-.35A.21.21,0,0,1,341,855.22Z"
        ></path>
        <path
          className="cls-3"
          d="M350.37,861.66a.25.25,0,0,1-.4.29.25.25,0,0,1,.4-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M359.94,867.82a.24.24,0,0,1-.05.34.24.24,0,1,1,.05-.34Z"
        ></path>
        <path
          className="cls-3"
          d="M369.69,873.69a.22.22,0,0,1-.05.3.23.23,0,0,1-.3,0,.22.22,0,1,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M379.69,879.19a.27.27,0,0,1-.07.38.27.27,0,0,1-.38-.06.28.28,0,0,1,.07-.38A.27.27,0,0,1,379.69,879.19Z"
        ></path>
        <circle className="cls-3" cx="383.96" cy="895.93" r="0.18"></circle>
        <path
          className="cls-3"
          d="M394.42,900.69a.21.21,0,0,1-.05.3.2.2,0,0,1-.29,0,.21.21,0,0,1,0-.29A.22.22,0,0,1,394.42,900.69Z"
        ></path>
        <path
          className="cls-3"
          d="M404.85,905.26a.22.22,0,0,1-.05.3.21.21,0,0,1-.3,0,.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M415.41,909.51a.21.21,0,0,1,0,.29.21.21,0,0,1-.3,0,.21.21,0,1,1,.34-.24Z"
        ></path>
        <path
          className="cls-3"
          d="M426.11,913.42a.23.23,0,0,1-.05.3.22.22,0,0,1-.25-.35A.23.23,0,0,1,426.11,913.42Z"
        ></path>
        <path
          className="cls-3"
          d="M436.91,917a.21.21,0,1,1-.29,0A.2.2,0,0,1,436.91,917Z"
        ></path>
        <path
          className="cls-3"
          d="M447.53,920.2a.22.22,0,1,1,.25.35.22.22,0,0,1-.25-.35Z"
        ></path>
        <circle className="cls-3" cx="458.66" cy="923.28" r="0.18"></circle>
        <circle className="cls-3" cx="469.76" cy="925.85" r="0.18"></circle>
        <path
          className="cls-3"
          d="M481.07,928a.19.19,0,0,1,0,.26.18.18,0,1,1,0-.26Z"
        ></path>
        <path
          className="cls-3"
          d="M492.33,929.82a.22.22,0,0,1-.35.25.22.22,0,0,1,0-.3A.21.21,0,0,1,492.33,929.82Z"
        ></path>
        <path
          className="cls-3"
          d="M503.61,931.34a.22.22,0,1,1-.35.25.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M514.91,932.54a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.19.19,0,0,1,0-.25A.18.18,0,0,1,514.91,932.54Z"
        ></path>
        <path
          className="cls-3"
          d="M526.29,933.34a.21.21,0,0,1,0,.3.2.2,0,0,1-.29,0,.21.21,0,0,1,0-.29A.22.22,0,0,1,526.29,933.34Z"
        ></path>
        <path
          className="cls-3"
          d="M537.64,933.83a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,537.64,933.83Z"
        ></path>
        <path
          className="cls-3"
          d="M549.05,933.94a.21.21,0,1,1-.29-.05A.2.2,0,0,1,549.05,933.94Z"
        ></path>
        <path
          className="cls-3"
          d="M560.44,933.7a.23.23,0,0,1-.05.3.21.21,0,0,1-.3,0,.2.2,0,0,1,0-.29A.22.22,0,0,1,560.44,933.7Z"
        ></path>
        <path
          className="cls-3"
          d="M571.81,933.12a.22.22,0,0,1-.35.25.22.22,0,1,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M583.13,932.2a.19.19,0,0,1,0,.26.19.19,0,0,1-.26-.05.17.17,0,0,1,0-.25A.18.18,0,0,1,583.13,932.2Z"
        ></path>
        <path
          className="cls-3"
          d="M594.47,930.9a.22.22,0,0,1-.35.25.22.22,0,0,1,0-.3A.21.21,0,0,1,594.47,930.9Z"
        ></path>
        <path
          className="cls-3"
          d="M605.71,929.28a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,.05-.25A.18.18,0,0,1,605.71,929.28Z"
        ></path>
        <path
          className="cls-3"
          d="M616.65,927.23a.21.21,0,0,1,.3,0,.22.22,0,0,1-.35.25A.21.21,0,0,1,616.65,927.23Z"
        ></path>
        <path
          className="cls-3"
          d="M628.09,925a.2.2,0,0,1-.05.29.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.3A.21.21,0,0,1,628.09,925Z"
        ></path>
        <path
          className="cls-3"
          d="M639.13,922.29a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.18.18,0,0,1,.29-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M650.11,919.28a.18.18,0,1,1-.25,0A.18.18,0,0,1,650.11,919.28Z"
        ></path>
        <path
          className="cls-3"
          d="M661,915.92a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,661,915.92Z"
        ></path>
        <circle className="cls-3" cx="671.62" cy="912.34" r="0.18"></circle>
        <circle className="cls-3" cx="682.27" cy="908.32" r="0.18"></circle>
        <circle className="cls-3" cx="692.79" cy="903.97" r="0.18"></circle>
        <circle className="cls-3" cx="703.18" cy="899.3" r="0.18"></circle>
        <path
          className="cls-3"
          d="M713.58,894.19a.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,713.58,894.19Z"
        ></path>
        <path
          className="cls-3"
          d="M723.63,888.9a.18.18,0,1,1-.25,0A.18.18,0,0,1,723.63,888.9Z"
        ></path>
        <circle className="cls-3" cx="733.39" cy="883.39" r="0.18"></circle>
        <circle className="cls-3" cx="743.12" cy="877.48" r="0.18"></circle>
        <path
          className="cls-3"
          d="M752.81,871.15a.19.19,0,0,1,0,.26.17.17,0,0,1-.25,0,.18.18,0,0,1,0-.25A.19.19,0,0,1,752.81,871.15Z"
        ></path>
        <circle className="cls-3" cx="762" cy="864.75" r="0.18"></circle>
        <path
          className="cls-3"
          d="M771.28,857.85a.18.18,0,1,1-.25,0A.18.18,0,0,1,771.28,857.85Z"
        ></path>
        <path
          className="cls-3"
          d="M780.21,850.78a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,0-.25A.18.18,0,0,1,780.21,850.78Z"
        ></path>
        <path
          className="cls-3"
          d="M788.9,843.43a.17.17,0,0,1,0,.25.18.18,0,1,1,0-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M797.37,835.82a.18.18,0,1,1-.25,0A.18.18,0,0,1,797.37,835.82Z"
        ></path>
        <path
          className="cls-3"
          d="M805.58,828.23a.23.23,0,0,1-.3-.05.21.21,0,0,1,.05-.3.21.21,0,0,1,.29,0A.22.22,0,0,1,805.58,828.23Z"
        ></path>
        <path
          className="cls-3"
          d="M813.6,819.81a.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,813.6,819.81Z"
        ></path>
        <circle className="cls-3" cx="821.16" cy="811.57" r="0.18"></circle>
        <path
          className="cls-3"
          d="M828.77,802.86a.18.18,0,1,1-.3.21.18.18,0,0,1,0-.25A.19.19,0,0,1,828.77,802.86Z"
        ></path>
        <path
          className="cls-3"
          d="M835.71,794a.18.18,0,0,1,.25,0,.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0A.2.2,0,0,1,835.71,794Z"
        ></path>
        <path
          className="cls-3"
          d="M842.58,785.2a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.18.18,0,0,1-.3.21Z"
        ></path>
        <path
          className="cls-3"
          d="M849.51,775.74a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.19.19,0,0,1,0-.25A.19.19,0,0,1,849.51,775.74Z"
        ></path>
        <circle className="cls-3" cx="855.7" cy="766.39" r="0.18"></circle>
        <path
          className="cls-3"
          d="M861.88,756.91a.21.21,0,0,1-.3,0,.21.21,0,1,1,.34-.24A.21.21,0,0,1,861.88,756.91Z"
        ></path>
        <path
          className="cls-3"
          d="M867.65,746.81a.18.18,0,0,1,0,.25.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.19.19,0,0,1,867.65,746.81Z"
        ></path>
        <path
          className="cls-3"
          d="M872.79,737a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.2.2,0,0,1-.05.26A.18.18,0,0,1,872.79,737Z"
        ></path>
        <circle className="cls-3" cx="878.06" cy="726.74" r="0.18"></circle>
        <path
          className="cls-3"
          d="M882.73,716.53a.18.18,0,1,1,.29-.21.18.18,0,0,1-.29.21Z"
        ></path>
        <path
          className="cls-3"
          d="M887.19,706.08a.2.2,0,0,1,0-.29.22.22,0,0,1,.3,0,.23.23,0,0,1,0,.3A.21.21,0,0,1,887.19,706.08Z"
        ></path>
        <path
          className="cls-3"
          d="M891.67,695.25a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,891.67,695.25Z"
        ></path>
        <path
          className="cls-3"
          d="M895.46,684.79a.17.17,0,0,1-.25,0,.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0A.19.19,0,0,1,895.46,684.79Z"
        ></path>
        <path
          className="cls-3"
          d="M898.71,673.91a.18.18,0,1,1,.25,0A.18.18,0,0,1,898.71,673.91Z"
        ></path>
        <path
          className="cls-3"
          d="M901.87,663a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.17.17,0,0,1,0,.25A.18.18,0,0,1,901.87,663Z"
        ></path>
        <path
          className="cls-3"
          d="M904.72,651.92a.15.15,0,1,1,.21,0A.15.15,0,0,1,904.72,651.92Z"
        ></path>
        <path
          className="cls-3"
          d="M907.17,640.83a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.18.18,0,0,1,0,.25A.19.19,0,0,1,907.17,640.83Z"
        ></path>
        <path
          className="cls-3"
          d="M909.31,629.64a.18.18,0,0,1,.29-.21.19.19,0,0,1,0,.26A.18.18,0,0,1,909.31,629.64Z"
        ></path>
        <path
          className="cls-3"
          d="M911.39,618.19a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.17.17,0,0,1,0-.25A.18.18,0,0,1,911.39,618.19Z"
        ></path>
        <circle className="cls-3" cx="912.68" cy="607" r="0.18"></circle>
        <path
          className="cls-3"
          d="M913.64,595.75a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <circle className="cls-3" cx="914.5" cy="584.3" r="0.18"></circle>
        <circle className="cls-3" cx="914.89" cy="572.92" r="0.18"></circle>
        <path
          className="cls-3"
          d="M914.77,561.64a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.18.18,0,0,1,0,.25A.19.19,0,0,1,914.77,561.64Z"
        ></path>
        <path
          className="cls-3"
          d="M914.45,550.26a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.17.17,0,0,1,0,.25A.18.18,0,0,1,914.45,550.26Z"
        ></path>
        <path
          className="cls-3"
          d="M914,538.91a.15.15,0,1,1-.18-.24.15.15,0,0,1,.21,0A.16.16,0,0,1,914,538.91Z"
        ></path>
        <path
          className="cls-3"
          d="M913,527.6a.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0A.19.19,0,0,1,913,527.6Z"
        ></path>
        <path
          className="cls-3"
          d="M911.36,516.27a.2.2,0,0,1,0-.29.21.21,0,0,1,.29,0,.21.21,0,0,1-.05.3A.2.2,0,0,1,911.36,516.27Z"
        ></path>
        <path
          className="cls-3"
          d="M909.66,505a.18.18,0,1,1,.25,0A.18.18,0,0,1,909.66,505Z"
        ></path>
        <path
          className="cls-3"
          d="M907.59,493.8a.18.18,0,1,1,.25,0A.18.18,0,0,1,907.59,493.8Z"
        ></path>
        <path
          className="cls-3"
          d="M905.2,482.66a.15.15,0,1,1,.21,0A.15.15,0,0,1,905.2,482.66Z"
        ></path>
        <path
          className="cls-3"
          d="M902.41,471.63a.17.17,0,0,1,.05-.25.18.18,0,1,1,.21.29A.19.19,0,0,1,902.41,471.63Z"
        ></path>
        <circle className="cls-3" cx="899.46" cy="460.57" r="0.18"></circle>
        <path
          className="cls-3"
          d="M895.88,449.82a.18.18,0,1,1,.25,0A.18.18,0,0,1,895.88,449.82Z"
        ></path>
        <path
          className="cls-3"
          d="M892.1,439.08a.19.19,0,0,1,0-.26.17.17,0,0,1,.25.05.18.18,0,0,1,0,.25A.19.19,0,0,1,892.1,439.08Z"
        ></path>
        <path
          className="cls-3"
          d="M888,428.46a.19.19,0,1,1,.26,0A.19.19,0,0,1,888,428.46Z"
        ></path>
        <circle className="cls-3" cx="883.72" cy="417.86" r="0.18"></circle>
        <circle className="cls-3" cx="878.97" cy="407.51" r="0.18"></circle>
        <path
          className="cls-3"
          d="M873.73,397.44a.2.2,0,0,1,0-.29.21.21,0,0,1,.29,0,.21.21,0,0,1-.05.3A.2.2,0,0,1,873.73,397.44Z"
        ></path>
        <path
          className="cls-3"
          d="M868.37,387.39a.18.18,0,1,1,.25,0A.18.18,0,0,1,868.37,387.39Z"
        ></path>
        <path
          className="cls-3"
          d="M862.68,377.53a.18.18,0,1,1,.25,0A.18.18,0,0,1,862.68,377.53Z"
        ></path>
        <circle className="cls-3" cx="856.83" cy="367.74" r="0.18"></circle>
        <circle className="cls-3" cx="850.54" cy="358.25" r="0.18"></circle>
        <path
          className="cls-3"
          d="M843.82,349.06a.18.18,0,0,1,.29-.21.19.19,0,0,1,0,.26A.17.17,0,0,1,843.82,349.06Z"
        ></path>
        <circle className="cls-3" cx="837.1" cy="339.88" r="0.18"></circle>
        <path
          className="cls-3"
          d="M829.81,331.11a.18.18,0,0,1,.29-.21.19.19,0,0,1,0,.26A.18.18,0,0,1,829.81,331.11Z"
        ></path>
        <circle className="cls-3" cx="822.54" cy="322.37" r="0.18"></circle>
        <path
          className="cls-3"
          d="M814.72,314.07a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.19.19,0,0,1,814.72,314.07Z"
        ></path>
        <path
          className="cls-3"
          d="M806.76,305.92a.21.21,0,0,1,0-.3.2.2,0,0,1,.29.05.21.21,0,0,1-.05.3A.22.22,0,0,1,806.76,305.92Z"
        ></path>
        <path
          className="cls-3"
          d="M798.6,298a.18.18,0,0,1,0-.25.18.18,0,1,1,.21.29A.18.18,0,0,1,798.6,298Z"
        ></path>
        <circle className="cls-3" cx="790.32" cy="290.22" r="0.18"></circle>
        <path
          className="cls-3"
          d="M781.52,282.93a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.18.18,0,0,1,781.52,282.93Z"
        ></path>
        <circle className="cls-3" cx="772.79" cy="275.7" r="0.18"></circle>
        <path
          className="cls-3"
          d="M763.54,269a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.18.18,0,0,1,763.54,269Z"
        ></path>
        <path
          className="cls-3"
          d="M754.23,262.39a.18.18,0,0,1,0-.25.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.19.19,0,0,1,754.23,262.39Z"
        ></path>
        <path
          className="cls-3"
          d="M744.73,256.12a.18.18,0,0,1,.29-.21.18.18,0,1,1-.29.21Z"
        ></path>
        <path
          className="cls-3"
          d="M735,250.17a.22.22,0,0,1,0-.3.22.22,0,0,1,.25.35A.22.22,0,0,1,735,250.17Z"
        ></path>
        <path
          className="cls-3"
          d="M725.16,244.48a.17.17,0,0,1,.05-.25.18.18,0,1,1,.21.29A.19.19,0,0,1,725.16,244.48Z"
        ></path>
        <path
          className="cls-3"
          d="M715.12,239.12a.18.18,0,1,1,.25,0A.18.18,0,0,1,715.12,239.12Z"
        ></path>
        <path
          className="cls-3"
          d="M704.89,234.09a.21.21,0,0,1,.05-.3.22.22,0,0,1,.29.05.21.21,0,1,1-.34.25Z"
        ></path>
        <circle className="cls-3" cx="694.7" cy="229.24" r="0.18"></circle>
        <path
          className="cls-3"
          d="M684,225a.22.22,0,1,1,.3.05A.21.21,0,0,1,684,225Z"
        ></path>
        <path
          className="cls-3"
          d="M673.43,220.85a.18.18,0,0,1,.29-.21.19.19,0,0,1,0,.26A.18.18,0,0,1,673.43,220.85Z"
        ></path>
        <path
          className="cls-3"
          d="M662.65,217.12a.21.21,0,0,1,.35-.24.22.22,0,0,1,0,.29A.21.21,0,0,1,662.65,217.12Z"
        ></path>
        <circle className="cls-3" cx="651.96" cy="213.58" r="0.18"></circle>
        <path
          className="cls-3"
          d="M640.85,210.61a.18.18,0,0,1,0-.25.18.18,0,1,1,.21.29A.19.19,0,0,1,640.85,210.61Z"
        ></path>
        <circle className="cls-3" cx="629.95" cy="207.77" r="0.18"></circle>
        <path
          className="cls-3"
          d="M618.64,205.5a.22.22,0,0,1,.35-.25.22.22,0,1,1-.35.25Z"
        ></path>
        <circle className="cls-3" cx="607.62" cy="203.33" r="0.18"></circle>
        <circle className="cls-3" cx="596.36" cy="201.63" r="0.18"></circle>
        <path
          className="cls-3"
          d="M584.91,200.39a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.18.18,0,0,1,584.91,200.39Z"
        ></path>
        <path
          className="cls-3"
          d="M573.59,199.11a.21.21,0,1,1-.05.29A.2.2,0,0,1,573.59,199.11Z"
        ></path>
        <path
          className="cls-3"
          d="M562.17,198.75a.21.21,0,0,1,.05-.29.21.21,0,0,1,.3,0,.22.22,0,0,1,0,.29A.21.21,0,0,1,562.17,198.75Z"
        ></path>
        <path
          className="cls-3"
          d="M550.81,198.44a.18.18,0,0,1,.05-.25.18.18,0,0,1,.21.29A.19.19,0,0,1,550.81,198.44Z"
        ></path>
        <path
          className="cls-3"
          d="M539.7,198.56a.21.21,0,0,1-.3-.05.22.22,0,0,1,.05-.29.21.21,0,1,1,.25.34Z"
        ></path>
        <path
          className="cls-3"
          d="M528.37,198.67a.21.21,0,0,1,0,.3.2.2,0,0,1-.29-.05.21.21,0,0,1,0-.29A.22.22,0,0,1,528.37,198.67Z"
        ></path>
        <path
          className="cls-3"
          d="M516.67,199.68a.21.21,0,0,1,0-.29.21.21,0,0,1,.3.05.21.21,0,1,1-.34.24Z"
        ></path>
        <path
          className="cls-3"
          d="M505.36,200.78a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.18.18,0,0,1,505.36,200.78Z"
        ></path>
        <circle className="cls-3" cx="494.21" cy="202.13" r="0.18"></circle>
        <path
          className="cls-3"
          d="M483.14,203.82a.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0,.21.21,0,0,1,.05-.3A.2.2,0,0,1,483.14,203.82Z"
        ></path>
        <path
          className="cls-3"
          d="M471.94,206a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,471.94,206Z"
        ></path>
        <path
          className="cls-3"
          d="M460.54,208.71a.19.19,0,0,1,0-.26.18.18,0,0,1,.21.3A.18.18,0,0,1,460.54,208.71Z"
        ></path>
        <path
          className="cls-3"
          d="M449.83,211.32a.21.21,0,1,1-.29,0A.22.22,0,0,1,449.83,211.32Z"
        ></path>
        <circle
          className="cls-3"
          cx="438.73"
          cy="214.63"
          r="0.21"
          transform="translate(-42.83 296.18) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M427.78,218a.2.2,0,0,1,.29.05.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0A.21.21,0,0,1,427.78,218Z"
        ></path>
        <path
          className="cls-3"
          d="M417.36,221.88a.21.21,0,0,1-.05.3.2.2,0,0,1-.29-.05.22.22,0,0,1,0-.3A.23.23,0,0,1,417.36,221.88Z"
        ></path>
        <path
          className="cls-3"
          d="M406.74,226.39a.24.24,0,1,1-.28-.4.24.24,0,1,1,.28.4Z"
        ></path>
        <path
          className="cls-3"
          d="M396,230.81a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.18.18,0,0,1-.29.21Z"
        ></path>
        <path
          className="cls-3"
          d="M386,235.43a.18.18,0,0,1-.29.21.18.18,0,1,1,.29-.21Z"
        ></path>
        <circle className="cls-3" cx="375.68" cy="240.68" r="0.18"></circle>
        <path
          className="cls-3"
          d="M365.86,246a.21.21,0,0,1-.34.24.21.21,0,0,1,0-.29A.21.21,0,0,1,365.86,246Z"
        ></path>
        <path
          className="cls-3"
          d="M355.7,252a.21.21,0,0,1,.05-.3.22.22,0,0,1,.29.05.21.21,0,0,1-.05.3A.2.2,0,0,1,355.7,252Z"
        ></path>
        <path
          className="cls-3"
          d="M346.06,258.1a.22.22,0,1,1,.3,0A.23.23,0,0,1,346.06,258.1Z"
        ></path>
        <path
          className="cls-3"
          d="M337,264.21a.23.23,0,0,1-.05.3.21.21,0,0,1-.3-.05.2.2,0,0,1,.05-.29A.22.22,0,0,1,337,264.21Z"
        ></path>
        <path
          className="cls-3"
          d="M327.73,270.87a.22.22,0,0,1-.05.29.21.21,0,0,1-.3-.05.2.2,0,0,1,.05-.29A.21.21,0,0,1,327.73,270.87Z"
        ></path>
        <path
          className="cls-3"
          d="M318.69,277.8a.22.22,0,0,1,0,.3.23.23,0,0,1-.3-.05.21.21,0,0,1,0-.3A.22.22,0,0,1,318.69,277.8Z"
        ></path>
        <path
          className="cls-3"
          d="M309.88,285a.21.21,0,1,1-.29-.05A.22.22,0,0,1,309.88,285Z"
        ></path>
        <path
          className="cls-3"
          d="M301.3,292.49a.21.21,0,0,1-.05.3.22.22,0,0,1-.3-.05.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M293,300.23a.21.21,0,0,1-.05.3.21.21,0,1,1-.24-.34A.21.21,0,0,1,293,300.23Z"
        ></path>
        <circle className="cls-3" cx="284.67" cy="308.36" r="0.18"></circle>
        <path
          className="cls-3"
          d="M276.71,316.45a.19.19,0,1,1,.22.3.2.2,0,0,1-.26,0A.19.19,0,0,1,276.71,316.45Z"
        ></path>
        <path
          className="cls-3"
          d="M269.42,324.94a.24.24,0,1,1-.39.28.24.24,0,0,1,.39-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M262.07,333.67a.21.21,0,0,1-.05.3.21.21,0,0,1-.29-.05.22.22,0,0,1,0-.3A.23.23,0,0,1,262.07,333.67Z"
        ></path>
        <path
          className="cls-3"
          d="M255,342.61a.22.22,0,0,1,0,.3.23.23,0,0,1-.3-.05.21.21,0,0,1,.05-.3A.2.2,0,0,1,255,342.61Z"
        ></path>
        <path
          className="cls-3"
          d="M248.24,351.76a.21.21,0,0,1-.05.3.22.22,0,0,1-.25-.35A.21.21,0,0,1,248.24,351.76Z"
        ></path>
        <path
          className="cls-3"
          d="M241.78,361.1a.24.24,0,0,1-.4.28.24.24,0,1,1,.4-.28Z"
        ></path>
        <path
          className="cls-3"
          d="M235.55,370.67a.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,235.55,370.67Z"
        ></path>
        <path
          className="cls-3"
          d="M229.66,380.41a.22.22,0,1,1-.3-.05A.21.21,0,0,1,229.66,380.41Z"
        ></path>
        <path
          className="cls-3"
          d="M224.06,390.33a.21.21,0,1,1-.29-.05A.2.2,0,0,1,224.06,390.33Z"
        ></path>
        <circle className="cls-3" cx="218.61" cy="400.53" r="0.18"></circle>
        <path
          className="cls-3"
          d="M213.79,410.67a.2.2,0,0,1,0,.26.18.18,0,0,1-.25-.05.18.18,0,0,1,0-.25A.19.19,0,0,1,213.79,410.67Z"
        ></path>
        <path
          className="cls-3"
          d="M208.85,421a.24.24,0,0,1,.28.4.24.24,0,0,1-.28-.4Z"
        ></path>
        <path
          className="cls-3"
          d="M204.83,431.58a.22.22,0,0,1,0,.3.23.23,0,0,1-.3-.05.21.21,0,0,1,0-.3A.21.21,0,0,1,204.83,431.58Z"
        ></path>
        <path
          className="cls-3"
          d="M200.84,442.24a.22.22,0,1,1-.3-.05A.22.22,0,0,1,200.84,442.24Z"
        ></path>
        <path
          className="cls-3"
          d="M197.17,453a.21.21,0,0,1,0,.3.22.22,0,0,1-.25-.35A.22.22,0,0,1,197.17,453Z"
        ></path>
        <path
          className="cls-3"
          d="M193.84,463.91a.21.21,0,0,1-.34.24.21.21,0,0,1,0-.29A.21.21,0,0,1,193.84,463.91Z"
        ></path>
        <path
          className="cls-3"
          d="M190.85,474.89a.22.22,0,0,1,0,.3.21.21,0,0,1-.3-.05.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M188.2,486a.22.22,0,0,1,0,.29.21.21,0,1,1,0-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M185.59,497.07a.21.21,0,0,1,.3.05.21.21,0,0,1-.34.24A.21.21,0,0,1,185.59,497.07Z"
        ></path>
        <path
          className="cls-3"
          d="M183.9,508.35a.18.18,0,1,1-.25,0A.18.18,0,0,1,183.9,508.35Z"
        ></path>
        <path
          className="cls-3"
          d="M182.32,519.6a.23.23,0,0,1,0,.3.21.21,0,0,1-.3,0,.21.21,0,0,1,.05-.29A.22.22,0,0,1,182.32,519.6Z"
        ></path>
        <path
          className="cls-3"
          d="M181.05,530.92a.21.21,0,0,1,0,.29.22.22,0,0,1-.3,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,181.05,530.92Z"
        ></path>
        <path
          className="cls-3"
          d="M180.14,542.27a.2.2,0,0,1,0,.29.21.21,0,0,1-.29,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,180.14,542.27Z"
        ></path>
        <path
          className="cls-3"
          d="M179.58,553.64a.21.21,0,0,1-.05.3.22.22,0,0,1-.29-.05.21.21,0,1,1,.34-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M179.37,565a.21.21,0,0,1-.05.3.21.21,0,0,1-.24-.34A.21.21,0,0,1,179.37,565Z"
        ></path>
        <path
          className="cls-3"
          d="M179.52,576.41a.22.22,0,0,1-.35.25.22.22,0,0,1,.05-.3A.21.21,0,0,1,179.52,576.41Z"
        ></path>
        <path
          className="cls-3"
          d="M180,587.79a.21.21,0,1,1-.29,0A.2.2,0,0,1,180,587.79Z"
        ></path>
        <path
          className="cls-3"
          d="M180.55,599.07a.23.23,0,0,1,.33,0,.24.24,0,1,1-.39.28A.23.23,0,0,1,180.55,599.07Z"
        ></path>
        <circle className="cls-3" cx="181.89" cy="610.59" r="0.18"></circle>
        <path
          className="cls-3"
          d="M183.61,621.74a.22.22,0,1,1-.35.25.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M185.51,633a.22.22,0,0,1,0,.29.21.21,0,1,1,0-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M187.75,644.13a.21.21,0,0,1-.05.3.22.22,0,0,1-.3,0,.23.23,0,0,1,0-.3A.22.22,0,0,1,187.75,644.13Z"
        ></path>
        <path
          className="cls-3"
          d="M190.34,655.22a.22.22,0,0,1-.35.25.22.22,0,0,1,0-.3A.21.21,0,0,1,190.34,655.22Z"
        ></path>
        <path
          className="cls-3"
          d="M193.27,666.22a.22.22,0,1,1-.3-.05A.22.22,0,0,1,193.27,666.22Z"
        ></path>
        <path
          className="cls-3"
          d="M196.54,677.13a.22.22,0,0,1,0,.29.21.21,0,1,1,0-.29Z"
        ></path>
        <path
          className="cls-3"
          d="M200.14,687.93a.21.21,0,1,1-.29,0A.2.2,0,0,1,200.14,687.93Z"
        ></path>
        <path
          className="cls-3"
          d="M204.08,698.61a.22.22,0,1,1-.35.25.22.22,0,1,1,.35-.25Z"
        ></path>
        <circle className="cls-3" cx="208.17" cy="709.29" r="0.18"></circle>
        <circle className="cls-3" cx="212.76" cy="719.71" r="0.18"></circle>
        <path
          className="cls-3"
          d="M217.82,729.88a.18.18,0,1,1-.25,0A.18.18,0,0,1,217.82,729.88Z"
        ></path>
        <path
          className="cls-3"
          d="M223.07,740a.22.22,0,0,1,0,.3.21.21,0,0,1-.3,0,.2.2,0,0,1,.05-.29A.21.21,0,0,1,223.07,740Z"
        ></path>
        <path
          className="cls-3"
          d="M228.61,749.92a.21.21,0,0,1-.05.3.2.2,0,0,1-.29-.05.21.21,0,0,1,0-.29A.22.22,0,0,1,228.61,749.92Z"
        ></path>
        <path
          className="cls-3"
          d="M234.45,759.7a.21.21,0,1,1-.29-.05A.2.2,0,0,1,234.45,759.7Z"
        ></path>
        <path
          className="cls-3"
          d="M240.59,769.28a.21.21,0,1,1-.34.25.21.21,0,0,1,.05-.3A.22.22,0,0,1,240.59,769.28Z"
        ></path>
        <path
          className="cls-3"
          d="M247,778.68a.21.21,0,1,1-.29,0A.2.2,0,0,1,247,778.68Z"
        ></path>
        <path
          className="cls-3"
          d="M253.75,787.86a.22.22,0,0,1,0,.3.21.21,0,0,1-.3,0,.2.2,0,0,1,.05-.29A.21.21,0,0,1,253.75,787.86Z"
        ></path>
        <path
          className="cls-3"
          d="M260.76,796.84a.21.21,0,0,1-.05.3.22.22,0,1,1-.25-.35A.22.22,0,0,1,260.76,796.84Z"
        ></path>
        <path
          className="cls-3"
          d="M268,805.6a.21.21,0,0,1-.35.24.22.22,0,0,1,.05-.29A.21.21,0,0,1,268,805.6Z"
        ></path>
        <path
          className="cls-3"
          d="M275.58,814.12a.21.21,0,1,1-.29,0A.22.22,0,0,1,275.58,814.12Z"
        ></path>
        <path
          className="cls-3"
          d="M283.37,822.43a.18.18,0,0,1-.05.25.18.18,0,0,1-.21-.29A.19.19,0,0,1,283.37,822.43Z"
        ></path>
        <path
          className="cls-3"
          d="M291.43,830.47a.18.18,0,1,1-.26,0A.19.19,0,0,1,291.43,830.47Z"
        ></path>
        <path
          className="cls-3"
          d="M299.73,838.26a.18.18,0,1,1-.25,0A.18.18,0,0,1,299.73,838.26Z"
        ></path>
        <path
          className="cls-3"
          d="M308.3,845.77a.23.23,0,0,1-.05.3.22.22,0,1,1,.05-.3Z"
        ></path>
        <path
          className="cls-3"
          d="M317.07,853a.23.23,0,0,1-.05.3.22.22,0,0,1-.25-.35A.23.23,0,0,1,317.07,853Z"
        ></path>
        <path
          className="cls-3"
          d="M326.06,860a.21.21,0,0,1-.05.29.21.21,0,1,1-.25-.34A.21.21,0,0,1,326.06,860Z"
        ></path>
        <path
          className="cls-3"
          d="M335.26,866.72a.21.21,0,0,1-.05.3.21.21,0,0,1-.29,0,.22.22,0,0,1,0-.3A.23.23,0,0,1,335.26,866.72Z"
        ></path>
        <path
          className="cls-3"
          d="M344.67,873.14a.22.22,0,0,1-.35.25.22.22,0,0,1,.35-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M354.24,879.28a.18.18,0,1,1-.25,0A.18.18,0,0,1,354.24,879.28Z"
        ></path>
        <path
          className="cls-3"
          d="M364,885.1a.18.18,0,0,1,0,.25.18.18,0,0,1-.21-.29A.19.19,0,0,1,364,885.1Z"
        ></path>
        <circle className="cls-3" cx="373.84" cy="890.72" r="0.18"></circle>
        <path
          className="cls-3"
          d="M378.46,907.21a.15.15,0,1,1-.21,0A.15.15,0,0,1,378.46,907.21Z"
        ></path>
        <path
          className="cls-3"
          d="M388.74,912.11a.15.15,0,1,1-.21,0A.15.15,0,0,1,388.74,912.11Z"
        ></path>
        <path
          className="cls-3"
          d="M399.17,916.69a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,0,1,0-.21A.16.16,0,0,1,399.17,916.69Z"
        ></path>
        <circle className="cls-3" cx="409.6" cy="921.05" r="0.18"></circle>
        <path
          className="cls-3"
          d="M420.4,924.92a.15.15,0,1,1-.21,0A.15.15,0,0,1,420.4,924.92Z"
        ></path>
        <path
          className="cls-3"
          d="M431.19,928.55a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M442.08,931.86a.15.15,0,1,1-.21,0A.15.15,0,0,1,442.08,931.86Z"
        ></path>
        <path
          className="cls-3"
          d="M453.1,934.82a.18.18,0,1,1-.26,0A.19.19,0,0,1,453.1,934.82Z"
        ></path>
        <path
          className="cls-3"
          d="M464.14,937.49a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,464.14,937.49Z"
        ></path>
        <path
          className="cls-3"
          d="M475.32,939.79a.17.17,0,0,1-.05.25.18.18,0,0,1-.21-.29A.19.19,0,0,1,475.32,939.79Z"
        ></path>
        <circle className="cls-3" cx="486.38" cy="941.88" r="0.18"></circle>
        <path
          className="cls-3"
          d="M497.77,943.44a.15.15,0,0,1-.25.17.16.16,0,0,1,0-.21A.16.16,0,0,1,497.77,943.44Z"
        ></path>
        <circle className="cls-3" cx="508.96" cy="944.84" r="0.18"></circle>
        <path
          className="cls-3"
          d="M520.45,945.7a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,.05-.25A.18.18,0,0,1,520.45,945.7Z"
        ></path>
        <path
          className="cls-3"
          d="M531.82,946.33a.19.19,0,0,1,0,.26.2.2,0,0,1-.26-.05.18.18,0,0,1,0-.25A.19.19,0,0,1,531.82,946.33Z"
        ></path>
        <path
          className="cls-3"
          d="M543,946.61a.15.15,0,1,1,.18.24.15.15,0,0,1-.18-.24Z"
        ></path>
        <circle className="cls-3" cx="554.44" cy="946.68" r="0.18"></circle>
        <path
          className="cls-3"
          d="M565.94,946.2a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.16.16,0,0,1,0-.21A.15.15,0,0,1,565.94,946.2Z"
        ></path>
        <path
          className="cls-3"
          d="M577.3,945.47a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <circle className="cls-3" cx="588.52" cy="944.49" r="0.18"></circle>
        <path
          className="cls-3"
          d="M600,943a.19.19,0,0,1,0,.26.18.18,0,1,1,0-.26Z"
        ></path>
        <path
          className="cls-3"
          d="M611.19,941.24a.15.15,0,0,1-.25.18.15.15,0,0,1,0-.21A.16.16,0,0,1,611.19,941.24Z"
        ></path>
        <path
          className="cls-3"
          d="M622.38,939.16a.15.15,0,1,1-.21,0A.15.15,0,0,1,622.38,939.16Z"
        ></path>
        <path
          className="cls-3"
          d="M633.51,936.75a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.17Z"
        ></path>
        <path
          className="cls-3"
          d="M644.56,934a.15.15,0,1,1-.21,0A.15.15,0,0,1,644.56,934Z"
        ></path>
        <path
          className="cls-3"
          d="M655.52,930.92a.15.15,0,0,1,0,.21.15.15,0,1,1-.17-.24A.15.15,0,0,1,655.52,930.92Z"
        ></path>
        <path
          className="cls-3"
          d="M666.41,927.5a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,0-.25A.18.18,0,0,1,666.41,927.5Z"
        ></path>
        <path
          className="cls-3"
          d="M677.14,923.79a.15.15,0,0,1,0,.21.15.15,0,0,1-.18-.25A.16.16,0,0,1,677.14,923.79Z"
        ></path>
        <path
          className="cls-3"
          d="M687.79,919.74a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.24A.16.16,0,0,1,687.79,919.74Z"
        ></path>
        <path
          className="cls-3"
          d="M698.33,915.36a.17.17,0,0,1-.05.25.18.18,0,0,1-.21-.29A.19.19,0,0,1,698.33,915.36Z"
        ></path>
        <circle className="cls-3" cx="708.56" cy="910.79" r="0.18"></circle>
        <circle className="cls-3" cx="718.8" cy="905.8" r="0.18"></circle>
        <path
          className="cls-3"
          d="M729,900.43a.15.15,0,1,1-.21,0A.15.15,0,0,1,729,900.43Z"
        ></path>
        <path
          className="cls-3"
          d="M738.92,894.84a.15.15,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M748.67,889a.17.17,0,0,1,0,.22.15.15,0,0,1-.18-.25A.16.16,0,0,1,748.67,889Z"
        ></path>
        <path
          className="cls-3"
          d="M758.24,882.78a.15.15,0,0,1-.25.18.16.16,0,0,1,0-.21A.16.16,0,0,1,758.24,882.78Z"
        ></path>
        <path
          className="cls-3"
          d="M767.41,876.29a.15.15,0,1,1,.17.25.16.16,0,0,1-.21,0A.16.16,0,0,1,767.41,876.29Z"
        ></path>
        <path
          className="cls-3"
          d="M776.55,869.77a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21A.17.17,0,0,1,776.55,869.77Z"
        ></path>
        <path
          className="cls-3"
          d="M785.57,862.56a.15.15,0,1,1,0,.21A.15.15,0,0,1,785.57,862.56Z"
        ></path>
        <path
          className="cls-3"
          d="M794.54,855.32a.15.15,0,0,1-.25.18.16.16,0,0,1,0-.21A.15.15,0,0,1,794.54,855.32Z"
        ></path>
        <path
          className="cls-3"
          d="M803.08,847.79a.16.16,0,0,1,0,.21.15.15,0,1,1-.18-.24A.15.15,0,0,1,803.08,847.79Z"
        ></path>
        <path
          className="cls-3"
          d="M811.42,840a.19.19,0,0,1,0,.26.2.2,0,0,1-.26,0,.19.19,0,0,1,.3-.22Z"
        ></path>
        <path
          className="cls-3"
          d="M819.49,832a.18.18,0,1,1-.25,0A.18.18,0,0,1,819.49,832Z"
        ></path>
        <path
          className="cls-3"
          d="M827.09,823.69a.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.24Z"
        ></path>
        <path
          className="cls-3"
          d="M834.88,815.23a.15.15,0,1,1-.21,0A.15.15,0,0,1,834.88,815.23Z"
        ></path>
        <path
          className="cls-3"
          d="M842,806.47a.15.15,0,1,1,0,.21A.15.15,0,0,1,842,806.47Z"
        ></path>
        <circle className="cls-3" cx="849.14" cy="797.66" r="0.18"></circle>
        <path
          className="cls-3"
          d="M855.85,788.4a.16.16,0,0,1,.21,0,.17.17,0,0,1,0,.22.16.16,0,0,1-.21,0A.15.15,0,0,1,855.85,788.4Z"
        ></path>
        <path
          className="cls-3"
          d="M862.36,779.06a.15.15,0,1,1,0,.21A.15.15,0,0,1,862.36,779.06Z"
        ></path>
        <path
          className="cls-3"
          d="M868.78,769.58a.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16A.12.12,0,0,1,868.78,769.58Z"
        ></path>
        <path
          className="cls-3"
          d="M874.74,759.85a.15.15,0,1,1-.24.18.15.15,0,0,1,0-.21A.16.16,0,0,1,874.74,759.85Z"
        ></path>
        <path
          className="cls-3"
          d="M880.39,750a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M885.47,740.11a.18.18,0,0,1,.3-.21.18.18,0,0,1,0,.25A.19.19,0,0,1,885.47,740.11Z"
        ></path>
        <path
          className="cls-3"
          d="M890.79,729.71a.15.15,0,0,1,0,.21.15.15,0,0,1-.18-.24A.16.16,0,0,1,890.79,729.71Z"
        ></path>
        <path
          className="cls-3"
          d="M895.29,719.54a.15.15,0,1,1,.21,0A.15.15,0,0,1,895.29,719.54Z"
        ></path>
        <path
          className="cls-3"
          d="M899.72,709.05a.15.15,0,0,1,.24-.18.16.16,0,0,1,0,.21A.15.15,0,0,1,899.72,709.05Z"
        ></path>
        <path
          className="cls-3"
          d="M903.88,698.24a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.14.14,0,0,1,903.88,698.24Z"
        ></path>
        <path
          className="cls-3"
          d="M907.83,687.73a.16.16,0,0,1-.21,0,.16.16,0,0,1,0-.22.16.16,0,0,1,.21,0A.15.15,0,0,1,907.83,687.73Z"
        ></path>
        <path
          className="cls-3"
          d="M911.09,676.85a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M914.48,665.73a.15.15,0,1,1-.24.18.16.16,0,0,1,0-.21A.14.14,0,0,1,914.48,665.73Z"
        ></path>
        <path
          className="cls-3"
          d="M917.05,654.88a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21A.17.17,0,0,1,917.05,654.88Z"
        ></path>
        <path
          className="cls-3"
          d="M919.54,643.77a.17.17,0,0,1,0-.22.17.17,0,0,1,.22,0,.16.16,0,0,1,0,.21A.16.16,0,0,1,919.54,643.77Z"
        ></path>
        <path
          className="cls-3"
          d="M921.69,632.58a.15.15,0,1,1,.21,0A.15.15,0,0,1,921.69,632.58Z"
        ></path>
        <path
          className="cls-3"
          d="M923.51,621.34a.15.15,0,1,1,.21,0A.15.15,0,0,1,923.51,621.34Z"
        ></path>
        <path
          className="cls-3"
          d="M925,610.05a.15.15,0,1,1,.24-.17.16.16,0,0,1,0,.21A.15.15,0,0,1,925,610.05Z"
        ></path>
        <path
          className="cls-3"
          d="M926.13,598.73a.15.15,0,1,1,.21,0A.15.15,0,0,1,926.13,598.73Z"
        ></path>
        <path
          className="cls-3"
          d="M926.93,587.37a.15.15,0,1,1,.21,0A.15.15,0,0,1,926.93,587.37Z"
        ></path>
        <path
          className="cls-3"
          d="M927.39,576a.15.15,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M927.51,564.61a.15.15,0,1,1,.21,0A.16.16,0,0,1,927.51,564.61Z"
        ></path>
        <path
          className="cls-3"
          d="M927.29,553.22a.15.15,0,1,1,.21,0A.15.15,0,0,1,927.29,553.22Z"
        ></path>
        <path
          className="cls-3"
          d="M926.73,541.85a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,1,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M926,530.53a.15.15,0,0,1-.18-.24.16.16,0,0,1,.21,0A.14.14,0,0,1,926,530.53Z"
        ></path>
        <path
          className="cls-3"
          d="M924.62,519a.15.15,0,1,1,0,.21A.15.15,0,0,1,924.62,519Z"
        ></path>
        <path
          className="cls-3"
          d="M923,507.91a.15.15,0,0,1,0-.21.15.15,0,0,1,.18.24A.16.16,0,0,1,923,507.91Z"
        ></path>
        <path
          className="cls-3"
          d="M921.09,496.68a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M918.84,485.52a.16.16,0,0,1,0-.21.16.16,0,0,1,.21,0,.17.17,0,0,1,0,.22A.17.17,0,0,1,918.84,485.52Z"
        ></path>
        <path
          className="cls-3"
          d="M916.31,474.25a.12.12,0,1,1,.15.19.12.12,0,0,1-.17,0A.11.11,0,0,1,916.31,474.25Z"
        ></path>
        <path
          className="cls-3"
          d="M913.35,463.42a.15.15,0,1,1,.24-.17.15.15,0,0,1,0,.21A.15.15,0,0,1,913.35,463.42Z"
        ></path>
        <path
          className="cls-3"
          d="M910.11,452.51a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.15.15,0,0,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M906.54,441.7a.15.15,0,0,1,.25-.18.15.15,0,0,1,0,.21A.16.16,0,0,1,906.54,441.7Z"
        ></path>
        <path
          className="cls-3"
          d="M902.66,431a.15.15,0,1,1,.24-.17.16.16,0,0,1,0,.21A.15.15,0,0,1,902.66,431Z"
        ></path>
        <path
          className="cls-3"
          d="M898.45,420.41a.16.16,0,0,1,0-.21.15.15,0,0,1,.18.25A.15.15,0,0,1,898.45,420.41Z"
        ></path>
        <path
          className="cls-3"
          d="M893.93,410a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1,0,.21A.15.15,0,0,1,893.93,410Z"
        ></path>
        <path
          className="cls-3"
          d="M889.07,399.67a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.18.18,0,0,1-.3.21Z"
        ></path>
        <circle className="cls-3" cx="884.08" cy="389.41" r="0.18"></circle>
        <path
          className="cls-3"
          d="M878.52,379.49a.16.16,0,0,1,0-.21.15.15,0,0,1,.18.25A.16.16,0,0,1,878.52,379.49Z"
        ></path>
        <path
          className="cls-3"
          d="M872.78,369.66a.15.15,0,1,1,.25-.18.15.15,0,0,1,0,.21A.16.16,0,0,1,872.78,369.66Z"
        ></path>
        <path
          className="cls-3"
          d="M866.76,360a.15.15,0,0,1,.24-.18.16.16,0,0,1,0,.21A.15.15,0,0,1,866.76,360Z"
        ></path>
        <path
          className="cls-3"
          d="M860.44,350.52a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.16.16,0,0,1,0,.22A.15.15,0,0,1,860.44,350.52Z"
        ></path>
        <path
          className="cls-3"
          d="M853.85,341.24a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1,0,.21A.14.14,0,0,1,853.85,341.24Z"
        ></path>
        <path
          className="cls-3"
          d="M847,332.16a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,1,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M839.84,323.29a.15.15,0,1,1,.24-.18.15.15,0,1,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M832.44,314.64a.15.15,0,1,1,.21,0A.16.16,0,0,1,832.44,314.64Z"
        ></path>
        <path
          className="cls-3"
          d="M824.78,306.21a.15.15,0,1,1,.21,0A.15.15,0,0,1,824.78,306.21Z"
        ></path>
        <circle className="cls-3" cx="817" cy="297.92" r="0.18"></circle>
        <path
          className="cls-3"
          d="M808.71,290.08a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26A.18.18,0,0,1,808.71,290.08Z"
        ></path>
        <circle className="cls-3" cx="800.47" cy="282.26" r="0.18"></circle>
        <path
          className="cls-3"
          d="M791.74,274.9a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21A.16.16,0,0,1,791.74,274.9Z"
        ></path>
        <path
          className="cls-3"
          d="M782.92,267.7a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1,0,.21A.15.15,0,0,1,782.92,267.7Z"
        ></path>
        <path
          className="cls-3"
          d="M773.86,260.8a.18.18,0,1,1,.25,0A.18.18,0,0,1,773.86,260.8Z"
        ></path>
        <path
          className="cls-3"
          d="M764.64,254.13a.15.15,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M755.2,247.75a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M745.58,241.67a.15.15,0,0,1,.25-.18.15.15,0,0,1-.25.18Z"
        ></path>
        <path
          className="cls-3"
          d="M735.78,235.87a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1-.25.18Z"
        ></path>
        <path
          className="cls-3"
          d="M725.87,230.18a.13.13,0,0,1,.17,0,.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,725.87,230.18Z"
        ></path>
        <circle className="cls-3" cx="715.81" cy="225.08" r="0.18"></circle>
        <path
          className="cls-3"
          d="M705.4,220.27a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21A.16.16,0,0,1,705.4,220.27Z"
        ></path>
        <path
          className="cls-3"
          d="M695,215.69a.15.15,0,1,1,.21,0A.15.15,0,0,1,695,215.69Z"
        ></path>
        <path
          className="cls-3"
          d="M684.43,211.42a.15.15,0,0,1,0-.21.15.15,0,0,1,.18.24A.16.16,0,0,1,684.43,211.42Z"
        ></path>
        <path
          className="cls-3"
          d="M673.73,207.48a.18.18,0,1,1,.25,0A.18.18,0,0,1,673.73,207.48Z"
        ></path>
        <path
          className="cls-3"
          d="M663,203.83a.16.16,0,0,1,0-.21.17.17,0,0,1,.22,0,.16.16,0,0,1,0,.21A.15.15,0,0,1,663,203.83Z"
        ></path>
        <path
          className="cls-3"
          d="M652.06,200.52a.16.16,0,0,1,0-.21.15.15,0,0,1,.18.25A.16.16,0,0,1,652.06,200.52Z"
        ></path>
        <path
          className="cls-3"
          d="M641.08,197.54a.15.15,0,0,1,0-.21.15.15,0,0,1,.18.25A.16.16,0,0,1,641.08,197.54Z"
        ></path>
        <circle className="cls-3" cx="630.13" cy="194.8" r="0.18"></circle>
        <path
          className="cls-3"
          d="M619.07,192.61a.15.15,0,0,1-.18-.25.15.15,0,0,1,.21,0A.16.16,0,0,1,619.07,192.61Z"
        ></path>
        <path
          className="cls-3"
          d="M607.64,190.59a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21A.16.16,0,0,1,607.64,190.59Z"
        ></path>
        <path
          className="cls-3"
          d="M596.35,189a.18.18,0,1,1,.26,0A.19.19,0,0,1,596.35,189Z"
        ></path>
        <path
          className="cls-3"
          d="M585.07,187.63a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M573.74,186.42a.18.18,0,1,1,0,.26A.19.19,0,0,1,573.74,186.42Z"
        ></path>
        <path
          className="cls-3"
          d="M562.6,185.85a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M551.22,185.56a.15.15,0,1,1-.21,0A.15.15,0,0,1,551.22,185.56Z"
        ></path>
        <path
          className="cls-3"
          d="M539.8,185.82a.15.15,0,1,1,0-.21A.15.15,0,0,1,539.8,185.82Z"
        ></path>
        <path
          className="cls-3"
          d="M528.18,186.2a.19.19,0,0,1,.05-.26.17.17,0,0,1,.25.05.18.18,0,0,1,0,.25A.19.19,0,0,1,528.18,186.2Z"
        ></path>
        <path
          className="cls-3"
          d="M516.85,186.91a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.17.17,0,0,1,0,.22A.16.16,0,0,1,516.85,186.91Z"
        ></path>
        <path
          className="cls-3"
          d="M505.49,188a.19.19,0,0,1,0-.26.18.18,0,0,1,.25,0,.18.18,0,0,1-.29.21Z"
        ></path>
        <path
          className="cls-3"
          d="M494.46,189.21a.15.15,0,0,1-.25.18.15.15,0,0,1,0-.21A.16.16,0,0,1,494.46,189.21Z"
        ></path>
        <path
          className="cls-3"
          d="M482.94,191.15a.18.18,0,1,1,.25,0A.18.18,0,0,1,482.94,191.15Z"
        ></path>
        <path
          className="cls-3"
          d="M471.77,193.22A.15.15,0,1,1,472,193a.16.16,0,0,1,0,.21A.14.14,0,0,1,471.77,193.22Z"
        ></path>
        <circle className="cls-3" cx="460.76" cy="195.55" r="0.18"></circle>
        <path
          className="cls-3"
          d="M449.86,198.19a.18.18,0,1,1-.25,0A.18.18,0,0,1,449.86,198.19Z"
        ></path>
        <path
          className="cls-3"
          d="M438.87,201.28a.15.15,0,1,1-.21,0A.15.15,0,0,1,438.87,201.28Z"
        ></path>
        <path
          className="cls-3"
          d="M428,204.69a.15.15,0,1,1-.21,0A.15.15,0,0,1,428,204.69Z"
        ></path>
        <path
          className="cls-3"
          d="M417.28,208.4a.19.19,0,0,1,0,.25.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.19.19,0,0,1,417.28,208.4Z"
        ></path>
        <circle className="cls-3" cx="406.49" cy="212.55" r="0.18"></circle>
        <path
          className="cls-3"
          d="M396.09,216.83a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.25A.15.15,0,0,1,396.09,216.83Z"
        ></path>
        <circle className="cls-3" cx="385.59" cy="221.59" r="0.18"></circle>
        <path
          className="cls-3"
          d="M375.5,226.47a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,.05-.25A.18.18,0,0,1,375.5,226.47Z"
        ></path>
        <circle className="cls-3" cx="365.27" cy="231.86" r="0.18"></circle>
        <circle className="cls-3" cx="355.35" cy="237.45" r="0.18"></circle>
        <path
          className="cls-3"
          d="M345.45,243.44a.18.18,0,0,1,0-.25.19.19,0,0,1,.26,0,.18.18,0,0,1,0,.25A.19.19,0,0,1,345.45,243.44Z"
        ></path>
        <path
          className="cls-3"
          d="M335.93,249.36a.18.18,0,0,1,.25,0,.19.19,0,0,1,0,.26.2.2,0,0,1-.26,0A.17.17,0,0,1,335.93,249.36Z"
        ></path>
        <circle className="cls-3" cx="326.65" cy="255.96" r="0.18"></circle>
        <circle className="cls-3" cx="317.47" cy="262.7" r="0.18"></circle>
        <circle className="cls-3" cx="308.49" cy="269.7" r="0.18"></circle>
        <path
          className="cls-3"
          d="M299.62,276.82a.19.19,0,1,1,0,.26A.2.2,0,0,1,299.62,276.82Z"
        ></path>
        <path
          className="cls-3"
          d="M291.34,284.39a.18.18,0,1,1-.3.21.18.18,0,0,1,0-.25A.19.19,0,0,1,291.34,284.39Z"
        ></path>
        <path
          className="cls-3"
          d="M283,292.17a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,283,292.17Z"
        ></path>
        <circle className="cls-3" cx="274.8" cy="300.31" r="0.18"></circle>
        <path
          className="cls-3"
          d="M267.12,308.46a.19.19,0,0,1-.05.26.18.18,0,0,1-.25,0,.19.19,0,0,1,.3-.22Z"
        ></path>
        <circle className="cls-3" cx="259.39" cy="317.06" r="0.18"></circle>
        <circle className="cls-3" cx="252.06" cy="325.78" r="0.18"></circle>
        <path
          className="cls-3"
          d="M245.15,334.61a.18.18,0,1,1-.25,0A.18.18,0,0,1,245.15,334.61Z"
        ></path>
        <path
          className="cls-3"
          d="M238.13,343.73a.15.15,0,1,1,0,.21A.15.15,0,0,1,238.13,343.73Z"
        ></path>
        <path
          className="cls-3"
          d="M231.85,353.09a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,0-.25A.18.18,0,0,1,231.85,353.09Z"
        ></path>
        <path
          className="cls-3"
          d="M225.32,362.83a.17.17,0,0,1,.05-.25.18.18,0,0,1,.21.29A.19.19,0,0,1,225.32,362.83Z"
        ></path>
        <circle className="cls-3" cx="219.53" cy="372.44" r="0.18"></circle>
        <path
          className="cls-3"
          d="M214,382.22a.18.18,0,0,1-.05.25.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.2.2,0,0,1,214,382.22Z"
        ></path>
        <circle className="cls-3" cx="208.53" cy="392.37" r="0.18"></circle>
        <path
          className="cls-3"
          d="M203.6,402.49a.15.15,0,1,1-.24.18.15.15,0,0,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M198.86,412.84a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M194.22,423.3a.15.15,0,0,1,.21,0,.16.16,0,0,1,0,.21.15.15,0,1,1-.18-.24Z"
        ></path>
        <path
          className="cls-3"
          d="M190.34,433.93a.18.18,0,1,1-.25,0A.18.18,0,0,1,190.34,433.93Z"
        ></path>
        <path
          className="cls-3"
          d="M186.53,444.68a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.16.16,0,0,1,0-.21A.17.17,0,0,1,186.53,444.68Z"
        ></path>
        <path
          className="cls-3"
          d="M183.08,455.51a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,.05-.25A.18.18,0,0,1,183.08,455.51Z"
        ></path>
        <path
          className="cls-3"
          d="M179.91,466.47a.15.15,0,0,1,0,.21.15.15,0,1,1-.17-.24A.15.15,0,0,1,179.91,466.47Z"
        ></path>
        <circle className="cls-3" cx="176.97" cy="477.59" r="0.18"></circle>
        <circle className="cls-3" cx="174.49" cy="488.7" r="0.18"></circle>
        <path
          className="cls-3"
          d="M172.48,499.78a.18.18,0,1,1-.25,0A.18.18,0,0,1,172.48,499.78Z"
        ></path>
        <path
          className="cls-3"
          d="M170.67,511a.18.18,0,0,1,0,.25.18.18,0,0,1-.21-.29A.19.19,0,0,1,170.67,511Z"
        ></path>
        <path
          className="cls-3"
          d="M169.21,522.29a.2.2,0,0,1-.05.29.21.21,0,0,1-.29,0,.21.21,0,0,1,0-.3A.2.2,0,0,1,169.21,522.29Z"
        ></path>
        <circle className="cls-3" cx="167.9" cy="533.74" r="0.18"></circle>
        <path
          className="cls-3"
          d="M167.22,545a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <circle className="cls-3" cx="166.64" cy="556.48" r="0.18"></circle>
        <path
          className="cls-3"
          d="M166.64,567.77a.15.15,0,0,1-.25.18.16.16,0,0,1,0-.21A.15.15,0,0,1,166.64,567.77Z"
        ></path>
        <path
          className="cls-3"
          d="M166.88,579.14a.18.18,0,1,1-.25,0A.18.18,0,0,1,166.88,579.14Z"
        ></path>
        <path
          className="cls-3"
          d="M167.42,590.53a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.25A.16.16,0,0,1,167.42,590.53Z"
        ></path>
        <path
          className="cls-3"
          d="M168.09,601.82a.19.19,0,0,1,.26,0,.19.19,0,0,1,0,.25.19.19,0,0,1-.26,0A.18.18,0,0,1,168.09,601.82Z"
        ></path>
        <path
          className="cls-3"
          d="M169.56,613.2a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.25A.15.15,0,0,1,169.56,613.2Z"
        ></path>
        <circle className="cls-3" cx="171.02" cy="624.56" r="0.18"></circle>
        <circle className="cls-3" cx="172.93" cy="635.79" r="0.18"></circle>
        <circle className="cls-3" cx="175.18" cy="646.95" r="0.18"></circle>
        <circle className="cls-3" cx="177.76" cy="658.04" r="0.18"></circle>
        <path
          className="cls-3"
          d="M180.82,668.94a.18.18,0,1,1-.25,0A.18.18,0,0,1,180.82,668.94Z"
        ></path>
        <path
          className="cls-3"
          d="M184,679.87a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,184,679.87Z"
        ></path>
        <circle className="cls-3" cx="187.48" cy="690.77" r="0.18"></circle>
        <circle className="cls-3" cx="191.37" cy="701.47" r="0.18"></circle>
        <path
          className="cls-3"
          d="M195.72,712a.18.18,0,1,1-.25,0A.18.18,0,0,1,195.72,712Z"
        ></path>
        <path
          className="cls-3"
          d="M200.24,722.4a.17.17,0,0,1,0,.25.18.18,0,1,1,0-.25Z"
        ></path>
        <path
          className="cls-3"
          d="M205.08,732.71a.17.17,0,0,1-.05.25.18.18,0,1,1-.21-.29A.19.19,0,0,1,205.08,732.71Z"
        ></path>
        <circle className="cls-3" cx="210.07" cy="742.97" r="0.18"></circle>
        <circle className="cls-3" cx="215.51" cy="752.98" r="0.18"></circle>
        <path
          className="cls-3"
          d="M221.39,762.71a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.2.2,0,0,1,0-.26A.18.18,0,0,1,221.39,762.71Z"
        ></path>
        <circle className="cls-3" cx="227.27" cy="772.47" r="0.18"></circle>
        <path
          className="cls-3"
          d="M233.73,781.84a.18.18,0,1,1-.25,0A.18.18,0,0,1,233.73,781.84Z"
        ></path>
        <path
          className="cls-3"
          d="M240.33,791.12a.18.18,0,0,1,0,.25.19.19,0,0,1-.26,0,.18.18,0,0,1,0-.25A.19.19,0,0,1,240.33,791.12Z"
        ></path>
        <circle className="cls-3" cx="247.05" cy="800.31" r="0.18"></circle>
        <path
          className="cls-3"
          d="M254.33,809.07a.19.19,0,0,1,0,.26.18.18,0,0,1-.25-.05.18.18,0,0,1,.29-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M261.73,817.73a.18.18,0,1,1-.29.21.19.19,0,0,1,0-.26A.18.18,0,0,1,261.73,817.73Z"
        ></path>
        <path
          className="cls-3"
          d="M269.39,826.15a.19.19,0,0,1,0,.26.18.18,0,1,1-.21-.3A.18.18,0,0,1,269.39,826.15Z"
        ></path>
        <circle className="cls-3" cx="277.15" cy="834.45" r="0.18"></circle>
        <circle className="cls-3" cx="285.29" cy="842.41" r="0.18"></circle>
        <path
          className="cls-3"
          d="M293.8,850a.15.15,0,1,1-.25.17.16.16,0,0,1,0-.21A.17.17,0,0,1,293.8,850Z"
        ></path>
        <path
          className="cls-3"
          d="M302.43,857.46a.19.19,0,0,1-.3.22.18.18,0,0,1,.05-.26A.19.19,0,0,1,302.43,857.46Z"
        ></path>
        <path
          className="cls-3"
          d="M311.25,864.66a.18.18,0,1,1-.25,0A.18.18,0,0,1,311.25,864.66Z"
        ></path>
        <path
          className="cls-3"
          d="M320.27,871.6a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M329.51,878.25a.15.15,0,1,1-.21,0A.15.15,0,0,1,329.51,878.25Z"
        ></path>
        <circle className="cls-3" cx="338.82" cy="884.71" r="0.18"></circle>
        <path
          className="cls-3"
          d="M348.59,890.69a.19.19,0,0,1,0,.26.18.18,0,0,1-.25,0,.19.19,0,0,1,0-.26A.18.18,0,0,1,348.59,890.69Z"
        ></path>
        <path
          className="cls-3"
          d="M358.37,896.51a.16.16,0,0,1,0,.21.16.16,0,0,1-.21,0,.17.17,0,0,1,0-.22A.17.17,0,0,1,358.37,896.51Z"
        ></path>
        <path
          className="cls-3"
          d="M368.36,902a.19.19,0,0,1,0,.26.19.19,0,0,1-.26,0,.18.18,0,0,1,.05-.25A.18.18,0,0,1,368.36,902Z"
        ></path>
        <path
          className="cls-3"
          d="M372.79,918.77a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,372.79,918.77Z"
        ></path>
        <path
          className="cls-3"
          d="M383.12,923.48a.15.15,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M393.51,928.1a.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0,.12.12,0,1,1,.2-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M404,932.57a.14.14,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,404,932.57Z"
        ></path>
        <path
          className="cls-3"
          d="M414.69,936.55a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.11.11,0,0,1,414.69,936.55Z"
        ></path>
        <path
          className="cls-3"
          d="M425.51,940.05a.15.15,0,0,1,0,.21.15.15,0,0,1-.21,0,.16.16,0,0,1,0-.21A.15.15,0,0,1,425.51,940.05Z"
        ></path>
        <path
          className="cls-3"
          d="M436.34,943.6a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.13.13,0,0,1,436.34,943.6Z"
        ></path>
        <path
          className="cls-3"
          d="M447.31,946.65a.12.12,0,0,1-.14-.2.12.12,0,1,1,.14.2Z"
        ></path>
        <circle className="cls-3" cx="458.29" cy="949.28" r="0.12"></circle>
        <path
          className="cls-3"
          d="M469.54,951.6a.15.15,0,1,1-.24.18.15.15,0,0,1,0-.21A.16.16,0,0,1,469.54,951.6Z"
        ></path>
        <path
          className="cls-3"
          d="M480.68,953.88a.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,480.68,953.88Z"
        ></path>
        <path
          className="cls-3"
          d="M492,955.47a.12.12,0,0,1,0,.17.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.17A.13.13,0,0,1,492,955.47Z"
        ></path>
        <path
          className="cls-3"
          d="M503.23,957.07a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.13.13,0,0,1,503.23,957.07Z"
        ></path>
        <path
          className="cls-3"
          d="M514.61,958a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.15.15,0,0,1,514.61,958Z"
        ></path>
        <path
          className="cls-3"
          d="M525.92,959a.12.12,0,0,1-.14-.2.14.14,0,0,1,.17,0A.13.13,0,0,1,525.92,959Z"
        ></path>
        <path
          className="cls-3"
          d="M537.14,959.19a.16.16,0,0,1,.21,0,.15.15,0,1,1-.25.18A.15.15,0,0,1,537.14,959.19Z"
        ></path>
        <path
          className="cls-3"
          d="M548.68,959.53a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.14.14,0,0,1,.17,0A.12.12,0,0,1,548.68,959.53Z"
        ></path>
        <path
          className="cls-3"
          d="M560.07,959.32a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.15.19Z"
        ></path>
        <path
          className="cls-3"
          d="M571.47,958.61a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16A.12.12,0,0,1,571.47,958.61Z"
        ></path>
        <path
          className="cls-3"
          d="M582.84,957.73a.15.15,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.17Z"
        ></path>
        <path
          className="cls-3"
          d="M594.11,956.72a.11.11,0,0,1-.16,0,.12.12,0,1,1,.19-.14A.12.12,0,0,1,594.11,956.72Z"
        ></path>
        <path
          className="cls-3"
          d="M605.4,955.2a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.14.14,0,0,1,605.4,955.2Z"
        ></path>
        <path
          className="cls-3"
          d="M616.63,953.35a.14.14,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,616.63,953.35Z"
        ></path>
        <path
          className="cls-3"
          d="M627.81,951.17a.12.12,0,0,1-.14-.2.14.14,0,0,1,.17,0A.13.13,0,0,1,627.81,951.17Z"
        ></path>
        <path
          className="cls-3"
          d="M639,948.49a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <circle className="cls-3" cx="649.88" cy="945.76" r="0.12"></circle>
        <path
          className="cls-3"
          d="M661,942.54a.15.15,0,1,1-.21,0A.15.15,0,0,1,661,942.54Z"
        ></path>
        <path
          className="cls-3"
          d="M671.8,939.08a.16.16,0,0,1,0,.22.15.15,0,0,1-.17-.25A.16.16,0,0,1,671.8,939.08Z"
        ></path>
        <path
          className="cls-3"
          d="M682.49,935.51a.12.12,0,0,1-.14-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,682.49,935.51Z"
        ></path>
        <path
          className="cls-3"
          d="M693.12,931.44a.12.12,0,0,1-.14-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,693.12,931.44Z"
        ></path>
        <path
          className="cls-3"
          d="M703.64,927.06a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,703.64,927.06Z"
        ></path>
        <path
          className="cls-3"
          d="M714,922.37a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,714,922.37Z"
        ></path>
        <path
          className="cls-3"
          d="M724.25,917.39a.12.12,0,1,1-.14-.19.12.12,0,0,1,.17,0A.12.12,0,0,1,724.25,917.39Z"
        ></path>
        <path
          className="cls-3"
          d="M734.34,912.12a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,734.34,912.12Z"
        ></path>
        <path
          className="cls-3"
          d="M744.14,906.36a.11.11,0,0,1,.16,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,744.14,906.36Z"
        ></path>
        <path
          className="cls-3"
          d="M754.05,900.7a.12.12,0,0,1-.15-.19.12.12,0,0,1,.17,0A.11.11,0,0,1,754.05,900.7Z"
        ></path>
        <path
          className="cls-3"
          d="M763.64,894.57a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,763.64,894.57Z"
        ></path>
        <path
          className="cls-3"
          d="M773.05,888.17a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,773.05,888.17Z"
        ></path>
        <path
          className="cls-3"
          d="M782.28,881.49a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,782.28,881.49Z"
        ></path>
        <path
          className="cls-3"
          d="M791.3,874.55a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,791.3,874.55Z"
        ></path>
        <path
          className="cls-3"
          d="M800.13,867.36a.13.13,0,0,1-.15-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,800.13,867.36Z"
        ></path>
        <path
          className="cls-3"
          d="M808.74,859.91a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,808.74,859.91Z"
        ></path>
        <path
          className="cls-3"
          d="M817.15,852a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17A.12.12,0,0,1,817.15,852Z"
        ></path>
        <path
          className="cls-3"
          d="M825.29,844.27a.12.12,0,1,1-.14-.19.12.12,0,0,1,.17,0A.12.12,0,0,1,825.29,844.27Z"
        ></path>
        <path
          className="cls-3"
          d="M833.22,836.1a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,833.22,836.1Z"
        ></path>
        <path
          className="cls-3"
          d="M840.94,827.54a.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0,.12.12,0,1,1,.19-.14Z"
        ></path>
        <circle className="cls-3" cx="848.29" cy="819" r="0.12"></circle>
        <path
          className="cls-3"
          d="M855.41,810.07a.13.13,0,0,1,.17,0,.12.12,0,0,1-.2.14A.14.14,0,0,1,855.41,810.07Z"
        ></path>
        <path
          className="cls-3"
          d="M862.29,801.23a.16.16,0,0,1,0-.21.15.15,0,0,1,.21,0,.15.15,0,0,1-.25.18Z"
        ></path>
        <path
          className="cls-3"
          d="M869.16,792a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,869.16,792Z"
        ></path>
        <path
          className="cls-3"
          d="M875.42,782.4a.11.11,0,0,1,.16,0,.12.12,0,0,1-.19.15A.12.12,0,0,1,875.42,782.4Z"
        ></path>
        <path
          className="cls-3"
          d="M881.54,772.8a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,881.54,772.8Z"
        ></path>
        <circle className="cls-3" cx="887.45" cy="763.12" r="0.12"></circle>
        <path
          className="cls-3"
          d="M892.94,753.09a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,892.94,753.09Z"
        ></path>
        <path
          className="cls-3"
          d="M898.21,743a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,898.21,743Z"
        ></path>
        <path
          className="cls-3"
          d="M903.19,732.75a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,903.19,732.75Z"
        ></path>
        <path
          className="cls-3"
          d="M908,722.57a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,908,722.57Z"
        ></path>
        <circle className="cls-3" cx="912.3" cy="711.96" r="0.12"></circle>
        <path
          className="cls-3"
          d="M916.44,701.42a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.17,0A.12.12,0,0,1,916.44,701.42Z"
        ></path>
        <path
          className="cls-3"
          d="M920.06,690.48a.12.12,0,1,1,0,.17A.12.12,0,0,1,920.06,690.48Z"
        ></path>
        <path
          className="cls-3"
          d="M923.5,679.62a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,923.5,679.62Z"
        ></path>
        <path
          className="cls-3"
          d="M926.63,668.68a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,926.63,668.68Z"
        ></path>
        <path
          className="cls-3"
          d="M929.44,657.64a.13.13,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,929.44,657.64Z"
        ></path>
        <path
          className="cls-3"
          d="M931.93,646.53a.13.13,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,931.93,646.53Z"
        ></path>
        <path
          className="cls-3"
          d="M934.1,635.36a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,934.1,635.36Z"
        ></path>
        <path
          className="cls-3"
          d="M935.94,624.12a.12.12,0,0,1,.17,0,.12.12,0,1,1-.19.14A.12.12,0,0,1,935.94,624.12Z"
        ></path>
        <path
          className="cls-3"
          d="M937.46,612.84a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,937.46,612.84Z"
        ></path>
        <path
          className="cls-3"
          d="M938.65,601.51a.13.13,0,0,1,.17,0,.12.12,0,1,1-.2.14A.13.13,0,0,1,938.65,601.51Z"
        ></path>
        <path
          className="cls-3"
          d="M939.51,590.16a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0A.14.14,0,0,1,939.51,590.16Z"
        ></path>
        <path
          className="cls-3"
          d="M940,578.78a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,940,578.78Z"
        ></path>
        <path
          className="cls-3"
          d="M940.25,567.4a.12.12,0,0,1,.17,0,.14.14,0,0,1,0,.17.13.13,0,0,1-.17,0A.12.12,0,0,1,940.25,567.4Z"
        ></path>
        <path
          className="cls-3"
          d="M940.29,556a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16A.12.12,0,0,1,940.29,556Z"
        ></path>
        <path
          className="cls-3"
          d="M939.66,544.64a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M938.85,533.45a.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0,.13.13,0,0,1,0,.17A.13.13,0,0,1,938.85,533.45Z"
        ></path>
        <path
          className="cls-3"
          d="M937.76,522a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0A.12.12,0,0,1,937.76,522Z"
        ></path>
        <path
          className="cls-3"
          d="M936.32,510.65a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.14.14,0,0,1,936.32,510.65Z"
        ></path>
        <path
          className="cls-3"
          d="M934.55,499.41a.12.12,0,1,1,0,.17A.12.12,0,0,1,934.55,499.41Z"
        ></path>
        <path
          className="cls-3"
          d="M932.46,488.21a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.13.13,0,0,1,932.46,488.21Z"
        ></path>
        <circle className="cls-3" cx="930.12" cy="477.19" r="0.12"></circle>
        <path
          className="cls-3"
          d="M927.31,466a.12.12,0,1,1,.14.19.12.12,0,0,1-.17,0A.11.11,0,0,1,927.31,466Z"
        ></path>
        <path
          className="cls-3"
          d="M924.25,455.07a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.14.14,0,0,1,924.25,455.07Z"
        ></path>
        <path
          className="cls-3"
          d="M920.83,444.38a.15.15,0,1,1,.21,0A.15.15,0,0,1,920.83,444.38Z"
        ></path>
        <path
          className="cls-3"
          d="M917.19,433.42a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,917.19,433.42Z"
        ></path>
        <path
          className="cls-3"
          d="M913.2,422.76a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,913.2,422.76Z"
        ></path>
        <path
          className="cls-3"
          d="M908.89,412.22a.12.12,0,1,1,0,.17A.12.12,0,0,1,908.89,412.22Z"
        ></path>
        <path
          className="cls-3"
          d="M904.29,401.8a.14.14,0,0,1,.17,0,.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,904.29,401.8Z"
        ></path>
        <path
          className="cls-3"
          d="M899.38,391.53a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0A.13.13,0,0,1,899.38,391.53Z"
        ></path>
        <path
          className="cls-3"
          d="M894.18,381.4a.12.12,0,1,1,.14.2.12.12,0,0,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M888.69,371.43a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,888.69,371.43Z"
        ></path>
        <path
          className="cls-3"
          d="M882.91,361.62a.12.12,0,1,1,0,.17A.12.12,0,0,1,882.91,361.62Z"
        ></path>
        <path
          className="cls-3"
          d="M876.85,352a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,876.85,352Z"
        ></path>
        <path
          className="cls-3"
          d="M870.51,342.52a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.14.14,0,0,1,870.51,342.52Z"
        ></path>
        <path
          className="cls-3"
          d="M863.9,333.25a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.11.11,0,0,1,863.9,333.25Z"
        ></path>
        <path
          className="cls-3"
          d="M857,324.17a.12.12,0,0,1,.17,0,.13.13,0,0,1,0,.17.14.14,0,0,1-.17,0A.12.12,0,0,1,857,324.17Z"
        ></path>
        <path
          className="cls-3"
          d="M849.9,315.3a.12.12,0,0,1,.17,0,.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,849.9,315.3Z"
        ></path>
        <path
          className="cls-3"
          d="M842.51,306.63a.12.12,0,1,1,0,.17A.12.12,0,0,1,842.51,306.63Z"
        ></path>
        <path
          className="cls-3"
          d="M834.87,298.19a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,834.87,298.19Z"
        ></path>
        <path
          className="cls-3"
          d="M827,290a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,827,290Z"
        ></path>
        <path
          className="cls-3"
          d="M818.88,282a.13.13,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,818.88,282Z"
        ></path>
        <path
          className="cls-3"
          d="M810.54,274.22a.12.12,0,0,1,.17,0,.12.12,0,1,1-.17,0Z"
        ></path>
        <path
          className="cls-3"
          d="M802,266.71a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,802,266.71Z"
        ></path>
        <path
          className="cls-3"
          d="M793.21,259.46a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,1,1-.14-.19Z"
        ></path>
        <path
          className="cls-3"
          d="M784.18,252.64a.16.16,0,0,1,0-.21.15.15,0,0,1,.18.25A.15.15,0,0,1,784.18,252.64Z"
        ></path>
        <path
          className="cls-3"
          d="M775,245.91a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <circle className="cls-3" cx="765.75" cy="239.35" r="0.12"></circle>
        <path
          className="cls-3"
          d="M756.07,233.25a.15.15,0,0,1,.25-.18.16.16,0,0,1,0,.21A.17.17,0,0,1,756.07,233.25Z"
        ></path>
        <path
          className="cls-3"
          d="M746.4,227.14a.14.14,0,0,1,.17,0,.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,746.4,227.14Z"
        ></path>
        <path
          className="cls-3"
          d="M736.5,221.51a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.13.13,0,0,1,736.5,221.51Z"
        ></path>
        <path
          className="cls-3"
          d="M726.45,216.17a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,726.45,216.17Z"
        ></path>
        <path
          className="cls-3"
          d="M716.24,211.12a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.13.13,0,0,1,716.24,211.12Z"
        ></path>
        <path
          className="cls-3"
          d="M705.84,206.56a.15.15,0,0,1,0-.21.15.15,0,1,1,.17.24A.15.15,0,0,1,705.84,206.56Z"
        ></path>
        <path
          className="cls-3"
          d="M695.41,201.93a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,695.41,201.93Z"
        ></path>
        <path
          className="cls-3"
          d="M684.81,197.78a.12.12,0,0,1,.17,0,.13.13,0,0,1,0,.17.13.13,0,0,1-.17,0A.12.12,0,0,1,684.81,197.78Z"
        ></path>
        <path
          className="cls-3"
          d="M674.09,194a.12.12,0,0,1,.17,0,.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,674.09,194Z"
        ></path>
        <path
          className="cls-3"
          d="M663.21,190.61a.15.15,0,1,1,.21,0A.15.15,0,0,1,663.21,190.61Z"
        ></path>
        <path
          className="cls-3"
          d="M652.33,187.21a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,652.33,187.21Z"
        ></path>
        <path
          className="cls-3"
          d="M641.27,184.51a.16.16,0,0,1,0-.21.17.17,0,0,1,.22,0,.16.16,0,0,1,0,.21A.15.15,0,0,1,641.27,184.51Z"
        ></path>
        <path
          className="cls-3"
          d="M630.23,181.75a.13.13,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.13.13,0,0,1,630.23,181.75Z"
        ></path>
        <path
          className="cls-3"
          d="M619.07,179.5a.14.14,0,0,1,.17,0,.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,619.07,179.5Z"
        ></path>
        <path
          className="cls-3"
          d="M607.85,177.58a.11.11,0,0,1,.16,0,.12.12,0,1,1-.19.14A.12.12,0,0,1,607.85,177.58Z"
        ></path>
        <path
          className="cls-3"
          d="M596.52,176.17a.16.16,0,0,1,0-.21.15.15,0,1,1,.18.24A.15.15,0,0,1,596.52,176.17Z"
        ></path>
        <path
          className="cls-3"
          d="M585.26,174.71a.12.12,0,0,1,.17,0,.12.12,0,0,1-.2.14A.12.12,0,0,1,585.26,174.71Z"
        ></path>
        <path
          className="cls-3"
          d="M573.91,173.77a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,1,1-.14-.19Z"
        ></path>
        <path
          className="cls-3"
          d="M562.68,173.35a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,562.68,173.35Z"
        ></path>
        <path
          className="cls-3"
          d="M551.11,173.05a.15.15,0,1,1,.21,0A.15.15,0,0,1,551.11,173.05Z"
        ></path>
        <path
          className="cls-3"
          d="M539.75,173.08a.12.12,0,1,1,.17,0A.12.12,0,0,1,539.75,173.08Z"
        ></path>
        <path
          className="cls-3"
          d="M528.39,173.29a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.11.11,0,0,1,528.39,173.29Z"
        ></path>
        <path
          className="cls-3"
          d="M517,174a.12.12,0,1,1,.14.2.13.13,0,0,1-.17,0A.12.12,0,0,1,517,174Z"
        ></path>
        <path
          className="cls-3"
          d="M505.64,175.21a.16.16,0,0,1,0-.21.15.15,0,1,1,0,.21Z"
        ></path>
        <path
          className="cls-3"
          d="M494.53,176.58a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,494.53,176.58Z"
        ></path>
        <path
          className="cls-3"
          d="M483.13,178.07a.11.11,0,0,1,.16,0,.11.11,0,0,1,0,.16.12.12,0,0,1-.17,0A.12.12,0,0,1,483.13,178.07Z"
        ></path>
        <path
          className="cls-3"
          d="M472.11,180.09a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M460.92,182.61a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.11.11,0,0,1,.16,0A.12.12,0,0,1,460.92,182.61Z"
        ></path>
        <path
          className="cls-3"
          d="M449.7,185.07a.13.13,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,449.7,185.07Z"
        ></path>
        <path
          className="cls-3"
          d="M438.91,188.06a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M427.81,191.34a.13.13,0,0,1,.15.2.12.12,0,0,1-.17,0A.12.12,0,0,1,427.81,191.34Z"
        ></path>
        <path
          className="cls-3"
          d="M417,194.93a.15.15,0,0,1,.21,0,.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0A.15.15,0,0,1,417,194.93Z"
        ></path>
        <path
          className="cls-3"
          d="M406.27,199.06a.15.15,0,0,1,.25-.18.15.15,0,0,1,0,.21A.16.16,0,0,1,406.27,199.06Z"
        ></path>
        <path
          className="cls-3"
          d="M396,203.11a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M385.31,207.63a.12.12,0,1,1,.14.19.12.12,0,0,1-.17,0A.12.12,0,0,1,385.31,207.63Z"
        ></path>
        <path
          className="cls-3"
          d="M375.19,212.47a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,375.19,212.47Z"
        ></path>
        <path
          className="cls-3"
          d="M365,217.6a.15.15,0,1,1-.24.17.16.16,0,0,1,0-.21A.15.15,0,0,1,365,217.6Z"
        ></path>
        <path
          className="cls-3"
          d="M355,223a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.25A.15.15,0,0,1,355,223Z"
        ></path>
        <path
          className="cls-3"
          d="M345.16,228.72a.15.15,0,1,1-.25.18.16.16,0,0,1,0-.21A.16.16,0,0,1,345.16,228.72Z"
        ></path>
        <path
          className="cls-3"
          d="M335.48,234.72a.16.16,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.17Z"
        ></path>
        <path
          className="cls-3"
          d="M325.73,241.16a.15.15,0,0,1,0-.21.16.16,0,0,1,.21,0,.15.15,0,0,1-.24.18Z"
        ></path>
        <path
          className="cls-3"
          d="M316.44,247.49a.16.16,0,0,1,.21,0,.17.17,0,0,1,0,.22.16.16,0,0,1-.21,0A.15.15,0,0,1,316.44,247.49Z"
        ></path>
        <path
          className="cls-3"
          d="M307.52,254.33a.15.15,0,1,1-.24.18.16.16,0,0,1,0-.21A.14.14,0,0,1,307.52,254.33Z"
        ></path>
        <path
          className="cls-3"
          d="M298.6,261.4a.16.16,0,0,1,0,.21.15.15,0,1,1-.18-.24A.15.15,0,0,1,298.6,261.4Z"
        ></path>
        <path
          className="cls-3"
          d="M289.67,268.69a.15.15,0,1,1,0,.21A.15.15,0,0,1,289.67,268.69Z"
        ></path>
        <path
          className="cls-3"
          d="M281.38,276.3a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,0,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M273.1,284.12a.15.15,0,1,1-.21,0A.15.15,0,0,1,273.1,284.12Z"
        ></path>
        <path
          className="cls-3"
          d="M265.05,292.17a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M257.24,300.45a.15.15,0,0,1,0,.21.15.15,0,1,1-.18-.24A.16.16,0,0,1,257.24,300.45Z"
        ></path>
        <path
          className="cls-3"
          d="M249.67,309a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M242.35,317.68a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M235.29,326.61a.15.15,0,0,1,0,.21.15.15,0,1,1-.17-.24A.15.15,0,0,1,235.29,326.61Z"
        ></path>
        <path
          className="cls-3"
          d="M228.28,335.71a.15.15,0,1,1,.17.24.16.16,0,0,1-.21,0A.15.15,0,0,1,228.28,335.71Z"
        ></path>
        <path
          className="cls-3"
          d="M222,345.06a.17.17,0,0,1,0,.22.17.17,0,0,1-.22,0,.16.16,0,0,1,0-.21A.16.16,0,0,1,222,345.06Z"
        ></path>
        <path
          className="cls-3"
          d="M215.69,354.57a.15.15,0,0,1-.25.18.15.15,0,0,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M209.65,364.45a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.13.13,0,0,1,209.65,364.45Z"
        ></path>
        <path
          className="cls-3"
          d="M204,374.11a.17.17,0,0,1,0,.22.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.16.16,0,0,1,204,374.11Z"
        ></path>
        <path
          className="cls-3"
          d="M198.59,384.13a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M193.46,394.3a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.14.14,0,0,1,193.46,394.3Z"
        ></path>
        <path
          className="cls-3"
          d="M188.64,404.61a.15.15,0,1,1-.21,0A.15.15,0,0,1,188.64,404.61Z"
        ></path>
        <path
          className="cls-3"
          d="M183.92,415.05a.12.12,0,0,1,.14.2.12.12,0,1,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M179.89,425.63a.16.16,0,0,1,0,.22.15.15,0,0,1-.21,0,.15.15,0,0,1,0-.21A.15.15,0,0,1,179.89,425.63Z"
        ></path>
        <path
          className="cls-3"
          d="M175.93,436.51a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,175.93,436.51Z"
        ></path>
        <path
          className="cls-3"
          d="M172.38,447.13a.15.15,0,0,1,0,.21.15.15,0,1,1-.17-.24A.15.15,0,0,1,172.38,447.13Z"
        ></path>
        <path
          className="cls-3"
          d="M168.88,458a.15.15,0,1,1,.18.24.16.16,0,0,1-.21,0A.15.15,0,0,1,168.88,458Z"
        ></path>
        <path
          className="cls-3"
          d="M166.12,469a.15.15,0,1,1-.21,0A.15.15,0,0,1,166.12,469Z"
        ></path>
        <path
          className="cls-3"
          d="M163.47,480.1a.16.16,0,0,1,0,.21.15.15,0,0,1-.18-.25A.15.15,0,0,1,163.47,480.1Z"
        ></path>
        <path
          className="cls-3"
          d="M161.14,491.24a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M159.14,502.45a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M157.46,513.71a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M156.1,525a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.15.15,0,0,1,156.1,525Z"
        ></path>
        <path
          className="cls-3"
          d="M155.08,536.35a.16.16,0,0,1,0,.22.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.16.16,0,0,1,155.08,536.35Z"
        ></path>
        <path
          className="cls-3"
          d="M154.38,547.72a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,154.38,547.72Z"
        ></path>
        <path
          className="cls-3"
          d="M154,559.1a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M153.92,570.67a.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.12.12,0,0,1,153.92,570.67Z"
        ></path>
        <path
          className="cls-3"
          d="M154.27,581.87a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M154.84,593.42a.12.12,0,1,1,0-.17A.12.12,0,0,1,154.84,593.42Z"
        ></path>
        <path
          className="cls-3"
          d="M155.63,604.55a.15.15,0,0,1,.21,0,.15.15,0,1,1-.21,0Z"
        ></path>
        <path
          className="cls-3"
          d="M157.12,615.9a.15.15,0,1,1-.21,0A.15.15,0,0,1,157.12,615.9Z"
        ></path>
        <path
          className="cls-3"
          d="M158.72,627.17a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M160.65,638.39a.15.15,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M162.86,649.74a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,162.86,649.74Z"
        ></path>
        <path
          className="cls-3"
          d="M165.48,660.64a.15.15,0,0,1-.24.18.15.15,0,0,1,0-.21A.14.14,0,0,1,165.48,660.64Z"
        ></path>
        <path
          className="cls-3"
          d="M168.38,671.65a.16.16,0,0,1,0,.21.15.15,0,1,1-.18-.24A.15.15,0,0,1,168.38,671.65Z"
        ></path>
        <path
          className="cls-3"
          d="M171.6,682.57a.17.17,0,0,1,0,.22.16.16,0,0,1-.21,0,.16.16,0,0,1,0-.21A.17.17,0,0,1,171.6,682.57Z"
        ></path>
        <path
          className="cls-3"
          d="M175.13,693.4a.16.16,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.16.16,0,0,1,175.13,693.4Z"
        ></path>
        <path
          className="cls-3"
          d="M179,704.12a.15.15,0,1,1-.25.17.16.16,0,0,1,0-.21A.16.16,0,0,1,179,704.12Z"
        ></path>
        <path
          className="cls-3"
          d="M183.12,714.72a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,0,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M187.57,725.2a.15.15,0,0,1,0,.21.15.15,0,0,1-.18-.25A.16.16,0,0,1,187.57,725.2Z"
        ></path>
        <path
          className="cls-3"
          d="M192.33,735.54a.15.15,0,1,1-.25.18.15.15,0,0,1,0-.21A.16.16,0,0,1,192.33,735.54Z"
        ></path>
        <path
          className="cls-3"
          d="M197.38,745.75a.15.15,0,1,1-.21,0A.15.15,0,0,1,197.38,745.75Z"
        ></path>
        <path
          className="cls-3"
          d="M202.73,755.8a.15.15,0,1,1-.25.17.16.16,0,0,1,0-.21A.15.15,0,0,1,202.73,755.8Z"
        ></path>
        <path
          className="cls-3"
          d="M208.37,765.69a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,0,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M214.29,775.42a.15.15,0,1,1-.21,0A.15.15,0,0,1,214.29,775.42Z"
        ></path>
        <path
          className="cls-3"
          d="M220.49,785a.16.16,0,0,1,0,.21.15.15,0,1,1,0-.21Z"
        ></path>
        <path
          className="cls-3"
          d="M227,794.33a.15.15,0,0,1-.25.18.15.15,0,0,1,0-.21A.16.16,0,0,1,227,794.33Z"
        ></path>
        <path
          className="cls-3"
          d="M233.7,803.51a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,233.7,803.51Z"
        ></path>
        <path
          className="cls-3"
          d="M240.7,812.49a.15.15,0,1,1-.24.17.16.16,0,0,1,0-.21A.15.15,0,0,1,240.7,812.49Z"
        ></path>
        <path
          className="cls-3"
          d="M248,821.26a.15.15,0,1,1-.21,0A.15.15,0,0,1,248,821.26Z"
        ></path>
        <path
          className="cls-3"
          d="M255.48,829.81a.15.15,0,0,1-.25.18.16.16,0,0,1,0-.21A.15.15,0,0,1,255.48,829.81Z"
        ></path>
        <path
          className="cls-3"
          d="M263.24,838.15a.15.15,0,1,1-.25.17.16.16,0,0,1,0-.21A.16.16,0,0,1,263.24,838.15Z"
        ></path>
        <path
          className="cls-3"
          d="M271.23,846.25a.17.17,0,0,1,0,.22.16.16,0,0,1-.21,0,.15.15,0,0,1,0-.21A.16.16,0,0,1,271.23,846.25Z"
        ></path>
        <path
          className="cls-3"
          d="M279.41,854.31a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,1,1,.15.19Z"
        ></path>
        <path
          className="cls-3"
          d="M287.91,861.76a.15.15,0,1,1-.21,0A.15.15,0,0,1,287.91,861.76Z"
        ></path>
        <path
          className="cls-3"
          d="M296.58,869.14a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.17.17,0,0,1,0-.22A.17.17,0,0,1,296.58,869.14Z"
        ></path>
        <path
          className="cls-3"
          d="M305.4,876.45a.12.12,0,1,1-.14-.19.12.12,0,0,1,.17,0A.12.12,0,0,1,305.4,876.45Z"
        ></path>
        <path
          className="cls-3"
          d="M314.48,883.32a.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.12.12,0,0,1,314.48,883.32Z"
        ></path>
        <path
          className="cls-3"
          d="M323.81,889.74a.16.16,0,0,1,0,.21.15.15,0,0,1-.21,0,.15.15,0,1,1,.24-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M333.22,896.26a.13.13,0,0,1-.17,0,.13.13,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,333.22,896.26Z"
        ></path>
        <path
          className="cls-3"
          d="M342.86,902.31a.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.17,0A.12.12,0,0,1,342.86,902.31Z"
        ></path>
        <path
          className="cls-3"
          d="M352.73,907.9a.16.16,0,0,1,0,.21.17.17,0,0,1-.22,0,.17.17,0,0,1,0-.22A.16.16,0,0,1,352.73,907.9Z"
        ></path>
        <path
          className="cls-3"
          d="M362.66,913.57a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.15.19Z"
        ></path>
        <path
          className="cls-3"
          d="M367.16,930.14a.12.12,0,0,1-.14-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,367.16,930.14Z"
        ></path>
        <path
          className="cls-3"
          d="M377.44,935a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.11.11,0,0,1,.16,0A.12.12,0,0,1,377.44,935Z"
        ></path>
        <path
          className="cls-3"
          d="M387.87,939.49a.12.12,0,1,1-.19.14.12.12,0,0,1,0-.17A.11.11,0,0,1,387.87,939.49Z"
        ></path>
        <path
          className="cls-3"
          d="M398.38,944a.12.12,0,0,1-.14-.2.12.12,0,1,1,.14.2Z"
        ></path>
        <path
          className="cls-3"
          d="M409,948a.08.08,0,0,1-.12,0,.09.09,0,1,1,.15-.11A.1.1,0,0,1,409,948Z"
        ></path>
        <circle
          className="cls-3"
          cx="419.72"
          cy="951.63"
          r="0.09"
          transform="translate(-476.26 423.44) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M430.64,955.12a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.11A.11.11,0,0,1,430.64,955.12Z"
        ></path>
        <path
          className="cls-3"
          d="M441.6,958.26a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,441.6,958.26Z"
        ></path>
        <path
          className="cls-3"
          d="M452.64,961.06a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,452.64,961.06Z"
        ></path>
        <path
          className="cls-3"
          d="M463.75,963.56a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.13.13,0,0,1,463.75,963.56Z"
        ></path>
        <path
          className="cls-3"
          d="M474.92,965.74a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.16.12.12,0,0,1,.17,0A.12.12,0,0,1,474.92,965.74Z"
        ></path>
        <path
          className="cls-3"
          d="M486.14,967.59a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.09.09,0,1,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M497.43,969.16a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,497.43,969.16Z"
        ></path>
        <path
          className="cls-3"
          d="M508.75,970.4a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,508.75,970.4Z"
        ></path>
        <path
          className="cls-3"
          d="M520.1,971.32a.12.12,0,0,1-.14-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,520.1,971.32Z"
        ></path>
        <path
          className="cls-3"
          d="M531.45,971.89a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.1.1,0,0,1,.13,0A.1.1,0,0,1,531.45,971.89Z"
        ></path>
        <path
          className="cls-3"
          d="M542.84,972.17a.09.09,0,1,1-.11-.15.1.1,0,0,1,.13,0A.08.08,0,0,1,542.84,972.17Z"
        ></path>
        <path
          className="cls-3"
          d="M554.27,972a.12.12,0,0,1-.2.14.12.12,0,1,1,.2-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M565.6,971.77a.09.09,0,0,1-.1-.14.09.09,0,1,1,.1.14Z"
        ></path>
        <circle
          className="cls-3"
          cx="576.92"
          cy="971.02"
          r="0.09"
          transform="translate(-458.06 518.77) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M588.31,970.1a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,588.31,970.1Z"
        ></path>
        <path
          className="cls-3"
          d="M599.64,968.81a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.13.13,0,0,1,599.64,968.81Z"
        ></path>
        <path
          className="cls-3"
          d="M610.91,967.18a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.14.14,0,0,1,610.91,967.18Z"
        ></path>
        <path
          className="cls-3"
          d="M622.13,965.08a.09.09,0,1,1-.13,0A.09.09,0,0,1,622.13,965.08Z"
        ></path>
        <circle className="cls-3" cx="633.21" cy="962.88" r="0.12"></circle>
        <path
          className="cls-3"
          d="M644.38,960.4a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.11.11,0,0,1,.16,0A.12.12,0,0,1,644.38,960.4Z"
        ></path>
        <path
          className="cls-3"
          d="M655.38,957.5a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.11.11,0,0,1,655.38,957.5Z"
        ></path>
        <circle
          className="cls-3"
          cx="666.25"
          cy="954.23"
          r="0.09"
          transform="translate(-431.5 567.72) rotate(-35.68)"
        ></circle>
        <circle
          className="cls-3"
          cx="677.09"
          cy="950.74"
          r="0.09"
          transform="translate(-427.43 573.39) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M687.9,946.89a.09.09,0,0,1,0,.12.08.08,0,0,1-.12,0,.08.08,0,0,1,0-.12A.09.09,0,0,1,687.9,946.89Z"
        ></path>
        <circle
          className="cls-3"
          cx="698.45"
          cy="942.84"
          r="0.09"
          transform="translate(-418.81 584.36) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M709,938.52a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.12,0A.08.08,0,0,1,709,938.52Z"
        ></path>
        <path
          className="cls-3"
          d="M719.4,933.86a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,719.4,933.86Z"
        ></path>
        <circle className="cls-3" cx="729.57" cy="928.79" r="0.12"></circle>
        <path
          className="cls-3"
          d="M739.74,923.62a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.11.11,0,0,1,739.74,923.62Z"
        ></path>
        <path
          className="cls-3"
          d="M749.66,918.06a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,749.66,918.06Z"
        ></path>
        <path
          className="cls-3"
          d="M759.45,912.24a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,759.45,912.24Z"
        ></path>
        <path
          className="cls-3"
          d="M769.07,906.15a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.09.09,0,0,1,.13,0A.09.09,0,0,1,769.07,906.15Z"
        ></path>
        <path
          className="cls-3"
          d="M778.52,899.79a.09.09,0,1,1,0-.13A.09.09,0,0,1,778.52,899.79Z"
        ></path>
        <path
          className="cls-3"
          d="M787.67,893a.09.09,0,1,1,0,.13A.09.09,0,0,1,787.67,893Z"
        ></path>
        <path
          className="cls-3"
          d="M796.75,886.14a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,796.75,886.14Z"
        ></path>
        <path
          className="cls-3"
          d="M805.75,879a.09.09,0,1,1-.13,0A.09.09,0,0,1,805.75,879Z"
        ></path>
        <path
          className="cls-3"
          d="M814.42,871.66a.08.08,0,0,1,0,.12.09.09,0,0,1-.1-.14A.08.08,0,0,1,814.42,871.66Z"
        ></path>
        <path
          className="cls-3"
          d="M822.87,864.17a.09.09,0,1,1,0-.13A.09.09,0,0,1,822.87,864.17Z"
        ></path>
        <path
          className="cls-3"
          d="M831.13,856.34a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.17,0A.12.12,0,0,1,831.13,856.34Z"
        ></path>
        <path
          className="cls-3"
          d="M839.15,848.26a.11.11,0,0,1-.16,0,.12.12,0,1,1,.19-.15A.12.12,0,0,1,839.15,848.26Z"
        ></path>
        <path
          className="cls-3"
          d="M846.93,839.94a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,846.93,839.94Z"
        ></path>
        <path
          className="cls-3"
          d="M854.49,831.42a.09.09,0,1,1-.11-.15.1.1,0,0,1,.13,0A.08.08,0,0,1,854.49,831.42Z"
        ></path>
        <path
          className="cls-3"
          d="M861.8,822.7a.08.08,0,0,1-.12,0,.09.09,0,1,1,.15-.11A.11.11,0,0,1,861.8,822.7Z"
        ></path>
        <path
          className="cls-3"
          d="M868.89,813.64a.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13A.08.08,0,0,1,868.89,813.64Z"
        ></path>
        <path
          className="cls-3"
          d="M875.58,804.5a.09.09,0,1,1,0,.13A.09.09,0,0,1,875.58,804.5Z"
        ></path>
        <path
          className="cls-3"
          d="M882.24,795.34a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.08.08,0,0,1,882.24,795.34Z"
        ></path>
        <path
          className="cls-3"
          d="M888.54,785.85a.09.09,0,1,1-.11-.15.11.11,0,0,1,.13,0A.08.08,0,0,1,888.54,785.85Z"
        ></path>
        <path
          className="cls-3"
          d="M894.58,776.22a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.13.13,0,0,1,894.58,776.22Z"
        ></path>
        <path
          className="cls-3"
          d="M900.31,766.36a.09.09,0,1,1,0-.12A.08.08,0,0,1,900.31,766.36Z"
        ></path>
        <path
          className="cls-3"
          d="M905.68,756.23a.09.09,0,1,1,0,.13A.09.09,0,0,1,905.68,756.23Z"
        ></path>
        <path
          className="cls-3"
          d="M911,746.25a.1.1,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M915.78,735.82a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,915.78,735.82Z"
        ></path>
        <path
          className="cls-3"
          d="M920.52,725.43a.09.09,0,1,1-.15.11.09.09,0,0,1,.15-.11Z"
        ></path>
        <path
          className="cls-3"
          d="M924.71,714.88a.09.09,0,1,1,0,.13A.09.09,0,0,1,924.71,714.88Z"
        ></path>
        <circle
          className="cls-3"
          cx="928.79"
          cy="704.3"
          r="0.09"
          transform="translate(-236.44 673.93) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M932.46,693.47a.09.09,0,0,1,.13,0,.11.11,0,0,1,0,.13.09.09,0,0,1-.1-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M935.88,682.61a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.09.09,0,0,1-.12,0A.08.08,0,0,1,935.88,682.61Z"
        ></path>
        <path
          className="cls-3"
          d="M939,671.66a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,939,671.66Z"
        ></path>
        <path
          className="cls-3"
          d="M941.8,660.62a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,941.8,660.62Z"
        ></path>
        <path
          className="cls-3"
          d="M944.28,649.49a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,944.28,649.49Z"
        ></path>
        <path
          className="cls-3"
          d="M946.48,638.34a.08.08,0,0,1,.12,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.1-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M948.34,627.11a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,948.34,627.11Z"
        ></path>
        <path
          className="cls-3"
          d="M949.88,615.8a.12.12,0,1,1,.14.2.12.12,0,1,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M951.13,604.51a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,951.13,604.51Z"
        ></path>
        <path
          className="cls-3"
          d="M952,593.13a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.12.12,0,0,1,952,593.13Z"
        ></path>
        <path
          className="cls-3"
          d="M952.65,581.79a.09.09,0,1,1,.11.15.09.09,0,1,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M952.93,570.41a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,952.93,570.41Z"
        ></path>
        <path
          className="cls-3"
          d="M952.89,559a.09.09,0,1,1,0,.13A.09.09,0,0,1,952.89,559Z"
        ></path>
        <path
          className="cls-3"
          d="M952.64,547.79a.09.09,0,1,1,0-.13A.09.09,0,0,1,952.64,547.79Z"
        ></path>
        <path
          className="cls-3"
          d="M951.85,536.27a.11.11,0,0,1,.13,0,.09.09,0,0,1,0,.13.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M950.84,525.06a.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13A.08.08,0,0,1,950.84,525.06Z"
        ></path>
        <path
          className="cls-3"
          d="M949.67,513.64a.09.09,0,1,1-.13,0A.09.09,0,0,1,949.67,513.64Z"
        ></path>
        <path
          className="cls-3"
          d="M947.91,502.35a.09.09,0,1,1,0,.13A.09.09,0,0,1,947.91,502.35Z"
        ></path>
        <path
          className="cls-3"
          d="M946,491.13a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,946,491.13Z"
        ></path>
        <path
          className="cls-3"
          d="M943.69,480a.12.12,0,0,1,.17,0,.12.12,0,1,1-.2.14A.12.12,0,0,1,943.69,480Z"
        ></path>
        <path
          className="cls-3"
          d="M941.12,468.86a.12.12,0,0,1,.17,0,.12.12,0,1,1-.2.14A.12.12,0,0,1,941.12,468.86Z"
        ></path>
        <path
          className="cls-3"
          d="M938.25,457.87a.09.09,0,1,1,.11.15.11.11,0,0,1-.13,0A.09.09,0,0,1,938.25,457.87Z"
        ></path>
        <path
          className="cls-3"
          d="M935.06,446.94a.09.09,0,1,1,.11.15.11.11,0,0,1-.13,0A.09.09,0,0,1,935.06,446.94Z"
        ></path>
        <path
          className="cls-3"
          d="M931.57,436.1a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,931.57,436.1Z"
        ></path>
        <path
          className="cls-3"
          d="M927.77,425.37a.09.09,0,1,1,.11.15.09.09,0,0,1-.13,0A.08.08,0,0,1,927.77,425.37Z"
        ></path>
        <path
          className="cls-3"
          d="M923.67,414.75a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,923.67,414.75Z"
        ></path>
        <path
          className="cls-3"
          d="M919.28,404.24a.09.09,0,1,1,0,.13A.09.09,0,0,1,919.28,404.24Z"
        ></path>
        <path
          className="cls-3"
          d="M914.59,393.86a.09.09,0,1,1,0,.13A.09.09,0,0,1,914.59,393.86Z"
        ></path>
        <circle className="cls-3" cx="909.67" cy="383.7" r="0.12"></circle>
        <path
          className="cls-3"
          d="M904.34,373.5a.12.12,0,0,1,.14.2.13.13,0,0,1-.17,0A.13.13,0,0,1,904.34,373.5Z"
        ></path>
        <path
          className="cls-3"
          d="M898.81,363.58a.09.09,0,0,1,.13,0,.09.09,0,0,1-.15.11A.09.09,0,0,1,898.81,363.58Z"
        ></path>
        <path
          className="cls-3"
          d="M893,353.79a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,893,353.79Z"
        ></path>
        <path
          className="cls-3"
          d="M886.9,344.17a.09.09,0,0,1,.13,0,.09.09,0,1,1-.13,0Z"
        ></path>
        <path
          className="cls-3"
          d="M880.55,334.73a.08.08,0,0,1,.12,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.1-.14Z"
        ></path>
        <circle
          className="cls-3"
          cx="873.98"
          cy="325.54"
          r="0.09"
          transform="translate(-25.81 570.87) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M867.05,316.39a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,867.05,316.39Z"
        ></path>
        <path
          className="cls-3"
          d="M859.92,307.51a.09.09,0,0,1,.13,0,.1.1,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,859.92,307.51Z"
        ></path>
        <path
          className="cls-3"
          d="M852.54,298.84a.09.09,0,1,1,0,.13A.09.09,0,0,1,852.54,298.84Z"
        ></path>
        <path
          className="cls-3"
          d="M844.93,290.38a.09.09,0,1,1,.1.14.09.09,0,0,1-.1-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M837.06,282.1a.14.14,0,0,1,.17,0,.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0A.12.12,0,0,1,837.06,282.1Z"
        ></path>
        <path
          className="cls-3"
          d="M829,274.08a.12.12,0,0,1,.17,0,.12.12,0,0,1-.2.14A.12.12,0,0,1,829,274.08Z"
        ></path>
        <path
          className="cls-3"
          d="M820.7,266.31a.08.08,0,0,1,.12,0,.09.09,0,1,1-.15.11A.1.1,0,0,1,820.7,266.31Z"
        ></path>
        <path
          className="cls-3"
          d="M812.18,258.75a.11.11,0,0,1,.13,0,.09.09,0,1,1-.15.11A.09.09,0,0,1,812.18,258.75Z"
        ></path>
        <circle
          className="cls-3"
          cx="803.51"
          cy="251.51"
          r="0.09"
          transform="translate(4.13 515.87) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M794.51,244.35a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,794.51,244.35Z"
        ></path>
        <path
          className="cls-3"
          d="M785.41,237.56a.09.09,0,1,1,0,.12A.08.08,0,0,1,785.41,237.56Z"
        ></path>
        <path
          className="cls-3"
          d="M776.1,231a.09.09,0,0,1,.13,0,.11.11,0,0,1,0,.13.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M766.61,224.71a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,766.61,224.71Z"
        ></path>
        <path
          className="cls-3"
          d="M756.93,218.66a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,1,1-.14-.19Z"
        ></path>
        <path
          className="cls-3"
          d="M747.12,212.93a.09.09,0,1,1,0,.13A.09.09,0,0,1,747.12,212.93Z"
        ></path>
        <path
          className="cls-3"
          d="M737.12,207.43a.12.12,0,0,1,.14.2.12.12,0,1,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M727,202.24a.11.11,0,0,1,.16,0,.12.12,0,1,1-.19.14A.12.12,0,0,1,727,202.24Z"
        ></path>
        <path
          className="cls-3"
          d="M716.73,197.36a.09.09,0,0,1,.13,0,.11.11,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,716.73,197.36Z"
        ></path>
        <path
          className="cls-3"
          d="M706.32,192.75a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.1-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M695.78,188.43a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,695.78,188.43Z"
        ></path>
        <path
          className="cls-3"
          d="M685.12,184.38a.12.12,0,0,1,.17,0,.12.12,0,0,1-.2.14A.12.12,0,0,1,685.12,184.38Z"
        ></path>
        <path
          className="cls-3"
          d="M674.37,180.68a.09.09,0,1,1,0,.13A.09.09,0,0,1,674.37,180.68Z"
        ></path>
        <circle
          className="cls-3"
          cx="663.57"
          cy="177.33"
          r="0.09"
          transform="translate(21.13 420.32) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M652.56,174.14a.1.1,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M641.48,171.48a.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0,.13.13,0,0,1,0,.17A.12.12,0,0,1,641.48,171.48Z"
        ></path>
        <path
          className="cls-3"
          d="M630.4,168.82a.12.12,0,1,1,.14.2.14.14,0,0,1-.17,0A.13.13,0,0,1,630.4,168.82Z"
        ></path>
        <path
          className="cls-3"
          d="M619.24,166.66a.09.09,0,1,1,0,.13A.09.09,0,0,1,619.24,166.66Z"
        ></path>
        <path
          className="cls-3"
          d="M608,164.77a.12.12,0,0,1,.17,0,.12.12,0,0,1-.19.15A.12.12,0,0,1,608,164.77Z"
        ></path>
        <path
          className="cls-3"
          d="M596.71,163.22a.12.12,0,0,1,.17,0,.12.12,0,0,1,0,.17.11.11,0,0,1-.16,0A.11.11,0,0,1,596.71,163.22Z"
        ></path>
        <path
          className="cls-3"
          d="M585.4,162a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.14.14,0,0,1-.17,0A.12.12,0,0,1,585.4,162Z"
        ></path>
        <path
          className="cls-3"
          d="M574.05,161.06a.12.12,0,0,1,.17,0,.14.14,0,0,1,0,.17.13.13,0,0,1-.17,0A.12.12,0,0,1,574.05,161.06Z"
        ></path>
        <path
          className="cls-3"
          d="M562.82,160.66a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,562.82,160.66Z"
        ></path>
        <path
          className="cls-3"
          d="M551.31,160.21a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,551.31,160.21Z"
        ></path>
        <path
          className="cls-3"
          d="M540,160.39a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.1A.08.08,0,0,1,540,160.39Z"
        ></path>
        <path
          className="cls-3"
          d="M528.53,160.58a.12.12,0,0,1,.17,0,.14.14,0,0,1,0,.17.13.13,0,0,1-.17,0A.12.12,0,0,1,528.53,160.58Z"
        ></path>
        <path
          className="cls-3"
          d="M517.3,161.45a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.11.11,0,0,1,517.3,161.45Z"
        ></path>
        <path
          className="cls-3"
          d="M505.82,162.25a.14.14,0,0,1,.17,0,.13.13,0,0,1,0,.17.12.12,0,1,1-.14-.2Z"
        ></path>
        <path
          className="cls-3"
          d="M494.68,163.6a.11.11,0,0,1,0,.16.12.12,0,1,1-.14-.19A.12.12,0,0,1,494.68,163.6Z"
        ></path>
        <path
          className="cls-3"
          d="M483.36,165.37a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,483.36,165.37Z"
        ></path>
        <path
          className="cls-3"
          d="M472,167.14a.14.14,0,0,1,.17,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.14.14,0,0,1,472,167.14Z"
        ></path>
        <path
          className="cls-3"
          d="M460.88,169.43a.09.09,0,1,1,0,.13A.09.09,0,0,1,460.88,169.43Z"
        ></path>
        <path
          className="cls-3"
          d="M449.89,172.15a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,449.89,172.15Z"
        ></path>
        <path
          className="cls-3"
          d="M438.9,175.06a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,438.9,175.06Z"
        ></path>
        <circle className="cls-3" cx="427.9" cy="178.15" r="0.12"></circle>
        <path
          className="cls-3"
          d="M417.13,181.74a.12.12,0,0,1-.17,0,.12.12,0,1,1,.2-.14A.12.12,0,0,1,417.13,181.74Z"
        ></path>
        <path
          className="cls-3"
          d="M406.42,185.37a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0,.12.12,0,1,1,.19-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M395.77,189.64a.14.14,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,395.77,189.64Z"
        ></path>
        <circle
          className="cls-3"
          cx="385.2"
          cy="193.93"
          r="0.09"
          transform="translate(-40.8 261.07) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M374.88,198.69a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,374.88,198.69Z"
        ></path>
        <path
          className="cls-3"
          d="M364.63,203.67a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,364.63,203.67Z"
        ></path>
        <path
          className="cls-3"
          d="M354.58,208.78a.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M344.64,214.32a.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17A.12.12,0,0,1,344.64,214.32Z"
        ></path>
        <path
          className="cls-3"
          d="M334.85,220.14a.13.13,0,1,1-.17,0A.13.13,0,0,1,334.85,220.14Z"
        ></path>
        <path
          className="cls-3"
          d="M325.06,226.21a.12.12,0,0,1,.17,0,.11.11,0,0,1,0,.16.12.12,0,1,1-.14-.19Z"
        ></path>
        <path
          className="cls-3"
          d="M315.62,232.57a.11.11,0,0,1,.16,0,.12.12,0,0,1,0,.17.12.12,0,0,1-.17,0A.11.11,0,0,1,315.62,232.57Z"
        ></path>
        <path
          className="cls-3"
          d="M306.49,239.38a.12.12,0,1,1-.14-.19.12.12,0,0,1,.17,0A.12.12,0,0,1,306.49,239.38Z"
        ></path>
        <path
          className="cls-3"
          d="M297.45,246.09a.13.13,0,0,1,0,.17.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17A.14.14,0,0,1,297.45,246.09Z"
        ></path>
        <path
          className="cls-3"
          d="M288.54,253.39a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.14.14,0,0,1,.17,0A.12.12,0,0,1,288.54,253.39Z"
        ></path>
        <path
          className="cls-3"
          d="M279.87,260.77a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.14.14,0,0,1,279.87,260.77Z"
        ></path>
        <path
          className="cls-3"
          d="M271.4,268.38a.12.12,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,271.4,268.38Z"
        ></path>
        <path
          className="cls-3"
          d="M263.14,276.21a.09.09,0,1,1,0-.13A.09.09,0,0,1,263.14,276.21Z"
        ></path>
        <path
          className="cls-3"
          d="M255.12,284.29a.09.09,0,1,1,0-.13A.09.09,0,0,1,255.12,284.29Z"
        ></path>
        <circle
          className="cls-3"
          cx="247.27"
          cy="292.52"
          r="0.09"
          transform="translate(-124.2 199.13) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M239.78,301.13a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.13.13,0,0,1,.17,0A.12.12,0,0,1,239.78,301.13Z"
        ></path>
        <path
          className="cls-3"
          d="M232.47,309.85a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.11.11,0,0,1,232.47,309.85Z"
        ></path>
        <path
          className="cls-3"
          d="M225.4,318.78a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0A.13.13,0,0,1,225.4,318.78Z"
        ></path>
        <path
          className="cls-3"
          d="M218.58,327.9a.11.11,0,0,1-.16,0,.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0A.12.12,0,0,1,218.58,327.9Z"
        ></path>
        <circle className="cls-3" cx="211.96" cy="337.11" r="0.12"></circle>
        <path
          className="cls-3"
          d="M205.73,346.7a.11.11,0,0,1-.16,0,.12.12,0,1,1,.19-.14A.12.12,0,0,1,205.73,346.7Z"
        ></path>
        <path
          className="cls-3"
          d="M199.71,356.21a.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13A.08.08,0,0,1,199.71,356.21Z"
        ></path>
        <path
          className="cls-3"
          d="M194,366.19a.13.13,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.13.13,0,0,1,194,366.19Z"
        ></path>
        <path
          className="cls-3"
          d="M188.49,376.17a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.15.19Z"
        ></path>
        <path
          className="cls-3"
          d="M183.35,386.12a.15.15,0,0,1,0,.21.16.16,0,0,1-.21,0,.15.15,0,1,1,.25-.18Z"
        ></path>
        <path
          className="cls-3"
          d="M178.39,396.58a.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.12.12,0,0,1,178.39,396.58Z"
        ></path>
        <path
          className="cls-3"
          d="M173.78,407a.12.12,0,0,1-.17,0,.12.12,0,1,1,.19-.14A.12.12,0,0,1,173.78,407Z"
        ></path>
        <path
          className="cls-3"
          d="M169.33,417.35a.09.09,0,1,1,0,.13A.09.09,0,0,1,169.33,417.35Z"
        ></path>
        <path
          className="cls-3"
          d="M165.41,428.15a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,165.41,428.15Z"
        ></path>
        <path
          className="cls-3"
          d="M161.71,438.94a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,161.71,438.94Z"
        ></path>
        <path
          className="cls-3"
          d="M158.27,449.77a.09.09,0,1,1,0-.13A.09.09,0,0,1,158.27,449.77Z"
        ></path>
        <path
          className="cls-3"
          d="M155.17,460.75a.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,155.17,460.75Z"
        ></path>
        <path
          className="cls-3"
          d="M152.37,471.78a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.17,0A.14.14,0,0,1,152.37,471.78Z"
        ></path>
        <path
          className="cls-3"
          d="M149.87,482.89a.12.12,0,0,1-.17,0,.13.13,0,0,1,0-.17.14.14,0,0,1,.17,0A.12.12,0,0,1,149.87,482.89Z"
        ></path>
        <path
          className="cls-3"
          d="M147.69,494.07a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.13.13,0,0,1,147.69,494.07Z"
        ></path>
        <path
          className="cls-3"
          d="M145.82,505.3a.13.13,0,0,1-.17,0,.12.12,0,1,1,.17,0Z"
        ></path>
        <path
          className="cls-3"
          d="M144.3,516.41a.14.14,0,0,1,0,.17.13.13,0,0,1-.17,0,.12.12,0,0,1,.2-.14Z"
        ></path>
        <path
          className="cls-3"
          d="M143,527.9a.13.13,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,143,527.9Z"
        ></path>
        <path
          className="cls-3"
          d="M142.11,539.25a.12.12,0,0,1-.14-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,142.11,539.25Z"
        ></path>
        <path
          className="cls-3"
          d="M141.52,550.61a.12.12,0,1,1-.15-.19.12.12,0,0,1,.17,0A.11.11,0,0,1,141.52,550.61Z"
        ></path>
        <path
          className="cls-3"
          d="M141.24,562a.13.13,0,0,1-.15-.2.12.12,0,0,1,.17,0A.12.12,0,0,1,141.24,562Z"
        ></path>
        <path
          className="cls-3"
          d="M141.26,573.36a.09.09,0,1,1,0-.13A.09.09,0,0,1,141.26,573.36Z"
        ></path>
        <path
          className="cls-3"
          d="M141.62,584.74a.09.09,0,1,1,0-.13A.09.09,0,0,1,141.62,584.74Z"
        ></path>
        <path
          className="cls-3"
          d="M142.31,596.13a.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.12.12,0,0,1,142.31,596.13Z"
        ></path>
        <path
          className="cls-3"
          d="M143.34,607.3a.14.14,0,0,1,0,.17.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16A.12.12,0,0,1,143.34,607.3Z"
        ></path>
        <path
          className="cls-3"
          d="M144.67,618.6a.15.15,0,1,1-.24.17.16.16,0,0,1,0-.21A.15.15,0,0,1,144.67,618.6Z"
        ></path>
        <path
          className="cls-3"
          d="M146.24,630a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.11A.11.11,0,0,1,146.24,630Z"
        ></path>
        <path
          className="cls-3"
          d="M148.2,641.27a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.14.14,0,0,1,148.2,641.27Z"
        ></path>
        <path
          className="cls-3"
          d="M150.44,652.4a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,150.44,652.4Z"
        ></path>
        <path
          className="cls-3"
          d="M153,663.52a.12.12,0,0,1-.14-.2.14.14,0,0,1,.17,0A.13.13,0,0,1,153,663.52Z"
        ></path>
        <path
          className="cls-3"
          d="M155.91,674.54a.14.14,0,0,1-.17,0,.13.13,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,155.91,674.54Z"
        ></path>
        <path
          className="cls-3"
          d="M159.1,685.47a.14.14,0,0,1-.17,0,.13.13,0,0,1,0-.17.12.12,0,0,1,.14.2Z"
        ></path>
        <path
          className="cls-3"
          d="M162.6,696.3a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0A.14.14,0,0,1,162.6,696.3Z"
        ></path>
        <path
          className="cls-3"
          d="M166.39,707a.12.12,0,0,1-.16,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,166.39,707Z"
        ></path>
        <path
          className="cls-3"
          d="M170.49,717.66a.12.12,0,0,1-.17,0,.12.12,0,0,1,.2-.14A.12.12,0,0,1,170.49,717.66Z"
        ></path>
        <path
          className="cls-3"
          d="M174.87,728.14a.09.09,0,1,1,0-.13A.09.09,0,0,1,174.87,728.14Z"
        ></path>
        <path
          className="cls-3"
          d="M179.55,738.52a.1.1,0,0,1-.12,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.1.1,0,0,1,179.55,738.52Z"
        ></path>
        <path
          className="cls-3"
          d="M184.55,748.78a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0A.13.13,0,0,1,184.55,748.78Z"
        ></path>
        <path
          className="cls-3"
          d="M189.81,758.88a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.14.2Z"
        ></path>
        <circle className="cls-3" cx="195.28" cy="768.73" r="0.12"></circle>
        <path
          className="cls-3"
          d="M201.17,778.61a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,1,1,.14.19Z"
        ></path>
        <path
          className="cls-3"
          d="M207.26,788.23a.12.12,0,0,1-.17,0,.11.11,0,0,1,0-.16.12.12,0,1,1,.14.19Z"
        ></path>
        <path
          className="cls-3"
          d="M213.62,797.68a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.14.14,0,0,1,213.62,797.68Z"
        ></path>
        <path
          className="cls-3"
          d="M220.24,806.94a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.13.13,0,0,1,220.24,806.94Z"
        ></path>
        <path
          className="cls-3"
          d="M227.12,816a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,227.12,816Z"
        ></path>
        <path
          className="cls-3"
          d="M234.25,824.89a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.14.2Z"
        ></path>
        <path
          className="cls-3"
          d="M241.62,833.57a.14.14,0,0,1-.17,0,.14.14,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,241.62,833.57Z"
        ></path>
        <path
          className="cls-3"
          d="M249.24,842a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.14.2Z"
        ></path>
        <path
          className="cls-3"
          d="M257.07,850.25a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,257.07,850.25Z"
        ></path>
        <path
          className="cls-3"
          d="M265.15,858.27a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.12.09.09,0,1,1,.1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M273.45,866.07a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,273.45,866.07Z"
        ></path>
        <path
          className="cls-3"
          d="M282,873.65a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,282,873.65Z"
        ></path>
        <path
          className="cls-3"
          d="M290.71,881a.14.14,0,0,1-.17,0,.13.13,0,0,1,0-.17.12.12,0,1,1,.14.2Z"
        ></path>
        <path
          className="cls-3"
          d="M299.64,888a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.14.14,0,0,1,299.64,888Z"
        ></path>
        <path
          className="cls-3"
          d="M308.76,894.85a.13.13,0,0,1-.17,0,.12.12,0,0,1,0-.17.13.13,0,0,1,.17,0A.14.14,0,0,1,308.76,894.85Z"
        ></path>
        <path
          className="cls-3"
          d="M318.07,901.41a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.11.11,0,0,1,.16,0A.12.12,0,0,1,318.07,901.41Z"
        ></path>
        <path
          className="cls-3"
          d="M327.54,907.67a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,327.54,907.67Z"
        ></path>
        <path
          className="cls-3"
          d="M337.2,913.7a.09.09,0,1,1,0-.13A.09.09,0,0,1,337.2,913.7Z"
        ></path>
        <path
          className="cls-3"
          d="M347,919.47a.11.11,0,0,1-.16,0,.12.12,0,0,1,.19-.15A.12.12,0,0,1,347,919.47Z"
        ></path>
        <path
          className="cls-3"
          d="M357,924.92a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12c.05,0,.1,0,.13,0A.08.08,0,0,1,357,924.92Z"
        ></path>
        <path
          className="cls-3"
          d="M361.52,941.48a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,361.52,941.48Z"
        ></path>
        <path
          className="cls-3"
          d="M371.79,946.39a.09.09,0,1,1,0-.12A.08.08,0,0,1,371.79,946.39Z"
        ></path>
        <path
          className="cls-3"
          d="M382.2,951a.09.09,0,1,1,0-.13A.09.09,0,0,1,382.2,951Z"
        ></path>
        <path
          className="cls-3"
          d="M392.72,955.36a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.12.09.09,0,1,1,.1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M403.36,959.42a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,403.36,959.42Z"
        ></path>
        <path
          className="cls-3"
          d="M414.11,963.18a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,414.11,963.18Z"
        ></path>
        <path
          className="cls-3"
          d="M425,966.65a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,425,966.65Z"
        ></path>
        <path
          className="cls-3"
          d="M435.89,969.83a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.11A.11.11,0,0,1,435.89,969.83Z"
        ></path>
        <path
          className="cls-3"
          d="M446.9,972.7a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.12.08.08,0,0,1,.12,0A.08.08,0,0,1,446.9,972.7Z"
        ></path>
        <path
          className="cls-3"
          d="M458,975.28a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,458,975.28Z"
        ></path>
        <path
          className="cls-3"
          d="M469.15,977.55a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,469.15,977.55Z"
        ></path>
        <path
          className="cls-3"
          d="M480.36,979.52a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.08.08,0,0,1,480.36,979.52Z"
        ></path>
        <path
          className="cls-3"
          d="M491.63,981.19a.11.11,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,1,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M502.93,982.54a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,502.93,982.54Z"
        ></path>
        <path
          className="cls-3"
          d="M514.27,983.59a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,514.27,983.59Z"
        ></path>
        <path
          className="cls-3"
          d="M525.62,984.3a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.06.06,0,0,1,525.62,984.3Z"
        ></path>
        <path
          className="cls-3"
          d="M537,984.76a.09.09,0,1,1-.11-.15.09.09,0,0,1,.13,0A.09.09,0,0,1,537,984.76Z"
        ></path>
        <path
          className="cls-3"
          d="M548.38,984.85a.06.06,0,0,1-.09,0,.06.06,0,1,1,.09,0Z"
        ></path>
        <path
          className="cls-3"
          d="M559.76,984.66a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,559.76,984.66Z"
        ></path>
        <path
          className="cls-3"
          d="M571.15,984.08a.06.06,0,0,1,0,.09.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08A.05.05,0,0,1,571.15,984.08Z"
        ></path>
        <path
          className="cls-3"
          d="M582.51,983.38a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.12,0A.08.08,0,0,1,582.51,983.38Z"
        ></path>
        <path
          className="cls-3"
          d="M593.84,982.27a.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.12.08.08,0,0,1,.12,0A.09.09,0,0,1,593.84,982.27Z"
        ></path>
        <path
          className="cls-3"
          d="M605.14,980.84a.09.09,0,1,1-.11-.15.09.09,0,0,1,.13,0A.09.09,0,0,1,605.14,980.84Z"
        ></path>
        <path
          className="cls-3"
          d="M616.39,979.11a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,616.39,979.11Z"
        ></path>
        <path
          className="cls-3"
          d="M627.58,977.05a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,627.58,977.05Z"
        ></path>
        <path
          className="cls-3"
          d="M638.74,974.73a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,638.74,974.73Z"
        ></path>
        <path
          className="cls-3"
          d="M649.81,972.09a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,649.81,972.09Z"
        ></path>
        <path
          className="cls-3"
          d="M660.83,969a.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13A.08.08,0,0,1,660.83,969Z"
        ></path>
        <path
          className="cls-3"
          d="M671.73,965.91a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M682.55,962.37a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,682.55,962.37Z"
        ></path>
        <path
          className="cls-3"
          d="M693.26,958.52a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,693.26,958.52Z"
        ></path>
        <path
          className="cls-3"
          d="M703.87,954.4a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.08.08,0,0,1,703.87,954.4Z"
        ></path>
        <path
          className="cls-3"
          d="M714.37,950a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,714.37,950Z"
        ></path>
        <path
          className="cls-3"
          d="M724.74,945.3a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,724.74,945.3Z"
        ></path>
        <path
          className="cls-3"
          d="M735,940.35a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,735,940.35Z"
        ></path>
        <path
          className="cls-3"
          d="M745.11,935.1a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,745.11,935.1Z"
        ></path>
        <circle className="cls-3" cx="755.02" cy="929.51" r="0.06"></circle>
        <path
          className="cls-3"
          d="M764.85,923.77a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,764.85,923.77Z"
        ></path>
        <path
          className="cls-3"
          d="M774.5,917.71a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,774.5,917.71Z"
        ></path>
        <circle className="cls-3" cx="783.94" cy="911.35" r="0.06"></circle>
        <path
          className="cls-3"
          d="M793.28,904.75a.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09A.05.05,0,0,1,793.28,904.75Z"
        ></path>
        <path
          className="cls-3"
          d="M802.39,898a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,802.39,898Z"
        ></path>
        <path
          className="cls-3"
          d="M811.32,890.94a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.05.05,0,0,1,811.32,890.94Z"
        ></path>
        <path
          className="cls-3"
          d="M820.05,883.64a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M828.58,876.1a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,828.58,876.1Z"
        ></path>
        <path
          className="cls-3"
          d="M836.92,868.35a.09.09,0,0,1-.1-.14.09.09,0,1,1,.1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M845,860.36a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,845,860.36Z"
        ></path>
        <path
          className="cls-3"
          d="M852.9,852.12a.06.06,0,0,1-.07-.09.06.06,0,0,1,.09,0A.05.05,0,0,1,852.9,852.12Z"
        ></path>
        <path
          className="cls-3"
          d="M860.56,843.7a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.06.06,0,0,1,860.56,843.7Z"
        ></path>
        <path
          className="cls-3"
          d="M867.92,835a.06.06,0,0,1,.09,0,.06.06,0,1,1-.1.07A.06.06,0,0,1,867.92,835Z"
        ></path>
        <path
          className="cls-3"
          d="M875.2,826.27a.08.08,0,0,1-.12,0,.09.09,0,0,1,.14-.1A.08.08,0,0,1,875.2,826.27Z"
        ></path>
        <path
          className="cls-3"
          d="M882.16,817.25a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0S882.2,817.23,882.16,817.25Z"
        ></path>
        <path
          className="cls-3"
          d="M888.84,808a.06.06,0,1,1,0-.09A.06.06,0,0,1,888.84,808Z"
        ></path>
        <path
          className="cls-3"
          d="M895.22,798.54a.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.09,0A.08.08,0,0,1,895.22,798.54Z"
        ></path>
        <path
          className="cls-3"
          d="M901.5,789.11a.09.09,0,1,1,0-.13A.09.09,0,0,1,901.5,789.11Z"
        ></path>
        <path
          className="cls-3"
          d="M907.41,779.37a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,907.41,779.37Z"
        ></path>
        <path
          className="cls-3"
          d="M913.07,769.49a.06.06,0,0,1-.08,0,.06.06,0,0,1,.1-.08A.07.07,0,0,1,913.07,769.49Z"
        ></path>
        <path
          className="cls-3"
          d="M918.48,759.48a.08.08,0,0,1-.12,0,.09.09,0,0,1,.14-.1A.08.08,0,0,1,918.48,759.48Z"
        ></path>
        <path
          className="cls-3"
          d="M923.49,749.17a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,923.49,749.17Z"
        ></path>
        <path
          className="cls-3"
          d="M928.44,739a.09.09,0,1,1,0-.13A.09.09,0,0,1,928.44,739Z"
        ></path>
        <path
          className="cls-3"
          d="M932.91,728.45a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0A.07.07,0,0,1,932.91,728.45Z"
        ></path>
        <path
          className="cls-3"
          d="M937.27,718a.09.09,0,1,1,0-.13A.09.09,0,0,1,937.27,718Z"
        ></path>
        <path
          className="cls-3"
          d="M941.16,707.23a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,941.16,707.23Z"
        ></path>
        <path
          className="cls-3"
          d="M944.84,696.43a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,944.84,696.43Z"
        ></path>
        <path
          className="cls-3"
          d="M948.32,685.69a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M951.33,674.61a.09.09,0,1,1,0,.13A.09.09,0,0,1,951.33,674.61Z"
        ></path>
        <path
          className="cls-3"
          d="M954.14,663.57a.1.1,0,0,1,.12,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.09.09,0,0,1,954.14,663.57Z"
        ></path>
        <path
          className="cls-3"
          d="M956.63,652.47a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,956.63,652.47Z"
        ></path>
        <path
          className="cls-3"
          d="M958.85,641.32a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,958.85,641.32Z"
        ></path>
        <path
          className="cls-3"
          d="M960.72,630.07a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,960.72,630.07Z"
        ></path>
        <path
          className="cls-3"
          d="M962.44,618.81a.09.09,0,1,1-.13,0A.09.09,0,0,1,962.44,618.81Z"
        ></path>
        <path
          className="cls-3"
          d="M963.61,607.5a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,963.61,607.5Z"
        ></path>
        <path
          className="cls-3"
          d="M964.56,596.13a.09.09,0,0,1,.13,0,.09.09,0,1,1-.15.11A.09.09,0,0,1,964.56,596.13Z"
        ></path>
        <path
          className="cls-3"
          d="M965.24,584.79a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,965.24,584.79Z"
        ></path>
        <path
          className="cls-3"
          d="M965.57,573.39a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,965.57,573.39Z"
        ></path>
        <path
          className="cls-3"
          d="M965.64,562a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,965.64,562Z"
        ></path>
        <path
          className="cls-3"
          d="M965.35,550.62a.09.09,0,1,1,.11.15.11.11,0,0,1-.13,0A.08.08,0,0,1,965.35,550.62Z"
        ></path>
        <path
          className="cls-3"
          d="M964.79,539.27a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,964.79,539.27Z"
        ></path>
        <path
          className="cls-3"
          d="M963.87,528a.09.09,0,0,1,0-.13.1.1,0,0,1,.13,0,.08.08,0,0,1,0,.12A.09.09,0,0,1,963.87,528Z"
        ></path>
        <path
          className="cls-3"
          d="M962.78,516.69a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,962.78,516.69Z"
        ></path>
        <path
          className="cls-3"
          d="M961.19,505.28a.1.1,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M959.39,494a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,959.39,494Z"
        ></path>
        <circle className="cls-3" cx="957.33" cy="482.93" r="0.06"></circle>
        <path
          className="cls-3"
          d="M954.86,471.73a.09.09,0,0,1,.13,0,.11.11,0,0,1,0,.13.09.09,0,0,1-.12,0A.08.08,0,0,1,954.86,471.73Z"
        ></path>
        <path
          className="cls-3"
          d="M952.16,460.7a.06.06,0,0,1,.07.09.06.06,0,0,1-.09,0A.05.05,0,0,1,952.16,460.7Z"
        ></path>
        <path
          className="cls-3"
          d="M949.14,449.72s.07,0,.08,0a.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,949.14,449.72Z"
        ></path>
        <path
          className="cls-3"
          d="M945.81,438.8a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,945.81,438.8Z"
        ></path>
        <path
          className="cls-3"
          d="M942.2,428a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,942.2,428Z"
        ></path>
        <path
          className="cls-3"
          d="M938.31,417.33a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,938.31,417.33Z"
        ></path>
        <path
          className="cls-3"
          d="M934.12,406.74a.06.06,0,0,1,.07.1.06.06,0,0,1-.08,0A.06.06,0,0,1,934.12,406.74Z"
        ></path>
        <path
          className="cls-3"
          d="M929.64,396.28a.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,929.64,396.28Z"
        ></path>
        <circle className="cls-3" cx="924.92" cy="385.98" r="0.06"></circle>
        <path
          className="cls-3"
          d="M919.82,375.7a.09.09,0,0,1,.11.15.11.11,0,0,1-.13,0A.08.08,0,0,1,919.82,375.7Z"
        ></path>
        <path
          className="cls-3"
          d="M914.52,365.65a.08.08,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,914.52,365.65Z"
        ></path>
        <path
          className="cls-3"
          d="M908.93,355.73a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,908.93,355.73Z"
        ></path>
        <path
          className="cls-3"
          d="M903.08,346a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.06.06,0,0,1-.07-.1Z"
        ></path>
        <path
          className="cls-3"
          d="M897,336.37a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,897,336.37Z"
        ></path>
        <path
          className="cls-3"
          d="M890.58,326.94a.06.06,0,0,1,.09,0,.06.06,0,1,1-.09,0Z"
        ></path>
        <path
          className="cls-3"
          d="M884,317.68a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,884,317.68Z"
        ></path>
        <path
          className="cls-3"
          d="M877.07,308.61a.06.06,0,0,1,.08,0,.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,877.07,308.61Z"
        ></path>
        <circle className="cls-3" cx="869.98" cy="299.78" r="0.06"></circle>
        <path
          className="cls-3"
          d="M862.58,291.05a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,862.58,291.05Z"
        ></path>
        <path
          className="cls-3"
          d="M855,282.57a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.09.08.08,0,0,1-.09,0A.05.05,0,0,1,855,282.57Z"
        ></path>
        <circle
          className="cls-3"
          cx="847.19"
          cy="274.35"
          r="0.09"
          transform="translate(-0.99 545.63) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M839.1,266.24a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08,0A.07.07,0,0,1,839.1,266.24Z"
        ></path>
        <path
          className="cls-3"
          d="M830.84,258.41a.05.05,0,0,1,.08,0,.05.05,0,1,1-.08,0Z"
        ></path>
        <path
          className="cls-3"
          d="M822.36,250.81a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.08.08,0,0,1-.09,0A.06.06,0,0,1,822.36,250.81Z"
        ></path>
        <path
          className="cls-3"
          d="M813.69,243.44a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,813.69,243.44Z"
        ></path>
        <path
          className="cls-3"
          d="M804.79,236.28a.09.09,0,1,1,0,.13A.09.09,0,0,1,804.79,236.28Z"
        ></path>
        <path
          className="cls-3"
          d="M795.73,229.39a.09.09,0,0,1,.13,0,.11.11,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,795.73,229.39Z"
        ></path>
        <path
          className="cls-3"
          d="M786.48,222.75a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,786.48,222.75Z"
        ></path>
        <path
          className="cls-3"
          d="M777.07,216.39a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,777.07,216.39Z"
        ></path>
        <circle
          className="cls-3"
          cx="767.51"
          cy="210.31"
          r="0.09"
          transform="translate(21.41 487.14) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M757.71,204.4a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,757.71,204.4Z"
        ></path>
        <path
          className="cls-3"
          d="M747.8,198.8a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,747.8,198.8Z"
        ></path>
        <path
          className="cls-3"
          d="M737.71,193.45a.1.1,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M727.51,188.41a.08.08,0,0,1,.12,0,.09.09,0,1,1-.12,0Z"
        ></path>
        <path
          className="cls-3"
          d="M717.17,183.64a.08.08,0,0,1,.12,0,.08.08,0,0,1,0,.12.08.08,0,0,1-.12,0A.08.08,0,0,1,717.17,183.64Z"
        ></path>
        <path
          className="cls-3"
          d="M706.72,179.18a.06.06,0,1,1,.07.09.06.06,0,1,1-.07-.09Z"
        ></path>
        <path
          className="cls-3"
          d="M696.12,175a.09.09,0,1,1,0,.13A.09.09,0,0,1,696.12,175Z"
        ></path>
        <path
          className="cls-3"
          d="M685.44,171.07a.06.06,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.06.06,0,0,1,685.44,171.07Z"
        ></path>
        <path
          className="cls-3"
          d="M674.63,167.42a.09.09,0,0,1,.13,0,.09.09,0,0,1,0,.13.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M663.76,164.12a.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08s-.07,0-.08,0A.06.06,0,0,1,663.76,164.12Z"
        ></path>
        <circle
          className="cls-3"
          cx="652.82"
          cy="161.15"
          r="0.09"
          transform="translate(28.55 411.01) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M641.73,158.37a.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,641.73,158.37Z"
        ></path>
        <path
          className="cls-3"
          d="M630.58,155.92a.1.1,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.11-.15Z"
        ></path>
        <path
          className="cls-3"
          d="M619.4,153.8a.08.08,0,0,1,.12,0,.09.09,0,0,1,0,.13.08.08,0,0,1-.12,0A.09.09,0,0,1,619.4,153.8Z"
        ></path>
        <circle
          className="cls-3"
          cx="608.21"
          cy="152.06"
          r="0.09"
          transform="translate(25.48 383.29) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M596.87,150.48a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,596.87,150.48Z"
        ></path>
        <path
          className="cls-3"
          d="M585.55,149.28a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,585.55,149.28Z"
        ></path>
        <path
          className="cls-3"
          d="M574.22,148.41a.05.05,0,0,1,.08,0,.07.07,0,0,1,0,.09.08.08,0,0,1-.09,0A.05.05,0,0,1,574.22,148.41Z"
        ></path>
        <path
          className="cls-3"
          d="M562.83,147.8a.09.09,0,1,1,.1.14.08.08,0,0,1-.12,0A.08.08,0,0,1,562.83,147.8Z"
        ></path>
        <path
          className="cls-3"
          d="M551.45,147.52a.08.08,0,0,1,.12,0,.09.09,0,1,1-.15.11A.09.09,0,0,1,551.45,147.52Z"
        ></path>
        <path
          className="cls-3"
          d="M540.06,147.56a.09.09,0,0,1,.13,0s0,.1,0,.12a.09.09,0,0,1-.13,0A.08.08,0,0,1,540.06,147.56Z"
        ></path>
        <path
          className="cls-3"
          d="M528.78,148.05a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,528.78,148.05Z"
        ></path>
        <path
          className="cls-3"
          d="M517.42,148.7a.09.09,0,0,1-.11-.15.11.11,0,0,1,.13,0A.08.08,0,0,1,517.42,148.7Z"
        ></path>
        <path
          className="cls-3"
          d="M506,149.52a.08.08,0,0,1,.12,0,.09.09,0,0,1-.14.1A.08.08,0,0,1,506,149.52Z"
        ></path>
        <path
          className="cls-3"
          d="M494.65,150.79a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,494.65,150.79Z"
        ></path>
        <path
          className="cls-3"
          d="M483.37,152.37a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,483.37,152.37Z"
        ></path>
        <circle
          className="cls-3"
          cx="472.2"
          cy="154.32"
          r="0.09"
          transform="translate(-1.37 304.38) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M461,156.44a.09.09,0,1,1,.11.15.1.1,0,0,1-.13,0A.08.08,0,0,1,461,156.44Z"
        ></path>
        <path
          className="cls-3"
          d="M450,159a.09.09,0,1,1-.13,0A.09.09,0,0,1,450,159Z"
        ></path>
        <path
          className="cls-3"
          d="M438.82,161.72a.09.09,0,1,1,0,.13A.09.09,0,0,1,438.82,161.72Z"
        ></path>
        <path
          className="cls-3"
          d="M428,165a.09.09,0,1,1-.1-.14.09.09,0,0,1,.1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M417.1,168.35a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.09.09,0,0,1,.1.15Z"
        ></path>
        <path
          className="cls-3"
          d="M406.33,172a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,406.33,172Z"
        ></path>
        <path
          className="cls-3"
          d="M395.68,175.88a.09.09,0,1,1-.13,0A.09.09,0,0,1,395.68,175.88Z"
        ></path>
        <path
          className="cls-3"
          d="M385.1,180.27a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.12.09.09,0,0,1,.1.14Z"
        ></path>
        <circle
          className="cls-3"
          cx="374.61"
          cy="184.75"
          r="0.09"
          transform="translate(-37.44 253.18) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M364.35,189.66a.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.12.08.08,0,0,1,.12,0A.09.09,0,0,1,364.35,189.66Z"
        ></path>
        <path
          className="cls-3"
          d="M354.2,194.64a.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12A.09.09,0,0,1,354.2,194.64Z"
        ></path>
        <path
          className="cls-3"
          d="M344.15,200.15a.09.09,0,1,1,0-.13A.09.09,0,0,1,344.15,200.15Z"
        ></path>
        <path
          className="cls-3"
          d="M334.26,205.81a.09.09,0,0,1-.1-.15.09.09,0,0,1,.13,0A.11.11,0,0,1,334.26,205.81Z"
        ></path>
        <path
          className="cls-3"
          d="M324.54,211.73a.09.09,0,1,1,0-.13A.09.09,0,0,1,324.54,211.73Z"
        ></path>
        <path
          className="cls-3"
          d="M314.87,217.77a.09.09,0,0,1,.13,0,.08.08,0,0,1,0,.12.09.09,0,0,1-.13,0A.08.08,0,0,1,314.87,217.77Z"
        ></path>
        <circle
          className="cls-3"
          cx="305.54"
          cy="224.28"
          r="0.09"
          transform="translate(-73.46 220.31) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M296.38,231.05a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,296.38,231.05Z"
        ></path>
        <path
          className="cls-3"
          d="M287.36,238a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,287.36,238Z"
        ></path>
        <circle
          className="cls-3"
          cx="278.47"
          cy="245.11"
          r="0.09"
          transform="translate(-90.69 208.43) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M269.89,252.6a.09.09,0,0,1-.1-.14.09.09,0,1,1,.1.14Z"
        ></path>
        <path
          className="cls-3"
          d="M261.47,260.26a.09.09,0,1,1,0-.13A.09.09,0,0,1,261.47,260.26Z"
        ></path>
        <path
          className="cls-3"
          d="M253.25,268.14a.09.09,0,0,1-.11-.15.1.1,0,0,1,.13,0A.08.08,0,0,1,253.25,268.14Z"
        ></path>
        <path
          className="cls-3"
          d="M245.25,276.24a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,245.25,276.24Z"
        ></path>
        <path
          className="cls-3"
          d="M237.47,284.56a.08.08,0,0,1-.12,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.09.09,0,0,1,237.47,284.56Z"
        ></path>
        <path
          className="cls-3"
          d="M229.93,293.09a.09.09,0,1,1,0-.13A.09.09,0,0,1,229.93,293.09Z"
        ></path>
        <path
          className="cls-3"
          d="M222.62,301.82a.09.09,0,0,1-.11-.15.09.09,0,1,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M215.54,310.74a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,215.54,310.74Z"
        ></path>
        <path
          className="cls-3"
          d="M208.72,319.85a.09.09,0,1,1,0-.13A.09.09,0,0,1,208.72,319.85Z"
        ></path>
        <path
          className="cls-3"
          d="M202.16,329.17a.12.12,0,0,1-.17,0,.12.12,0,0,1,0-.17.14.14,0,0,1,.17,0A.13.13,0,0,1,202.16,329.17Z"
        ></path>
        <path
          className="cls-3"
          d="M195.82,338.62a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M189.76,348.25a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,189.76,348.25Z"
        ></path>
        <path
          className="cls-3"
          d="M184,358.05a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.12.08.08,0,0,1,.12,0A.08.08,0,0,1,184,358.05Z"
        ></path>
        <path
          className="cls-3"
          d="M178.43,368a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,178.43,368Z"
        ></path>
        <path
          className="cls-3"
          d="M173.18,378.11a.09.09,0,1,1,0-.13A.09.09,0,0,1,173.18,378.11Z"
        ></path>
        <path
          className="cls-3"
          d="M168.2,388.35a.09.09,0,1,1,0-.13A.09.09,0,0,1,168.2,388.35Z"
        ></path>
        <path
          className="cls-3"
          d="M163.5,398.72a.09.09,0,1,1-.11-.15.09.09,0,0,1,.13,0A.09.09,0,0,1,163.5,398.72Z"
        ></path>
        <path
          className="cls-3"
          d="M159.09,409.22a.1.1,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M155,419.83a.09.09,0,1,1,0-.13A.09.09,0,0,1,155,419.83Z"
        ></path>
        <path
          className="cls-3"
          d="M151.12,430.55a.09.09,0,1,1-.11-.15.09.09,0,0,1,.13,0A.09.09,0,0,1,151.12,430.55Z"
        ></path>
        <path
          className="cls-3"
          d="M147.58,441.37a.09.09,0,1,1,0-.13A.09.09,0,0,1,147.58,441.37Z"
        ></path>
        <path
          className="cls-3"
          d="M144.33,452.28a.09.09,0,1,1,0-.13A.09.09,0,0,1,144.33,452.28Z"
        ></path>
        <path
          className="cls-3"
          d="M141.39,463.3a.11.11,0,0,1-.16,0,.11.11,0,0,1,0-.16.12.12,0,0,1,.17,0A.12.12,0,0,1,141.39,463.3Z"
        ></path>
        <path
          className="cls-3"
          d="M138.73,474.35a.09.09,0,0,1-.13,0,.09.09,0,1,1,.13,0Z"
        ></path>
        <path
          className="cls-3"
          d="M136.38,485.49a.09.09,0,1,1,0-.13A.09.09,0,0,1,136.38,485.49Z"
        ></path>
        <path
          className="cls-3"
          d="M134.33,496.69a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.08.08,0,0,1,134.33,496.69Z"
        ></path>
        <path
          className="cls-3"
          d="M132.59,507.94a.08.08,0,0,1-.12,0,.09.09,0,1,1,.12,0Z"
        ></path>
        <path
          className="cls-3"
          d="M131.16,519.24a.09.09,0,1,1,0-.13A.09.09,0,0,1,131.16,519.24Z"
        ></path>
        <path
          className="cls-3"
          d="M130,530.57a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.1.1,0,0,1,130,530.57Z"
        ></path>
        <path
          className="cls-3"
          d="M129.22,541.93a.09.09,0,1,1,0-.13A.09.09,0,0,1,129.22,541.93Z"
        ></path>
        <path
          className="cls-3"
          d="M128.71,553.3a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.09.09,0,0,1,.1.15Z"
        ></path>
        <path
          className="cls-3"
          d="M128.51,564.69a.08.08,0,0,1-.12,0,.08.08,0,0,1,0-.12.08.08,0,0,1,.12,0A.09.09,0,0,1,128.51,564.69Z"
        ></path>
        <path
          className="cls-3"
          d="M128.63,576.07a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.11.11,0,0,1,.13,0A.09.09,0,0,1,128.63,576.07Z"
        ></path>
        <path
          className="cls-3"
          d="M129.05,587.45a.09.09,0,1,1,0-.13A.09.09,0,0,1,129.05,587.45Z"
        ></path>
        <path
          className="cls-3"
          d="M129.8,598.84a.14.14,0,0,1-.17,0,.12.12,0,0,1,0-.17.12.12,0,0,1,.17,0A.12.12,0,0,1,129.8,598.84Z"
        ></path>
        <path
          className="cls-3"
          d="M130.84,610a.09.09,0,1,1-.13,0A.09.09,0,0,1,130.84,610Z"
        ></path>
        <path
          className="cls-3"
          d="M132.16,621.46a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.13,0A.11.11,0,0,1,132.16,621.46Z"
        ></path>
        <path
          className="cls-3"
          d="M133.82,632.72a.09.09,0,0,1-.11-.15.09.09,0,0,1,.13,0A.08.08,0,0,1,133.82,632.72Z"
        ></path>
        <path
          className="cls-3"
          d="M135.78,643.94a.09.09,0,1,1,0-.13A.09.09,0,0,1,135.78,643.94Z"
        ></path>
        <path
          className="cls-3"
          d="M138,655.1a.09.09,0,0,1-.12,0,.08.08,0,0,1,0-.12.08.08,0,0,1,.12,0A.09.09,0,0,1,138,655.1Z"
        ></path>
        <path
          className="cls-3"
          d="M140.61,666.19a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,140.61,666.19Z"
        ></path>
        <path
          className="cls-3"
          d="M143.48,677.21a.09.09,0,1,1,0-.13A.09.09,0,0,1,143.48,677.21Z"
        ></path>
        <path
          className="cls-3"
          d="M146.66,688.17a.11.11,0,0,1-.16,0,.12.12,0,1,1,.19-.14A.12.12,0,0,1,146.66,688.17Z"
        ></path>
        <path
          className="cls-3"
          d="M150.11,699a.09.09,0,1,1,0-.13A.09.09,0,0,1,150.11,699Z"
        ></path>
        <path
          className="cls-3"
          d="M153.86,709.74a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.09.09,0,0,1,153.86,709.74Z"
        ></path>
        <path
          className="cls-3"
          d="M157.91,720.38a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.08.08,0,0,1,157.91,720.38Z"
        ></path>
        <path
          className="cls-3"
          d="M162.25,730.91a.09.09,0,1,1,0-.13A.09.09,0,0,1,162.25,730.91Z"
        ></path>
        <path
          className="cls-3"
          d="M166.87,741.32a.09.09,0,1,1,0-.13A.09.09,0,0,1,166.87,741.32Z"
        ></path>
        <path
          className="cls-3"
          d="M171.77,751.59a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,171.77,751.59Z"
        ></path>
        <path
          className="cls-3"
          d="M177,761.73a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,177,761.73Z"
        ></path>
        <path
          className="cls-3"
          d="M182.4,771.73a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,182.4,771.73Z"
        ></path>
        <path
          className="cls-3"
          d="M188.12,781.57a.08.08,0,0,1-.12,0,.09.09,0,1,1,.15-.11A.09.09,0,0,1,188.12,781.57Z"
        ></path>
        <path
          className="cls-3"
          d="M194.11,791.26a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,194.11,791.26Z"
        ></path>
        <path
          className="cls-3"
          d="M200.36,800.77a.09.09,0,1,1,0-.12A.08.08,0,0,1,200.36,800.77Z"
        ></path>
        <path
          className="cls-3"
          d="M206.87,810.12a.09.09,0,1,1,0-.13A.09.09,0,0,1,206.87,810.12Z"
        ></path>
        <path
          className="cls-3"
          d="M213.63,819.28a.09.09,0,1,1,0-.13A.09.09,0,0,1,213.63,819.28Z"
        ></path>
        <circle
          className="cls-3"
          cx="220.58"
          cy="828.18"
          r="0.09"
          transform="translate(-441.64 284.12) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M227.88,837a.09.09,0,1,1,0-.13A.09.09,0,0,1,227.88,837Z"
        ></path>
        <path
          className="cls-3"
          d="M235.36,845.62a.09.09,0,0,1-.11-.15.11.11,0,0,1,.13,0A.08.08,0,0,1,235.36,845.62Z"
        ></path>
        <path
          className="cls-3"
          d="M243.07,854a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,243.07,854Z"
        ></path>
        <path
          className="cls-3"
          d="M251,862.16a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.09.09,0,0,1,.13,0A.09.09,0,0,1,251,862.16Z"
        ></path>
        <path
          className="cls-3"
          d="M259.17,870.11a.11.11,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M267.54,877.82a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,267.54,877.82Z"
        ></path>
        <path
          className="cls-3"
          d="M276.11,885.31a.08.08,0,0,1-.12,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,276.11,885.31Z"
        ></path>
        <path
          className="cls-3"
          d="M284.89,892.57a.1.1,0,0,1-.12,0,.09.09,0,1,1,.14-.1A.09.09,0,0,1,284.89,892.57Z"
        ></path>
        <path
          className="cls-3"
          d="M293.86,899.58a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,293.86,899.58Z"
        ></path>
        <path
          className="cls-3"
          d="M303,906.34a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,303,906.34Z"
        ></path>
        <path
          className="cls-3"
          d="M312.36,912.85a.09.09,0,0,1-.13,0,.09.09,0,0,1,.15-.1A.08.08,0,0,1,312.36,912.85Z"
        ></path>
        <path
          className="cls-3"
          d="M321.87,919.11a.08.08,0,0,1-.12,0,.09.09,0,0,1,.15-.11A.11.11,0,0,1,321.87,919.11Z"
        ></path>
        <path
          className="cls-3"
          d="M331.55,925.11a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.13,0A.11.11,0,0,1,331.55,925.11Z"
        ></path>
        <circle
          className="cls-3"
          cx="341.34"
          cy="930.76"
          r="0.09"
          transform="translate(-478.8 373.81) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M351.38,936.3a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,351.38,936.3Z"
        ></path>
        <path
          className="cls-3"
          d="M355.88,952.83a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,355.88,952.83Z"
        ></path>
        <path
          className="cls-3"
          d="M366.15,957.74a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,366.15,957.74Z"
        ></path>
        <path
          className="cls-3"
          d="M376.55,962.38a.06.06,0,0,1-.09,0,.06.06,0,1,1,.1-.07A.06.06,0,0,1,376.55,962.38Z"
        ></path>
        <path
          className="cls-3"
          d="M387.06,966.75a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,387.06,966.75Z"
        ></path>
        <path
          className="cls-3"
          d="M397.69,970.83a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M408.42,974.64a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,408.42,974.64Z"
        ></path>
        <path
          className="cls-3"
          d="M419.25,978.15a.05.05,0,0,1-.08,0s0-.07,0-.08a.06.06,0,0,1,.09,0A.05.05,0,0,1,419.25,978.15Z"
        ></path>
        <path
          className="cls-3"
          d="M430.17,981.38a.06.06,0,1,1,0-.08S430.2,981.37,430.17,981.38Z"
        ></path>
        <path
          className="cls-3"
          d="M441.17,984.33a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,441.17,984.33Z"
        ></path>
        <path
          className="cls-3"
          d="M452.24,987a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.08.08,0,0,1,452.24,987Z"
        ></path>
        <path
          className="cls-3"
          d="M463.38,989.34a.07.07,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.08.08,0,0,1,463.38,989.34Z"
        ></path>
        <path
          className="cls-3"
          d="M474.58,991.4a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,474.58,991.4Z"
        ></path>
        <circle className="cls-3" cx="485.79" cy="993.12" r="0.06"></circle>
        <path
          className="cls-3"
          d="M497.12,994.63a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,497.12,994.63Z"
        ></path>
        <path
          className="cls-3"
          d="M508.46,995.83a.09.09,0,0,1-.13,0,.11.11,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,508.46,995.83Z"
        ></path>
        <path
          className="cls-3"
          d="M519.8,996.68a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,519.8,996.68Z"
        ></path>
        <path
          className="cls-3"
          d="M531.17,997.25a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,531.17,997.25Z"
        ></path>
        <path
          className="cls-3"
          d="M542.55,997.52a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,542.55,997.52Z"
        ></path>
        <path
          className="cls-3"
          d="M553.94,997.49a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,553.94,997.49Z"
        ></path>
        <path
          className="cls-3"
          d="M565.32,997.16a.07.07,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,565.32,997.16Z"
        ></path>
        <path
          className="cls-3"
          d="M576.69,996.52a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,576.69,996.52Z"
        ></path>
        <path
          className="cls-3"
          d="M588,995.6a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,588,995.6Z"
        ></path>
        <path
          className="cls-3"
          d="M599.35,994.36a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,599.35,994.36Z"
        ></path>
        <path
          className="cls-3"
          d="M610.64,992.84a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,610.64,992.84Z"
        ></path>
        <path
          className="cls-3"
          d="M621.89,990.93a.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09A.06.06,0,0,1,621.89,990.93Z"
        ></path>
        <circle className="cls-3" cx="633.03" cy="988.84" r="0.06"></circle>
        <path
          className="cls-3"
          d="M644.19,986.47a.06.06,0,0,1-.09,0s0-.07,0-.08a.06.06,0,1,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M655.25,983.76a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M666.23,980.76a.06.06,0,0,1-.07-.09.06.06,0,0,1,.09,0A.05.05,0,0,1,666.23,980.76Z"
        ></path>
        <path
          className="cls-3"
          d="M677.13,977.48a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,677.13,977.48Z"
        ></path>
        <path
          className="cls-3"
          d="M687.94,973.9a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,687.94,973.9Z"
        ></path>
        <path
          className="cls-3"
          d="M698.65,970a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,698.65,970Z"
        ></path>
        <path
          className="cls-3"
          d="M709.26,965.9a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.08.08,0,0,1,709.26,965.9Z"
        ></path>
        <path
          className="cls-3"
          d="M719.75,961.48a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,719.75,961.48Z"
        ></path>
        <path
          className="cls-3"
          d="M730.13,956.78a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,730.13,956.78Z"
        ></path>
        <path
          className="cls-3"
          d="M740.39,951.73a.07.07,0,0,1,0,.09.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08A.07.07,0,0,1,740.39,951.73Z"
        ></path>
        <path
          className="cls-3"
          d="M750.48,946.58a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,750.48,946.58Z"
        ></path>
        <path
          className="cls-3"
          d="M760.45,941.08a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,760.45,941.08Z"
        ></path>
        <path
          className="cls-3"
          d="M770.27,935.32a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,770.27,935.32Z"
        ></path>
        <path
          className="cls-3"
          d="M779.94,929.3a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,779.94,929.3Z"
        ></path>
        <path
          className="cls-3"
          d="M789.44,923a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,789.44,923Z"
        ></path>
        <path
          className="cls-3"
          d="M798.77,916.51a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,798.77,916.51Z"
        ></path>
        <circle className="cls-3" cx="807.9" cy="909.69" r="0.06"></circle>
        <path
          className="cls-3"
          d="M816.91,902.74a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,816.91,902.74Z"
        ></path>
        <path
          className="cls-3"
          d="M825.7,895.5a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,825.7,895.5Z"
        ></path>
        <path
          className="cls-3"
          d="M834.29,888a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,834.29,888Z"
        ></path>
        <path
          className="cls-3"
          d="M842.68,880.34a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,842.68,880.34Z"
        ></path>
        <path
          className="cls-3"
          d="M850.8,872.32a.08.08,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,850.8,872.32Z"
        ></path>
        <path
          className="cls-3"
          d="M858.85,864.3a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,858.85,864.3Z"
        ></path>
        <path
          className="cls-3"
          d="M866.61,856a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,866.61,856Z"
        ></path>
        <path
          className="cls-3"
          d="M874.15,847.43a.06.06,0,0,1-.08-.1.07.07,0,0,1,.09,0A.06.06,0,0,1,874.15,847.43Z"
        ></path>
        <path
          className="cls-3"
          d="M881.45,838.7a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,881.45,838.7Z"
        ></path>
        <path
          className="cls-3"
          d="M888.53,829.78a.07.07,0,0,1-.09,0,.08.08,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,888.53,829.78Z"
        ></path>
        <path
          className="cls-3"
          d="M895.37,820.68a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,895.37,820.68Z"
        ></path>
        <path
          className="cls-3"
          d="M902,811.39a.05.05,0,0,1-.08,0s0-.07,0-.08a.06.06,0,1,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M908.31,801.94a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,908.31,801.94Z"
        ></path>
        <path
          className="cls-3"
          d="M914.41,792.32a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,914.41,792.32Z"
        ></path>
        <path
          className="cls-3"
          d="M920.18,782.45a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,920.18,782.45Z"
        ></path>
        <path
          className="cls-3"
          d="M925.75,772.53a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.08.08,0,0,1-.09,0A.06.06,0,0,1,925.75,772.53Z"
        ></path>
        <path
          className="cls-3"
          d="M931.14,762.56a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.08.08,0,0,1,931.14,762.56Z"
        ></path>
        <path
          className="cls-3"
          d="M936.12,752.25a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,936.12,752.25Z"
        ></path>
        <path
          className="cls-3"
          d="M940.9,741.92a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.09.08.08,0,0,1-.09,0A.05.05,0,0,1,940.9,741.92Z"
        ></path>
        <path
          className="cls-3"
          d="M945.47,731.56a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,945.47,731.56Z"
        ></path>
        <path
          className="cls-3"
          d="M949.7,721a.06.06,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.06.06,0,0,1,949.7,721Z"
        ></path>
        <path
          className="cls-3"
          d="M953.57,710.21a.06.06,0,0,1,.07.09s-.07,0-.08,0S953.54,710.22,953.57,710.21Z"
        ></path>
        <path
          className="cls-3"
          d="M957.3,699.52a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M960.68,688.65a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,960.68,688.65Z"
        ></path>
        <path
          className="cls-3"
          d="M963.76,677.69a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,963.76,677.69Z"
        ></path>
        <path
          className="cls-3"
          d="M966.49,666.55a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,966.49,666.55Z"
        ></path>
        <path
          className="cls-3"
          d="M969,655.45a.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,969,655.45Z"
        ></path>
        <path
          className="cls-3"
          d="M971.2,644.28a.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,971.2,644.28Z"
        ></path>
        <path
          className="cls-3"
          d="M973.12,633.05a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,973.12,633.05Z"
        ></path>
        <circle className="cls-3" cx="974.77" cy="621.83" r="0.06"></circle>
        <path
          className="cls-3"
          d="M976.06,610.47a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,976.06,610.47Z"
        ></path>
        <path
          className="cls-3"
          d="M977.08,599.13a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,977.08,599.13Z"
        ></path>
        <path
          className="cls-3"
          d="M977.8,587.77a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,977.8,587.77Z"
        ></path>
        <path
          className="cls-3"
          d="M978.22,576.39a.05.05,0,0,1,.08,0,.05.05,0,1,1-.08,0Z"
        ></path>
        <path
          className="cls-3"
          d="M978.34,565a.06.06,0,0,1,.07.09.06.06,0,0,1-.09,0S978.31,565,978.34,565Z"
        ></path>
        <path
          className="cls-3"
          d="M978.16,553.62a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0A.07.07,0,0,1,978.16,553.62Z"
        ></path>
        <path
          className="cls-3"
          d="M977.68,542.25a.06.06,0,1,1,.07.09.06.06,0,0,1-.09,0A.05.05,0,0,1,977.68,542.25Z"
        ></path>
        <circle className="cls-3" cx="976.93" cy="530.93" r="0.06"></circle>
        <path
          className="cls-3"
          d="M975.8,519.64a.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08A.07.07,0,0,1,975.8,519.64Z"
        ></path>
        <path
          className="cls-3"
          d="M974.44,508.25a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,974.44,508.25Z"
        ></path>
        <path
          className="cls-3"
          d="M972.76,497a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,972.76,497Z"
        ></path>
        <path
          className="cls-3"
          d="M970.79,485.77a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,970.79,485.77Z"
        ></path>
        <path
          className="cls-3"
          d="M968.52,474.62a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,968.52,474.62Z"
        ></path>
        <path
          className="cls-3"
          d="M966,463.52a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,966,463.52Z"
        ></path>
        <path
          className="cls-3"
          d="M963.12,452.52s0,0,0,0a0,0,0,0,1,0,0h0S963.1,452.53,963.12,452.52Z"
        ></path>
        <path
          className="cls-3"
          d="M960,441.56a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,960,441.56Z"
        ></path>
        <path
          className="cls-3"
          d="M956.52,430.7a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.07.07,0,0,1,956.52,430.7Z"
        ></path>
        <path
          className="cls-3"
          d="M952.81,419.94a.06.06,0,0,1,.08,0,.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,952.81,419.94Z"
        ></path>
        <path
          className="cls-3"
          d="M948.8,409.28a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,948.8,409.28Z"
        ></path>
        <path
          className="cls-3"
          d="M944.52,398.73a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,944.52,398.73Z"
        ></path>
        <path
          className="cls-3"
          d="M940,388.29a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,940,388.29Z"
        ></path>
        <path
          className="cls-3"
          d="M935.13,378a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,935.13,378Z"
        ></path>
        <path
          className="cls-3"
          d="M930,367.81a.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,930,367.81Z"
        ></path>
        <path
          className="cls-3"
          d="M924.66,357.76a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,924.66,357.76Z"
        ></path>
        <path
          className="cls-3"
          d="M919,347.87a.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,919,347.87Z"
        ></path>
        <path
          className="cls-3"
          d="M913.14,338.12a.08.08,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,913.14,338.12Z"
        ></path>
        <path
          className="cls-3"
          d="M907,328.54a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08,0A.07.07,0,0,1,907,328.54Z"
        ></path>
        <path
          className="cls-3"
          d="M900.6,319.12a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,900.6,319.12Z"
        ></path>
        <path
          className="cls-3"
          d="M894,309.87a.08.08,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,894,309.87Z"
        ></path>
        <circle className="cls-3" cx="887.1" cy="300.86" r="0.06"></circle>
        <path
          className="cls-3"
          d="M879.94,291.92a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08-.1Z"
        ></path>
        <path
          className="cls-3"
          d="M872.59,283.23a.07.07,0,0,1,.09,0,.08.08,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,872.59,283.23Z"
        ></path>
        <path
          className="cls-3"
          d="M865,274.74a.05.05,0,0,1,.08,0s0,.07,0,.08-.07,0-.08,0A.05.05,0,0,1,865,274.74Z"
        ></path>
        <path
          className="cls-3"
          d="M857.21,266.44a.07.07,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,857.21,266.44Z"
        ></path>
        <path
          className="cls-3"
          d="M849.19,258.36a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,849.19,258.36Z"
        ></path>
        <path
          className="cls-3"
          d="M841,250.49a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,841,250.49Z"
        ></path>
        <path
          className="cls-3"
          d="M832.52,242.84a.07.07,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,832.52,242.84Z"
        ></path>
        <path
          className="cls-3"
          d="M823.89,235.42a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,823.89,235.42Z"
        ></path>
        <path
          className="cls-3"
          d="M815.06,228.23a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,815.06,228.23Z"
        ></path>
        <path
          className="cls-3"
          d="M806.05,221.27a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,806.05,221.27Z"
        ></path>
        <circle className="cls-3" cx="796.89" cy="214.6" r="0.06"></circle>
        <circle className="cls-3" cx="787.52" cy="208.13" r="0.06"></circle>
        <path
          className="cls-3"
          d="M778,201.86a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,778,201.86Z"
        ></path>
        <circle className="cls-3" cx="768.29" cy="195.94" r="0.06"></circle>
        <path
          className="cls-3"
          d="M758.41,190.18a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,758.41,190.18Z"
        ></path>
        <path
          className="cls-3"
          d="M748.41,184.73a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,748.41,184.73Z"
        ></path>
        <path
          className="cls-3"
          d="M738.27,179.55a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,738.27,179.55Z"
        ></path>
        <path
          className="cls-3"
          d="M728,174.64a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.09.08.08,0,0,1-.09,0A.05.05,0,0,1,728,174.64Z"
        ></path>
        <path
          className="cls-3"
          d="M717.6,170a.06.06,0,0,1,.09,0,.06.06,0,1,1-.09,0Z"
        ></path>
        <path
          className="cls-3"
          d="M707.09,165.63a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,707.09,165.63Z"
        ></path>
        <circle className="cls-3" cx="696.49" cy="161.6" r="0.06"></circle>
        <path
          className="cls-3"
          d="M685.73,157.74a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,685.73,157.74Z"
        ></path>
        <path
          className="cls-3"
          d="M674.9,154.23a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,674.9,154.23Z"
        ></path>
        <path
          className="cls-3"
          d="M664,151a.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,664,151Z"
        ></path>
        <path
          className="cls-3"
          d="M653,148.05a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.07.07,0,0,1-.09,0A.08.08,0,0,1,653,148.05Z"
        ></path>
        <path
          className="cls-3"
          d="M641.91,145.4a.06.06,0,0,1,.08,0,.06.06,0,1,1-.1.08A.07.07,0,0,1,641.91,145.4Z"
        ></path>
        <path
          className="cls-3"
          d="M630.77,143a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,630.77,143Z"
        ></path>
        <path
          className="cls-3"
          d="M619.57,141a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,619.57,141Z"
        ></path>
        <path
          className="cls-3"
          d="M608.32,139.21a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,608.32,139.21Z"
        ></path>
        <path
          className="cls-3"
          d="M597,137.75s.07,0,.08,0a.06.06,0,1,1-.08,0Z"
        ></path>
        <path
          className="cls-3"
          d="M585.7,136.57a.08.08,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,585.7,136.57Z"
        ></path>
        <path
          className="cls-3"
          d="M574.44,135.72a.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09A.07.07,0,0,1,574.44,135.72Z"
        ></path>
        <circle className="cls-3" cx="563.01" cy="135.18" r="0.06"></circle>
        <path
          className="cls-3"
          d="M551.6,134.86a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0A.07.07,0,0,1,551.6,134.86Z"
        ></path>
        <path
          className="cls-3"
          d="M540.21,134.89a.06.06,0,0,1,.07.1.06.06,0,0,1-.08,0A.06.06,0,0,1,540.21,134.89Z"
        ></path>
        <path
          className="cls-3"
          d="M528.83,135.22a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,528.83,135.22Z"
        ></path>
        <path
          className="cls-3"
          d="M517.46,135.85a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,517.46,135.85Z"
        ></path>
        <circle className="cls-3" cx="506.15" cy="136.83" r="0.06"></circle>
        <circle className="cls-3" cx="494.83" cy="138.06" r="0.06"></circle>
        <path
          className="cls-3"
          d="M483.6,139.67a.1.1,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M472.26,141.45a.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0,.07.07,0,0,1,0,.09A.06.06,0,0,1,472.26,141.45Z"
        ></path>
        <path
          className="cls-3"
          d="M461.16,143.59a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,461.16,143.59Z"
        ></path>
        <path
          className="cls-3"
          d="M450,146a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M439,148.63a.05.05,0,0,1,0,.08s-.07,0-.08,0a.06.06,0,1,1,.09-.07Z"
        ></path>
        <path
          className="cls-3"
          d="M428,151.71a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,428,151.71Z"
        ></path>
        <path
          className="cls-3"
          d="M417.09,155a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,417.09,155Z"
        ></path>
        <path
          className="cls-3"
          d="M406.28,158.58a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,406.28,158.58Z"
        ></path>
        <path
          className="cls-3"
          d="M395.49,162.34a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,395.49,162.34Z"
        ></path>
        <path
          className="cls-3"
          d="M385,166.58a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,385,166.58Z"
        ></path>
        <circle
          className="cls-3"
          cx="374.43"
          cy="170.95"
          r="0.09"
          transform="translate(-29.42 250.48) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M364.09,175.69a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.08.08,0,0,1,.09,0A.05.05,0,0,1,364.09,175.69Z"
        ></path>
        <path
          className="cls-3"
          d="M353.85,180.66a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,353.85,180.66Z"
        ></path>
        <path
          className="cls-3"
          d="M343.74,185.9a.07.07,0,0,1-.09,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,343.74,185.9Z"
        ></path>
        <path
          className="cls-3"
          d="M333.77,191.4a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,333.77,191.4Z"
        ></path>
        <path
          className="cls-3"
          d="M324,197.16a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.07.07,0,0,1,324,197.16Z"
        ></path>
        <path
          className="cls-3"
          d="M314.28,203.18a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,314.28,203.18Z"
        ></path>
        <circle className="cls-3" cx="304.74" cy="209.4" r="0.06"></circle>
        <path
          className="cls-3"
          d="M295.46,215.89a.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09A.07.07,0,0,1,295.46,215.89Z"
        ></path>
        <path
          className="cls-3"
          d="M286.29,222.74a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,286.29,222.74Z"
        ></path>
        <path
          className="cls-3"
          d="M277.31,229.74a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M268.52,237a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M259.93,244.45a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,259.93,244.45Z"
        ></path>
        <path
          className="cls-3"
          d="M251.53,252.14a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,251.53,252.14Z"
        ></path>
        <path
          className="cls-3"
          d="M243.35,260.05a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,243.35,260.05Z"
        ></path>
        <path
          className="cls-3"
          d="M235.37,268.18a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,235.37,268.18Z"
        ></path>
        <path
          className="cls-3"
          d="M227.63,276.54a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M220.09,285.07a.09.09,0,0,1-.11-.15.09.09,0,1,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M212.76,293.78a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,212.76,293.78Z"
        ></path>
        <path
          className="cls-3"
          d="M205.69,302.7a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,205.69,302.7Z"
        ></path>
        <path
          className="cls-3"
          d="M198.85,311.8a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M192.26,321.08a.06.06,0,0,1-.09,0,.06.06,0,0,1,.1-.07A.06.06,0,0,1,192.26,321.08Z"
        ></path>
        <path
          className="cls-3"
          d="M185.91,330.54a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,185.91,330.54Z"
        ></path>
        <path
          className="cls-3"
          d="M179.81,340.15a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.08.08,0,0,1,.09,0A.05.05,0,0,1,179.81,340.15Z"
        ></path>
        <path
          className="cls-3"
          d="M174,350a.09.09,0,1,1,0-.12A.08.08,0,0,1,174,350Z"
        ></path>
        <path
          className="cls-3"
          d="M168.41,359.88a.09.09,0,0,1-.13,0,.09.09,0,0,1,0-.13.09.09,0,0,1,.11.15Z"
        ></path>
        <path
          className="cls-3"
          d="M163.09,370a.08.08,0,0,1-.12,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.1.1,0,0,1,163.09,370Z"
        ></path>
        <path
          className="cls-3"
          d="M158,380.13a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,158,380.13Z"
        ></path>
        <path
          className="cls-3"
          d="M153.25,390.46a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,153.25,390.46Z"
        ></path>
        <path
          className="cls-3"
          d="M148.75,400.92a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,148.75,400.92Z"
        ></path>
        <path
          className="cls-3"
          d="M144.52,411.49a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.05.05,0,0,1,144.52,411.49Z"
        ></path>
        <path
          className="cls-3"
          d="M140.6,422.2a.09.09,0,1,1,0-.13A.09.09,0,0,1,140.6,422.2Z"
        ></path>
        <path
          className="cls-3"
          d="M136.94,433a.09.09,0,1,1,0-.13A.11.11,0,0,1,136.94,433Z"
        ></path>
        <path
          className="cls-3"
          d="M133.54,443.83a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,133.54,443.83Z"
        ></path>
        <path
          className="cls-3"
          d="M130.45,454.79a.06.06,0,0,1-.08,0,.06.06,0,0,1,.1-.08A.07.07,0,0,1,130.45,454.79Z"
        ></path>
        <path
          className="cls-3"
          d="M127.66,465.83a.06.06,0,1,1-.07-.1.05.05,0,0,1,.08,0A.06.06,0,0,1,127.66,465.83Z"
        ></path>
        <path
          className="cls-3"
          d="M125.17,477a.09.09,0,0,1-.13,0,.1.1,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,125.17,477Z"
        ></path>
        <path
          className="cls-3"
          d="M122.94,488.1a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.05.05,0,0,1,122.94,488.1Z"
        ></path>
        <path
          className="cls-3"
          d="M121,499.33a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,121,499.33Z"
        ></path>
        <path
          className="cls-3"
          d="M119.43,510.62a.09.09,0,0,1-.11-.15.1.1,0,0,1,.13,0A.08.08,0,0,1,119.43,510.62Z"
        ></path>
        <path
          className="cls-3"
          d="M118.09,521.91a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,118.09,521.91Z"
        ></path>
        <path
          className="cls-3"
          d="M117.07,533.25a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.08.08,0,0,1,117.07,533.25Z"
        ></path>
        <path
          className="cls-3"
          d="M116.35,544.61a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,116.35,544.61Z"
        ></path>
        <path
          className="cls-3"
          d="M115.93,556a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,115.93,556Z"
        ></path>
        <path
          className="cls-3"
          d="M115.83,567.4a.09.09,0,1,1,0-.13A.09.09,0,0,1,115.83,567.4Z"
        ></path>
        <path
          className="cls-3"
          d="M116,578.78a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,116,578.78Z"
        ></path>
        <path
          className="cls-3"
          d="M116.47,590.13a.06.06,0,1,1-.07-.09.06.06,0,1,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M117.25,601.49a.05.05,0,0,1-.08,0,.06.06,0,1,1,.09-.07A.05.05,0,0,1,117.25,601.49Z"
        ></path>
        <path
          className="cls-3"
          d="M118.33,612.83a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,118.33,612.83Z"
        ></path>
        <path
          className="cls-3"
          d="M119.73,624.15a.09.09,0,0,1-.13,0,.08.08,0,0,1,0-.12.09.09,0,0,1,.13,0A.08.08,0,0,1,119.73,624.15Z"
        ></path>
        <path
          className="cls-3"
          d="M121.4,635.42a.08.08,0,0,1-.12,0,.09.09,0,1,1,.15-.11A.1.1,0,0,1,121.4,635.42Z"
        ></path>
        <circle
          className="cls-3"
          cx="123.33"
          cy="646.56"
          r="0.09"
          transform="translate(-353.96 193.3) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M125.65,657.79a.09.09,0,1,1,0-.13A.09.09,0,0,1,125.65,657.79Z"
        ></path>
        <path
          className="cls-3"
          d="M128.19,668.86a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,128.19,668.86Z"
        ></path>
        <path
          className="cls-3"
          d="M131.05,679.88a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.05.05,0,0,1,131.05,679.88Z"
        ></path>
        <path
          className="cls-3"
          d="M134.21,690.85a.09.09,0,1,1,0-.13A.09.09,0,0,1,134.21,690.85Z"
        ></path>
        <path
          className="cls-3"
          d="M137.63,701.68a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,1,1,.08.1Z"
        ></path>
        <path
          className="cls-3"
          d="M141.34,712.44a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,141.34,712.44Z"
        ></path>
        <path
          className="cls-3"
          d="M145.34,723.1a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,145.34,723.1Z"
        ></path>
        <path
          className="cls-3"
          d="M149.64,733.68a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,149.64,733.68Z"
        ></path>
        <circle className="cls-3" cx="154.15" cy="744.04" r="0.06"></circle>
        <path
          className="cls-3"
          d="M159,754.4a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,159,754.4Z"
        ></path>
        <path
          className="cls-3"
          d="M164.12,764.57a.06.06,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.06.06,0,0,1,164.12,764.57Z"
        ></path>
        <path
          className="cls-3"
          d="M169.48,774.62a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,169.48,774.62Z"
        ></path>
        <path
          className="cls-3"
          d="M175.12,784.51a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,175.12,784.51Z"
        ></path>
        <path
          className="cls-3"
          d="M181,794.25a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,181,794.25Z"
        ></path>
        <path
          className="cls-3"
          d="M187.15,803.84a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,187.15,803.84Z"
        ></path>
        <path
          className="cls-3"
          d="M193.55,813.26a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,193.55,813.26Z"
        ></path>
        <path
          className="cls-3"
          d="M200.2,822.5a.06.06,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.06.06,0,0,1,200.2,822.5Z"
        ></path>
        <path
          className="cls-3"
          d="M207.08,831.57a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <circle className="cls-3" cx="214.17" cy="840.41" r="0.06"></circle>
        <path
          className="cls-3"
          d="M221.56,849.15a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,221.56,849.15Z"
        ></path>
        <path
          className="cls-3"
          d="M229.14,857.64a.06.06,0,0,1-.09,0,.08.08,0,0,1,0-.09.06.06,0,0,1,.08,0A.05.05,0,0,1,229.14,857.64Z"
        ></path>
        <circle className="cls-3" cx="236.91" cy="865.89" r="0.06"></circle>
        <path
          className="cls-3"
          d="M245,874a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,245,874Z"
        ></path>
        <path
          className="cls-3"
          d="M253.19,881.89a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,253.19,881.89Z"
        ></path>
        <path
          className="cls-3"
          d="M261.64,889.56a.08.08,0,0,1-.12,0,.09.09,0,0,1,0-.13.08.08,0,0,1,.12,0A.09.09,0,0,1,261.64,889.56Z"
        ></path>
        <path
          className="cls-3"
          d="M270.26,897a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,270.26,897Z"
        ></path>
        <path
          className="cls-3"
          d="M279.08,904.15a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,279.08,904.15Z"
        ></path>
        <path
          className="cls-3"
          d="M288.1,911.11a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,288.1,911.11Z"
        ></path>
        <path
          className="cls-3"
          d="M297.29,917.83a.07.07,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,297.29,917.83Z"
        ></path>
        <path
          className="cls-3"
          d="M306.66,924.3a.06.06,0,0,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,306.66,924.3Z"
        ></path>
        <path
          className="cls-3"
          d="M316.2,930.52a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.06.06,0,0,1,316.2,930.52Z"
        ></path>
        <path
          className="cls-3"
          d="M325.91,936.51a.09.09,0,0,1-.11-.15.1.1,0,0,1,.13,0A.08.08,0,0,1,325.91,936.51Z"
        ></path>
        <path
          className="cls-3"
          d="M335.74,942.2a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,335.74,942.2Z"
        ></path>
        <path
          className="cls-3"
          d="M345.74,947.65a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08s.07,0,.08,0A.06.06,0,0,1,345.74,947.65Z"
        ></path>
        <path
          className="cls-3"
          d="M350.25,964.2a.06.06,0,0,1-.08,0,.06.06,0,0,1,.1-.08A.07.07,0,0,1,350.25,964.2Z"
        ></path>
        <path
          className="cls-3"
          d="M360.52,969.12a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,360.52,969.12Z"
        ></path>
        <path
          className="cls-3"
          d="M370.92,973.77a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,370.92,973.77Z"
        ></path>
        <path
          className="cls-3"
          d="M381.41,978.13s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,381.41,978.13Z"
        ></path>
        <path
          className="cls-3"
          d="M392,982.27a.06.06,0,0,1-.08,0,.06.06,0,0,1,.09-.07A.06.06,0,0,1,392,982.27Z"
        ></path>
        <path
          className="cls-3"
          d="M402.74,986.08s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,402.74,986.08Z"
        ></path>
        <path
          className="cls-3"
          d="M413.57,989.67a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,413.57,989.67Z"
        ></path>
        <path
          className="cls-3"
          d="M424.48,993a.06.06,0,0,1-.09,0,.06.06,0,0,1,.1-.07A.06.06,0,0,1,424.48,993Z"
        ></path>
        <path
          className="cls-3"
          d="M435.46,996a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,435.46,996Z"
        ></path>
        <path
          className="cls-3"
          d="M446.5,998.66a0,0,0,0,1-.05,0s0,0,0,0h0A0,0,0,0,1,446.5,998.66Z"
        ></path>
        <path
          className="cls-3"
          d="M457.64,1001.12a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,457.64,1001.12Z"
        ></path>
        <path
          className="cls-3"
          d="M468.82,1003.27a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.07.07,0,0,1,468.82,1003.27Z"
        ></path>
        <path
          className="cls-3"
          d="M480,1005.11h0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,480,1005.11Z"
        ></path>
        <path
          className="cls-3"
          d="M491.33,1006.71a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,491.33,1006.71Z"
        ></path>
        <path
          className="cls-3"
          d="M502.64,1008a.06.06,0,0,1-.09,0s0-.07,0-.08a.06.06,0,1,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M514,1009h0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0S514,1009,514,1009Z"
        ></path>
        <path
          className="cls-3"
          d="M525.35,1009.69a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,525.35,1009.69Z"
        ></path>
        <path
          className="cls-3"
          d="M536.71,1010.08s0,0-.05,0a.06.06,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,536.71,1010.08Z"
        ></path>
        <path
          className="cls-3"
          d="M548.09,1010.2a0,0,0,1,1,0-.05s0,0,0,0A0,0,0,0,1,548.09,1010.2Z"
        ></path>
        <path
          className="cls-3"
          d="M559.5,1010.05a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.06.06,0,0,1,559.5,1010.05Z"
        ></path>
        <path
          className="cls-3"
          d="M570.87,1009.59a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M582.21,1008.81a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,582.21,1008.81Z"
        ></path>
        <path
          className="cls-3"
          d="M593.55,1007.76h0s0,0,0,0h0A0,0,0,0,1,593.55,1007.76Z"
        ></path>
        <path
          className="cls-3"
          d="M604.86,1006.43s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S604.87,1006.42,604.86,1006.43Z"
        ></path>
        <path
          className="cls-3"
          d="M616.13,1004.8a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S616.14,1004.79,616.13,1004.8Z"
        ></path>
        <path
          className="cls-3"
          d="M627.37,1002.91a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M638.52,1000.69s0,0,0,0,0,0,0,0h0S638.54,1000.68,638.52,1000.69Z"
        ></path>
        <path
          className="cls-3"
          d="M649.64,998.2a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,649.64,998.2Z"
        ></path>
        <path
          className="cls-3"
          d="M660.68,995.43s0,0,0,0,0,0,0,0h.05A0,0,0,0,1,660.68,995.43Z"
        ></path>
        <path
          className="cls-3"
          d="M671.65,992.37h0v0s0,0,0,0A0,0,0,0,1,671.65,992.37Z"
        ></path>
        <path
          className="cls-3"
          d="M682.53,989h0s0,0,0,0,0,0,0,0A0,0,0,0,1,682.53,989Z"
        ></path>
        <path
          className="cls-3"
          d="M693.35,985.45a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,693.35,985.45Z"
        ></path>
        <path
          className="cls-3"
          d="M704,981.54s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,704,981.49Z"
        ></path>
        <path
          className="cls-3"
          d="M714.63,977.38s0,0,0,0a0,0,0,0,1,0,0h0S714.65,977.37,714.63,977.38Z"
        ></path>
        <path
          className="cls-3"
          d="M725.12,973h0s0,0,0,0h0A0,0,0,0,1,725.12,973Z"
        ></path>
        <path
          className="cls-3"
          d="M735.51,968.27a.07.07,0,0,1-.09,0,.08.08,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,735.51,968.27Z"
        ></path>
        <path
          className="cls-3"
          d="M745.77,963.22a.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08A.06.06,0,0,1,745.77,963.22Z"
        ></path>
        <path
          className="cls-3"
          d="M755.89,958a.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09A.08.08,0,0,1,755.89,958Z"
        ></path>
        <path
          className="cls-3"
          d="M765.8,952.53h0a0,0,0,0,1,0,.05,0,0,0,0,1-.05,0S765.79,952.54,765.8,952.53Z"
        ></path>
        <path
          className="cls-3"
          d="M775.67,946.84s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S775.68,946.83,775.67,946.84Z"
        ></path>
        <path
          className="cls-3"
          d="M785.36,940.85h-.05a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,785.36,940.85Z"
        ></path>
        <path
          className="cls-3"
          d="M794.88,934.62a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,794.85,934.57Z"
        ></path>
        <path
          className="cls-3"
          d="M804.25,928.15s0,0,0,0,0,0,0,0h0A0,0,0,0,1,804.25,928.15Z"
        ></path>
        <path
          className="cls-3"
          d="M813.45,921.43a0,0,0,0,1-.05,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,813.45,921.43Z"
        ></path>
        <path
          className="cls-3"
          d="M822.47,914.49h0s0,0,0,0,0,0,.05,0A.07.07,0,0,1,822.47,914.49Z"
        ></path>
        <path
          className="cls-3"
          d="M831.31,907.31s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,831.31,907.31Z"
        ></path>
        <path
          className="cls-3"
          d="M840,899.91a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S840,899.9,840,899.91Z"
        ></path>
        <path
          className="cls-3"
          d="M848.44,892.31a.06.06,0,1,1-.07-.09.06.06,0,0,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M856.7,884.48a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M864.76,876.44a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08s.07,0,.08,0A.06.06,0,0,1,864.76,876.44Z"
        ></path>
        <path
          className="cls-3"
          d="M872.59,868.16h0s0,0,0,0h0S872.61,868.15,872.59,868.16Z"
        ></path>
        <path
          className="cls-3"
          d="M880.23,859.72s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S880.24,859.71,880.23,859.72Z"
        ></path>
        <path
          className="cls-3"
          d="M887.65,851a0,0,0,0,1,0,0s0,0-.06,0,0,0,0,0S887.64,851,887.65,851Z"
        ></path>
        <path
          className="cls-3"
          d="M894.86,842.28a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,1,1,.08.1Z"
        ></path>
        <path
          className="cls-3"
          d="M901.8,833.25h0s0,0,0,0h0S901.82,833.24,901.8,833.25Z"
        ></path>
        <path
          className="cls-3"
          d="M908.53,824.06a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,908.53,824.06Z"
        ></path>
        <path
          className="cls-3"
          d="M915,814.66a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S915,814.67,915,814.66Z"
        ></path>
        <path
          className="cls-3"
          d="M921.28,805.19s0,0,0,0a.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,921.28,805.19Z"
        ></path>
        <path
          className="cls-3"
          d="M927.28,795.52s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,927.28,795.52Z"
        ></path>
        <path
          className="cls-3"
          d="M933,785.69h0v0a0,0,0,0,1,0,0A0,0,0,0,1,933,785.69Z"
        ></path>
        <path
          className="cls-3"
          d="M938.56,775.75a.06.06,0,1,1,0-.09A.07.07,0,0,1,938.56,775.75Z"
        ></path>
        <path
          className="cls-3"
          d="M943.75,765.57a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,943.75,765.57Z"
        ></path>
        <path
          className="cls-3"
          d="M948.76,755.38s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,948.76,755.38Z"
        ></path>
        <path
          className="cls-3"
          d="M953.48,745s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,953.48,745Z"
        ></path>
        <path
          className="cls-3"
          d="M958,734.56a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,958,734.56Z"
        ></path>
        <path
          className="cls-3"
          d="M962.08,723.9h0a0,0,0,0,1,0,.05h0S962.07,723.91,962.08,723.9Z"
        ></path>
        <path
          className="cls-3"
          d="M966,713.21a0,0,0,0,1,0,0s0,0-.06,0a0,0,0,0,1,0,0Z"
        ></path>
        <path
          className="cls-3"
          d="M969.66,702.42a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0S969.65,702.41,969.66,702.42Z"
        ></path>
        <path
          className="cls-3"
          d="M973,691.51a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,973,691.51Z"
        ></path>
        <path
          className="cls-3"
          d="M976.05,680.57a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1-.05,0A.06.06,0,0,1,976.05,680.57Z"
        ></path>
        <path
          className="cls-3"
          d="M978.88,669.58a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S978.89,669.57,978.88,669.58Z"
        ></path>
        <path
          className="cls-3"
          d="M981.35,658.42s0,0,0,0,0,0,0,0h0A0,0,0,0,1,981.35,658.42Z"
        ></path>
        <path
          className="cls-3"
          d="M983.61,647.31s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,983.61,647.31Z"
        ></path>
        <path
          className="cls-3"
          d="M985.51,636h0a0,0,0,0,1,0,.05h0S985.5,636.05,985.51,636Z"
        ></path>
        <path
          className="cls-3"
          d="M987.16,624.77a0,0,0,0,1,0,.05,0,0,0,0,1,0-.05S987.15,624.78,987.16,624.77Z"
        ></path>
        <path
          className="cls-3"
          d="M988.55,613.52s0,0,0,0a0,0,0,0,1,0,0h0S988.57,613.51,988.55,613.52Z"
        ></path>
        <path
          className="cls-3"
          d="M989.57,602.11a.05.05,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.05.05,0,0,1,989.57,602.11Z"
        ></path>
        <path
          className="cls-3"
          d="M990.36,590.77s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S990.35,590.78,990.36,590.77Z"
        ></path>
        <path
          className="cls-3"
          d="M990.85,579.39h0s0,0,0,0h0S990.83,579.4,990.85,579.39Z"
        ></path>
        <path
          className="cls-3"
          d="M991.07,568.06a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,991,568Z"
        ></path>
        <path
          className="cls-3"
          d="M990.94,556.63h0s0,0,0,0h-.05S990.93,556.63,990.94,556.63Z"
        ></path>
        <path
          className="cls-3"
          d="M990.55,545.25h0a.1.1,0,0,1,0,0h-.06S990.54,545.26,990.55,545.25Z"
        ></path>
        <path
          className="cls-3"
          d="M989.87,533.88a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,989.9,533.93Z"
        ></path>
        <path
          className="cls-3"
          d="M988.89,522.54h.05s0,0,0,0h0S988.88,522.55,988.89,522.54Z"
        ></path>
        <path
          className="cls-3"
          d="M987.6,511.28a.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08A.05.05,0,0,1,987.6,511.28Z"
        ></path>
        <path
          className="cls-3"
          d="M986.08,499.94a0,0,0,0,1,0,.05,0,0,0,0,1,0-.05A0,0,0,0,1,986.08,499.94Z"
        ></path>
        <path
          className="cls-3"
          d="M984.22,488.68a.06.06,0,0,1,.08,0,.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,984.22,488.68Z"
        ></path>
        <path
          className="cls-3"
          d="M982.1,477.52s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,982.1,477.52Z"
        ></path>
        <path
          className="cls-3"
          d="M979.69,466.39s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,979.69,466.39Z"
        ></path>
        <path
          className="cls-3"
          d="M977,455.33s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,977,455.33Z"
        ></path>
        <path
          className="cls-3"
          d="M974,444.34a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,974,444.34Z"
        ></path>
        <path
          className="cls-3"
          d="M970.74,433.44h0s0,0,0,.05h0S970.73,433.45,970.74,433.44Z"
        ></path>
        <circle
          className="cls-3"
          cx="967.21"
          cy="422.64"
          r="0.03"
          transform="translate(-64.95 643.47) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M963.38,411.89a0,0,0,1,1,0,.05s0,0,0,0A0,0,0,0,1,963.38,411.89Z"
        ></path>
        <path
          className="cls-3"
          d="M959.28,401.26a0,0,0,0,1,.05,0s0,0,0,0h0A0,0,0,0,1,959.28,401.26Z"
        ></path>
        <path
          className="cls-3"
          d="M954.92,390.75h0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0S954.91,390.76,954.92,390.75Z"
        ></path>
        <path
          className="cls-3"
          d="M950.27,380.32a.06.06,0,0,1,.07.1.06.06,0,0,1-.08,0A.06.06,0,0,1,950.27,380.32Z"
        ></path>
        <path
          className="cls-3"
          d="M945.37,370a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08,0A.07.07,0,0,1,945.37,370Z"
        ></path>
        <path
          className="cls-3"
          d="M940.21,359.89a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08,0A.07.07,0,0,1,940.21,359.89Z"
        ></path>
        <path
          className="cls-3"
          d="M934.81,349.9a0,0,0,0,1,.05,0,.06.06,0,0,1,0,0h0A0,0,0,0,1,934.81,349.9Z"
        ></path>
        <path
          className="cls-3"
          d="M929.14,340s0,0,0,0a0,0,0,0,1,0,0s-.05,0-.06,0S929.13,340,929.14,340Z"
        ></path>
        <path
          className="cls-3"
          d="M923.22,330.31h0a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,923.22,330.31Z"
        ></path>
        <path
          className="cls-3"
          d="M917,320.74s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,917,320.74Z"
        ></path>
        <path
          className="cls-3"
          d="M910.63,311.33s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S910.62,311.34,910.63,311.33Z"
        ></path>
        <path
          className="cls-3"
          d="M904,302.09s0,0,0,0,0,0,0,0h0S904,302.1,904,302.09Z"
        ></path>
        <path
          className="cls-3"
          d="M897.09,293h0a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,897.09,293Z"
        ></path>
        <path
          className="cls-3"
          d="M890,284.14s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,890,284.14Z"
        ></path>
        <path
          className="cls-3"
          d="M882.62,275.44s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,882.62,275.44Z"
        ></path>
        <path
          className="cls-3"
          d="M875.06,266.93h0a0,0,0,0,1,0,0s-.05,0-.06,0A0,0,0,0,1,875.06,266.93Z"
        ></path>
        <path
          className="cls-3"
          d="M867.26,258.59a.06.06,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,867.26,258.59Z"
        ></path>
        <path
          className="cls-3"
          d="M859.27,250.48a.06.06,0,0,1,.08,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0A.07.07,0,0,1,859.27,250.48Z"
        ></path>
        <path
          className="cls-3"
          d="M851.07,242.58a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,851.07,242.58Z"
        ></path>
        <path
          className="cls-3"
          d="M842.69,234.92h0a0,0,0,0,1,0,.05,0,0,0,0,1-.05,0S842.68,234.93,842.69,234.92Z"
        ></path>
        <path
          className="cls-3"
          d="M834.1,227.44h0v0s0,0-.06,0A0,0,0,0,1,834.1,227.44Z"
        ></path>
        <path
          className="cls-3"
          d="M825.3,220.17a.07.07,0,0,1,.09,0,.08.08,0,0,1,0,.09.06.06,0,0,1-.08,0A.06.06,0,0,1,825.3,220.17Z"
        ></path>
        <circle className="cls-3" cx="816.37" cy="213.2" r="0.06"></circle>
        <path
          className="cls-3"
          d="M807.22,206.38h0s0,0,0,0,0,0,0,0S807.2,206.39,807.22,206.38Z"
        ></path>
        <path
          className="cls-3"
          d="M797.9,199.83s0,0,.05,0,0,0,0,0,0,0-.05,0A0,0,0,0,1,797.9,199.83Z"
        ></path>
        <path
          className="cls-3"
          d="M788.43,193.52a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,788.46,193.57Z"
        ></path>
        <path
          className="cls-3"
          d="M778.79,187.45s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,778.79,187.45Z"
        ></path>
        <path
          className="cls-3"
          d="M769,181.63s0,0,0,0,0,0,0,0h0S769,181.64,769,181.63Z"
        ></path>
        <path
          className="cls-3"
          d="M759.05,176.05a.06.06,0,0,1,.09,0,.05.05,0,0,1,0,.08.05.05,0,0,1-.08,0A.06.06,0,0,1,759.05,176.05Z"
        ></path>
        <path
          className="cls-3"
          d="M749,170.76a0,0,0,0,1,0,.05s0,0,0,0S749,170.77,749,170.76Z"
        ></path>
        <path
          className="cls-3"
          d="M738.78,165.69a.05.05,0,0,1,.08,0,.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,738.78,165.69Z"
        ></path>
        <path
          className="cls-3"
          d="M728.46,160.93a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S728.45,160.94,728.46,160.93Z"
        ></path>
        <path
          className="cls-3"
          d="M718,156.41a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S718,156.42,718,156.41Z"
        ></path>
        <path
          className="cls-3"
          d="M707.43,152.14a.06.06,0,0,1,.07.09.06.06,0,0,1-.09,0S707.4,152.15,707.43,152.14Z"
        ></path>
        <circle className="cls-3" cx="696.8" cy="148.21" r="0.06"></circle>
        <path
          className="cls-3"
          d="M686,144.48s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S686,144.49,686,144.48Z"
        ></path>
        <path
          className="cls-3"
          d="M675.15,141.06h0a0,0,0,0,1,0,.05,0,0,0,0,1-.05,0S675.14,141.07,675.15,141.06Z"
        ></path>
        <path
          className="cls-3"
          d="M664.21,137.91h0a0,0,0,0,1,0,0s0,0-.06,0S664.19,137.92,664.21,137.91Z"
        ></path>
        <path
          className="cls-3"
          d="M653.17,135a.06.06,0,0,1,.08,0,.06.06,0,0,1-.09.07A.06.06,0,0,1,653.17,135Z"
        ></path>
        <path
          className="cls-3"
          d="M642.1,132.47a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,642.1,132.47Z"
        ></path>
        <circle className="cls-3" cx="630.96" cy="130.2" r="0.06"></circle>
        <path
          className="cls-3"
          d="M619.74,128.17h0s0,0,0,.05h0A0,0,0,0,1,619.74,128.17Z"
        ></path>
        <path
          className="cls-3"
          d="M608.48,126.45s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,608.48,126.45Z"
        ></path>
        <path
          className="cls-3"
          d="M597.17,125a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,597.17,125Z"
        ></path>
        <path
          className="cls-3"
          d="M585.89,123.93s0,0,0,0,0,0,0,0h0S585.91,123.92,585.89,123.93Z"
        ></path>
        <path
          className="cls-3"
          d="M574.57,123a.06.06,0,0,1,0,.08.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09A.06.06,0,0,1,574.57,123Z"
        ></path>
        <path
          className="cls-3"
          d="M563.11,122.44a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.05.05,0,0,1-.08,0A.07.07,0,0,1,563.11,122.44Z"
        ></path>
        <path
          className="cls-3"
          d="M551.73,122.18s.07,0,.08,0a.06.06,0,0,1,0,.09.05.05,0,0,1-.08,0A.05.05,0,0,1,551.73,122.18Z"
        ></path>
        <path
          className="cls-3"
          d="M540.36,122.23s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,540.36,122.23Z"
        ></path>
        <path
          className="cls-3"
          d="M529,122.52a.07.07,0,0,1,.09,0,.07.07,0,0,1,0,.09.06.06,0,0,1-.08,0A.07.07,0,0,1,529,122.52Z"
        ></path>
        <path
          className="cls-3"
          d="M517.66,123.23a.06.06,0,0,1-.08,0,.06.06,0,1,1,.1-.08A.07.07,0,0,1,517.66,123.23Z"
        ></path>
        <circle className="cls-3" cx="506.28" cy="124.08" r="0.06"></circle>
        <path
          className="cls-3"
          d="M494.92,125.22a.05.05,0,0,1,.08,0,.05.05,0,0,1,0,.08.06.06,0,0,1-.08,0A.06.06,0,0,1,494.92,125.22Z"
        ></path>
        <circle className="cls-3" cx="483.67" cy="126.75" r="0.06"></circle>
        <path
          className="cls-3"
          d="M472.4,128.5a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0-.05,0S472.39,128.51,472.4,128.5Z"
        ></path>
        <path
          className="cls-3"
          d="M461.18,130.53a.07.07,0,0,1,.09,0,.06.06,0,0,1,0,.08.06.06,0,0,1-.08-.1Z"
        ></path>
        <path
          className="cls-3"
          d="M450.11,133a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,450.11,133Z"
        ></path>
        <path
          className="cls-3"
          d="M439,135.61a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,439,135.61Z"
        ></path>
        <path
          className="cls-3"
          d="M428,138.42a.06.06,0,0,1,.08,0,.07.07,0,0,1,0,.09.07.07,0,0,1-.09,0A.06.06,0,0,1,428,138.42Z"
        ></path>
        <path
          className="cls-3"
          d="M417.1,141.71a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,417.1,141.71Z"
        ></path>
        <path
          className="cls-3"
          d="M406.24,145.16s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,406.24,145.16Z"
        ></path>
        <path
          className="cls-3"
          d="M395.49,148.91h0a0,0,0,0,1,0,0h0A0,0,0,0,1,395.49,148.91Z"
        ></path>
        <path
          className="cls-3"
          d="M384.85,153a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,384.85,153Z"
        ></path>
        <path
          className="cls-3"
          d="M374.31,157.26a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,374.31,157.26Z"
        ></path>
        <path
          className="cls-3"
          d="M363.86,161.8a0,0,0,0,1,0-.05s0,0,0,0S363.87,161.79,363.86,161.8Z"
        ></path>
        <path
          className="cls-3"
          d="M353.55,166.63s0,0,0,0a0,0,0,0,1,0,0s.05,0,.06,0S353.56,166.62,353.55,166.63Z"
        ></path>
        <path
          className="cls-3"
          d="M343.39,171.75a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.07.07,0,0,1,343.39,171.75Z"
        ></path>
        <path
          className="cls-3"
          d="M333.35,177a.06.06,0,0,1,0,.08.06.06,0,0,1-.09,0,.06.06,0,0,1,.1-.07Z"
        ></path>
        <path
          className="cls-3"
          d="M323.43,182.71a.06.06,0,0,1-.09,0,.08.08,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,323.43,182.71Z"
        ></path>
        <path
          className="cls-3"
          d="M313.67,188.57a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.06.06,0,1,1,.07.09Z"
        ></path>
        <path
          className="cls-3"
          d="M304.07,194.6a.06.06,0,1,1-.09.07.06.06,0,1,1,.09-.07Z"
        ></path>
        <circle className="cls-3" cx="294.58" cy="200.99" r="0.06"></circle>
        <path
          className="cls-3"
          d="M285.33,207.63a.06.06,0,0,1-.07-.09.06.06,0,0,1,.09,0S285.36,207.62,285.33,207.63Z"
        ></path>
        <path
          className="cls-3"
          d="M276.22,214.46a.06.06,0,1,1-.07-.09s.07,0,.08,0S276.25,214.45,276.22,214.46Z"
        ></path>
        <path
          className="cls-3"
          d="M267.29,221.53a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.08,0A.06.06,0,0,1,267.29,221.53Z"
        ></path>
        <path
          className="cls-3"
          d="M258.54,228.82a.06.06,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,258.54,228.82Z"
        ></path>
        <path
          className="cls-3"
          d="M250,236.33a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,250,236.33Z"
        ></path>
        <path
          className="cls-3"
          d="M241.62,244.05a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,241.62,244.05Z"
        ></path>
        <path
          className="cls-3"
          d="M233.48,251.91a.05.05,0,0,1,0,.08.06.06,0,1,1-.07-.09A.06.06,0,0,1,233.48,251.91Z"
        ></path>
        <path
          className="cls-3"
          d="M225.49,260.11h0s0,0,0,0h.05S225.5,260.1,225.49,260.11Z"
        ></path>
        <path
          className="cls-3"
          d="M217.76,268.49a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,217.76,268.49Z"
        ></path>
        <path
          className="cls-3"
          d="M210.24,277a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,210.24,277Z"
        ></path>
        <path
          className="cls-3"
          d="M202.93,285.76a.06.06,0,1,1,0-.08A.05.05,0,0,1,202.93,285.76Z"
        ></path>
        <path
          className="cls-3"
          d="M195.85,294.68a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,195.85,294.68Z"
        ></path>
        <path
          className="cls-3"
          d="M189,303.75h-.05a0,0,0,0,1,0-.05s0,0,0,0S189,303.74,189,303.75Z"
        ></path>
        <path
          className="cls-3"
          d="M182.39,313.05a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,182.39,313.05Z"
        ></path>
        <path
          className="cls-3"
          d="M176,322.48a.06.06,0,0,1-.09,0,.06.06,0,1,1,.09,0Z"
        ></path>
        <path
          className="cls-3"
          d="M169.87,332.06h0s0,0,0,0h0S169.89,332.05,169.87,332.06Z"
        ></path>
        <circle className="cls-3" cx="163.97" cy="341.78" r="0.06"></circle>
        <path
          className="cls-3"
          d="M158.38,351.73a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,158.38,351.73Z"
        ></path>
        <path
          className="cls-3"
          d="M153,361.77a.07.07,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,153,361.77Z"
        ></path>
        <path
          className="cls-3"
          d="M147.9,371.94a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08.1Z"
        ></path>
        <path
          className="cls-3"
          d="M143,382.24a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,143,382.24Z"
        ></path>
        <path
          className="cls-3"
          d="M138.46,392.66a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,138.46,392.66Z"
        ></path>
        <circle className="cls-3" cx="134.11" cy="403.15" r="0.06"></circle>
        <path
          className="cls-3"
          d="M130.1,413.84a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,130.1,413.84Z"
        ></path>
        <path
          className="cls-3"
          d="M126.31,424.56s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,126.31,424.56Z"
        ></path>
        <path
          className="cls-3"
          d="M122.83,435.42a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,122.83,435.42Z"
        ></path>
        <path
          className="cls-3"
          d="M119.62,446.34a.06.06,0,0,1-.09,0,.06.06,0,0,1,.1-.07A.06.06,0,0,1,119.62,446.34Z"
        ></path>
        <circle className="cls-3" cx="116.65" cy="457.3" r="0.06"></circle>
        <path
          className="cls-3"
          d="M114,468.42a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.08.08,0,0,1,114,468.42Z"
        ></path>
        <path
          className="cls-3"
          d="M111.67,479.56a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,111.67,479.56Z"
        ></path>
        <path
          className="cls-3"
          d="M109.59,490.75a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,109.59,490.75Z"
        ></path>
        <path
          className="cls-3"
          d="M107.79,502a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,107.79,502Z"
        ></path>
        <path
          className="cls-3"
          d="M106.29,513.28a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,1,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M105.08,524.6a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,105.08,524.6Z"
        ></path>
        <path
          className="cls-3"
          d="M104.16,536a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,104.16,536Z"
        ></path>
        <path
          className="cls-3"
          d="M103.52,547.32a.06.06,0,0,1-.08,0,.06.06,0,0,1,.1-.08A.07.07,0,0,1,103.52,547.32Z"
        ></path>
        <path
          className="cls-3"
          d="M103.19,558.7a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,103.19,558.7Z"
        ></path>
        <path
          className="cls-3"
          d="M103.14,570.09a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,103.14,570.09Z"
        ></path>
        <path
          className="cls-3"
          d="M103.38,581.47a.06.06,0,0,1-.08,0,.06.06,0,1,1,.1-.08A.07.07,0,0,1,103.38,581.47Z"
        ></path>
        <path
          className="cls-3"
          d="M103.92,592.84a.06.06,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,103.92,592.84Z"
        ></path>
        <path
          className="cls-3"
          d="M104.75,604.2a.07.07,0,0,1-.09,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.08,0A.07.07,0,0,1,104.75,604.2Z"
        ></path>
        <circle className="cls-3" cx="105.83" cy="615.48" r="0.06"></circle>
        <path
          className="cls-3"
          d="M107.27,626.83a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.07.07,0,0,1,.09,0A.07.07,0,0,1,107.27,626.83Z"
        ></path>
        <path
          className="cls-3"
          d="M109,638.06s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,109,638.06Z"
        ></path>
        <circle className="cls-3" cx="110.92" cy="649.25" r="0.06"></circle>
        <path
          className="cls-3"
          d="M113.23,660.46a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,113.23,660.46Z"
        ></path>
        <path
          className="cls-3"
          d="M115.79,671.55a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,115.79,671.55Z"
        ></path>
        <path
          className="cls-3"
          d="M118.63,682.58a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,118.63,682.58Z"
        ></path>
        <path
          className="cls-3"
          d="M121.76,693.52a.06.06,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0S121.79,693.51,121.76,693.52Z"
        ></path>
        <path
          className="cls-3"
          d="M125.15,704.37a0,0,0,0,1-.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,125.15,704.37Z"
        ></path>
        <path
          className="cls-3"
          d="M128.84,715.16a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.05.05,0,0,1,128.84,715.16Z"
        ></path>
        <path
          className="cls-3"
          d="M132.8,725.84a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,132.8,725.84Z"
        ></path>
        <circle className="cls-3" cx="136.99" cy="736.36" r="0.06"></circle>
        <path
          className="cls-3"
          d="M141.51,746.85s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S141.52,746.84,141.51,746.85Z"
        ></path>
        <path
          className="cls-3"
          d="M146.29,757.21a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.08.08,0,0,1,.09,0A.05.05,0,0,1,146.29,757.21Z"
        ></path>
        <path
          className="cls-3"
          d="M151.32,767.43a.05.05,0,0,1-.08,0,.05.05,0,0,1,0-.08.07.07,0,0,1,.09,0A.08.08,0,0,1,151.32,767.43Z"
        ></path>
        <path
          className="cls-3"
          d="M156.61,777.51a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M162.16,787.46a.08.08,0,0,1-.09,0,.05.05,0,0,1,0-.08.05.05,0,0,1,.08,0A.07.07,0,0,1,162.16,787.46Z"
        ></path>
        <path
          className="cls-3"
          d="M168,797.25a.06.06,0,0,1-.09,0,.06.06,0,1,1,.1-.07A.06.06,0,0,1,168,797.25Z"
        ></path>
        <path
          className="cls-3"
          d="M174,806.9a.06.06,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.06.06,0,0,1,174,806.9Z"
        ></path>
        <path
          className="cls-3"
          d="M180.3,816.39a.06.06,0,1,1-.07-.1.06.06,0,0,1,.08,0A.06.06,0,0,1,180.3,816.39Z"
        ></path>
        <path
          className="cls-3"
          d="M186.83,825.71a.05.05,0,0,1-.08,0,.07.07,0,0,1,0-.09.07.07,0,0,1,.09,0A.05.05,0,0,1,186.83,825.71Z"
        ></path>
        <circle className="cls-3" cx="193.57" cy="834.82" r="0.06"></circle>
        <path
          className="cls-3"
          d="M200.61,843.84a.05.05,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.07.1Z"
        ></path>
        <path
          className="cls-3"
          d="M207.84,852.64a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,207.84,852.64Z"
        ></path>
        <path
          className="cls-3"
          d="M215.3,861.24a.07.07,0,0,1-.09,0,.08.08,0,0,1,0-.09.06.06,0,0,1,.08,0A.05.05,0,0,1,215.3,861.24Z"
        ></path>
        <circle className="cls-3" cx="222.94" cy="869.6" r="0.06"></circle>
        <path
          className="cls-3"
          d="M230.86,877.87a.08.08,0,0,1-.09,0,.06.06,0,0,1,0-.08.05.05,0,0,1,.08,0A.06.06,0,0,1,230.86,877.87Z"
        ></path>
        <path
          className="cls-3"
          d="M238.94,885.85s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,238.94,885.85Z"
        ></path>
        <path
          className="cls-3"
          d="M247.25,893.67a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.07.07,0,0,1,.09,0A.07.07,0,0,1,247.25,893.67Z"
        ></path>
        <path
          className="cls-3"
          d="M255.75,901.25a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,255.75,901.25Z"
        ></path>
        <path
          className="cls-3"
          d="M264.42,908.59a0,0,0,0,1-.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,264.42,908.59Z"
        ></path>
        <path
          className="cls-3"
          d="M273.29,915.72h0a0,0,0,0,1,0-.05,0,0,0,0,1,.05,0S273.3,915.71,273.29,915.72Z"
        ></path>
        <path
          className="cls-3"
          d="M282.34,922.63a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S282.35,922.62,282.34,922.63Z"
        ></path>
        <path
          className="cls-3"
          d="M291.58,929.33a.07.07,0,0,1-.08,0,.06.06,0,0,1,0-.08.06.06,0,0,1,.09,0A.07.07,0,0,1,291.58,929.33Z"
        ></path>
        <path
          className="cls-3"
          d="M301,935.76a.06.06,0,0,1-.08,0,.06.06,0,0,1,0-.09.06.06,0,0,1,.08,0A.06.06,0,0,1,301,935.76Z"
        ></path>
        <path
          className="cls-3"
          d="M310.54,942a.06.06,0,1,1,0-.09A.07.07,0,0,1,310.54,942Z"
        ></path>
        <path
          className="cls-3"
          d="M320.25,947.89a.07.07,0,0,1-.09,0,.07.07,0,0,1,0-.09.05.05,0,0,1,.08,0A.05.05,0,0,1,320.25,947.89Z"
        ></path>
        <path
          className="cls-3"
          d="M330.09,953.56s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,330.09,953.56Z"
        ></path>
        <path
          className="cls-3"
          d="M340.1,959a0,0,0,0,1-.05,0s0,0,0,0h0A0,0,0,0,1,340.1,959Z"
        ></path>
        <path
          className="cls-3"
          d="M344.61,975.55s0,0,0,0,0,0,0,0h0A0,0,0,0,1,344.61,975.55Z"
        ></path>
        <path
          className="cls-3"
          d="M354.88,980.47s0,0,0,0a0,0,0,0,1,0,0h.05A0,0,0,0,1,354.88,980.47Z"
        ></path>
        <path
          className="cls-3"
          d="M365.27,985.13s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,365.27,985.13Z"
        ></path>
        <path
          className="cls-3"
          d="M375.77,989.53s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,375.77,989.53Z"
        ></path>
        <path
          className="cls-3"
          d="M386.37,993.67s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,386.37,993.67Z"
        ></path>
        <path
          className="cls-3"
          d="M397.08,997.55s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,397.08,997.55Z"
        ></path>
        <path
          className="cls-3"
          d="M407.88,1001.15a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S407.89,1001.14,407.88,1001.15Z"
        ></path>
        <path
          className="cls-3"
          d="M418.77,1004.49s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,418.77,1004.49Z"
        ></path>
        <path
          className="cls-3"
          d="M429.73,1007.55a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,429.7,1007.5Z"
        ></path>
        <circle
          className="cls-3"
          cx="440.76"
          cy="1010.31"
          r="0.03"
          transform="translate(-506.54 446.73) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M451.88,1012.85s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,451.88,1012.85Z"
        ></path>
        <path
          className="cls-3"
          d="M463,1015.09s0,0,0,0a0,0,0,0,1,0,0h0S463.06,1015.08,463,1015.09Z"
        ></path>
        <path
          className="cls-3"
          d="M474.26,1017a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S474.27,1017,474.26,1017Z"
        ></path>
        <path
          className="cls-3"
          d="M485.52,1018.72s0,0,0,0a0,0,0,0,1,0,0h0S485.54,1018.71,485.52,1018.72Z"
        ></path>
        <path
          className="cls-3"
          d="M496.82,1020.11h0s0,0,0-.05,0,0,0,0A0,0,0,0,1,496.82,1020.11Z"
        ></path>
        <path
          className="cls-3"
          d="M508.15,1021.22h0s0,0,0-.05h0S508.17,1021.21,508.15,1021.22Z"
        ></path>
        <path
          className="cls-3"
          d="M519.51,1022.05h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S519.52,1022,519.51,1022.05Z"
        ></path>
        <path
          className="cls-3"
          d="M530.88,1022.6a0,0,0,1,1,0-.05s0,0,0,0A0,0,0,0,1,530.88,1022.6Z"
        ></path>
        <path
          className="cls-3"
          d="M542.27,1022.86s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,542.27,1022.86Z"
        ></path>
        <path
          className="cls-3"
          d="M553.65,1022.84a0,0,0,1,1,0-.05s0,0,0,0S553.66,1022.83,553.65,1022.84Z"
        ></path>
        <path
          className="cls-3"
          d="M565,1022.53h0s0,0,0,0h0S565.05,1022.52,565,1022.53Z"
        ></path>
        <path
          className="cls-3"
          d="M576.4,1022h0s0,0,0,0h0S576.42,1021.94,576.4,1022Z"
        ></path>
        <path
          className="cls-3"
          d="M587.76,1021.07h0a0,0,0,0,1,0-.05h0A0,0,0,0,1,587.76,1021.07Z"
        ></path>
        <path
          className="cls-3"
          d="M599.08,1019.92s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,599.08,1019.92Z"
        ></path>
        <path
          className="cls-3"
          d="M610.38,1018.48s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S610.39,1018.47,610.38,1018.48Z"
        ></path>
        <path
          className="cls-3"
          d="M621.63,1016.76a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,621.63,1016.76Z"
        ></path>
        <path
          className="cls-3"
          d="M632.84,1014.76h0s0,0,0,0,0,0,0,0A0,0,0,0,1,632.84,1014.76Z"
        ></path>
        <path
          className="cls-3"
          d="M644,1012.49s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,644,1012.49Z"
        ></path>
        <path
          className="cls-3"
          d="M655.1,1009.93s0,0-.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,655.1,1009.93Z"
        ></path>
        <path
          className="cls-3"
          d="M666.12,1007.1a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,666.12,1007.1Z"
        ></path>
        <circle
          className="cls-3"
          cx="677.06"
          cy="1003.97"
          r="0.03"
          transform="translate(-458.48 583.36) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M688,1000.62a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,687.92,1000.57Z"
        ></path>
        <circle
          className="cls-3"
          cx="698.72"
          cy="996.95"
          r="0.03"
          transform="matrix(0.81, -0.58, 0.58, 0.81, -450.32, 594.68)"
        ></circle>
        <path
          className="cls-3"
          d="M709.43,993.06s0,0,0,0a0,0,0,0,1,.05,0S709.44,993.05,709.43,993.06Z"
        ></path>
        <path
          className="cls-3"
          d="M720,988.88s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,720,988.88Z"
        ></path>
        <path
          className="cls-3"
          d="M730.51,984.44a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,730.51,984.44Z"
        ></path>
        <path
          className="cls-3"
          d="M740.88,979.73h0a0,0,0,0,1,0-.05h0A0,0,0,0,1,740.88,979.73Z"
        ></path>
        <path
          className="cls-3"
          d="M751.12,974.77h0a0,0,0,0,1,0,0s0,0,0,0S751.14,974.76,751.12,974.77Z"
        ></path>
        <path
          className="cls-3"
          d="M761.25,969.56s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,761.25,969.56Z"
        ></path>
        <path
          className="cls-3"
          d="M771.2,964.05h0a0,0,0,0,1,0,.05,0,0,0,0,1,0,0S771.19,964.06,771.2,964.05Z"
        ></path>
        <path
          className="cls-3"
          d="M781.09,958.38H781a0,0,0,0,1,0,0s0,0,0,0S781.1,958.37,781.09,958.38Z"
        ></path>
        <path
          className="cls-3"
          d="M790.79,952.43s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S790.8,952.42,790.79,952.43Z"
        ></path>
        <path
          className="cls-3"
          d="M800.34,946.23h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,800.34,946.23Z"
        ></path>
        <path
          className="cls-3"
          d="M809.74,939.8a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S809.75,939.79,809.74,939.8Z"
        ></path>
        <path
          className="cls-3"
          d="M819,933.14s0,0,0,0a0,0,0,0,1,0,0H819A0,0,0,0,1,819,933.14Z"
        ></path>
        <path
          className="cls-3"
          d="M828,926.24h0s0,0,0,0,0,0,0,0S828.05,926.23,828,926.24Z"
        ></path>
        <path
          className="cls-3"
          d="M836.92,919.13a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,836.89,919.08Z"
        ></path>
        <path
          className="cls-3"
          d="M845.63,911.79h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S845.64,911.78,845.63,911.79Z"
        ></path>
        <path
          className="cls-3"
          d="M854.15,904.24h0s0,0,0,0,0,0,0,0A0,0,0,0,1,854.15,904.24Z"
        ></path>
        <path
          className="cls-3"
          d="M862.48,896.48h0a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,862.48,896.48Z"
        ></path>
        <path
          className="cls-3"
          d="M870.62,888.52s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S870.63,888.51,870.62,888.52Z"
        ></path>
        <path
          className="cls-3"
          d="M878.55,880.35a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,878.55,880.35Z"
        ></path>
        <path
          className="cls-3"
          d="M886.28,872a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,886.25,871.94Z"
        ></path>
        <path
          className="cls-3"
          d="M893.8,863.44s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,893.8,863.44Z"
        ></path>
        <path
          className="cls-3"
          d="M901.1,854.7h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,901.1,854.7Z"
        ></path>
        <path
          className="cls-3"
          d="M908.19,845.79a0,0,0,0,1-.05,0s0,0,0,0,0,0,0,0A0,0,0,0,1,908.19,845.79Z"
        ></path>
        <path
          className="cls-3"
          d="M915,836.7s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,915,836.7Z"
        ></path>
        <path
          className="cls-3"
          d="M921.64,827.39a0,0,0,0,1,0,.05,0,0,0,0,1,0-.05A0,0,0,0,1,921.64,827.39Z"
        ></path>
        <path
          className="cls-3"
          d="M928.07,818a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,.05,0S928.08,818,928.07,818Z"
        ></path>
        <path
          className="cls-3"
          d="M934.23,808.44h0v0s.05,0,.06,0S934.24,808.43,934.23,808.44Z"
        ></path>
        <path
          className="cls-3"
          d="M940.11,798.67a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S940.1,798.68,940.11,798.67Z"
        ></path>
        <path
          className="cls-3"
          d="M945.78,788.8h.05a0,0,0,0,1,0,0,0,0,0,0,1-.05,0A0,0,0,0,1,945.78,788.8Z"
        ></path>
        <path
          className="cls-3"
          d="M951.25,778.84s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,951.25,778.84Z"
        ></path>
        <path
          className="cls-3"
          d="M956.42,768.69h0s0,0,0,0h0S956.44,768.68,956.42,768.69Z"
        ></path>
        <path
          className="cls-3"
          d="M961.34,758.43s0,0,0,0a0,0,0,0,1,0,0h0S961.36,758.42,961.34,758.43Z"
        ></path>
        <path
          className="cls-3"
          d="M966,748a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S966,748,966,748Z"
        ></path>
        <path
          className="cls-3"
          d="M970.37,737.49s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,970.37,737.49Z"
        ></path>
        <path
          className="cls-3"
          d="M974.51,726.88a0,0,0,0,1,0,0,.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,974.51,726.88Z"
        ></path>
        <path
          className="cls-3"
          d="M978.42,716.23s0,0,0,0,0,0,0,0h0S978.44,716.22,978.42,716.23Z"
        ></path>
        <path
          className="cls-3"
          d="M982,705.38h0v0s0,0,0,0A0,0,0,0,1,982,705.38Z"
        ></path>
        <path
          className="cls-3"
          d="M985.36,694.54a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,985.33,694.49Z"
        ></path>
        <circle
          className="cls-3"
          cx="988.41"
          cy="683.55"
          r="0.03"
          transform="translate(-213.15 704.81) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M991.22,672.53h-.05a0,0,0,0,1,0,0,0,0,0,0,1,.05,0A0,0,0,0,1,991.22,672.53Z"
        ></path>
        <path
          className="cls-3"
          d="M993.69,661.38s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,993.69,661.38Z"
        ></path>
        <path
          className="cls-3"
          d="M995.93,650.21s0,0,0,0a0,0,0,0,1,0,0h0S995.91,650.22,995.93,650.21Z"
        ></path>
        <path
          className="cls-3"
          d="M997.88,639h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,997.88,639Z"
        ></path>
        <path
          className="cls-3"
          d="M999.56,627.73h0v0s-.05,0-.06,0A0,0,0,0,1,999.56,627.73Z"
        ></path>
        <path
          className="cls-3"
          d="M1001,616.48h0a0,0,0,0,1,0-.05,0,0,0,0,1,0,0S1001,616.47,1001,616.48Z"
        ></path>
        <path
          className="cls-3"
          d="M1002.07,605.1h0a0,0,0,0,1,0,0s0,0-.06,0A0,0,0,0,1,1002.07,605.1Z"
        ></path>
        <path
          className="cls-3"
          d="M1002.89,593.75h.05a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,1002.89,593.75Z"
        ></path>
        <path
          className="cls-3"
          d="M1003.44,582.37s0,0,0,0a0,0,0,0,1,0,0h-.05A.07.07,0,0,1,1003.44,582.37Z"
        ></path>
        <path
          className="cls-3"
          d="M1003.7,571s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,1003.7,571Z"
        ></path>
        <path
          className="cls-3"
          d="M1003.68,559.6s0,0,0,0,0,0,0,0h-.05A.07.07,0,0,1,1003.68,559.6Z"
        ></path>
        <path
          className="cls-3"
          d="M1003.38,548.22h0a0,0,0,0,1,0,0s0,0-.06,0S1003.36,548.23,1003.38,548.22Z"
        ></path>
        <path
          className="cls-3"
          d="M1002.79,536.85s0,0,0,0a0,0,0,1,1-.05,0A0,0,0,0,1,1002.83,536.86Z"
        ></path>
        <path
          className="cls-3"
          d="M1001.92,525.5a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,1002,525.55Z"
        ></path>
        <path
          className="cls-3"
          d="M1000.76,514.17s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S1000.75,514.18,1000.76,514.17Z"
        ></path>
        <path
          className="cls-3"
          d="M999.32,502.88h0a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,999.32,502.88Z"
        ></path>
        <path
          className="cls-3"
          d="M997.6,491.62a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,997.6,491.62Z"
        ></path>
        <path
          className="cls-3"
          d="M995.61,480.41s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,995.64,480.46Z"
        ></path>
        <path
          className="cls-3"
          d="M993.33,469.26h0s0,0,0,.05h0A0,0,0,0,1,993.33,469.26Z"
        ></path>
        <path
          className="cls-3"
          d="M990.77,458.16s0,0,.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,990.77,458.16Z"
        ></path>
        <path
          className="cls-3"
          d="M987.94,447.13s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,987.94,447.13Z"
        ></path>
        <path
          className="cls-3"
          d="M984.84,436.18h0s0,0,0,.05,0,0,0,0A0,0,0,0,1,984.84,436.18Z"
        ></path>
        <path
          className="cls-3"
          d="M981.46,425.3s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,981.46,425.3Z"
        ></path>
        <path
          className="cls-3"
          d="M977.81,414.52h.05a0,0,0,0,1,0,.05h0A0,0,0,0,1,977.81,414.52Z"
        ></path>
        <path
          className="cls-3"
          d="M973.9,403.83h0v0s-.05,0-.06,0S973.89,403.84,973.9,403.83Z"
        ></path>
        <path
          className="cls-3"
          d="M969.72,393.23s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,969.72,393.23Z"
        ></path>
        <path
          className="cls-3"
          d="M965.28,382.75a0,0,0,1,1,0,.05,0,0,0,0,1,0-.05A0,0,0,0,1,965.28,382.75Z"
        ></path>
        <path
          className="cls-3"
          d="M960.58,372.38a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0-.05Z"
        ></path>
        <path
          className="cls-3"
          d="M955.62,362.13s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,955.62,362.13Z"
        ></path>
        <path
          className="cls-3"
          d="M950.4,352s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,950.4,352Z"
        ></path>
        <path
          className="cls-3"
          d="M944.94,342a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,944.94,342Z"
        ></path>
        <path
          className="cls-3"
          d="M939.23,332.17a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0-.05Z"
        ></path>
        <path
          className="cls-3"
          d="M933.27,322.47s0,0,0,0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0S933.26,322.48,933.27,322.47Z"
        ></path>
        <path
          className="cls-3"
          d="M927.07,312.91a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,927.07,312.91Z"
        ></path>
        <path
          className="cls-3"
          d="M920.64,303.52s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,920.64,303.52Z"
        ></path>
        <path
          className="cls-3"
          d="M914,294.29s0,0,0,0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0S914,294.3,914,294.29Z"
        ></path>
        <path
          className="cls-3"
          d="M907.09,285.22s0,0,0,0a0,0,0,0,1,0,0h0S907.07,285.23,907.09,285.22Z"
        ></path>
        <path
          className="cls-3"
          d="M900,276.33s0,0,0,0,0,0,0,0h0A0,0,0,0,1,900,276.33Z"
        ></path>
        <path
          className="cls-3"
          d="M892.64,267.63h0a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,892.64,267.63Z"
        ></path>
        <path
          className="cls-3"
          d="M885.09,259.1s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0S885.07,259.11,885.09,259.1Z"
        ></path>
        <path
          className="cls-3"
          d="M877.32,250.77a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,877.32,250.77Z"
        ></path>
        <path
          className="cls-3"
          d="M869.36,242.64h0s0,0,0,.05,0,0,0,0S869.35,242.65,869.36,242.64Z"
        ></path>
        <path
          className="cls-3"
          d="M861.19,234.7s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,861.19,234.7Z"
        ></path>
        <path
          className="cls-3"
          d="M852.83,227s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A.06.06,0,0,1,852.83,227Z"
        ></path>
        <path
          className="cls-3"
          d="M844.28,219.46s0,0,0,0a.08.08,0,0,1,0,0s-.05,0-.06,0S844.27,219.46,844.28,219.46Z"
        ></path>
        <path
          className="cls-3"
          d="M835.54,212.15a0,0,0,0,1,.05,0s0,0,0,0,0,0,0,0A0,0,0,0,1,835.54,212.15Z"
        ></path>
        <path
          className="cls-3"
          d="M826.63,205.07s0,0,0,0a0,0,0,0,1,0,0s0,0-.06,0S826.62,205.08,826.63,205.07Z"
        ></path>
        <path
          className="cls-3"
          d="M817.54,198.21s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S817.53,198.22,817.54,198.21Z"
        ></path>
        <path
          className="cls-3"
          d="M808.28,191.58s0,0,0,0,0,0,0,0h-.05A.07.07,0,0,1,808.28,191.58Z"
        ></path>
        <path
          className="cls-3"
          d="M798.86,185.19h0a.07.07,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,798.86,185.19Z"
        ></path>
        <path
          className="cls-3"
          d="M789.29,179a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,789.29,179Z"
        ></path>
        <path
          className="cls-3"
          d="M779.56,173.11a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S779.55,173.12,779.56,173.11Z"
        ></path>
        <path
          className="cls-3"
          d="M769.69,167.44h0s0,0,0,0a0,0,0,0,1,0,0S769.67,167.45,769.69,167.44Z"
        ></path>
        <path
          className="cls-3"
          d="M759.68,162a0,0,0,1,1,0,.05s0,0,0,0A0,0,0,0,1,759.68,162Z"
        ></path>
        <path
          className="cls-3"
          d="M749.54,156.83s0,0,0,0,0,0,0,0h0S749.52,156.84,749.54,156.83Z"
        ></path>
        <path
          className="cls-3"
          d="M739.27,151.91s0,0,0,0a0,0,0,0,1,0,0h0S739.25,151.92,739.27,151.91Z"
        ></path>
        <path
          className="cls-3"
          d="M728.88,147.25s0,0,0,0a0,0,0,0,1,0,0s0,0-.06,0S728.87,147.26,728.88,147.25Z"
        ></path>
        <path
          className="cls-3"
          d="M718.38,142.85h0a0,0,0,0,1,0,.05,0,0,0,0,1,0,0S718.37,142.86,718.38,142.85Z"
        ></path>
        <path
          className="cls-3"
          d="M707.77,138.71a0,0,0,0,1,.05,0,0,0,0,0,1,0,0h0A0,0,0,0,1,707.77,138.71Z"
        ></path>
        <path
          className="cls-3"
          d="M697.07,134.83s0,0,0,0a0,0,0,0,1,0,0h0S697.05,134.84,697.07,134.83Z"
        ></path>
        <path
          className="cls-3"
          d="M686.27,131.23h0s0,0,0,.05,0,0,0,0A0,0,0,0,1,686.27,131.23Z"
        ></path>
        <path
          className="cls-3"
          d="M675.38,127.89s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S675.37,127.9,675.38,127.89Z"
        ></path>
        <path
          className="cls-3"
          d="M664.41,124.83s0,0,.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,664.41,124.83Z"
        ></path>
        <path
          className="cls-3"
          d="M653.38,122a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,653.41,122.09Z"
        ></path>
        <path
          className="cls-3"
          d="M642.31,119.58a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,642.31,119.58Z"
        ></path>
        <path
          className="cls-3"
          d="M631.1,117.29a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,631.1,117.29Z"
        ></path>
        <path
          className="cls-3"
          d="M619.89,115.34a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,619.89,115.34Z"
        ></path>
        <path
          className="cls-3"
          d="M608.63,113.66a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,608.63,113.66Z"
        ></path>
        <path
          className="cls-3"
          d="M597.33,112.27h0s0,0,0,0,0,0,0,0A0,0,0,0,1,597.33,112.27Z"
        ></path>
        <path
          className="cls-3"
          d="M586,111.15a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,586,111.15Z"
        ></path>
        <path
          className="cls-3"
          d="M574.64,110.33a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0S574.62,110.34,574.64,110.33Z"
        ></path>
        <path
          className="cls-3"
          d="M563.3,109.83s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S563.31,109.82,563.3,109.83Z"
        ></path>
        <path
          className="cls-3"
          d="M551.92,109.57s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,551.92,109.57Z"
        ></path>
        <path
          className="cls-3"
          d="M540.5,109.54a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,540.5,109.54Z"
        ></path>
        <path
          className="cls-3"
          d="M529.15,109.89a0,0,0,0,1,0,0s0,0,0,0h0S529.16,109.88,529.15,109.89Z"
        ></path>
        <path
          className="cls-3"
          d="M517.78,110.48h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S517.79,110.47,517.78,110.48Z"
        ></path>
        <path
          className="cls-3"
          d="M506.39,111.31s0,0,0,0a0,0,0,0,1,0,0h-.05S506.38,111.32,506.39,111.31Z"
        ></path>
        <path
          className="cls-3"
          d="M495.1,112.51s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0S495.11,112.5,495.1,112.51Z"
        ></path>
        <path
          className="cls-3"
          d="M483.8,114s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,483.8,114Z"
        ></path>
        <path
          className="cls-3"
          d="M472.51,115.62a0,0,0,0,1,.05,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,472.51,115.62Z"
        ></path>
        <path
          className="cls-3"
          d="M461.3,117.62s0,0,.05,0a0,0,0,0,1,0,0,0,0,0,0,1-.05,0S461.29,117.63,461.3,117.62Z"
        ></path>
        <path
          className="cls-3"
          d="M450.18,119.94a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,450.18,119.94Z"
        ></path>
        <path
          className="cls-3"
          d="M439.09,122.5s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,439.09,122.5Z"
        ></path>
        <path
          className="cls-3"
          d="M428,125.28s0,0,.05,0a.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,428,125.28Z"
        ></path>
        <path
          className="cls-3"
          d="M417.07,128.38h0a0,0,0,0,1,0,0s-.05,0-.06,0A0,0,0,0,1,417.07,128.38Z"
        ></path>
        <path
          className="cls-3"
          d="M406.23,131.81a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S406.24,131.8,406.23,131.81Z"
        ></path>
        <path
          className="cls-3"
          d="M395.44,135.45a0,0,0,0,1,0,0s0,0,0,0h0S395.46,135.45,395.44,135.45Z"
        ></path>
        <path
          className="cls-3"
          d="M384.75,139.37a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,384.75,139.37Z"
        ></path>
        <path
          className="cls-3"
          d="M374.16,143.55a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05S374.17,143.54,374.16,143.55Z"
        ></path>
        <path
          className="cls-3"
          d="M363.68,148h0a0,0,0,0,1,0-.05,0,0,0,0,1,.05,0A.06.06,0,0,1,363.68,148Z"
        ></path>
        <path
          className="cls-3"
          d="M353.31,152.69h0v0s0,0,.05,0S353.32,152.69,353.31,152.69Z"
        ></path>
        <path
          className="cls-3"
          d="M343.06,157.65h0s0,0,0,0a0,0,0,0,1,.05,0S343.07,157.65,343.06,157.65Z"
        ></path>
        <path
          className="cls-3"
          d="M332.94,162.87s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,1,332.94,162.87Z"
        ></path>
        <path
          className="cls-3"
          d="M323,168.33h0a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,323,168.33Z"
        ></path>
        <path
          className="cls-3"
          d="M313.1,174s0,0,0,0,0,0,0,0h0S313.11,174,313.1,174Z"
        ></path>
        <path
          className="cls-3"
          d="M303.4,180a0,0,0,0,1,0,0s-.05,0-.06,0a0,0,0,0,1,0,0S303.39,179.94,303.4,180Z"
        ></path>
        <path
          className="cls-3"
          d="M293.84,186.2s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,293.84,186.2Z"
        ></path>
        <path
          className="cls-3"
          d="M284.44,192.63s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,284.41,192.58Z"
        ></path>
        <path
          className="cls-3"
          d="M275.21,199.29s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,275.21,199.29Z"
        ></path>
        <path
          className="cls-3"
          d="M266.15,206.18h0s0,0,0-.05,0,0,0,0S266.16,206.17,266.15,206.18Z"
        ></path>
        <path
          className="cls-3"
          d="M257.26,213.3a0,0,0,1,1,0-.05s0,0,0,0S257.27,213.29,257.26,213.3Z"
        ></path>
        <path
          className="cls-3"
          d="M248.55,220.64h0s0,0,0,0h0S248.57,220.63,248.55,220.64Z"
        ></path>
        <path
          className="cls-3"
          d="M240,228.19h0s0,0,0,0h0A0,0,0,0,1,240,228.19Z"
        ></path>
        <path
          className="cls-3"
          d="M231.7,236s0,0,0,0,0,0,0,0a0,0,0,0,1,.05,0A0,0,0,0,1,231.7,236Z"
        ></path>
        <path
          className="cls-3"
          d="M223.56,243.91a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,223.56,243.91Z"
        ></path>
        <path
          className="cls-3"
          d="M215.63,252.08s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,215.63,252.08Z"
        ></path>
        <path
          className="cls-3"
          d="M207.9,260.44s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S207.91,260.43,207.9,260.44Z"
        ></path>
        <path
          className="cls-3"
          d="M200.38,269a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,200.38,269Z"
        ></path>
        <path
          className="cls-3"
          d="M193.08,277.73s0,0,0,0a0,0,0,0,1,0,0h.05A0,0,0,0,1,193.08,277.73Z"
        ></path>
        <path
          className="cls-3"
          d="M186,286.64s0,0-.05,0a.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,186,286.64Z"
        ></path>
        <path
          className="cls-3"
          d="M179.14,295.73a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,.05,0S179.15,295.72,179.14,295.73Z"
        ></path>
        <path
          className="cls-3"
          d="M172.51,305s0,0,0,0a0,0,0,0,1,.05,0S172.52,305,172.51,305Z"
        ></path>
        <path
          className="cls-3"
          d="M166.11,314.41s0,0,0,0,0,0,0,0a0,0,0,0,1,0,.05Z"
        ></path>
        <path
          className="cls-3"
          d="M160,324h0s0,0,0,0h0S160,324,160,324Z"
        ></path>
        <path
          className="cls-3"
          d="M154,333.71s0,0,0,0a.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,154,333.71Z"
        ></path>
        <path
          className="cls-3"
          d="M148.36,343.58a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,148.36,343.58Z"
        ></path>
        <path
          className="cls-3"
          d="M142.94,353.59a0,0,0,0,1-.05,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,142.94,353.59Z"
        ></path>
        <path
          className="cls-3"
          d="M137.76,363.73h0s0,0,0-.05h0S137.77,363.72,137.76,363.73Z"
        ></path>
        <path
          className="cls-3"
          d="M132.84,374h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0S132.85,374,132.84,374Z"
        ></path>
        <path
          className="cls-3"
          d="M128.18,384.39s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,128.18,384.39Z"
        ></path>
        <path
          className="cls-3"
          d="M123.77,394.89s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,123.77,394.89Z"
        ></path>
        <path
          className="cls-3"
          d="M119.63,405.5s0,0,0,0,0,0,0,0h0S119.65,405.49,119.63,405.5Z"
        ></path>
        <path
          className="cls-3"
          d="M115.76,416.2h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0S115.77,416.19,115.76,416.2Z"
        ></path>
        <path
          className="cls-3"
          d="M112.15,427h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,112.15,427Z"
        ></path>
        <path
          className="cls-3"
          d="M108.82,437.89a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S108.83,437.88,108.82,437.89Z"
        ></path>
        <path
          className="cls-3"
          d="M105.76,448.86a0,0,0,0,1-.05,0s0,0,0,0h0A0,0,0,0,1,105.76,448.86Z"
        ></path>
        <path
          className="cls-3"
          d="M103,459.9h0v0s0,0,0,0A0,0,0,0,1,103,459.9Z"
        ></path>
        <path
          className="cls-3"
          d="M100.45,471a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,100.45,471Z"
        ></path>
        <path
          className="cls-3"
          d="M98.22,482.17s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,98.22,482.17Z"
        ></path>
        <path
          className="cls-3"
          d="M96.26,493.38h0a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,96.26,493.38Z"
        ></path>
        <path
          className="cls-3"
          d="M94.59,504.64h0a0,0,0,0,1,0-.05,0,0,0,0,1,0,0S94.6,504.63,94.59,504.64Z"
        ></path>
        <path
          className="cls-3"
          d="M93.19,515.94h0s0,0,0,0h0S93.21,515.94,93.19,515.94Z"
        ></path>
        <path
          className="cls-3"
          d="M92.08,527.28h0a0,0,0,0,1,0,0s0,0,.06,0S92.1,527.27,92.08,527.28Z"
        ></path>
        <path
          className="cls-3"
          d="M91.25,538.63a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,91.25,538.63Z"
        ></path>
        <path
          className="cls-3"
          d="M90.71,550h0s0,0,0,0h0S90.72,550,90.71,550Z"
        ></path>
        <path
          className="cls-3"
          d="M90.44,561.39h0s0,0,0,0,0,0,0,0A0,0,0,0,1,90.44,561.39Z"
        ></path>
        <path
          className="cls-3"
          d="M90.47,572.77h0a0,0,0,0,1,0,0s0,0,0,0S90.48,572.76,90.47,572.77Z"
        ></path>
        <path
          className="cls-3"
          d="M90.77,584.16s0,0,0,0,0,0,0,0h0S90.79,584.15,90.77,584.16Z"
        ></path>
        <path
          className="cls-3"
          d="M91.36,595.53s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,91.36,595.53Z"
        ></path>
        <path
          className="cls-3"
          d="M92.23,606.88a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,92.2,606.83Z"
        ></path>
        <path
          className="cls-3"
          d="M93.39,618.21s0,0,0,0a0,0,0,0,1,0,0h.05A0,0,0,0,1,93.39,618.21Z"
        ></path>
        <circle
          className="cls-3"
          cx="94.81"
          cy="629.48"
          r="0.03"
          transform="translate(-349.35 173.46) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M96.54,640.76s0,0,0,0a0,0,0,0,1,0,0h0S96.56,640.75,96.54,640.76Z"
        ></path>
        <path
          className="cls-3"
          d="M98.54,652s0,0,0,0a0,0,0,0,1,0,0h0S98.56,652,98.54,652Z"
        ></path>
        <path
          className="cls-3"
          d="M100.82,663.12h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0S100.83,663.11,100.82,663.12Z"
        ></path>
        <path
          className="cls-3"
          d="M103.37,674.22s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,103.37,674.22Z"
        ></path>
        <path
          className="cls-3"
          d="M106.21,685.25a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,106.21,685.25Z"
        ></path>
        <path
          className="cls-3"
          d="M109.31,696.2a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S109.32,696.19,109.31,696.2Z"
        ></path>
        <path
          className="cls-3"
          d="M112.69,707.08a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,112.69,707.08Z"
        ></path>
        <path
          className="cls-3"
          d="M116.33,717.86s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0A0,0,0,0,1,116.33,717.86Z"
        ></path>
        <path
          className="cls-3"
          d="M120.25,728.55h0v0h0A0,0,0,0,1,120.25,728.55Z"
        ></path>
        <path
          className="cls-3"
          d="M124.43,739.15h0s0,0,0,0h0A0,0,0,0,1,124.43,739.15Z"
        ></path>
        <path
          className="cls-3"
          d="M128.87,749.63a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S128.88,749.62,128.87,749.63Z"
        ></path>
        <path
          className="cls-3"
          d="M133.57,760s0,0,0,0a0,0,0,1,1,.05,0A0,0,0,0,1,133.53,760Z"
        ></path>
        <path
          className="cls-3"
          d="M138.53,770.25s0,0,0,0a0,0,0,0,1,0,0h0S138.55,770.24,138.53,770.25Z"
        ></path>
        <path
          className="cls-3"
          d="M143.75,780.37s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,143.75,780.37Z"
        ></path>
        <path
          className="cls-3"
          d="M149.21,790.36a0,0,0,0,1,0-.05s0,0,0,0S149.22,790.35,149.21,790.36Z"
        ></path>
        <path
          className="cls-3"
          d="M154.92,800.21s0,0,0,0a0,0,0,0,1,.05,0A0,0,0,0,1,154.88,800.2Z"
        ></path>
        <path
          className="cls-3"
          d="M160.88,809.91h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,160.88,809.91Z"
        ></path>
        <path
          className="cls-3"
          d="M167.07,819.47s0,0,0,0,0,0,0,0h0S167.09,819.46,167.07,819.47Z"
        ></path>
        <path
          className="cls-3"
          d="M173.51,828.86s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,173.51,828.86Z"
        ></path>
        <path
          className="cls-3"
          d="M180.17,838.09h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0S180.18,838.08,180.17,838.09Z"
        ></path>
        <path
          className="cls-3"
          d="M187.06,847.16s0,0,0,0,0,0,0,0h0S187.08,847.15,187.06,847.16Z"
        ></path>
        <path
          className="cls-3"
          d="M194.18,856h-.05a.07.07,0,0,1,0,0h0S194.19,856,194.18,856Z"
        ></path>
        <path
          className="cls-3"
          d="M201.51,864.75h0s0,0,0,0,0,0,0,0A0,0,0,0,1,201.51,864.75Z"
        ></path>
        <path
          className="cls-3"
          d="M209.06,873.28s0,0,0,0a0,0,0,0,1,0,0h0S209.08,873.27,209.06,873.28Z"
        ></path>
        <path
          className="cls-3"
          d="M216.82,881.61s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,216.82,881.61Z"
        ></path>
        <path
          className="cls-3"
          d="M224.79,889.74h0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0S224.8,889.73,224.79,889.74Z"
        ></path>
        <path
          className="cls-3"
          d="M233,897.68a0,0,0,0,1-.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,233,897.68Z"
        ></path>
        <path
          className="cls-3"
          d="M241.32,905.41a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,241.32,905.41Z"
        ></path>
        <path
          className="cls-3"
          d="M249.87,912.92h0s0,0,0,0h0S249.88,912.91,249.87,912.92Z"
        ></path>
        <path
          className="cls-3"
          d="M258.6,920.23h0a0,0,0,0,1,0,0s.05,0,.06,0S258.62,920.22,258.6,920.23Z"
        ></path>
        <path
          className="cls-3"
          d="M267.52,927.31s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S267.53,927.3,267.52,927.31Z"
        ></path>
        <path
          className="cls-3"
          d="M276.61,934.17h0a0,0,0,0,1,0,0s.05,0,.05,0A0,0,0,0,1,276.61,934.17Z"
        ></path>
        <path
          className="cls-3"
          d="M285.87,940.79h-.05s0,0,0,0h0S285.88,940.79,285.87,940.79Z"
        ></path>
        <path
          className="cls-3"
          d="M295.28,947.19a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,295.28,947.19Z"
        ></path>
        <path
          className="cls-3"
          d="M304.86,953.35a0,0,0,1,1,0-.05s0,0,0,0S304.87,953.34,304.86,953.35Z"
        ></path>
        <path
          className="cls-3"
          d="M314.59,959.27s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S314.6,959.26,314.59,959.27Z"
        ></path>
        <path
          className="cls-3"
          d="M324.46,964.94h0a0,0,0,0,1,0-.05,0,0,0,0,1,.05,0S324.47,964.93,324.46,964.94Z"
        ></path>
        <path
          className="cls-3"
          d="M334.47,970.37a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S334.48,970.36,334.47,970.37Z"
        ></path>
        <path
          className="cls-3"
          d="M339,986.92h-.05a.06.06,0,0,1,0,0h0A0,0,0,0,1,339,986.92Z"
        ></path>
        <path
          className="cls-3"
          d="M349.25,991.84a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,349.25,991.84Z"
        ></path>
        <path
          className="cls-3"
          d="M359.64,996.51h-.05a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,359.64,996.51Z"
        ></path>
        <path
          className="cls-3"
          d="M370.13,1000.93a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,.05,0S370.14,1000.92,370.13,1000.93Z"
        ></path>
        <path
          className="cls-3"
          d="M380.73,1005.1s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,1,380.73,1005.1Z"
        ></path>
        <path
          className="cls-3"
          d="M391.42,1009h0s0,0,0,0h0S391.43,1009,391.42,1009Z"
        ></path>
        <path
          className="cls-3"
          d="M402.21,1012.65s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,402.21,1012.65Z"
        ></path>
        <path
          className="cls-3"
          d="M413.08,1016h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S413.09,1016,413.08,1016Z"
        ></path>
        <path
          className="cls-3"
          d="M424,1019.15h0a0,0,0,0,1,0,0H424S424,1019.14,424,1019.15Z"
        ></path>
        <path
          className="cls-3"
          d="M435.05,1022h0s0,0,0,0h0S435.07,1022,435.05,1022Z"
        ></path>
        <path
          className="cls-3"
          d="M446.14,1024.59s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,446.14,1024.59Z"
        ></path>
        <path
          className="cls-3"
          d="M457.29,1026.9s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0S457.3,1026.89,457.29,1026.9Z"
        ></path>
        <path
          className="cls-3"
          d="M468.49,1028.94h0s0,0,0,0h0S468.51,1028.93,468.49,1028.94Z"
        ></path>
        <path
          className="cls-3"
          d="M479.74,1030.71h0s0,0,0,0h0S479.75,1030.7,479.74,1030.71Z"
        ></path>
        <path
          className="cls-3"
          d="M491,1032.21h0a0,0,0,0,1,0,0h0A0,0,0,0,1,491,1032.21Z"
        ></path>
        <path
          className="cls-3"
          d="M502.35,1033.43h0s0,0,0,0h0S502.36,1033.42,502.35,1033.43Z"
        ></path>
        <path
          className="cls-3"
          d="M513.69,1034.38h0a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,513.69,1034.38Z"
        ></path>
        <path
          className="cls-3"
          d="M525.06,1035.05h0s0,0,0,0h.05S525.07,1035.05,525.06,1035.05Z"
        ></path>
        <path
          className="cls-3"
          d="M536.44,1035.45s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0S536.45,1035.44,536.44,1035.45Z"
        ></path>
        <path
          className="cls-3"
          d="M547.82,1035.57h0a0,0,0,0,1,0,0h0A0,0,0,0,1,547.82,1035.57Z"
        ></path>
        <path
          className="cls-3"
          d="M559.21,1035.42s0,0,0,0a0,0,0,0,1,0,0h.05A0,0,0,0,1,559.21,1035.42Z"
        ></path>
        <path
          className="cls-3"
          d="M570.59,1035h-.05v0h.05A0,0,0,0,1,570.59,1035Z"
        ></path>
        <path
          className="cls-3"
          d="M582,1034.28s0,0,0,0,0,0,0,0h0S582,1034.27,582,1034.28Z"
        ></path>
        <path
          className="cls-3"
          d="M593.29,1033.29h0a0,0,0,0,1,0,0h0A0,0,0,0,1,593.29,1033.29Z"
        ></path>
        <path
          className="cls-3"
          d="M604.61,1032h0s0,0,0,0h0A0,0,0,0,1,604.61,1032Z"
        ></path>
        <path
          className="cls-3"
          d="M615.89,1030.5a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,615.89,1030.5Z"
        ></path>
        <path
          className="cls-3"
          d="M627.14,1028.7a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,627.14,1028.7Z"
        ></path>
        <path
          className="cls-3"
          d="M638.33,1026.62a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S638.34,1026.61,638.33,1026.62Z"
        ></path>
        <path
          className="cls-3"
          d="M649.47,1024.27a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,649.44,1024.22Z"
        ></path>
        <path
          className="cls-3"
          d="M660.55,1021.65a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,660.55,1021.65Z"
        ></path>
        <path
          className="cls-3"
          d="M671.57,1018.77h-.05s0,0,0,0,0,0,.05,0A0,0,0,0,1,671.57,1018.77Z"
        ></path>
        <path
          className="cls-3"
          d="M682.51,1015.61h0a.1.1,0,0,1,0,0s0,0,0,0A0,0,0,0,1,682.51,1015.61Z"
        ></path>
        <path
          className="cls-3"
          d="M693.37,1012.2s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,693.37,1012.2Z"
        ></path>
        <path
          className="cls-3"
          d="M704.14,1008.52s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,704.14,1008.52Z"
        ></path>
        <path
          className="cls-3"
          d="M714.83,1004.58a0,0,0,0,1-.05,0,.06.06,0,0,1,0,0h0A0,0,0,0,1,714.83,1004.58Z"
        ></path>
        <path
          className="cls-3"
          d="M725.41,1000.38a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S725.42,1000.37,725.41,1000.38Z"
        ></path>
        <path
          className="cls-3"
          d="M735.89,995.93s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,735.89,995.93Z"
        ></path>
        <path
          className="cls-3"
          d="M746.26,991.22s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A.06.06,0,0,1,746.26,991.22Z"
        ></path>
        <path
          className="cls-3"
          d="M756.51,986.26h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S756.52,986.25,756.51,986.26Z"
        ></path>
        <path
          className="cls-3"
          d="M766.64,981.06s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,766.64,981.06Z"
        ></path>
        <circle
          className="cls-3"
          cx="776.62"
          cy="975.59"
          r="0.03"
          transform="translate(-423.24 636.1) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M786.5,969.92h0a0,0,0,0,1,0,0s0,0,0,0S786.51,969.91,786.5,969.92Z"
        ></path>
        <path
          className="cls-3"
          d="M796.22,964s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0S796.23,964,796.22,964Z"
        ></path>
        <path
          className="cls-3"
          d="M805.8,957.84a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,805.8,957.84Z"
        ></path>
        <path
          className="cls-3"
          d="M815.22,951.45s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0S815.23,951.44,815.22,951.45Z"
        ></path>
        <path
          className="cls-3"
          d="M824.45,944.78a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S824.44,944.79,824.45,944.78Z"
        ></path>
        <path
          className="cls-3"
          d="M833.59,938s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0A0,0,0,0,1,833.59,938Z"
        ></path>
        <path
          className="cls-3"
          d="M842.52,930.93a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S842.53,930.92,842.52,930.93Z"
        ></path>
        <path
          className="cls-3"
          d="M851.28,923.66s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S851.29,923.65,851.28,923.66Z"
        ></path>
        <path
          className="cls-3"
          d="M859.86,916.17h0s0,0,0,0,0,0,0,0A0,0,0,0,1,859.86,916.17Z"
        ></path>
        <path
          className="cls-3"
          d="M868.26,908.48h0a0,0,0,0,1,0-.05h0S868.27,908.47,868.26,908.48Z"
        ></path>
        <path
          className="cls-3"
          d="M876.47,900.59s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0A.06.06,0,0,1,876.47,900.59Z"
        ></path>
        <path
          className="cls-3"
          d="M884.48,892.5h0s0,0,0,0h0S884.5,892.49,884.48,892.5Z"
        ></path>
        <path
          className="cls-3"
          d="M892.3,884.22h0a0,0,0,0,1,0-.05s0,0,0,0S892.31,884.21,892.3,884.22Z"
        ></path>
        <path
          className="cls-3"
          d="M899.91,875.76s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,899.91,875.76Z"
        ></path>
        <path
          className="cls-3"
          d="M907.31,867.11s0,0,0,0,0,0,0,0h0S907.33,867.1,907.31,867.11Z"
        ></path>
        <path
          className="cls-3"
          d="M914.51,858.28s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,914.51,858.28Z"
        ></path>
        <path
          className="cls-3"
          d="M921.48,849.28h0s0,0,0,0h0A0,0,0,0,1,921.48,849.28Z"
        ></path>
        <path
          className="cls-3"
          d="M941.08,821.31s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,1,941.08,821.31Z"
        ></path>
        <path
          className="cls-3"
          d="M947.11,811.63s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A.06.06,0,0,1,947.11,811.63Z"
        ></path>
        <path
          className="cls-3"
          d="M953,801.9h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,953,801.9Z"
        ></path>
        <path
          className="cls-3"
          d="M958.58,792s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S958.6,792,958.58,792Z"
        ></path>
        <path
          className="cls-3"
          d="M969.05,771.76h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,969.05,771.76Z"
        ></path>
        <path
          className="cls-3"
          d="M973.91,761.47s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S973.92,761.46,973.91,761.47Z"
        ></path>
        <path
          className="cls-3"
          d="M978.52,751.06h0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,978.52,751.06Z"
        ></path>
        <path
          className="cls-3"
          d="M982.84,740.49s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,982.84,740.49Z"
        ></path>
        <path
          className="cls-3"
          d="M986.94,729.87h0s0,0,0,0h-.05S986.93,729.88,986.94,729.87Z"
        ></path>
        <path
          className="cls-3"
          d="M990.82,719.2h0a0,0,0,0,1,0,0s0,0,.05,0A0,0,0,0,1,990.82,719.2Z"
        ></path>
        <path
          className="cls-3"
          d="M994.36,708.34s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,994.36,708.34Z"
        ></path>
        <path
          className="cls-3"
          d="M997.72,697.5a0,0,0,0,1-.05,0s0,0,0,0h0A0,0,0,0,1,997.72,697.5Z"
        ></path>
        <path
          className="cls-3"
          d="M1000.77,686.53a0,0,0,0,1,0,0,.06.06,0,0,1,0,0h0A0,0,0,0,1,1000.77,686.53Z"
        ></path>
        <path
          className="cls-3"
          d="M1003.52,675.44s0,0,0,0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0A0,0,0,0,1,1003.52,675.44Z"
        ></path>
        <path
          className="cls-3"
          d="M1006.07,664.38a0,0,0,0,1,0,0,0,0,0,0,1,0,0h.05S1006.08,664.37,1006.07,664.38Z"
        ></path>
        <path
          className="cls-3"
          d="M1008.28,653.17s0,0,0,0a0,0,0,0,1,0,0s-.05,0-.06,0S1008.27,653.18,1008.28,653.17Z"
        ></path>
        <path
          className="cls-3"
          d="M1010.26,642h0a0,0,0,0,1,0,0s0,0,0,0S1010.24,642,1010.26,642Z"
        ></path>
        <path
          className="cls-3"
          d="M1012,630.7a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,1012,630.7Z"
        ></path>
        <path
          className="cls-3"
          d="M1013.39,619.4s0,0,0,0,0,0,0,0a0,0,0,1,1,0-.05Z"
        ></path>
        <path
          className="cls-3"
          d="M1014.54,608.08h0a.1.1,0,0,1,0,0h-.06S1014.53,608.08,1014.54,608.08Z"
        ></path>
        <path
          className="cls-3"
          d="M1015.42,596.72s0,0,0,0a0,0,0,0,1,0,0h0S1015.4,596.73,1015.42,596.72Z"
        ></path>
        <path
          className="cls-3"
          d="M1016,585.35s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,1016,585.35Z"
        ></path>
        <path
          className="cls-3"
          d="M1016.35,574s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S1016.34,574,1016.35,574Z"
        ></path>
        <path
          className="cls-3"
          d="M1016.4,562.59h0a.1.1,0,0,1,0,0h-.06S1016.39,562.6,1016.4,562.59Z"
        ></path>
        <path
          className="cls-3"
          d="M1016.18,551.2h0s0,0,0,0,0,0-.05,0A0,0,0,0,1,1016.18,551.2Z"
        ></path>
        <path
          className="cls-3"
          d="M1015.67,539.83h.05a0,0,0,0,1,0,0h0A0,0,0,0,1,1015.67,539.83Z"
        ></path>
        <path
          className="cls-3"
          d="M1014.93,528.52s0,0,0,0a0,0,0,0,1,0,0h0S1015,528.51,1014.93,528.52Z"
        ></path>
        <path
          className="cls-3"
          d="M1013.85,517.13a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,1013.88,517.18Z"
        ></path>
        <path
          className="cls-3"
          d="M1012.52,505.82s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S1012.51,505.83,1012.52,505.82Z"
        ></path>
        <path
          className="cls-3"
          d="M1010.92,494.55s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1-.06,0S1010.91,494.56,1010.92,494.55Z"
        ></path>
        <path
          className="cls-3"
          d="M1009,483.32h.05a0,0,0,0,1,0,.05s0,0,0,0A0,0,0,0,1,1009,483.32Z"
        ></path>
        <path
          className="cls-3"
          d="M1006.9,472.14h0s0,0,0,.05h0S1006.89,472.15,1006.9,472.14Z"
        ></path>
        <path
          className="cls-3"
          d="M1004.48,461s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,1004.48,461Z"
        ></path>
        <path
          className="cls-3"
          d="M1001.8,449.94s0,0,0,0a0,0,0,0,1,0,0h0S1001.78,450,1001.8,449.94Z"
        ></path>
        <path
          className="cls-3"
          d="M992.14,417.19h0s0,0,0,0h0S992.13,417.2,992.14,417.19Z"
        ></path>
        <path
          className="cls-3"
          d="M988.4,406.43s0,0,0,0a0,0,0,0,1,0,0h0S988.38,406.44,988.4,406.43Z"
        ></path>
        <path
          className="cls-3"
          d="M984.39,395.77a0,0,0,0,1,.05,0s0,0,0,0h0A0,0,0,0,1,984.39,395.77Z"
        ></path>
        <path
          className="cls-3"
          d="M980.13,385.22s0,0,0,0,0,0,0,0h0S980.12,385.23,980.13,385.22Z"
        ></path>
        <path
          className="cls-3"
          d="M975.62,374.76s0,0,0,0,0,0,0,0h0S975.6,374.77,975.62,374.76Z"
        ></path>
        <path
          className="cls-3"
          d="M970.85,364.42h0s0,0,0,0,0,0,0,0S970.83,364.43,970.85,364.42Z"
        ></path>
        <path
          className="cls-3"
          d="M965.83,354.2s0,0,0,0a0,0,0,0,1,0,0h0S965.81,354.21,965.83,354.2Z"
        ></path>
        <path
          className="cls-3"
          d="M960.56,344.11h.05a0,0,0,0,1,0,.05h0A0,0,0,0,1,960.56,344.11Z"
        ></path>
        <path
          className="cls-3"
          d="M955.06,334.14h0s0,0,0,0,0,0-.05,0S955,334.15,955.06,334.14Z"
        ></path>
        <path
          className="cls-3"
          d="M949.31,324.32s0,0,0,0,0,0,0,0h0S949.29,324.32,949.31,324.32Z"
        ></path>
        <path
          className="cls-3"
          d="M943.32,314.63s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1-.05,0S943.31,314.64,943.32,314.63Z"
        ></path>
        <path
          className="cls-3"
          d="M937.1,305.09s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,937.1,305.09Z"
        ></path>
        <path
          className="cls-3"
          d="M930.66,295.71h0s0,0,0,0h0S930.64,295.72,930.66,295.71Z"
        ></path>
        <path
          className="cls-3"
          d="M924,286.48s0,0,0,0a.06.06,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,924,286.48Z"
        ></path>
        <path
          className="cls-3"
          d="M917.09,277.42s0,0,0,0a0,0,0,0,1,0,0s0,0-.06,0A0,0,0,0,1,917.09,277.42Z"
        ></path>
        <path
          className="cls-3"
          d="M910,268.53a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,910,268.53Z"
        ></path>
        <path
          className="cls-3"
          d="M902.65,259.81s0,0,0,0a0,0,0,0,1,0,0h0S902.63,259.82,902.65,259.81Z"
        ></path>
        <path
          className="cls-3"
          d="M895.11,251.28s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,895.11,251.28Z"
        ></path>
        <path
          className="cls-3"
          d="M887.37,242.93a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S887.36,242.94,887.37,242.93Z"
        ></path>
        <path
          className="cls-3"
          d="M879.43,234.77a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1-.05,0S879.42,234.78,879.43,234.77Z"
        ></path>
        <path
          className="cls-3"
          d="M871.29,226.8a0,0,0,0,1,.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,871.29,226.8Z"
        ></path>
        <path
          className="cls-3"
          d="M863,219h0s0,0,0,.05,0,0,0,0A0,0,0,0,1,863,219Z"
        ></path>
        <path
          className="cls-3"
          d="M854.45,211.48h0a0,0,0,0,1,0,.05s0,0,0,0S854.44,211.49,854.45,211.48Z"
        ></path>
        <path
          className="cls-3"
          d="M845.76,204.12s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,845.76,204.12Z"
        ></path>
        <path
          className="cls-3"
          d="M836.89,197s0,0,0,0a.08.08,0,0,1,0,0s-.05,0-.06,0S836.88,197,836.89,197Z"
        ></path>
        <path
          className="cls-3"
          d="M827.85,190.06s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,827.85,190.06Z"
        ></path>
        <path
          className="cls-3"
          d="M818.64,183.36a0,0,0,0,1,.05,0s0,0,0,0,0,0,0,0A0,0,0,0,1,818.64,183.36Z"
        ></path>
        <path
          className="cls-3"
          d="M809.28,176.89h0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0A0,0,0,0,1,809.28,176.89Z"
        ></path>
        <path
          className="cls-3"
          d="M799.76,170.64a0,0,0,1,1,0,.05s0,0,0,0A0,0,0,0,1,799.76,170.64Z"
        ></path>
        <path
          className="cls-3"
          d="M790.09,164.63s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1-.06,0S790.08,164.64,790.09,164.63Z"
        ></path>
        <path
          className="cls-3"
          d="M780.28,158.85a0,0,0,1,1,0,.05,0,0,0,0,1,0-.05A0,0,0,0,1,780.28,158.85Z"
        ></path>
        <path
          className="cls-3"
          d="M770.33,153.31h0a0,0,0,0,1,0,0s0,0-.06,0S770.31,153.32,770.33,153.31Z"
        ></path>
        <path
          className="cls-3"
          d="M760.25,148a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S760.24,148,760.25,148Z"
        ></path>
        <path
          className="cls-3"
          d="M750,143s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,750,143Z"
        ></path>
        <path
          className="cls-3"
          d="M739.72,138.17a0,0,0,0,1,0,.05,0,0,0,0,1,0-.05A0,0,0,0,1,739.72,138.17Z"
        ></path>
        <path
          className="cls-3"
          d="M729.28,133.62s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S729.26,133.63,729.28,133.62Z"
        ></path>
        <path
          className="cls-3"
          d="M718.73,129.33s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,718.73,129.33Z"
        ></path>
        <path
          className="cls-3"
          d="M708.09,125.29h0a.08.08,0,0,1,0,0s0,0-.06,0S708.07,125.3,708.09,125.29Z"
        ></path>
        <path
          className="cls-3"
          d="M697.38,121.57s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S697.39,121.56,697.38,121.57Z"
        ></path>
        <circle
          className="cls-3"
          cx="686.53"
          cy="118.03"
          r="0.03"
          transform="translate(60.03 422.58) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M675.6,114.75h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,675.6,114.75Z"
        ></path>
        <path
          className="cls-3"
          d="M664.61,111.77h0a0,0,0,0,1,0,0h0A0,0,0,0,1,664.61,111.77Z"
        ></path>
        <path
          className="cls-3"
          d="M653.56,109.05a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,653.59,109.1Z"
        ></path>
        <path
          className="cls-3"
          d="M642.47,106.65a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,642.47,106.65Z"
        ></path>
        <path
          className="cls-3"
          d="M631.26,104.42a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,631.26,104.42Z"
        ></path>
        <path className="cls-3" d="M620.08,102.52s0,0,0,0h0s0,0,0,0Z"></path>
        <path
          className="cls-3"
          d="M608.77,100.88h0a0,0,0,0,1,0,0s-.05,0-.06,0S608.76,100.89,608.77,100.88Z"
        ></path>
        <path
          className="cls-3"
          d="M597.5,99.57a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S597.51,99.56,597.5,99.57Z"
        ></path>
        <path
          className="cls-3"
          d="M586.17,98.48h0s0,0,0,0h0S586.18,98.48,586.17,98.48Z"
        ></path>
        <path
          className="cls-3"
          d="M574.77,97.63h.05s0,0,0,0,0,0,0,0S574.76,97.64,574.77,97.63Z"
        ></path>
        <path
          className="cls-3"
          d="M563.43,97.14a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,563.43,97.14Z"
        ></path>
        <path
          className="cls-3"
          d="M552.05,96.88a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,552.05,96.88Z"
        ></path>
        <path
          className="cls-3"
          d="M540.63,96.85a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S540.62,96.86,540.63,96.85Z"
        ></path>
        <path
          className="cls-3"
          d="M529.25,97.14s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0S529.23,97.15,529.25,97.14Z"
        ></path>
        <path
          className="cls-3"
          d="M517.88,97.72a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S517.86,97.73,517.88,97.72Z"
        ></path>
        <circle
          className="cls-3"
          cx="506.54"
          cy="98.59"
          r="0.03"
          transform="translate(37.58 313.95) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M495.23,99.73s0,0-.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,495.23,99.73Z"
        ></path>
        <path
          className="cls-3"
          d="M483.93,101.13a0,0,0,0,1-.05,0s0,0,0,0,0,0,0,0A0,0,0,0,1,483.93,101.13Z"
        ></path>
        <path
          className="cls-3"
          d="M472.66,102.8h0s0,0,0,0a0,0,0,0,1,0,0S472.68,102.79,472.66,102.8Z"
        ></path>
        <path
          className="cls-3"
          d="M461.44,104.74h0a0,0,0,0,1,0,0s.05,0,.06,0S461.46,104.73,461.44,104.74Z"
        ></path>
        <path
          className="cls-3"
          d="M450.28,107s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,450.28,107Z"
        ></path>
        <path
          className="cls-3"
          d="M439.16,109.43h0s0,0,0-.05h0A0,0,0,0,1,439.16,109.43Z"
        ></path>
        <path
          className="cls-3"
          d="M428.08,112.14a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0S428.07,112.15,428.08,112.14Z"
        ></path>
        <path
          className="cls-3"
          d="M417.14,115.21h-.05s0,0,0,0h0A0,0,0,0,1,417.14,115.21Z"
        ></path>
        <path
          className="cls-3"
          d="M406.23,118.49a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,406.23,118.49Z"
        ></path>
        <path
          className="cls-3"
          d="M395.42,122s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,395.42,122Z"
        ></path>
        <path
          className="cls-3"
          d="M384.69,125.85s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,384.69,125.85Z"
        ></path>
        <path
          className="cls-3"
          d="M374.05,129.92s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S374.06,129.91,374.05,129.92Z"
        ></path>
        <path
          className="cls-3"
          d="M363.52,134.24h0s0,0,0,0a0,0,0,0,1,0,0S363.53,134.23,363.52,134.24Z"
        ></path>
        <path
          className="cls-3"
          d="M353.09,138.82s0,0,0,0a0,0,0,0,1,0,0h.05S353.11,138.81,353.09,138.82Z"
        ></path>
        <path
          className="cls-3"
          d="M342.79,143.66h-.05s0,0,0,0,0,0,.05,0A0,0,0,0,1,342.79,143.66Z"
        ></path>
        <path
          className="cls-3"
          d="M332.59,148.74s0,0,0,0,0,0,0,0h0S332.61,148.73,332.59,148.74Z"
        ></path>
        <path
          className="cls-3"
          d="M322.53,154.06a0,0,0,0,1,0-.05h0A0,0,0,0,1,322.53,154.06Z"
        ></path>
        <path
          className="cls-3"
          d="M312.6,159.63s0,0,0,0a0,0,0,0,1,0,0s.05,0,.06,0S312.61,159.62,312.6,159.63Z"
        ></path>
        <path
          className="cls-3"
          d="M302.81,165.44a0,0,0,0,1-.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,302.81,165.44Z"
        ></path>
        <path
          className="cls-3"
          d="M293.16,171.48s0,0-.05,0,0,0,0,0h0A0,0,0,0,1,293.16,171.48Z"
        ></path>
        <path
          className="cls-3"
          d="M283.66,177.71a0,0,0,0,1,0,0,0,0,0,0,1-.05,0s0,0,0,0A0,0,0,0,1,283.66,177.71Z"
        ></path>
        <path
          className="cls-3"
          d="M274.31,184.26s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.06,0S274.32,184.25,274.31,184.26Z"
        ></path>
        <path
          className="cls-3"
          d="M265.13,191s0,0-.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,265.13,191Z"
        ></path>
        <path
          className="cls-3"
          d="M256.11,197.94s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,1,256.11,197.94Z"
        ></path>
        <path
          className="cls-3"
          d="M247.26,205.11s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0S247.27,205.1,247.26,205.11Z"
        ></path>
        <path
          className="cls-3"
          d="M238.59,212.49s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,238.59,212.49Z"
        ></path>
        <path
          className="cls-3"
          d="M230.1,220.07s0,0,0,0,0,0,0,0h.05S230.11,220.06,230.1,220.07Z"
        ></path>
        <path
          className="cls-3"
          d="M221.8,227.87a0,0,0,0,1-.05,0s0,0,0,0h0A0,0,0,0,1,221.8,227.87Z"
        ></path>
        <path
          className="cls-3"
          d="M213.68,235.86h0s0,0,0,0,0,0,.05,0S213.7,235.85,213.68,235.86Z"
        ></path>
        <path
          className="cls-3"
          d="M205.77,244s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0S205.78,244,205.77,244Z"
        ></path>
        <path
          className="cls-3"
          d="M198.05,252.42s0,0,0,0,0,0,0,0h0S198.07,252.41,198.05,252.42Z"
        ></path>
        <path
          className="cls-3"
          d="M190.54,261h0s0,0,0,0h0S190.56,261,190.54,261Z"
        ></path>
        <path
          className="cls-3"
          d="M183.25,269.71a0,0,0,0,1-.05,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,183.25,269.71Z"
        ></path>
        <path
          className="cls-3"
          d="M176.16,278.63h0a.08.08,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,176.16,278.63Z"
        ></path>
        <path
          className="cls-3"
          d="M169.29,287.71s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S169.31,287.7,169.29,287.71Z"
        ></path>
        <path
          className="cls-3"
          d="M162.65,297h0s0,0,0,0a0,0,0,0,1,0,0S162.66,296.94,162.65,297Z"
        ></path>
        <path
          className="cls-3"
          d="M156.23,306.36a0,0,0,1,1,0-.05,0,0,0,0,1,0,.05S156.24,306.35,156.23,306.36Z"
        ></path>
        <path
          className="cls-3"
          d="M150,315.92s0,0,0,0a0,0,0,0,1,0,0h0S150.06,315.91,150,315.92Z"
        ></path>
        <path
          className="cls-3"
          d="M144.09,325.62s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0A0,0,0,0,1,144.09,325.62Z"
        ></path>
        <path
          className="cls-3"
          d="M138.37,335.47s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S138.38,335.46,138.37,335.47Z"
        ></path>
        <path
          className="cls-3"
          d="M132.89,345.45a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,132.89,345.45Z"
        ></path>
        <path
          className="cls-3"
          d="M127.66,355.56h0a0,0,0,0,1,0-.05,0,0,0,0,1,0,0S127.67,355.55,127.66,355.56Z"
        ></path>
        <path
          className="cls-3"
          d="M122.67,365.8a0,0,0,1,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,122.67,365.8Z"
        ></path>
        <path
          className="cls-3"
          d="M117.94,376.15s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,117.94,376.15Z"
        ></path>
        <path
          className="cls-3"
          d="M113.45,386.62h0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,113.45,386.62Z"
        ></path>
        <path
          className="cls-3"
          d="M109.19,397.14a0,0,0,0,1,0,.05,0,0,0,0,1,0-.05S109.18,397.15,109.19,397.14Z"
        ></path>
        <path
          className="cls-3"
          d="M105.25,407.86a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,105.25,407.86Z"
        ></path>
        <path
          className="cls-3"
          d="M101.54,418.62h0a0,0,0,0,1,0,0h0S101.55,418.62,101.54,418.62Z"
        ></path>
        <path
          className="cls-3"
          d="M98.09,429.48s0,0,0,0a0,0,0,0,1,0,0h0S98.11,429.47,98.09,429.48Z"
        ></path>
        <path
          className="cls-3"
          d="M94.91,440.41s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,94.91,440.41Z"
        ></path>
        <path
          className="cls-3"
          d="M92,451.41h0a0,0,0,0,1,0-.05,0,0,0,0,1,0,0S92,451.4,92,451.41Z"
        ></path>
        <path
          className="cls-3"
          d="M89.34,462.49s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,89.34,462.49Z"
        ></path>
        <path
          className="cls-3"
          d="M87,473.62s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,87,473.62Z"
        ></path>
        <path
          className="cls-3"
          d="M84.85,484.81s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,84.85,484.81Z"
        ></path>
        <path
          className="cls-3"
          d="M83,496.05s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,83,496.05Z"
        ></path>
        <path
          className="cls-3"
          d="M81.44,507.32h0v0s0,0,.06,0S81.46,507.31,81.44,507.32Z"
        ></path>
        <path
          className="cls-3"
          d="M80.15,518.64h0a0,0,0,0,1,0,0s.05,0,.06,0A0,0,0,0,1,80.15,518.64Z"
        ></path>
        <path
          className="cls-3"
          d="M79.13,530s0,0,0,0,0,0,0,0a0,0,0,1,1,0,.05Z"
        ></path>
        <path
          className="cls-3"
          d="M78.39,541.34a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,78.39,541.34Z"
        ></path>
        <path
          className="cls-3"
          d="M77.93,552.72a0,0,0,0,1-.05,0,.06.06,0,0,1,0,0h0A0,0,0,0,1,77.93,552.72Z"
        ></path>
        <path
          className="cls-3"
          d="M77.74,564.1s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S77.75,564.09,77.74,564.1Z"
        ></path>
        <path
          className="cls-3"
          d="M77.82,575.49h0s0,0,0,0,0,0,0,0S77.84,575.48,77.82,575.49Z"
        ></path>
        <path
          className="cls-3"
          d="M78.19,586.87s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,78.19,586.87Z"
        ></path>
        <path
          className="cls-3"
          d="M78.83,598.23h0s0,0,0,0,0,0,0,0S78.84,598.22,78.83,598.23Z"
        ></path>
        <path
          className="cls-3"
          d="M79.74,609.58h0s0,0,0-.05h0A0,0,0,0,1,79.74,609.58Z"
        ></path>
        <path
          className="cls-3"
          d="M80.93,620.91s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S80.94,620.9,80.93,620.91Z"
        ></path>
        <path
          className="cls-3"
          d="M82.4,632.2s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,82.4,632.2Z"
        ></path>
        <path
          className="cls-3"
          d="M84.13,643.45a0,0,0,1,1,0-.05h0A0,0,0,0,1,84.13,643.45Z"
        ></path>
        <path
          className="cls-3"
          d="M86.14,654.66a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S86.15,654.65,86.14,654.66Z"
        ></path>
        <path
          className="cls-3"
          d="M88.42,665.81a0,0,0,0,1,0,0s0,0,0,0h0S88.44,665.8,88.42,665.81Z"
        ></path>
        <path
          className="cls-3"
          d="M91,676.91a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,91,676.91Z"
        ></path>
        <path
          className="cls-3"
          d="M93.79,687.94h0s0,0,0-.05,0,0,0,0A0,0,0,0,1,93.79,687.94Z"
        ></path>
        <path
          className="cls-3"
          d="M96.88,698.9h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S96.89,698.89,96.88,698.9Z"
        ></path>
        <path
          className="cls-3"
          d="M100.23,709.78h0s0,0,0,0h0S100.24,709.77,100.23,709.78Z"
        ></path>
        <path
          className="cls-3"
          d="M103.85,720.58s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,103.85,720.58Z"
        ></path>
        <path
          className="cls-3"
          d="M107.72,731.29s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S107.73,731.28,107.72,731.29Z"
        ></path>
        <circle
          className="cls-3"
          cx="111.84"
          cy="741.87"
          r="0.03"
          transform="translate(-411.71 204.49) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M116.24,752.4h0s0,0,0,0,0,0,0,0A0,0,0,0,1,116.24,752.4Z"
        ></path>
        <path
          className="cls-3"
          d="M120.89,762.8s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,120.89,762.8Z"
        ></path>
        <path
          className="cls-3"
          d="M125.78,773.08a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S125.79,773.07,125.78,773.08Z"
        ></path>
        <path
          className="cls-3"
          d="M130.92,783.24a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,130.92,783.24Z"
        ></path>
        <path
          className="cls-3"
          d="M136.31,793.27a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0S136.32,793.26,136.31,793.27Z"
        ></path>
        <path
          className="cls-3"
          d="M141.94,803.17h-.05v0s0,0,.05,0A0,0,0,0,1,141.94,803.17Z"
        ></path>
        <path
          className="cls-3"
          d="M147.8,812.93s0,0,0,0a0,0,0,0,1,0,0h0S147.82,812.92,147.8,812.93Z"
        ></path>
        <path
          className="cls-3"
          d="M153.91,822.54s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,153.91,822.54Z"
        ></path>
        <path
          className="cls-3"
          d="M160.24,832a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S160.25,832,160.24,832Z"
        ></path>
        <path
          className="cls-3"
          d="M166.8,841.31s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,166.8,841.31Z"
        ></path>
        <path
          className="cls-3"
          d="M173.59,850.45s0,0-.05,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,173.59,850.45Z"
        ></path>
        <path
          className="cls-3"
          d="M180.59,859.43h0a0,0,0,0,1,0,0s.05,0,.06,0A0,0,0,0,1,180.59,859.43Z"
        ></path>
        <path
          className="cls-3"
          d="M187.81,868.23s0,0,0,0a0,0,0,0,1,0,0s.05,0,.06,0S187.82,868.22,187.81,868.23Z"
        ></path>
        <path
          className="cls-3"
          d="M195.24,876.86s0,0,0,0a0,0,0,0,1,0,0h0S195.26,876.85,195.24,876.86Z"
        ></path>
        <path
          className="cls-3"
          d="M202.88,885.3s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S202.89,885.29,202.88,885.3Z"
        ></path>
        <path
          className="cls-3"
          d="M210.72,893.56s0,0,0,0a0,0,0,0,1,0,0h0S210.74,893.55,210.72,893.56Z"
        ></path>
        <path
          className="cls-3"
          d="M218.76,901.62s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,218.76,901.62Z"
        ></path>
        <path
          className="cls-3"
          d="M227,909.48h0s0,0,0,0a0,0,0,0,1,0,0S227,909.47,227,909.48Z"
        ></path>
        <path
          className="cls-3"
          d="M235.41,917.15h0s0,0,0,0,0,0,0,0A0,0,0,0,1,235.41,917.15Z"
        ></path>
        <path
          className="cls-3"
          d="M244,924.6h0a0,0,0,0,1,0,0H244S244,924.6,244,924.6Z"
        ></path>
        <path
          className="cls-3"
          d="M252.8,931.85h0s0,0,0-.05h0A0,0,0,0,1,252.8,931.85Z"
        ></path>
        <path
          className="cls-3"
          d="M261.76,938.88h0a.1.1,0,0,1,0,0s0,0,.05,0A0,0,0,0,1,261.76,938.88Z"
        ></path>
        <path
          className="cls-3"
          d="M270.88,945.69h0s0,0,0,0h0A0,0,0,0,1,270.88,945.69Z"
        ></path>
        <path
          className="cls-3"
          d="M280.17,952.28h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,280.17,952.28Z"
        ></path>
        <path
          className="cls-3"
          d="M289.61,958.64h0s0,0,0,0,0,0,0,0S289.63,958.63,289.61,958.64Z"
        ></path>
        <path
          className="cls-3"
          d="M299.21,964.77h0a0,0,0,0,1,0,0s0,0,0,0S299.22,964.76,299.21,964.77Z"
        ></path>
        <path
          className="cls-3"
          d="M309,970.67s0,0-.05,0v0s0,0,.05,0A0,0,0,0,1,309,970.67Z"
        ></path>
        <path
          className="cls-3"
          d="M318.83,976.33s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A.06.06,0,0,1,318.83,976.33Z"
        ></path>
        <path
          className="cls-3"
          d="M328.84,981.74h0s0,0,0,0h0A0,0,0,0,1,328.84,981.74Z"
        ></path>
        <path
          className="cls-3"
          d="M343.63,1003.21h-.05a0,0,0,0,1,0-.05h0A0,0,0,0,1,343.63,1003.21Z"
        ></path>
        <path
          className="cls-3"
          d="M354,1007.9a0,0,0,0,1-.05,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,354,1007.9Z"
        ></path>
        <path
          className="cls-3"
          d="M364.49,1012.33h0s0,0,0-.05h0S364.5,1012.32,364.49,1012.33Z"
        ></path>
        <path
          className="cls-3"
          d="M385.76,1020.46a0,0,0,1,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,385.76,1020.46Z"
        ></path>
        <path
          className="cls-3"
          d="M396.54,1024.14h-.05a0,0,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,396.54,1024.14Z"
        ></path>
        <path
          className="cls-3"
          d="M407.39,1027.57a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,407.39,1027.57Z"
        ></path>
        <path
          className="cls-3"
          d="M429.34,1033.65h0a0,0,0,0,1,0-.05s0,0,0,0S429.35,1033.64,429.34,1033.65Z"
        ></path>
        <path
          className="cls-3"
          d="M451.54,1038.69h0s0,0,0,0,0,0,0,0A0,0,0,0,1,451.54,1038.69Z"
        ></path>
        <path
          className="cls-3"
          d="M462.73,1040.82a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S462.74,1040.81,462.73,1040.82Z"
        ></path>
        <path
          className="cls-3"
          d="M519.24,1047.45h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S519.25,1047.44,519.24,1047.45Z"
        ></path>
        <path
          className="cls-3"
          d="M530.61,1048s0,0,0,0a0,0,0,0,1,0,0h0S530.63,1048,530.61,1048Z"
        ></path>
        <path
          className="cls-3"
          d="M542,1048.23s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,542,1048.23Z"
        ></path>
        <path
          className="cls-3"
          d="M564.77,1047.93h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S564.78,1047.92,564.77,1047.93Z"
        ></path>
        <path
          className="cls-3"
          d="M643.81,1038.45h0s0,0,0,0h0S643.83,1038.44,643.81,1038.45Z"
        ></path>
        <path
          className="cls-3"
          d="M751.64,1002.71s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,751.64,1002.71Z"
        ></path>
        <path
          className="cls-3"
          d="M761.89,997.75h0s0,0,0,0,0,0,0,0A0,0,0,0,1,761.89,997.75Z"
        ></path>
        <path
          className="cls-3"
          d="M772,992.56s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,772,992.56Z"
        ></path>
        <path
          className="cls-3"
          d="M874,920.46s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S874,920.45,874,920.46Z"
        ></path>
        <path
          className="cls-3"
          d="M882.29,912.64s0,0,0,0a.06.06,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,882.29,912.64Z"
        ></path>
        <path
          className="cls-3"
          d="M890.38,904.63a0,0,0,0,1,0,0s0,0,0,0h0A0,0,0,0,1,890.38,904.63Z"
        ></path>
        <path
          className="cls-3"
          d="M999.4,732.9s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,999.4,732.9Z"
        ></path>
        <path
          className="cls-3"
          d="M1013.07,689.43s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,1013.07,689.43Z"
        ></path>
        <path
          className="cls-3"
          d="M1027.93,599.7s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,1027.93,599.7Z"
        ></path>
        <path
          className="cls-3"
          d="M1029,554.18s0,0,0,0a0,0,0,0,1,0,0s0,0-.06,0S1028.94,554.19,1029,554.18Z"
        ></path>
        <path
          className="cls-3"
          d="M976,346.28h0a0,0,0,0,1,0,0s-.05,0-.06,0S976,346.29,976,346.28Z"
        ></path>
        <path
          className="cls-3"
          d="M970.72,336.21s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,970.72,336.21Z"
        ></path>
        <path
          className="cls-3"
          d="M897.42,235.08s0,0,0,0,0,0,0,0h0S897.4,235.09,897.42,235.08Z"
        ></path>
        <path
          className="cls-3"
          d="M889.5,226.9s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,889.5,226.9Z"
        ></path>
        <path
          className="cls-3"
          d="M881.39,218.91h0s0,0,0,.05,0,0,0,0S881.38,218.92,881.39,218.91Z"
        ></path>
        <path
          className="cls-3"
          d="M790.84,150.31a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A.06.06,0,0,1,790.84,150.31Z"
        ></path>
        <path
          className="cls-3"
          d="M781,144.67a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0S780.94,144.68,781,144.67Z"
        ></path>
        <path
          className="cls-3"
          d="M719.07,115.86s0,0,0,0a.1.1,0,0,1,0,0,0,0,0,0,1-.06,0S719.06,115.87,719.07,115.86Z"
        ></path>
        <path
          className="cls-3"
          d="M697.61,108.24h0s0,0,0,0a0,0,0,0,1,0,0S697.6,108.25,697.61,108.24Z"
        ></path>
        <path
          className="cls-3"
          d="M686.79,104.86s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,686.79,104.86Z"
        ></path>
        <path
          className="cls-3"
          d="M675.82,101.64a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,675.82,101.64Z"
        ></path>
        <path
          className="cls-3"
          d="M653.74,96.07h0a0,0,0,0,1,0,0h0S653.72,96.08,653.74,96.07Z"
        ></path>
        <path
          className="cls-3"
          d="M620.19,89.7a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,620.22,89.75Z"
        ></path>
        <path
          className="cls-3"
          d="M608.91,88.11a0,0,0,0,1,0,0,0,0,0,0,1,0,0h-.05S608.9,88.12,608.91,88.11Z"
        ></path>
        <path
          className="cls-3"
          d="M586.27,85.72a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,586.27,85.72Z"
        ></path>
        <path
          className="cls-3"
          d="M563.57,84.45h0a0,0,0,0,1,0,0h.05S563.58,84.44,563.57,84.45Z"
        ></path>
        <path
          className="cls-3"
          d="M552.15,84.15a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1-.05,0S552.14,84.16,552.15,84.15Z"
        ></path>
        <path
          className="cls-3"
          d="M540.8,84.21a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0S540.81,84.2,540.8,84.21Z"
        ></path>
        <path
          className="cls-3"
          d="M518.05,85.05a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,518.05,85.05Z"
        ></path>
        <path
          className="cls-3"
          d="M506.69,85.87s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,506.69,85.87Z"
        ></path>
        <path
          className="cls-3"
          d="M495.35,87h0s0,0,0,0h0S495.37,87,495.35,87Z"
        ></path>
        <path
          className="cls-3"
          d="M450.37,94a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,.05,0S450.38,94,450.37,94Z"
        ></path>
        <path
          className="cls-3"
          d="M439.24,96.39a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,439.21,96.34Z"
        ></path>
        <path
          className="cls-3"
          d="M417.18,102a0,0,0,0,1-.05,0s0,0,0,0,0,0,0,0A0,0,0,0,1,417.18,102Z"
        ></path>
        <path
          className="cls-3"
          d="M406.21,105.16h.05s0,0,0,0h0S406.2,105.17,406.21,105.16Z"
        ></path>
        <path
          className="cls-3"
          d="M363.39,120.51a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0S363.38,120.49,363.39,120.51Z"
        ></path>
        <path
          className="cls-3"
          d="M352.91,125h0a0,0,0,0,1,0-.05,0,0,0,0,1,.05,0A.06.06,0,0,1,352.91,125Z"
        ></path>
        <path
          className="cls-3"
          d="M322.16,139.87s0,0-.05,0a.06.06,0,0,1,0,0s0,0,0,0A0,0,0,0,1,322.16,139.87Z"
        ></path>
        <path
          className="cls-3"
          d="M312.15,145.3a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05S312.16,145.29,312.15,145.3Z"
        ></path>
        <path
          className="cls-3"
          d="M302.27,151s0,0,0,0,0,0,0,0h0S302.29,151,302.27,151Z"
        ></path>
        <path
          className="cls-3"
          d="M292.53,156.86h0s0,0,0,0h0S292.55,156.85,292.53,156.86Z"
        ></path>
        <path
          className="cls-3"
          d="M282.94,163s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,282.94,163Z"
        ></path>
        <path
          className="cls-3"
          d="M273.49,169.34h-.05a.07.07,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,273.49,169.34Z"
        ></path>
        <path
          className="cls-3"
          d="M264.2,175.87a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0Z"
        ></path>
        <path
          className="cls-3"
          d="M255.05,182.71s0,0,0,0a0,0,0,0,1,0,0h0S255.07,182.7,255.05,182.71Z"
        ></path>
        <path
          className="cls-3"
          d="M246.07,189.71a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,246.07,189.71Z"
        ></path>
        <circle
          className="cls-3"
          cx="237.25"
          cy="196.9"
          r="0.03"
          transform="translate(-70.31 175.34) rotate(-35.68)"
        ></circle>
        <path
          className="cls-3"
          d="M228.63,204.35s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,228.63,204.35Z"
        ></path>
        <path
          className="cls-3"
          d="M220.17,212s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0A0,0,0,0,1,220.17,212Z"
        ></path>
        <path
          className="cls-3"
          d="M211.9,219.79s0,0-.05,0,0,0,0,0,0,0,0,0A0,0,0,0,1,211.9,219.79Z"
        ></path>
        <path
          className="cls-3"
          d="M188.21,244.39a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,.05,0S188.22,244.38,188.21,244.39Z"
        ></path>
        <path
          className="cls-3"
          d="M180.71,253s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0S180.72,253,180.71,253Z"
        ></path>
        <path
          className="cls-3"
          d="M173.41,261.7a0,0,0,0,1,0-.05,0,0,0,0,1,0,.05A0,0,0,0,1,173.41,261.7Z"
        ></path>
        <path
          className="cls-3"
          d="M159.45,279.69s0,0,0,0,0,0,0,0h.05A.07.07,0,0,1,159.45,279.69Z"
        ></path>
        <path
          className="cls-3"
          d="M152.79,288.92a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,.05,0S152.8,288.91,152.79,288.92Z"
        ></path>
        <path
          className="cls-3"
          d="M146.35,298.31s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S146.36,298.3,146.35,298.31Z"
        ></path>
        <path
          className="cls-3"
          d="M140.13,307.85a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,140.13,307.85Z"
        ></path>
        <path
          className="cls-3"
          d="M134.15,317.53s0,0-.05,0,0,0,0,0,0,0,.05,0S134.16,317.53,134.15,317.53Z"
        ></path>
        <path
          className="cls-3"
          d="M128.39,327.36s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,128.39,327.36Z"
        ></path>
        <path
          className="cls-3"
          d="M122.86,337.31h0a.1.1,0,0,1,0,0,0,0,0,0,1,.06,0S122.88,337.3,122.86,337.31Z"
        ></path>
        <path
          className="cls-3"
          d="M107.72,367.93s0,0,0,0,0,0,0,0h0A0,0,0,0,1,107.72,367.93Z"
        ></path>
        <path
          className="cls-3"
          d="M103.16,378.36h0a0,0,0,0,1,0,0s0,0,.06,0S103.17,378.35,103.16,378.36Z"
        ></path>
        <path
          className="cls-3"
          d="M98.85,388.89h0s0,0,0,0,0,0,0,0S98.86,388.89,98.85,388.89Z"
        ></path>
        <path
          className="cls-3"
          d="M91,410.26a0,0,0,1,1,0-.05h0A0,0,0,0,1,91,410.26Z"
        ></path>
        <path
          className="cls-3"
          d="M87.42,421.08a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,87.42,421.08Z"
        ></path>
        <path
          className="cls-3"
          d="M84.12,432h-.05s0,0,0,0,0,0,.05,0A0,0,0,0,1,84.12,432Z"
        ></path>
        <path
          className="cls-3"
          d="M81.07,443s0,0,0,0,0,0,0,0a0,0,0,1,1,0,.05Z"
        ></path>
        <path
          className="cls-3"
          d="M78.29,454s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,78.29,454Z"
        ></path>
        <path
          className="cls-3"
          d="M75.77,465.09a0,0,0,0,1,0,0s0,0,0,0,0,0,0,0A0,0,0,0,1,75.77,465.09Z"
        ></path>
        <path
          className="cls-3"
          d="M73.52,476.25h0a.07.07,0,0,1,0-.05s0,0,0,0A0,0,0,0,1,73.52,476.25Z"
        ></path>
        <path
          className="cls-3"
          d="M71.52,487.46a0,0,0,0,1,0,0,0,0,0,0,1,0,0h0A0,0,0,0,1,71.52,487.46Z"
        ></path>
        <path
          className="cls-3"
          d="M68.34,510s0,0,0,0,0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,68.34,510Z"
        ></path>
        <path
          className="cls-3"
          d="M67.14,521.33h0s0,0,0-.05h0A0,0,0,0,1,67.14,521.33Z"
        ></path>
        <path
          className="cls-3"
          d="M66.22,532.68s0,0,0,0,0,0,0,0,0,0,0,0A0,0,0,0,1,66.22,532.68Z"
        ></path>
        <path
          className="cls-3"
          d="M65.56,544.05s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,65.56,544.05Z"
        ></path>
        <path
          className="cls-3"
          d="M65.17,555.43s0,0,0,0a0,0,0,0,1,0,0s0,0,.05,0S65.18,555.42,65.17,555.43Z"
        ></path>
        <path
          className="cls-3"
          d="M65.05,566.81s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,65.05,566.81Z"
        ></path>
        <path
          className="cls-3"
          d="M65.61,589.58s0,0,0,0,0,0,0,0h0S65.63,589.57,65.61,589.58Z"
        ></path>
        <path
          className="cls-3"
          d="M66.3,600.94a0,0,0,0,1,0-.05s0,0,0,0S66.31,600.93,66.3,600.94Z"
        ></path>
        <path
          className="cls-3"
          d="M67.25,612.29h0s0,0,0,0,0,0,.05,0S67.27,612.28,67.25,612.29Z"
        ></path>
        <path
          className="cls-3"
          d="M71.72,646.15s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,71.72,646.15Z"
        ></path>
        <path
          className="cls-3"
          d="M73.74,657.35a0,0,0,1,1,0-.05s0,0,0,0A0,0,0,0,1,73.74,657.35Z"
        ></path>
        <path
          className="cls-3"
          d="M76,668.51a0,0,0,0,1,0,0,.06.06,0,0,1,0,0h0A0,0,0,0,1,76,668.51Z"
        ></path>
        <path
          className="cls-3"
          d="M78.57,679.6h0a0,0,0,0,1,0,0h0S78.59,679.59,78.57,679.6Z"
        ></path>
        <path
          className="cls-3"
          d="M81.38,690.64a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,81.35,690.59Z"
        ></path>
        <path
          className="cls-3"
          d="M84.45,701.6h0s0,0,0-.05,0,0,0,0S84.46,701.59,84.45,701.6Z"
        ></path>
        <path
          className="cls-3"
          d="M87.78,712.49a0,0,0,0,1,0,0,0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,87.78,712.49Z"
        ></path>
        <path
          className="cls-3"
          d="M95.2,734s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,95.2,734Z"
        ></path>
        <path
          className="cls-3"
          d="M99.29,744.65s0,0,0,0a0,0,0,0,1,0,0h0A0,0,0,0,1,99.29,744.65Z"
        ></path>
        <path
          className="cls-3"
          d="M103.63,755.17h0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,103.63,755.17Z"
        ></path>
        <path
          className="cls-3"
          d="M118.12,786.1s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0S118.13,786.09,118.12,786.1Z"
        ></path>
        <path
          className="cls-3"
          d="M123.43,796.17s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,123.43,796.17Z"
        ></path>
        <path
          className="cls-3"
          d="M129,806.11s0,0,0,0a0,0,0,0,1,0,0s0,0,.06,0S129,806.1,129,806.11Z"
        ></path>
        <path
          className="cls-3"
          d="M134.76,815.92a0,0,0,0,1,0,0s0,0,0,0a0,0,0,0,1,0,0A0,0,0,0,1,134.73,815.87Z"
        ></path>
        <path
          className="cls-3"
          d="M140.78,825.59s0,0,0,0a0,0,0,0,1,0,0s0,0,0,0A0,0,0,0,1,140.78,825.59Z"
        ></path>
        <path
          className="cls-3"
          d="M147,835.11s0,0-.05,0,0,0,0,0a0,0,0,0,1,.05,0A0,0,0,0,1,147,835.11Z"
        ></path>
        <path
          className="cls-3"
          d="M153.48,844.49s0,0,0,0a0,0,0,0,1,0,0,0,0,0,0,1,.05,0A0,0,0,0,1,153.48,844.49Z"
        ></path>
        <path
          className="cls-3"
          d="M160.16,853.7h0s0,0,0,0h0S160.17,853.7,160.16,853.7Z"
        ></path>
        <path
          className="cls-3"
          d="M167.06,862.76h0a0,0,0,0,1,0,0,0,0,0,0,1,0,0S167.07,862.75,167.06,862.76Z"
        ></path>
        <path
          className="cls-3"
          d="M174.17,871.66s0,0,0,0a0,0,0,0,1,0,0h.05A0,0,0,0,1,174.17,871.66Z"
        ></path>
        <path
          className="cls-3"
          d="M181.49,880.38s0,0,0,0a0,0,0,0,1,0,0s.05,0,.06,0S181.5,880.37,181.49,880.38Z"
        ></path>
        <path
          className="cls-3"
          d="M189,888.93s0,0,0,0a0,0,0,0,1,0,0H189A0,0,0,0,1,189,888.93Z"
        ></path>
        <path
          className="cls-3"
          d="M196.73,897.29h0s0,0,0,0h0S196.75,897.29,196.73,897.29Z"
        ></path>
        <path
          className="cls-3"
          d="M221.05,921.27a0,0,0,0,1,0,0,0,0,0,0,1,0,0,0,0,0,0,1,0,0A0,0,0,0,1,221,921.22Z"
        ></path>
        <path
          className="cls-3"
          d="M229.53,928.87h0s0,0,0,0,0,0,0,0S229.54,928.86,229.53,928.87Z"
        ></path>
        <path
          className="cls-3"
          d="M265.16,957.21h0s0,0,0-.05h0S265.17,957.2,265.16,957.21Z"
        ></path>
        <path
          className="cls-3"
          d="M274.47,963.76h0s0,0,0,0h0A0,0,0,0,1,274.47,963.76Z"
        ></path>
        <path
          className="cls-3"
          d="M293.55,976.19h0s0,0,0,0h0S293.57,976.18,293.55,976.19Z"
        ></path>
        <path
          className="cls-3"
          d="M303.31,982.07s0,0-.05,0,0,0,0,0,0,0,.05,0A0,0,0,0,1,303.31,982.07Z"
        ></path>
      </g>
      <polygon
        className="cls-3"
        points="541.61 312.01 580.16 279.81 541.5 249.19 541.61 312.01"
      ></polygon>
      <ellipse
        className="cls-3"
        cx="815.09"
        cy="719.13"
        rx="80.53"
        ry="80.47"
      ></ellipse>
      <ellipse
        className="cls-3"
        cx="381.93"
        cy="833.08"
        rx="80.53"
        ry="80.47"
      ></ellipse>
      <ellipse
        className="cls-3"
        cx="782.08"
        cy="372.28"
        rx="80.53"
        ry="80.47"
      ></ellipse>
      <ellipse
        className="cls-3"
        cx="305.88"
        cy="364.27"
        rx="80.53"
        ry="80.47"
      ></ellipse>
      <g>
        <path
          className="cls-3"
          d="M412.56,825.41a5.56,5.56,0,1,1-1.27,7.76A5.56,5.56,0,0,1,412.56,825.41Z"
        ></path>
        <path
          className="cls-3"
          d="M430.72,833.63a5.56,5.56,0,1,1-1.27,7.76A5.56,5.56,0,0,1,430.72,833.63Z"
        ></path>
        <path
          className="cls-3"
          d="M448.13,848.33a5.56,5.56,0,1,1,7.77,1.28A5.56,5.56,0,0,1,448.13,848.33Z"
        ></path>
        <path
          className="cls-3"
          d="M466.09,854.81a7,7,0,1,1,9.76,1.6A7,7,0,0,1,466.09,854.81Z"
        ></path>
        <path
          className="cls-3"
          d="M487.32,857.84a4.82,4.82,0,1,1,6.73,1.11A4.82,4.82,0,0,1,487.32,857.84Z"
        ></path>
        <path
          className="cls-3"
          d="M505.83,861.66a6.3,6.3,0,1,1,8.79,1.44A6.3,6.3,0,0,1,505.83,861.66Z"
        ></path>
        <path
          className="cls-3"
          d="M535.94,855.89a6.31,6.31,0,1,1-8.8-1.44A6.31,6.31,0,0,1,535.94,855.89Z"
        ></path>
        <path
          className="cls-3"
          d="M545.63,863.46a6.31,6.31,0,1,1,8.8,1.44A6.3,6.3,0,0,1,545.63,863.46Z"
        ></path>
        <path
          className="cls-3"
          d="M567.84,854.7a4.82,4.82,0,1,1-1.11,6.73A4.82,4.82,0,0,1,567.84,854.7Z"
        ></path>
        <path
          className="cls-3"
          d="M587.17,851.56a5.56,5.56,0,1,1-1.27,7.76A5.57,5.57,0,0,1,587.17,851.56Z"
        ></path>
        <path
          className="cls-3"
          d="M607.56,848.83a4.13,4.13,0,1,1-.95,5.77A4.12,4.12,0,0,1,607.56,848.83Z"
        ></path>
        <path
          className="cls-3"
          d="M624.68,850.2a5.56,5.56,0,1,1,7.77,1.27A5.56,5.56,0,0,1,624.68,850.2Z"
        ></path>
        <circle className="cls-3" cx="648.03" cy="840.41" r="5.56"></circle>
        <path
          className="cls-3"
          d="M663,835a4.13,4.13,0,1,1,5.77.94A4.14,4.14,0,0,1,663,835Z"
        ></path>
        <path
          className="cls-3"
          d="M688,820.69a4.82,4.82,0,1,1-6.72-1.1A4.82,4.82,0,0,1,688,820.69Z"
        ></path>
        <path
          className="cls-3"
          d="M697.27,816a4.82,4.82,0,1,1,6.73,1.11A4.82,4.82,0,0,1,697.27,816Z"
        ></path>
        <path
          className="cls-3"
          d="M713.6,804.6a4.82,4.82,0,1,1,6.73,1.1A4.83,4.83,0,0,1,713.6,804.6Z"
        ></path>
        <path
          className="cls-3"
          d="M729.66,791.67a4.13,4.13,0,1,1,5.77.94A4.13,4.13,0,0,1,729.66,791.67Z"
        ></path>
        <path
          className="cls-3"
          d="M744.26,778.1A4.13,4.13,0,1,1,750,779,4.14,4.14,0,0,1,744.26,778.1Z"
        ></path>
        <path
          className="cls-3"
          d="M764.49,765.66a5.56,5.56,0,1,1,1.27-7.76A5.56,5.56,0,0,1,764.49,765.66Z"
        ></path>
        <path
          className="cls-3"
          d="M777.19,743.28a4.13,4.13,0,1,1-5.77-.95A4.12,4.12,0,0,1,777.19,743.28Z"
        ></path>
        <path
          className="cls-3"
          d="M782,731.81a4.13,4.13,0,1,1,5.77,1A4.13,4.13,0,0,1,782,731.81Z"
        ></path>
        <path
          className="cls-3"
          d="M792.92,714.34a3.39,3.39,0,1,1,4.73.78A3.39,3.39,0,0,1,792.92,714.34Z"
        ></path>
        <path
          className="cls-3"
          d="M806.8,697.41a3.39,3.39,0,1,1,.77-4.73A3.39,3.39,0,0,1,806.8,697.41Z"
        ></path>
        <path
          className="cls-3"
          d="M827.46,642.25a4.82,4.82,0,1,1,1.1-6.72A4.83,4.83,0,0,1,827.46,642.25Z"
        ></path>
        <path
          className="cls-3"
          d="M831,622.16a4.13,4.13,0,1,1,.94-5.77A4.13,4.13,0,0,1,831,622.16Z"
        ></path>
        <path
          className="cls-3"
          d="M834,603a4.82,4.82,0,1,1,1.1-6.73A4.82,4.82,0,0,1,834,603Z"
        ></path>
        <path
          className="cls-3"
          d="M828.56,582a4.82,4.82,0,1,1,6.72,1.1A4.82,4.82,0,0,1,828.56,582Z"
        ></path>
        <path
          className="cls-3"
          d="M830.19,560.76a2.65,2.65,0,1,1,3.69.61A2.65,2.65,0,0,1,830.19,560.76Z"
        ></path>
        <path
          className="cls-3"
          d="M828.08,541.31a3.39,3.39,0,1,1,4.73.78A3.38,3.38,0,0,1,828.08,541.31Z"
        ></path>
        <path
          className="cls-3"
          d="M823.44,522.86a5.56,5.56,0,1,1,7.76,1.27A5.55,5.55,0,0,1,823.44,522.86Z"
        ></path>
        <path
          className="cls-3"
          d="M821,502.11a3.39,3.39,0,1,1,4.73.78A3.39,3.39,0,0,1,821,502.11Z"
        ></path>
        <path
          className="cls-3"
          d="M814.8,483.4a4.13,4.13,0,1,1,5.77,1A4.13,4.13,0,0,1,814.8,483.4Z"
        ></path>
        <path
          className="cls-3"
          d="M806.77,465.53a5.56,5.56,0,1,1,7.76,1.27A5.56,5.56,0,0,1,806.77,465.53Z"
        ></path>
        <path
          className="cls-3"
          d="M799.79,446.5a4.13,4.13,0,1,1,5.76.95A4.14,4.14,0,0,1,799.79,446.5Z"
        ></path>
        <path
          className="cls-3"
          d="M790.41,428.91a4.13,4.13,0,1,1,5.76.95A4.13,4.13,0,0,1,790.41,428.91Z"
        ></path>
        <path
          className="cls-3"
          d="M779.27,412.42a4.82,4.82,0,1,1,6.73,1.1A4.83,4.83,0,0,1,779.27,412.42Z"
        ></path>
        <path
          className="cls-3"
          d="M767.56,396.29a4.82,4.82,0,1,1,6.73,1.1A4.83,4.83,0,0,1,767.56,396.29Z"
        ></path>
        <path
          className="cls-3"
          d="M754.76,381a4.82,4.82,0,1,1,6.73,1.11A4.81,4.81,0,0,1,754.76,381Z"
        ></path>
        <path
          className="cls-3"
          d="M741.5,366.24a4.13,4.13,0,1,1,5.77.95A4.12,4.12,0,0,1,741.5,366.24Z"
        ></path>
        <path
          className="cls-3"
          d="M725.56,353.7a5.56,5.56,0,1,1,7.76,1.27A5.57,5.57,0,0,1,725.56,353.7Z"
        ></path>
        <path
          className="cls-3"
          d="M530.2,287.37a5.56,5.56,0,1,1,1.28-7.76A5.56,5.56,0,0,1,530.2,287.37Z"
        ></path>
        <path
          className="cls-3"
          d="M511,281.9a4.82,4.82,0,1,1-6.73-1.11A4.82,4.82,0,0,1,511,281.9Z"
        ></path>
        <path
          className="cls-3"
          d="M491.51,293.6a7,7,0,1,1,1.61-9.75A7,7,0,0,1,491.51,293.6Z"
        ></path>
        <path
          className="cls-3"
          d="M474.32,288a7.74,7.74,0,1,1-10.8-1.77A7.73,7.73,0,0,1,474.32,288Z"
        ></path>
        <path
          className="cls-3"
          d="M451.81,302.3a4.82,4.82,0,1,1,1.1-6.73A4.82,4.82,0,0,1,451.81,302.3Z"
        ></path>
        <path
          className="cls-3"
          d="M434.32,302.76a4.82,4.82,0,1,1-6.73-1.1A4.82,4.82,0,0,1,434.32,302.76Z"
        ></path>
        <path
          className="cls-3"
          d="M409.11,309.52a5.56,5.56,0,1,1-1.28,7.76A5.55,5.55,0,0,1,409.11,309.52Z"
        ></path>
        <path
          className="cls-3"
          d="M399.45,320.47a5.56,5.56,0,1,1-7.76-1.27A5.57,5.57,0,0,1,399.45,320.47Z"
        ></path>
        <path
          className="cls-3"
          d="M323.91,385.89a4.82,4.82,0,1,1-6.73-1.1A4.82,4.82,0,0,1,323.91,385.89Z"
        ></path>
        <path
          className="cls-3"
          d="M311.86,401.77a4.82,4.82,0,1,1-6.73-1.1A4.82,4.82,0,0,1,311.86,401.77Z"
        ></path>
        <path
          className="cls-3"
          d="M302.69,417.17a7,7,0,1,1-9.75-1.6A7,7,0,0,1,302.69,417.17Z"
        ></path>
        <path
          className="cls-3"
          d="M292.38,435a6.3,6.3,0,1,1-8.79-1.44A6.3,6.3,0,0,1,292.38,435Z"
        ></path>
        <path
          className="cls-3"
          d="M284.42,452.58a7,7,0,1,1-9.76-1.6A7,7,0,0,1,284.42,452.58Z"
        ></path>
        <path
          className="cls-3"
          d="M276,472a5.56,5.56,0,1,1-7.76-1.27A5.56,5.56,0,0,1,276,472Z"
        ></path>
        <circle className="cls-3" cx="265.5" cy="494.24" r="7.73"></circle>
        <circle className="cls-3" cx="260.86" cy="513.62" r="6.99"></circle>
        <path
          className="cls-3"
          d="M251.89,537.36a7,7,0,1,1,9.76,1.6A7,7,0,0,1,251.89,537.36Z"
        ></path>
        <path
          className="cls-3"
          d="M251.13,546.84a7.73,7.73,0,1,1-1.77,10.79A7.74,7.74,0,0,1,251.13,546.84Z"
        ></path>
        <path
          className="cls-3"
          d="M251.84,568.53a5.56,5.56,0,1,1-1.27,7.76A5.57,5.57,0,0,1,251.84,568.53Z"
        ></path>
        <path
          className="cls-3"
          d="M251.83,587.28a7,7,0,1,1-1.6,9.76A7,7,0,0,1,251.83,587.28Z"
        ></path>
        <path
          className="cls-3"
          d="M254,607.09a7,7,0,1,1-1.61,9.76A7,7,0,0,1,254,607.09Z"
        ></path>
        <path
          className="cls-3"
          d="M256,636.46a7,7,0,1,1,9.76,1.61A7,7,0,0,1,256,636.46Z"
        ></path>
        <path
          className="cls-3"
          d="M260.88,655.79a7,7,0,1,1,9.76,1.6A7,7,0,0,1,260.88,655.79Z"
        ></path>
        <path
          className="cls-3"
          d="M270,666.73a4.82,4.82,0,1,1-1.11,6.73A4.81,4.81,0,0,1,270,666.73Z"
        ></path>
        <path
          className="cls-3"
          d="M275.78,692.35a5.56,5.56,0,1,1,7.76,1.28A5.55,5.55,0,0,1,275.78,692.35Z"
        ></path>
        <path
          className="cls-3"
          d="M285.39,701.89a6.3,6.3,0,1,1-1.45,8.8A6.3,6.3,0,0,1,285.39,701.89Z"
        ></path>
        <path
          className="cls-3"
          d="M295.37,719.14a6.3,6.3,0,1,1-1.45,8.8A6.3,6.3,0,0,1,295.37,719.14Z"
        ></path>
        <path
          className="cls-3"
          d="M306.52,735.67a6.3,6.3,0,1,1-1.45,8.79A6.29,6.29,0,0,1,306.52,735.67Z"
        ></path>
        <path
          className="cls-3"
          d="M319.21,752a5.56,5.56,0,1,1-1.27,7.76A5.56,5.56,0,0,1,319.21,752Z"
        ></path>
        <path
          className="cls-3"
          d="M331.25,774.58a5.56,5.56,0,1,1,7.77,1.27A5.57,5.57,0,0,1,331.25,774.58Z"
        ></path>
        <path
          className="cls-3"
          d="M345.56,788.45a5.56,5.56,0,1,1,7.77,1.28A5.56,5.56,0,0,1,345.56,788.45Z"
        ></path>
        <path
          className="cls-3"
          d="M362.5,794.15a4.82,4.82,0,1,1-1.1,6.73A4.82,4.82,0,0,1,362.5,794.15Z"
        ></path>
        <path
          className="cls-3"
          d="M375.12,814.35a7.73,7.73,0,1,1,10.79,1.77A7.72,7.72,0,0,1,375.12,814.35Z"
        ></path>
        <circle className="cls-3" cx="398.26" cy="820.48" r="6.99"></circle>
      </g>
    </S.SVG>
  );
}
