import React from "react";
import * as S from "./styles";

export interface Card {
  title: string;
  content: React.ReactNode | string;
  image: string;
}

export interface CardHomeProps {
  card: Card;
}

export default function CardHome({ card }: CardHomeProps) {
  const { title, content, image } = card;

  return (
    <S.Wrapper>
      <div>
        <h1>{title}</h1>
        <p>{content}</p>
      </div>
      <img src={image} alt="" />
    </S.Wrapper>
  );
}
