import styled from "styled-components";
import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export const StyledDatePicker = styled(ReactDatePicker)`
  padding: 1rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid #d2d2d2;
  font-size: 1.5rem;
`;

export const Calendar = styled.div`
  width: 100%;
  border-radius: 0.4rem;
  box-shadow: 0 6px 12px rgba(27, 37, 86, 0.16);
  overflow: hidden;
  font-size: 1.3rem;
  border: 0.1rem solid #d2d2d2;

  > button:nth-child(1) {
    top: 1rem;
    left: 2rem;
  }
  > button:nth-child(2) {
    top: 1rem;
    right: 2rem;
  }

  .react-datepicker__month-container {
    .react-datepicker__header {
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 2rem 3rem;

      .react-datepicker__day-names {
        display: flex;
        flex-direction: row;
        gap: 1rem;
      }

      > div:nth-child(1) {
        font-size: 1.3rem;
      }

      > div:nth-child(2) {
        font-size: 1.3rem;
        display: flex;
        justify-content: space-between;
        > div {
          > select {
            border-radius: 0.4rem;
            border: 0.1rem solid #d2d2d2;
            padding: 0.3rem 0.3rem;
          }
        }
      }
    }

    .react-datepicker__month {
      padding: 2rem;
      .react-datepicker__week {
        > div {
          font-size: 1.4rem;
          margin: 0.5rem 0.6rem;
        }
      }
    }
  }
`;

export const Popper = styled.div`
  width: 100%;
  padding: 0.1rem;
  font-size: 1.3rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
`;
