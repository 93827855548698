import styled from "styled-components";

export const SVG = styled.svg`
  .circle-center-vision {
    fill: #fad62b;
    cursor: pointer;
  }

  .cls-1 {
    fill: #fad62b;
    cursor: default !important;
  }

  .cls-hover-vision {
    fill: #fad62b;
  }

  .step-1-vision,
  .step-2-vision,
  .step-3-vision,
  .step-4-vision,
  .step-5-vision {
    fill: #fad62b;
  }
`;
