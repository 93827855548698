import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Helmet from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import image17 from "assets/images/ge-in-numbers/017.svg";
import image18 from "assets/images/ge-in-numbers/018.svg";
import image19 from "assets/images/ge-in-numbers/019.svg";

import image23 from "assets/images/ge-in-numbers/023.svg";

import image83 from "assets/images/ge-in-numbers/083.png";

import boxEmpty from "assets/images/ge-in-numbers/box_empty.svg";

import PyramidChart from "../../components/PyramidChart";
import PercentageChart from "../../components/PercentageChart";

import Map from "../../components/Map";
import CircleChart from "../../components/CircleChart";
import GeneralFilter from "../../components/GeneralFilter";
import RegionFilter from "../../components/RegionFilter";

import { Chart as ChartJS, ArcElement, Tooltip } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import ComparativeCardCompetences1 from "pages/EdutecGuideInNumbers/components/ComparativeCardCompetences1";
import ComparativeCardCompetences2 from "pages/EdutecGuideInNumbers/components/ComparativeCardCompetences2";
import { useGEINNumbersFilter } from "hooks/use-ge-in-numbers-filter";
import { useStatesQuery } from "services/common";
import { number } from "utils/masks";
import Modal from "../../components/Modal";
import useClipboard from "react-hook-clipboard";
import { useMicrodataInfo } from "services/ge-in-numbers";
import { getStorageUpdateDate } from "utils/local-storage";
import useScrollToElement from "hooks/use-scroll-to-element";
import Banner from "pages/EdutecGuideInNumbers/components/Banner";
import ReactTooltip from "react-tooltip";

ChartJS.register(ArcElement, Tooltip);

const PARSE = {
  city: "MUNICIPAL",
  state: "ESTADUAL",
  others: "DEMAIS REDES",
};

const REGION = {
  allRegions: [
    "Amazonas",
    "Roraima",
    "Amapá",
    "Pará",
    "Tocantins",
    "Rondônia",
    "Acre",
    "Maranhão",
    "Piauí",
    "Ceará",
    "Rio Grande do Norte",
    "Pernambuco",
    "Paraíba",
    "Sergipe",
    "Alagoas",
    "Bahia",
    "Mato Grosso",
    "Mato Grosso do Sul",
    "Goiás",
    "São Paulo",
    "Rio de Janeiro",
    "Espírito Santo",
    "Minas Gerais",
    "Paraná",
    "Rio Grande do Sul",
    "Santa Catarina",
  ],
  north: [
    "Amazonas",
    "Roraima",
    "Amapá",
    "Pará",
    "Tocantins",
    "Rondônia",
    "Acre",
  ],
  northeast: [
    "Maranhão",
    "Piauí",
    "Ceará",
    "Rio Grande do Norte",
    "Pernambuco",
    "Paraíba",
    "Sergipe",
    "Alagoas",
    "Bahia",
  ],
  midwest: ["Mato Grosso", "Mato Grosso do Sul", "Goiás"],
  southeast: ["São Paulo", "Rio de Janeiro", "Espírito Santo", "Minas Gerais"],
  south: ["Paraná", "Rio Grande do Sul", "Santa Catarina"],
};

const PARSE_REGION = {
  north: "REGIÃO NORTE",
  northeast: "REGIÃO NORDESTE",
  midwest: "REGIÃO CENTRO-OESTE",
  southeast: "REGIÃO SUDESTE",
  south: "REGIÃO SUL",
  allRegions: "TODAS AS REGIÕES",
};

const MODAL_DIGITAL_COMPETENCE_BY_AREA = {
  EXPOSIÇÃO: (
    <p>
      Quando não há uso das tecnologias na prática pedagógica ou quando o (a)
      docente requer apoio de terceiros para utilizá-las. Ou ainda, quando o uso
      é apenas pessoal. Nesse nível, o (a) docente identifica as tecnologias
      como instrumento e não como parte da cultura digital.
    </p>
  ),
  FAMILIARIZAÇÃO: (
    <p>
      O(a) docente começa a conhecer e a usar pontualmente as tecnologias em
      suas atividades. Identifica e enxerga as tecnologias como apoio ao ensino.
      O uso de tecnologias está centrado no(a) docente.
    </p>
  ),
  ADAPTAÇÃO: (
    <p>
      As tecnologias são usadas periodicamente e podem estar integradas ao
      planejamento das atividades pedagógicas. O(a) docente identifica as
      tecnologias como recursos complementares para a melhoria dos processos de
      ensino e aprendizagem.
    </p>
  ),
  INTEGRAÇÃO: (
    <p>
      O uso das tecnologias é frequente no planejamento das atividades e na
      interação com os estudantes. O(a) docente trabalha com as tecnologias de
      forma integrada e contextualizada nos processos de ensino e aprendizagem.
    </p>
  ),
  TRANSFORMAÇÃO: (
    <p>
      Quando o(a) docente usa as tecnologias de forma inovadora, compartilha com
      os(as) colegas e realiza projetos colaborativos para além da escola,
      mostrando-se desenvolvido(a) quanto ao uso de tecnologias digitais e
      identificando-as como ferramenta de transformação social.
    </p>
  ),
};

const MODAL_COMPETENCES_LEVEL = {
  "PRÁTICA PEDAGÓGICA": (
    <p>
      Ser capaz de incorporar tecnologias digitais às estratégias de ensino, às
      experiências de aprendizagem dos/as estudantes e promover o engajamento.
    </p>
  ),
  PERSONALIZAÇÃO: (
    <p>
      Ser capaz de utilizar tecnologias digitais para criar experiências de
      aprendizagem que atendam às necessidades de cada estudante
    </p>
  ),
  AVALIAÇÃO: (
    <p>
      Ser capaz de usar as tecnologias digitais no planejamento e na realização
      de processos de avaliação da aprendizagem dos/as estudantes
    </p>
  ),
  "CURADORIA E CRIAÇÃO": (
    <p>
      Ser capaz de selecionar e criar recursos digitais que contribuam para os
      processo de ensino, aprendizagem e gestão de sala de aula
    </p>
  ),
  "USO RESPONSÁVEL": (
    <p>Ser capaz de fazer e promover o uso ético das tecnologias digitais</p>
  ),
  "USO CRÍTICO": (
    <p>
      Ser capaz de fazer e promover a interpretação crítica das informações
      disponíveis em ambientes digitais, de modo a garantir a avaliação da
      credibilidade e confiabilidade das fontes de pesquisa.
    </p>
  ),
  "USO SEGURO": (
    <p>
      Ser capaz de fazer e promover o uso seguro das tecnologias, estratégias e
      ferramentas de proteção de dados e da privacidade.
    </p>
  ),
  INCLUSÃO: (
    <p>
      Ser capaz de utilizar as tecnologias digitais para promover inclusão e
      equidade educativa
    </p>
  ),
  AUTODESENVOLVIMENTO: (
    <p>
      Ser capaz de usar fontes e recursos digitais nas atividades de formação
      continuada visando contribuir com o desenvolvimento profissional contínuo.
    </p>
  ),
  AUTOAVALIAÇÃO: (
    <p>
      Ser capaz de avaliar sua prática docente por meio de tecnologias digitais
      e implementar as ações para melhorias do ensino.
    </p>
  ),
  COMPARTILHAMENTO: (
    <p>
      Ser capaz de promover e participar de comunidades de aprendizagem online
      com o objetivo de colaborar e partilhar experiências e conhecimentos com
      outros educadores.
    </p>
  ),
  COMUNICAÇÃO: (
    <p>
      Ser capaz de utilizar tecnologias digitais para a comunicação ativa,
      sistemática e eficiente com os atores da comunidade escolar
    </p>
  ),
};

export default function GENTeachersSelfAssessment() {
  const {
    networkTypeSelected,
    dataFiltered,
    dataFilteredComparativeRede,
    dataFilteredComparativeNacional,
    dataFilteredComparativeEstado,
    stateIdMemo,
    cityIdMemo,
    stateNameMemo,
    comparative1,
    comparative2,
    setComparative1,
    setComparative2,
    showGeneralFilterModal,
    setShowGeneralFilterModal,
  } = useGEINNumbersFilter();

  const { data: microdataInfo, isSuccess: microdataInfoSuccess } =
    useMicrodataInfo("personal");

  const teachersData = dataFiltered?.teachers;

  const update_at = new Date(getStorageUpdateDate());

  const { data: states, isSuccess: isSuccessStates } = useStatesQuery();

  const [clipboard, copyToClipboard] = useClipboard();
  const [sectionId, setSectionId] = useState("");
  const [openClipboardModal, setOpenClipboardModal] = useState(false);

  const [openDigitalCompetenceByArea, setOpenDigitalCompetenceByArea] =
    useState(false);

  const [digitalCompetenceByAreaContent, setDigitalCompetenceByAreaContent] =
    useState("");

  const [openInfoBox, setOpenInfoBox] = useState(false);

  const [openCompetencesLevel, setOpenCompetencesLevel] = useState(false);

  const [competencesLevelContent, setCompetencesLevelContent] = useState("");

  const [version, setVersion] = useState(1);

  const { goToElement } = useScrollToElement();

  useEffect(() => {
    if (openInfoBox) {
      goToElement("infobox");
    }
  }, [openInfoBox, goToElement]);

  useEffect(() => {
    if (microdataInfoSuccess) {
      setVersion(microdataInfo[microdataInfo.length - 1].version);
    }
  }, [microdataInfo, microdataInfoSuccess]);

  useEffect(() => {
    copyToClipboard(
      `https://plataforma.guiaedutec.com.br/ge-em-numeros/autoavaliacao-professores#${sectionId}`
    );
  }, [sectionId, copyToClipboard]);

  useEffect(() => {
    if (["allNetworks"].includes(networkTypeSelected)) {
      setComparative1(false);
      setComparative2(false);
    }
  }, [networkTypeSelected, setComparative1, setComparative2]);

  useEffect(() => {
    if (!stateIdMemo) {
      setComparative1(false);
      setComparative2(false);
    }
  }, [setComparative1, setComparative2, stateIdMemo, cityIdMemo]);

  const getAcronym = useCallback(
    (key) => {
      if (isSuccessStates) {
        return states?.find((state) => state.name === key)?.acronym;
      }
    },
    [states, isSuccessStates]
  );

  const progressBarData = useMemo(() => {
    if (teachersData) {
      return Object.entries(teachersData?.teachers_answers?.network)?.map(
        ([key, value]) => {
          return { type: PARSE[key], value: value?.percent };
        }
      );
    } else {
      return [];
    }
  }, [teachersData]);

  const [regionViewType, setRegionViewType] = useState("listView");

  const [regionSelected, setRegionSelected] = useState("allRegions");

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 920px)" });

  useEffect(() => {
    if (isTabletOrMobile) {
      setRegionViewType("gridView");
    }
  }, [isTabletOrMobile]);

  useEffect(() => {
    if (digitalCompetenceByAreaContent) {
      setOpenDigitalCompetenceByArea(true);
    }
  }, [digitalCompetenceByAreaContent]);

  useEffect(() => {
    if (competencesLevelContent) {
      setOpenCompetencesLevel(true);
    }
  }, [competencesLevelContent]);

  useEffect(() => {
    if (competencesLevelContent) {
      goToElement("innerModal");
    }
  }, [goToElement, competencesLevelContent, openCompetencesLevel]);

  useEffect(() => {
    setShowGeneralFilterModal(false);
  }, [setShowGeneralFilterModal]);

  const thereIsNoData = teachersData?.teachers_answers?.total_amount === 0;

  return (
    <Layout hasNavigationBar={!isTabletOrMobile} name="geInNumbers">
      <Helmet title="Guia EduTec em Números" />
      <S.Wrapper>
        <Banner
          update_at={update_at}
          tool={
            <p>
              Autoavaliação de Competências
              <br /> Digitais dos Professores (as)
            </p>
          }
        />
        {!thereIsNoData ? (
          <S.Content comparative2={comparative2}>
            <GeneralFilter display={isTabletOrMobile && "none"} boxShadow />
            <section>
              <div id="gen-autoavaliacao-1">
                <header>
                  <S.SectionTitle>
                    <div
                      onClick={() => {
                        setOpenClipboardModal(true);
                        setSectionId("gen-autoavaliacao-1");
                      }}
                    >
                      <S.StyledShareIcon />
                    </div>
                  </S.SectionTitle>
                </header>
                <main>
                  <div>
                    <S.StyledGroupsIcon />

                    <span>
                      {number.resolve(
                        String(teachersData?.teachers_answers?.total_amount)
                      )}
                    </span>
                  </div>
                  <div>
                    <p>
                      PROFESSORES (AS) RESPONDERAM À AUTOAVALIAÇÃO DE
                      COMPETÊNCIAS DIGITAIS{" "}
                      {openInfoBox ? (
                        <div>
                          <S.StyledRemoveIcon
                            onClick={() => {
                              setOpenInfoBox(false);
                            }}
                          />
                        </div>
                      ) : (
                        <div>
                          <S.StyledAddIcon
                            onClick={() => {
                              setOpenInfoBox(true);
                            }}
                          />
                        </div>
                      )}
                    </p>
                  </div>
                </main>
              </div>
              <div id="gen-autoavaliacao-2">
                <header>
                  <S.SectionTitle>
                    <div
                      onClick={() => {
                        setOpenClipboardModal(true);
                        setSectionId("gen-autoavaliacao-2");
                      }}
                    >
                      <S.StyledShareIcon />
                    </div>
                    <h1>ONDE ATUAM?</h1>
                  </S.SectionTitle>
                </header>
                <main>
                  <div>
                    <p>REDE ESTADUAL</p>
                    <span>
                      {number.resolve(
                        String(
                          teachersData?.teachers_answers?.network?.state?.amount
                        )
                      )}
                    </span>
                  </div>
                  <div>
                    <p>REDE MUNICIPAL</p>
                    <span>
                      {number.resolve(
                        String(
                          teachersData?.teachers_answers?.network?.city?.amount
                        )
                      )}
                    </span>
                  </div>
                  <div>
                    <p>DEMAIS REDES</p>
                    <span>
                      {number.resolve(
                        String(
                          teachersData?.teachers_answers?.network?.others
                            ?.amount
                        )
                      )}
                    </span>
                  </div>
                </main>
              </div>
              {networkTypeSelected === "allNetworks" && (
                <div id="gen-autoavaliacao-3">
                  <header>
                    <S.SectionTitle>
                      <div
                        onClick={() => {
                          setOpenClipboardModal(true);
                          setSectionId("gen-autoavaliacao-3");
                        }}
                      >
                        <S.StyledShareIcon />
                      </div>
                      <h1>O QUE CORRESPONDE À</h1>
                    </S.SectionTitle>
                  </header>
                  <main>
                    {progressBarData?.map((item, index) => (
                      <S.ProgressBar
                        key={`${item?.type}-${index}`}
                        item={index}
                      >
                        <S.StyledGroupsIcon item={index} />
                        <div>
                          <p>
                            <span>{item?.value}%</span>{" "}
                            {index === 2 ? "do total das" : "do total da rede"}{" "}
                            {item?.type}
                          </p>
                          <progress value={item?.value} max="100" />
                        </div>
                      </S.ProgressBar>
                    ))}
                  </main>
                </div>
              )}
            </section>
            <section style={{ display: !openInfoBox && "none" }} id="infobox">
              <header>
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenInfoBox(false);
                    }}
                  >
                    <S.StyledCloseIcon />
                  </div>
                  <h1>
                    DADOS DA AUTOAVALIAÇÃO DE COMPETÊNCIAS DIGITAIS DOS
                    PROFESSORES(AS)
                  </h1>
                </S.SectionTitle>
              </header>
              <main>
                <p>
                  O nível de desenvolvimento das competências digitais de
                  professores(as) é uma metodologia desenvolvida pelo CIEB para
                  que professores(as) identifiquem em qual nível se encontram na
                  desenvolvimento de suas competências digitais e como podem se
                  desenvolver (veja aqui a{" "}
                  <a
                    href="https://cieb.net.br/wp-content/uploads/2020/07/CIEB_NotaTecnica15_junho_-2019.pdf"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Nota Técnica #15 Autoavaliação de Competências Digitais de
                    Professores
                  </a>
                  ). A ferramenta utilizada para fazer esta avaliação é a
                  Autoavaliação de Competências Digitais de Professores(as) do
                  portal Guia Edutec, disponível "online" e gratuitamente para
                  profissionais da educação do Brasil e do mundo. A ferramenta
                  de Autoavaliação é respondida voluntariamente pelos(as)
                  professores(as) que se cadastram na plataforma e consiste em
                  um conjunto de 23 perguntas de múltipla escolha que abordam 12
                  competências digitais em três áreas (pedagógica, cidadania
                  digital, e desenvolvimento profissional). Os dados abaixo
                  apresentam os resultados agregados de todos(as) os(as)
                  professores(as) que responderam à Autoavaliação do portal Guia
                  Edutec (informações autodeclaratórias), desde sua criação em
                  2018 - cada professor(a) é contabilizado individualmente com
                  sua resposta mais recente (mesmo que já tenha respondido
                  outras vezes).
                </p>
              </main>
            </section>
            <section id="gen-autoavaliacao-4">
              {/* MODAL */}
              {openDigitalCompetenceByArea && (
                <S.InnerModal>
                  <div>
                    <div
                      onClick={() => {
                        setOpenDigitalCompetenceByArea(false);
                        setDigitalCompetenceByAreaContent("");
                      }}
                    >
                      <span>&times;</span>
                    </div>
                    <div>
                      <h1>{digitalCompetenceByAreaContent}</h1>
                      <p>
                        {
                          MODAL_DIGITAL_COMPETENCE_BY_AREA[
                            digitalCompetenceByAreaContent
                          ]
                        }
                      </p>
                    </div>
                  </div>
                </S.InnerModal>
              )}
              <header>
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenClipboardModal(true);
                      setSectionId("gen-autoavaliacao-4");
                    }}
                  >
                    <S.StyledShareIcon />
                  </div>
                  <div>
                    <h1>
                      NÍVEL MÉDIO DE COMPETÊNCIAS DIGITAIS DOS (AS) PROFESSORES
                      (AS) POR ÁREA
                    </h1>
                    {["state", "city"].includes(networkTypeSelected) &&
                      stateIdMemo?.value && (
                        <div>
                          <input
                            type="checkbox"
                            onChange={() => setComparative1(!comparative1)}
                          />
                          <p>COMPARE OS DADOS</p>
                        </div>
                      )}
                  </div>
                </S.SectionTitle>
              </header>
              {!comparative1 ? (
                <main>
                  <div>
                    <S.Competence item={0}>
                      <div>
                        <img src={image17} alt="" />
                        <span>PEDAGÓGICA</span>
                        <div>
                          <p>NÍVEL</p>
                          <span>
                            {
                              teachersData?.digital_competence_by_area
                                .pedagogical
                            }
                          </span>
                        </div>
                      </div>
                      <img
                        src={require(`assets/images/ge-in-numbers/velocimeter/1_${
                          teachersData?.digital_competence_by_area
                            .pedagogical || 1
                        }.svg`)}
                        alt=""
                      />
                    </S.Competence>
                    <S.Competence item={1}>
                      <div>
                        <img src={image18} alt="" />
                        <span>CIDADANIA DIGITAL</span>
                        <div>
                          <p>NÍVEL</p>
                          <span>
                            {
                              teachersData?.digital_competence_by_area
                                .digital_citizenship
                            }
                          </span>
                        </div>
                      </div>
                      <img
                        src={require(`assets/images/ge-in-numbers/velocimeter/2_${
                          teachersData?.digital_competence_by_area
                            .digital_citizenship || 1
                        }.svg`)}
                        alt=""
                      />
                    </S.Competence>
                    <S.Competence item={2}>
                      <div>
                        <img src={image19} alt="" />
                        <span>DESENVOLVIMENTO PROFISSIONAL</span>
                        <div>
                          <p>NÍVEL</p>
                          <span>
                            {
                              teachersData?.digital_competence_by_area
                                .professional_development
                            }
                          </span>
                        </div>
                      </div>
                      <img
                        src={require(`assets/images/ge-in-numbers/velocimeter/3_${
                          teachersData?.digital_competence_by_area
                            .professional_development || 1
                        }.svg`)}
                        alt=""
                      />
                    </S.Competence>
                  </div>
                  <div>
                    <table>
                      <tr>
                        <th>NÍVEL</th>
                        <th>COMPETÊNCIA DIGITAL</th>
                      </tr>
                      <tr>
                        <td>1</td>
                        <td>
                          <div>
                            <div
                              onClick={() => {
                                setDigitalCompetenceByAreaContent("EXPOSIÇÃO");
                              }}
                            >
                              ?
                            </div>
                            <p>Exposição</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td>
                          <div>
                            <div
                              onClick={() => {
                                setDigitalCompetenceByAreaContent(
                                  "FAMILIARIZAÇÃO"
                                );
                              }}
                            >
                              ?
                            </div>
                            <p>Familiarização</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>3</td>
                        <td>
                          <div>
                            <div
                              onClick={() => {
                                setDigitalCompetenceByAreaContent("ADAPTAÇÃO");
                              }}
                            >
                              ?
                            </div>
                            <p>Adaptação</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td>
                          <div>
                            <div
                              onClick={() => {
                                setDigitalCompetenceByAreaContent("INTEGRAÇÃO");
                              }}
                            >
                              ?
                            </div>
                            <p>Integração</p>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td>
                          <div>
                            <div
                              onClick={() => {
                                setDigitalCompetenceByAreaContent(
                                  "TRANSFORMAÇÃO"
                                );
                              }}
                            >
                              ?
                            </div>
                            <p>Transformação</p>
                          </div>
                        </td>
                      </tr>
                    </table>
                  </div>
                </main>
              ) : (
                <article>
                  <div>
                    <S.Competence item={0}>
                      <div>
                        <img src={image17} alt="" />
                        <span>PEDAGÓGICA</span>
                      </div>
                    </S.Competence>
                    <div>
                      <ComparativeCardCompetences1
                        local="rede"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.digital_competence_by_area?.pedagogical
                            : null
                        }
                        competence={1}
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences1
                          local={stateNameMemo}
                          type={networkTypeSelected}
                          level={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.digital_competence_by_area?.pedagogical
                              : null
                          }
                          competence={1}
                        />
                      )}
                      <ComparativeCardCompetences1
                        local="brasil"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.digital_competence_by_area?.pedagogical
                            : null
                        }
                        competence={1}
                      />
                    </div>

                    <S.Competence item={1}>
                      <div>
                        <img src={image18} alt="" />
                        <span>CIDADANIA DIGITAL</span>
                      </div>
                    </S.Competence>

                    <div>
                      <ComparativeCardCompetences1
                        local="rede"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.digital_competence_by_area
                                ?.digital_citizenship
                            : null
                        }
                        competence={2}
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences1
                          local={stateNameMemo}
                          type={networkTypeSelected}
                          level={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.digital_competence_by_area
                                  ?.digital_citizenship
                              : null
                          }
                          competence={2}
                        />
                      )}
                      <ComparativeCardCompetences1
                        local="brasil"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.digital_competence_by_area
                                ?.digital_citizenship
                            : null
                        }
                        competence={2}
                      />
                    </div>

                    <S.Competence item={2}>
                      <div>
                        <img src={image19} alt="" />
                        <span>DESENVOLVIMENTO PROFISSIONAL</span>
                      </div>
                    </S.Competence>

                    <div>
                      <ComparativeCardCompetences1
                        local="rede"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.digital_competence_by_area
                                ?.professional_development
                            : null
                        }
                        competence={3}
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences1
                          local={stateNameMemo}
                          type={networkTypeSelected}
                          level={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.digital_competence_by_area
                                  ?.professional_development
                              : null
                          }
                          competence={3}
                        />
                      )}
                      <ComparativeCardCompetences1
                        local="brasil"
                        type={networkTypeSelected}
                        level={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.digital_competence_by_area
                                ?.professional_development
                            : null
                        }
                        competence={3}
                      />
                    </div>
                  </div>
                </article>
              )}
            </section>
            <section>
              <div id="gen-autoavaliacao-5">
                {openCompetencesLevel && (
                  <S.InnerModal id="innerModal">
                    <div>
                      <div
                        onClick={() => {
                          setCompetencesLevelContent("");
                          setOpenCompetencesLevel(false);
                        }}
                      >
                        <span>&times;</span>
                      </div>
                      <div>
                        <h1> {competencesLevelContent}</h1>
                        <p>
                          {MODAL_COMPETENCES_LEVEL[competencesLevelContent]}
                        </p>
                      </div>
                    </div>
                  </S.InnerModal>
                )}
                <header>
                  <S.SectionTitle>
                    <div
                      onClick={() => {
                        setOpenClipboardModal(true);
                        setSectionId("gen-autoavaliacao-5");
                      }}
                    >
                      <S.StyledShareIcon />
                    </div>
                    <div>
                      <h1>
                        NÍVEL MÉDIO ALCANÇADO PELA MAIOR PARTE DOS (AS)
                        PROFESSORES (AS) EM CADA UMA DAS COMPETÊNCIAS
                      </h1>
                      {["state", "city"].includes(networkTypeSelected) &&
                        stateIdMemo?.value && (
                          <div>
                            <input
                              type="checkbox"
                              onChange={() => setComparative2(!comparative2)}
                            />
                            <p>COMPARE OS DADOS</p>
                          </div>
                        )}
                    </div>
                  </S.SectionTitle>
                </header>

                <S.CompetencesLevels item={0}>
                  <h1>PEDAGÓGICA</h1>
                  {!comparative2 ? (
                    <>
                      <div>
                        <span>
                          PRÁTICA PEDAGÓGICA{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("PRÁTICA PEDAGÓGICA");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={1}
                          value={
                            teachersData?.competences_level
                              ? teachersData?.competences_level.pedagogical
                                  .pedagogical_practice
                              : {}
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          PERSONALIZAÇÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("PERSONALIZAÇÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={1}
                          value={
                            teachersData?.competences_level
                              ? teachersData?.competences_level.pedagogical
                                  .customization
                              : {}
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value} item={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          AVALIAÇÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("AVALIAÇÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={1}
                          value={
                            teachersData?.competences_level
                              ? teachersData?.competences_level.pedagogical
                                  .evaluation
                              : {}
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value} item={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          CURADORIA E CRIAÇÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("CURADORIA E CRIAÇÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={1}
                          value={
                            teachersData?.competences_level
                              ? teachersData?.competences_level.pedagogical
                                  .curatory_and_creation
                              : {}
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value} item={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                    </>
                  ) : (
                    <article>
                      <ComparativeCardCompetences2
                        local="rede"
                        type={PARSE[networkTypeSelected]}
                        competence={1}
                        values={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.competences_level?.pedagogical
                            : {}
                        }
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences2
                          local={stateNameMemo}
                          type={PARSE[networkTypeSelected]}
                          competence={1}
                          values={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.competences_level?.pedagogical
                              : {}
                          }
                        />
                      )}

                      <ComparativeCardCompetences2
                        local="brasil"
                        type={PARSE[networkTypeSelected]}
                        competence={1}
                        values={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.competences_level?.pedagogical
                            : {}
                        }
                      />
                    </article>
                  )}
                </S.CompetencesLevels>
                <S.CompetencesLevels item={1}>
                  <h1>CIDADANIA DIGITAL</h1>
                  {!comparative2 ? (
                    <>
                      <div>
                        <span>
                          USO RESPONSÁVEL{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("USO RESPONSÁVEL");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={2}
                          value={
                            teachersData?.competences_level.digital_citizenship
                              .responsible_use
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          USO CRÍTICO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("USO CRÍTICO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={2}
                          value={
                            teachersData?.competences_level.digital_citizenship
                              .critical_use
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          USO SEGURO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("USO SEGURO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={2}
                          value={
                            teachersData?.competences_level.digital_citizenship
                              .safe_use
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          INCLUSÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("INCLUSÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={2}
                          value={
                            teachersData?.competences_level.digital_citizenship
                              .inclusion
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                    </>
                  ) : (
                    <article>
                      <ComparativeCardCompetences2
                        local="rede"
                        type={PARSE[networkTypeSelected]}
                        competence={2}
                        values={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.competences_level?.digital_citizenship
                            : {}
                        }
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences2
                          local={stateNameMemo}
                          type={PARSE[networkTypeSelected]}
                          competence={2}
                          values={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.competences_level?.digital_citizenship
                              : {}
                          }
                        />
                      )}
                      <ComparativeCardCompetences2
                        local="brasil"
                        type={PARSE[networkTypeSelected]}
                        competence={2}
                        values={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.competences_level?.digital_citizenship
                            : {}
                        }
                      />
                    </article>
                  )}
                </S.CompetencesLevels>
                <S.CompetencesLevels item={2}>
                  <h1>DESENVOLVIMENTO PROFISSIONAL</h1>
                  {!comparative2 ? (
                    <>
                      <div>
                        <span>
                          AUTODESENVOLVIMENTO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("AUTODESENVOLVIMENTO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={3}
                          value={
                            teachersData?.competences_level
                              .professional_development.self_development
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          AUTOAVALIAÇÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("AUTOAVALIAÇÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={3}
                          value={
                            teachersData?.competences_level
                              .professional_development.self_evaluation
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          COMPARTILHAMENTO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("COMPARTILHAMENTO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={3}
                          value={
                            teachersData?.competences_level
                              .professional_development.sharing
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                      <div>
                        <span>
                          COMUNICAÇÃO{" "}
                          <div
                            onClick={() => {
                              setCompetencesLevelContent("COMUNICAÇÃO");
                            }}
                          >
                            ?
                          </div>
                        </span>
                        <S.CompetenceLevel
                          competence={3}
                          value={
                            teachersData?.competences_level
                              .professional_development.communication
                          }
                        >
                          {[...new Array(5).keys()].map((value) => (
                            <li key={value}>
                              <p>{value + 1}</p>
                              <span />
                            </li>
                          ))}
                        </S.CompetenceLevel>
                      </div>
                    </>
                  ) : (
                    <article>
                      <ComparativeCardCompetences2
                        local="rede"
                        type={PARSE[networkTypeSelected]}
                        competence={3}
                        values={
                          dataFilteredComparativeRede
                            ? dataFilteredComparativeRede?.teachers
                                ?.competences_level?.professional_development
                            : {}
                        }
                      />

                      {networkTypeSelected === "city" && (
                        <ComparativeCardCompetences2
                          local={stateNameMemo}
                          type={PARSE[networkTypeSelected]}
                          competence={3}
                          values={
                            dataFilteredComparativeEstado
                              ? dataFilteredComparativeEstado?.teachers
                                  ?.competences_level?.professional_development
                              : {}
                          }
                        />
                      )}

                      <ComparativeCardCompetences2
                        local="brasil"
                        type={PARSE[networkTypeSelected]}
                        competence={3}
                        values={
                          dataFilteredComparativeNacional
                            ? dataFilteredComparativeNacional?.teachers
                                ?.competences_level?.professional_development
                            : {}
                        }
                      />
                    </article>
                  )}
                </S.CompetencesLevels>
              </div>
              <div>
                <div id="gen-autoavaliacao-6">
                  <header>
                    <S.SectionTitle>
                      <div
                        onClick={() => {
                          setOpenClipboardModal(true);
                          setSectionId("gen-autoavaliacao-6");
                        }}
                      >
                        <S.StyledShareIcon />
                      </div>
                      <span>Perfil dos(as) professores(as)</span>
                      <h1>DISTRIBUIÇÃO DOS (AS) PROFESSORES (AS) POR IDADE</h1>
                    </S.SectionTitle>
                  </header>

                  <PyramidChart
                    data={{
                      titleLeft: "ESTADUAL",
                      titleCenter: "FAIXA ETÁRIA",
                      titleRight: "MUNICIPAL",
                      left: teachersData?.teacher_distribuition_by_age
                        ? Object.values(
                            teachersData?.teacher_distribuition_by_age?.network
                              ?.state
                          )
                        : [],
                      center: [
                        "60 ou +",
                        "50 - 59",
                        "40 - 49",
                        "30 - 39",
                        "25 - 29",
                        "até 25",
                      ],
                      right: teachersData?.teacher_distribuition_by_age
                        ? Object.values(
                            teachersData?.teacher_distribuition_by_age?.network
                              ?.city
                          )
                        : [],
                    }}
                  />
                </div>
                <div id="gen-autoavaliacao-7">
                  <header>
                    <S.SectionTitle>
                      <div
                        onClick={() => {
                          setOpenClipboardModal(true);
                          setSectionId("gen-autoavaliacao-7");
                        }}
                      >
                        <S.StyledShareIcon />
                      </div>
                      <span>Perfil dos(as) professores(as)</span>
                      <h1>GÊNERO</h1>
                    </S.SectionTitle>
                  </header>
                  <div>
                    <PercentageChart
                      label="FEMININO"
                      amount={teachersData?.gender.female.amount}
                      progress={teachersData?.gender.female.percent}
                    />
                    <PercentageChart
                      label="MASCULINO"
                      amount={teachersData?.gender.male.amount}
                      progress={teachersData?.gender.male.percent}
                    />
                    <PercentageChart
                      label="OUTROS"
                      amount={teachersData?.gender.others.amount}
                      progress={teachersData?.gender.others.percent}
                    />
                    <PercentageChart
                      label="PREFERE NÃO DIZER"
                      amount={teachersData?.gender.prefer_not_to_say.amount}
                      progress={teachersData?.gender.prefer_not_to_say.percent}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section id="gen-autoavaliacao-8">
              <S.SectionTitle>
                <div
                  onClick={() => {
                    setOpenClipboardModal(true);
                    setSectionId("gen-autoavaliacao-8");
                  }}
                >
                  <S.StyledShareIcon />
                </div>
                <span>Perfil dos(as) professores(as)</span>
                <h1>NÍVEL DE FORMAÇÃO DOS (AS) PROFESSORES (AS)</h1>
              </S.SectionTitle>

              <PyramidChart
                data={{
                  titleLeft: "ESTADUAL",
                  titleCenter: "FORMAÇÃO",
                  titleRight: "MUNICIPAL",
                  left: teachersData?.formation_level
                    ? Object.values(teachersData?.formation_level.network.state)
                    : [],
                  center: [
                    "Médio ou inferior",
                    "Médio (normal/magistério)",
                    "Superior Completo Bacharelado",
                    "Superior Completo Licenciatura",
                    "Especialização",
                    "Mestrado",
                    "Doutorado",
                  ],
                  right: teachersData?.formation_level
                    ? Object.values(teachersData?.formation_level.network.city)
                    : [],
                }}
              />
            </section>
            <section>
              <div id="gen-autoavaliacao-9">
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenClipboardModal(true);
                      setSectionId("gen-autoavaliacao-9");
                    }}
                  >
                    <S.StyledShareIcon />
                  </div>
                  <span>Perfil dos(as) professores(as)</span>
                  <h1>
                    DISTRIBUIÇÃO DOS (AS) PROFESSORES (AS) POR ETAPA DE ENSINO
                  </h1>
                </S.SectionTitle>

                <PyramidChart
                  data={{
                    titleLeft: "ESTADUAL",
                    titleCenter: "ETAPA DE ENSINO",
                    titleRight: "MUNICIPAL",
                    left: teachersData?.teacher_distribuition_by_teaching_stage
                      ? Object.values(
                          teachersData?.teacher_distribuition_by_teaching_stage
                            .network.state
                        )
                      : [],
                    center: [
                      "Educação Infantil",
                      "E.F. Anos Iniciais",
                      "E.F. Anos Finais",
                      "Ensino Médio",
                      "Ensino Técnico",
                      "Ensino Superior",
                      "EJA",
                    ],
                    right: teachersData?.teacher_distribuition_by_teaching_stage
                      ? Object.values(
                          teachersData?.teacher_distribuition_by_teaching_stage
                            .network.city
                        )
                      : [],
                  }}
                />
              </div>
              {teachersData?.course_modality &&
                Object.values(teachersData?.course_modality).some(
                  (item) => item !== 0
                ) && (
                  <div id="gen-autoavaliacao-10">
                    <S.SectionTitle>
                      <div
                        onClick={() => {
                          setOpenClipboardModal(true);
                          setSectionId("gen-autoavaliacao-10");
                        }}
                      >
                        <S.StyledShareIcon />
                      </div>
                      <span>Perfil dos(as) professores(as)</span>
                      <h1>MODALIDADE DO CURSO DE FORMAÇÃO INICIAL</h1>
                    </S.SectionTitle>
                    <div>
                      <Doughnut
                        data={{
                          datasets: [
                            {
                              label: "Modalidades",
                              data: Object.values(
                                teachersData?.course_modality
                              ),
                              backgroundColor: [
                                "#3f764c",
                                "#a0941d",
                                "#fd8925",
                              ],
                              borderWidth: 0,
                            },
                          ],
                        }}
                        width={25}
                        height={25}
                        options={{
                          cutout: "80%",
                        }}
                      />
                    </div>
                    <div>
                      <ul>
                        <li item={0}>A DISTÂNCIA</li>
                        <li item={1}>SEMI-PRESENCIAL</li>
                        <li item={2}>PRESENCIAL</li>
                      </ul>
                    </div>
                  </div>
                )}
            </section>
            <section id="gen-autoavaliacao-11">
              <header>
                <S.SectionTitle>
                  <div
                    onClick={() => {
                      setOpenClipboardModal(true);
                      setSectionId("gen-autoavaliacao-11");
                    }}
                  >
                    <S.StyledShareIcon />
                  </div>
                  <span>Perfil dos(as) professores(as)</span>
                  <h1>
                    PARTICIPAÇÃO DE FORMAÇÕES CONTINUADAS PARA O USO DE
                    TECNOLOGIAS DIGITAIS NOS PROCESSOS DE ENSINO E DE
                    APRENDIZAGEM NOS ÚLTIMOS 2 ANOS
                  </h1>
                </S.SectionTitle>
              </header>

              <div>
                <div>
                  <CircleChart
                    data={
                      teachersData?.cont_educ_in_the_use_of_digital_technologies
                        ? Object.values(
                            teachersData?.cont_educ_in_the_use_of_digital_technologies
                          )
                        : []
                    }
                  />
                </div>
                <div>
                  <S.Legend>
                    {teachersData?.cont_educ_in_the_use_of_digital_technologies &&
                      Object.entries(
                        teachersData?.cont_educ_in_the_use_of_digital_technologies
                      )
                        ?.map(([key, value]) => ({
                          label: key,
                          value: value,
                        }))
                        .map((item, index) => (
                          <S.LegendItem key={`item-${index}`} item={index}>
                            <p>{number.resolve(String(item?.value))}</p>
                            <div />
                            <p>{item?.label}</p>
                          </S.LegendItem>
                        ))}
                  </S.Legend>
                </div>
              </div>
            </section>
            <section id="gen-autoavaliacao-12">
              <S.SectionTitle>
                <div
                  onClick={() => {
                    setOpenClipboardModal(true);
                    setSectionId("gen-autoavaliacao-12");
                  }}
                >
                  <S.StyledShareIcon />
                </div>
                <h1>
                  DISTRIBUIÇÃO GEOGRÁFICA DOS (AS) PROFESSORES (AS) NO
                  TERRITÓRIO NACIONAL
                </h1>
              </S.SectionTitle>

              <div style={{ width: "100%", height: "950px" }}>
                {teachersData?.geographical_distribuition_of_teachers && (
                  <Map
                    networkTypeSelected={networkTypeSelected}
                    data={Object.entries(
                      teachersData?.geographical_distribuition_of_teachers
                    )}
                  />
                )}
              </div>

              <div>
                <header>
                  <RegionFilter
                    setRegionViewType={setRegionViewType}
                    regionViewType={regionViewType}
                    regionSelected={regionSelected}
                    setRegionSelected={setRegionSelected}
                  />
                </header>
                <main>
                  {regionViewType === "gridView" &&
                    (teachersData?.geographical_distribuition_of_teachers
                      ? Object.entries(
                          teachersData?.geographical_distribuition_of_teachers
                        ).filter(([key, _]) =>
                          REGION[regionSelected].includes(key)
                        )
                      : []
                    ).map(([key, value], index) => (
                      <S.StateCard
                        key={`${key}-${index}`}
                        networkTypeSelected={networkTypeSelected}
                      >
                        <header>
                          <img
                            src={require(`assets/images/ge-in-numbers/states_${networkTypeSelected}/${key}.svg`)}
                            alt=""
                          />
                          <span>{getAcronym(key)}</span>
                        </header>
                        <main>
                          <p>{key}</p>
                          <span>{number.resolve(String(value?.state))}</span>
                        </main>
                        <footer>
                          <p>MUNICÍPIOS</p>
                          <span>{number.resolve(String(value?.city))}</span>
                        </footer>
                      </S.StateCard>
                    ))}

                  {regionViewType === "listView" && (
                    <S.RegionsTable
                      cellSpacing="0"
                      cellPadding="0"
                      networkTypeSelected={networkTypeSelected}
                    >
                      <thead>
                        <tr>
                          <th>
                            <img
                              src={require(`assets/images/ge-in-numbers/states_${networkTypeSelected}/brasil.svg`)}
                              alt="Brasil"
                            />
                          </th>
                          <th>{PARSE_REGION[regionSelected]}</th>
                          <th>ESTADO</th>
                          <th>MUNICÍPIO</th>
                        </tr>
                      </thead>
                      <tbody>
                        {(teachersData?.geographical_distribuition_of_teachers
                          ? Object.entries(
                              teachersData?.geographical_distribuition_of_teachers
                            ).filter(([key, _]) =>
                              REGION[regionSelected].includes(key)
                            )
                          : []
                        ).map(([key, value], index) => (
                          <tr key={`${getAcronym(key)}-${index}`}>
                            <td>
                              <div>
                                <img
                                  src={require(`assets/images/ge-in-numbers/states_${networkTypeSelected}/${key}.svg`)}
                                  alt={getAcronym(key)}
                                />
                                <span>{getAcronym(key)}</span>
                              </div>
                            </td>
                            <td>{key}</td>
                            <td>{number.resolve(String(value.state))}</td>
                            <td>{number.resolve(String(value.city))}</td>
                          </tr>
                        ))}
                      </tbody>
                    </S.RegionsTable>
                  )}
                </main>
              </div>
            </section>
            <section>
              <header>
                <img src={image83} alt="" />
              </header>
              <main>
                <div>
                  <h1>ACESSO AOS MICRODADOS</h1>
                  <p>
                    O CIEB disponibiliza os microdados da <strong>Autoavaliação de
                    Competências Digitais de Professores(as)</strong> de forma aberta e
                    totalmente anônima para fins de pesquisa e análise. Alinhado
                    ao conceito de dados abertos, o CIEB acredita que todas as
                    matérias e/ou estudos publicados por terceiros, a partir os
                    microdados aqui disponibilizados, contenham a devida
                    referência bibliográfica.
                  </p>

                  <div>
                    <S.TabsWrapper>
                      {microdataInfo
                        ?.sort((a, b) => a.version - b.version)
                        ?.map((item, index) => (
                          <React.Fragment key={index}>
                            {item.disabled && (
                              <ReactTooltip
                                effect="solid"
                                id={`item-${item?.version}`}
                                aria-haspopup="true"
                                place="top"
                                className="tooltip"
                              >
                                Temporariamente indisponível
                              </ReactTooltip>
                            )}
                            <S.Tab
                              key={`tab-${index}`}
                              onClick={() => setVersion(item.version)}
                              active={version === item.version}
                              data-tip
                              data-for={`item-${item?.version}`}
                            >
                              Versão {item.version}
                            </S.Tab>
                          </React.Fragment>
                        ))}
                    </S.TabsWrapper>
                  </div>
                </div>
                <div>
                  {microdataInfoSuccess && (
                    <a
                      // target="_blank"
                      // rel="noreferrer"
                      download
                      href={microdataInfo[version - 1]?.data}
                      // href="javascript:void(0)"
                      data-href={microdataInfo[version - 1]?.data}
                    >
                      FAÇA O DOWNLOAD
                    </a>
                  )}
                </div>
                <div>
                  {microdataInfoSuccess && (
                    <table cellSpacing="0" cellPadding="0">
                      <thead>
                        <tr>
                          <th>CAMPO</th>
                          <th>VALOR</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Fonte</td>
                          <td>
                            <a href="https://guiaedutec.com.br">
                              guiaedutec.com.br
                            </a>
                          </td>
                        </tr>
                        <tr>
                          <td>Autor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Mantenedor</td>
                          <td>Centro de Inovação para Educação Brasileira</td>
                        </tr>
                        <tr>
                          <td>Versão</td>
                          <td>{microdataInfo[version - 1]?.version}</td>
                        </tr>
                        <tr>
                          <td>Última atualização</td>
                          <td>
                            {new Date(
                              microdataInfo[version - 1]?.updated_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Criado</td>
                          <td>
                            {new Date(
                              microdataInfo[version - 1]?.created_at
                            ).toLocaleDateString("pt-BR")}
                          </td>
                        </tr>
                        <tr>
                          <td>Cobertura geográfica</td>
                          <td>Brasil</td>
                        </tr>
                        <tr>
                          <td>Cobertura temporal</td>
                          <td>{microdataInfo[version - 1]?.coverage}</td>
                        </tr>
                        <tr>
                          <td>Frequência de atualização</td>
                          <td>Mensal</td>
                        </tr>
                        <tr>
                          <td>
                            Codificação (<span>encoding</span>)
                          </td>
                          <td>UTF-8</td>
                        </tr>
                        <tr>
                          <td>Dicionário de dados</td>
                          <td>
                            <a
                              href={microdataInfo[version - 1]?.dictionary}
                              target="_blank"
                              rel="noreferrer"
                            >
                              Baixe o dicionário de dados.
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
              </main>
              <footer>
                <div>
                  <img src={image23} alt="" /> <h1>OBSERVAÇÃO</h1>
                </div>
                <p>
                  As variações entre os microdados e o dashboard apresentado
                  nesta página ocorrem porque a frequência de atualização é
                  diferente, sendo que a do dashboard é semanal e a do
                  microdados é mensal.
                </p>
              </footer>
            </section>
          </S.Content>
        ) : (
          <S.NoDataContent>
            <GeneralFilter display={isTabletOrMobile && "none"} boxShadow />
            <section>
              <img src={boxEmpty} alt="Sem dados" />
              <p>Não há dados para os filtros selecionados</p>
            </section>
          </S.NoDataContent>
        )}
      </S.Wrapper>
      <Modal
        open={openClipboardModal}
        onClose={() => setOpenClipboardModal(false)}
      >
        <S.ClipboardModal>
          <div>
            <S.StyledLinkIcon />
          </div>
          <h1>Link copiado!</h1>
          <p>{clipboard}</p>
        </S.ClipboardModal>
      </Modal>

      {showGeneralFilterModal && (
        <S.Modal>
          <div>
            <div onClick={() => setShowGeneralFilterModal(false)}>
              <span>&times;</span>
            </div>
            <div>
              <GeneralFilter display />
            </div>
          </div>
        </S.Modal>
      )}
    </Layout>
  );
}
