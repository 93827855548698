import React from "react";
import * as S from "./styles";
import image26 from "assets/images/red-demands/026.svg";
import image27 from "assets/images/red-demands/027.svg";
import image28 from "assets/images/red-demands/028.svg";
import image29 from "assets/images/red-demands/029.svg";
import image30 from "assets/images/red-demands/030.svg";

const IMAGES = [image26, image27, image28, image29, image30];

export default function DemandsCard({
  key,
  index,
  title,
  children,
  isActive,
  setDemandSelected,
  name,
}) {
  return (
    <S.Wrapper
      key={key}
      isActive={isActive}
      onClick={() => setDemandSelected(name)}
    >
      <S.Header>
        <img src={IMAGES[index - 1]} alt="" />
      </S.Header>
      <S.Main>
        <p>{children}</p>
      </S.Main>
      <S.Footer index={index}>
        <h1>{title}</h1>
      </S.Footer>
    </S.Wrapper>
  );
}
