import React, { useEffect, useState } from "react";
import Plan from "pages/Plans/components/Plans";
import { GoalActionsTableProvider } from "hooks/use-goal-actions-table";
import * as S from "./styles";
import EquipmentsTable from "pages/Plans/components/EquipmentsTable";
import { useSurveyListQuery } from "services/common";
import { CircularProgress } from "@mui/material";
import { getSchoolSurveySchedules } from "utils/surveys";
import { useCalcInfraDashboardQuery } from "services/infra-calculator";
import Select from "shared-components/Select";
import { usePlans } from "hooks/use-plans";

const LEVELS = [
  { value: 0, label: "Básico" },
  { value: 1, label: "Intermediário" },
  { value: 2, label: "Avançado" },
];

const STAGES = [
  { value: "", label: "Selecione uma etapa" },
  { value: "elementary_1", label: "Fundamental 1" },
  { value: "elementary_2", label: "Fundamental 2" },
  { value: "highschool", label: "Ensino Médio" },
  { value: "technical", label: "Ensino Técnico" },
  { value: "adult", label: "EJA" },
];

export default function InfraPlan() {
  const [surveyScheduleId, setSurveyScheduleId] = useState("");
  const [level, setLevel] = useState(LEVELS[0]);
  const [stage, setStage] = useState("");
  const dimension = "infra";

  const { getCurrentPlan, institutionSuccess } = usePlans();

  const {
    data: calcInfraDashboard,
    isLoading: calcInfraDashboardLoading,
    isSuccess: calcInfraDashboardSuccess,
  } = useCalcInfraDashboardQuery(surveyScheduleId?.value, stage?.value);

  const {
    data: surveyList,
    isLoading: surveyListLoading,
    isSuccess: surveyListSuccess,
  } = useSurveyListQuery();

  useEffect(() => {
    if (surveyListSuccess && institutionSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);

      setSurveyScheduleId({
        label: schoolSchedules[0]?.name,
        value: schoolSchedules[0]?.id?.$oid,
      });

      const infra = getCurrentPlan().infra;

      if (infra?.stage) {
        const stage = STAGES.find((stage) => stage.value === infra?.stage);
        setStage(stage);
      }

      if (infra?.survey_schedule_id) {
        const schedule = schoolSchedules.find(
          (schedule) => schedule.id.$oid === infra?.survey_schedule_id
        );
        setSurveyScheduleId({ label: schedule.name, value: schedule.id.$oid });
      }

      if (infra?.level) {
        const level = LEVELS.find((level) => level.value === infra?.level);
        setLevel(level);
      }
    }
  }, [surveyList, surveyListSuccess, institutionSuccess, getCurrentPlan]);

  return (
    <GoalActionsTableProvider dimension={dimension}>
      <Plan
        dimension={dimension}
        previous="/planos/red"
        next="/planos/acompanhamento"
        infraParams={{
          survey_schedule_id: surveyScheduleId?.value,
          level: level?.value,
          stage: stage?.value,
        }}
      >
        <S.InfraCalculatorTitle>
          <div>
            <h1>
              <span>CALCULADORA</span> DE INFRAESTRUTURA
            </h1>
            <p>
              Planeje de forma estruturada a aquisição dos equipamentos
              necessários
              <br /> para a sua rede de ensino fazer uso pedagógico das
              tecnologias digitais
            </p>
          </div>
          <S.ButtonWrapper>
            <S.Button
              href="https://guiaedutec.com.br/calculadora-infra"
              target="_blank"
            >
              Saiba mais
            </S.Button>
          </S.ButtonWrapper>
        </S.InfraCalculatorTitle>
        <S.EquipmentsTableWrapper>
          <div>
            <p>
              <strong>ATENÇÃO!</strong> Após selecionar os filtros, clique no
              botão <strong>SALVAR</strong>, que está no final da página, para
              que os dados de INFRAESTRUTURA sejam armazenados.
            </p>
          </div>
          <S.FilterWrapper>
            <div>
              <label>Ciclo</label>
              {surveyListLoading ? (
                <CircularProgress size={30} />
              ) : (
                <Select
                  labelId="years"
                  id="years"
                  name="years"
                  value={surveyScheduleId}
                  onChange={(event) => {
                    setSurveyScheduleId(event);
                  }}
                  options={getSchoolSurveySchedules(surveyList?.surveys)?.map(
                    (schedule) => ({
                      label: schedule?.name,
                      value: schedule?.id?.$oid,
                    })
                  )}
                />
              )}
            </div>
            <div>
              <label>Nível pretendido</label>
              <Select
                labelId="levels"
                id="levels"
                name="levels"
                value={level}
                onChange={(event) => {
                  setLevel(event);
                }}
                options={LEVELS?.map((l) => ({
                  label: l?.label,
                  value: l?.value,
                }))}
              />
            </div>

            <div>
              <label>Etapas de ensino</label>
              <Select
                labelId="stages"
                id="stages"
                name="stages"
                value={stage}
                onChange={(event) => {
                  setStage(event);
                }}
                options={STAGES?.map((s) => ({
                  label: s?.label,
                  value: s?.value,
                }))}
              />
            </div>
          </S.FilterWrapper>

          {calcInfraDashboardSuccess ? (
            <EquipmentsTable
              rows={calcInfraDashboard?.data[level.value]?.total}
              calcInfraDashboardLoading={calcInfraDashboardLoading}
              calcInfraDashboardSuccess={calcInfraDashboardSuccess}
              columns={[
                "EQUIPAMENTOS QUE SERÃO ADQUIRIDOS",
                "QUANTIDADE",
                "VALOR TOTAL",
              ]}
            />
          ) : (
            <S.ErrorMessage>
              <S.ReportProblem />
              <p>Um erro inesperado aconteceu !</p>
              <p>
                Caso o erro continue entre em contato com o email:{" "}
                <a
                  href="mailto:guiaedutec@cieb.net.br"
                  target="_blank"
                  rel="noreferrer"
                >
                  guiaedutec@cieb.net.br
                </a>
              </p>
            </S.ErrorMessage>
          )}
        </S.EquipmentsTableWrapper>
      </Plan>
    </GoalActionsTableProvider>
  );
}
