import styled from "styled-components";
import media from "styled-media-query";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LockIcon from "@mui/icons-material/Lock";
import PasswordIcon from "@mui/icons-material/Password";
import EmailIcon from "@mui/icons-material/Email";

export const StyledVisibilityIcon = styled(VisibilityIcon)`
  cursor: pointer;
  &&& {
    font-size: 2rem;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledVisibilityOffIcon = styled(VisibilityOffIcon)`
  cursor: pointer;
  &&& {
    font-size: 2rem;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledLockIcon = styled(LockIcon)`
  cursor: pointer;
  &&& {
    font-size: 2rem;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledPasswordIcon = styled(PasswordIcon)`
  cursor: pointer;
  &&& {
    font-size: 2rem;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledEmailIcon = styled(EmailIcon)`
  cursor: pointer;
  &&& {
    font-size: 2rem;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const Wrapper = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  flex: 1;
  height: 100%;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.white} 60%,
    ${({ theme }) => theme.colors.gray3} 40%
  );
  ${media.lessThan("large")`
    background: ${({ theme }) => theme.colors.gray3};
  `}

  > section:nth-child(1) {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    height: 100vh;
    flex: 1;

    ${media.lessThan("large")`
        gap: 4.3rem;
        display: none;
        width: 100%;
    `}

    > div:nth-child(1) {
      width: 45rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 1.3rem;
      ${media.greaterThan("huge")`
        width: 50rem;
      `}
    }

    > div:nth-child(2) {
      width: 70rem;
      > video {
        object-fit: contain;
        width: 100%;
        height: auto;
      }

      ${media.greaterThan("huge")`
        width: 90rem;
      `}
    }
  }

  > section:nth-child(2) {
    height: 100vh;
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${media.lessThan("large")`
        width: 100%;  
    `}
  }
`;

export const Heading = styled.h3`
  color: ${({ theme }) => theme.colors.green};
  font-size: 3.3rem;
  font-weight: 500;
  ${media.greaterThan("huge")`
    font-size: 4rem;  
  `}
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.black};
  font-size: 2.5rem;
  font-weight: 500;
  text-align: center;
  padding: 1rem 4rem;

  ${media.greaterThan("huge")`
    font-size: 3.3rem;   
  `}
`;

export const SignInForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 0.5rem;
  padding: 5.5rem 7rem;
  ${media.greaterThan("huge")`
    padding: 6.4rem 12rem; 
  `}
  ${media.lessThan("large")`
    padding: 4rem; 
  `}

  > div {
    flex: 1;
    position: relative;

    > svg:nth-child(1) {
      position: absolute;
      left: 1.5rem;
      top: 1.45rem;
    }

    > svg:nth-child(3) {
      position: absolute;
      right: 1.5rem;
      top: 1.45rem;
      color: ${({ theme }) => theme.colors.green2};
    }

    > input {
      width: 100%;
      height: 5rem;
      font-size: 1.6rem;
      border-radius: 0.5rem;
      color: ${({ theme }) => theme.colors.black};
      padding: 0px 1rem 0rem 4.5rem;
      border: none;
      background-color: ${({ theme }) => theme.colors.white};
      &:active {
        background-color: ${({ theme }) => theme.colors.white};
      }
    }

    &:focus-within {
      > input:focus {
        outline: 0.2rem solid ${({ theme }) => theme.colors.green2};
        font-weight: 500;
      }

      > svg:nth-child(1) {
        transition: 0.3s;
        color: ${({ theme }) => theme.colors.green2};
      }
    }
  }

  > p {
    font-size: 1.5rem;
    > a {
      text-decoration: none;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.green2};
      &:hover {
        color: ${({ theme }) => theme.colors.green};
        transition: 0.4s;
      }
    }
  }

  > p:nth-child(7) {
    text-align: center;
  }

  > button {
    height: 5rem;
    border: none;
    border-radius: 0.5rem;
    background-color: ${({ theme }) => theme.colors.green};
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.6rem;
    font-weigth: 500;
    margin-top: 2rem;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.green2};
      transition: 0.4s;
    }
    svg {
      color: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const ErrorArea = styled.div`
  > p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.warning};
  }
`;
