import styled, { css } from "styled-components";

const COLORS = {
  infra: "#67ae3f",
  vision: "#fad62b",
  competence: "#e62270",
  red: "#008bbc",
};

const small = css`
  width: 0.75rem;
  height: 0.75rem;
`;

const large = css`
  width: 1.1rem;
  height: 1.1rem;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.73rem;
  margin-bottom: 1.4rem;
`;

export const Circle = styled.div`
  background-color: ${({ dimension }) => COLORS[dimension]};
  border-radius: 50%;
  ${({ size }) => (size === "small" ? small : large)}
`;
