import styled, { css } from "styled-components";
import media from "styled-media-query";

const COLORS = {
  vision: "yellow",
  competence: "pink",
  red: "blue",
  infra: "green",
};

const BACKGROUND_COLORS = {
  vision: "yellow4",
  competence: "pink4",
  red: "blue4",
  infra: "green4",
};

const wrapperModifiers = {
  background: css`
    background-color: ${({ theme, dimension }) =>
      theme.colors[BACKGROUND_COLORS[dimension]]};
    font-weight: 500;
    padding: 1rem 0.6rem 1rem 1rem;
  `,
  menuRecoilLeft: css`
    left: 0rem;
  `,
  menuRecoilRight: css`
    right: 0rem;
  `,
};

export const Wrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const Title = styled.h1`
  font-size: 1.9rem;
  font-weight: 500;
`;

export const Menu = styled.ul`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, dimension }) =>
    theme.colors[COLORS[dimension]]};
  list-style-type: none;
  width: 100%;
  cursor: pointer;
`;

export const MenuItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.7rem;
  font-weight: 500;
  color: ${({ theme, dimension }) =>
    dimension === "vision" ? theme.colors.black : theme.colors.white};
  gap: 1rem;
  height: 9rem;
  width: 28rem;
  background-color: ${({ theme, dimension }) =>
    theme.colors[COLORS[dimension]]};
  position: relative;

  > img {
    width: 5rem;
  }

  > section {
    z-index: 4;
    cursor: default;
    position: absolute;
    bottom: 0rem;
    transform: translateY(100%);
    min-height: 25rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 0rem 0rem 2.5rem 2.5rem;
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
    display: flex;

    ${media.lessThan("huge")`
      ${({ stepNumber }) =>
        stepNumber === 1 && wrapperModifiers["menuRecoilLeft"]}
      ${({ stepNumber }) =>
        stepNumber === 4 && wrapperModifiers["menuRecoilRight"]}
    `}

    > article {
      > div:nth-child(1) {
        width: 20rem;
        > div {
          width: 6rem;
          height: 4rem;
          background-color: ${({ theme, dimension }) =>
            dimension === "vision"
              ? theme.colors.yellow2
              : theme.colors[COLORS[dimension]]};
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 2.2rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors.white};
        }
        > img {
          height: 4.75rem;
        }
      }
      > div:nth-child(2) {
        position: relative;
        position: absolute;
        left: 2.5rem;
        top: 4.6rem;

        > p {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: ${({ theme, dimension }) =>
            dimension === "vision" ? theme.colors.black : theme.colors.white};
          font-size: 1.2rem;
          font-weight: 500;
          width: 12rem;
          text-align: center;
          cursor: pointer;
        }
      }
    }

    > div:nth-child(2) {
      flex: 1;
      min-width: 33rem;
      max-width: 40rem;
      padding: 6.5rem 3rem 3rem 2rem;
    }
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
  }

  ${({ isActive }) =>
    isActive &&
    css`
      background-color: ${({ theme }) => theme.colors.gray3};
      color: ${({ theme }) => theme.colors.black};
    `};
`;

export const ItemsList = styled.ul`
  list-style-type: none;
  display: inline-flex;
  flex-direction: column;
`;

export const Item = styled.li`
  margin-bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 400;
  > a {
    text-decoration: none;
    color: ${({ theme, dimension }) =>
      dimension === "vision"
        ? theme.colors.yellow2
        : theme.colors[COLORS[dimension]]};
  }

  ${({ background }) => background && wrapperModifiers["background"]};
`;

export const Cicle = styled.div`
  width: 15rem;
  height: 15rem;
  border-radius: 50%;
  background-color: ${({ theme, dimension }) =>
    theme.colors[COLORS[dimension]]};
  cursor: pointer;
  position: relative;
`;
