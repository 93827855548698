import AppRoute from "./routes";
import { GlobalStyles } from "global-styles/globalStyles";
import { ThemeProvider } from "styled-components";
import { StepProvider } from "hooks/use-steps";
import { MenuProvider } from "hooks/use-menu";
import { theme } from "global-styles/theme";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SideNavigationBarProvider } from "hooks/use-side-navigation-bar";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { NotificationContainer } from "react-notifications";
// import { useEffect } from "react";
const queryClient = new QueryClient();

export default function App() {
  // useEffect(() => {
  //   const script = document.createElement("script");
  //   script.src = "http://api-emails.cieb.net.br/javascripts/pdfs.js";
  //   script.async = true;
  //   document.body.appendChild(script);
  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <SideNavigationBarProvider>
          <MenuProvider>
            <StepProvider>
              <AppRoute />
            </StepProvider>
          </MenuProvider>
        </SideNavigationBarProvider>
        <NotificationContainer />
        <GlobalStyles />
        {/* {process.env.NODE_ENV === "development" && <ReactQueryDevtools />} */}
      </ThemeProvider>
    </QueryClientProvider>
  );
}
