import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useForm, FormProvider } from "react-hook-form";
import {
  useInstitutionPlansMutation,
  useInstitutionQuery,
} from "services/common";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { usePlans } from "./use-plans";

const ResponsibleTableContext = createContext();

function ResponsibleTableProvider({ children }) {
  const [responsible, setResponsible] = useState([]);
  const [canEdit, setCanEdit] = useState([]);
  const [loadings, setLoadings] = useState([]);
  const [selectedRow, setSelectedRow] = useState();
  const methods = useForm();
  const { unregister, getValues } = methods;

  const {
    data: institution,
    isLoading: institutionLoading,
    isSuccess: institutionSuccess,
  } = useInstitutionQuery();

  const { getCurrentPlan } = usePlans();

  useEffect(() => {
    if (institutionSuccess) {
      const currentPlan = getCurrentPlan();
      if (!currentPlan.general_data) {
        currentPlan.general_data = {};
      }
      if (!currentPlan.general_data.responsible) {
        currentPlan.general_data.responsible = [];
      }
      if (!currentPlan.general_data.network) {
        currentPlan.general_data.network = {};
      }
      const responsible = currentPlan?.general_data?.responsible;
      setResponsible(responsible);
    }
  }, [getCurrentPlan, institutionSuccess]);

  const institutionPlans = useInstitutionPlansMutation();

  const {
    isLoading: institutionPlansLoading,
    isSuccess: institutionPlansSuccess,
    error: institutionPlansError,
  } = institutionPlans;

  useEffect(() => {
    if (institutionPlansSuccess) {
      NotificationManager.success("Os dados foram salvos com sucesso !");
    }
    if (institutionPlansError) {
      NotificationManager.error("Ocorreu um erro ao salvar os dados");
    }
  }, [institutionPlansSuccess, institutionPlansError]);

  useEffect(() => {
    if (selectedRow !== undefined) {
      setLoadings((loadings) => {
        const loadingsAux = [...loadings];
        loadingsAux[selectedRow] = institutionPlansLoading;
        return loadingsAux;
      });
    }
  }, [selectedRow, institutionPlansLoading]);

  const saveResponsibleInDataBase = useCallback(
    (index) => {
      setSelectedRow(index);
      const data = getValues(`row_${index}`);
      setResponsible((responsible) => {
        responsible[index] = data;
        return responsible;
      });
      const currentPlan = getCurrentPlan();
      if (!currentPlan.general_data) {
        currentPlan.general_data = { responsible: [] };
      }
      currentPlan.general_data.responsible[index] = data;
      const length = institution?.plans?.length;
      institution.plans[length - 1] = currentPlan;
      institutionPlans.mutate({ institution });
    },
    [getCurrentPlan, getValues, institution, institutionPlans]
  );

  const removeResponsibleFromDataBase = useCallback(
    (index) => {
      setSelectedRow(index);
      const currentPlan = getCurrentPlan();
      let responsibleAux = [...currentPlan?.general_data?.responsible];
      responsibleAux = responsibleAux.filter((_, i) => i !== index);
      currentPlan.general_data.responsible = responsibleAux;
      const length = institution?.plans?.length;
      institution.plans[length - 1] = currentPlan;
      institutionPlans.mutate({ institution });
      unregister(`_row_${index}`);
    },
    [getCurrentPlan, institution, institutionPlans, unregister]
  );

  const changeCanEdit = useCallback(
    (index, bool) => {
      const canEditAux = [...canEdit];
      canEditAux[index] = bool;
      setCanEdit(canEditAux);
    },
    [canEdit, setCanEdit]
  );

  const addResponsibleInState = useCallback(
    (data) => {
      setResponsible((responsible) => {
        const responsibleAux = [...responsible, data];
        changeCanEdit(responsibleAux.length - 1, true);
        return responsibleAux;
      });
    },
    [changeCanEdit]
  );

  const deleteResponsibleFromState = useCallback(
    (index) => {
      setResponsible(() => {
        const responsibleAux = responsible.filter((_, i) => i !== index);
        return responsibleAux;
      });
    },
    [responsible]
  );

  return (
    <FormProvider {...methods}>
      <ResponsibleTableContext.Provider
        value={{
          canEdit,
          loadings,
          responsible,
          getValues,
          institution,
          institutionPlans,
          institutionPlansLoading,
          institutionPlansSuccess,
          institutionLoading,
          institutionSuccess,
          getCurrentPlan,
          saveResponsibleInDataBase,
          removeResponsibleFromDataBase,
          deleteResponsibleFromState,
          addResponsibleInState,
          changeCanEdit,
        }}
      >
        {children}
      </ResponsibleTableContext.Provider>
    </FormProvider>
  );
}

const useResponsibleTable = () => useContext(ResponsibleTableContext);

export { ResponsibleTableProvider, useResponsibleTable };
