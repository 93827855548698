import React from "react";
import {
  CheckIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@radix-ui/react-icons";
import * as S from "./styles";

export interface ContentProps {
  children?: React.ReactNode;
}
function Content({ children, ...props }: ContentProps) {
  return (
    <S.StyledPortal>
      <S.StyledContent {...props}>{children}</S.StyledContent>
    </S.StyledPortal>
  );
}

export interface GENSelectProps {
  data: any;
  placeholder?: string;
  onValueChange: any;
  disabled?: boolean;
  value: any;
  defaultValue?: any;
}

const GENSelect = ({
  data,
  placeholder,
  onValueChange,
  disabled,
  value,
  defaultValue,
}: GENSelectProps) => {
  return (
    <S.Select
      onValueChange={onValueChange}
      value={value}
      defaultValue={defaultValue}
    >
      <S.StyledTrigger aria-label={placeholder} disabled={disabled}>
        <S.StyledValue placeholder={placeholder}>{value?.label}</S.StyledValue>

        <S.StyledIcon>
          <ChevronDownIcon />
        </S.StyledIcon>
      </S.StyledTrigger>
      <Content>
        <S.StyledScrollUpButton>
          <ChevronUpIcon />
        </S.StyledScrollUpButton>
        <S.StyledViewport>
          {data?.map((option: any) => (
            <S.StyledItem value={option} key={option?.value}>
              <S.StyledItemText>{option?.label}</S.StyledItemText>
              <S.StyledItemIndicator>
                <CheckIcon />
              </S.StyledItemIndicator>
            </S.StyledItem>
          ))}
        </S.StyledViewport>
        <S.StyledScrollDownButton>
          <ChevronDownIcon />
        </S.StyledScrollDownButton>
      </Content>
    </S.Select>
  );
};
export default GENSelect;
