import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import image11 from "assets/images/plans/11.svg";
import HelpIcon from "@mui/icons-material/Help";

export const StyledHelpIcon = styled(HelpIcon)`
  &&& {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-size: 1.5rem;
`;

export const BannerWrapper = styled.section`
  background: #fad62b;
`;

export const Banner = styled.div`
  ${container}
  background: url(${image11});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  height: 42rem;
  width: 100%;
  margin-bottom: 5rem;
`;

export const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 2rem;
  width: 100%;
  > img {
    width: 6rem;
  }
  border-bottom: 0.1rem solid #cfded7;
  padding: 1rem 2rem;
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black};
`;

export const Content = styled.div`
  ${container}
  display: flex;
  flex-direction: column;
  gap: 10rem;
  margin-bottom: 10rem;
`;
export const TermPlanAndBaseCycle = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div:nth-child(1) {
    > div:nth-child(2) {
      display: flex;
      justify-content: space-between;
      gap: 3rem;
      > div {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        > label {
          font-weight: 500;
        }
      }
    }
  }

  > div:nth-child(2) {
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > label {
        font-weight: 500;
      }
    }
  }
`;
export const NetworkDiagnosticReport = styled.section`
  display: flex;
  flex-direction: column;
`;

export const Tip = styled.div`
  margin-top: 2rem;
  > p {
    font-size: 1.6rem;
    text-align: center;
  }
`;

export const MainGoalText = styled.section`
  display: flex;
  flex-direction: column;
  > textarea {
    border: none;
    background-color: ${({ theme }) => theme.colors.gray5};
    height: 15rem;
    padding: 2rem;
    border-radius: 1rem;
    resize: none;
    border: 1px solid #e5e5e5;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  > button {
    background-color: ${({ theme }) => theme.colors.blue5};
    color: ${({ theme }) => theme.colors.white};
    padding: 0rem 2rem;
    height: 4rem;
    min-width: 10rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue2};
      transition: 0.1s ease-in-out;
    }
  }
`;
