import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function SecondStep() {
  return (
    <S.Wrapper>
      <h1 id="i-e-2">
        2. DIAGNÓSTICO DA SITUAÇÃO DE INFRAESTRUTURA E CONECTIVIDADE DAS REDES
      </h1>

      <h2 id="i-e-2-s-1">2.1 Diagnóstico de conectividade</h2>

      <p>
        Entender a situação real da conectividade nas escolas de sua rede é o
        primeiro passo para visualizar os principais desafios e oportunidades
        para melhorar o acesso à internet.
      </p>

      <p>
        Para apoiá-lo/a nesse diagnóstico, o CIEB e o NIC.br, em parceria com as
        organizações que compõem o Grupo Interinstitucional de Conectividade
        para a Educação (GICE), desenvolveram o “Mapa de Conectividade na
        Educação”, que mostra um retrato da conectividade das escolas.
      </p>

      <p>Explore o Mapa de Conectividade!</p>

      <Card
        heading="MAPA DE"
        title="CONECTIVIDADE"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="mapaDeConectividade"
        link="https://conectividadenaeducacao.nic.br/"
      />

      <h2 id="i-e-2-s-2">2.2 Diagnóstico de Infraestrutura</h2>

      <p>
        Pautado nos equipamentos recomendados, as escolas respondem, por meio do{" "}
        <a
          href="https://guiaedutec.com.br/escola"
          target="_blank"
          rel="noreferrer"
        >
          <i>Diagnóstico Guia Edutec</i>
        </a>
        , informações sobre sua infraestrutura atual, gerando um inventário de
        infraestrutura. Nele, encontram-se informações como o quantitativo de
        equipamentos disponíveis nas escolas, tamanho de turmas e quantidade de
        estudantes. Essas informações possibilitam que você consiga na etapa
        seguinte calcular o quanto será necessário investir para melhorar a
        infraestrutura da sua rede.
      </p>
    </S.Wrapper>
  );
}
