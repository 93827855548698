import React, { useState } from "react";
import { useJourneys } from "hooks/use-journeys";
import PropTypes from "prop-types";
import * as S from "./styles";

function FourLeafClover2({ className }) {
  const [infraIsActive, setInfraIsActive] = useState(false);
  const [visionIsActive, setVisionIsActive] = useState(false);
  const [redIsActive, setRedIsActive] = useState(false);
  const [competenceIsActive, setCompetenceIsActive] = useState(false);

  const { pushToVision, pushToCompetence, pushToRed, pushToInfrastructure } =
    useJourneys();

  return (
    <S.Wrapper className={className}>
      <p
        onClick={pushToVision}
        onMouseOver={() => setVisionIsActive(true)}
        onMouseOut={() => setVisionIsActive(false)}
      >
        VISÃO
      </p>
      <p
        onClick={pushToCompetence}
        onMouseOver={() => setCompetenceIsActive(true)}
        onMouseOut={() => setCompetenceIsActive(false)}
      >
        COMPETÊNCIA
      </p>
      <p
        onClick={pushToRed}
        onMouseOver={() => setRedIsActive(true)}
        onMouseOut={() => setRedIsActive(false)}
      >
        RECURSOS <br />
        EDUCACIONAIS <br /> DIGITAIS (RED)
      </p>
      <p
        onClick={pushToInfrastructure}
        onMouseOver={() => setInfraIsActive(true)}
        onMouseOut={() => setInfraIsActive(false)}
      >
        INFRAESTRUTURA
      </p>
      <S.SVG
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="121 34.17 1113.78 939.98"
        infraIsActive={infraIsActive}
        visionIsActive={visionIsActive}
        redIsActive={redIsActive}
        competenceIsActive={competenceIsActive}
      >
        <defs>
          <radialGradient
            id="Gradiente_sem_nome_90"
            data-name="Gradiente sem nome 90"
            cx="697.6"
            cy="492.02"
            r="36.23"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.55" stopColor="#fff"></stop>
            <stop offset="0.55" stopColor="#fdfdfd"></stop>
            <stop offset="0.61" stopColor="#a8a9aa"></stop>
            <stop offset="0.66" stopColor="#6a6c6d"></stop>
            <stop offset="0.7" stopColor="#444647"></stop>
            <stop offset="0.72" stopColor="#353839"></stop>
            <stop offset="0.88" stopColor="#79b142"></stop>
          </radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_87"
            data-name="Gradiente sem nome 87"
            cx="293.6"
            cy="1034.62"
            fy="1023.8240699214684"
            r="46.76"
            gradientTransform="translate(990.45 1524.55) rotate(180)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#fff"></stop>
            <stop offset="0.54" stopColor="#fff" stopOpacity="0.6"></stop>
            <stop offset="0.81" stopColor="#353839"></stop>
          </radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_96"
            data-name="Gradiente sem nome 96"
            cx="566.2"
            cy="283.89"
            r="34.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.55" stopColor="#fff"></stop>
            <stop offset="0.55" stopColor="#fdfdfd"></stop>
            <stop offset="0.62" stopColor="#a8a9aa"></stop>
            <stop offset="0.68" stopColor="#6a6c6d"></stop>
            <stop offset="0.72" stopColor="#444647"></stop>
            <stop offset="0.74" stopColor="#353839"></stop>
            <stop offset="0.89" stopColor="#e2c23a"></stop>
          </radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_87-2"
            cx="132.88"
            cy="953.19"
            fy="942.8650188349742"
            r="44.76"
            gradientTransform="translate(698.37 1235.08) rotate(180)"
            xlinkHref="#Gradiente_sem_nome_87"
          ></radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_88"
            data-name="Gradiente sem nome 88"
            cx="738.98"
            cy="319.26"
            r="38.05"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.55" stopColor="#fff"></stop>
            <stop offset="0.55" stopColor="#fdfdfd"></stop>
            <stop offset="0.61" stopColor="#a8a9aa"></stop>
            <stop offset="0.66" stopColor="#6a6c6d"></stop>
            <stop offset="0.7" stopColor="#444647"></stop>
            <stop offset="0.72" stopColor="#353839"></stop>
            <stop offset="0.88" stopColor="#cf2467"></stop>
          </radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_87-3"
            cx="254.75"
            cy="919.74"
            fx="248.22680149328573"
            fy="908.4042776907411"
            r="49.11"
            gradientTransform="translate(992.95 1236.81) rotate(180)"
            xlinkHref="#Gradiente_sem_nome_87"
          ></radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_2"
            data-name="Gradiente sem nome 2"
            cx="510.28"
            cy="448.23"
            r="35.37"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.55" stopColor="#fff"></stop>
            <stop offset="0.55" stopColor="#fdfdfd"></stop>
            <stop offset="0.61" stopColor="#a8a9aa"></stop>
            <stop offset="0.66" stopColor="#6a6c6d"></stop>
            <stop offset="0.7" stopColor="#444647"></stop>
            <stop offset="0.72" stopColor="#353839"></stop>
            <stop offset="0.9" stopColor="#127fa9"></stop>
          </radialGradient>
          <radialGradient
            id="Gradiente_sem_nome_87-4"
            cx="189.17"
            cy="1083.64"
            fy="1073.0993124559127"
            r="45.65"
            gradientTransform="translate(698.73 1529.83) rotate(180)"
            xlinkHref="#Gradiente_sem_nome_87"
          ></radialGradient>
        </defs>
        <g className="cls-1">
          <g id="Camada_1" data-name="Camada 1">
            <g id="Circles">
              <circle
                className="cls-2"
                cx="768.69"
                cy="840.74"
                r="9.98"
              ></circle>
              <circle
                className="cls-2"
                cx="747.13"
                cy="812.1"
                r="5.75"
              ></circle>
              <circle
                className="cls-2"
                cx="279.93"
                cy="935.78"
                r="14.04"
              ></circle>
              <circle
                className="cls-2"
                cx="752.49"
                cy="742.51"
                r="2.44"
              ></circle>
              <circle
                className="cls-2"
                cx="500.88"
                cy="826.94"
                r="5.27"
              ></circle>
              <circle
                className="cls-2"
                cx="976.37"
                cy="906.49"
                r="9.47"
              ></circle>
              <circle
                className="cls-2"
                cx="1132.05"
                cy="812.33"
                r="3.51"
              ></circle>
              <circle className="cls-2" cx="1192.55" cy="820" r="2.02"></circle>
              <circle
                className="cls-2"
                cx="474.09"
                cy="738.08"
                r="4.29"
              ></circle>
              <circle
                className="cls-2"
                cx="784.79"
                cy="949.94"
                r="9.09"
              ></circle>
              <circle
                className="cls-2"
                cx="800.12"
                cy="887.31"
                r="6.25"
              ></circle>
              <circle
                className="cls-2"
                cx="1075.3"
                cy="945.35"
                r="9.75"
              ></circle>
              <circle
                className="cls-2"
                cx="732.7"
                cy="955.96"
                r="3.07"
              ></circle>
              <circle
                className="cls-2"
                cx="614.05"
                cy="961.39"
                r="6.62"
              ></circle>
              <circle
                className="cls-2"
                cx="595.35"
                cy="925.12"
                r="3.88"
              ></circle>
              <circle
                className="cls-2"
                cx="539.5"
                cy="915.37"
                r="3.99"
              ></circle>
            </g>
            <path
              className="cls-3"
              d="M447.62,729.34c96,0,173.78-75.44,173.78-168.51V507c0-2.16-.4-3.46-1.19-3.87-1.11-.59-3.19.74-3.88,1.18a26.24,26.24,0,0,1-14.14,4.15c-15,0-27.32-12.88-27.49-28.78.17-15.79,12.51-28.64,27.49-28.64a26.32,26.32,0,0,1,14.14,4.15c.68.44,2.77,1.77,3.88,1.19.79-.41,1.19-1.72,1.19-3.88V392.31h-62c-5.16,0-8.69-1.65-10.51-4.92s-1.27-6.95,1.55-11.11a17.32,17.32,0,0,0,3.07-9.77c0-10.62-10-19.36-22.14-19.48-12.28.12-22.28,8.86-22.28,19.48a17.39,17.39,0,0,0,3.06,9.77c2.82,4.16,3.34,7.9,1.55,11.11s-5.35,4.92-10.5,4.92H447.62c-96,0-173.78,75.45-173.78,168.52S351.64,729.34,447.62,729.34Z"
              onClick={pushToRed}
              onMouseOver={() => setRedIsActive(true)}
              onMouseOut={() => setRedIsActive(false)}
            ></path>
            <ellipse
              className="cls-4"
              cx="452.1"
              cy="558.67"
              rx="138.31"
              ry="135.35"
              onClick={pushToRed}
              onMouseOver={() => setRedIsActive(true)}
              onMouseOut={() => setRedIsActive(false)}
            ></ellipse>
            <path
              className="cls-2"
              d="M964.52,558.92A167.16,167.16,0,0,0,797.36,391.76H743.93c-2.14,0-3.44.38-3.84,1.14-.58,1.07.74,3.07,1.17,3.73a24.76,24.76,0,0,1,4.12,13.6c0,14.42-12.78,26.28-28.55,26.45-15.67-.17-28.41-12-28.41-26.45a24.67,24.67,0,0,1,4.12-13.6c.43-.66,1.75-2.66,1.17-3.73-.41-.76-1.7-1.14-3.84-1.14H630.19v59.68c0,5-1.64,8.36-4.88,10.1s-6.9,1.22-11-1.49a17.59,17.59,0,0,0-9.69-2.95c-10.53,0-19.21,9.59-19.33,21.3.12,11.81,8.8,21.43,19.33,21.43a17.59,17.59,0,0,0,9.69-2.95c4.12-2.71,7.83-3.21,11-1.49s4.88,5.15,4.88,10.11v53.42a167.17,167.17,0,1,0,334.33,0Z"
              onMouseOver={() => setInfraIsActive(true)}
              onMouseOut={() => setInfraIsActive(false)}
              onClick={pushToInfrastructure}
            ></path>
            <circle
              className="cls-5"
              cx="795.26"
              cy="556.83"
              r="132.55"
              onMouseOver={() => setInfraIsActive(true)}
              onMouseOut={() => setInfraIsActive(false)}
              onClick={pushToInfrastructure}
            ></circle>
            <g>
              <path
                className="cls-2"
                d="M748.23,491.9A52.28,52.28,0,0,0,696,439.63h-16.7c-.67,0-1.08.12-1.2.35s.23,1,.36,1.17a7.66,7.66,0,0,1,1.29,4.25c0,4.51-4,8.22-8.93,8.27s-8.88-3.76-8.88-8.27a7.74,7.74,0,0,1,1.29-4.25c.13-.21.55-.83.37-1.17s-.54-.35-1.21-.35H643.68v18.66a3.28,3.28,0,0,1-1.52,3.16,3.24,3.24,0,0,1-3.45-.47,5.53,5.53,0,0,0-3-.92c-3.29,0-6,3-6,6.66s2.75,6.7,6,6.7a5.5,5.5,0,0,0,3-.92,3.24,3.24,0,0,1,3.45-.47,3.28,3.28,0,0,1,1.52,3.16V491.9a52.28,52.28,0,0,0,104.55,0Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <circle
                className="cls-6"
                cx="697.6"
                cy="490.72"
                r="34.49"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></circle>
              <path
                className="cls-7"
                d="M732.09,490.72a34.49,34.49,0,1,1-69,0c0-6.07,9.78,13.58,12.54,8.63,5.89-10.59,15.33-20.52,28.22-22,28.22-3.14,3-20.2,7.16-18.46a34.65,34.65,0,0,1,14,10.93A34.27,34.27,0,0,1,732.09,490.72Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <circle
                className="cls-8"
                cx="696.86"
                cy="489.93"
                r="30.39"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></circle>
              <circle
                className="cls-9"
                cx="696.86"
                cy="489.93"
                r="24.31"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></circle>
              <g>
                <path
                  className="cls-2"
                  d="M709.24,500H684.51a1.67,1.67,0,0,1-1.66-1.67v-15a1.67,1.67,0,0,1,1.66-1.66h24.73a1.67,1.67,0,0,1,1.66,1.66v15A1.67,1.67,0,0,1,709.24,500Zm-24.73-17.06a.42.42,0,0,0-.42.42v15a.42.42,0,0,0,.42.43h24.73a.42.42,0,0,0,.42-.43v-15a.42.42,0,0,0-.42-.42Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
                <path
                  className="cls-2"
                  d="M702.09,502.65H692a.62.62,0,0,1,0-1.24h10.05a.62.62,0,0,1,0,1.24Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
                <path
                  className="cls-2"
                  d="M702.8,489.57a.61.61,0,0,1-.39-.13c-5.61-4.52-11-.19-11.28,0a.62.62,0,0,1-.87-.08.61.61,0,0,1,.09-.87c.06-.06,6.43-5.17,12.83,0a.62.62,0,0,1,.1.87A.59.59,0,0,1,702.8,489.57Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
                <path
                  className="cls-2"
                  d="M700.85,491.47a.56.56,0,0,1-.38-.14,5.64,5.64,0,0,0-6.93,0,.61.61,0,0,1-.87-.1.62.62,0,0,1,.1-.87s4.27-3.32,8.47,0a.62.62,0,0,1,.1.87A.6.6,0,0,1,700.85,491.47Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
                <path
                  className="cls-2"
                  d="M699,493.74a.61.61,0,0,1-.42-.16c-1.53-1.4-3.37,0-3.45,0a.61.61,0,0,1-.86-.11.62.62,0,0,1,.11-.87,3.91,3.91,0,0,1,5,0,.61.61,0,0,1,0,.87A.62.62,0,0,1,699,493.74Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
                <path
                  className="cls-2"
                  d="M697.16,495.76h-.39a.62.62,0,0,1-.62-.62.62.62,0,0,1,.62-.62h.39a.62.62,0,0,1,.62.62A.62.62,0,0,1,697.16,495.76Z"
                  onMouseOver={() => setInfraIsActive(true)}
                  onMouseOut={() => setInfraIsActive(false)}
                  onClick={pushToInfrastructure}
                ></path>
              </g>
            </g>
            <path
              className="cls-10"
              d="M262.8,662.84,249.63,686s3.65,11.47,16.06,9.72L269.43,675Z"
            ></path>
            <path
              className="cls-11"
              d="M302.08,715l10.37,4s14.69-2.91,24.3-5.36a174,174,0,0,0,20.1-6.82,43.4,43.4,0,0,0,.52,6.33c.44,1.73-42.27,12.4-47.25,11.4S297,716.44,297,716.44Z"
            ></path>
            <path
              className="cls-3"
              d="M211.7,761.11s-13.31,30.17-30.77,69.82-33.84,75.31-33.84,75.31l29.47,8.37S190,887,203.85,861.13s29.83-50.21,29.83-50.21a237.13,237.13,0,0,0,31.71-5.57c16.31-4.13,17.89-2.19,17.52.48s-10.06,28.62-10.06,28.62l28.5-.85s21.7-61,18.79-62.82-56.27,1.58-56.27,1.58Z"
            ></path>
            <path
              className="cls-3"
              d="M297,715l-17.83,17.37s-9.94-7-10.3-4.73-5,44.75-5,44.75l-15.15,1.45s-7.88,3.28-20.13-4.85-26.8-10.3-26.8-10.3,23-37.42,31.89-51.06,12.1-23.74,18.92-19.64S297,715,297,715Z"
            ></path>
            <polygon
              className="cls-12"
              points="279.15 732.34 283.34 735.07 301.25 717.25 296.98 714.97 279.15 732.34"
            ></polygon>
            <path
              className="cls-4"
              d="M270.18,727.26l-9.58-3.77s1.26,8.12,7.42,11.16C268,734.65,268.93,726.82,270.18,727.26Z"
            ></path>
            <polygon
              className="cls-13"
              points="263.59 694.62 298.8 713.06 296.98 714.97 263.59 694.62"
            ></polygon>
            <polygon
              className="cls-14"
              points="298.8 713.06 301.71 714.42 299.75 716.44 296.98 714.97 298.8 713.06"
            ></polygon>
            <path
              className="cls-4"
              d="M233.68,810.92C220.22,813.65,202.94,794,202.94,794s-6.76,39,9.81,51.13Z"
            ></path>
            <polygon
              className="cls-15"
              points="147.09 906.24 143.21 914.61 171.95 922.73 176.56 914.61 147.09 906.24"
            ></polygon>
            <polygon
              className="cls-15"
              points="272.85 834.45 270.18 842.21 298.19 842.21 301.35 833.6 272.85 834.45"
            ></polygon>
            <polygon
              className="cls-16"
              points="277.7 842.21 271.15 859.31 284.97 859.31 291.8 842.21 277.7 842.21"
            ></polygon>
            <polygon
              className="cls-16"
              points="151.6 916.98 144.18 930.98 155.28 939.8 165.21 920.82 151.6 916.98"
            ></polygon>
            <path
              className="cls-5"
              d="M277.7,842.21,275.46,848s4.79-4.1,16.34-5.83Z"
            ></path>
            <path
              className="cls-11"
              d="M151.6,917s7,3,11.31,8.22l2.3-4.38Z"
            ></path>
            <path
              className="cls-17"
              d="M271.15,859.31s-12.1,27-12.19,31.47h48.21s.81-7.1-6.19-8.64-19.1-5.19-18.74-10.92A47.79,47.79,0,0,1,285,859.31Z"
            ></path>
            <path
              className="cls-17"
              d="M144.18,931s-4.82,5.45-9.82,6.36L121,939.8l21.61,34.35h13.34s.53-6.43-4.34-8-6-8-2-15.4,7.8-15.08,7.8-15.08L146,927.56Z"
            ></path>
            <polygon
              className="cls-12"
              points="940.16 704.02 937.43 701.52 951.67 688.45 955.2 690.25 940.16 704.02"
            ></polygon>
            <path
              className="cls-2"
              d="M1025.45,788.45s89.37,51.63,93,51.83,65.27-28.94,65.27-28.94l-9.58-21.62,9.58-11.71-25-8.39L1128.39,803a1.55,1.55,0,0,0-2.6-.59s-39.18-39.82-61.3-41.77C1064.49,760.59,1041.47,770,1025.45,788.45Z"
            ></path>
            <path
              className="cls-16"
              d="M948.43,706.69s-28.15-15.36-33.2-17.49a57.28,57.28,0,0,0-9.86-3.08s6-5.13,9-8A95.4,95.4,0,0,0,924.78,682c9,2.93,34.76,14.77,34.76,14.77Z"
            ></path>
            <path
              className="cls-5"
              d="M945,698.83,940.16,704s26.48,22.36,26.08,27.15-31.67,6-31.67,6l14,22.49s12.51-1.73,24.09,2.66,50.83,27.41,50.83,27.41a279.79,279.79,0,0,0,25.28-18.1c12.65-10.24,20.63-13.57,20.63-13.57s-31.4-12.11-48-18.36-35.39-22-43.38-29.05-24.75-20.06-24.75-20.06Z"
            ></path>
            <path
              className="cls-12"
              d="M965.74,732.1l-2,1.2a15.38,15.38,0,0,0,8.61-.88c4.92-1.85,5.72-3,5.72-3l-1.49-2.27Z"
            ></path>
            <polygon
              className="cls-12"
              points="934.57 737.16 930.98 737.16 943.15 759.74 948.55 759.64 934.57 737.16"
            ></polygon>
            <path
              className="cls-5"
              d="M1173.74,789.88,1153.39,825s15.07-6.3,29.59-13.23Z"
            ></path>
            <path
              className="cls-5"
              d="M1043.64,775.64s-1.44,6.21-12.21,16.26l-6-3.45Z"
            ></path>
            <polygon
              className="cls-15"
              points="1158.73 769.62 1183.68 778.01 1188.07 772.42 1163.19 763.77 1158.73 769.62"
            ></polygon>
            <polygon
              className="cls-15"
              points="1174.1 789.72 1183.68 811.34 1189.27 808.62 1181.68 786.39 1174.1 789.72"
            ></polygon>
            <polygon
              className="cls-16"
              points="1182.36 788.38 1198.58 781.47 1202.44 793.18 1186.48 800.44 1182.36 788.38"
            ></polygon>
            <polygon
              className="cls-16"
              points="1165.85 764.7 1178.45 769.08 1188.07 755.52 1175.3 752.06 1165.85 764.7"
            ></polygon>
            <path
              className="cls-18"
              d="M1198.58,781.47l24.36-10.58,11.84,47s-9.72,2.13-12.64-6-6.52-21-20.83-18.25Z"
            ></path>
            <path
              className="cls-18"
              d="M1175.3,752.06l14-24.22,41.38,16.63s-3.72,8.39-12.64,6.79-23.42-2.33-30.67,5.29Z"
            ></path>
            <path
              className="cls-11"
              d="M1165.85,764.7s11.1,2.72,15,1l-2.41,3.4Z"
            ></path>
            <path
              className="cls-11"
              d="M1186.48,800.44s-1.68-7.3-.69-13.52l-3.43,1.46Z"
            ></path>
            <path
              className="cls-11"
              d="M931.6,738.31s4.37,11.4,3.37,17.51l6-.17Z"
            ></path>
            <path
              className="cls-18"
              d="M252.43,657.19a5.44,5.44,0,0,1-3.33-1l.83-1.09a4.76,4.76,0,0,0,4.29.48,15.36,15.36,0,0,0,4.25-2.45l.8,1.11a16.34,16.34,0,0,1-4.64,2.64A7.11,7.11,0,0,1,252.43,657.19Z"
            ></path>
            <path
              className="cls-18"
              d="M256.9,672.4a12.08,12.08,0,0,1,1.37-18.79l.77,1.13a10.69,10.69,0,0,0-1.2,16.65Z"
            ></path>
            <path
              className="cls-10"
              d="M291.84,664.91a8.76,8.76,0,0,0-1.22,3.19l-1.28,5.72s1.87,10,.84,10a17.7,17.7,0,0,1-3.47-.89s-6.86,9.07-7.79,8.89-12.44-6.75-13.37-8.32.12-8.83.12-8.83-2.68-5.23-1.74-8.72,6-5.62,7.11-5.55,13.54,1.54,13.54,1.54Z"
            ></path>
            <path
              className="cls-18"
              d="M267,670.33a5.9,5.9,0,0,1-1.67,1.55,30.5,30.5,0,0,1-2.61,1.61.64.64,0,0,1-.27.11,2.21,2.21,0,0,1-.35-.08,1.11,1.11,0,0,0-.64.09,13.35,13.35,0,0,0-3.15,1.81,17.56,17.56,0,0,1-2,1.3c-.46.24-1.12.63-1.24-.08a4,4,0,0,1,.26-1.52c.13-.53.23-1.06.32-1.59a15.35,15.35,0,0,0,.17-5,28.94,28.94,0,0,1-.53-3.8,14.41,14.41,0,0,1,1-4.56,43.18,43.18,0,0,1,1.47-4.08A7.09,7.09,0,0,1,260,653c1.85-1.24,4.18-.86,6.27-.85a17.76,17.76,0,0,1,3.84.53,76.66,76.66,0,0,1,10.26,3.2c1,.36,12.6,4.6,12.18,5.78a25.06,25.06,0,0,0-.74,3.27,33.56,33.56,0,0,1-6.88-1.45c-1.27-.44-1.68-1.12-2.76-.13S280,665,279,666a15.07,15.07,0,0,1-2.39,2.18c-.93.63-2.62,1.75-3.07,0-.09-.35-.11-.71-.21-1,0-.09-.07-.19-.16-.22a.91.91,0,0,0-.23,0c-.24,0-.35-.29-.39-.53-.3-1.8.19-4-2.36-3.54a5.82,5.82,0,0,0-3.7,3.34c-.59,1.26-.63,2.65.62,3.46a.26.26,0,0,1,.13.14.21.21,0,0,1,0,.17A3.07,3.07,0,0,1,267,670.33Z"
            ></path>
            <path
              className="cls-17"
              d="M261,649.27c5.39-5.36,7.82-9.93,22.53-7.47s13,9.59,12.64,10.9-4,10.51-4,10.51-34-7.64-33.83-9.13S261,649.27,261,649.27Z"
            ></path>
            <path
              className="cls-18"
              d="M289,657.69a12.32,12.32,0,0,0-1.19,2.63l4.78,1.32,1.48-3.36Z"
            ></path>
            <path
              className="cls-17"
              d="M261.46,655.5s-23.2-3.45-23.91-5.35.32-2.81,2.06-2.45S259.4,654,259.4,654Z"
            ></path>
            <path
              className="cls-19"
              d="M267.66,685.27s-2.16-1-2.32-2.37,1.17-11.37,1.17-11.37-5.86,13.19-5.19,16.07,5.61,1.25,5.61,1.25Z"
            ></path>
            <path
              className="cls-20"
              d="M287.27,673.76c-.25,1-.94,1.66-1.53,1.57s-.86-1-.6-1.91.94-1.66,1.53-1.56S287.53,672.81,287.27,673.76Z"
            ></path>
            <path
              className="cls-20"
              d="M269.56,668.8h-.09a1,1,0,0,1-.84-1,1.67,1.67,0,0,1,.21-.75,3.87,3.87,0,0,0,.39-1.39l-.16.16a.64.64,0,0,1-.91,0,.65.65,0,0,1,0-.91,4.38,4.38,0,0,1,.7-.59,1.12,1.12,0,0,1,.52-.21,1,1,0,0,1,1,.51,1.48,1.48,0,0,1,.19.71,4.72,4.72,0,0,1-.5,2.2l-.06.13a.62.62,0,0,1,.24.6A.65.65,0,0,1,269.56,668.8Zm.1-1.28Z"
            ></path>
            <path
              className="cls-20"
              d="M288.89,670.87a.62.62,0,0,1-.47-.21,3.58,3.58,0,0,0-3.72-.95.64.64,0,1,1-.41-1.22,4.92,4.92,0,0,1,5.08,1.3.65.65,0,0,1,0,.91A.67.67,0,0,1,288.89,670.87Z"
            ></path>
            <path
              className="cls-20"
              d="M282.88,687.19a2.94,2.94,0,0,1-1.71-.55l-.24-.16a5.84,5.84,0,0,1-1.68-1.54.64.64,0,0,1,1.07-.71,4.93,4.93,0,0,0,1.35,1.19l.24.17a2,2,0,0,0,1.8.17l.21-.06a.64.64,0,0,1,.76.5.63.63,0,0,1-.49.76l-.13,0A4.23,4.23,0,0,1,282.88,687.19Z"
            ></path>
            <path
              className="cls-17"
              d="M977.69,715.1H977l0-1.51a32.9,32.9,0,0,0,7.27-.65,8.46,8.46,0,0,0,2.4-.78,6.58,6.58,0,0,0,1.53-1.25c2.69-2.84,3.65-7.31,3.65-10.55,0-2-1-4.28-1.73-6.15l-.06-.15,1.39-.57.06.15c.83,2,1.85,4.47,1.85,6.72,0,3.53-1.07,8.43-4.06,11.59a7.73,7.73,0,0,1-1.9,1.53,9.78,9.78,0,0,1-2.84.94A35.29,35.29,0,0,1,977.69,715.1Z"
            ></path>
            <path
              className="cls-17"
              d="M977.26,720.27c3.24-1.28,1-4.78,3.24-5.43s8.94-3.36,10.1-13.85-.52-15.67-8-18.91-24.42,1.88-26.93,5.07c-4.48,5.69.9,9.43.9,9.43Z"
            ></path>
            <path
              className="cls-10"
              d="M963.15,717.82c0,.39.26,14.12,0,15s17.22-.38,17.35-1.55-6.29-23.89-6.29-23.89-6.14,2-6,2.39-4.53,7.26-4.53,7.26Z"
            ></path>
            <path
              className="cls-10"
              d="M958.1,690.89a38.87,38.87,0,0,1-1.56,5.69c-.52.91-6.08,7.9-5.31,8.81s2.59,1.55,2.85,2.07.13,11.79,1.56,12.43,11.78.78,13.85-.9,3.63-10.36,4.15-10.23,4.92-2.46,4.14-6.09-3.92-.85-5.31-.91c-8-.33-5.05-7.12-6.09-8.67s-6.08-5.18-6.6-4.66A20.21,20.21,0,0,0,958.1,690.89Z"
            ></path>
            <path
              className="cls-18"
              d="M992.2,689a13.61,13.61,0,0,1-.44,3.38c-.29,1-.66,1.68-1,1.76-3.91.91-23.53-1-23.53-1L933,687.7c-1.31-.19-.49-1.74,5.1-1.67,6.15.06,15.18,1.37,16.44.65s3.23-6.93,7.44-10,16.75-5.63,24.53-.3C991,679.53,992.3,685,992.2,689Z"
            ></path>
            <path
              className="cls-17"
              d="M992.2,689a13.61,13.61,0,0,1-.44,3.38c-1.78.68-7,.5-7.35-.08s-.95-3.07,1.25-3.17C987.58,689.05,990.86,689.37,992.2,689Z"
            ></path>
            <path
              className="cls-21"
              d="M955.61,720.11c1,.23,9.3-1.06,11.2-1.88s7.66-9.59,7.66-9.59l.87,3.1s-4.15,11.58-6.75,11.81a16.6,16.6,0,0,1-5.4-.59l0-2.41h-3.73A14.61,14.61,0,0,1,955.61,720.11Z"
            ></path>
            <ellipse
              className="cls-20"
              cx="960.91"
              cy="700"
              rx="1.14"
              ry="2.28"
            ></ellipse>
            <path
              className="cls-20"
              d="M956,713a7.93,7.93,0,0,1-1.61-.17.73.73,0,0,1-.55-.85.71.71,0,0,1,.85-.54,6.28,6.28,0,0,0,4.07-.51.72.72,0,0,1,1,.32.71.71,0,0,1-.32.95A7.62,7.62,0,0,1,956,713Z"
            ></path>
            <path
              className="cls-20"
              d="M974.06,706.64a.69.69,0,0,1-.53-.24.7.7,0,0,1,.05-1q.76-.69,1.47-1.44a.78.78,0,0,1-.6-.28.7.7,0,0,1,.13-1,2.17,2.17,0,0,1,1.54-.46,1.09,1.09,0,0,1,.63,2,27.84,27.84,0,0,1-2.22,2.26A.71.71,0,0,1,974.06,706.64Z"
            ></path>
            <path
              className="cls-20"
              d="M964.18,696.8a.73.73,0,0,1-.35-.09,5.35,5.35,0,0,0-4.71-.29.71.71,0,1,1-.56-1.3,6.77,6.77,0,0,1,6,.36.71.71,0,0,1-.36,1.32Z"
            ></path>
            <path
              className="cls-22"
              d="M179.82,312.91s16.26,36.62,19.06,38.08,73.1-20,76.51-17.9,31.24,36.29,31.24,36.29l18.55-16.11s-39.54-49-42.14-49-9.44.65-9.44.65-1-7.48-2.28-7.81-59.23,3.58-59.23,3.58Z"
            ></path>
            <path
              className="cls-23"
              d="M169,231.56s-12-4.89-10.52,6.18,5.64,27.5,10.52,45.56,9.44,30.1,9.44,30.1L211.23,302s-12.12-10.43-20.61-18.54l13.82-13,2.45,2.56s-3.42-7.65-3.58-11.23,10.74-3.42,10.74-3.42l-1-20.66s-25.27-1.44-31.46-3.55C181.61,234.19,183.17,240.42,169,231.56Z"
            ></path>
            <path
              className="cls-24"
              d="M213.1,239.22l13.3,1.12s13.91-6.89,31.24-14.2,28.95-11.31,34.55-11.92,8.09-.62,8.82.41,5,7.32,5,7.32-1.17.62-2.72-.69a14.59,14.59,0,0,1-2.73-3.32l1.64,7.49s-1.64.84-2.57-1.93a30.78,30.78,0,0,0-1.87-4.48l-.62,9.8s-1.56,0-2-2.7a42.69,42.69,0,0,1-.39-6.48,28.33,28.33,0,0,0-1.63,12s-2.1.62-2.72-2.31-1.32-7.64-1.32-7.64a30.23,30.23,0,0,0-5.84,6.64s-2.41-2.32-6.15,0-41.24,26-44.2,26.48S213.9,256,213.9,256Z"
            ></path>
            <polygon
              className="cls-12"
              points="204.45 270.49 207.75 273.59 194.14 287.75 190.02 283.73 204.45 270.49"
            ></polygon>
            <polygon
              className="cls-12"
              points="214.04 258.4 218.44 258.4 217.14 237.96 213.07 237.74 214.04 258.4"
            ></polygon>
            <path
              className="cls-11"
              d="M207.75,273.59s-6.33,10.22-8.1,18.34l-5-4.7Z"
            ></path>
            <path
              className="cls-25"
              d="M217.18,238.64s1.69,12,3.37,17.06h-2.28Z"
            ></path>
            <path
              className="cls-22"
              d="M275.39,333.09,281,369.38l-23.65,3.2L252,337.8S271.16,332,275.39,333.09Z"
            ></path>
            <polygon
              className="cls-22"
              points="325.18 353.27 330.07 359.05 312 375.32 306.63 369.38 325.18 353.27"
            ></polygon>
            <polygon
              className="cls-22"
              points="281.03 369.38 282.06 376.21 258.8 379.96 257.38 372.58 281.03 369.38"
            ></polygon>
            <polygon
              className="cls-24"
              points="325.18 363.45 340.26 381.04 331.04 389.83 315.05 372.58 325.18 363.45"
            ></polygon>
            <polygon
              className="cls-24"
              points="277.19 377 281.03 398.4 268.67 400.25 264.31 379.07 277.19 377"
            ></polygon>
            <path
              className="cls-23"
              d="M330.07,388.62l17.73,24.7L379.37,385s-4.55-5.69-15.62-1.14S346.19,388,340.26,381Z"
            ></path>
            <path
              className="cls-23"
              d="M268.67,400.24l4,27.56,44.87-10.9s-.65-6.35-15.14-6.35-19.63-7.7-21.37-12.15Z"
            ></path>
            <path
              className="cls-25"
              d="M325.18,363.45s-4.48,7-4.57,15.13l-5.57-6Z"
            ></path>
            <path
              className="cls-25"
              d="M277.2,377s-7.28,2.75-11.59,8.39l-1.31-6.32Z"
            ></path>
            <path
              className="cls-12"
              d="M211.23,302l-8.13-6.94a27.87,27.87,0,0,1-16.58,15.32Z"
            ></path>
            <path
              className="cls-12"
              d="M168.34,231.33l.67-1.13s6.3,3.91,8.79,4.12a3,3,0,0,0,3.25-1.67l.56,1.54s1.24,2.19-2.12,2.46S168.34,231.33,168.34,231.33Z"
            ></path>
            <path
              className="cls-26"
              d="M1003.74,197.81c7.23-9,20.91-16.56,30-4.43s11.39,54.47,9,58.26-8.18,5.48-8.18,5.48l-.44,5.85a19.51,19.51,0,0,1-16.24,4.84c-10.27-1.42-26.46-1.57-36.53,2.31,0,0,3.2-11.71,1.27-18a58.06,58.06,0,0,1-1.55-8.49l9.28-5.56-12-6,1-5-6.45-15.62s-.12-17.41,16.45-13.38C989.37,198.11,995.69,203.54,1003.74,197.81Z"
            ></path>
            <path
              className="cls-16"
              d="M996.81,235.06s-4.74-.79-7.13-2.15-3.81-4.66-5.59-5.39a6,6,0,0,1-2.23-1.28l1.41,6.41s-.84,2.17-5.43,0-9-5.32-10.07-2.11c0,0,3.78,2.46,5.41,3.4a24.88,24.88,0,0,0,3,1.46,5.57,5.57,0,0,1-4.14-.27c-2.21-1-5.61-2.17-6,0a53.47,53.47,0,0,0,6.29,2.79c2.41.76,4,1.15,4,1.15a11.65,11.65,0,0,1-5.86-1c-3.56-1.42-3.65,1-3.65,1s3,1.28,4.59,1.76a52,52,0,0,0,5.23,1,10.69,10.69,0,0,1-5.33-.27c-2.42-.91-2.82,1-2.82,1s1.77.53,4.2,1.21,8.59.41,12.79-.07,9.51,1,9.51,1Z"
            ></path>
            <path
              className="cls-27"
              d="M1001.41,249.46s-3.51-9.07,0-14.6c0,0-2.71-2.14-4.61.2s-2.81,8.37-1.2,12S1001.41,249.46,1001.41,249.46Z"
            ></path>
            <path
              className="cls-27"
              d="M995.47,237.77c-1.62,2.19-2,6.55-2,6.55l1.42.35A17.81,17.81,0,0,1,995.47,237.77Z"
            ></path>
            <circle
              className="cls-27"
              cx="1011.57"
              cy="214.24"
              r="4.45"
            ></circle>
            <path
              className="cls-27"
              d="M1031.46,256.53s10.7,24.24,24.74,56.11,27.19,60.53,27.19,60.53l-23.68,6.73s-10.82-22.23-21.93-43-24-40.35-24-40.35a191.21,191.21,0,0,1-25.49-4.48c-13.11-3.32-14.38-1.76-14.08.39s8.09,23,8.09,23l-22.91-.69s-17.45-49-15.11-50.48,45.23,1.26,45.23,1.26Z"
            ></path>
            <path
              className="cls-26"
              d="M1013.8,296.56c10.82,2.19,24.71-13.6,24.71-13.6s5.43,31.32-7.89,41.1Z"
            ></path>
            <polygon
              className="cls-15"
              points="1083.39 373.17 1086.51 379.89 1063.41 386.42 1059.71 379.89 1083.39 373.17"
            ></polygon>
            <polygon
              className="cls-15"
              points="982.32 315.47 984.46 321.7 961.95 321.7 959.41 314.79 982.32 315.47"
            ></polygon>
            <polygon
              className="cls-16"
              points="978.42 321.7 983.68 335.45 972.57 335.45 967.09 321.7 978.42 321.7"
            ></polygon>
            <polygon
              className="cls-16"
              points="1079.77 381.8 1085.73 393.05 1076.81 400.14 1068.83 384.89 1079.77 381.8"
            ></polygon>
            <path
              className="cls-5"
              d="M978.42,321.7l1.79,4.7s-3.84-3.31-13.12-4.7Z"
            ></path>
            <path
              className="cls-11"
              d="M1079.77,381.8a28,28,0,0,0-9.09,6.61l-1.85-3.52Z"
            ></path>
            <path
              className="cls-17"
              d="M983.68,335.45s9.72,21.71,9.8,25.29H954.73s-.65-5.7,5-6.94,15.36-4.17,15.06-8.77a38.18,38.18,0,0,0-2.19-9.58Z"
            ></path>
            <path
              className="cls-16"
              d="M973,211.53l-11.4,6.95s-15.52.06-34.28,1.28-31,2.82-36.24,4.78-7.51,3.06-7.7,4.31-1.18,8.78-1.18,8.78,1.32,0,2.12-1.84a14.59,14.59,0,0,0,1-4.18l1.88,7.42s1.84,0,1.44-2.87a29.55,29.55,0,0,1-.33-4.84l4.93,8.48s1.4-.69.61-3.32a41.9,41.9,0,0,0-2.55-6,31.81,31.81,0,0,1,4.78,5.91,32.67,32.67,0,0,1,2.06,4.13s2.15-.39,1.4-3.29-2.23-7.42-2.23-7.42a30.23,30.23,0,0,1,8.18,3.32s1.13-3.15,5.5-2.75,48.54,4.85,51.38,3.92,17-7.21,17-7.21Z"
            ></path>
            <path
              className="cls-17"
              d="M1085.73,393.05s3.87,4.39,7.9,5.12l10.74,2L1087,427.75h-10.72s-.42-5.17,3.49-6.43,4.79-6.44,1.58-12.38-6.28-12.13-6.28-12.13l9.21-6.5Z"
            ></path>
            <path
              className="cls-16"
              d="M938.44,738.12H931.7s-17.3-10.87-26.61-18-12.64-7.85-13.84-13.84a47.25,47.25,0,0,0-2-7.85s-2.88,1.74-5.85,3.85c-2.8-.66-4.06-6-5.26-10.34s-4.49-6.49-5.89-5.59c0,0,2.5,4.29,2.9,7.08s1,3.6,1,3.6a20.79,20.79,0,0,1-3.9-6.09c-1.59-3.79-4.69-2.63-4.69-2.63s1.8,3.13,2.6,5,2.19,5.09,2.19,5.09a10.85,10.85,0,0,1-3.69-3.5c-1.3-2.19-4.76-3-5.09-1.83a21.39,21.39,0,0,1,2.2,2.66c.93,1.4,2.52,5,4.92,7.06A6,6,0,0,1,865,700.9a3.92,3.92,0,0,0-5.13-.93s2.87,2.6,5.46,5.26,7.65,4.79,12.31,7.38,44.84,42.25,49.43,42.85,20.75,0,20.75,0Z"
            ></path>
            <path
              className="cls-23"
              d="M268.38,210.6C268.38,308,347.4,387,444.89,387H501.3c2.26,0,3.63-.4,4.06-1.2.61-1.13-.78-3.25-1.24-3.94a26.06,26.06,0,0,1-4.35-14.36c0-15.21,13.49-27.73,30.15-27.91,16.54.18,30,12.7,30,27.91a26.13,26.13,0,0,1-4.34,14.36c-.46.69-1.85,2.81-1.25,3.94.44.8,1.8,1.2,4.06,1.2h63V324c0-5.24,1.73-8.83,5.15-10.67s7.29-1.29,11.64,1.58a18.62,18.62,0,0,0,10.23,3.11c11.12,0,20.28-10.12,20.41-22.48-.13-12.47-9.29-22.62-20.41-22.62a18.62,18.62,0,0,0-10.23,3.11c-4.35,2.87-8.27,3.4-11.64,1.58s-5.15-5.43-5.15-10.67V210.6c0-97.44-79-176.43-176.51-176.43S268.38,113.16,268.38,210.6Z"
              onMouseOver={() => setVisionIsActive(true)}
              onMouseOut={() => setVisionIsActive(false)}
              onClick={pushToVision}
            ></path>
            <circle
              className="cls-22"
              cx="445.27"
              cy="208.91"
              r="143.05"
              onMouseOver={() => setVisionIsActive(true)}
              onMouseOut={() => setVisionIsActive(false)}
              onClick={pushToVision}
            ></circle>
            <g>
              <path
                className="cls-23"
                d="M514,282.22a50,50,0,0,0,50,50h16c.64,0,1-.12,1.15-.34s-.22-.92-.35-1.12a7.37,7.37,0,0,1-1.24-4.07c0-4.32,3.83-7.87,8.55-7.92s8.5,3.6,8.5,7.92a7.45,7.45,0,0,1-1.23,4.07c-.13.2-.53.8-.35,1.12s.51.34,1.15.34H614V314.39a2.85,2.85,0,0,1,4.76-2.58,5.23,5.23,0,0,0,2.9.88c3.15,0,5.75-2.87,5.78-6.37s-2.63-6.42-5.78-6.42a5.24,5.24,0,0,0-2.9.89,2.85,2.85,0,0,1-4.76-2.58v-16a50,50,0,1,0-100.06,0Z"
                onMouseOver={() => setVisionIsActive(true)}
                onMouseOut={() => setVisionIsActive(false)}
                onClick={pushToVision}
              ></path>
              <circle
                className="cls-28"
                cx="566.2"
                cy="282.65"
                r="33.01"
                onMouseOver={() => setVisionIsActive(true)}
                onMouseOut={() => setVisionIsActive(false)}
                onClick={pushToVision}
              ></circle>
              <path
                className="cls-29"
                d="M599.21,282.65a33,33,0,0,1-66,0c0-5.82,9.37,13,12,8.25,5.63-10.13,14.67-19.64,27-21,27-3,2.9-19.33,6.85-17.66a33,33,0,0,1,20.16,30.42Z"
                onMouseOver={() => setVisionIsActive(true)}
                onMouseOut={() => setVisionIsActive(false)}
                onClick={pushToVision}
              ></path>
              <circle
                className="cls-8"
                cx="565.48"
                cy="281.89"
                r="29.09"
                onMouseOver={() => setVisionIsActive(true)}
                onMouseOut={() => setVisionIsActive(false)}
                onClick={pushToVision}
              ></circle>
              <circle
                className="cls-30-vision"
                cx="565.48"
                cy="281.89"
                r="23.27"
                onMouseOver={() => setVisionIsActive(true)}
                onMouseOut={() => setVisionIsActive(false)}
                onClick={pushToVision}
              ></circle>
              <g>
                <circle
                  className="cls-23"
                  cx="566.32"
                  cy="282.53"
                  r="4.8"
                  onMouseOver={() => setVisionIsActive(true)}
                  onMouseOut={() => setVisionIsActive(false)}
                  onClick={pushToVision}
                ></circle>
                <path
                  className="cls-23"
                  d="M566,291.09c-4.39,0-9.55-1.87-14.63-8l-.44-.53.46-.52c8-9.19,15.68-8.86,20.74-7,5.27,2,9.29,6.33,9.29,7.5s-4.31,5.59-9.72,7.53A16.78,16.78,0,0,1,566,291.09Zm-12.92-8.54c4.52,5.25,9.53,7.53,14.91,6.77a19.45,19.45,0,0,0,11.72-6.8,18.73,18.73,0,0,0-11-6.74C563.23,274.86,558,277.14,553.1,282.55Zm26.74.16Zm0-.37Z"
                  onMouseOver={() => setVisionIsActive(true)}
                  onMouseOut={() => setVisionIsActive(false)}
                  onClick={pushToVision}
                ></path>
              </g>
            </g>
            <path
              className="cls-27"
              d="M799.74,37.13A173.63,173.63,0,0,0,626.11,210.76v55.49c0,2.22.4,3.57,1.19,4,1.11.6,3.19-.77,3.88-1.22a25.64,25.64,0,0,1,14.12-4.28c15,0,27.3,13.27,27.47,29.66-.17,16.27-12.49,29.51-27.47,29.51a25.64,25.64,0,0,1-14.12-4.28c-.69-.45-2.77-1.82-3.88-1.22-.79.42-1.19,1.77-1.19,4v62h62c5.15,0,8.68,1.7,10.5,5.06s1.26,7.17-1.55,11.45A18.34,18.34,0,0,0,694,411c0,10.94,10,19.95,22.11,20.08,12.28-.13,22.26-9.14,22.26-20.08a18.34,18.34,0,0,0-3.06-10.07c-2.81-4.28-3.34-8.13-1.55-11.45s5.35-5.06,10.5-5.06h55.49a173.63,173.63,0,0,0,0-347.26Z"
              onMouseOver={() => setCompetenceIsActive(true)}
              onMouseOut={() => setCompetenceIsActive(false)}
              onClick={pushToCompetence}
            ></path>
            <circle
              className="cls-26"
              cx="801.88"
              cy="207.75"
              r="136.62"
              onMouseOver={() => setCompetenceIsActive(true)}
              onMouseOut={() => setCompetenceIsActive(false)}
              onClick={pushToCompetence}
            ></circle>
            <g>
              <path
                className="cls-27"
                d="M736.3,261.87a54.9,54.9,0,0,0-54.9,54.91v17.54c0,.7.13,1.13.38,1.26s1-.24,1.22-.38a8.08,8.08,0,0,1,4.47-1.35c4.73,0,8.63,4.19,8.68,9.37s-3.95,9.33-8.68,9.33A8.17,8.17,0,0,1,683,351.2c-.21-.14-.87-.58-1.22-.39s-.38.56-.38,1.27v19.6H701a3.42,3.42,0,0,1,3.32,1.6c.57,1.05.4,2.26-.49,3.62a5.8,5.8,0,0,0-1,3.18c0,3.46,3.15,6.31,7,6.35s7-2.89,7-6.35a5.79,5.79,0,0,0-1-3.18c-.89-1.36-1.06-2.57-.49-3.62a3.42,3.42,0,0,1,3.32-1.6H736.3a54.91,54.91,0,0,0,0-109.81Z"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></path>
              <circle
                className="cls-31"
                cx="738.98"
                cy="317.91"
                r="36.23"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></circle>
              <path
                className="cls-32"
                d="M775.21,317.91a36.23,36.23,0,0,1-72.45,0c0-6.38,10.27,14.26,13.17,9,6.18-11.12,16.1-21.55,29.64-23,29.64-3.29,3.18-21.22,7.51-19.38a36.23,36.23,0,0,1,22.13,33.38Z"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></path>
              <circle
                className="cls-8"
                cx="738.2"
                cy="317.07"
                r="31.92"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></circle>
              <circle
                className="cls-33"
                cx="738.2"
                cy="317.07"
                r="25.53"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></circle>
              <path
                className="cls-27"
                d="M753.61,320.41v-5.92a1,1,0,0,0-.09-1.84l-15.08-6.54a1,1,0,0,0-.83,0l-15.22,6.52a1,1,0,0,0,0,1.89l5.82,2.51v7.14l9.48,4.65,9.89-4.6v-7.1l4.79-2.08v5.37l-.53,2.22a1.17,1.17,0,0,0,.61,1.06,4.33,4.33,0,0,0,1.08,0,1.17,1.17,0,0,0,.61-1.06ZM738,307.26l14.6,6.34L738,319.93l-14.72-6.36Zm8.33,16.18-8.65,4-8.26-4.06v-5.84l8.16,3.52h0a1.06,1.06,0,0,0,.42.09,1.07,1.07,0,0,0,.43-.09l7.9-3.43Z"
                onMouseOver={() => setCompetenceIsActive(true)}
                onMouseOut={() => setCompetenceIsActive(false)}
                onClick={pushToCompetence}
              ></path>
            </g>
            <g>
              <g>
                <path
                  className="cls-3"
                  d="M507.89,497.28a51,51,0,0,0,51-51V429.93c0-.65-.12-1-.35-1.17s-.93.23-1.14.36a7.55,7.55,0,0,1-4.15,1.26c-4.4,0-8-3.9-8.07-8.72s3.67-8.67,8.07-8.67a7.54,7.54,0,0,1,4.15,1.25c.2.14.82.54,1.14.36s.35-.52.35-1.17V395.21H540.7a2.9,2.9,0,0,1-2.63-4.85,5.38,5.38,0,0,0,.9-3c0-3.22-2.92-5.86-6.5-5.9s-6.54,2.68-6.54,5.9a5.38,5.38,0,0,0,.9,3,3.17,3.17,0,0,1,.46,3.36,3.21,3.21,0,0,1-3.09,1.49H507.89a51,51,0,0,0,0,102.07Z"
                  onMouseOver={() => setRedIsActive(true)}
                  onMouseOut={() => setRedIsActive(false)}
                  onClick={pushToRed}
                ></path>
                <circle
                  className="cls-34"
                  cx="510.28"
                  cy="446.97"
                  r="33.67"
                  onMouseOver={() => setRedIsActive(true)}
                  onMouseOut={() => setRedIsActive(false)}
                  onClick={pushToRed}
                ></circle>
                <path
                  className="cls-35"
                  d="M544,447a33.67,33.67,0,1,1-67.34,0c0-5.93,9.55,13.26,12.24,8.42,5.75-10.34,15-20,27.55-21.43,27.55-3.06,3-19.72,7-18A33.71,33.71,0,0,1,544,447Z"
                  onMouseOver={() => setRedIsActive(true)}
                  onMouseOut={() => setRedIsActive(false)}
                  onClick={pushToRed}
                ></path>
                <circle
                  className="cls-8"
                  cx="509.55"
                  cy="446.2"
                  r="29.67"
                  onMouseOver={() => setRedIsActive(true)}
                  onMouseOut={() => setRedIsActive(false)}
                  onClick={pushToRed}
                ></circle>
                <circle
                  className="cls-36"
                  cx="509.55"
                  cy="446.2"
                  r="23.73"
                  onMouseOver={() => setRedIsActive(true)}
                  onMouseOut={() => setRedIsActive(false)}
                  onClick={pushToRed}
                ></circle>
              </g>
              <path
                className="cls-3"
                d="M518.81,446.55a1.93,1.93,0,0,0-1.49.09,2.27,2.27,0,0,0-1.22-.93,2.31,2.31,0,0,0-1.7.09,2.37,2.37,0,0,0-1.11-1.1,7.1,7.1,0,1,0-6.42.79v3.56l-.54-.61c-.57-.64-1.06-1.19-1.56-1.69a2.6,2.6,0,0,0-3.3.17,2.17,2.17,0,0,0-.26,3.13c1.37,1.93,7,10.33,7,10.41a.62.62,0,0,0,.53.28h8.41a.63.63,0,0,0,.63-.62v-.81l2.43-4.49a.67.67,0,0,0,.08-.3v-5.79A2.33,2.33,0,0,0,518.81,446.55Zm-11.94-8.09v3.27a3.73,3.73,0,1,1,6.18-2.82,3.68,3.68,0,0,1-1.61,3v-3.56A2.41,2.41,0,0,0,509,436,2.16,2.16,0,0,0,506.87,438.46Zm-3.4.39a5.84,5.84,0,1,1,8,5.41v-.85a5,5,0,1,0-4.57-.16v.88A5.86,5.86,0,0,1,503.47,438.85Zm15.58,15.51-2.43,4.5a.61.61,0,0,0-.08.3v.33h-7.45c-1-1.51-5.6-8.4-6.85-10.17-.42-.59-.41-1.06.05-1.44a1.43,1.43,0,0,1,1.59-.24c.47.47.95,1,1.51,1.64s1,1.14,1.65,1.83a.62.62,0,0,0,.69.15.6.6,0,0,0,.4-.58V438.46c0-.76.31-1.18.87-1.18a1.17,1.17,0,0,1,1.18,1.11v7.48a.63.63,0,0,0,1.17.32,1,1,0,0,1,1.19-.44,1.08,1.08,0,0,1,.82,1.1.63.63,0,0,0,.4.61.65.65,0,0,0,.71-.18,1.13,1.13,0,0,1,1.24-.37.92.92,0,0,1,.68.82.61.61,0,0,0,.44.55.62.62,0,0,0,.68-.2.79.79,0,0,1,.88-.34,1,1,0,0,1,.66,1Z"
                onMouseOver={() => setRedIsActive(true)}
                onMouseOut={() => setRedIsActive(false)}
                onClick={pushToRed}
              ></path>
            </g>
            <path
              className="cls-16"
              d="M299.6,717.47l12,6.66s11.55-3.46,21.47-5.55,16.28-6,18.22-8.43,2.61-4.79,4.79-6.37a16.58,16.58,0,0,0,3.76-4.12s1.63.43.67,3.21-3.76,3.7-3,6,6.25.18,9-1.82,8.06-4.37,9.58-1.88a22.6,22.6,0,0,0-6.85,4c-2.85,2.61-2.79,2.18-2.79,2.18s3.09-1.27,6.85-2.73,5,.55,5,.55-1.94,1-4.06,1.9-4.55,2.71-7.4,3.8A13.38,13.38,0,0,0,373,714c3.39-1.27,4.67,1.4,4.67,1.4a40.39,40.39,0,0,1-12.37,2.85,26.71,26.71,0,0,0,7.15,2.12c3.76.48,3,3,3,3a17.67,17.67,0,0,0-3.69-1c-2.25-.37-6.49-.18-10.13-.18s-7.58-.43-10.86,1.15-36.44,18.8-40.44,18.55S288,729,288,729Z"
            ></path>
            <g>
              <path
                className="cls-24"
                d="M186.57,196.78c-.11.13.23,1.13.27,1.29.24,1.17.47,2.34.7,3.52.62,3.18.15,5.13,2.52,7.54a35.74,35.74,0,0,1,2.9,4c.14.22.27.52.12.73a.72.72,0,0,1-.45.2c-.88.16-2.42.07-2.83,1s.3,2.26.37,3.17c0,.46.43,3.41-.4,3.52-2,.27-15.4-2.06-15.4-2.06l-2.92-.43a10.83,10.83,0,0,1-4.55-7.8c-.64-5.43,5.46,3.81,6.49,1.67s-.86-12,4.19-13.08A52,52,0,0,0,186.57,196.78Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-24"
                d="M182.48,224.06,178,235.27s-7,1.84-12.15-3.25l3.54-16,4.94,3.7Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-37"
                d="M175.11,200.34l.11,1.71,1.41,6s2.46,9.36,3.91,9.77,9.28-3.67,9.44-2.33,2.35,12.19-.23,13-13.16-.47-14.4-2.2-3.83-19-3.83-19Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-25"
                d="M180.92,228.14l-.61,1.39s-5.14.75-6.68-.62S171.72,213,171.72,213l.81.63s1.74,11.61,3.22,12.82C177.47,227.82,180.92,228.14,180.92,228.14Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-37"
                d="M183.88,208.82c.16,1.05.66,1.84,1.12,1.77s.68-1,.51-2-.67-1.83-1.12-1.76S183.71,207.78,183.88,208.82Z"
              ></path>
              <path
                className="cls-8"
                d="M187.82,219.2a8.06,8.06,0,0,1-2.07-.33l.3-1a6.69,6.69,0,0,0,4.15-.07l.33.95A7.8,7.8,0,0,1,187.82,219.2Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-37"
                d="M180.53,205a.47.47,0,0,1-.43-.27.5.5,0,0,1,.21-.68,12.36,12.36,0,0,1,5.62-1.39.51.51,0,0,1,.51.51.5.5,0,0,1-.51.5,11,11,0,0,0-5.15,1.28A.56.56,0,0,1,180.53,205Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-25"
                d="M169.2,215.78a.49.49,0,0,1-.43-.26,5.57,5.57,0,0,1-.6-1.67,1.67,1.67,0,0,1,.27-1.47,1.09,1.09,0,0,1,1.1-.31.85.85,0,0,1,.64.69.5.5,0,0,1-.41.58.53.53,0,0,1-.54-.28c-.12.11-.12.32-.07.6a4.84,4.84,0,0,0,.49,1.38.49.49,0,0,1-.2.68A.37.37,0,0,1,169.2,215.78Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-38"
                d="M187.93,197.69c-.47-.57-1-1.22-1.67-2-1.41-.51-6.19.1-6.66,2.3-.92,1.73-6.53,6.09-6.57,8.16-.07,4.13,1.7,9.38,3.16,13.11-3.49-6.16-2.75-6.14-5.79-10.18a3.23,3.23,0,0,0-3.57-1.53c-5.77,1.7-2.61,5.58-2.69,9.23a12.2,12.2,0,0,1-1.16,4.24l-.08,0c-1.14-.19-4.25,1.83-6.31.45-.57-.39-.9.4-1.8-.57-.23-.25.23-.79,0-1.05s-.81.39-1.36-.24c-.87-1,.24-1.17,0-1.45s-1.32.9-2.17-.21c-.67-.89.53-1.41.33-1.68-.38-.53-1.17.75-2-.67-.49-.89.82-1,.48-1.62-.22-.41-1,.25-1.65-.84s.91-1.23.6-1.77-1.11.25-1.46-.94c-.23-.82.73-1.06.58-1.51s-1.31-.48-1.55-2.28c-.18-1.4,1-.86,1-1.79s-1.48-1.15-.87-3.93c.2-.9,1.33-.71,1.56-1.62s-1.05-.52-.57-2.55c.56-2.39,1.91-1.28,2.16-1.81s-.89-.84.06-2.26c.33-.52,1.49-.38,1.87-.87s-.17-.75.65-1.62c.54-.58,1.12-.42,1.43-.71s-.43-.67.25-1.31,1.41-.1,1.78-.35.4-.83,1.44-1.43,1.33-.25,2.16-.66,1-1.1,2.33-1.58c.83-.33,1.79-.14,2.59-.39s1.6-1,2.39-1.21c1-.22,2,.2,3,.09a15,15,0,0,1,3.25-.52,15.06,15.06,0,0,1,2.33.49c.87.1.85-.46,2.59,0,.56.13,1.08.68,1.6.86s1.06-.07,1.78.24c1,.42.73.72,1.2,1s1.34,0,1.76.28.45,1.08.87,1.42,1.31.47,1.67.85.17,1,.44,1.36a19.62,19.62,0,0,1,1.29,1.49,5.82,5.82,0,0,1,.59,1.81,6.18,6.18,0,0,1-.2,1.84,7.83,7.83,0,0,1-.23,2,16.14,16.14,0,0,1-.91,2Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-39"
                d="M177.51,200.44c-.63.61-1.29,1.29-1.9,1.94h0a11.07,11.07,0,0,0-2.47,3.3c-1.06,1.23.4,7.23-.61,7.91a32.79,32.79,0,0,0-3.82-3.16c-2-1.41-2.16-1-1.5,3,.2,1.26,2,2.49,1.93,3.86a47.45,47.45,0,0,1-1.84,8.32L160.72,222c-1.15-.19-2.07.82-4.13-.56-.57-.39-.9.4-1.8-.57-.23-.25.23-.79,0-1.05s-.81.39-1.36-.24c-.87-1,.24-1.17,0-1.45s-1.32.9-2.17-.21c-.67-.89.53-1.41.33-1.68-.38-.53-1.17.75-2-.67-.49-.89.82-1,.48-1.62-.22-.41-1,.25-1.65-.84s.91-1.23.6-1.77-1.11.25-1.46-.94c-.23-.82.73-1.06.58-1.51s-1.31-.48-1.55-2.28c-.18-1.4,1-.86,1-1.79,0-.66-.76-1-1-2.11a4.14,4.14,0,0,1,2,.69c1.06.7,1.19-.93,2-.7,1.39.42.48,1.38,2.31,2.15.68.28,1.06-.55,1.67-.52a.89.89,0,0,1,.61,1.55c-.61.77,2.41,1.72,3.23.73,1.32-1.59,2.85.41,3.85-.13s.34-2.12,2.6-1.21c1.32.54,1.15-2.22,2.44-1.46,3.36,1.95,1.58-1.93,3-1.94,2.3,0,3.82.56,4.59-2.91Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
              <path
                className="cls-39"
                d="M187.93,197.69c-.47-.57-2.11,1.76-2.76,1-1.41-.51-3.28-.95-3.75,1.25-.87,1.64-7.71,3.66-8.31,5.85-.05-2.49,1-4.55,1.82-6.81s2.47-3,2.78-4.71c.29-1.49,1.34-.63,1.91-.93s.93-1.1,1.48-1.31a2.55,2.55,0,0,1,1.51.1c1.06.36,1.17-.41,2,0,1.63.84,1.36,1.52,2.33,2,.8.38,1.5.35,2,.72l.08,0a3.14,3.14,0,0,1-.17.77,16.14,16.14,0,0,1-.91,2Z"
                onMouseOver={() => setInfraIsActive(true)}
                onMouseOut={() => setInfraIsActive(false)}
                onClick={pushToInfrastructure}
              ></path>
            </g>
            <path
              className="cls-24"
              d="M204.45,270.49l12.36,11.62s42.85-12.91,47.41-16.82,4.77-5.09,6.94-6.83,5.1-3.69,5.1-3.69v6.08s5.48.73,9.06-.73,5.2-2.28,6-.33c0,0-2,1.46-3.58,2.44a20.85,20.85,0,0,1-3.66,1.71,12.47,12.47,0,0,0,5.69-1.3c2.36-1.38,3.75-1.22,4.4.08a10.4,10.4,0,0,1-4,2.77,31,31,0,0,1-5.53,1.3,8.74,8.74,0,0,0,6.1-.41c3-1.46,5.13-2,5.94-.08,0,0-3.83,2.69-6.19,3.17s-5.93.82-5.93.82,1.22.4,6.1.4,4.63,2.85,4.63,2.85a20.5,20.5,0,0,0-6.59-.57,121.6,121.6,0,0,1-14.88.73c-5.62-.16-54.92,31.08-57.85,31.24s-25.38-21.48-25.38-21.48Z"
            ></path>
            <path
              className="cls-23"
              d="M976.53,160.67c.3,0,.59,0,.89,0a8.57,8.57,0,0,0,5.08-1.93,12.42,12.42,0,0,0,2.72-4.18l.45-.94c1.07-2.16,2.26-3.4,3.64-3.8a7.62,7.62,0,0,1,3.62.17,9.2,9.2,0,0,0,4,.18l-.27-1a8.07,8.07,0,0,1-3.53-.19,8.61,8.61,0,0,0-4.13-.15c-1.68.49-3.09,1.91-4.3,4.35l-.46,1a11.27,11.27,0,0,1-2.46,3.84,7.62,7.62,0,0,1-4.45,1.67,11.93,11.93,0,0,1-4.7-.64l-.35,1A12.72,12.72,0,0,0,976.53,160.67Z"
            ></path>
            <path
              className="cls-23"
              d="M1022.1,178.75l1.37-.39a15.2,15.2,0,0,0,4.13-1.63,8.07,8.07,0,0,0-.88-14.12,16.25,16.25,0,0,0-2.66-.88,18.87,18.87,0,0,1-1.93-.59,9.5,9.5,0,0,1-5.25-5c-.17-.41-.32-.85-.48-1.28a9.09,9.09,0,0,0-1.46-3,7.85,7.85,0,0,0-3.18-2.08,22.46,22.46,0,0,0-11.89-1.38l.17,1.06a21.26,21.26,0,0,1,11.32,1.31,7.08,7.08,0,0,1,2.76,1.77,8.35,8.35,0,0,1,1.27,2.65c.16.46.32.92.51,1.36a10.43,10.43,0,0,0,5.85,5.61,19.81,19.81,0,0,0,2,.62,16,16,0,0,1,2.48.82,7,7,0,0,1,.76,12.25,14.81,14.81,0,0,1-3.86,1.5l-1.37.39Z"
            ></path>
            <path
              className="cls-23"
              d="M975.37,184.12c-.3.22,3.25,4.83,3,5,2.6.5-5.91,12.17-4,14,1.42,1.35,10.34,5,12,4,8-5,4.09-15.52,6-16,1.22-.31-1.2-3.62,0-4a2.19,2.19,0,0,1,2.49.34,2,2,0,0,0,1.4,1.16,6.18,6.18,0,0,0,1.87.1c.86-.05,6.24,10.4,19.24,17.4,5.91,3.18,14.77-7.6,15-9,2-12-11.53-16.57-13-19s2-5-1-10c-.67-1.12,2-7-2.25-9.55-1.44-.86-1.72-2.88-2.73-4.2a14.86,14.86,0,0,0-7.87-5,17.39,17.39,0,0,0-4.54-.52c-1.87,0-3.68-.51-5.6-.24a8.51,8.51,0,0,0-5.4,2.82,13.94,13.94,0,0,0-2.22,4.74,6.94,6.94,0,0,1-2.88,3.71,16.91,16.91,0,0,0-4,2.86c-1.92,2.32-1.58,5.68-1.88,8.68a4.7,4.7,0,0,1-.7,2.3,5.49,5.49,0,0,1-1.47,1.28c-1.9,1.28-2.09,1.87-2.51,4.13C974.14,180.37,975.37,184.12,975.37,184.12Z"
            ></path>
            <path
              className="cls-10"
              d="M991.37,200.12c6,3,14-3,14-3s-4.73-5.93-5-7,1-2.13-2.85-1.89c-3.39.21-1.31-4.16.4-7.19.22-.36.42-.71.6-1a14.07,14.07,0,0,0,1.49-6.43c0-.57,0-.92,0-.92s-8.39,4.47-8.49,8.25a2.41,2.41,0,0,0,.11.76,10.11,10.11,0,0,1,.2,4C991.44,189,991.37,200.12,991.37,200.12Z"
            ></path>
            <path
              className="cls-19"
              d="M991.63,181.68a10.11,10.11,0,0,1,.2,4s3.65-2.82,6.09-4.6c.22-.36.42-.71.6-1a14.07,14.07,0,0,0,1.49-6.43c-.66,1.75-1.44,3.34-2.24,3.91a49.38,49.38,0,0,1-6.25,3.42A2.41,2.41,0,0,0,991.63,181.68Z"
            ></path>
            <path
              className="cls-10"
              d="M997.71,155.81c-1.6,2.41.69,7.69.58,9.06s.11,2.3.8,2.18,2.06-3.44,4-1.83,2.07,6.42,0,7.45-3.09,0-3.09,0S998.4,178,997.14,179s-8.49,4.24-9.86,3.79-1.95-10.33-2.3-10.33a4.75,4.75,0,0,1-2.29-1.14c-.69-.69,4.36-6.54,4.7-8s1.72-6.65,4-6.88S997.71,155.81,997.71,155.81Z"
            ></path>
            <ellipse
              className="cls-20"
              cx="990.91"
              cy="165.44"
              rx="0.91"
              ry="1.82"
            ></ellipse>
            <path
              className="cls-20"
              d="M1002.08,171.64a.51.51,0,0,0,.28-.09,1.21,1.21,0,0,0,.27-1.61,3.63,3.63,0,0,0-.43-.55,2.34,2.34,0,0,1-.25-.3,1.24,1.24,0,0,1-.19-1,.53.53,0,0,1,.19-.33.48.48,0,0,0,.61.33.54.54,0,0,0,.36-.64.93.93,0,0,0-.5-.6,1.09,1.09,0,0,0-.9,0,1.45,1.45,0,0,0-.74,1,2.34,2.34,0,0,0,.34,1.8,4.23,4.23,0,0,0,.34.4,2.91,2.91,0,0,1,.29.37.41.41,0,0,1,0,.28.49.49,0,0,0-.15.69A.51.51,0,0,0,1002.08,171.64Z"
            ></path>
            <path
              className="cls-20"
              d="M993.77,163a.53.53,0,0,0,.4-.19.51.51,0,0,0-.09-.71,6.65,6.65,0,0,0-4-1.36.5.5,0,0,0-.5.51.52.52,0,0,0,.51.5h.06a5.43,5.43,0,0,1,3.33,1.14A.53.53,0,0,0,993.77,163Z"
            ></path>
            <path
              className="cls-20"
              d="M985.28,173.93c.41-.12.38.28,1.48,1a2.79,2.79,0,0,0,2.5.46,7.87,7.87,0,0,1-1.3,1.15,4.26,4.26,0,0,1-1.16.6c-.42.12-.8.34-1.17.11s0-1.17-.29-1.56A1.68,1.68,0,0,1,985.28,173.93Z"
            ></path>
          </g>
        </g>
      </S.SVG>
    </S.Wrapper>
  );
}

FourLeafClover2.propTypes = {
  className: PropTypes.string,
};

export default FourLeafClover2;
