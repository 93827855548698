import * as S from "./styles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Card({
  title,
  buttonText,
  dimension,
  children,
  backgroundName,
  heading,
  link,
  to,
  disabled,
}) {
  return (
    <S.Wrapper
      backgroundName={backgroundName}
      dimension={dimension}
      disabled={disabled}
    >
      <div>EM BREVE</div>
      <div>
        <div>
          <span>{heading}</span>
          <h1>{title}</h1>
        </div>
        {children && <p>{children}</p>}
        {to && !disabled ? (
          <Link to={to}>{buttonText}</Link>
        ) : (
          !disabled && (
            <a target="_blank" rel="noreferrer" href={link}>
              {buttonText}
            </a>
          )
        )}
      </div>
    </S.Wrapper>
  );
}

const DIMENSIONS = ["vision", "competence", "red", "infra"];

Card.propTypes = {
  title: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  dimension: PropTypes.oneOf(DIMENSIONS).isRequired,
  children: PropTypes.string,
  backgroundName: PropTypes.string.isRequired,
  heading: PropTypes.string,
};

export default Card;
