import styled from "styled-components";

const BORDER_AND_HEADER_COLORS = {
  vision: "rgb(232, 197, 0)",
  competence: "rgb(231, 35, 113)",
  red: "rgb(0, 155, 212)",
  infra: "rgb(152, 212, 78)",
};

const BACKGROUND = {
  vision: "rgb(212, 180, 0)",
  competence: "rgb(212, 32, 104)",
  red: "rgb(0, 139, 188)",
  infra: "rgb(134, 189, 69)",
};

export const Wrapper = styled.div`
  display: inline-flex;
  width: 35rem;
  flex-direction: column;
  border-radius: 2rem;
  align-items: center;
  border: 0.2rem solid ${({ dimension }) => BORDER_AND_HEADER_COLORS[dimension]};

  > div {
    display: inline-flex;
    justify-content: center;
    height: 24rem;
    position: relative;
    text-align: center;
    padding: 5rem 2rem 4.5rem 2rem;
    transform: rotate(180deg);

    > div:nth-child(4) {
      margin-right: 0rem;
    }

    > div {
      z-index: 1;
      margin-right: 4rem;
      width: 3.5rem;
      border-radius: 4rem/1.1rem;

      > div {
        transform: rotate(180deg);
        border-radius: 4rem/1.1rem;
        position: relative;
        display: flex;
        justify-content: center;
        background-color: ${({ dimension }) =>
          BORDER_AND_HEADER_COLORS[dimension]};
        > span {
          color: ${({ dimension }) => BORDER_AND_HEADER_COLORS[dimension]};
        }

        &::after {
          z-index: 1;
          position: absolute;
          left: 0;
          top: 0;
          width: 3.5rem;
          height: 1rem;
          border-radius: 4rem/1.1rem;
          content: "";
          background-color: ${({ dimension }) => BACKGROUND[dimension]};
        }

        > span {
          font-weight: 500;
          position: absolute;
          top: -3rem;
          font-size: 1.6rem;
        }

        > p {
          position: absolute;
          bottom: -3rem;
          font-size: 1.3rem;
          font-weight: 400;
          color: ${({ theme }) => theme.colors.gray};
        }
      }

      > div::before {
        position: absolute;
        left: 0rem;
        bottom: 0rem;
        width: 3.5rem;
        height: 0.8rem;
        border-radius: 4rem/1.3rem;
        box-shadow: 0rem 0.37rem 0.2rem 0.3rem rgba(0, 0, 0, 0.07);
        content: "";
      }
    }
  }
`;

export const TitleWrapper = styled.header`
  margin-top: 0;
  min-height: 7rem;
  width: 65%;
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 0 0 2rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ dimension }) => BORDER_AND_HEADER_COLORS[dimension]};
  > h1 {
    color: ${({ theme }) => theme.colors.white};
    font-weight: 500;
    font-size: 1.5rem;
  }
`;
