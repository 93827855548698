import styled from "styled-components";
import Select from "react-select";

export const StyledSelect = styled(Select)`
  &&& {
    font-size: 1.4rem !important;
    > div {
      font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
      font-size: 1.4rem !important;
      height: 3.9rem;
    }
  }
`;
