import React, { useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import {
  useAllTechnologiesQuery,
  useTechnologyMutation,
  useAllSolutionsEdutecPlataformQuery,
} from "services/red-demands";

const createOption = (label) => ({
  label,
  value: label.toLowerCase().replace(/\W/g, ""),
});

export default function CreatableMultiSelect({
  options,
  setOptions,
  clearRadios,
  isDisabled,
}) {
  const [value, setValue] = useState(undefined);

  const {
    data: allSolutionsEdutecPlataform,
    isLoading: allSolutionsEdutecPlataformLoading,
    isSuccess: allSolutionsEdutecPlataformSuccess,
  } = useAllSolutionsEdutecPlataformQuery();

  const {
    data: allTechnologies,
    isSuccess: allTechnologiesSuccess,
    isLoading: allTechnologiesLoading,
  } = useAllTechnologiesQuery();

  const handleChange = (newValue) => {
    clearRadios();
    setOptions(newValue);
    setValue(newValue);
  };

  const technologyMutation = useTechnologyMutation();

  const handleCreate = (inputValue) => {
    setOptions([...options, createOption(inputValue)]);
    setValue([...options, createOption(inputValue)]);
    technologyMutation.mutate({
      name: inputValue,
    });
  };

  useEffect(() => {
    if (options.length === 0) {
      setValue([]);
    }
  }, [options.length]);

  return (
    <CreatableSelect
      isMulti
      isClearable
      isDisabled={
        // allSolutionsEdutecPlataformLoading ||
        // allTechnologiesLoading ||
        // technologyMutation.isLoading ||
        isDisabled
      }
      isLoading={
        allSolutionsEdutecPlataformLoading ||
        allTechnologiesLoading ||
        technologyMutation.isLoading
      }
      onChange={handleChange}
      onCreateOption={handleCreate}
      formatCreateLabel={(inputValue) => (
        <p>
          Criar <strong>{inputValue}</strong>
        </p>
      )}
      options={
        allSolutionsEdutecPlataformSuccess
          ? [
              ...allSolutionsEdutecPlataform?.solucoes?.map((solution) =>
                createOption(solution?.titulo)
              ),
              allTechnologies?.map((technology) => createOption(technology)),
            ]
          : allTechnologiesSuccess && [
              ...allTechnologies?.map((technology) => createOption(technology)),
            ]
      }
      value={value}
    />
  );
}
