import styled, { css } from "styled-components";
import image37 from "assets/images/plans/37.svg";
import headerDevolutiva from "assets/images/plans/header-devolutiva.svg";
import { container } from "global-styles/shared-styles";
import PrintIcon from "@mui/icons-material/Print";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Gantt } from "gantt-task-react";
import "gantt-task-react/dist/index.css";

export const StyledPrintIcon = styled(PrintIcon)`
  &&& {
    font-size: 2rem;
  }
`;

const pageBreak = css`
  @media print {
    page-break-before: always;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  flex: 1;
  font-size: 1.5rem;
  @media print {
    margin-top: 0;
  }
`;

export const WrapperBanner = styled.section`
  background: ${({ theme }) => theme.colors.yellow};

  /* @media print {
    background: ${({ theme }) => theme.colors.gray8};
  } */
`;

export const Banner = styled.header`
  ${container}
  background: url(${image37});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  height: 42rem;
  width: 100%;

  @media print {
    height: 30rem;
    /* background: url(${headerDevolutiva}); */
  }
`;

export const Contents = styled.section`
  ${container}
  display: block;
  margin-bottom: 10rem;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  > button {
    background-color: ${({ theme }) => theme.colors.blue5};
    color: ${({ theme }) => theme.colors.white};
    padding: 0rem 2rem;
    height: 4rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue2};
      transition: 0.1s ease-in-out;
    }
  }

  @media print {
    display: none;
  }
`;

export const Button = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  align-self: flex-end;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1rem 2rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  white-space: nowrap;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  > img {
    width: 6rem;
  }

  > div {
    display: flex;
    align-items: center;
    gap: 2rem;
    > img {
      width: 6rem;
    }
  }
  border-bottom: 0.1rem solid #cfded7;
  padding: 1rem 2rem;
`;

export const Title = styled.h1`
  font-size: 2.4rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.blue5};

  @media print {
    font-size: 1.9rem;
  }
`;

export const SubTitle = styled.h2`
  font-size: 1.8rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue5};
`;

export const Reminder = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 8rem;
  > div:nth-child(2) {
    > p {
      font-size: 1.6rem;
    }
  }

  @media print {
    display: none;
  }
`;

export const DimensionsChartWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 8rem;
  @media print {
    padding-bottom: 0rem;
    transform: scale(0.56);
    gap: 2rem;
  }
`;

export const DimensionsTableWrapper = styled.section`
  ${pageBreak};
  display: flex;

  flex-direction: column;
  gap: 3rem;
  padding-bottom: 8rem;

  @media print {
    padding-bottom: 0rem;
  }
`;

export const FollowUpTableWrapper = styled.section`
  ${pageBreak};
  display: flex;
  flex-direction: column;
  gap: 3rem;

  > div:nth-child(2) {
    > p {
      font-size: 1.6rem;
    }
  }

  > div:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 8rem;

    @media print {
      display: block;
    }

    > div {
      ${pageBreak};
    }
  }

  padding-bottom: 8rem;
`;

export const MainGoalWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  > div:nth-child(2) {
    border-radius: 1rem;
    background-color: ${({ theme }) => theme.colors.gray5};
    min-height: 15rem;
    padding: 2rem;
  }
  padding-bottom: 8rem;

  @media print {
    padding-bottom: 0rem;
  }
`;

export const TermPlanWrapper = styled.section`
  padding: 8rem 1rem 8rem 1rem;

  @media print {
    padding: 2rem 1rem 2rem 1rem;
  }
`;

export const EquipmentsTableWrapper = styled.section`
  ${pageBreak};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 8rem;

  > p {
    font-size: 1.8rem;
  }
`;

export const NetworkTableFollowUpWrapper = styled.section`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 8rem;

  @media print {
    padding-bottom: 4rem;
  }
`;

export const GanttChartWrapper = styled.section`
  ${pageBreak};
  display: flex;
  flex-direction: column;
  gap: 3rem;
  padding-bottom: 8rem;

  > div:nth-child(2) {
    > p {
      font-size: 1.6rem;
    }
  }

  @media print {
    display: none;
  }
`;

export const GeneralInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 1.6rem;
  color: ${({ theme }) => theme.colors.blue5};
  > img {
    width: 8rem;

    @media print {
      width: 6rem;
    }
  }

  > div {
    > h1 {
      font-size: 2.1rem;
      font-weight: 400;

      @media print {
        font-size: 1.9rem;
      }
    }
    > p {
      font-size: 2.8rem;
      font-weight: 500;

      @media print {
        font-size: 2rem;
      }
    }
  }

  margin-bottom: 2rem;
  padding: 1rem 2rem;

  @media print {
    margin-bottom: 1rem;
    padding: 2rem 0rem;
  }
`;

export const PrintWrapper = styled.section`
  display: flex;
  justify-content: center;
  margin-bottom: 8rem;
  margin-top: 6rem;

  @media print {
    display: none;
  }

  > button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
      0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
    align-self: flex-end;
    border: none;
    color: ${({ theme }) => theme.colors.black};
    padding: 1.3rem 1.6rem;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-weight: 500;
    min-height: 4.6rem;
    cursor: pointer;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 6rem 6rem 4rem 6rem;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 1rem !important;

  > p {
    font-size: 2rem;
    > a {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ReportProblem = styled(ReportProblemIcon)`
  &&& {
    cursor: pointer;
    font-size: 10rem;
    color: #c90a12;
    margin-bottom: 3rem;
  }
`;

export const StyledGantt = styled(Gantt)`
  &&& {
    div {
      border: 1px solid red !important;
    }
  }
`;
