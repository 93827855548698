import styled, { css } from "styled-components";

export const large = css`
  p:nth-child(2) {
    position: absolute;
    top: 23.5rem;
    left: 17.8rem;
    width: 11rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(3) {
    position: absolute;
    bottom: 17.5rem;
    left: 25.8rem;
    width: 10rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(4) {
    position: absolute;
    bottom: 28.5rem;
    right: 16rem;
    width: 10rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(5) {
    position: absolute;
    top: 27rem;
    right: 20.4rem;
    width: 7.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(6) {
    position: absolute;
    top: 44rem;
    right: 42rem;
    width: 10rem;
    text-align: center;
    font-size: 1.8rem;
  }
`;

export const Wrapper = styled.div`
  height: 94.3rem;
  width: 94.3rem;
  ${large}
  color: ${({ theme }) => theme.colors.white};

  > svg {
    .cls-3 {
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  > p {
    font-weight: 400;
  }

  > p:nth-last-child(1) {
    font-weight: 500;
    cursor: pointer;
  }
`;

export const Image = styled.img``;
