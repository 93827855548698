import styled, { css } from "styled-components";

const large = css`
  p:nth-child(2) {
    position: absolute;
    top: 33.5rem;
    right: 7.5rem;
    width: 9.5rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(3) {
    position: absolute;
    bottom: 13rem;
    right: 19rem;
    width: 12rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(4) {
    position: absolute;
    bottom: 20rem;
    left: 12.5rem;
    width: 10rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(5) {
    position: absolute;
    top: 14.5rem;
    left: 19rem;
    width: 10.2rem;
    text-align: center;
    font-size: 1.2rem;
  }

  p:nth-child(6) {
    position: absolute;
    top: 35.5rem;
    left: 32.2rem;
    width: 15rem;
    text-align: center;
    font-size: 1.8rem;
  }
`;

export const Wrapper = styled.div`
  height: 80rem;
  width: 80rem;
  ${large};
  color: ${({ theme }) => theme.colors.white};

  svg {
    .cls-2 {
      cursor: pointer;
      &:hover {
        opacity: 0.9;
      }
    }
  }

  > p {
    font-weight: 400;
  }

  > p:nth-last-child(1) {
    font-weight: 500;
    cursor: pointer;
  }
`;

export const Image = styled.img``;
