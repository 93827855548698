import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/calculadora/017.svg";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-family: ${({ theme }) => theme.fonts.poppins};
  background-color: #f0f0f0;
  .content {
    ${container};
    /* display: flex; */

    .loading {
      display: flex;
      padding: 100px 0px;
      justify-content: center;
      > span {
        > svg {
          color: #87c249;
        }
      }
    }

    > article {
      margin: 50px auto;
      > div {
        > div:nth-child(1) {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          flex-wrap: wrap;
          justify-content: space-between;
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          padding: 4rem 6rem;
          background-color: ${({ theme }) => theme.colors.white};
          margin-top: 5rem;
          margin-bottom: 5rem;

          > h1 {
            font-weight: 500;
            font-size: 2.2rem;
            color: ${({ theme }) => theme.colors.blue5};
          }

          > p {
            font-size: 1.8rem;
          }
          > a {
            text-decoration: none;
            width: fit-content;
            padding: 1rem 2rem;
            border-radius: 0.5rem;
            border: none;
            background-color: ${({ theme }) => theme.colors.blue5};

            font-weight: 500;
            color: ${({ theme }) => theme.colors.white};
            cursor: pointer;

            &:hover {
              opacity: 0.9;
            }
          }
        }
        > div:nth-child(2) {
          display: flex;
          gap: 4rem;
          flex-wrap: wrap;
          justify-content: space-between;
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          padding: 4rem 6rem;
          background-color: ${({ theme }) => theme.colors.white};
          margin-top: 5rem;
          margin-bottom: 5rem;
        }
        margin-bottom: 50px;
        p {
          margin-bottom: 10px;
        }
      }
    }
  }

  .level_title {
    cursor: pointer;
    width: 380px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px;
    /* background-color: $dark-blue; */
    color: white;
    border-radius: 6px;
    margin-bottom: 10px;
    h1 {
      font-size: 1.2rem;
      font-weight: 500;
    }
  }

  .level_title_disable {
    background-color: rgb(136, 142, 156);
  }

  .loading {
    display: flex;
    padding: 100px 0px;
    justify-content: center;
  }

  .bar_title {
    padding: 2rem 15rem;
    border-radius: 1rem 1rem 0rem 0rem;
    margin: 5rem 0 1rem 0;

    background-color: #73a032;

    > h1 {
      color: white;
      font-weight: 400;
      font-size: 1.8rem;
      text-align: center;
    }
  }

  .table {
    max-height: 60vh;
    overflow-x: scroll;
    overflow-y: scroll;

    > table {
      width: 110rem;
      max-height: 50rem;
      border-collapse: collapse;
      > thead {
        > tr {
          padding: 10px;
          background-color: #cfded7;
          font-size: 1.6rem;

          > th {
            border: 0.4rem solid #f0f0f0;
            color: ${({ theme }) => theme.colors.black};
            padding: 1.2rem 2rem;
            font-weight: 500;
            text-align: center;
            vertical-align: middle;
            text-transform: uppercase;
          }

          .school_column {
            min-width: 25vw;
          }
        }
      }

      > tbody {
        > tr {
          padding: 10px;
          background-color: ${({ theme }) => theme.colors.white};
          > td:nth-child(1) {
            padding: 1rem 2rem;

            > div {
              display: flex;
              gap: 1rem;

              > svg {
                cursor: pointer;
              }
            }
          }
          > td {
            padding: 1rem;
            border: 1px solid #d3d3d38a;
            text-align: center;
            vertical-align: middle;

            > input {
              border: 1px solid gray;
              border-radius: 0 !important;
            }

            > button {
              border: none;
              background-color: white;
              cursor: pointer;
              font-weight: bold;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }
  }

  > header {
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #85c440;
  }

  .box_title {
    cursor: pointer;
    text-align: center;
    h1 {
      color: white;
      font-size: 2.5rem;
      margin-bottom: 10px;
      font-weight: 500;
    }
    p {
      span {
        /* color $green; */
        font-weight: 500;
      }
      text-align: center;
      color: white;
      font-size: 1.3rem;
    }
  }

  .start {
    height: 20rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;

    > button {
      background-color: #141d3c;
      color: ${({ theme }) => theme.colors.white};
      padding: 1.5rem 2rem;
      border-radius: 0.5rem;
      border: none;
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .no_schools {
    font-size: 2rem;
    > ul {
      > li {
        list-style: disc outside none;
        margin-left: 1em;
        > a {
          color: ${({ theme }) => theme.colors.blue5};
          &:hover {
            opacity: 0.9;
          }
        }
      }
    }
  }
`;

export const ErrorMessage = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  padding: 6rem 6rem 4rem 6rem;
  background-color: ${({ theme }) => theme.colors.white};
  gap: 1rem !important;

  > p {
    font-size: 2rem;
    text-align: center;
    > a {
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;

export const ReportProblem = styled(ReportProblemIcon)`
  &&& {
    cursor: pointer;
    font-size: 10rem;
    color: #c90a12;
    margin-bottom: 3rem;
  }
`;

export const SearchInput = styled.div`
  position: relative;
  font-size: 2.6rem;
  width: 100%;
  > input {
    width: 100%;
    border: 1px solid lightgray;
    border-radius: 10px;
    padding: 1.5rem 2rem 1.5rem 4.5rem;
    font-size: 1.6rem;
  }
  > svg {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
  }
`;
