import styled, { css } from "styled-components";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import media from "styled-media-query";

export const StyledArrowForwardIosIcon = styled(ArrowForwardIosIcon)`
  &&& {
    background-color: ${({ theme }) => theme.colors.black};
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    padding: 0.5rem;
    color: ${({ theme }) => theme.colors.white};
  }
`;

// const border1 = css`
//   border-radius: 1rem;
// `;

// const border2 = css`
//   border-radius: 0rem 0rem 1rem 1rem;
// `;

export const Wrapper = styled.section`
  ${({ display }) => ({ display })};
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;
  box-shadow: ${({ boxShadow }) =>
    boxShadow &&
    "0 1rem 2.5rem rgba(0, 0, 0, 0.05), 0 1rem 2.5rem rgba(0, 0, 0, 0.05)"};
  z-index: 25;
  position: sticky;
  width: 100%;
  top: 7rem;
  padding: 2rem !important;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2.5rem;
    font-size: 1.4rem;

    > div {
      ${media.lessThan("medium")`
        width: 100%;
			`}
      display: flex;
      flex-shrink: 1;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => theme.colors.white};
      justify-content: center;
      gap: 2rem;

      > span {
        color: #1a2341;
        font-weight: 500;
        ${media.lessThan("medium")`
          font-size: 1.7rem;
        `}
      }

      > ul {
        ${media.lessThan("medium")`
          width: 100%;
			  `}
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        border-radius: 1rem;
        padding: 1rem 2rem;

        gap: 1.5rem;
        list-style: none;
        background-color: ${({ theme }) => theme.colors.gray11};
        z-index: 999;

        ${media.lessThan("medium")`
          padding: 2rem;
          font-size: 1.8rem !important;
          gap: 1.5rem;
			  `}
        > li {
          display: flex;
          text-align: center;
          justify-content: space-around;
          z-index: 999;
        }
      }
    }
  }
`;

const buttonActiveOrSelectedStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 1rem hsl(0deg 0% 0% / 14%);
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  ${({ isActive, isSelected }) =>
    (isActive || isSelected) && buttonActiveOrSelectedStyles};
  padding: 0rem 1.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  color: #707070;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  /* font-weight: 500; */

  ${media.lessThan("medium")`
    padding: 2rem 2rem;
  `}

  > svg {
    transform: ${({ isActive }) =>
      isActive ? "rotate(90deg)" : "rotate(0deg)"};
  }
`;
