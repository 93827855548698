import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import media from "styled-media-query";

export const Wrapper = styled.section`
  flex: 1;
  display: flex;
  padding: 0 !important;

  ${container}
  > div:nth-child(1) {
    background-color: ${({ theme }) => theme.colors.gray98};
    position: relative;

    > aside {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding-top: 5rem;
      gap: 0rem;
      top: 0rem;
      > svg:nth-child(1) {
        margin-bottom: 5rem;
      }

      > div:nth-child(1) {
        top: 25rem;
        margin-bottom: 25rem;
      }

      ${media.lessThan("large")`
        display: none;
      `}
    }
  }

  > div:nth-child(2) {
    background-color: ${({ theme }) => theme.colors.white};
    padding: 6.2rem 24rem 9rem 6rem;
    ${media.lessThan("huge")`
      padding: 6.2rem 4rem 9rem 4rem;
    `}

    color: ${({ theme }) => theme.colors.textGray};
    > section {
      a {
        text-decoration: none;
      }

      > h1 {
        font-size: 2.3rem;
        margin: 6rem 0rem 5rem 0rem;
        font-weight: 500;
      }

      > h2 {
        font-size: 2.2rem;
        margin: 6rem 0rem 5rem 0px;
        font-weight: 500;
      }

      > h3 {
        font-size: 2rem;
        margin: 6rem 0rem 4rem 0rem;
        font-weight: 500;
      }

      > h4 {
        font-size: 1.8rem;
        margin: 6rem 0rem 4rem 0rem;
        font-weight: 500;
      }

      > h5 {
        font-size: 1.7rem;
        margin: 6rem 0rem 5rem 0rem;
        font-weight: 500;
      }

      > p {
        text-align: justify;
        font-size: 1.8rem;
        line-height: 1.5;
        margin-bottom: 2rem;
      }

      > ol,
      > ul {
        font-size: 1.7rem;
        margin-left: 1.7rem;
        margin-bottom: 2rem;
        text-align: justify;
        > ul,
        > ol {
          margin-left: 2rem;
          > li {
            margin: 1.5rem 0;
          }
        }
        > li {
          margin: 1.5rem 0;
        }
      }
    }
  }
`;
