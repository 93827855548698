import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "shared-components/Layout";
import * as S from "./styles";
import Select from "shared-components/Select";
import { BarChart, Bar, LabelList, XAxis, YAxis, Tooltip } from "recharts";
import ReactWordcloud from "react-wordcloud";
// import image62 from "assets/images/red-demands/062.svg";
// import image63 from "assets/images/red-demands/063.svg";

// import p01 from "assets/images/red-demands/velocimetro/p-01.svg";
// import p02 from "assets/images/red-demands/velocimetro/p-02.svg";
// import p03 from "assets/images/red-demands/velocimetro/p-03.svg";
// import p04 from "assets/images/red-demands/velocimetro/p-04.svg";
// import c01 from "assets/images/red-demands/velocimetro/c-01.svg";
// import c02 from "assets/images/red-demands/velocimetro/c-02.svg";
// import c03 from "assets/images/red-demands/velocimetro/c-03.svg";
// import c04 from "assets/images/red-demands/velocimetro/c-04.svg";
// import d01 from "assets/images/red-demands/velocimetro/d-01.svg";
// import d02 from "assets/images/red-demands/velocimetro/d-02.svg";
// import d03 from "assets/images/red-demands/velocimetro/d-03.svg";
// import d04 from "assets/images/red-demands/velocimetro/d-04.svg";

// import DimensionsTable from "shared-components/DimensionsTable";
// import FourLeafClover from "shared-components/FourLeafClover";
import GroupSection from "../../components/GroupSection";
import {
  useDigitalCompetencesByAreaQuery,
  useSchoolsAmountQuery,
  useAllNetworkTechnologiesQuery,
} from "services/red-demands";
import { useSurveyListQuery } from "services/common";
import {
  getPersonalSurveySchedules,
  getSchoolSurveySchedules,
} from "utils/surveys";
import { CircularProgress } from "@mui/material";

const SHORTENING = {
  GA: "GESTÃO ADMINISTRATIVA",
  AC: "AUTORIA E COLABORAÇÃO",
  CE: "CONTEÚDO ESTRUTURADO",
  EA: "ESPAÇOS DE APRENDIZAGEM",
  GP: "GESTÃO PEDAGÓGICA",
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: "white",
          border: "1px solid rgba(0,0,0,0.2)",
          padding: "2rem",
        }}
      >
        <h1
          style={{
            fontSize: "1.5rem",
          }}
        >
          {SHORTENING[label]}
        </h1>

        <p
          style={{
            fontSize: "1.5rem",
          }}
        >
          Escolas: {payload[0].value}
        </p>
      </div>
    );
  }

  return null;
};
const DEMANDS = {
  CONTEUDO_ESTRUTURADO: {
    name: (
      <h1>
        CONTEÚDO
        <br /> ESTRUTURADO
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam recursos, como vídeos, imagens, textos,
        áudios, simulações, jogos educativos ou cursos online, com o objetivo de
        desenvolver habilidades e conteúdos previstos no currículo. Esses
        recursos são encaminhados diretamente aos/às estudantes ou
        disponibilizados em repositórios digitais e/ou plataformas de oferta de
        conteúdo online, como banco de questões ou biblioteca de livros.
      </p>
    ),
  },
  AUTORIA_E_COLABORACAO: {
    name: (
      <h1>
        AUTORIA E<br /> COLABORAÇÃO
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam, de forma individual ou colaborativa, um
        ou mais softwares ou aplicativos para criar apresentações de slides,
        desenvolver projetos em vídeo, escrever e distribuir livros digitais
        e/ou realizar simulações em 3D. Nos momentos de reforço, docentes e
        estudantes interagem online por meio de ferramentas de vídeo, áudio e/ou
        texto.
      </p>
    ),
  },
  ESPACOS_DE_APRENDIZAGEM: {
    name: (
      <h1>
        ESPAÇOS DE
        <br /> APRENDIZAGEM
      </h1>
    ),
    description: (
      <p>
        Docentes e estudantes utilizam ferramentas ou plataformas que
        disponibilizam trilhas de conteúdos (personalizadas ou não), materiais
        e/ou roteiros de estudo (atividades). Os/as docentes utilizam ainda
        recursos digitais para criar planos de aula, apresentar conteúdos,
        receber atividades realizadas pelos/as estudantes e criar experiências
        de aprendizagem presenciais, remotas ou híbridas com uso de tecnologias
        digitais.
      </p>
    ),
  },
  GESTAO_PEDAGOGICA: {
    name: (
      <h1>
        GESTÃO <br />
        PEDAGÓGICA
      </h1>
    ),
    description: (
      <p>
        Coordenador/a pedagógico/a, docentes e estudantes utilizam uma ou mais
        ferramentas para gerenciar a notas dos/das alunos/as se comunicar com a
        comunidade escolar, acompanhar a execução do currículo da rede ao longo
        do ano letivo, do planejamento pedagógico docente e/ou da avaliação da
        aprendizagem ou ainda para monitorar o uso pedagógico de tablets ou
        computadores pelos estudantes.
      </p>
    ),
  },
  GESTAO_ADMINISTRATIVA: {
    name: (
      <h1>
        GESTÃO <br />
        ADMINISTRATIVA
      </h1>
    ),
    description: (
      <p>
        Gestor/a escolar e profissionais do setor administrativo utilizam um ou
        mais sistemas para gerenciar matrículas de estudantes, grade horária,
        alimentação escolar, contratos com fornecedores, verbas disponíveis e
        fluxo de caixa, vida funcional dos profissionais da escola, entre outros
        processos.
      </p>
    ),
  },
};

// const VELOCIMETERS = {
//   p: [p01, p02, p03, p04],
//   c: [c01, c02, c03, c04],
//   d: [d01, d02, d03, d04],
// };

// const ARGS = {
//   resultsAvgLoading: false,
//   resultsAvgSuccess: true,
//   surveyAnswersLoading: false,
//   results: {
//     answered_count: 12,
//     answered_sample: 32,
//     total_count: 345,
//     total_sample: 232,
//   },
//   resultsAvg: {
//     competence_avg: 2,
//     crd_avg: 3,
//     infra_avg: 3,
//     vision_avg: 4,
//   },
//   yearOfCycle: "2021",
// };

export default function AdminResults() {
  const [surveyScheduleIdSchool, setSurveyScheduleIdSchool] = useState("");
  const [surveyScheduleIdPersonal, setSurveyScheduleIdPersonal] = useState("");
  // const [isCollapseOpen1, setIsCollapseOpen1] = useState(false);
  // const [isCollapseOpen2, setIsCollapseOpen2] = useState(false);

  // const [yearOfCycle, setYearOfCycle] = useState("");
  const [stage, setStage] = useState({ label: "Todas as etapas", value: "" });

  const [surveyIdPersonal, setSurveyIdPersonal] = useState("");
  // const [surveyIdSchool, setSurveyIdSchool] = useState("");

  const [chartBarData, setChartBarData] = useState([]);

  const {
    data: surveyList,
    // isLoading: surveyListLoading,
    isSuccess: surveyListSuccess,
  } = useSurveyListQuery();

  const {
    data: schoolsAmount,
    isLoading: schoolsAmountLoading,
    isSuccess: schoolsAmountSuccess,
  } = useSchoolsAmountQuery(surveyScheduleIdSchool?.value, stage.value);

  const {
    // data: digitalCompetencesByArea,
    isLoading: digitalCompetencesByAreaLoading,
    // isSuccess: digitalCompetencesByAreaSuccess,
  } = useDigitalCompetencesByAreaQuery(
    surveyIdPersonal,
    surveyScheduleIdPersonal?.value
  );

  const {
    data: allNetworkTechnologies,
    // isLoading: allNetworkTechnologiesLoading,
    isSuccess: allNetworkTechnologiesSuccess,
  } = useAllNetworkTechnologiesQuery(
    surveyScheduleIdSchool?.value,
    stage?.value
  );

  // const setScheduleNameAndSurveyIdSchool = (surveyScheduleId) => {
  //   // const schedule = getSchoolSurveySchedules(surveyList?.surveys).find(
  //   //   (schedule) => schedule?.id?.$oid === surveyScheduleId
  //   // );
  //   // setSurveyIdSchool(schedule?.survey_id?.$oid);
  //   // setYearOfCycle(schedule?.name);
  // };

  // const setScheduleNameAndSurveyIdPersonal = (surveyScheduleId) => {
  //   const schedule = getPersonalSurveySchedules(surveyList?.surveys).find(
  //     (schedule) => schedule?.id?.$oid === surveyScheduleId
  //   );
  //   setSurveyIdPersonal(schedule?.survey_id?.$oid);
  //   setYearOfCycle(schedule?.survey_name);
  // };

  useEffect(() => {
    if (schoolsAmountSuccess) {
      const chartBarData = [
        {
          name: "GA",
          escolas: schoolsAmount?.chart?.GESTAO_ADMINISTRATIVA,
          fill: "#f86920",
        },
        {
          name: "AC",
          escolas: schoolsAmount?.chart?.AUTORIA_E_COLABORACAO,
          fill: "#9c3b85",
        },
        {
          name: "CE",
          escolas: schoolsAmount?.chart?.CONTEUDO_ESTRUTURADO,
          fill: "#1ebad6",
        },
        {
          name: "EA",
          escolas: schoolsAmount?.chart?.ESPACOS_DE_APRENDIZAGEM,
          fill: "#63a54e",
        },
        {
          name: "GP",
          escolas: schoolsAmount?.chart?.GESTAO_PEDAGOGICA,
          fill: "#1cb79c",
        },
      ];

      setChartBarData(chartBarData);
    }
  }, [schoolsAmount, schoolsAmountSuccess]);

  useEffect(() => {
    if (surveyListSuccess) {
      const personalSchedules = getPersonalSurveySchedules(surveyList?.surveys);
      setSurveyIdPersonal(personalSchedules[0]?.survey_id?.$oid);
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      // setSurveyIdSchool(schoolSchedules[0]?.survey_id?.$oid);

      setSurveyScheduleIdPersonal({
        label: personalSchedules[0]?.survey_name,
        value: personalSchedules[0]?.id?.$oid,
      });
      setSurveyScheduleIdSchool({
        label: schoolSchedules[0]?.name,
        value: schoolSchedules[0]?.id?.$oid,
      });
    }
  }, [surveyList, surveyListSuccess]);

  if (schoolsAmountLoading && digitalCompetencesByAreaLoading) {
    return (
      <Layout hasNavigationBar name="redDemands">
        <Helmet title="Guia EduTec | Planos - Início" />
        <S.Wrapper>
          <S.BannerWrapper>
            <S.Banner />
          </S.BannerWrapper>

          <section>
            <div>
              <CircularProgress size={30} />
            </div>
          </section>
        </S.Wrapper>
      </Layout>
    );
  }

  if (schoolsAmountSuccess && schoolsAmount?.school_amount === 0) {
    return (
      <Layout hasNavigationBar name="redDemands">
        <Helmet title="Guia EduTec | Planos - Início" />
        <S.Wrapper>
          <S.BannerWrapper>
            <S.Banner />
          </S.BannerWrapper>

          <section>
            <div>
              <h1>ATENÇÃO !</h1>
              <p>
                Nenhuma escola da sua rede respondeu ao questionário do Levantamento de Demanda de RED
              </p>
              <div>
                <p>
                  Use os filtros para traçar estratégias específicas para cada
                  etapa de ensino
                </p>
              </div>
                <div>
                  <label htmlFor="stage">Etapas de ensino</label>
                  <Select
                    labelId="stage"
                    id="stage"
                    name="stage"
                    value={stage}
                    onChange={setStage}
                    options={[
                      { label: "Todas as etapas", value: "" },
                      { label: "Educação Infantil", value: "kindergarten" },
                      {
                        label: "Fundamental - Anos iniciais",
                        value: "elementary_1",
                      },
                      {
                        label: "Fundamental - Anos finais",
                        value: "elementary_2",
                      },
                    ]}
                  />
                </div>
            </div>
            
          </section>
        </S.Wrapper>
      </Layout>
    );
  }

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>

        <section>
          <div>
            <h1>RESULTADOS DO LEVANTAMENTO DE DEMANDAS DE RED</h1>
            <p>
              Aqui você encontrará informações importantes para tomar a melhor
              decisão de quais tipos de RED são mais prioritários neste momento
              para sua rede.
            </p>
          </div>
        </section>

        <section>
          <div>
            <div>
              <div>
                <p>{schoolsAmount?.school_amount}</p>
                <div>
                  <span>nº de escolas</span>
                  <span>que responderam</span>
                </div>
              </div>
            </div>
            <div>
              <div>
                <p>
                  {schoolsAmount?.percent || 0}
                  <span>%</span>
                </p>

                <div>
                  <span>de escolas</span>
                  <span>que responderam</span>
                </div>
              </div>
            </div>
          </div>
          {schoolsAmount?.percent < 85 && (
            <div>
              <p>Este valor ainda não é representativo da rede (mínimo 85%)</p>
            </div>
          )}
        </section>

        <section>
          {/* <div>
            <div>
              <div onClick={() => setIsCollapseOpen1(!isCollapseOpen1)}>
                <p>Nível de adoção de tecnologia nas quatro dimensões</p>
                {isCollapseOpen1 ? <S.ArrowDropDown /> : <S.ArrowDropUp />}
              </div>
              <div>
                {isCollapseOpen1 && (
                  <>
                    <div>
                      <FourLeafClover
                        width={80}
                        vision={true}
                        competence={true}
                        red={true}
                        infra={true}
                      />

                      <div>
                        <label htmlFor="years">Ano</label>
                        {surveyListLoading ? (
                          <CircularProgress size={30} />
                        ) : (
                          <Select
                            labelId="years"
                            id="years"
                            name="years"
                            value={surveyScheduleIdSchool}
                            onChange={(event) => {
                              setScheduleNameAndSurveyIdSchool(event.value);
                              setSurveyScheduleIdSchool(event);
                            }}
                            options={getSchoolSurveySchedules(
                              surveyList?.surveys
                            ).map((schedule) => ({
                              label: schedule?.name,
                              value: schedule?.id?.$oid,
                            }))}
                          />
                        )}
                      </div>

                      <div>
                        <p>
                          <strong>%</strong> de <strong>escolas</strong> <br />{" "}
                          que responderam
                        </p>
                      </div>
                    </div>

                    <div>
                      <DimensionsTable {...ARGS} />
                    </div>

                    <div>
                      <div>
                        <img src={image62} alt="" />
                        <button>SAIBA MAIS</button>
                      </div>

                      <div>
                        <button>AINDA NÃO TENHO ESSAS INFORMAÇÕES</button>
                        <button>QUERO ATUALIZAR ESSAS INFORMAÇÕES</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div>
              <div onClick={() => setIsCollapseOpen2(!isCollapseOpen2)}>
                <p>
                  Nível de competências digitais dos docentes da rede por área
                </p>
                {isCollapseOpen2 ? <S.ArrowDropDown /> : <S.ArrowDropUp />}
              </div>
              <div>
                {isCollapseOpen2 && (
                  <>
                    <div>
                      <FourLeafClover
                        width={80}
                        vision={true}
                        competence={true}
                        red={true}
                        infra={true}
                      />

                      <div>
                        <p>
                          <strong>%</strong> de <strong>professores</strong>{" "}
                          <br /> que responderam
                        </p>
                      </div>
                    </div>

                    <div>
                      <div>
                        <img
                          src={
                            VELOCIMETERS.p[
                              digitalCompetencesByArea?.avg_pedagogica - 1
                            ]
                          }
                          alt=""
                        />
                        <div>
                          <h1>O que isso significa?</h1>
                          <p>
                            {
                              digitalCompetencesByArea?.feedbacks
                                ?.feedback_pedagogica
                            }
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          src={
                            VELOCIMETERS.c[
                              digitalCompetencesByArea?.avg_cidadania - 1
                            ]
                          }
                          alt=""
                        />
                        <div>
                          <h1>O que isso significa?</h1>
                          <p>
                            {
                              digitalCompetencesByArea?.feedbacks
                                ?.feedback_cidadania_digital
                            }
                          </p>
                        </div>
                      </div>
                      <div>
                        <img
                          src={
                            VELOCIMETERS.d[
                              digitalCompetencesByArea?.avg_desenvolvimento - 1
                            ]
                          }
                          alt=""
                        />
                        <div>
                          <h1>O que isso significa?</h1>
                          <p>
                            {
                              digitalCompetencesByArea?.feedbacks
                                ?.feedback_desenvolvimento_profissional
                            }
                          </p>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>
                        <img src={image63} alt="" />
                        <button>SAIBA MAIS</button>
                      </div>

                      <div>
                        <button>AINDA NÃO TENHO ESSAS INFORMAÇÕES</button>
                        <button>QUERO ATUALIZAR ESSAS INFORMAÇÕES</button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div> */}
        </section>

        <section>
          <div>
            <div>
              <p>
                Esse é o consolidado das respostas das suas escolas sobre RED
              </p>
            </div>
            <div>
              <div>
                <p>
                  Use os filtros para traçar estratégias específicas para cada
                  etapa de ensino
                </p>
              </div>
              <div>
                <div>
                  <label htmlFor="stage">Etapas de ensino</label>
                  <Select
                    labelId="stage"
                    id="stage"
                    name="stage"
                    value={stage}
                    onChange={setStage}
                    options={[
                      { label: "Todas as etapas", value: "" },
                      { label: "Educação Infantil", value: "kindergarten" },
                      {
                        label: "Fundamental - Anos iniciais",
                        value: "elementary_1",
                      },
                      {
                        label: "Fundamental - Anos finais",
                        value: "elementary_2",
                      },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div>
            <div>
              <p>
                O gráfico abaixo mostra quantas escolas utilizam RED e para
                quais demandas
              </p>

              <BarChart
                width={480}
                height={300}
                data={chartBarData}
                style={{ fontSize: "1.4rem" }}
              >
                <Bar
                  dataKey="escolas"
                  margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                >
                  <LabelList
                    dataKey="escolas"
                    position="inside"
                    fill="rgba(0, 0, 0, 0.87)"
                    style={{ fontSize: "1.5rem" }}
                  />
                </Bar>
                <Tooltip content={<CustomTooltip />} />
                {/* <Legend /> */}
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                <XAxis dataKey="name" />
                <YAxis />
              </BarChart>

              <div>
                <ul>
                  <li>
                    <div></div>GESTÃO ADMINISTRATIVA
                  </li>
                  <li>
                    <div></div>AUTORIA E COLABORAÇÃO
                  </li>
                  <li>
                    <div></div>ESPAÇOS DE APRENDIZAGEM
                  </li>
                  <li>
                    <div></div>GESTÃO PEDAGÓGICA
                  </li>
                  <li>
                    <div></div>CONTEÚDO ESTRUTURADO
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <p>
                Estas são as tecnologias mais mencionadas que as escolas da sua
                rede já utilizam
              </p>
              {allNetworkTechnologiesSuccess && (
                <ReactWordcloud
                  options={{
                    enableTooltip: false,
                    deterministic: false,
                    fontFamily: "poppins",
                    fontSizes: [30, 50],
                    fontStyle: "normal",
                    fontWeight: "normal",
                    padding: 3,
                    rotations: 1,
                    rotationAngles: [0],
                    scale: "sqrt",
                    spiral: "archimedean",
                    transitionDuration: 1000,
                  }}
                  words={
                    allNetworkTechnologiesSuccess &&
                    Object.entries(allNetworkTechnologies).map(
                      ([key, value]) => ({ text: key, value: value * 20 })
                    )
                  }
                />
              )}
            </div>
          </div>
        </section>

        <section>
          <p>Esta é a principal demanda da sua rede por RED</p>
        </section>

        {schoolsAmountSuccess &&
          Object.entries(schoolsAmount?.demands)
            .sort((a, b) => b[1] - a[1])
            .filter(([_, value]) => value !== 0)
            .map(([demand, _], index) => (
              <>
                {index === 1 && (
                  <section>
                    <p>Estas são as outras demandas da sua rede</p>
                  </section>
                )}
                <GroupSection
                  key={DEMANDS[demand]?.name}
                  groupId={index}
                  demand={demand}
                  groupName={DEMANDS[demand]?.name}
                  schoolAmount={schoolsAmount}
                  priorities={schoolsAmount?.priorities[demand]}
                  description={DEMANDS[demand]?.description}
                />
              </>
            ))}

        <section>
          {/* <div>
            <button>QUERO BAIXAR</button>
          </div> */}
        </section>

        <section>
          <div>
            <div>
              <p>SIGA PARA OS PRÓXIMOS PASSOS</p>
            </div>
            <div>
              <div>
                <a
                  href="https://www.google.com/url?q=https://plataformaedutec.cieb.net.br/&sa=D&source=docs&ust=1654609693849156&usg=AOvVaw2ked82Vf-5uE3AKxSGjPQL"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAÇA SUA PESQUISA DE MERCADO
                </a>
              </div>
              <div>
                <a
                  href="https://docs.google.com/spreadsheets/u/1/d/15dwz-kmuND3ndF1iwacHEWyNyuCjLm-y9Gi3B7Hz-KA/copy?pli=1#gid=2073279345"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAÇA CURADORIADOS REDs SELECIONADOS
                </a>
              </div>
            </div>
          </div>
        </section>
      </S.Wrapper>
    </Layout>
  );
}
