import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import * as S from "./styles";
import image12 from "assets/images/plans/12.svg";
import image13 from "assets/images/plans/13.svg";
import image28 from "assets/images/plans/28.svg";

import CircularProgress from "@mui/material/CircularProgress";
import DimensionsTable from "shared-components/DimensionsTable";
import Layout from "shared-components/Layout";
import {
  useSurveyListQuery,
  useSurveyAnswersCicleQuery,
  useInstitutionQuery,
  useInstitutionPlansMutation,
  useResultAvgQuery,
} from "services/common";

import { getSchoolSurveySchedules } from "utils/surveys";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import DatePicker from "shared-components/DatePicker";
import Select from "shared-components/Select";
import ReactTooltip from "react-tooltip";
import { NotificationManager } from "react-notifications";

import "react-notifications/lib/notifications.css";
import { getStatus } from "utils/plans";
import { usePlans } from "hooks/use-plans";

export default function MainGoal() {
  const navigate = useNavigate();
  const [resultType] = useState("");
  const [surveyId, setSurveyId] = useState("");
  const [surveyScheduleId, setSurveyScheduleId] = useState("");
  const [yearOfCycle, setYearOfCycle] = useState("");
  const [mainGoal, setMainGoal] = useState("");
  const [start, setStart] = useState();
  const [end, setEnd] = useState();

  const institutionPlans = useInstitutionPlansMutation();
  const {
    isLoading: institutionPlansLoading,
    error: institutionPlansError,
    isSuccess: institutionPlansSuccess,
  } = institutionPlans;

  const { data: institution, isSuccess: institutionSuccess } =
    useInstitutionQuery();

  const {
    data: surveyList,
    isLoading: surveyListLoading,
    isSuccess: surveyListSuccess,
  } = useSurveyListQuery();

  const {
    data: surveyAnswers,
    isLoading: surveyAnswersLoading,
    isSuccess: surveyAnswersSuccess,
  } = useSurveyAnswersCicleQuery(surveyId, surveyScheduleId?.value);

  const {
    data: resultsAvg,
    isLoading: resultsAvgLoading,
    isSuccess: resultsAvgSuccess,
  } = useResultAvgQuery(surveyId, surveyScheduleId?.value);

  const { getCurrentPlan } = usePlans();

  useEffect(() => {
    if (
      getStatus(
        getCurrentPlan()?.setting_plan?.validity?.start,
        getCurrentPlan()?.setting_plan?.validity?.end
      ) === "concluded" ||
      institution?.plans?.length === 0
    ) {
      navigate("/planos");
    }
  }, [getCurrentPlan, navigate, institution]);

  useEffect(() => {
    if (institutionSuccess) {
      const currentPlan = getCurrentPlan();
      const settingPlan = currentPlan?.setting_plan;
      setStart(settingPlan?.validity?.start);
      setEnd(settingPlan?.validity?.end);
      setMainGoal(settingPlan?.main_goal);
      setYearOfCycle(settingPlan?.year_of_cycle);
      if (settingPlan?.survey_id && settingPlan?.survey_schedule_id) {
        setSurveyId(settingPlan?.survey_id);
        setSurveyScheduleId({
          label: settingPlan?.year_of_cycle,
          value: settingPlan?.survey_schedule_id,
        });
      } else if (surveyListSuccess) {
        const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
        setSurveyScheduleId({
          label: schoolSchedules[0]?.name,
          value: schoolSchedules[0]?.id?.$oid,
        });
        setSurveyId(schoolSchedules[0]?.survey_id?.$oid);
      }
    }
  }, [getCurrentPlan, institutionSuccess, surveyList, surveyListSuccess]);

  const saveResult = () => {
    const currentPlan = getCurrentPlan();
    if (!currentPlan.setting_plan) {
      currentPlan.setting_plan = {};
      currentPlan.setting_plan.result = {};
      currentPlan.setting_plan.result_type = {};
    }
    currentPlan.setting_plan.results = surveyAnswers;
    currentPlan.setting_plan.result_type = resultType;
    const length = institution?.plans.length;
    institution.plans[length - 1] = currentPlan;
    return institutionPlans.mutateAsync({ institution });
  };

  const saveSettingPlan = (data) => {
    const currentPlan = getCurrentPlan();
    currentPlan.setting_plan = { ...currentPlan.setting_plan, ...data };
    const length = institution?.plans.length;
    institution.plans[length - 1] = currentPlan;
    return institutionPlans.mutateAsync({ institution });
  };

  const saveResultsAvg = () => {
    const currentPlan = getCurrentPlan();
    const currentPlanAux = { ...currentPlan };
    const institutionsAux = { ...institution };
    const resultsAvgAux = [...resultsAvg];

    if (resultsAvgAux.length > 0) {
      const newResultsAvg = {
        ...resultsAvgAux[0],
        state: resultsAvgAux[0].state
          ? resultsAvgAux[0].state.$oid
          : resultsAvgAux[0].state ?? null,
        city: resultsAvgAux[0].city
          ? resultsAvgAux[0].city.$oid
          : resultsAvgAux[0].city ?? null,
      };

      currentPlanAux.setting_plan.results_avg = newResultsAvg;

      const length = institutionsAux?.plans.length;
      institutionsAux.plans[length - 1] = currentPlanAux;
      return institutionPlans.mutateAsync({ institution: institutionsAux });
    }
  };

  const saveData = async () => {
    const data = {
      survey_schedule_id: surveyScheduleId?.value,
      survey_id: surveyId,
      validity: { start, end },
      main_goal: mainGoal,
      year_of_cycle: yearOfCycle,
    };

    return Promise.all([saveResult(), saveSettingPlan(data), saveResultsAvg()]);
  };

  const setScheduleNameAndSurveyId = (surveyScheduleId) => {
    const schedule = getSchoolSurveySchedules(surveyList?.surveys).find(
      (schedule) => schedule?.id?.$oid === surveyScheduleId
    );
    setSurveyId(schedule?.survey_id?.$oid);
    setYearOfCycle(schedule?.name);
  };

  useEffect(() => {
    if (institutionPlansSuccess) {
      NotificationManager.success("Os dados foram salvos com sucesso !");
    }
    if (institutionPlansError) {
      NotificationManager.error("Ocorreu um erro ao salvar os dados");
    }
  }, [institutionPlansSuccess, institutionPlansError]);

  return (
    <Layout hasNavigationBar name="plans">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>
        <S.Content>
          <S.TermPlanAndBaseCycle>
            <div>
              <S.TitleWrapper>
                <img src={image12} alt="Vigência do Planejamento" />
                <div>
                  <S.Title>Vigência do Planejamento</S.Title>
                  <S.SubTitle>
                    Qual o período de implementação do PIT?
                  </S.SubTitle>
                </div>
              </S.TitleWrapper>
              <div>
                <div>
                  <label htmlFor="start">Início do plano</label>
                  <DatePicker
                    name="start"
                    onChange={(event) => setStart(event)}
                    selected={dayjs(start).toDate()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
                <div>
                  <label htmlFor="end">Fim do plano</label>
                  <DatePicker
                    name="end"
                    onChange={(event) => setEnd(event)}
                    selected={dayjs(end).toDate()}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                  />
                </div>
              </div>
            </div>
            <div>
              <S.TitleWrapper>
                <img src={image13} alt="Vigência do Planejamento" />
                <div>
                  <S.Title>Ciclo base do plano</S.Title>
                  <S.SubTitle>
                    Qual ciclo do diagnóstico será usado para construção do PIT?
                  </S.SubTitle>
                </div>
              </S.TitleWrapper>
              <div>
                <label htmlFor="years">Resultados dos ciclos</label>
                {surveyListLoading ? (
                  <CircularProgress size={30} />
                ) : (
                  <Select
                    labelId="years"
                    id="years"
                    name="years"
                    value={surveyScheduleId}
                    onChange={(event) => {
                      setScheduleNameAndSurveyId(event.value);
                      setSurveyScheduleId(event);
                    }}
                    options={getSchoolSurveySchedules(surveyList?.surveys).map(
                      (schedule) => ({
                        label: schedule?.name,
                        value: schedule?.id?.$oid,
                      })
                    )}
                  />
                )}
              </div>
            </div>
          </S.TermPlanAndBaseCycle>
          <S.NetworkDiagnosticReport>
            <S.TitleWrapper>
              <img src={image12} alt="Vigência do Planejamento" />
              <div>
                <S.Title>Relatório de Diagnóstico da Rede</S.Title>
                <S.SubTitle>
                  Como está a sua rede de ensino em relação à adoção de
                  tecnologias?
                </S.SubTitle>
              </div>
            </S.TitleWrapper>
            <DimensionsTable
              results={surveyAnswers}
              resultsAvg={resultsAvg?.length > 0 ? resultsAvg[0] : {}}
              resultsAvgLoading={resultsAvgLoading}
              resultsAvgSuccess={resultsAvgSuccess}
              surveyAnswersLoading={surveyAnswersLoading}
              surveyAnswersSuccess={surveyAnswersSuccess}
              yearOfCycle={yearOfCycle}
            />
            <S.Tip>
              {resultsAvgSuccess &&
                surveyAnswersSuccess &&
                resultsAvg?.length > 0 && (
                  <>
                    <p>
                      <strong>Dica:</strong> Não se esqueça de fazer um
                      levantamento sobre os programas e projetos de tecnologia
                      que a rede já possui.
                    </p>
                    <p>
                      Isso evitará que haja sobreposição de esforços e ações.
                    </p>
                  </>
                )}
            </S.Tip>
          </S.NetworkDiagnosticReport>

          <S.MainGoalText>
            <S.TitleWrapper>
              <img src={image28} alt="Vigência do Planejamento" />
              <S.Title>Escreva abaixo o objetivo geral do PIT</S.Title>
              <S.StyledHelpIcon data-tip="" data-for="mainGoal" />
              <ReactTooltip effect="solid" id="mainGoal" aria-haspopup="true">
                <p>
                  Objetivo geral do Plano é onde a rede de ensino almeja <br />
                  chegar após executar as ações propostas. <br />
                  Ex: Tornar as escolas capixabas, por meio da fluência
                  <br />
                  tecnológica e da cultura digital, capazes de desenvolver
                  <br />
                  práticas pedagógicas inovadoras.
                </p>
              </ReactTooltip>
            </S.TitleWrapper>
            <textarea
              placeholder="Escreva aqui o seu objetivo"
              onChange={(event) => setMainGoal(event?.target?.value)}
              defaultValue={
                institution?.plans &&
                institution?.plans[institution?.plans.length - 1]?.setting_plan
                  ?.main_goal
              }
            />
          </S.MainGoalText>

          <S.ButtonWrapper>
            <button
              onClick={async () => {
                await saveData();
                navigate("/planos/dados-da-rede");
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "VOLTAR"
              )}
            </button>
            <button onClick={saveData}>
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "SALVAR"
              )}
            </button>
            <button
              onClick={async () => {
                await saveData();
                navigate("/planos/visao");
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "AVANÇAR"
              )}
            </button>
          </S.ButtonWrapper>
        </S.Content>
      </S.Wrapper>
    </Layout>
  );
}
