import styled from "styled-components";
import { container } from "global-styles/shared-styles";
import banner from "assets/images/calculadora/01.svg";

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-family: ${({ theme }) => theme.fonts.poppins};

  > header {
    background-image: url(${banner});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: 46rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #85c440;
  }

  > div:nth-child(2) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #f0f0f0;
    > article:nth-child(1) {
      ${container};
      display: flex;
      gap: 4rem;
      justify-content: center;
      margin: 8rem auto;
      flex-wrap: wrap;
      align-items: center;
    }

    > div:nth-child(2) {
      background-color: ${({ theme }) => theme.colors.white};
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 2rem;
      padding: 8rem;

      > h1 {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors.blue5};
      }

      > p {
        font-size: 2rem;
        color: ${({ theme }) => theme.colors.blue5};
      }

      > div {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50rem, 1fr));
        gap: 4rem;
        margin-top: 5rem;

        > div {
          box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 1rem;
          padding: 4rem;
          display: flex;
          align-items: center;
          gap: 6rem;
          justify-content: center;
          > img {
            width: 22rem;
          }

          > p {
            line-height: 1.5;
            font-size: 1.8rem;
            color: ${({ theme }) => theme.colors.blue5};

            > a {
              font-weight: 500;
              color: #7eb846;
            }

            > span {
              font-weight: 500;
              color: #ff0000;
            }
          }
        }
      }
    }

    > article:nth-child(3) {
      ${container};
      margin: 8rem auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8rem;

      > p {
        font-size: 1.8rem;
        color: ${({ theme }) => theme.colors.blue5};
        line-height: 1.5;
        text-align: center;
      }

      > img {
        width: 100rem;
      }

      > button {
        background-color: #141d3c;
        color: ${({ theme }) => theme.colors.white};
        padding: 1.5rem 2rem;
        border-radius: 0.5rem;
        border: none;
        font-size: 1.8rem;
        cursor: pointer;
      }
    }
  }
`;

export const Test = styled.div``;
