import React from "react";
import * as S from "./styles";
import { registerLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
registerLocale("pt-BR", ptBR);

export default function DatePicker(props) {
  return (
    <S.StyledDatePicker
      {...props}
      popperContainer={S.Popper}
      calendarContainer={S.Calendar}
      locale="pt-BR"
      dateFormat="dd/MM/yyyy"
    />
  );
}
