import React from "react";
import PropTypes from "prop-types";
import { CircularProgress } from "@material-ui/core";
import * as S from "./styles";

const DIMENSIONS = ["vision", "competence", "red", "infra"];

function DimensionsChart({
  dimensionData,
  dimension,
  title,
  resultPerDimensionLoading,
  resultPerDimensionSuccess,
}) {
  const getLevelValue = (level) => {
    const index = DIMENSIONS?.indexOf(dimension);
    const value = Object.values(dimensionData[index]?.percent)[level];
    return value;
  };

  if (resultPerDimensionLoading) {
    return (
      <S.Wrapper dimension={dimension}>
        <CircularProgress size={30} />
      </S.Wrapper>
    );
  }

  return (
    resultPerDimensionSuccess && (
      <S.Wrapper dimension={dimension}>
        <S.TitleWrapper dimension={dimension}>
          <h1>{title}</h1>
        </S.TitleWrapper>
        <div>
          <div>
            <div
              style={{ height: `${getLevelValue(0) + 5}%` }}
              className={`background_${dimension}`}
            >
              <span>{getLevelValue(0)}%</span>
              <p>Emergente</p>
            </div>
          </div>
          <div>
            <div
              style={{ height: `${getLevelValue(1) + 5}%` }}
              className={`background_${dimension}`}
            >
              <span>{getLevelValue(1)}%</span>
              <p>Básico</p>
            </div>
          </div>
          <div>
            <div
              style={{ height: `${getLevelValue(2) + 5}%` }}
              className={`background_${dimension}`}
            >
              <span>{getLevelValue(2)}%</span>
              <p>Intermediário</p>
            </div>
          </div>
          <div>
            <div
              style={{ height: `${getLevelValue(3) + 5}%` }}
              className={`background_${dimension}`}
            >
              <span>{getLevelValue(3)}%</span>
              <p>Avançado</p>
            </div>
          </div>
        </div>
      </S.Wrapper>
    )
  );
}

DimensionsChart.propTypes = {
  dimensionData: PropTypes.shape([
    {
      percent: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      schools_number: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      level: PropTypes.number,
      feedback: {
        0: PropTypes.string,
        1: PropTypes.string,
        2: PropTypes.string,
        3: PropTypes.string,
      },
    },
    {
      percent: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      schools_number: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      level: PropTypes.number,
      feedback: {
        0: PropTypes.string,
        1: PropTypes.string,
        2: PropTypes.string,
        3: PropTypes.string,
      },
    },
    {
      percent: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      schools_number: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      level: PropTypes.number,
      feedback: {
        0: PropTypes.string,
        1: PropTypes.string,
        2: PropTypes.string,
        3: PropTypes.string,
      },
    },
    {
      percent: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      schools_number: {
        level1: PropTypes.number,
        level2: PropTypes.number,
        level3: PropTypes.number,
        level4: PropTypes.number,
      },
      level: PropTypes.number,
      feedback: {
        0: PropTypes.string,
        1: PropTypes.string,
        2: PropTypes.string,
        3: PropTypes.string,
      },
    },
    { total_schools: 1 },
  ]),
  dimension: PropTypes.oneOf(DIMENSIONS),
  title: PropTypes.string,
  resultPerDimensionLoading: PropTypes.bool,
  resultPerDimensionSuccess: PropTypes.bool,
};

export default DimensionsChart;
