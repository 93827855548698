import { css } from "styled-components";
import media from "styled-media-query";

export const container = css`
  width: 100%;

  padding-inline: 2rem;
  margin-inline: auto;

  ${media.lessThan("huge")`
    &&& {
      padding-inline: 7rem;
    }
  `}

  ${media.greaterThan("small")`
    & {
      max-width: 45rem;
    }
  `}

  ${media.greaterThan("medium")`
    & {
      max-width: 76.8rem;
    }
  `}

  ${media.greaterThan("large")`
    & {
      max-width: 117rem;
    }
  `}

  ${media.greaterThan("huge")`
    & {
      max-width: 144rem;
    }
  `}


  @media print {
    & {
      max-width: 100%;
    }
  }
`;
