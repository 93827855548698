import styled from "styled-components";
import Table from "@mui/material/Table";
import { TextField } from "@mui/material";

export const StyledTable = styled(Table)`
  &&& {
    box-shadow: 0 1rem 1.5rem 0.3rem rgb(0 0 0 / 10%) !important;
    > thead {
      > tr {
        > th:nth-child(1) {
          border-radius: 1rem 0rem 0rem 0rem;
        }
        > th:nth-last-child(1) {
          border-radius: 0rem 1rem 0rem 0rem;
        }
        > th {
          text-align: center;
          text-transform: uppercase;
          padding-inline: 3rem;
          font-size: 1.4rem;
          font-weight: 500;
          background-color: #cfded7;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          background-color: #f5f5f5;
          padding-inline: 3rem;
          height: 8rem;
          font-size: 1.4rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          text-align: center;

          > input {
            border: none;
            height: 3rem;
            padding: 1rem;
          }
        }
      }
    }
  }
`;

export const StyledTextField = styled(TextField)`
  &&& {
    > div {
      font-size: 1.5rem;
    }
  }
`;
