import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  createRef,
} from "react";
// import * as S from "./styles";
import { MapContainer, TileLayer, Polygon, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import ResetViewControl from "@20tab/react-leaflet-resetview";
import restart_alt from "assets/images/ge-in-numbers/restart_alt.svg";
import marker from "assets/images/ge-in-numbers/marker.svg";
import MarkerClusterGroup from "react-leaflet-markercluster";
import axios from "axios";

const center = [-14.988763317275554, -54.21707059070964];

const mapColors = {
  state: ["#159ad4", "#8bc2e6"],
  city: ["#8747d6", "#ccb5f8"],
  allNetworks: ["#F09035", "#f8c999"],
};

const markerIcon = new L.icon({
  iconUrl: marker,
  iconSize: [30, 40],
  iconAnchor: [15, 38],
});

const STATES_COORDINATES = [
  ["Rondônia", { longitude: -62.842, latitude: -10.9132 }],
  ["Acre", { longitude: -70.4729, latitude: -9.2133 }],
  ["Amazonas", { longitude: -64.6531, latitude: -4.1541 }],
  ["Roraima", { longitude: -61.3919, latitude: 2.0827 }],
  ["Pará", { longitude: -53.0722, latitude: -3.981 }],
  ["Amapá", { longitude: -51.9673, latitude: 1.4455 }],
  ["Tocantins", { longitude: -48.3313, latitude: -10.1481 }],
  ["Maranhão", { longitude: -45.2892, latitude: -5.0789 }],
  ["Piauí", { longitude: -42.9705, latitude: -7.3893 }],
  ["Ceará", { longitude: -39.6158, latitude: -5.0932 }],
  ["Rio Grande do Norte", { longitude: -36.6733, latitude: -5.8398 }],
  ["Paraíba", { longitude: -36.8327, latitude: -7.1211 }],
  ["Pernambuco", { longitude: -37.9988, latitude: -8.3261 }],
  ["Alagoas", { longitude: -36.6248, latitude: -9.5137 }],
  ["Sergipe", { longitude: -37.4438, latitude: -10.5838 }],
  ["Bahia", { longitude: -41.7212, latitude: -12.4753 }],
  ["Minas Gerais", { longitude: -44.6734, latitude: -18.4562 }],
  ["Espírito Santo", { longitude: -40.6712, latitude: -19.5749 }],
  ["Rio de Janeiro", { longitude: -42.6524, latitude: -22.1885 }],
  ["São Paulo", { longitude: -48.734, latitude: -22.2635 }],
  ["Paraná", { longitude: -51.6166, latitude: -24.6359 }],
  ["Santa Catarina", { longitude: -50.4749, latitude: -27.2474 }],
  ["Rio Grande do Sul", { longitude: -53.248, latitude: -29.755 }],
  ["Mato Grosso do Sul", { longitude: -54.8456, latitude: -20.3273 }],
  ["Mato Grosso", { longitude: -55.9122, latitude: -12.949 }],
  ["Goiás", { longitude: -49.6225, latitude: -16.0412 }],
  ["Distrito Federal", { longitude: -47.7969, latitude: -15.7812 }],
];

function Map({ data, networkTypeSelected }) {
  const elementsRef = useRef(
    data.map(([key, _]) => ({ NOME_UF: key, ref: createRef() }))
  );

  const [statesData, setStatesData] = useState([]);

  const fetchStatesData = useCallback(async () => {
    const response = await axios.get(
      `https://raw.githubusercontent.com/fititnt/gis-dataset-brasil/master/uf/geojson/uf.json`
    );

    setStatesData((statesData) => [...statesData, response?.data]);
  }, []);

  useEffect(() => {
    fetchStatesData();
  }, [fetchStatesData]);

  const removeSymbol = (string) => string.replace("�", "");

  const onClickShowMarker = (NOME_UF) => {
    const marker = elementsRef.current.find(
      (item) =>
        item.NOME_UF.replace(/[^a-zA-Z\s]/g, "") === removeSymbol(NOME_UF)
    ).current;
    if (marker) {
      marker.openPopup();
    }
  };

  return (
    <MapContainer
      center={center}
      scrollWheelZoom={false}
      zoom={5}
      style={{ width: "100%", height: "100%", zIndex: 2, borderRadius: "1rem" }}
    >
      <TileLayer
        url="https://api.maptiler.com/maps/basic-v2/256/{z}/{x}/{y}.png?key=SoDj5TqTGbx6U15jRvYc"
        attribution='<a href="https://www.maptiler.com/copyright/" target="_blank">&copy; MapTiler</a> <a href="https://www.openstreetmap.org/copyright" target="_blank">&copy; OpenStreetMap contributors</a>'
      />
      {data.map(([key, value], index) => (
        <MarkerClusterGroup key={key}>
          <Marker
            ref={elementsRef.current[index]}
            icon={markerIcon}
            position={[
              STATES_COORDINATES?.find((item) => item[0] === key)[1].latitude,
              STATES_COORDINATES?.find((item) => item[0] === key)[1].longitude,
            ]}
          >
            <Popup data-state={value.state}>
              <div
                style={{
                  fontSize: "1.5rem",
                }}
              >
                <h1
                  style={{
                    fontSize: "1.6rem",
                  }}
                >
                  {key}
                </h1>
                <p>
                  <strong
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {value.state}
                  </strong>{" "}
                  docentes da rede estadual
                </p>
                <p>
                  <strong
                    style={{
                      fontWeight: "600",
                    }}
                  >
                    {value.city}
                  </strong>{" "}
                  docentes da rede municipal
                </p>
              </div>
            </Popup>
          </Marker>
        </MarkerClusterGroup>
      ))}
      ;
      {statesData?.map((_, index) => {
        return statesData[index]?.features?.map((state, index) => {
          const geometry = state?.geometry;
          const coordinatesAux =
            geometry.type === "MultiPolygon"
              ? geometry?.coordinates[0][0]
              : geometry?.coordinates[0];

          const coordinates = coordinatesAux.map((item) => [item[1], item[0]]);

          return (
            <Polygon
              key={index}
              UF={state.properties}
              pathOptions={{
                fillColor: mapColors[networkTypeSelected][0],
                fillOpacity: 0.8,
                weight: 2,
                opacity: 0.9,
                dashArray: 3,
                color: mapColors[networkTypeSelected][1],
              }}
              positions={coordinates}
              eventHandlers={{
                mouseover: (e) => {
                  const layer = e.target;
                  layer.setStyle({
                    dashArray: "",
                    fillColor: mapColors[networkTypeSelected][1],
                    fillOpacity: 0.6,
                    opacity: 1,
                    weight: 2,
                    color: mapColors[networkTypeSelected][0],
                  });
                },
                mouseout: (e) => {
                  const layer = e.target;
                  layer.setStyle({
                    fillOpacity: 0.8,
                    weight: 2,
                    opacity: 0.9,
                    dashArray: "3",
                    fillColor: mapColors[networkTypeSelected][0],
                    color: mapColors[networkTypeSelected][1],
                  });
                },
                click: (e) => {
                  onClickShowMarker(e.target.options?.UF?.NOME_UF);
                },
              }}
            />
          );
        });
      })}
      <ResetViewControl title="Reset view" icon={`url(${restart_alt})`} />
    </MapContainer>
  );
}

export default Map;
