import styled from "styled-components";
import media from "styled-media-query";

export const TitleWrapper = styled.div`
  &&& {
    display: flex;
    justify-content: center;
    padding-left: 17rem;
    margin-top: 5rem;

    ${media.lessThan("medium")`
      padding-left: 0rem;
    `}

    @media print {
      padding-left: 15rem;
    }

    > h1 {
      font-size: 2.1rem;
      font-weight: 500;
    }
  }
`;

export const MessageWrapper = styled.div`
  &&& {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    text-align: center;
    width: 88%;
    > p {
      font-size: 1.7rem;
      > a {
        text-decoration: none;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }
`;

export const Wrapper = styled.div`
  &&& {
    font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1.2rem;
    /* width: fit-content; */

    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;

    > div:nth-child(1) {
      width: 100%;
      text-align: center;
      background-color: #d0342c;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 600;
      padding: 1.5rem;
    }

    .results_print_column {
      display: flex;
      justify-content: center;
      padding-left: 19rem;
      margin: 2rem 0rem 4rem 0rem;

      ${media.lessThan("medium")`
        padding-left: 0rem;
        flex-wrap: wrap;
        width: 100%;
        gap: 1rem;
      `}

      @media print {
        padding-left: 15rem;
      }
      .result_box {
        display: flex;
        flex-direction: column;
        margin: 0 0.5rem;
        font-weight: 500;

        ${media.lessThan("medium")`
          flex: 1 1 15rem;
        `}
        > div {
          border-radius: 0.8rem 0.8rem 0 0;
          width: 13.8rem;
          text-align: center;
          padding: 0.7rem 1rem;
          background-color: rgb(85, 172, 181);
          font-size: 1.4rem;

          ${media.lessThan("medium")`
            width: 100%;
          `}
          @media print {
            width: 13rem;
            font-size: 1.2rem;
          }
          > h1 {
            color: ${({ theme }) => theme.colors.white};
            font-size: 1.2rem;
            font-weight: 500;
            @media print {
              font-size: 1.1rem;
            }
          }
        }
        > span {
          text-align: center;
          padding: 0.5rem 0.7rem;
          border-radius: 0 0 0.8rem 0.8rem;
          background-color: rgb(54, 109, 115);
          font-size: 1.5rem;
          color: ${({ theme }) => theme.colors.white};
        }
      }
    }
  }
`;

export const Table = styled.table`
  &&& {
    position: relative;
    margin: 0 auto;

    .result_puzzle {
      display: flex;
      align-items: center;
      justify-content: center;

      ${media.lessThan("medium")`
        flex-direction: column-reverse;
      `}

      > td {
        > img {
          width: 4.8rem;
          margin-right: 0.5rem;
        }
      }

      > td {
        > span {
          font-weight: 500;
          font-size: 1.6rem;
        }
      }
    }

    .table_title {
      position: absolute;
      height: 21.8rem;
      width: 16.9rem;
      padding: 5.6rem 2.6rem;
      border-radius: 1rem 1rem 0 0;
      font-size: 1.76rem;
      background-color: rgb(54, 109, 115);
      top: -12.4rem;
      left: 0.2rem;

      @media print {
        top: -9.6rem;
        height: 19rem;
        width: 14.4rem;
      }

      > h1 {
        color: ${({ theme }) => theme.colors.white};
        line-height: 1.5;
        font-weight: 500;
        font-size: 1.8rem;
        text-align: center;

        @media print {
          font-size: 1.5rem;
        }
      }
    }

    > thead,
    > tbody {
      > .row_without_img {
        > td,
        > th {
          text-align: center;
          height: 6.4rem;
          background-color: #eee;
          vertical-align: middle;

          @media print {
            height: 6.2rem;
            min-width: 14.5rem;
          }
        }

        .none {
          ${media.lessThan("medium")`
            background-color: ${({ theme }) => theme.colors.white};
          `}
        }

        .title {
          background-color: rgb(85, 172, 181);
          color: ${({ theme }) => theme.colors.white};
          font-size: 1.4rem;
          font-weight: 500;
          padding: 1rem;
          text-align: center;
          min-width: 17rem;

          ${media.lessThan("medium")`
            min-width: 0rem !important;
            width: 7.5rem !important;
          `}

          > img {
            width: 5rem;
            margin-bottom: 1rem;
          }
        }

        .level {
          ${media.lessThan("medium")`
            min-width: 7.5rem !important;
          `}
        }

        .v_title {
          font-size: 1.3rem;
          height: 9rem;
          padding: 1rem 0;
          border-radius: 1rem 1rem 0 0;
          background-color: rgb(212, 180, 0);
          position: relative;

          &::before {
            left: 0;
            bottom: 0;
            height: 2rem;
            width: 100%;
            position: absolute;
            background-color: rgb(232, 197, 0);
            content: "";
          }
        }

        .c_title {
          font-size: 1.3rem;
          height: 9rem;
          padding: 1rem 0;
          border-radius: 1rem 1rem 0 0;
          background-color: rgb(212, 32, 104);
          position: relative;
          &::before {
            left: 0;
            bottom: 0;
            height: 2rem;
            width: 100%;
            position: absolute;
            background-color: rgb(231, 35, 113);
            content: "";
          }
        }

        .r_title {
          font-size: 1.3rem;
          height: 9rem;
          padding: 1rem 0;
          border-radius: 1rem 1rem 0 0;
          background-color: rgb(0, 155, 212);
          position: relative;
          &::before {
            left: 0;
            bottom: 0;
            height: 2rem;
            width: 100%;
            position: absolute;
            background-color: rgb(0, 139, 188);
            content: "";
          }
        }

        .i_title {
          font-size: 1.3rem;
          height: 9rem;
          padding: 1rem 0;
          border-radius: 1rem 1rem 0 0;
          background-color: rgb(152, 212, 78);
          position: relative;
          &::before {
            left: 0;
            bottom: 0;
            height: 2rem;
            width: 100%;
            position: absolute;
            background-color: rgb(134, 189, 69);
            content: "";
          }
        }
      }
    }
  }
`;
