import styled from "styled-components";

export const SVG = styled.svg`
  .circle-center-red {
    fill: #008bbc;
    cursor: pointer;
  }
  .cls-3 {
    fill: #008bbc;
    cursor: default !important;
  }

  .cls-hover-red {
    fill: #008bbc;
  }

  .step-1-red,
  .step-2-red,
  .step-3-red,
  .step-4-red,
  .step-5-red {
    fill: #008bbc;
  }
`;
