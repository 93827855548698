import React from "react";
import * as S from "./styles";

const TITLES = {
  rede: "DA",
  brasil: "DO",
};

const PARSE = {
  ESTADUAL: "ESTADUAIS",
  MUNICIPAL: "MUNICIPAIS",
};

function ComparativeCardCompetences2({ local, type, competence, values }) {
  let localImage;
  try {
    localImage = require(`assets/images/ge-in-numbers/states_${competence}/${local}.svg`);
  } catch (error) {
    localImage = null;
  }

  const data = values ? Object.values(values) : [];

  return (
    <S.Wrapper>
      <header>
        {localImage && <img src={localImage} alt={`${local}`} />}
        <h1>
          DOCENTES {PARSE[type?.toUpperCase()]}
          <br /> {TITLES[local]} {local?.toUpperCase()}
        </h1>
      </header>
      <main>
        {data.length ? (
          <>
            <div>
              <span>PRÁTICA PEDAGÓGICA</span>
              <S.CompetenceLevel
                value={data[0] || null}
                competence={competence}
              >
                {[...new Array(5).keys()].map((value) => (
                  <li key={value}>
                    <p>{value + 1}</p>
                    <span />
                  </li>
                ))}
              </S.CompetenceLevel>
            </div>
            <div>
              <span>PERSONALIZAÇÃO</span>
              <S.CompetenceLevel
                value={data[1] || null}
                competence={competence}
              >
                {[...new Array(5).keys()].map((value) => (
                  <li key={value} item={value}>
                    <p>{value + 1}</p>
                    <span />
                  </li>
                ))}
              </S.CompetenceLevel>
            </div>
            <div>
              <span>AVALIAÇÃO</span>
              <S.CompetenceLevel
                value={data[2] || null}
                competence={competence}
              >
                {[...new Array(5).keys()].map((value) => (
                  <li key={value} item={value}>
                    <p>{value + 1}</p>
                    <span />
                  </li>
                ))}
              </S.CompetenceLevel>
            </div>
            <div>
              <span>CURADORIA E CRIAÇÃO</span>
              <S.CompetenceLevel
                value={data[3] || null}
                competence={competence}
              >
                {[...new Array(5).keys()].map((value) => (
                  <li key={value} item={value}>
                    <p>{value + 1}</p>
                    <span />
                  </li>
                ))}
              </S.CompetenceLevel>
            </div>
          </>
        ) : (
          <p>Não há dados disponíveis.</p>
        )}
      </main>
    </S.Wrapper>
  );
}

export default ComparativeCardCompetences2;
