import { useGoalActionsTable } from "hooks/use-goal-actions-table";
import React from "react";
import * as S from "./styles";
import { v4 as uuidv4 } from "uuid";
import { useEffect } from "react";
import { useMemo } from "react";

export default function Targets({ dimension, goal, goalId }) {
  const editTableHookValues = useGoalActionsTable(dimension);
  const {
    register,
    saveGoalDescription,
    targets,
    setTargets,
    unregister,
    setValue,
    goals,
  } = editTableHookValues;

  const targetsFiltered = useMemo(() => {
    return targets.filter((target) => target.goalId === goalId);
  }, [targets, goalId]);

  useEffect(() => {
    const allTargets = [];

    goals?.forEach((goal, index) => {
      setValue(`goal_description.goal_${index}`, goal?.description);
      setValue(`school_number.goal_${index}`, goal?.school_number);
      goal?.targets?.forEach(({ target, goalId, id, indicators }) => {
        allTargets.push({ target, goalId, id, indicators });
        setValue(`targets.${goalId}.${id}.target`, target);
        indicators &&
          indicators.forEach((indicator) => {
            setValue(
              `targets.${goalId}.${id}.indicators.${indicator.id}`,
              indicator.indicator
            );
          });
      });
    });
    setTargets(allTargets);
  }, [goals, setValue, setTargets]);

  return (
    <S.IndicatorsAndGoals>
      <div>
        <h1>Metas e indicadores</h1>

        {goal && (
          <S.Button
            onClick={() => {
              if (!goalId) return;
              const targetsUpdated = [
                ...targets,
                { id: uuidv4(), target: "", indicators: [], goalId },
              ];

              setTargets(targetsUpdated);
              saveGoalDescription(targetsUpdated);
            }}
          >
            Adicionar meta
          </S.Button>
        )}
      </div>
      {Array.isArray(targetsFiltered) &&
        targetsFiltered?.map((target, index) => (
          <S.Target key={target.id}>
            <div>
              <label>Meta {index + 1}</label>
              <div>
                <textarea
                  disabled={!goal}
                  {...register(`targets.${goalId}.${target.id}.target`)}
                  onBlur={() => saveGoalDescription()}
                />
              </div>
            </div>
            <div>
              <label>Indicadores:</label>
            </div>

            {target?.indicators?.map(({ id }, i) => (
              <div key={`indicator_${id}`}>
                <div>
                  <p>{i + 1}</p>
                  <textarea
                    disabled={!goal}
                    placeholder={`Indicador ${i + 1}`}
                    {...register(
                      `targets.${goalId}.${target.id}.indicators.${id}`
                    )}
                    onBlur={() => saveGoalDescription()}
                  />
                  {goal && (
                    <S.Button
                      onClick={() => {
                        unregister(
                          `targets.${goalId}.${target.id}.indicators.${id}`
                        );
                        const targetsAux = [...targets];
                        const targetAux = targetsAux.find(
                          ({ id }) => target.id === id
                        );

                        targetAux.indicators = targetAux?.indicators?.filter(
                          (indicador) => indicador.id !== id
                        );

                        setTargets(targetsAux);
                        saveGoalDescription(targetsAux);
                      }}
                    >
                      Excluir
                    </S.Button>
                  )}
                </div>
              </div>
            ))}
            {goal && (
              <div>
                <S.Button
                  onClick={() => {
                    const targetsAux = [...targets];
                    const targetAux = targetsAux.find(
                      ({ id }) => target.id === id
                    );
                    targetAux.indicators = [
                      ...targetAux.indicators,
                      {
                        id: uuidv4(),
                        indicador: "",
                      },
                    ];
                    setTargets(targetsAux);
                    saveGoalDescription(targetsAux);
                  }}
                >
                  Adicionar indicador
                </S.Button>
                <S.Button
                  onClick={() => {
                    unregister(`targets.${goalId}.${target.id}`);
                    const targetsAux = [...targets];

                    const targetsFilteredAux =
                      targetsAux.filter(({ id }) => id !== target.id) || [];
                    setTargets(targetsFilteredAux);
                    saveGoalDescription(targetsFilteredAux);
                  }}
                >
                  Excluir meta
                </S.Button>
              </div>
            )}
          </S.Target>
        ))}
      {targets.length === 0 && (
        <div>
          <p>Não há metas registradas para este objetivo.</p>
        </div>
      )}
    </S.IndicatorsAndGoals>
  );
}
