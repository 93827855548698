import React from "react";
import { Link } from "react-router-dom";
import * as S from "./styles";

export default function ThirdStep() {
  return (
    <S.Wrapper>
      <h1 id="c-e-3">
        3. PLANEJAMENTO DE FORMAÇÕES VOLTADAS PARA O DESENVOLVIMENTO DE
        COMPETENCIAS DIGITAIS DOCENTES
      </h1>

      <p>
        Após aplicar a ferramenta de Autoavaliação e analisar os resultados,
        você terá um diagnóstico da situação dos/as docentes em relação às
        competências digitais, e, assim, conseguirá planejar formações
        continuadas mais efetivas, de acordo com o nível de competências
        digitais dos/as docentes.
      </p>

      <p>
        <a
          href="https://guiaedutec.com.br/gestor"
          target="_blank"
          rel="noreferrer"
        >
          <i className="pink">
            Acesse novamente os resultados da Autoavaliação da sua rede de
            ensino
          </i>
        </a>
      </p>

      <p>
        Para que o planejamento de formações continuadas tenha mais
        consistência, é importante que ele esteja previsto e alinhado às demais
        ações da dimensão de competência no{" "}
        <Link to="/planos"><i>Plano de Inovação e Tecnologias (PIT)</i></Link> para
        rede. Esse instrumento funciona como bússola, orientando o que precisa
        ser feito, quando e quem são os responsáveis. Então, paralelo a
        construção do plano de formação, elabore o PIT. Você pode entender mais
        sobre esse processo na Jornada de Visão.
      </p>

      <h2 id="c-e-3-s-1">3.1 O que é preciso para planejar as formações</h2>

      <p>
        As formações precisam ser práticas, ter objetivos de aprendizagem claros
        e bem definidos, e serem conduzidas por formadores/as especialistas no
        conteúdo.
      </p>

      <p>
        Para que essas formações atinjam seu propósito, algumas etapas devem ser
        realizadas:
      </p>

      <p>Antes da formação</p>

      <ul>
        <li>
          Faça um levantamento da infraestrutura disponível para atender às
          formações. Verifique os espaços à disposição e os equipamentos
          tecnológicos disponíveis. Os ambientes virtuais da rede de ensino
          também podem ser utilizados para as formações a distância ou
          semipresenciais.
        </li>
      </ul>

      <p>
        Os espaços de atividades práticas, que estimulam a participação e a
        experimentação, são uma boa opção para os/as docentes colocarem a "mão
        na massa" e aplicar os novos conhecimentos.
      </p>

      <p>
        Conheça o{" "}
        <a href="https://cieb.net.br/efex/" target="_blank" rel="noreferrer">
          <i className="pink">Conceito Efex</i>
        </a>{" "}
        e veja como pode ser um espaço de experimentação de tecnologias para
        docentes.
      </p>

      <ul>
        <li>
          <span className="pink">Faça a seleção dos/as formadores/as</span> que
          irão conduzir as formações, considerando seus conhecimentos,
          competências e experiências como facilitadores/as. É importante que
          o/a formador/a saiba:
        </li>
        <li>
          habilidades e conteúdos que desenvolvem aquela(s) competência(s)
        </li>
        <li>teorias da aprendizagem</li>
        <li>sobre feedbacks instrucionais</li>
        <li>sobre o engajamento na aprendizagem</li>
        <li>sobre o contexto de atuação</li>
        <li>como encorajar e atuar de forma equitativa</li>
        <li>sobre teorias de Formação</li>
        <li>aprendizagem de adulto</li>
      </ul>

      <p>
        Nas DCN de formação continuada, há 5 fatores que apontam programas de
        formação com impacto positivo, são eles:
      </p>

      <ul>
        <li>foco no conhecimento pedagógico do conteúdo;</li>
        <li>baseado na aprendizagem ativa;</li>
        <li>colaborativo;</li>
        <li>com duração prolongada; e</li>
        <li>coerente (com a política e currículo da rede)</li>
      </ul>

      <p>
        Leve em consideração esses fatores para planejar a formação na rede.
      </p>

      <h2 id="c-e-3-s-2">3.2 Como construir Planos de Formação</h2>

      <p>
        Uma vez feita a seleção dos/as formadores/as, defina o formato das
        formações, ou seja, sua metodologia, estratégia e formas de avaliação.
      </p>

      <p>
        Adotar um padrão de plano de formação será útil por vários motivos: fará
        com que o encontro realmente atenda às demandas identificadas na rede de
        ensino e ainda lhe ajudará a identificar e organizar mais facilmente os
        materiais e equipamentos tecnológicos necessários.
      </p>

      <p>
        Normalmente, o Plano de Formação é desenvolvido pelo/a formador/a, mas
        você ou alguém da equipe interdisciplinar (tópico explicado na Etapa 2 /
        Como aplicar a Autoavaliação em sua rede), também pode participar dessa
        construção, pois conhece as demandas da rede.
      </p>

      <p>
        Ainda não possui um instrumento de Plano de Formação ? Utilize o{" "}
        <i className="pink">Template de Plano de Formação do CIEB</i>.
      </p>

      <h2 id="c-e-3-s-3">3.3 Como acompanhar e avaliar as formações</h2>

      <p>
        O processo formativo dos/as docentes não termina na realização de uma
        formação. Acompanhar e avaliar as atividades formativas também faz parte
        do processo. Para isso, sugere-se a utilização de um instrumento de
        avaliação para apurar o que pode ser aperfeiçoado nos próximos
        encontros.
      </p>

      <p>
        Você está chegando ao fim da Jornada de Competências. Siga em frente e
        entenda como monitorar o desenvolvimento de competências digitais dos/as
        docentes.
      </p>
    </S.Wrapper>
  );
}
