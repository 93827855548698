import React from "react";
import PropTypes from "prop-types";
import * as S from "./styles";

import visaoAmarelo from "assets/images/devolutiva-rede/visao-amarelo.svg";
import competenciaRosa from "assets/images/devolutiva-rede/competencia-rosa.svg";
import redAzul from "assets/images/devolutiva-rede/red-azul.svg";
import infraVerde from "assets/images/devolutiva-rede/infra-verde.svg";

import vision from "assets/images/table_vision.svg";
import competence from "assets/images/table_competence.svg";
import red from "assets/images/table_red.svg";
import infra from "assets/images/table_infra.svg";

import CircularProgress from "@mui/material/CircularProgress";
import { number } from "utils/masks";
import { useMediaQuery } from "react-responsive";

function Message() {
  return (
    <S.Wrapper>
      <S.MessageWrapper>
        <p>Caro/a gestor/a,</p>
        <p>
          A sua rede ainda não realizou o diagnóstico do nível de adoção de
          tecnologias nas escolas.
        </p>
        <p>
          Antes de iniciar a construção do PIT, é recomendado que as escolas da
          rede respondam o questionário sobre o nível de adoção de tecnologia em
          quatro dimensões: visão, competência, recursos educacionais digitais e
          infraestrutura.
        </p>
        <p>Clique aqui para realizar o diagnóstico da sua rede.</p>
      </S.MessageWrapper>
    </S.Wrapper>
  );
}

function DimensionsTable({
  resultsAvg,
  results,
  resultsAvgLoading,
  resultsAvgSuccess,
  surveyAnswersLoading,
  yearOfCycle,
  sampleAnswer = true,
}) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 540px)" });
  var dimensions = [
    { name: "vision_avg", value: 0 },
    { name: "competence_avg", value: 0 },
    { name: "crd_avg", value: 0 },
    { name: "infra_avg", value: 0 }
  ];

  if(resultsAvg){
    dimensions.forEach(dimension => {
      const spacing = 3.4
      var valueFloat = (resultsAvg[dimension.name] % 1).toFixed(1) * 10;
      var valueDecimal = Math.floor(resultsAvg[dimension.name]);
      var area = spacing / 2;
      var division = ((valueDecimal - 1) * spacing) * 2;

      if(valueFloat > 5){
        area = spacing + 0.2;
      } else if(valueFloat < 5){
        area = 0;
      }

      dimension.value =  33.4 - (area + division)
    })
}

  if (resultsAvgSuccess) {
    if (Object.keys(resultsAvg).length === 0) {
      return <Message />;
    }
  }

  if (resultsAvgLoading || surveyAnswersLoading) {
    return (
      <S.Wrapper>
        <CircularProgress size={30} />
      </S.Wrapper>
    );
  }

  const percentageOfAnswers = parseFloat(
    100 * (results?.answered_count / results?.total_count)
  ).toFixed(1);

  return (
    <S.Wrapper>
      <div style={{ display: percentageOfAnswers > 85 && "none" }}>
        <p>
          Atenção!! Ainda não há respostas suficientes para que o resultado seja
          considerado representativo de sua rede
        </p>
      </div>
      <S.TitleWrapper>
        <h1>RELATÓRIO DAS RESPOSTAS COLETADAS ({yearOfCycle})</h1>
      </S.TitleWrapper>
      <div className="results_print_column">
        {sampleAnswer && (
          <>
            <div className="result_box">
              <div>
                <h1>TOTAL DE ESCOLAS</h1>
              </div>
              <span>{number.resolve(String(results?.total_count))}</span>
            </div>
            <div className="result_box">
              <div>
                <h1>TOTAL DE RESPOSTAS </h1>
              </div>
              <span>
                {number.resolve(String(results?.answered_count))} (
                {results?.total_count > 0 ? percentageOfAnswers : 0}
                %)
              </span>
            </div>
          </>
        )}
      </div>
      <S.Table>
        <thead>
          {!isTabletOrMobile && (
            <tr>
              <td>
                <div className="table_title">
                  <h1>
                    NÍVEL DE
                    <br />
                    ADOÇÃO DE <br />
                    TECNOLOGIA
                    <br />
                    NAS ESCOLAS
                  </h1>
                </div>
              </td>
            </tr>
          )}
          <tr className="row_without_img">
            <th className="none"></th>
            <th className="title v_title">
              {isTabletOrMobile ? <img src={vision} alt="" /> : "VISÃO"}
            </th>
            <th className="title c_title">
              {isTabletOrMobile ? (
                <img src={competence} alt="" />
              ) : (
                <>
                  COMPETÊNCIA <br /> FORMAÇÃO
                </>
              )}
            </th>
            <th className="title r_title">
              {isTabletOrMobile ? (
                <img src={red} alt="" />
              ) : (
                <>
                  RECURSOS
                  <br />
                  EDUCACIONAIS
                  <br /> DIGITAIS
                </>
              )}
            </th>
            <th className="title i_title">
              {isTabletOrMobile ? <img src={infra} alt="" /> : "INFRAESTRUTURA"}
            </th>
          </tr>
        </thead>
        <tbody>
          {resultsAvgSuccess && (
            <tr
              className="result_puzzle result_puzzle_vision"
              style={{
                position: "absolute",
                top: dimensions.find(d => d.name === "vision_avg").value + "rem",
                left: isTabletOrMobile ? "9.3rem" : "22.0rem",
              }}
            >
              <style>
                {`@media print {
                          .result_puzzle_vision{
                            top: ${
                              dimensions.find(d => d.name === "vision_avg").value
                            }rem !important;
                            left: 18.5rem !important;
                          }
                    }`}
              </style>
              <td>
                <img src={visaoAmarelo} alt="1" />
              </td>
              <td>
                <span>{parseFloat(resultsAvg?.vision_avg).toFixed(1)}</span>
              </td>
            </tr>
          )}
          {resultsAvgSuccess && (
            <tr
              className="result_puzzle result_puzzle_competence"
              style={{
                position: "absolute",
                top: dimensions.find(d => d.name === "competence_avg").value + "rem",
                left: isTabletOrMobile ? "16.8rem" : "38.5rem",
              }}
            >
              <style>
                {`@media print {
                          .result_puzzle_competence{
                            top: ${
                              dimensions.find(d => d.name === "competence_avg").value
                            }rem !important;
                            left: 33.5rem !important;
                          }
                }`}
              </style>
              <td>
                <img src={competenciaRosa} alt="2" />
              </td>
              <td>
                <span>{parseFloat(resultsAvg?.competence_avg).toFixed(1)}</span>
              </td>
            </tr>
          )}
          {resultsAvgSuccess && (
            <tr
              className="result_puzzle result_puzzle_red"
              style={{
                position: "absolute",
                top: dimensions.find(d => d.name === "crd_avg").value + "rem",
                left: isTabletOrMobile ? "24.7rem" : "55.0rem",
              }}
            >
              <style>
                {`@media print {
                        .result_puzzle_red{
                          top: ${
                            dimensions.find(d => d.name === "crd_avg").value
                          }rem !important;
                          left: 48.0rem !important;
                        }
                      }`}
              </style>
              <td>
                <img src={redAzul} alt="3" />
              </td>
              <td>
                <span>{parseFloat(resultsAvg?.crd_avg).toFixed(1)}</span>
              </td>
            </tr>
          )}
          {resultsAvgSuccess && (
            <tr
              className="result_puzzle result_puzzle_infra"
              style={{
                position: "absolute",
                top: dimensions.find(d => d.name === "infra_avg").value + "rem",
                left: isTabletOrMobile ? "31.5rem" : "71.5rem",
              }}
            >
              <style>
                {`@media print {
                          .result_puzzle_infra{
                            top: ${
                              dimensions.find(d => d.name === "infra_avg").value
                            }rem !important;
                            left: 61.5rem !important;
                          }
                        }`}
              </style>
              <td>
                <img className="puzzle_infra" src={infraVerde} alt="4" />
              </td>
              <td>
                <span>{parseFloat(resultsAvg?.infra_avg).toFixed(1)}</span>
              </td>
            </tr>
          )}
          <tr className="row_without_img">
            <td className="title level">
              {isTabletOrMobile ? "AVA" : "AVANÇADO (AVA)"}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="row_without_img">
            <td className="title level">
              {isTabletOrMobile ? "INT" : "INTERMEDIÁRIO (INT)"}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="row_without_img">
            <td className="title level">
              {isTabletOrMobile ? "BÁS" : "BÁSICO (BÁS)"}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr className="row_without_img">
            <td className="title level">
              {isTabletOrMobile ? "EME" : "EMERGENTE (EME)"}
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </S.Table>
    </S.Wrapper>
  );
}

DimensionsTable.propTypes = {
  resultsAvgLoading: PropTypes.bool,
  resultsAvgSuccess: PropTypes.bool,
  surveyAnswersLoading: PropTypes.bool,
  results: PropTypes.shape({
    answered_count: PropTypes.number,
    answered_sample: PropTypes.number,
    total_count: PropTypes.number,
    total_sample: PropTypes.number,
  }),
  resultsAvg: PropTypes.shape({
    competence_avg: PropTypes.number,
    crd_avg: PropTypes.number,
    infra_avg: PropTypes.number,
    vision_avg: PropTypes.number,
  }),
  yearOfCycle: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default DimensionsTable;
