import styled from "styled-components";

export const Wrapper = styled.div`
  width: 40rem;
  height: 70rem;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.05);
  border: none;
  border-radius: 0.2rem;
  padding: 8rem 4rem 8rem 4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.8rem;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 1rem;

  > div {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    color: ${({ theme }) => theme.colors.blue5};
    > h1 {
      font-size: 1.8rem;
      font-weight: 500;
    }
    > p {
      font-size: 1.8rem;
      line-height: 1.5;
    }
  }
`;
