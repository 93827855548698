import { createContext, useContext, useState } from "react";

const SideNavigationBarContext = createContext();

function SideNavigationBarProvider({ children }) {
  const [isExpanded, setIsExpanded] = useState(false);
  return (
    <SideNavigationBarContext.Provider value={{ isExpanded, setIsExpanded }}>
      {children}
    </SideNavigationBarContext.Provider>
  );
}

const useSideNavigationBar = () => useContext(SideNavigationBarContext);

export { SideNavigationBarProvider, useSideNavigationBar };
