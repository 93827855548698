import React from "react";
import * as S from "./styles";
import PropTypes from "prop-types";

function Button({ children, dimension, ...props }) {
  return (
    <S.Wrapper {...props} dimension={dimension}>
      {children}
    </S.Wrapper>
  );
}

Button.propTypes = {
  dimension: PropTypes.oneOf(["vision", "competence", "red", "infra"])
    .isRequired,
  children: PropTypes.node.isRequired,
};

export default Button;
