import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * This component scrolls the page to the
 *  top whenever there is navigation using React Router Dom.
 * @returns {null}
 */
export default function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}
