import React from "react";
import * as S from "./styles";

const WIDTH = [40, 35, 30, 25, 20, 15, 10];

function Circle({ progress, width, zIndex, maxWidth }) {
  return (
    <S.CircleWrapper
      data-progress={progress}
      progress={progress}
      width={width}
      zIndex={zIndex}
      maxWidth={maxWidth}
    />
  );
}

function CircleChart({ data }) {
  const maxWidth = Math.max(...WIDTH);
  const total = data.reduce((pV, cV) => pV + cV, 0);
  return (
    <S.Wrapper maxWidth={maxWidth}>
      {data?.map((item, index) => (
        <Circle
          progress={(item / total) * 360}
          width={WIDTH[index]}
          zIndex={index + 1}
          maxWidth={maxWidth}
          key={`circle-${index}`}
        />
      ))}
    </S.Wrapper>
  );
}

export default CircleChart;
