import React from "react";
import Card from "../../../../components/Card";
import notebooks from "assets/images/jornada-infra/hardwares/notebooks.jpg";
import accessPoint from "assets/images/jornada-infra/hardwares/access-point.jpg";
import carrinho from "assets/images/jornada-infra/hardwares/carrinho.jpg";
import firewall from "assets/images/jornada-infra/hardwares/firewall.jpg";
import impressora from "assets/images/jornada-infra/hardwares/impressora.jpg";
import nobreak from "assets/images/jornada-infra/hardwares/nobreak.jpg";
import projetor from "assets/images/jornada-infra/hardwares/projetor.jpg";
import rack from "assets/images/jornada-infra/hardwares/rack.jpg";
import switchs from "assets/images/jornada-infra/hardwares/switch.jpg";
import * as S from "./styles";

export default function FirstStep() {
  return (
    <S.Wrapper>
      <p>Olá gestor, olá gestora!</p>

      <p>
        Bem-vindo/a à Jornada de Infraestrutura! A partir de agora, vamos
        percorrer quatro etapas:
      </p>

      <h1 id="i-e-1">1. PLANEJAR A INFRAESTRUTURA E A CONECTIVIDADE</h1>

      <p>
        <strong>Olá, gestor/a!</strong>
      </p>

      <p>
        Sabemos que os conceitos relacionados à infraestrutura e à conectividade
        são complexos e que, às vezes, estamos pouco habituados a eles.
      </p>

      <p>
        Nesta etapa da Jornada vamos sugerir e explicar como definir
        quantitativos, equipamentos e conectividade para tornar a rede mais
        conectada. Para sermos mais precisos no planejamento de aquisição, temos
        que levar em consideração o perfil das escolas e alguns parâmetros
        mínimos relacionados à infraestrutura que garanta acesso a todos nas
        escolas. Para isso, será necessário fazer um diagnóstico da situação da
        conectividade e da infraestrutura da rede de ensino. Vamos começar?
      </p>

      <h2 id="i-e-1-s-1">1.1 Níveis de adoção de tecnologia</h2>

      <p>
        Para estimar quantos e quais equipamentos são necessários para as
        escolas da rede de ensino, é preciso considerar alguns fatores:
      </p>

      <ul>
        <li>a visão estratégica do uso de tecnologias da rede;</li>
        <li>
          as condições físicas de cada escola (espaço físico, elétrica,
          mobiliário);
        </li>
        <li>
          as condições e quantidade de equipamentos existentes nas escolas;
        </li>
        <li>
          proposta pedagógica com inserção de uso de tecnologias nas escolas;
        </li>
        <li>
          quantidade de equipamentos e qualidade de conexão necessárias às
          escolas;
        </li>
        <li>
          o nível que as escolas se encontram e o nível que a rede quer
          alcançar.
        </li>
      </ul>

      <p>
        Na infraestrutura, assim como nas demais dimensões, definimos quatro
        níveis de adoção de tecnologias: emergente, básico, intermediário e
        avançado. Os níveis são pautados pelo{" "}
        <a
          href="https://cieb.net.br/wp-content/uploads/2021/07/Marco-Conceitual-Escola-Conectada.pdf"
          target="_blank"
          rel="noreferrer"
        >
          <i>Marco Conceitual da Escola Conectada</i>
        </a>{" "}
        e detalhados na dimensão de infraestrutura na Nota Técnica #19. Acesse!
      </p>

      <p>
        Se você ainda não sabe qual é o nível de adoção de tecnologia da sua
        rede, acesse o{" "}
        <a href="https://guiaedutec.com.br" target="_blank" rel="noreferrer">
          <i>Diagnóstico Guia Edutec</i>
        </a>{" "}
        aqui e mobilize as escolas para responder ao questionário.
      </p>

      <Card
        heading="NOTAS"
        title="TÉCNICAS"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="notasTecnicas"
        link="https://cieb.net.br/cieb-notas-tecnicas-10-niveis-de-maturidade-na-adocao-de-tecnologia-pela-escola/"
      />

      <h2 id="i-e-1-s-2">1.2 Equipamentos recomendados</h2>

      <ol type="a">
        <strong>
          <li>Dispositivos para uso dos(as) estudantes</li>
        </strong>
        <ol type="i">
          <li>Notebook/Chromebook/Tablet</li>
          <li>Vantagens e desvantagens de cada um</li>
        </ol>
        <strong>
          <li>Dispositivos para uso docente</li>
        </strong>
      </ol>
      <img src={notebooks} alt="notebooks" />

      <ol type="a" start="3">
        <strong>
          <li>Projetor ou TV</li>
        </strong>
      </ol>

      <img src={projetor} alt="projector" />

      <p>
        É um equipamento que projeta em paredes ou estruturas próprias uma
        imagem da tela de um computador utilizando tecnologia baseada em uma
        lâmpada.
      </p>

      <ol type="a" start="4">
        <strong>
          <li>Carrinho de carregamento</li>
        </strong>
      </ol>

      <img src={carrinho} alt="carrinho de carregamento" />

      <p>?</p>

      <ol type="a" start="5">
        <strong>
          <li>Impressora multifuncional</li>
        </strong>
      </ol>

      <img src={impressora} alt="impressora multifuncional" />

      <p>
        São dispositivos para impressão de conteúdos em papel, que em alguns
        casos possuem múltiplas funções, como: digitalizar um documento
        impresso.
      </p>

      <ol type="a" start="6">
        <strong>
          <li>Access Point</li>
        </strong>
      </ol>

      <img src={accessPoint} alt="access point" />

      <p>
        Access Point, cuja tradução é ponto de acesso, são os equipamentos
        utilizados para transmitir o sinal da rede Wifi pela escola. Também são
        conhecidos como roteador sem fio.
      </p>

      <ol type="a" start="7">
        <strong>
          <li>Switch Layer 3 (mínimo 24 portas)</li>
        </strong>
      </ol>

      <img src={switchs} alt="switch" />

      <p>
        São equipamentos que conectam todos os dispositivos na rede, organizando
        a troca de dados entre eles. Possuem número variado de conectores (cha-
        mados de "portas") para ligar os cabos de redes e os mais comuns são os
        de 8, 12, 16, 24, 32 ou 48 portas.
      </p>

      <ol type="a" start="8">
        <strong>
          <li>Rack 6U ou 8U</li>
        </strong>
      </ol>

      <img src={rack} alt="rack" />

      <p>
        É um tipo de armário fechado feito de metal (aço carbono) e tampa
        frontal de vidro ou acrílico, onde ficam guardados os equipamentos. É
        dentro do rack que são instalados os equipamentos de rede (modem,
        roteador, switch, firewall, servidor de cache e até alguns modelos de
        nobreak), pode ser um armário grande apoiado no chão, ou pequeno preso
        na parede.
      </p>

      <ol type="a" start="9">
        <strong>
          <li>Nobreak</li>
        </strong>
      </ol>

      <img src={nobreak} alt="nobreak" />

      <p>
        Equipamento que utiliza da carga de baterias instaladas em seu interior
        para alimentar os aparelhos, enquanto se recarregam com a energia
        oriunda da rede. Quando ocorre uma queda de energia ele passa a
        alimentar os aparelhos com a energia acumulada nas baterias por alguns
        minutos, até que a energia se restabeleça.
      </p>

      <ol type="a" start="10">
        <strong>
          <li>Firewall</li>
        </strong>
      </ol>

      <img src={firewall} alt="firewall" />

      <p>
        Controla a transferência de dados entre o computador e a internet. Dessa
        forma, impede que o dispositivo (ex.: computador, celular, tablet) ou a
        rede sejam invadidos por hackers ou acessados por softwares maliciosos O
        firewall pode ser um programa ou um computador dedicado apenas a cum-
        prir a função de "guardião" da rede.
      </p>

      <h2 id="i-e-1-s-3">
        1.3 Como estimar a infraestrutura necessária a partir de parâmetros
        pré-definidos
      </h2>

      <p>
        Para auxiliar você a definir a infraestrutura tecnológica necessária
        para a sua rede de ensino, listamos todos os itens necessários (e a
        quantidade de cada um) conforme os diferentes níveis de adoção de
        tecnologias.
      </p>

      <p>Veja a seguir!</p>

      <h3>Parâmetros de infraestrutura - Calculadora de infraestrutura</h3>

      <p>
        A Calculadora de infraestrutura foi desenhada para que o gestor público
        possa dimensionar a quantidade de equipamentos necessários para
        transformar as escolas de sua rede em escolas conectadas, considerando a
        relevância de se pensar o uso pedagógico da tecnologia na escola.
      </p>

      <p>
        Escola Conectada é aquela que possui uma visão estratégica e planejada
        para a incorporação de tecnologia em seu currículo e prática pedagógica,
        conta com equipe capacitada para o uso de tecnologia, utiliza recursos
        digitais e dispõe de equipamentos e conectividade adequadas.*
      </p>

      <p>
        Para isso, a ferramenta dispõe de algumas premissas e parâmetros para
        este cálculo. O objetivo deste documento é detalhar essas informações
        para os gestores públicos.
      </p>

      <p>Cada kit possui duas configurações possíveis:</p>

      <ul>
        <li>
          1 dispositivo por estudante, considerando a quantidade de estudantes
          da maior turma da escola.
        </li>
        Ex: Se a maior turma da escola tiver 40 estudantes, cada kit terá 40
        dispositivos estudantes (o dispositivo estudante pode ser notebook,
        chromebook, tablet, etc) ou,
        <li>
          1 dispositivo para cada 2 estudantes, considerando a quantidade de
          estudantes da maior turma da escola.
        </li>
        Ex: Se a maior turma da escola tiver 40 estudantes, cada kit terá 20
        dispositivos estudantes, para uso em duplas ou grupos. (o dispositivo
        estudante pode ser notebook, chromebook, tablet, etc)
      </ul>

      <h4>
        <span className="green">Nível básico</span>
      </h4>

      <h5>
        <i>Itens para as salas de aula</i>
      </h5>

      <ul>
        <li>1 Kit de dispositivos para cada 8 salas de aula</li>
        <li>1 access point para cada 4 salas de aula</li>
        <li>
          1 computador para uso dos professores, para cada 8 salas de aula
        </li>
        <li>1 projetor ou TV para cada 8 salas de aula</li>
      </ul>

      <h5>
        <i>Itens de conectividade para toda escola</i>
      </h5>

      <ul>
        <li>1 wi-fi (access point) para a área administrativa</li>
        <li>1 wi-fi (access point) para a sala dos professores</li>
        <li>1 switch layer 3 (24 portas no mínimo)</li>
        <li>
          Não há necessidade de firewall, pode-se utilizar o filtro switch
        </li>
        <li>
          1 Rack 6U ou 8U por escola para switch, roteador, firewall, servidores
          e/ou outros equipamentos que houverem na escola
        </li>
        <li>
          1 nobreak para proteger equipamentos que estiverem no Rack de
          oscilações de energia
        </li>
        <li>
          Infraestrutura de cabeamento para cada 4 salas (1 por access point)
        </li>
      </ul>

      <h5>
        <i>Outros itens para a escola</i>
      </h5>

      <ul>
        <li>
          Entre 5 e 10 dispositivos para serem compartilhados entre a equipe
          gestora e professores;
        </li>
        <li>
          1 impressora multifuncional para ser compartilhada entre equipe
          gestora e professores;
        </li>
        <li>1 carrinho de carregamento para cada 8 salas de aula</li>
      </ul>

      <h4>
        <span className="green">Nível intermediário</span>
      </h4>

      <h5>
        <i>Itens para sala de aula</i>
      </h5>

      <ul>
        <li>1 kit de equipamentos para cada 5 salas de aula</li>
        <li>1 wi-fi (access point) para cada 2 salas de aula</li>
        <li>1 computador de uso dos professores para cada 5 salas de aula</li>
        <li>1 projetor ou TV para cada 5 salas de aula</li>
      </ul>

      <h5>
        <i>Itens de conectividade para toda escola</i>
      </h5>

      <ul>
        <li>1 switch layer 3 (24 portas no mínimo)</li>
        <li>1 firewall para toda a escola</li>
        <li>1 access point para a área administrativa</li>
        <li>1 access point para a sala dos professores</li>
        <li>
          1 Rack 6U ou 8U por escola para switch, roteador, firewall, servidores
          e/ou outros equipamentos que houverem na escola
        </li>
        <li>
          1 nobreak para proteger equipamentos que estiverem no Rack de
          oscilações de energia
        </li>
        <li>
          Infraestrutura de cabeamento para cada 2 salas de aula (1 por access
          point)
        </li>
      </ul>

      <h5>
        <i>Outros itens para a escola</i>
      </h5>

      <ul>
        <li>
          Entre 6 e 11 dispositivos para serem compartilhados entre a equipe
          gestora e professores;
        </li>
        <li>
          1 impressora multifuncional para ser compartilhada entre equipe
          gestora e professores
        </li>
        <li>1 carrinho de carregamento para cada 5 salas de aula</li>
      </ul>

      <h4>
        <span className="green">Nível avançado</span>
      </h4>

      <h5>
        <i>Itens para sala de aula</i>
      </h5>

      <ul>
        <li>1 kit de equipamentos para cada 2 salas de aula</li>
        <li>1 wi-fi (access point) para cada 2 salas de aula</li>
        <li>1 computador para cada 2 salas de aula</li>
        <li>1 projetor ou TV para cada 2 salas de aula</li>
      </ul>

      <h5>
        <i>Itens de conectividade para toda escola</i>
      </h5>

      <ul>
        <li> 1 switch layer 3 (24 portas no mínimo)</li>
        <li>1 firewall para toda a escola</li>
        <li>2 access point para a área administrativa</li>
        <li>1 access point para sala dos professores</li>
        <li>
          2 access point para locais de uso comum na escola (pátio, corredores,
          refeitório, etc)
        </li>
        <li>
          1 Rack 6U ou 8U por escola para switch, roteador, firewall, servidores
          e/ou outros equipamentos que houverem na escola
        </li>
        <li>
          1 nobreak para proteger equipamentos que estiverem no Rack de
          oscilações de energia
        </li>
        <li>
          Infraestrutura de cabeamento para cada 2 salas de aula (1 para cada
          access point)
        </li>
      </ul>

      <h5>
        <i>Outros itens para a escola</i>
      </h5>

      <ul>
        <li>1 dispositivo para cada professor da escola;</li>
        <li>1 computador por colaborador da equipe gestora</li>
        <li>
          2 impressoras multifuncional para ser compartilhada entre equipe
          gestora e professores
        </li>
        <li>1 ponto de carregamento para cada 2 salas de aula</li>
      </ul>

      <Card
        heading="PARÂMETROS DE"
        title="INFRAESTRUTURA"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="parametrosDeInfraestrutura"
        link="https://guiaedutec.com.br/calculadora-infra/parametros"
      />
    </S.Wrapper>
  );
}
