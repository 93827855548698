import styled from "styled-components";

export const Wrapper = styled.div`
  height: 19.3rem;
  border-radius: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: ${({ selected, theme }) =>
    selected ? "#e5f3f8" : theme.colors.white};
  gap: 2rem;
  line-height: 1.5;
  outline: ${({ selected }) => (selected ? "0.3rem solid #1960b1" : "none")};

  box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
    0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
  cursor: pointer;

  > div:nth-child(1) {
    background-color: ${({ selected }) => (selected ? "#b7deec" : "#e5e6ed")};
    border-radius: 1rem 0rem 0rem 1rem;
    display: flex;
    justify-content: center;
    padding: 2rem;

    > img {
      object-fit: contain;
      width: 16rem;
    }
  }

  > div:nth-child(2) {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 2rem;

    > input[type="checkbox"] {
    }

    > label {
      font-size: clamp(1.2rem, 1.5vw, 1.8rem);
      line-height: 1.5;
      cursor: pointer;
    }
  }
`;
