import React, { useEffect } from "react";
import Helmet from "react-helmet";
import * as S from "./styles.js";
import Layout from "shared-components/Layout";
import image10 from "assets/images/plans/10.svg";
import image03 from "assets/images/plans/03.svg";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useResponsibleTable } from "hooks/use-responsible-table.js";
import ResponsibleTable from "pages/Plans/components/ResponsibleTable";
import NetworkDataTable from "pages/Plans/components/NetworkDataTable";
import { useForm } from "react-hook-form";
import { getStatus } from "utils/plans.js";
import { useState } from "react";

const createData = () => {
  return { name: "", email: "", phone: "", role: "" };
};

export default function NetworkData() {
  const [navigateTo, setNavigateTo] = useState();
  const navigate = useNavigate();
  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();
  const editTableHookValues = useResponsibleTable();
  const {
    institutionLoading,
    institution,
    institutionPlansSuccess,
    institutionPlans,
    institutionPlansLoading,
    institutionSuccess,
    getCurrentPlan,
    addResponsibleInState,
  } = editTableHookValues;

  useEffect(() => {
    if (institutionSuccess) {
      const currentPlan = getCurrentPlan();
      if (
        getStatus(
          currentPlan?.setting_plan?.validity?.start,
          currentPlan?.setting_plan?.validity?.end
        ) === "concluded" ||
        institution?.plans?.length === 0
      ) {
        navigate("/planos");
      }
      const network = currentPlan?.general_data?.network;
      setValue("network", network);
    }
  }, [institutionSuccess, getCurrentPlan, setValue, navigate, institution]);

  const saveNetworkData = () => {
    const data = getValues("network");
    if (!data) return;
    const currentPlan = getCurrentPlan();
    const currentPlanAux = { ...currentPlan };
    if (!currentPlan) return;
    const institutionAux = { ...institution };
    if (!currentPlan.general_data) {
      currentPlan.general_data = {};
    }
    currentPlanAux.general_data.network = data;
    const length = institutionAux?.plans?.length;
    institutionAux.plans[length - 1] = currentPlanAux;
    institutionPlans.mutate({ institution: institutionAux });
  };

  useEffect(() => {
    if (institutionPlansSuccess && !!navigateTo) {
      navigate(navigateTo);
    }
  }, [institutionPlansSuccess, navigate, navigateTo]);

  return (
    <Layout hasNavigationBar name="plans">
      <Helmet title="Guia EduTec | Planos - Dados da Rede" />
      <S.Wrapper>
        <S.WrapperBanner>
          <S.Banner />
        </S.WrapperBanner>

        <S.Contents>
          <S.NetworkName>
            <h1>Nome da Rede</h1>
            <p>{institution?.name}</p>
          </S.NetworkName>
          <S.Responsible>
            <S.TitleWrapper>
              <img src={image03} alt="Nome da Rede" />
              <S.Title>Equipe Responsável</S.Title>
            </S.TitleWrapper>
            <S.TableWrapper>
              <ResponsibleTable
                columns={[
                  "NOME",
                  "EMAIL",
                  "TELEFONE",
                  "CARGO",
                  "EDITAR/DELETAR",
                ]}
              />
              <S.AlignButton>
                <S.Button onClick={() => addResponsibleInState(createData())}>
                  Adicionar
                </S.Button>
              </S.AlignButton>
            </S.TableWrapper>
          </S.Responsible>
          <S.NetworkData>
            <S.TitleWrapper>
              <img src={image10} alt="Dados da Rede" />
              <S.Title>Dados da Rede</S.Title>
            </S.TitleWrapper>

            <p>
              Os valores da tabela de contemplados devem ser iguais ou
              inferiores aos valores da tabela de totais.{" "}
            </p>

            {institutionLoading ? (
              <S.CircularProgressWrapper>
                <CircularProgress size={30} />
              </S.CircularProgressWrapper>
            ) : (
              <>
                <NetworkDataTable
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                  title="TOTAL"
                  type="total"
                  isEditable={true}
                />
                <NetworkDataTable
                  register={register}
                  getValues={getValues}
                  setValue={setValue}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                  title="CONTEMPLADO"
                  type="contemp"
                  isEditable={true}
                  maxValuesType="total"
                />
              </>
            )}
          </S.NetworkData>
          <S.ButtonWrapper>
            <button
              onClick={() => {
                saveNetworkData();
                setNavigateTo("/planos");
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "VOLTAR"
              )}
            </button>
            <button
              onClick={() => {
                saveNetworkData();
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "SALVAR"
              )}
            </button>
            <button
              onClick={async () => {
                saveNetworkData();
                setNavigateTo("/planos/vigencia-objetivo-geral");
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "AVANÇAR"
              )}
            </button>
          </S.ButtonWrapper>
        </S.Contents>
      </S.Wrapper>
    </Layout>
  );
}
