import styled, { css } from "styled-components";
import media from "styled-media-query";

const schoolDiagnosisChartColors = {
  vision: ["#fef2c7", "#fbe58d", "#fadd67", "#f9d541"],
  competence: ["#f7bfd5", "#ee7eaa", "#e9538d", "#e32871"],
  red: ["#baddea", "#73b9d6", "#44a3c7", "#158cba"],
  infra: ["#dcedc9", "#b7db92", "#9ecf6d", "#87c249"],
};

const emphasis = css`
  &::before {
    content: "";
    position: absolute;
    border: 0.2rem solid
      ${({ dimension }) => schoolDiagnosisChartColors[dimension][3]};
    width: 100%;
    height: 100%;
    padding: 5rem 0rem;
    top: 75%;
    transform: translateY(-75%);
  }
`;

export const Wrapper = styled.div`
  padding: 4rem;
  box-shadow: 0 0.3rem 0.6rem rgba(0, 0, 0, 0.16);
  border-radius: 1.8rem;
  display: flex;
  flex-direction: column;
  height: fit-content;

  > header {
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-bottom: 18rem;
    ${media.lessThan("medium")`
      margin-bottom: 10rem;
    `}

    > img {
      width: 5rem;
    }
    > h1 {
      font-size: 2.5rem;
      color: #1a2341;
      font-weight: 500;
    }
  }

  > main {
    display: flex;
    flex-direction: column;
    padding-inline: 8rem;
    width: 100%;
    height: 2.5rem;
    margin-bottom: 8rem;

    > div:nth-child(1) {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;

      > div {
        height: 100%;
        z-index: 1;
        position: relative;

        > span {
          position: absolute;
          left: 0;
          top: -3rem;
          font-weight: 500;
          font-size: 1.7rem;
          color: #1a2341;
          text-align: center;
        }
      }

      > div:nth-child(1) {
        > span {
          ${({ data }) => data[0]?.percent <= 3 && "transform: rotate(-90deg)"};
          ${({ data }) => data[0]?.percent <= 4 && "display: none"};
        }
        width: ${({ data }) => data[0]?.percent}%;
        background-color: ${({ dimension }) =>
          schoolDiagnosisChartColors[dimension][0]};

        ${({ emphasize }) => emphasize === 0 && emphasis}
      }

      > div:nth-child(2) {
        > span {
          ${({ data }) => data[1]?.percent <= 3 && "transform: rotate(-90deg)"};
          ${({ data }) => data[1]?.percent <= 4 && "display: none"};
        }
        width: ${({ data }) => data[1]?.percent}%;
        background-color: ${({ dimension }) =>
          schoolDiagnosisChartColors[dimension][1]};

        ${({ emphasize }) => emphasize === 1 && emphasis}
      }

      > div:nth-child(3) {
        > span {
          ${({ data }) => data[2]?.percent <= 3 && "transform: rotate(-90deg)"};
          ${({ data }) => data[2]?.percent <= 4 && "display: none"};
        }
        width: ${({ data }) => data[2]?.percent}%;
        background-color: ${({ dimension }) =>
          schoolDiagnosisChartColors[dimension][2]};

        ${({ emphasize }) => emphasize === 2 && emphasis}
      }

      > div:nth-child(4) {
        > span {
          ${({ data }) => data[3]?.percent <= 3 && "transform: rotate(-90deg)"};
          ${({ data }) => data[3]?.percent <= 4 && "display: none"};
        }
        width: ${({ data }) => data[3]?.percent}%;
        background-color: ${({ dimension }) =>
          schoolDiagnosisChartColors[dimension][3]};

        ${({ emphasize }) => emphasize === 3 && emphasis}
      }
    }
  }

  > footer {
    padding-inline: 8rem;
    ${media.lessThan("medium")`
      padding-inline: 0rem;
    `}

    margin-bottom: 4rem;
    width: 100%;
    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 2.5rem;

      > div {
        display: flex;
        flex-direction: column;
        gap: 1.2rem;
        flex: 1 1 20rem;
        background-color: ${({ theme }) => theme.colors.white};
        padding: 2rem;
        border-radius: 1rem;
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.08);

        > p:nth-child(1) {
          font-size: 2.8rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          color: ${({ theme }) => theme.colors.blue5};
          position: relative;

          &::after {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            width: 2rem;
            height: 2rem;
            background-color: ${({ theme }) => theme.colors.blue5};
          }
        }

        > p:nth-child(2) {
          font-size: 1.6rem;
          font-weight: 500;
        }

        > p:nth-child(3) {
          font-size: 2.2rem;
          font-weight: 500;
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif;
          color: ${({ theme }) => theme.colors.blue5};

          > span {
            font-size: 1.5rem;
            font-weight: 400;
            font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif;
          }
        }
      }

      > div:nth-child(1) {
        > p:nth-child(1) {
          &::after {
            background-color: ${({ dimension }) =>
              schoolDiagnosisChartColors[dimension][0]};
          }
        }
      }

      > div:nth-child(2) {
        > p:nth-child(1) {
          &::after {
            background-color: ${({ dimension }) =>
              schoolDiagnosisChartColors[dimension][1]};
          }
        }
      }

      > div:nth-child(3) {
        > p:nth-child(1) {
          &::after {
            background-color: ${({ dimension }) =>
              schoolDiagnosisChartColors[dimension][2]};
          }
        }
      }

      > div:nth-child(4) {
        > p:nth-child(1) {
          &::after {
            background-color: ${({ dimension }) =>
              schoolDiagnosisChartColors[dimension][3]};
          }
        }
      }
    }
  }
`;
