import React from "react";
import * as S from "./styles";

export default function PriorityCard({
  itemIndex,
  name,
  description,
  selectPriority,
  prioritiesAmount,
  priority,
}) {
  return priority[itemIndex] ? (
    <S.Wrapper
      key={`priority-card-${itemIndex}`}
      priorities={priority[itemIndex] && priority[itemIndex]}
    >
      {priority.length !== 0 && priority[itemIndex] && (
        <header>
          <S.Priorities status={priority[itemIndex]} priority={priority}>
            {[...Array(prioritiesAmount).keys()]?.map(
              (priorityValue, index) =>
                priority[itemIndex][index] && (
                  <S.Priority
                    key={`priority-${priorityValue + 1}-${itemIndex}`}
                    id={`priority-${priorityValue + 1}-${itemIndex}`}
                    priority={priority}
                    status={priority[itemIndex][index]}
                    onClick={() => selectPriority(itemIndex, priorityValue + 1)}
                  >
                    {priorityValue + 1}
                  </S.Priority>
                )
            )}
          </S.Priorities>
        </header>
      )}
      <main>{description}</main>
      <footer>
        <h2>{name}</h2>
      </footer>
    </S.Wrapper>
  ) : null;
}
