import React from "react";
import * as S from "./styles";

export default function FourthStep() {
  return (
    <S.Wrapper>
      <h1 id="i-e-4">4. MONITORAMENTO</h1>

      <p></p>
    </S.Wrapper>
  );
}
