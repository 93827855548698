import SummaryTable from "../SummaryTable";
import * as S from "./styles";
import GoalActionsTable from "../GoalActionsTable";
import { useGoalActionsTable } from "hooks/use-goal-actions-table";
import { useEffect } from "react";
import Targets from "../Targets";

export default function Goal({ dimension }) {
  const editTableHookValues = useGoalActionsTable(dimension);
  const { register, goals, setValue, saveGoalDescription, setTargets } =
    editTableHookValues;

  useEffect(() => {
    const allTargets = [];
    goals?.forEach((goal, index) => {
      setValue(`goal_description.goal_${index}`, goal?.description);
      setValue(`school_number.goal_${index}`, goal?.school_number);
      goal?.targets?.forEach(({ target, goalId, id, indicators }) => {
        allTargets.push({ target, goalId, id, indicators });
        setValue(`targets.${goalId}.${id}.target`, target);
        indicators &&
          indicators?.forEach((indicator) => {
            setValue(
              `targets.${goalId}.${id}.indicators.${indicator.id}`,
              indicator.indicator
            );
          });
      });
    });
    setTargets(allTargets);
  }, [goals, setValue, setTargets]);

  return goals?.map((goal, index) => (
    <S.Wrapper key={`goal-${index}`}>
      <h1>OBJETIVO {index + 1}</h1>
      <S.SummaryTableWrapper>
        <textarea
          id={`goal_description-${index}`}
          key={`goal_description-${index}`}
          placeholder={`${index + 1}. Escreva aqui o seu objetivo`}
          {...register(`goal_description.goal_${index}`)}
          onBlur={() => saveGoalDescription()}
        />
        <SummaryTable
          tableKey={index}
          totalBudget={goal?.totalBudget}
          register={register}
          dimension={dimension}
        />
      </S.SummaryTableWrapper>
      <Targets dimension={dimension} goal goalId={goal.id} />

      <h1>Ações</h1>

      <S.SpecificGoalsTableWrapper>
        <GoalActionsTable
          columns={[
            "AÇÃO",
            "INÍCIO",
            "FIM",
            "CUSTO",
            "RESPONSÁVEL",
            "EDITAR/DELETAR",
          ]}
          rows={goal?.actions}
          tableKey={index}
          editTableHookValues={editTableHookValues}
          dimension={dimension}
          goalId={goal?.id}
        />
      </S.SpecificGoalsTableWrapper>
    </S.Wrapper>
  ));
}
