import { css } from "styled-components";
import golosMediumEot from "./golos/golos_ui_medium-webfont.eot";
import golosMediumWoff2 from "./golos/golos_ui_medium-webfont.woff2";
import golosMediumWoff from "./golos/golos_ui_medium-webfont.woff";

import golosBoldEot from "./golos/golos_ui_bold-webfont.eot";
import golosBoldWoff2 from "./golos/golos_ui_bold-webfont.woff2";
import golosBoldWoff from "./golos/golos_ui_bold-webfont.woff";

import golosRegularEot from "./golos/golos_ui_regular-webfont.eot";
import golosRegularWoff2 from "./golos/golos_ui_regular-webfont.woff2";
import golosRegularWoff from "./golos/golos_ui_regular-webfont.woff";

export const fontGolos = css`
  @font-face {
    font-family: "Golos";
    src: url(${golosMediumEot});
    src: url(${golosMediumWoff2}) format("woff2"),
      url(${golosMediumWoff}) format("woff");
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: "Golos";
    src: url(${golosBoldEot});
    src: url(${golosBoldWoff2}) format("woff2"),
      url(${golosBoldWoff}) format("woff");
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: "Golos";
    src: url(${golosRegularEot});
    src: url(${golosRegularWoff2}) format("woff2"),
      url(${golosRegularWoff}) format("woff");
    font-weight: 400;
    font-style: normal;
  }
`;
