import styled from "styled-components";

const COLORS_BUTTON = {
  competence: "#af0161",
  vision: "#615310",
  red: "#004b66",
  infra: "#00672f",
};

export const Wrapper = styled.button`
  cursor: pointer;
  padding: 1.3rem 2.2rem;
  border-radius: 0.7rem;
  border: none;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ dimension }) => COLORS_BUTTON[dimension]};
  display: flex;
  align-items: center;
  gap: 1rem;
  width: fit-content;
  > p {
    display: flex;
    align-items: center;
    padding: 0;
    margin: 0;
    line-height: 0 !important;
  }

  &:hover {
    opacity: 0.9;
  }
`;
