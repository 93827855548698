import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

import {
  useInNumbersQuery,
  useInNumbersQueryComparative,
} from "services/ge-in-numbers";

const PARSE = {
  city: "municipal",
  state: "estadual",
  allNetworks: "nacional",
};

const PARSE_TYPE = {
  city: "Municipal",
  state: "Estadual",
  allNetworks: "Todos",
};

const GEINNumbersFilterContext = createContext();

function GEINNumbersFilterProvider({ children }) {
  const [networkTypeSelected, setNetworkTypeSelected] = useState("allNetworks");

  const [showGeneralFilterModal, setShowGeneralFilterModal] = useState(false);

  const [comparative1, setComparative1] = useState(false);
  const [comparative2, setComparative2] = useState(false);

  const [stateId, setStateId] = useState({ label: "ESTADOS", value: "" });
  const [stateName, setStateName] = useState("");

  const [cityId, setCityId] = useState({ label: "MUNICÍPIOS", value: "" });
  const [cityName, setCityName] = useState("");

  const [lastCycles, setLastCycles] = useState({
    penultimate: false,
    last: false,
  });

  const [selectedCycle, setSelectedCycle] = useState({
    label: "TODOS",
    value: "",
  });

  const stateIdMemo = useMemo(() => stateId, [stateId]);
  const cityIdMemo = useMemo(() => cityId, [cityId]);

  const stateNameMemo = useMemo(() => stateName, [stateName]);
  const cityNameMemo = useMemo(() => cityName, [cityName]);

  const showGeneralFilterModalMemo = useMemo(
    () => showGeneralFilterModal,
    [showGeneralFilterModal]
  );

  const setComparative1Callback = useCallback(
    (boolean) => {
      setComparative1(boolean);
    },
    [setComparative1]
  );

  const setComparative2Callback = useCallback(
    (boolean) => {
      setComparative2(boolean);
    },
    [setComparative2]
  );

  const networkTypeSelectedMemo = useMemo(
    () => networkTypeSelected,
    [networkTypeSelected]
  );

  const { data: aggregation } = useInNumbersQuery(
    PARSE_TYPE[networkTypeSelectedMemo],
    stateIdMemo?.value,
    cityIdMemo?.value
  );

  const { data: comparativeRede } = useInNumbersQueryComparative(
    "rede",
    PARSE_TYPE[networkTypeSelectedMemo],
    stateIdMemo?.value,
    cityIdMemo?.value,
    comparative1,
    comparative2
  );

  const { data: comparativeState } = useInNumbersQueryComparative(
    "estado",
    PARSE_TYPE[networkTypeSelectedMemo],
    stateIdMemo?.value,
    cityIdMemo?.value,
    comparative1,
    comparative2
  );

  const { data: comparativeNacional } = useInNumbersQueryComparative(
    PARSE[networkTypeSelectedMemo],
    null,
    stateIdMemo?.value,
    cityIdMemo?.value,
    comparative1,
    comparative2
  );

  const dataFiltered = useMemo(() => {
    const data = aggregation;

    if (selectedCycle?.value) {
      return data?.data[parseInt(selectedCycle?.value)];
    } else {
      return data?.data?.total;
    }
  }, [aggregation, selectedCycle]);

  const dataFilteredComparativeRede = useMemo(() => {
    const data = comparativeRede;

    if (selectedCycle?.value) {
      return data?.data[parseInt(selectedCycle?.value)];
    } else {
      return data?.data?.total;
    }
  }, [comparativeRede, selectedCycle]);

  const dataFilteredComparativeEstado = useMemo(() => {
    const data = comparativeState;

    if (selectedCycle?.value) {
      return data?.data[parseInt(selectedCycle?.value)];
    } else {
      return data?.data?.total;
    }
  }, [comparativeState, selectedCycle]);

  const dataFilteredComparativeNacional = useMemo(() => {
    const data = comparativeNacional;

    if (selectedCycle?.value) {
      return data?.data[parseInt(selectedCycle?.value)];
    } else {
      return data?.data?.total;
    }
  }, [comparativeNacional, selectedCycle]);

  const aggregationType = useMemo(
    () => aggregation?.aggregation,

    [aggregation]
  );

  return (
    <GEINNumbersFilterContext.Provider
      value={{
        networkTypeSelected,
        setNetworkTypeSelected,
        lastCycles,
        setLastCycles,
        selectedCycle,
        setSelectedCycle,
        dataFiltered,
        dataFilteredComparativeRede,
        dataFilteredComparativeNacional,
        dataFilteredComparativeEstado,
        stateIdMemo,
        setStateId,
        cityIdMemo,
        setCityId,
        setStateName,
        setCityName,
        stateNameMemo,
        cityNameMemo,
        comparative1,
        comparative2,
        setComparative1: setComparative1Callback,
        setComparative2: setComparative2Callback,
        aggregationType,
        setShowGeneralFilterModal,
        showGeneralFilterModal: showGeneralFilterModalMemo,
      }}
    >
      {children}
    </GEINNumbersFilterContext.Provider>
  );
}

const useGEINNumbersFilter = () => useContext(GEINNumbersFilterContext);

export { GEINNumbersFilterProvider, useGEINNumbersFilter };
