import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import * as S from "./styles";
import { useGoalActionsTable } from "hooks/use-goal-actions-table";
import { convertToMoneyFormat } from "utils/money";

export default function SummaryTable({
  tableKey,
  totalBudget,
  register,
  dimension,
}) {
  const { saveGoalDescription } = useGoalActionsTable(dimension);
  return (
    <TableContainer>
      <S.StyledTable>
        <TableHead>
          <TableRow>
            <TableCell>
              ABRAGÊNCIA <br />
              (número de escolas)
            </TableCell>
            <TableCell>
              ORÇAMENTO TOTAL <br />
              (soma dos custos de cada ação)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <S.StyledTextField
                {...register(`school_number.goal_${tableKey}`)}
                variant="standard"
                type="number"
                inputProps={{ min: 0 }}
                onBlur={() => saveGoalDescription()}
              />
            </TableCell>
            <TableCell>{convertToMoneyFormat(totalBudget)}</TableCell>
          </TableRow>
        </TableBody>
      </S.StyledTable>
    </TableContainer>
  );
}
