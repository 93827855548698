import { useMenu } from "hooks/use-menu";
import { useSideNavigationBar } from "hooks/use-side-navigation-bar";
import PropTypes from "prop-types";
import { useEffect } from "react";
// import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
// import BottomNavigationBar from "shared-components/BottomNavigationBar";
import { Menu } from "shared-components/Menu";
import SideNavigationBar from "shared-components/SideNavigationBar";
import { getStorageUserName } from "utils/local-storage";
import { isAdminCity, isAdminState, isPrincipal } from "utils/users";
import DropdownLogin from "./DropdownLogin";

import * as S from "./styles";

const ROUTES = {
  plans: [
    {
      name: "home",
      route: () => "/planos",
      label: "Início",
      icon: <S.StyledHomeIconOutline />,
    },
    {
      name: "networkData",
      route: () => "/planos/dados-da-rede",
      label: "Dados da rede",
      icon: <S.StyledDataBaseIcon />,
    },
    {
      name: "mainGoal",
      route: () => "/planos/vigencia-objetivo-geral",
      label: "Objetivo geral",
      icon: <S.StyledCalendarIcon />,
    },
    {
      name: "vision",
      route: () => "/planos/visao",
      label: "Visão",
      icon: <S.StyledVisionIcon />,
    },
    {
      name: "competence",
      route: () => "/planos/competencia",
      label: "Competência",
      icon: <S.StyledCompetenceIcon />,
    },
    {
      name: "red",
      route: () => "/planos/red",
      label: "RED",
      icon: <S.StyledREDIcon />,
    },
    {
      name: "infra",
      route: () => "/planos/infraestrutura",
      label: "Infraestrutura",
      icon: <S.StyledInfraIcon />,
    },
    {
      name: "followUp",
      route: () => "/planos/acompanhamento",
      label: "Acompanhamento",
      icon: <S.StyledDocumentReportIcon />,
    },
  ],

  redDemands: [
    {
      name: "home",
      route: () => "/demanda-de-red",
      label: "Início",
      icon: <S.StyledHomeIconOutline />,
      disabled: isPrincipal(),
    },
    {
      name: "networkData",
      route: () => "/demanda-de-red/escola",
      label: "Questionário",
      icon: <S.StyledClipboardListIcon />,
    },
    {
      name: "devolutiva",
      route: () => {
        if (isPrincipal()) {
          return "/demanda-de-red/resultados-da-escola";
        }
        if (isAdminCity() || isAdminState()) {
          return "/demanda-de-red/resultados-da-rede";
        }
        return "";
      },
      label: "Devolutiva",
      icon: <S.StyledDocumentReportIcon />,
    },
  ],

  geInNumbers: [
    {
      name: "teachersSelfAssessment",
      route: () => "/ge-em-numeros/autoavaliacao-professores",
      label: "Autoavaliação de Competências Digitais dos Professores (as)",
      icon: <S.StyledUserGroupIcon />,
    },
    {
      name: "schoolDiagnosis",
      route: () => "/ge-em-numeros/diagnostico-escolas",
      label: "Diagnóstico de Adoção de Tecnologias das escolas",
      icon: <S.StyledSchoolIcon />,
    },
  ],

  infraCalculator: [
    {
      name: "home",
      route: () => "/calculadora-de-infraestrutura",
      label: "Início",
      icon: <S.StyledHomeIconOutline />,
    },
    {
      name: "networkData",
      route: () => "/calculadora-de-infraestrutura/dados-da-rede",
      label: "Dados da rede",
      icon: <S.StyledDataBaseIcon />,
    },
    {
      name: "referenceValues",
      route: () => "/calculadora-de-infraestrutura/valores-de-referencia",
      label: "Valores de Referência",
      icon: <S.StyledReferenceValuesIcon />,
    },
    {
      name: "feedback",
      route: () => "/calculadora-de-infraestrutura/investimento",
      label: "Investimento",
      icon: <S.StyledFeedbackIcon />,
    },
    // {
    //   name: "distribution",
    //   route: () => "/calculadora-de-infraestrutura/distribuicao",
    //   label: "Distribuição",
    //   icon: <S.StyledDistributionIcon />,
    // },
    {
      name: "parameters",
      route: () => "/calculadora-de-infraestrutura/parametros",
      label: "Parâmetros",
      icon: <S.StyledParametersIcon />,
    },
  ],
};

// const NAVIGATION_MOBILE = {
//   geInNumbers: [
//     {
//       name: "filterBottom",
//       // route: () => "/ge-em-numeros/diagnostico-escolas",
//       label: "Diagnóstico de Adoção de Tecnologias das escolas",
//       icon: [<S.StyledFiltroOFFIcon />, <S.StyledFiltroONIcon />],
//       have_an_external_link: false,
//     },
//     {
//       name: "teachersSelfAssessmentBottom",
//       route: () => "/ge-em-numeros/autoavaliacao-professores",
//       have_an_external_link: false,
//       label: "Autoavaliação de Competências Digitais dos Professores (as)",
//       icon: [<S.StyledAutoavaliacaoOFFIcon />, <S.StyledAutoavaliacaoONIcon />],
//     },
//     {
//       name: "schoolDiagnosisBottom",
//       route: () => "/ge-em-numeros/diagnostico-escolas",
//       have_an_external_link: false,
//       label: "Diagnóstico de Adoção de Tecnologias das escolas",
//       icon: [<S.StyledDiagnosticoOFFIcon />, <S.StyledDiagnosticoONIcon />],
//     },
//     {
//       name: "edutecGuideBottom",
//       route: () => "https://guiaedutec.com.br/",
//       have_an_external_link: true,
//       label: "Diagnóstico de Adoção de Tecnologias das escolas",
//       icon: [<S.StyledGuiaedutecOFFIcon />, <S.StyledGuiaedutecONIcon />],
//     },
//   ],
// };

function Layout({ children, hasNavigationBar, name }) {
  const { showMenu, handleShowMenu } = useMenu();

  const { isExpanded, setIsExpanded } = useSideNavigationBar();

  useEffect(() => {
    if (showMenu) {
      window.scrollTo(0, 0);
    }
  }, [showMenu]);

  const navigate = useNavigate();

  // const isTabletOrMobile = useMediaQuery({ query: "(max-width: 920px)" });

  const userName = getStorageUserName()?.split(" ")[0];

  return (
    <S.Wrapper isExpanded={isExpanded} hasNavigationBar={hasNavigationBar}>
      {/* {isTabletOrMobile && (
        <BottomNavigationBar
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          routes={NAVIGATION_MOBILE[name]}
        />
      )} */}
      {hasNavigationBar && (
        <SideNavigationBar
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          routes={ROUTES[name]}
        />
      )}
      <div>
        <S.Nav showMenu={showMenu}>
          <div>
            <div>
              <Link to="/" title="INÍCIO">
                <S.StyledHomeIcon
                  fontSize="large"
                  onClick={() => handleShowMenu(false)}
                />
              </Link>
              <h1>JORNADA ESCOLA CONECTADA</h1>
              <S.StyledLogoCIEB2 />
            </div>
            <div>
              <div
                onClick={() => handleShowMenu(!showMenu)}
                title="VISÃO GERAL"
              >
                {showMenu ? <S.StyledOpenIcon /> : <S.StyledCloseIcon />}
              </div>
              {!!userName ? (
                <div onClick={() => navigate("/login")} title="ENTRAR">
                  <DropdownLogin>
                    <S.StyledAccountCircleIcon fontSize="large" />
                  </DropdownLogin>
                  <span>Olá, {userName}</span>
                </div>
              ) : (
                <div onClick={() => navigate("/login")} title="ENTRAR">
                  <S.StyledLoginIcon fontSize="large" />
                </div>
              )}
              {/* <LanguageSwitcher /> */}
            </div>
          </div>
          <div>
            <Menu setShowMenu={handleShowMenu} />
          </div>
        </S.Nav>
        <S.Content hasNavigationBar={hasNavigationBar} isExpanded={isExpanded}>
          {children}
        </S.Content>
        <S.Footer hasNavigationBar={hasNavigationBar} isExpanded={isExpanded}>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://docs.google.com/forms/d/e/1FAIpQLScL625kD63o2xM7RxDGpjtmG-C1r15VGEFesekDnR7d6TvtMg/viewform"
            >
              PRECISA DE AJUDA?
            </a>
          </div>
          <div>
            <div>
              <S.StyledLogoCIEB />
            </div>
            <div>
              <div>
                <S.StyledFacebookIcon />
                <p>
                  <a
                    href="https://fb.com/cieb.net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cieb.net
                  </a>
                </p>
              </div>
              <div>
                <S.StyledInstagramIcon />
                <p>
                  <a
                    href="https://www.instagram.com/cieb.inovacao/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cieb.inovacao
                  </a>
                </p>
              </div>
              <div>
                <S.StyledTwitterIcon />
                <p>
                  <a
                    href="https://twitter.com/cieb_net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cieb_net
                  </a>
                </p>
              </div>
              <div>
                <S.StyledLinkedInIcon />
                <p>
                  <a
                    href="https://www.linkedin.com/company/cieb-net/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cieb-net
                  </a>
                </p>
              </div>
              <div>
                <S.StyledYouTubeIcon />
                <p>
                  <a
                    href="https://www.youtube.com/c/CIEB_net"
                    target="_blank"
                    rel="noreferrer"
                  >
                    cieb_net
                  </a>
                </p>
              </div>
            </div>
            <div>
              <div>
                <div>
                  <S.StyledFmdGoodIcon />
                  <h1>Endereço</h1>
                </div>
                <div>
                  <p>Rua Laboriosa, 37</p>
                  <p>CEP 05434-060 - São Paulo - SP</p>
                </div>
              </div>
              <div>
                <div>
                  <S.StyledEmailIcon />
                  <h1>Email</h1>
                </div>
                <div>
                  <p>guiaedutec@cieb.net.br</p>
                </div>
              </div>
            </div>
            <div>
              <form>
                <h1>ASSINE NOSSA NEWSLETTER</h1>
                <input placeholder="Qual o seu nome?" />
                <input placeholder="Qual o seu e-mail?" />

                <input type="submit" value="CADASTRAR" />
              </form>
            </div>
            <div>
              <h1>CÓDIGO ABERTO</h1>
              <div>
                <h2>Documentação</h2>
                <p>Para usuários finais</p>
                <p>Para desenvolvedores</p>
              </div>

              <div>
                <h2>Repositórios</h2>
                <p>Banco de dados</p>
                <p>Back-end</p>
                <p>Front-end</p>
              </div>
            </div>
          </div>
          <div>
            <h2>CC BY-NC-SA 4.0</h2>
            <p>
              O conteúdo publicado no site do CIEB está licenciado com a Licença
              Creative Commons - Atribuição - Não Comercial - Compartilha Igual
              4.0 Internacional, a menos que condições e/ou restrições
              adicionais específicas estejam claramente explícitas na página
              correspondente.
            </p>
          </div>
        </S.Footer>
      </div>
    </S.Wrapper>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
