import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function FourthStep() {
  return (
    <S.Wrapper>
      <h1 id="v-e-4">4. ACOMPANHE E MONITORE</h1>

      <p>
        Nesta plataforma, todas as jornadas estão organizadas em ciclos. Ou
        seja, há uma sequência de estágios, e eles vão avançando. Mas, depois de
        um tempo, esse ciclo recomeça. Você que vivencia o universo escolar sabe
        bem como é isso!
      </p>

      <p>
        A quarta e última etapa da Jornada de Visão insere as ações de
        monitoramento e também convida a um novo diagnóstico! Continue em
        frente!
      </p>

      <h2 id="v-e-4-s-1">
        4.1 Como acompanhar os avanços da dimensão Visão na sua rede
      </h2>

      <p>
        Para acompanhar se as ações do seu Plano de Inovação & Tecnologia estão
        alcançando os resultados esperados, sugere-se que o diagnóstico do nível
        de adoção de tecnologias pelas escolas seja refeito anualmente. Isso
        ajudará a rede a monitorar os avanços e a eventual necessidade de
        revisitar as ações planejadas ou incluir novas ações e/ou objetivos.
      </p>

      <Card
        heading="DIAGNÓSTICO DE"
        title="NÍVEIS DE ADOÇÃO"
        buttonText="LEIA MAIS"
        dimension="vision"
        backgroundName="nivelDeAdocao"
        link="https://guiaedutec.com.br/escola"
      >
        Clique aqui para realizar o diagnóstico na sua rede.
      </Card>
    </S.Wrapper>
  );
}
