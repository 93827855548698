import styled from "styled-components";

export const SVG = styled.svg`
  & {
    cursor: pointer;
  }
  .cls-100-flc {
    isolation: isolate;
  }

  .cls-200-flc,
  .cls-400v,
  .cls-400c,
  .cls-400r,
  .cls-400i {
    mix-blend-mode: multiply;
  }

  .infra {
    fill: ${({ infraIsActive }) => (infraIsActive ? "#85c440" : "#bfb6b6")};
    fill: ${({ dimension }) => dimension === "infra" && "#85c440"};
  }

  .vision {
    fill: ${({ visionIsActive }) => (visionIsActive ? "#fad62b" : "#bfb6b6")};
    fill: ${({ dimension }) => dimension === "vision" && "#fad62b"};
  }

  .competence {
    fill: ${({ competenceIsActive }) =>
      competenceIsActive ? "#e62270" : "#bfb6b6"};
    fill: ${({ dimension }) => dimension === "competence" && "#e62270"};
  }

  .red {
    fill: ${({ redIsActive }) => (redIsActive ? "#008bbc" : "#bfb6b6")};
    fill: ${({ dimension }) => dimension === "red" && "#008bbc"};
  }

  .cls-300i {
    fill: #85c440;
  }

  .cls-400v,
  .cls-400c,
  .cls-400r,
  .cls-400i {
    fill: #e8e8e8;
  }

  .cls-800-flc {
    fill: url(#linear-gradient);
  }

  .cls-900-flc {
    fill: url(#linear-gradient-2);
  }

  .cls-1000-flc {
    fill: url(#linear-gradient-3);
  }

  .cls-2000-flc {
    fill: url(#linear-gradient-4);
  }
`;
