import * as S from "./styles";
import { OrbitalSVGCompetence } from "../../OrbitalsSVG";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function OrbitalCompetence({ id, large }) {
  const navigate = useNavigate();

  return (
    <S.Wrapper id={id} large={large}>
      <OrbitalSVGCompetence />
      <p>DESCUBRA EM QUE NÍVEL ESTÃO OS DOCENTES DA SUA REDE</p>
      <p>OFEREÇA FORMAÇÕES QUE VISAM O DESENVOLVIMENTO DE COMPETÊNCIAS</p>
      <p>MONITORE O DESENVOLVIMENTO DE COMPETÊNCIAS DIGITAIS</p>
      <p>CONHEÇA AS COMPETÊNCIAS DIGITAIS PARA DOCENTES</p>
      <p onClick={() => navigate("/jornada/competencia")}>
        COMO DESENVOLVER AS COMPETÊNCIAS DIGITAIS?
      </p>
    </S.Wrapper>
  );
}

OrbitalCompetence.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
};

export default OrbitalCompetence;
