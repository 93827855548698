import { TextField } from "@mui/material";
import styled from "styled-components";

export const StyledTextField = styled(TextField)`
  width: 100%;

  &&& {
    > div {
      font-size: 1.5rem;
    }
    > label {
      font-size: 1.6rem;
    }
  }
`;

export const Wrapper = styled.div`
  position: relative;
  overflow-x: auto;
  > span:nth-child(1) {
    position: absolute;
    bottom: 43%;
    left: ${({ title }) => (title === "TOTAL" ? "1.7rem" : "-0.9rem")};
    font-size: 1.4rem;
    font-weight: 500;
    transform: rotate(-90deg);
  }
  > table {
    width: 100%;
    font-size: 1.4rem;
    font-weight: 500;

    > tbody {
      > tr {
        > td {
          background-color: #f5f5f5;
          padding-inline: 3rem;
          height: 8rem;

          @media print {
            padding-inline: 1rem;
          }

          > div {
            width: 170px;
            display: inline-flex;
            justify-content: center;
            > input {
              border: none;
              border-radius: 2rem;
              width: 6rem;
              height: 2rem;
              margin-right: 1.5rem;
              padding: 1rem;
            }
          }
        }
      }

      > tr:nth-child(1) {
        > td:nth-child(1),
        > td:nth-child(2) {
          background-color: #cfded7;
          width: 17rem;
          text-align: center;

          @media print {
            width: 11.6rem;
          }
        }

        > td:nth-child(1) {
          border-radius: 1rem 0rem 0rem 0rem;
          border-bottom: none;
          width: 7rem;
        }

        > td:nth-last-child(1) {
          border-radius: 0rem 1rem 0rem 0rem;
        }
      }

      > tr:nth-child(2) {
        > td:nth-child(1),
        > td:nth-child(2) {
          background-color: #cfded7;
          width: 17rem;
          text-align: center;
          @media print {
            width: 11.6rem;
          }
        }

        > td:nth-child(1) {
          border-radius: 0rem 0rem 0rem 1rem;
          border-top: none;
          width: 7rem;
        }

        > td:nth-last-child(1) {
          border-radius: 0rem 0rem 1rem 0rem;
        }
      }
    }
  }
`;
