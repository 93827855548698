import styled, { css } from "styled-components";
import GridViewRoundedIcon from "@mui/icons-material/GridViewRounded";
import ViewListRoundedIcon from "@mui/icons-material/ViewListRounded";

export const StyledGridViewIcon = styled(GridViewRoundedIcon)`
  &&& {
    font-size: 3rem;
    color: #ababab;
    cursor: pointer;
  }
`;
export const StyledViewListIcon = styled(ViewListRoundedIcon)`
  &&& {
    font-size: 3rem;
    color: #ababab;
    cursor: pointer;
  }
`;

export const Wrapper = styled.nav`
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2.5rem;
  font-size: 1.3rem;

  > div:nth-child(1) {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    flex-wrap: wrap;
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: center;
    gap: 2rem;

    > ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      border-radius: 1rem;
      padding: 1rem 2.5rem;
      gap: 2rem;
      list-style: none;
      background-color: ${({ theme }) => theme.colors.gray11};
      > li {
        display: flex;
        text-align: center;
        justify-content: space-around;
      }
    }
  }

  > div:nth-child(2) {
    display: flex;
    flex-shrink: 1;
    align-items: center;
    flex-wrap: wrap;
    background-color: ${({ theme }) => theme.colors.white};
    justify-content: center;
    gap: 1rem;
  }
`;

const buttonActiveOrSelectedStyles = css`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 1rem hsl(0deg 0% 0% / 14%);
`;

export const Button = styled.button`
  border: none;
  background-color: transparent;
  ${({ isActive, isSelected }) =>
    (isActive || isSelected) && buttonActiveOrSelectedStyles};
  padding: 0rem 1.5rem;
  height: 3.5rem;
  border-radius: 1rem;
  color: #707070;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  font-weight: 500;

  > svg {
    transform: ${({ isActive }) =>
      isActive ? "rotate(90deg)" : "rotate(0deg)"};
  }
`;

const typeViewActive = css`
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
    0 1rem 2.5rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
`;

export const ButtonTypeView = styled.button`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  ${({ isActive }) => isActive && typeViewActive};
`;
