import React from "react";
import CardHome from "./components/CardHome";
import * as S from "./styles";
import Layout from "shared-components/Layout/index.js";

import image02 from "assets/images/calculadora/02.svg";
import image03 from "assets/images/calculadora/03.svg";
import image04 from "assets/images/calculadora/04.svg";

import image05 from "assets/images/calculadora/05.svg";
import image06 from "assets/images/calculadora/06.svg";
import image07 from "assets/images/calculadora/07.svg";
import image08 from "assets/images/calculadora/08.svg";
import image09 from "assets/images/calculadora/09.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const CARD_HOME_DATA = [
  {
    title: "A FERRAMENTA",
    content: (
      <p>
        Ferramenta online e gratuita que permite gestores(as) calcularem e
        planejarem a aquisição de equipamentos necessários para que suas redes
        façam uso pedagógico de tecnologias digitais.
      </p>
    ),
    image: image02,
  },
  {
    title: "COMO FUNCIONA",
    content: (
      <p>
        A partir do preenchimento do Inventário dentro Guia EduTec, pelos
        gestores escolares, a ferramenta identifica o nível atual de adoção de
        tecnologia na sua rede e aponta o investimento necessário para atingir
        novos estágios
      </p>
    ),
    image: image03,
  },
  {
    title: "NÍVEIS E PARÂMETROS",
    content: (
      <p>
        Conheça os 3 níveis de adoção de tecnologia digital na escola conforme
        objetivos e expectativas de uso pedagógico dos recursos.
      </p>
    ),
    image: image04,
  },
];

export default function InfraCalculatorHome() {
  const navigate = useNavigate();

  return (
    <Layout hasNavigationBar name="infraCalculator">
      <S.Wrapper>
        <header />
        <div>
          <article>
            {CARD_HOME_DATA.map((card) => (
              <div key={`card_home_${card.title}`}>
                <CardHome card={card} />
              </div>
            ))}
          </article>
          <div>
            <h1>ACESSO DIRETO</h1>
            <p>
              Explore de forma segmentada as quatro funcionalidades da
              calculadora
            </p>

            <div>
              <div>
                <img src={image05} alt="" />
                <p>
                  Visualize os dados e informações das escolas que responderam
                  ao diagnóstico do Guia EduTec.{" "}
                  <Link to="/calculadora-de-infraestrutura/dados-da-rede">
                    Confira os Dados da Rede.
                  </Link>
                </p>
              </div>
              <div>
                <img src={image06} alt="" />
                <p>
                  Faça simulações de investimento para a sua rede. Podendo usar
                  os filtros de ciclo, nível, uso e etapa de ensino.{" "}
                  <Link to="/calculadora-de-infraestrutura/investimento">
                    Confira o Investimento.
                  </Link>
                </p>
              </div>
              <div>
                <img src={image07} alt="" />
                <p>
                  Faça simulações da quantidade de equipamentos que cada escola
                  deve receber.{" "}
                  <Link to="/calculadora-de-infraestrutura/investimento">
                    Confira a Distribuição.
                  </Link>
                </p>
              </div>
              <div>
                <img src={image08} alt="" />
                <p>
                  Em breve você poderá realizar o planejamento da sua rede.{" "}
                  <span>Seção em construção.</span>
                </p>
              </div>
            </div>
          </div>
          <article>
            <p>
              Sugerimos este percurso para que você possa explorar todos os
              recursos <br /> que a Calculadora de Infraestrutura oferece. Vamos
              começar?
            </p>
            <img src={image09} alt="" />

            <button
              onClick={() =>
                navigate("/calculadora-de-infraestrutura/dados-da-rede")
              }
            >
              INICIAR FERRAMENTA
            </button>
          </article>
        </div>
      </S.Wrapper>
    </Layout>
  );
}
