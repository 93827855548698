import React from "react";
import * as S from "./styles";

export interface CardParameter {
  amount: number | "";
  description: string;
}

export interface Card {
  id: string;
  title: string;
  image: string;
  level: string;
  parameters: CardParameter[];
}

export interface CardParametersProps {
  card: Card;
}

export default function CardParameters({ card }: CardParametersProps) {
  const { title, image, parameters, level } = card;
  return (
    <S.Wrapper>
      <div>
        <h1>NÍVEL {level}</h1>
        <h2>{title}</h2>
      </div>
      <S.Content>
        <div>
          <img src={image} alt={`${image}`} />
        </div>

        <div>
          {parameters.map((parameter, index) => (
            <div key={index}>
              {parameter?.amount && <div>{parameter?.amount}</div>}
              <p>{parameter?.description}</p>
            </div>
          ))}
        </div>
      </S.Content>
    </S.Wrapper>
  );
}
