import styled from "styled-components";

export const Wrapper = styled.div`
  width: 30rem;
  height: 42rem;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  justify-content: space-between;
  margin-bottom: 4.5rem;
  box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.05);
  border-radius: 1rem;
  padding: 4rem 3rem;

  > div:nth-child(1) {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
      object-fit: contain;
      width: 85%;
    }
  }

  > div:nth-child(2) {
    border-radius: 0.6rem;
    height: 8rem;
    color: ${({ theme }) => theme.colors.blue5};
    display: flex;
    justify-content: space-between;
    align-items: center;
    > h1 {
      font-weight: 400;
      font-size: 1.8rem;
      > button {
        box-shadow: 0 0.4rem 1.3rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.white};
        width: 2rem;
        height: 2rem;
        color: ${({ theme }) => theme.colors.black};
        font-weight: 500;
        border: none;
        cursor: pointer;
      }
    }
  }

  > div:nth-child(3) {
    display: flex;
    justify-content: center;
    gap: 0.5rem;
    > div:nth-child(1) {
      height: 4rem;
      border-radius: 1rem 0em 0rem 1rem;
      background-color: rgb(245, 246, 248);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 2rem;
      width: 100%;
      gap: 1rem;
      > span {
        font-size: 1.8rem;
        font-weight: 500;
      }
      > input {
        width: 15rem;
        height: 2.4rem;
        border: 0.1rem solid lightgray;
        border-radius: 0;
        padding: 1rem;
      }

      > div {
        font-size: 1.8rem;
      }
    }
    > div:nth-child(2) {
      height: 4rem;
      border-radius: 0rem 1rem 1rem 0rem;
      background-color: rgb(245, 246, 248);
      padding: 0 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
`;
