import React from "react";
import Plan from "pages/Plans/components/Plans";
import { GoalActionsTableProvider } from "hooks/use-goal-actions-table";
//
export default function RedPlan() {
  const dimension = "red";
  return (
    <GoalActionsTableProvider dimension={dimension}>
      <Plan
        dimension={dimension}
        previous="/planos/competencia"
        next="/planos/infraestrutura"
      />
    </GoalActionsTableProvider>
  );
}
