import styled, { css } from "styled-components";
import { container } from "global-styles/shared-styles";

const COLORS = ["#1ebad6", "#9c3b85", "#7ac368", "#1cb79c", "#f68c42"];

const BUTTON_COLORS = ["#1488a5", "#79095c", "#63a54e", "#14917d", "#cc5315"];

const areNetworkResults = css`
  gap: 10rem;
  margin-bottom: 10rem;
`;

export const Wrapper = styled.section`
  &&& {
    display: flex;
    flex-direction: column;

    ${({ school }) => !school && areNetworkResults};

    > div:nth-child(1) {
      background-color: #158bbc;
      background: linear-gradient(
        90deg,
        ${({ groupId }) => COLORS[groupId]} 50%,
        #dfeef4 50%
      );

      > div:nth-child(1) {
        display: flex;
        height: 50rem;
        ${container};

        > div:nth-child(1) {
          background-color: ${({ groupId }) => COLORS[groupId]};
          flex: 0.15;
          padding: 2rem;
          display: flex;
          align-items: center;
          justify-content: center;

          > img {
            width: 100%;
            max-width: 10rem;
            object-fit: contain;
          }
        }

        > div:nth-child(2) {
          background-color: #dfeef4;
          flex: 0.75;
          display: flex;
          align-items: center;
          gap: 6rem;
          padding: 6rem 0rem 6rem 6rem;
          justify-content: space-between;
          > div {
            flex: 1;
          }

          > div:nth-child(1) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            > h1 {
              font-size: 4rem;
              font-weight: 600;
            }

            > p {
              font-size: 1.6rem;
            }
          }

          > div:nth-child(2) {
            display: flex;

            > div {
              flex: 1;
              display: flex;
              flex-direction: column;
              align-items: center;
              > span {
                font-size: 5rem;
                font-weight: 500;
                margin-bottom: 3.5rem;
                padding-bottom: 2rem;
                border-bottom: 0.4rem solid ${({ theme }) => theme.colors.white};
              }

              > p:nth-child(2) {
                font-size: 2rem;
                font-weight: 600;
                color: ${({ groupId }) => BUTTON_COLORS[groupId]};
              }

              > p:nth-child(3) {
                font-size: 1.6rem;
                text-align: center;
              }
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      ${container};
      display: flex;
      flex-direction: column;
      gap: 1rem;

      > div:nth-child(1) {
        display: flex;
        /* gap: ${({ school }) => (!school ? "20rem" : "1rem")}; */
        justify-content: center;
        flex-direction: ${({ school }) => (!school ? "row" : "column")};
        margin-top: 5rem;

        /* justify-content: space-between; */
        > p {
          font-size: 2.2rem;
          flex: 0.6;
          /* font-weight: 500; */
        }

        a {
          color: ${({ theme }) => theme.colors.black};
        }

        > div {
          flex: 0.4;
          > p {
            font-size: 2.2rem;
            font-weight: 500;
          }
          > span {
            font-size: 1.6rem;
          }
          > div {
            margin-top: 3rem;
            /* margin-right: 3rem; */
            > div:nth-child(1) {
              text-align: center;
              padding: 1rem;
              background-color: #303652;
              color: ${({ theme }) => theme.colors.white};
              font-size: 1.6rem;
            }
            > div:nth-child(2) {
              background-color: #dfeef3;
              padding: 2rem 2rem;
              display: flex;
              justify-content: center;
              > ul {
                display: flex;
                gap: 8rem;
                list-style: none;
                > li {
                  border-radius: 50%;
                  width: 3rem;
                  height: 3rem;
                  background-color: #303652;
                  font-size: 1.6rem;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  color: ${({ theme }) => theme.colors.white};
                }
              }
            }
          }
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 4rem;
        /* padding: ${({ school }) =>
          !school ? "10rem 7rem" : "0rem 7rem"}; */
        margin-top: ${({ school }) => school && "4rem"};

        > div {
          display: flex;

          > div {
            display: flex;
            align-items: center;
            justify-content: center;
          }

          > div:nth-child(1) {
            flex: 0.1;

            > span {
              font-size: 16rem;
              font-weight: 600;
              color: ${({ groupId }) => COLORS[groupId]};
            }
          }

          > div:nth-child(2) {
            flex: 0.5;
            margin-bottom: 5rem;
            > div {
              height: 18rem;
              width: 50rem;
              background-color: ${({ groupId }) => COLORS[groupId]};
              border-radius: 1rem;
              padding: 4rem;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 2rem;

              > h1 {
                color: ${({ theme }) => theme.colors.white};
                text-transform: uppercase;
                text-align: center;
                font-size: 1.8rem;
              }

              > button {
                background-color: ${({ groupId }) => BUTTON_COLORS[groupId]};
                color: ${({ theme }) => theme.colors.white};
                padding: 1rem 2rem;
                font-size: 1.6rem;
                border: none;
                border-radius: 1rem;
                cursor: pointer;
                &:hover {
                  opacity: 0.8;
                }
              }
            }
          }

          > div:nth-child(3) {
            flex: 0.4;
            justify-content: center;
            gap: 4rem;

            > div {
              box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.1),
                0 0.2rem 0.5rem rgba(0, 0, 0, 0.1);
              width: 7rem;
              height: 7rem;
              border-radius: 1rem;
              display: grid;
              place-items: center;
              > p {
                font-size: 2.5rem;
              }
            }
          }
        }
      }
    }
  }
`;

export const TechnologyModalInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.blue5};
  > h2 {
    font-size: 1.8rem;
    font-weight: 500;
  }

  > p {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }

  > ul {
    margin-left: 2rem;
    > li {
      font-size: 1.6rem;
      margin-bottom: 1rem;
    }
  }
`;
