import styled from "styled-components";
import media from "styled-media-query";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ rightIsEmpty }) =>
    rightIsEmpty ? "row-reverse" : "row"};
  gap: 2rem;
  overflow-x: auto;
  overflow-y: hidden;

  ${media.greaterThan("medium")`
    width: 100%;
    justify-content: center;
  `}

  ${media.lessThan("medium")`
    padding: 0rem 0rem 2rem 0rem;
    gap: 1rem;
  `}
`;

export const Bars = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ left }) => (left ? "flex-end" : "flex-start")};
  width: fit-content;

  > div {
    display: flex;
    flex-direction: column;
    align-items: ${({ left }) => (left ? "flex-end" : "flex-start")};
    justify-content: space-between;
    gap: 2.8rem;
    flex: 1;
  }
`;

export const BarWrapper = styled.div`
  display: flex;
  gap: 1.6rem;
  align-items: center;
  flex-direction: ${({ right }) => (right ? "row-reverse" : "row")};
  justify-content: flex-end;
  width: fit-content;

  > span {
    font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
    font-size: 1.7rem;
  }
`;

export const Bar = styled.div`
  width: ${({ value, max, width }) =>
    `${(value / max) * (0.3 * (width / 10))}rem`};
  height: 1.4rem;
  background-color: ${({ theme, left }) =>
    left ? theme.colors.blue6 : theme.colors.purple3};
`;

export const CentralAxis = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 3rem;
  }

  > h1 {
    text-align: center;
    font-size: 1.5rem;
  }

  > span {
    font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray9};
    text-align: center;
    font-size: 1.5rem;
  }
`;

export const Title = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  width: fit-content;
  font-weight: 500;
  padding-bottom: 2.8rem;
  color: ${({ theme, left, right, center }) => {
    if (left) return theme.colors.blue6;
    if (right) return theme.colors.purple3;
    if (center) return theme.colors.gray9;
  }};
`;
