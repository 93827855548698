import React from "react";
import * as S from "./styles";

import bannerGif from "assets/images/ge-in-numbers/RENDER.gif";

export default function Banner({ update_at, tool }) {
  return (
    <S.Wrapper>
      <S.Banner>
        <S.Links>
          <ul>
            <li>
              <a href="/">Conheça o CIEB</a>
            </li>
            <li>
              <a href="/">Precisa de ajuda?</a>
            </li>
          </ul>
        </S.Links>
        <S.BannerContent>
          <S.InfoDataWrapper>
            <S.BannerTitle>
              <span>GUIA EDUTEC</span> <br />
              EM NÚMEROS
            </S.BannerTitle>
            {tool}
            <ul>
              <li>
                <S.StyledBarChartIcon />
                <p>Dados extraídos do Guia Edutec - CIEB</p>
              </li>
              <li>
                <S.StyledCalendarMonthIcon />
                <p>
                  {update_at.toLocaleDateString("pt-BR") +
                    " às " +
                    update_at.toLocaleTimeString("pt-BR", {
                      timeStyle: "short",
                    })}
                </p>
              </li>
            </ul>
          </S.InfoDataWrapper>
          <div>
            <img src={bannerGif} alt="" />
          </div>
        </S.BannerContent>
      </S.Banner>
    </S.Wrapper>
  );
}
