import * as S from "./styles";
import PropTypes from "prop-types";
import { useMenu } from "hooks/use-menu";
import { useNavigate } from "react-router-dom";
import { useSteps } from "hooks/use-steps";
import { useState, useEffect } from "react";

const DIMENSIONS = {
  v: "visao",
  c: "competencia",
  r: "red",
  i: "infraestrutura",
};

function MenuStep({
  open,
  handleMenu,
  dimension,
  stepNumber,
  children,
  title,
  index,
}) {
  return (
    <S.MenuStep open={open[dimension][index]}>
      <S.MenuStepTitle
        dimension={dimension}
        onClick={(event) => {
          event.preventDefault();
          handleMenu(dimension, index);
        }}
      >
        <div>
          <S.StepNumber dimension={dimension}>{stepNumber}</S.StepNumber>
          <S.TrapezioIcon dimension={dimension} />
        </div>
        <div>{title}</div>
        <div>
          <S.ArrowDropDown open={open[dimension][index]} />
        </div>
      </S.MenuStepTitle>
      {children}
    </S.MenuStep>
  );
}

function MenuSubStep({ children, stepNumber, dimension, subStepNumber }) {
  const navigate = useNavigate();
  const { setStep } = useSteps();

  return (
    <S.MenuSubStep
      onClick={async () => {
        await setStep(stepNumber);
        navigate(`/jornada/${DIMENSIONS[dimension]}`, {
          state: {
            stepNumber,
            menuDimension: dimension,
            subStepNumber,
          },
        });
      }}
    >
      <p>{children}</p>
    </S.MenuSubStep>
  );
}

function MenuVision({ hideTitle }) {
  const [dimension, title] = ["vision", "VISÃO"];
  const { openOrCloseAll, handleMenu, areAllOpen, open } = useMenu();

  return (
    <S.MenuDimension>
      {!hideTitle && (
        <S.MenuHeader onClick={() => openOrCloseAll(dimension)}>
          <S.VisionIcon />
          <h3>{title}</h3>
          <S.ArrowDropDown open={areAllOpen[dimension]} />
        </S.MenuHeader>
      )}
      <S.MenuBody>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={1}
          title="CONCEITO DE VISÃO E ESCOLA CONECTADA"
          index={0}
        >
          <MenuSubStep stepNumber={0} subStepNumber={1} dimension="v">
            1.1 O que significa ter uma Visão para o uso de tecnologias na sua
            rede
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={2} dimension="v">
            1.2 Currículos de Referência em Tecnologia e Computação - da
            Educação Infantil ao Ensino Médio
          </MenuSubStep>
        </MenuStep>

        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={2}
          title="CONHEÇA SUA REDE: DIAGNÓSTICO SOBRE O NÍVEL DE ADOÇÃO DE TECNOLOGIAS NAS ESCOLAS"
          index={1}
        >
          <MenuSubStep stepNumber={1} subStepNumber={1} dimension="v">
            2.1 Por que realizar o diagnóstico da sua rede
          </MenuSubStep>
          <MenuSubStep stepNumber={1} subStepNumber={2} dimension="v">
            2.2 Monte a equipe de trabalho
          </MenuSubStep>
          <MenuSubStep stepNumber={1} subStepNumber={3} dimension="v">
            2.3 Conheça sua rede: Ferramenta de diagnóstico de adoção de
            tecnologia nas escolas
          </MenuSubStep>
        </MenuStep>

        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={3}
          title="ELABORAÇÃO DO PLANO DE INOVAÇÃO & TECNOLOGIA DA REDE"
          index={2}
        >
          <MenuSubStep stepNumber={2} subStepNumber={1} dimension="v">
            3.1 Construa o Plano de Inovação & Tecnologia
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={2} dimension="v">
            3.2 Acompanhe o Plano de Inovação & Tecnologia
          </MenuSubStep>
        </MenuStep>

        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={4}
          title="ACOMPANHAMENTO E MONITORE"
          index={3}
        >
          <MenuSubStep stepNumber={3} subStepNumber={1} dimension="v">
            4.1 Como acompanhar os avanços da dimensão Visão na sua rede
          </MenuSubStep>
        </MenuStep>
      </S.MenuBody>
    </S.MenuDimension>
  );
}

function MenuCompetence({ hideTitle }) {
  const [dimension, title] = ["competence", "COMPETÊNCIAS DIGITAIS"];
  const { openOrCloseAll, handleMenu, areAllOpen, open } = useMenu();

  return (
    <S.MenuDimension>
      {!hideTitle && (
        <S.MenuHeader onClick={() => openOrCloseAll(dimension)}>
          <S.CompetenceIcon />
          <h3>{title}</h3>
          <S.ArrowDropDown open={areAllOpen[dimension]} />
        </S.MenuHeader>
      )}

      <S.MenuBody>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={1}
          title="CONCEITOS EM COMPETÊNCIAS DIGITAIS"
          index={0}
        >
          <MenuSubStep stepNumber={0} subStepNumber={1} dimension="c">
            1.1 O que são competências digitais
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={2} dimension="c">
            1.2 De quais competências digitais estamos falando
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={3} dimension="c">
            1.3 Para que desenvolver competências digitais
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={2}
          title="DIAGNÓSTICO DO NÍVEL DE COMPETÊNCIAS DIGITAIS DOS/AS DOCENTES"
          index={1}
        >
          <MenuSubStep stepNumber={1} subStepNumber={1} dimension="c">
            2.1 O que é a Autoavaliação de Competências Digitais ?
          </MenuSubStep>
          <MenuSubStep stepNumber={1} subStepNumber={2} dimension="c">
            2.2 Como aplicar a Autoavaliação em sua rede
          </MenuSubStep>
          <MenuSubStep stepNumber={1} subStepNumber={3} dimension="c">
            2.3 Como analisar os resultados
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={3}
          title="PLANEJAMENTO DE FORMAÇÕES VOLTADAS PARA O DESENVOLVIMENTO DE COMPETÊNCIAS DIGITAIS DOCENTES"
          index={2}
        >
          <MenuSubStep stepNumber={2} subStepNumber={1} dimension="c">
            3.1 O que é preciso para planejar as formações
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={2} dimension="c">
            3.2 Como construir Planos de Formação
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={3} dimension="c">
            3.3 Como acompanhar e avaliar as formações
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={4}
          title="ACOMPANHAMENTO"
          index={3}
        >
          <MenuSubStep stepNumber={3} subStepNumber={1} dimension="c">
            4.1 Como acompanhar o desenvolvimento de competências digitais
          </MenuSubStep>
          <MenuSubStep stepNumber={3} subStepNumber={2} dimension="c">
            4.2 Como posso saber se houve avanço no nível de desenvolvimento das
            competências digitais
          </MenuSubStep>
        </MenuStep>
      </S.MenuBody>
    </S.MenuDimension>
  );
}

function MenuRed({ hideTitle }) {
  const [dimension, title] = ["red", "RECURSOS EDUCACIONAIS DIGITAIS"];
  const { openOrCloseAll, areAllOpen, open, handleMenu } = useMenu();

  return (
    <S.MenuDimension>
      {!hideTitle && (
        <S.MenuHeader onClick={() => openOrCloseAll(dimension)}>
          <S.RedIcon />
          <h3>{title}</h3>
          <S.ArrowDropDown open={areAllOpen[dimension]} />
        </S.MenuHeader>
      )}
      <S.MenuBody>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={1}
          title="ENTENDENDO RED"
          index={0}
        >
          <MenuSubStep stepNumber={0} subStepNumber={1} dimension="r">
            1.1 O que é RED
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={2} dimension="r">
            1.2 Como são organizados os recursos educacionais digitais
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={2}
          title="ENTENDA AS DEMANDAS DA REDE E QUAIS RED SÃO MAIS ADEQUADOS"
          index={1}
        ></MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={3}
          title="APRENDA A FAZER CURADORIA E AQUISIÇÃO DE RED"
          index={2}
        >
          <MenuSubStep stepNumber={2} subStepNumber={1} dimension="r">
            3.1 Faça pesquisas de mercado
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={2} dimension="r">
            3.2 Avalie os RED de maneira criteriosa
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={3} dimension="r">
            3.3 Avance para o processo de adoção ou aquisição do recurso
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={4}
          title="O CAMINHO ATÉ OS/AS ESTUDANTES: FASE DE IMPLEMENTAÇÃO E ACOMPANHAMENTO"
          index={3}
        >
          <MenuSubStep stepNumber={3} subStepNumber={1} dimension="r">
            4.1 Como colocar os RED em uso
          </MenuSubStep>
          <MenuSubStep stepNumber={3} subStepNumber={2} dimension="r">
            4.2 Entenda a necessidade de oferecer formação para uso dos RED
          </MenuSubStep>
          <MenuSubStep stepNumber={3} subStepNumber={3} dimension="r">
            4.3 Utilize a comunicação a seu favor
          </MenuSubStep>
          <MenuSubStep stepNumber={3} subStepNumber={4} dimension="r">
            4.4 Defina indicadores para acompanhar
          </MenuSubStep>
          <MenuSubStep stepNumber={3} subStepNumber={5} dimension="r">
            4.5 Defina uma estratégia de coleta e frequência das informações
          </MenuSubStep>
        </MenuStep>
      </S.MenuBody>
    </S.MenuDimension>
  );
}

function MenuInfra({ hideTitle }) {
  const [dimension, title] = ["infra", "INFRAESTRUTURA"];
  const { openOrCloseAll, areAllOpen, open, handleMenu } = useMenu();

  return (
    <S.MenuDimension>
      {!hideTitle && (
        <S.MenuHeader onClick={() => openOrCloseAll(dimension)}>
          <S.InfraIcon />
          <h3>{title}</h3>
          <S.ArrowDropDown open={areAllOpen[dimension]} />
        </S.MenuHeader>
      )}
      <S.MenuBody>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={1}
          title="PLANEJAR A INFRAESTRUTURA E A
          CONECTIVIDADE"
          index={0}
        >
          <MenuSubStep stepNumber={0} subStepNumber={1} dimension="i">
            1.1 Níveis de adoção de tecnologia
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={2} dimension="i">
            1.2 Equipamentos recomendados
          </MenuSubStep>
          <MenuSubStep stepNumber={0} subStepNumber={3} dimension="i">
            1.3 Como estimar a infraestrutura necessária a partir de parâmetros
            pré-definidos
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={2}
          title="DIAGNÓSTICO DA SITUAÇÃO DE INFRAESTRUTURA E CONECTIVIDADE DAS REDES"
          index={1}
        >
          <MenuSubStep stepNumber={1} subStepNumber={1} dimension="i">
            2.1 Diagnóstico de conectividade
          </MenuSubStep>
          <MenuSubStep stepNumber={1} subStepNumber={2} dimension="i">
            2.2 Diagnóstico de infraestrutura
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={3}
          title="PLANEJAMENTO, AQUISIÇÃO DE INFRAESTRUTURA E CONTRATAÇÃO DE CONECTIVIDADE"
          index={2}
        >
          <MenuSubStep stepNumber={2} subStepNumber={1} dimension="i">
            3.1 Como planejar a contratação de conectividade
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={2} dimension="i">
            3.2 Como planejar a aquisição de equipamentos
          </MenuSubStep>
          <MenuSubStep stepNumber={2} subStepNumber={3} dimension="i">
            3.3 Aquisição e contratação
          </MenuSubStep>
        </MenuStep>
        <MenuStep
          open={open}
          handleMenu={handleMenu}
          dimension={dimension}
          stepNumber={4}
          title="MONITORAMENTO"
          index={3}
        ></MenuStep>
      </S.MenuBody>
    </S.MenuDimension>
  );
}

function Menu({ setShowMenu }) {
  const [scrollY, setPageYOffset] = useState(0);

  const setOffset = () => setPageYOffset(window.scrollY);

  useEffect(() => {
    window.addEventListener("scroll", setOffset);
    return () => {
      window.removeEventListener("scroll", setOffset);
    };
  }, []);

  return (
    <S.Wrapper scrollY={scrollY}>
      <section onClick={() => setShowMenu(false)}></section>
      <MenuVision />
      <MenuCompetence />
      <MenuRed />
      <MenuInfra />
    </S.Wrapper>
  );
}

Menu.propTypes = {
  setShowMenu: PropTypes.func.isRequired,
};

export { Menu, MenuVision, MenuCompetence, MenuRed, MenuInfra };
