import styled, { css } from "styled-components";
import banner from "assets/images/ge-in-numbers/banner.jpg";
import GroupsIcon from "@mui/icons-material/Groups";
// import { container } from "global-styles/shared-styles";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";

const icons = css`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
    color: white;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    padding: 0.5rem;
  }
`;

export const StyledYouTubeIcon = styled(YouTubeIcon)`
  ${icons};
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
  ${icons};
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${icons};
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  ${icons};
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  ${icons};
`;

export const StyledGroupsIcon = styled(GroupsIcon)`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
`;

export const BannerWrapper = styled.div`
  background: url(${banner}),
    linear-gradient(90deg, rgb(82, 97, 185) 50%, rgb(65, 77, 148) 50%);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Banner = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 39.5rem;
  width: 100%;
  padding: 4.73rem 5.78rem;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 2rem;
  background-color: #f0f0f0;
  position: relative;
  > section {
    padding: 2rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);
  }

  > section:nth-child(1) {
    z-index: 25;
    position: sticky;
    width: 100%;
    top: 7rem;
    > div {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      background-color: ${({ theme }) => theme.colors.white};
      justify-content: center;
      gap: 2.5rem;
      font-size: 1.5rem;

      > span {
        color: ${({ theme }) => theme.colors.blue5};
      }

      > ul {
        display: flex;
        border-radius: 1rem;
        padding: 2rem;
        gap: 1rem;
        list-style: none;
        background-color: ${({ theme }) => theme.colors.gray11};
      }
    }
  }

  > section:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    > div {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem;
      background-color: ${({ theme }) => theme.colors.gray10};
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05),
        0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
    }

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.colors.blue5};
        gap: 1rem;
        flex: 1;
        font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
        > span {
          font-size: 7.5rem;
          font-weight: 600;
        }

        > p {
          font-size: 2.77rem;
          font-weight: 400;
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > header {
        margin-bottom: 3rem;
      }
      > main {
        display: flex;
        flex-direction: column;
        color: ${({ theme }) => theme.colors.blue5};
        gap: 1rem;

        > div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;

          > p {
            flex: 1 1;
            font-size: 2.25rem;
            color: ${({ theme }) => theme.colors.gray9};
          }

          > span {
            flex: 1 1;
            font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
            font-size: 5.55rem;
            font-weight: bold;
          }
        }

        > div:nth-child(1) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.blue6};
          }
        }

        > div:nth-child(2) {
          > span:nth-child(1) {
            color: ${({ theme }) => theme.colors.purple2};
          }
        }

        > div:nth-child(3) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.green5};
          }
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > header {
        margin-bottom: 3rem;
      }

      > main {
        display: flex;
        flex-direction: column;
        color: ${({ theme }) => theme.colors.blue5};
        gap: 2rem;

        > div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;

          > img {
            flex: 0.1 1;
          }

          > div {
            padding: 2rem;
            background-color: ${({ theme }) => theme.colors.white};
            box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05),
              0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
            border-radius: 1rem;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            flex: 0.9 1;

            > p {
              flex: 1 1;
              font-size: 1.6rem;
              > span {
                font-size: 2rem;
                font-weight: 500;
              }
            }

            > div {
              width: 100%;
              height: 0.6rem;
              background-color: ${({ theme }) => theme.colors.gray5};
              border-radius: 1rem;
              position: relative;

              &::before {
                position: absolute;
                content: "";
                border-radius: 1rem;
                left: 0;
                width: 50%;
                height: 0.6rem;
                background-color: ${({ theme }) => theme.colors.red};
              }
            }
          }
        }
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    > main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        flex: 0.7 1;

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 6rem;
          flex-wrap: wrap;
          > div:nth-child(1) {
            flex: 1 1;
            position: relative;
            background-color: #f8f0f4;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 2rem;
            padding: 2rem 4rem 2rem 12rem;
            border-radius: 4rem 2rem 2rem 4rem;
            height: 10rem;
            > img {
              position: absolute;
              width: 10rem;
              left: 0rem;
              top: 50%;
              transform: translateY(-50%);
            }

            > span {
              color: ${({ theme }) => theme.colors.blue5};
            }

            > div {
              display: flex;
              align-items: center;
              gap: 2rem;
              > p {
                font-size: 2.175rem;
              }
              > span {
                font-size: 5.55rem;
                font-weight: 600;
              }
            }
          }
          > img {
            /* flex: 1 1; */
            width: 26.7rem;
          }
        }
      }

      > div:nth-child(2) {
        flex: 0.3 1;
        display: flex;
        justify-content: center;
        align-items: center;

        > div {
          padding: 3rem;
          border: 0.1rem solid rgba(0, 0, 0, 0.1);
          height: fit-content;
          width: fit-content;
          border-radius: 1rem;

          > ul {
            display: flex;
            flex-direction: column;
            gap: 4rem;
            list-style: none;

            > li {
              > span {
                margin-right: 1rem;
                font-size: 1.8rem;
                font-weight: 500;
              }
            }
          }
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      gap: 6rem;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05),
        0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);

      > div {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > h1 {
          font-size: 2.25rem;
          font-weight: 500;
          color: #7e255a;
          margin-bottom: 1rem;
        }
        > div {
          display: flex;
          justify-content: space-between;
          border: 0.1rem solid rgba(0, 0, 0, 0.1);
          border-radius: 2rem;
          padding: 1rem 2rem;
          > span {
            font-weight: 400;
            font-size: 2.175rem;
          }

          > ul {
            display: flex;
            gap: 4rem;
            list-style: none;

            > li {
              position: relative;
              z-index: 2;
              position: relative;
              border: 1px solid red;

              > p {
                z-index: 1;
                color: white;
              }

              > span {
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                position: absolute;
                background-color: #7e255a;
                width: 3rem;
                height: 5rem;
                border-radius: 2rem;
                z-index: -1;
              }
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 2rem;
      flex: 1 1;

      > div {
        flex: 1 1;
        padding: 2rem;
        border-radius: 1rem;
        background-color: ${({ theme }) => theme.colors.white};
        box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05),
          0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
      }

      > div:nth-child(1) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        > div {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: 4rem;
        }
      }
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
  }

  > section:nth-child(6) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;
    > div {
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem;
      background-color: ${({ theme }) => theme.colors.white};
      box-shadow: 0 0.2rem 0.5rem rgba(0, 0, 0, 0.05),
        0 0.2rem 0.5rem rgba(0, 0, 0, 0.05);
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  > section:nth-child(7) {
    display: flex;
    flex-direction: column;
    gap: 3rem;

    > div {
      display: flex;
      justify-content: space-between;

      > div:nth-child(1) {
        flex: 0.4;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      > div:nth-child(2) {
        flex: 0.6;
        > ul {
          display: flex;
          flex-direction: column;
          gap: 4rem;
          list-style: none;
          > li {
            font-size: 1.875rem;
            display: flex;
            gap: 1.5rem;
            > div {
              width: 1.5rem;
              height: 1.5rem;
              background-color: #a0941d;
              border-radius: 50%;
            }
          }
        }
      }
    }
  }
  > section:nth-child(8) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 4rem 2rem 8rem 2rem;

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5rem;
      > header {
        > nav {
          padding: 1.4rem 4rem;
          border-radius: 4rem;
          width: fit-content;
          background-color: #f8f8f8;
          > ul {
            list-style: none;
            display: flex;
            justify-content: center;
            gap: 3rem;
          }
        }
      }

      > main {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        gap: 1.2rem;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          background-color: ${({ theme }) => theme.colors.white};
          /* width: 10rem; */
          height: 30rem;
          border-radius: 1.5rem;
          box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05);
          padding: 2rem 1.4rem;
          > header {
            display: flex;
            align-items: center;
            gap: 1rem;
            > img {
              width: 4rem;
            }
            > span {
              font-size: 1.5rem;
            }
          }

          > main {
            > p {
              margin-bottom: 1rem;
              font-size: 1.65rem;
            }
            > span {
              font-size: 2.85rem;
              font-weight: 500;
              color: #572e8a;
            }
          }

          > footer {
            > p {
              font-size: 1.2rem;
              margin-bottom: 1rem;
            }
            > span {
              font-size: 2.25rem;
            }
          }
        }

        > table {
          width: fit-content;
          border-radius: 1rem;
          border: none;
          border-spacing: 0 1rem;
          max-width: 95.6rem;
          > thead {
            background-color: #8747d6;

            > tr {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2.4rem;
                background-color: #572e8a;
              }

              > th {
                padding: 2.8rem 3.5rem;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 500;
                text-align: left;

                font-size: 1.4rem;

                > img {
                  width: 6rem;
                }
                /* text-align: center; */
              }

              > th:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              > th:last-child {
                border-radius: 0rem 1rem 0rem 0rem;
              }
            }
          }

          > tbody {
            font-size: 1.5rem;
            font-weight: 500;
            > tr {
              box-shadow: 0rem 0.1rem 2.5rem rgba(0, 0, 0, 0.05);
              border-radius: 2rem;
              > td {
                width: 40rem;
                padding: 1.6rem 3.5rem;
                height: 6.47rem;
                font-size: 1.5rem;
                color: #707070;

                &:nth-last-child(1),
                &:nth-last-child(2) {
                  font-size: 2.2rem;
                  color: #914fe3;
                  font-family: ${({ theme }) => theme.fonts.firaSans},
                    sans-serif !important;
                }

                > div {
                  display: flex;
                  align-items: center;
                  gap: 3rem;
                  > img {
                    width: 6rem;
                  }

                  > span {
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  > section:nth-child(9) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0rem;

    > header {
      /* width: 100%; */

      > img {
        max-height: 31.1rem;
        object-fit: cover;
        border-radius: 1rem 1rem 0rem 0rem;
        width: 100%;
      }
    }

    > main {
      padding: 4rem 8rem;
      > div:nth-child(1) {
        margin-bottom: 5.93rem;
        > h1 {
          font-size: 2.1rem;
          font-weight: 500;
          margin-bottom: 2.1rem;
          color: #1a2341;
        }

        > p {
          margin-bottom: 6.5rem;
          font-size: 1.65rem;
          font-weight: 500;
          color: #707070;
        }

        > button {
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
          font-size: 1.5rem;
          padding: 1.4rem 3rem;
          border-radius: 1rem;
          color: ${({ theme }) => theme.colors.white};
          background-color: #1a2341;
          border: none;
          width: fit-content;
          cursor: pointer;
        }
      }

      > div:nth-child(2) {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        > h1 {
          font-size: 1.65rem;
          font-weight: 500;
          color: #b53661;
        }

        > table {
          width: fit-content;
          box-shadow: 0rem 0.1rem 2.5rem rgba(0, 0, 0, 0.05);
          border-radius: 1rem;
          border: none;
          > thead {
            background-color: #b53661;

            > tr {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2.4rem;
                background-color: #75233f;
              }
              > th {
                padding: 3.5rem 2rem;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 500;
                text-align: left;
              }

              > th:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              > th:nth-child(2) {
                border-radius: 0rem 1rem 0rem 0rem;
              }
            }
          }

          > tbody {
            font-size: 1.5rem;
            font-weight: 500;
            > tr {
              > td {
                width: 40rem;
                padding: 1.6rem 2rem;
                > a {
                  text-decoration: none;
                  font-weight: 600;
                }
              }
            }
            > tr:nth-child(odd) {
              background-color: ${({ theme }) => theme.colors.white};
              color: #b53661;
            }
            > tr:nth-child(even) {
              background-color: #fef8f9;
              color: ${({ theme }) => theme.colors.gray};
            }

            > tr:last-child {
              > td:nth-child(1) {
                border-radius: 0rem 0rem 0rem 1rem;
              }

              > td:nth-child(2) {
                border-radius: 0rem 0rem 1rem 0rem;
              }
            }
          }
        }
      }
    }

    > footer {
      padding: 4rem 8rem;

      > div {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 0.83rem;
        > img {
          width: 2.5rem;
        }

        > h1 {
          color: #1a2341;
          font-size: 1.88rem;
          font-weight: 500;
        }
      }

      > p {
        font-size: 1.5rem;
        font-weight: 500;
        color: #707070;
      }
    }
  }

  > section:nth-child(10) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 6rem;
    > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 10rem;

      > input {
        border: none;
        border-bottom: 0.1rem solid #707070;
        padding: 2rem;
        flex: 1;
        max-width: 40rem;

        &::placeholder {
          color: #9a9a9a;
          font-size: 500;
        }
      }
      > button {
        font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
        font-size: 1.5rem;
        padding: 1rem 3rem;
        border-radius: 1rem;
        color: ${({ theme }) => theme.colors.white};
        background-color: #1a2341;
        border: none;
        width: fit-content;
        cursor: pointer;
      }
    }
  }

  > section:nth-child(11) {
    display: flex;
    justify-content: center;
    /* height: 11.3rem; */
    flex-wrap: wrap;
    padding: 3rem;

    > ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      align-items: center;
      gap: 9rem;
      > li {
        display: flex;
        align-items: center;
        gap: 2rem;
        > span {
          font-size: 1.5rem;
          font-weight: 600;
        }
      }
    }
  }
`;

export const SectionTitle = styled.h1`
  font-size: 2.1rem;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.blue5};
`;

export const BannerTitle = styled.h1`
  font-weight: 600;
  font-size: 6.375rem;
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 1rem;

  > span {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const InfoDataWrapper = styled.div`
  > p {
    color: ${({ theme }) => theme.colors.white};
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

export const Links = styled.div`
  height: 100%;
  > ul {
    display: flex;
    gap: 4rem;
    list-style: none;
    > li {
      > a {
        font-size: 1.4rem;
        text-decoration: none;
        color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
