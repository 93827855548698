import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";
import image01 from "assets/images/plans/recursos/images/01.svg";

export default function FirstStep() {
  return (
    <S.Wrapper>
      <p>Olá gestor, olá gestora!</p>

      <p>Seja bem-vindo/a à Jornada de RED (Recursos Educacionais Digitais)!</p>

      <p>
        A proposta desta trilha é percorrer quatro etapas para selecionar e
        incorporar recursos educacionais digitais (RED) nas escolas.
      </p>
      <h1 id="r-e-1">1. ENTENDENDO RED</h1>

      <p>
        Nesta primeira etapa você vai entender o que são recursos educacionais
        digitais (RED) e como eles podem ser organizados.
      </p>

      <h2 id="r-e-1-s-1">1.1 O que é RED</h2>

      <p>
        <strong>
          Recursos educacionais digitais, ou RED, são conteúdos, ferramentas
          e/ou plataformas em formato digital para fins educacionais
          (pedagógicos e/ou administrativos), que facilitam, potencializam e
          apoiam as atividades de docentes, estudantes e gestores/as.
        </strong>
      </p>

      <h2 id="r-e-1-s-2">
        1.2 Como são organizados os recursos educacionais digitais
      </h2>

      <p>
        O universo de RED é grande e merece ser explorado, mas não se preocupe
        com o volume de informações! Criamos uma forma de organizar esses RED a
        partir de características comuns e, assim, ajudá-lo/a a delimitar o que
        realmente precisa para sua rede. Com isso os RED foram categorizados de
        acordo com as demandas educacionais, sendo elas:
      </p>

      <ul>
        <li>
          Conteúdo estruturado - recursos educacionais digitais que oferecem
          conteúdos curriculares;
        </li>
        <li>
          Gestão pedagógica - recursos educacionais digitais que objetivam
          apoiar processos de gestão pedagógica, sejam eles pertencentes ou não
          ao âmbito da sala de aula;
        </li>
        <li>
          Gestão administrativa - recursos educacionais digitais que visam
          facilitar processos de gestão administrativa da escola ou da rede;
        </li>
        <li>
          Autoria e colaboração - recursos educacionais digitais que favorecem
          processos de autoria e colaboração, seja entre estudantes, docentes ou
          gestores/as;
        </li>
        <li>
          Espaços de aprendizagem - recursos educacionais digitais que oferecem
          ambientes a serem utilizados para criar jornadas de aprendizagem
          online ou apoiar processos de aprendizagem presenciais ou híbridos.
        </li>
      </ul>
      <img src={image01} alt="" />

      <p>
        Para conhecer um pouco mais sobre cada grupo, exemplos e
        funcionalidades,  acesse {" "}
        <a href="https://reds.cieb.net.br/" target="_blank" rel="noreferrer">
          <i>Taxonomia de RED</i>
        </a>{" "}e descubra!
      </p>

      <Card
        heading="TAXONOMIA DE"
        title="RED"
        buttonText="LEIA MAIS"
        dimension="red"
        backgroundName="guiaDeEspecificacao"
        link="https://reds.cieb.net.br/"
      />
    </S.Wrapper>
  );
}
