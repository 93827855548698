import TableContainer from "@mui/material/TableContainer";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import PropTypes from "prop-types";
import * as S from "./styles";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { getStatus } from "utils/plans";

const STATUS = {
  in_progress: "EM ANDAMENTO",
  concluded: "CONCLÚIDO",
  not_started: "NÃO INICIADO",
};

function PlansTable({ columns, plans, institutionLoading }) {
  if (institutionLoading) {
    return (
      <S.Wrapper>
        <CircularProgress size={30} />
      </S.Wrapper>
    );
  }

  return (
    <S.Wrapper>
      <TableContainer>
        <S.StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {plans?.map((plan, index) => (
              <TableRow key={`row-${index}`}>
                <TableCell>
                  <Link to={`/planos/acompanhamento/${index + 1}`}>
                    <S.StyledAssessmentIcon />
                  </Link>
                </TableCell>
                <TableCell>{plan?.setting_plan?.main_goal}</TableCell>
                <TableCell>
                  {dayjs(plan?.setting_plan?.validity?.start).format(
                    "DD/MM/YYYY"
                  )}
                </TableCell>
                <TableCell>
                  {dayjs(plan?.setting_plan?.validity?.end).format(
                    "DD/MM/YYYY"
                  )}
                </TableCell>
                <TableCell>
                  {
                    STATUS[
                      getStatus(
                        plan?.setting_plan?.validity?.start,
                        plan?.setting_plan?.validity?.end
                      )
                    ]
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </S.StyledTable>
      </TableContainer>
    </S.Wrapper>
  );
}

PlansTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
};

export default PlansTable;
