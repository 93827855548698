import styled from "styled-components";

const COLORS = {
  vision: "yellow",
  competence: "pink",
  red: "blue",
  infra: "green",
};

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1.5rem;
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: column;
  align-items: center;

  section:nth-child(2) {
    display: flex;
    gap: 10rem;
  }
  section:nth-child(n + 2) {
    margin-bottom: 10rem;
  }
  section:nth-child(3) {
    margin-bottom: 1rem;
  }

  .speedometer {
    width: 28rem;
    align-self: center;
    margin-top: 2rem;
  }
`;

export const BoxDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  font-size: 1.6rem;
  color: ${({ theme }) => theme.colors.black};
  .first {
    text-align: center;
  }
  .description_area {
    > p {
      font-size: 1.6rem;
      margin-bottom: 1.8rem;
    }
    > ul {
      padding: 0 4rem;
      > li {
        font-size: 1.6rem;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.black};
        margin-bottom: 1.2rem;
        text-align: justify;
        > a {
          text-decoration: none;
        }
      }
    }
  }

  .box {
    margin-top: 4rem;
    border-radius: 0.6rem;
    padding: 2.5rem 3rem;
    color: ${({ theme }) => theme.colors.white};
    > p {
      > a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.black};
      }
    }
  }
  > p {
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.white};
  }
  > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.white};
  }
  .box-vision {
    background-color: ${({ theme }) => theme.colors.yellow3};
    color: ${({ theme }) => theme.colors.black};
  }
  .box-competence {
    background-color: ${({ theme }) => theme.colors.pink3};
  }
  .box-red {
    background-color: ${({ theme }) => theme.colors.blue3};
  }
  .box-infra {
    background-color: ${({ theme }) => theme.colors.green3};
  }
`;

export const Tabs = styled.div`
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 10%), 0 0.2rem 0.5rem rgb(0 0 0 / 10%);
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
`;

export const Tab = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;

  > button:nth-child(1) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background-color: ${({ theme, dimension, selectedTab }) =>
        selectedTab === 0 && theme.colors[COLORS[dimension]]};
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 1rem 1rem 0rem 0rem;
    }
  }

  > button:nth-child(2) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background-color: ${({ theme, dimension, selectedTab }) =>
        selectedTab === 1 && theme.colors[COLORS[dimension]]};
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 1rem 1rem 0rem 0rem;
    }
  }

  > button:nth-child(3) {
    position: relative;
    &::before {
      content: "";
      position: absolute;
      background-color: ${({ theme, dimension, selectedTab }) =>
        selectedTab === 2 && theme.colors[COLORS[dimension]]};
      width: 10px;
      height: 10px;
      top: 0;
      left: 0;
      width: 100%;
      border-radius: 1rem 1rem 0rem 0rem;
    }

    /* color: ${({ theme, dimension, selectedTab }) =>
      selectedTab === 2 && dimension !== "vision"
        ? theme.colors.white
        : theme.colors.black}; */
  }

  > button {
    flex: 1;
    padding: 3rem 2rem;
    border: none;
    border-radius: 1rem 1rem 0rem 0rem;
    font-size: 1.6rem;
    font-weight: 500;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.black};
    cursor: pointer;
    box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 0.05);
  }
`;

export const Content = styled.div`
  padding: 6rem 6rem;
  min-height: 44rem;
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  /* text-align: center; */
  width: 88%;
  > p {
    font-size: 1.7rem;
    > a {
      text-decoration: none;
      font-weight: 500;
      color: ${({ theme }) => theme.colors.black};
    }
  }
`;
