import styled, { css } from "styled-components";
import { container } from "global-styles/shared-styles";
import media from "styled-media-query";

import HomeIcon from "@mui/icons-material/Home";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import RoomIcon from "@mui/icons-material/Room";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
// import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LoginIcon from "@mui/icons-material/Login";

import AccountCircleIcon from "@mui/icons-material/AccountCircle";

import { ReactComponent as OpenEye } from "assets/images/menu/open-eye.svg";
import { ReactComponent as CloseEye } from "assets/images/menu/close-eye.svg";
import { ReactComponent as LogoCIEB2 } from "assets/images/menu/logo-cieb2.svg";
import { ReactComponent as LogoCIEB } from "assets/images/ge-in-numbers/cieb_logo.svg";

import { ReactComponent as vision } from "assets/images/menu-plans/vision.svg";
import { ReactComponent as competence } from "assets/images/menu-plans/competence.svg";
import { ReactComponent as red } from "assets/images/menu-plans/red.svg";
import { ReactComponent as infra } from "assets/images/menu-plans/infra.svg";

import { ReactComponent as School } from "assets/images/ge-in-numbers/escola.svg";

import { ReactComponent as autoavaliacaoON } from "assets/images/ge-in-numbers/menu-bottom/autoavaliacaoON.svg";
import { ReactComponent as diagnosticoON } from "assets/images/ge-in-numbers/menu-bottom/diagnosticoON.svg";
import { ReactComponent as filtroON } from "assets/images/ge-in-numbers/menu-bottom/filtroON.svg";
import { ReactComponent as guiaedutecON } from "assets/images/ge-in-numbers/menu-bottom/guiaedutecON.svg";

import { ReactComponent as autoavaliacaoOFF } from "assets/images/ge-in-numbers/menu-bottom/autoavaliacaoOFF.svg";
import { ReactComponent as diagnosticoOFF } from "assets/images/ge-in-numbers/menu-bottom/diagnosticoOFF.svg";
import { ReactComponent as filtroOFF } from "assets/images/ge-in-numbers/menu-bottom/filtroOFF.svg";
import { ReactComponent as guiaedutecOFF } from "assets/images/ge-in-numbers/menu-bottom/guiaedutecOFF.svg";

import { ReactComponent as parameters } from "assets/images/calculadora/029.svg";
import { ReactComponent as distribution } from "assets/images/calculadora/030.svg";
import { ReactComponent as feedback } from "assets/images/calculadora/031.svg";
import { ReactComponent as referenceValues } from "assets/images/calculadora/032.svg";

import {
  HomeIcon as HomeIconOutline,
  DatabaseIcon,
  CalendarIcon,
  DocumentReportIcon,
  ClipboardListIcon,
  AcademicCapIcon,
  BookOpenIcon,
  UserGroupIcon,
} from "@heroicons/react/outline";

export const wrapperModifiers = {
  itemActive1: css`
    color: ${({ state, theme }) => state?.active && theme.colors.black};
    font-weight: ${({ state }) => state?.active && 500};
  `,
  itemActive2: css`
    fill: ${({ state, theme }) => state?.active && theme.colors.black};
  `,
  mouseOver1: css`
    color: ${({ state, theme }) => state?.hover && theme.colors.black};
    background-color: ${({ state, theme }) => state?.hover && "#efefef"};
  `,
  mouseOver2: css`
    fill: ${({ state, theme }) => state?.hover && theme.colors.black};
    background-color: ${({ state, theme }) => state?.hover && "#efefef"};
  `,
};

export const StyledAutoavaliacaoOFFIcon = styled(autoavaliacaoOFF)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledDiagnosticoOFFIcon = styled(diagnosticoOFF)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledFiltroOFFIcon = styled(filtroOFF)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledGuiaedutecOFFIcon = styled(guiaedutecOFF)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledAutoavaliacaoONIcon = styled(autoavaliacaoON)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledDiagnosticoONIcon = styled(diagnosticoON)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledFiltroONIcon = styled(filtroON)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledGuiaedutecONIcon = styled(guiaedutecON)`
  width: 3.8rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledParametersIcon = styled(parameters)`
  width: 2.6rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledDistributionIcon = styled(distribution)`
  width: 2.6rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledFeedbackIcon = styled(feedback)`
  width: 2.6rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const StyledReferenceValuesIcon = styled(referenceValues)`
  width: 2.6rem;
  color: ${({ theme }) => theme.colors.black};
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;

  > div:nth-child(1) {
    width: 100%;
  }

  > div:nth-child(2) {
    width: 100%;
  }
`;

export const StyledLogoCIEB2 = styled(LogoCIEB2)`
  width: 22rem;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.textDarkGray};
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledLogoCIEB = styled(LogoCIEB)`
  width: 20rem;
  cursor: pointer;
  fill: ${({ theme }) => theme.colors.textDarkGray};
`;

export const StyledHomeIcon = styled(HomeIcon)`
  cursor: pointer;
  &&& {
    font-size: 2.8rem;
  }
  color: ${({ theme }) => theme.colors.textDarkGray};

  &:hover {
    opacity: 0.9;
  }
`;

export const StyledOpenIcon = styled(OpenEye)`
  cursor: pointer;
  width: 2.8rem;
  fill: ${({ theme }) => theme.colors.textDarkGray};
  &:hover {
    opacity: 0.9;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledCloseIcon = styled(CloseEye)`
  cursor: pointer;
  width: 2.8rem;
  fill: ${({ theme }) => theme.colors.textDarkGray};
  &:hover {
    opacity: 0.9;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledLoginIcon = styled(LoginIcon)`
  cursor: pointer;
  &&& {
    font-size: 2.8rem;
  }
  &:hover {
    opacity: 0.7;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledAccountCircleIcon = styled(AccountCircleIcon)`
  cursor: pointer;
  &&& {
    font-size: 2.8rem;
  }
  &:hover {
    opacity: 0.7;
  }
  ${media.lessThan("large")`
    display: none;
  `}
`;

export const StyledPhoneEnabledIcon = styled(PhoneEnabledIcon)``;

export const StyledRoomIcon = styled(RoomIcon)``;

export const StyledFmdGoodIcon = styled(FmdGoodIcon)`
  &&& {
    font-size: 3rem;
  }
`;

export const StyledEmailIcon = styled(EmailIcon)`
  &&& {
    font-size: 3rem;
  }
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
  &&& {
    font-size: 4rem;
  }
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  &&& {
    font-size: 4rem;
  }
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  &&& {
    font-size: 4rem;
  }
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  &&& {
    font-size: 4rem;
  }
`;

export const StyledYouTubeIcon = styled(YouTubeIcon)`
  &&& {
    font-size: 4rem;
  }
`;

const isFixed = css`
  position: fixed;
`;

const isStatic = css`
  position: static;
`;

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.colors.white};
  ${({ showMenu }) => (!showMenu ? isFixed : isStatic)}
  width: 100%;
  z-index: 26;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 0.05);

  > div:nth-child(1) {
    /* ${container} */
    padding-inline: 4rem;
    position: relative;
    z-index: 10;
    background-color: ${({ theme }) => theme.colors.white};
    display: flex;
    justify-content: space-between;
    font-size: 1.2rem;
    align-items: center;
    height: 7rem;
    color: ${({ theme }) => theme.colors.textDarkGray};

    ${media.lessThan("large")`
      padding: 0rem 5rem;
    `}

    ${media.lessThan("huge")`
      padding: 0rem 7rem;
    `}

    ${media.lessThan("medium")`
      padding: 0rem 2rem;
    `}

    a {
      color: ${({ theme }) => theme.colors.white};
    }

    > div {
      display: flex;
      align-items: center;
      > div {
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        ${media.lessThan("large")`
          display: none;
        `}
        > p {
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
    }

    > div:nth-child(1) {
      gap: 2rem;
      > h1 {
        color: ${({ theme }) => theme.colors.green};
        font-size: 2rem;
        font-weight: 500;
      }
    }

    > div:nth-child(2) {
      gap: 2.5rem;
      > div {
        gap: 1rem;
        display: flex;
        flex-direction: row;

        > span {
          font-size: 1.4rem;
          font-weight: 500;
        }
      }
    }
  }

  > div:nth-child(2) {
    display: ${({ showMenu }) => (showMenu ? "block" : "none")};
    background-color: ${({ theme }) => theme.colors.gray4};
    height: 7.5rem;
    z-index: 1000 !important;
  }

  @media print {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ isExpanded, hasNavigationBar }) =>
    isExpanded &&
    hasNavigationBar &&
    css`
      margin-left: 24rem;
    `}

  ${({ isExpanded, hasNavigationBar }) =>
    !isExpanded &&
    hasNavigationBar &&
    css`
      margin-left: 8rem;
    `}

  @media print {
    margin-left: 0rem;
  }
`;

export const Footer = styled.footer`
  display: flex;
  padding-right: 1.7rem;
  ${media.lessThan("medium")`
    padding-right: 0rem;
    padding-bottom: 5rem;
  `}
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.gray3};
  width: 100%;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;

  ${({ isExpanded, hasNavigationBar }) =>
    isExpanded &&
    hasNavigationBar &&
    css`
      margin-left: 24rem;
    `}

  ${({ isExpanded, hasNavigationBar }) =>
    !isExpanded &&
    hasNavigationBar &&
    css`
      margin-left: 8rem;
    `}

  > div:nth-child(1) {
    padding: 2rem;
    text-align: center;
    background-color: #16837f;
    cursor: pointer;
    width: 100%;

    &:hover {
      transition: 0.2s ease-in-out;
      background-color: #45ae63;
    }

    > a {
      text-decoration: none;
      flex: 1;
      width: 100%;
      height: 100%;
      font-size: 1.8rem;
      color: ${({ theme }) => theme.colors.white};
      font-weight: 500;
    }
  }

  > div:nth-child(2) {
    display: grid;
    place-items: center;
    width: 100%;
    grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
    background-color: ${({ theme }) => theme.colors.white};
    padding: 10rem 17rem 10rem 10rem;
    color: ${({ theme }) => theme.colors.textDarkGray};
    font-size: 1.5rem;
    flex-wrap: wrap;
    gap: 5rem;
    width: 100%;

    ${media.lessThan("medium")`
      grid-template-columns: 1fr;
      padding: 10rem 5rem 10rem 5rem;
    `}

    > a {
      color: ${({ theme }) => theme.colors.textDarkGray};
      text-decoration: none;
    }

    > div:nth-child(1) {
      display: flex;
      align-self: flex-start;
      justify-content: flex-start;
      width: 100%;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      justify-content: center;
      width: fit-content;
      gap: 2rem;
      width: 100%;

      ${media.lessThan("medium")`
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}

      > div {
        display: flex;
        gap: 1rem;
        align-items: center;

        > p {
          font-weight: 500;

          > a {
            text-decoration: none;
            color: ${({ theme }) => theme.colors.textDarkGray};
          }
        }
      }
    }

    > div:nth-child(3) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-self: flex-start;
      gap: 4rem;
      width: 100%;

      > div {
        display: flex;
        gap: 3rem;
        flex-direction: column;
        > div:nth-child(1) {
          display: flex;
          align-items: center;
          gap: 1rem;

          > h1 {
            font-weight: 500;
            font-size: 1.6rem;
            color: #11817c;
          }
        }

        > div:nth-child(2) {
          > p {
            font-size: 1.6rem;
            margin-bottom: 1rem;
          }
        }
      }

      ${media.lessThan("huge")`
        flex: 1;
      `}
    }

    > div:nth-child(4) {
      display: flex;
      flex-direction: column;
      align-self: flex-start;
      width: 100%;
      > form {
        > h1 {
          font-weight: 500;
          font-size: 2rem;
        }

        > input:nth-child(2),
        > input:nth-child(3) {
          padding: 1.5rem 1.5rem;
          border-radius: 1rem;
          border: 0.1rem solid rgba(0, 0, 0, 0.1);
          box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 0.05),
            0 0.1rem 0.2rem 0 rgb(0 0 0 / 0.05);
        }
        > input:nth-child(4) {
          margin-top: 2rem;
          padding: 2rem 3rem;
          width: fit-content;
          border-radius: 1rem;
          background-color: #141d3c;
          color: ${({ theme }) => theme.colors.white};
          border: none;
          font-size: 1.5rem;
          cursor: pointer;

          &:hover {
            opacity: 0.9;
          }
        }
        display: flex;
        flex-direction: column;
        gap: 2rem;
      }
    }

    > div:nth-child(5) {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-self: flex-start;
      gap: 3rem;
      > h1 {
        color: #1d7e7a;
        font-weight: 500;
        font-size: 1.6rem;
        padding-bottom: 0.8rem;
        border-bottom: 0.5rem solid #1d7e7a;
      }

      > div {
        display: flex;
        flex-direction: column;
        gap: 1.8rem;
        > h2 {
          color: #1d7e7a;
          font-weight: 500;
          font-size: 1.6rem;
        }

        > p {
          font-weight: 500;
        }
      }
    }
  }

  > div:nth-child(3) {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 6rem 2rem;
    text-align: center;

    > h2 {
      font-size: 1.8rem;
      font-weight: 600;
    }

    > p {
      width: 90%;
      line-height: 1.5;
    }
  }

  @media print {
    display: none;
  }
`;

export const StyledSchoolIcon = styled(School)`
  .cls-3 {
    fill: ${({ theme }) => theme.colors.white};
  }

  .cls-1 {
    fill: ${({ theme }) => theme.colors.black};
  }
  width: 2.6rem;
  height: 2.6rem;
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledHomeIconOutline = styled(HomeIconOutline)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;
export const StyledClipboardListIcon = styled(ClipboardListIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledDataBaseIcon = styled(DatabaseIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledCalendarIcon = styled(CalendarIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledAcademicCapIcon = styled(AcademicCapIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledBookOpenIcon = styled(BookOpenIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledUserGroupIcon = styled(UserGroupIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledDocumentReportIcon = styled(DocumentReportIcon)`
  width: 2.6rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.black};
  ${wrapperModifiers.itemActive1};
  ${wrapperModifiers.mouseOver1};
`;

export const StyledVisionIcon = styled(vision)`
  width: 2.6rem;
  .menu-plans-vision {
    fill: ${({ theme }) => theme.colors.black};
    ${wrapperModifiers.itemActive2};
    ${wrapperModifiers.mouseOver2};
  }
`;

export const StyledCompetenceIcon = styled(competence)`
  width: 2.6rem;
  .menu-plans-competence {
    fill: ${({ theme }) => theme.colors.black};
    ${wrapperModifiers.itemActive2};
    ${wrapperModifiers.mouseOver2};
  }
`;
export const StyledREDIcon = styled(red)`
  width: 1.95rem;
  margin-right: 0.5rem;
  color: ${({ theme }) => theme.colors.black};
  .menu-plans-red {
    fill: ${({ theme }) => theme.colors.black};
    ${wrapperModifiers.itemActive2};
    ${wrapperModifiers.mouseOver2};
  }
`;
export const StyledInfraIcon = styled(infra)`
  width: 2.5rem;
  color: ${({ theme }) => theme.colors.black};
  .menu-plans-infra {
    fill: ${({ theme }) => theme.colors.black};
    ${wrapperModifiers.itemActive2};
    ${wrapperModifiers.mouseOver2};
  }
`;
