import {
  removeStorageAuthenticityToken,
  removeStorageInstitutionId,
  removeStorageUserId,
  removeStorageUserName,
  removeStorageSchoolId,
  removeStorageProfile,
} from "./local-storage";

export const logout = () => {
  removeStorageAuthenticityToken();
  removeStorageInstitutionId();
  removeStorageUserId();
  removeStorageUserName();
  removeStorageSchoolId();
  removeStorageProfile();
};
