import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "shared-components/Layout";
import { QUESTIONS } from "./data";
import PriorityCard from "./PriorityCard";
import * as S from "./styles";
import image45 from "assets/images/red-demands/045.svg";
import image46 from "assets/images/red-demands/046.svg";
import image47 from "assets/images/red-demands/047.svg";

import { useNavigate } from "react-router-dom";
import { useAnswersMutation, useGetAnswersQuery } from "services/red-demands";
import { useSurveyListQuery } from "services/common";
import { getSchoolSurveySchedules } from "utils/surveys";
import Modal from "shared-components/Modal";
import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { useURLQuery } from "utils/routes";
import { isAdminCity, isAdminState, isPrincipal } from "utils/users";

const DEMANDS = {
  CONTEUDO_ESTRUTURADO: "CONTEÚDO ESTRUTURADO",
  AUTORIA_E_COLABORACAO: "AUTORIA E COLABORAÇÃO",
  ESPACOS_DE_APRENDIZAGEM: "ESPAÇOS DE APRENDIZAGEM",
  GESTAO_PEDAGOGICA: "GESTÃO PEDAGÓGICA",
  GESTAO_ADMINISTRATIVA: "GESTÃO ADMINISTRATIVA",
};

export default function Questions() {
  const navigate = useNavigate();
  const [surveyScheduleId, setSurveyScheduleId] = useState("");
  const [demandSelected, setDemandSelected] = useState("");
  const { data: surveyList, isSuccess: surveyListSuccess } =
    useSurveyListQuery();

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);

  const query = useURLQuery();

  useEffect(() => {
    if (isAdminCity() || isAdminState()) {
      const demandSelected = query.get("demand");
      setDemandSelected(demandSelected);
    }
  }, [query]);

  useEffect(() => {
    if (surveyListSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      setSurveyScheduleId(schoolSchedules[0]?.id?.$oid);
    }
  }, [surveyList, surveyListSuccess]);

  const { data: getAnswers, isSuccess: getAnswersSuccess } =
    useGetAnswersQuery(surveyScheduleId);

  const answersMutation = useAnswersMutation();

  let [priority, setPriority] = useState([]);
  const [priorityOrder, setPriorityOrder] = useState([]);

  const selectPriority = (itemIndex, priorityValue) => {
    const priorityAux = [...priority];
    priorityValue = priorityValue - 1;
    priorityAux[itemIndex][priorityValue] = "enabled";

    priorityAux[itemIndex].forEach((_, index) => {
      if (index !== priorityValue) {
        priorityAux[itemIndex][index] = "disabled";
      }
    });

    priorityAux.forEach((item, index) => {
      if (index !== itemIndex) {
        item[priorityValue] = "disabled";
      }
    });

    priority[0].forEach((_, index) => {
      let flag = true;
      priorityAux.forEach((item) => {
        if (item[index] === "enabled") {
          flag = false;
        }
      });

      priorityAux.forEach((item) => {
        if (flag) {
          item[index] = "initial";
        }
      });
    });

    priorityAux.forEach((item, index1) => {
      const hasEnabled = item.some((v) => v === "enabled");
      if (hasEnabled) {
        const indexOfEnabled = item.indexOf("enabled");
        priorityAux[index1].forEach((_, index2) => {
          if (index2 !== indexOfEnabled) {
            priorityAux[index1][index2] = "disabled";
          }
        });
      }
    });

    setPriority([...priorityAux]);
  };

  const getPriorityOrder = useCallback(() => {
    const order = priority.map((item, index) => [
      index,
      item.indexOf("enabled"),
    ]);
    order.sort((a, b) => a[1] - b[1]);
    setPriorityOrder(order);
  }, [priority]);

  useEffect(() => {
    if (getAnswersSuccess || demandSelected) {
      const length = QUESTIONS[getAnswers?.name || demandSelected]?.length;
      const priorityItemAux = new Array(length).fill("initial");
      const priority = new Array(length)
        .fill()
        .map((_, index) => [...priorityItemAux]);
      setPriority(priority);
    }
  }, [getAnswersSuccess, getAnswers, demandSelected]);

  useEffect(() => {
    getPriorityOrder();
  }, [priority, getPriorityOrder]);

  const onSubmit = () => {
    const priorities = {};
    const demand = QUESTIONS[getAnswers.name];
    if (priorityOrder.some((item) => item[1] === -1)) {
      return NotificationManager.error(
        "Ordene os itens pelo nível de prioridade"
      );
    }
    setIsOpen1(false);
    priorityOrder.forEach(([itemIndex, priorityIndex]) => {
      if (demand[itemIndex]?.id) {
        priorities[demand[itemIndex]?.id] = priorityIndex + 1;
      }
    });
    const red_demands = { ...getAnswers, priorities, status: "Completed" };
    answersMutation.mutate({
      red_demands,
      survey_schedule_id: surveyScheduleId,
    });
  };

  useEffect(() => {
    if (answersMutation.isSuccess) {
      setIsOpen2(true);
    }
  }, [answersMutation.isSuccess]);

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Demandas por RED - Questões" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>

        <section>
          <span>Levantamento de demanda da escola</span>
          <h1>{DEMANDS[getAnswers?.name || demandSelected]}</h1>
        </section>

        <section>
          <h2>
            Pensando na área de {DEMANDS[getAnswers?.name]?.toLowerCase()}.
            Abaixo você encontra alguns tipos de tecnologia na educação. Você
            deverá organizá-las em ordem de prioridade de como você gostaria de
            ver sua escola no futuro, sendo que o número 1 é o mais prioritário
            e o {QUESTIONS[getAnswers?.name || demandSelected]?.length} menos
            prioritário.
          </h2>
          <p>
            Conforme você selecionar os números, as tecnologias aparecerão
            abaixo, de acordo com a ordem de priorização que você estipular.
          </p>
        </section>

        <section>
          {(getAnswersSuccess || !!demandSelected) &&
            priority?.length !== 0 &&
            QUESTIONS[getAnswers?.name || demandSelected]?.map(
              ({ name, description, id }, itemIndex) => (
                <PriorityCard
                  key={`priority-card-${itemIndex}-key`}
                  id={id}
                  itemIndex={itemIndex}
                  name={name}
                  description={description}
                  selectPriority={selectPriority}
                  prioritiesAmount={
                    QUESTIONS[getAnswers?.name || demandSelected]?.length
                  }
                  priority={priority}
                />
              )
            )}
        </section>
        <section>
          <div>
            <img src={image45} alt="Priority" />
            <p>mais prioritário</p>
            <p>menos prioritário</p>
          </div>

          <div>
            {(getAnswersSuccess || !!demandSelected) &&
              QUESTIONS[getAnswers?.name || demandSelected]?.map((_, index) => (
                <div key={`order-${index}`}>
                  <div>{index + 1}</div>
                  <div>
                    <p>
                      {priorityOrder.find(
                        (priority) => priority[1] === index
                      ) &&
                        QUESTIONS[getAnswers?.name || demandSelected][
                          priorityOrder.find(
                            (priority) => priority[1] === index
                          )[0]
                        ]?.name}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        </section>
        <section>
          <button
            onClick={() => navigate("/demanda-de-red/demandas-da-escola")}
          >
            {" < "} anterior
          </button>
          {isPrincipal() && (
            <button onClick={() => setIsOpen1(true)}>
              enviar respostas {" > "}
            </button>
          )}
        </section>

        <Modal isOpen={isOpen1} closeModal={() => setIsOpen1(false)}>
          <S.SubmitFormModalConfirmation>
            <img src={image46} alt="" />
            <h2>TEM CERTEZA QUE DESEJA ENVIAR?</h2>
            <p>Depois de enviado você não poderá editar suas respostas.</p>
            <div>
              <button onClick={() => setIsOpen1(false)}>
                {" < "} RETORNAR
              </button>
              <button onClick={onSubmit}>CONTINUAR {" > "}</button>
            </div>
          </S.SubmitFormModalConfirmation>
        </Modal>

        <Modal isOpen={isOpen2}>
          <S.SubmitFormModalSuccess>
            <img src={image47} alt="" />
            <h2>QUESTIONÁRIO ENVIADO COM SUCESSO</h2>

            <button
              onClick={() => {
                setIsOpen2(false);
                navigate("/demanda-de-red/resultados-da-escola");
              }}
            >
              ACESSE O RESULTADO
            </button>
          </S.SubmitFormModalSuccess>
        </Modal>
      </S.Wrapper>
    </Layout>
  );
}
