import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useNavigate } from "react-router-dom";
import { useSurveyListQuery } from "services/common";
import { useAnswersMutation, useGetAnswersQuery } from "services/red-demands";
import Layout from "shared-components/Layout";
import { getSchoolSurveySchedules } from "utils/surveys";
import DemandsCard from "./DemandsCard";
import * as S from "./styles";

import "react-notifications/lib/notifications.css";
import { NotificationManager } from "react-notifications";
import { isAdminCity, isAdminState } from "utils/users";

export default function SchoolDemands() {
  const navigate = useNavigate();
  const [demandSelected, setDemandSelected] = useState("");
  const [surveyScheduleId, setSurveyScheduleId] = useState("");

  const { data: surveyList, isSuccess: surveyListSuccess } =
    useSurveyListQuery();

  const { data: getAnswers } = useGetAnswersQuery(surveyScheduleId);

  useEffect(() => {
    if (surveyListSuccess) {
      const schoolSchedules = getSchoolSurveySchedules(surveyList?.surveys);
      setSurveyScheduleId(schoolSchedules[0]?.id?.$oid);
    }
  }, [surveyList, surveyListSuccess]);

  const answersMutation = useAnswersMutation();

  const submitDemand = () => {
    if (!demandSelected) {
      return NotificationManager.error(
        "Selecione uma situação que você mais gostaria que seja usada na sua escola."
      );
    }
    if (isAdminCity() || isAdminState()) {
      navigate(`/demanda-de-red/questoes?demand=${demandSelected}`);
    }

    answersMutation.mutate({
      red_demands: { ...getAnswers, name: demandSelected, status: "Started" },
      survey_schedule_id: surveyScheduleId,
    });
  };

  useEffect(() => {
    if (answersMutation.isSuccess) {
      navigate("/demanda-de-red/questoes");
    }
  }, [answersMutation.isSuccess, navigate]);

  return (
    <Layout hasNavigationBar name="redDemands">
      <Helmet title="Guia EduTec | Planos - Início" />
      <S.Wrapper>
        <S.BannerWrapper>
          <S.Banner />
        </S.BannerWrapper>
        <section>
          <div>
            <h1>Levantamento de demanda escola</h1>
          </div>
        </section>
        <section>
          <div>
            <h2>
              Abaixo você encontra algumas situações de uso da tecnologia na
              educação.
              <br /> Você deverá selecionar a situação que você mais gostaria
              que seja usada na sua escola.
            </h2>
            <ul>
              <li>
                <DemandsCard
                  index={1}
                  title="CONTEÚDO ESTRUTURADO"
                  name="CONTEUDO_ESTRUTURADO"
                  setDemandSelected={setDemandSelected}
                  isActive={"CONTEUDO_ESTRUTURADO" === demandSelected}
                >
                  Docentes e estudantes utilizam recursos, como vídeos, imagens,
                  textos, áudios, simulações, jogos educativos ou cursos online,
                  com o objetivo de desenvolver habilidades e conteúdos
                  previstos no currículo. Esses recursos são encaminhados
                  diretamente aos/às estudantes ou disponibilizados em
                  repositórios digitais e/ou plataformas de oferta de conteúdo
                  online, como banco de questões ou biblioteca de livros.
                </DemandsCard>
              </li>
              <li>
                <DemandsCard
                  index={2}
                  title="AUTORIA E COLABORAÇÃO"
                  name="AUTORIA_E_COLABORACAO"
                  setDemandSelected={setDemandSelected}
                  isActive={"AUTORIA_E_COLABORACAO" === demandSelected}
                >
                  Docentes e estudantes utilizam, de forma individual ou
                  colaborativa, um ou mais softwares ou aplicativos para criar
                  apresentações de slides, desenvolver projetos em vídeo,
                  escrever e distribuir livros digitais e/ou realizar simulações
                  em 3D. Nos momentos de reforço, docentes e estudantes
                  interagem online por meio de ferramentas de vídeo, áudio e/ou
                  texto.
                </DemandsCard>
              </li>
              <li>
                <DemandsCard
                  index={3}
                  title="ESPAÇOS DE APRENDIZAGEM"
                  name="ESPACOS_DE_APRENDIZAGEM"
                  setDemandSelected={setDemandSelected}
                  isActive={"ESPACOS_DE_APRENDIZAGEM" === demandSelected}
                >
                  Docentes e estudantes utilizam ferramentas ou plataformas que
                  disponibilizam trilhas de conteúdos (personalizadas ou não),
                  materiais e/ou roteiros de estudo (atividades). Os/as docentes
                  utilizam ainda recursos digitais para criar planos de aula,
                  apresentar conteúdos, receber atividades realizadas pelos/as
                  estudantes e criar experiências de aprendizagem presenciais,
                  remotas ou híbridas com uso de tecnologias digitais.
                </DemandsCard>
              </li>
            </ul>

            <ul>
              <li>
                <DemandsCard
                  index={4}
                  title="GESTÃO PEDAGÓGICA"
                  name="GESTAO_PEDAGOGICA"
                  setDemandSelected={setDemandSelected}
                  isActive={"GESTAO_PEDAGOGICA" === demandSelected}
                >
                  Coordenador/a pedagógico/a, docentes e estudantes utilizam uma
                  ou mais ferramentas para gerenciar a notas dos/das alunos/as
                  se comunicar com a comunidade escolar, acompanhar a execução
                  do currículo da rede ao longo do ano letivo, do planejamento
                  pedagógico docente e/ou da avaliação da aprendizagem ou ainda
                  para monitorar o uso pedagógico de tablets ou computadores
                  pelos estudantes.
                </DemandsCard>
              </li>

              <li>
                <DemandsCard
                  index={5}
                  title="GESTÃO ADMINISTRATIVA"
                  name="GESTAO_ADMINISTRATIVA"
                  setDemandSelected={setDemandSelected}
                  isActive={"GESTAO_ADMINISTRATIVA" === demandSelected}
                >
                  Gestor/a escolar e profissionais do setor administrativo
                  utilizam um ou mais sistemas para gerenciar matrículas de
                  estudantes, grade horária, alimentação escolar, contratos com
                  fornecedores, verbas disponíveis e fluxo de caixa, vida
                  funcional dos profissionais da escola, entre outros processos.
                </DemandsCard>
              </li>
            </ul>
          </div>
        </section>

        <section>
          <button
            onClick={() => {
              navigate("/demanda-de-red/diagnostico-da-escola");
            }}
          >
            {" < "} anterior
          </button>
          <button onClick={submitDemand}>próxima {" > "}</button>
        </section>
      </S.Wrapper>
    </Layout>
  );
}
