import styled from "styled-components";

export const Wrapper = styled.section``;

export const Curriculum = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;

  > div {
    background-color: ${({ theme }) => theme.colors.yellow};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 4.5rem 2.1rem;

    > p {
      color: ${({ theme }) => theme.colors.black};
      font-size: 1.3rem !important;
    }

    > a {
      text-decoration: none;
      background-color: #615310;
      color: ${({ theme }) => theme.colors.white};
      border: none;
      padding: 1.1rem 2.5rem;
      border-radius: 1.5rem;
      font-size: 1.1rem;
      cursor: pointer;
    }
  }
`;
