import conceito from "assets/images/menu-etapas/conceito.svg";
import diagnostico from "assets/images/menu-etapas/diagnostico.svg";
import planejamento from "assets/images/menu-etapas/planejamento.svg";
import monitoramento from "assets/images/menu-etapas/monitoramento.svg";

const MENU_COMPETENCE_STEPS = [
  {
    key: "concept",
    title: "CONCEITO",
    image: conceito,
    alt: "conceito",
    stepName: "CONCEITOS EM COMPETÊNCIAS DIGITAIS",
    items: [
      {
        content: "Matriz de competências Digitais para Docentes",
        background: true,
        link: "https://cieb.net.br/cieb-notas-tecnicas-15-autoavaliacao-de-competencias-digitais-de-professores/",
      },
      {
        content: "Para que desenvolver competências digitais",
        background: false,
      },
    ],
    visible: "",
  },
  {
    key: "diagnosis",
    title: "DIAGNÓSTICO",
    image: diagnostico,
    alt: "diagnóstico",
    stepName: "DIAGNÓSTICO DO NÍVEL DE COMPETÊNCIAS DIGITAIS DOS/AS DOCENTES",
    items: [
      {
        content: "Autoavaliação de competências digitais docente",
        background: true,
        link: "https://guiaedutec.com.br/educador",
      },
    ],
  },
  {
    key: "planning",
    title: "PLANEJAMENTO",
    image: planejamento,
    alt: "planejamento",
    stepName:
      "PLANEJAMENTO DE FORMAÇÕES VOLTADAS PARA O DESENVOLVIMENTO DE COMPETÊNCIAS DIGITAIS DOCENTES",
    items: [
      {
        content: "Como elaborar planos de formação",
        background: true,
        to: "/planos",
      },
    ],
  },
  {
    key: "monitoring",
    title: "MONITORAMENTO",
    image: monitoramento,
    alt: "monitoramento",
    stepName: "ACOMPANHAMENTO",
    items: [],
  },
];

export default MENU_COMPETENCE_STEPS;
