import * as S from "./styles";
import { OrbitalSVGRed } from "../../OrbitalsSVG";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function OrbitalRed({ id, large }) {
  const navigate = useNavigate();

  return (
    <S.Wrapper id={id} large={large}>
      <OrbitalSVGRed />
      <p>
        O CAMINHO ATÉ OS/AS ESTUDANTES: FASE DE IMPLEMENTAÇÃO E ACOMPANHAMENTO
      </p>
      <p>APRENDA A FAZER CURADORIA E AQUISIÇÃO DA RED</p>
      <p>ENTENDA AS DEMANDAS DE SUA REDE E QUAIS RECURSOS SÃO MAIS ADEQUADOS</p>
      <p>ENTENDENDO RED</p>
      <p onClick={() => navigate("/jornada/red")}>
        COM QUAIS RECURSOS DIGITAIS?
      </p>
    </S.Wrapper>
  );
}

OrbitalRed.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
};

export default OrbitalRed;
