import React, { Component } from "react";
import * as S from "./styles";

import axios from "axios";
import SearchIcon from "@mui/icons-material/Search";

import { getStorageAuthenticityToken } from "utils/local-storage.js";
import Layout from "shared-components/Layout";
import { CircularProgress } from "@mui/material";

const STAGES = [
  { value: "", label: "Selecione uma etapa" },
  { value: "elementary_1", label: "Fundamental 1" },
  { value: "elementary_2", label: "Fundamental 2" },
  { value: "highschool", label: "Ensino Médio" },
  { value: "technical", label: "Ensino Técnico" },
  { value: "adult", label: "EJA" },
];

const LEVELS = ["Emergente", "Básico", "Intermediário", "Avançado"];

const CONF = process.env.REACT_APP_BASE_URL;

class InfraCalculatorDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      surveySchool: {},
      surveyScheduleID: {},
      dashboard: {},
      equip_data_amount: {},
      equip_data_price: {},
      schools_ppis_data_amount: {},
      data: [],
      localData: [],
      level: 0,
      isLoading: true,
      stage: { value: "", label: "Selecione uma etapa" },
      infrastructure_data: [0, 0, 0, 0],
      survey_id: "",
      schedule_id: "",
      total_investiment: "",
      cicle: {},
      cicles: [],
      error: false,
    };
    this.fetchData = this.fetchData.bind(this);
    this.getSurveysList = this.getSurveysList.bind(this);
    this.getRouteParams = this.getRouteParams.bind(this);
    this.handleTextFilter = this.handleTextFilter.bind(this);
  }

  getRouteParams() {}

  handleTextFilter(event) {
    console.log(event.target.value);
    const value = event.target.value.toLowerCase();

    if (value === "") {
      this.setState({ localData: this.state.data });
    } else {
      const schoolsFiltered = this.state.data.filter(
        (school) =>
          school.inep_code.toLowerCase().includes(value) ||
          school.school_name.toLowerCase().includes(value) ||
          school.city_name.toLowerCase().includes(value)
      );
      this.setState({ localData: schoolsFiltered });
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(window.location.search);
    const level = params.get("level");
    const stage = params.get("stage");
    const schedule_id = params.get("schedule_id");
    if (level) {
      this.setState(
        {
          level,
          stage: STAGES.find((s) => s.value === stage),
          schedule_id,
        },
        () => {
          this.getSurveysList();
        }
      );
    } else {
      this.getSurveysList();
    }
  }

  async getSurveysList() {
    try {
      const params = { access_token: getStorageAuthenticityToken() };
      const response = await axios.get(CONF + "/api/v1/survey/surveys_list", {
        params,
      });
      const { surveys } = response.data;
      const { schedule_id } = this.state;
      const filteredSurvey = surveys.find((survey) => survey.type === "school");
      const survey_id = filteredSurvey.id;
      let cicles = filteredSurvey.schedule.filter(
        (cicle) => cicle.name >= 2021
      );
      cicles = cicles.map((cicle) => ({
        value: cicle.id.$oid,
        label: cicle.name,
        survey_id: cicle.survey_id.$oid,
      }));

      let cicle = cicles[0];
      if (schedule_id) {
        cicle = cicles.find((cicle) => cicle.value === schedule_id);
      }
      this.setState({ survey_id, cicles, cicle }, () => {
        this.fetchData();
      });
    } catch (error) {
      console.log(error.message);
    }
  }

  currency = (val) => {
    return val
      ? val.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      : "0";
  };

  async fetchData() {
    this.setState({
      isLoading: true,
      error: false,
    });

    const { level, stage, schedule_id } = this.state;
    const params = {
      survey_schedule_id: schedule_id,
      access_token: getStorageAuthenticityToken(),
    };

    if (stage !== "") {
      params["stage"] = stage;
    }

    try {
      const response = await axios.get(CONF + "/api/v1/calc_infra_dashboard", {
        params,
      });

      const distribution = response.data.data[level - 1].distribution;

      this.setState({
        isLoading: false,
        data: distribution,
        localData: distribution,
      });
    } catch (error) {
      this.setState({
        error: true,
      });
    }
  }

  render() {
    const { isLoading, localData, data, error, stage, schedule_id } =
      this.state;
    return (
      <Layout hasNavigationBar name="infraCalculator">
        <S.Wrapper>
          <header />
          <div className="content">
            {isLoading ? (
              error ? (
                <S.ErrorMessage>
                  <S.ReportProblem />
                  <p>Um erro inesperado aconteceu !</p>
                  <p>
                    Caso o erro continue entre em contato com o email:{" "}
                    <a
                      href="mailto:guiaedutec@cieb.net.br"
                      target="_blank"
                      rel="noreferrer"
                    >
                      guiaedutec@cieb.net.br
                    </a>
                  </p>
                </S.ErrorMessage>
              ) : (
                <article className="loading">
                  <CircularProgress size={40} />
                </article>
              )
            ) : (
              <article>
                {data && data.length !== 0 ? (
                  <div>
                    <div>
                      <h1>Baixar dados de distribuição</h1>
                      <p>
                        Faça o download de uma planilha com os dados da
                        distribuição dos equipamentos.
                      </p>
                      <a
                        download
                        href={
                          CONF +
                          `/api/v1/calc_infra_export_distribution_data?access_token=${getStorageAuthenticityToken()}&stage=${
                            stage || ""
                          }&survey_schedule_id=${schedule_id}&level=${
                            this.state.level
                          }`
                        }
                      >
                        Baixar
                      </a>
                    </div>
                    <div>
                      <S.SearchInput>
                        <input
                          type="text"
                          onChange={this.handleTextFilter}
                          placeholder="Pesquise pelo INEP, nome da escola ou município"
                        />
                        <SearchIcon fontSize="inherit" />
                      </S.SearchInput>
                    </div>
                    <div className="columns">
                      <div className="bar_title">
                        <h1>
                          QUANTIDADE DE EQUIPAMENTO QUE CADA ESCOLA DEVE RECEBER
                        </h1>
                      </div>
                    </div>
                    <div className="columns">
                      {isLoading ? (
                        <div className={"loading"}>Loading</div>
                      ) : (
                        <div className="table">
                          <table>
                            <thead>
                              <tr>
                                <th>INEP</th>
                                <th className="school_column">ESCOLA</th>
                                <th>ESTADO</th>
                                <th>MUNICÍPIO</th>
                                <th>REDE</th>
                                <th>NÍVEL ATUAL</th>
                                <th>FIREWALL</th>
                                <th>Switch Layer 3</th>
                                <th>Rack 6U</th>
                                <th>Nobreak</th>
                                <th>Wifi (acess point)</th>
                                <th>Comput. Admin</th>
                                <th>Impres. Multifunc.</th>
                                <th>Computador Professores</th>
                                <th>Kit Laptop (computador estudantes)</th>
                                <th>Carrinho de carregamento</th>
                                <th>Projetor Multimidia</th>
                              </tr>
                            </thead>
                            <tbody>
                              {localData &&
                                localData.map((school) => (
                                  <tr key={school.inep_code}>
                                    <td>{school.inep_code}</td>
                                    <td>{school.school_name}</td>
                                    <td>{school.state_name}</td>
                                    <td>{school.city_name}</td>
                                    <td>{school.type}</td>
                                    <td>{LEVELS[school.level - 1]}</td>
                                    <td>
                                      {school.infrastructure.firewall.amount}
                                    </td>
                                    <td>
                                      {school.infrastructure.switch.amount}
                                    </td>
                                    <td>{school.infrastructure.rack.amount}</td>
                                    <td>
                                      {school.infrastructure.nobreak.amount}
                                    </td>
                                    <td>
                                      {
                                        school.infrastructure.access_point
                                          .amount
                                      }
                                    </td>
                                    <td>
                                      {school.infrastructure.comp_admins.amount}
                                    </td>
                                    <td>
                                      {school.infrastructure.printers.amount}
                                    </td>
                                    <td>
                                      {
                                        school.infrastructure.comp_teachers
                                          .amount
                                      }
                                    </td>
                                    <td>
                                      {
                                        school.infrastructure.comp_students
                                          .amount
                                      }
                                    </td>
                                    <td>
                                      {school.infrastructure.charger.amount}
                                    </td>
                                    <td>
                                      {school.infrastructure.projector.amount}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="no_schools">
                    <p>
                      Nenhuma escola selecionada. Você pode escolher uma das
                      ações abaixo:
                    </p>
                    <ul>
                      <li>
                        Volte a página{" "}
                        <a href="/calculadora-infra/detalhes">Dados da rede</a>{" "}
                        e verifiquei se possui pelo menos uma escola que tenha
                        respondido ao inventário de infraestrutura.
                      </li>
                      <li>
                        Volte à página de{" "}
                        <a href="/calculadora-infra/dashboard">Investimento</a>{" "}
                        e selecione outras opções de filtros de investimento.
                      </li>
                    </ul>
                  </div>
                )}
              </article>
            )}
            <div className="start">
              <button
                onClick={() =>
                  (window.location =
                    "/calculadora-de-infraestrutura/dados-da-rede")
                }
              >
                NOVA SIMULAÇÃO
              </button>
            </div>
          </div>
        </S.Wrapper>
      </Layout>
    );
  }
}

export default InfraCalculatorDistribution;
