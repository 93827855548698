import conceito from "assets/images/menu-etapas/conceito.svg";
import diagnostico from "assets/images/menu-etapas/diagnostico.svg";
import planejamento from "assets/images/menu-etapas/planejamento.svg";
import monitoramento from "assets/images/menu-etapas/monitoramento.svg";

const MENU_VISION_STEPS = [
  {
    key: "concept",
    title: "CONCEITO",
    image: conceito,
    alt: "conceito",
    stepName: "COMO A TECNOLOGIA PODE IMPACTAR A SUA REDE",
    items: [
      {
        content:
          "O que significa ter uma Visão para o uso de tecnologias na sua rede",
        background: false,
      },
      {
        content:
          "Currículo de Referência em Tecnologia e Computação - da Educação Infantil ao Ensino Fundamental Anos Finais",
        background: true,
        link: "https://curriculo.cieb.net.br/",
      },
      {
        content:
          "Currículos de Referência em Tecnologia e Computação - da Educação Infantil ao Ensino Médio",
        background: true,
        link: "https://curriculo.cieb.net.br/medio",
      },
      {
        content:
          "Currículo de Referência para a Educação Profissional Técnica de Nível Médio em Tecnologia e Computação",
        background: true,
        link: "https://curriculo.cieb.net.br/profissional/prof_tec",
      },
      {
        content: "Currículo de Referência em Ciência de Dados",
        background: true,
        link: "https://curriculo.cieb.net.br/profissional/prof_cdd",
      },
    ],
  },
  {
    key: "diagnosis",
    title: "DIAGNÓSTICO",
    image: diagnostico,
    alt: "diagnóstico",
    stepName:
      "CONHEÇA SUA REDE: DIAGNÓSTICO SOBRE O NÍVEL DE ADOÇÃO DE TECNOLOGIAS NAS ESCOLAS",
    items: [
      {
        content: "Conheça sua rede:",
        background: false,
      },
      {
        content:
          "Ferramenta de diagnóstico de adoção de tecnologia nas escolas",
        background: true,
        link: "https://guiaedutec.com.br/escola",
      },
    ],
  },
  {
    key: "planning",
    title: "PLANEJAMENTO",
    image: planejamento,
    alt: "planejamento",
    stepName: "ELABORAÇÃO DO PLANO DE INOVAÇÃO & TECNOLOGIA DA REDE",
    items: [
      {
        content: "Construa o Plano de Inovação & Tecnologia",
        background: true,
        to: "/planos",
      },
      {
        content: "Guia de acompanhamento do Plano de Inovação e Tecnologia",
        background: true,
        to: "/planos/acompanhamento",
      },
    ],
  },
  {
    key: "monitoring",
    title: "MONITORAMENTO",
    image: monitoramento,
    alt: "monitoramento",
    stepName: "ACOMPANHAMENTO E MONITORE",
    items: [
      {
        content: "Acompanhe sua rede:",
        background: false,
      },
      {
        content:
          "Ferramenta de diagnóstico de adoção de tecnologia nas escolas",
        background: true,
        link: "https://guiaedutec.com.br/escola",
      },
    ],
  },
];

export default MENU_VISION_STEPS;
