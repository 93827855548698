import { container } from "global-styles/shared-styles";
import styled from "styled-components";
import bannerVision from "assets/images/plans/banner-vision.svg";
import bannerCompetence from "assets/images/plans/banner-competence.svg";
import bannerRed from "assets/images/plans/banner-red.svg";
import bannerInfra from "assets/images/plans/banner-infra.svg";
import HelpIcon from "@mui/icons-material/Help";

const BANNER_DIMENSIONS = {
  vision: {
    banner: bannerVision,
    backgroundColor: "#fad62b",
    color: "#fad62b",
  },
  competence: {
    banner: bannerCompetence,
    backgroundColor: "#e62270",
    color: "#e62270",
  },
  red: { banner: bannerRed, backgroundColor: "#008bbc", color: "#008bbc" },
  infra: { banner: bannerInfra, backgroundColor: "#85c440", color: "#85c440" },
};

const chooseBannerBackground = (dimension) => {
  return `url(${BANNER_DIMENSIONS[dimension].banner})`;
};

export const StyledHelpIcon = styled(HelpIcon)`
  &&& {
    width: 2rem;
    height: 2rem;
    cursor: pointer;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  font-size: 1.5rem;
  display: flex;
  flex-direction: column;
`;
export const BannerWrapper = styled.section`
  background: ${({ dimension }) =>
    BANNER_DIMENSIONS[dimension].backgroundColor};
`;

export const Loading = styled.section`
  margin-bottom: 10rem;
  margin-top: 10rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export const Content = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10rem;
  /* margin-bottom: 10rem; */
  margin-top: 10rem;
`;
export const Banner = styled.div`
  ${container}
  background:  ${({ dimension }) => chooseBannerBackground(dimension)};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  height: 42rem;
  width: 100%;
  margin-bottom: 5rem;
`;
export const DescriptionWrapper = styled.section`
  > h2 {
    font-size: 2.2rem;
    font-weight: 400;
    text-align: center;
    > span {
      font-weight: 500;
      color: ${({ dimension }) => BANNER_DIMENSIONS[dimension].color};
    }
  }
`;
export const DimensionsSections = styled.section`
  ${container}
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  gap: 2rem;
`;
export const JourneySection = styled.section`
  background-color: ${({ theme }) => theme.colors.gray6};
  padding: 6rem 0rem 3rem 0rem;

  > div {
    ${container}
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 6rem;
    > div:nth-child(1) {
      display: flex;
      align-items: center;
      > img {
        margin-top: 1.5rem;
        width: 70rem;
      }
    }
    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 2rem;
      font-size: 1.6rem;
      > h1 {
        font-weight: 500;
        font-size: 1.8rem;
        color: ${({ theme }) => theme.colors.black};
        text-align: center;
      }

      > p {
        font-size: 1.6rem;
        text-align: center;
      }
    }
  }
`;
export const SpecificGoals = styled.section`
  ${container}
`;

export const TitleWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 2rem;
  > img {
    width: 6rem;
  }
  border-bottom: 0.1rem solid #cfded7;
  padding: 1rem 2rem;
  margin-bottom: 3rem;
`;

export const Title = styled.h1`
  font-size: 2.2rem;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.black};
`;

export const ButtonWrapper = styled.div`
  ${container}
  display: flex;
  justify-content: space-between;
  > button {
    background-color: ${({ theme }) => theme.colors.blue5};
    color: ${({ theme }) => theme.colors.white};
    padding: 0rem 2rem;
    height: 4rem;
    border: none;
    border-radius: 0.5rem;
    cursor: pointer;
    &:hover {
      background-color: ${({ theme }) => theme.colors.blue2};
      transition: 0.1s ease-in-out;
    }
  }
`;

export const Button = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.7rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.2rem 0.5rem rgb(0 0 0 / 10%), 0 0.2rem 0.5rem rgb(0 0 0 / 10%);
  align-self: flex-end;
  border: none;
  color: ${({ theme }) => theme.colors.black};
  padding: 1.3rem 1.6rem;
  border-radius: 0.5rem;
  font-size: 1.5rem;
  font-weight: 500;
  min-height: 4.6rem;
  cursor: pointer;
  > svg {
    font-size: 2rem;
  }
`;

export const NewGoalWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 6rem;
`;

export const Goals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const InfraCalculatorSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 7rem;
`;

//

export const CircularProgressWrapper = styled.div`
  text-align: center;
`;

export const Contents = styled.div`
  ${container}
  display: flex;
  flex-direction: column;
  margin-bottom: 10rem;
  gap: 6rem;
`;

export const NetworkName = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: ${({ theme }) => theme.colors.black};
  > h1 {
    font-size: 2.2rem;
    font-weight: 400;
  }
  > p {
    font-size: 2.8rem;
    font-weight: 500;
  }

  margin-bottom: 2rem;
  padding: 1rem 2rem;
`;

export const NetworkData = styled.div`
  ${container}
  display: flex;
  flex-direction: column;
  gap: 5rem;
`;

export const Data = styled.div`
  display: flex;
  width: 100%;
  height: 16rem;
  margin-top: 1rem;
`;
