import React from "react";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function ThirdStep() {
  return (
    <S.Wrapper>
      <h1 id="r-e-3">3. APRENDA A FAZER CURADORIA E AQUISIÇÃO DE RED</h1>

      <p>
        Agora que você já conseguiu delimitar o que precisa e qual ou quais
        categorias atendem melhor à demanda educacional de sua rede de ensino, é
        hora de selecionar e avaliar os recursos.
      </p>

      <p>Vamos juntos?</p>

      <h2 id="r-e-3-s-1">3.1 Faça pesquisas de mercado</h2>

      <p>
        Para começar, indicamos que faça uma busca sobre RED demandados que
        estejam disponíveis no mercado para depois avaliá-los.
      </p>

      <p>
        Você pode fazer isso por meio de uma chamada pública, edital, sistema de
        inscrição ou mesmo por uma pesquisa de mercado feita pela sua rede de
        ensino.
      </p>

      <p>
        A Plataforma EduTec é outro recurso que pode auxiliá-lo/a na busca por
        RED. O site concentra informações sobre mais de 450 soluções, todas
        organizadas por filtros para facilitar a consulta.
      </p>

      <p>Acesse e explore!</p>

      <Card
        heading="PLATAFORMA"
        title="EDUTEC"
        buttonText="LEIA MAIS"
        dimension="red"
        backgroundName="plataformaEdutec"
        link="https://plataformaedutec.cieb.net.br/"
      />

      <h2 id="r-e-3-s-2">3.2 Avalie os RED de maneira criteriosa</h2>

      <p>
        Antes de decidir qual ou quais RED atendem de forma mais assertiva às
        necessidades de sua rede, você pode aprofundar a análise fazendo uma
        avaliação de cada solução encontrada.
      </p>

      <p>
        Com o{" "}
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.google.com/spreadsheets/u/1/d/15dwz-kmuND3ndF1iwacHEWyNyuCjLm-y9Gi3B7Hz-KA/copy?pli=1#gid=2073279345"
        >
          <i>instrumento de avaliação criado pelo CIEB</i>
        </a>
        , você poderá comparar os recursos, identificar o potencial de cada um e
        analisar de forma mais profunda os seguintes critérios:
      </p>

      <ul>
        <li>
          Critérios pedagógicos: atendimento às necessidades pedagógicas da
          rede, compatibilidade com o currículo e com as metodologias
          inovadoras.
        </li>
        <li>
          Critérios de gestão escolar (apenas para recursos de gestão):
          atendimento às necessidades do planejamento, da gestão escolar e
          análise de dados para tomada de decisão.
        </li>
        <li>
          Critérios tecnológicos: usabilidade, facilidade de implementação,
          conectividade, acesso por diferentes dispositivos e atendimento à
          legislação vigente de proteção de dados (Lei n° 13.709/2018).
        </li>
        <li>
          Critérios de acessibilidade: atendimento às necessidades de pessoas
          com deficiência.
        </li>
        <li>
          Critérios de evidências: existência de atestados ou evidências de sua
          implementação em outras redes de ensino e/ou contextos.
        </li>
      </ul>

      <p>
        Acesse aqui o instrumento de avaliação e faça uma cópia para começar a
        usar
      </p>

      <Card
        heading="INSTRUMENTO DE"
        title="CURADORIA"
        buttonText="LEIA MAIS"
        dimension="red"
        backgroundName="investimentoDeCuradoria"
        link="https://docs.google.com/spreadsheets/u/1/d/15dwz-kmuND3ndF1iwacHEWyNyuCjLm-y9Gi3B7Hz-KA/copy?pli=1#gid=2073279345"
      />

      <h2 id="r-e-3-s-3">
        3.3 Avance para o processo de adoção ou aquisição do recurso
      </h2>

      <p>
        Depois de fazer uma avaliação criteriosa por meio do instrumento de
        curadoria, é hora de decidir se irá adotá-lo (recursos gratuitos) e/ou
        adquiri-lo (recursos pagos). Pode ainda fazer combinações de RED
        gratuitos e outros pagos.
      </p>

      <p>
        Em caso de adoção, é importante se atentar que recursos free ou freemium
        podem em algum momento deixar de serem gratuitos, e muitas vezes exigem
        o compartilhamento de dados que possam ser sigilosos de acordo com a Lei
        Geral de Proteção de Dados Pessoais (LGPD). Para entender melhor como a
        LGPD é aplicada a educação, assista ao webinário do CIEB{" "}
        <a
          href="https://www.youtube.com/watch?v=o41_X4qYykw"
          target="_blank"
          rel="noreferrer"
        >
          <i>aqui</i>
        </a>
        .
      </p>

      <p>
        No caso da aquisição, sabemos da complexidade do processo de contratação
        de recursos educacionais digitais, por isso, desenvolvemos o Guia de
        Aquisições de Tecnologias Educacionais contendo orientações e modelos de
        compras para você selecionar e adquirir tecnologias com eficiência,
        agilidade e segurança. Explore!
      </p>

      <Card
        heading="TOOLKIT DE"
        title="COMPRAS"
        buttonText="LEIA MAIS"
        dimension="red"
        backgroundName="toolkitDeCompras"
        link="https://toolkit.plataformaedutec.com.br/"
      />
    </S.Wrapper>
  );
}
