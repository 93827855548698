export const QUESTIONS = {
  CONTEUDO_ESTRUTURADO: [
    {
      id: "online_content_delivery_platform",
      name: "PLATAFORMA DE OFERTA DE CONTEÚDO ONLINE",
      description: (
        <p>
          Docentes e estudantes tendo acesso a plataformas digitais (pagas ou
          gratuitas) com vários recursos, como jogos, videoaulas, cursos online
          ou outros conteúdos educacionais próprios da plataforma (não gerados
          por terceiros), por exemplo, plataforma com conteúdos preparatórios
          para o vestibular mediante inscrição ou assinatura.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>MOOCs (Massive Open On-line Course); Marketplace.</p>
        ),
        definition: (
          <p>
            Plataforma com cursos, jogos e/ou objetos digitais de aprendizagem
            acessada por estudantes de forma gratuita ou paga. Diferencia-se de
            um repositório por restringir o acesso ao conteúdo ao ambiente da
            plataforma.
          </p>
        ),
        main_features: (
          <p>
            Apresenta cursos online e/ou videoaulas, além de ferramentas de
            apoio com atividades, simulados, interações, tutoria, entre outros.
            Pagamento integrado, direcionado ao usuário final.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Plataforma com cursos online pagos e acessível a qualquer pessoa
              (e não apenas a instituições de ensino, empresas etc.)
            </li>
            <li>
              Site pago (mediante assinatura) com coleção de videoaulas para o
              estudo pré-vestibular.
            </li>
            <li>
              Site de conteúdos próprios (não gerado por usuários/as ou
              terceiros) organizado por temática e disponibilizado
              gratuitamente.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "digital_repository",
      name: "REPOSITÓRIO DIGITAL",
      description: (
        <p>
          Docentes e estudantes acessando plataformas digitais, com vários
          recursos e conteúdos para aprendizagem, para realizar pesquisas por
          tema, componente curricular e/ou ano/série, por exemplo, pesquisando
          repositório de imagens sobre o estilo barroco para compartilhar com
          estudantes.
        </p>
      ),
      what_is: {
        international_terms: <p>DR (Digital Repository).</p>,
        definition: (
          <p>
            Plataforma online que reúne e sistematiza conteúdos para permitir
            que eles sejam acessados, baixados e/ou utilizados em outras
            plataformas ou ferramentas.
          </p>
        ),
        main_features: (
          <p>
            Armazena e/ou referencia conteúdos da web, permitindo a busca a
            partir de diferentes aspectos, como área de conhecimento, série
            indicada, formato do objeto digital de aprendizagem e forma de
            aplicação. Além disso, facilita o download dos conteúdos e ainda
            possibilita a criação de portfólios e recursos digitais (objetos
            digitais de aprendizagem ou planos de aula, por exemplo) pelas
            pessoas.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Plataforma para os(as) docentes publicarem objetos digitais de
              aprendizagem de autoria própria e ainda avaliarem conteúdos
              publicados por outras pessoas.
            </li>
            <li>
              Site para os(as) docentes pesquisarem e disponibilizarem conteúdos
              em um Ambiente Virtual de Aprendizagem.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "online_course",
      name: "CURSO ONLINE",
      description: (
        <p>
          Estudantes realizando cursos online, com conteúdo organizado e
          avaliação das aprendizagens, sobre temas relacionados ao currículo ou
          aos seus projetos de vida, por exemplo, acessando videoaulas e
          atividades em um curso de programação.
        </p>
      ),
      what_is: {
        international_terms: <p>On-line Courses.</p>,
        definition: (
          <p>
            Programa sequencial de estudos realizado em ambiente virtual, de
            forma autoinstrucional, com o apoio de objetos de aprendizagem. Além
            da formação completa, também possibilita a avaliação do aprendizado
            de um tema específico. Pode ser ofertado em diferentes ferramentas e
            plataformas.
          </p>
        ),
        main_features: (
          <p>
            São compostos por objetos digitais de aprendizagem instrucionais
            (como textos, vídeos e áudios) e atividades de avaliação e reforço
            do conhecimento. Podem conter interação entre os/as
            participantes(chats ou fóruns) e certificado de conclusão.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Curso online de programação com vídeos, avaliações, jogos
              interativos e momentos de prática.
            </li>
            <li>
              Curso online de carpintaria com vídeos sobre técnicas e propostas
              de exercícios práticos.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "educational_game",
      name: "JOGO EDUCATIVO",
      description: (
        <p>
          Estudantes utilizando, de forma individual ou coletiva, jogos digitais
          que apoiam o desenvolvimento de habilidades e competências previstas
          no currículo. Exemplo: jogos que demandam a realização de cálculos
          para avançar de fase ou a visita a patrimônios históricos para
          colecionar medalhas.
        </p>
      ),
      what_is: {
        international_terms: <p>Educational Games; Serious Games.</p>,
        definition: (
          <p>
            Software lúdico desenvolvido ou utilizado com finalidade pedagógica
            para expandir conceitos e/ou contribuir com o desenvolvimento das
            crianças e jovens. Pode estar contido em diferentes ferramentas e
            plataformas.
          </p>
        ),
        main_features: (
          <p>
            Possui a figura do jogador, além de regras, desafios e recompensas.
            Em alguns casos, contém narrativas e possibilidade de progressão.
            Pode ser jogado individualmente ou entre múltiplas pessoas.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Jogo 3D em terceira pessoa para explorar um ambiente histórico e a
              interação com personagens da época.
            </li>
            <li>
              Jogo com problemas de lógica matemática e computacional
              apresentados de forma lúdica e interativa.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "oda",
      name: "OBJETO DIGITAL DE APRENDIZAGEM (ODA)",
      description: (
        <p>
          Docentes e estudantes usando recursos digitais como parte do processo
          de aprendizagem, em mídias diversas, como infográficos, tabelas
          interativas, podcasts, videoaulas, notícias ou artigos veiculados em
          sites. por exemplo, vídeos de parlendas para as crianças identificarem
          as diferenças entre os sons das sílabas.
        </p>
      ),
      what_is: {
        international_terms: <p>DLO (Digital Learning Objects).</p>,
        definition: (
          <p>
            São vídeos, imagens, textos, áudios, simulações, infográficos,
            conteúdos interativos, questões, entre outros recursos digitais
            utilizados para contribuir com a prática pedagógica, dentro e fora
            de sala de aula. São reutilizáveis e autocontidos, ou seja, contêm
            todos os recursos e arquivos necessários para a sua execução, sem
            dependência externa. Podem ser encontrados em diferentes plataformas
            e ferramentas.
          </p>
        ),
        main_features: (
          <p>
            Os Objetos Digitais de Aprendizagem (ODAs) têm a função de
            incrementar o processo de aprendizagem. Podem ser elaborados de
            forma padronizada para integrar ambientes virtuais de aprendizagem e
            repositórios digitais.
          </p>
        ),
        examples: (
          <ul>
            <li>Videoaula sobre frações.</li>
            <li>
              Podcast sobre o Brasil colonial indicado como conteúdo extra da
              aula de história.
            </li>
            <li>
              Notícia ambiental utilizada pela professora ou professor de
              Ciências da Natureza em sala de aula.
            </li>
          </ul>
        ),
      },
    },
  ],
  AUTORIA_E_COLABORACAO: [
    {
      id: "authoring_tool",
      name: "FERRAMENTA DE AUTORIA - PRODUÇÃO DE RECURSOS MULTIMÍDIA",
      description: (
        <p>
          Docentes e estudantes utilizando ferramentas digitais que apoiam a
          criação e a edição de texto, áudio, vídeo ou outros formatos, para
          elaboração ou apresentação de conteúdo pedagógico, por exemplo,
          produzindo um podcast, livro autoral ou vídeo.
        </p>
      ),
      what_is: {
        international_terms: <p>Authoring Tools.</p>,
        definition: (
          <p>
            Tecnologia que viabiliza a criação ou modificação de textos,
            imagens, áudios, vídeos, conteúdos interativos, softwares, dados e
            demais conteúdos com propósito pedagógico. Não requer conhecimentos
            técnicos avançados.
          </p>
        ),
        main_features: (
          <p>
            Possibilita criar, editar e salvar projetos em padrão
            compartilhável. O objetivo final apresenta todos os recursos
            necessários para o seu funcionamento (autocontido), sem dependência
            da ferramenta que o criou.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Software para a criação de apresentações que possam ser utilizadas
              em sala de aula.
            </li>
            <li>
              Programa para a criação de simulações 3D para serem usadas, por
              exemplo, nas aulas de física.
            </li>
            <li>
              Software para a programação de jogos simples no laboratório de
              informática.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "tutoring_tool",
      name: "FERRAMENTA DE TUTORIA",
      description: (
        <p>
          Docentes usando uma ou mais ferramentas digitais para acompanhar, a
          distância, estudantes em seu processo de aprendizagem, em momentos de
          reforço ou resolução de dúvidas, para se comunicar por meio de áudio,
          vídeo, texto, lousa digital e outros recursos. Exemplo: realização de
          videochamada para atender a um grupo de estudantes com dificuldades em
          álgebra.
        </p>
      ),
      what_is: {
        international_terms: <p>Tutoring Tools</p>,
        definition: (
          <p>
            Tecnologia que possibilita a docentes e/ou tutores(as) interagirem
            com estudantes a distância e em tempo real, para oferecer reforço
            escolar.
          </p>
        ),
        main_features: (
          <p>
            Permite a realização de interação online por meio de áudio, vídeo ou
            texto, aliada ao compartilhamento de tela e arquivos em tempo real.
            Pode conter software de lousa virtual integrado.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Ferramenta online para a realização de videochamada, fora da sala
              de aula, para auxiliar estudantes com necessidade de reforço.
            </li>
            <li>
              Ferramenta online com opção de chamada em áudio e software de
              lousa digital para que os(as) estudantes tirem dúvidas uns dos
              outros, a distância.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "collaboration_tool",
      name: "FERRAMENTA DE COLABORAÇÃO/GESTÃO DE TAREFAS",
      description: (
        <p>
          Docentes e estudantes utilizando ferramentas digitais para trabalhar
          colaborativamente, em um único arquivo de texto, imagem, vídeo ou
          outro formato, com atualizações simultâneas ou remotas. Exemplo:
          ferramenta para elaboração de redação coletiva ou mapa mental sobre
          algum tema estudado em sala de aula.
        </p>
      ),
      what_is: {
        international_terms: <p>Collaboration Tools.</p>,
        definition: (
          <p>
            Tecnologia online que possibilita a colaboração entre estudantes e
            docentes na execução de trabalhos, como a criação de conteúdos.
          </p>
        ),
        main_features: (
          <p>
            Permite que múltiplas pessoas trabalhem em um mesmo arquivo, em
            tempo real, utilizando uma ferramenta de autoria, por exemplo.
            Possui controle de usuários(as) e acessos e pode conter
            funcionalidade de planejamento e divisão de tarefas.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Ferramenta online que permite que vários estudantes trabalhem na
              produção de uma mesma análise literária e, em tempo real, comentem
              e melhorem a produção uns dos outros.
            </li>
            <li>
              Ferramenta que permite a coleta colaborativa de dados, por
              diversos estudantes, para uma pesquisa ou projeto de ciências.
            </li>
          </ul>
        ),
      },
    },
  ],
  ESPACOS_DE_APRENDIZAGEM: [
    {
      id: "ava",
      name: "AMBIENTE VIRTUAL PLATAFORMA EDUCACIONAL DE APRENDIZAGEM (AVA)",
      description: (
        <p>
          Docentes e estudantes acessando plataformas digitais para criar
          espaços de ensino e aprendizagem online para os modelos EaD, remoto ou
          híbrido, por exemplo, utilizando uma sala de aula virtual para
          disponibilizar conteúdos e atribuir tarefas para a turma.
        </p>
      ),
      what_is: {
        international_terms: <p>LMS (Learning Management System).</p>,
        definition: (
          <p>
            Plataforma de ensino e aprendizagem online para a prática do ensino
            remoto (e-learning), ensino híbrido ou ensino semipresencial.
            Incluir recursos para gerenciar conteúdos, atividades e avaliações.
          </p>
        ),
        main_features: (
          <p>
            Permite que a construção de ambientes de ensino, a disponibilização
            de conteúdos, atividades, avaliações e possibilidades de interação
            entre as pessoas e ainda disponibiliza ferramentas de gestão de
            turmas, notas, usuários(as) e relatórios.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Plataforma utilizada por um(a) professor(a) de química para
              disponibilizar materiais para estudo e exercícios.
            </li>
            <li>
              Plataforma utilizada por um(a) professor(a) de português para
              disponibilização de textos e notícias selecionadas na internet
              para leitura em casa e discussão em sala de aula.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "class_support_tool",
      name: "FERRAMENTA DE APOIO À AULA",
      description: (
        <p>
          Docentes utilizando ferramentas para construir ou consultar planos de
          aula, organizar conteúdos, registrar atividades que realizaram com as
          turmas ou receber perguntas de estudantes durante as aulas. Exemplo:
          software de lousa digital.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>TAS (Teaching Assistant System); Lesson Planning.</p>
        ),
        definition: (
          <p>
            Tecnologia que oferece apoio a docentes em processos pedagógicos
            relativos ao planejamento e/ou execução de aulas presenciais. Pode
            funcionar integrada a um sistema gerenciador de sala de aula.
          </p>
        ),
        main_features: (
          <p>
            Entre outras funcionalidades, oferece os recursos de: criação de
            planos de aula, distribuição de atividades dentro da sala, controle
            e apresentação de conteúdos em tempo real, software de lousa
            virtual, entre outros.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Aplicativo para os(as) estudantes fazerem perguntas usando seus
              celulares durante a aula. O conteúdo é acessado no computador, em
              tempo real, pelos(as) docentes(as).
            </li>
            <li>
              Aplicativo para os(as) estudantes compartilharem suas telas entre
              si ou com o(a) professor(a) para mostrar o trabalho que acabaram
              de realizar.
            </li>
            <li>
              Programa para os(as) docentes armazenarem conteúdos que vão
              utilizar em sala de aula e registrarem suas atividades.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "adaptive_educational_platform",
      name: "PLATAFORMA EDUCACIONAL ADAPTATIVA",
      description: (
        <p>
          Estudantes usando uma plataforma digital que realiza avaliação
          diagnóstica de um componente curricular ou tema, fornece uma trilha de
          estudos personalizada, com conteúdos e exercícios que atendem à sua
          necessidade, enquanto os/as docentes visualizam avanços e dificuldades
          de cada estudante para adaptar as trilhas de estudo.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>ITS (Intelligent Tutoring System); Adaptive Learning</p>
        ),
        definition: (
          <p>
            Plataforma pedagógica que apoia o processo de aprendizagem ao
            empregar técnicas computacionais para modelar e conduzir a
            apresentação dos conteúdos, visando definir o que e como ensinar de
            forma personalizada.
          </p>
        ),
        main_features: (
          <p>
            Disponibiliza conteúdos, atividades e avaliações de forma
            sequencial, sendo capaz de criar trilhas individualizadas de acordo
            com o perfil do(a) aprendiz e de gerar relatórios para o(a) docente
            ou escola.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Aplicativo para a realização de exercícios de física. O nível de
              complexidade da sequência avança conforme o desempenho do(a)
              estudante.
            </li>
            <li>
              Plataforma com conteúdos de inglês para complementar o ensino. Os
              conteúdos são selecionados automaticamente com base nas
              dificuldades dos(as) estudantes.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "educational_platform",
      name: "PLATAFORMA EDUCACIONAL",
      description: (
        <p>
          Estudantes usando plataformas para desenvolver habilidades de um ou
          mais componentes curriculares por meio do acesso a conteúdos e
          avaliações organizados em sequências préestruturadas, por exemplo,
          acessando uma plataforma que apresenta uma videoaula de inglês e, em
          seguida, um jogo de perguntas e respostas relacionado ao conteúdo.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>Curriculum Platform; Digital Learning Platform.</p>
        ),
        definition: (
          <p>
            Plataforma pedagógica de apoio ao(à) docente no processo de ensino.
            Apresenta conteúdos e avaliações de forma sequencial
            pré-estruturada.
          </p>
        ),
        main_features: (
          <p>
            Disponibiliza trilhas de conteúdo associadas a atividades e
            avaliações, com informações de progresso do(a) estudante para uso
            docente.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Plataforma para complemento do ensino de inglês para os(as)
              estudantes explorarem, durante 15 minutos, jogos linguísticos
              sequenciais.
            </li>
            <li>
              Plataforma com vídeos, textos e avaliações para cada habilidade
              requerida pela BNCC na área de Matemática.
            </li>
          </ul>
        ),
      },
    },
  ],
  GESTAO_PEDAGOGICA: [
    {
      id: "pedagogical_management_support_tool",
      name: "FERRAMENTA DE APOIO À GESTÃO PEDAGÓGICA",
      description: (
        <p>
          Profissionais da escola utilizando ferramentas para registrar
          matrículas, calendário letivo, grade horária, notas dos/as estudantes
          e/ ou para comunicação com a comunidade escolar. Docentes acessando
          ferramentas para aferir presença dos/das estudantes, apontar notas ou
          agendar avaliações.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>
            Communication Tools; Data Systems; Finances and Fess; Help Desk;
            Human Resources; Grading and Attendance.
          </p>
        ),
        definition: (
          <p>
            Tecnologia que apoia a gestão de processos pedagógicos. Pode
            funcionar integrada a uma plataforma ou sistema de gestão.
          </p>
        ),
        main_features: (
          <p>
            Gestão de matrículas, notas, calendário, grade horária, diário de
            classe, diário do(a) estudante, comunicação com familiares e
            responsáveis, comunicação com estudantes, gestão de indicadores.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Ferramenta online para enviar as notas dos(as) estudantes ao
              celular da/do responsável por SMS;
            </li>
            <li>
              Aplicativo com a função de calendário individual para estudantes
              acompanharem datas de provas e eventos agendados pela coordenação
              e corpo docente.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "curriculum_manager_tool",
      name: "FERRAMENTA GERENCIADORA DE CURRÍCULO",
      description: (
        <p>
          Coordenação pedagógica e docentes cadastrando e/ou acompanhando o
          desenvolvimento do currículo e acessando recursos educacionais
          relacionados às habilidades previstas neste currículo, por exemplo,
          consultando a distribuição de conteúdos ao longo do ano letivo e
          construindo planos de aula.
        </p>
      ),
      what_is: {
        international_terms: <p>Curriculum Management System.</p>,
        definition: (
          <p>
            Tecnologia de apoio à construção de currículos por meio da
            sistematização de práticas pedagógicas, conteúdos e recursos,
            organizando-os ao redor de habilidades previamente definidas para um
            componente curricular.
          </p>
        ),
        main_features: (
          <p>
            Permite construir e compartilhar o currículo de uma rede de ensino
            ou escola e indexar recursos educacionais associados ao
            desenvolvimento de habilidades. Em alguns casos, permite analisar o
            grau de alinhamento do currículo com as habilidades previamente
            definidas.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Site para os(as) gestores da rede ensino cadastrarem e divulgarem
              a estrutura do currículo territorial, que deverá servir de base
              para a organização de aulas e conteúdos pelo corpo docente.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "classroom_management_system",
      name: "SISTEMA GERENCIADOR DE SALA DE AULA",
      description: (
        <p>
          Docentes e/ou outros funcionários acessando um sistema para controlar
          e monitorar remotamente tablets, notebooks ou outros dispositivos
          utilizados pelos/as estudantes na escola, podendo compartilhar
          conteúdos e atividades, por exemplo, o(a docente, visualiza as telas
          dos/as estudantes e envia exercícios aos dispositivos.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>
            CLM (Classroom Management System); MDM (Mobile Device Management).
          </p>
        ),
        definition: (
          <p>
            Sistema de apoio ao(à) docente para gerenciar e monitorar o uso
            simultâneo de dispositivos em sala de aula. Também permite a
            realização de atividades e avaliações em tempo real.
          </p>
        ),
        main_features: (
          <p>
            Combina ferramentas de monitoramento e controle remoto de
            dispositivos (MDM) com funcionalidades de distribuição de
            conteúdo,atividades e avaliação. Também pode conter ferramenta de
            colaboração em sala de aula.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Software instalado em kits de sala de aula (especificamente em
              tablets) que permite ao(à) docente acompanhar a tela de todos, em
              tempo real, e compartilhar arquivos de forma individual ou
              coletiva.
            </li>
          </ul>
        ),
      },
    },
    {
      id: "student_assessment_tool",
      name: "FERRAMENTA DE AVALIAÇÃO DO(A) ESTUDANTE",
      description: (
        <p>
          Docentes selecionando ou criando questões e atividades em formato
          digital para acompanhar e avaliar as aprendizagens sobre um tema, por
          exemplo, um formulário online que disponibiliza as respostas dos
          estudantes em listas ou gráficos.
        </p>
      ),
      what_is: {
        international_terms: <p>SAS (Student Assessment System).</p>,
        definition: (
          <p>
            Tecnologia para avaliar os(as) estudantes e monitorar o entendimento
            deles sobre um tema. Pode funcionar integrada a plataformas.
          </p>
        ),
        main_features: (
          <p>
            Permite a criação de questões (eventualmente, pode conter um banco
            de perguntas) ou a proposta de atividades para um grupo de
            estudantes. Possui funcionalidades de correção, automatizada ou não,
            e de visualização de resultados individuais e coletivos.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Site com questões pedagógicas com a funcionalidade de correção
              automática das perguntas de múltipla escolha.
            </li>
            <li>
              Aplicativo para os(as) docentes fazerem perguntas aos(às)
              estudantes em sala de aula e receber respostas em tempo real. As
              respostas são enviadas pelo celular e agregadas automaticamente
              para facilitar sua visualização.
            </li>
          </ul>
        ),
      },
    },
  ],
  GESTAO_ADMINISTRATIVA: [
    {
      id: "tool_for_administrative_financial_management",
      name: "FERRAMENTA DE APOIO À GESTÃO ADMINISTRATIVO - FINANCEIRA",
      description: (
        <p>
          Gestão escolar e profissionais do setor administrativo da escola
          utilizam uma ou mais ferramentas digitais para gerenciar a
          distribuição de material didático, o transporte escolar ou a merenda,
          e/ou gerenciar funcionários, controlar pagamentos, acompanhar o fluxo
          de caixa ou realizar outras atividades financeiras.
        </p>
      ),
      what_is: {
        international_terms: (
          <p>
            Communication Tools; Data Systems; Finances and Fess; Help Desk;
            Human Resources; Grading and Attendance.
          </p>
        ),
        definition: (
          <p>
            Ferramenta que apoia processos administrativos e/ou financeiros
            específicos da gestão da escola ou da rede de ensino. Pode funcionar
            integrada a uma plataforma ou sistema de gestão.
          </p>
        ),
        main_features: (
          <p>
            Possui funcionalidades relacionadas à: Gestão administrativa:
            recursos humanos, segurança, transporte, alimentação, material
            didático, infraestrutura, gestão de contratos, planejamento
            estratégico ou outros processos de gestão; Gestão financeira:
            mensalidades, pagamentos, repasses de verba, balanço, controle de
            custos, demonstração de resultado de exercício (DRE), fluxo de
            caixa, entre outros.
          </p>
        ),
        examples: (
          <ul>
            <li>Ferramenta de fluxo de caixa para a escola;</li>
            <li>
              Ferramenta para gestão de alimentação e merenda para a rede de
              ensino;
            </li>
            <li>
              Ferramenta para gestão de contratos de serviços ou fornecimento de
              bens de uma ou mais escolas da rede de ensino;
            </li>
            <li>Ferramenta de CRM educacional.</li>
          </ul>
        ),
      },
    },
    {
      id: "sig_sis",
      name: "SISTEMA DE GESTÃO EDUCACIONAL (SIG/SIS)",
      description: (
        <p>
          Gestão escolar e profissionais do setor administrativo utilizam um
          sistema que integra mais de uma ferramenta para gerenciar atividades
          relacionadas ao funcionamento da unidade escolar (repasse de verbas,
          fluxo de caixa, vida funcional, distribuição de merenda), podendo
          incluir ferramentas de apoio pedagógico (matrículas, grade horária,
          calendário letivo, notas).
        </p>
      ),
      what_is: {
        international_terms: (
          <p>
            EMIS (Education Management Information System);SIS (Student
            Information System).
          </p>
        ),
        definition: (
          <p>
            Sistema de gestão de processos e dados pedagógicos, financeiros e/ou
            administrativos de escolas e redes de ensino.
          </p>
        ),
        main_features: (
          <p>
            Apoia vários processos por meio de ferramentas de gestão pedagógica
            ou administrativo-financeira para fazer o controle de matrícula,
            turmas, grade horária, notas, pagamentos, recebimentos, recursos
            humanos, entre outros. Disponibiliza relatórios e indicadores de
            processos e resultados.
          </p>
        ),
        examples: (
          <ul>
            <li>
              Sistema para o(a) diretor(a) escolar controlar matrículas, grade
              horária dos(as) docentes e calendário, de forma integrada.
            </li>
            <li>
              Plataforma para os(as) estudantes acessarem notas e presença nas
              disciplinas que cursam.
            </li>
          </ul>
        ),
      },
    },
  ],
};
