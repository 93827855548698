import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import * as S from "./styles";
import {
  CircularProgress,
  TableContainer,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from "@mui/material";
import { convertToMoneyFormat } from "utils/money";

const LABELS = {
  access_point: "Access Point",
  charger: "Carregadores",
  comp_admins: "Computador para uso administrativo",
  comp_students: "Computadores para estudantes",
  comp_teachers: "Computadores para professores (sala de aula)",
  projector: "Projetor ou TV",
  rack: "Rack",
  firewall: "Firewall",
  nobreak: "Nobreaks",
  printers: "Impressora multifuncional",
  switch: "Switch L3 (16,24 ou 48 portas)",
};

const createData = () => {
  return { equipament: "", amount: "", total_price: "" };
};

function EquipmentsTable({
  rows,
  columns,
  calcInfraDashboardLoading,
  calcInfraDashboardSuccess,
}) {
  const [totalInvestiment, setTotalInvestiment] = useState(0);
  const rowsMemo = useMemo(() => {
    if (calcInfraDashboardSuccess && rows) {
      const rowsAux = Object.entries(rows)
        .filter(([key, _]) => key !== "comp_students")
        .map(([key, values]) => ({
          equipament: LABELS[key],
          amount: values?.amount,
          total_price: values?.price,
        }));

      const totalInvestimentAux = rowsAux.reduce(
        (pv, cv) => ({ total_price: pv.total_price + cv.total_price }),
        { total_price: 0 }
      ).total_price;
      setTotalInvestiment(totalInvestimentAux);
      return rowsAux;
    } else {
      return [];
    }
  }, [rows, calcInfraDashboardSuccess]);

  if (calcInfraDashboardLoading) {
    return (
      <S.LoadingWrapper>
        <CircularProgress size={30} />
      </S.LoadingWrapper>
    );
  }

  return (
    calcInfraDashboardSuccess && (
      <TableContainer>
        <S.StyledTable sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell key={column}>{column}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsMemo?.map((row, index) => (
              <TableRow key={`row-${index}`}>
                {Object.keys(createData()).map((key) => (
                  <TableCell key={key}>
                    {key === "total_price"
                      ? convertToMoneyFormat(row[key])
                      : row[key]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell>Investimento total</TableCell>
              <TableCell></TableCell>
              <TableCell>{convertToMoneyFormat(totalInvestiment)}</TableCell>
            </TableRow>
          </TableFooter>
        </S.StyledTable>
      </TableContainer>
    )
  );
}

EquipmentsTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
};

export default EquipmentsTable;
