import {
  createContext,
  useContext,
  useState,
  useEffect,
  useCallback,
} from "react";
import { useMenu } from "./use-menu";

const SCROLL_INTO_VIEW_OPTIONS = {
  behavior: "smooth",
  block: "center",
  inline: "nearest",
};

const DIMENSION = {
  v: "vision",
  c: "competence",
  r: "red",
  i: "infra",
};

const StepsContext = createContext();

function StepProvider({ children }) {
  const [step, setStep] = useState(0);
  const [dimension, setDimension] = useState();
  const { handleShowMenu, handleMenu } = useMenu();

  const nextStep = useCallback(() => {
    setStep(step + 1);
  }, [step]);

  const previousStep = useCallback(() => {
    setStep(step - 1);
  }, [step]);

  const restartStep = useCallback((dimension) => {
    setStep(0);
    setDimension(dimension);
  }, []);

  const goToElement = useCallback((id) => {
    const element = document.getElementById(id);
    element?.scrollIntoView(SCROLL_INTO_VIEW_OPTIONS);
  }, []);

  const goToStep = useCallback(
    (index, dimension) => {
      setDimension(DIMENSION[dimension]);
      if ([0, 1].includes(index)) {
        setStep(0);
        goToElement(`${dimension}-e-1`);
        window.scrollBy(0, 300);
      } else {
        index -= 1;
        setStep(index);
        goToElement(`${dimension}-e-${index}`);
        window.scrollBy(0, 300);
      }
    },
    [goToElement]
  );

  useEffect(() => {
    if (dimension) {
      handleMenu(dimension, step);
    }
  }, [step, dimension, handleMenu]);

  const goToSubStep = useCallback(
    (stepNumber, dimension, subStepNumber) => {
      setStep(stepNumber);
      goToElement(`${dimension}-e-${stepNumber + 1}-s-${subStepNumber}`);
      handleShowMenu(false);
    },
    [handleShowMenu, goToElement]
  );

  return (
    <StepsContext.Provider
      value={{
        step,
        nextStep,
        previousStep,
        restartStep,
        setStep,
        goToStep,
        goToSubStep,
        goToElement,
      }}
    >
      {children}
    </StepsContext.Provider>
  );
}

const useSteps = () => useContext(StepsContext);

export { StepProvider, useSteps };
