import * as S from "./styles";
import { OrbitalSVGVision } from "../../OrbitalsSVG";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

function OrbitalVision({ id, large }) {
  const navigate = useNavigate();

  return (
    <S.Wrapper id={id} large={large}>
      <OrbitalSVGVision />
      <p>CONHEÇA SUA REDE</p>
      <p>ELABORE O PLANO DE INOVAÇÃO E TECNOLOGIA </p>
      <p>ACOMPANHE E MONITORE </p>
      <p>COMO A TECNOLOGIA PODE IMPACTAR A SUA REDE </p>
      <p onClick={() => navigate("/jornada/visao")}>
        COMO PLANEJAR O USO DE TECNOLOGIAS?
      </p>
    </S.Wrapper>
  );
}

OrbitalVision.propTypes = {
  id: PropTypes.string,
  large: PropTypes.bool,
};

export default OrbitalVision;
