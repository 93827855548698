import React, { useMemo, useRef, useState } from "react";
import * as S from "./styles";
import GENSelect from "../GENSelect";
import { useGEINNumbersFilter } from "hooks/use-ge-in-numbers-filter";
import { useCitiesQuery, useStatesQuery } from "services/common";

function GeneralFilter({ display, boxShadow }) {
  const {
    networkTypeSelected,
    setNetworkTypeSelected,
    lastCycles,
    setLastCycles,
    setSelectedCycle,
    selectedCycle,
    stateIdMemo,
    setStateId,
    setCityId,
    setStateName,
    setCityName,
    cityIdMemo,
  } = useGEINNumbersFilter();

  const [distanceToTop] = useState(null);

  const sectionRef = useRef();

  const distanceToTopMemo = useMemo(() => distanceToTop, [distanceToTop]);

  const {
    data: states,
    // isLoading: isLoadingStates,
    isSuccess: isSuccessStates,
  } = useStatesQuery();
  const {
    data: cities,
    // isLoading: isLoadingCities,
    isSuccess: isSuccessCities,
  } = useCitiesQuery(stateIdMemo?.value);

  const years = Array(new Date().getFullYear() - 2017 + 1)
    .fill("")
    .map((_, idx) => ({ value: `${2017 + idx}`, label: `${2017 + idx}` }))
    .sort((a, b) => b.value - a.value);

  const othersCycles = [
    { label: "ANOS ANTERIORES", value: "ANOS ANTERIORES" },
    ...years.slice(2, years.length),
  ];

  const citiesOptions = useMemo(() => {
    if (isSuccessCities && isSuccessStates && Array.isArray(cities)) {
      return [
        { label: "MUNICÍPIOS", value: "" },
        ...cities?.map((city) => ({
          label: city.name,
          value: city._id.$oid,
        })),
      ];
    }
  }, [isSuccessCities, isSuccessStates, cities]);

  const statesOptions = useMemo(() => {
    if (isSuccessStates && Array.isArray(states)) {
      return [
        { label: "ESTADOS", value: "" },
        ...states?.map((state) => ({
          label: state.name,
          value: state._id.$oid,
        })),
      ];
    }
  }, [isSuccessStates, states]);

  return (
    <S.Wrapper
      ref={sectionRef}
      distanceToTop={distanceToTopMemo}
      display={display}
      boxShadow={boxShadow}
    >
      <div>
        <div>
          <span>TIPO DE REDE</span>
          <ul>
            <li>
              <S.Button
                isSelected={networkTypeSelected === "allNetworks"}
                onClick={() => {
                  setNetworkTypeSelected("allNetworks");
                }}
              >
                TODAS
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={networkTypeSelected === "state"}
                onClick={() => setNetworkTypeSelected("state")}
              >
                ESTADUAL
              </S.Button>
            </li>
            <li>
              <S.Button
                isSelected={networkTypeSelected === "city"}
                onClick={() => setNetworkTypeSelected("city")}
              >
                MUNICIPAL
              </S.Button>
            </li>
          </ul>
        </div>
        <div>
          <span>LOCALIZAÇÃO</span>
          <ul>
            <li>
              <GENSelect
                value={stateIdMemo}
                onValueChange={(state) => {
                  setCityId({ label: "MUNICÍPIOS", value: "" });
                  setStateId(state);
                  setStateName(state?.label);
                }}
                data={statesOptions}
              />
            </li>
            <li>
              <GENSelect
                value={cityIdMemo}
                onValueChange={(city) => {
                  setCityId(city);
                  setCityName(city?.label);
                }}
                data={citiesOptions}
                disabled={!isSuccessStates}
              />
            </li>
          </ul>
        </div>
        <div>
          <span>ANO</span>
          <ul>
            <li>
              <S.Button
                isActive={selectedCycle.value === ""}
                onClick={() => {
                  setSelectedCycle({ label: "TODOS", value: "" });
                  setLastCycles({ penultimate: false, last: false });
                }}
              >
                TODOS
              </S.Button>
            </li>
            {years
              .slice(0, 2)
              .sort((a, b) => b.value - a.value)
              .map((year, index) => (
                <li key={year.value}>
                  <S.Button
                    isActive={
                      index === 0 ? lastCycles?.penultimate : lastCycles?.last
                    }
                    onClick={() => {
                      setLastCycles(() => {
                        if (index === 0) {
                          return { penultimate: true, last: false };
                        } else {
                          return { penultimate: false, last: true };
                        }
                      });

                      setSelectedCycle(year);
                    }}
                  >
                    {year.value}
                  </S.Button>
                </li>
              ))}
            <li>
              <GENSelect
                placeholder="ANOS ANTERIORES"
                value={
                  othersCycles.some(
                    (option) => option.value === selectedCycle?.value
                  )
                    ? selectedCycle
                    : { label: "ANOS ANTERIORES", value: "" }
                }
                onValueChange={(year) => {
                  if (year?.label === "ANOS ANTERIORES") {
                    setSelectedCycle({ label: "TODOS", value: "" });
                  } else {
                    setSelectedCycle(year);
                  }
                  setLastCycles({ penultimate: false, last: false });
                }}
                data={othersCycles}
              />
            </li>
          </ul>
        </div>
      </div>
    </S.Wrapper>
  );
}

export default GeneralFilter;
