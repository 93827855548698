import React, { useEffect } from "react";
import Helmet from "react-helmet";
import * as S from "./styles";
import image90 from "assets/images/plans/90.svg";
import SceneCompetence from "assets/images/jornada-competencia/cena_competencia.svg";
import SceneVision from "assets/images/jornada-visao/cena_visao.svg";
import SceneRed from "assets/images/jornada-red/cena_red.svg";
import SceneInfra from "assets/images/jornada-infra/cena_infra.svg";
import FourLeafClover from "shared-components/FourLeafClover";
import Layout from "shared-components/Layout";
import Goal from "../Goal";
import { useGoalActionsTable } from "hooks/use-goal-actions-table";
import { CircularProgress } from "@mui/material";
import DimensionsSections from "../DimensionsSections";
import { useResultPerDimensionQuery } from "services/common";
import { useNavigate } from "react-router-dom";
import image10 from "assets/images/plans/10.svg";

import ReactTooltip from "react-tooltip";
import NetworkDataTable from "../NetworkDataTable";
import { useForm } from "react-hook-form";
import { getStatus } from "utils/plans";

const DIMENSIONS = {
  vision: {
    id: 0,
    scene: SceneVision,
    title: "VISÃO",
    name: "visao",
    goal_tooltip: (
      <p>
        Os objetivos específicos correspondem aos resultados
        <br /> concretos que o Plano pretende alcançar e contribui
        <br /> para o objetivo geral previamente definido.
        <br /> Exemplo: Orientar as escolas sobre o uso de tecnologia
        <br /> para aprendizagem.
      </p>
    ),
  },
  competence: {
    id: 1,
    scene: SceneCompetence,
    title: "COMPETÊNCIA",
    name: "competence",
    goal_tooltip: (
      <p>
        Os objetivos específicos correspondem aos resultados
        <br /> concretos que o Plano pretende alcançar e <br />
        contribui para o objetivo geral previamente definido. <br />
        Exemplo: Aprimorar as competências digitais dos docentes do
        <br /> Ensino Médio da rede.
      </p>
    ),
  },
  red: {
    id: 2,
    scene: SceneRed,
    title: "RECURSOS EDUCACIONAIS DIGITAIS",
    name: "crd",
    goal_tooltip: (
      <p>
        Os objetivos específicos correspondem aos resultados
        <br /> concretos que o Plano pretende alcançar e <br />
        contribui para o objetivo geral previamente definido.
        <br /> Exemplo: Adquirir RED de práticas pedagógicas inovadoras.
      </p>
    ),
  },
  infra: {
    id: 3,
    scene: SceneInfra,
    title: "INFRAESTRUTURA",
    name: "infra",
    goal_tooltip: (
      <p>
        Os objetivos específicos correspondem aos resultados <br />
        concretos que o Plano pretende alcançar e contribui <br />
        para o objetivo geral previamente definido. <br />
        Exemplo: Dimensionar investimento e quantidade de <br />
        equipamentos de infraestrutura a serem adquiridos para <br />
        as escolas do Ensino Médio.
      </p>
    ),
  },
};

export default function Plan({
  dimension,
  children,
  previous,
  next,
  infraParams,
}) {
  const navigate = useNavigate();
  const {
    addGoal,
    saveGoalDescription,
    institutionPlansLoading,
    institutionLoading,
    getCurrentPlan,
    saveInfraParams,
    institution,
    institutionSuccess,
  } = useGoalActionsTable(dimension);

  const {
    register,
    getValues,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm();

  const { data: resultPerDimension, isLoading: resultPerDimensionLoading } =
    useResultPerDimensionQuery(
      getCurrentPlan()?.setting_plan?.survey_id,
      getCurrentPlan()?.setting_plan?.survey_schedule_id
    );

  useEffect(() => {
    if (
      getStatus(
        getCurrentPlan()?.setting_plan?.validity?.start,
        getCurrentPlan()?.setting_plan?.validity?.end
      ) === "concluded" ||
      institution?.plans?.length === 0
    ) {
      navigate("/planos");
    }

    if (
      !getCurrentPlan()?.setting_plan?.survey_id ||
      !getCurrentPlan()?.setting_plan?.survey_schedule_id
    ) {
      navigate("/planos/dados-da-rede");
    }

    if (institutionSuccess) {
      const currentPlan = getCurrentPlan();
      const network = currentPlan?.general_data?.network;
      setValue("network", network);
    }
  }, [getCurrentPlan, navigate, institution, setValue, institutionSuccess]);

  if (institutionLoading || resultPerDimensionLoading) {
    return (
      <Layout hasNavigationBar name="plans">
        <Helmet
          title={`Guia EduTec | Planos - ${DIMENSIONS[dimension]?.title}`}
        />
        <S.Wrapper>
          <S.BannerWrapper dimension={dimension}>
            <S.Banner dimension={dimension} />
          </S.BannerWrapper>
          <S.Loading>
            <CircularProgress size={30} />
          </S.Loading>
        </S.Wrapper>
      </Layout>
    );
  }

  return (
    <Layout hasNavigationBar name="plans">
      <Helmet
        title={`Guia EduTec | Planos - ${DIMENSIONS[dimension]?.title}`}
      />
      <S.Wrapper>
        <S.BannerWrapper dimension={dimension}>
          <S.Banner dimension={dimension} />
        </S.BannerWrapper>
        <S.Content>
          <S.DimensionsSections>
            <S.DescriptionWrapper dimension={dimension}>
              <h2>
                Para o planejamento dos objetivos e ações da dimensão{" "}
                <span>{DIMENSIONS[dimension]?.title}</span>, revisite abaixo os
                dados do Relatório
                <br />
                Diagnóstico da sua rede sobre uso de tecnologias.
              </h2>
            </S.DescriptionWrapper>

            <DimensionsSections
              dimension={dimension}
              dimensionData={
                resultPerDimension &&
                resultPerDimension[DIMENSIONS[dimension]?.id]
              }
            />
          </S.DimensionsSections>

          {children && (
            <S.InfraCalculatorSection>{children}</S.InfraCalculatorSection>
          )}

          <S.NetworkData>
            <S.TitleWrapper>
              <img src={image10} alt="Dados da Rede" />
              <S.Title>Dados da Rede</S.Title>
            </S.TitleWrapper>

            {institutionLoading ? (
              <S.CircularProgressWrapper>
                <CircularProgress size={30} />
              </S.CircularProgressWrapper>
            ) : (
              <>
                <NetworkDataTable
                  register={register}
                  getValues={getValues}
                  title="TOTAL"
                  type="total"
                  isEditable={true}
                  setValue={setValue}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                  disabled
                />
                <NetworkDataTable
                  register={register}
                  getValues={getValues}
                  title="CONTEMPLADO"
                  type="contemp"
                  isEditable={true}
                  setValue={setValue}
                  setError={setError}
                  errors={errors}
                  clearErrors={clearErrors}
                  disabled
                />
              </>
            )}
          </S.NetworkData>

          {/* AAA */}
          <S.SpecificGoals>
            <div>
              <S.TitleWrapper>
                <img
                  src={image90}
                  alt="Objetivos específicos para a dimensão Visão"
                />
                <div>
                  <S.Title>
                    Objetivos específicos para a dimensão{" "}
                    {DIMENSIONS[dimension]?.title}:
                  </S.Title>
                </div>

                <S.StyledHelpIcon data-tip="" data-for={`goal_${dimension}`} />
                <ReactTooltip
                  effect="solid"
                  id={`goal_${dimension}`}
                  aria-haspopup="true"
                >
                  {DIMENSIONS[dimension]?.goal_tooltip}
                </ReactTooltip>
              </S.TitleWrapper>

              <S.Goals>
                <Goal dimension={dimension} />
              </S.Goals>

              <S.NewGoalWrapper>
                <S.Button onClick={addGoal}>Novo Objetivo</S.Button>
              </S.NewGoalWrapper>
            </div>
          </S.SpecificGoals>
          <S.ButtonWrapper>
            <button
              onClick={async () => {
                await saveGoalDescription();
                if (dimension === "infra") {
                  await saveInfraParams(infraParams);
                }
                navigate(previous, { replace: true });
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "VOLTAR"
              )}
            </button>
            <button
              onClick={async () => {
                await saveGoalDescription();
                if (dimension === "infra") {
                  await saveInfraParams(infraParams);
                }
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "SALVAR"
              )}
            </button>
            <button
              onClick={async () => {
                await saveGoalDescription();
                if (dimension === "infra") {
                  await saveInfraParams(infraParams);
                }
                navigate(next, { replace: true });
              }}
            >
              {institutionPlansLoading ? (
                <CircularProgress size={20} />
              ) : (
                "AVANÇAR"
              )}
            </button>
          </S.ButtonWrapper>
          <S.JourneySection>
            <div>
              <div>
                <img src={DIMENSIONS[dimension]?.scene} alt="" />
              </div>
              <div>
                <FourLeafClover width={120} dimension={dimension} />
                <h1>
                  Conheça a Jornada completa da dimensão <br />
                  {DIMENSIONS[dimension]?.title}
                </h1>
                <p>
                  Confira conceitos, ferramentas e dicas para desenvolver
                  Escolas Conectadas.
                </p>
              </div>
            </div>
          </S.JourneySection>
        </S.Content>
      </S.Wrapper>
    </Layout>
  );
}
