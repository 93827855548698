import React, { useCallback, useEffect, useState } from "react";
import classNames from "classnames";

import * as S from "./styles";

import axios from "axios";
import image011 from "assets/images/calculadora/011.svg";
import image014 from "assets/images/calculadora/014.svg";
import image033 from "assets/images/calculadora/033.svg";

import image015 from "assets/images/calculadora/015.svg";

import image016 from "assets/images/calculadora/016.svg";
import school from "assets/images/calculadora/escola.svg";

import { getStorageAuthenticityToken } from "utils/local-storage";
import Layout from "shared-components/Layout";
import InfraCalculatorFilter from "pages/InfrastructureCalculator/components/InfraCalculatorFilter";
import { useInfraCalculatorFilter } from "hooks/use-infra-calculator-filter";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";

import Modal from "../../components/Modal";

import carrinho from "assets/images/calculadora/valores-de-referencia/devices/carrinho.jpg";
import computer from "assets/images/calculadora/valores-de-referencia/devices/computer.jpg";
import firewall from "assets/images/calculadora/valores-de-referencia/devices/firewall.jpg";
import nobreak from "assets/images/calculadora/valores-de-referencia/devices/nobreak.jpg";
import notebooks from "assets/images/calculadora/valores-de-referencia/devices/notebooks.jpg";
import printers from "assets/images/calculadora/valores-de-referencia/devices/printers.jpg";
import projector from "assets/images/calculadora/valores-de-referencia/devices/projector.jpg";
import rack from "assets/images/calculadora/valores-de-referencia/devices/rack.jpg";
import switchDevice from "assets/images/calculadora/valores-de-referencia/devices/switch.jpg";
import wifi from "assets/images/calculadora/valores-de-referencia/devices/wifi.jpg";

const LABELS = {
  access_point: {
    name: (
      <p>
        Access Point <br />
        <span>(Compõe o mínimo recomendado)</span>
      </p>
    ),
    image: wifi,
  },
  charger: { name: <p>Carregadores</p>, image: carrinho },
  comp_admins: {
    name: <p>Computador para uso administrativo</p>,
    image: computer,
  },
  comp_students: {
    name: (
      <p>
        Computadores para estudantes
        <br />
        <span>(Compõe o mínimo recomendado)</span>
      </p>
    ),
    image: notebooks,
  },
  comp_teachers: {
    name: <p>Computadores para professores (sala de aula)</p>,
    image: computer,
  },
  projector: { name: <p>Projetor ou TV</p>, image: projector },
  rack: { name: <p>Rack</p>, image: rack },
  firewall: { name: <p>Firewall</p>, image: firewall },
  nobreak: { name: <p>Nobreaks</p>, image: nobreak },
  printers: { name: <p>Impressora multifuncional</p>, image: printers },
  switch: {
    name: (
      <p>
        Switch L3 (16,24 ou 48 portas)
        <br />
        <span>(Compõe o mínimo recomendado)</span>
      </p>
    ),
    image: switchDevice,
  },
};

const CONF = process.env.REACT_APP_BASE_URL;

const MIN_TOTAL_MODAL_DATA = {
  min: {
    title: "Valor Mínimo",
    description: (
      <p>
        Os valores mínimos consideram apenas os{" "}
        <span>
          equipamentos mínimos para a garantia da conectividade e uso frequente
          dos estudantes
        </span>{" "}
        (dispositivos, access points e switch).
      </p>
    ),
  },
  total: {
    title: "Valor Total",
    description: (
      <p>
        Os valores totais consideram a compra de todos os equipamentos listados.
      </p>
    ),
  },
  recomendado: {
    title: "Nível recomendado",
    description: (
      <p>
        O parâmetro recomendado pelo CIEB corresponde ao nível intermediário, em que a conectividade de internet está presente em todos os ambientes escolares e viabiliza o uso frequente e simultâneo de dispositivos (computadores, notebooks tablets e outros) e equipamentos (impressoras, lousas interativas e outros) por alunos, docentes e gestores.
      </p>
    ),
  },
};

function InfraCalculatorFeedback() {
  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState();

  const {
    stageSelected,
    setStageSelected,
    setLastCycles,
    setSelectedCycle,
    selectedCycle,
  } = useInfraCalculatorFilter();
  const [data, setData] = useState({ distribution: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [level, setLevel] = useState({ value: "3", label: "Intermediário" });
  const [total_investiment, setTotalInvestiment] = useState([]);
  const [cicles, setCicles] = useState([]);
  const [levels, setLevels] = useState([]);
  const [table, setTable] = useState(0);
  const [network_level, setNetworkLevel] = useState(1);
  const [schools, setSchools] = useState([]);
  const [haveInventory, setHaveInventory] = useState(0);
  const [equipaments, setEquipaments] = useState({
    comp_teachers: 0,
    comp_admins: 0,
    comp_students: 0,
    printers: 0,
    rack: 0,
    nobreak: 0,
    switch: 0,
    firewall: 0,
    wifi: 0,
    projector: 0,
    charger: 0,
    maker: 0,
  });

  const navigate = useNavigate();

  const sumEquipaments = useCallback(
    (data) => {
      const equipaments = {
        comp_teachers: 0,
        comp_admins: 0,
        comp_students: 0,
        printers: 0,
        rack: 0,
        nobreak: 0,
        switch: 0,
        firewall: 0,
        wifi: 0,
        projector: 0,
        charger: 0,
        maker: 0,
      };

      data.forEach((school) => {
        if (!school.inventory || !school.inventory[selectedCycle.value]) return;
        Object.entries(school.inventory[selectedCycle.value]).forEach(
          ([key, value]) => {
            if (typeof value === "number") {
              equipaments[key] = equipaments[key] + value;
            }
          }
        );
      });

      setEquipaments(equipaments);
    },
    [selectedCycle.value]
  );

  const countSchoolsWithInventory = useCallback(
    (schools) => {
      const data = [];
      let count = 0;
      for (let school of schools) {
        if (
          school.inventory &&
          school.inventory[selectedCycle.value] &&
          selectedCycle.value
        ) {
          count += 1;
        }
      }
      setHaveInventory(count);
      return data;
    },
    [selectedCycle.value]
  );

  const getSchools = useCallback(async () => {
    try {
      const params = {
        access_token: getStorageAuthenticityToken(),
        stage: stageSelected,
      };
      const response = await axios.get(CONF + "/api/v1/calc_infra_schools", {
        params,
      });
      sumEquipaments(response.data);
      countSchoolsWithInventory(response.data);
      setSchools(response.data);
    } catch (error) {
      console.log(error.message);
    }
  }, [countSchoolsWithInventory, stageSelected, sumEquipaments]);

  const getSurveysList = useCallback(async () => {
    try {
      const params = { access_token: getStorageAuthenticityToken() };
      const response = await axios.get(CONF + "/api/v1/survey/surveys_list", {
        params,
      });
      const { surveys } = response.data;
      const filteredSurvey = surveys.find((survey) => survey.type === "school");
      let cicles = filteredSurvey.schedule.filter(
        (cicle) => cicle.name >= 2021
      );

      cicles = cicles.map((cicle) => ({
        value: cicle.id.$oid,
        label: cicle.name,
        survey_id: cicle.survey_id.$oid,
      }));

      let cicle = cicles[0];
      if (selectedCycle.value) {
        cicle = cicles.find((cicle) => cicle.value === selectedCycle.value);
      }
      setLastCycles({ last: false, penultimate: true });
      setSelectedCycle(cicle);
      setCicles(cicles);
    } catch (error) {
      console.log(error.message);
    }
  }, [selectedCycle?.value, setLastCycles, setSelectedCycle]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const level = params.get("level");
    const stage = params.get("stage");
    const schedule_id = params.get("schedule_id");

    level && setLevel(levels.find((l) => l.value === level));
    schedule_id && setSelectedCycle({ label: schedule_id, value: schedule_id });
    stage && setStageSelected(stage);
  }, [levels, getSurveysList, setSelectedCycle, setStageSelected]);

  useEffect(() => {
    if (cicles.length === 0) {
      getSurveysList();
    }
  }, [level, stageSelected, selectedCycle?.value, getSurveysList, cicles]);

  const currency = (val) => {
    return val
      ? val.toLocaleString("pt-BR", { style: "currency", currency: "BRL" })
      : "0";
  };

  const changeElementPosition = (array, positions) => {
    positions.forEach(({ to, from }) => {
      array.splice(to, 0, array.splice(from, 1)[0]);
    });
    return array;
  };

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    setData({ distribution: [] });
    setLevels([]);
    setError(false);

    const params = {
      survey_schedule_id: selectedCycle?.value,
      access_token: getStorageAuthenticityToken(),
    };

    if (stageSelected !== "") {
      params["stage"] = stageSelected;
    }

    try {
      const response = await axios.get(CONF + "/api/v1/calc_infra_dashboard", {
        params,
      });

      const total_investiment = [];
      response.data.data.forEach((item) => {
        const total = Object.values(item.total).reduce(
          (a, b) => a + b.price,
          0
        );
        total_investiment.push(total);
      });

      const network_level = response.data.levels.sort(
        (a, b) => b.amount - a.amount
      )[0].level;
      setIsLoading(false);
      setNetworkLevel(network_level);
      setLevels(response.data.levels.sort((a, b) => a.level - b.level));
      setData(response.data.data);
      setTotalInvestiment(total_investiment);
    } catch (error) {
      setError(true);
    }
  }, [selectedCycle?.value, stageSelected]);

  useEffect(() => {
    fetchData();
    getSchools();
  }, [selectedCycle?.value, fetchData, stageSelected, getSchools]);

  return (
    <Layout hasNavigationBar name="infraCalculator">
      <S.Wrapper>
        <header />

        <div className={"content"}>
          <article>
            <div>
              <p>
                Na dimensão de infraestrutura, é fundamental garantir que
                estudantes e professores consigam utilizar os equipamentos
                cotidianamente. Para isso, recomenda-se que a secretaria de
                educação priorize os investimentos para que todas as escolas da
                rede alcancem, no mínimo, o nível intermediário.
              </p>
              <p>
                Na prática, isso significa que as escolas terão pelo menos{" "}
                <strong>
                  1 dispositivo (notebook, cloudbook ou tablet) para cada 5
                  estudantes*
                </strong>{" "}
                e 1 access point para cada duas salas de aula.
              </p>
            </div>
            <div>
              <div>
                <img src={school} alt="" />
                <h1>
                  DADOS DO DIAGNÓSTICO DE ADOÇÃO DE TECNOLOGIAS DAS ESCOLAS
                </h1>
              </div>
              <div>
                <div>
                  <div>
                    <p>
                      <span>{haveInventory}</span>
                      <span>/{schools?.length}</span> ESCOLAS DA SUA REDE
                    </p>
                  </div>
                  <hr />
                  <div>
                    <p>
                      <span>responderam</span> o inventário de infraestrutura do
                      diagnóstico do Guia Edutec 2022
                    </p>
                  </div>
                </div>
                <div>
                  <img src={image011} alt="" />
                </div>
              </div>
            </div>
            <div className={classNames("filter")}>
              <p>
                Selecione as opções abaixo para calcular o investimento
                necessário para avançar nos parâmetros da dimensão de
                infraestrutura:
              </p>
              {cicles && <InfraCalculatorFilter cycles={cicles} />}
            </div>
            {!error ? (
              <>
                {isLoading ? (
                  <div className="loading">
                    <CircularProgress size={40} />
                  </div>
                ) : (
                  <div>
                    {data[0] ? (
                      <div>
                        <div className={classNames("boxes")}>
                          <div>
                            <div>
                              <p>Mais de 8 estudantes por dispositivo</p>
                            </div>
                            <div>
                              <p>
                                {levels[0].amount} escolas da sua rede estão
                                aqui no nível emergente
                              </p>
                            </div>
                            <div></div>
                            <div></div>
                            <div>EMERGENTE</div>
                          </div>
                          <div
                            style={
                              table === 0
                                ? {
                                    border: "5px solid #87c249"
                                  }
                                : {}
                            }
                            onClick={(event) => {
                              if (event.target.nodeName === "BUTTON") return;
                              setTable(0);
                              const anchor = document.getElementById('table_area');
                              anchor.scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              });
                            }}
                          >
                            <div>
                              <p>
                                Uso pelos estudantes 1 vez
                                por semana
                              </p>

                              <h1>
                                Até <span>8 estudantes </span>
                                <br /> por dispositivo
                              </h1>
                            </div>

                            <div>
                              <p>
                                {currency(
                                  data[0].total.access_point.price +
                                    data[0].total.comp_students.price +
                                    data[0].total.switch.price
                                )}{" "}
                                <span>Mínimo </span>
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("min");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                              <p>
                                {currency(total_investiment[0])}{" "}
                                <span>Total</span>{" "}
                                <button
                                  onClick={(event) => {
                                    setShowModal(true);
                                    setModalData("total");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                            </div>

                            <div>
                              <div>
                                <img src={image014} alt="" />
                                <p>
                                  {levels[1].amount} escolas da sua <br />
                                  rede estão aqui no nível básico
                                </p>
                              </div>
                              <hr />
                              <div>
                                <img src={image015} alt="" />
                                <p>
                                  {levels[0].amount} escolas{" "}
                                  <strong>chegarão nesse parâmetro</strong> com
                                  o investimento mínimo
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                style={
                                  table === 0
                                    ? {
                                        color: "#131d3c",
                                        border: "3px solid #131d3c",
                                        backgroundColor: "white",
                                      }
                                    : {
                                        backgroundColor: "#131d3c",
                                        color: "white",
                                        border: "3px solid #131d3c",
                                      }
                                }
                                onClick={() => setTable(0)}
                                href="#table_area"
                              >
                                Ver lista de <br />
                                equipamentos{" "}
                                {table === 0 ? (
                                  <i className="fa fa-times" />
                                ) : (
                                  <i className="fa fa-plus" />
                                )}
                              </p>
                            </div>
                            <div>BÁSICO</div>
                          </div>
                          <div
                            style={
                              network_level === 2
                                ? {
                                    border: "4px solid #99ce5f",
                                  }
                                : table === 1
                                ? {
                                    border: "5px solid #87c249"
                                  }
                                : {}
                            }
                            onClick={(event) => {
                              if (event.target.nodeName === "BUTTON") return;
                              setTable(1);
                              const anchor = document.getElementById('table_area');
                              anchor.scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              });
                            }}
                          >
                            <div>
                              <div className={classNames("destaque")}>
                                <img src={image033} alt="" />
                                <p>Nível recomendado</p>
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("recomendado");
                                  }}
                                >
                                  ?
                                </button>
                              </div>
                              <p>
                                Uso pelos estudantes de 2 a 3
                                vezes por semana
                              </p>

                              <h1>
                                Até <span>5 estudantes </span>
                                <br /> por dispositivo
                              </h1>
                            </div>

                            <div>
                              <p>
                                {currency(
                                  data[1].total.access_point.price +
                                    data[1].total.comp_students.price +
                                    data[1].total.switch.price
                                )}{" "}
                                <span>Mínimo</span>{" "}
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("min");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                              <p>
                                {currency(total_investiment[1])}{" "}
                                <span>Total</span>{" "}
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("total");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                            </div>

                            <div>
                              <div>
                                <img src={image014} alt="" />
                                <p>
                                  {levels[2].amount} escolas da sua <br />
                                  rede estão aqui no nível intermediário
                                </p>
                              </div>
                              <hr />
                              <div>
                                <img src={image015} alt="" />
                                <p>
                                  {levels[0].amount + levels[1].amount} escolas{" "}
                                  <strong>chegarão nesse parâmetro</strong> com
                                  o investimento mínimo
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                style={
                                  table === 1
                                    ? {
                                        color: "#131d3c",
                                        border: "3px solid #131d3c",
                                        backgroundColor: "white",
                                      }
                                    : {
                                        backgroundColor: "#131d3c",
                                        color: "white",
                                        border: "3px solid #131d3c",
                                      }
                                }
                              >
                                Ver lista de
                                <br /> equipamentos{" "}
                                {table === 1 ? (
                                  <i className="fa fa-times" />
                                ) : (
                                  <i className="fa fa-plus" />
                                )}
                              </p>
                            </div>
                            <div>INTERMEDIÁRIO</div>
                          </div>
                          <div
                            style={
                              table === 2
                                ? {
                                    border: "5px solid #87c249"
                                  }
                                : {}
                            }
                            onClick={(event) => {
                              if (event.target.nodeName === "BUTTON") return;
                              setTable(2);
                              const anchor = document.getElementById('table_area');
                              anchor.scrollIntoView({
                                block: 'start',
                                behavior: 'smooth'
                              });
                            }}
                          >
                            <div>
                              <p>
                                Uso pelos estudantes em
                                todos os dias da semana
                              </p>

                              <h1>
                                Até <span>2 estudantes </span>
                                <br /> por dispositivo
                              </h1>
                            </div>

                            <div>
                              <p>
                                {currency(
                                  data[2]?.total.access_point.price +
                                    data[2]?.total.comp_students.price +
                                    data[2]?.total.switch.price
                                )}{" "}
                                <span>Mínimo</span>{" "}
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("min");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                              <p>
                                {currency(total_investiment[2])}{" "}
                                <span>Total</span>{" "}
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setModalData("total");
                                  }}
                                >
                                  ?
                                </button>
                              </p>
                            </div>

                            <div>
                              <div>
                                <img src={image014} alt="" />
                                <p>
                                  {levels[3].amount} escolas da sua <br />
                                  rede estão aqui no nível avançado
                                </p>
                              </div>
                              <hr />
                              <div>
                                <img src={image015} alt="" />
                                <p>
                                  {levels[0].amount +
                                    levels[1].amount +
                                    levels[2].amount}{" "}
                                  escolas{" "}
                                  <strong>chegarão nesse parâmetro</strong> com
                                  o investimento mínimo
                                </p>
                              </div>
                            </div>
                            <div>
                              <p
                                style={
                                  table === 2
                                    ? {
                                        color: "#131d3c",
                                        border: "3px solid #131d3c",
                                        backgroundColor: "white",
                                      }
                                    : {
                                        backgroundColor: "#131d3c",
                                        border: "3px solid #131d3c",
                                        color: "white",
                                      }
                                }
                                onClick={() => setTable(2)}
                                href="#table_area"
                              >
                                Ver lista de
                                <br /> equipamentos{" "}
                                {table === 2 ? (
                                  <i className="fa fa-times" />
                                ) : (
                                  <i className="fa fa-plus" />
                                )}
                              </p>
                            </div>
                            <div>AVANÇADO</div>
                          </div>
                        </div>

                        {/* TABELA */}

                        {isLoading ? (
                          <div className={"loading"}>
                            <CircularProgress size={30} />
                          </div>
                        ) : (
                          <div id="table_area">
                            <div>
                              <div>
                                <div className={classNames("column", "table")}>
                                  <table>
                                    <thead>
                                      <tr>
                                        <th>EQUIPAMENTOS</th>
                                        <th>EXISTENTE NA REDE</th>
                                        <th>NECESSÁRIO ADQUIRIR</th>
                                        <th>VALOR TOTAL</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {data[table] &&
                                        changeElementPosition(
                                          Object.entries(data[table].total),
                                          [
                                            { to: 1, from: 2 },
                                            { to: 2, from: 8 },
                                          ]
                                        ).map(([key, values], index) => (
                                          <tr key={index}>
                                            <td>
                                              <div>
                                                <img
                                                  src={LABELS[key].image}
                                                  alt=""
                                                />{" "}
                                                {LABELS[key].name}
                                              </div>
                                            </td>
                                            <td>
                                              {key === "access_point"
                                                ? equipaments["wifi"]
                                                : equipaments[key]}
                                            </td>
                                            <td>{values.amount}</td>
                                            <td
                                              className={
                                                index % 2 === 0
                                                  ? "purple_after"
                                                  : "green_after"
                                              }
                                            >
                                              {currency(values.price)}
                                            </td>
                                          </tr>
                                        ))}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className={classNames("fallback")}>
                        <p>
                          Não há dados disponíveis para os filtros selecionados
                        </p>
                      </div>
                    )}
                  </div>
                )}
                <div>
                  <h1>Baixar dados de equipamentos</h1>
                  <p>
                    Faça o download de uma planilha com os dados dos equipamentos em
                    cada nível.
                  </p>
                  <a
                    download
                    href={
                      CONF +
                      `/api/v1/calc_infra_export_dashboard_data?access_token=${getStorageAuthenticityToken()}&stage=${
                        stageSelected || ""
                      }&survey_schedule_id=${selectedCycle.value}`
                    }
                  >
                    Baixar
                  </a>
                </div>
                <div>
                  <p>
                    Obs.: Todos os itens listado na calculadora são importantes
                    para garantir uma infraestrutura adequada para as escolas, a
                    recomendação acima é que os dispositivos dos estudantes e os
                    pontos de acesso sejam priorizados no planejamento da rede.
                  </p>
                </div>
                <div>
                  <div>
                    <h1>
                      Como distribuir os
                      <br /> equipamentos nas escolas?
                    </h1>
                    <button
                      onClick={() =>
                        navigate(
                          `/calculadora-de-infraestrutura/distribuicao?stage=${stageSelected}&schedule_id=${
                            selectedCycle.value
                          }&level=${table + 1}`
                        )
                      }
                    >
                      VISUALIZAR DISTRIBUIÇÃO
                    </button>
                  </div>
                  <img src={image016} alt="" />
                </div>
              </>
            ) : (
              <S.ErrorMessage>
                <S.ReportProblem />
                <p>Um erro inesperado aconteceu !</p>
                <p>
                  Caso o erro continue entre em contato com o email:{" "}
                  <a
                    href="mailto:guiaedutec@cieb.net.br"
                    target="_blank"
                    rel="noreferrer"
                  >
                    guiaedutec@cieb.net.br
                  </a>
                </p>
              </S.ErrorMessage>
            )}
          </article>
        </div>

        <Modal open={showModal} onClose={setShowModal}>
          <S.MinTotalModal>
            <div>
              <h2>{MIN_TOTAL_MODAL_DATA[modalData]?.title}</h2>
            </div>
            <div>{MIN_TOTAL_MODAL_DATA[modalData]?.description}</div>
          </S.MinTotalModal>
        </Modal>
      </S.Wrapper>
    </Layout>
  );
}

export default InfraCalculatorFeedback;
