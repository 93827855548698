import styled from "styled-components";



export const Wrapper = styled.section`
  > img {
    width: 100%;
    height: auto;
    margin: 1rem 0rem;
  }

  > section {
    display: flex;
    > img {
      flex: 0.5;
      width: 10rem;
      margin: 1rem 0rem;
    }
  }
`;
