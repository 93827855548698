import styled from "styled-components";
import CloseIcon from "@mui/icons-material/Close";
import ReactModal from "react-modal";

export const StyledCloseIcon = styled(CloseIcon)`
  cursor: pointer;
  &&& {
    font-size: 3.5rem;
  }
`;
export const StyledModal = styled(ReactModal)`
  &&& {
    font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    > header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: ${({ theme }) => theme.colors.blue5};
      > h1 {
        font-size: 2.2rem;
        font-weight: 500;
      }

      > button {
        border: none;
        background-color: ${({ theme }) => theme.colors.white};
      }
    }
  }
`;
