import styled, { css } from "styled-components";
import GroupsIcon from "@mui/icons-material/Groups";
// import { container } from "global-styles/shared-styles";
import YouTubeIcon from "@mui/icons-material/YouTube";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import ShareIcon from "@mui/icons-material/Share";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import BarChartIcon from "@mui/icons-material/BarChart";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { ReactComponent as School } from "assets/images/ge-in-numbers/escola.svg";
import image83a from "assets/images/ge-in-numbers/083a.png";
import { LinkIcon } from "@heroicons/react/outline";
import media from "styled-media-query";

export const StyledSchoolIcon = styled(School)`
  .cls-3 {
    fill: ${({ theme }) => theme.colors.white};
  }
  width: 4rem;
  height: 4rem;
  stroke: ${({ theme }) => theme.colors.black};
  fill: ${({ theme }) => theme.colors.black};
`;

export const StyledBarChartIcon = styled(BarChartIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;

export const StyledCalendarMonthIcon = styled(CalendarMonthIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;

export const StyledReportProblemIcon = styled(ReportProblemIcon)`
  &&& {
    font-size: 2.5rem;
    color: rgb(255, 176, 46);
    margin-right: 1rem;
  }
`;

const icons = css`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
    color: white;
    background-color: ${({ theme }) => theme.colors.black};
    border-radius: 50%;
    padding: 0.5rem;
  }
`;

export const StyledAddIcon = styled(AddIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledRemoveIcon = styled(RemoveIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    font-size: 2rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const StyledCloseIcon = styled(CloseIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2rem;
  }
`;

export const StyledShareIcon = styled(ShareIcon)`
  &&& {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.black};
    font-size: 2rem;
  }
`;

export const StyledYouTubeIcon = styled(YouTubeIcon)`
  ${icons};
`;

export const StyledFacebookIcon = styled(FacebookIcon)`
  ${icons};
`;

export const StyledLinkedInIcon = styled(LinkedInIcon)`
  ${icons};
`;

export const StyledInstagramIcon = styled(InstagramIcon)`
  ${icons};
`;

export const StyledTwitterIcon = styled(TwitterIcon)`
  ${icons};
`;

export const StyledGroupsIcon = styled(GroupsIcon)`
  &&& {
    cursor: pointer;
    font-size: 3.5rem;
  }
`;

export const Wrapper = styled.div`
  margin-top: 7rem;
  margin-right: 1.7rem;

  ${media.lessThan("large")`
    margin-right: 0rem;
  `}
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
`;

export const BannerWrapper = styled.div`
  background-color: #f0f0f0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Banner = styled.header`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 1.75rem 5.78rem 0rem 5.78rem;
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  > div:nth-child(2) {
    flex: 1 1 60rem;
    width: 100%;
    > img {
      width: 100%;
      max-width: 90rem;
      object-fit: contain;
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 2rem 4rem 2rem !important;
  background-color: #f0f0f0;
  position: relative;

  > section {
    padding: 2rem 2rem 4rem 4rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);
  }

  > div:nth-child(2) {
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);
    background-color: rgb(219, 234, 254);
    color: rgb(37, 99, 235);
    padding: 2.5rem;
    /* margin-bottom: 18px; */
    border-radius: 1rem;
    text-align: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 1rem;

      > h1 {
        font-size: 1.8rem;
        font-weight: bold;
      }
    }

    > p {
      font-size: 1.5rem;
      > a {
        text-decoration: none;
        color: rgb(37, 99, 235);
        font-weight: 400;
      }
    }
  }

  > section:nth-child(3) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 2rem;
    padding: 0;
    box-shadow: none;
    background-color: transparent;

    > div {
      display: flex;
      flex-direction: column;
      flex: 1 1;
      border-radius: 1rem;
      padding: 2rem 3rem 4rem 3rem;
      background-color: ${({ theme }) => theme.colors.gray10};
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
        0 1rem 2.5rem rgba(0, 0, 0, 0.05);
      min-height: 44rem;
    }

    > div:nth-child(1) {
      display: flex;
      flex-direction: column;
      > main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.colors.blue5};
        padding: 4rem 2rem;
        flex: 1 1 0%;
        gap: 1rem;
        font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;

        > div:nth-child(1) {
          align-self: flex-start;
          width: fit-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 1rem;
          > span {
            font-size: 7.5rem;
            font-weight: 600;
          }
        }

        > div:nth-child(2) {
          > p {
            align-self: center;
            font-size: 2.77rem;
            font-weight: 400;

            > div {
              display: inline-block;
              justify-content: center;
              align-items: center;
              background-color: ${({ theme }) => theme.colors.black};
              width: 2.5rem;
              height: 2.5rem;
              min-width: 2.5rem;
              min-height: 2.5rem;
              border-radius: 50%;
              position: relative;
            }
          }
        }
      }
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      > header {
        margin-bottom: 3rem;
      }
      > main {
        display: flex;
        flex-direction: column;
        /* display: flex;
        align-items: space-between; */
        color: ${({ theme }) => theme.colors.blue5};
        gap: 3rem;

        > div {
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 2rem;

          > p {
            font-size: 2.25rem;
            color: ${({ theme }) => theme.colors.gray9};
          }

          > span {
            font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
            font-size: 5.55rem;
            font-weight: bold;
          }
        }

        > div:nth-child(1) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.blue6};
          }
        }

        > div:nth-child(2) {
          > span:nth-child(1) {
            color: ${({ theme }) => theme.colors.purple2};
          }
        }

        > div:nth-child(3) {
          > span:nth-child(2) {
            color: ${({ theme }) => theme.colors.green5};
          }
        }
      }
    }
  }

  > section:nth-child(4) {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem 4rem 3rem;
    gap: 3rem;
    > main {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      > p {
        font-size: 1.7rem;
        line-height: 1.5;
        color: ${({ theme }) => theme.colors.gray};
        > a {
          text-decoration: none;
          color: ${({ theme }) => theme.colors.black};
          font-weight: 600;
        }
      }
    }
  }

  > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    padding: 2rem 3rem 4rem 3rem;
    gap: 3rem;

    > main {
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }
  }

  > section:nth-child(6) {
    background-color: #e4e6f5;
    border: 1px solid #c8c8c8;
    /* box-shadow: none; */
    display: flex;
    justify-content: flex-start !important;
    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 3rem 6rem 2rem 5rem;
      flex-wrap: wrap;
      gap: 4rem;
      > div {
        flex: 1 1;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > h1 {
          font-size: 2.1rem;
          font-weight: 500;
          color: ${({ theme }) => theme.colors.blue5};
        }

        > p {
          font-weight: 500;
          color: ${({ theme }) => theme.colors.gray};
          > strong {
            font-weight: 600;
          }
        }

        > a {
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
          text-decoration: none;
          font-size: 1.5rem;
          padding: 1.4rem 3rem;
          border-radius: 1rem;
          color: ${({ theme }) => theme.colors.white};
          background-color: #1a2341;
          border: none;
          width: fit-content;
          cursor: pointer;
          margin-top: 1rem;
        }
      }

      > img {
        flex: 1 1;
        max-width: 40rem;
      }
    }
  }

  > section:last-child {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding: 0rem;

    > header {
      border-radius: 1rem 1rem 0rem 0rem;
      background-color: #e05887;
      background-image: url(${image83a});
      height: 31.1rem;
      background-position: right top;
      background-size: cover;
      background-repeat: no-repeat;
      display: flex;
      align-items: center;
      padding-inline: 10rem;

      ${media.lessThan("large")`
        padding-inline: 2rem;
      `}

      > img {
        width: 48rem;

        ${media.lessThan("large")`
          width: 40rem;
        `}
      }
    }

    > main {
      display: flex;
      flex-direction: column;
      gap: 4rem;

      > div:nth-child(1), > div:nth-child(4) {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        > h1 {
          padding: 4rem 8rem 0rem 8rem;
          font-size: 2.1rem;
          font-weight: 500;
          margin-bottom: 2.1rem;
          color: #1a2341;

          ${media.lessThan("large")`
            padding: 4rem 4rem 0rem 4rem;
          `}
        }

        > p {
          padding-inline: 8rem;
          ${media.lessThan("large")`
            padding-inline: 4rem;
          `}
          font-size: 1.65rem;
          font-weight: 400;
          line-height: 1.5;
          color: #707070;
        }
      }

      > div:nth-child(2), > div:nth-child(5) {
        padding-inline: 8rem;
        ${media.lessThan("large")`
          padding-inline: 4rem;
        `}
        > a {
          font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;
          text-decoration: none;
          font-size: 1.5rem;
          padding: 1.4rem 3rem;
          border-radius: 1rem;
          color: ${({ theme }) => theme.colors.white};
          background-color: #1a2341;
          border: none;
          width: fit-content;
          cursor: pointer;
        }
      }

      > div:nth-child(3), > div:nth-child(6) {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        padding-inline: 8rem;
        ${media.lessThan("large")`
          padding-inline: 4rem;
        `}

        > h1 {
          font-size: 1.65rem;
          font-weight: 500;
          color: #b53661;
        }

        > table {
          width: fit-content;
          box-shadow: 0rem 0.1rem 2.5rem rgba(0, 0, 0, 0.05);
          border-radius: 1rem;
          border: none;
          > thead {
            background-color: #b53661;

            > tr {
              position: relative;
              &::after {
                content: "";
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 2.4rem;
                background-color: #75233f;
              }
              > th {
                padding: 3.5rem 2rem;
                color: ${({ theme }) => theme.colors.white};
                font-weight: 500;
                text-align: left;
              }

              > th:nth-child(1) {
                border-radius: 1rem 0rem 0rem 0rem;
              }

              > th:nth-child(2) {
                border-radius: 0rem 1rem 0rem 0rem;
              }
            }
          }

          > tbody {
            font-size: 1.5rem;
            font-weight: 500;
            > tr {
              > td {
                width: 40rem;
                padding: 1.6rem 2rem;
                > a {
                  text-decoration: none;
                  font-weight: 600;
                }
              }
            }
            > tr:nth-child(odd) {
              background-color: ${({ theme }) => theme.colors.white};
              > td {
                color: #b53661;
                > a {
                  color: #b53661;
                }
              }
            }
            > tr:nth-child(even) {
              background-color: #fef8f9;
              color: ${({ theme }) => theme.colors.gray};
            }

            > tr:last-child {
              > td:nth-child(1) {
                border-radius: 0rem 0rem 0rem 1rem;
              }

              > td:nth-child(2) {
                border-radius: 0rem 0rem 1rem 0rem;
              }
            }
          }
        }
      }
    }

    > footer {
      padding: 4rem 8rem;
      ${media.lessThan("large")`
        padding: 4rem 4.5rem;
      `}

      > div {
        display: flex;
        align-items: center;
        gap: 2rem;
        margin-bottom: 0.83rem;
        > img {
          width: 2.5rem;
        }

        > h1 {
          color: #1a2341;
          font-size: 1.88rem;
          font-weight: 500;
        }
      }

      > p {
        font-size: 1.5rem;
        margin-top: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #707070;
      }
    }
  }

  /* > section:nth-child(5) {
    display: flex;
    flex-direction: column;
    gap: 3rem;
    padding-bottom: 6rem;
    > div {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      gap: 10rem;

      > input {
        border: none;
        border-bottom: 0.1rem solid #707070;
        padding: 2rem;
        flex: 1;
        max-width: 40rem;

        &::placeholder {
          color: #9a9a9a;
          font-size: 500;
        }
      }
      > button {
        font-family: ${({ theme }) =>
    theme.fonts.firaSans}, sans-serif !important;
        font-size: 1.5rem;
        padding: 0.5rem 3rem;
        border-radius: 1rem;
        color: ${({ theme }) => theme.colors.white};
        background-color: #1a2341;
        border: none;
        width: fit-content;
        cursor: pointer;
      }
    }
  } */

  > section:nth-child(6) {
    display: flex;
    justify-content: center;
    /* height: 11.3rem; */
    flex-wrap: wrap;
    /* padding: 3rem; */

    > ul {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 100%;
      list-style: none;
      align-items: center;
      gap: 9rem;
      > li {
        > a {
          text-decoration: none;
          display: flex;
          align-items: center;
          gap: 1.5rem;
          color: ${({ theme }) => theme.colors.black};

          > p {
            font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
          }

          > span {
            font-size: 1.5rem;
            font-weight: 600;
          }
        }
      }
    }
  }
`;

export const NoDataContent = styled.main`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 4rem 2rem 4rem 2rem !important;
  background-color: #f0f0f0;
  position: relative;

  > section:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3rem;
    padding: 4rem 3rem 4rem 3rem;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 1rem;
    box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.05),
      0 1rem 2.5rem rgba(0, 0, 0, 0.05);

    > img {
      width: 20rem;
    }

    > p {
      font-size: 1.9rem;
    }
  }
`;

export const SectionTitle = styled.div`
  color: ${({ theme }) => theme.colors.blue5};
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  > div {
    width: 3.6rem;
    height: 3.6rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.white};
    box-shadow: 0.1rem 0.4rem 0.8rem rgba(164, 168, 177, 0.2),
      0.1rem 0.4rem 0.8rem rgba(164, 168, 177, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    cursor: pointer;
  }
  > span {
    font-weight: 400;
    font-size: 1.8rem;
  }
  > h1 {
    font-weight: 400;
    font-size: 2.6rem;
    line-height: 1.5;
  }
`;

export const BannerTitle = styled.h1`
  font-weight: 600;
  font-size: 7rem;
  color: #313131;
  /* margin-bottom: 1.5rem; */

  > span {
    color: ${({ theme }) => theme.colors.green};
    font-size: 8rem;
  }
`;

export const InfoDataWrapper = styled.div`
  flex: 1 1 50rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;
  > p {
    font-weight: 200;
    font-size: 2.5rem;
  }

  > ul {
    list-style: none;

    > li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      gap: 1rem;
      > p {
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.5rem;
        font-weight: 500;
        color: #313131;
      }
    }
  }
`;

export const Links = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-self: flex-end;

  > ul {
    display: flex;
    gap: 1rem;
    list-style: none;
    align-self: flex-end;
    > li {
      padding: 1rem 2rem;
      cursor: pointer;
      > a {
        font-size: 1.4rem;
        text-decoration: none;
        color: #313131;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 1rem 2rem;
        border-radius: 1rem;
        transition: 0.2s ease-in;
      }
    }
  }
`;

export const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  > svg {
    width: 10rem;
  }

  > p {
    font-size: 1.6rem;
  }
`;

export const StyledLinkIcon = styled(LinkIcon)`
  width: 5rem;
`;

export const ClipboardModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  color: ${({ theme }) => theme.colors.black};
  padding: 2rem 2rem;

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f3f9f8;
    width: 9rem;
    height: 9rem;
    border-radius: 50%;
  }

  > h1 {
    font-size: 2.4rem;
    font-weight: 500;
    color: #85c440;
  }

  > p {
    font-size: 1.6rem;
    text-align: center;
  }
`;

export const TabsWrapper = styled.ul`
  display: flex;
  padding-inline: 8rem;
  gap: 4rem;
  list-style: none;
  border-bottom: 1rem solid #eaeaea;
  margin-bottom: 2rem;
  ${media.lessThan("medium")`
    justify-content:center;
  `}
`;

export const Tab = styled.li`
  width: fit-content;
  position: relative;
  padding: 1.5rem 1rem;
  text-align: center;
  color: ${({ active }) => (active ? "#b53661" : "rgba(0,0,0,0.3)")};

  font-size: 1.6rem;
  font-weight: 500;
  cursor: pointer;
  transition: 0.4s ease-in-out;

  &::before {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 0;
    height: 1rem;
    background-color: ${({ active }) => (active ? "#b53661" : "#eaeaea")};
    width: 100%;
    transition: 0.4s ease-in-out;
  }
`;

export const Modal = styled.div`
  border-radius: 1rem;
  position: fixed;
  z-index: 20;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;

  ${media.greaterThan("medium")`
    display: none;
  `}

  > div {
    position: fixed;
    background-color: #fefefe;
    margin: auto;
    padding: 2rem;
    width: 100%;
    bottom: 58px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 2rem 2rem 0rem 0rem;
    > div:nth-child(1) {
      align-self: flex-end;
      width: 3rem;
      height: 3rem;
      box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.1);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      > span {
        color: #aaaaaa;
        font-size: 2.8rem;
        font-weight: bold;

        &:hover,
        &:focus {
          color: #000;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    > div:nth-child(2) {
      padding: 2rem 6rem 6rem 6rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;

      ${media.lessThan("medium")`
        padding: 2rem 0rem 6rem 0rem;
      `}

      > h1 {
        font-size: 2rem;
        font-weight: 500;
        color: ${({ theme }) => theme.colors.blue5};
      }

      > p {
        font-size: 1.8rem;
        line-height: 1.5;
      }
    }
  }
`;
