import React from "react";
import { Link } from "react-router-dom";
import Card from "../../../../components/Card";
import * as S from "./styles";

export default function ThirdStep() {
  return (
    <S.Wrapper>
      <h1 id="i-e-3">
        3. PLANEJAMENTO, AQUISIÇÃO DE INFRAESTRUTURA E CONTRATAÇÃO DE
        CONECTIVIDADE
      </h1>

      <p>Olá, gestor(a)!</p>

      <p>
        Sabemos que podem surgir vários obstáculos e dificuldades técnicas no
        momento de contratar conectividade e adquirir equipamentos para as
        escolas. Por isso, nesta etapa da Jornada iremos apresentar ferramentas
        desenvolvidas para apoiar você nesse processo.
      </p>

      <p>Vamos lá?</p>

      <h2 id="i-e-3-s-1">3.1 Como planejar a contratação de conectividade</h2>

      <p>
        O CIEB e o NIC.br desenvolveram, em parceria com o Grupo
        Interinstitucional de Conectividade na Educação (GICE), o Guia
        Conectividade na Educação, que traz orientações e referências técnicas
        sobre parâmetros de infraestrutura, distribuição de equipamentos e sinal
        nas escolas, alternativas de financiamento e modelos de contratação.
      </p>

      <p>
        O objetivo da publicação é auxiliar gestores/as públicos/as na tomada de
        decisão para a implantação de políticas de conectividade nas escolas
        públicas brasileiras.
      </p>

      <p>Conheça!</p>

      <Card
        heading="GUIA"
        title="CONECTIVIDADE NA EDUCAÇÃO"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="guiaDeConectividade"
        link="https://cieb.net.br/wp-content/uploads/2021/09/Guia-Conectividade-na-Educacao.pdf"
      />

      <h2 id="i-e-3-s-2">3.2 Como planejar a aquisição de equipamentos</h2>

      <p>
        Qual é o gasto que será destinado à aquisição de equipamentos, como
        distribuir a verba e o que será priorizado? Essas são algumas das
        perguntas que os/as gestores/as se fazem ao começar o plano de
        investimento da rede.
      </p>

      <p>
        Pensando nisso, o CIEB desenvolveu uma Calculadora de Infraestrutura
        para você fazer essa estimativa de valor e visualizar as possibilidades
        de distribuição de equipamentos em sua rede.
      </p>

      <p>
        Explore a calculadora de infraestrutura e estruture seu planejamento
      </p>

      <Card
        heading="CALCULADORA DE"
        title="INFRAESTRUTURA"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="calculadoraDeInfra"
        link="https://guiaedutec.com.br/calculadora-infra"
      />

      <p>
        Para que o planejamento e aquisições de infraestrutura tenham mais
        consistência e impacto, é importante que elas estejam previstas e
        alinhadas às demais dimensões no Plano de Inovação e Tecnologias (PIT)
        para rede. Esse instrumento funciona como bússola, orientando o que
        precisa ser feito, quando e quem são os responsáveis. Então, paralelo a
        construção do plano de infraestrutura, elabore o PIT. Você pode entender
        mais sobre esse processo na{" "}
        <Link to="/jornada/visao">Jornada de Visão</Link>.
      </p>

      <Card
        heading="PLANO DE"
        title="INOVAÇÃO E TECNOLOGIA"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="planoDeInovacao"
        to="/planos"
      />

      <h2 id="i-e-3-s-3">3.3 Aquisição e contratação</h2>

      <p>
        A fase de aquisição de equipamentos é um grande desafio para a
        incorporação da tecnologia e inovação na educação pública. Pensando
        nisso, desenvolvemos um manual com ferramentas (toolkit) contendo
        orientações e modelos para que você, gestor/a, possa selecionar e
        adquirir equipamentos com eficiência, agilidade e segurança.
      </p>

      <p>
        Esse toolkit cobre as seguintes etapas, disponibilizando ferramentas e
        modelos específicos para cada uma delas:
      </p>

      <ol>
        <li>Planejamento para aquisição</li>
        <li>
          Definição do tipo de equipamento que a rede precisa, especificações
          técnicas, pesquisa de mercado e quantitativos;
        </li>
        <li>Apoio para a elaboração do Estudo Técnico Preliminar;</li>
        <li>
          Definição da melhor alternativa de compra (pregão, concorrência do
          tipo técnica, etc) e início do processo licitatório.
        </li>
      </ol>

      <Card
        heading="TOOLKIT DE"
        title="COMPRAS"
        buttonText="LEIA MAIS"
        dimension="infra"
        backgroundName="toolkitDeComprasInfra"
        link="https://toolkit.plataformaedutec.com.br/"
      />
    </S.Wrapper>
  );
}
