import styled, { css } from "styled-components";

import {
  MenuIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  XIcon,
} from "@heroicons/react/outline";

export const wrapperModifiers = {
  itemActive1: css`
    color: ${({ state, theme }) => state?.active && theme.colors.black};
    font-weight: ${({ state }) => state?.active && 500};
  `,
  itemActive2: css`
    fill: ${({ state, theme }) => state?.active && theme.colors.black};
  `,
  mouseOver1: css`
    color: ${({ state, theme }) => state?.hover && theme.colors.black};
    background-color: ${({ state, theme }) => state?.hover && "#efefef"};
  `,
  mouseOver2: css`
    fill: ${({ state, theme }) => state?.hover && theme.colors.black};
    background-color: ${({ state, theme }) => state?.hover && "#efefef"};
  `,
};

export const StyledChevronLeftIcon = styled(ChevronLeftIcon)`
  width: 1.8rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledChevronRightIcon = styled(ChevronRightIcon)`
  width: 1.8rem;
  stroke-width: 1.2;
  color: ${({ theme }) => theme.colors.white};
`;

export const StyledMenuIcon = styled(MenuIcon)`
  width: 2.6rem;
  stroke-width: 1.4;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const StyledXIcon = styled(XIcon)`
  width: 2.6rem;
  stroke-width: 1.4;
  color: ${({ theme }) => theme.colors.black};
  cursor: pointer;
`;

export const Wrapper = styled.aside`
  background-color: ${({ theme }) => theme.colors.white};
  margin-top: 7rem;
  height: 100%;
  width: 24rem;
  display: flex;
  flex-direction: column;
  z-index: 26;
  gap: 1rem;
  bottom: 0;
  position: fixed;
  color: ${({ theme }) => theme.colors.black};
  padding: 0rem 1.2rem;
  border-right: 0.1rem solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.1rem 0.2rem 0 rgb(0 0 0 / 0.05);

  > div {
    margin-top: 11rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      width: 8rem;
    `}

  @media print {
    display: none;
  }
`;

export const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ isExpanded }) => (isExpanded ? "flex-end" : "center")};
  text-align: center;
  position: relative;
  margin-top: 0rem;
  margin-bottom: 2rem;
`;

export const OpenCloseButton = styled.article`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.6rem;
  height: 2.6rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.black};
  position: absolute;
  right: -1.4rem;
  top: 10rem;
  &:hover {
    opacity: 0.9;
  }
  cursor: pointer;
  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      right: -1.4rem;
    `}
`;

export const TitleWrapper = styled.div`
  > span {
    font-size: 1.3rem;
    font-weight: 500;
    background-color: ${({ theme }) => theme.colors.yellow};
    padding: 0.2rem 0.7rem;
    border-radius: 1rem;
    width: 100%;
  }
  > div {
    padding: 2rem;
    background-color: ${({ theme }) => theme.colors.gray5};
    height: 7.4rem;
    border-radius: 1rem;
    ${({ isExpanded }) =>
      !isExpanded &&
      css`
        background-color: ${({ theme }) => theme.colors.white};
      `}
    > h1 {
      font-size: 1.4rem;
      font-weight: 500;
      ${({ isExpanded }) =>
        !isExpanded &&
        css`
          display: none;
        `}
    }
  }
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  padding: 2rem 0rem 2rem 0rem;

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      align-items: center;
    `};

  > ul {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Item = styled.li`
  display: flex;
  list-style: none;
  border-radius: 1rem;
  ${wrapperModifiers.mouseOver1};
  ${({ state, theme }) =>
    state?.active &&
    css`
      background-color: #efefef;
    `};

  ${({ isExpanded }) =>
    !isExpanded &&
    css`
      width: 5rem;
      height: 5rem;
      justify-content: center;
    `};

  > a {
    padding: 1.6rem;
    flex: 1;
    text-decoration: none;
    display: flex;
    gap: 1.6rem;
    align-items: center;

    > span {
      line-height: 1.5;
      font-weight: 400;
      font-size: 1.4rem;
      color: ${({ theme }) => theme.colors.black};
      ${wrapperModifiers.itemActive1};
      ${wrapperModifiers.mouseOver1};
      ${({ isExpanded }) =>
        !isExpanded &&
        css`
          display: none;
          color: ${({ theme }) => theme.colors.black};
        `};
    }
  }
`;
