import styled from "styled-components";

const WIDTH = 25;

const genderColors = {
  FEMININO: "#ff8900",
  MASCULINO: "#3f764c",
  OUTROS: "#e271a9",
  "PREFERE NÃO DIZER": "#a09403",
};

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: fit-content;
  align-items: center;
  font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
  > div:nth-child(1) {
    display: flex;
    width: ${WIDTH}rem;
    height: ${WIDTH}rem;
    border-radius: 50%;
    background: ${({ progress, label }) =>
      `conic-gradient(${genderColors[label]} ${
        (progress / 100) * 360
      }deg, #f0f0f0 0deg)`};
    transition: 0.2s ease-in;
    position: relative;

    &::after {
      content: "";
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 100%;
      margin: ${0.03 * WIDTH}rem;
      border-radius: 50%;
      background: ${({ theme }) => theme.colors.white};
      text-align: center;
    }

    > span {
      position: absolute;
      width: 0.1rem;
      height: 0.1rem;
      padding: 0;
      margin: -0.1rem;
      overflow: hidden;
      clip: rect(0, 0, 0, 0);
      white-space: nowrap;
      border-width: 0;
    }

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      gap: 0.6rem;
      z-index: 2;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-family: ${({ theme }) => theme.fonts.firaSans}, sans-serif !important;

      > span {
        font-size: ${0.25 * WIDTH}rem;
        font-weight: 600;
        color: ${({ label }) => genderColors[label]};
      }

      > p {
        font-size: ${0.1 * WIDTH}rem;
        border-top: 0.1rem solid ${({ theme }) => theme.colors.gray9};
        color: ${({ theme }) => theme.colors.gray9};
        padding: 1.5rem ${0.1 * WIDTH}rem 1rem ${0.1 * WIDTH}rem;
        font-family: ${({ theme }) => theme.fonts.poppins}, sans-serif !important;
      }
    }
  }

  > div:nth-child(2) {
    > p {
      color: #707070;
      font-size: ${0.09 * WIDTH}rem;
    }
  }
`;
