import styled from "styled-components";
import media from "styled-media-query";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import BarChartIcon from "@mui/icons-material/BarChart";

export const Wrapper = styled.div`
  background-color: #f0f0f0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
`;

export const Banner = styled.header`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 1.75rem 5.78rem 0rem 5.78rem;

  ${media.lessThan("medium")`
    padding: 1.75rem 2rem 0rem 2rem;
  `}
`;

export const BannerContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;

  > div:nth-child(2) {
    flex: 1 1 60rem;
    width: 100%;
    > img {
      width: 100%;
      max-width: 90rem;
      object-fit: contain;
    }
  }
`;

export const BannerTitle = styled.h1`
  font-weight: 600;
  font-size: 6.5rem;
  color: #313131;

  ${media.lessThan("medium")`
    text-align: center;
    font-size: 6rem;
  `}

  > span {
    color: ${({ theme }) => theme.colors.green};
    font-size: 7rem;
    ${media.lessThan("medium")`
      font-size: 6rem;
    `}
  }
`;

export const InfoDataWrapper = styled.div`
  flex: 1 1 50rem;
  padding-left: 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.2rem;

  ${media.lessThan("medium")`
    padding-left: 0rem;
  `}
  > p {
    font-weight: 200;
    font-size: 2.5rem;

    ${media.lessThan("medium")`
      text-align: center;
      font-size: 2.2rem;
      font-weight: 400;
    `}
  }

  > ul {
    list-style: none;

    > li {
      display: flex;
      align-items: center;
      margin-bottom: 0.5rem;
      gap: 1rem;

      ${media.lessThan("medium")`
        justify-content: center;
      `}
      > p {
        color: ${({ theme }) => theme.colors.white};
        font-size: 1.5rem;
        font-weight: 500;
        color: #313131;
      }
    }
  }
`;

export const Links = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  align-self: flex-end;

  > ul {
    display: flex;
    gap: 1rem;
    list-style: none;
    align-self: flex-end;
    > li {
      padding: 1rem 2rem;
      cursor: pointer;
      > a {
        font-size: 1.4rem;
        text-decoration: none;
        color: #313131;
      }

      &:hover {
        background-color: ${({ theme }) => theme.colors.white};
        padding: 1rem 2rem;
        border-radius: 1rem;
        transition: 0.2s ease-in;
      }
    }
  }
`;

export const StyledCalendarMonthIcon = styled(CalendarMonthIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;

export const StyledBarChartIcon = styled(BarChartIcon)`
  &&& {
    font-size: 2rem;
    color: #313131;
  }
`;
