import { container } from "global-styles/shared-styles";
import styled from "styled-components";

export const Wrapper = styled.section`
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    span {
      font-weight: 500;
    }
  }

  p, li {
    i {
      color: ${({ theme }) => theme.colors.blue};
    }
  }

  .gray {
    color: ${({ theme }) => theme.colors.gray};
  }

  .blue {
    color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Header = styled.header`
  background-color: ${({ theme }) => theme.colors.gray3};
  > div {
    z-index: 2;
    ${container}
    height: 880px;
    background-color: ${({ theme }) => theme.colors.gray3};
    position: relative;

    svg {
      position: absolute;
      left: 46.4%;
      top: 19%;
    }

    > div:nth-child(2) {
      position: absolute;
      bottom: 130px;
      right: 235px;

      h2 {
        font-size: 3rem;
        font-weight: 400;
        color: ${({ theme }) => theme.colors.textGray};
        font-family: ${({ theme }) => theme.fonts.openSansLight}, sans-serif;
      }
      h1 {
        font-size: 3.8rem;
        font-weight: 500;
        margin: 5px 0 20px 0;
        color: ${({ theme }) => theme.colors.textDarkGray};
      }
      button {
        border: 1px dashed;
        padding: 1rem;
        color: ${({ theme }) => theme.colors.textGray};
        font-weight: 500;
        background-color: ${({ theme }) => theme.colors.gray3};
      }
    }
  }
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1;
  background: linear-gradient(
    90deg,
    ${({ theme }) => theme.colors.gray98} 50%,
    ${({ theme }) => theme.colors.white} 50%
  );
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.colors.white};
  font-weight: 500;
`;

export const DescriptionArea = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 120px 0;
  background-color: ${({ theme }) => theme.colors.white};

  > article {
    width: 530px;
    height: 370px;
    background-color: ${({ theme }) => theme.colors.gray3};
    color: ${({ theme }) => theme.colors.gray};
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 50px;
    opacity: 0.8;
    z-index: 1;
    p {
      font-size: 1.5rem;
      margin-bottom: 20px;
    }

    span {
      font-size: 1.9rem;
      font-weight: 500;
      margin-top: 13px;
    }
  }

  > div {
    position: relative;
    &::before {
      position: absolute;
      content: "";
      background-color: rgb(244 244 244 / 70%);
      width: 30px;
      height: 370.5px;
      top: -24.5px;
      left: 0;
    }

    img {
      width: 100%;
      height: 320px;
    }
  }
`;

export const SummarySteps = styled.section`
  /* ${container} */
  background-color: ${({ theme }) => theme.colors.gray98};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6rem;
  > img {
    width: 90rem;
  }
`;
