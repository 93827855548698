import styled from "styled-components";
import image08 from "assets/images/plans/08.svg";
import { container } from "global-styles/shared-styles";

export const Wrapper = styled.div`
  margin-top: 7rem;
`;

export const BannerWrapper = styled.div`
  background: url(${image08}),
    linear-gradient(
      to top,
      #d9e1e9 0%,
      #d9e1e9 15%,
      #e6c528 15%,
      #e6c528 29.6%,
      #fad62b 29.6%,
      #fad62b 100%
    );
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`;

export const Banner = styled.header`
  ${container};
  display: flex;
  height: 50rem;
  width: 100%;
`;

export const FirstText = styled.div`
  margin-top: 12rem;
  margin-bottom: 8rem;
  text-align: center;
  font-size: 2rem;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Dimensions = styled.div`
  ${container};
  display: flex;
  justify-content: center;
`;

export const Balance = styled.div`
  margin: 6rem 0;
  text-align: center;

  > p {
    font-size: 2.6rem;
    color: ${({ theme }) => theme.colors.blue5};
    font-weight: 400;
  }
`;

export const BalanceText = styled.div`
  ${container};
  margin-bottom: 6rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  padding-inline: 16rem !important;
  color: ${({ theme }) => theme.colors.gray};

  .amarelo {
    color: ${({ theme }) => theme.colors.yellow};
  }

  .azul {
    color: ${({ theme }) => theme.colors.blue};
  }

  .vermelho {
    color: ${({ theme }) => theme.colors.pink};
  }

  .verde {
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const TwoText = styled.div`
  ${container};
  padding-inline: 10rem !important;
  margin-bottom: 8rem;
  text-align: center;
  font-size: 2rem;
  font-weight: 300;
  color: ${({ theme }) => theme.colors.gray};
  > p {
    margin-bottom: 2rem;
  }
`;

export const School = styled.div`
  text-align: center;
  ${container}
`;

export const Flow = styled.div`
  background-color: #f2f2f2;
  padding: 9rem 0;
`;

export const FlowImageWrapper = styled.div`
  ${container}
  display: flex;
  justify-content: center;
  gap: 6rem;
`;

export const FlowItem = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  width: 16rem;
  align-items: center;

  color: ${({ theme }) => theme.colors.gray};

  > img {
    width: 12rem;
  }

  > p {
    text-align: center;
    font-size: 1.6rem;
    font-weight: 300;
    width: 100%;
  }
`;

export const Icons = styled.td`
  font-size: 1.3rem;
`;

export const Start = styled.div`
  ${container};
  margin: 13rem auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 6rem;
  justify-content: center;
  /* align-items: center; */

  > h3 {
    font-size: 2.8rem;
    margin-bottom: 2.5rem;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.gray};
  }

  > button {
    background-color: ${({ theme }) => theme.colors.blue5};
    border-radius: 0.6rem;
    padding: 1.5rem 2rem;
    border: none;
    font-size: 1.5rem;
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: fit-content;
    align-self: center;
    &:hover {
      opacity: 0.9;
    }
  }
`;
